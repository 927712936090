
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import { toast } from "react-toastify";
import { checkArray } from "../../../utils/CheckType";



const CreateLotModal = ({createLotModal, setCreateLotModal, phases}) => {


  const [lotsState, setLotsState] = useState([])
  const [numLandLots, setNumLandLots] = useState(0)



  useEffect(()=>{


  if(createLotModal &&phases?.length > 0){

    setLotsState(phases?.lots)

  }
  else{
    setLotsState([])
  }

  },[phases])






const handleInputNumber = (e) => {
  const { name, value } = e.target;
  const result = e.target.value.replace(/\D/g, "");
  setNumLandLots(e.target.value)
  let tempArray  = ((new Array(+value)).fill( {
    numOfLot:"" ,
    lotDescription: "",
    lotType: ""
}))

   


    setLotsState(tempArray)
  }



  const handleLotChange = (e,index,indexMain) => {
    const {name,value} = e.target;


    let tempArray = [...lotsState];


    let tempObj = tempArray[index];

    if(name ==="lotType"){

      tempObj['lotType'] = value


    }


    if(name ==="numOfLot"){

      tempObj['numOfLot'] = value


    }
    if(name ==="lotDescription"){

      tempObj['lotDescription'] = value


    }





  
  

    setLotsState(tempArray)
    
    }
  



  return (

    <Modal show={createLotModal} className="ModalBox LargeModal">
    <div class="modal-content BgCreate">
      <a
        class="CloseModal"
        onClick={()=> setCreateLotModal(false)}
        data-dismiss="modal"
      >
        ×
      </a>
      <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create Land Development Lots</h4>
            </div>

            <div class="FolderForm">

              <form>

                <div class="FolderFormCreateArea">
                  <div class="ModalMediumheading">
                    <h3><span>Project:</span> Proj-4E-SIN-1</h3>
                    <h3><span>Job id:</span> HIL-2B-376</h3>
                    <h3><span>Land Tract/Phase Description:</span> Phase 1</h3>
                  </div>
                  <div class="ModalBigheading2 d-flex align-items-center mb-3">
                    <h3 class="mb-0">Enter Number of Lots to be added</h3>
                    <input className="ModalBigheadingBox" name='numLandLots' value={numLandLots} onChange={handleInputNumber}/>                  </div>
                  <div class="ModalMediumheading">
                    <h3><span>Lots Setup</span></h3>
                  </div>
                  <div class="EnterProjectTable UnitSetupTable">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Lot #</th>
                          <th>Lot Description</th>
                          <th>Lot Type</th>
                        </tr>
                      </thead>
                      <tbody>
                      <>
                   <tr>
                <td colSpan={4}>Phase </td>
              </tr>
              {checkArray(lotsState) && lotsState?.map((item,i) => {
                return (
                  <>
                   <tr>
                <td>Lot {i+1}</td>
                <td>
                  <div className="form-group mr-5">
                    <input
                      type="number"
                      className="form-control width-50"
                      placeholder={"10"}
                      name='numOfLot'
                      min={0}
                      value={item?.numOfLot}
                    />
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Residential Lot 10"
                      name='lotDescription'
                      value={item?.lotDescription}
                      onChange={(e) => {
                        lotsState[0].lotDescription = (e.target.value)
                        let value = [...lotsState];
                        setLotsState(value);
                      }}                    />
                  </div>
                </td>
                <td>
                  <div className="CommonSelectBox">
                    <select name='lotType'
                      value={item?.lotType}
                      onChange={(e) => handleLotChange(e,i)}
                      >
                      <option selected="">Select Lot Type</option>
                      <option value="Lot 2">Lot type 1</option>
                    </select>
                  </div>
                </td>
              </tr>
                  </>
                )
              })}
             
                  </>
            

                      </tbody>
                    </table>
                  </div>
                </div>



                <div class="Button d-flex justify-content-between align-items-center px-5">
                  <button class="Outline" data-toggle="modal" data-target="#CreateLandDevelopmentLandTractsPhasesLandTrack" data-dismiss="modal">Back</button>
                  <button class="Create FolderPermissionId" data-toggle="modal" data-target="#CreateMultiFamilyJobsUnitsNext" data-dismiss="modal">Create Lots</button>
                </div>
              </form>

            </div>

          </div>
    </div>
  </Modal>


)
}

export default CreateLotModal