import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import {
  getCitiesList,
  getCountriesList,
  getStateList,
  subDivisionListAction,
} from "../redux/action/authAction";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLeadsListAction,
  updateContactDetails,
} from "../redux/action/saleAction";
import { toast } from "react-toastify";
import { calculateDateDifferenceInDays } from "../utils/uploadFile";

const initialState = {
  legalName: "",
  streetAddress: "",
  primaryPhoneNum: "",
  addressLineTwo: "",
  email: "",
  city: "",
  businessPhoneNum: "",
  zipCode: "",
  faxPhoneNum: "",
  country: "",
  preferredCommu: "",
  province: "",
  subDivision: "",
  leadStatus: "",
  confidenceLevel: "",
  referrerName: "",
  referrerAgency: "",
  referrerEmail: "",
  assignStaffId: [],
  surveys: [],
  leadId: "",
};
const UpdateLeadModal = ({ show, onClose, LeadDatas }) => {
  const [iState, updateState] = useState(initialState);
  const dispatch = useDispatch();
  const { countryData, cityData, stateData, subDivisionListData } = useSelector(
    (state) => state.authReducer
  );
  const {
    legalName,

    streetAddress,
    primaryPhoneNum,
    addressLineTwo,
    email,
    city,
    businessPhoneNum,
    zipCode,
    faxPhoneNum,
    country,
    preferredCommu,
    province,
    subDivision,
    leadStatus,
    confidenceLevel,
    referrerName,
    referrerAgency,
    referrerEmail,
    survey,
    assignStaffId,
    surveys,
    leadId,
  } = iState;

  useEffect(() => {
    if (LeadDatas) {
      const updateData = structuredClone(iState);
      updateData.legalName = LeadDatas?.legalName;
      updateData.name = LeadDatas?.name;
      updateData.primaryPhoneNum = LeadDatas?.primaryPhoneNum;
      updateData.email = LeadDatas?.email;
      updateData.businessPhoneNum = LeadDatas?.businessPhoneNum;
      updateData.faxPhoneNum = LeadDatas?.faxPhoneNum;
      updateData.preferredCommu = LeadDatas?.preferredCommu;
      updateData.streetAddress = LeadDatas?.streetAddress;
      updateData.addressLineTwo = LeadDatas?.addressLineTwo;
      updateData.zipCode = LeadDatas?.zipCode;
      updateData.country = LeadDatas?.country;
      updateData.province = LeadDatas?.province;
      updateData.city = LeadDatas?.city;
      updateData.subDivision = LeadDatas?.subDivision;
      updateData.leadStatus = LeadDatas?.leadStatus;
      updateData.confidenceLevel = LeadDatas?.confidenceLevel;
      updateData.referrerName = LeadDatas?.referrerName;
      updateData.referrerEmail = LeadDatas?.referrerEmail;
      updateData.referrerAgency = LeadDatas?.referrerAgency;
      updateData.referrerAgency = LeadDatas?.referrerAgency;
      updateData.leadId = LeadDatas?.id;
      updateState(updateData);
    }
    setBussinessPhoneCode(
      LeadDatas?.bussinessPhoneCode?.startsWith("+")
        ? LeadDatas?.bussinessPhoneCode?.substring(1)
        : LeadDatas?.bussinessPhoneCode
    );
    setPhoneCode(
      LeadDatas?.phonecode?.startsWith("+")
        ? LeadDatas?.phonecode?.substring(1)
        : LeadDatas?.phonecode
    );
  }, [LeadDatas]);

  useEffect(() => {
    const fetchCountryStateCity = async () => {
      if (country && province) {
        await dispatch(getCountriesList());
        const countryIsoCode = countryData?.countries?.find(
          (x) => x.name === country
        )?.isoCode;
        if (countryIsoCode) {
          await dispatch(getStateList(countryIsoCode));
          const stateIsoCode = stateData?.states?.find(
            (x) => x.name === province
          )?.isoCode;
          if (stateIsoCode) {
            await dispatch(getCitiesList(countryIsoCode, stateIsoCode));
          }
        }
      }
    };

    fetchCountryStateCity();
  }, [country, province]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };
  const [phonecode, setPhoneCode] = useState();
  const [bussinessPhoneCode, setBussinessPhoneCode] = useState("");
  const handlePhoneInputChange = (value, country) => {
    setPhoneCode(`+${country.dialCode}`);
  };
  const handleBussinessPhoneInputChange = (value, country) => {
    setBussinessPhoneCode(`+${country.dialCode}`);
  };

  const handleUpdateLeads = () => {
    const data = {
      legalName,
      streetAddress,
      primaryPhoneNum,
      addressLineTwo,
      phonecode: phonecode?.includes("+") ? phonecode : `+${phonecode}`,
      bussinessPhoneCode: bussinessPhoneCode?.includes("+")
        ? bussinessPhoneCode
        : `+${bussinessPhoneCode}`,
      email,
      city,
      businessPhoneNum,
      zipCode,
      faxPhoneNum,
      country,
      preferredCommu,
      province,
      subDivision,
      leadStatus,
      confidenceLevel,
      referrerName,
      referrerAgency,
      referrerEmail,
      survey,
      // assignStaffId: [{ UserId }],
      bussinessPhoneCode,
    };

    dispatch(updateContactDetails(leadId, data)).then((res) => {
      if (res.status === 200) {
        dispatch(getAllLeadsListAction());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState(initialState);
        onClose();
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  return (
    <>
      <Modal show={show} onHide={onClose} className="ModalBox LargeModal">
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal ClosePermissionId"
                onClick={onClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Update Leads</h4>
                </div>
                <div className="FolderForm">
                  <div className="row">
                    <div className="col-lg-5 col-md-12">
                      <div className="form-group">
                        <div className="ModalBigheading">
                          <h3>
                            <span>Personal Information</span>
                          </h3>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>
                          Client Full Legal name
                          <span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input client's full legal name"
                          name="legalName"
                          value={legalName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Country Code</h6>
                        <PhoneInput
                          inputClass="CountryCodeInput"
                          countryCodeEditable={false}
                          enableSearch
                          value={phonecode}
                          onChange={handlePhoneInputChange}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          Primary Phone Number
                          <span className="required">*</span>
                        </h6>

                        <input
                          type="number"
                          className="form-control"
                          placeholder="Input client's full legal name"
                          name="primaryPhoneNum"
                          value={primaryPhoneNum}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>
                          Email<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input email "
                          name="email"
                          value={email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6> Business Country Code</h6>
                        <PhoneInput
                          inputClass="CountryCodeInput "
                          countryCodeEditable={false}
                          enableSearch
                          value={bussinessPhoneCode}
                          onChange={handleBussinessPhoneInputChange}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>Business Phone Number</h6>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Input client's full legal name"
                          name="businessPhoneNum"
                          value={businessPhoneNum}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Fax Phone Number</h6>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Input fax number"
                          name="faxPhoneNum"
                          value={faxPhoneNum}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div class="form-group">
                        <h6>Preferred Communication</h6>
                        <div class="CommonSelectBox w-100">
                          <select
                            name="preferredCommu"
                            value={preferredCommu}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Preference</option>
                            <option value="No Preference">No Preference</option>
                            <option value="Phone">Phone</option>
                            <option value="Email">Email</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12 ml-auto">
                      <div className="form-group">
                        <div className="ModalBigheading">
                          <h3>
                            <span>Address</span>
                          </h3>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>Street Address</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input street name"
                          name="streetAddress"
                          value={streetAddress}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Address Line two</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input address line two"
                          name="addressLineTwo"
                          value={addressLineTwo}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>
                          Zip/Postal Code<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input postal name"
                          name="zipCode"
                          value={zipCode}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div class="form-group">
                        <h6>
                          Country<span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox w-100">
                          <select
                            name="country"
                            value={country}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Country</option>
                            {countryData?.countries?.length > 0 &&
                              countryData?.countries?.map((item, i) => {
                                return (
                                  <option value={item.name}>{item.name}</option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div class="form-group">
                        <h6>
                          Province/State<span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox w-100">
                          <select
                            name="province"
                            value={province}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Province/State</option>
                            {stateData?.states?.length > 0 ? (
                              stateData?.states?.map((item, i) => {
                                return (
                                  <option value={item.name}>{item.name}</option>
                                );
                              })
                            ) : (
                              <option disabled>
                                No Province/State Available
                              </option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div class="form-group">
                        <h6>
                          City<span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox w-100">
                          <select
                            name="city"
                            value={city}
                            onChange={handleInputChange}
                          >
                            <option value="">Select City</option>
                            {cityData?.cities?.length > 0 ? (
                              cityData?.cities?.map((item, i) => {
                                return (
                                  <option value={item.name}>{item.name}</option>
                                );
                              })
                            ) : (
                              <option disabled>No Cities Available</option>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="TodosButtons mt-0 text-center">
                        <a href="javascript:void(0)">
                          <img src="images/general_information.png" />
                          General Information
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="GeneralInformation mt-4">
                    <div className="row">
                      {/* <div className="form-group col-lg-4 col-md-12">
                          <div className="CommonModalArea">
                            <h5>Assigned to</h5>
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                title="assign sales representative"
                                onClick={() => showAssignSaleModal(false)}
                              >
                                <figure>
                                  <img src="images/icon-32.png" />
                                </figure>
                                <figure>
                                  <img src="images/refresh.png" />
                                </figure>
                                {UserId
                                  ? staffListData?.staff?.find(
                                      (ele) => ele?.id == UserId
                                    )?.name
                                  : "Select"}
                              </h5>
                            </div>
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {leadErrors?.UserIdError}
                            </span>
                          </div>
                        </div> */}

                      <div class="form-group col-lg-4 col-md-12">
                        <h6>
                          SubDivision/Projects<span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox w-100">
                          <select
                            name="subDivision"
                            value={subDivision}
                            onChange={handleInputChange}
                          >
                            <option value="">
                              Select SubDivision/Projects
                            </option>
                            {subDivisionListData?.details?.length > 0 ? (
                              subDivisionListData?.details?.map((item, i) => {
                                return <option value={item}>{item}</option>;
                              })
                            ) : (
                              <option disabled>
                                No SubDivision/Projetcs Available
                              </option>
                            )}
                          </select>
                        </div>
                      </div>

                      {/* <div className="form-group col-lg-4 col-md-12">
                          <div className="CommonModalArea">
                            <h5>Survey</h5>
                            <div className="CommonModalBox">
                              <figure>
                                <img src="images/survey.png" title="survey" />
                              </figure>
                              <span className="Count">3</span>
                              <h5 onClick={showAddSurveyModal} className="">
                                Survey
                              </h5>
                            </div>
                          </div>
                        </div> */}
                      <div className="form-group col-lg-4 col-md-12">
                        <div className="CommonModalArea">
                          <h5>Lead Age</h5>
                          <div className="CommonModalBox">
                            <h5 className="">
                              &lt;
                              {calculateDateDifferenceInDays(
                                LeadDatas?.createdAt,
                                new Date().toISOString()
                              )}
                              day
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="form-group">
                          <h6>
                            Lead Status<span className="required">*</span>
                          </h6>

                          <div class="CommonSelectBox w-100">
                            <select
                              name="leadStatus"
                              value={leadStatus}
                              onChange={handleInputChange}
                            >
                              <option>Select Status </option>
                              <option value="New">New</option>
                              <option value="InProgress">In progress</option>
                              <option value="Intersted">Interested</option>
                              <option value="Not Interested">
                                Not Interested
                              </option>
                              <option value="Cancelled">Cancelled</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="form-group">
                          <h6>
                            Confidence Level
                            {/* <span className="required">*</span> */}
                          </h6>
                          <div className="CommonSelectBox w-100">
                            <select
                              name="confidenceLevel"
                              value={confidenceLevel}
                              onChange={handleInputChange}
                            >
                              <option>Confidence Level </option>
                              <option value="Low">Low</option>
                              <option value="Medium">Medium</option>
                              <option value="High">High</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <h6 className="mb-4">Realtor/Referrer</h6>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="row">
                          <div className="form-group col-md-10">
                            <h6>Name</h6>
                            <input
                              type="text"
                              className="form-control"
                              name="referrerName"
                              value={referrerName}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="form-group col-md-10">
                            <h6>Email</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Input Email"
                              name="referrerEmail"
                              value={referrerEmail}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group col-md-10 ml-auto">
                          <h6>Agency</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Agency"
                            name="referrerAgency"
                            value={referrerAgency}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="Button d-flex justify-content-between mt-3">
                      <button onClick={handleUpdateLeads} className="Create">
                        Update Lead
                      </button>
                      <button className="Outline" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateLeadModal;
