import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import CreateBlock from './CreateBlock';
import CreateCommonBuilding from './CreateCommonBuilding';
import { getMultiFamilyDetailsAction } from '../../redux/action/jobAction';
import { useDispatch } from 'react-redux';
import { getMultifamilyJobAction } from '../../redux/action/MultifamilyJobActionDk';
import { useSelector } from 'react-redux';
import CreateUnits from './CreateUnits';
import CreateOnlyUnits from './createOnlyUnit';

const EditJobForBlock = ({  jobDetailsForBlock,
    setJobDetailsForBlock,
    selectedId,
    jobId,
    jobData,
    setEditJobForBlockModal,
    editJobForBlockModal,
    

    jobName}) => {

      const [createOnlyUnitsModal, setCreateOnlyUnitsModal] = useState(false);



      const dispatch = useDispatch()
    const handleJobDetialsModalClose = () => {
        setEditJobForBlockModal(false);
      };
      const handleJobDetialsModalShow = () => {
        setEditJobForBlockModal(true);
      };

      const initialState = {
        ProjectId:selectedId,
        buildingPermit: "",
        subdividision: "",
        specificationDoc: "",
        taxProfile: "",
        baseModel: "",
        homeType: "",
        defaultCurrency: "",
        costScheme: "",
        timeZone: "",
        accountSoftware: false,
        underParkade: false,
        jobAddress: "",
        lot: "",
        block: "",
        phase: "",
        city: "",
        zipCode: "",
        country: "",
        province: "",
        numOfBlocks: 0,
        blocks: [],
        numOfBuildings: 0,
      
      
        
        buildings: [],
        itemId:"",
        errorMsg: {},
      };


      const [iState, UpdateState] = useState(initialState);

      const {
        ProjectId,
        subdividision,
        taxProfile,
        buildingPermit,
        homeType,
        costScheme,
        province,
        specificationDoc,
        baseModel,
        defaultCurrency,
        timeZone,
        proformaId,
    
    
        
        accountSoftware,
        underParkade,
        jobAddress,
        lot,
        block,
        phase,
        city,
        zipCode,
        country,
        numOfBlocks,
        blocks,
        numOfBuildings,
        buildings,
        itemId,
      
        errorMsg,
      } = iState;
    
    
    
      const {
    
        foundationTypeData,
    
      } = useSelector((state) => state.ProformaReducer);
      const [createBlockModal, setCreateBlockModal] =useState(false);
      const [createCommonBuildingModal, setCreateCommonBuildingModal] =useState(false)


      useEffect(()=>{

        if(selectedId){
    dispatch(getMultifamilyJobAction(selectedId)).then((res) => {

      UpdateState({...iState,
        buildingPermit: res?.data?.[0]?.buildingPermit,
        subdividision: res?.data?.[0]?.subdividision,
        specificationDoc: "",
        taxProfile: "",
        baseModel: "",
        homeType: "",
        defaultCurrency: "",
        costScheme: "",
        timeZone: "",
        accountSoftware: false,
        underParkade: false,
        jobAddress: res?.data?.[0]?.jobNumber,
        lot: "",
        block: "",
        phase: "",
        city: "",
        zipCode: "",
        country: "",
        province: "",
        numOfBlocks: 0,
        blocks: res?.data?.[0]?.MultiBlocks?.filter((ele)=>ele?.jobNumber==jobName),
        numOfBuildings: 0,
      
      
        
        buildings: res?.data?.[0]?.MultiBuildings,
        itemId:res?.data?.[0]?.id,

      });

    })}

      },[])


      const handleChange = (e) => {
        const { name, value } = e.target;
    
        UpdateState({
          ...iState,
          [name]: value,
        });
      };


    

  return (

    <>
    <Modal show={editJobForBlockModal} className="ModalBox LargeModal">
    <div class="modal-content BgCreate">
      <a
        class="CloseModal"
        onClick={handleJobDetialsModalClose}
        data-dismiss="modal"
      >
        ×
      </a>
      <div class="FormArea">
            <div class="ModalTitleBox GrayModalTitle">
              <h4>Multi-Family Job Details</h4>
            </div>
            <div class="FolderForm">
              <div class="ResidentialJobArea ResidentialJobAreaPara">

                <div class="ResidentialJobHead">
                  <h3>
                    {jobData?.jobNumber}
                    <span class="dropdown">
                      <a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="images/dots.png" />
                      </a>
                      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" href="javascript:void(0);"><img src="images/archive_job.png" />Archive
                          Job</a>
                      </div>
                    </span>
                  </h3>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <h6>Created By</h6>
                        <input type="text" className="form-control" />John Doe
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <h6>Creation Date</h6>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <h6>Last Update</h6>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ModalAccordian">
                  <div id="accordion">

                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#GeneralDetails" aria-expanded="true">
                        <h4><span><img src="images/general_details.png" /></span>General Details</h4>
                        <a class="Edit" data-toggle="modal" data-target="#MultiFamilyJobDetailsUnit3" data-dismiss="modal">
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div id="GeneralDetails" class="collapse show" data-parent="#accordion">

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-sm-5">
                                <div class="form-group">
                                  <h6>Project</h6>
                                  <input type="text" className="form-control" />
                                </div>
                                <div class="form-group">
                                  <h6>Home Type</h6>
                                  <input type="text" className="form-control" />
                                </div>
                                <div class="form-group">
                                  <h6>Shared Unit Cost Scheme</h6>
                                  <input type="text" className="form-control" />Square Foot Percentage
                                </div>
                                <div class="form-group">
                                  <h6>Subdivision</h6>
                                  <input type="text" className="form-control" />Delta
                                </div>
                                <div class="form-group">
                                  <h6>Building Permit #</h6>
                                  <input type="text" className="form-control" />BP2023-11766
                                </div>
                                <div class="form-group">
                                  <h6>Accounting Status</h6>
                                  <h6 class="fw-400">Integrate with Accounting Software</h6>
                                </div>




                              </div>
                              <div class="col-sm-5 ml-auto">
                                <div class="form-group">
                                  <h6>Specification Document</h6>
                                  <input type="text" className="form-control" />
                                </div>
                                <div class="form-group">
                                  <h6>Base Model</h6>
                                  <input type="text" className="form-control" />
                                </div>
                                <div class="form-group">
                                  <h6>Tax Profile</h6>
                                  <input type="text" className="form-control" />
                                </div>
                                <div class="form-group">
                                  <h6>Default Currency</h6>
                                  <input type="text" className="form-control" />
                                </div>
                                <div class="form-group">
                                  <h6>Job Site GMT (UTC) Timezone</h6>
                                  <input type="text" className="form-control" />
                                </div>

                              </div>
                              <div class="col-md-7">
                                <div class="form-group">
                                  <h6>Parking</h6>
                                  <div class="ParkingBoxArea">
                                    <div class="ParkingBox">
                                      <h6>Job Id</h6>
                                      <input type="text" className="form-control" />
                                    </div>
                                    <div class="ParkingBox">
                                      <h6>Type</h6>
                                      <input type="text" className="form-control" />
                                    </div>
                                    <div class="ParkingBox">
                                      <h6>Action</h6>
                                      <div class="Actions">
                                        <a href="javascript:void(0);"><img src="images/Action-1.png" /></a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </form>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#BlocksUnits">
                        <h4><span><img src="images/blocks_units.png" /></span>Blocks &amp; Units</h4>
                        <a class="Edit" href="javascript:void(0);" data-toggle="modal" data-target="#MultiFamilyJobDetailsUnit3" data-dismiss="modal">
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div id="BlocksUnits" class="collapse show" data-parent="#accordion">

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <h6>Block</h6>
                                  <p type="text" className="form-control" >{jobData?.blockDescription}</p >
                                </div>
                                <div class="form-group">
                                  <h6>Unit #</h6>
                                  <p type="text" className="form-control" >{jobData?.numOfUnits}</p >
                                </div>
                                <div class="form-group">
                                  <h6>Unit's Sq/Ft</h6>
                                  <p type="text" className="form-control" >1482</p >
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <h6># of Bedrooms</h6>
                                  <p type="text" className="form-control" >3</p >
                                </div>
                                <div class="form-group">
                                  <h6># of Bedrooms</h6>
                                  <p type="text" className="form-control" >2.5</p >
                                </div>
                                <div class="form-group">
                                  <h6>Basement Development</h6>
                                  <p type="text" className="form-control" >Yes</p >
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="TodosButtons mt-0 text-center">
                                  <a onClick={()=>setCreateOnlyUnitsModal(true)}><img src="images/create_new_units.png" />Create New Units
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div class="Tableheader">
                              <h6 class="mb-0">Units</h6>
                              <div class="Actions">
                                <a href="javascript:void(0);"><img src="images/icon-53.png" /></a>
                                <a href="javascript:void(0);"><img src="images/download.png" /></a>
                                <a href="javascript:void(0);"><img src="images/setting.png" /></a>
                              </div>
                            </div>


                            <div class="TableList TableListHeader NewTableList TableHeightScroll">
                              <table>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Block's Units #</th>
                                    <th>Unit's Sq/Ft</th>
                                    <th># of Bedrooms</th>
                                    <th># of Bathrooms</th>
                                    <th>Basement Development</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>

                                {jobData?.MultiUnits?.map((item)=>

                                  <tr>
                                    <td>{item?.unitNumber}</td>
                                    <td>{item?.unitsSqFt}</td>
                                    <td>{item?.numOfBedrooms}</td>
                                    <td>{item?.numOfBathrooms}</td>
                                    <td>
                                      <label class="CheckBox mb-0">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>
                                      <div class="TableThreeDots">
                                        <ul class="">
                                          <li class="dropdown">
                                            <a class="" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                              <img src="images/dots.png" />
                                            </a>
                                            <ol class="dropdown-menu dropdown-menuwidth">
                                              <li><a href="javascript:void(0);"><img src="images/Action-1.png" />Edit Job
                                                  Details</a></li>
                                              <li><a href="javascript:void(0);"><img src="images/archive_job.png" />Archive Job</a></li>
                                            </ol>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>)}
                             
                                </tbody>
                              </table>
                            </div>

                            <div class="PaginationArea">
                              <h6>Showing 3 of 27 entries</h6>
                              <h6><img src="images/leftarrow.png" /> 01 <img src="images/rightarrow.png" /> Out 10</h6>
                            </div>
                            <div class="Button d-flex justify-content-center mt-2">
                              <button class="OutlineBlack mr-3">Cancel</button>
                              <button class="Respond ml-3">Save</button>
                            </div>


                          </form>

                        </div>
                      </div>
                    </div>


                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#BusinessAddress">
                        <h4><span><img src="images/business_address.png" /></span>Business Address</h4>
                        <a class="Edit" href="javascript:void(0);" data-toggle="modal" data-target="#MultiFamilyJobDetailsUnit3" data-dismiss="modal">
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div id="BusinessAddress" class="collapse show" data-parent="#accordion">

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-sm-5">
                                <div class="form-group">
                                  <h6>Job Address</h6>
                                  <input type="text" className="form-control"  />Street 1
                                </div>
                                <div class="form-group">
                                  <h6>Lot</h6>
                                </div>
                                <div class="form-group">
                                  <h6>Block</h6>
                                </div>
                                <div class="form-group">
                                  <h6>Phase</h6>
                                  <input type="text" className="form-control"  />
                                </div>


                              </div>
                              <div class="col-sm-5 ml-auto">
                                <div class="form-group">
                                  <h6>City</h6>
                                  <input type="text" className="form-control" value={"Calgary"} />
                                </div>
                                <div class="form-group">
                                  <h6>Zip/ Postal Code</h6>
                                  <input type="text" className="form-control" value={"T3A T3A"} />
                                </div>
                                <div class="form-group">
                                  <h6>Country</h6>
                                  <input type="text" className="form-control" value={"Canada"} />
                                </div>
                                <div class="form-group">
                                  <h6>State/Province</h6>
                                  <input type="text" className="form-control" value={"AB"} />
                                </div>

                              </div>
                            </div>



                          </form>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#SalesInformation">
                        <h4><span><img src="images/sales_information.png" /></span>Sales Information<span class="ml-3"><img src="images/request.png" /></span></h4>


                      </div>
                      <div id="SalesInformation" class="collapse show" data-parent="#accordion">

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <h6>Agreement Date</h6>
                                  <input type="text" className="form-control" value="05/15/2023" />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <h6>Possession Date</h6>
                                  <input type="text" className="form-control" value={"05/15/2023"} />
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <h6>Customer Purchase Price</h6>
                                  <input type="text" className="form-control" value={"$450,000.00"} />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <h6>Sales Consultant</h6>
                                  <input type="text" className="form-control" value={"Jill Sales"} />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <h6>Sales Type</h6>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                            </div>



                          </form>
                        </div>
                      </div>
                    </div>



                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#EstimationConstructionInformation">
                        <h4><span><img src="images/estimation_information.png" /></span>Estimation &amp; Construction
                          Information</h4>

                      </div>
                      <div id="EstimationConstructionInformation" class="collapse show" data-parent="#accordion">

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <h6>Primary Estimator/Purchaser</h6>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <h6>Primary Site Super</h6>
                                  <input type="text" className="form-control"  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>



                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#ClientInformation">
                        <h4><span><img src="images/estimation_information.png" /></span>Client Information</h4>


                      </div>
                      <div id="ClientInformation" class="collapse show" data-parent="#accordion">

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-md-6">
                                <h6>Personal Information</h6>
                                <div class="form-group">
                                  <h6>Client Full Legal name</h6>
                                  <input type="text" className="form-control" value="Joe Full Client" />
                                </div>
                                <div class="form-group">
                                  <h6>Primary Phone Number</h6>
                                  <input type="text" className="form-control" value="(587)555-555<"  />
                                </div>
                                <div class="form-group">
                                  <h6>Email</h6>
                                  <input type="text" className="form-control" value="oe.fclient@email.com"  />
                                </div>
                                <div class="form-group">
                                  <h6>Business Phone Number</h6>
                                </div>
                                <div class="form-group">
                                  <h6>Fax Phone Number</h6>
                                </div>
                                <div class="form-group">
                                  <h6>Preferred Communication</h6>
                                  <input type="text" className="form-control" value={"Email"} />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <h6>Address</h6>
                                <div class="form-group">
                                  <h6>Street Address</h6>
                                  <input type="text" className="form-control" value={"120 4th Street Sw"} />
                                </div>
                                <div class="form-group">
                                  <h6>Address Line two</h6>
                                </div>
                                <div class="form-group">
                                  <h6>City</h6>
                                  <input type="text" className="form-control" value="Calgary" />
                                </div>
                                <div class="form-group">
                                  <h6>Zip/Postal Code</h6>
                                  <input type="text" className="form-control"  value={"T3A T3A"} />
                                </div>
                                <div class="form-group">
                                  <h6>Country</h6>
                                  <input type="text" className="form-control"   value={"Canda"}/>
                                </div>
                                <div class="form-group">
                                  <h6>State/Province</h6>
                                  <input type="text" className="form-control"  value={"T3A T3A"} />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>





                  </div>
                  <div class="CloseButton">
                    <button class="Close ClosePermissionId" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
      </div>
      </Modal>

   <CreateBlock createBlockModal={createBlockModal} setCreateBlockModal={setCreateBlockModal}  setJobDetailsForBlock={setJobDetailsForBlock} iState={iState} UpdateState={UpdateState} /> 

   <CreateOnlyUnits
        createOnlyUnitsModal={createOnlyUnitsModal}
        setCreateOnlyUnitsModal={setCreateOnlyUnitsModal}
        selectedId={selectedId}
      />

      <CreateCommonBuilding createCommonBuildingModal={createCommonBuildingModal} setCreateCommonBuildingModal={setCreateCommonBuildingModal}  setJobDetailsForBlock={setJobDetailsForBlock} jobName={jobName} iState={iState} UpdateState={UpdateState} />     



      
      </>)
}

export default EditJobForBlock