import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getAllProjectDetailsAction } from "../../redux/action/jobAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import EditProjectModal from "./EditProjectModal";

const ProjectDetailsModal = ({
  projectDetailsModal,
  setJobDetialsModal,
  selectedId,
  jobName
}) => {
  const dispatch = useDispatch();

  const [editProjectModal,setEditProjectModal] =useState(false)

  const [projectDetailsData, setProjectDetialsData] = useState(null);

  const handleJobDetialsModalClose = () => {
    setJobDetialsModal(false);
  };
  const handleJobDetialsModalShow = () => {
    setJobDetialsModal(true);
  };

  useEffect(() => {
    dispatch(getAllProjectDetailsAction(selectedId)).then((res) => {
      // console.log("details updated", res)
      setProjectDetialsData(res?.projects);
    });
  }, []);


  return (
    <>
    <Modal show={projectDetailsModal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          class="CloseModal"
          onClick={handleJobDetialsModalClose}
          data-dismiss="modal"
        >
          ×
        </a>
        <div class="ResidentialJobArea">
          <div class="ModalTitleBox GrayModalTitle">
            <h4>{jobName} Job Details</h4>
          </div>

          <div class="ResidentialJobHead">
            <h3>
              {projectDetailsData?.jobNumber} (Basement Development)
              <span class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src="images/dots.png" />
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="#">
                    <img src="images/Archive.png" /> Archive Job
                  </a>
                </div>
              </span>
            </h3>
            <article>
              <aside>
                <h4>Created By</h4>
                <p>{projectDetailsData?.createdBy}</p>
              </aside>
              <aside>
                <h4>Creation Date</h4>
                <p>{moment(projectDetailsData?.createdAt)?.format('DD/MM/YYYY')}</p>
              </aside>
              <aside>
                <h4>Last Update</h4>
                <p>{moment(projectDetailsData?.updatedAt)?.format('DD/MM/YYYY')}</p>
              </aside>
            </article>
          </div>

          <div class="ModalAccordian">
            <div id="accordion">
              <div class="card">
                <div
                  class="card-header collapsed"
                  data-toggle="collapse"
                  href="#collapseOne"
                  aria-expanded="false"
                >
                  <h4>
                    <span>
                      <img src="images/generation_information.png" />
                    </span>{" "}
                    Project &amp; Foundation
                  </h4>
                  <a
                    class="Edit"

                    onClick={()=> setEditProjectModal(true)}
              
                  >
                    <img src="images/Action-1.png" />
                  </a>
                </div>
                <div id="collapseOne" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Project</h6>
                            <p>{projectDetailsData?.projectName}</p>
                          </div>
                          <div class="Residential">
                            <h6>Foundation</h6>
                            <p>Basement Development</p>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Add-Ons</h6>
                            <div class="AddonTable">
                              <div class="Addon">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Job Id</th>
                                      <th>Job Id</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>HIL-2B-377</td>
                                      <td>
                                        Detached Garage or Workshop or Major
                                        Storage Shed
                                      </td>
                                      <td>
                                        <a href="javascript:void(0);">
                                          <img src="images/Action-1.png" />
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>HIL-2B-378</td>
                                      <td>
                                        Secondary Suite - Over Detached G arage
                                      </td>
                                      <td>
                                        <a href="javascript:void(0);">
                                          <img src="images/Action-1.png" />
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header"
                  data-toggle="collapse"
                  href="#collapseTwo"
                >
                  <h4>
                    <span>
                      <img src="images/Residential-2.png" />
                    </span>{" "}
                    Details
                  </h4>
                  <a
                    class="Edit"
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#ResidentialDetailsBasementEdit"
                    data-dismiss="modal"
                  >
                    <img src="images/Action-1.png" />
                  </a>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Home Type</h6>
                            <p>2-Storey</p>
                          </div>
                          <div class="Residential">
                            <h6>Specification Document</h6>
                            <p>Standard Specifications</p>
                          </div>
                          <div class="Residential">
                            <h6>Tax Profile</h6>
                            <p>{projectDetailsData?.TaxProfile?.name}</p>
                          </div>
                          <div class="Residential">
                            <h6>Job Site GMT (UTC) Timezone</h6>
                            <p>Moutain Time GMT - 06</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Building Permit #</h6>
                            <p>BP2023-11766</p>
                          </div>
                          <div class="Residential">
                            <h6>Base Model</h6>
                            <p>Residential Job</p>
                          </div>
                          <div class="Residential">
                            <h6>Default Currency</h6>
                            <p>CAD</p>
                          </div>
                          <div class="Residential">
                            <h6>Accounting Status</h6>
                            <h6 class="fw-400 mb-4">
                              Integrate with Accounting Software
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header"
                  data-toggle="collapse"
                  href="#collapseThree"
                >
                  <h4>
                    <span>
                      <img src="images/Residential-3.png" />
                    </span>{" "}
                    Square Foot
                  </h4>
                  <a
                    class="Edit"
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#ResidentialDetailsBasementEdit"
                    data-dismiss="modal"
                  >
                    <img src="images/Action-1.png" />
                  </a>
                </div>
                <div
                  id="collapseThree"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>SqFt Main Floor</h6>
                            <p>1,000.00</p>
                          </div>
                          <div class="Residential">
                            <h6>SqFt Third Floor</h6>
                            <p>&nbsp;</p>
                          </div>
                          <div class="Residential">
                            <h6>SqFt Garage Floor</h6>
                            <p>800.00</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>SqFt Second Floor</h6>
                            <p>1,000.00</p>
                          </div>
                          <div class="Residential">
                            <h6>Total House Square Footage</h6>
                            <p>2000</p>
                          </div>
                          <div class="Residential">
                            <h6>SqFt Basement/Lower Level</h6>
                            <p>800.00</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header"
                  data-toggle="collapse"
                  href="#collapseFour"
                >
                  <h4>
                    <span>
                      <img src="images/Residential-4.png" />
                    </span>{" "}
                    Business Address
                  </h4>
                  <a
                    class="Edit"
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#ResidentialDetailsBasementEdit"
                    data-dismiss="modal"
                  >
                    <img src="images/Action-1.png" />
                  </a>
                </div>
                <div
                  id="collapseFour"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Job Address</h6>
                            <p>Street 1</p>
                          </div>
                          <div class="Residential">
                            <h6>Lot</h6>
                            <p>&nbsp;</p>
                          </div>
                          <div class="Residential">
                            <h6>Block</h6>
                            <p>&nbsp;</p>
                          </div>
                          <div class="Residential">
                            <h6>Phase</h6>
                            <p>Insert phase</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>City</h6>
                            <p>Calgary</p>
                          </div>
                          <div class="Residential">
                            <h6>Zip/ Postal Code</h6>
                            <p>T3A T3A</p>
                          </div>
                          <div class="Residential">
                            <h6>Country</h6>
                            <p>Canada</p>
                          </div>
                          <div class="Residential">
                            <h6>State/Province</h6>
                            <p>AB</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header"
                  data-toggle="collapse"
                  href="#collapseFive"
                >
                  <h4>
                    <span>
                      <img src="images/Residential-5.png" />
                    </span>{" "}
                    Sales Information
                  </h4>
                </div>
                <div
                  id="collapseFive"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Agreement Date</h6>
                            <p>05/15/2023</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Possession Date</h6>
                            <p>05/15/2024</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Customer Purchase Price</h6>
                            <p>$450,000.00</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Sales Consultant</h6>
                            <p>Jill Sales</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Sales Type</h6>
                            <p>Model Home</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header"
                  data-toggle="collapse"
                  href="#collapseSix"
                >
                  <h4>
                    <span>
                      <img src="images/Residential-6.png" />
                    </span>{" "}
                    Estimation &amp; Construction Information
                  </h4>
                </div>
                <div
                  id="collapseSix"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Primary Estimator/Purchaser</h6>
                            <p>Jack Purchaser</p>
                          </div>
                          <div class="Residential">
                            <h6>Primary Site Super</h6>
                            <p>Joe Site</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  class="card-header"
                  data-toggle="collapse"
                  href="#collapseSeven"
                >
                  <h4>
                    <span>
                      <img src="images/Residential-7.png" />
                    </span>{" "}
                    Client Information
                  </h4>
                </div>
                <div
                  id="collapseSeven"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Personal Information</h6>
                          </div>
                          <div class="Residential">
                            <h6>Client Full Legal name</h6>
                            <p>Joe Full Client</p>
                          </div>
                          <div class="Residential">
                            <h6>Primary Phone Number</h6>
                            <p>(587)555-555</p>
                          </div>
                          <div class="Residential">
                            <h6>Email</h6>
                            <p>joe.fclient@email.com</p>
                          </div>
                          <div class="Residential">
                            <h6>Business Phone Number</h6>
                            <p>&nbsp;</p>
                          </div>
                          <div class="Residential">
                            <h6>Fax Phone Number</h6>
                            <p>&nbsp;</p>
                          </div>
                          <div class="Residential">
                            <h6>Preferred Communication</h6>
                            <p>Email</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="ResidentialBox">
                          <div class="Residential">
                            <h6>Address</h6>
                          </div>
                          <div class="Residential">
                            <h6>Street Address</h6>
                            <p>120 4th Street Sw</p>
                          </div>
                          <div class="Residential">
                            <h6>Address Line two</h6>
                            <p>Insert</p>
                          </div>
                          <div class="Residential">
                            <h6>City</h6>
                            <p>Calgary</p>
                          </div>
                          <div class="Residential">
                            <h6>Zip/Postal Code</h6>
                            <p>T3A T3A</p>
                          </div>
                          <div class="Residential">
                            <h6>Country</h6>
                            <p>Canada</p>
                          </div>
                          <div class="Residential">
                            <h6>State/Province</h6>
                            <p>Select State or Province</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="CloseButton">
              <button class="Close ClosePermissionId" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    {editProjectModal &&<EditProjectModal editProjectModal={editProjectModal} setEditProjectModal={setEditProjectModal} selectedId={selectedId} jobName={jobName} setJobDetialsModal={setJobDetialsModal} />}
    </>
  );
};

export default ProjectDetailsModal;
