
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import { toast } from "react-toastify";

const units = []


const GenerateChangeOrder = ({generateChangeOrder, setGenerateChangeOrder,setSignClientCtcModal}) => {




  return (
    <>

    <Modal show={generateChangeOrder} className="ModalBox ExtraLargeModal">
    <div class="modal-content BgCreate">
      <a
        class="CloseModal"
        onClick={()=> {
          setSignClientCtcModal(true)
          setGenerateChangeOrder(false)}}
        data-dismiss="modal"
      >
        ×
      </a>
      <div class="FormArea">
         
              <div class="FolderForm">
                <form>
                    
                  <div class="WrapperArea m-0 w-100">
                    <div class="WrapperBox  pt-0 px-0">
            
                      <div class="TitleBox">
                        <h4 class="Title">Change Order #1</h4>
                        <div class="TitleBox2 TitleBoxRight">
                          <div class="dropdown">
                            <h6 data-toggle="dropdown">UUnit3 -CHI -2B -315</h6>
                            <span>Multi-Family - Residential Unit</span>
                          </div>
                        </div>
                      </div>
              
                      
                      <div class="WarrantyDashboardForm mb-4">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <label>Description <span class="Required">*</span></label>
                                  <textarea class="form-control" rows="3" placeholder="Write here ....."></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                              <div class="form-group">
                                  <div class="CommonModalArea">
                                   <h5>Supporting Document</h5>
                                  <div class="CommonModalBox">
                                    <figure><img src="images/document-support.png"/></figure>
                                    <span class="Count CursorPointer">3</span>
                                    <h5 class="CursorPointer">
                                      Supporting Document
                                    </h5>
                                     
                                  </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="CommonModalArea">
                                   <h5>Add Notes</h5>
                                  <div class="CommonModalBox">
                                    <figure><img src="images/document-support.png"/></figure>
                                    <span class="Count CursorPointer">3</span>
                                    <h5 class="CursorPointer">
                                      Add Notes</h5> 
                                      
                                      
                                  </div>
                                  </div>
                                </div>
                          </div>
                        </div>
                      </div>
              
                      <div class="WarrantyDashboardForm">
                        <h4>Client Details</h4>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label>Possession Date</label>
                                  <input type="text" class="form-control" placeholder="Auto-filled by System"/>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label>Request Date</label>
                                  <input type="text" class="form-control" placeholder="Auto-filled by System"/>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label>Client Full Legal Name</label>
                                  <input type="text" class="form-control" placeholder="Auto-filled by System"/>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label>Phone Number</label>
                                  <input type="text" class="form-control" placeholder="Auto-filled by System"/>
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label>Primary Email</label>
                                  <input type="text" class="form-control" placeholder="Auto-filled by System"/>
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label>Job Address <img src="images/location.png" class="ml-2"/></label>
                                  <input type="text" class="form-control" placeholder="Auto-filled by System"/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
              
                            <div class="Profile_ontario">
                              <div class="Profile_ontario_box">
                                <h6>Profile:</h6>
                                <p>Ontario Tax</p>
                              </div>
                              <div class="Profile_ontario_box">
                                <h6>HST:</h6>
                                <p>13%</p>
                              </div>
                            </div>
              
                            <div class="form-group">
                              <div class="CommonModalArea">
                               <h5>Add Items from Pricebook</h5>
                              <div class="CommonModalBox">
                                <h5><figure><img src="images/add_items_from_pricebook.png" /></figure>Add Items from Pricebook</h5>
                              </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="CommonModalArea">
                               <h5>Add Custom Item</h5>
                              <div class="CommonModalBox">
                                <h5>
                                  <figure><img src="images/add_custom_item.png" /></figure>Add Custom Item</h5>
                              </div>
                              </div>
                            </div>
              
              
                            
              
                          </div>
                        </div>
                      </div>
              
                      <div class="Tableheader mt-5">
                        <h6>Change Items</h6>
                      </div>
                        <div class="TableList NewTableList TableListHeader TableHeightScroll">
                          <table>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Measure</th>
                                <th>Per Unit</th>
                                <th>Client Total</th>
                                <th>Po Line Total</th>
                                <th>Markup</th>
                                <th>Accounts</th>
                                <th>Pictures</th>
                                <th>Supporting Doc</th>
                                <th>Action</th>
                              </tr>
                              
                            </thead>
                            <tbody>
              
              
                              <tr>
                                <td>1</td>
                                <td>Sandstone Color Pallete</td>
                                <td>2</td>
                                <td>
                                  <div class="SelectAnOption">
                                    <div class="dropdown">
                                      <button class="" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Select an option
                                        <img src="images/black-down-arrow.png" />
                                      </button>
                                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <div class="DropdownItemSearch">
                                            <input type="text" placeholder="-Search" />
                                            
                                              <img src="images/search.png" />
                                            
                                          </div>
                                          <a class="dropdown-item" href="javascript:void(0);"><img src="images/add-plus.png" />Add New</a>
                                          <a class="dropdown-item" href="javascript:void(0);">-Warranty</a>
                                          <a class="dropdown-item" href="javascript:void(0);">-   Full list of units 
                                            loaded from 
                                            the system</a>
                                        </div>
                                    </div>
                                  </div>
                                </td>
                                <td>$1,000.00</td>
                                <td>$1,000.00</td>
                                <td>$1,000.00</td>
                                <td>20 %</td>
                                <td>
                                  <div class="SelectAnOption">
                                    <div class="dropdown">
                                      <button class="" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Select an option
                                        <img src="images/black-down-arrow.png" />
                                      </button>
                                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <div class="DropdownItemSearch">
                                            <input type="text" placeholder="-Search" />
                                            
                                              <img src="images/search.png" />
                                            
                                          </div>
                                          <div class="TableCheckBoxArea">
                                            <label class="CheckBox"> List of Cost Codes loaded by the system
                                              <input type="checkbox" />
                                              <span class="checkmark"></span>
                                          </label>
                                          </div>
                                          
                                          
                                        </div>
                                    </div>
                                  </div>
                                </td>
                                <td><figure><img src="images/picture-11.png" /></figure></td>
                                <td>
                                  <div class="Actions">
                                        <a href="javascript:void(0);"><img src="images/icon-63.png" /></a>
                                        <a href="javascript:void(0)"><span class="Count">3</span></a>
                                    </div>
                                </td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown ">
                                        <a href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li><a href="javascript:void(0);"><img src="images/update_price_book.png" />Update Price Book's Entry</a></li>
                                          <li><a href="javascript:void(0);"><img src="images/dropdown-delete.png" />Remove item</a></li>
                                        </ol>
                                      </li>
                                    </ul>
                                   </div>
                                </td>
                                
                              </tr>
              
              
              
              
                              <tr>
                                <td>2</td>
                                <td></td>
                                <td></td>
                                <td>
                                  <div class="SelectAnOption">
                                    <div class="dropdown">
                                      <button class="" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Select an option
                                        <img src="images/black-down-arrow.png" />
                                      </button>
                                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <div class="DropdownItemSearch">
                                            <input type="text" placeholder="-Search" />
                                            
                                              <img src="images/search.png" />
                                            
                                          </div>
                                          <a class="dropdown-item" href="javascript:void(0);"><img src="images/add-plus.png" />Add New</a>
                                          <a class="dropdown-item" href="javascript:void(0);">-Warranty</a>
                                          <a class="dropdown-item" href="javascript:void(0);">-   Full list of units 
                                            loaded from 
                                            the system</a>
                                        </div>
                                    </div>
                                  </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <div class="SelectAnOption">
                                    <div class="dropdown">
                                      <button class="" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Select an option
                                        <img src="images/black-down-arrow.png" />
                                      </button>
                                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <div class="DropdownItemSearch">
                                            <input type="text" placeholder="-Search" />
                                            
                                              <img src="images/search.png" />
                                            
                                          </div>
                                          <div class="TableCheckBoxArea">
                                            <label class="CheckBox"> List of Cost Codes loaded by the system
                                              <input type="checkbox" />
                                              <span class="checkmark"></span>
                                          </label>
                                          </div>
                                          
                                          
                                        </div>
                                    </div>
                                  </div>
                                </td>
                                <td><figure><img src="images/picture_icon.png" /></figure></td>
                                <td>
                                </td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a class="" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li><a href="javascript:void(0);"><img src="images/update_price_book.png" />Update Price Book's Entry</a></li>
                                          <li><a href="javascript:void(0);"><img src="images/dropdown-delete.png" />Remove item</a></li>
                                        </ol>
                                      </li>
                                    </ul>
                                   </div>
                                </td>
                                
                              </tr>
              
                              <tr>
                                <td colspan="12">
                                  <div class="TableButtons">
                                    <button class="BrandBtn">Add Item</button>
                                    <button class="BrandBtn">Clear Table</button>
                                  </div>
                                </td>
                              </tr>
              
              
              
              
                           
              
                              
                              
                
                                
                            </tbody>
                          </table>
                        </div>
                       
                        <div class="ClientContactArea mt-5">
                          <div class="ClientContactTitle ClientContactTitle1">
                            <h4>Client Contact</h4>
                            <div class="DiscussionChat">
                              <img src="images/down.png" class="DiscussionIcon" />
                            </div>
                          </div>
                          <div class="ClientContactHideArea ClientContactHideArea1">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-5">
                                    <div class="UrgencyArea mb-5">
                                      <div class="form-group">
                                        <label>Builder Signee</label>
                                        <div class="Categories Categories1">
                                          <div class="Categories_all Categories_all1">
                                            <span>Select Builder Signee</span>
                                            <img src="images/down.png" />
                                          </div>
                                          <ul>
                                            <li>List of employees that are authorized to sign documents. Text Format: Employee's name(Employee's email)</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5 ml-auto">
                                    <div class="FolderForm">
                                      <div class="form-group">
                                        <h6>Client Signees</h6>
                                        <h6>Number of Signees</h6>
                                        <input type="text" class="form-control" placeholder="Insert Number of Signees" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">
                                    <div class="UrgencyArea">
                                      <div class="form-group">
                                        <label>Primary Client Signee</label>
                                        <div class="Categories Categories1">
                                          <div class="Categories_all Categories_all1">
                                            <span>Select Builder Signee</span>
                                            <img src="images/down.png" />
                                          </div>
                                          <ul>
                                            <li>List of employees that are authorized to sign documents. Text Format: Employee's name(Employee's email)</li>
                                            <li><span class="AddNew">+</span>Add New</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5 ml-auto">
                                    <div class="UrgencyArea">
                                      <div class="form-group">
                                        <label>Additional Client Signee</label>
                                        <div class="Categories Categories1">
                                          <div class="Categories_all Categories_all1">
                                            <span>Select Builder Signee</span>
                                            <img src="images/down.png" />
                                          </div>
                                          <ul>
                                            <li>List of employees that are authorized to sign documents. Text Format: Employee's name(Employee's email)</li>
                                            <li><span class="AddNew">+</span>Add New</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                              <div class="FolderForm">
                                <div class="form-group">
                                  <h6>Request Adjustments Function</h6>
                                <label class="CheckBox">
                                  <input type="checkbox" />
                                  <span class="checkmark"></span>
                              </label>
                                </div>
                              </div>
                              </div>
                            </div>
                            <div class="Button my-4">
                               <button class="Create">Generate/Update Agreement</button>
                            </div>
                          </div>
              
              
                          
                        </div>
              
              
                        <div class="ClientContactArea">
                          <div class="ClientContactTitle ClientContactTitle2">
                            <h4>Agreement Document</h4>
                            <div class="DiscussionChat">
                              <img src="images/down.png" class="DiscussionIcon" />
                            </div>
                          </div>
                          <div class="ClientContactHideArea ClientContactHideArea2">
                            <div class="FolderForm">
                              
                                <div class="row">
                                  <div class="form-group col-md-6 mb-4">
                                    <div class="CommonModalArea">
                                     <h5>Agreement Document</h5>
                                    <div>
                                      <figure class="mb-0"><img src="images/agreement_document.png" /></figure>
                                        <span class="Count CursorPointer">3</span>
                                        <h5 class="ml-2"> Agreement Document</h5>
                                    </div>
                                    </div>
                                  </div>
                    
                                  
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="ModalFilterAreaBox">
                                      <h4 class="mb-4">Client Acknowledgement of Change Contract
                                      </h4>
                                      <div class="RadioArea">
                                       
                                            <div class="form-group">
                                              <label class="Radio">Electronic
                                                <input type="radio" name="Cuisine" value="text" checked=""/>
                                                <span class="checkmark"></span>
                                              </label>
                                            </div>
                          
                                            <div class="form-group ml-5">
                                              <label class="Radio">Hardcopy
                                                <input type="radio" name="Cuisine" value="file"/>
                                                <span class="checkmark"></span>
                                              </label>
                                            </div>
                                      </div>
                                  
                                         
                                    </div>
              
              
              
                              
                  
                                    <div class="file RadioText">
                                      <div class="row">
                                        <div class="form-group col-md-6">
                                          <div class="CommonModalArea">
                                           <h5>Download Hardcopy Agreement</h5>
                                          <div class="CommonModalBox">
                                            <figure class="mb-0"><img src="images/agreement_document.png" /></figure>
                                              <h5 class="ml-2"> Download Hardcopy Agreement</h5>
                                          </div>
                                          </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                          <div class="CommonModalArea">
                                           <h5>Upload Signed Agreement</h5>
                                          <div class="CommonModalBox">
                                            <figure class="mb-0"><img src="images/agreement_document.png" /></figure>
                                              <h5 class="ml-2"> Download Hardcopy Agreement</h5>
                                          </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
              
              
              
                                  </div>
                                  
                                    
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group mt-4">
                                      <h6>Read-Only Carbon Copies</h6>
                                      <div class="ReadOnlyCarbonCopies">
                                        <h6 class="mb-0">1</h6>
                                      <input type="text" class="form-control" placeholder="Auto-filled by System" />
                                      <input type="text" class="form-control" placeholder="Auto-filled by System" />
                                      <span class="cross"><img src="images/cancel_icon.png" /></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
              
                                <div>
                                  <button class="AddCopy">Add Copy</button>
                                </div>
                                
                              
                            </div>
              
              
                            
              
                            
              
              
                          </div>    
                          <div class="SaveDraftBtn d-flex justify-content-between mt-5">
                            <button class="BrandBtn" data-toggle="modal" data-target="#TwoSignClientCtcThird" data-dismiss="modal">Cencel</button>
                            <div class="d-flex ">
                              <button class="BrandBtnGreen">Save draft</button>
                              <div class="dropdown ml-3">
                                <button class="BrandBtnGreen" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Save and <img src="images/down-arrow-white.png" />
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a class="dropdown-item" href="javascript:void(0);">Save and Print</a>
                                  <a class="dropdown-item" href="javascript:void(0);">Save and E-Sign</a>
                                  <a class="dropdown-item" href="javascript:void(0);">Save and Authorize</a>
                                </div>
                              </div>
                            </div>
                          </div>        
                        </div>
              
              
                        
              
              
              
              
                    </div>
                  </div></form>
      
      
                
              </div>
            </div>
    </div>
  </Modal>



 

  </>

  


)
}

export default GenerateChangeOrder