import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCitiesList,
  getCountriesList,
  getStateList,
  getTimeZoneList,
} from "../redux/action/authAction";
import { checkArray } from "../utils/CheckType";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  FoundationListListAction,
  ProformaTypesListAction,
  addProformaAction,
  addProformaBlockStep1Failure,
  addProformaBlockStep1aAction,
} from "../redux/action/proformaAction";
import { toast } from "react-toastify";
import {
  companyDetailsAction,
  createCompanyAction,
} from "../redux/action/companyAction";
import jobReducer from "../redux/reducer/jobReducer";
import { errorToast, sucessToast } from "../utils/toast";
import {
  createAllTaxProfileAction,
  getAllTaxProfileAction,
} from "../redux/action/jobAction";
import { clientViewConfigAction } from "../redux/actionTypes";
import { Editor } from "@tinymce/tinymce-react";
import { init, monthsData } from "../utils/uploadFile";
import { findAllTaskFailure } from "../redux/action/constructionsAction";
import { uploadAggrementFiles } from "../redux/action/designSelectionsAction";
const initModal = {
  generalStateModal: false,
  bussinessAddressModal: false,
  accountingModal: false,
  taxModal: false,
  costProfilesModal: false,
  letterHeaderModal: false,
  confirmationModal: false,
  HeaderPreviewModal: false,
  modalState: "",
  secondaryId: "",
  modalId: "",
  modalManagementState: "",
};

const initLeadState = {
  name: "",
  email: "",
  primaryNumber: "",
  website: "",
  logo: null,
  motto: "",
  displayLogo: false,
  displayMotto: false,
  displayName: false,
  streetAddressLine1: "",
  streetAddressLine2: "",
  zipCode: "",
  country: "",
  province: "",
  city: "",
  taxRegistration: "",
  jobNumberPattern: "",
  fiscalYearEnd: "",
  quickBooks: false,
  letterHeader: "",
  sage: false,
  curStep: "",
  costCodeProfileType: false,
  residentialProfile: "",
  multiFamilyProfile: "",
  landDevelopmentProfile: "",
  companyErrors: {},
};
const iniialState = {
 
  isRebate: false,
  taxRebates: [
    {
      minRange: 0,
      maxRange: 0,
      appliedAss: "",
      amount: 0,
    },
  ],
  taxRows: [
    {
      taxName: "",
      taxPercent: 0,
    },
  ],
};
const CompanyModal = ({ companyModal, setCompanyModal, setShow }) => {
  const [iModalState, updateModalState] = useState(initModal);
  const [iLeadState, updateLeadState] = useState(initLeadState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const { companyDetailData } = useSelector((state) => state.jobReducer);


  const [subDisvisionState, setsubDivisonState] = useState("");
  const [subDivisonFlag, setsubDivisonFlag] = useState(false);
  const [tempIndex, setTempIndex] = useState("")
  const[subdivisions, setSubdivisions] = useState([])
  const [editFlag, setEditFlag] =useState(false)


  const handlePhoneInputChange = (value, country) => {
    updateLeadState({
      ...iLeadState,
      phonecode: `+${country.dialCode}${value}`,
    });
  };
  const handlHeaderPreviewModalClose = () => {
    updateModalState((prev) => ({
      ...prev,
      HeaderPreviewModal: false,
      letterHeaderModal: true,
    }));
  };
  const handlHeaderPreviewModalShow = () => {
    updateModalState((prev) => ({
      ...prev,
      HeaderPreviewModal: true,
      letterHeaderModal: false,
    }));
  };
  const {
    generalStateModal,
    bussinessAddressModal,
    accountingModal,
    taxModal,
    costProfilesModal,
    letterHeaderModal,
    confirmationModal,
    modalState,
    secondaryId,
    modalId,
    modalManagementState,
    HeaderPreviewModal,
  } = iModalState;
  console.log({ iBuildLocalData });
  const dateDropdown = [];
  let currentYear = new Date().getFullYear();
  let earliestYear = currentYear - 25;
  while (currentYear > earliestYear) {
    dateDropdown.push(currentYear);
    currentYear -= 1;
  }

  const {
    name,
    email,
    primaryNumber,
    website,
    logo,
    motto,
    displayLogo,
    displayMotto,
    displayName,
    streetAddressLine1,
    streetAddressLine2,
    zipCode,
    country,
    province,
    city,
    taxRegistration,
    jobNumberPattern,
    fiscalYearEnd,
    quickBooks,
    letterHeader,
    curStep,
    costCodeProfileType,
    residentialProfile,
    multiFamilyProfile,
    landDevelopmentProfile,
    companyErrors,
    sage,
    phonecode,
  } = iLeadState;
  const { countryData } = useSelector((state) => state.authReducer);

  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  useEffect(() => {
    if (!companyModal) return;

    const fetchCompanyDetails = async () => {
      try {
        const data = await dispatch(
          companyDetailsAction(iBuildLocalData?.user?.CompanyId)
        );
        const company = data?.company;

        if (!company) return;

        const {
          name,
          email,
          primaryNumber,
          website,
          logo,
          motto,
          displayLogo,
          displayMotto,
          displayName,
          streetAddressLine1,
          streetAddressLine2,
          zipCode,
          country,
          province,
          city,
          taxRegistration,
          jobNumberPattern,
          fiscalYearEnd,
          quickBooks,
          letterHeader,
          curStep,
          costCodeProfileType,
          residentialProfile,
          multiFamilyProfile,
          landDevelopmentProfile,
          companyErrors,
          sage,
          phoneCountryCode,
        } = company;

        updateLeadState((prev) => ({
          ...prev,
          name,
          email,
          primaryNumber,
          website,
          logo,
          motto,
          displayLogo,
          displayMotto,
          displayName,
          streetAddressLine1,
          streetAddressLine2,
          zipCode,
          country,
          province,
          city,
          taxRegistration,
          jobNumberPattern,
          fiscalYearEnd,
          quickBooks,
          letterHeader,
          curStep,
          costCodeProfileType,
          residentialProfile,
          multiFamilyProfile,
          landDevelopmentProfile,
          companyErrors,
          sage,
          phonecode: phoneCountryCode,
        }));

        const coun = await dispatch(getCountriesList());
        if (!coun) return;

        const countryIsoCode = coun?.countries?.find(
          (x) => x.name === country
        )?.isoCode;

        if (countryIsoCode) {
          await dispatch(getStateList(countryIsoCode)).then((stateNewData) => {
            setStateData(stateNewData?.states);
            const provinceIsoCode = stateNewData?.states?.find(
              (x) => x.name === province
            )?.isoCode;
            if (countryIsoCode && provinceIsoCode) {
              dispatch(getCitiesList(countryIsoCode, provinceIsoCode)).then(
                (cityNewData) => setCityData(cityNewData?.cities)
              );
            }
          });
        }
      } catch (error) {
        console.error(
          "Error fetching company details or country/state/city lists:",
          error
        );
      }
    };

    fetchCompanyDetails();
  }, [companyModal]);

  useEffect(() => {
    updateModalState((prev) => ({ ...prev, generalStateModal: true }));
  }, []);

  const hideMainCreateModal = () => {
    updateModalState((prev) => ({
      ...prev,
      generalStateModal: true,
      bussinessAddressModal: false,
    }));
  };

  const [taxData, setTaxData] = useState(iniialState);
  const [defaultCheck, setDefaultCheck] = useState(false);
  const [activeCheck, setActiveCheck] = useState(false);
  const {
    isRebate,
    taxRebates,
    taxRows,
    CompanyId,
  } = taxData;

  

  const mainModal = () => {
    setShow(true);
    setCompanyModal(false);
  };

  const handleInput = (e) => {
    
    const { name, value} = e.target;
    if (name == "country") {
      dispatch(
        getStateList(
          countryData?.countries?.find((x) => x.name == value)?.isoCode
        )
      ).then((stateNewData) => {
        setStateData(stateNewData?.states);
      });
      updateLeadState({ ...iLeadState, [name]: value, province: "", city: "" });
    }
     else if (name === "province") {
      dispatch(
        getCitiesList(
          countryData?.countries?.find((x) => x.name == country)?.isoCode,
          stateData?.find((x) => x.name == value)?.isoCode
        )
      ).then((cityNewData) => setCityData(cityNewData?.cities));
      updateLeadState({ ...iLeadState, [name]: value });
    } 
    else  {
        updateLeadState({ ...iLeadState, [name]: value });
      }
    }



  const handleInputFile = (e) => {
    const { name } = e?.target;
    const file = e.target.files[0];

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        const imagePreview = [URL?.createObjectURL(file)];
        updateLeadState((prevState) => ({
          ...prevState,
          [name]: imagePreview,
          imagePreview,
        }));
      } else {
        updateLeadState((prevState) => ({
          ...prevState,
          [name]: file,
        }));
      }
    }
  };

  useEffect(() => {
    if (taxModal) {
      dispatch(getAllTaxProfileAction()).then((taxData) => {
        const {
          name,
          activeCheck,
          defaultCheck,
          isRebate,
          province,
          taxRebates,
          taxRows,
          CompanyId,
        } = taxData?.[0];
        setTaxData({
          isRebate,
          province,
          CompanyId,
          taxRebates: taxRebates?.map((item) => ({
            minRange: item?.minRange,
            maxRange: item?.maxRange,
            appliedAss: item?.appliedAss,
            amount: item?.amount,
          })),
          taxRows: taxRows?.map((item) => ({
            taxName: item?.taxName,
            taxPercent: item?.taxPercent,
          })),
        });
        setActiveCheck(activeCheck)
        setActiveCheck(defaultCheck)
      });
    }
  }, []);

  console.log("activeCheck",activeCheck);
  console.log("defaultCheck",defaultCheck);

  const handleRadio = (e) => {
    const { name, value } = e.target;
    updateLeadState({
      ...iLeadState,
      name: value === name,
    });
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    updateLeadState({
      ...iLeadState,
      [name]: checked,
    });
  };

  const handleTaxInput = (e, i) => {
    const { name, value } = e.target;
    let user = [...taxRows];
    user[i] = { ...user[i], [name]: value };
    setTaxData({ ...taxData, taxRows: user });
  };
  const handleRebelChange = (e, i) => {
    const { name, value } = e.target;
    let user = [...taxRebates];
    user[i] = { ...user[i], [name]: value };
    setTaxData({ ...taxData, taxRebates: user });
  };
  const handleTaxRemove = (id) => {
    let user = [...taxRows];
    user.splice(id, 1);
    setTaxData({
      ...taxData,
      taxRows: user,
    });
  };
  const handleRebelRemove = (id) => {
    let user = [...taxRebates];
    user.splice(id, 1);
    setTaxData({
      ...taxData,
      taxRebates: user,
    });
  };
  const handleTaxCheck = (e) => {
    const { name, checked } = e.target;
    setTaxData({
      ...taxData,
      [name]: checked,
    });
  };
  //=================ADD NEW LINE=================
  const Addmore = (type) => {
    if (type == "taxRow") {
      setTaxData({
        ...taxData,
        taxRows: [...taxRows, { taxName: "", taxPercent: 0 }],
      });
    } else if (type == "taxRebel") {
      setTaxData({
        ...taxData,
        taxRebates: [
          ...taxRebates,
          {
            minRange: 0,
            maxRange: 0,
            appliedAss: "",
            amount: 0,
          },
        ],
      });
    }
  };

  const showbussinessAddressModal = () => {
    dispatch(companyDetailsAction(iBuildLocalData?.user?.CompanyId));
    updateModalState((prev) => ({
      ...prev,
      generalStateModal: false,
      bussinessAddressModal: true,
    }));
  };

  const hidebussinessAddressModal = () => {
    updateModalState({
      ...iModalState,
      bussinessAddressModal: false,
      generalStateModal: true,
    });
  };

  const showAccountingModal = () => {
    updateModalState({
      ...iModalState,
      bussinessAddressModal: false,
      accountingModal: true,
    });
    // setCompanyModal(false)
  };

  const hideAccountingModal = () => {
    updateModalState((prev) => ({
      ...prev,
      accountingModal: false,
      bussinessAddressModal: true,
    }));
  };

  const showTaxModal = () => {
    updateModalState({
      ...iModalState,
      accountingModal: false,
      taxModal: true,
    });
    // setCompanyModal(false)
  };

  const hideTaxModal = () => {
    updateModalState({
      ...iModalState,
      accountingModal: true,
      taxModal: false,
    });
  };

  const showCostProfilesModal = () => {
    updateModalState({
      ...iModalState,
      taxModal: false,
      costProfilesModal: true,
    });
    // setCompanyModal(false)
  };

  const hideCostProfilesModal = () => {
    updateModalState((prev) => ({
      ...prev,
      costProfilesModal: false,
      taxModal: true,
    }));
  };

  const showLetterHeaderModal = () => {
    updateModalState({
      ...iModalState,
      costProfilesModal: false,
      letterHeaderModal: true,
    });
  };
  const hideLetterHeaderModal = () => {
    updateModalState((prev) => ({
      ...prev,
      letterHeaderModal: false,
      costProfilesModal: true,
    }));
  };

  const showConfirmationModal = () => {
    dispatch(companyDetailsAction(iBuildLocalData?.user?.CompanyId));
    updateModalState({
      ...iModalState,
      letterHeaderModal: false,
      confirmationModal: true,
    });
  };

  const hideConfirmationModal = () => {
    updateModalState((prev) => ({
      ...prev,
      confirmationModal: false,
      letterHeaderModal: true,
    }));
  };

  const addCompanyFunc = (e, curStep) => {
    e.preventDefault();
    dispatch(
      createCompanyAction(iBuildLocalData?.user?.CompanyId, {
        costCodeProfileType: "System Default Cost Codes",
        curStep,
        name,
        email,
        primaryNumber,
        website,
        logo,
        motto,
        displayLogo,
        displayMotto,
        displayName,
        streetAddressLine1,
        streetAddressLine2,
        zipCode,
        country,
        province,
        city,
        taxRegistration,
        jobNumberPattern,
        fiscalYearEnd,
        quickBooks,
        sage,
        letterHeader,
        curStep,
        residentialProfile,
        multiFamilyProfile,
        landDevelopmentProfile,
        phoneCountryCode: phonecode,
      })
    )
      .then((res) => {
        if (curStep == 1) {
          showbussinessAddressModal();
        } else if (curStep == 2) {
          showAccountingModal();
        } else if (curStep == 3) {
          showTaxModal();
        } else if (curStep === 4) {
          showCostProfilesModal();
        } else if (curStep === 5) {
          showLetterHeaderModal();
        } else if (curStep === 6) {
          showConfirmationModal();
        }
        else {
          updateModalState({
            ...iModalState,
            confirmationModal:false,
          })
          toast.success("Comapny Created Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  };

  const handleValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!name) {
      error.nameError = "Name can't be empty";
      formIsValid = false;
    }

    if (!email) {
      error.emailError = "Email can't be empty";
      formIsValid = false;
    }

    if (!primaryNumber) {
      error.primaryNumberError = "Primary number can't be empty";
      formIsValid = false;
    }

    if (!name) {
      error.nameError = "Name can't be empty";
      formIsValid = false;
    }

    if (!email) {
      error.emailError = "Email can't be empty";
      formIsValid = false;
    }

    if (!primaryNumber) {
      error.primaryNumberError = "Primary number can't be empty";
      formIsValid = false;
    }
  };

  const showModalFunc = (modal, falseModal) => {
    updateModalState({
      ...iModalState,
      [falseModal]: false,
      [modal]: true,
    });
  };
  const handleCreateTaxProfile = () => {
    dispatch(createAllTaxProfileAction(taxData)).then((res) => {
      if (res?.message === "Success") {
        dispatch(companyDetailsAction(iBuildLocalData?.user?.CompanyId));
        toast.success("Tax Create Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const editorsRef = useRef(null);

  const handleEditorChange = (newContent) => {
    updateLeadState({ ...iLeadState, letterHeader: newContent });
  };



  const [file, setFile] = useState(null); 
  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  };

  const handleUploadFiles = async () => {
    if (!file) {
      toast.error("No file selected", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const formData = new FormData();
    formData.append("files", file); 

    try {
      const uploadResponse = await dispatch(uploadAggrementFiles(formData))
      const imgTag = `<img src="${uploadResponse?.data?.data}" height=10% width=20% alt="Signature Image" />`;
updateLeadState({...iLeadState,letterHeader:imgTag})
      toast.success("File uploaded successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };



  
  const handleaddSubDivision = (e) => {
    e.preventDefault();

 

    

    setSubdivisions([...subdivisions, subDisvisionState])

    setsubDivisonState("");
  };

  const handleDeleteSubDivison = (e, i) => {
    e.preventDefault();

    let temArray = [...subdivisions];

    temArray.splice(i, 1);

   

    setSubdivisions(temArray)
  };


  const handleUpdateSubDivison = (e) => {
    e.preventDefault();

    let temArray = [...subdivisions];
    temArray[tempIndex] = subDisvisionState


 


    setSubdivisions(temArray)

    setEditFlag(false)
    setsubDivisonState('')

  };



  return (
    <>
      <Modal
        show={generalStateModal}
        onHide={hideMainCreateModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={mainModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Company Setup</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span className="Icon">
                    <img src="images/step_1.png" />
                  </span>
                  <span className="Text">
                    General <br /> Information
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc(
                        "bussinessAddressModal",
                        "generalStateModal"
                      );
                    }}
                  >
                    <img src="images/step_2.png" />
                  </span>
                  <span className="Text">
                    Business <br /> Address
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("accountingModal", "generalStateModal");
                    }}
                  >
                    <img src="images/step_3.png" />
                  </span>
                  <span className="Text">Accounting</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("taxModal");
                    }}
                  >
                    <img src="images/step_4.png" />
                  </span>
                  <span className="Text">Tax</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("costProfilesModal", "generalStateModal");
                    }}
                  >
                    <img src="images/step_6.png" />
                  </span>
                  <span className="Text">
                    Cost <br /> Profiles
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("letterHeaderModal", "generalStateModal");
                    }}
                  >
                    <img src="images/step_6.png" />
                  </span>
                  <span className="Text">
                    Letter <br /> Header
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("confirmationModal", "generalStateModal");
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea">
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <h6>
                        Name <span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Company name"
                        name="name"
                        value={name}
                        defaultValue={iBuildLocalData?.companies[0]?.name}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {companyErrors?.nameError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>
                        Email <span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Company's email address"
                        name="email"
                        value={email}
                        defaultValue={iBuildLocalData?.companies[0]?.email}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {companyErrors?.emailError}
                      </span>
                    </div>

                    <div className="form-group">
                      <h6>Country Code</h6>
                      <PhoneInput
                        inputClass="CountryCodeInput"
                        countryCodeEditable={false}
                        enableSearch
                        //  value={iBuildLocalData?.companies[0]?.phoneCountryCode}
                        value={phonecode}
                        onChange={handlePhoneInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <h6>
                        Primary Phone Number <span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Company's primary phone number"
                        name="primaryNumber"
                        value={primaryNumber}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {companyErrors?.primaryNumberError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>Website</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Company's website"
                        name="website"
                        value={website}
                        defaultValue={iBuildLocalData?.companies[0]?.website}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {companyErrors?.websiteError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group">
                      <h6>Logo</h6>
                      <div className="Upload">
                        {logo ? (
                          <>
                            <img src={logo ? logo : ""} />
                            <input
                              type="file"
                              name="logo"
                              onChange={handleInputFile}
                            />
                          </>
                        ) : (
                          <>
                            <input
                              type="file"
                              name="logo"
                              onChange={handleInputFile}
                            />
                            <span>-Drag and Drop Files or click to select</span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Motto</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Motto"
                        name="motto"
                        value={motto}
                        defaultValue={iBuildLocalData?.companies[0]?.motto}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {companyErrors?.mottoError}
                      </span>
                    </div>
                    <div className="DisplayLoginOnPage">
                      <div className="form-group">
                        <h6>Display on Login Page</h6>
                        <div className="mb-2">
                          <label className="CheckBox">
                            {" "}
                            Name
                            <input
                              type="checkbox"
                              name="displayName"
                              checked={displayName}
                              onChange={handleCheckbox}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="mb-2">
                          <label className="CheckBox">
                            {" "}
                            Logo
                            <input
                              type="checkbox"
                              value={displayLogo}
                              name="displayLogo"
                              checked={displayLogo}
                              onChange={handleCheckbox}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div>
                          <label className="CheckBox">
                            {" "}
                            Motto
                            <input
                              type="checkbox"
                              value={displayMotto}
                              name="displayMotto"
                              checked={displayMotto}
                              onChange={handleCheckbox}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button className="Outline" onClick={hideMainCreateModal}>
                  Cancel
                </button>
                <button
                  className="Create FolderPermissionId"
                  onClick={(e) => addCompanyFunc(e, 1)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={bussinessAddressModal}
        onHide={hidebussinessAddressModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={mainModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Company Setup</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li
                  onClick={() => {
                    showModalFunc("generalStateModal", "bussinessAddressModal");
                  }}
                >
                  <span className="Icon">
                    <img src="images/step_1.png" />
                  </span>
                  <span className="Text">
                    General <br /> Information
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("bussinessAddressModal");
                    }}
                  >
                    <img src="images/step_2.png" />
                  </span>
                  <span className="Text">
                    Business <br /> Address
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("accountingModal", "bussinessAddressModal");
                    }}
                  >
                    <img src="images/step_3.png" />
                  </span>
                  <span className="Text">Accounting</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("taxModal", "bussinessAddressModal");
                    }}
                  >
                    <img src="images/step_4.png" />
                  </span>
                  <span className="Text">Tax</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc(
                        "costProfilesModal",
                        "bussinessAddressModal"
                      );
                    }}
                  >
                    <img src="images/step_6.png" />
                  </span>
                  <span className="Text">
                    Cost <br /> Profiles
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc(
                        "letterHeaderModal",
                        "bussinessAddressModal"
                      );
                    }}
                  >
                    <img src="images/step_6.png" />
                  </span>
                  <span className="Text">
                    Letter <br /> Header
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc(
                        "confirmationModal",
                        "bussinessAddressModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea">
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <h6>
                        Street Address <span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert street address"
                        name="streetAddressLine1"
                        value={streetAddressLine1}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {companyErrors?.streetAddressLine1Error}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>
                        Street Address Line Two{" "}
                        {/* <span className="required">*</span> */}
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert full Address"
                        name="streetAddressLine2"
                        value={streetAddressLine2}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {companyErrors?.streetAddressLine2Error}
                      </span>
                    </div>
                    {/* <div className="form-group">
                      <h6>
                        City <span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert City Name"
                        name="city"
                        value={city}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {companyErrors?.cityError}
                      </span>
                    </div> */}
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group">
                      <h6>
                        Zip/ Postal Code <span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert zip code"
                        name="zipCode"
                        value={zipCode}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {companyErrors?.zipCodeError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>
                        Country <span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="country"
                          value={country}
                          onChange={handleInput}
                        >
                          <option value="">Select Country</option>
                          {checkArray(countryData?.countries) ? (
                            <>
                              {countryData?.countries?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <span></span>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>
                        State/Province<span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="province"
                          value={province}
                          onChange={handleInput}
                        >
                          <option value="">Select State</option>
                          {stateData?.map((ele, i) => {
                            return (
                              <option
                                value={ele?.name}
                                selected={ele?.name == province}
                              >
                                {ele?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <h6>
                        City<span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select name="city" value={city} onChange={handleInput}>
                          <option value="">Select City</option>

                          {cityData?.map((ele, i) => {
                            return (
                              <option value={ele?.name}>{ele?.name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={() => {
                    updateModalState((prev) => ({
                      ...prev,
                      generalStateModal: true,
                    }));
                  }}
                >
                  Back
                </button>
                <button
                  className="Create"
                  onClick={(e) => addCompanyFunc(e, 2)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={accountingModal}
        onHide={hideAccountingModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={mainModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Company Setup</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li
                  onClick={() => {
                    showModalFunc("generalStateModal", "accountingModal");
                  }}
                >
                  <span className="Icon">
                    <img src="images/step_1.png" />
                  </span>
                  <span className="Text">
                    General <br /> Information
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("bussinessAddressModal", "accountingModal");
                    }}
                  >
                    <img src="images/step_2.png" />
                  </span>
                  <span className="Text">
                    Business <br /> Address
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("accountingModal");
                    }}
                  >
                    <img src="images/step_3.png" />
                  </span>
                  <span className="Text">Accounting</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("taxModal", "accountingModal");
                    }}
                  >
                    <img src="images/step_4.png" />
                  </span>
                  <span className="Text">Tax</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("costProfilesModal", "accountingModal");
                    }}
                  >
                    <img src="images/step_6.png" />
                  </span>
                  <span className="Text">
                    Cost <br /> Profiles
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("letterHeaderModal", "accountingModal");
                    }}
                  >
                    <img src="images/step_6.png" />
                  </span>
                  <span className="Text">
                    Letter <br /> Header
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("confirmationModal", "accountingModal");
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea">
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h6>
                      Select Accounting Software
                      <span className="required">*</span>
                    </h6>
                    <div className="form-group">
                      <label className="Radio">
                        {" "}
                        <img src="images/quickbooks.png" />
                        <input
                          type="radio"
                          value={"quickBooks"}
                          checked={quickBooks}
                          // onChange={handleRadio}
                        />
                        <span className="checkmark mt-2" />
                      </label>
                    </div>

                    <div className="form-group">
                      <label className="Radio">
                        {" "}
                        <img src="images/sage.png" />
                        <input
                        // type="radio"
                        // value={"sage"}
                        // checked={sage}
                        // onChange={handleRadio}
                        />
                        <span className="checkmark mt-2" />
                      </label>
                    </div>
                    <div className="form-group">
                      <h6>Fiscal Year End</h6>
                      <div className="Categories Categories1">
                        {/* <div className="Categories_all Categories_all1">
                          <span>Select month</span>
                          <img src="images/down.png" />
                        </div> */}
                        <select
                          name="fiscalYearEnd"
                          value={fiscalYearEnd}
                          onChange={handleInput}
                          className="form-control"
                        >
                          <option value="">Select Fiscal Year End</option>
                          {/* {dateDropdown?.map((ele, i) => (
                            <option value={ele} selected={ele == fiscalYearEnd}>
                              {ele}
                            </option>
                          ))} */}
                          {monthsData?.map((ele, i) => (
                            <option
                              value={ele?.name}
                              selected={ele.name == fiscalYearEnd}
                            >
                              {ele?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button className="Outline" onClick={hideAccountingModal}>
                  Back
                </button>
                <button
                  className="Create"
                  onClick={(e) => addCompanyFunc(e, 3)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={taxModal}
        onHide={hideTaxModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={mainModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Company Setup</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li
                  onClick={() => {
                    showModalFunc("generalStateModal", "taxModal");
                  }}
                >
                  <span className="Icon">
                    <img src="images/step_1.png" />
                  </span>
                  <span className="Text">
                    General <br /> Information
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("bussinessAddressModal", "taxModal");
                    }}
                  >
                    <img src="images/step_2.png" />
                  </span>
                  <span className="Text">
                    Business <br /> Address
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("accountingModal", "taxModal");
                    }}
                  >
                    <img src="images/step_3.png" />
                  </span>
                  <span className="Text">Accounting</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("taxModal");
                    }}
                  >
                    <img src="images/step_4.png" />
                  </span>
                  <span className="Text">Tax</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("costProfilesModal", "taxModal");
                    }}
                  >
                    <img src="images/step_6.png" />
                  </span>
                  <span className="Text">
                    Cost <br /> Profiles
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("letterHeaderModal", "taxModal");
                    }}
                  >
                    <img src="images/step_6.png" />
                  </span>
                  <span className="Text">
                    Letter <br /> Header
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("confirmationModal", "taxModal");
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea">
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>
                        1. Tax Registration<span className="required">#</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Tax information"
                        name="taxRegistration"
                        value={taxRegistration}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <h6 className="mb-1">2. Create your task profile</h6>
                      <h6>Tax Location</h6>
                      <div className="CommonSelectBox">
                        <select
                          name="country"
                          value={country}
                          onChange={handleInput}
                        >
                          <option value="">Select Country</option>
                          {checkArray(countryData?.countries) ? (
                            <>
                              {countryData?.countries?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <h6 className="mb-1">&nbsp;</h6>
                      <h6>&nbsp;</h6>
                      <div className="CommonSelectBox">
                        <select
                          name="province"
                          value={province}
                          onChange={handleInput}
                        >
                          <option value="">Select State</option>
                          {stateData?.map((ele, i) => {
                            return (
                              <option
                                value={ele?.name}
                                selected={ele?.name == province}
                              >
                                {ele?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="AlbertaTaxMain">
                      <div className="TaxRowHide">
                        <table>
                          <thead>
                            <tr>
                              <th>Tax Name</th>
                              <th>Tax Percent</th>
                              <th>Action</th>
                              <th>
                                <button
                                  className="BrandBtn mr-2"
                                  onClick={() => Addmore("taxRow")}
                                >
                                  Add
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {taxRows?.map((taxD, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Insert Tax Name"
                                        name="taxName"
                                        value={taxD.taxName}
                                        onChange={(e) => handleTaxInput(e, i)}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Insert Tax Percent"
                                        name="taxPercent"
                                        value={taxD.taxPercent}
                                        onChange={(e) => handleTaxInput(e, i)}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="TableThreeDots text-center">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            {taxRows.length > 1 && (
                                              <li
                                                onClick={() =>
                                                  handleTaxRemove(i)
                                                }
                                              >
                                                <a href="javascript:void(0);">
                                                  <img src="images/remove_tax_line.png" />
                                                  Remove line
                                                </a>
                                              </li>
                                            )}
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={3}>
                                <div className="form-group">
                                  <h6>Rebate</h6>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="isRebate"
                                      checked={isRebate}
                                      onClick={handleTaxCheck}
                                    />
                                    <span className="slider" />
                                  </label>
                                </div>
                              </td>
                            </tr>
                            {isRebate &&
                              taxRebates?.length > 0 &&
                              taxRebates?.map((taxRe, i) => {
                                return (
                                  <tr>
                                    <td>
                                      <div className="d-flex">
                                        <div className="form-group mr-1">
                                          <h6>Sale Price Range</h6>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="$ greater than"
                                            name="minRange"
                                            value={taxRe?.minRange}
                                            onChange={(e) =>
                                              handleRebelChange(e, i)
                                            }
                                          />
                                        </div>
                                        <div className="form-group ml-1">
                                          <h6>&nbsp;</h6>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="$ to"
                                            name="maxRange"
                                            value={taxRe?.maxRange}
                                            onChange={(e) =>
                                              handleRebelChange(e, i)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-group">
                                        <h6>Applied as</h6>
                                        <div className="CommonSelectBox">
                                          <select
                                            name="appliedAss"
                                            onChange={(e) =>
                                              handleRebelChange(e, i)
                                            }
                                          >
                                            <option value="">
                                              Select Option
                                            </option>
                                            <option
                                              value="Sliding"
                                              selected={
                                                taxRe?.appliedAss == "Sliding"
                                              }
                                            >
                                              Sliding
                                            </option>
                                            <option
                                              value="Fixed"
                                              selected={
                                                taxRe?.appliedAss == "Fixed"
                                              }
                                            >
                                              Fixed
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-group">
                                        <h6>Amount</h6>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Insert Tax Percent"
                                          name="amount"
                                          value={taxRe?.amount}
                                          onChange={(e) =>
                                            handleRebelChange(e, i)
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td className="pt-0">
                                      <div className="form-group">
                                        <h6 style={{ marginTop: "-10px" }}>
                                          Action
                                        </h6>
                                        <ul className="TableThreeDots text-center">
                                          <li className="dropdown">
                                            <a
                                              className=""
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </a>
                                            <ol className="dropdown-menu dropdown-menuwidth">
                                              {taxRebates.length > 0 && (
                                                <li
                                                  onClick={() =>
                                                    handleRebelRemove(i)
                                                  }
                                                >
                                                  <a href="javascript:void(0);">
                                                    <img src="images/remove_tax_line.png" />
                                                    Remove line
                                                  </a>
                                                </li>
                                              )}
                                            </ol>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            <button
                              className="BrandBtn mr-2 mt-2"
                              onClick={() => Addmore("taxRebel")}
                            >
                              Add New Line
                            </button>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="form-gro">
                      <h6>Tax Condition</h6>
                      <div className="d-flex flex-column">
                        <label className="CheckBox">
                          {" "}
                          Default
                          <input
                            type="checkbox"
                            name="defaultCheck"
                            checked={defaultCheck}
                            onChange={() => setDefaultCheck(!defaultCheck)}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="CheckBox">
                          {" "}
                          Active
                          <input
                            type="checkbox"
                            name="activeCheck"
                            checked={activeCheck}
                            onChange={() => setActiveCheck(!activeCheck)}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="SaveDeleteProfile justify-content-start mt-5">
                      <button className="mx-0" onClick={handleCreateTaxProfile}>
                        Create Tax Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button className="Outline" onClick={hideTaxModal}>
                  Back
                </button>
                <button
                  className="Create"
                  onClick={(e) => addCompanyFunc(e, 4)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={costProfilesModal}
        onHide={hideCostProfilesModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={mainModal}
          >
            ×
          </a>
          <div class="AuthoritySetupBox">
                    <div class="AuthoritySetupBoxMain">
                      <div class="AuthoritySetupBoxLeft">
                        <h6>Contact</h6>
                        <div class="CommonSelectBox">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Subdivision"
                            value={subDisvisionState}
                            onChange={(e) => setsubDivisonState(e.target.value)}
                          />
                        </div>
                        {editFlag?
                        <button
                          onClick={handleUpdateSubDivison}
                          class="SmallRedBtn"
                        >
                          Update Contact
                        </button>:
                         <button
                          onClick={handleaddSubDivision}
                          class="SmallRedBtn"
                        >
                          Add Contact
                        </button>}
                      </div>
                      <div class="AuthoritySetupBoxRight">
                        <h6>Contact List:</h6>

                        {subdivisions?.map((item, i) => (
                          <div class="AuthorizeName">
                            <p>{item}</p>

                            <span onClick={(e) => {setEditFlag(true)
                            setsubDivisonState(item)
                              setTempIndex(i)
                            }}>
                              <img src="images/Action-1.png" />
                            </span>

                            <span onClick={(e) => handleDeleteSubDivison(e, i)}>
                              <img src="images/dropdown-delete.png" />
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={letterHeaderModal}
        onHide={hideLetterHeaderModal}
        className="ModalBox fade ModalBox LargeModal modal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={mainModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Company Setup</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li
                  onClick={() => {
                    showModalFunc("generalStateModal", "letterHeaderModal");
                  }}
                >
                  <span className="Icon">
                    <img src="images/step_1.png" />
                  </span>
                  <span className="Text">
                    General <br /> Information
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc(
                        "bussinessAddressModal",
                        "letterHeaderModal"
                      );
                    }}
                  >
                    <img src="images/step_2.png" />
                  </span>
                  <span className="Text">
                    Business <br /> Address
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("accountingModal", "letterHeaderModal");
                    }}
                  >
                    <img src="images/step_3.png" />
                  </span>
                  <span className="Text">Accounting</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("taxModal", "letterHeaderModal");
                    }}
                  >
                    <img src="images/step_4.png" />
                  </span>
                  <span className="Text">Tax</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("costProfilesModal", "letterHeaderModal");
                    }}
                  >
                    <img src="images/step_6.png" />
                  </span>
                  <span className="Text">
                    Cost <br /> Profiles
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("confirmationModal,letterHeaderModal");
                    }}
                  >
                    <img src="images/step_6.png" />
                  </span>
                  <span className="Text">
                    Letter <br /> Header
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("letterHeaderModal", "HeaderPreviewModal");
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea">
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Letter Header</h6>
                      <h5
                        className="CursorPointer text-black FolderPermissonId"
                        onClick={() => handlHeaderPreviewModalShow()}
                      >
                        <figure>
                          <img src="images/configure_header.png" />
                        </figure>
                        <p
                          dangerouslySetInnerHTML={{ __html: letterHeader }}
                          className="editor"
                        ></p>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button className="Outline" onClick={hideLetterHeaderModal}>
                  Back
                </button>
                <button
                  className="Create"
                  onClick={(e) => addCompanyFunc(e, 6)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={confirmationModal}
        onHide={hideConfirmationModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={mainModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Company Setup</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li
                  onClick={() => {
                    showModalFunc("generalStateModal", "confirmationModal");
                  }}
                >
                  <span className="Icon">
                    <img src="images/step_1.png" />
                  </span>
                  <span className="Text">
                    General <br /> Information
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc(
                        "bussinessAddressModal",
                        "confirmationModal"
                      );
                    }}
                  >
                    <img src="images/step_2.png" />
                  </span>
                  <span className="Text">
                    Business <br /> Address
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("accountingModal", "confirmationModal");
                    }}
                  >
                    <img src="images/step_3.png" />
                  </span>
                  <span className="Text">Accounting</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("taxModal", "confirmationModal");
                    }}
                  >
                    <img src="images/step_4.png" />
                  </span>
                  <span className="Text">Tax</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("costProfilesModal", "confirmationModal");
                    }}
                  >
                    <img src="images/step_6.png" />
                  </span>
                  <span className="Text">
                    Cost <br /> Profiles
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("letterHeaderModal", "confirmationModal");
                    }}
                  >
                    <img src="images/step_6.png" />
                  </span>
                  <span className="Text">
                    Letter <br /> Header
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      showModalFunc("confirmationModal");
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea">
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="row">
                  <div className="form-group col-md-3">
                    <h6>Name</h6>
                    <p>{name}</p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Email</h6>
                    <p>{email}</p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Primary Phone Number</h6>
                    <p>{primaryNumber}</p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Logo</h6>
                    <img src={logo} />
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Website</h6>
                    <p>{website}</p>
                  </div>
                  {/* <div className="form-group col-md-3">
                      <h6>Login URL Shorthand</h6>
                      <p>company1</p>
                    </div> */}
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <h6>Motto</h6>
                    <p>{motto}</p>
                  </div>
                  <div className="form-group col-md-6">
                    <h6>Letter Header</h6>
                    <p
                      dangerouslySetInnerHTML={{ __html: letterHeader }}
                      className="editor"
                    ></p>
                  </div>
                  <div className="form-group col-md-12">
                    <h6>Display on Login page</h6>
                    <p>
                      <div>
                        {[
                          displayLogo && "Display Logo",
                          displayMotto && "Display Motto",
                          displayName && "Display Name",
                        ]
                          .filter(Boolean)
                          .map((profile, index) => (
                            <div key={index}>{profile}</div>
                          ))}
                      </div>
                    </p>
                  </div>
                  <div className="form-group col-md-4">
                    <h6>Street Address</h6>
                    <p>
                      {streetAddressLine1 ?? ""},{streetAddressLine2 ?? ""}
                    </p>
                  </div>
                  <div className="form-group col-md-4">
                    <h6>City</h6>
                    <p>{city ?? ""}</p>
                  </div>
                  <div className="form-group col-md-4">
                    <h6>State/Province</h6>
                    <p>{province ?? ""}</p>
                  </div>
                  {/* <div className="form-group col-md-4">
                      <h6>{companyDetailData?.streetAddressLine2}</h6>
                      <p /> 
                    </div>*/}
                  <div className="form-group col-md-4">
                    <h6>Zip/ Postal Code</h6>
                    <p>{zipCode ?? ""}</p>
                  </div>
                  <div className="form-group col-md-4">
                    <h6>Country</h6>
                    <p>{country}</p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Accounting Software</h6>
                    <p>{sage ? "Sage" : "Quickbooks"}</p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Fiscal year End</h6>
                    <p>{fiscalYearEnd}</p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Tax Registration #</h6>
                    <p>{taxRegistration}</p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Tax Profiles</h6>
                    <p>Alberta Tax (Default) Ontario Tax</p>
                  </div>
                  {/* <div className="form-group col-md-4">
                    <h6>Job Pattern</h6>
                    <p>{jobNumberPattern}</p>
                  </div> */}
                  <div className="form-group col-md-4">
                    <h6>Cost Code Profiles Type</h6>
                    <p>{costCodeProfileType}</p>
                  </div>
                  <div className="form-group col-md-4">
                    <h6>Cost Code Profiles</h6>
                    <p>
                      <div>
                        {[
                          landDevelopmentProfile &&
                            "Land Development (enabled)",
                          multiFamilyProfile && "Multi-Family (enabled)",
                          residentialProfile && "Residential (enabled)",
                        ]
                          .filter(Boolean)
                          .map((profile, index) => (
                            <div key={index}>{profile}</div>
                          ))}
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button className="Outline" onClick={hideConfirmationModal}>
                  Back
                </button>
                <button className="Create" onClick={(e)=>addCompanyFunc(e,7)}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
       {
        HeaderPreviewModal&&
        <Modal
        show={HeaderPreviewModal}
        onHide={handlHeaderPreviewModalClose}
        className="ModalBox fade ModalBox LargeModal modal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handlHeaderPreviewModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Configure Company Letter Header</h4>
            </div>
            <div className="FolderForm">

            <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload" style={{
                    width:"300px"
                  }}>
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              <div className="AgreementViewArea">
                <div className="AgreementViewTop">
                  <div className="AgreementViewTopRight">
                    <div className="mce-pagebreak">
                      <Editor
                        apiKey="s5lpq1i7ftirx73vaw448qm0lk3phqsvnx3dpqvp542y1fbd"
                        onInit={(evt, editor) => (editorsRef.current = editor)}
                        value={letterHeader}
                        init={init}
                        onEditorChange={handleEditorChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button text-center mt-5">
                <button className="Outline" onClick={handleUploadFiles}>
                  Apply
                </button>

                <button className="Outline ml-2" onClick={handlHeaderPreviewModalClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
       }
     
    </>
  );
};

export default CompanyModal;
