import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  RoleDropdownAction,
  UserDropdownAction,
  getUrlImageAction,
} from "../../redux/action/dropdownAction";
import {
  CreateProfileAction,
  ProfileAction,
  UpdateProfileAction,
} from "../../redux/action/profileAction";
import { toast } from "react-toastify";
import Profile from "../../assets/images/user_avtar.png";
import moment from "moment";
import PhoneInput from "react-phone-input-2";

const initialSate = {
  name: "",
  UserId: "",
  userRole: "",
  email: "",
  phoneNumber: "",
  RoleId: "",
  allProjectsAssigned: false,
  assignedProjects: [1],
  absenceFrom: "",
  absenceTo: "",
  preview: "",
  profilePicture: "",
  roleType: "",
  stopper: false,
};

const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

const User = JSON.parse(window.localStorage.getItem("user"));

const UserProfile = ({ UserProfileModal, setUserProfile }) => {
  const [iState, updateState] = useState(initialSate);
  const { userList, roleList } = useSelector((state) => state.dropdownReducer);
  const { profileData } = useSelector((state) => state.profileReducer);
  const dispatch = useDispatch();

  const {
    name,
    userRole,
    UserId,
    email,
    phoneNumber,
    RoleId,
    allProjectsAssigned,
    assignedProjects,
    absenceFrom,
    absenceTo,
    profilePicture,
    stopper,
    preview,
    roleType,
  } = iState;

  const [phonecode, setPhoneCode] = useState("");
  const handlePhoneInputChange = (value, country) => {
    // console.log('-------->', value, country)
    setPhoneCode(`+${country.dialCode}`);
  };

  // const [UserProfileModal, setUserProfile] = useState(false);
  const handleUserProfileModalClose = () => setUserProfile(false);
  const handleUserProfileModalShow = () => {
    setUserProfile(true);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  useEffect(() => {
    if (UserProfileModal && iBuildLocalData) {
      dispatch(UserDropdownAction());
      dispatch(RoleDropdownAction());

      dispatch(
        ProfileAction(
          iBuildLocalData?.user?.CompanyId,
          iBuildLocalData?.user?.id
        )
      );
    }
  }, [UserProfileModal]);

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        //  dispatch( getUrlImageAction(file)).then((res)=>{

        //   }
        //   )

        updateState({
          ...iState,
          profilePicture: file,
          preview: URL.createObjectURL(file),
        });

        window.localStorage.setItem(
          "user",
          JSON.stringify({ profile: URL.createObjectURL(file) })
        );
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateState({ ...iState, stopper: true });
    let formIsValid = true;

    if (formIsValid) {
      dispatch(
        UpdateProfileAction(
          iBuildLocalData?.user?.CompanyId,
          iBuildLocalData?.user?.id,
          {
            name,
            email,
            phonecode,
            phoneNumber,
            profilePicture: preview,
            absenceFrom: absenceFrom,
            absenceTo: absenceTo,
            backUpUserId: UserId,
            roleType,
            RoleId,
            allProjectsAssigned: true,
            assignedProjects: [],
          }
        )
      )
        .then((res) => {
          if (res.status === 200) {
            toast.success("User Profile created Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              stopper: false,
              userId: "",
              name: "",
              email: "",
              phoneNumber: "",
              RoleId: "",
            });
            setUserProfile(false);
          } else {
            updateState({ ...iState, stopper: false });

            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          updateState({ ...iState, stopper: false });

          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    updateState({
      ...iState,
      CompanyId: profileData?.user?.CompanyId,

      RoleId: profileData?.user?.RoleId,
      roleType: profileData?.user?.roleType,
      UserId: profileData?.user?.id,
      absenceFrom: profileData?.user?.absenceFrom,
      absenceTo: profileData?.user?.absenceTo,
      allProjectsAssigned: profileData?.user?.allProjectsAssigned,
      email: profileData?.user?.email,
      name: profileData?.user?.name,
      phoneNumber: profileData?.user?.phoneNumber,
      profilePicture: User?.profile,
    });
  }, [profileData?.user]);

  const handleCancel = (e) => {
    e.preventDefault();
    setUserProfile(false);
  };

  return (
    <>
      <Modal show={UserProfileModal} className="ModalBox LargeModal">
        <div className="modal-content">
          <a
            onClick={handleUserProfileModalClose}
            className="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>User Profile</h4>
            </div>
            <div className="FolderForm">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="d-flex align-items-center mb-3">
                      <div className="UserProfileBox">
                        {(preview || profilePicture) && (
                          <figure>
                            <img
                              src={preview ? preview : profilePicture}
                              alt="Profile"
                            />
                          </figure>
                        )}
                        <span>
                          <img src="images/camera_icon.png" alt="Upload Icon" />
                          <input
                            type="file"
                            name="profile"
                            onChange={handleUpload}
                          />
                        </span>
                      </div>
                      <h6 className="ml-3 CursorPointer d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={name}
                          onChange={handleInput}
                          placeholder="Enter name"
                        />
                        <img
                          src="images/Action-1.png"
                          className="ml-2"
                          alt="Edit Icon"
                        />
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Email </h6>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={handleInput}
                        placeholder="janewilliams@builder.com"
                      />
                    </div>
                    <div className="form-group">
                      <h6>Role </h6>
                      <div className="CommonSelectBox w-100">
                        <select
                          disabled={true}
                          value={RoleId}
                          name="RoleId"
                          className="form-control"
                          onChange={handleInput}
                        >
                          <option value="">Select Role</option>
                          {roleList?.map((role) => (
                            <option key={role.id} value={role.id}>
                              {role.roleName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <h6>Absence</h6>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <h6>From</h6>
                          <input
                            type="date"
                            className="form-control"
                            name="absenceFrom"
                            value={moment(absenceFrom).format("YYYY-MM-DD")}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <h6>To</h6>
                          <input
                            type="date"
                            className="form-control"
                            name="absenceTo"
                            value={moment(absenceTo).format("YYYY-MM-DD")}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Back-up user</h6>
                      <div className="CommonSelectBox w-100">
                        <select
                          value={UserId}
                          name="UserId"
                          className="form-control"
                          onChange={handleInput}
                        >
                          <option value="">Select User</option>
                          {userList?.map((user) => (
                            <option
                              key={user.id}
                              value={user.id}
                              selected={UserId == user.id}
                            >
                              {user.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>
                        Phone Number{" "}
                        <img src="images/Action-1.png" alt="Edit Icon" />
                      </h6>
                      <input
                        type="tel"
                        className="form-control"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={handleInput}
                        placeholder="(587)555-4444"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>
                        Phone Code{" "}
                        <img src="images/Action-1.png" alt="Edit Icon" />
                      </h6>
                      <PhoneInput
                        inputClass="CountryCodeInput "
                        countryCodeEditable={false}
                        enableSearch
                        value={phonecode}
                        onChange={handlePhoneInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="Button d-flex justify-content-between mt-4">
                  <button type="submit" className="Create" disabled={stopper}>
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="Create"
                  >
                    Reset Password
                  </button>
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="Outline"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserProfile;
