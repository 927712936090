import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../App.css";
import { toast } from "react-toastify";

import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  acceptRejectEditPO,
  getEditPOById,
  rejectPOs,
} from "../redux/action/tradeViewAuthAction";
import { companyDetails } from "../redux/action/designSelectionsAction";

const initialState = {
  viewPoData: [],
};

const TradeViewPurchaseOrder = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const { viewPoData } = iState;
  const location = useLocation();
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1].split("#")[0]
  );
  const companyId = urlParams?.get("companyId");
  const documentId = urlParams?.get("documentId");
  const token = urlParams?.get("token");
  const [companyData,setCompanyData] = useState([])
  useEffect(() => {
    dispatch(getEditPOById(companyId, token, documentId)).then((res) => {
      if (res.status === 200) {
        updateState({ ...iState, viewPoData: res?.data?.data });
      }
    });
    dispatch(companyDetails(companyId,token)).then((res)=>{
      if(res.status===200){
        let dummData=res?.data?.company
        setCompanyData(dummData)

      }
    })
  }, []);

  const handleAcceptRejectPO = (type) => {
    if (type === "accept") {
      dispatch(acceptRejectEditPO(companyId, documentId, token)).then((res) => {
        if (res.status === 200) {
          dispatch(getEditPOById(companyId, token, documentId)).then((res) => {
            if (res.status === 200) {
              updateState({ ...iState, viewPoData: res?.data?.data });
            }
          });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } else {
      dispatch(rejectPOs(companyId, documentId, token)).then((res) => {
        if (res.status === 200) {
          dispatch(getEditPOById(companyId, token, documentId)).then((res) => {
            if (res.status === 200) {
              updateState({ ...iState, viewPoData: res?.data?.data });
            }
          });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handlePrint = () => {
    window.print();
  };
  const createAboutMarkup = () => {
    return { __html: companyData?.letterHeader };
  };
  return (
    <>
      <div className="FormArea p-5">
      <div className="AgreementViewTop">
                
                <div className="AgreementViewTopRight">
                <p
                    dangerouslySetInnerHTML={createAboutMarkup()}
                    className="editor"
                  ></p>
                </div>
              </div>
        <div className="ModalTitleBox ModalTitleBoxBlue">
          <h4>View Purchase Order</h4>
        </div>
        <div className="FolderForm">
          <div className="FolderForm">
            <div className="BlueTextArea">
              <div className="row">
                <div className="col-md-6">
                  <div className="Heading">
                    <h3>Purchase Order # {viewPoData?.documentCode}</h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Heading">
                    <h3 className="d-flex">
                      {/* Rivers Edge Unit */}
                      {viewPoData?.job?.jobNumber ?? "N/A"}
                    </h3>
                    <h3>
                      Cost Code:{" "}
                      <span>{`${viewPoData?.CostCode?.number ?? ""}-${
                        viewPoData?.CostCode?.name ?? ""
                      }`}</span>
                    </h3>
                    <h6>
                      Status:<span> {viewPoData?.status}</span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="form-group col-md-6">
                  <h6>Reason</h6>
                  <p className="ml-0 text-black">
                    {" "}
                    {viewPoData?.Reason?.description ?? "N/A"}
                  </p>
                </div>
                {/* <div className="form-group col-md-6">
              <div className="CommonModalArea">
                <h5 className="BlueText">History</h5>
                <div className="CommonModalBox">
                  <h5>
                    <figure>
                      <img src="images/timer.png" />
                    </figure>
                  </h5>
                </div>
              </div>
            </div> */}
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <div className="CommonModalArea BlueText">
                      <h5 className="BlueText">Vendor</h5>
                      <div className="CommonModalBox">
                        <h5>
                          <p className="ml-0">
                            {viewPoData?.Vendor?.vendorName ?? "N/A"}
                          </p>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <div className="CommonModalArea BlueText">
                      <h5 className="BlueText">Date Sent</h5>
                      <p className="ml-0">
                        {viewPoData?.sendAt !== null
                          ? moment(viewPoData?.sendAt).format("YYYY-MM-DD")
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <div className="CommonModalArea BlueText">
                      <h5 className="BlueText">Date Accepted</h5>
                      <p>
                        {viewPoData?.acceptedAt !== null
                          ? moment(viewPoData?.acceptedAt).format("YYYY-MM-DD")
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="CommonModalArea BlueText">
                      <h5 className="BlueText">Reminder</h5>
                      <div className="CommonModalBox">
                        <p>
                          {viewPoData?.reminder !== null
                            ? moment(viewPoData?.reminder).format("YYYY-MM-DD")
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <h6>Time since last status update</h6>
                    <p className="text-black ml-0">
                      {moment(viewPoData?.updatedAt).format("YYYY-MM-DD")}
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="ShippingAddressArea">
                    <div className="ShippingAddressAreaBox">
                      <h6>Mailing Address</h6>
                      <p>{`${viewPoData?.Vendor?.addressLine1 ?? ""}
                                ${viewPoData?.Vendor?.addressLine2 ?? ""}`}</p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Email</h6>
                      <p>{viewPoData?.Vendor?.email}</p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Phone</h6>
                      <p>{viewPoData?.Vendor?.phone}</p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Shipping Address</h6>
                      <p>{`${viewPoData?.Vendor?.addressLine1 ?? ""}
                                ${viewPoData?.Vendor?.addressLine2 ?? ""}`}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="form-group">
                    <h6>Messsage</h6>
                    <p>{viewPoData?.message ?? "N/A"}</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="ItemDetails">
                    <h6>Item Details</h6>
                  </div>
                </div>
                <div className="col-12">
                  <div className="Tableheader mb-3">
                    <h6 className="mb-0">Item</h6>
                    <div className="Actions">
                      <a onClick={handlePrint}>
                        <img src="images/icon-53.png" />
                      </a>
                      <a onClick={handlePrint}>
                        <img src="images/download.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            <div className="text-center">
                              Material Description
                              <br />
                              (i.e: Modal/Type/Style/manuf part#/Dimensions,
                              etc.)
                            </div>
                          </th>
                          <th>Color</th>
                          <th>Quantity</th>
                          <th>Units</th>
                          <th>Price/Unit</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewPoData?.Items?.length > 0 ? (
                          viewPoData?.Items?.map((item, ind) => {
                            return (
                              <>
                                <tr key={ind}>
                                  <td>{item.id}</td>
                                  <td>{item.name}</td>
                                  <td>{item.color}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.unitId}</td>
                                  <td>$ {item.price}</td>
                                  <td>$ {item.total}</td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="12">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-md-4 ml-auto">
                      <div className="GstTable">
                        <table>
                          <tbody>
                            <tr>
                              <td>Profile</td>
                              <td>{viewPoData?.TaxProfile?.name ?? "N/A"}</td>
                            </tr>
                            <tr>
                              <td>Subtotal</td>
                              <td>${viewPoData?.subtotal || 0}</td>
                              {/* <td>Modify Tax</td> */}
                            </tr>
                            {viewPoData?.otherTaxes?.length > 0 && (
                              <tr>
                                <td>{`${viewPoData?.otherTaxes?.[0]?.taxName} (${viewPoData?.otherTaxes?.[0]?.taxPercentage})%`}</td>
                                <td>
                                  $ {viewPoData?.otherTaxes?.[0]?.taxAmount}
                                </td>
                              </tr>
                            )}
                            <tr>
                              <td>
                                {" "}
                                GST(
                                {Number(
                                  viewPoData?.TaxProfile?.taxRows?.[0]
                                    ?.taxPercent
                                ) || 0}
                                %)
                              </td>
                              <td>
                                $
                                {(Number(viewPoData?.subtotal) *
                                  (Number(
                                    viewPoData?.TaxProfile?.taxRows?.[0]
                                      ?.taxPercent
                                  ) || 0)) /
                                  100}
                              </td>
                            </tr>
                            <tr>
                              <td>Grand Total</td>
                              <td>${viewPoData?.grandTotal || 0}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {viewPoData?.status === "SEND" ? (
                <div className="Button d-flex justify-content-between mt-4 ">
                  <button
                    className="Create"
                    onClick={() => handleAcceptRejectPO("accept")}
                  >
                    Accept
                  </button>
                  <button
                    className="Create"
                    onClick={() => handleAcceptRejectPO("reject")}
                  >
                    Reject
                  </button>
                </div>
              ) : (
                <p>An Action has been already performed</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeViewPurchaseOrder;
