import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SubscriptionPlanListAction, addSubscriptionAction, getCitiesList, getCountriesList, getStateList } from "../redux/action/authAction";
import { useEffect } from "react";
import {Modal} from "react-bootstrap";
import { checkArray } from "../utils/CheckType";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const init = {
  paymentType: false,
  name: "",
  email: "",
  primaryNumber: "",
  password: "",
  streetAddressLine1: "",
  streetAddressLine2: "",
  zipCode: "",
  country: "",
  planId: "",
  city: "",
  state: "",
  paymentMethod: "",
  cardNumber: "",
  currencyType: "usd",
  expDate: "",
  cvv: "",
  addressErrors:{},
  companyErrors:{},
  paymentMethodErrors:{},
  afterSubscriptionData:[],
  isAddressSame:false,
  SubscriptionCompleteModal:false,
  loginDetailsModal:false,
};
const CheckoutScreen = () => {
  const [iState, updateState] = useState(init);
  const [showCompany, setShowCompany] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showCreditCard, setShowCreditCard] = useState(false);
  const {planId, price} = useParams()
  const {
    paymentType,
    name,
    email,
    primaryNumber,
    password,
    streetAddressLine1,
    streetAddressLine2,
    zipCode,
    country,
    // planId,
    city,
    state,
    paymentMethod,
    cardNumber,
    currencyType,
    expDate,
    cvv,
    errors,
    isAddressSame,
    addressErrors, companyErrors, paymentMethodErrors,
    afterSubscriptionData,SubscriptionCompleteModal,loginDetailsModal
  } = iState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const[phonecode, setPhoneCode] = useState("")
 
  const handlePhoneInputChange = (value,country) => {
    setPhoneCode(`+${country.dialCode}`)
    
  };
  const [planDetailsData, setPlanDetailsData] = useState([]);
  const { countryData, cityData, stateData } = useSelector(
    (state) => state.authReducer
  );
  useEffect(() => {
    dispatch(SubscriptionPlanListAction(planId)).then((res) => {
      setPlanDetailsData(res.data.plan)
    })
    dispatch(getCountriesList());
  },[])

  useEffect(() => {
    if(country)
    dispatch(
      getStateList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode
      )
    );
  }, [country]);
  useEffect(() => {
    dispatch(
      getCitiesList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode,
        stateData?.states?.find((x) => x.name == state)?.isoCode
      )
    );
  }, [state]);
  const handleRadio = (e) => {
    const { name, value, checked } = e.target;
    if (value === "creditCard" && checked == true) {
      updateState({ ...iState, paymentType: true , paymentMethod:value});
    } else {
      updateState({ ...iState, paymentType: false, paymentMethod:value });
    }
  };


  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };
  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateState({ ...iState, [name]: result });  };

    const handleCompanyValidation = () => {
      let formIsValid = true;
      let error = {};
  
      if (!name) {
        error.nameError = "* Name can't be empty";
        formIsValid = false;
      }
      if (!primaryNumber) {
        error.contactNumberError = "*  Mobile number can't be empty";
        formIsValid = false;
      }
      if (primaryNumber) {
        if (!/^[1-9][0-9]{9,12}$/.test(primaryNumber)) {
          error.contactNumberError = "Please enter a valid phone number";
          formIsValid = false;
        }
      }
      // if (!address) {
      //   error.addressError = "* Address can't be empty";
      //   formIsValid = false;
      // }
     
  
      if (!password?.trim("")) {
        error.passwordError = "! Password can't be empty";
        formIsValid = false;
      }
  
      if (password) {
        if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            password
          )
        ) {
          error.passwordError =
            "Please enter atleast one speical character one alphnumeric and one capital letter";
          formIsValid = false;
        }
      }
  
   
  
      if (!email) {
        error.emailError = "* Email address can't be empty";
        formIsValid = false;
      }
  
      if (email) {
        if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            email
          )
        ) {
          error.emailError = "Please enter a valid email";
          formIsValid = false;
        }
      }

      if(paymentMethod === "creditCard"){
        if (!cvv) {
          error.cvvError = "* CVV can't be empty";
          formIsValid = false;
        }
        if (!expDate) {
          error.expDateError = "* Exp date can't be empty";
          formIsValid = false;
        }
  
        if (!cardNumber) {
          error.cardNumberError = "* Card number can't be empty";
          formIsValid = false;
        }
  
        const regex = new RegExp("^[0-9]{13,19}$");
  
        if (cardNumber) {
          if (!new RegExp("^[0-9]{16}$").test(cardNumber)) {
            error.cardNumberError = "Please enter a valid card number";
            formIsValid = false;
          }   
        }
      }

  
     
  
      if (!paymentMethod) {
        error.paymentMethodError = "* Payment method can't be empty";
        formIsValid = false;
      }

      
      if (!streetAddressLine1) {
        error.streetAddressLine1Error = "* Street address can't be empty";
        formIsValid = false;
      }
      if (!streetAddressLine2) {
        error.streetAddressLine2Error = "* Street address 2 can't be empty";
        formIsValid = false;
      }

      if (!zipCode) {
        error.zipCodeError = "* Zip code can't be empty";
        formIsValid = false;
      }
      if (zipCode) {
        if (!/^[1-9][0-9]{5,8}$/.test(zipCode)) {
          error.zipCodeError = "Please enter a zipcode";
          formIsValid = false;
        }
      }
   
  
      if (!city?.trim("")) {
        error.cityErrorityError = "! City can't be empty";
        formIsValid = false;
      }
  
      if (!country) {
        error.countryError = "* Country can't be empty";
        formIsValid = false;
      }

      if (!state) {
        error.stateError = "* State can't be empty";
        formIsValid = false;
      }

      updateState({ ...iState, companyErrors: error });
      return formIsValid;
    };

    const handleCompanyData = (e) =>{
      e.preventDefault()
        setShowCompany(true)
        setShowAddress(true)
    }

    const handleAddressData = (e) =>{
        e.preventDefault()
        setShowCreditCard(false)
        setShowAddress(false)
    }

    const handlePaymentData = (e) =>{
      e.preventDefault()
        setShowCreditCard(true)
        setShowAddress(true)
      }
    // }


    const addSubscription = (e) => {
         e.preventDefault()
        //  let formIsValidAddress = handleAddressValidation();
         let formIsValidCompany = handleCompanyValidation();
        //  let formIsValidPayment = handlePaymentValidation();
         const parsedDate = new Date(expDate);
         const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
         const year = String(parsedDate.getFullYear()).slice(-2);
         const convertedDate = `${month}${year}`;
         console.log("convertedDate", convertedDate);
        //  if(formIsValidCompany){
          const data = {
            name,
            email,
            primaryNumber,
            password,
            streetAddressLine1,
            streetAddressLine2,
            zipCode,
            country,
            PlanId:planId,
            city,
            state,
            // paymentMethod,
            paymentMethod : planDetailsData?.[0]?.isContract ? "contract":"creditCard",
            phoneCountryCode: phonecode,
            currencyType,
        
          }
           
          if(paymentMethod === "creditCard"){
           data["cardNumber"] = cardNumber;
           data["expDate"] = convertedDate;
           data["cvv"] = cvv;
          }
           dispatch(addSubscriptionAction(data)).then((res) => {
            console.log("=======res.", res)
              updateState({...iState,})
              SubscriptonCompleteModalShow(res.data)
           }).catch((err) =>{
            console.log("=====>",err)
           })

        //  }
        
    }
    const SubscriptonCompleteModalClose = () => {
      updateState({...iState, SubscriptionCompleteModal:false})
    }
    const SubscriptonCompleteModalShow = (data) => {
    updateState({...iState, SubscriptionCompleteModal:true,afterSubscriptionData: data})
    }
    const handleLoginDetailsClose = () => {
      updateState({...iState, loginDetailsModal:false, })
    }
    const handleLoginDetailsShow = () => {
    updateState({...iState, loginDetailsModal:true,SubscriptionCompleteModal:false})
    }

  return (
    <>
      <div className="SubsCriptionBackground">
        <div className="SubscriptionTitleBox">
          <h4 className="Title">Subscription starts</h4>
          <div>
            <img src="images/Logo.png" />
          </div>
        </div>
        <div className="PlanScreenArea">
          <div className="row">
            <div className="col-md-6">
              <div className="YourProductArea">
                <div className="YourProduct">
                  <h4>Checkout</h4>
                  <ul className="breadcumbs">
                    <li className="breadcumb-link active">
                      Your Product &gt;{" "}
                    </li>
                    <li className="breadcumb-link"> Checkout</li>
                  </ul>
                </div>
                <div className="YourProductBox">
                  <div className="YourProductBoxTop CompanyDetailsToggle">
                    <h6>Company Details</h6>
                    <a
                      onClick={() => setShowCompany(!showCompany)}
                      href="javascript:void(0);"
                    >
                      <img src="images/black-down-arrow.png" />
                    </a>
                  </div>
                  {!showCompany ? (
                    <div className="CompanyDetailsForm CompanyDetailsShow">
                      <form className="row">
                        <div className="form-group col-md-9">
                          <label>
                            Name<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Insert Company's Name"
                            name="name"
                            value={name}
                            onChange={handleInput}
                          />
                          <span style={{color:"red", fontSize:"14px"}}>{companyErrors?.nameError}</span>
                        </div>
                        
                  <div className="form-group">
                    <h6>Country Code</h6>
                    <PhoneInput inputClass="CountryCodeInput"
                      countryCodeEditable={false}
                      enableSearch
                      value={phonecode}
                      onChange={handlePhoneInputChange}
                    />
                                 
                  </div>
                        <div className="form-group col-md-9">
                          <label>
                            Primary Phone Number<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Insert Company's Primary Phone Number"
                            name="primaryNumber"
                            value={primaryNumber}
                            onChange={handleInputNumber}
                          />
                           <span style={{color:"red", fontSize:"14px"}}>{companyErrors?.contactNumberError}</span>
                        </div>
                        <div className="form-group col-md-9">
                          <label>
                            Email<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Insert email for initial access"
                            name="email"
                            value={email}
                            onChange={handleInput}
                          />
                           <span style={{color:"red", fontSize:"14px"}}>{companyErrors?.emailError}</span>
                        </div>
                        <div className="form-group col-md-9">
                          <label>
                            Passwords<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Insert password"
                            name="password"
                            value={password}
                            onChange={handleInput}
                          />
                           <span style={{color:"red", fontSize:"14px"}}>{companyErrors?.passwordError}</span>
                        </div>
                        <div className="col-md-12 mt-4">
                          <button onClick={handleCompanyData} className="PlanContinueBtn">
                            Continue to Address
                          </button>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="YourProductBox">
                  <div className="YourProductBoxTop AddressToggle">
                    <h6>Address</h6>
                    <a
                      onClick={() => setShowAddress(!showAddress)}
                      href="javascript:void(0);"
                    >
                      <img src="images/black-down-arrow.png" />
                    </a>
                  </div>
                  {showAddress ? (
                    <div className="CompanyDetailsForm AddressShow">
                      <form className="row">
                        <div className="form-group col-md-9">
                          <label>
                            Street Address<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Insert Street Address"
                            name="streetAddressLine1"
                            value={streetAddressLine1}
                            onChange={handleInput}
                          />
                            <span style={{color:"red", fontSize:"14px"}}>{companyErrors?.streetAddressLine1Error}</span>
                        </div>
                        <div className="form-group col-md-9">
                          <label>
                            Address Line Two<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Insert Street Address Line Two"
                            name="streetAddressLine2"
                            value={streetAddressLine2}
                            onChange={handleInput}
                          />
                            <span style={{color:"red", fontSize:"14px"}}>{companyErrors?.streetAddressLine2Error}</span>
                        </div>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label>
                                Zip Code / Postal Code<span>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Insert zip code"
                                name="zipCode"
                                value={zipCode}
                                onChange={handleInput}
                              />
                                <span style={{color:"red", fontSize:"14px"}}>{companyErrors?.zipCodeError}</span>
                            </div>
                            {/* <div className="form-group col-md-6">
                              <label>
                                City<span>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Insert City"
                                name="city"
                                value={city}
                                onChange={handleInput}
                              />
                                <span style={{color:"red", fontSize:"14px"}}>{companyErrors?.cityErrorityError}</span>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label>
                                Country/Region<span>*</span>
                              </label>
                              <div className="CommonSelectBox">
                              <select
                                name="country"
                                value={country}
                                onChange={handleInput}
                              >
                                <option>Select Country</option>
                                {checkArray(countryData?.countries) ? (
                                  <>
                                    {countryData?.countries?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                              <span style={{color:"red", fontSize:"14px"}}>{companyErrors?.countryError}</span>
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                State/Province<span>*</span>
                              </label>
                              <div className="CommonSelectBox">
                              <select
                                name="state"
                                value={state}
                                onChange={handleInput}
                              >
                                <option>Select Province</option>
                                {checkArray(stateData?.states) ? (
                                  <>
                                    {stateData?.states?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                              <span style={{color:"red", fontSize:"14px"}}>{companyErrors?.stateError}</span>
                            </div>



                            <div className="form-group col-md-6">
                              <label>
                               City<span>*</span>
                              </label>
                              <div className="CommonSelectBox">
                              <select
                                name="city"
                                value={city}
                                onChange={handleInput}
                              >
                                <option>Select City</option>
                                {checkArray(cityData?.cities) ? (
                                  <>
                                    {cityData?.cities?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                              <span style={{color:"red", fontSize:"14px"}}>{companyErrors?.cityErrorityError}</span>
                            </div>


                           
                          </div>
                        </div>
                        <div className="col-md-12 mt-2">
                          <button className="PlanContinueBtn" onClick={handleAddressData}>
                            Continue to Payment
                          </button>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="YourProductBox">
                  <div className="YourProductBoxTop PaymentMethodToggle mb-0">
                    <h6>Payment Method</h6>
                    <a onClick={() => setShowCreditCard(!showCreditCard)}>
                      <img src="images/black-down-arrow.png" />
                    </a>
                  </div>
                  {!showCreditCard ? (
                    <div className="CompanyDetailsForm PaymentMethodShow pt-5">
                      <div className="facilitiesBox mb-4">
                        <div className="AmmentitiesBox">
                          <ul>
                            {planDetailsData?.[0]?.isContract ? <>
                              <li>
                              <input
                                type="radio"
                                className="RadioShow"
                                name="paymentType"
                                checked={planDetailsData?.[0]?.isContract}
                                defaultValue="contract"
                                onChange={handleRadio}
                              />
                              <aside>
                                <figure>
                                  <img src="images/contract.png" />
                                </figure>
                                <label>Contract</label>
                                <div className="RedioOverlay">
                                  <img src="images/circle_check.png" />
                                </div>
                              </aside>
                            </li>
                            </>  : <>
                            <li>
                              <input
                                type="radio"
                           
                                className="RadioShow"
                                name="paymentType"
                                checked={!planDetailsData?.[0]?.isContract}
                                defaultValue="creditCard"
                                onChange={handleRadio}
                              />
                              <aside>
                                <figure>
                                  <img src="images/credit-card.png" />
                                </figure>
                                <label>Credit Card</label>
                                <div className="RedioOverlay">
                                  <img src="images/circle_check.png" />
                                </div>
                              </aside>
                            </li>
                            </>}
                           
                           
                          </ul>
                        </div>
                      </div>
                      {/* paymentType */}
                      {paymentType ? (
                        <div className="CreditCard RadioText">
                          <form className="row">
                            <div className="col-md-10 mx-auto">
                              {/* <div className="form-group">
                                <label className="Radio">
                                  Credit Card (Moneris)
                                  <input type="radio" />
                                  <span className="checkmark" />
                                </label>
                              </div> */}
                              {/* <div className="form-group col-10 mb-0">
                                <label className="CheckBox">
                                  {" "}
                                  My Billing and Shipping Address are the same{" "}
                                  <br />
                                  {isAddressSame ? 
                                  <>
                                  {streetAddressLine1}<br />
                                  {streetAddressLine2}{" "}, {city}
                                  {zipCode} {" "} {state} <br />
                                 
                                  {country}
                                  </> : <></>}
                                  
                                  <input type="checkbox" onChange={(e) => {
                                    updateState({...iState, isAddressSame:e.target.checked})
                                  }}  />
                                  <span className="checkmark" />
                                </label>
                              </div> */}
                            </div>
                            <div className="form-group col-md-9">
                              <label>
                                Credit Card Number<span>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Credit Card Number"
                                name="cardNumber"
                                value={cardNumber}
                                onChange={handleInputNumber}
                              />
                               <span style={{color:"red", fontSize:"14px"}}>{paymentMethodErrors?.cardNumberError}</span>
                            </div>
                            <div className="col-md-10">
                              <div className="row">
                                <div className="form-group col-md-12 mb-2">
                                  <label>
                                    Expiration Date<span>*</span>
                                  </label>
                                  <input
                                type="date"
                                className="form-control"
                                // placeholder="Enter Credit Card Number"
                                name="expDate"
                                value={expDate}
                                onChange={handleInput}
                              />
                                  <span style={{color:"red", fontSize:"14px"}}>{paymentMethodErrors?.expDateError}</span>
                                </div>
                                {/* {!isAddressSame ? <>
                                  <div className="form-group col-md-6">
                                  <div className="CommonSelectBox">
                                    <select>
                                      <option selected="">
                                        Select Country/Region
                                      </option>
                                      <option>India</option>
                                      <option>USA</option>
                                      <option>Australia</option>
                                    </select>
                                    <span>
                                      <img src="images/down.png" />
                                    </span>
                                  </div>
                                </div>
                                <div className="form-group col-md-6">
                                  <div className="CommonSelectBox">
                                    <select>
                                      <option selected="">
                                        Select State/Province
                                      </option>
                                      <option>India</option>
                                      <option>USA</option>
                                      <option>Australia</option>
                                    </select>
                                    <span>
                                      <img src="images/down.png" />
                                    </span>
                                  </div>
                                </div>
                                </> : <></>} */}
                              
                              </div>
                            </div>
                            <div className="form-group col-md-7">
                              <label>
                                Card Verification Number<span>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Credit Card Number"
                                name="cvv"
                                value={cvv}
                                onChange={handleInputNumber}
                              />
                                  <span style={{color:"red", fontSize:"14px"}}>{paymentMethodErrors?.cvvError}</span>
                              <span className="QuestionMark">
                                <img src="images/question_mark.png" />
                              </span>
                            </div>
                            <div className="form-group col-12 mb-0">
                              <label className="CheckBox">
                                {" "}
                                Save For Later Use
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="Contract RadioText"></div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="OrderSummaryArea">
                <div className="OrderSummaryBox">
                  <div className="BillingAnnually border-0 pt-0 pb-4">
                    <div className="BillingAnnuallyLeft">
                      <h3>Order Summary</h3>
                    </div>
                    <div class="CurrencyOverlay">
                    <img src="images/usd.png"/>
                    <div class="CurrencyOverlaySelect">
                      <select name="currencyType" value={currencyType} onChange={handleInput}>
                        <option value={"usd"}>USD</option>
                        <option value={"cad"}>CANADIAN</option>
                      </select>
                      <span><img src="images/black-down-arrow.png"/></span>
                    </div>
                    {/* <span style={{color:"red", fontSize:"14px"}}>{paymentMethodErrors?.cvvError}</span> */}
                  </div>
                    {/* <div className="BillingAnnuallyRight">
                      <h6>$ 20.05</h6>
                    </div> */}
                  </div>
                  <div className="BillingAnnually">
                    <div className="BillingAnnuallyLeft">
                      <h3>{planDetailsData?.[0]?.planName}</h3>
                      <h6>Annual Subscription</h6>
                    </div>
                    <div className="BillingAnnuallyRight">
                    <h6>{currencyType === "usd" ? <>
                          <h6>$  {price ? price : 0}</h6>
                    </> : <>
                    <h6>$  {price ? (price * 0.80).toFixed(2) : 0}</h6>
                    </>}</h6>
                    </div>
                  </div>
                  <div className="BillingAnnually">
                    <div className="BillingAnnuallyLeft">
                      <h3>Total Billed </h3>
                      {/* <p>Next charge date Jul 14, 2024</p> */}
                      <p>
                        Based on the billing information you have provided, your
                        purchase may be subject to local taxes. The final charge
                        may be different than the amount shown here and will be
                        displayed on your invoice
                      </p>
                    </div>
                    <div className="BillingAnnuallyRight">
                    <h6>{currencyType === "usd" ? <>
                          <h6>$  {price ? price : 0}</h6>
                    </> : <>
                    <h6>$  {price ? (price * 0.80).toFixed(2) : 0}</h6>
                    </>}</h6>
                    </div>
                  </div>
                  <div className="BillingAnnually">
                    <div className="BillingAnnuallyLeft">
                      <h3>Today's Total</h3>
                    </div>
                    <div className="BillingAnnuallyRight">
                    
                      <h6>{currencyType === "usd" ? <>
                          <h6>$  {price ? price : 0}</h6>
                    </> : <>
                    <h6>$  {price ? (price * 0.80).toFixed(2) : 0}</h6>
                    </>}</h6>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button onClick={addSubscription} className="PlanContinueBtn">Place Order</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
        show={SubscriptionCompleteModal}
        onHide={SubscriptonCompleteModalClose}
        className="ModalBox MediumModal"
  >
        <Modal.Body >     
        <div>
        <div >
          <a
          onClick={SubscriptonCompleteModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand ModalTitleBoxBrandOrange">
              <h4>Multi-Family and Residential Platform</h4>
              <a href="">
                <img src="images/logo-orange.png" />
              </a>
            </div>
            <div className="FolderForm">
              <form>
                <div className="SubscriptionArea">
                  <h3>Subscription Completed Successfully!</h3>
                  <div className="BuilderArea">
                    <h4>Builder 1,</h4>
                    <p>
                      Welcome to Ibuild Multi-Family and Residential Platform!
                    </p>
                    <p>Here is a summary of your subscription details:</p>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="SelectPlanArea">
                        <h6>Selected Plan</h6>
                        <h6>Plan 1</h6>
                        <p>Billing: ${afterSubscriptionData?.existingPlan?.price}/{afterSubscriptionData?.existingPlan?.interval}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="SelectPlanArea">
                        <h6>Payment Method</h6>
                        <p className="fw-500 mb-2 text-decoration-underline">
                        {afterSubscriptionData?.paymentMethod}
                        </p>
                        {/* <p>
                          Mastercard ending 5454 Grand Total (GST 5% included){" "}
                          <span>$yyy.yy</span>
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="CompanyDetailsTable">
                    <h6>Company Details</h6>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Primary phone Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{afterSubscriptionData?.company?.name}</td>
                          <td>{afterSubscriptionData?.company?.email}</td>
                          <td>{afterSubscriptionData?.company?.primaryNumber}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="AddressArea">
                    <h5>Address</h5>
                    <p>Address</p>
                    <p>
                    {afterSubscriptionData?.company?.streetAddressLine1} {" "} {afterSubscriptionData?.company?.streetAddressLine2} <br />
                    {afterSubscriptionData?.company?.city}  {afterSubscriptionData?.company?.state} <br />
                      {afterSubscriptionData?.company?.country}
                    </p>
                  </div>
                  <div className="SetToStartArea">
                    <h6>You are all set to start!</h6>
                    <p>
                      Please use the following <a onClick={handleLoginDetailsShow} >login link</a> to get
                      started and the email and password informed during the
                      subscription process.
                    </p>
                    <p>
                      A copy of your order, invoice and login details was sent
                      to your email. If you have any questions you can reach us
                      at <a >support@ibuildapplications.com</a> or{" "}
                      <a href="">(587) 444-4444.</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
     
        </Modal.Body>
      </Modal>
      <Modal
        show={loginDetailsModal}
        onHide={handleLoginDetailsClose}
        className="ModalBox MediumModal"
  >
        <Modal.Body >     
        <div>
        <div>
          <a
           onClick={handleLoginDetailsClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand ModalTitleBoxBrandOrange">
              <h4>Multi-Family and Residential Platform</h4>
              <a href="">
                <img src="images/logo-orange.png" />
              </a>
            </div>
            <div className="FolderForm">
              <form>
                <div className="SubscriptionArea">
                  <h3>Login Details</h3>
                  <div className="BuilderArea">
                    <h4>Builder 1,</h4>
                    <p>
                      Welcome to{" "}
                      <span>Ibuild Multi-Family and Residential Platform!</span>
                    </p>
                  </div>
                  <div className="SetToStartArea mb-4">
                    <h6>You are all set to start!</h6>
                    <p className="text-grey-80">
                      Please find bellow email and password created during
                      subscription process that must be used during initial
                      login in the platform.
                    </p>
                  </div>
                  <div className="LoginDetailsBox">
                    <h6>Email</h6>
                    <p>{afterSubscriptionData?.email}</p>
                  </div>
                  <div className="LoginDetailsBox">
                    <h6>Password</h6>
                    <p>{afterSubscriptionData?.password}</p>
                  </div>
                  <div className="LoginDetailsBox">
                    <h6>Login Link</h6>
                    <Link to="/">
                      https://ibuildapplications.com/login
                    </Link>
                  </div>
                  <div className="SetToStartArea">
                    <p>
                      If you have any questions or experience any problem you
                      can reach us at{" "}
                      <a href="javascript:vid(0);">
                        support@ibuildapplications.com
                      </a>
                      or <a href="javascript:vid(0);">(587) 444-4444.</a>
                    </p>
                    <p>
                      You can find additional documentation and tutorials
                      regarding the use of the platform at this{" "}
                      <a href="javascript:vid(0);">link</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        </Modal.Body>
      </Modal>

      </div>
    </>
  );
};

export default CheckoutScreen;
