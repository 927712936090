import React, { useState,useRef, useEffect } from 'react'
import ClientHeader from './ClientHeader'
import ClientViewSidenav from './client-view-sidenav'
import { checkArray } from "../utils/CheckType";
import { convertToPNG, dataURLtoBlob } from '../utils/blog';
import { addSignatureAction } from '../redux/action/clientDocumentationAction';
import { useDispatch } from 'react-redux';
import { errorToast, sucessToast } from "../utils/toast";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import { getDate } from '../utils/uploadFile';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';




import {
  
  ContractDetailsAction,
  getAllClientAction,
  getClientCtcByTemplate,
  sendForClientSignatures,

} from "../redux/action/saleAction";
import { getTaxProfileList } from '../redux/action/companyAction';
const initModal = {
  
}
const initLeadState = {
  signature:"",
  modalId:"",
  initial:"",
  signatureErros:{}
}
const ClientCTCSales = () => {

  const [contactDetails, setContactDetails] = useState([]);


  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const {
   
    loader,
  
    allClientListData,
    clientCtcByTemplate

  } = useSelector((state) => state.salesReducer);
  const { getTaxProfile } = useSelector((state) => state.companyReducer);

  const [signedDate, setSignedDate] = useState("")


  const initModal = {
    AddLeadModal: false,
    contactInformationModal: false,
    assignSaleModal: false,
    AddContractModal: false,
    ClientDocumentationModal: false,
    sendClientInformationModal: false,
    createClientCTCModal: false,
    manageSignatureModal: false,
    folderHistoryModal: false,
    documentHistoryModal: false,
    discussionModal: false,
    editFileModal: false,
    shareDocumentsModal: false,
    editLeadDetailsModal: false,
    editContactInformationModal: false,
    editSurveyModal: false,
    supportingDocsModal: false,
    SystemVendorModal: false,
    viewDocumentModal: false,
    locationModal: false,
    createNewFileModal: false,
    uploadFilesModal: false,
    leadClientDetailsShow: false,
    editActionLeadDetailsModal: false,
    scheduleNewFollowModal: false,
    convertLeadToContractModal: false,
    editFollowUpActivityModal: false,
    addItineraryModal: false,
    setReminderModal: false,
    modalState: "",
    newEmailModal: false,
    newTextModal: false,
    adjustmentStatementModal: false,
    supportingDocumentListShow: false,
    mainContractEditModal: false,
    contractAssignUnitModal: false,
    contractTransactionsModal: false,
    contractSchedulePaymentsModal: false,
    contractAddSupportingModal: false,
    ContractSupportingDocsModal: false,
    editSupportingModal: false,
    scheduledPaymentModal: false,
    modalId: "",
    secondaryId: "",
    thirdId: "",
    leadClientDetailsDataTemp: [],
    setReminderState: "",
    addFolderModal: false,
    addSurveyModal: false,
    showHideCheck: true,
    SOAModal: false,
    addUnlockConditionalModal: false,
    unlockConditonListModal: false,
    clientAssignUnitModal: false,
    SOAHistoryModal: false,
    heading: "",
    avjobId: "",
    DocumentKeyModal: false,
    CTCTemplateReleasedModal: false,
    ViewCtcModal: false,
    FollowUpActivityModal: false,
    SendForClientModal: false,
    assignsaleDetailsModal: false,
    clientSignatureModal: false,
    clientHistoryModal: false,
    assignsaleDetailsModal: false,
    primarySignature: null,
    secondarySignature: null,
    additionalSignature: null,
    builderSignature: null,
    ManageSignature: false,
    signTypes: "",
    primarySignatureBlob: "",
    secondarySignatureBlob: "",
    builderSignatureBlob: "",
    additionalSignatureBlob: "",
    fileNames: "",
    SignModal: false,
    afterDraftViewCTCData: [],
    addClientModal: false,
    ctcSignature:"",
    ctcSignatureBlob:"",
    clientSignatureModal:false,
  clientHistoryModal:false,
  ctcSignatureBuilder:"",
    ctcSignatureBuilderBlob:"",
  };


  const [signedDateBuilder, setSignedDateBuilder] = useState("")
  
  const [rejectCtcDocModal, setrejectCtcDocModal] = useState(false)
  const [sendClientInfoModal, setsendClientInfoModal] = useState(false)
  const [sendClientDropdown, setsendClientDropdown] = useState(false)
  const[iModalState , updateModalState] = useState(initModal)
  const[iLeadState, updateLeadState] = useState(initLeadState)
  const dispatch = useDispatch();
  const location = useLocation();
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1].split("#")[0]

    
  );


  const {
    AddLeadModal,
    contactInformationModal,
    assignSaleModal,
    AddContractModal,
    ClientDocumentationModal,
    sendClientInformationModal,
    createClientCTCModal,
    manageSignatureModal,
    folderHistoryModal,
    documentHistoryModal,
    discussionModal,
    shareDocumentsModal,
    editFileModal,
    editLeadDetailsModal,
    editContactInformationModal,
    editSurveyModal,
    supportingDocsModal,
    SystemVendorModal,
    viewDocumentModal,
    locationModal,
    createNewFileModal,
    uploadFilesModal,
    leadClientDetailsShow,
    editActionLeadDetailsModal,
    scheduleNewFollowModal,
    convertLeadToContractModal,
    editFollowUpActivityModal,
    addItineraryModal,
    setReminderModal,
    newEmailModal,
    newTextModal,
    modalState,
    adjustmentStatementModal,
    supportingDocumentListShow,
    mainContractEditModal,
    updateSatisfiedConditionModal,
    contractAssignUnitModal,
    contractTransactionsModal,
    contractSchedulePaymentsModal,
    contractAddSupportingModal,
    ContractSupportingDocsModal,
    editSupportingModal,
    scheduledPaymentModal,
    setReminderState,
    secondaryId,
    leadClientDetailsDataTemp,
    addSurveyModal,
    addFolderModal,
    thirdId,
    SOAModal,
    addUnlockConditionalModal,
    unlockConditonListModal,
    clientAssignUnitModal,
    SOAHistoryModal,
    DocumentKeyModal,
    avjobId,
    ViewCtcModal,
    CTCTemplateReleasedModal,
    FollowUpActivityModal,
    SendForClientModal,
    assignsaleDetailsModal,
    clientSignatureModal,
    clientHistoryModal,
    primarySignature,
    secondarySignature,
    additionalSignature,
    builderSignature,
    ManageSignature,
    signTypes,
    primarySignatureBlob,
    secondarySignatureBlob,
    builderSignatureBlob,
    additionalSignatureBlob,
    fileNames,
    SignModal,
    afterDraftViewCTCData,
    addClientModal,
    ctcSignature,
    ctcSignatureBlob,
    ctcSignatureBuilderBlob,
    ctcSignatureBuilder,
  } = iModalState;
  const CompanyId = urlParams?.get("companyId");
  const clientCTCId = urlParams?.get("clientCTCId");
  const contractId = urlParams?.get("contractId");
  const token = urlParams?.get("token");
  const [clientCtcByTemplateData, setClientCtcByTemplateData] = useState({});

  console.log("CompanyId", CompanyId)
  console.log("clientCTCId", clientCTCId)
  console.log("tokennnnnn", token)
  console.log("clientId",contractId)
  const{signature,
    modalId,
    signatureErrors,
    initial} = iLeadState ;
    const signCanvas = useRef({});
  const intialCanvas = useRef({});
  

  const showClientSignatureModal = () =>{
    updateModalState({...iModalState, clientSignatureModal:true})
  }

  const hideClientSignatureModal = () =>{
    updateModalState({...iModalState, clientSignatureModal:false})
  }

  const showClientHistoryModal = () =>{
    updateModalState({...iModalState, clientHistoryModal:true})
   }
 
   const hideClientHistoryModal = () =>{
     updateModalState({...iModalState, clientHistoryModal:false})
   }
  const clearSignature = () => {
    signCanvas.current.clear();
  };
  const handleSignatureValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!signature) {
      error.signatureError = "* Signature can't be empty";
      formIsValid = false;
    }

   

    updateLeadState({ ...iLeadState, signatureErrors: error });
    return formIsValid;
  };
  const addSignatureFun = (e) => {
    e.preventDefault();
    sucessToast("Signature added successfully")
    updateModalState({ ...iModalState, manageSignatureModal: true });
    let formIsValid = handleSignatureValidation();
    if (formIsValid) {
      const signatureBlob = dataURLtoBlob(signature);
      const initialBlob = dataURLtoBlob(initial);

      const convertToValidFormat = (blob, fileName) => {
        const fileExtension = fileName.split(".").pop().toLowerCase();
        if (
          ![
            "jpg",
            "jpeg",
            "png",
            "gif",
            "pdf",
            "bmp",
            "webp",
            "svg+xml",
          ].includes(fileExtension)
        ) {
          const convertedBlob = convertToPNG(blob);
          return convertedBlob;
        }
        return blob;
      };
      const validSignatureBlob = convertToValidFormat(
        signatureBlob,
        "signature.png"
      );
      const validInitialBlob = convertToValidFormat(initialBlob, "initial.png");
      const formData = new FormData();
      formData.append("signature", validSignatureBlob, "signature.png");
      formData.append("initial", validInitialBlob, "initial.png");
      formData.append("imageOwner", "test");
      dispatch(addSignatureAction(formData, modalId))
        .then((res) => {
          sucessToast("Signaute updated successfully");
          updateModalState({ ...iModalState, manageSignatureModal: true });
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          hideClientSignatureModal();
        });
    }
  };

  

  const totalClientTotal = clientCtcByTemplateData?.formatedData?.[0]?.assemblies?.[0]?.purchaseDetails?.reduce((total, item) => {
    return total  +(item?.clientTotal * +item?.quantity)-item?.unitDiscount;
}, 0)


  useEffect(()=>{
    dispatch(getAllClientAction(contractId));
    dispatch(getClientCtcByTemplate(contractId, clientCTCId));
    dispatch(getTaxProfileList())
    dispatch(ContractDetailsAction(contractId)).then((res) => {
      if (res) {
        setContactDetails(res?.setContactDetails);
        setContactDetails(res?.contract?.[0]);

      }
    });



  },[])

  useEffect(() => {
    setClientCtcByTemplateData(clientCtcByTemplate?.data);
  }, [clientCtcByTemplate]);


  const handleSendClientSignatures = () => {
    dispatch(
      sendForClientSignatures(
        clientCtcByTemplateData?.ctc?.ContractId,
        clientCtcByTemplateData?.ctc?.id
        //{state:"SEND"}
      )
    )
      .then((res) => {
        console.log("SEND EMAIL =============>")
        if (res.status == 201) {
          sucessToast("Send Signture Successfully");
          updateModalState({ ...iModalState, ViewCtcModal: false });
          updateModalState({
            ...iModalState,
            ClientDocumentationModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateModalState({ ...iModalState, ViewCtcModal: true });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({ ...iModalState, ViewCtcModal: false });
      });
  };


  const handleManageSignatueShow = (type) => {
    if (type == "primarySignature") {
      updateModalState({
        ...iModalState,
        ManageSignature: true,
        primarySignature: "",
        signTypes: type,
      });
    }
    if (type == "secondarySignature") {
      updateModalState({
        ...iModalState,
        ManageSignature: true,
        secondarySignature: "",
        signTypes: type,
      });
    }
    if (type == "builderSignature") {
      updateModalState({
        ...iModalState,
        ManageSignature: true,
        builderSignature: "",
        signTypes: type,
      });
    }
    if (type == "additionalSignature") {
      updateModalState({
        ...iModalState,
        ManageSignature: true,
        additionalSignature: "",
        signTypes: type,
      });
    }


    if (type == "ctcSignature") {
      updateModalState({
        ...iModalState,
        ManageSignature: true,
        ctcSignature: "",
        signTypes: type,
      });
    }

    if (type == "ctcSignatureBuilder") {
      updateModalState({
        ...iModalState,
        ManageSignature: true,
        ctcSignatureBuilder: "",
        signTypes: type,
      });
    }
  };
  const handleManageSignatueClose = () => {
    updateModalState({
      ...iModalState,
      ManageSignature: false,
      signature: "",
      signTypes: "",
    });
  };


    const showManageSignatureModal = (id) => {
    updateModalState({
      ...iModalState,
      manageSignatureModal: true,
      ClientDocumentationModal: false,
    });
  };
  const hideManageSignatureModal = () => {
    updateModalState({
      ...iModalState,
      manageSignatureModal: false,
      ClientDocumentationModal: true,
    });
    updateLeadState({ ...iLeadState, signature: "", initial: "" });
  };
  const addSignature = () => {
    const signatureBlob = dataURLtoBlob(signature);
    const convertToValidFormat = (blob, fileName) => {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (
        ![
          "jpg",
          "jpeg",
          "png",
          "gif",
          "pdf",
          "bmp",
          "webp",
          "svg+xml",
        ].includes(fileExtension)
      ) {
        const convertedBlob = convertToPNG(blob);
        return convertedBlob;
      }
      return blob;
    };
    const validSignatureBlob = convertToValidFormat(
      signatureBlob,
      "signature.png"
    );
    const validSignatureBlobURL = URL.createObjectURL(validSignatureBlob);

    if (signTypes === "primarySignature") {
      updateModalState({
        ...iModalState,
        primarySignature: validSignatureBlobURL,
        primarySignatureBlob: validSignatureBlob,
        ManageSignature: false,
      });
    } else if (signTypes === "secondarySignature") {
      updateModalState({
        ...iModalState,
        secondarySignature: validSignatureBlobURL,
        secondarySignatureBlob: validSignatureBlob,
        ManageSignature: false,
      });
    } else if (signTypes === "builderSignature") {
      updateModalState({
        ...iModalState,
        builderSignature: validSignatureBlobURL,
        builderSignatureBlob: validSignatureBlob,
        ManageSignature: false,
      });
    } else if (signTypes === "additionalSignature") {
      updateModalState({
        ...iModalState,
        additionalSignature: validSignatureBlobURL,
        additionalSignatureBlob: validSignatureBlob,
        ManageSignature: false,
      });
    

  } else if (signTypes === "ctcSignature") {
    updateModalState({
      ...iModalState,
      ctcSignature: validSignatureBlobURL,
      ctcSignatureBlob: validSignatureBlob,
      ManageSignature: false,
    });
  

} else if (signTypes === "ctcSignatureBuilder") {
  updateModalState({
    ...iModalState,
    ctcSignatureBuilder: validSignatureBlobURL,
    ctcSignatureBuilderBlob: validSignatureBlob,
    ManageSignature: false,
  });

}}

  return (
    <>
      {/* <ClientHeader />
        <ClientViewSidenav /> */}
   <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View Client's CTC</h4>
            </div>
            <div className="FolderForm">
              <div className="ContactInformationArea">
                <h6>Contact Information</h6>
                <div className="ContactInformationBox">
                  <span className="circle">JL</span>
                  <div className="ContactInformationName">
                    <h6>{allClientListData?.clientDetails?.[0]?.legalName}</h6>
                    <p>
                      {allClientListData?.clientDetails?.[0]?.streetAddress},{" "}
                      {allClientListData?.clientDetails?.[0]?.city}{" "}
                      <img src="images/location.png" />
                    </p>
                    <p>
                      Phone:{" "}
                      <span>
                        {
                          allClientListData?.clientDetails?.[0]
                            ?.businessPhoneNum
                        }
                      </span>
                    </p>
                    <p>
                      Email:{" "}
                      <span>
                        {allClientListData?.clientDetails?.[0]?.email}
                      </span>
                    </p>
                  </div>
                  <div className="AssignUnit">
                    <h4>Assigned Unit</h4>
                    <p>U222-CHI-2B-243 (Unit #222)</p>
                  </div>
                </div>
              </div>
              <div className="AgreementViewAreaGreen mt-3">
              <table>
                  <thead>
                    <tr>
                  
                      <th>Name</th>
                      <th>Pictures</th>
                      <th>Unit Cost</th>
                      <th>Unit Discount</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkArray(clientCtcByTemplateData?.formatedData) &&
                      clientCtcByTemplateData?.formatedData?.map(
                        (ele, i) => {
                          return (
                            <>
                              <tr className="DarkBlueBg" key={`ele-${i}`}>
                               
                                <td colSpan={6}>{ele?.name}</td>
                              </tr>
                              {checkArray(ele?.assemblies) &&
                                ele?.assemblies?.map((item, j) => {
                                  return (
                                    <>
                                      <tr
                                        className="LightBlueBg"
                                        key={`item-${j}`}
                                      >
                                        <td>
                                          {/* <label className="CheckBox">
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label> */}
                                        </td>
                                        <td colSpan={6}>{item?.name}</td>
                                      </tr>
                                      {checkArray(item?.purchaseDetails) &&
                                        item?.purchaseDetails?.map(
                                          (result, k) => {
                                            return (
                                              <tr key={`result-${k}`}>
                                             
                                                <td>{result?.name}</td>
                                                <td>
                                                  <div>
                                                    <img
                                                      src="images/picture-1.png"
                                                      alt="Thumbnail"
                                                    />
                                                  </div>
                                                </td>
                                                <td>{result?.clientTotal}</td>
                                                <td>
                                                  {result?.unitDiscount}
                                                </td>  

                                                   <td>
                                                  {result?.quantity}
                                                </td> 

                                                     <td>
                                                  {result?.quantity * result?.clientTotal- result?.unitDiscount}
                                                </td>            
                                                
                                                        </tr>
                                            );
                                          }
                                        )}
                                    </>
                                  );
                                })}
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
                <div className="CostBreakDownArea">
                  <h5 className="TableHeading CursorPointer" id="CostBreakDown">
                    Cost Breakdown <img src="images/down-arrow.png" />
                  </h5>
                  <div className="CostBreakDownShow">
                    <div className="Box">
                      <h6>Selections Details</h6>
                    </div>
                    <div className="Box DarkBlueBg">
                      <h6>Options Sub-Total</h6>
                      <h6>${totalClientTotal}</h6>
                    </div>
                    <div className="Box LightBlueBg">
                      <h6>Tax {getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent}%</h6>
                      <h6>$ {((totalClientTotal * getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) / 100).toFixed(2)}</h6>
                    </div>
                    <div className="Box NavyBlueBg">
                      <h6>Total</h6>
                      <h6>
                        ${" "}
                        {(totalClientTotal+(totalClientTotal * getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) / 100).toFixed(2)}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="CostBreakDownArea">
                  <h5 className="TableHeading  my-4">
                    Cost Breakdown With Options
                  </h5>
                  <div className="Box DarkBlueBg">
                    <h6>Contract Price Before Tax</h6>
                    <h6>
                      $
                      {
                        contactDetails?.clientPurchasePrice
                      }
                    </h6>
                  </div>
                  <div className="Box LightBlueBg">
                    <h6>Change Order Total</h6>
                    <h6>
                      ${(totalClientTotal+(totalClientTotal * getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) / 100).toFixed(2)}
                    </h6>
                  </div>
                  <div className="Box DarkBlueBg">
                    <h6>Sub Total</h6>
                    <h6>
                      {totalClientTotal}
                    </h6>
                  </div>
                  <div className="Box LightBlueBg">
                    <h6>Tax {getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent}%</h6>
                    <h6>$ {((totalClientTotal * getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) / 100).toFixed(2)}</h6>

                  </div>
                  <div className="Box LightBlueBg">
                    <h6>Adjustment: Applied Tax Rebate</h6>
                    <h6>
                      -${getTaxProfile?.[0]?.taxRebates?.[0]?.amount}
                    </h6>
                  </div>
                  <div className="Box NavyBlueBg">
                    <h6>Total</h6>
                    <h6>
                      {parseFloat( ((totalClientTotal * getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) / 100).toFixed(2)-getTaxProfile?.[0]?.taxRebates?.[0]?.amount+            totalClientTotal+          contactDetails?.clientPurchasePrice
                        +totalClientTotal+(totalClientTotal * getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) / 100).toFixed(2)}{" "}
                    </h6>
                  </div>
                </div>
                
                <div className="ChangeOrderCreatArea mt-5">
                <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <div className="InputBoxSmall">
                            <h6>                        <input type="date" value={moment(signedDate).format("YYYY-MM-DD")}  onChange={(e)=> setSignedDate(e.target.value)}/>
                           </h6>
                          </div>
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                        {ctcSignature ? (
                              <a onClick={()=>handleManageSignatueShow("ctcSignature")}>
                                <img style={{height:"80px", width:"150px"}} src={ctcSignature} />
                              </a>
                            ):
                            <a
                            onClick={()=>handleManageSignatueShow("ctcSignature")}
                              className="FolderPermissionId"
                              
                            >
                              <img src="images/insert_signature.png" />
                              Insert Signature
                            </a>}
                          <label>{allClientListData?.clientDetails?.[0]?.legalName}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="InputBox border-0 text-center">
                        <h6>
                          Signed on {signedDate&& moment(signedDate).format('LLL')} <br />
                          by {allClientListData?.clientDetails?.[0]?.legalName} <br />
                          (mailto:{allClientListData?.clientDetails?.[0]?.email})
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <div className="InputBoxSmall">
                            <h6>                        <input type="date" value={moment(signedDateBuilder).format("YYYY-MM-DD")}  onChange={(e)=> setSignedDateBuilder(e.target.value)}/>
                           </h6>
                          </div>
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                        {ctcSignatureBuilder ? (
                              <a onClick={()=>handleManageSignatueShow("ctcSignatureBuilder")}>
                                <img style={{height:"80px", width:"150px"}} src={ctcSignatureBuilder} />
                              </a>
                            ):
                            <a
                            onClick={()=>handleManageSignatueShow("ctcSignatureBuilder")}
                              className="FolderPermissionId"
                              
                            >
                              <img src="images/insert_signature.png" />
                              Insert Signature
                            </a>}
                          <label>{allClientListData?.clientDetails?.[0]?.legalName} Builder Company</label>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                 
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <div className="Button dropdown show">
                  <button
                    className="Create px-4"
                    type="button"
                    onClick={()=> setsendClientDropdown(sendClientDropdown?false:true)}
                  >
                    Send for client signature{" "}
                    <img src="images/down-arrow-white.png" />
                  </button>
            
                  <div
                    className={sendClientDropdown?"dropdown-menu show":"dropdown-menu"}
                    aria-labelledby="dropdownMenuButton"
                    style={{position:"absolute", transform:"translate3d(0px, -120px, 0px)", top:"0px", left:"0px" ,willChange:"transform"}}
                  >
                    <a
                      className="dropdown-item FolderPermissionId"
                      onClick={handleSendClientSignatures}
                    >
                      Send for Client Signature
                    </a>
                    <a class="dropdown-item" href="javascript:void(0);">
                      Confirm Signature
                    </a>
                    {/* <a
                      onClick={showClientHistoryModal}
                      class="dropdown-item"
                    >
                      view History
                    </a> */}

                    <a
              className="dropdown-item FolderPermissionId"

              onClick={()=> setrejectCtcDocModal(true)}
              data-toggle="modal"
              data-target="#TwoRejectReturnCtcMaster"
              data-dismiss="modal"
            >
              Reject/Return
            </a>
                    {/* <a
              className="dropdown-item FolderPermissionId"
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#TwoRejectReturnCtcMaster"
              data-dismiss="modal"
            >
              Reject/Return
            </a>
            <a
            
                    {/* <a class="dropdown-item FolderPermissionId" href="javascript:void(0);" data-toggle="modal" data-target="#TwoGenerateChangeOrderCtc" data-dismiss="modal">Generate Change Order List</a>
                  <a class="dropdown-item FolderPermissionId" href="javascript:void(0);" data-toggle="modal" data-target="#TwoChangeOrderCtc" data-dismiss="modal">View Generated Change Order</a> */}
                  </div>
                </div>
            
                <button className="Outline" >
                  Cancel
                </button>
              </div>
            </div>
          </div>
<Modal
        show={clientSignatureModal}
        onHide={hideClientSignatureModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideClientSignatureModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new signature</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/add_new_signature.png" />
                        </figure>{" "}
                        Add new signature
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Signatures</h5>
                    <div className="CommonModalBox">
                      {/* <input
                        // type="file"
                        // name="signature"
                        // onChange={handleInputFile}
                      /> */}
                    <SignaturePad
                        ref={signCanvas}
                        penColor="green"
                        canvasProps={{
                          width: 400,
                          height: 300,
                          className: "sigCanvas",
                        }}
                        onEnd={() =>
                          updateLeadState({
                            ...iLeadState,
                            clientSignature: signCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearSignature} href="javascript:void(0);">
                        <img src="images/circle_minus.png" />
                      </a>
                      {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {signatureErrors?.signatureError}
                    </span>
                  </div>
                </div>
             
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={addSignatureFun}>
                    Save
                  </button>
                  <button
                    className="Outline"
                    onClick={hideClientSignatureModal}
                  >
                    Cancel
                  </button>
                </div>
              
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={clientHistoryModal}
        onHide={hideClientHistoryModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideClientHistoryModal }
          >
            ×
          </a>
          <div className="FormArea">
        <div className="ModalTitleBox">
          <h4>CTC History</h4>
        </div>
        <div className="JobTitleArea mb-2">
          <div className="JobBox">
            <h6><span>Document:</span>&nbsp;Sales Contract.pdf</h6>
          </div>
        </div>
        <div className="TableList CommonHeading TableHeightScroll">
          <table>
            <thead>
              <tr>
                <th>Modified By</th>
                <th>Created By</th>
                <th>Date</th> 
                {/* /<th>View</th> */}
                <th>Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>John Doe</td>
                <td>Jane Williams</td>
                <td>04/25/2023</td>
                {/* <td>
                  <a href="javascript:void(0);" className="FolderPermissionId" data-toggle="modal" data-target="#TwoSignClientCtcThird" data-dismiss="modal"><i className="fa fa-eye" /></a>
                </td> */}
                <td>File Shared by the trade</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ManageSignature}
        onHide={handleManageSignatueClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handleManageSignatueClose}>
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new signature</h5>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea border p-2">
                  <h5>Signatures</h5>
                  <div className="CommonModalBox">
                    <SignaturePad
                      ref={signCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        updateLeadState({
                          ...iLeadState,
                          signature: signCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearSignature}>
                      <img src="images/circle_minus.png" />
                    </a>
                    {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                    {errors?.signatureError}
                  </span> */}
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addSignature}>
                  Save
                </button>
                <button className="Outline" onClick={handleManageSignatueClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default ClientCTCSales