import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { UserDropdownAction } from "../../redux/action/dropdownAction";
import { addContactAction, addOtherContactAction, contactListAction } from "../../redux/action/profileAction";
import { ContactListAction, addVendorAction } from "../../redux/action/vendorAction";
import {toast} from 'react-toastify'
import { getAllIbuildAdminUserListAction } from "../../redux/action/ibuildAdminRoleManagement";

const initialSate = {
  userType: "",
  userData: {},
  userId: "INTERNAL",
  name: "",
  companyId: "",
  email: "",
  phone: "",
  role: "",
};
const AddContact = ({ SetAddContact }) => {
  const dispatch = useDispatch();
  const handleAddContactModalClose = () => SetAddContact(false);
  const handleAddContactModalShow = () => {
    SetAddContact(true);
  };

  const [iState, updateState] = useState(initialSate);

  const { userList } = useSelector((state) => state.dropdownReducer);
 

  const { userType, userId, name, companyId, email, phone, role, userData } =
    iState;

  const handleCheck = (type) => {
    updateState({ ...iState, userType: type });
  };

  
  const {
    countryData,
    cityData,
    stateData,
    timeZoneData,
    headerNotificationListData,
    iBuildAdminUserListData,
  } = useSelector((state) => state.authReducer);
  console.log("iBuildAdminUserListData",iBuildAdminUserListData)
  useEffect(() => {
    // dispatch(getAllIbuildAdminUserListAction())
    dispatch(UserDropdownAction());
  }, []);

  useEffect(() => {
    let temp = userList?.filter((item) => item?.id == userId);

    if (temp) {
      updateState({ ...iState, userData: temp[0] });
    }
  }, [userId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateState({ ...iState, stopper: true });
    let formIsValid = true;

    if (formIsValid) {
    // if(userType =="INTERNAL"){
      dispatch(
        addContactAction({
          userId,
          email: userData?.email,
          phoneNumber: userData?.phoneNumber,
          role: userData?.roleName,
          companyId: userData?.CompanyId,
          type:userType,
          name:userData?.name,
          phone:userData?.phoneNumber,
          // roleName:
        })
      )
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            toast.success("Contact added  Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            dispatch(contactListAction())
            updateState({
              ...iState,
              stopper: false,
              userId: "",
              name: "",
              email: "",
              phoneNumber: "",
              RoleId: "",
              
            });
            SetAddContact(false);
          } else {
            updateState({ ...iState, stopper: false });

            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          updateState({ ...iState, stopper: false });

          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    // }



    // if(userType =="vendor"){
    //   dispatch(
    //     addVendorAction({
    //       userId,
    //       email: userData?.email,
    //       phoneNumber: userData?.phoneNumber,
    //       role: userData?.RoleId,
    //       companyId: userData?.CompanyId,
    //     })
    //   )
    //     .then((res) => {
    //       if (res.status >= 200 && res.status <= 300) {
    //         toast.success("Contact added  Successfully", {
    //           position: toast.POSITION.TOP_RIGHT,
    //           autoClose: 1000,
    //         });
    //         updateState({
    //           ...iState,
    //           stopper: false,
    //           userId: "",
    //           name: "",
    //           email: "",
    //           phoneNumber: "",
    //           RoleId: "",
    //         });
    //         SetAddContact(false);
    //       } else {
    //         updateState({ ...iState, stopper: false });

    //         toast.error(res.data.message, {
    //           position: toast.POSITION.TOP_RIGHT,
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       updateState({ ...iState, stopper: false });

    //       toast.error(error.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     });
    // }


    // if(userType =="other"){
    //   dispatch(
    //     addOtherContactAction({
    //       userId,
    //       email: userData?.email,
    //       phoneNumber: userData?.phoneNumber,
    //       role: userData?.RoleId,
    //       companyId: userData?.CompanyId,
    //     })
    //   )
    //     .then((res) => {
    //       if (res.status >= 200 && res.status <= 300) {
    //         toast.success("Contact added  Successfully", {
    //           position: toast.POSITION.TOP_RIGHT,
    //           autoClose: 1000,
    //         });
    //         updateState({
    //           ...iState,
    //           stopper: false,
    //           userId: "",
    //           name: "",
    //           email: "",
    //           phoneNumber: "",
    //           RoleId: "",
    //         });
    //         SetAddContact(false);
    //       } else {
    //         updateState({ ...iState, stopper: false });

    //         toast.error(res.data.message, {
    //           position: toast.POSITION.TOP_RIGHT,
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       updateState({ ...iState, stopper: false });

    //       toast.error(error.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     });
    // }



    }
  };



  return (
    <Modal show={AddContact} className="ModalBox LargeModal">
      <div class="modal-content">
        <a
          onClick={handleAddContactModalClose}
          class="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>{" "}
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Add Contact</h4>
          </div>
          <div class="FolderForm">
            <form>
              <div class="form-group d-flex justify-content-between pr-5  mb-4">
                <label class="Radio">
                  {" "}
                  Internal User
                  <input
                    type="radio"
                    onChange={() => handleCheck("INTERNAL")}
                    name="Cuisine"
                    value="InternalUser"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="Radio">
                  {" "}
                  Vendor
                  <input
                    type="radio"
                    name="Cuisine"
                    value="Vendor"
                    onChange={() => handleCheck("VENDOR")}
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="Radio">
                  {" "}
                  Other
                  <input
                    type="radio"
                    name="Cuisine"
                    value="Other"
                    onChange={() => handleCheck("OTHER")}
                  />
                  <span class="checkmark"></span>
                </label>
              </div>

              <div class="InternalUser RadioText" style={{ display: "block" }}>
                <div class="form-group">
                  <h6>Users</h6>
                  <div class="Categories Categories1">
                    <select
                      name="UserId"
                      className="form-control"
                      onChange={(e) =>
                        updateState({ ...iState, userId: e.target.value })
                      }
                    >
                      <option value="">Select users</option>
                      {userList?.map((user) => (
                        <option value={user?.id}>{user?.name}</option>
                      ))}
                    </select>

                  </div>
                </div>
                <div class="form-group">
                  <h6>
                    Name <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    disabled={true}
                    class="form-control"
                    value={userData?.name}
                    placeholder="Auto-filled by System"
                  />
                </div>
                <div class="form-group">
                  <h6>
                    Email <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    value={userData?.email}
                    class="form-control"
                    placeholder="Auto-filled by System"
                  />
                </div>
                <div class="form-group">
                  <h6>
                    Phone <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    value={userData?.phoneNumber}
                    class="form-control"
                    placeholder="Auto-filled by System"
                  />
                </div>
                <div class="form-group">
                  <h6>
                    Company <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    value={userData?.companyId}
                    placeholder="Auto-filled by System"
                  />
                </div>
                <div class="form-group">
                  <h6>
                    Role/Title <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    value={userData?.roleName}
                    placeholder="Auto-filled by System"
                  />
                </div>
              </div>

              {/* <div class="Vendor RadioText">
                <div class="form-group">
                  <h6>Category</h6>
                  <div class="Categories Categories1">
                    <div class="Categories_all Categories_all1">
                      <span>Select a category</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>- Full list of categories loaded from the system</li>
                    </ul>
                  </div>
                </div>
                <div class="form-group">
                  <h6>Name</h6>
                  <div class="Categories Categories2">
                    <div class="Categories_all Categories_all1">
                      <span>Select a vendor</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        - list of vendor loaded from the system for the selected
                        category
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="form-group">
                  <h6>Select Vendor Contact</h6>
                  <div class="Categories Categories3">
                    <div class="Categories_all Categories_all1">
                      <span>Select Vendor Contact</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        - Full List of vendor contacts that are still not in
                        this user contact list
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="form-group">
                  <h6>
                    Name <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Auto-filled by System"
                  />
                </div>
                <div class="form-group">
                  <h6>
                    Email <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Auto-filled by System"
                  />
                </div>
                <div class="form-group">
                  <h6>
                    Phone <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Auto-filled by System"
                  />
                </div>
                <div class="form-group">
                  <h6>
                    Company <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Auto-filled by System"
                  />
                </div>
                <div class="form-group">
                  <h6>
                    Role/Title <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Auto-filled by System"
                  />
                </div>
              </div>
              <div class="Other RadioText">
                <div class="form-group">
                  <h6>
                    Name <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Insert Name"
                  />
                </div>
                <div class="form-group">
                  <h6>
                    Email <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Insert Email"
                  />
                </div>
                <div class="form-group">
                  <h6>
                    Phone <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Insert Phone"
                  />
                </div>
                <div class="form-group">
                  <h6>
                    Company <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Insert Company"
                  />
                </div>
                <div class="form-group">
                  <h6>
                    Role/Title <span class="required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Insert role/title"
                  />
                </div>
              </div> */}

              <div class="Button d-flex justify-content-between mt-4">
                <button
                onClick={handleSubmit}
                  class="Create"
                
                >
                  Add Contact
                </button>
                <button

                onClick={()=> SetAddContact(false)}
                  class="Outline"
                  data-toggle="modal"
                  data-target="#ContactsNewModal"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddContact;
