import React, { useEffect } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import AdminNotificationIndex from "./AdminNotification/AdminNotificationIndex";
import AdminSalesIndex from "./AdminSales/AdminSalesIndex";
import AdminMenuSetup from "./AdminMenuSetup/AdminMenuSetup";
import AdminSigningAuthority from "./AdminSigningAuthority/AdminSigningAuthority";
import AdminAccounting from "./AdminAccounting/AdminAccounting";
import AdminConstructor from "./AdminConstructor/AdminConstructor";

import {
  addCategoryAction,
  addCostCodeAction,
  addCostProfileAction,
  addSubCostProfileAction,
  changeCostCategoryMasterSettingAction,
  changeSubProfileMasterSettingAction,
  getAllCategoryListAction,
  getAllCostCodeListAction,
  getAllCostCodeMasterListAction,
  getAllCostProfielListAction,
  getAllMasterCategorylListAction,
  getAllMasterSubProfilelListAction,
  getAllProformaBaseCostProfileAction,
  getAllProformaProfileAction,
  getAllSubCostProfielListAction,
  getMasterCostCategoryAction,
  getProformaSettingAction,
  removeCategoryAction,
  removeCostCodeAction,
  removeCostProfileAction,
  removeSubCostProfileAction,
  unitAdminListAction,
  updateCategoryAction,
  updateCostCodeAction,
  updateCostProfileAction,
  updateProformaBaseCostAction,
  updateProformaSettingAction,
  updateSubCostProfileAction,
} from "../redux/action/costProfileAction";
import NoDataFound from "./Nodatafound";
import { checkArray } from "../utils/CheckType";
import Loader from "./Loader";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { errorToast, sucessToast } from "../utils/toast";
import { get } from "lodash";
import {
  addWidCastNotificAction,
  addWidCastNotificSuccess,
  removeWidCastAction,
  turnOffWidCastAction,
  turnOnWidCastAction,
  updateWidCastAction,
  viewWidCastAction,
  widCastListAction,
} from "../redux/action/widCastAction";
import AdminUserManagement from "./admin-user-management";
import AdminCompanyDetails from "./AdminCompanyDetails/AdminCompanyDetails";
import {
  ConstructionCategoryListAction,
  ProjectProfileListAction,
} from "../redux/action/adminConstructionAction";
import EstimationAdmin from "./Estimation-admin";
import { getListProjectJobs } from "../redux/action/warrantyAction";
const initModal = {
  CostProfileDetailsMOdal: false,
  addCostProfileModal: false,
  addCategoryModal: false,
  updateCategoryModal: false,
  updateCostModal: false,
  modalState: "",
  modalId: "",
  updateProfileModal: false,
  addCostCodeModal: false,
  categoryDetailsModal: false,
  proformaCostCodeStateTwo: false,
};
const initLeadState = {
  ProfileDetailsData: [],
  name: "",
  costProfileError: "",
  costId: "",
  profileName: "",
  subCostId: "",
  categoryId: "",
  categoryError: {},
  costCodeError: {},
  categoryName: "",
  categoryNumber: "",
  costCodeName: "",
  costCodeNumber: "",
  applyMarkup: false,
  classification: "",
  estimationMessage: "",
  masterState: false,
  classNameState: false,
  widcastUpperSide: false,
  is_bell: false,
  is_text: false,
  is_email: false,
  companyId: "",
  clientName: "",
  configType: "",
  eventId: "",
  eventName: "",
  widcastMessage: "",
  repeat: "",
  unitId: "",
  checkStateToShow: true,
  ProjectId: "",
  companyPrices: true,
  cityPrices: false,
  frequency: "DAILY",
  costOfConcretePerCubicYard: "",
  costOfPumperTruckPerHour: "",
  CostCodeId: "",
  defaultMasterDeceider: false,
};
const Admin = () => {
  const [iModalState, updateModalState] = useState(initModal);
  const [iLeadState, updateLeadState] = useState(initLeadState);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const [adminCompanyDetailsStatus, setAdminCompanDetailsStatus] =
    useState(false);
  const [estimationStatus, setEstimationStatus] = useState(false);
  const [adminNotificationStatus, setAdminNotificationStatus] = useState(false);
  const [adminConstructionStatus, setAdminConstructionStatus] = useState(false);
  // const [estimationStatus, setEstimationStatus] = useState(false);

  const { constructionProjectProfileList } = useSelector(
    (state) => state.adminConstructionReducer
  );

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const {
    ProfileDetailsData,
    name,
    unitId,
    costProfileError,
    costId,
    profileName,
    subCostId,
    categoryError,
    categoryName,
    categoryId,
    categoryNumber,
    costCodeName,
    costCodeNumber,
    costCodeError,
    applyMarkup,
    classification,
    estimationMessage,
    classNameState,
    widcastUpperSide,
    is_bell,
    is_text,
    is_email,
    companyId,
    clientName,
    configType,
    eventId,
    eventName,
    widcastMessage,
    repeat,
    checkStateToShow,
    ProjectId,
    companyPrices,
    cityPrices,
    frequency,
    costOfConcretePerCubicYard,
    costOfPumperTruckPerHour,
    CostCodeId,
    defaultMasterDeceider,
  } = iLeadState;
  const {
    CostProfileDetailsMOdal,
    addCostProfileModal,
    modalState,
    addCategoryModal,
    updateCostModal,
    costCodeDetailsModal,
    updateCategoryModal,
    modalId,
    updateProfileModal,
    addCostCodeModal,
    proformaCostCodeState,
    proformaCostCodeStateTwo,
  } = iModalState;
  const {
    costProfielistData,
    loader,
    subCostProfileData,
    categoryListData,
    costCodesListData,
    masterCategoryListData,
    masterSubProfileData,
    proformaProfileListData,
    proformaBaseCostListData,
    unitAdminListData,
    proformaSettingsData,
    masterCostCategoryListData,
    masterCostCodeListData,
  } = useSelector((state) => state.costReducer);

  console.log("masterCostCodeListData", masterCostCodeListData);
  const { countryData, cityData, stateData, viewWidCastData, widCastListData } =
    useSelector((state) => state.authReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMasterCostCategoryAction());
    dispatch(getAllCostProfielListAction());
    dispatch(viewWidCastAction());
    dispatch(widCastListAction());
    dispatch(ProjectProfileListAction());
    dispatch(getAllProformaProfileAction());
    dispatch(getProformaSettingAction());

    // dispatch(getAllCostCodeMasterListAction());
  }, []);

  const updateBasicProfromaFunc = () => {
    let data = {
      companyPrices,
      cityPrices,
      frequency,
      costOfConcretePerCubicYard,
      costOfPumperTruckPerHour,
    };
    dispatch(updateProformaSettingAction(data)).then((res) => {
      console.log(res);
      sucessToast("Proforma setting updated successfully");
      updateModalState({
        ...iModalState,
        proformaCostCodeStateTwo: false,
        proformaCostCodeState: false,
      });
      dispatch(getProformaSettingAction());
    });
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    updateLeadState({ ...iLeadState, [name]: value });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateLeadState({ ...iLeadState, [name]: value });
  };

  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateLeadState({ ...iLeadState, [name]: result });
  };

  const handleCheckbox = (e) => {
    const { name, value, checked } = e.target;
    if (checked == true) {
      updateLeadState({ ...iLeadState, [name]: checked });
    } else {
      updateLeadState({ ...iLeadState, [name]: checked });
    }
  };

  const handleInputFile = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    if (file) {
      updateLeadState({
        ...iLeadState,
        [name]: file,
      });
    }
    e.target.files = null;
  };

  const hideCostProfileDetailsModal = (ele) => {
    updateLeadState({ ...iLeadState, ProfileDetailsData: "" });
    updateModalState({ ...iModalState, CostProfileDetailsMOdal: false });
  };

  const showCostProfileDetailsModal = (ele) => {
    updateLeadState({ ...iLeadState, ProfileDetailsData: ele });
    updateModalState({ ...iModalState, CostProfileDetailsMOdal: true });
  };

  const hideAddCostProfileModal = (ele) => {
    updateLeadState({ ...iLeadState, name: "", costProfileError: "" });
    updateModalState({ ...iModalState, addCostProfileModal: false });
  };

  const showAddCostProfileModal = (ele) => {
    updateLeadState({ ...iLeadState, name: "", costProfileError: "" });
    updateModalState({
      ...iModalState,
      addCostProfileModal: true,
      modalState: ele,
    });
  };

  const addCostProfileFun = (e) => {
    e.preventDefault();
    if (!name) {
      updateLeadState({
        ...iLeadState,
        costProfileError: "Name can't be empty",
      });
    } else {
      if (modalState === "cost") {
        dispatch(addCostProfileAction({ name }))
          .then((res) => {
            dispatch(getAllCostProfielListAction());
            hideAddCostProfileModal();
            sucessToast("Cost profile created successfully");
          })
          .catch((err) => {
            console.log("eerrer", err);
          });
      } else {
        dispatch(addSubCostProfileAction({ name, CostProfileId: costId }))
          .then((res) => {
            dispatch(getAllSubCostProfielListAction(costId));
            dispatch(getAllMasterSubProfilelListAction(costId));
            hideAddCostProfileModal();
            sucessToast("Sub cost profile created successfully");
          })
          .catch((err) => {
            console.log("eerrer", err);
          });
      }
    }
  };

  const getCostID = (id) => {
    updateLeadState({ ...iLeadState, costId: id });
    dispatch(getAllSubCostProfielListAction(id));
    dispatch(getAllMasterSubProfilelListAction(id));
  };

  const getSubCostID = (id) => {
    updateLeadState({ ...iLeadState, subCostId: id });
    dispatch(getAllCategoryListAction(id, costId));
    dispatch(getAllMasterCategorylListAction(id, costId));
  };

  const getDataFromProfileDropdown = (id, name) => {
    updateLeadState({ ...iLeadState, costId: id, profileName: name });
    dispatch(getAllSubCostProfielListAction(id));
    dispatch(getAllMasterSubProfilelListAction(id));
  };

  const getDataFromSubProfile = (id, name) => {
    updateLeadState({ ...iLeadState, subCostId: id, profileName: name });
    dispatch(getAllCategoryListAction(id, costId));
    dispatch(getAllMasterCategorylListAction(id, costId));
  };

  const getCategoryId = (id) => {
    updateLeadState({ ...iLeadState, categoryId: id });
    dispatch(getAllCostCodeListAction(id, costId, subCostId));
    dispatch(getAllCostCodeMasterListAction(id, costId, subCostId));
  };

  const hideAddCategoryModal = (ele) => {
    // updateLeadState({ ...iLeadState, ProfileDetailsData: "" });
    updateModalState({ ...iModalState, addCategoryModal: false });
  };

  const showAddCategoryModal = (ele) => {
    updateLeadState({ ...iLeadState, categoryName: "", categoryNumber: "" });
    updateModalState({ ...iModalState, addCategoryModal: true });
  };

  const handleCategoryValidation = () => {
    let formIsValid = true;
    let error = {};
    if (!categoryName) {
      error.categoryNameError = "Category name can't be empty";
      formIsValid = false;
    }

    // if (!categoryNumber) {
    //   error.categoryNumberError = "Category name can't be empty";
    //   formIsValid = false;
    // }

    updateLeadState({ ...iLeadState, categoryError: error });
    return formIsValid;
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    let formIsValid = handleCategoryValidation();
    if (formIsValid) {
      dispatch(
        addCategoryAction({
          name: categoryName,
          number: categoryNumber,
          CostProfileId: costId,
          CostSubProfileId: subCostId,
        })
      )
        .then((res) => {
          dispatch(getAllCategoryListAction(subCostId, costId));
          dispatch(getAllMasterCategorylListAction(subCostId, costId));
          hideAddCategoryModal();
          sucessToast("Category created successfully");
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
          console.log("eeeeeee", err?.response?.data?.error);
        });
    }
  };

  const hideUpdateCategoryModal = (ele) => {
    updateLeadState({ ...iLeadState, ProfileDetailsData: "" });
    updateModalState({ ...iModalState, updateCategoryModal: false });
  };

  const showUpdateCategoryModal = (ele) => {
    updateLeadState({
      ...iLeadState,
      categoryName: ele?.name,
      categoryNumber: ele?.number,
      ProfileDetailsData: ele,
    });
    updateModalState({
      ...iModalState,
      updateCategoryModal: true,
      modalId: ele?.CostCategoryId,
    });
  };

  const archiveCategoryFun = (state, ele) => {
    dispatch(removeCategoryAction(ele?.CostCategory?.id)).then((res) => {
      sucessToast("Category archived successfully");
      dispatch(getAllCategoryListAction(subCostId, costId));
      dispatch(getAllMasterCategorylListAction(subCostId, costId));
    });
  };

  const handleUpdateCategoryFun = (e) => {
    e.preventDefault();
    let formIsValid = handleCategoryValidation();
    if (formIsValid) {
      dispatch(
        updateCategoryAction(
          {
            name: categoryName,
            number: categoryNumber,
            CostProfileId: costId,
            CostSubProfileId: subCostId,
          },
          modalId
        )
      )
        .then((res) => {
          dispatch(getAllCategoryListAction(subCostId, costId));
          hideUpdateCategoryModal();
          sucessToast("Category updated successfully");
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
        });
    }
  };

  const hideUpdateProfileModal = () => {
    updateModalState({ ...iModalState, updateProfileModal: false });
  };
  const showUpdateProfileModal = (state, ele) => {
    updateLeadState({
      ...iLeadState,
      name: ele?.name,
      ProfileDetailsData: ele,
    });
    updateModalState({
      ...iModalState,
      updateProfileModal: true,
      modalId: ele?.id,
      modalState: state,
    });
  };

  const updateProfileFunc = (e) => {
    e.preventDefault();
    if (!name) {
      updateLeadState({
        ...iLeadState,
        costProfileError: "Name can't be empty",
      });
    } else {
      if (modalState === "cost") {
        dispatch(updateCostProfileAction({ name }, modalId))
          .then((res) => {
            dispatch(getAllCostProfielListAction());
            hideUpdateProfileModal();
            sucessToast("Cost profile updated successfully");
          })
          .catch((err) => {
            console.log("eerrer", err);
          });
      } else {
        dispatch(
          updateSubCostProfileAction({ name, CostProfileId: costId }, modalId)
        )
          .then((res) => {
            dispatch(getAllSubCostProfielListAction(costId));

            hideUpdateProfileModal();
            sucessToast("Sub cost profile updated successfully");
          })
          .catch((err) => {
            console.log("eerrer", err);
            errorToast(err?.response?.data?.error);
          });
      }
    }
  };

  const archiveProfileFun = (state, ele) => {
    if (state === "cost") {
      dispatch(removeCostProfileAction(ele?.id)).then((res) => {
        dispatch(getAllCostProfielListAction());
        sucessToast("Cost profile archived successfully");
      });
    } else {
      dispatch(removeSubCostProfileAction(ele?.id)).then((res) => {
        dispatch(getAllSubCostProfielListAction(costId));
        dispatch(getAllMasterSubProfilelListAction(costId));
        sucessToast("Sub cost profile archived successfully");
      });
    }
  };

  const showAddCostCodeModal = () => {
    updateModalState({ ...iModalState, addCostCodeModal: true });
  };

  const hideAddCostCodeModal = () => {
    updateModalState({ ...iModalState, addCostCodeModal: false });
    updateLeadState({
      ...iLeadState,
      estimationMessage: "",
      classification: "",
      costCodeName: "",
      costCodeNumber: "",
      applyMarkup: false,
      number: "",
    });
  };

  const handleCostCodeValidation = () => {
    let error = {};
    let formIsValid = true;
    if (!costCodeName) {
      error.costCOdeNameError = "Cost code name can't be empty";
      formIsValid = false;
    }
    if (!costCodeNumber) {
      error.costCodeNumber = "Cost code number can't be empty";
      formIsValid = false;
    }
    if (!classification) {
      error.classificationError = "* Classification can't be empty";
      formIsValid = false;
    }
    updateLeadState({ ...iLeadState, costCodeError: error });
    return formIsValid;
  };

  const addCostCodeFunc = (e) => {
    e.preventDefault();
    let formIsValid = handleCostCodeValidation();
    if (formIsValid) {
      let data = {
        name: costCodeName,
        number: +costCodeNumber,
        CostProfileId: costId,
        CostCategoryId: +categoryId,
        CostSubProfileId: subCostId,
        applyMarkup,
        classification,
        estimationMessage,
      };
      dispatch(addCostCodeAction(data))
        .then((res) => {
          hideAddCostCodeModal();
          dispatch(getAllCostCodeListAction(categoryId, costId, subCostId));
          sucessToast("Cost code created successfully");
        })
        .catch((err) => {
          console.log("eerererer", err);
          errorToast("Internal server error");
          errorToast(err?.response?.data?.error);
        });
    }
  };

  const archiveCostCodeFun = (state, ele) => {
    dispatch(removeCostCodeAction(ele?.CostCodeId)).then((res) => {
      sucessToast("Cost code archived successfully");
      dispatch(
        getAllCostCodeListAction(
          ele?.CostCode?.CostCategoryId,
          ele?.CostProfileId,
          ele?.CostSubProfileId
        )
      );
      dispatch(
        getAllCostCodeMasterListAction(
          ele?.CostCode?.CostCategoryId,
          ele?.CostProfileId,
          ele?.CostSubProfileId
        )
      );
    });
  };

  const showCostCOdeDetails = (ele) => {
    updateLeadState({ ...iLeadState, ProfileDetailsData: ele });
    updateModalState({ ...iModalState, costCodeDetailsModal: true });
  };
  const hideCostCOdeDetails = () => {
    updateModalState({ ...iModalState, costCodeDetailsModal: false });
  };

  const showUpdateCostCodeModal = (ele) => {
    updateLeadState({
      ...iLeadState,
      ProfileDetailsData: ele,
      costCodeName: ele?.name,
      costCodeNumber: ele?.CostCode?.number,
      classification: ele?.classification,
      applyMarkup: ele?.applyMarkup,
      estimationMessage: ele?.estimationMessage,
      costId: ele?.CostProfileId,
      subCostId: ele?.CostSubProfileId,
      // costId:ele?.CostCodeId
    });
    updateModalState({
      ...iModalState,
      updateCostModal: true,
      modalId: ele?.CostCodeId,
    });
  };
  const hideUpdateCostCodeModal = () => {
    updateModalState({ ...iModalState, updateCostModal: false });
  };

  const updateCostCOdeFun = () => {
    let formIsValid = handleCostCodeValidation();
    if (formIsValid) {
      let data = {
        name: costCodeName,
        number: +costCodeNumber,
        CostProfileId: costId,
        CostCategoryId: +categoryId,
        CostSubProfileId: subCostId,
        applyMarkup,
        classification,
        estimationMessage,
      };
      dispatch(updateCostCodeAction(data, modalId))
        .then((res) => {
          console.log("ressssss", res);
          sucessToast("Cost code updated successfully");
          dispatch(getAllCostCodeListAction(categoryId, costId, subCostId));
          hideUpdateCostCodeModal();
        })
        .catch((err) => {
          console.log("eleleleel", err);
        });
    }
  };

  const updateWidCastFunction = (id) => {
    let data = { is_email, is_bell, is_text, id };

    dispatch(updateWidCastAction(data)).then((res) => {
      console.log("ersssss, ", res);
      dispatch(viewWidCastAction());
      sucessToast("Widcast updated successfully");
    });
  };

  const addNewWidCastFunc = () => {
    let data = {
      widcastMessage,
      eventId,
      eventName,
      repeat,
      configType,
      companyId: iBuildLocalData?.user?.CompanyId,
    };
    console.log("addNewWidCast", data);
    dispatch(addWidCastNotificAction(data)).then((res) => {
      console.log("ressss", res);
      sucessToast("Widcast notification added successfully");
      dispatch(widCastListAction());
    });
  };

  const removeWidcastFunc = (id) => {
    console.log("Removing", id);
    dispatch(removeWidCastAction(id)).then((res) => {
      console.log("ressss", res);
      sucessToast("Widcast notification removed successfully");
      dispatch(widCastListAction());
    });
  };

  const turnOnWidcastFunc = (id) => {
    dispatch(turnOnWidCastAction(id)).then((res) => {
      console.log("ressss", res);
      sucessToast("Widcast turned on  successfully");
      dispatch(widCastListAction());
    });
  };

  const turnOffWidcastFunc = (id) => {
    dispatch(turnOffWidCastAction(id)).then((res) => {
      console.log("ressss", res);
      sucessToast("Widcast turned off  successfully");
      dispatch(widCastListAction());
    });
  };

  const handleGetCategoryList = (item) => {
    dispatch(ConstructionCategoryListAction({ id: item?.id }));
  };

  const updatePrfomoraFunc = (id, baseCost, unitId, consolidateCost) => {
    let data = {
      CompanyId: iBuildLocalData?.user?.CompanyId,
      baseCost,
      unitId,
      consolidateCost,
    };
    dispatch(updateProformaBaseCostAction(id, data)).then((res) => {
      console.log("=========>", res);
    });
  };

  const handleChildData = (data) => {
    dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateLeadState({ ...iLeadState, ProjectId: data });
  };

  const handleSubProformBaseCostCOde = (id, type, pid) => {
    dispatch(getAllProformaBaseCostProfileAction(id, type, pid));
  };

  const changeMasterSubProfileFUnc = (status, sid) => {
    dispatch(
      changeSubProfileMasterSettingAction(subCostId, { showCode: status })
    )
      .then((res) => {
        console.log("ressssss", res);
        dispatch(getAllSubCostProfielListAction(costId));
        dispatch(getAllMasterSubProfilelListAction(costId));
      })
      .catch((err) => {
        console.log("errrrr", err);
      });
  };

  const changeMasterCategoryFUnc = (status, sid) => {
    dispatch(
      changeCostCategoryMasterSettingAction(categoryId, { showCode: status })
    )
      .then((res) => {
        console.log("ressssss", res);
        dispatch(getAllCategoryListAction(subCostId, costId));
        dispatch(getAllMasterCategorylListAction(subCostId, costId));
      })
      .catch((err) => {
        console.log("errrrr", err);
      });
  };

  const changeMasterCostCodeFUnc = (status, sid) => {
    dispatch(
      changeCostCategoryMasterSettingAction(CostCodeId, { showCode: status })
    )
      .then((res) => {
        console.log("ressssss", res);
        dispatch(getAllCostCodeListAction(categoryId));
        dispatch(getAllCostCodeMasterListAction(categoryId));
      })
      .catch((err) => {
        console.log("errrrr", err);
      });
  };

  return (
    <>
      <Header sendDataToParent={handleChildData} />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox align-items-start">
            <h4 className="Title">Admin</h4>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="ViewsConfigLeft">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      href="#UserManagement"
                      className="nav-link active"
                      data-toggle="tab"
                    >
                      User Management
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#CompanyDetails"
                      className="nav-link"
                      data-toggle="tab"
                      onClick={() =>
                        setAdminCompanDetailsStatus(
                          adminCompanyDetailsStatus ? false : true
                        )
                      }
                    >
                      Company Details
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#Notifications"
                      className="nav-link"
                      data-toggle="tab"
                      onClick={() =>
                        setAdminNotificationStatus(
                          adminNotificationStatus ? false : true
                        )
                      }
                    >
                      Notifications
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#Warranty" className="nav-link " data-toggle="tab">
                      Warranty
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                       href="#EstimationOldss"
                       className="nav-link"
                       data-toggle="tab"
                      onClick={() =>
                        setEstimationStatus(true)
                      }
                    >
                      Estimation
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#DesignSelections"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      Design Selections
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      href="#EstimationOld"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      Estimation
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a href="#Sales" className="nav-link" data-toggle="tab">
                      Sales
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#MenuSetup"
                      className="nav-link "
                      data-toggle="tab"
                    >
                      Menu Setup
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#AccountingSetup"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      Accounting Setup
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#SingingAuthority"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      Signing Authority
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#ConstructionTab"
                      class="nav-link CostCodesLink "
                      onClick={() =>
                        setAdminConstructionStatus(
                          adminNotificationStatus ? false : true
                        )
                      }
                      data-toggle="tab"
                    >
                      Construction
                    </a>
                    <a
                      class="nav-link nav-linkNew CostCodesLink"
                      data-toggle="collapse"
                      href="#Constructioncollapse"
                      role="button"
                    >
                      <img src="images/arrowleft.png" />
                    </a>

                    <div
                      class="collapse collapseExample"
                      id="Constructioncollapse"
                    >
                      {constructionProjectProfileList?.map((item) => (
                        <div class="nav-item">
                          <a
                            onClick={() => {
                              handleGetCategoryList(item);
                            }}
                            href="#ConstructionMultiFamily"
                            class="nav-link CostCodesLink"
                            data-toggle="tab"
                          >
                            {item?.projectTypeDescription}
                          </a>
                        </div>
                      ))}
                    </div>
                  </li>

                  <li className="nav-item">
                    <a
                      href="#ReminderWidcast"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      Reminder/Widcast
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#CostCodesTabs2"
                      className="nav-link CostCodesLink "
                      data-toggle="tab"
                    >
                      Cost Codes
                    </a>
                    <a
                      className="nav-link nav-linkNew CostCodesLink"
                      data-toggle="collapse"
                      href="#collapseExample"
                      role="button"
                    >
                      <img src="images/arrowleft.png" />
                    </a>
                    <div
                      className="collapse collapseExample"
                      id="collapseExample"
                    >
                      <div>
                        {checkArray(costProfielistData?.data) &&
                          costProfielistData?.data?.map((ele, i) => {
                            const dropdownId = `collapseExample_${i}`;
                            return (
                              <>
                                <div className="nav-item">
                                  <a
                                    className="nav-link CostCodesLink"
                                    data-toggle="tab"
                                    href={`#MultiFamilyTabs2`}
                                    onClick={() =>
                                      getDataFromProfileDropdown(
                                        ele?.id,
                                        ele?.name
                                      )
                                    }
                                  >
                                    {ele?.name}
                                  </a>
                                  <a
                                    className="nav-link nav-linkNew CostCodesLink"
                                    data-toggle="collapse"
                                    href={`#${dropdownId}`}
                                  >
                                    <img src="images/arrowleft.png" />
                                  </a>
                                  <div
                                    className="collapse collapseExample2"
                                    id={dropdownId}
                                  >
                                    <div>
                                      {checkArray(ele?.directTable) &&
                                        ele?.directTable?.map((ele, i) => {
                                          return (
                                            <>
                                              <div className="nav-item">
                                                <a
                                                  href={`#UnitsTabs`}
                                                  className="nav-link CostCodesLink "
                                                  data-toggle="tab"
                                                  onClick={() =>
                                                    getDataFromSubProfile(
                                                      ele?.id,
                                                      ele?.name
                                                    )
                                                  }
                                                >
                                                  {ele?.name}

                                                  {/* {ele?.name} */}
                                                </a>
                                              </div>
                                            </>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </li>

                  <li class="nav-item">
                    <a
                      href="#BaseCodeProforma"
                      class="nav-link CostCodesLink"
                      data-toggle="tab"
                    >
                      Proforma Base Code
                    </a>
                    <a
                      class="nav-link nav-linkNew CostCodesLink"
                      data-toggle="collapse"
                      href="#BaseCodeProformaCollapse"
                      role="button"
                    >
                      <img src="images/arrowleft.png" />
                    </a>

                    <div
                      class="collapse collapseExample"
                      id="BaseCodeProformaCollapse"
                    >
                      <div>
                        {checkArray(proformaProfileListData?.data) &&
                          proformaProfileListData?.data?.map((ele, i) => {
                            const dropdownId = `collapseExample_${i}`;
                            return (
                              <>
                                <div className="nav-item">
                                  <a
                                    className="nav-link CostCodesLink"
                                    data-toggle="tab"
                                    href={`#BaseCodeProformaBlocks`}
                                    // {ele?.FoundationType && ele?.FoundationType?.length > 0 ? <></> : }
                                    onClick={() =>
                                      handleSubProformBaseCostCOde(ele?.id)
                                    }
                                  >
                                    {ele?.name}
                                  </a>
                                  <a
                                    className="nav-link nav-linkNew CostCodesLink"
                                    data-toggle="collapse"
                                    href={`#${dropdownId}`}
                                  >
                                    <img src="images/arrowleft.png" />
                                    ``
                                  </a>
                                  <div
                                    className="collapse collapseExample2"
                                    id={dropdownId}
                                  >
                                    <div>
                                      {checkArray(ele?.FoundationType) &&
                                        ele?.FoundationType?.map((item, i) => {
                                          return (
                                            <>
                                              <div className="nav-item">
                                                <a
                                                  href={`#BaseCodeProformaBlocks`}
                                                  className="nav-link CostCodesLink "
                                                  data-toggle="tab"
                                                  onClick={() =>
                                                    handleSubProformBaseCostCOde(
                                                      ele?.id,
                                                      "FoundationType",
                                                      item?.id
                                                    )
                                                  }
                                                >
                                                  {item?.name}

                                                  {/* {ele?.name} */}
                                                </a>
                                              </div>
                                            </>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-9">
              <div className="tab-content AdminTabContent2">
                <div className="tab-pane fade show active" id="UserManagement">
                  <AdminUserManagement />
                </div>
                <AdminCompanyDetails
                  adminCompanyDetailsStatus={adminCompanyDetailsStatus}
                />

                {adminNotificationStatus && (
                  <AdminNotificationIndex
                    adminNotificationStatus={adminNotificationStatus}
                  />
                )}
                <div className="tab-pane fade" id="Warranty">
                  <div className="ResidentialJobArea ViewConfigArea">
                    <div className="ModalAccordian">
                      <div id="accordion">
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#WarrantorInformationTabs"
                            >
                              <span>
                                <img src="images/sales_information.png" />
                              </span>{" "}
                              Warrantor Information
                            </h4>
                            <a className="Edit EditViewConfigBodyBtn">
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="WarrantorInformationTabs"
                            className="collapse"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="ViewConfigBodyMain">
                                <div className="ViewConfigBody">
                                  <aside>
                                    <div className="form-group">
                                      <h6>
                                        Company Name{" "}
                                        <span className="required">*</span>
                                      </h6>
                                      <p>Warrantor 1</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Email</h6>
                                      <p>support@warrantor1.com</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Phone</h6>
                                      <p>(587)565-5556</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Fax</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Toll Free</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Add to Vendor/Supplier list</h6>
                                      <p>On</p>
                                    </div>
                                  </aside>
                                </div>
                              </div>
                              <div className="ViewConfigBodyHideMain">
                                <div className="ViewConfigBodyHide">
                                  <aside>
                                    <div className="form-group">
                                      <h6>
                                        Company Name{" "}
                                        <span className="required">*</span>
                                      </h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Warrantor 1"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Email</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="support@warrantor1.com"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Phone</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="(587)565-5556"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Fax</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Fax Number"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Toll Free</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Toll Free"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Email</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="support@warrantor1.com"
                                      />
                                    </div>
                                    <label className="switch">
                                      {" "}
                                      Add to Vendor/Supplier list
                                      <input type="checkbox" />
                                      <span className="slider" />
                                    </label>
                                  </aside>
                                </div>
                                <div className="Button mt-3 d-flex justify-content-center">
                                  <button className="OutlineBlack mr-3 SaveViewConfigBodyBtn">
                                    Cancel
                                  </button>
                                  <button className="Respond ml-3 SaveViewConfigBodyBtn">
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#WarrantorAddressTabs"
                            >
                              <span>
                                <img src="images/warrantor_address.png" />
                              </span>{" "}
                              Warrantor Address
                            </h4>
                            <a
                              className="Edit EditViewConfigBodyBtn"
                              href="javascript:void(0);"
                            >
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="WarrantorAddressTabs"
                            className="collapse"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="ViewConfigBodyMain">
                                <div className="ViewConfigBody">
                                  <aside>
                                    <div className="form-group">
                                      <h6>
                                        Street Address{" "}
                                        <span className="required">*</span>
                                      </h6>
                                      <p>Street 1</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Street Address Line Two</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>City</h6>
                                      <p>Calgary</p>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Zip/Postal Code</h6>
                                      <p>T3A T3A</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Country</h6>
                                      <p>Canada</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>State/Province</h6>
                                      <p>AB</p>
                                    </div>
                                  </aside>
                                </div>
                              </div>
                              <div className="ViewConfigBodyHideMain">
                                <div className="ViewConfigBodyHide">
                                  <aside>
                                    <div className="form-group">
                                      <h6>Street Address</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Street 1"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Street Address Line Two</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Insert full Address"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>City</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Calgary"
                                      />
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Zip/ Postal Code</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="T3A T3A"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Country</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">Canada</option>
                                          <option>India</option>
                                          <option>USA</option>
                                          <option>Australia</option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>State/Province</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">AB</option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </aside>
                                </div>
                                <div className="Button d-flex justify-content-center mt-3">
                                  <button className="OutlineBlack mr-3 SaveViewConfigBodyBtn">
                                    Cancel
                                  </button>
                                  <button className="Respond ml-3 SaveViewConfigBodyBtn">
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="DesignSelections">
                  <div className="ResidentialJobArea ViewConfigArea">
                    <div className="ModalAccordian">
                      <div id="accordion">
                        <div className="card">
                          <div className="card-header">
                            <h4 data-toggle="collapse" href="#ConstructionApps">
                              <span>
                                <img src="images/home_warranty.png" />
                              </span>{" "}
                              Design Selections Interior Default Cost Codes
                            </h4>
                            <a className="Edit EditViewConfigBodyBtn">
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="ConstructionApps"
                            className="collapse"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="ViewConfigBodyMain">
                                <div className="ViewConfigBody DesignSelectionWidth">
                                  <aside>
                                    <div className="form-group">
                                      <h6>Electrical</h6>
                                      <p>13.0311 Cable/ Phone - R/I</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Windows &amp; Doors</h6>
                                      <p>8.0101 Windows &amp; Doors</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Fireplaces R/I</h6>
                                      <p>10.0101 Fireplace - R/I</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Fireplaces Finals</h6>
                                      <p>10.0301 Fireplace - Final</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Appliances</h6>
                                      <p>23.0101 Appliances</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Cabinets</h6>
                                      <p>
                                        19.0101 Cabinets - Design Selections -
                                        Layouts &amp; C...
                                      </p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Counter Tops</h6>
                                      <p>
                                        19.0401 Counter Tops - Granite / Other -
                                        S&amp;I
                                      </p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Plumbing R/I</h6>
                                      <p>3-0909 Plumbing - Final</p>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Plumbing Finals</h6>
                                      <p>
                                        11.0907 Plumbing - Materials Freight
                                      </p>
                                    </div>
                                    <div className="form-group">
                                      <h6>In-Slab Heating Unit</h6>
                                      <p>
                                        2.0610 Install Shallow U.G. Utility
                                        Mains - Cabl...
                                      </p>
                                    </div>
                                    <div className="form-group">
                                      <h6>In-Slab Heating Tubing Grid</h6>
                                      <p>
                                        2.0610 Install Shallow U.G. Utility
                                        Mains - Cabl...
                                      </p>
                                    </div>
                                    <div className="form-group">
                                      <h6>
                                        Heating &amp; Air Conditioning (HVAC)
                                        R/I
                                      </h6>
                                      <p>
                                        2.0641 Sidewalks/Mail Box Area - Cribb /
                                        For...
                                      </p>
                                    </div>
                                    <div className="form-group">
                                      <h6>
                                        Heating &amp; Air Conditioning (HVAC)
                                        Finals
                                      </h6>
                                      <p>
                                        2.0802 Permit - For Temporary
                                        Building(s)
                                      </p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Air Conditioning Unit</h6>
                                      <p>
                                        2.0653 Swales - Pumper Truck for
                                        Concrete
                                      </p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Overhead Garage Doors</h6>
                                      <p>
                                        2.0608 Install Shallow U.G. Utility
                                        Mains - Elec...
                                      </p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Garage Door Opener</h6>
                                      <p>
                                        2.0621 Sidewalks, Curbs&amp;Gutters,
                                        Swales...
                                      </p>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Security Systems</h6>
                                      <p>Select Cost Code</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Central Vac System</h6>
                                      <p>Select Cost Code</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>
                                        Drywall Corners &amp; Ceilings Texture
                                      </h6>
                                      <p>Select Cost Code</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Finishing Materials - Stage 1</h6>
                                      <p>Select Cost Code</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Finishing Materials - Stage 2</h6>
                                      <p>Select Cost Code</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Painting &amp; Staining</h6>
                                      <p>Select Cost Code</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Railing Painting &amp; Staining</h6>
                                      <p>Select Cost Code</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Flooring &amp; Wall Tiles</h6>
                                      <p>Select Cost Code</p>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Railing Interior</h6>
                                      <p>Select Cost Code</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>
                                        Wire Shelving, Vanity Mirrors, Shower
                                        Doors
                                      </h6>
                                      <p>Select Cost Code</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Lighting &amp; Fans</h6>
                                      <p>Select Cost Code</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Window Coverings</h6>
                                      <p>Select Cost Code</p>
                                    </div>
                                  </aside>
                                </div>
                              </div>
                              <div className="ViewConfigBodyHideMain">
                                <div className="ViewConfigBodyHide DesignSelectionWidth">
                                  <aside>
                                    <div className="form-group">
                                      <h6>Electrial </h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            13.0311 Cable/ Phone - R/I
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Windows &amp; Doors</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            8.0101 Windows &amp; Doors
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Fireplaces R/I</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            10.0101 Fireplace - R/I
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Fireplaces Finals</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            10.0301 Fireplace - Final
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Appliances</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            23.0101 Appliances
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Cabinets</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            19.0101 Cabinets - Design Selections
                                            - Layouts &amp; C...
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Counter Tops</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            19.0401 Counter Tops - Granite /
                                            Other - S&amp;I
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Plumbing R/I</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            3-0909 Plumbing - Final
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Plumbing Finals </h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            11.0907 Plumbing - Materials Freight
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>In-Slab Heating Unit</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            2.0610 Install Shallow U.G. Utility
                                            Mains - Cabl...
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>In-Slab Heating Tubing Grid</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            2.0610 Install Shallow U.G. Utility
                                            Mains - Cabl...
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>
                                        Heating &amp; Air Conditioning (HVAC)
                                        R/I
                                      </h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            2.0641 Sidewalks/Mail Box Area -
                                            Cribb / For...
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>
                                        Heating &amp; Air Conditioning (HVAC)
                                        Finals
                                      </h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            2.0802 Permit - For Temporary
                                            Building(s)
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Air Conditioning Unit</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            2.0653 Swales - Pumper Truck for
                                            Concrete
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Overhead Garage Doors</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            2.0608 Install Shallow U.G. Utility
                                            Mains - Elec...
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Garage Door Opener</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            2.0621 Sidewalks, Curbs&amp;Gutters,
                                            Swales...
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Security Systems</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select Cost Code
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Central Vac System</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select Cost Code
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>
                                        Drywall Corners &amp; Ceilings Texture
                                      </h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select Cost Code
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Finishing Materials - Stage 1</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select Cost Code
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Finishing Materials - Stage 2</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select Cost Code
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Painting &amp; Staining</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select Cost Code
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Railing Painting &amp; Staining</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select Cost Code
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Flooring &amp; Wall Tiles</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select Cost Code
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Railing Interior</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select Cost Code
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>
                                        Wire Shelving, Vanity Mirrors, Shower
                                        Doors
                                      </h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select Cost Code
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Lighting &amp; Fans</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select Cost Code
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Window Coverings</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select Cost Code
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </aside>
                                </div>
                                <div className="Button d-flex justify-content-center mt-5">
                                  <button className="OutlineBlack mr-3 SaveViewConfigBodyBtn">
                                    Cancel
                                  </button>
                                  <button className="Respond ml-3 SaveViewConfigBodyBtn">
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#DesignSelectionsInteriorDefaultCostCodesTabs"
                            >
                              <span>
                                <img src="images/home_warranty.png" />
                              </span>{" "}
                              Design Selections Interior Default Cost Codes
                            </h4>
                            <a
                              className="Edit EditViewConfigBodyBtn"
                              href="javascript:void(0);"
                            >
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="DesignSelectionsInteriorDefaultCostCodesTabs"
                            className="collapse"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="ViewConfigBodyMain">
                                <div className="ViewConfigBody ParagraphMarginLeft DesignSelectionWidth">
                                  <aside>
                                    <div className="form-group">
                                      <h6>Roofing</h6>
                                      <p>2.0214 Developers Condominium Board</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Soffit</h6>
                                      <p>
                                        2.0226 Equipment Contribution to Condo
                                        Board
                                      </p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Fascia</h6>
                                      <p>
                                        2.0218 Condo Insurance - Fire &amp;
                                        Third Party Liability
                                      </p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Eavestrough/Spout</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Siding</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Flashing</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Corners</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Shakes</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Brackets (Decorative)</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Accents</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Shutters</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Louvers</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Battens/Trims</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Keystones</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Stucco</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Reliefs/Battens</h6>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Stone</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Stonetile</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Brick Veneer</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Main Columns/Posts</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Trim/Caps</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Front Door</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>SideLight(s)</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Transom</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Garage To House Door</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Back/Side Door(s)</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Exterior Frames Vinyl</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Exterior Frames Aluminum</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Jamb Extensions</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Glass Options</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Grills Colour</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Grill Pattern</h6>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Skylight(s)</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>OH Garage Door(s)</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Glass Inserts-Top Panel</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>O/H Door Opener</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Front Step</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Front Railing</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Nailer</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Joist</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Beams</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Posts</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Decking</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Skirt Paneling</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Lattice</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Veranda / Deck Railing</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Rear Railing</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Sidewalk</h6>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Finish (if concrete)</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Driveway</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Finish (if concrete)</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Patio - Rear</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Parging</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>House Numbers</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Nailer</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Joist</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Beams</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Posts</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Decking</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Skirt Paneling</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Lattice</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Veranda / Deck Railing</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Rear Railing</h6>
                                    </div>
                                    <div className="form-group">
                                      <h6>Sidewalk</h6>
                                    </div>
                                  </aside>
                                </div>
                              </div>
                              <div className="ViewConfigBodyHideMain">
                                <div className="ViewConfigBodyHide DesignSelectionWidth">
                                  <aside>
                                    <div className="form-group">
                                      <h6>Roofing</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            2.0214 Developers Condominium Board
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Soffit</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            2.0226 Equipment Contribution to
                                            Condo Board
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Fascia</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            2.0218 Condo Insurance - Fire &amp;
                                            Third Party Liability
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Eavestrough/Spout</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Siding</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Flashing</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Corners</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Shakes</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Brackets (Decorative)</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Accents</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Shutters</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Louvers</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Battens/Trims</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Keystones</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Stucco</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Reliefs/Battens</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Stone</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Stonetile</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Brick Veneer</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Main Columns/Posts</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Trim/Caps</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Front Door</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>SideLight(s)</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Transom</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Garage To House Door</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Back/Side Door(s)</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Exterior Frames Vinyl</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Exterior Frames Aluminum</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Jamb Extensions</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Glass Options</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Grills Colour</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Grill Pattern</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Skylight(s)</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>OH Garage Door(s)</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Glass Inserts-Top Panel</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>O/H Door Opener</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Front Step</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Front Railing</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Nailer</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Joist</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Beams</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Beams</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Decking</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Skirt Paneling</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Lattice</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Veranda / Deck Railing</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Rear Railing</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Rear Railing</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Finish (if concrete)</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Driveway</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Finish (if concrete)</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Patio - Rear</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>Parging</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>House Numbers</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="" />
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                  </aside>
                                </div>
                                <div className="Button d-flex justify-content-center mt-5">
                                  <button className="OutlineBlack mr-3 SaveViewConfigBodyBtn">
                                    Cancel
                                  </button>
                                  <button className="Respond ml-3 SaveViewConfigBodyBtn">
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="EstimationOld">
                  <div className="ResidentialJobArea ViewConfigArea">
                    <div className="ModalAccordian">
                      <div id="accordion">
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#BiddingRequestMessagesTabs"
                            >
                              <span>
                                <img src="images/select_message.png" />
                              </span>{" "}
                              Bidding Request Messages
                            </h4>
                            <a className="Edit EditViewConfigBodyBtn">
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="BiddingRequestMessagesTabs"
                            className="collapse"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="ViewConfigBodyMain">
                                <div className="ViewConfigBody">
                                  <aside>
                                    <div className="form-group mb-0">
                                      <h6>Bidding Request Email</h6>
                                      <p>project1.estimation@builder.ca</p>
                                    </div>
                                  </aside>
                                </div>
                              </div>
                              <div className="ViewConfigBodyHideMain">
                                <div className="ViewConfigBodyHide">
                                  <aside>
                                    <div className="form-group">
                                      <h6>project1.estimation@builder.ca</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="project1.estimation@builder.ca"
                                      />
                                    </div>
                                  </aside>
                                </div>
                                <div className="Button d-flex justify-content-center">
                                  <button className="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                                    Cancel
                                  </button>
                                  <button className="Respond ml-5 SaveViewConfigBodyBtn">
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#IndividualLimitsTabs"
                            >
                              <span>
                                <img src="images/qoqr_1.png" />
                              </span>{" "}
                              Individual Limits QR, PO and FPO
                            </h4>
                            <a
                              className="Edit EditViewConfigBodyBtn"
                              href="javascript:void(0);"
                            >
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="IndividualLimitsTabs"
                            className="collapse"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="ViewConfigBodyMain">
                                <div className="ModalBigheading">
                                  <h3>
                                    <span>Limits per Role</span>
                                  </h3>
                                </div>
                                <div className="ViewConfigBody">
                                  <aside>
                                    <div className="form-group">
                                      <h6>Estimator</h6>
                                      <p>$50,000.00</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Construction Manager</h6>
                                      <p>$50,000.00</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Warranty Manager</h6>
                                      <p>$50,000.00</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Estimation 1</h6>
                                      <p>$10,000.00</p>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Estimation and Purchasing Manager</h6>
                                      <p>$ 300,000.00</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Site Super</h6>
                                      <p>$10,000.00</p>
                                    </div>
                                    <div className="form-group">
                                      <h6>Warranty Staff</h6>
                                      <p>$50,000.00</p>
                                    </div>
                                  </aside>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                    <div className="ModalBigheading">
                                      <h3>
                                        <span>Limits per User</span>
                                      </h3>
                                    </div>
                                    <div className="Tableheader mb-2">
                                      <h6
                                        style={{ fontSize: "16px!important" }}
                                      >
                                        User Limits
                                      </h6>
                                      <div className="NewActions">
                                        <a
                                          href="javascript:void(0);"
                                          title="pdf"
                                        >
                                          <img src="images/icon-53.png" />
                                        </a>
                                        <a
                                          href="javascript:void(0);"
                                          title="download"
                                        >
                                          <img src="images/download.png" />
                                        </a>
                                        {/* <a
                                          href="javascript:void(0);"
                                          title="settings"
                                        >
                                          <img src="images/setting.png" />
                                        </a> */}
                                      </div>
                                    </div>
                                    <div className="TableList TableListHeader">
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>User Name</th>
                                            <th>Limit</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>John Estimator</td>
                                            <td>$ 250,000.00</td>
                                          </tr>
                                          <tr>
                                            <td>John Estimator</td>
                                            <td>$ 50,000.00</td>
                                          </tr>
                                          <tr>
                                            <td>Jack Site Super</td>
                                            <td>$ 10,000.00</td>
                                          </tr>
                                          <tr>
                                            <td>Jason Warranty</td>
                                            <td>$ 30,000.00</td>
                                          </tr>
                                          <tr>
                                            <td>Jason Warranty</td>
                                            <td>$ 25,000.00</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ViewConfigBodyHideMain">
                                <div className="ModalBigheading">
                                  <h3>
                                    <span>Limits per Role</span>
                                  </h3>
                                </div>
                                <div className="ViewConfigBodyHide">
                                  <aside>
                                    <div className="form-group">
                                      <h6>Estimation</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="$ 50,000.00"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Construction Manager</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="$ 50,000.00"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Warranty Manager</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="$ 50,000.00"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Estimation 1</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="$ 50,000.00"
                                      />
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Estimation and Purchasing Manager</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="$ 300,000.00"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Site Super</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="$ 10,000.00"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Warranty Staff</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="$ 50,000.00"
                                      />
                                    </div>
                                  </aside>
                                </div>
                                <div className="ModalBigheading">
                                  <h3>
                                    <span>Limits per User</span>
                                  </h3>
                                </div>
                                <div className="ViewConfigBodyHide">
                                  <aside>
                                    <div className="form-group">
                                      <h6>User</h6>
                                      <div className="CommonSelectBox">
                                        <select>
                                          <option selected="">
                                            Select User
                                          </option>
                                        </select>
                                        <span>
                                          <img src="images/down.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="mb-4">
                                      <button className="SmallRedBtn">
                                        Add New User Limit
                                      </button>
                                    </div>
                                  </aside>
                                  <aside>
                                    <div className="form-group">
                                      <h6>Limit</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="$"
                                      />
                                    </div>
                                  </aside>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                    <div className="Tableheader mb-2">
                                      <h6
                                        style={{ fontSize: "16px!important" }}
                                      >
                                        User Limits
                                      </h6>
                                      <div className="NewActions">
                                        <a
                                          href="javascript:void(0);"
                                          title="pdf"
                                        >
                                          <img src="images/icon-53.png" />
                                        </a>
                                        <a
                                          href="javascript:void(0);"
                                          title="download"
                                        >
                                          <img src="images/download.png" />
                                        </a>
                                        {/* <a
                                          href="javascript:void(0);"
                                          title="settings"
                                        >
                                          <img src="images/setting.png" />
                                        </a> */}
                                      </div>
                                    </div>
                                    <div className="TableList TableListHeader">
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>User Name</th>
                                            <th>Limit</th>
                                            <th>
                                              <div className="text-center">
                                                Action
                                              </div>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>John Estimator</td>
                                            <td>$ 250,000.00</td>
                                            <td>
                                              <div className="TableThreeDots text-center">
                                                <ul className="">
                                                  <li className="dropdown">
                                                    <a
                                                      className=""
                                                      role="button"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      <img src="images/dots.png" />
                                                    </a>
                                                    <ol className="dropdown-menu dropdown-menuwidth">
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/Action-1.png" />
                                                          Edit Limit
                                                        </a>
                                                      </li>
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/dropdown-delete.png" />
                                                          Remove Line
                                                        </a>
                                                      </li>
                                                    </ol>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>John Estimator</td>
                                            <td>$ 50,000.00</td>
                                            <td>
                                              <div className="TableThreeDots text-center">
                                                <ul className="">
                                                  <li className="dropdown">
                                                    <a
                                                      className=""
                                                      role="button"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      <img src="images/dots.png" />
                                                    </a>
                                                    <ol className="dropdown-menu dropdown-menuwidth">
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/Action-1.png" />
                                                          Edit Limit
                                                        </a>
                                                      </li>
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/dropdown-delete.png" />
                                                          Remove Line
                                                        </a>
                                                      </li>
                                                    </ol>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Jack Site Super</td>
                                            <td>$ 10,000.00</td>
                                            <td>
                                              <div className="TableThreeDots text-center">
                                                <ul className="">
                                                  <li className="dropdown">
                                                    <a
                                                      className=""
                                                      role="button"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      <img src="images/dots.png" />
                                                    </a>
                                                    <ol className="dropdown-menu dropdown-menuwidth">
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/Action-1.png" />
                                                          Edit Limit
                                                        </a>
                                                      </li>
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/dropdown-delete.png" />
                                                          Remove Line
                                                        </a>
                                                      </li>
                                                    </ol>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Jason Warranty</td>
                                            <td>$ 30,000.00</td>
                                            <td>
                                              <div className="TableThreeDots text-center">
                                                <ul className="">
                                                  <li className="dropdown">
                                                    <a
                                                      className=""
                                                      role="button"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      <img src="images/dots.png" />
                                                    </a>
                                                    <ol className="dropdown-menu dropdown-menuwidth">
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/Action-1.png" />
                                                          Edit Limit
                                                        </a>
                                                      </li>
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/dropdown-delete.png" />
                                                          Remove Line
                                                        </a>
                                                      </li>
                                                    </ol>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Jason Warranty</td>
                                            <td>$ 25,000.00</td>
                                            <td>
                                              <div className="TableThreeDots text-center">
                                                <ul className="">
                                                  <li className="dropdown">
                                                    <a
                                                      className=""
                                                      role="button"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      <img src="images/dots.png" />
                                                    </a>
                                                    <ol className="dropdown-menu dropdown-menuwidth">
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/Action-1.png" />
                                                          Edit Limit
                                                        </a>
                                                      </li>
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/dropdown-delete.png" />
                                                          Remove Line
                                                        </a>
                                                      </li>
                                                    </ol>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="Button d-flex justify-content-center mt-4">
                                  <button className="OutlineBlack mr-3 SaveViewConfigBodyBtn">
                                    Cancel
                                  </button>
                                  <button className="Respond ml-3 SaveViewConfigBodyBtn">
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#GeneralSettingsTabs"
                            >
                              <span>
                                <img src="images/select_message.png" />
                              </span>{" "}
                              General Settings
                            </h4>
                            <a className="Edit EditViewConfigBodyBtn">
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="GeneralSettingsTabs"
                            className="collapse"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="ViewConfigBodyMain">
                                <div className="ViewConfigBody">
                                  <aside style={{ width: "100%" }}>
                                    <div className="form-group">
                                      <h6>
                                        Allow Warranty users to send FPOs from
                                        Work Orders
                                      </h6>
                                      <p>On</p>
                                    </div>
                                    <div className="form-group mb-0">
                                      <h6>
                                        Auto Cancel Non-accepted Budged Quotes
                                      </h6>
                                      <p>On</p>
                                    </div>
                                  </aside>
                                </div>
                              </div>
                              <div className="ViewConfigBodyHideMain">
                                <div className="ViewConfigBodyHide">
                                  <aside style={{ width: "100%" }}>
                                    <div className="form-group mb-0">
                                      <h6>
                                        Allow Warranty users to send FPOs from
                                        Work Orders
                                      </h6>
                                      <label className="switch">
                                        <input type="checkbox" />
                                        <span
                                          className="slider"
                                          style={{ left: 0 }}
                                        />
                                      </label>
                                    </div>
                                    <div className="form-group mb-0">
                                      <h6>
                                        Auto Cancel Non-accepted Budged Quotes
                                      </h6>
                                      <label className="switch">
                                        <input type="checkbox" />
                                        <span
                                          className="slider"
                                          style={{ left: 0 }}
                                        />
                                      </label>
                                    </div>
                                  </aside>
                                </div>
                                <div className="Button d-flex justify-content-center">
                                  <button className="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                                    Cancel
                                  </button>
                                  <button className="Respond ml-5 SaveViewConfigBodyBtn">
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <AdminSalesIndex />
                <AdminMenuSetup />
                <AdminAccounting />
                <AdminSigningAuthority />
                <div className="tab-pane fade" id="CostCodesTabs2">
                  <div className="ResidentialJobArea ViewConfigArea">
                    <div className="ModalAccordian">
                      <div id="accordion">
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#CostCodeProfileSetupTabs"
                            >
                              <span>
                                <img src="images/side_menu.png" />
                              </span>{" "}
                              Cost Code Profile Setup
                            </h4>
                            <a className="Edit EditViewConfigBodyBtn">
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="CostCodeProfileSetupTabs"
                            className="collapse show"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="ViewConfigBodyMain">
                                <div className="ViewConfigBody">
                                  <aside style={{ width: "60%" }}>
                                    <div className="ModalBigheading">
                                      <h3>
                                        <span>Main Project Profiles</span>
                                      </h3>
                                    </div>
                                    <div className="FilesArea">
                                      <div className="FilesHead  justify-content-end mb-2">
                                        <div className="FilesLeft">
                                          <aside className="mr-0">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search"
                                              onChange={(e) =>
                                                dispatch(e.target.value)
                                              }
                                            />
                                            <span>
                                              <img src="images/search.png" />
                                            </span>
                                          </aside>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="MainProjectProfilesArea">
                                      <ul>
                                        {loader ? (
                                          <>
                                            <td>
                                              {" "}
                                              <Loader />
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            {checkArray(
                                              costProfielistData?.data
                                            ) ? (
                                              costProfielistData?.data?.map(
                                                (ele, ind) => {
                                                  return (
                                                    <>
                                                      <li>
                                                        <a
                                                          onClick={() =>
                                                            getCostID(ele?.id)
                                                          }
                                                          className={`${
                                                            costId === ele?.id
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                          href="javascript:void(0);"
                                                        >
                                                          {ele?.name}
                                                        </a>
                                                        <div className="dropdown">
                                                          <span
                                                            className="Dots"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            <img src="images/dots.png" />
                                                          </span>
                                                          <div
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton"
                                                          >
                                                            <a
                                                              className="dropdown-item"
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                showCostProfileDetailsModal(
                                                                  ele
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <img src="images/view-po.png" />
                                                              View Details
                                                            </a>
                                                            <a
                                                              className="dropdown-item"
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                showUpdateProfileModal(
                                                                  "cost",
                                                                  ele
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <img src="images/edit.png" />
                                                              Modify
                                                            </a>
                                                            <a
                                                              className="dropdown-item"
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                archiveProfileFun(
                                                                  "cost",
                                                                  ele
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <img src="images/archive_job.png" />
                                                              Archive
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <td>
                                                {" "}
                                                <NoDataFound />
                                              </td>
                                            )}
                                          </>
                                        )}
                                      </ul>
                                    </div>
                                    <div className="Button d-flex justify-content-between align-items-center mt-4">
                                      <button
                                        className="Create"
                                        onClick={() =>
                                          showAddCostProfileModal("cost")
                                        }
                                      >
                                        Add New Project Profile
                                      </button>
                                      {/* <button class="Outline" data-toggle="modal" data-target="#ProjectProfileDetails" >View Details</button> */}
                                    </div>
                                  </aside>
                                </div>
                              </div>
                              <div className="ViewConfigBodyHideMain">
                                <div className="ViewConfigBodyHide flex-column" />
                                <div className="d-flex justify-content-between">
                                  <div className="CostCodesStyling">
                                    <div className="ModalBigheading mt-5">
                                      <h3>
                                        <span>Project Profile Options</span>
                                      </h3>
                                      <h3>
                                        <span>Active</span>Profile Option List
                                      </h3>
                                    </div>
                                    <div className="FilesArea">
                                      <div className="FilesHead  justify-content-end mb-2">
                                        <div className="FilesLeft">
                                          <aside className="mr-0">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search"
                                              onChange={(e) =>
                                                dispatch(
                                                  getAllSubCostProfielListAction(
                                                    costId,
                                                    e.target.value
                                                  )
                                                )
                                              }
                                            />
                                            <span>
                                              <img src="images/search.png" />
                                            </span>
                                          </aside>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="MainProjectProfilesArea">
                                      <ul>
                                        <ul>
                                          {loader ? (
                                            <>
                                              <td>
                                                {" "}
                                                <Loader />
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              {checkArray(
                                                subCostProfileData?.data
                                              ) ? (
                                                subCostProfileData?.data?.map(
                                                  (ele, ind) => {
                                                    return (
                                                      <>
                                                        <li>
                                                          <a
                                                            onClick={() =>
                                                              updateLeadState({
                                                                ...iLeadState,

                                                                subCostId:
                                                                  ele?.id,
                                                              })
                                                            }
                                                            href="javascript:void(0);"
                                                            className={`${
                                                              subCostId ===
                                                              ele?.id
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                          >
                                                            {ele?.name}
                                                          </a>
                                                          <div className="dropdown">
                                                            <span
                                                              className="Dots"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              <img src="images/dots.png" />
                                                            </span>
                                                            <div
                                                              className="dropdown-menu"
                                                              aria-labelledby="dropdownMenuButton"
                                                            >
                                                              <a
                                                                className="dropdown-item"
                                                                href="javascript:void(0);"
                                                                onClick={() =>
                                                                  showCostProfileDetailsModal(
                                                                    ele
                                                                  )
                                                                }
                                                              >
                                                                {" "}
                                                                <img src="images/view-po.png" />
                                                                View Details
                                                              </a>
                                                              <a
                                                                className="dropdown-item"
                                                                href="javascript:void(0);"
                                                                onClick={() =>
                                                                  showUpdateProfileModal(
                                                                    "subCost",
                                                                    ele
                                                                  )
                                                                }
                                                              >
                                                                {" "}
                                                                <img src="images/edit.png" />
                                                                Modify
                                                              </a>
                                                              <a
                                                                className="dropdown-item"
                                                                href="javascript:void(0);"
                                                                onClick={() =>
                                                                  archiveProfileFun(
                                                                    "subCost",
                                                                    ele
                                                                  )
                                                                }
                                                              >
                                                                {" "}
                                                                <img src="images/archive_job.png" />
                                                                Archive
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </li>
                                                      </>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <td>
                                                  {" "}
                                                  <NoDataFound />
                                                </td>
                                              )}
                                            </>
                                          )}
                                        </ul>
                                      </ul>
                                    </div>
                                    <div className="Button d-flex justify-content-between align-items-center mt-4">
                                      <button
                                        onClick={() =>
                                          showAddCostProfileModal("subCost")
                                        }
                                        className="Create px-2"
                                      >
                                        Add New Profile Option
                                      </button>

                                      {/* <button class="Create px-4" data-toggle="modal" data-target="#ProjectProfileOptionDetailsEdit">Modify</button>
                             <button class="Outline px-2">Archive Profile Option</button> */}
                                    </div>
                                  </div>
                                  <div className="Button d-flex flex-column align-items-center justify-content-center mx-3">
                                    <button
                                      onClick={() =>
                                        changeMasterSubProfileFUnc(true)
                                      }
                                      className="Create mb-2"
                                    >
                                      Add
                                    </button>
                                    <button
                                      onClick={() =>
                                        changeMasterSubProfileFUnc(false)
                                      }
                                      className="Outline my-2"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                  <div className="CostCodesStyling">
                                    <div className="ModalBigheading mt-5">
                                      <h3>
                                        <span>Master</span>File Profile Option
                                        List
                                      </h3>
                                    </div>
                                    <div className="FilesArea">
                                      <div className="FilesHead  justify-content-end mb-2">
                                        <div className="FilesLeft">
                                          <aside className="mr-0">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search"
                                              onChange={(e) =>
                                                dispatch(
                                                  getAllMasterSubProfilelListAction(
                                                    costId,
                                                    e.target.value
                                                  )
                                                )
                                              }
                                            />
                                            <span>
                                              <img src="images/search.png" />
                                            </span>
                                          </aside>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="MainProjectProfilesArea">
                                      <ul>
                                        {loader ? (
                                          <>
                                            <td>
                                              {" "}
                                              <Loader />
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            {checkArray(
                                              masterSubProfileData?.data
                                            ) ? (
                                              masterSubProfileData?.data?.map(
                                                (ele, ind) => {
                                                  return (
                                                    <>
                                                      <li>
                                                        <a
                                                          onClick={() =>
                                                            updateLeadState({
                                                              ...iLeadState,

                                                              subCostId:
                                                                ele?.id,
                                                            })
                                                          }
                                                          href="javascript:void(0);"
                                                          className={`${
                                                            subCostId ===
                                                            ele?.id
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                        >
                                                          {ele?.name}
                                                        </a>
                                                      </li>
                                                    </>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <td>
                                                {" "}
                                                <NoDataFound />
                                              </td>
                                            )}
                                          </>
                                        )}
                                      </ul>
                                    </div>
                                    {/* <div className="Button d-flex justify-content-between align-items-center mt-4">
                                      <button className="Create">
                                        Add New Profile Option
                                      </button>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="MultiFamilyTabs2">
                  <div className="ResidentialJobArea ViewConfigArea">
                    <div className="ModalAccordian">
                      <div id="accordion">
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#CostCodeProfileSetupTabs2"
                            >
                              <span>
                                <img src="images/side_menu.png" />
                              </span>{" "}
                              Cost Code Profile Setup
                            </h4>
                            <a className="Edit EditViewConfigBodyBtn">
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="CostCodeProfileSetupTabs2"
                            className="collapse show"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="ViewConfigBodyMain">
                                <div className="ViewConfigBody">
                                  <aside style={{ width: "60%" }}>
                                    <ul className="breadcrumbs">
                                      <li>
                                        <a href="javascript:void(0);">
                                          Main Project Profiles{" "}
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          {profileName}
                                        </a>
                                      </li>
                                    </ul>
                                    <div className="ModalBigheading">
                                      <h3>
                                        <span>Profile: </span> {profileName}
                                      </h3>
                                      <h3>
                                        <span>Project Profile Options</span>
                                      </h3>
                                    </div>
                                    <div className="FilesArea">
                                      <div className="FilesHead  justify-content-end mb-2">
                                        <div className="FilesLeft">
                                          <aside className="mr-0">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search"
                                              onChange={(e) =>
                                                dispatch(
                                                  getAllSubCostProfielListAction(
                                                    costId,
                                                    e.target.value
                                                  )
                                                )
                                              }
                                            />
                                            <span>
                                              <img src="images/search.png" />
                                            </span>
                                          </aside>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="MainProjectProfilesArea">
                                      <ul>
                                        {loader ? (
                                          <>
                                            <td>
                                              {" "}
                                              <Loader />
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            {checkArray(
                                              subCostProfileData?.data
                                            ) ? (
                                              subCostProfileData?.data?.map(
                                                (ele, ind) => {
                                                  return (
                                                    <>
                                                      <li>
                                                        <a
                                                          onClick={() =>
                                                            getSubCostID(
                                                              ele?.id
                                                            )
                                                          }
                                                          href="javascript:void(0);"
                                                          className={`${
                                                            subCostId ===
                                                            ele?.id
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                        >
                                                          {ele?.name}
                                                        </a>
                                                        {/* style={{position: "absolute"; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 21px, 0px);" x-placement="bottom-start"}} */}

                                                        <div className="dropdown">
                                                          <span
                                                            className="Dots"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            <img src="images/dots.png" />
                                                          </span>
                                                          <div
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton"
                                                          >
                                                            <a
                                                              className="dropdown-item"
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                showCostProfileDetailsModal(
                                                                  ele
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <img src="images/view-po.png" />
                                                              View Details
                                                            </a>
                                                            <a
                                                              className="dropdown-item"
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                showUpdateProfileModal(
                                                                  "subCost",
                                                                  ele
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <img src="images/edit.png" />
                                                              Modify
                                                            </a>
                                                            <a
                                                              className="dropdown-item"
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                archiveProfileFun(
                                                                  "subCost",
                                                                  ele
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <img src="images/archive_job.png" />
                                                              Archive
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <td>
                                                {" "}
                                                <NoDataFound />
                                              </td>
                                            )}
                                          </>
                                        )}
                                      </ul>
                                    </div>

                                    <div className="Button d-flex justify-content-between align-items-center mt-4">
                                      <button
                                        className="Create"
                                        onClick={() =>
                                          showAddCostProfileModal("subCost")
                                        }
                                      >
                                        Add New Profile Option
                                      </button>
                                      {/* <button class="Outline" data-toggle="modal" data-target="#CostProfileCategoryDetails" >View Details</button> */}
                                    </div>
                                  </aside>
                                </div>
                              </div>
                              <div className="ViewConfigBodyHideMain">
                                <div className="ViewConfigBodyHide flex-column ViewConfigBodyHideWhitespace">
                                  <aside style={{ width: "60%" }}>
                                    <ul className="breadcrumbs">
                                      <li>
                                        <a href="javascript:void(0);">
                                          Main Project Profiles
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          {profileName}
                                        </a>
                                      </li>
                                    </ul>
                                    <div className="ModalBigheading">
                                      <h3>
                                        <span>Profile: </span> {profileName}
                                      </h3>
                                      {/* <h3>
                                        <span>Project Profile Options</span>
                                      </h3> */}
                                    </div>
                                  </aside>
                                  <aside
                                    className="d-flex"
                                    style={{ width: "100%" }}
                                  >
                                    <div>
                                      <div className="ModalBigheading mt-4">
                                        <h3>
                                          <span>Categories</span>
                                        </h3>
                                      </div>
                                      <div className="FilesArea">
                                        <div className="FilesHead  justify-content-end mb-2">
                                          <div className="FilesLeft">
                                            <aside className="mr-0">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search"
                                                onChange={(e) => {
                                                  dispatch(
                                                    getAllCategoryListAction(
                                                      subCostId,
                                                      costId,
                                                      e.target.value
                                                    )
                                                  );
                                                }}
                                              />
                                              <span>
                                                <img src="images/search.png" />
                                              </span>
                                            </aside>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="MainProjectProfilesArea"
                                        style={{ width: "100%" }}
                                      >
                                        <ul>
                                          <li>
                                            <h6>Category Major Number</h6>
                                            <h6>Category Description</h6>
                                          </li>
                                          {loader ? (
                                            <>
                                              <td>
                                                {" "}
                                                <Loader />
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              {checkArray(
                                                categoryListData?.data
                                              ) ? (
                                                categoryListData?.data?.map(
                                                  (ele, ind) => {
                                                    return (
                                                      <>
                                                        <li>
                                                          <a
                                                            onClick={() =>
                                                              updateLeadState({
                                                                ...iLeadState,

                                                                categoryId:
                                                                  ele?.id,
                                                              })
                                                            }
                                                            href="javascript:void(0);"
                                                            className={`${
                                                              categoryId ===
                                                              ele?.id
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                          >
                                                            {
                                                              ele?.CostCategory
                                                                ?.number
                                                            }
                                                          </a>
                                                          {/* <span>
                                                            {
                                                              ele?.CostCategory
                                                                ?.number
                                                            }
                                                          </span> */}
                                                          <span>
                                                            {ele?.name}
                                                          </span>
                                                          <div className="dropdown">
                                                            <span
                                                              className="Dots"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              <img src="images/dots.png" />
                                                            </span>
                                                            <div
                                                              className="dropdown-menu"
                                                              aria-labelledby="dropdownMenuButton"
                                                            >
                                                              <a
                                                                className="dropdown-item"
                                                                href="javascript:void(0);"
                                                                onClick={() =>
                                                                  showCostProfileDetailsModal(
                                                                    ele
                                                                  )
                                                                }
                                                              >
                                                                <img src="images/view-po.png" />
                                                                View Details
                                                              </a>
                                                              <a
                                                                className="dropdown-item"
                                                                href="javascript:void(0);"
                                                                onClick={() =>
                                                                  showUpdateCategoryModal(
                                                                    ele
                                                                  )
                                                                }
                                                              >
                                                                {" "}
                                                                <img src="images/edit.png" />
                                                                Modify
                                                              </a>
                                                              <a
                                                                className="dropdown-item"
                                                                href="javascript:void(0);"
                                                                onClick={() =>
                                                                  archiveCategoryFun(
                                                                    "category",
                                                                    ele
                                                                  )
                                                                }
                                                              >
                                                                {" "}
                                                                <img src="images/archive_job.png" />
                                                                Archive
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </li>
                                                      </>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <td>
                                                  {" "}
                                                  <NoDataFound />
                                                </td>
                                              )}
                                            </>
                                          )}
                                        </ul>
                                      </div>
                                      {subCostId ? (
                                        <div className="Button d-flex justify-content-between align-items-center mt-4">
                                          <button
                                            className="Create"
                                            onClick={showAddCategoryModal}
                                          >
                                            Add New Category
                                          </button>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className="Button d-flex flex-column align-items-center justify-content-center mx-2">
                                      <button
                                        onClick={() =>
                                          changeMasterCategoryFUnc(true)
                                        }
                                        className="Create mb-2"
                                      >
                                        Add
                                      </button>
                                      <button
                                        onClick={() =>
                                          changeMasterCategoryFUnc(false)
                                        }
                                        className="Outline mt-2"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                    <div>
                                      <div className="ModalBigheading">
                                        <h3>
                                          <span>Master File:</span>Categories
                                          List
                                        </h3>
                                      </div>
                                      <div className="FilesArea">
                                        <div className="FilesHead  justify-content-end mb-2">
                                          <div className="FilesLeft">
                                            <aside className="mr-0">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search"
                                                onChange={(e) => {
                                                  dispatch(
                                                    getAllMasterCategorylListAction(
                                                      subCostId,
                                                      costId,
                                                      e.target.value
                                                    )
                                                  );
                                                }}
                                              />
                                              <span>
                                                <img src="images/search.png" />
                                              </span>
                                            </aside>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="MainProjectProfilesArea"
                                        style={{ width: "100%" }}
                                      >
                                        <ul>
                                          <li>
                                            <h6>Category Major Number</h6>
                                            <h6>Category Description</h6>
                                          </li>
                                          {loader ? (
                                            <>
                                              <td>
                                                {" "}
                                                <Loader />
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              {checkArray(
                                                masterCategoryListData?.data
                                              ) ? (
                                                masterCategoryListData?.data?.map(
                                                  (ele, ind) => {
                                                    return (
                                                      <>
                                                        <li>
                                                          <span>
                                                            {
                                                              ele?.CostCategory
                                                                ?.number
                                                            }
                                                          </span>
                                                          <span>
                                                            {ele?.name}
                                                          </span>
                                                          <div className="dropdown">
                                                            <span
                                                              className="Dots"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              <img src="images/dots.png" />
                                                            </span>
                                                            <div
                                                              className="dropdown-menu"
                                                              aria-labelledby="dropdownMenuButton"
                                                            >
                                                              <a
                                                                className="dropdown-item"
                                                                href="javascript:void(0);"
                                                                onClick={() =>
                                                                  archiveCategoryFun(
                                                                    "category",
                                                                    ele
                                                                  )
                                                                }
                                                              >
                                                                {" "}
                                                                <img src="images/archive_job.png" />
                                                                Archive
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </li>
                                                      </>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <td>
                                                  {" "}
                                                  <NoDataFound />
                                                </td>
                                              )}
                                            </>
                                          )}
                                        </ul>
                                      </div>
                                      {/* <div className="Button d-flex justify-content-between align-items-center mt-4">
                                        <button
                                          className="Create"
                                          data-toggle="modal"
                                          data-target="#AddNewCostProfileCategory"
                                        >
                                          Add New Category
                                        </button>
                                       
                                      </div> */}
                                    </div>
                                  </aside>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="UnitsTabs">
                  <div className="ResidentialJobArea ViewConfigArea">
                    <div className="ModalAccordian">
                      <div id="accordion">
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#CostCodeProfileSetupTabs2"
                            >
                              <span>
                                <img src="images/side_menu.png" />
                              </span>{" "}
                              Cost Code Profile Setup
                            </h4>
                            <a className="Edit EditViewConfigBodyBtn">
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="CostCodeProfileSetupTabs2"
                            className="collapse show"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="ViewConfigBodyMain">
                                <div className="ViewConfigBody">
                                  <aside style={{ width: "90%" }}>
                                    <ul className="breadcrumbs">
                                      <li>
                                        <a href="javascript:void(0);">
                                          Main Project Profiles
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          {profileName}
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          {" "}
                                          {profileName}
                                        </a>
                                      </li>
                                    </ul>
                                    <div className="ModalBigheading">
                                      <h3>
                                        <span>Profile: </span> {profileName}
                                      </h3>
                                      <h3>
                                        <span>Categories</span>
                                      </h3>
                                    </div>
                                    <div className="FilesArea">
                                      <div className="FilesHead  justify-content-end mb-2">
                                        <div className="FilesLeft">
                                          <aside className="mr-0">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search"
                                              onChange={(e) =>
                                                dispatch(
                                                  getAllCategoryListAction(
                                                    subCostId,
                                                    costId,
                                                    e.target.value
                                                  )
                                                )
                                              }
                                            />
                                            <span>
                                              <img src="images/search.png" />
                                            </span>
                                          </aside>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="MainProjectProfilesArea MainProjectProfilesAreaSpan"
                                      style={{ width: "100%" }}
                                    >
                                      <ul>
                                        <li>
                                          <h6>Category Major Number</h6>
                                          <h6>Category Description</h6>
                                        </li>
                                        {loader ? (
                                          <>
                                            <td>
                                              {" "}
                                              <Loader />
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            {checkArray(
                                              categoryListData?.data
                                            ) ? (
                                              categoryListData?.data?.map(
                                                (ele, ind) => {
                                                  return (
                                                    <>
                                                      <li>
                                                        <span
                                                          className={`${
                                                            categoryId ===
                                                            ele?.CostCategory
                                                              ?.id
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                          onClick={() =>
                                                            getCategoryId(
                                                              ele?.CostCategory
                                                                ?.id
                                                            )
                                                          }
                                                        >
                                                          {
                                                            ele?.CostCategory
                                                              ?.number
                                                          }
                                                        </span>
                                                        <span
                                                          onClick={() =>
                                                            getCategoryId(
                                                              ele?.CostCategory
                                                                ?.id
                                                            )
                                                          }
                                                          className={`${
                                                            categoryId ===
                                                            ele?.CostCategory
                                                              ?.id
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                        >
                                                          {ele?.name}
                                                        </span>
                                                        <div className="dropdown">
                                                          <span
                                                            className="Dots"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            <img src="images/dots.png" />
                                                          </span>
                                                          <div
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton"
                                                          >
                                                            <a
                                                              className="dropdown-item"
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                showCostProfileDetailsModal(
                                                                  ele
                                                                )
                                                              }
                                                            >
                                                              <img src="images/view-po.png" />
                                                              View Details
                                                            </a>
                                                            <a
                                                              className="dropdown-item"
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                showUpdateCategoryModal(
                                                                  ele
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <img src="images/edit.png" />
                                                              Modify
                                                            </a>
                                                            <a
                                                              className="dropdown-item"
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                archiveCategoryFun(
                                                                  "category",
                                                                  ele
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <img src="images/archive_job.png" />
                                                              Archive
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <td>
                                                {" "}
                                                <NoDataFound />
                                              </td>
                                            )}
                                          </>
                                        )}
                                      </ul>
                                    </div>
                                    <div className="Button d-flex justify-content-between align-items-center mt-4">
                                      <button
                                        className="Create"
                                        onClick={showAddCategoryModal}
                                      >
                                        Add New Category
                                      </button>
                                      {/* <button class="Outline">View Details</button> */}
                                    </div>
                                  </aside>
                                </div>
                              </div>
                              <div className="ViewConfigBodyHideMain">
                                <div className="ViewConfigBodyHide flex-column ViewConfigBodyHideWhitespace">
                                  <aside style={{ width: "85%" }}>
                                    <ul className="breadcrumbs">
                                      <li>
                                        <a href="javascript:void(0);">
                                          Main Project Profiles
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          Multi-Family
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">Units</a>
                                      </li>
                                    </ul>
                                    {/* <div className="ModalBigheading">
                                      <h3>
                                        <span>Profile: </span> Multi-Family
                                      </h3>
                                      <h3>
                                        <span>Categories</span>
                                      </h3>
                                    </div>
                                    <div
                                      className="MainProjectProfilesArea MainProjectProfilesAreaSpan"
                                      style={{ width: "100%" }}
                                    >
                                      <ul>
                                        <li>
                                          <h6>Category Major Number</h6>
                                          <h6>Category Description</h6>
                                        </li>
                                        <li>
                                          <span>11.01</span>{" "}
                                          <span>Plumbing</span>
                                          <div className="dropdown">
                                            <span
                                              className="Dots"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </span>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuButton"
                                            >
                                              <a
                                                className="dropdown-item"
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#ProjectProfileDetails"
                                              >
                                                <img src="images/view-po.png" />
                                                View Details
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#UpdateCostProfileCategoryDetails"
                                              >
                                                <img src="images/edit.png" />
                                                Modify
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/archive_job.png" />
                                                Archive
                                              </a>
                                            </div>
                                          </div>
                                        </li>
                                    
                                      </ul>
                                    </div>
                                    <div className="Button d-flex justify-content-between align-items-center mt-4">
                                      <button
                                        className="Create"
                                        data-toggle="modal"
                                        data-target="#AddNewCostProfileCategory"
                                      >
                                        Add New Category
                                      </button> 
                                    
                                    </div>*/}
                                  </aside>
                                  <aside
                                    className="d-flex flex-column"
                                    style={{ width: "100%" }}
                                  >
                                    <div
                                      className="d-flex py-5"
                                      style={{ overflowX: "scroll" }}
                                    >
                                      <div>
                                        <div className="ModalBigheading mt-4">
                                          <h3>
                                            <span>Cost Codes</span>
                                          </h3>
                                        </div>
                                        <div className="FilesArea">
                                          <div className="FilesHead  justify-content-end mb-2">
                                            <div className="FilesLeft">
                                              <aside className="mr-0">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Search"
                                                  onChange={(e) => {
                                                    dispatch(
                                                      getAllCostCodeListAction(
                                                        categoryId,
                                                        costId,
                                                        subCostId,
                                                        e.target.value
                                                      )
                                                    );
                                                  }}
                                                />
                                                <span>
                                                  <img src="images/search.png" />
                                                </span>
                                              </aside>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="MainProjectProfilesArea WhiteSpace"
                                          style={{ width: "100%" }}
                                        >
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Cost Code</th>
                                                <th>Cost Code Description</th>
                                                <th>Classification</th>
                                                <th>Apply markup</th>
                                                <th>User Estimation Message</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {loader ? (
                                                <>
                                                  <td>
                                                    {" "}
                                                    <Loader />
                                                  </td>
                                                </>
                                              ) : (
                                                <>
                                                  {checkArray(
                                                    costCodesListData?.data
                                                  ) ? (
                                                    costCodesListData?.data?.map(
                                                      (ele, ind) => {
                                                        return (
                                                          <>
                                                            <tr>
                                                              <td>
                                                                {
                                                                  ele?.CostCode
                                                                    ?.number
                                                                }
                                                              </td>
                                                              <td>
                                                                {ele?.name}
                                                              </td>
                                                              <td>
                                                                {
                                                                  ele?.classification
                                                                }
                                                              </td>
                                                              <td>
                                                                {ele?.applyMarkup
                                                                  ? "Yes"
                                                                  : "No"}
                                                              </td>
                                                              <td>
                                                                {
                                                                  ele?.estimationMessage
                                                                }
                                                              </td>
                                                              <td>
                                                                <div className="dropdown">
                                                                  <span
                                                                    className="Dots"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                  >
                                                                    <img src="images/dots.png" />
                                                                  </span>
                                                                  <div
                                                                    className="dropdown-menu"
                                                                    aria-labelledby="dropdownMenuButton"
                                                                  >
                                                                    <a
                                                                      className="dropdown-item"
                                                                      href="javascript:void(0);"
                                                                      onClick={() =>
                                                                        showCostCOdeDetails(
                                                                          ele
                                                                        )
                                                                      }
                                                                    >
                                                                      <img src="images/view-po.png" />
                                                                      View
                                                                      Details
                                                                    </a>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      href="javascript:void(0);"
                                                                      onClick={() =>
                                                                        showUpdateCostCodeModal(
                                                                          ele
                                                                        )
                                                                      }
                                                                    >
                                                                      {" "}
                                                                      <img src="images/edit.png" />
                                                                      Modify
                                                                    </a>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      href="javascript:void(0);"
                                                                      onClick={() =>
                                                                        archiveCostCodeFun(
                                                                          "costCode",
                                                                          ele
                                                                        )
                                                                      }
                                                                    >
                                                                      {" "}
                                                                      <img src="images/archive_job.png" />
                                                                      Archive
                                                                    </a>
                                                                  </div>
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        );
                                                      }
                                                    )
                                                  ) : (
                                                    <td>
                                                      {" "}
                                                      <NoDataFound />
                                                    </td>
                                                  )}
                                                </>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        <div className="Button d-flex justify-content-between align-items-center mt-4">
                                          <button
                                            className="Create"
                                            onClick={showAddCostCodeModal}
                                          >
                                            Add Cost Code
                                          </button>
                                        </div>
                                      </div>
                                      <div className="Button d-flex flex-column align-items-center justify-content-center mx-2">
                                        <button className="Create mb-b">
                                          Add
                                        </button>
                                        <button className="Outline my-2">
                                          Remove
                                        </button>
                                      </div>
                                      <div>
                                        <div className="ModalBigheading">
                                          <h3>
                                            <span>Master File:</span>Category
                                            Cost Code List
                                          </h3>
                                        </div>
                                        <div className="FilesArea">
                                          <div className="FilesHead  justify-content-end mb-2">
                                            <div className="FilesLeft">
                                              <aside className="mr-0">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Search"
                                                  onChange={(e) => {
                                                    dispatch(
                                                      getAllCostCodeMasterListAction(
                                                        categoryId,
                                                        costId,
                                                        subCostId,
                                                        e.target.value
                                                      )
                                                    );
                                                  }}
                                                />
                                                <span>
                                                  <img src="images/search.png" />
                                                </span>
                                              </aside>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="MainProjectProfilesArea WhiteSpace"
                                          style={{ width: "100%" }}
                                        >
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Cost Code</th>
                                                <th>Cost Code Description</th>
                                                <th>Classification</th>
                                                <th>Apply markup</th>
                                                <th>User Estimation Message</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {loader ? (
                                                <>
                                                  <td>
                                                    {" "}
                                                    <Loader />
                                                  </td>
                                                </>
                                              ) : (
                                                <>
                                                  {checkArray(
                                                    masterCostCodeListData?.data
                                                  ) ? (
                                                    masterCostCodeListData?.data?.map(
                                                      (ele, ind) => {
                                                        return (
                                                          <>
                                                            <tr>
                                                              <td>
                                                                {
                                                                  ele?.CostCode
                                                                    ?.number
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  ele?.CostCode
                                                                    ?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  ele?.classification
                                                                }
                                                              </td>
                                                              <td>
                                                                {ele?.applyMarkup
                                                                  ? "Yes"
                                                                  : "No"}
                                                              </td>
                                                              <td>
                                                                {
                                                                  ele?.estimationMessage
                                                                }
                                                              </td>
                                                            </tr>
                                                          </>
                                                        );
                                                      }
                                                    )
                                                  ) : (
                                                    <td>
                                                      {" "}
                                                      <NoDataFound />
                                                    </td>
                                                  )}
                                                </>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        {/* <div className="Button d-flex justify-content-between align-items-center mt-4">
                                          <button
                                            className="Create"
                                            onClick={showAddCostCodeModal}
                                          >
                                            Add Cost Code
                                          </button>
                                         
                                        </div> */}
                                      </div>
                                    </div>
                                  </aside>
                                </div>
                                {/* <div class="Button d-flex justify-content-center mt-5">
                        <button class="OutlineBlack mr-5 SaveViewConfigBodyBtn">Cancel</button>
                        <button class="Respond ml-5 SaveViewConfigBodyBtn">Save</button>
                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Proforma tab content start */}
                <div className="tab-pane fade" id="ProformaTabs2">
                  <div className="ResidentialJobArea ViewConfigArea">
                    <div className="ModalAccordian">
                      <div id="accordion">
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#CostCodeProfileSetupTabs2"
                            >
                              <span>
                                <img src="images/side_menu.png" />
                              </span>{" "}
                              Cost Code Profile Setup
                            </h4>
                            <a className="Edit EditViewConfigBodyBtn">
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="CostCodeProfileSetupTabs2"
                            className="collapse show"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="ViewConfigBodyMain">
                                <div className="ViewConfigBody">
                                  <aside style={{ width: "60%" }}>
                                    <div className="ModalBigheading">
                                      <h3>
                                        <span>Profile: </span> Proforma
                                      </h3>
                                      <h3>
                                        <span>Project Profile Options</span>
                                      </h3>
                                    </div>
                                    <div className="FilesArea">
                                      <div className="FilesHead  justify-content-end mb-2">
                                        <div className="FilesLeft">
                                          <aside className="mr-0">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search"
                                            />
                                            <span>
                                              <img src="images/search.png" />
                                            </span>
                                          </aside>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="MainProjectProfilesArea">
                                      <ul>
                                        <li>
                                          <a href="javascript:void(0);">
                                            Land, Design, Development and Admin
                                            Costs
                                          </a>
                                          <div className="dropdown">
                                            <span
                                              className="Dots"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </span>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuButton"
                                            >
                                              <a
                                                className="dropdown-item"
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#ProjectProfileOptionDetails"
                                              >
                                                {" "}
                                                <img src="images/view-po.png" />
                                                View Details
                                              </a>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="Button d-flex justify-content-between align-items-center mt-4">
                                      <button
                                        className="Create"
                                        data-toggle="modal"
                                        data-target="#AddNewProjectProfileOption"
                                      >
                                        Add New Profile Option
                                      </button>
                                      {/* <button class="Create">Modify</button>
                            <button class="Outline">Archive Profile Option</button> */}
                                    </div>
                                  </aside>
                                  <aside
                                    className="d-flex"
                                    style={{ width: "100%" }}
                                  >
                                    <div style={{ width: 500 }}>
                                      <div className="ModalBigheading mt-4">
                                        <h3>
                                          <span>Categories</span>
                                        </h3>
                                        <h3>
                                          <span>
                                            Active Profile Option List
                                          </span>
                                        </h3>
                                      </div>
                                      <div className="FilesArea">
                                        <div className="FilesHead  justify-content-end mb-2">
                                          <div className="FilesLeft">
                                            <aside className="mr-0">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search"
                                              />
                                              <span>
                                                <img src="images/search.png" />
                                              </span>
                                            </aside>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="MainProjectProfilesArea MainProjectProfilesAreaSpan SpanNot"
                                        style={{ width: "100%" }}
                                      >
                                        <ul>
                                          <li>
                                            <h6>Category Description</h6>
                                          </li>
                                          <li>
                                            <span>Interior Finishing</span>
                                            <div className="dropdown">
                                              <span
                                                className="Dots"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </span>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-target="#ProjectProfileDetails"
                                                >
                                                  <img src="images/view-po.png" />
                                                  View Details
                                                </a>
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-target="#UpdateCostProfileCategoryDetails"
                                                >
                                                  <img src="images/edit.png" />
                                                  Modify
                                                </a>
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/archive_job.png" />
                                                  Archive
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="Button d-flex justify-content-between align-items-center mt-4">
                                        <button
                                          className="Create"
                                          data-toggle="modal"
                                          data-target="#AddNewCostProfileCategory"
                                        >
                                          Add New Category
                                        </button>
                                        {/* <button class="Create" >Modify</button>
                            <button class="Outline" >Archive Category</button> */}
                                      </div>
                                    </div>
                                    <div className="Button d-flex flex-column align-items-center justify-content-center mx-2">
                                      <button className="Create mb-2">
                                        Add
                                      </button>
                                      <button className="Outline mt-2">
                                        Remove
                                      </button>
                                    </div>
                                    <div>
                                      <div className="ModalBigheading">
                                        <h3>
                                          <span>Master File:</span>Categories
                                          List
                                        </h3>
                                      </div>
                                      <div className="FilesArea">
                                        <div className="FilesHead  justify-content-end mb-2">
                                          <div className="FilesLeft">
                                            <aside className="mr-0">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search"
                                              />
                                              <span>
                                                <img src="images/search.png" />
                                              </span>
                                            </aside>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="MainProjectProfilesArea MainProjectProfilesAreaSpan"
                                        style={{ width: "100%" }}
                                      >
                                        <ul>
                                          <li>
                                            <h6>Category Major Number</h6>
                                            <h6>Category Description</h6>
                                          </li>
                                          <li>
                                            <span>15.01</span>
                                            <span>Exterior Finish</span>
                                            <div className="dropdown">
                                              <span
                                                className="Dots"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </span>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/archive_job.png" />
                                                  Archive
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>16.01</span>
                                            <span>
                                              Insulation Batt / Spray &amp;
                                              Vapor Barrier
                                            </span>
                                            <div className="dropdown">
                                              <span
                                                className="Dots"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </span>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/archive_job.png" />
                                                  Archive
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>17.01</span>
                                            <span>
                                              Drywall/Gyprock,Taping,Textured
                                              Ceilings
                                            </span>
                                            <div className="dropdown">
                                              <span
                                                className="Dots"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </span>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/archive_job.png" />
                                                  Archive
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>25.01</span>
                                            <span>
                                              Flat Work - Attached Garage Floor
                                            </span>
                                            <div className="dropdown">
                                              <span
                                                className="Dots"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </span>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/archive_job.png" />
                                                  Archive
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li className="active">
                                            <span>27.01</span>
                                            <span>
                                              Cleaning, Rentals &amp; Site Labor
                                            </span>
                                            <div className="dropdown">
                                              <span
                                                className="Dots"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </span>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/archive_job.png" />
                                                  Archive
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>28.01</span>
                                            <span>
                                              28.01 Financing, Legal &amp;
                                              Insurance Fees
                                            </span>
                                            <div className="dropdown">
                                              <span
                                                className="Dots"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </span>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/archive_job.png" />
                                                  Archive
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>20.01</span>
                                            <span>
                                              Paiting, Staining Interior
                                            </span>
                                            <div className="dropdown">
                                              <span
                                                className="Dots"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </span>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/archive_job.png" />
                                                  Archive
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>30.01</span>
                                            <span>Framing</span>
                                            <div className="dropdown">
                                              <span
                                                className="Dots"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </span>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/archive_job.png" />
                                                  Archive
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>31.01</span>
                                            <span>
                                              Custom Options R/I's &amp; Finals
                                            </span>
                                            <div className="dropdown">
                                              <span
                                                className="Dots"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </span>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/archive_job.png" />
                                                  Archive
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <span>200.01</span>
                                            <span>
                                              Design / Architect / Engineers /
                                              Permits
                                            </span>
                                            <div className="dropdown">
                                              <span
                                                className="Dots"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </span>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/archive_job.png" />
                                                  Archive
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="Button d-flex justify-content-between align-items-center mt-4">
                                        <button
                                          className="Create"
                                          data-toggle="modal"
                                          data-target="#AddNewCostProfileCategory"
                                        >
                                          Add New Category
                                        </button>
                                        {/* <button class="Create" >Modify</button>
                              <button class="Outline" >Archive Category</button> */}
                                      </div>
                                    </div>
                                  </aside>
                                </div>
                                {/* <div class="Button d-flex justify-content-center mt-5">
                        <button class="OutlineBlack mr-5 SaveViewConfigBodyBtn">Cancel</button>
                        <button class="Respond ml-5 SaveViewConfigBodyBtn">Save</button>
                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <AdminConstructor
                  adminConstructionStatus={adminConstructionStatus}
                />
                <div className="tab-pane fade" id="EstimationOldss">
                
                <EstimationAdmin ProjectId={ProjectId} />
                </div>
                {/* {estimationStatus && <EstimationAdmin ProjectId={ProjectId} />} */}

                <div className="tab-pane fade" id="BaseCodeProformaBlocks">
                  <div className="ResidentialJobArea ViewConfigArea">
                    <div className="ModalAccordian">
                      <div id="accordion15">
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#ProformaBlocksBaseCostSetup"
                            >
                              <span>
                                <img src="images/blocks_units.png" />
                              </span>{" "}
                              Blocks Base Cost Setup
                            </h4>
                            <a
                              onClick={() =>
                                updateLeadState({
                                  ...iLeadState,
                                  checkStateToShow: !checkStateToShow,
                                })
                              }
                              className="Edit EditViewConfigBodyBtn"
                            >
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="ProformaBlocksBaseCostSetup"
                            className="collapse show"
                            data-parent="#accordion15"
                          >
                            <div className="card-body">
                              <div className="ViewConfigBodyMain">
                                <div className="ViewConfigBody flex-column">
                                  {checkStateToShow ? (
                                    checkArray(
                                      proformaBaseCostListData?.data
                                    ) ? (
                                      proformaBaseCostListData?.data?.map(
                                        (ele, i) => {
                                          return (
                                            <>
                                              <div className="ProformaTabContent">
                                                <div className="RedHeaderTable">
                                                  <table>
                                                    <thead>
                                                      <tr>
                                                        <th colSpan={4}>
                                                          {ele?.name}
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>Cost Code #</td>
                                                        <td>Description</td>
                                                        <td>Base Cost</td>
                                                        <td>Unit of Measure</td>
                                                      </tr>
                                                      {checkArray(
                                                        ele?.ProformaBaseCosts
                                                      ) ? (
                                                        ele?.ProformaBaseCosts?.map(
                                                          (item, i) => {
                                                            return (
                                                              <tr>
                                                                <td>
                                                                  {
                                                                    item
                                                                      ?.CostCode
                                                                      ?.number
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    item
                                                                      ?.CostCode
                                                                      ?.name
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    item?.baseCost
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    item?.Unit
                                                                      ?.name
                                                                  }
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <NoDataFound />
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <div className="form-group">
                                                  <h6>
                                                    Use Consolidated Cost Codes
                                                  </h6>
                                                  <p>
                                                    {ele?.consolidateCost
                                                      ? "On"
                                                      : "Off"}
                                                  </p>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )
                                    ) : (
                                      <>
                                        <NoDataFound />
                                      </>
                                    )
                                  ) : checkArray(
                                      proformaBaseCostListData?.data
                                    ) ? (
                                    proformaBaseCostListData?.data?.map(
                                      (ele, i) => {
                                        return (
                                          <>
                                            <div className="ProformaTabContent">
                                              <div className="RedHeaderTable">
                                                <table>
                                                  <thead>
                                                    <tr>
                                                      <th colSpan={4}>
                                                        {ele?.name}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>Cost Code #</td>
                                                      <td>Description</td>
                                                      <td>Base Cost</td>
                                                      <td>Unit of Measure</td>
                                                    </tr>
                                                    {checkArray(
                                                      ele?.ProformaBaseCosts
                                                    ) ? (
                                                      ele?.ProformaBaseCosts?.map(
                                                        (item, i) => {
                                                          return (
                                                            <tr>
                                                              <td>
                                                                {
                                                                  item?.CostCode
                                                                    ?.number
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  item?.CostCode
                                                                    ?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                <div class="form-group">
                                                                  <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    placeholder="2,100.00"
                                                                    name="baseCost"
                                                                    defaultValue={
                                                                      item?.baseCost
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      updatePrfomoraFunc(
                                                                        item?.id,
                                                                        e.target
                                                                          .value,
                                                                        item
                                                                          ?.Unit
                                                                          ?.id,
                                                                        ele?.consolidateCost
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                                {/* value={baseCost} */}
                                                              </td>
                                                              <td>
                                                                <div class="form-group">
                                                                  <select
                                                                    className="form-control"
                                                                    name="unitId"
                                                                    defaultValue={
                                                                      item?.Unit
                                                                        ?.id
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      updatePrfomoraFunc(
                                                                        item?.id,
                                                                        item?.baseCost,
                                                                        e.target
                                                                          .value,
                                                                        ele?.consolidateCost
                                                                      )
                                                                    }
                                                                  >
                                                                    <option value="">
                                                                      Select
                                                                    </option>
                                                                    {checkArray(
                                                                      unitAdminListData
                                                                    ) &&
                                                                      unitAdminListData?.map(
                                                                        (
                                                                          ele,
                                                                          i
                                                                        ) => {
                                                                          return (
                                                                            <>
                                                                              <option
                                                                                value={
                                                                                  ele?.id
                                                                                }
                                                                              >
                                                                                {
                                                                                  ele?.type
                                                                                }
                                                                              </option>
                                                                            </>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </select>
                                                                  {/* <input type="text" class="form-control" placeholder="2,100.00"  /> */}
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )
                                                    ) : (
                                                      <NoDataFound />
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                              <div className="form-group">
                                                <h6>
                                                  Use Consolidated Cost Codes
                                                </h6>
                                                <label class="switch">
                                                  <input
                                                    type="checkbox"
                                                    // onChange={(e) =>updatePrfomoraFunc(item?.id,item?.baseCost,e.target.value,e.target.checked )}
                                                  />
                                                  <span class="slider Left0"></span>
                                                </label>
                                                {/* <p>{ele?.consolidateCost ? "On" :"Off"}</p> */}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <>
                                      <NoDataFound />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="ReminderWidcast">
                  <div className="ResidentialJobArea ViewConfigArea">
                    <div className="ModalAccordian">
                      <div id="accordionReminderWidcast">
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#GeneralInformationReminder"
                            >
                              <span>
                                <img src="images/generation_information.png" />
                              </span>{" "}
                              General Information
                            </h4>
                            <a
                              onClick={() =>
                                updateLeadState({
                                  ...iLeadState,
                                  widcastUpperSide: !widcastUpperSide,
                                })
                              }
                              className="Edit EditViewConfigBodyBtn"
                            >
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="GeneralInformationReminder"
                            className="collapse show"
                            data-parent="#accordionReminderWidcast"
                          >
                            <div className="card-body">
                              {widcastUpperSide ? (
                                <div className="ViewConfigBodyMain">
                                  <div className="ViewConfigBody">
                                    <aside>
                                      <div className="ModalBigheading">
                                        <h3>
                                          <span>When reminder is reached:</span>
                                        </h3>
                                      </div>
                                      <div className="form-group">
                                        <h6>Send Bell Notification</h6>
                                        <p>
                                          {viewWidCastData?.notData?.[0]
                                            ?.is_bell
                                            ? "On"
                                            : "Off"}
                                        </p>
                                      </div>
                                      <div className="form-group">
                                        <h6>Send Email</h6>
                                        <p>
                                          {viewWidCastData?.notData?.[0]
                                            ?.is_email
                                            ? "On"
                                            : "Off"}
                                        </p>
                                      </div>
                                      <div className="form-group">
                                        <h6>Send Text</h6>
                                        <p>
                                          {viewWidCastData?.notData?.[0]
                                            ?.is_text
                                            ? "On"
                                            : "Off"}
                                        </p>
                                      </div>
                                    </aside>
                                  </div>
                                </div>
                              ) : (
                                <div className="ViewConfigBodyHideMain">
                                  <div className="ViewConfigBodyHide">
                                    <aside>
                                      <div className="ModalBigheading">
                                        <h3>
                                          <span>When reminder is reached:</span>
                                        </h3>
                                      </div>
                                      <div className="form-group">
                                        <h6>Send Bell Notification</h6>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            name="is_bell"
                                            value={is_bell}
                                            onChange={handleCheckbox}
                                          />
                                          <span
                                            className="slider"
                                            style={{ left: 0 }}
                                          />
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <h6>Send Email</h6>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            name="is_email"
                                            value={is_email}
                                            onChange={handleCheckbox}
                                          />
                                          <span
                                            className="slider"
                                            style={{ left: 0 }}
                                          />
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <h6>Send Text</h6>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            name="is_text"
                                            value={is_text}
                                            onChange={handleCheckbox}
                                          />
                                          <span
                                            className="slider"
                                            style={{ left: 0 }}
                                          />
                                        </label>
                                      </div>
                                    </aside>
                                  </div>
                                  <div className="Button d-flex justify-content-center">
                                    <button className="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                                      Cancel
                                    </button>
                                    <button
                                      onClick={() =>
                                        updateWidCastFunction(
                                          viewWidCastData?.notData?.[0]?.id
                                        )
                                      }
                                      className="Respond ml-5 SaveViewConfigBodyBtn"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <h4
                              data-toggle="collapse"
                              href="#WidCastConfigurationTabs"
                            >
                              <span>
                                <img src="images/project_collapse.png" />
                              </span>{" "}
                              WidCast Configuration
                            </h4>
                            <a className="Edit EditViewConfigBodyBtn">
                              <img src="images/Action-1.png" />
                            </a>
                          </div>
                          <div
                            id="WidCastConfigurationTabs"
                            className="collapse show"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              {/* <div className="ViewConfigBodyMain">
                <div className="ViewConfigBody">
                  <aside className="w-100">
                    <div className="Tableheader">
                      <h6 className="mb-0">WidCasts</h6>
                    </div>
                    <div className="TableList TableListHeader NewTableList TableHeightScroll">
                      <table>
                        <thead>
                          <tr>
                            <th>WidCast Message</th>
                            <th>Originator</th>
                            <th>Designator</th>
                            <th>Date of the WidCast</th>
                            <th>Config Type</th>
                            <th>Turned off By</th>
                            <th>Repeat</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Purchase Order approved by Vendor</td>
                            <td>Process originator</td>
                            <td>Estimators</td>
                            <td>01/31/2024</td>
                            <td>Global</td>
                            <td>John Doe</td>
                            <td>Every 10 min</td>
                            <td>
                              <div className="TableThreeDots ThreeDotsSwitch text-center">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth2">
                                      <li>
                                        <a href="javascript:void(0);">
                                          <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider" />
                                          </label>
                                          Turn off WidCast
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Supplier Credit approved by Vendor</td>
                            <td>Process originator</td>
                            <td>Estimators</td>
                            <td>01/31/2024</td>
                            <td>Notification Config </td>
                            <td />
                            <td>Every hour</td>
                            <td>
                              <div className="TableThreeDots ThreeDotsSwitch text-center">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth2">
                                      <li>
                                        <a href="javascript:void(0);">
                                          <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider" />
                                          </label>
                                          Turn off WidCast
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Quote Returned</td>
                            <td>Process originator</td>
                            <td>Estimators</td>
                            <td>01/31/2024</td>
                            <td>Global</td>
                            <td />
                            <td>Every 30 min</td>
                            <td>
                              <div className="TableThreeDots ThreeDotsSwitch text-center">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth2">
                                      <li>
                                        <a href="javascript:void(0);">
                                          <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider" />
                                          </label>
                                          Turn off WidCast
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Back Charge approved by vendor</td>
                            <td>Process originator</td>
                            <td>Estimator, Managers</td>
                            <td>01/31/2024</td>
                            <td>Notification config</td>
                            <td />
                            <td>Every 10 min</td>
                            <td>
                              <div className="TableThreeDots ThreeDotsSwitch text-center">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth2">
                                      <li>
                                        <a href="javascript:void(0);">
                                          <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider" />
                                          </label>
                                          Turn off WidCast
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              New Warranty Request Received from client to job
                              Id
                            </td>
                            <td>Process originator</td>
                            <td>Estimators</td>
                            <td>01/31/2024</td>
                            <td>Global</td>
                            <td />
                            <td>Every 30 min</td>
                            <td>
                              <div className="TableThreeDots ThreeDotsSwitch text-center">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth2">
                                      <li>
                                        <a href="javascript:void(0);">
                                          <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider" />
                                          </label>
                                          Turn off WidCast
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>FPO accepted by vendor</td>
                            <td>Process originator</td>
                            <td>Estimators, Construction staff</td>
                            <td>01/31/2024</td>
                            <td>Notification config</td>
                            <td />
                            <td>Every 30 min</td>
                            <td>
                              <div className="TableThreeDots ThreeDotsSwitch text-center">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth2">
                                      <li>
                                        <a href="javascript:void(0);">
                                          <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider" />
                                          </label>
                                          Turn off WidCast
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="PaginationArea">
                      <h6>Showing 3 of 3 entries</h6>
                      <h6>
                        <img src="images/leftarrow.png" /> 01{" "}
                        <img src="images/rightarrow.png" /> Out 10
                      </h6>
                    </div>
                  </aside>
                </div>
              </div> */}
                              <div className="ViewConfigBodyHideMain">
                                <div className="ViewConfigBodyHide">
                                  <aside className="w-100">
                                    <div className="form-group">
                                      <h6>Config Type</h6>
                                      <div className="d-flex">
                                        <div>
                                          <label className="Radio">
                                            {" "}
                                            Global
                                            <input
                                              type="radio"
                                              name="configType"
                                              value="global"
                                              checked={configType === "global"}
                                              onChange={(e) =>
                                                handleRadioChange(e)
                                              }
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </div>
                                        <div className="ml-5">
                                          <label className="Radio">
                                            {" "}
                                            Use Notification Config
                                            <input
                                              type="radio"
                                              name="configType"
                                              value="Notification"
                                              checked={
                                                configType === "Notification"
                                              }
                                              onChange={(e) =>
                                                handleRadioChange(e)
                                              }
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <h6>Select Notification</h6>
                                          <div className="Categories Categories1">
                                            <div className="Categories_all Categories_all1">
                                              <select
                                                className="form-control"
                                                name="eventId"
                                                value={eventId}
                                                onChange={handleInput}
                                              >
                                                <option value="1">
                                                  Select Country
                                                </option>
                                                <option value="2">
                                                  Select Country 2
                                                </option>
                                              </select>
                                              <img src="images/down.png" />
                                            </div>
                                            <ul>
                                              <li>
                                                <input
                                                  type="text"
                                                  placeholder="Search"
                                                />
                                                <span className="Search">
                                                  <img src="images/search.png" />
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <h6>Search </h6>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <h6>WidCast Message</h6>
                                      <input
                                        type="text"
                                        placeholder="widcast message ..."
                                        name="widcastMessage"
                                        value={widcastMessage}
                                        onChange={handleInput}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <h6>Repeat</h6>
                                      <div className="d-flex">
                                        <div>
                                          <label className="Radio">
                                            {" "}
                                            Every 10 min
                                            <input
                                              type="radio"
                                              name="repeat"
                                              value="10min"
                                              checked={repeat == "10min"}
                                              onChange={(e) =>
                                                handleRadioChange(e)
                                              }
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </div>
                                        <div className="ml-5">
                                          <label className="Radio">
                                            {" "}
                                            Every 30 min
                                            <input
                                              type="radio"
                                              name="repeat"
                                              value="30min"
                                              checked={repeat == "30min"}
                                              onChange={(e) =>
                                                handleRadioChange(e)
                                              }
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </div>
                                        <div className="ml-5">
                                          <label className="Radio">
                                            {" "}
                                            Every hour
                                            <input
                                              type="radio"
                                              name="repeat"
                                              value="60min"
                                              checked={repeat == "60min"}
                                              onChange={(e) =>
                                                handleRadioChange(e)
                                              }
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="Button mb-3">
                                      <button
                                        onClick={addNewWidCastFunc}
                                        className="Create"
                                      >
                                        Add New WidCast
                                      </button>
                                    </div>
                                    <div className="TableList TableListHeader NewTableList TableHeightScroll">
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>WidCast Message</th>
                                            <th>Originator</th>
                                            <th>Designator</th>
                                            <th>Date of the WidCast</th>
                                            <th>Config Type</th>
                                            <th>Turned off By</th>
                                            <th>Repeat</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {loader ? (
                                            <>
                                              <td>
                                                {" "}
                                                <Loader />
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              {checkArray(
                                                widCastListData?.notData
                                              ) ? (
                                                widCastListData?.notData?.map(
                                                  (ele, ind) => {
                                                    return (
                                                      <>
                                                        <tr>
                                                          <td>
                                                            {
                                                              ele?.widcastMessage
                                                            }
                                                          </td>
                                                          <td>
                                                            {ele?.originator}
                                                          </td>
                                                          <td>
                                                            {ele?.designator}
                                                          </td>
                                                          <td>
                                                            {
                                                              ele?.createdAt?.split(
                                                                "T"
                                                              )[0]
                                                            }
                                                          </td>
                                                          <td>
                                                            {ele?.configType}
                                                          </td>
                                                          <td>{ele?.turnBy}</td>
                                                          <td>
                                                            Every {ele?.repeat}
                                                          </td>
                                                          <td>
                                                            <div className="TableThreeDots ThreeDotsSwitch text-center">
                                                              <ul>
                                                                <li className="dropdown">
                                                                  <a
                                                                    className=""
                                                                    role="button"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                  >
                                                                    <img src="images/dots.png" />
                                                                  </a>
                                                                  <ol className="dropdown-menu dropdown-menuwidth2">
                                                                    <li>
                                                                      <a href="javascript:void(0);">
                                                                        <img src="images/Action-1.png" />
                                                                        Edit
                                                                        WidCast
                                                                      </a>
                                                                    </li>
                                                                    {/* <li>
                                        <a href="javascript:void(0);">
                                          <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider" />
                                          </label>
                                          Turn on WidCast
                                        </a>
                                      </li> */}
                                                                    <li>
                                                                      <a
                                                                        onChange={() =>
                                                                          turnOffWidCastAction(
                                                                            ele?.id
                                                                          )
                                                                        }
                                                                        href="javascript:void(0);"
                                                                      >
                                                                        <img src="images/dropdown-delete.png" />
                                                                        Turn off
                                                                        WidCast
                                                                      </a>
                                                                    </li>
                                                                    <li>
                                                                      <a
                                                                        onClick={() =>
                                                                          removeWidcastFunc(
                                                                            ele?.id
                                                                          )
                                                                        }
                                                                        href="javascript:void(0);"
                                                                      >
                                                                        <img src="images/dropdown-delete.png" />
                                                                        Delete
                                                                        WidCast
                                                                      </a>
                                                                    </li>
                                                                  </ol>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <td>
                                                  {" "}
                                                  <NoDataFound />
                                                </td>
                                              )}
                                            </>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="PaginationArea">
                                      <h6>Showing 3 of 3 entries</h6>
                                      <h6>
                                        <img src="images/leftarrow.png" /> 01{" "}
                                        <img src="images/rightarrow.png" /> Out
                                        10
                                      </h6>
                                    </div>
                                  </aside>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="CloseButton">
                          <button className="Close" data-dismiss="modal">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Proforma tab content end */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={CostProfileDetailsMOdal}
        onHide={hideCostProfileDetailsModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideCostProfileDetailsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Project Profile Details</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <h6>Project Profile Description</h6>
                    <p className="ml-0">{ProfileDetailsData?.name}</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <h6>Last Update</h6>
                    <p className="ml-0">
                      {ProfileDetailsData?.updatedAt?.split("T")[0]}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <h6>Updated By</h6>
                    <p className="ml-0">{ProfileDetailsData?.updatedBy}</p>
                  </div>
                </div>
              </div>
              <div className="Button text-center mt-5">
                <button
                  className="Outline"
                  onClick={hideCostProfileDetailsModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addCostProfileModal}
        onHide={hideAddCostProfileModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddCostProfileModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Project Profile</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>
                  Project Profile Description{" "}
                  <span className="required">*</span>
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Project Profile Description"
                  name="name"
                  value={name}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {costProfileError}
                </span>
              </div>
              <div className="Button d-flex justify-content-between mt-5">
                <button onClick={addCostProfileFun} className="Create">
                  Create New Project Profile
                </button>
                <button onClick={hideAddCostProfileModal} className="Outline">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addCategoryModal}
        onHide={hideAddCategoryModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddCategoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Cost Profile Category</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Project Cost Profile</h6>
                      <div
                        className="CommonSelectBox"
                        style={{ width: "100%" }}
                      >
                        <select
                          name="categoryName"
                          value={categoryName}
                          onChange={handleInput}
                        >
                          {checkArray(costProfielistData?.data) &&
                            costProfielistData?.data?.map((ele, i) => {
                              console.log("datatatta", ele);
                              return (
                                <>
                                  <option value={costId}>{ele?.name}</option>
                                </>
                              );
                            })}
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>

                    <div className="form-group">
                      <h6>
                        Master #<span className="required">*</span>
                      </h6>
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={() =>
                            updateLeadState({
                              ...iLeadState,
                              defaultMasterDeceider: !defaultMasterDeceider,
                            })
                          }
                        />
                        <span className="slider" style={{ left: 0 }} />
                      </label>
                    </div>
                    {!defaultMasterDeceider ? (
                      <div className="form-group">
                        <h6>
                          Category #<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Cost Category Number"
                          name="categoryName"
                          value={categoryName}
                          onChange={handleInput}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {categoryError?.categoryNameError}
                        </span>
                      </div>
                    ) : (
                      <div className="form-group">
                        <h6>Project Cost Profile</h6>
                        <div
                          className="CommonSelectBox"
                          style={{ width: "100%" }}
                        >
                          <select
                            name="categoryName"
                            value={categoryName}
                            onChange={handleInput}
                          >
                            {checkArray(masterCostCategoryListData?.data) &&
                              masterCostCategoryListData?.data?.map(
                                (ele, i) => {
                                  return (
                                    <>
                                      <option value={ele?.id}>
                                        {ele?.name}
                                      </option>
                                    </>
                                  );
                                }
                              )}
                          </select>
                          <span>
                            <img src="images/down.png" />
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <h6>
                        Cost Category Number <span className="required">*</span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Cost Category Number"
                          name="categoryNumber"
                          value={categoryNumber}
                          onChange={handleInputNumber}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {categoryError?.categoryNumberError}
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-3">
                  <button onClick={handleAddCategory} className="Create">
                    Create New Cost Category
                  </button>
                  <button onClick={hideAddCategoryModal} className="Outline">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateCategoryModal}
        onHide={hideUpdateCategoryModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideUpdateCategoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Update Cost Profile Category Details</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-md-6">
                  {/* <div className="form-group">
            <h6>Cost Profile Category Details</h6>
            <div className="CommonSelectBox" style={{ width: "100%" }}>
              <select>
                <option>Luxury Unit</option>
              </select>
              <span>
                <img src="images/down.png" />
              </span>
            </div>
          </div> */}
                  <div className="form-group">
                    <h6>Cost Category Description</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Cost Category Description"
                      name="categoryName"
                      value={categoryName}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="form-group">
                    <h6>Category #</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={21}
                      name="categoryNumber"
                      value={categoryNumber}
                      onChange={handleInput}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <h6>Cost Category Number</h6>
                    <p className="ml-0">{ProfileDetailsData?.number}</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <h6>Last Updates</h6>
                    <p className="ml-0">
                      {ProfileDetailsData?.updatedAt?.split("T")[0]}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <h6>Updated By</h6>
                    <p className="ml-0">{ProfileDetailsData?.updatedBy}</p>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-3">
                <button onClick={handleUpdateCategoryFun} className="Create">
                  Modify Cost Category
                </button>
                <button onClick={hideAddCategoryModal} className="Outline">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateProfileModal}
        onHide={hideUpdateProfileModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideUpdateProfileModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Project Profile Details</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>
                        Project Profile Description{" "}
                        <span className="required">*</span>{" "}
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Multi-Family Building"
                        name="name"
                        value={name}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <h6>Last Update</h6>
                      <p className="ml-0">
                        {ProfileDetailsData?.updatedAt?.split("T")[0]}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <h6>Updated By</h6>
                      <p className="ml-0">{ProfileDetailsData?.updatedBy}</p>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-3">
                  <button onClick={updateProfileFunc} className="Create">
                    Modify Project Profile
                  </button>
                  <button onClick={hideUpdateProfileModal} className="Outline">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addCostCodeModal}
        onHide={hideAddCostCodeModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddCostCodeModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Cost Code</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <h6>Project Cost Profile</h6>
                      <div
                        className="CommonSelectBox"
                        style={{ width: "100%" }}
                      >
                        <select disabled>
                          {checkArray(costProfielistData?.data) &&
                            costProfielistData?.data?.map((ele, i) => {
                              return (
                                <>
                                  <option selected={costId}>{ele?.name}</option>
                                </>
                              );
                            })}
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Cost Category Description</h6>
                      <div
                        className="CommonSelectBox"
                        style={{ width: "100%" }}
                      >
                        <select disabled>
                          {checkArray(categoryListData?.data) &&
                            categoryListData?.data?.map((ele, i) => {
                              return (
                                <>
                                  <option selected={categoryId === ele?.id}>
                                    {ele?.name}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    {/* <div className="form-group">
            <h6>Cost Category #</h6>
            <input type="text" className="form-control" placeholder="21.01" />
          </div> */}
                    <div className="form-group">
                      <h6>
                        Master #<span className="required">*</span>
                      </h6>
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={() =>
                            updateLeadState({
                              ...iLeadState,
                              defaultMasterDeceider: !defaultMasterDeceider,
                            })
                          }
                        />
                        <span className="slider" style={{ left: 0 }} />
                      </label>
                    </div>
                    {!defaultMasterDeceider ? (
                      <div className="form-group">
                        <h6>
                          Cost Code Description{" "}
                          <span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          name="costCodeName"
                          value={costCodeName}
                          onChange={handleInput}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {costCodeError?.costCOdeNameError}
                        </span>
                      </div>
                    ) : (
                      <div className="form-group">
                        <h6> Cost Code Description </h6>
                        <div
                          className="CommonSelectBox"
                          style={{ width: "100%" }}
                        >
                          <select
                            name="costCodeName"
                            value={costCodeName}
                            onChange={handleInput}
                          >
                            {checkArray(masterCostCodeListData?.data) &&
                              masterCostCodeListData?.data?.map((ele, i) => {
                                return (
                                  <>
                                    <option value={ele?.id}>{ele?.name}</option>
                                  </>
                                );
                              })}
                          </select>
                          <span>
                            <img src="images/down.png" />
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <h6>
                        Code # <span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Cost Category Number"
                        name="costCodeNumber"
                        value={costCodeNumber}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {costCodeError?.costCodeNumberError}
                      </span>
                    </div>
                    {/* <div className="form-group mb-5">
                      <h6>Cost Code Number</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Cost Category Number"
                        name="number"
                        value={number}
                        onChange={handleInput}
                      />
                    </div> */}
                    <div className="form-group">
                      <h6>
                        Classification <span className="required">*</span>
                      </h6>
                      <div
                        className="CommonSelectBox"
                        style={{ width: "100%" }}
                      >
                        <select
                          name="classification"
                          value={classification}
                          onChange={handleInput}
                        >
                          <option value="Land Acquistion">
                            Select Classification Type
                          </option>
                          <option value="Land Acquistion">
                            Land Acquistion
                          </option>
                          <option value="House">House</option>
                          <option value="Basement">Basement</option>
                          <option value="Parking/Detached Garage">
                            Parking/Detached Garage
                          </option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {costCodeError?.classificationError}
                        </span>
                      </div>
                    </div>
                    <div className="form-group ResidentialJobArea">
                      <h6>Apply Markup</h6>
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={() =>
                            updateLeadState({
                              ...iLeadState,
                              applyMarkup: !applyMarkup,
                            })
                          }
                        />
                        <span className="slider" style={{ left: 0 }} />
                      </label>
                    </div>
                    <div className="form-group">
                      <h6>User Estimation Message</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert User Estimation Message"
                        name="estimationMessage"
                        value={estimationMessage}
                        onChange={handleInput}
                      />
                      {/* <span style={{color:"red", fontSize:"14px"}}>{costCodeError?.estimationMessageError}</span> */}
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-3">
                  <button onClick={addCostCodeFunc} className="Create">
                    Create New Cost Code
                  </button>
                  <button onClick={hideAddCostCodeModal} className="Outline">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateCostModal}
        onHide={hideUpdateCostCodeModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideUpdateCostCodeModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Update Cost Code Details</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>Project Cost Profile</h6>
                    <div className="CommonSelectBox" style={{ width: "100%" }}>
                      <select disabled>
                        {checkArray(costProfielistData?.data) &&
                          costProfielistData?.data?.map((ele, i) => {
                            return (
                              <>
                                <option selected={costId}>{ele?.name}</option>
                              </>
                            );
                          })}
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>Cost Category Description</h6>
                    <select disabled>
                      {checkArray(categoryListData?.data) &&
                        categoryListData?.data?.map((ele, i) => {
                          return (
                            <>
                              <option selected={categoryId === ele?.id}>
                                {ele?.name}
                              </option>
                            </>
                          );
                        })}
                    </select>
                  </div>
                  <div className="form-group">
                    <h6>Cost Category #</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="21.01"
                      name=""
                    />
                  </div>
                  <div className="form-group">
                    <h6>Cost Code Description</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Flooring - Vinyl Plank"
                      name="costCodeName"
                      value={costCodeName}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="form-group">
                    <h6>Code #</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={10}
                    />
                  </div>
                  <div className="form-group">
                    <h6>Cost Code Number</h6>
                    <p className="ml-0">21.0110</p>
                  </div>
                  <div className="form-group">
                    <h6>
                      Classification <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox" style={{ width: "100%" }}>
                      <select
                        name="classification"
                        value={classification}
                        onChange={handleInput}
                      >
                        <option value="Land Acquistion">
                          Select Classification Type
                        </option>
                        <option value="Land Acquistion">Land Acquistion</option>
                        <option value="House">House</option>
                        <option value="Basement">Basement</option>
                        <option value="Parking/Detached Garage">
                          Parking/Detached Garage
                        </option>
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group ResidentialJobArea">
                    <h6>Apply Markup</h6>
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={() =>
                          updateLeadState({
                            ...iLeadState,
                            applyMarkup: !applyMarkup,
                          })
                        }
                      />
                      <span className="slider" style={{ left: 0 }} />
                    </label>
                  </div>
                  <div className="form-group">
                    <h6>User Estimation Message</h6>
                    <input
                      type="text"
                      className="form-control"
                      name="estimationMessage"
                      value={estimationMessage}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-md-3 ml-auto">
                  <div className="form-group">
                    <h6>Last Update</h6>
                    <p className="ml-0">
                      {ProfileDetailsData?.updatedAt?.split("T")[0]}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 ml-auto">
                  <div className="form-group">
                    <h6>Updated By</h6>
                    <p className="ml-0">{ProfileDetailsData?.updatedBy}</p>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-3">
                <button onClick={updateCostCOdeFun} className="Create">
                  Update Cost Category
                </button>
                <button onClick={hideUpdateCostCodeModal} className="Outline">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={costCodeDetailsModal}
        onHide={hideCostCOdeDetails}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideCostCOdeDetails}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Cost Code Details</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <h6>Project Cost Profile</h6>
                      <select disabled>
                        {checkArray(costProfielistData?.data) &&
                          costProfielistData?.data?.map((ele, i) => {
                            return (
                              <>
                                <option selected={costId}>{ele?.name}</option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group">
                      <h6>Cost Category Description</h6>
                      <select disabled>
                        {checkArray(categoryListData?.data) &&
                          categoryListData?.data?.map((ele, i) => {
                            return (
                              <>
                                <option selected={categoryId === ele?.id}>
                                  {ele?.name}
                                </option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group">
                      <h6>Cost Category #</h6>
                      <p className="ml-0">21.01</p>
                    </div>
                    <div className="form-group">
                      <h6>
                        Cost Code Description<span className="required">*</span>
                      </h6>
                      <p className="ml-0">{ProfileDetailsData?.name}</p>
                    </div>
                    {/* <div className="form-group">
            <h6>
              Code # <span className="required">*</span>{" "}
            </h6>
            <p className="ml-0">10</p>
          </div> */}
                    <div className="form-group">
                      <h6>Cost Code Number</h6>
                      <p className="ml-0">
                        {ProfileDetailsData?.CostCode?.number}
                      </p>
                    </div>
                    <div className="form-group">
                      <h6>
                        Classification <span className="required">*</span>
                      </h6>
                      <p className="ml-0">
                        {ProfileDetailsData?.classification}
                      </p>
                    </div>
                    <div className="form-group ResidentialJobArea">
                      <h6>Apply Markup</h6>
                      <p className="ml-0">
                        {ProfileDetailsData?.applyMarkup ? "Yes" : "No"}
                      </p>
                    </div>
                    <div className="form-group">
                      <h6>Estimation Message</h6>
                      <p>{ProfileDetailsData?.estimationMessage}</p>
                    </div>
                  </div>
                  <div className="col-md-3 ml-auto">
                    <div className="form-group">
                      <h6>Last Update</h6>
                      <p className="ml-0">
                        {ProfileDetailsData?.updatedAt?.split("T")[0]}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 ml-auto">
                    <div className="form-group">
                      <h6>Updated By</h6>
                      <p className="ml-0">{ProfileDetailsData?.updatedBy}</p>
                    </div>
                  </div>
                </div>
                <div className="Button text-center mt-5">
                  <button onClick={hideCostCOdeDetails} className="Outline">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        // show={costCodeDetailsModal}
        // onHide={hideCostCOdeDetails}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            // onClick={hideCostCOdeDetails}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Cost Profile Category Details</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Cost Profile Category Details</h6>
                      <p className="ml-0">Luxury Unit</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <h6>Last Updates</h6>
                      <p className="ml-0">08/01/2023</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <h6>Updated By</h6>
                      <p className="ml-0">John Doe</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Cost Category Description</h6>
                      <p className="ml-0">Wall Tile &amp; Floor Coverings</p>
                    </div>
                  </div>
                  <div className="col-md-3 mx-auto">
                    <div className="form-group">
                      <h6>Past Version</h6>
                      <p className="text-red">21.01 Wall Tile</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Category #</h6>
                      <p className="ml-0">21</p>
                    </div>
                    <div className="form-group">
                      <h6>Cost Category Number</h6>
                      <p className="ml-0">21.01</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="Button text-center mt-3">
              <button className="Outline" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Admin;
