import React, { useEffect, useState } from "react";
import "../App.css";
import Logo from "../assets/images/Logo.png";
import search from "../assets/images/search.png";
import switchHorizontal from "../assets/images/switch-horizontal.png";
import icon1 from "../assets/images/Icon-1.png";
// import Logo from "../assets/images/Logo.png";
import { ThemeContext } from "../ThemeContext";
import Theme from "../components/theme";
import { Modal } from "react-bootstrap";
import { update } from "lodash";
import { Checkbox } from "react-bootstrap";
import { useDispatch } from "react-redux";
import NoDataFound from "./Nodatafound";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import {
  headerNotifcaitionList,
  removeHeaderNotifcaitionList,
  updateChangePasswordLinkAction,
} from "../redux/action/authAction";
import { FirstLetters, checkArray } from "../utils/CheckType";
import { useSelector } from "react-redux";
import {
  getCitiesList,
  getCountriesList,
  getStateList,
  getTimeZoneList,
} from "../redux/action/authAction";
import {
  getAllJobListAction,
  getAllLandDevelopmentDetailsAction,
  getAllProjectDetailsAction,
  getAllProjectListAction,
  getMultiFamilyDetailsAction,
  getResidentialDetailsAction,
  updateLandDevelopmentAction,
  updateResidentialAction,
} from "../redux/action/jobAction";
import { notificationsAction } from "../redux/actionTypes";
import {
  AddDiscussionAction,
  AddReplyAction,
  discussionListAction,
} from "../redux/action/constructionsAction";
import UserProfile from "./headerModals/userProfile";
import ContactList from "./headerModals/contactList";
import Loader from "./Loader";
import AddContact from "./headerModals/addContact";
import ContactDetails from "./headerModals/contactDetails";
import {
  addHelpAndSupportAction,
  getHeaderHelpListAction,
  helpDetailsAction,
  helpListAction,
} from "../redux/action/systemAdministrationAction";
import { errorToast, sucessToast } from "..//utils/toast";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { ProfileAction } from "../redux/action/profileAction";
import { companyDetailsAction } from "../redux/action/companyAction";
import { UserDetailsAction } from "../redux/action/adminUserAction";
import JobDetailsModal from "./JobDetailsModal";
import EditProjectModal from "./DashboardModal/EditProjectModal";
const init = {
  email: "",
  emailModal: false,
  emailError: "",
  projectDetailsModal: false,
  projectDetailsData: [],
  ProjectNameShow: "",
  companyId: "",
  clientName: "",
  phoneNumber: "",
  availability: "",
  contactPrefrence: "",
  languagePrefrence: "",
  issueDetailsType: "",
  severity: "",
  module: "",
  description: "",
  problemStartDate: "",
  time: "",
  timeType: "",
  timeZone: "",
  errorMessage: "",
  applicationLink: "",
  helpAndSupportError: {},
  email: "",
  supportingDocFiles: [],
  falseImage: "",
  addSupportModal: false,
  historyListData: [],
  supportListingModal: false,
  supportDetailsModal: false,
  residentialDetailModal: false,
  multiFamilyJobDetailsModal: false,
  landDevelopmentJobDetailsModal: false,
  residentialJobDetailModal: false,
  updateResidentialJobDetailModal: false,
  jobDetailsData: [],
  projectListNameStatic: "",
  ProjectLogoShow: "",
  subdividision: "",
  singleFamilyResid: false,
  singleFamilyFoundationId: "",
  singleFamilyBasementFoundation: false,
  detacGarOrWorkOrMajStorShed: true,
  parkingPad: false,
  secondarySuite: false,
  secoSuiOverFounOptBelow: false,
  secoSuiOverFoundationId:"",
  secondarySuiteBasementFoundation: true,
  buildingPermit: "",
  specificationDocument: "",
  baseModel:"",
  taxProfile:"",
  homeType: "",
  defaultCurrency:"",
  timeZone: "",
  intJobWithAccSof:"",
  sqFtMainFloor: "",
  sqFtSecondFloor: "",
  sqFtThirdFloor: "",
  sqFtGarageFloor: "",
  sqFtBaseOrLowLevel:"",
  jobAddress: "",
  lot: "",
  city: "",
  zipCode: "",
  country: "",
  province: "",
  jobType: "",
  curStep: "",
  status:"",
  homeType: "",
  defaultCurrency:"",
  timeZone: "",
  intJobWithAccSof: true,
  sqFtMainFloor: "",
  sqFtSecondFloor: "",
  sqFtThirdFloor: "",
  sqFtGarageFloor: "",
  sqFtBaseOrLowLevel:"",
  jobAddress: "",
  lot: "",
  city: "",
  zipCode: "",
  country: "",
  province: "",
  jobType: "",
  curStep: "",
  status:"",
  block:"",
  phase:"",
  updateLandDevelopmentModal:false
};
const Header = ({ sendDataToParent }) => {
  const { profileData } = useSelector((state) => state.profileReducer);
  const { userData } = useSelector((state) => state.adminUserReducers);
  const { userList } = useSelector((state) => state.dropdownReducer);


  const [editProjectModal, setEditProjectModal] = useState(false)
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const [phonecode, setPhoneCode] = useState("");
  const [iState, updateState] = useState(init);
  const [widthState, setWidth] = useState(false);
  const [helpState, setHelpState] = useState(false);
  const [UserProfileModal, setUserProfile] = useState(false);

  const [ContactListModal, setContactList] = useState(false);

  const [ContactDetailsModal, SetContactDetails] = useState(false);

  const [AddContactModal, SetAddContact] = useState(false);
  const [NavShow, SetNavShow] = useState(false);

  const [NotificationState, setNotificationState] = useState(false);
  const {
    email,
    emailModal,
    emailError,
    projectDetailsModal,
    projectDetailsData,
    ProjectNameShow,
    companyId,
    clientName,
    phoneNumber,
    availability,
    contactPrefrence,
    languagePrefrence,
    issueDetailsType,
    severity,
    module,
    description,
    problemStartDate,
    time,
    timeType,
    timeZone,
    errorMessage,
    applicationLink,
    helpAndSupportError,
    addSupportModal,
    supportingDocFiles,
    falseImage,
    supportListingModal,
    supportDetailsModal,
    residentialDetailModal,
    residentialJobDetailModal,
    multiFamilyJobDetailsModal,
    landDevelopmentJobDetailsModal,
    jobDetailsData,
    projectListNameStatic,
    updateResidentialJobDetailModal,
    ProjectLogoShow,
    homeType,
    defaultCurrency,
    intJobWithAccSof,
    sqFtMainFloor,
    sqFtSecondFloor,
    sqFtThirdFloor,
    sqFtGarageFloor,
    sqFtBaseOrLowLevel,
    jobAddress,
    lot,
    city,
    zipCode,
    country,
    province,
    jobType,
    curStep,
    status,
    taxProfile,foundationType,buildingPermit,baseModel,specificationDocument,projectName,block,
    phase,updateLandDevelopmentModal
  } = iState;
  const dispatch = useDispatch();
  const {
    countryData,
    cityData,
    stateData,
    timeZoneData,
    headerNotificationListData,
  } = useSelector((state) => state.authReducer);

  const {
    helpListData,
    helpHistoryListData,
    loader,
    helpDetailsData,
    headerHelpListData,
  } = useSelector((state) => state.systemAdministrationReducer);

  const { discussionList } = useSelector((state) => state.constructionReducer);
  const { companyDetailData } = useSelector((state) => state.companyReducer);

  const selectedProject = JSON.parse(window.localStorage.getItem("tempData"));
  const User = JSON.parse(window.localStorage.getItem("user"));
 

  
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };
  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateState({ ...iState, [name]: result });
  };
  const handlePhoneInputChange = (value, country) => {
    // console.log("-------->", value, country);
    setPhoneCode(`+${country.dialCode}`);
  };
  const handleInputFile = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    if (file) {
      updateState({
        ...iState,
        [name]: file,
      });
      sucessToast("File uploaded successfully")
    }
    e.target.files = null;
  };
  let path = window.location.href.split("/");
  let pathLength = parseInt(path.length) - 1;
  let pathMatch = path[pathLength];
  // const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const { projectListData, jobListData, taxProfileListData, landDetailsData ,
    foundationListData,
    homeListData,
    residentialDetailsData,} =
    useSelector((state) => state.jobReducer);
 
  useEffect(() => {
    if (iBuildLocalData?.user?.CompanyId != null) {
      dispatch(getAllProjectListAction());
      dispatch(headerNotifcaitionList());
      dispatch(
        ProfileAction(
          iBuildLocalData?.user?.CompanyId,
          iBuildLocalData?.user?.id
        )
      );
    }

    if (selectedProject) {
      updateState({
        ...iState,
        ProjectNameShow: selectedProject?.projectName,
        ProjectLogoShow: selectedProject?.projectLogo,
      });
    }
  }, [dispatch, iBuildLocalData?.user?.CompanyId]);
  useEffect(() => {
    dispatch(getHeaderHelpListAction(pathMatch));
  }, [pathMatch]);
  const hideEmailPassModal = () => {
    updateState({ ...iState, emailModal: false });
  };

  useEffect(() => {
    if(iBuildLocalData?.user?.id){
      dispatch(getCountriesList());
      dispatch(companyDetailsAction(iBuildLocalData?.user?.CompanyId));
      // dispatch(UserDetailsAction(iBuildLocalData?.user?.id));
    }
  }, [dispatch,iBuildLocalData?.user?.id]);

  useEffect(() => {
    const fetchCountryStateCity = async () => {
        await dispatch(getCountriesList());
        const countryIsoCode = countryData?.countries?.find(
          (x) => x.name === country
        )?.isoCode;
        if (countryIsoCode) {
          await dispatch(getStateList(countryIsoCode));
          const stateIsoCode = stateData?.states?.find(
            (x) => x.name === province
          )?.isoCode;
          if (stateIsoCode) {
            await dispatch(getCitiesList(countryIsoCode, stateIsoCode));
          }
        }
      }

    fetchCountryStateCity();
  }, [country, province]);

  const showProjectDetailsModal = (id) => {
    // console.log("resssssssss", id)
    dispatch(getAllJobListAction(id));
    dispatch(getAllProjectDetailsAction(id)).then((res) => {
      updateState({
        ...iState,
        projectDetailsData: res?.projects,
        projectDetailsModal: true,
      });
    });
  };

  const hideProjectDetailsModal = () => {
    updateState({ ...iState, projectDetailsModal: false });
  };

  const showEmailPassModal = () => {
    updateState({ ...iState, emailModal: true });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const sendLinkToEmail = (e) => {
    e.preventDefault();
    if (!email) {
      updateState({ ...iState, emailError: "Email can't be empty" });
    } else if (email) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        updateState({ ...iState, emailError: "Please enter a valid email" });
      } else {
        dispatch(updateChangePasswordLinkAction({ email })).then((res) => {
          sucessToast("Please check your email for changing the password")
        });
      }
    }
  };

  const showSupportModal = () => {
    updateState({
      ...iState,
      addSupportModal: true,
    });
  };

  const hideSupportModal = () => {
    updateState({
      ...iState,
      addSupportModal: false,
    });
  };

  const showSupportListingModal = () => {
    updateState({
      ...iState,
      supportListingModal: true,
    });
  };

  const hideSupportListingModal = () => {
    updateState({
      ...iState,
      supportListingModal: false,
    });
  };

  const remvoeHeaderFunc = (id) => {
    dispatch(removeHeaderNotifcaitionList()).then((res) => {
      dispatch(headerNotifcaitionList());
    });
  };

  const saveProjectId = (ele) => {
    
    updateState({
      ...iState,
      ProjectNameShow: ele?.projectName,
      ProjectLogoShow: ele?.projectLogo,
    });
    window.localStorage.setItem(
      "tempData",
      JSON.stringify({
        ProjectNameShow: ele?.projectName,
        ProjectId:ele?.id,
        ProjectLogoShow: ele?.projectLogo,
      })
    );
    sendDataToParent(ele?.id);
  };

  const addSupportFunc = (e) => {
    e.preventDefault();
    let data = {
      companyId: iBuildLocalData?.user?.CompanyId,
      companyName: iBuildLocalData?.companies[0]?.name,
      clientName,
      phoneNumber,
      phonecode,
      availability,
      contactPrefrence,
      languagePrefrence,
      issueDetailsType,
      severity,
      module,
      description,
      problemStartDate,
      time,
      timeType,
      timeZone,
      errorMessage,
      applicationLink,
      supportingDocFiles,
      // helpAndSupportError
    };
    // console.log("addSupport", data);
    dispatch(addHelpAndSupportAction(data)).then((res) => {
      if (res.status === 400) {
        errorToast(res.data.message);
      } else {
        sucessToast("Support added successfully");
        hideSupportModal();
        dispatch(helpListAction());
      }
    });
  };

  const showAddSupportingDocsModal = () => {
    updateState({
      ...iState,
      addSupportingDocsModal: true,
    });
  };

  const hideAddSupportingDocsModal = () => {
    updateState({
      ...iState,
      addSupportingDocsModal: false,
    });
  };

  const hideSupportDetailsModal = (d) => {
    updateState({
      ...iState,
      supportDetailsModal: false,
    });
  };

  const showSupportDetailsModal = (id) => {
    dispatch(helpDetailsAction(id));
    updateState({
      ...iState,
      supportDetailsModal: true,
      modalId: id,
    });
  };

  const showSupportingListModal = () => {
    updateState({
      ...iState,
      supportingDocsListModal: true,
    });
  };

  // console.log("supportingDocsListModal", supportingDocFiles)
  const hideSupportingListModal = () => {
    updateState({
      ...iState,
      supportingDocsListModal: false,
    });
  };

  const [discussionState, updateDiscussionState] = useState({
    jobId:"",
    message: "",
    discussionError: "",
  });

  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction({
          message: discussionState.message,
          helpId: helpDetailsData[0]?.id,
          type: "Help",
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: helpDetailsData[0]?.id,
                type: "Help",
              })
            );

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          helpId: helpDetailsData[0]?.id,
          type: "Help",
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: helpDetailsData[0]?.id,
                type: "Help",
              })
            );
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([
    { path: "/todos", element: "Todos" },
    { path: "/itinerary", element: "Itinerary" },
    { path: "/archive", element: "Archive" },
    { path: "/file-manager", element: "File Manager" },
    { path: "/admin", element: "Admin" },
    { path: "/reports", element: "Reports" },
    { path: "/vendor-view-config", element: "Views Config" },
    {
      path: "/individual-components-part2-Vendor-List",
      element: "Vendor List",
    },
    { path: "/price-book", element: "Price Book" },
    { path: "/residential", element: "Residential" },
    { path: "/multi-family", element: "Multi Family" },
    { path: "/sales", element: "Sales Module" },
  ]);

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (query === "") {
      setSearchResults([
        { path: "/todos", element: "Todos" },
        { path: "/itinerary", element: "Itinerary" },
        { path: "/archive", element: "Archive" },
        { path: "/file-manager", element: "File Manager" },
        { path: "/admin", element: "Admin" },
        { path: "/reports", element: "Reports" },
        { path: "/vendor-view-config", element: "Views Config" },
        {
          path: "/individual-components-part2-Vendor-List",
          element: "Vendor List",
        },
        { path: "/price-book", element: "Price Book" },
        { path: "/residential", element: "Residential" },
        { path: "/multi-family", element: "Multi Family" },
        { path: "/sales", element: "Sales Module" },
      ]);
    } else {
      const filteredResults = searchResults.filter((result) =>
        result.element.toLowerCase().includes(query)
      );
      setSearchResults(filteredResults);
    }
  };

  const showResidentialDetailModal = (pid, id) => {
    dispatch(getResidentialDetailsAction(pid, id)).then((res) => {
      // console.log("details updated", res?.details);
      updateState({
        ...iState,
        jobDetailsData: res?.details,
        residentialDetailModal: true,
        projectDetailsModal: false,
      });
    });
  };

  const hideResidentialDetailModal = () => {
    updateState({
      ...iState,
      residentialJobDetailModal: false,
      projectDetailsModal: true,
    });
  };

  const showResidentialJobDetailModal = (pid, id) => {
    dispatch(getResidentialDetailsAction(pid, id)).then((res) => {
     
      updateState({
        ...iState,
        jobDetailsData: res?.details,
        residentialJobDetailModal: true,
        projectDetailsModal: false,
      });
    });
  };

  const hideResidentialJobDetailModal = () => {
    updateState({
      ...iState,
      residentialJobDetailModal: false,
      projectDetailsModal: true,
    });
  };


  const showResidentialUpdateDetailModal = (pid, id) => {
    // dispatch(getResidentialDetailsAction(pid, id)).then((res) => {
    //   console.log("details updated", res?.details);
      updateState({
        ...iState,
        // josbDetailsData: res?.details,
        updateResidentialJobDetailModal: true,
        residentialJobDetailModal: false,
      });
    // });
  };

  const hideResidentialUpdateJobDetailModal = () => {
    updateState({
      ...iState,
      updateResidentialJobDetailModal: false,
      residentialJobDetailModal: true,
    });
  };
  const showMultiFamilyJobDetailsModal = (pid, id) => {
    dispatch(getMultiFamilyDetailsAction(pid, id)).then((res) => {
      // console.log("details updated", res);
      updateState({
        ...iState,
        jobDetailsData: res?.data[0],
        multiFamilyJobDetailsModal: true,
        projectDetailsModal: false,
      });
    });
  };

  // console.log("jobDetailsData", jobDetailsData);

  const hideMultiFamilyJobDetailsModal = () => {
    updateState({
      ...iState,
      residentialDetailModal: false,
      projectDetailsModal: true,
    });
  };

  const showLandDevelopmentJobDetailsModal = (pid, id) => {
    dispatch(getAllLandDevelopmentDetailsAction(pid, id)).then((res) => {
      // console.log("details updated", res);
      updateState({
        ...iState,
        projectDetailsData: res?.projects,
        landDevelopmentJobDetailsModal: true,
        projectDetailsModal: false,
      });
    });
  };

  const hideLandDevelopmentJobDetailsModal = () => {
    updateState({
      ...iState,
      landDevelopmentJobDetailsModal: false,
      projectDetailsModal: false,
    });
  };


  const showLandDevelopmentJobUpdateModal = (pid, id) => {
    // dispatch(getAllLandDevelopmentDetailsAction(pid, id)).then((res) => {
      // console.log("details updated", res);
      updateState({
        ...iState,
      //  projectDetailsData: res?.projects,
        updateLandDevelopmentModal: true,
        landDevelopmentJobDetailsModal: false,
      });
    // });
  };

  const hideLandDevelopmentJobUpdateModal = () => {
    updateState({
      ...iState,
      landDevelopmentJobDetailsModal: true,
      updateLandDevelopmentModal: false,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".SearchPosition")) {
        setIsSearchFocused(false);
      }
    };

    if (isSearchFocused) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isSearchFocused]);

  const handleClick = (e) => {
    const cssLink = document.getElementById("thememode");
  
    if (e.target.checked) {
      cssLink.href = "css/darkmode.css";
    } else {
      cssLink.href = "css/lightmode.css";
    }
  };

  const [jobDetailModalShow,setJobDetailModalShow]=useState(false)


  const updatejobFunc = (e) =>{
    e.preventDefault();
    let data = {
      homeType,
      defaultCurrency,
      intJobWithAccSof,
      sqFtMainFloor,
      sqFtSecondFloor,
      sqFtThirdFloor,
      sqFtGarageFloor,
      sqFtBaseOrLowLevel,
      jobAddress,
      lot,
      city,
      zipCode,
      country,
      province,
      jobType,
      curStep,
      status,
      taxProfile,buildingPermit,baseModel,specificationDocument, block ,phase
    }
    dispatch(updateResidentialAction(jobDetailsData?.Project?.id, jobDetailsData?.id, data)).then((res) =>{
   
      hideResidentialUpdateJobDetailModal()
      showResidentialJobDetailModal(jobDetailsData?.Project?.id, jobDetailsData?.id)
      sucessToast("Residential job updated successfully")
    })

  }



  console.log(projectDetailsData, 'PROJECT DETAILS =================>')
  console.log(userData , "USER DATA ===============>")
  return (
    <>
      <header>
     
        <div className="Logo">
          <img src="images/Logo.png" />
        </div>

        <div className="Navigation">
          <div className="Search SearchPosition">
            <span>
              <img src="images/search.png" />
            </span>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
              onFocus={() => setIsSearchFocused(true)}
            />
            <div className="SearchOverlay">
              {isSearchFocused && (
                <ul>
                  {searchResults.map((result, index) => (
                    <li key={index}>
                      <Link to={result.path}>{result.element}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="ProjectName">
            <span className="Icon">
              <img src={companyDetailData?.logo} />
            </span>
            <span className="Name">{companyDetailData?.name}</span>
          </div>

          <div className="NavigationMenu">
            <div
              id="mySettings"
              className="sidenav"
              style={{ width: widthState ? 300 : 0 }}
            >
              <a className="editbtn" id="editbtn">
                <img src="images/edit2.png" />
              </a>
              <a className="closebtn" onClick={() => setWidth(false)}>
                ×
              </a>
              <div className="SideNavTopBox">
                <h1>Settings</h1>
              </div>
              <div className="SideNavBottom">
                <div className="SettingThemeArea">
                  <div className="SideTaskBoxTitle">
                    <h6>Theme</h6>
                  </div>
                  <div className="SideNavHelpBox">
                    <ul>
                      <ThemeContext.Consumer>
                        {({ changeTheme }) => (
                          <Theme
                            chooseTheme={() => {
                              changeTheme("yellow");
                            }}
                          />
                        )}
                      </ThemeContext.Consumer>
                      <ThemeContext.Consumer>
                        {({ changeTheme }) => (
                          <Theme
                            chooseTheme={() => {
                              changeTheme("cyan");
                            }}
                          />
                        )}
                      </ThemeContext.Consumer>
                      <ThemeContext.Consumer>
                        {({ changeTheme }) => (
                          <Theme
                            chooseTheme={() => {
                              changeTheme("lightgreen");
                            }}
                          />
                        )}
                      </ThemeContext.Consumer>
                      <ThemeContext.Consumer>
                        {({ changeTheme }) => (
                          <Theme
                            chooseTheme={() => {
                              changeTheme("pink");
                            }}
                          />
                        )}
                      </ThemeContext.Consumer>

                      <ThemeContext.Consumer>
                        {({ changeTheme }) => (
                          <Theme
                            chooseTheme={() => {
                              changeTheme("brown");
                            }}
                          />
                        )}
                      </ThemeContext.Consumer>
                    </ul>
                  </div>

                  <div className="form-group mt-3 d-flex align-items-center justify-content-between">
                    <label className="Dark-mode mb-0">Dark Mode</label>
                    <label className="switch">
                      <input type="checkbox" onChange={handleClick} />
                      <span className="slider" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="Dark-mode d-block">Password</label>
                    <label
                      onClick={() => showEmailPassModal()}
                      className="text-red"
                    >
                      Change Password
                    </label>
                  </div>
                </div>
                <div className="SettingsEditArea">
                  <div className="SettingsEdit">
                    <h6>Theme</h6>
                    <div className="settingsEditBox">
                      <h6>Add</h6>
                      <p>Remove</p>
                    </div>
                  </div>
                  <div className="SettingsEdit">
                    <h6>Language</h6>
                    <div className="settingsEditBox">
                      <h6>Add</h6>
                      <p>Remove</p>
                    </div>
                  </div>
                  <div className="SettingsEdit">
                    <h6>Dark Mode</h6>
                    <div className="settingsEditBox">
                      <h6>Add</h6>
                      <p>Remove</p>
                    </div>
                  </div>
                  <div className="SettingsEdit">
                    <h6>Password</h6>
                    <div className="settingsEditBox">
                      <h6>Add</h6>
                      <p>Remove</p>
                    </div>
                  </div>
                  <div className="text-center">
                    <button className="Save SaveBtn">Save</button>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="myNotification"
              className="sidenav"
              style={{ width: NotificationState ? 300 : 0 }}
            >
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={() => setNotificationState(false)}
              >
                ×
              </a>
              <div className="SideNavTopBox">
                <h1>Notification</h1>
              </div>
              <div className="SideNavBottom">
                <div className="SideTaskBoxTitle">
                  <h6>Today</h6>
                </div>

                {checkArray(headerNotificationListData) &&
                  headerNotificationListData?.map((ele, i) => {
                    return (
                      <>
                        <div className="SideNotificationBox">
                          <h6>Lorem Ipsum</h6>
                          <p>
                            There are many variations of passages Lorem Ipsum
                            available
                          </p>
                        </div>
                        <div className="text-right">
                          <button
                            onClick={() => remvoeHeaderFunc(ele?.id)}
                            className="DeleteBtn"
                          >
                            Delete
                          </button>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div className="NavigationProject">
              <div className="dropdown">
                <h6
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                                    <img className="GreyCircle" src={require("../assets/images/project_logo.png") } />

                  <span  /> {selectedProject?.ProjectNameShow || "All Projects"}{" "}
                </h6>
                <div
                  className="ProjectMenu dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <h6>Current</h6>
                  <div className="ProjectMenuTop">
                    <a href="javascript:void(0);">
                      {selectedProject?.ProjectNameShow || "All Projects"}{" "}
                    </a>{" "}
                    <img
                      src={
                        selectedProject?.ProjectNameShow
                        ??
                         <img src={require("../assets/images/icon-62.png")} />
                      }
                    />
                  </div>
                  <div className="SearchBar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) =>
                        dispatch(getAllProjectListAction(e.target.value))
                      }
                    />
                    <span>
                      <img src="images/search.png" />
                    </span>
                  </div>

                  {checkArray(projectListData) &&
                    projectListData?.map((ele, i) => {
                      return (
                        <>
                          <p
                            onClick={() => saveProjectId(ele)}
                            className="green"
                          >
                            {ele?.projectName}{" "}
                            {ele?.projectMode === "regular" ? " " : "Entity"}
                            <img
  style={{ height: "15px" }}
  onClick={() => showProjectDetailsModal(ele?.id)}
  src={ele?.projectLogo || require("../assets/images/icon-62.png")}
/>

                          </p>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
            <ul>
              <div id="myTask" className="sidenav">
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onclick="closeTask()"
                >
                  ×
                </a>
                <div className="SideNavTopBox">
                  <h1>My Task</h1>
                </div>
                <div className="SideNavBottom">
                  <div className="SideTaskBoxTitle">
                    <h6>Today</h6>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 1</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Start">Start</button>
                    </div>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 2</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Start">Start</button>
                    </div>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 3</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Completed">Completed</button>
                    </div>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 4</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Ongoing">Ongoing</button>
                    </div>
                  </div>
                </div>
              </div>
              <li>
                <a href="javascript:void(0);" className="SearchIcon">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </a>
              </li>
              <li onClick={() => setNotificationState(!NotificationState)}>
                <a href="javascript:void(0);">
                  <img src="images/Icon-2.png" />
                </a>
              </li>

              <li onClick={() => setHelpState(!helpState)}>
                <a href="javascript:void(0);">
                  <img src="images/Icon-3.png" />
                </a>
              </li>
              <div style={{ width: helpState ? 300 : 0 }} className="sidenav">
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onClick={() => setHelpState(false)}
                >
                  ×
                </a>
                <div className="SideNavTopBox flex-column">
                  <h1>Help</h1>
                  <a
                    href="javascript:void(0);"
                    onClick={showSupportListingModal}
                  >
                    Support Requests <span className="Count">3</span>
                  </a>
                  <a
                    href="javascript:void(0);"
                    onClick={showSupportModal}
                    className="CreateNewSupportRequest"
                  >
                    <img src="images/icon-90.png" />
                    &nbsp;&nbsp;Create New Support Request
                  </a>
                </div>
                <div className="SideNavBottom">
                  {checkArray(headerHelpListData?.notData) ? (
                    headerHelpListData?.notData?.map((ele, i) => {
                      console.log("eleleleleellee", ele);
                      return (
                        <>
                          <div className="SideNavSettingBox">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: ele?.helpMessage,
                              }}
                              className="editor"
                            ></div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <NoDataFound />
                    </>
                  )}
                </div>
              </div>
              <li onClick={() => setWidth(!widthState)}>
                <a href="javascript:void(0);">
                  <img src="images/Icon-4.png" />
                </a>
              </li>
              <div id="mySettings" className="sidenav">
                <a className="editbtn" id="editbtn">
                  <img src="images/edit2.png" />
                </a>
                <a className="closebtn" onclick="closeSettings()">
                  ×
                </a>
                <div className="SideNavTopBox">
                  <h1>Settings</h1>
                </div>
                <div className="SideNavBottom">
                  <div className="SettingThemeArea">
                    <div className="SideTaskBoxTitle">
                      <h6>Theme</h6>
                    </div>
                    <div className="SideNavHelpBox">
                      <ul>
                        <li>
                          <img src="images/theme-1.png" />
                        </li>
                        <li>
                          <img src="images/theme-2.png" />
                        </li>
                        <li>
                          <img src="images/theme-3.png" />
                        </li>
                        <li>
                          <img src="images/theme-4.png" />
                        </li>
                        <li>
                          <img src="images/theme-5.png" />
                        </li>
                        <li>
                          <img src="images/theme-6.png" />
                        </li>
                        <li>
                          <img src="images/theme-7.png" />
                        </li>
                        <li>
                          <img src="images/theme-8.png" />
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                      <div className="form-group">
                        <label className="Radio">
                          English
                          <input type="radio" name="Cuisine" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="Radio">
                          French
                          <input type="radio" name="Cuisine" />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="form-group mt-3 d-flex align-items-center justify-content-between">
                      <label className="Dark-mode mb-0">Dark Mode</label>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Dark-mode d-block">Password</label>
                      <label
                        onClick={() => showEmailPassModal()}
                        className="text-red"
                      >
                        Change Password
                      </label>
                    </div>
                  </div>
                  <div className="SettingsEditArea">
                    <div className="SettingsEdit">
                      <h6>Theme</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="SettingsEdit">
                      <h6>Language</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="SettingsEdit">
                      <h6>Dark Mode</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="SettingsEdit">
                      <h6>Password</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="text-center">
                      <button className="Save SaveBtn">Save</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <li onclick="openNav()">
          <a className="dropdown-toggle" id="navbarDropdown" role="button">
            <span>MB</span>
          </a>
        </li> */}
              <li onclick="openNav()">
                <a
                  class="dropdown-toggle"
                  id="navbarDropdown"
                  onClick={() => SetNavShow(true)}
                  role="button"
                >
                  <span>
                    {User ? (
                      <img src={User?.profile} />
                    ) : (
                      iBuildLocalData?.user?.name.charAt(0) +
                      iBuildLocalData?.user?.name.charAt(1)
                    )}{" "}
                  </span>
                </a>
              </li>
              <div
                id="mySidenav"
                class="sidenav"
                style={{ width: NavShow ? "300px" : "0px" }}
              >
                <a onClick={() => SetNavShow(false)} className="closebtn">
                  ×
                </a>
                <div class="sidenavTop">
                  <span>
                    {User ? (
                      <img src={User?.profile} />
                    ) : (
                      iBuildLocalData?.user?.name.charAt(0) +
                      iBuildLocalData?.user?.name.charAt(1)
                    )}{" "}
                  </span>
                  <h6>
                    {iBuildLocalData?.user?.fullName}{" "}
                    {iBuildLocalData?.user?.lastName}
                  </h6>
                  <h6>{iBuildLocalData?.user?.name}</h6>
                </div>
                <div class="d-flex flex-column justify-content-between">
                  <ul class="">
                    <li>
                      <a onClick={() => setUserProfile(true)}>
                        <img src="images/profile.png" />
                        Profile
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setContactList(true)}>
                        <img src="images/contacts.png" />
                        Contacts
                      </a>
                    </li>
                  </ul>
                  <ul class="bottomUl">
                    <li>
                      <a href="">
                        <img src="images/signout.png" />
                        Sign Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </header>

      <Modal
        show={emailModal}
        onHide={hideEmailPassModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideEmailPassModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="LoginBox Modal">
              <a href="javascript:void(0);" className="nav-link">
                IBuild Admin
              </a>
              <div className="LoginContentBox">
                <h3>Recover your account</h3>
                <p className="mb-4">
                  Enter email associated with your account and follow the
                  instructions
                </p>
                <form>
                  <div className="form-group">
                    <label>Enter Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter text"
                      name="email"
                      value={email}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {emailError}
                    </span>
                  </div>
                  <a
                    className="Button FolderPermissionId"
                    href="javascript:void(0);"
                    onClick={sendLinkToEmail}
                  >
                    Send Reset Email
                  </a>
                  <a
                    className="ButtonOutline mt-3"
                    href="javascript:void(0);"
                    onClick={hideEmailPassModal}
                  >
                    Cancel
                  </a>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <UserProfile
        UserProfileModal={UserProfileModal}
        setUserProfile={setUserProfile}
      />
      <ContactList
        ContactListModal={ContactListModal}
        setContactList={setContactList}
        SetAddContact={SetAddContact}
        SetContactDetails={SetContactDetails}
      />

      {AddContactModal && (
        <AddContact
          AddContactModal={AddContactModal}
          SetAddContact={SetAddContact}
        />
      )}

      <ContactDetails
        ContactDetailsModal={ContactDetailsModal}
        SetContactDetails={SetContactDetails}
      />

      <Modal
        show={projectDetailsModal}
        onHide={hideProjectDetailsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal closebtn ClosePermissionId"
            onClick={hideProjectDetailsModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox GrayModalTitle">
              <h4>Project Details</h4>
            </div>
            <div className="FolderForm">
              <div className="ResidentialJobArea ResidentialJobAreaPara">
                <div className="ResidentialJobHead">
                  <h3>{projectDetailsData?.jobNumber}</h3>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Created By</h6>
                        <p>{
                          
                          userList?.find(
                            (doc) => doc?.id == projectDetailsData?.createdBy
                          )?.name}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Creation Date</h6>
                        <p>{projectDetailsData?.createdAt?.split("T")[0]}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Last Update</h6>
                        <p>{projectDetailsData?.updatedAt?.split("T")[0]}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Type</h6>
                        <p>{projectDetailsData?.projectType}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ModalAccordian">
                  <div id="accordion">
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#GenerationInformation"
                      >
                        <h4>
                          <span>
                            <img src="images/generation_information.png" />
                          </span>
                          Generation Information
                        </h4>
                        <a
                          className="Edit"
                          onClick={()=>{
                            setJobDetailModalShow(true)
                            updateState({...iState,projectDetailsModal:false})
                          }}
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="GenerationInformation"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <form>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <h6>Name</h6>
                                  <p>{projectDetailsData?.projectName}</p>
                                </div>

                                <div className="form-group">
                                  <h6>Email</h6>
                                  <p>{projectDetailsData?.projectEmail}</p>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <h6>Logo</h6>
                                  <span className="FmBuilderLogo">
                                    <img
                                      src={projectDetailsData?.projectLogo}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#businessAddress"
                      >
                        <h4>
                          <span>
                            <img src="images/business_address.png" />
                          </span>{" "}
                          Business Address
                        </h4>
                        <a
                          className="Edit"
                          onClick={()=>{
                            setJobDetailModalShow(true)
                            updateState({...iState,projectDetailsModal:false})
                          }}
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="businessAddress"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Address</h6>
                                <p>{projectDetailsData?.projectAddress}</p>
                              </div>
                              <div className="form-group">
                                <h6>City</h6>
                                <p>{projectDetailsData?.city}</p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Zip/ Postal Code</h6>
                                <p>{projectDetailsData?.zipCode}</p>
                              </div>
                              <div className="form-group">
                                <h6>Country</h6>
                                <p>{projectDetailsData?.country}</p>
                              </div>
                              <div className="form-group">
                                <h6>State/Province</h6>
                                <p>{projectDetailsData?.province}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#Accounting"
                      >
                        <h4>
                          <span>
                            <img src="images/accounting.png" />
                          </span>
                          Accounting
                        </h4>
                        <a
                          className="Edit"
                          onClick={()=>{
                            setJobDetailModalShow(true)
                            updateState({...iState,projectDetailsModal:false})
                          }}
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="Accounting"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Subdivisions</h6>
                                {projectDetailsData?.subdivisions?.map(
                                  (ele, i) => {
                                    return (
                                      <>
                                        {" "}
                                        <p className="mb-3">{ele}</p> ,{" "}
                                      </>
                                    );
                                  }
                                )}
                              </div>
                              <div className="form-group">
                                <h6>Accounting Status</h6>
                                <h6 className="fw-500">
                                  {projectDetailsData?.integrateAccountingSoftware
                                    ? "Yes"
                                    : "No"}
                                </h6>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Developer</h6>
                                <p className="mb-2">
                                  {projectDetailsData?.developer}
                                </p>
                              </div>
                              <div className="form-group">
                                <h6>Tax Profile</h6>
                                <p className="mb-2">
                                  {projectDetailsData?.TaxProfile?.name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">

                          <div class="AccounitingCollapseArea">
                            <h6>Recommended Accounting Configuration Procedures Before Integration</h6>
                            <p>- You must first purchase the <span>QuickBooks Online Plus</span> version as the
                              <span>Plus</span> version allows class usage</p>
                            <p>- Second, ensure the <span>Chart of Accounts (COA)</span> has an <span>Accounts Payables
                                (A/P)</span> account already set-up,
                              if not, set one up.</p>
                            <p>- In your QuickBooks Online Plus version you must first Turn-On the <span>Purchase
                                Order</span> Option, the <span>Enable account numbers</span>
                              option, then the the <span>Class</span> Option as follows:</p>
                            <ul>

                              <li>--Click on the <span>Gear Icon in the Upper Right-Hand Corner of on your Home
                                  Dashboard screen.</span></li>
                              <li>--Click on <span>Account and Settings</span></li>
                              <li>--Click on the <span>Account and Settings</span> option. </li>

                              <li>--Click on the <span>Expenses</span> option. </li>
                              <li>--Click on the box in front of the <span>Use Purchase Orders</span> option.</li>
                              <li>--Click the green <span>Save</span> button.</li>
                              <li>--Click on the <span>Advanced</span> option.</li>
                              <li>--Click on the <span>Chart of Accounts</span> option.</li>
                              <li>--Click on the box in front of <span>Enable Account Numbers</span> </li>
                              <li>--Click the green <span>Save</span> button.</li>
                              <li>--Click the box in front of the <span>Track Classes</span> option.</li>
                              <li>--Click the green <span>Save</span> button</li>
                            </ul>
                            <p>-Return to this page and click on the Green <span>Connect to QuickBooks </span>button.
                            </p>
                          </div>


                          <div class="row mt-5">
                            <div class="col-md-4">
                              <div class="form-group">
                                <h6>Software</h6>
                                <img src="images/quickbooks.png" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <h6>Software</h6>
                                <h6 class="fw-500">Not Syncd</h6>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <h6>Fiscal Year End</h6>
                                <p>March</p>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#Tax">
                        <h4><span><img src="images/tax.png" /></span> Tax</h4>
                        <a class="Edit" href="javascript:void(0);" data-toggle="modal" data-target="#ProjectDetailsResidentialJobsEntityEdit" data-dismiss="modal">
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div id="Tax" class="collapse show" data-parent="#accordion">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <h6>Tax Registration</h6>
                                <p>713158681RT0001</p>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="AlbertaTaxMain">
                                <div class="AlbertaTax AlbertaTaxBtn">
                                  <h5>Alberta Taxs</h5>
                                  <img src="images/down_arrow_fill.png" />
                                </div>
                                <div class="AlbertaTaxHide">
                                  <div class="row">
                                    <div class="col-md-7">
                                      <div class="mb-2">
                                        <label class="CheckBox"> Default
                                          <input type="checkbox" />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                      <div class="mb-2">
                                        <label class="CheckBox"> Active
                                          <input type="checkbox" />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-md-5">
                                      <div class="form-group">
                                        <h6>Name</h6>
                                        <p>Alberta Tax</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="AlbertaTaxMain">
                                <div class="TaxRow">
                                  <h5>Tax Rows</h5>
                                </div>
                                <div class="TaxRowHide">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Tax Location</th>
                                        <th>Tax Name</th>
                                        <th>Tax Percent</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <p>Alberta (AB)</p>
                                        </td>
                                        <td>
                                          <p>GST</p>
                                        </td>
                                        <td>
                                          <p>5%</p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="AlbertaTaxMain">
                                <div class="Rebate">
                                  <h5>Rebates</h5>
                                </div>
                                <div class="TaxRowHide">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Applicable Row</th>
                                        <th>Sale Price Range</th>
                                        <th>Applied as</th>
                                        <th>Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <p>GST(AB 5%)</p>
                                        </td>
                                        <td>
                                          <div class="d-flex">
                                            <p>$0.00</p>
                                            <p class="ml-5">$350,000.00</p>
                                          </div>
                                        </td>
                                        <td>
                                          <p>Sliding Scale</p>
                                        </td>
                                        <td>
                                          <p>$6,300.00</p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="AlbertaTaxMain">
                                <div class="AlbertaTax AlbertaTaxBtn">
                                  <h5>Ontario Tax</h5>
                                  <img src="images/down_arrow_fill.png" />
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#JobNumbering"
                      >
                        <h4>
                          <span>
                            <img src="images/job_numbering.png" />
                          </span>{" "}
                          Job Numbering
                        </h4>
                        <a
                          className="Edit"
                          onClick={()=>{
                            setJobDetailModalShow(true)
                            updateState({...iState,projectDetailsModal:false})
                          }}
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="JobNumbering"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Job Number Pattern</h6>
                                <p>{projectDetailsData?.jobPattern}</p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Sample Job Number</h6>
                                <input
                                  type="text"
                                  className="form-control InputCyanBg"
                                  placeholder="MAH-2B-377"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#Jobs"
                      >
                        <h4>
                          <span>
                            <img src="images/jobs_collpase.png" />
                          </span>{" "}
                          Jobs
                        </h4>
                        <a
                          className="Edit"
                          onClick={()=>{
                            setJobDetailModalShow(true)
                            updateState({...iState,projectDetailsModal:false})
                          }}
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="Jobs"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="Tableheader">
                            <h6 className="mb-0">
                              {projectDetailsData?.projectType ===
                              "Single Family"
                                ? "Residential Jobs"
                                : projectDetailsData?.projectType ===
                                  "Multi Family"
                                ? "Multi Family Jobs"
                                : projectDetailsData?.projectType ===
                                  "Land Development"
                                ? "Land Development"
                                : ""}
                            </h6>
                            <div className="d-flex align-items-center">
                              <div className="NewActions">
                                <a href="jacascript:void(0);">
                                  <img src="images/icon-53.png" />
                                </a>
                                <a href="jacascript:void(0);">
                                  <img src="images/download.png" />
                                </a>
                                <a href="jacascript:void(0);">
                                  <img src="images/setting.png" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="TableList TableListHeader NewTableList TableHeightScroll">
                            <table>
                              <thead>
                                <tr>
                                  <th>Id</th>
                                  <th>Home Type</th>
                                  <th>Tax Profile</th>
                                  <th> Foundation</th>
                                  <th>Address</th>
                                  <th>Creation Date</th>
                                  <th>Created By</th>
                                  <th>TimeZone</th>
                                </tr>
                              </thead>
                              <tbody>
                                {checkArray(jobListData?.jobs) ? (
                                  jobListData?.jobs?.map((ele, i) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            <div
                                              onClick={
                                                ele?.jobType === "Single Family"
                                                  ? () =>
                                                      showResidentialJobDetailModal(
                                                        ele?.ProjectId,
                                                        ele?.id
                                                      )
                                                  : ele?.jobType ===
                                                    "Multi Family"
                                                  ? () =>
                                                      showMultiFamilyJobDetailsModal(
                                                        ele?.ProjectId,
                                                        ele?.id
                                                      )
                                                  : ele?.jobType ===
                                                    "LandDevelopmentJob"
                                                  ? () =>
                                                      showLandDevelopmentJobDetailsModal(
                                                        ele?.ProjectId,
                                                        ele?.id
                                                      )
                                                  : () =>
                                                    showResidentialJobDetailModal(
                                                        ele?.ProjectId,
                                                        ele?.id
                                                      )
                                              }
                                              className="TextBlue"
                                            >
                                              {ele?.jobNumber}
                                            </div>
                                          </td>
                                          <td></td>
                                          <td>{ele?.TaxProfile?.name}</td>
                                          <td>Basement</td>
                                          <td>
                                            {ele?.jobAddress} , {ele?.city},
                                            {ele?.country}
                                          </td>
                                          <td>
                                            {" "}
                                            {ele?.createdAt?.split("T")[0]}{" "}
                                          </td>
                                          <td>John Doe</td>
                                          <td>
                                            {ele?.timeZone?.split("T")[0]}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })
                                ) : (
                                  <NoDataFound />
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="PaginationArea">
                            <h6>Showing 3 of 3 entries</h6>
                            <h6>
                              <img src="images/leftarrow.png" /> 01{" "}
                              <img src="images/rightarrow.png" /> Out 10
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="CloseButton">
                    <button className="Close" data-dismiss="modal">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addSupportModal}
        onHide={hideSupportModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Support Request</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading mb-4">
                <h3 className="mb-1">
                  <span>Contact Details</span>
                </h3>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>
                      Company <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <input  className="form-control" value={iBuildLocalData?.companies[0]?.name}/>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Client Name <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                    <input  className="form-control" value={iBuildLocalData?.user?.name}/>
                    </div>
                  </div>

                  <div className="form-group">
                    <h6>Country Code</h6>
                    <PhoneInput
                      inputClass="CountryCodeInput"
                      countryCodeEditable={false}
                      enableSearch
                      value={phonecode}
                      onChange={handlePhoneInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>
                      Telephone <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      value={iBuildLocalData?.user?.phoneNumber}
                    />
                  </div>
                </div>
                <div className="col-md-5 ml-auto">
                  <div className="form-group">
                    <h6>
                      Availability <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="availability"
                        value={availability}
                        onChange={handleInput}
                      >
                        <option selected="">Select Availability</option>
                        <option value="Morning Hours (08 AM - 12 PM)">Morning Hours (08 AM - 12 PM) </option>
                        <option value="Afternoon Hours (01 PM - 06 PM)">Afternoon Hours (01 PM - 06 PM) </option>
                        <option value="Afternoon Hours (01 PM - 06 PM)">Afternoon Hours (01 PM - 06 PM) </option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Contact Preference <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="contactPrefrence"
                        value={contactPrefrence}
                        onChange={handleInput}
                      >
                        <option selected="">Select Preference</option>
                        <option value = "Telephone">Telephone</option>
<option value = "Email">Email</option>
<option value = "Discussion Message">Discussion Message</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Email <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      value={iBuildLocalData?.user?.email}
                      
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <h6>
                      Language Preference <span className="required">*</span>
                    </h6>
                    <div className="d-flex justify-content-between">
                      <label className="Radio text-black ">
                        {" "}
                        English
                        <input
                          type="radio"
                          name="languagePrefrence"
                          value={"English"}
                          onChange={handleRadioChange}
                        />
                        <span className="checkmark" />
                      </label>
                      <label className="Radio text-black ">
                        {" "}
                        French
                        <input
                          type="radio"
                          name="languagePrefrence"
                          value={"French"}
                          onChange={handleRadioChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ModalBigheading mb-4">
                <h3 className="mb-1">
                  <span>Issue Details</span>
                </h3>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>
                      Type <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="issueDetailsType"
                        value={issueDetailsType}
                        onChange={handleInput}
                      >
                        <option selected="">Select Type</option>
                        <option value="Question">Question</option>
                        <option value="Answer">Answer</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Module <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="module"
                        value={module}
                        onChange={handleInput}
                      >
                        <option selected="">Select Module</option>
                        <option value = "System Task ">System Task </option>
<option value = "Notification ">Notification </option>
<option value = "ToDo">ToDo </option>
<option value = "Itinerary">Itinerary</option>
<option value = "Estimation">Estimation</option>
<option value = "Sales">Sales</option>
<option value = "Change Order">Change Order</option>
<option value = "CTC">CTC</option>
<option value = "Design Selections">Design Selections</option>
<option value = "Accounting">Accounting</option>
<option value = "Reports">Reports</option>
<option value = "Client View">Client View</option>
<option value = "Trade View">Trade View</option>
<option value = "Investor View">Investor View</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 ml-auto">
                  <div className="form-group">
                    <h6>
                      Severity <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="severity"
                        value={severity}
                        onChange={handleInput}
                      >
                        <option selected="">Select Severity</option>
                        <option value = "Major Impact">Major Impact</option>
                        <option value = "Significant Impact">Significant Impact</option>
                        <option value = "Minor impact">Minor impact </option>
                        <option value = "No impact">No impact</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <h6>
                      Description <span className="required">*</span>
                    </h6>
                    <textarea
                      className="form-control"
                      placeholder="Insert Description"
                      rows={3}
                      name="description"
                      value={description}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="form-group col-12 mb-0">
                  <h6>
                    Problem Start Date <span className="required">*</span>
                  </h6>
                </div>
                <div className="form-group col-md-4">
                  <input
                    type="date"
                    className="form-control"
                    name="problemStartDate"
                    value={problemStartDate}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group col-md-4">
                  <input
                    type="time"
                    className="form-control"
                    placeholder="hh:mm"
                    name="time"
                    value={time}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group col-md-4">
                  <div className="CommonSelectBox CommonSelectBoxWidth">
                    <select
                      name="timeType"
                      value={timeType}
                      onChange={handleInput}
                    >
                      <option selected="">Select</option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-md-5">
                  <div className="CommonSelectBox CommonSelectBoxWidth mb-3">
                    <select
                      name="timeZone"
                      value={timeZone}
                      onChange={handleInput}
                    >
                      <option>Select Timezone</option>
                      {checkArray(timeZoneData?.data) ? (
                        <>
                          {timeZoneData?.data?.map((ele, i) => {
                            return (
                              <option value={ele?.zoneName}>
                                {ele?.zoneName}
                              </option>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                  </div>
                  <label className="CheckBox">
                    {" "}
                    Not sure, use current time
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group col-md-12">
                  <h6>Error Message</h6>
                  <textarea
                    className="form-control"
                    placeholder="Insert Error Message if applicable, or more details."
                    rows={3}
                    name="errorMessage"
                    value={errorMessage}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group col-md-6">
                  <h6>Url link of the issue page</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert link where the error is occuring"
                    name="applicationLink"
                    value={applicationLink}
                    onChange={handleInput}
                  />
                </div>
                <div className="ModalBigheading col-md-12">
                  <h3>
                    <span>Supporting Documents</span>
                  </h3>
                </div>
                <div className="form-group col-md-6">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      name="supportingDocFiles"
                      onChange={(e) => handleInputFile(e)}
                    />
                  </div>
                  {/* <img   src={supportingDocFiles ? URL.createObjectURL(supportingDocFiles) :""}/> */}
                </div>
              </div>
              <div className="row"></div>

              <div className="Button d-flex justify-content-between">
                <button
                  onClick={addSupportFunc}
                  className="Create ClosePermissionId"
                >
                  Create Support Request
                </button>
                <button className="Outline ClosePermissionId">Cancel</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={supportListingModal}
        onHide={hideSupportListingModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportListingModal}
          >
            ×
          </a>

          <a href="javascript:void(0);" onClick={hideSupportListingModal}>
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View Support Request</h4>
            </div>
            <div className="Tableheader">
              <div className="d-flex align-items-center">
                <h6>Support Requests</h6>
              </div>
              <div className="d-flex">
                <div className="TodosButtons mt-0 d-flex">
                  <a href="javascript:void(0)" onClick={showSupportModal}>
                    <img src="images/icon-90.png" />
                    Create New Support Request
                  </a>
                </div>
                <div className="NewActions">
                  <a href="javascript:void(0);" title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);" title="download">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);" title="settings">
                    <img src="images/setting.png" />
                  </a>
                  <a href="javascript:void(0);" title="filter">
                    <img src="images/icon-64.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Client</th>
                    <th>Company</th>
                    <th>Type</th>
                    <th>Module</th>
                    <th>Description</th>
                    <th>Problem Start Date</th>
                    <th>Severity</th>
                    <th>Error Message</th>
                    <th>Url link of the issue page</th>
                    <th>Status</th>
                    <th>Creation Date</th>
                    <th>Last Update</th>
                    <th>Solution</th>
                    <th>Root Cause</th>
                    <th>Bug ID</th>
                    <th>Messages</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(helpListData?.notData) ? (
                        helpListData?.notData?.map((ele, ind) => {
                          return (
                            <>
                              <tr className="PendingConfirmation">
                                <td>{ele?.id}</td>
                                <td>{ele?.clientName}</td>
                                <td>{ele?.companyId}</td>
                                <td>{ele?.issueDetailsType}</td>
                                <td>{ele?.module}</td>
                                <td>{ele?.description}</td>
                                <td>
                                  {ele?.problemStartDate}, {ele?.time}
                                </td>
                                <td>{ele?.severity}</td>
                                <td>{ele?.errorMessage}</td>
                                <td>{ele?.applicationLink}</td>

                                <td>{ele?.requestStatus}</td>

                                <td>
                                  <div className="text-black">
                                    {ele?.createdAt?.split("T")[0]}
                                  </div>
                                </td>
                                <td>
                                  <div className="text-black">
                                    {ele?.updatedAt?.split("T")[0]}
                                  </div>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td>
                                  <div className="Actions">
                                    <a href="javascript:void(0);">
                                      <img src="images/chat_icon.png" />
                                    </a>
                                    <a href="javascript:void(0);">
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <div className="TableThreeDots text-center">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol
                                          className="dropdown-menu dropdown-menuwidth2"
                                          style={{}}
                                        >
                                          <li>
                                            <a
                                              onClick={() =>
                                                showSupportDetailsModal(ele?.id)
                                              }
                                            >
                                              <img src="images/view-po.png" />
                                              View Support Request
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={supportDetailsModal}
        onHide={hideSupportDetailsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportDetailsModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Support Request Details</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="form-group col-md-3">
                    <h6>ID</h6>
                    <p className="ml-0">{helpDetailsData[0]?.id}</p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Creation Date</h6>
                    <p className="ml-0">
                      {helpDetailsData[0]?.createdAt?.split("T")[0]}
                    </p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Last Update</h6>
                    <p className="ml-0">
                      {helpDetailsData[0]?.updatedAt?.split("T")[0]}
                    </p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Status</h6>
                    <p className="ml-0">{helpDetailsData[0]?.status}</p>
                  </div>
                </div>
                <div className="ModalBigheading mb-4">
                  <h3 className="mb-1">
                    <span>Contact Details</span>
                  </h3>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <h6>Company</h6>
                      <p className="ml-0">{helpDetailsData[0]?.id}</p>
                    </div>
                    <div className="form-group">
                      <h6>Client Name</h6>
                      <p className="ml-0">{helpDetailsData[0]?.clientName}</p>
                    </div>
                    <div className="form-group">
                      <h6>Telephone</h6>
                      <p className="ml-0">{helpDetailsData[0]?.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group">
                      <h6>Availability</h6>
                      <p className="ml-0">{helpDetailsData[0]?.availability}</p>
                    </div>
                    <div className="form-group">
                      <h6>Contact Preference</h6>
                      <p className="ml-0">
                        {helpDetailsData[0]?.contactPrefrence}
                      </p>
                    </div>
                    <div className="form-group">
                      <h6>Email</h6>
                      <p className="ml-0">{helpDetailsData[0]?.email}</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h6>Language Preference </h6>
                    <p className="ml-0">
                      {helpDetailsData[0]?.languagePrefrence}
                    </p>
                  </div>
                </div>
                <div className="ModalBigheading mt-4 mb-4">
                  <h3 className="mb-1">
                    <span>Issue Details</span>
                  </h3>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Type</h6>
                      <p className="ml-0">
                        {helpDetailsData[0]?.languagePrefrence}
                      </p>
                    </div>
                    <div className="form-group">
                      <h6>Module</h6>
                      <p className="ml-0">{helpDetailsData[0]?.module}</p>
                    </div>
                    <div className="form-group">
                      <h6>Description</h6>
                      <p className="ml-0">{helpDetailsData[0]?.description}</p>
                    </div>
                    <div className="form-group">
                      <h6>Problem Start Date</h6>
                      <div className="d-flex mb-4">
                        <p className="ml-0">
                          {helpDetailsData[0]?.problemStartDate?.split("T")[0]}
                        </p>
                        {/* <p>09:00 AM</p> */}
                      </div>
                      {/* <p className="ml-0">{helpDetailsData[0]?.problemStartDate?.split("T")[0]}</p> */}
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group">
                      <h6>Severity</h6>
                      <p className="ml-0">{helpDetailsData[0]?.severity}</p>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <h6>Error Message</h6>
                    <p className="ml-0">{helpDetailsData?.errorMessage}</p>
                  </div>
                  <div className="form-group col-md-12">
                    <h6>Url link of the issue page</h6>
                    <p className="ml-0"> {helpDetailsData?.applicationLink}</p>
                  </div>
                  {/* <div className="col-md-12">
          <div className="ModalBigheading">
            <h3>
              <span>Supporting Documents</span>
            </h3>
          </div>
          <div className="form-group">
            <h6>Files:</h6>
            <div className="FilesAdded">
              <label>error1_.jpeg</label>
              <span>
                <img src="images/view_jobs_details.png" />
              </span>
            </div>
            <div className="FilesAdded">
              <label>error1_.jpeg</label>
              <span>
                <img src="images/view_jobs_details.png" />
              </span>
            </div>
          </div>
          <div className="ModalBigheading">
            <h3>
              <span>Support Details</span>
            </h3>
          </div>
        </div> */}
                </div>
                <div className="row">
                  <div className="form-group col-6">
                    <h6>Assign To </h6>
                    <h6 className="fw-400 fw-14">Sysadmin 1</h6>
                  </div>
                  <div className="form-group col-12">
                    <h6>Solution</h6>
                  </div>
                  <div className="form-group col-md-6">
                    <h6>Root Cause</h6>
                    <p className="ml-0">Bug</p>
                  </div>
                  <div className="form-group col-md-6">
                    <h6>Bug Id</h6>
                    <p className="ml-0">Bug 36</p>
                  </div>
                </div>
                <div className="Button text-center">
                  <button className="Outline">Close</button>
                </div>
              </form>
            </div>
            <div class="DiscussionArea">
              <div class="DiscussionTitle">
                <h4>Discussion</h4>
                <div class="DiscussionChat">
                  <img
                    src={require("../assets/images/down.png")}
                    class="DiscussionIcon"
                  />
                  <a href="#">
                    <img src={require("../assets/images/pdf2.png")} />
                  </a>
                </div>
              </div>
              <div class="DiscussionHideArea" style={{ display: "block" }}>
                <div class="DiscussionBox">
                  <span>FM</span>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      value={discussionState?.message}
                      onChange={(e) =>
                        updateDiscussionState({
                          ...discussionState,
                          message: e.target.value,
                        })
                      }
                      placeholder="Add a Comment ....."
                    />
                    {discussionState.discussionError &&
                      !discussionState.message && (
                        <p style={{ color: "red" }}>
                          {discussionState.discussionError}
                        </p>
                      )}

                    <div class="DiscussionOverlay">
                      <a
                        onClick={() =>
                          updateDiscussionState({
                            ...discussionState,
                            message: "",
                          })
                        }
                        class="CancelChat"
                      >
                        <img
                          src={require("../assets/images/plus-cancel1.png")}
                        />
                      </a>
                      <a onClick={handleAddDiscussion} class="SendChat">
                        <img
                          src={require("../assets/images/forwardbutton.png")}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                {discussionList?.map((item, i) => (
                  <>
                    <div class="DiscussionBox">
                      <span>CO</span>
                      <div class="DiscussionRight">
                        <h6>{item?.message}</h6>
                        <small>{moment(item.updatedAt).format("lll")}</small>
                      </div>
                    </div>
                    <div class="ReplyArea">
                      <a
                        onClick={(e) => {
                          updateReplyField({ flag: true, index: i });
                          updateReplyState({ ...replyState, message: "" });
                        }}
                      >
                        Reply
                      </a>
                      {item?.ReplyDiscussions?.map((ele) => (
                        <div class="DiscussionBox">
                          <span>RE</span>
                          <div class="DiscussionRight">
                            <h6>{ele?.message}</h6>
                            <small>{moment(ele.updatedAt).format("lll")}</small>
                          </div>
                        </div>
                      ))}

                      {replyField.index == i && (
                        <div class="DiscussionBox w-100">
                          <span>FM{item?.id}</span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={replyState?.message}
                              onChange={(e) =>
                                updateReplyState({
                                  ...replyState,
                                  message: e.target.value,
                                  messageId: item?.id,
                                  jobId: item?.jobId,
                                })
                              }
                              placeholder="Add reply ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddReply} class="SendChat">
                                <img
                                  src={require("../assets/images/forwardbutton.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={residentialDetailModal}
        onHide={hideResidentialDetailModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideResidentialDetailModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox GrayModalTitle">
              <h4> Residential Project Details</h4>
            </div>
            <div className="FolderForm">
              <div className="ResidentialJobArea ResidentialJobAreaPara">
                <div className="ResidentialJobHead">
                  <h3>
                    {jobDetailsData?.jobPattern}
                    <span className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src="images/dots.png" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          <img src="images/cancecl_message.png" />
                          Cancel Project
                        </a>
                      </div>
                    </span>
                  </h3>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Created By</h6>
                        <p>John Doe</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Creation Date</h6>
                        <p> {jobDetailsData?.createdAt?.split("T")[0]}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Last Update</h6>
                        <p> {jobDetailsData?.updatedAt?.split("T")[0]}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Type</h6>
                        <p> {jobDetailsData?.jobType}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ModalAccordian">
                  <div id="accordion">
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#GenerationInformation"
                      >
                        <h4>
                          <span>
                            <img src="images/generation_information.png" />
                          </span>
                          Generation Information
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ResidentialEdit"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="GenerationInformation"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <h6>Name</h6>
                                <p> {jobDetailsData?.Project?.projectName}</p>
                              </div>
                              <div className="form-group">
                                <h6>Company</h6>
                                <p> {jobDetailsData?.Project?.projectName}</p>
                              </div>
                              <div className="form-group">
                                <h6>Email</h6>
                                <p> {jobDetailsData?.Project?.projectEmail}</p>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <h6>Logo</h6>
                                <span className="FmBuilderLogo">
                                  <img src="images/fm_builder_logo.png" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#businessAddress"
                      >
                        <h4>
                          <span>
                            <img src="images/business_address.png" />
                          </span>{" "}
                          Business Address
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ResidentialEdit"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="businessAddress"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Address</h6>
                                <p>
                                  {" "}
                                  {jobDetailsData?.Project?.projectAddress}
                                </p>
                              </div>
                              <div className="form-group">
                                <h6>City</h6>
                                <p> {jobDetailsData?.Project?.city}</p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Zip/ Postal Code</h6>
                                <p> {jobDetailsData?.Project?.zipCode}</p>
                              </div>
                              <div className="form-group">
                                <h6>Country</h6>
                                <p> {jobDetailsData?.Project?.country}</p>
                              </div>
                              <div className="form-group">
                                <h6>State/Province</h6>
                                <p> {jobDetailsData?.Project?.province}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#Accounting"
                      >
                        <h4>
                          <span>
                            <img src="images/accounting.png" />
                          </span>
                          Accounting and Tax
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ResidentialEdit"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="Accounting"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Subdivisions</h6>
                                <p> {jobDetailsData?.Project?.subdividision}</p>
                              </div>
                              <div className="form-group">
                                <h6>Accounting Status</h6>
                                <h6 className="fw-500">
                                  <p>
                                    {" "}
                                    {jobDetailsData?.Project
                                      ?.integrateAccountingSoftware
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </h6>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Developer</h6>
                                <p className="mb-2">
                                  <p> {jobDetailsData?.Project?.developer}</p>
                                </p>
                              </div>
                              <div className="form-group">
                                <h6>Tax Profile</h6>
                                <p className="mb-2">
                                  {" "}
                                  {jobDetailsData?.Project?.TaxProfile?.name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#JobNumbering"
                      >
                        <h4>
                          <span>
                            <img src="images/job_numbering.png" />
                          </span>{" "}
                          Job Numbering
                        </h4>
                      </div>
                      <div
                        id="JobNumbering"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Job Number Pattern</h6>
                                <p className="mb-2">
                                  {" "}
                                  {jobDetailsData?.Project?.jobPattern}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Sample Job Number</h6>
                                <input
                                  type="text"
                                  className="form-control InputCyanBg"
                                  placeholder="MAH-2B-377"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="card">
            <div className="card-header" data-toggle="collapse" href="#Jobs">
              <h4>
                <span>
                  <img src="images/jobs_collpase.png" />
                </span>{" "}
                Jobs
              </h4>
            </div>
            <div id="Jobs" className="collapse show" data-parent="#accordion">
              <div className="card-body">
                <div className="Tableheader">
                  <h6 className="mb-0">Residential Jobs</h6>
                  <div className="d-flex align-items-center">
                    <div className="TodosButtons mt-0 d-flex">
                      <a href="javascript:void(0)" className="">
                        <img src="images/create_new_job.png" />
                        Create New Job
                      </a>
                    </div>
                    <div className="NewActions">
                      <a href="jacascript:void(0);">
                        <img src="images/icon-53.png" />
                      </a>
                      <a href="jacascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                      <a href="jacascript:void(0);">
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="TableList TableListHeader NewTableList TableHeightScroll">
                  <table>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Home Type</th>
                        <th>Tax Profile</th>
                        <th> Foundation</th>
                        <th>Address</th>
                        <th>Creation Date</th>
                        <th>Created By</th>
                        <th>TimeZone</th>
                        <th>Accounting Status</th>
                        <th>Add-Ons</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="TextBlue">HIL-2B-376</div>
                        </td>
                        <td>2-Storey</td>
                        <td>Alberta Tax</td>
                        <td>Basement</td>
                        <td>
                          312 Weddenburn Rd SE Calgary, Alberta, T2J 1J1, Canada
                        </td>
                        <td>05/24/2023 </td>
                        <td>John Doe</td>
                        <td>Moutain Time GMT - 06</td>
                        <td>Integrated with Accouting Software</td>
                        <td>20</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="TextBlue">HIL-2B-376</div>
                        </td>
                        <td>2-Storey</td>
                        <td>Alberta Tax</td>
                        <td>Basement</td>
                        <td>
                          312 Weddenburn Rd SE Calgary, Alberta, T2J 1J1, Canada
                        </td>
                        <td>05/24/2023 </td>
                        <td>John Doe</td>
                        <td>Moutain Time GMT - 06</td>
                        <td>Integrated with Accouting Software</td>
                        <td>20</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="TextBlue">HIL-2B-376</div>
                        </td>
                        <td>2-Storey</td>
                        <td>Alberta Tax</td>
                        <td>Basement</td>
                        <td>
                          312 Weddenburn Rd SE Calgary, Alberta, T2J 1J1, Canada
                        </td>
                        <td>05/24/2023 </td>
                        <td>John Doe</td>
                        <td>Moutain Time GMT - 06</td>
                        <td>Integrated with Accouting Software</td>
                        <td>20</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="TextBlue">HIL-2B-376</div>
                        </td>
                        <td>2-Storey</td>
                        <td>Alberta Tax</td>
                        <td>Basement</td>
                        <td>
                          312 Weddenburn Rd SE Calgary, Alberta, T2J 1J1, Canada
                        </td>
                        <td>05/24/2023 </td>
                        <td>John Doe</td>
                        <td>Moutain Time GMT - 06</td>
                        <td>Integrated with Accouting Software</td>
                        <td>20</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="TextBlue">HIL-2B-376</div>
                        </td>
                        <td>2-Storey</td>
                        <td>Alberta Tax</td>
                        <td>Basement</td>
                        <td>
                          312 Weddenburn Rd SE Calgary, Alberta, T2J 1J1, Canada
                        </td>
                        <td>05/24/2023 </td>
                        <td>John Doe</td>
                        <td>Moutain Time GMT - 06</td>
                        <td>Integrated with Accouting Software</td>
                        <td>20</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="PaginationArea">
                  <h6>Showing 3 of 3 entries</h6>
                  <h6>
                    <img src="images/leftarrow.png" /> 01{" "}
                    <img src="images/rightarrow.png" /> Out 10
                  </h6>
                </div>
              </div>
            </div>
          </div> */}
                  </div>
                  <div className="CloseButton">
                    <button
                      className="Close"
                      onClick={hideResidentialDetailModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={multiFamilyJobDetailsModal}
        onHide={hideMultiFamilyJobDetailsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideMultiFamilyJobDetailsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox GrayModalTitle">
              <h4>Multi-Family Job Details</h4>
            </div>
            <div className="FolderForm">
              <div className="ResidentialJobArea ResidentialJobAreaPara">
                <div className="ResidentialJobHead">
                  <h3>
                    {jobDetailsData?.jobNumber}(Administration)
                    <span className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src="images/dots.png" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a className="dropdown-item" href="javascript:void(0);">
                          <img src="images/archive_job.png" />
                          Archive Job
                        </a>
                      </div>
                    </span>
                  </h3>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Created By</h6>
                        <p> {jobDetailsData?.createdBy}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Creation Date</h6>
                        {jobDetailsData?.createdAt?.split("T")[0]}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Last Update</h6>
                        {jobDetailsData?.updatedAt?.split("T")[0]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ModalAccordian">
                  <div id="accordion">
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#GeneralDetails"
                      >
                        <h4>
                          <span>
                            <img src="images/general_details.png" />
                          </span>
                          General Details
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#MultiFamilyJobDetails7"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="GeneralDetails"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="col-sm-5">
                            <div className="form-group">
                              <h6>Project</h6>
                              {jobDetailsData?.Project?.projectName}
                            </div>
                            <div className="form-group">
                              <h6>Home Type</h6>
                              {jobDetailsData?.HomeType?.name}
                            </div>
                            <div className="form-group">
                              <h6>Shared Unit Cost Scheme</h6>
                              {jobDetailsData?.updatedAt?.split("T")[0]}
                            </div>
                            <div className="form-group">
                              <h6>Subdivision</h6>
                              {jobDetailsData?.Project?.subdivison?.map(
                                (ele) => (
                                  <p>{ele}</p>
                                )
                              )}
                            </div>
                            <div className="form-group">
                              <h6>Building Permit #</h6>
                              <p>{jobDetailsData?.buildingPermit}</p>
                            </div>
                            <div className="form-group">
                              <h6>Accounting Status</h6>
                              <h6 className="fw-400">
                                {jobDetailsData?.Project
                                  ?.integrateAccountingSoftware
                                  ? "Yes"
                                  : "No"}
                              </h6>
                            </div>
                          </div>
                          <div className="col-sm-5 ml-auto">
                            <div className="form-group">
                              <h6>Specification Document</h6>
                              <p>{jobDetailsData?.specificationDoc}</p>
                            </div>
                            <div className="form-group">
                              <h6>Base Model</h6>
                              <p>{jobDetailsData?.baseModel}</p>
                            </div>
                            <div className="form-group">
                              <h6>Tax Profile</h6>
                              <p>{jobDetailsData?.TaxProfile?.name}</p>
                            </div>
                            <div className="form-group">
                              <h6>Default Currency</h6>
                              <p>{jobDetailsData?.defaultCurrency}</p>
                            </div>
                            <div className="form-group">
                              <h6>Job Site GMT (UTC) Timezone</h6>
                              <p>{jobDetailsData?.timeZone?.split("T")[0]}</p>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="form-group">
                              <h6>Parking</h6>
                              <div className="ParkingBoxArea">
                                <div className="ParkingBox">
                                  <h6>Job Id</h6>
                                  <p>{jobDetailsData?.jobNumber}</p>
                                </div>
                                <div className="ParkingBox">
                                  <h6>Type</h6>
                                  <p>Underground Parkade (Covered/ Heated)</p>
                                </div>
                                {/* <div className="ParkingBox">
                          <h6>Action</h6>
                          <p>
                            <img src="images/Action-1.png" />
                          </p>
                        </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#BlocksUnits"
                      >
                        <h4>
                          <span>
                            <img src="images/blocks_units.png" />
                          </span>
                          Blocks &amp; Units
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#MultiFamilyJobDetails7"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="BlocksUnits"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <form>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="form-group">
                                  <h6>Total Blocks</h6>
                                  <p>{jobDetailsData?.numOfBlocks}</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <h6>Total Units</h6>
                                  <p>{jobDetailsData?.numOfBuildings}</p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="TodosButtons mt-0 text-center">
                                  <a
                                    href="javascript:void(0)"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#CreateMultiFamilyBlocks"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/create_new_block.png" />
                                    Create New Block
                                  </a>
                                </div>
                              </div>
                            </div>
                          </form>
                          <div className="Tableheader">
                            <h6 className="mb-0">Blocks and Units</h6>
                            <div className="Actions">
                              <a href="javascript:void(0);">
                                <img src="images/icon-53.png" />
                              </a>
                              <a href="javascript:void(0);">
                                <img src="images/download.png" />
                              </a>
                              <a href="javascript:void(0);">
                                <img src="images/setting.png" />
                              </a>
                            </div>
                          </div>
                          <div className="BlockUnitsTable CommonHeading">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Block Description</th>
                                  <th>Foundation Type</th>
                                  <th># of Units Per Block</th>
                                  <th># Stories Per Block</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody className="addClasss">
                                <tr className="BorderBottomTable">
                                  <td className="hidtableshowChangeOrder">
                                    <div className="Action">
                                      Block A
                                      <img
                                        src="images/icon-34.png"
                                        className="ml-2"
                                      />
                                    </div>
                                  </td>
                                  <td className="hidtableshowChangeOrder">
                                    Basement Foundation
                                  </td>
                                  <td className="hidtableshowChangeOrder">3</td>
                                  <td className="hidtableshowChangeOrder">2</td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/Action-1.png" />
                                                Edit Job Details
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#CreateMultiFamilyUnits"
                                                data-dismiss="modal"
                                              >
                                                <img src="images/create_units.png" />
                                                Create Units
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={5} className="padding_none">
                                    <div className="JobBoxTable JobBoxTableBorder JobBoxTableHeight">
                                      <table>
                                        <thead>
                                          <tr>
                                            <th />
                                            <th>Block's Units #</th>
                                            <th>Unit's Sq/Ft</th>
                                            <th># of Bedrooms</th>
                                            <th># of Bathrooms</th>
                                            <th>Basement Development</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Unit 1</td>
                                            <td>101</td>
                                            <td>1482</td>
                                            <td>3</td>
                                            <td>2.5</td>
                                            <td>
                                              <div className="text-center">
                                                <label className="CheckBox">
                                                  <input type="checkbox" />
                                                  <span className="checkmark" />
                                                </label>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="TableThreeDots">
                                                <ul className="">
                                                  <li className="dropdown">
                                                    <a
                                                      className=""
                                                      role="button"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      <img src="images/dots.png" />
                                                    </a>
                                                    <ol className="dropdown-menu dropdown-menuwidth">
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/Action-1.png" />
                                                          Edit Job Details
                                                        </a>
                                                      </li>
                                                    </ol>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Unit 1</td>
                                            <td>101</td>
                                            <td>1482</td>
                                            <td>3</td>
                                            <td>2.5</td>
                                            <td>
                                              <div className="text-center">
                                                <label className="CheckBox">
                                                  <input type="checkbox" />
                                                  <span className="checkmark" />
                                                </label>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="TableThreeDots">
                                                <ul className="">
                                                  <li className="dropdown">
                                                    <a
                                                      className=""
                                                      role="button"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      <img src="images/dots.png" />
                                                    </a>
                                                    <ol className="dropdown-menu dropdown-menuwidth">
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/Action-1.png" />
                                                          Edit Job Details
                                                        </a>
                                                      </li>
                                                    </ol>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Unit 1</td>
                                            <td>101</td>
                                            <td>1482</td>
                                            <td>3</td>
                                            <td>2.5</td>
                                            <td>
                                              <div className="text-center">
                                                <label className="CheckBox">
                                                  <input type="checkbox" />
                                                  <span className="checkmark" />
                                                </label>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="TableThreeDots">
                                                <ul className="">
                                                  <li className="dropdown">
                                                    <a
                                                      className=""
                                                      role="button"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      <img src="images/dots.png" />
                                                    </a>
                                                    <ol className="dropdown-menu dropdown-menuwidth">
                                                      <li>
                                                        <a href="javascript:void(0);">
                                                          <img src="images/Action-1.png" />
                                                          Edit Job Details
                                                        </a>
                                                      </li>
                                                    </ol>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr className="BorderBottomTable">
                                  <td>
                                    <div className="Action">
                                      Block B
                                      <img
                                        src="images/icon-34.png"
                                        className="ml-2"
                                      />
                                    </div>
                                  </td>
                                  <td>Basement Foundation</td>
                                  <td>3</td>
                                  <td>2</td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            <li>
                                              <a href="Warranty_Work_Order1.html">
                                                <img src="images/Action-1.png" />
                                                Edit Job Details
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#CreateMultiFamilyUnits"
                                                data-dismiss="modal"
                                              >
                                                <img src="images/create_units.png" />
                                                Create Units
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr className="BorderBottomTable">
                                  <td>
                                    <div className="Action">
                                      Block C
                                      <img
                                        src="images/icon-34.png"
                                        className="ml-2"
                                      />
                                    </div>
                                  </td>
                                  <td>Slab-On-Grade-Foundation - No Piles</td>
                                  <td>3</td>
                                  <td>2</td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            <li>
                                              <a href="Warranty_Work_Order1.html">
                                                <img src="images/Action-1.png" />
                                                Edit Job Details
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#CreateMultiFamilyUnits"
                                                data-dismiss="modal"
                                              >
                                                <img src="images/create_units.png" />
                                                Create Units
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr className="BorderBottomTable">
                                  <td>
                                    <div className="Action">
                                      Block D
                                      <img
                                        src="images/icon-34.png"
                                        className="ml-2"
                                      />
                                    </div>
                                  </td>
                                  <td>Basement Foundation</td>
                                  <td>3</td>
                                  <td>2</td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            <li>
                                              <a href="Warranty_Work_Order1.html">
                                                <img src="images/Action-1.png" />
                                                Edit Job Details
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#CreateMultiFamilyUnits"
                                                data-dismiss="modal"
                                              >
                                                <img src="images/create_units.png" />
                                                Create Units
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="PaginationArea">
                            <h6>Showing 3 of 3 entries</h6>
                            <h6>
                              <img src="images/leftarrow.png" /> 01{" "}
                              <img src="images/rightarrow.png" /> Out 10
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#CommonBuilding"
                      >
                        <h4>
                          <span>
                            <img src="images/jobs_collpase.png" />
                          </span>
                          Common Building
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#MultiFamilyJobDetails7"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="CommonBuilding"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <form>
                            <div className="row">
                              <div className="col-md-5">
                                <div className="form-group">
                                  <h6>Total Commom Buildings</h6>
                                  <p>3</p>
                                </div>
                              </div>
                              <div className="col-md-5 ml-auto">
                                <div className="TodosButtons mt-0">
                                  <a
                                    href="javascript:void(0)"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#CreateMultiFamilyCommonBuilding"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/cmb_white.png" />
                                    Create Common Building
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="Tableheader">
                              <h6 className="mb-0">Common Building</h6>
                              <div className="Actions">
                                <a href="javascript:void(0);">
                                  <img src="images/icon-53.png" />
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/download.png" />
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/setting.png" />
                                </a>
                              </div>
                            </div>
                            <div className="TableList TableListHeader NewTableList TableHeightScroll">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Job Id</th>
                                    <th>Commom Building Name</th>
                                    <th>Foundation Type</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {checkArray(jobDetailsData?.MultiBuildings) &&
                                    jobDetailsData?.MultiBuildings?.map(
                                      (ele, i) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{ele?.jobNumber}</td>
                                              <td>{ele?.buildingName}</td>
                                              <td>{ele?.foundationType}</td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#BusinessAddress"
                      >
                        <h4>
                          <span>
                            <img src="images/business_address.png" />
                          </span>
                          Business Address
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#MultiFamilyJobDetails7"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="BusinessAddress"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-5">
                              <div className="form-group">
                                <h6>Job Address</h6>
                                <p>{jobDetailsData?.jobAddress}</p>
                              </div>
                              <div className="form-group">
                                <h6>Lot</h6>
                                <p>{jobDetailsData?.lot}</p>
                              </div>
                              <div className="form-group">
                                <h6>Block</h6>
                                <p>{jobDetailsData?.block}</p>
                              </div>
                              <div className="form-group">
                                <h6>Phase</h6>

                                <p>{jobDetailsData?.phase}</p>
                              </div>
                            </div>
                            <div className="col-sm-5 ml-auto">
                              <div className="form-group">
                                <h6>City</h6>
                                <p>{jobDetailsData?.city}</p>
                              </div>
                              <div className="form-group">
                                <h6>Zip/ Postal Code</h6>
                                <p>{jobDetailsData?.zipCode}</p>
                              </div>
                              <div className="form-group">
                                <h6>Country</h6>
                                <p>{jobDetailsData?.country}</p>
                              </div>
                              <div className="form-group">
                                <h6>State/Province</h6>
                                <p>{jobDetailsData?.province}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="CloseButton">
                    <button
                      className="Close ClosePermissionId"
                      onClick={hideMultiFamilyJobDetailsModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={landDevelopmentJobDetailsModal}
        onHide={hideLandDevelopmentJobDetailsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideLandDevelopmentJobDetailsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox GrayModalTitle">
              <h4>Project Details</h4>
            </div>
            <div className="FolderForm">
              <div className="ResidentialJobArea ResidentialJobAreaPara">
                <div className="ResidentialJobHead">
                  <h3>
                    Proj-4E-SIN-1
                    <span className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src="images/dots.png" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          <img src="images/cancecl_message.png" />
                          Cancel Project
                        </a>
                      </div>
                    </span>
                  </h3>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Created By</h6>
                        <p>John Doe</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Creation Date</h6>
                        <p>04/15/2023</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Last Update</h6>
                        <p>07/10/2023</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Type</h6>
                        <p>Single Family</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ModalAccordian">
                  <div id="accordion">
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#GenerationInformation"
                      >
                        <h4>
                          <span>
                            <img src="images/generation_information.png" />
                          </span>
                          Generation Information
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ResidentialEdit"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="GenerationInformation"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <form>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <h6>Name</h6>
                                  <p>Company 1</p>
                                </div>
                                <div className="form-group">
                                  <h6>Company</h6>
                                  <p>Company 1</p>
                                </div>
                                <div className="form-group">
                                  <h6>Email</h6>
                                  <p>contact@company1.com</p>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <h6>Logo</h6>
                                  <span className="FmBuilderLogo">
                                    <img src="images/fm_builder_logo.png" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#businessAddress"
                      >
                        <h4>
                          <span>
                            <img src="images/business_address.png" />
                          </span>{" "}
                          Business Address
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ResidentialEdit"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="businessAddress"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Address</h6>
                                <p>Street 1</p>
                              </div>
                              <div className="form-group">
                                <h6>City</h6>
                                <p>Calgary</p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Zip/ Postal Code</h6>
                                <p>T3A T3A</p>
                              </div>
                              <div className="form-group">
                                <h6>Country</h6>
                                <p>Canada</p>
                              </div>
                              <div className="form-group">
                                <h6>State/Province</h6>
                                <p>AB</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#Accounting"
                      >
                        <h4>
                          <span>
                            <img src="images/accounting.png" />
                          </span>
                          Accounting and Tax
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ResidentialEdit"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="Accounting"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Subdivisions</h6>
                                <p className="mb-3">Delta</p>
                                <p>Beta</p>
                              </div>
                              <div className="form-group">
                                <h6>Accounting Status</h6>
                                <h6 className="fw-500">
                                  Integrate with Accounting Software
                                </h6>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Developer</h6>
                                <p className="mb-2">Developer 1</p>
                              </div>
                              <div className="form-group">
                                <h6>Tax Profile</h6>
                                <p className="mb-2">Tax Profile</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#JobNumbering"
                      >
                        <h4>
                          <span>
                            <img src="images/job_numbering.png" />
                          </span>{" "}
                          Job Numbering
                        </h4>
                      </div>
                      <div
                        id="JobNumbering"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Job Number Pattern</h6>
                                <p>[UNUM]-[SD3]-[CID]-[JN]</p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Sample Job Number</h6>
                                <input
                                  type="text"
                                  className="form-control InputCyanBg"
                                  placeholder="MAH-2B-377"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#CostProfiles"
                      >
                        <h4>
                          <span>
                            <img src="images/cost_profile_collapse.png" />
                          </span>{" "}
                          Cost Profiles
                        </h4>
                      </div>
                      <div
                        id="CostProfiles"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Enabled Categories</h6>
                              <h6 className="fw-400">Land Costs</h6>
                              <h6 className="fw-400">
                                Architects &amp; Consultants Fees
                              </h6>
                              <h6 className="fw-400">Engineers Fees</h6>
                              <h6 className="fw-400">Permit Fees</h6>
                              <h6 className="fw-400">
                                Site Land Development Servicing Costs
                              </h6>
                              <h6 className="fw-400">
                                Site Preparation &amp; Tem Office Costs
                              </h6>
                              <h6 className="fw-400">
                                Site Landscaping, Retaining Walls, RPR's
                              </h6>
                              <h6 className="fw-400">
                                New Home Warranty &amp; Insurance Fees
                              </h6>
                              <h6 className="fw-400">
                                Condominium Plan Corporation Costs
                              </h6>
                              <h6 className="fw-400">
                                Marketing &amp; Sales Costs
                              </h6>
                              <h6 className="fw-400">
                                Management, Legal, Financing Fees
                              </h6>
                            </div>
                            <div className="col-md-6">
                              <h6>Include Land Development Shared Costs</h6>
                              <div className="form-group">
                                <h6>Job</h6>
                                <p>CHI-2B-382</p>
                              </div>
                              <div className="form-group">
                                <h6>Land Development Cost Categories</h6>
                                <p className="mb-2">
                                  Site Land Development Servicing Costs
                                </p>
                                <p>Site Preparation &amp; Tem Office Costs</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#Jobs"
                      >
                        <h4>
                          <span>
                            <img src="images/jobs_collpase.png" />
                          </span>{" "}
                          Jobs
                        </h4>
                      </div>
                      <div
                        id="Jobs"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="Tableheader">
                            <h6 className="mb-0">Land Developments</h6>
                            <div className="d-flex align-items-center">
                              <div className="TodosButtons mt-0 d-flex">
                                <a href="javascript:void(0)" className="">
                                  <img src="images/create_new_job.png" />
                                  Create New Job
                                </a>
                              </div>
                              <div className="NewActions">
                                <a href="jacascript:void(0);">
                                  <img src="images/icon-53.png" />
                                </a>
                                <a href="jacascript:void(0);">
                                  <img src="images/download.png" />
                                </a>
                                <a href="jacascript:void(0);">
                                  <img src="images/setting.png" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="TableList TableListHeader NewTableList TableHeightScroll">
                            <table>
                              <thead>
                                <tr>
                                  <th>Id</th>
                                  <th>Tax Profile</th>
                                  <th>Land Tracts/ Phases</th>
                                  <th>Total Lots</th>
                                  <th>Address</th>
                                  <th>Creation Date</th>
                                  <th>Created By</th>
                                  <th>TimeZone</th>
                                  <th>Accounting Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="TextBlue">HIL-2B-376</div>
                                  </td>
                                  <td>Alberta Tax</td>
                                  <td>5</td>
                                  <td>50</td>
                                  <td>
                                    312 Weddenburn Rd SE Calgary, Alberta, T2J
                                    1J1, Canada
                                  </td>
                                  <td>05/24/2023 </td>
                                  <td>John Doe</td>
                                  <td>Moutain Time GMT - 06</td>
                                  <td>Integrated with Accouting Software</td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="TextBlue">HIL-2B-376</div>
                                  </td>
                                  <td>Alberta Tax</td>
                                  <td>5</td>
                                  <td>50</td>
                                  <td>
                                    312 Weddenburn Rd SE Calgary, Alberta, T2J
                                    1J1, Canada
                                  </td>
                                  <td>05/24/2023 </td>
                                  <td>John Doe</td>
                                  <td>Moutain Time GMT - 06</td>
                                  <td>Integrated with Accouting Software</td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="TextBlue">HIL-2B-376</div>
                                  </td>
                                  <td>Alberta Tax</td>
                                  <td>5</td>
                                  <td>50</td>
                                  <td>
                                    312 Weddenburn Rd SE Calgary, Alberta, T2J
                                    1J1, Canada
                                  </td>
                                  <td>05/24/2023 </td>
                                  <td>John Doe</td>
                                  <td>Moutain Time GMT - 06</td>
                                  <td>Integrated with Accouting Software</td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="TextBlue">HIL-2B-376</div>
                                  </td>
                                  <td>Alberta Tax</td>
                                  <td>5</td>
                                  <td>50</td>
                                  <td>
                                    312 Weddenburn Rd SE Calgary, Alberta, T2J
                                    1J1, Canada
                                  </td>
                                  <td>05/24/2023 </td>
                                  <td>John Doe</td>
                                  <td>Moutain Time GMT - 06</td>
                                  <td>Integrated with Accouting Software</td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="TextBlue">HIL-2B-376</div>
                                  </td>
                                  <td>Alberta Tax</td>
                                  <td>5</td>
                                  <td>50</td>
                                  <td>
                                    312 Weddenburn Rd SE Calgary, Alberta, T2J
                                    1J1, Canada
                                  </td>
                                  <td>05/24/2023 </td>
                                  <td>John Doe</td>
                                  <td>Moutain Time GMT - 06</td>
                                  <td>Integrated with Accouting Software</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="PaginationArea">
                            <h6>Showing 3 of 3 entries</h6>
                            <h6>
                              <img src="images/leftarrow.png" /> 01{" "}
                              <img src="images/rightarrow.png" /> Out 10
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="CloseButton">
                    <button className="Close" data-dismiss="modal">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <JobDetailsModal
        jobDetailModalShow={jobDetailModalShow}
        setJobDetailModalShow={setJobDetailModalShow}
        updateState={updateState}
        projectDetailsData={projectDetailsData}
      />




<Modal
        show={residentialJobDetailModal}
        onHide={hideResidentialJobDetailModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideResidentialJobDetailModal}
          >
            ×
          </a>
          <div className="ResidentialJobArea">
        <div className="ModalTitleBox GrayModalTitle">
          <h4>Residential Job Details</h4>
        </div>
        <div className="ResidentialJobHead">
          <h3>
          {jobDetailsData?.jobNumber} (Residential Development)
            <span className="dropdown">
              <a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="images/dots.png" />
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#"><img src="images/Archive.png" /> Archive Job</a>
              </div>
            </span>
          </h3>
          <article>
            <aside>
              <h4>Created By</h4>
              <p>{jobDetailsData?.createdBy}</p>
            </aside>
            <aside>
              <h4>Creation Date</h4>
              <p>  {jobDetailsData?.createdAt?.split("T")[0]} </p>
            </aside>
            <aside>
              <h4>Last Update</h4>
              <p>  {jobDetailsData?.updatedAt?.split("T")[0]} </p>
            </aside>
          </article>
        </div>
        <div className="ModalAccordian">
          <div id="accordion">
            <div className="card">
              <div className="card-header collapsed" data-toggle="collapse" href="#collapseOne" aria-expanded="false">
                <h4><span><img src="images/generation_information.png" /></span> Project &amp; Foundation</h4>
                <a className="Edit" href="javascript:void(0);" onClick={showResidentialUpdateDetailModal}>
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div id="collapseOne" className="collapse" data-parent="#accordion" style={{}}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Project</h6>
                          <p>{jobDetailsData?.Project?.projectName}</p>
                        </div>
                        <div className="Residential">
                          <h6>Foundation</h6>
                          <p>{jobDetailsData?.Project?.projectType}</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-sm-12">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Add-Ons</h6>
                          <div className="AddonTable">
                            <div className="Addon">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Job Id</th>
                                    <th>Job Id</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>HIL-2B-377</td>
                                    <td>Detached Garage or Workshop or Major Storage Shed</td>
                                    <td>
                                      <a href="javascript:void(0);">
                                        <img src="images/Action-1.png" />
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>HIL-2B-378</td>
                                    <td>Secondary Suite - Over Detached G arage</td>
                                    <td>
                                      <a href="javascript:void(0);">
                                        <img src="images/Action-1.png" />
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" data-toggle="collapse" href="#collapseTwo">
                <h4><span><img src="images/Residential-2.png" /></span> Details</h4>
                <a className="Edit" href="javascript:void(0);"onClick={showResidentialUpdateDetailModal}>
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div id="collapseTwo" className="collapse show" data-parent="#accordion">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Home Type</h6>
                        <p>{jobDetailsData?.HomeType?.name}</p>
                        </div>
                        <div className="Residential">
                          <h6>Specification Document</h6>
                          <p>{jobDetailsData?.specificationDocument}</p>
                        </div>
                        <div className="Residential">
                          <h6>Tax Profile</h6>
                          <p>{jobDetailsData?.TaxProfile?.name}</p>
                        </div>
                        <div className="Residential">
                          <h6>Job Site GMT (UTC) Timezone</h6>
                          <p>{jobDetailsData?.timeZone}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Building Permit #</h6>
                          <p>{jobDetailsData?.buildingPermit}</p>
                        </div>
                        <div className="Residential">
                          <h6>Base Model</h6>
                          <p>{jobDetailsData?.baseModel}</p>
                        </div>
                        <div className="Residential">
                          <h6>Default Currency</h6>
                          <p>{jobDetailsData?.defaultCurrency}</p>
                        </div>
                        <div className="Residential">
                          <h6>Accounting Status</h6>
                          <h6 className="fw-400 mb-4">{jobDetailsData?.status}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false">
                <h4><span><img src="images/Residential-3.png" /></span> Square Foot</h4>
                <a className="Edit" onClick={showResidentialUpdateDetailModal}>
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div id="collapseThree" className="collapse" data-parent="#accordion" style={{}}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>SqFt Main Floor</h6>
                          <p>{jobDetailsData?.sqFtMainFloor}</p>
                        </div>
                        <div className="Residential">
                          <h6>SqFt Third Floor</h6>
                          <p>{jobDetailsData?.sqFtSecondFloor}</p>
                        </div>
                        <div className="Residential">
                          <h6>SqFt Garage Floor</h6>
                          <p>{jobDetailsData?.sqFtGarageFloor}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>SqFt Second Floor</h6>
                          <p>{jobDetailsData?.sqFtSecondFloor}</p>
                        </div>
                        <div className="Residential">
                          <h6>Total House Square Footage</h6>
                          <p>{jobDetailsData?.taxProfile}</p>
                        </div>
                        <div className="Residential">
                          <h6>SqFt Basement/Lower Level</h6>
                          <p>{jobDetailsData?.sqFtBaseOrLowLevel}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" data-toggle="collapse" href="#collapseFour">
                <h4><span><img src="images/Residential-4.png" /></span> Business Address</h4>
                <a className="Edit" href="javascript:void(0);" onClick={showResidentialUpdateDetailModal}>
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div id="collapseFour" className="collapse show" data-parent="#accordion">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Job Address</h6>
                          <p>{jobDetailsData?.jobAddress}</p>
                        </div>
                        <div className="Residential">
                          <h6>Lot</h6>
                          <p>{jobDetailsData?.lot}</p>
                        </div>
                        <div className="Residential">
                          <h6>Block</h6>
                          <p>{jobDetailsData?.block}</p>
                        </div>
                        <div className="Residential">
                          <h6>Phase</h6>
                          <p>{jobDetailsData?.phase}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>City</h6>
                          <p>{jobDetailsData?.city}</p>
                        </div>
                        <div className="Residential">
                          <h6>Zip/ Postal Code</h6>
                          <p>{jobDetailsData?.zipCode}</p>
                        </div>
                        <div className="Residential">
                          <h6>Country</h6>
                          <p>{jobDetailsData?.country}</p>
                        </div>
                        <div className="Residential">
                          <h6>State/Province</h6>
                          <p>{jobDetailsData?.province}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" data-toggle="collapse" href="#collapseFive">
                <h4><span><img src="images/Residential-5.png" /></span> Sales Information</h4>
              </div>
              <div id="collapseFive" className="collapse show" data-parent="#accordion">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Agreement Date</h6>
                          <p>{jobDetailsData?.Contracts?.[0]?.createdAt?.split("T")[0]}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Updated Date</h6>
                          <p>{jobDetailsData?.Contracts?.[0]?.updatedAt?.split("T")[0]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Customer Purchase Price</h6>
                          <p>{jobDetailsData?.Contracts?.[0]?.clientPurchasePrice}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Sales Consultant</h6>
                          <p>{jobDetailsData?.Contracts?.[0]?.referrerName}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Sales Type</h6>
                          <p>{jobDetailsData?.Contracts?.[0]?.jobType}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" data-toggle="collapse" href="#collapseSix">
                <h4><span><img src="images/Residential-6.png" /></span> Estimation &amp; Construction Information</h4>
              </div>
              <div id="collapseSix" className="collapse show" data-parent="#accordion">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Primary Estimator/Purchaser</h6>
                          <p>{jobDetailsData?.Clients?.[0]?.legalName}</p>
                        </div>
                        <div className="Residential">
                          <h6>Primary Site Super</h6>
                          <p>Yess</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" data-toggle="collapse" href="#collapseSeven" aria-expanded="true">
                <h4><span><img src="images/Residential-7.png" /></span> Client Information</h4>
              </div>
              <div id="collapseSeven" className="collapse show" data-parent="#accordion" style={{}}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Personal Information</h6>
                        </div>
                        <div className="Residential">
                          <h6>Client Full Legal name</h6>
                          <p>{jobDetailsData?.Clients?.[0]?.legalName}</p>
                        </div>
                        <div className="Residential">
                          <h6>Primary Phone Number</h6>
                          <p>{jobDetailsData?.Clients?.[0]?.primaryPhoneNum}</p>
                        </div>
                        <div className="Residential">
                          <h6>Email</h6>
                          <p>{jobDetailsData?.Clients?.[0]?.legalName}</p>
                        </div>
                        <div className="Residential">
                          <h6>Business Phone Number</h6>
                          <p>{jobDetailsData?.Clients?.[0]?.businessPhoneNum}</p>
                        </div>
                        <div className="Residential">
                          <h6>Fax Phone Number</h6>
                          <p>{jobDetailsData?.Clients?.[0]?.faxPhoneNum}</p>
                        </div>
                        <div className="Residential">
                          <h6>Preferred Communication</h6>
                          <p>{jobDetailsData?.Clients?.[0]?.preferredCommu}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="ResidentialBox">
                        <div className="Residential">
                          <h6>Address</h6>
                        </div>
                        <div className="Residential">
                          <h6>Street Address</h6>
                          <p>{jobDetailsData?.Clients?.[0]?.streetAddress}</p>
                        </div>
                        <div className="Residential">
                          <h6>Address Line two</h6>
                          <p>{jobDetailsData?.Clients?.[0]?.addressLineTwo}</p>
                        </div>
                        <div className="Residential">
                          <h6>City</h6>
                          <p>{jobDetailsData?.Clients?.[0]?.city}</p>
                        </div>
                        <div className="Residential">
                          <h6>Zip/Postal Code</h6>
                          <p>{jobDetailsData?.Clients?.[0]?.zipCode}</p>
                        </div>
                        <div className="Residential">
                          <h6>Country</h6> 
                           <p>{jobDetailsData?.Clients?.[0]?.country}</p> 
                        </div>
                        <div className="Residential">
                          <h6>State/Province</h6>
                          <p>{jobDetailsData?.Clients?.[0]?.province}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="CloseButton">
            <button className="Close ClosePermissionId" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
        </Modal.Body>
      </Modal>






   
<Modal
        show={updateResidentialJobDetailModal}
        onHide={hideResidentialUpdateJobDetailModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideResidentialUpdateJobDetailModal}
          >
            ×
          </a>
          <div className="ResidentialJobArea">
  <div className="ModalTitleBox GrayModalTitle">
    <h4>Residential Job Details</h4>
  </div>
  <div className="ResidentialJobHead">
    <h3>
      HIL-2B-376 (Basement Development)
      <span className="dropdown">
        <a
          className="dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src="images/dots.png" />
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <a className="dropdown-item" href="#">
            <img src="images/Archive.png" /> Archive Job
          </a>
        </div>
      </span>
    </h3>
    <article>
      <aside>
        <h4>Created By</h4>
        <p>Jonh Doe</p>
      </aside>
      <aside>
        <h4>Creation Date</h4>
        <p>04/15/2023</p>
      </aside>
      <aside>
        <h4>Last Update</h4>
        <p>07/10/2023</p>
      </aside>
    </article>
  </div>
  <div className="ModalAccordian">
    <div id="accordion1">
      <div className="card">
        <div
          className="card-header collapsed"
          data-toggle="collapse"
          href="#collapseOne1"
          aria-expanded="false"
        >
          <h4>
            <span>
              <img src="images/general_information.png" />
            </span>{" "}
            Project &amp; Foundation
          </h4>
        </div>
        <div
          id="collapseOne1"
          className="collapse"
          data-parent="#accordion1"
          style={{}}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Project</h6>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="Project 1"
                      name="projectName"
                      value={projectName}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="Residential">
                    <h6>Foundation</h6>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="Basement Development"
                      name="foundationType"
                      value={foundationType}
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-12">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Add-Ons</h6>
                    <div className="AddonTable">
                      <div className="Addon">
                        <table>
                          <thead>
                            <tr>
                              <th>Job Id</th>
                              <th>Job Id</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>HIL-2B-377</td>
                              <td>
                                Detached Garage or Workshop or Major Storage
                                Shed
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <img src="images/Action-1.png" />
                                </a>
                                <a href="javascript:void(0);" className="mx-3">
                                  <img src="images/dropdown-delete.png" />
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>HIL-2B-378</td>
                              <td>Secondary Suite - Over Detached G arage</td>
                              <td>
                                <a href="javascript:void(0);">
                                  <img src="images/Action-1.png" />
                                </a>
                                <a href="javascript:void(0);" className="mx-3">
                                  <img src="images/dropdown-delete.png" />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <button
                        className="Create FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateResidentialAddOns"
                        data-dismiss="modal"
                      >
                        + Create Add-Ons
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-sm-12">
                <div className="ResidentialButton">
                  <button  className="Cancel">Cancel</button>
                  <button onClick={updatejobFunc} className="Save">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div
          className="card-header collapsed"
          data-toggle="collapse"
          href="#collapseTwo1"
          aria-expanded="false"
        >
          <h4>
            <span>
              <img src="images/Residential-2.png" />
            </span>{" "}
            Details
          </h4>
        </div>
        <div
          id="collapseTwo1"
          className="collapse"
          data-parent="#accordion1"
          style={{}}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Home Type</h6>
                    <select name="homeType" value={homeType} onChange={handleInput} className="form-control">
                      <option value="">Option</option>
                      {checkArray(homeListData) &&
                            homeListData?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele?.id}>{ele?.name}</option>
                                </>
                              );
                            })}
                    </select>
                  </div>
                  <div className="Residential">
                    <h6>Specification Document</h6>
                    <select name="specificationDocument" value={specificationDocument} onChange={handleInput} className="form-control">
                      <option>Standard Specifications</option>
                      <option value="1">Document 1</option>
                          <option value="2">Document 2</option>
                          <option value="3">Document 3</option>
                    </select>
                  </div>
                  <div className="Residential">
                    <h6>Tax Profile</h6>
                    <select
                          name="taxProfile"
                          value={taxProfile}
                          onChange={handleInput}
                        >
                          <option value="">Select Tax profile</option>
                          {checkArray(taxProfileListData) &&
                            taxProfileListData?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele?.id}>{ele?.name}</option>
                                </>
                              );
                            })}
                        </select>
                  </div>
                  <div className="Residential">
                    <h6>Job Site GMT (UTC) Timezone</h6>
                    <select name="timeZone" value={timeZone} onChange={handleInput} className="form-control">
                    {checkArray(timeZoneData?.data) ? (
                            <>
                              {timeZoneData?.data?.map((ele, i) => {
                                return (
                                  <option value={ele?.gmtOffset}>
                                    {ele?.zoneName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Building Permit #</h6>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="BP2023-11766"
                      name="buildingPermit"
                      value={buildingPermit}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="Residential">
                    <h6>Base Model</h6>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="Residential Job"
                      name="baseModel"
                      value={baseModel}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="Residential">
                    <h6>Default Currency</h6>
                    <select
                          name="defaultCurrency"
                          value={defaultCurrency}
                          onChange={handleInput}
                        >
                          <option selected="">Select Currency</option>
                          <option value="USD">USD</option>
                          <option value="INR">INR</option>
                        </select>
                  </div>
                  <div className="Residential">
                    <h6 className="mb-4">Accounting Status</h6>
                    <label className="switch">
                      {" "}
                      Integrate with Accounting Software
                      <input type="checkbox" name="intJobWithAccSof" onChange={(e) => updateState({...iState,intJobWithAccSof : e.target.checked })} />
                      <span className="slider" />
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="ResidentialButton">
                  <button className="Cancel">Cancel</button>
                  <button onClick={updatejobFunc} className="Save">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div
          className="card-header collapsed"
          data-toggle="collapse"
          href="#collapseThree1"
          aria-expanded="false"
        >
          <h4>
            <span>
              <img src="images/Residential-3.png" />
            </span>{" "}
            Square Foot
          </h4>
        </div>
        <div
          id="collapseThree1"
          className="collapse"
          data-parent="#accordion1"
          style={{}}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>SqFt Main Floor</h6>
                    <input
                      type="text"
                      className="form-control"
                      name="sqFtMainFloor"
                      value={sqFtMainFloor}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="Residential">
                    <h6>SqFt Third Floor</h6>
                    <input
                      type="text"
                      className="form-control"
                      name="sqFtThirdFloor"
                      value={sqFtThirdFloor}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="Residential">
                    <h6>SqFt Garage Floor</h6>
                    <input
                      type="text"
                      className="form-control"
                      name="sqFtGarageFloor"
                      value={sqFtGarageFloor}
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>SqFt Second Floor</h6>
                    <input
                      type="text"
                      className="form-control"
                     
                      name="sqFtSecondFloor"
                      value={sqFtSecondFloor}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="Residential">
                    <h6>Total House Square Footage</h6>
                    <input
                      type="text"
                      className="form-control"
                   
                      disabled=""
                    />
                  </div>
                  <div className="Residential">
                    <h6>SqFt Basement/Lower Level</h6>
                    <input
                      type="text"
                      className="form-control"
                      name="sqFtBaseOrLowLevel"
                      value={sqFtBaseOrLowLevel}
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="ResidentialButton">
                  <button   className="Cancel">Cancel</button>
                  <button onClick={updatejobFunc} className="Save">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div
          className="card-header"
          data-toggle="collapse"
          href="#collapseFour1"
          aria-expanded="true"
        >
          <h4>
            <span>
              <img src="images/Residential-4.png" />
            </span>{" "}
            Business Address
          </h4>
        </div>
        <div
          id="collapseFour1"
          className="collapse show"
          data-parent="#accordion1"
          style={{}}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Job Address</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert full Address"
                    />
                  </div>
                  <div className="Residential">
                    <h6>Lot</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert lot number"
                      name="lot"
                      value={lot}
                      onChange={handleInput}
                    
                    />
                  </div>
                  <div className="Residential">
                    <h6>Block</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert block number"
                      name="block"
                      value={block}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="Residential">
                    <h6>Phase</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert phase"
                      name="phase"
                      value={phase}
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  
                  <div className="Residential">
                    <h6>Zip/ Postal Code</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert zip code"
                      name="zipCode"
                      value={zipCode}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="Residential">
                    <h6>Country</h6>
                    <select
                          name="country"
                          value={country}
                          onChange={handleInput}
                        >
                          <option>Select Country</option>
                          {checkArray(countryData?.countries) ? (
                            <>
                              {countryData?.countries?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                  </div>
              \

                  <div className="Residential">
                    <h6>State/Province</h6>
                    <select
                          name="province"
                          value={province}
                          onChange={handleInput}
                        >
                          <option>Select State</option>
                          {checkArray(stateData?.states) ? (
                            <>
                              {stateData?.states?.map((ele, i) => {
                                return (
                                  <option
                                    value={ele?.name}
                                    
                                  >
                                    {ele?.name}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                  </div>

                  <div className="Residential">
                    <h6>city</h6>
                    <select
                          name="city"
                          value={city}
                          onChange={handleInput}
                        >
                          <option>Select City</option>
                          {checkArray(cityData?.cities) ? (
                            <>
                              {cityData?.cities?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                )
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="ResidentialButton">
                  <button className="Cancel">Cancel</button>
                  <button onClick={updatejobFunc}  className="Save">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="card">
        <div
          className="card-header collapsed"
          data-toggle="collapse"
          href="#collapseFive1"
          aria-expanded="false"
        >
          <h4>
            <span>
              <img src="images/Residential-5.png" />
            </span>{" "}
            Sales Information
          </h4>
        </div>
        <div
          id="collapseFive1"
          className="collapse"
          data-parent="#accordion1"
          style={{}}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-4">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Agreement Date</h6>
                    <p>05/15/2023</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Possession Date</h6>
                    <p>05/15/2024</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Customer Purchase Price</h6>
                    <p>$450,000.00</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Sales Consultant</h6>
                    <p>Jill Sales</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Sales Type</h6>
                    <p>Model Home</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="card">
        <div
          className="card-header collapsed"
          data-toggle="collapse"
          href="#collapseSix1"
          aria-expanded="false"
        >
          <h4>
            <span>
              <img src="images/Residential-6.png" />
            </span>{" "}
            Estimation &amp; Construction Information
          </h4>
        </div>
        <div
          id="collapseSix1"
          className="collapse"
          data-parent="#accordion1"
          style={{}}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Primary Estimator/Purchaser</h6>
                    <p>Jack Purchaser</p>
                  </div>
                  <div className="Residential">
                    <h6>Primary Site Super</h6>
                    <p>Joe Site</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="card">
        <div
          className="card-header collapsed"
          data-toggle="collapse"
          href="#collapseSeven1"
          aria-expanded="false"
        >
          <h4>
            <span>
              <img src="images/Residential-7.png" />
            </span>{" "}
            Client Information
          </h4>
        </div>
        <div
          id="collapseSeven1"
          className="collapse"
          data-parent="#accordion1"
          style={{}}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Personal Information</h6>
                  </div>
                  <div className="Residential">
                    <h6>Client Full Legal name</h6>
                    <p>Joe Full Client</p>
                  </div>
                  <div className="Residential">
                    <h6>Primary Phone Number</h6>
                    <p>(587)555-555</p>
                  </div>
                  <div className="Residential">
                    <h6>Email</h6>
                    <p>joe.fclient@email.com</p>
                  </div>
                  <div className="Residential">
                    <h6>Business Phone Number</h6>
                    <p>&nbsp;</p>
                  </div>
                  <div className="Residential">
                    <h6>Fax Phone Number</h6>
                    <p>&nbsp;</p>
                  </div>
                  <div className="Residential">
                    <h6>Preferred Communication</h6>
                    <p>Email</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Address</h6>
                  </div>
                  <div className="Residential">
                    <h6>Street Address</h6>
                    <p>120 4th Street Sw</p>
                  </div>
                  <div className="Residential">
                    <h6>Address Line two</h6>
                    <p>Insert</p>
                  </div>
                  <div className="Residential">
                    <h6>City</h6>
                    <p>Calgary</p>
                  </div>
                  <div className="Residential">
                    <h6>Zip/Postal Code</h6>
                    <p>T3A T3A</p>
                  </div>
                  <div className="Residential">
                    <h6>Country</h6>
                    <p>Canada</p>
                  </div>
                  <div className="Residential">
                    <h6>State/Province</h6>
                    <p>Select State or Province</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
    <div className="ResidentialButton">
      <button
        className="Close"
         
      >
        Close
      </button>
    </div>
  </div>
</div>

        </Modal.Body>
      </Modal>   




      <Modal
        show={landDevelopmentJobDetailsModal}
        onHide={hideLandDevelopmentJobDetailsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideLandDevelopmentJobDetailsModal}
          >
            ×
          </a>
          <div className="FormArea">
  <div className="ModalTitleBox GrayModalTitle">
    <h4>Land Development Details</h4>
  </div>
  <div className="FolderForm">
    <div className="ResidentialJobArea ResidentialJobAreaPara">
      <div className="ResidentialJobHead">
        <h3>
          U10-HIL-2B-381 (Lot #10)
          <span className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="images/dots.png" />
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <a className="dropdown-item" href="javascript:void(0);">
                <img src="images/archive_job.png" />
                Archive Job
              </a>
            </div>
          </span>
        </h3>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <h6>Created By</h6>
              <p>John Doe</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>Creation Date</h6>
              <p>04/15/2023</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>Last Update</h6>
              <p>07/10/2023</p>
            </div>
          </div>
        </div>
      </div>
      <div className="ModalAccordian">
        <div id="accordion">
          <div className="card">
            <div
              className="card-header"
              data-toggle="collapse"
              href="#GeneralDetails"
            >
              <h4>
                <span>
                  <img src="images/general_details.png" />
                </span>
                General Details
              </h4>
              <a
                className="Edit"
                href="javascript:void(0);"
                 onClick={showLandDevelopmentJobUpdateModal}
              >
                <img src="images/Action-1.png" />
              </a>
            </div>
            <div
              id="GeneralDetails"
              className="collapse show"
              data-parent="#accordion"
            >
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-sm-5">
                      <div className="form-group">
                        <h6>Project</h6>
                        <p>Project 1</p>
                      </div>
                      <div className="form-group">
                        <h6>Home Type</h6>
                        <p>2-Storey</p>
                      </div>
                      <div className="form-group">
                        <h6>Track Share Cost Scheme</h6>
                        <p>Square Foot Percentage</p>
                      </div>
                      <div className="form-group">
                        <h6>Subdivision</h6>
                        <p>Delta</p>
                      </div>
                      <div className="form-group">
                        <h6>Building Permit #</h6>
                        <p>BP2023-11766</p>
                      </div>
                      <div className="form-group">
                        <h6>Accounting Status</h6>
                        <h6 className="fw-400">
                          Integrate with Accounting Software
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-5 ml-auto">
                      <div className="form-group">
                        <h6>Specification Document</h6>
                        <p>Standard Specifications</p>
                      </div>
                      <div className="form-group">
                        <h6>Job Site GMT (UTC) Timezone</h6>
                        <p>Moutain Time GMT - 06 1</p>
                      </div>
                      <div className="form-group">
                        <h6>Tax Profile</h6>
                        <p>Alberta Profile</p>
                      </div>
                      <div className="form-group">
                        <h6>Default Currency</h6>
                        <p>CAD</p>
                      </div>
                      <div className="form-group">
                        <h6>Tract SqFt</h6>
                        <p>150,000.00</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header"
              data-toggle="collapse"
              href="#LandTractsPhasesLots"
            >
              <h4>
                <span>
                  <img src="images/land_track_phase.png" />
                </span>
                Land Tracts/Phases &amp; Lots
              </h4>
              <a
                className="Edit"
                href="javascript:void(0);"
                onClick={showLandDevelopmentJobUpdateModal}
              >
                <img src="images/Action-1.png" />
              </a>
            </div>
            <div
              id="LandTractsPhasesLots"
              className="collapse show"
              data-parent="#accordion"
            >
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <h6>Land Tracts/ Phases</h6>
                        <p>Land Tracts/ Phase</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                     
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Lot#</h6>
                        <p>10</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Lot Description</h6>
                        <p>Residential Lot 10</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Lot Type</h6>
                        <p>Residential</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header"
              data-toggle="collapse"
              href="#BusinessAddress"
            >
              <h4>
                <span>
                  <img src="images/business_address.png" />
                </span>
                Business Address
              </h4>
              <a
                className="Edit"
                href="javascript:void(0);"
                onClick={showLandDevelopmentJobUpdateModal}
              >
                <img src="images/Action-1.png" />
              </a>
            </div>
            <div
              id="BusinessAddress"
              className="collapse show"
              data-parent="#accordion"
            >
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-sm-5">
                      <div className="form-group">
                        <h6>Job Address</h6>
                        <p>Street 1</p>
                      </div>
                      <div className="form-group">
                        <h6>Lot</h6>
                      </div>
                      <div className="form-group">
                        <h6>Block</h6>
                      </div>
                      <div className="form-group mb-0">
                        <h6>Phase</h6>
                        <p>Insert Phase</p>
                      </div>
                    </div>
                    <div className="col-sm-5 ml-auto">
                      <div className="form-group">
                        <h6>City</h6>
                        <p>Calgary</p>
                      </div>
                      <div className="form-group">
                        <h6>Zip/ Postal Code</h6>
                        <p>T3A T3A</p>
                      </div>
                      <div className="form-group">
                        <h6>Country</h6>
                        <p>Canada</p>
                      </div>
                      <div className="form-group mb-0">
                        <h6>State/Province</h6>
                        <p>AB</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header"
              data-toggle="collapse"
              href="#ClientInformation"
            >
              <h4>
                <span>
                  <img src="images/estimation_information.png" />
                </span>
                Client Information
              </h4>
              <a
                className="Edit"
                href="javascript:void(0);"
                onClick={showLandDevelopmentJobUpdateModal}
              >
                <img src="images/Action-1.png" />
              </a>
            </div>
            <div
              id="ClientInformation"
              className="collapse show"
              data-parent="#accordion"
            >
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <h6>Personal Information</h6>
                      <div className="form-group">
                        <h6>Client Full Legal name</h6>
                        <p>Joe Full Client</p>
                      </div>
                      <div className="form-group">
                        <h6>Primary Phone Number</h6>
                        <p>(587)555-555</p>
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <p>joe.fclient@email.com</p>
                      </div>
                      <div className="form-group">
                        <h6>Business Phone Number</h6>
                      </div>
                      <div className="form-group">
                        <h6>Fax Phone Number</h6>
                      </div>
                      <div className="form-group">
                        <h6>Preferred Communication</h6>
                        <p>Email</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h6>Address</h6>
                      <div className="form-group">
                        <h6>Street Address</h6>
                        <p>120 4th Street Sw</p>
                      </div>
                      <div className="form-group">
                        <h6>Address Line two</h6>
                      </div>
                      <div className="form-group">
                        <h6>City</h6>
                        <p>Calgary</p>
                      </div>
                      <div className="form-group">
                        <h6>Zip/Postal Code</h6>
                        <p>T3A T3A</p>
                      </div>
                      <div className="form-group">
                        <h6>Country</h6>
                        <p>Canada</p>
                      </div>
                      <div className="form-group">
                        <h6>State/Province</h6>
                        <p>Select State or Province</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="CloseButton">
          <button className="Close ClosePermissionid"  onClick={showLandDevelopmentJobUpdateModal}>
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


        </Modal.Body>
      </Modal>   




      <Modal
        show={updateLandDevelopmentModal}
        onHide={hideLandDevelopmentJobUpdateModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideLandDevelopmentJobUpdateModal}
          >
            ×
          </a>
          <div className="FormArea">
  <div className="ModalTitleBox GrayModalTitle">
    <h4>Land Development Details</h4>
  </div>
  <div className="FolderForm">
    <div className="ResidentialJobArea ResidentialJobAreaPara">
      <div className="ResidentialJobHead">
        <h3>
          HIL-2B-401 (Land Tract/Phase)
          <span className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="images/dots.png" />
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <a className="dropdown-item" href="javascript:void(0);">
                <img src="images/archive_job.png" />
                Archive Job
              </a>
            </div>
          </span>
        </h3>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <h6>Created By</h6>
              <p>John Doe</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>Creation Date</h6>
              <p>04/15/2023</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>Last Update</h6>
              <p>07/10/2023</p>
            </div>
          </div>
        </div>
      </div>
      <div className="ModalAccordian">
        <div id="accordion">
          <div className="card">
            <div
              className="card-header"
              data-toggle="collapse"
              href="#GeneralDetails"
              aria-expanded="true"
            >
              <h4>
                <span>
                  <img src="images/general_details.png" />
                </span>
                General Details
              </h4>
            </div>
            <div
              id="GeneralDetails"
              className="collapse show"
              data-parent="#accordion"
              style={{}}
            >
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-sm-5">
                      <div className="form-group">
                        <h6>Project</h6>
                        <p>Project 1</p>
                      </div>
                      <div className="form-group">
                        <h6>Home Type</h6>
                        <p>2-Storey</p>
                      </div>
                      <div className="form-group">
                        <h6>Shared Unit Cost Scheme</h6>
                        <p>Square Foot Percentage</p>
                      </div>
                      <div className="form-group">
                        <h6>Subdivision</h6>
                        <p>Delta</p>
                      </div>
                      <div className="form-group">
                        <h6>Building Permit #</h6>
                        <p>BP2023-11766</p>
                      </div>
                      <div className="form-group">
                        <h6>Accounting Status</h6>
                        <h6 className="fw-400">
                          Integrate with Accounting Software
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-5 ml-auto">
                      <div className="form-group">
                        <h6>Specification Document</h6>
                        <p>Standard Specifications</p>
                      </div>
                      <div className="form-group">
                        <h6>Job Site GMT (UTC) Timezone</h6>
                        <p>Moutain Time GMT - 06 1</p>
                      </div>
                      <div className="form-group">
                        <h6>Tax Profile</h6>
                        <p>Alberta Profile</p>
                      </div>
                      <div className="form-group">
                        <h6>Default Currency</h6>
                        <p>CAD</p>
                      </div>
                      <div className="form-group">
                        <h6>Tract SqFt</h6>
                        <p>150,000.00</p>
                      </div>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-center mt-5">
                    <button className="OutlineBlack mr-3">Cancel</button>
                    <button className="Respond ml-3">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header"
              data-toggle="collapse"
              href="#LandTractsPhasesLots"
              aria-expanded="true"
            >
              <h4>
                <span>
                  <img src="images/land_track_phase.png" />
                </span>
                Land Tracts/Phases &amp; Lots
              </h4>
              <a className="Edit" href="javascript:void(0);">
                <img src="images/Action-1.png" />
              </a>
            </div>
            <div
              id="LandTractsPhasesLots"
              className="collapse show"
              data-parent="#accordion"
              style={{}}
            >
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Land Tracts/ Phases</h6>
                        <p>Phase A</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Total Lots</h6>
                        <p>3</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="TodosButtons mt-0 text-right mb-3">
                        <a
                          href="javascript:void(0)"
                          className="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#CreateLandDevelopmentLandTractsPhasesLandTrack2"
                          data-dismiss="modal"
                        >
                          <img src="images/create_land_white.png" />
                          Create Land Tract/Phase
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="Tableheader">
                    <h6 className="mb-0">Lots</h6>
                    <div className="Actions">
                      <a href="javascript:void(0);">
                        <img src="images/icon-53.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  <div className="TableList TableListHeader NewTableList TableHeightScroll">
                    <table>
                      <thead>
                        <tr>
                          <th>Job Id</th>
                          <th>Lot #</th>
                          <th>Lot Description</th>
                          <th>Lot Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>U10-HIL-2B-381</td>
                          <td>10</td>
                          <td>Residential Lot 10</td>
                          <td>Residential</td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu dropdown-menuwidth">
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/Action-1.png" />
                                        Edit Job Details
                                      </a>
                                    </li>
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/create_loot.png" />
                                        Create Lots
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>U10-HIL-2B-381</td>
                          <td>11</td>
                          <td>Residential Lot 10</td>
                          <td>Residential</td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu dropdown-menuwidth">
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/Action-1.png" />
                                        Edit Job Details
                                      </a>
                                    </li>
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/create_loot.png" />
                                        Create Lots
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>U10-HIL-2B-381</td>
                          <td>12</td>
                          <td>Residential Lot 10</td>
                          <td>Multi-Family</td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu dropdown-menuwidth">
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/Action-1.png" />
                                        Edit Job Details
                                      </a>
                                    </li>
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/create_loot.png" />
                                        Create Lots
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="Button d-flex justify-content-center mt-5">
                    <button className="OutlineBlack mr-3">Cancel</button>
                    <button className="Respond ml-3">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header"
              data-toggle="collapse"
              href="#BusinessAddress"
              aria-expanded="true"
            >
              <h4>
                <span>
                  <img src="images/business_address.png" />
                </span>
                Business Address
              </h4>
            </div>
            <div
              id="BusinessAddress"
              className="collapse show"
              data-parent="#accordion"
              style={{}}
            >
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-sm-5">
                      <div className="form-group">
                        <h6>Job Address</h6>
                        <p>Street 1</p>
                      </div>
                      <div className="form-group">
                        <h6>Lot</h6>
                      </div>
                      <div className="form-group">
                        <h6>Block</h6>
                      </div>
                      <div className="form-group mb-0">
                        <h6>Phase</h6>
                        <p>Insert Phase</p>
                      </div>
                    </div>
                    <div className="col-sm-5 ml-auto">
                      <div className="form-group">
                        <h6>City</h6>
                        <p>Calgary</p>
                      </div>
                      <div className="form-group">
                        <h6>Zip/ Postal Code</h6>
                        <p>T3A T3A</p>
                      </div>
                      <div className="form-group">
                        <h6>Country</h6>
                        <p>Canada</p>
                      </div>
                      <div className="form-group mb-0">
                        <h6>State/Province</h6>
                        <p>AB</p>
                      </div>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-center mt-5">
                    <button className="OutlineBlack mr-3">Cancel</button>
                    <button className="Respond ml-3">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="CloseButton">
          <button
            className="Close"
            data-toggle="modal"
            data-target="#LandDevelopmentDetails1"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


        </Modal.Body>
      </Modal>   

    </>
  );
};

export default Header;
