import axios from "axios";
import { companyActionTypes } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));


export const createCompanyInitate = () => ({
    type: companyActionTypes.CREATE_COMPANY_INITIATE,
  });
  
  export const createCompanySuccess = (data) => ({
    type: companyActionTypes.CREATE_COMPANY_SUCCESS,
    payload: data,
  });
  
  export const createCompanyFailure = (data) => ({
    type: companyActionTypes.CREATE_COMPANY_FAILURE,
    payload: data,
  });
  
  export function createCompanyAction(id,payload) {
    const token = isLoggedIn("adminData1");
    const updatedData = multiPartData(payload)
    return (dispatch) => {
      dispatch(createCompanyInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${Url}companies/${id}`, updatedData, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(createCompanySuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(createCompanyFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
          
              dispatch(createCompanyFailure(err));
              reject(err);
          
           
          });
      });
    };
  }
  


  
export const companyDetailsInitate = () => ({
  type: companyActionTypes.COMPANY_DETAILS_INITIATE,
});

export const companyDetailsSuccess = (data) => ({
  type: companyActionTypes.COMPANY_DETAILS_SUCCESS,
  payload: data,
});

export const companyDetailsFailure = (data) => ({
  type: companyActionTypes.COMPANY_DETAILS_FAILURE,
  payload: data,
});

export function companyDetailsAction(id,payload) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData(payload)
  return (dispatch) => {
    dispatch(companyDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(companyDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(companyDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(companyDetailsSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(companyDetailsFailure(err));
            reject(err);
          }        
        });
    });
  };
}



export const addTaxInitate = () => ({
  type: companyActionTypes.ADD_TAX_INITIATE,
});

export const addTaxSuccess = (data) => ({
  type: companyActionTypes.ADD_TAX_SUCCESS,
  payload: data,
});

export const addTaxFailure = (data) => ({
  type: companyActionTypes.ADD_TAX_FAILURE,
  payload: data,
});

export function addTaxAction(id,payload) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData(payload)
  return (dispatch) => {
    dispatch(addTaxInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}taxprofile/${id}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(addTaxSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(addTaxFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(addTaxSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(addTaxFailure(err));
            reject(err);
          }
         
        });
    });
  };
}


//========================UPDATE--PROJECT=================

export const updateProjectsInitiate = () => ({
  type: companyActionTypes.UPDATE_PROJECTS_INITIATE,
});

export const updateProjectsSuccess = (data) => ({
  type: companyActionTypes.UPDATE_PROJECTS_SUCCESS,
  payload: data,
});

export const updateProjectsFailure = (data) => ({
  type: companyActionTypes.UPDATE_PROJECTS_FAILURE,
  payload: data,
});

export const updateProjectss = (projecId,payload) => async (dispatch) => {
    try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(updateProjectsInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projecId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(updateProjectsSuccess(response.data));
      } else {
        dispatch(updateProjectsFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(updateProjectsFailure(err));
      return err.response
    }
  };

//===============================GET--TAX--PROFILE===================


export const getTaxProfileInitiate = () => ({
  type: companyActionTypes.GET_TAX_PROFILE_INITIATE,
});

export const getTaxProfileSuccess = (data) => ({
  type: companyActionTypes.GET_TAX_PROFILE_SUCCESS,
  payload: data,
});

export const getTaxProfileFailure = (data) => ({
  type: companyActionTypes.GET_TAX_PROFILE_FAILURE,
  payload: data,
});

export const getTaxProfileList = () => async (dispatch) => {
    try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(getTaxProfileInitiate());
      const response = await axios.get(`${Url}taxprofile?CompanyId=${iBuildLocalData?.user?.CompanyId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(getTaxProfileSuccess(response.data));
      } else {
        dispatch(getTaxProfileFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(getTaxProfileFailure(err));
      return err.response
    }
  };

//=======================get project Types =================

export const getRegularProjectInitiate = () => ({
  type: companyActionTypes.GET_REGULAR_PROJECT_INITIATE,
});

export const getRegularProjectSuccess = (data) => ({
  type: companyActionTypes.GET_REGULAR_PROJECT_SUCCESS,
  payload: data,
});

export const getRegularProjectFailure = (data) => ({
  type: companyActionTypes.GET_REGULAR_PROJECT_FAILURE,
  payload: data,
});

export const getRegularProjectList = () => async (dispatch) => {
    try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(getRegularProjectInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/all-project?projectMode=regular`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(getRegularProjectSuccess(response.data));
      } else {
        dispatch(getRegularProjectFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(getRegularProjectFailure(err));
      return err.response
    }
  };

  
export const getEntityProjectInitiate = () => ({
  type: companyActionTypes.GET_ENTITY_PROJECT_INITIATE,
});

export const getEntityProjectSuccess = (data) => ({
  type: companyActionTypes.GET_ENTITY_PROJECT_SUCCESS,
  payload: data,
});

export const getEntityProjectFailure = (data) => ({
  type: companyActionTypes.GET_ENTITY_PROJECT_FAILURE,
  payload: data,
});

export const getEntityProjectList = () => async (dispatch) => {
    try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(getEntityProjectInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/all-project?projectMode=entity`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(getEntityProjectSuccess(response.data));
      } else {
        dispatch(getEntityProjectFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(getEntityProjectFailure(err));
      return err.response
    }
  };