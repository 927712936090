
export function multiPartData(data, type = "") {
  console.log("------>", data)
  let multiPart = new FormData();
  for (let key in data) {
    if (key === "file" || key === "images" || key == "files") {
      if (Array.isArray(data[key])) {
        data[key].forEach((file, index) => {
          multiPart.append(`${key}_${index}`, file);
        });
      } else {
        multiPart.append(key, data[key]); // Handle a single file case
      }
    } 
    else if ( key == "purchaseDetails") {
      data[key].forEach((obj, index) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            multiPart.append(`purchaseDetails[${index}][${key}]`, obj[key]);
          }
        }
      });
     
    }
    else if (key == "entryAccount") {
      data[key].forEach((obj, index) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            multiPart.append(`entryAccount[${index}][${key}]`, obj[key]);
          }
        }
      });
     
    }

    else if (key == "warrantyNotes") {
      data[key].forEach((obj, index) => {      
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            multiPart.append(`warrantyNotes[${index}][${key}]`, obj[key]);
          }
        }
      });
     
    }
  
  
    else if (key == "assignStaffs") {
      console.log("------>", data, data[key], key)
      data[key].forEach((obj, index) => {
        
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            multiPart.append(`assignStaffs[${index}][${key}]`, obj[key]);
          }
        }
      });
     
    }
    else if (key =="supportingDocFiles") {
    
      if(typeof data[key] === "object"){
        multiPart.append(key, data[key]);
      }else{
        data[key].forEach((obj, index) => {      
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              multiPart.append(`supportingDocFiles[${index}][${key}]`, obj[key]);
            }
          }
        });
       
      }
     
    }
   else if (key == "assignTrade" || key === "toDoNotes" ) {
    const assignTradeKeys = Object.keys(data[key]);
      assignTradeKeys.forEach(subKey => {
        multiPart.append(`${key}[${subKey}]`, data[key][subKey]);
      });

     
    }
    else {
      multiPart.append(key, data[key]);
    }
  } 

  return multiPart;
}


export function multiPartDatas(data, type='') {
  const multiPart = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (type === 'multiple' && key === 'blogImage') {
      value.forEach(file => multiPart.append(key, file));
    } else if (key !== 'blogImage') {
      multiPart.append(key, value);
    }
  }
  return multiPart;
}
export function calculateDateDifference(input) {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime()+ 24 * 60 * 60 * 1000 * Number(input));
  return futureDate?.toISOString()?.slice(0, -1);
}

export function convertToISOFormat(readableDate) {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime(readableDate) )
  //  + 24 * 60 * 60 * 1000 * Number(input));
  return futureDate.toISOString().slice(0, -1);
}

export function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${year}-${month}-${date}`;
}

export function formatDate (dateString){
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month index
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);
  const milliseconds = ('00' + date.getMilliseconds()).slice(-3);

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};


export const calculateDateDifferenceInDays = (createdAt, updatedAt) => {

  const createdDate = new Date(createdAt);
  const updatedDate = new Date(updatedAt??createdAt);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = updatedDate - createdDate;

  // Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const differenceInDays = differenceInMilliseconds / millisecondsInADay;

  // Return the difference rounded to the nearest integer (if you need it as an integer)
  return Math.round(differenceInDays);
};


export const calculateAdjustedDeadline = (date, days, type) => {
  if (!date) return "";

  const selectedDate = new Date(date);
  const daysToAddOrSubtract = parseInt(days, 10);

  if (type === "before") {
    selectedDate.setDate(selectedDate.getDate() - daysToAddOrSubtract);
  } else {
    selectedDate.setDate(selectedDate.getDate() + daysToAddOrSubtract);
  }

  // Format the date to "YYYY-MM-DD"
  const year = selectedDate.getFullYear();
  const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
  const day = String(selectedDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};


export const init={
  height: 500,
  menubar:
    "file edit view insert format tools table help",
  plugins: [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "print",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "paste",
    "help",
    "wordcount",
    "lists",
    "table",
    "pagebreak",
    "spellchecker",
    "powerpaste",
    "mediaembed",
    "advcode",
    "emoticons",
    "codesample",
    "advtable",
    "visualchars",
    "visualblocks",
    "linkchecker",
    "checklist",
    "media",
    "tableofcontents",
    "a11ychecker",
    "permanentpen",
    "casechange",
    "pageembed",
    "nonbreaking",
    "quickbars",
    "advlist",
    "autosave",
    "autolink",
    "autoresize",
  ],
  toolbar:
    "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | " +
    "alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent | " +
    "forecolor backcolor casechange permanentpen formatpainter removeformat | " +
    "pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media pageembed link anchor codesample | " +
    "a11ycheck ltr rtl | showcomments addcomment",

  toolbar_sticky: true,

  autosave_ask_before_unload: true,
  autosave_interval: "30s",
  autosave_prefix: "{path}{query}-{id}-",
  autosave_restore_when_empty: true,
  autosave_retention: "2m",

  image_advtab: true,

  content_css: "default",
  importcss_append: true,

  image_title: true,
  automatic_uploads: true,

  // Handle drag and drop events
  setup: (editor) => {
    editor.on("drop", (e) => {
      e.preventDefault();
      e.stopPropagation();

      // Handle dropped files
      if (e.dataTransfer && e.dataTransfer.files) {
        const files = e.dataTransfer.files;
        for (const file of files) {
          const reader = new FileReader();
          reader.onload = (event) => {
            // Insert image at the cursor position
            editor.insertContent(
              `<img src="${event.target.result}" alt="${file.name}"/>`
            );
          };
          reader.readAsDataURL(file);
        }
      }
    });
  },

  link_list: [
    {
      title: "My page 1",
      value: "https://www.tinymce.com",
    },
    {
      title: "My page 2",
      value: "http://www.moxiecode.com",
    },
  ],
  image_list: [
    {
      title: "My image 1",
      value:
        "https://www.tiny.cloud/images/glyph-tinymce.svg",
    },
    {
      title: "My image 2",
      value:
        "https://www.tiny.cloud/docs/images/glyph-tinymce.svg",
    },
  ],
  image_class_list: [
    { title: "None", value: "" },
    { title: "Some class", value: "some-class" },
  ],
  importcss_file_filter: "main.css",
  templates: [
    {
      title: "New Table",
      description: "creates a new table",
      content:
        "<table><tr><td>Sample Text</td></tr></table>",
    },
    {
      title: "Starting my story",
      description: "A sample document",
      content: "Once upon a time...",
    },
    {
      title: "New list with dates",
      description: "New List with Dates",
      content:
        "<ul><li>12 December</li><li>13 December</li></ul>",
    },
  ],
  template_cdate_format:
    "[CDATE: %m/%d/%Y : %H:%M:%S]",
  template_mdate_format:
    "[MDATE: %m/%d/%Y : %H:%M:%S]",
  height: 600,
  image_caption: true,
  quickbars_selection_toolbar:
    "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
  noneditable_class: "mceNonEditable",
  toolbar_mode: "sliding",
  spellchecker_dialog: true,
  spellchecker_whitelist: ["Ephox", "Moxiecode"],

  tinycomments_mode: "embedded",
  content_style:
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
  a11y_advanced_options: true,

  branding: false, // Remove TinyMCE branding
  powerpaste_word_import: "clean", // Configure PowerPaste
  powerpaste_html_import: "merge",
  powerpaste_allow_local_images: true,

  // Enable live media embeds
  media_live_embeds: true,

  // Enable line breaks
  forced_root_block: "",
  force_br_newlines: true,
  force_p_newlines: false,
}

export const monthsData = [
  { name: 'January' },
  { name: 'February' },
  { name: 'March' },
  { name: 'April' },
  { name: 'May' },
  { name: 'June' },
  { name: 'July' },
  { name: 'August' },
  { name: 'September' },
  { name: 'October' },
  { name: 'November' },
  { name: 'December' }
];



