import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ProjectAsEntityStep2 from "./ProjectAsEntityStep2";
import ProjectAsEntityStep3 from "./ProjectAsEntityStep3";
import ProjectAsEntityStep4 from "./ProjectAsEntityStep4";
import ProjectAsEntityStep5 from "./ProjectAsEntityStep5";
import ProjectAsEntityStep6 from "./ProjectAsEntityStep6";
import ProjectAsEntityStep7 from "./ProjectAsEntityStep7";
import ProjectAsEntityStep8 from "./ProjectAsEntityStep8";
import { getCountriesList } from "../../redux/action/authAction";
import { useDispatch } from "react-redux";

const initialState = {
  CompanyId: "",

  projectEmail: "",

  projectName: "",

  projectAddress: "",

  city: "",

  country: "",

  zipCode: "",

  province: "",

  projectType: "",

  creationType: "",

  subdivisions: [],

  taxProfile: 1,

  useCompanyPattern: false,

  jobPattern: "",
  profileCategories: {
    landCosts: false,
    archConsultantsFees: false,
    engineersFees: false,
    permitFees: false,
    siteLandDevServCosts: false,
    siteLanRetaiWallsRPR: false,
    sitePrepTemOfficeCosts: false,
    newHomeWarInsuranceFees: false,
    condPlanCorpoCosts: false,
    markSalesCosts: false,
    managLegFinFees: false,
  },

  loginUrl: "",

  projectLogo: "",
  products: "",
  Subscription: "",

  contractCode: "",

  billingOption: "",

  PlanId: "",

  accoTransSett: false,

  useCompFiscalYearEnd: false,

  fiscalYearEnd: 3,

  useCompTaxRegistration: false,

  taxRegistration: "",

  useTaxProfiles: false,

  costCodeStructure: false,

  commWorkflows: false,

  designSettings: false,

  jobModels: false,

  quickBooks: false,

  sage: false,

  proformaId: 1,

  ctcMaster: false,

  fileManager: true,

  priceBook: true,

  vendorList: true,

  selAccoSoft: 0,

  errorMsg: {},
};

const ProjectAsEntityStep1 = ({
  ProjectAsEntityStep1Modal,
  SetProjectAsEntityStep1Modal,
  setShow,
}) => {
  const dispatch = useDispatch()
  const handleProjectAsEntityStep1ModalClose = () => {
    setShow(true);
    SetProjectAsEntityStep1Modal(false);
  };
  const handleProjectAsEntityStep1ModalShow = () => {
    SetProjectAsEntityStep1Modal(true);
  };


  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  const [ProjectAsEntityStep2Modal, SetProjectAsEntityStep2Modal] =
    useState(false);
  const [ProjectAsEntityStep3Modal, SetProjectAsEntityStep3Modal] =
    useState(false);
  const [ProjectAsEntityStep4Modal, SetProjectAsEntityStep4Modal] =
    useState(false);
  const [ProjectAsEntityStep5Modal, SetProjectAsEntityStep5Modal] =
    useState(false);
  const [ProjectAsEntityStep6Modal, SetProjectAsEntityStep6Modal] =
    useState(false);
  const [ProjectAsEntityStep7Modal, SetProjectAsEntityStep7Modal] =
    useState(false);
  const [ProjectAsEntityStep8Modal, SetProjectAsEntityStep8Modal] =
    useState(false);

  const [iState, UpdateState] = useState(initialState);

  const {
    projectName, projectEmail, projectLogo ,   loginUrl,
    errorMsg,
  } = iState;

  const handleValidation = () => {
    let formIsValid = true;
    let errorMsg = {};

     const validUrl = (loginUrl) => {
      let url;
      try {
        url = new URL(loginUrl);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    }
 
    if (!projectName) {
      formIsValid = false;
      errorMsg.projectName = "* Please enter project name.";
    }

    if (!validUrl(loginUrl)) {  // Call the validUrl function with siteUrl as a parameter
      errorMsg.loginUrl = " * Enter a valid URL";
      formIsValid = false;
    }


    UpdateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    UpdateState({
      ...iState,
      [name]: value,
    });
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    UpdateState({ ...iState, projectLogo:     URL.createObjectURL(file)
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      SetProjectAsEntityStep1Modal(false);
      SetProjectAsEntityStep2Modal(true);
    }
  };




  useEffect(() => {
    dispatch(getCountriesList());
  }, [ProjectAsEntityStep1Modal]);
  return (
    <>
      <Modal
        show={ProjectAsEntityStep1Modal}
        className="ModalBox modal fade LargeModal show"
      >
        <div className="modal-content BgCreate">
          <a
            onClick={handleProjectAsEntityStep1ModalClose}
            class="CloseModal"

          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create New Project as an Entity</h4>
            </div>
            <div class="StepBox">
              <ul>
                <li class="active">
                  <span class="Icon">
                    <img src={require("../../assets/images/step_1.png")} />
                  </span>
                  <span class="Text">Details</span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      SetProjectAsEntityStep2Modal(true);
                      SetProjectAsEntityStep1Modal(false);
                    }}
                    class="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityAddress"
                    data-dismiss="modal"
                  >
                    <img src={require("../../assets/images/step_2.png")} />
                  </span>
                  <span class="Text">Address</span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissioId"
                    onClick={() => {
                      SetProjectAsEntityStep3Modal(true);
                      SetProjectAsEntityStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_8.png")} />
                  </span>
                  <span class="Text">Type</span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissioId"
                    onClick={() => {
                      SetProjectAsEntityStep4Modal(true);
                      SetProjectAsEntityStep1Modal(false);
                    }}
                  >
                    <img
                      src={require("../../assets/images/subscription_step.png")}
                    />
                  </span>
                  <span class="Text">Subscription</span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissioId"
                    onClick={() => {
                      SetProjectAsEntityStep5Modal(true);
                      SetProjectAsEntityStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_3.png")} />
                  </span>
                  <span class="Text">
                    Accounting <br /> &amp; Tax
                  </span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissioId"
                    onClick={() => {
                      SetProjectAsEntityStep6Modal(true);
                      SetProjectAsEntityStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_5.png")} />
                  </span>
                  <span class="Text">Job Number</span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissioId"
                    onClick={() => {
                      SetProjectAsEntityStep7Modal(true);
                      SetProjectAsEntityStep1Modal(false);
                    }}
                  >
                    <img
                      src={require("../../assets/images/initial_settings.png")}
                    />
                  </span>
                  <span class="Text">
                    Initial <br />
                    Settings
                  </span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissioId"
                    onClick={() => {
                      SetProjectAsEntityStep8Modal(true);
                      SetProjectAsEntityStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_7.png")} />
                  </span>
                  <span class="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div class="FolderForm">
              <form>
                <div class="FolderFormCreateArea">
                  <div class="FieldsMandatory">
                    <h6>
                      Fields with <span class="required">*</span> are mandatory
                    </h6>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <h6>Company</h6>
                        <p>{iBuildLocalData?.companies?.[0]?.name}</p>
                      </div>
                      <div class="form-group">
                        <h6>
                          Project Name <span class="required">*</span>{" "}
                        </h6>
                        <input
                          type="text"
                          value={projectName}
                          name="projectName"
                          onChange={handleChange}
                          class="form-control"
                          placeholder="Insert Project name"
                        />

                        {errorMsg.projectName && !projectName && (
                          <p style={{ color: "red" }}>{errorMsg.projectName}</p>
                        )}
                      </div>
                      <div class="form-group">
                        <h6>Logo</h6>
                        <div class="Upload">
                          <span>-Drag and Drop Files or click to select</span>
                          <input onChange={handleUpload} type="file" />
                          {projectLogo && <img src={projectLogo} /> }
                        </div>
                      </div>
                      <div class="form-group">
                        <h6>Project Email</h6>
                        <input
                          type="text"

                          onChange={handleChange} 
                          value={projectEmail}
                          name="projectEmail"
                          class="form-control"
                          placeholder="Insert Project Email"
                        />
                      </div>
                      <div class="form-group">
                        <h6>Login URL Shorthand</h6>
                        <input
                        onChange={handleChange}
                        value={loginUrl}
                        name="loginUrl"
                          type="text"
                          class="form-control"
                          placeholder="Insert Company's website"
                        />
                      </div>
                      {errorMsg.loginUrl  && (
                          <p style={{ color: "red" }}>{errorMsg.loginUrl}</p>
                        )}
                    </div>
                  </div>
                </div>
                <div class="Button d-flex justify-content-between align-items-center px-5">
                  <button
                    class="Outline"
                    data-dismiss="modal"
                    onClick={() => {
                      SetProjectAsEntityStep1Modal(false);
                      setShow(true);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    class="Create FolderPermissionId"
                    data-toggle="modal"
                    onClick={handleSubmit}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <ProjectAsEntityStep2

      iState={iState}
      UpdateState={UpdateState}
        ProjectAsEntityStep2Modal={ProjectAsEntityStep2Modal}
        ProjectAsEntityStep1Modal={ProjectAsEntityStep1Modal}
        SetProjectAsEntityStep1Modal={SetProjectAsEntityStep1Modal}
        SetProjectAsEntityStep2Modal={SetProjectAsEntityStep2Modal}
        SetProjectAsEntityStep3Modal={SetProjectAsEntityStep3Modal}
        SetProjectAsEntityStep4Modal={SetProjectAsEntityStep4Modal}
        SetProjectAsEntityStep5Modal={SetProjectAsEntityStep5Modal}
        SetProjectAsEntityStep6Modal={SetProjectAsEntityStep6Modal}
        SetProjectAsEntityStep7Modal={SetProjectAsEntityStep7Modal}
        SetProjectAsEntityStep8Modal={SetProjectAsEntityStep8Modal}
        setShow={setShow}
      />

      <ProjectAsEntityStep3

iState={iState}
UpdateState={UpdateState}
        ProjectAsEntityStep3Modal={ProjectAsEntityStep3Modal}
        SetProjectAsEntityStep1Modal={SetProjectAsEntityStep1Modal}
        SetProjectAsEntityStep2Modal={SetProjectAsEntityStep2Modal}
        SetProjectAsEntityStep3Modal={SetProjectAsEntityStep3Modal}
        SetProjectAsEntityStep4Modal={SetProjectAsEntityStep4Modal}
        SetProjectAsEntityStep5Modal={SetProjectAsEntityStep5Modal}
        SetProjectAsEntityStep6Modal={SetProjectAsEntityStep6Modal}
        SetProjectAsEntityStep7Modal={SetProjectAsEntityStep7Modal}
        SetProjectAsEntityStep8Modal={SetProjectAsEntityStep8Modal}
        setShow={setShow}
      />

      <ProjectAsEntityStep4

iState={iState}
UpdateState={UpdateState}
        ProjectAsEntityStep4Modal={ProjectAsEntityStep4Modal}
        SetProjectAsEntityStep1Modal={SetProjectAsEntityStep1Modal}
        SetProjectAsEntityStep2Modal={SetProjectAsEntityStep2Modal}
        SetProjectAsEntityStep3Modal={SetProjectAsEntityStep3Modal}
        SetProjectAsEntityStep4Modal={SetProjectAsEntityStep4Modal}
        SetProjectAsEntityStep5Modal={SetProjectAsEntityStep5Modal}
        SetProjectAsEntityStep6Modal={SetProjectAsEntityStep6Modal}
        SetProjectAsEntityStep7Modal={SetProjectAsEntityStep7Modal}
        SetProjectAsEntityStep8Modal={SetProjectAsEntityStep8Modal}
        setShow={setShow}
      />

      <ProjectAsEntityStep5

iState={iState}
UpdateState={UpdateState}
        ProjectAsEntityStep5Modal={ProjectAsEntityStep5Modal}
        SetProjectAsEntityStep1Modal={SetProjectAsEntityStep1Modal}
        SetProjectAsEntityStep2Modal={SetProjectAsEntityStep2Modal}
        SetProjectAsEntityStep3Modal={SetProjectAsEntityStep3Modal}
        SetProjectAsEntityStep4Modal={SetProjectAsEntityStep4Modal}
        SetProjectAsEntityStep5Modal={SetProjectAsEntityStep5Modal}
        SetProjectAsEntityStep6Modal={SetProjectAsEntityStep6Modal}
        SetProjectAsEntityStep7Modal={SetProjectAsEntityStep7Modal}
        SetProjectAsEntityStep8Modal={SetProjectAsEntityStep8Modal}
        setShow={setShow}
      />

      <ProjectAsEntityStep6

iState={iState}
UpdateState={UpdateState}
        ProjectAsEntityStep6Modal={ProjectAsEntityStep6Modal}
        SetProjectAsEntityStep1Modal={SetProjectAsEntityStep1Modal}
        SetProjectAsEntityStep2Modal={SetProjectAsEntityStep2Modal}
        SetProjectAsEntityStep3Modal={SetProjectAsEntityStep3Modal}
        SetProjectAsEntityStep4Modal={SetProjectAsEntityStep4Modal}
        SetProjectAsEntityStep5Modal={SetProjectAsEntityStep5Modal}
        SetProjectAsEntityStep6Modal={SetProjectAsEntityStep6Modal}
        SetProjectAsEntityStep7Modal={SetProjectAsEntityStep7Modal}
        SetProjectAsEntityStep8Modal={SetProjectAsEntityStep8Modal}
        setShow={setShow}
      />

      <ProjectAsEntityStep7
       iState={iState}
       UpdateState={UpdateState}
        ProjectAsEntityStep7Modal={ProjectAsEntityStep7Modal}
        SetProjectAsEntityStep1Modal={SetProjectAsEntityStep1Modal}
        SetProjectAsEntityStep2Modal={SetProjectAsEntityStep2Modal}
        SetProjectAsEntityStep3Modal={SetProjectAsEntityStep3Modal}
        SetProjectAsEntityStep4Modal={SetProjectAsEntityStep4Modal}
        SetProjectAsEntityStep5Modal={SetProjectAsEntityStep5Modal}
        SetProjectAsEntityStep6Modal={SetProjectAsEntityStep6Modal}
        SetProjectAsEntityStep7Modal={SetProjectAsEntityStep7Modal}
        SetProjectAsEntityStep8Modal={SetProjectAsEntityStep8Modal}
        setShow={setShow}
      />

      <ProjectAsEntityStep8

iState={iState}
UpdateState={UpdateState}
        ProjectAsEntityStep8Modal={ProjectAsEntityStep8Modal}
        SetProjectAsEntityStep1Modal={SetProjectAsEntityStep1Modal}
        SetProjectAsEntityStep2Modal={SetProjectAsEntityStep2Modal}
        SetProjectAsEntityStep3Modal={SetProjectAsEntityStep3Modal}
        SetProjectAsEntityStep4Modal={SetProjectAsEntityStep4Modal}
        SetProjectAsEntityStep5Modal={SetProjectAsEntityStep5Modal}
        SetProjectAsEntityStep6Modal={SetProjectAsEntityStep6Modal}
        SetProjectAsEntityStep7Modal={SetProjectAsEntityStep7Modal}
        SetProjectAsEntityStep8Modal={SetProjectAsEntityStep8Modal}
        setShow={setShow}
      />
    </>
  );
};

export default ProjectAsEntityStep1;
