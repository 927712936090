export const authActionTypes = {
  MAIN_ADMIN_LOGIN_INITIATE: "MAIN_ADMIN_LOGIN_INITIATE",
  MAIN_ADMIN_LOGIN_SUCCESS: "MAIN_ADMIN_LOGIN_SUCCESS",
  MAIN_ADMIN_LOGIN_FAILURE: "MAIN_ADMIN_LOGIN_FAILURE",

  APPROVER_LIST_INITIATE: "APPROVER_LIST_INITIATE",
  APPROVER_LIST_SUCCESS: "APPROVER_LIST_SUCCESS",
  APPROVER_LIST_FAILURE: "APPROVER_LIST_FAILURE",

  MAIN_LOGIN_FORGET_PASSWORD_INITIATE: "MAIN_LOGIN_FORGET_PASSWORD_INITIATE",
  MAIN_LOGIN_FORGET_PASSWORD_SUCCESS: "MAIN_LOGIN_FORGET_PASSWORD_SUCCESS",
  MAIN_LOGIN_FORGET_PASSWORD_FAILURE: "MAIN_LOGIN_FORGET_PASSWORD_FAILURE",

  CHANGE_MAIN_PASSWORD_INITIATE: "CHANGE_MAIN_PASSWORD_INITIATE",
  CHANGE_MAIN_PASSWORD_SUCCESS: "CHANGE_MAIN_PASSWORD_SUCCESS",
  CHANGE_MAIN_PASSWORD_FAILURE: "CHANGE_MAIN_PASSWORD_FAILURE",

  IBUILD_ADMIN_LOGOUT_INITIATE: "IBUILD_ADMIN_LOGOUT_INITIATE",
  IBUILD_ADMIN_LOGOUT_SUCCESS: "IBUILD_ADMIN_LOGOUT_SUCCESS",
  IBUILD_ADMIN_LOGOUT_FAILURE: "IBUILD_ADMIN_LOGOUT_FAILURE",

  IBUILD_ADMIN_DASHBOARD_INITITATE: "IBUILD_ADMIN_DASHBOARD_INITITATE",
  IBUILD_ADMIN_DASHBOARD_SUCCESS: "IBUILD_ADMIN_DASHBOARD_SUCCESS",
  IBUILD_ADMIN_DASHBOARD_FAILURE: "IBUILD_ADMIN_DASHBOARD_FAILURE",

  IBUILD_SIGNUP_INITIATE: "IBUILD_SIGNUP_INITIATE",
  IBUILD_SIGNUP_SUCCESS: "IBUILD_SIGNUP_SUCCESS",
  IBUILD_SIGNUP_FAILURE: "IBUILD_SIGNUP_FAILURE",

  SUBDIVISION_LIST_INITIATE: "SUBDIVISION_LIST_INITIATE",
  SUBDIVISION_LIST_SUCCESS: "SUBDIVISION_LIST_SUCCESS",
  SUBDIVISION_LIST_FAILURE: "SUBDIVISION_LIST_FAILURE",

  BUILDING_ADMIN_LOGIN_INITIATE: "BUILDING_ADMIN_LOGIN_INITIATE",
  BUILDING_ADMIN_LOGIN_SUCCESS: "BUILDING_ADMIN_LOGIN_SUCCESS",
  BUILDING_ADMIN_LOGIN_FAILURE: "BUILDING_ADMIN_LOGIN_FAILURE",

  BUILDING_MULTI_PROJECT_LOGIN_INITIATE:
    "BUILDING_MULTI_PROJECT_LOGIN_INITIATE",
  BUILDING_MULTI_PROJECT_LOGIN_SUCCESS: "BUILDING_MULTI_PROJECT_LOGIN_SUCCESS",
  BUILDING_MULTI_PROJECT_LOGIN_FAILURE: "BUILDING_MULTI_PROJECT_LOGIN_FAILURE",

  BUILDING_SINGLE_PROJECT_LOGIN_INITIATE:
    "BUILDING_SINGLE_PROJECT_LOGIN_INITIATE",
  BUILDING_SINGLE_PROJECT_LOGIN_SUCCESS:
    "BUILDING_SINGLE_PROJECT_LOGIN_SUCCESS",
  BUILDING_SINGLE_PROJECT_LOGIN_FAILURE:
    "BUILDING_SINGLE_PROJECT_LOGIN_FAILURE",

  SUBSCRIPTION_PLAN_LIST_INITIATE: "SUBSCRIPTION_PLAN_LIST_INITIATE",
  SUBSCRIPTION_PLAN_LIST_SUCCESS: "SUBSCRIPTION_PLAN_LIST_SUCCESS",
  SUBSCRIPTION_PLAN_LIST_FAILURE: "SUBSCRIPTION_PLAN_LIST_FAILURE",

  SUBSCRIPTION_PLAN_DETAILS_INITIATE: "SUBSCRIPTION_PLAN_DETAILS_INITIATE",
  SUBSCRIPTION_PLAN_DETAILS_SUCCESS: "SUBSCRIPTION_PLAN_DETAILS_SUCCESS",
  SUBSCRIPTION_PLAN_DETAILS_FAILURE: "SUBSCRIPTION_PLAN_DETAILS_FAILURE",

  ADD_SUBSCRIPTION_INITIATE: "ADD_SUBSCRIPTION_INITIATE",
  ADD_SUBSCRIPTION_SUCCESS: "ADD_SUBSCRIPTION_SUCCESS",
  ADD_SUBSCRIPTION_FAILURE: "ADD_SUBSCRIPTION_FAILURE",

  GET_COUNTRIES_LIST_INITIATE: "GET_COUNTRIES_LIST_INITIATE",
  GET_COUNTRIES_LIST_SUCCESS: "GET_COUNTRIES_LIST_SUCCESS",
  GET_COUNTRIES_LIST_FAILURE: "GET_COUNTRIES_LIST_FAILURE",

  GET_CITIES_LIST_INITIATE: "GET_CITIES_LIST_INITIATE",
  GET_CITIES_LIST_SUCCESS: "GET_CITIES_LIST_SUCCESS",
  GET_CITIES_LIST_FAILURE: "GET_CITIES_LIST_FAILURE",

  GET_BLOCK_LIST_INITIATE: "GET_BLOCK_LIST_INITIATE",
  GET_BLOCK_LIST_SUCCESS: "GET_BLOCK_LIST_SUCCESS",
  GET_BLOCK_LIST_FAILURE: "GET_BLOCK_LIST_FAILURE",

  GET_SPECIFICATION_DOCS_LIST_INITIATE: "GET_SPECIFICATION_DOCS_LIST_INITIATE",
  GET_SPECIFICATION_DOCS_LIST_SUCCESS: "GET_SPECIFICATION_DOCS_LIST_SUCCESS",
  GET_SPECIFICATION_DOCS_LIST_FAILURE: "GET_SPECIFICATION_DOCS_LIST_FAILURE",

  GET_STATE_LIST_INITIATE: "GET_STATE_LIST_INITIATE",
  GET_STATE_LIST_SUCCESS: "GET_STATE_LIST_SUCCESS",
  GET_STATE_LIST_FAILURE: "GET_STATE_LIST_FAILURE",

  UPLOAD_BULK_VENDOR_INITIATE: "UPLOAD_BULK_VENDOR_INITIATE",
  UPLOAD_BULK_VENDOR_SUCCESS: "UPLOAD_BULK_VENDOR_SUCCESS",
  UPLOAD_BULK_VENDOR_FAILUREE: "UPLOAD_BULK_VENDOR_FAILUREE",

  TIMEZONE_LIST_INITIATE: "TIMEZONE_LIST_INITIATE",
  TIMEZONE_LIST_SUCCESS: "TIMEZONE_LIST_SUCCESS",
  TIMEZONE_LIST_FAILURE: "TIMEZONE_LIST_FAILURE",

  UPDATE_PASSWORD_INITIATE: "UPDATE_PASSWORD_INITIATE",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",

  PASSWORD_RESET_INITIATE: "PASSWORD_RESET_INITIATE",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",

  HEADER_NOTIFICATION_LIST_INITIATE: "HEADER_NOTIFICATION_LIST_INITIATE",
  HEADER_NOTIFICATION_LIST_SUCCESS: "HEADER_NOTIFICATION_LIST_SUCCESS",
  HEADER_NOTIFICATION_LIST_FAILURE: "HEADER_NOTIFICATION_LIST_FAILURE",

  REMOVE_HEADER_NOTIFICATION_LIST_INITIATE:
    "REMOVE_HEADER_NOTIFICATION_LIST_INITIATE",
  REMOVE_HEADER_NOTIFICATION_LIST_SUCCESS:
    "REMOVE_HEADER_NOTIFICATION_LIST_SUCCESS",
  REMOVE_HEADER_NOTIFICATION_LIST_FAILURE:
    "REMOVE_HEADER_NOTIFICATION_LIST_FAILURE",

  COMMON_COST_CODE_LIST_INITIATE: "COMMON_COST_CODE_LIST_INITIATE",
  COMMON_COST_CODE_LIST_SUCCESS: "COMMON_COST_CODE_LIST_SUCCESS",
  COMMON_COST_CODE_LIST_FAILURE: "COMMON_COST_CODE_LIST_FAILURE",

  COMMON_COST_CATEGORY_LIST_INITIATE: "COMMON_COST_CATEGORY_LIST_INITIATE",
  COMMON_COST_CATEGORY_LIST_SUCCESS: "COMMON_COST_CATEGORY_LIST_SUCCESS",
  COMMON_COST_CATEGORY_LIST_FAILURE: "COMMON_COST_CATEGORY_LIST_FAILURE",

  MESSAGE_LIST_INITIATE: "MESSAGE_LIST_INITIATE",
  MESSAGE_LIST_SUCCESS: "MESSAGE_LIST_SUCCESS",
  MESSAGE_LIST_FAILURE: "MESSAGE_LIST_FAILURE",

  GET_ALL_COSTCATEGORY_INITIATE: "GET_ALL_COSTCATEGORY_INITIATE",
  GET_ALL_COSTCATEGORY_SUCCESS: "GET_ALL_COSTCATEGORY_SUCCESS",
  GET_ALL_COSTCATEGORY_FAILURE: "GET_ALL_COSTCATEGORY_FAILURE",

  GET_ALL_COSTCODE_INITIATE: "GET_ALL_COSTCODE_INITIATE",
  GET_ALL_COSTCODE_SUCCESS: "GET_ALL_COSTCODE_SUCCESS",
  GET_ALL_COSTCODE_FAILURE: "GET_ALL_COSTCODE_FAILURE",

  GET_PROJECT_BY_ID_INITIATE:"GET_PROJECT_BY_ID_INITIATE",
  GET_PROJECT_BY_ID_SUCCESS: "GET_PROJECT_BY_ID_SUCCESS",
  GET_PROJECT_BY_ID_FAILURE: "GET_PROJECT_BY_ID_FAILURE",
};

export const constructActionTypes = {
  ADD_PROJECT_INITIATE: "ADD_PROJECT_INITIATE",
  ADD_PROJECT_SUCCESS: "ADD_PROJECT_SUCCESS",
  ADD_PROJECT_FAILURE: "ADD_PROJECT_FAILURE",

  // PROJECT_LIST_INITIATE: "PROJECT_LIST_INITIATE",
  // PROJECT_LIST_SUCCESS: "PROJECT_LIST_SUCCESS",
  // PROJECT_LIST_FAILURE: "PROJECT_LIST_FAILURE",
};

export const vendorActionTypes = {
  ADD_VENDOR_INITIATE: "ADD_VENDOR_INITIATE",
  ADD_VENDOR_SUCCESS: "ADD_VENDOR_SUCCESS",
  ADD_VENDOR_FAILURE: "ADD_VENDOR_FAILURE",

  ADD_ADDRESS_INITIATE: "ADD_ADDRESS_INITIATE",
  ADD_ADDRESS_SUCCESS: "ADD_ADDRESS_SUCCESS",
  ADD_ADDRESS_FAILURE: "ADD_ADDRESS_FAILURE",

  ADDRESS_LIST_INITIATE: "ADDRESS_LIST_INITIATE",
  ADDRESS_LIST_SUCCESS: "ADDRESS_LIST_SUCCESS",
  ADDRESS_LIST_FAILURE: "ADDRESS_LIST_FAILURE",

  VENDOR_LIST_INITIATE: "VENDOR_LIST_INITIATE",
  VENDOR_LIST_SUCCESS: "VENDOR_LIST_SUCCESS",
  VENDOR_LIST_FAILURE: "VENDOR_LIST_FAILURE",

  UPDATE_VENDOR_INITIATE: "UPDATE_VENDOR_INITIATE",
  UPDATE_VENDOR_SUCCESS: "UPDATE_VENDOR_SUCCESS",
  UPDATE_VENDOR_FAILURE: "UPDATE_VENDOR_FAILURE",

  DELETE_VENDOR_INITIATE: "DELETE_VENDOR_INITIATE",
  DELETE_VENDOR_SUCCESS: "DELETE_VENDOR_SUCCESS",
  DELETE_VENDOR_FAILURE: "DELETE_VENDOR_FAILURE",

  VENDOR_TYPES_INITIATE: "VENDOR_TYPES_INITIATE",
  VENDOR_TYPES_SUCCESS: "VENDOR_TYPES_SUCCESS",
  VENDOR_TYPES_FAILURE: "VENDOR_TYPES_FAILURE",

  VENDOR_DETAILS_INITIATE: "VENDOR_DETAILS_INITIATE",
  VENDOR_DETAILS_SUCCESS: "VENDOR_DETAILS_SUCCESS",
  VENDOR_DETAILS_FAILURE: "VENDOR_DETAILS_FAILURE",

  GET_VENDOR_INITIATE: "GET_VENDOR_INITIATE",
  GET_VENDOR_SUCCESS: "GET_VENDOR_SUCCESS",
  GET_VENDOR_FAILURE: "GET_VENDOR_FAILURE",

  VENDOR_SEARCH_INITIATE: "VENDOR_SEARCH_INITIATE",
  VENDOR_SEARCH_SUCCESS: "VENDOR_SEARCH_SUCCESS",
  VENDOR_SEARCH_FAILURE: "VENDOR_SEARCH_FAILURE",

  ADD_CONTACT_INITIATE: "ADD_CONTACT_INITIATE",
  ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
  ADD_CONTACT_FAILURE: "ADD_CONTACT_FAILURE",

  CONTACT_LIST_INITIATE: "CONTACT_LIST_INITIATE",
  CONTACT_LIST_SUCCESS: "CONTACT_LIST_SUCCESS",
  CONTACT_LIST_FAILURE: "CONTACT_LIST_FAILURE",

  DELETE_CONTACT_INITIATE: "DELETE_CONTACT_INITIATE",
  DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",
  DELETE_CONTACT_FAILURE: "DELETE_CONTACT_FAILURE",

  UPDATE_CONTACT_INITIATE: "UPDATE_CONTACT_INITIATE",
  UPDATE_CONTACT_SUCCESS: "UPDATE_CONTACT_SUCCESS",
  UPDATE_CONTACT_FAILURE: "UPDATE_CONTACT_FAILURE",

  ADD_PREFFERED_COST_INITIATE: "ADD_PREFFERED_COST_INITIATE",
  ADD_PREFFERED_COST_SUCCESS: "ADD_PREFFERED_COST_SUCCESS",
  ADD_PREFFERED_COST_FAILURE: "ADD_PREFFERED_COST_FAILURE",

  PREFFERED_COST_LIST_INITIATE: "PREFFERED_COST_LIST_INITIATE",
  PREFFERED_COST_LIST_SUCCESS: "PREFFERED_COST_LIST_SUCCESS",
  PREFFERED_COST_LIST_FAILURE: "PREFFERED_COST_LIST_FAILURE",

  DELETE_PREFFERED_COST_INITIATE: "DELETE_PREFFERED_COST_INITIATE",
  DELETE_PREFFERED_COST_SUCCESS: "DELETE_PREFFERED_COST_SUCCESS",
  DELETE_PREFFERED_COST_FAILURE: "DELETE_PREFFERED_COST_FAILURE",

  UPDATE_PREFFERED_COST_INITIATE: "UPDATE_PREFFERED_COST_INITIATE",
  UPDATE_PREFFERED_COST_SUCCESS: "UPDATE_PREFFERED_COST_SUCCESS",
  UPDATE_PREFFERED_COST_FAILURE: "UPDATE_PREFFERED_COST_FAILURE",

  PREFFERED_COST_DETAILS_INITIATE: "PREFFERED_COST_DETAILS_INITIATE",
  PREFFERED_COST_DETAILS_SUCCESS: "PREFFERED_COST_DETAILS_SUCCESS",
  PREFFERED_COST_DETAILS_FAILURE: "PREFFERED_COST_DETAILS_FAILURE",

  CONTACT_DETAILS_INITIATE: "CONTACT_DETAILS_INITIATE",
  CONTACT_DETAILS_SUCCESS: "CONTACT_DETAILS_SUCCESS",
  CONTACT_DETAILS_FAILURE: "CONTACT_DETAILS_FAILURE",

  BULK_UPLOAD_VENDOR_INITIATE: "BULK_UPLOAD_VENDOR_INITIATE",
  BULK_UPLOAD_VENDOR_SUCCESS: "BULK_UPLOAD_VENDOR_SUCCESS",
  BULK_UPLOAD_VENDOR_FAILURE: "BULK_UPLOAD_VENDOR_FAILURE",

  BULK_UPLOAD_TEMPLATE_INITIATE: "BULK_UPLOAD_TEMPLATE_INITIATE",
  BULK_UPLOAD_TEMPLATE_SUCCESS: "BULK_UPLOAD_TEMPLATE_SUCCESS",
  BULK_UPLOAD_TEMPLATE_FAILURE: "BULK_UPLOAD_TEMPLATE_FAILURE",

  SET_VENDOR_PREFERRED_INITIATE: "SET_VENDOR_PREFERRED_INITIATE",
  SET_VENDOR_PREFERRED_SUCCESS: "SET_VENDOR_PREFERRED_SUCCESS",
  SET_VENDOR_PREFERRED_FAILURE: "SET_VENDOR_PREFERRED_FAILURE",
};

export const saleActionTypes = {
  CREATE_LEAD_INITIATE: "CREATE_LEAD_INITIATE",
  CREATE_LEAD_SUCCESS: "CREATE_LEAD_SUCCESS",
  CREATE_LEAD_FAILURE: "CREATE_LEAD_FAILURE",

  IMPORT_CONTACT_INITIATE: "IMPORT_CONTACT_INITIATE",
  IMPORT_CONTACT_SUCCESS: "IMPORT_CONTACT_SUCCESS",
  IMPORT_CONTACT_FAILURE: "IMPORT_CONTACT_FAILURE",

  GET_ALL_LEADS_INITIATE: "GET_ALL_LEADS_INITIATE",
  GET_ALL_LEADS_SUCCESS: "GET_ALL_LEADS_SUCCESS",
  GET_ALL_LEADS_FAILURE: "GET_ALL_LEADS_FAILURE",

  CONVERT_TO_CONTACT_INITIATE: "CONVERT_TO_CONTACT_INITIATE",
  CONVERT_TO_CONTACT_SUCCESS: "CONVERT_TO_CONTACT_SUCCESS",
  CONVERT_TO_CONTACT_FAILURE: "CONVERT_TO_CONTACT_FAILURE",

  LEAD_DETAILS_INITIATE: "LEAD_DETAILS_INITIATE",
  LEAD_DETAILS_SUCCESS: "LEAD_DETAILS_SUCCESS",
  LEAD_DETAILS_FAILURE: "LEAD_DETAILS_FAILURE",

  UPDATE_LEAD_INITIATE: "UPDATE_LEAD_INITIATE",
  UPDATE_LEAD_SUCCESS: "UPDATE_LEAD_SUCCESS",
  UPDATE_LEAD_FAILURE: "UPDATE_LEAD_FAILURE",

  UPDATE_CONTACT_INITIATE: "UPDATE_CONTACT_INITIATE",
  UPDATE_CONTACT_SUCCESS: "UPDATE_CONTACT_SUCCESS",
  UPDATE_CONTACT_FAILURE: "UPDATE_CONTACT_FAILURE",

  DELETE_LEAD_INITIATE: "DELETE_LEAD_INITIATE",
  DELETE_LEAD_SUCCESS: "DELETE_LEAD_SUCCESS",
  DELETE_LEAD_FAILURE: "DELETE_LEAD_FAILURE",

  ADD_SUPPORTING_DOCS_INITIATE: "ADD_SUPPORTING_DOCS_INITIATE",
  ADD_SUPPORTING_DOCS_SUCCESS: "ADD_SUPPORTING_DOCS_SUCCESS",
  ADD_SUPPORTING_DOCS_FAILURE: "ADD_SUPPORTING_DOCS_FAILURE",

  SUPPORTING_DOCS_LIST_INITIATE: "SUPPORTING_DOCS_LIST_INITIATE",
  SUPPORTING_DOCS_LIST_SUCCESS: "SUPPORTING_DOCS_LIST_SUCCESS",
  SUPPORTING_DOCS_LIST_FAILURE: "SUPPORTING_DOCS_LIST_FAILURE",

  UPDATE_SUPPORTING_DOCS_INITIATE: "UPDATE_SUPPORTING_DOCS_INITIATE",
  UPDATE_SUPPORTING_DOCS_SUCCESS: "UPDATE_SUPPORTING_DOCS_SUCCESS",
  UPDATE_SUPPORTING_DOCS_FAILURE: "UPDATE_SUPPORTING_DOCS_FAILURE",

  SUPPORTING_DOCS_DETAILS_INITIATE: "SUPPORTING_DOCS_DETAILS_INITIATE",
  SUPPORTING_DOCS_DETAILS_SUCCESS: "SUPPORTING_DOCS_DETAILS_SUCCESS",
  SUPPORTING_DOCS_DETAILS_FAILURE: "SUPPORTING_DOCS_DETAILS_FAILURE",

  DELETE_SUPPORTING_DOCS_INITIATE: "DELETE_SUPPORTING_DOCS_INITIATE",
  DELETE_SUPPORTING_DOCS_SUCCESS: "DELETE_SUPPORTING_DOCS_SUCCESS",
  DELETE_SUPPORTING_DOCS_FAILURE: "DELETE_SUPPORTING_DOCS_FAILURE",

  // DISCUSSION

  ADD_COMMENT_INITIATE: "ADD_COMMENT_INITIATE",
  ADD_COMMENT_SUCCESS: "ADD_COMMENT_SUCCESS",
  ADD_COMMENT_FAILURE: "ADD_COMMENT_FAILURE",

  COMMENT_LIST_INITIATE: "COMMENT_LIST_INITIATE",
  COMMENT_LIST_SUCCESS: "COMMENT_LIST_SUCCESS",
  COMMENT_LIST_FAILURE: "COMMENT_LIST_FAILURE",

  ADD_SUB_COMMENT_INITIATE: "ADD_SUB_COMMENT_INITIATE",
  ADD_SUB_COMMENT_SUCCESS: "ADD_SUB_COMMENT_SUCCESS",
  ADD_SUB_COMMENT_FAILURE: "ADD_SUB_COMMENT_FAILURE",

  SUB_COMMENT_LIST_INITIATE: "SUB_COMMENT_LIST_INITIATE",
  SUB_COMMENT_LIST_SUCCESS: "SUB_COMMENT_LIST_SUCCESS",
  SUB_COMMENT_LIST_FAILURE: "SUB_COMMENT_LIST_FAILURE",

  // CONTRACT

  ADD_CONTRACT_INITIATE: "ADD_CONTRACT_INITIATE",
  ADD_CONTRACT_SUCCESS: "ADD_CONTRACT_SUCCESS",
  ADD_CONTRACT_FAILURE: "ADD_CONTRACT_FAILURE",

  GET_ALL_CONTRACTS_INITIATE: "GET_ALL_CONTRACTS_INITIATE",
  GET_ALL_CONTRACTS_SUCCESS: "GET_ALL_CONTRACTS_SUCCESS",
  GET_ALL_CONTRACTS_FAILURE: "GET_ALL_CONTRACTS_FAILURE",

  GET_CONTRACTS_DETAILS_INITIATE: "GET_CONTRACTS_DETAILS_INITIATE",
  GET_CONTRACTS_DETAILS_SUCCESS: "GET_CONTRACTS_DETAILS_SUCCESS",
  GET_CONTRACTS_DETAILS_FAILURE: "GET_CONTRACTS_DETAILS_FAILURE",

  DELETE_CONTRACT_INITIATE: "DELETE_CONTRACT_INITIATE",
  DELETE_CONTRACT_SUCCESS: "DELETE_CONTRACT_SUCCESS",
  DELETE_CONTRACT_FAILURE: "DELETE_CONTRACT_FAILURE",

  UPDATE_CONTRACT_INITIATE: "UPDATE_CONTRACT_INITIATE",
  UPDATE_CONTRACT_SUCCESS: "UPDATE_CONTRACT_SUCCESS",
  UPDATE_CONTRACT_FAILURE: "UPDATE_CONTRACT_FAILURE",

  UPDATE_CONTRACT_GENERAL_INITIATE: "UPDATE_CONTRACT_GENERAL_INITIATE",
  UPDATE_CONTRACT_GENERAL_SUCCESS: "UPDATE_CONTRACT_GENERAL_SUCCESS",
  UPDATE_CONTRACT_GENERAL_FAILURE: "UPDATE_CONTRACT_GENERAL_FAILURE",

  UPDATE_CONTRACT_CONDITIONALS_INITIATE:
    "UPDATE_CONTRACT_CONDITIONALS_INITIATE",
  UPDATE_CONTRACT_CONDITIONALS_SUCCESS: "UPDATE_CONTRACT_CONDITIONALS_SUCCESS",
  UPDATE_CONTRACT_CONDITIONALS_FAILURE: "UPDATE_CONTRACT_CONDITIONALS_FAILURE",

  UPDATE_CONTRACT_ASSIGN_INITIATE: "UPDATE_CONTRACT_ASSIGN_INITIATE",
  UPDATE_CONTRACT_ASSIGN_SUCCESS: "UPDATE_CONTRACT_ASSIGN_SUCCESS",
  UPDATE_CONTRACT_ASSIGN_FAILURE: "UPDATE_CONTRACT_ASSIGN_FAILURE",

  REMVOE_ASSIGNED_INITIATE: "REMVOE_ASSIGNED_INITIATE",
  REMVOE_ASSIGNED_SUCCESS: "REMVOE_ASSIGNED_SUCCESS",
  REMVOE_ASSIGNED_FAILURE: "REMVOE_ASSIGNED_FAILURE",

  UPDATE_CONTRACT_CONTACT_INITIATE: "UPDATE_CONTRACT_CONTACT_INITIATE",
  UPDATE_CONTRACT_CONTACT_SUCCESS: "UPDATE_CONTRACT_CONTACT_SUCCESS",
  UPDATE_CONTRACT_CONTACT_FAILURE: "UPDATE_CONTRACT_CONTACT_FAILURE",

  // STAFF

  GET_STAFF_LIST_INITIATE: "GET_STAFF_LIST_INITIATE",
  GET_STAFF_LIST_SUCCESS: "GET_STAFF_LIST_SUCCESS",
  GET_STAFF_LIST_FAILURE: "GET_STAFF_LIST_FAILURE",

  GET_STAFF_DETAILS_INITIATE: "GET_STAFF_DETAILS_INITIATE",
  GET_STAFF_DETAILS_SUCCESS: "GET_STAFF_DETAILS_SUCCESS",
  GET_STAFF_DETAILS_FAILURE: "GET_STAFF_DETAILS_FAILURE",

  ADD_FOLLOWUP_INITIATE: "ADD_FOLLOWUP_INITIATE",
  ADD_FOLLOWUP_SUCCESS: "ADD_FOLLOWUP_SUCCESS",
  ADD_FOLLOWUP_FAILURE: "ADD_FOLLOWUP_FAILURE",

  GET_FOLLOWUP_LIST_INITIATE: "GET_FOLLOWUP_LIST_INITIATE",
  GET_FOLLOWUP_LIST_SUCCESS: "GET_FOLLOWUP_LIST_SUCCESS",
  GET_FOLLOWUP_LIST_FAILURE: "GET_FOLLOWUP_LIST_FAILURE",

  CANCEL_FOLLOWUP_LIST_INITIATE: "CANCEL_FOLLOWUP_LIST_INITIATE",
  CANCEL_FOLLOWUP_LIST_SUCCESS: "CANCEL_FOLLOWUP_LIST_SUCCESS",
  CANCEL_FOLLOWUP_LIST_FAILURE: "CANCEL_FOLLOWUP_LIST_FAILURE",

  GET_FOLLOWUP_DETAILS_INITIATE: "GET_FOLLOWUP_DETAILS_INITIATE",
  GET_FOLLOWUP_DETAILS_SUCCESS: "GET_FOLLOWUP_DETAILS_SUCCESS",
  GET_FOLLOWUP_DETAILS_FAILURE: "GET_FOLLOWUP_DETAILS_FAILURE",

  SEND_FOLLOWUP_INVITATION_INITIATE: "SEND_FOLLOWUP_INVITATION_INITIATE",
  SEND_FOLLOWUP_INVITATION_SUCCESS: "SEND_FOLLOWUP_INVITATION_SUCCESS",
  SEND_FOLLOWUP_INVITATION_FAILURE: "SEND_FOLLOWUP_INVITATION_FAILURE",

  UPDATE_FOLLOWUP_INITIATE: "UPDATE_FOLLOWUP_INITIATE",
  UPDATE_FOLLOWUP_SUCCESS: "UPDATE_FOLLOWUP_SUCCESS",
  UPDATE_FOLLOWUP_FAILURE: "UPDATE_FOLLOWUP_FAILURE",

  SET_REMINDER_INITIATE: "SET_REMINDER_INITIATE",
  SET_REMINDER_SUCCESS: "SET_REMINDER_SUCCESS",
  SET_REMINDER_FAILURE: "SET_REMINDER_FAILURE",

  UPDATE_SURVEY_INITIATE: "UPDATE_SURVEY_INITIATE",
  UPDATE_SURVEY_SUCCESS: "UPDATE_SURVEY_SUCCESS",
  UPDATE_SURVEY_FAILURE: "UPDATE_SURVEY_FAILURE",

  SURVEY_DETAILS_INITIATE: "SURVEY_DETAILS_INITIATE",
  SURVEY_DETAILS_SUCCESS: "SURVEY_DETAILS_SUCCESS",
  SURVEY_DETAILS_FAILURE: "SURVEY_DETAILS_FAILURE",

  ADD_SCHEDULE_PAYMENTS_INITIATE: "ADD_SCHEDULE_PAYMENTS_INITIATE",
  ADD_SCHEDULE_PAYMENTS_SUCCESS: "ADD_SCHEDULE_PAYMENTS_SUCCESS",
  ADD_SCHEDULE_PAYMENTS_FAILURE: "ADD_SCHEDULE_PAYMENTS_FAILURE",

  ADD_SCHEDULE_PAYMENTS_TRANSACATION_INITIATE:
    "ADD_SCHEDULE_PAYMENTS_TRANSACATION_INITIATE",
  ADD_SCHEDULE_PAYMENTS_TRANSACATION_SUCCESS:
    "ADD_SCHEDULE_PAYMENTS_TRANSACATION_SUCCESS",
  ADD_SCHEDULE_PAYMENTS_TRANSACATION_FAILURE:
    "ADD_SCHEDULE_PAYMENTS_TRANSACATION_FAILURE",

  GET_ALL_SCHEDULE_PAYMENTS_TRANSACATION_INITIATE:
    "GET_ALL_SCHEDULE_PAYMENTS_TRANSACATION_INITIATE",
  GET_ALL_SCHEDULE_PAYMENTS_TRANSACATION_SUCCESS:
    "GET_ALL_SCHEDULE_PAYMENTS_TRANSACATION_SUCCESS",
  GET_ALL_SCHEDULE_PAYMENTS_TRANSACATION_FAILURE:
    "GET_ALL_SCHEDULE_PAYMENTS_TRANSACATION_FAILURE",

  GET_DETAILS_SCHEDULE_PAYMENTS_TRANSACATION_INITIATE:
    "GET_DETAILS_SCHEDULE_PAYMENTS_TRANSACATION_INITIATE",
  GET_DETAILS_SCHEDULE_PAYMENTS_TRANSACATION_SUCCESS:
    "GET_DETAILS_SCHEDULE_PAYMENTS_TRANSACATION_SUCCESS",
  GET_DETAILS_SCHEDULE_PAYMENTS_TRANSACATION_FAILURE:
    "GET_DETAILS_SCHEDULE_PAYMENTS_TRANSACATION_FAILURE",

  UPDATE_DETAILS_SCHEDULE_PAYMENTS_TRANSACATION_INITIATE:
    "UPDATE_DETAILS_SCHEDULE_PAYMENTS_TRANSACATION_INITIATE",
  UPDATE_DETAILS_SCHEDULE_PAYMENTS_TRANSACATION_SUCCESS:
    "UPDATE_DETAILS_SCHEDULE_PAYMENTS_TRANSACATION_SUCCESS",
  UPDATE_DETAILS_SCHEDULE_PAYMENTS_TRANSACATION_FAILURE:
    "UPDATE_DETAILS_SCHEDULE_PAYMENTS_TRANSACATION_FAILURE",

  GET_ALL_SCHEDULE_INITIATE: "GET_ALL_SCHEDULE_INITIATE",
  GET_ALL_SCHEDULE_SUCCESS: "GET_ALL_SCHEDULE_SUCCESS",
  GET_ALL_SCHEDULE_FAILURE: "GET_ALL_SCHEDULE_FAILURE",

  UPDATE_SCHEDULE_PAYMENT_INITIATE: "UPDATE_SCHEDULE_PAYMENT_INITIATE",
  UPDATE_SCHEDULE_PAYMENT_SUCCESS: "UPDATE_SCHEDULE_PAYMENT_SUCCESS",
  UPDATE_SCHEDULE_PAYMENT_FAILURE: "UPDATE_SCHEDULE_PAYMENT_FAILURE",

  GET_SCHEDULE_DETAILS_INITIATE: "GET_SCHEDULE_DETAILS_INITIATE",
  GET_SCHEDULE_DETAILS_SUCCESS: "GET_SCHEDULE_DETAILS_SUCCESS",
  GET_SCHEDULE_DETAILS_FAILURE: "GET_SCHEDULE_DETAILS_FAILURE",

  SALE_TYPE_INITIATE: "SALE_TYPE_INITIATE",
  SALE_TYPE_SUCCESS: "SALE_TYPE_SUCCESS",
  SALE_TYPE_FAILURE: "SALE_TYPE_FAILURE",

  CLIENT_ASSIGNED_INITIATE: "CLIENT_ASSIGNED_INITIATE",
  CLIENT_ASSIGNED_SUCCESS: "CLIENT_ASSIGNED_SUCCESS",
  CLIENT_ASSIGNED_FAILURE: "CLIENT_ASSIGNED_FAILURE",

  GET_ALL_CLIENTS_INITIATE: "GET_ALL_CLIENTS_INITIATE",
  GET_ALL_CLIENTS_SUCCESS: "GET_ALL_CLIENTS_SUCCESS",
  GET_ALL_CLIENTS_FAILURE: "GET_ALL_CLIENTS_FAILURE",

  UPDATE_ASSIGN_UNIT_INITIATE: "UPDATE_ASSIGN_UNIT_INITIATE",
  UPDATE_ASSIGN_UNIT_SUCCESS: "UPDATE_ASSIGN_UNIT_SUCCESS",
  UPDATE_ASSIGN_UNIT_FAILURE: "UPDATE_ASSIGN_UNIT_FAILURE",

  ADD_PARKING_INITIATE: "ADD_PARKING_INITIATE",
  ADD_PARKING_SUCCESS: "ADD_PARKING_SUCCESS",
  ADD_PARKING_FAILURE: "ADD_PARKING_FAILURE",
  TEMPLATE_RELEASE_LIST_INITIATE: "TEMPLATE_RELEASE_LIST_INITIATE",
  TEMPLATE_RELEASE_LIST_SUCCESS: "TEMPLATE_RELEASE_LIST_SUCCESS",
  TEMPLATE_RELEASE_LIST_FAILURE: "TEMPLATE_RELEASE_LIST_FAILURE",

  DELETE_DRAFT_TEMPLATE_INITIATE: "DELETE_DRAFT_TEMPLATE_INITIATE",
  DELETE_DRAFT_TEMPLATE_SUCCESS: "DELETE_DRAFT_TEMPLATE_SUCCESS",
  DELETE_DRAFT_TEMPLATE_FAILURE: "DELETE_DRAFT_TEMPLATE_FAILURE",

  GET_CLIENT_CTC_GENERATE_HISTORY_INITIATE:
    "GET_CLIENT_CTC_GENERATE_HISTORY_INITIATE",
  GET_CLIENT_CTC_GENERATE_HISTORY_SUCCESS:
    "GET_CLIENT_CTC_GENERATE_HISTORY_SUCCESS",
  GET_CLIENT_CTC_GENERATE_HISTORY_FAILURE:
    "GET_CLIENT_CTC_GENERATE_HISTORY_FAILURE",

  GET_CLIENT_CTC_BY_TEMPLATE_INITIATE: "GET_CLIENT_CTC_BY_TEMPLATE_INITIATE",
  GET_CLIENT_CTC_BY_TEMPLATE_SUCCESS: "GET_CLIENT_CTC_BY_TEMPLATE_SUCCESS",
  GET_CLIENT_CTC_BY_TEMPLATE_FAILURE: "GET_CLIENT_CTC_BY_TEMPLATE_FAILURE",

  GET_COST_BREAKDOWN_INITIATE: "GET_COST_BREAKDOWN_INITIATE",
  GET_COST_BREAKDOWN_SUCCESS: "GET_COST_BREAKDOWN_SUCCESS",
  GET_COST_BREAKDOWN_FAILURE: "GET_COST_BREAKDOWN_FAILURE",

  GET_MARGIN_BREAKDOWN_INITIATE: "GET_MARGIN_BREAKDOWN_INITIATE",
  GET_MARGIN_BREAKDOWN_SUCCESS: "GET_MARGIN_BREAKDOWN_SUCCESS",
  GET_MARGIN_BREAKDOWN_FAILURE: "GET_MARGIN_BREAKDOWN_FAILURE",

  CREATE_CLIENT_DRAFT_INITIATE: "CREATE_CLIENT_DRAFT_INITIATE",
  CREATE_CLIENT_DRAFT_SUCCESS: "CREATE_CLIENT_DRAFT_SUCCESS",
  CREATE_CLIENT_DRAFT_FAILURE: "CREATE_CLIENT_DRAFT_FAILURE",

  SEND_FOR_CLIENT_SIGNATURES_INITIATE: "SEND_FOR_CLIENT_SIGNATURES_INITIATE",
  SEND_FOR_CLIENT_SIGNATURES_SUCCESS: "SEND_FOR_CLIENT_SIGNATURES_SUCCESS",
  SEND_FOR_CLIENT_SIGNATURES_FAILURE: "SEND_FOR_CLIENT_SIGNATURES_FAILURE",

  GET_PARKING_LIST_INITIATE: "GET_PARKING_LIST_INITIATE",
  GET_PARKING_LIST_SUCCESS: "GET_PARKING_LIST_SUCCESS",
  GET_PARKING_LIST_FAILURE: "GET_PARKING_LIST_FAILURE",

  CANCEL_PARKING_LIST_INITIATE: "CANCEL_PARKING_LIST_INITIATE",
  CANCEL_PARKING_LIST_SUCCESS: "CANCEL_PARKING_LIST_SUCCESS",
  CANCEL_PARKING_LIST_FAILURE: "CANCEL_PARKING_LIST_FAILURE",

  UPDATE_CONDITIONAL_INITIATE: "UPDATE_CONDITIONAL_INITIATE",
  UPDATE_CONDITIONAL_SUCCESS: "UPDATE_CONDITIONAL_SUCCESS",
  UPDATE_CONDITIONAL_FAILURE: "UPDATE_CONDITIONAL_FAILURE",

  GET_SOA_DETAILS_INITIATE: "GET_SOA_DETAILS_INITIATE",
  GET_SOA_DETAILS_SUCCESS: "GET_SOA_DETAILS_SUCCESS",
  GET_SOA_DETAILS_FAILURE: "GET_SOA_DETAILS_FAILURE",

  ADD_SOA_INITIATE: "ADD_SOA_INITIATE",
  ADD_SOA_SUCCESS: "ADD_SOA_SUCCESS",
  ADD_SOA_FAILURE: "ADD_SOA_FAILURE",

  SOA_HISTORY_INITIATE: "SOA_HISTORY_INITIATE",
  SOA_HISTORY_SUCCESS: "SOA_HISTORY_SUCCESS",
  SOA_HISTORY_FAILURE: "SOA_HISTORY_FAILURE",

  ADD_UNLOCK_CONDITION_INITIATE: "ADD_UNLOCK_CONDITION_INITIATE",
  ADD_UNLOCK_CONDITION_SUCCESS: "ADD_UNLOCK_CONDITION_SUCCESS",
  ADD_UNLOCK_CONDITION_FAILURE: "ADD_UNLOCK_CONDITION_FAILURE",

  UNLOCK_CONDITION_LIST_INITIATE: "UNLOCK_CONDITION_LIST_INITIATE",
  UNLOCK_CONDITION_LIST_SUCCESS: "UNLOCK_CONDITION_LIST_SUCCESS",
  UNLOCK_CONDITION_LIST_FAILURE: "UNLOCK_CONDITION_LIST_FAILURE",

  UNLOCK_CONDITION_DETAILS_INITIATE: "UNLOCK_CONDITION_DETAILS_INITIATE",
  UNLOCK_CONDITION_DETAILS_SUCCESS: "UNLOCK_CONDITION_DETAILS_SUCCESS",
  UNLOCK_CONDITION_DETAILS_FAILURE: "UNLOCK_CONDITION_DETAILS_FAILURE",

  CHANGE_UNLOCK_STATUS_LIST_INITIATE: "CHANGE_UNLOCK_STATUS_LIST_INITIATE",
  CHANGE_UNLOCK_STATUS_LIST_SUCCESS: "CHANGE_UNLOCK_STATUS_LIST_SUCCESS",
  CHANGE_UNLOCK_STATUS_LIST_FAILURE: "CHANGE_UNLOCK_STATUS_LIST_FAILURE",

  //Send Document for Client Signature

  SEND_DOCUMENT_FOR_CLIENT_SIGNATURE_INITIATE:
    "SEND_DOCUMENT_FOR_CLIENT_SIGNATURE_INITIATE",
  SEND_DOCUMENT_FOR_CLIENT_SIGNATURE_SUCCESS:
    "SEND_DOCUMENT_FOR_CLIENT_SIGNATURE_SUCCESS",
  SEND_DOCUMENT_FOR_CLIENT_SIGNATURE_FAILURE:
    "SEND_DOCUMENT_FOR_CLIENT_SIGNATURE_FAILURE",

  GET_GROSS_MARGIN_INITIATE: "GET_GROSS_MARGIN_INITIATE",
  GET_GROSS_MARGIN_SUCCESS: "GET_GROSS_MARGIN_SUCCESS",
  GET_GROSS_MARGIN_FAILURE: "GET_GROSS_MARGIN_FAILURE",

  GET_CLIENT_DOCS_BY_ID_INITIATE: "GET_CLIENT_DOCS_BY_ID_INITIATE",
  GET_CLIENT_DOCS_BY_ID_SUCCESS: "GET_CLIENT_DOCS_BY_ID_SUCCESS",
  GET_CLIENT_DOCS_BY_ID_FAILURE: "GET_CLIENT_DOCS_BY_ID_FAILURE",

  BUILDER_SIGNEE_LIST_INITIATE: "BUILDER_SIGNEE_LIST_INITIATE",
  BUILDER_SIGNEE_LIST_SUCCESS: "BUILDER_SIGNEE_LIST_SUCCESS",
  BUILDER_SIGNEE_LIST_FAILURE: "BUILDER_SIGNEE_LIST_FAILURE",

  GENERATED_DOCUMENT_FOR_CLIENT_SIGNATURE_INITIATE:
    "GENERATED_DOCUMENT_FOR_CLIENT_SIGNATURE_INITIATE",
  GENERATED_DOCUMENT_FOR_CLIENT_SIGNATURE_SUCCESS:
    "GENERATED_DOCUMENT_FOR_CLIENT_SIGNATURE_SUCCESS",
  GENERATED_DOCUMENT_FOR_CLIENT_SIGNATURE_FAILURE:
    "GENERATED_DOCUMENT_FOR_CLIENT_SIGNATURE_FAILURE",

  GET_ALL_SIGNATURE_INITIATE: "GET_ALL_SIGNATURE_INITIATE",
  GET_ALL_SIGNATURE_SUCCESS: "GET_ALL_SIGNATURE_SUCCESS",
  GET_ALL_SIGNATURE_FAILURE: "GET_ALL_SIGNATURE_FAILURE",

  DELETE_SIGNATURE_INITIATE: "DELETE_SIGNATURE_INITIATE",
  DELETE_SIGNATURE_SUCCESS: "DELETE_SIGNATURE_SUCCESS",
  DELETE_SIGNATURE_FAILURE: "DELETE_SIGNATURE_FAILURE",

  GET_SALES_TEMPLATE_BY_ID_INITIATE: "GET_SALES_TEMPLATE_BY_ID_INITIATE",
  GET_SALES_TEMPLATE_BY_ID_SUCCESS: "GET_SALES_TEMPLATE_BY_ID_SUCCESS",
  GET_SALES_TEMPLATE_BY_ID_FAILURE: "GET_SALES_TEMPLATE_BY_ID_FAILURE",

  GET_CONVERT_TO_STRING_INITIATE: "GET_CONVERT_TO_STRING_INITIATE",
  GET_CONVERT_TO_STRING_SUCCESS: "GET_CONVERT_TO_STRING_SUCCESS",
  GET_CONVERT_TO_STRING_FAILURE: "GET_CONVERT_TO_STRING_FAILURE",

  ADD_SIGN_IN_SALES_TEMPLATES_INITIATE: "ADD_SIGN_IN_SALES_TEMPLATES_INITIATE",
  ADD_SIGN_IN_SALES_TEMPLATES_SUCCESS: "ADD_SIGN_IN_SALES_TEMPLATES_SUCCESS",
  ADD_SIGN_IN_SALES_TEMPLATES_FAILURE: "ADD_SIGN_IN_SALES_TEMPLATES_FAILURE",

  GET_CLIENT_DOC_TEMPLATE_INITIATE: "GET_CLIENT_DOC_TEMPLATE_INITIATE",
  GET_CLIENT_DOC_TEMPLATE_SUCCESS: "GET_CLIENT_DOC_TEMPLATE_SUCCESS",
  GET_CLIENT_DOC_TEMPLATE_FAILURE: "GET_CLIENT_DOC_TEMPLATE_FAILURE",

  SEND_SMS_TO_LEAD_INITIATE: "SEND_SMS_TO_LEAD_INITIATE",
  SEND_SMS_TO_LEAD_SUCCESS: "SEND_SMS_TO_LEAD_SUCCESS",
  SEND_SMS_TO_LEAD_FAILURE: "SEND_SMS_TO_LEAD_FAILURE",

  REMOVED_ASSIGN_UNIT_INITIATE: "REMOVED_ASSIGN_UNIT_INITIATE",
  REMOVED_ASSIGN_UNIT_SUCCESS: "REMOVED_ASSIGN_UNIT_SUCCESS",
  REMOVED_ASSIGN_UNIT_FAILURE: "REMOVED_ASSIGN_UNIT_FAILURE",

  UPDATE_CONTACT_DETAILS_INITIATE: "UPDATE_CONTACT_DETAILS_INITIATE",
  UPDATE_CONTACT_DETAILS_SUCCESS: "UPDATE_CONTACT_DETAILS_SUCCESS",
  UPDATE_CONTACT_DETAILS_FAILURE: "UPDATE_CONTACT_DETAILS_FAILURE",

  SEND_NEW_EMAIL_INITIATE: "SEND_NEW_EMAIL_INITIATE",
  SEND_NEW_EMAIL_SUCCESS: "SEND_NEW_EMAIL_SUCCESS",
  SEND_NEW_EMAIL_FAILURE: "SEND_NEW_EMAIL_FAILURE",

  CLIENT_DOC_TEMPLATE_INITIATE: "CLIENT_DOC_TEMPLATE_INITIATE",
  CLIENT_DOC_TEMPLATE_SUCCESS: "CLIENT_DOC_TEMPLATE_SUCCESS",
  CLIENT_DOC_TEMPLATE_FAILURE: "CLIENT_DOC_TEMPLATE_FAILURE",

  SET_PAYMENT_SCHEDULE_INITIATE:"SET_PAYMENT_SCHEDULE_INITIATE",
  SET_PAYMENT_SCHEDULE_SUCCESS: "SET_PAYMENT_SCHEDULE_SUCCESS",
  SET_PAYMENT_SCHEDULE_FAILURE: "SET_PAYMENT_SCHEDULE_FAILURE",
};

export const ClientDocumentationActionTypes = {
  CREATE_CLIENT_DOCS_INITIATE: "CREATE_CLIENT_DOCS_INITIATE",
  CREATE_CLIENT_DOCS_SUCCESS: "CREATE_CLIENT_DOCS_SUCCESS",
  CREATE_CLIENT_DOCS_FAILURE: "CREATE_CLIENT_DOCS_FAILURE",

  CLIENT_LIST_INITIATE: "CLIENT_LIST_INITIATE",
  CLIENT_LIST_SUCCESS: "CLIENT_LIST_SUCCESS",
  CLIENT_LIST_FAILURE: "CLIENT_LIST_FAILURE",

  SEARCH_BY_NAME_INITIATE: "SEARCH_BY_NAME_INITIATE",
  SEARCH_BY_NAME_SUCCESS: "SEARCH_BY_NAME_SUCCESS",
  SEARCH_BY_NAME_FAILURE: "SEARCH_BY_NAME_FAILURE",

  UPDATE_CLIENT_DOCS_INITIATE: "UPDATE_CLIENT_DOCS_INITIATE",
  UPDATE_CLIENT_DOCS_SUCCESS: "UPDATE_CLIENT_DOCS_SUCCESS",
  UPDATE_CLIENT_DOCS_FAILURE: "UPDATE_CLIENT_DOCS_FAILURE",

  CHANGE_CLIENT_STATUS_INITIATE: "CHANGE_CLIENT_STATUS_INITIATE",
  CHANGE_CLIENT_STATUS_SUCCESS: "CHANGE_CLIENT_STATUS_SUCCESS",
  CHANGE_CLIENT_STATUS_FAILURE: "CHANGE_CLIENT_STATUS_FAILURE",

  DELETE_CLIENT_DOCS_INITIATE: "DELETE_CLIENT_DOCS_INITIATE",
  DELETE_CLIENT_DOCS_SUCCESS: "DELETE_CLIENT_DOCS_SUCCESS",
  DELETE_CLIENT_DOCS_FAILURE: "DELETE_CLIENT_DOCS_FAILURE",

  ADD_FOLDER_INITIATE: "ADD_FOLDER_INITIATE",
  ADD_FOLDER_SUCCESS: "ADD_FOLDER_SUCCESS",
  ADD_FOLDER_FAILURE: "ADD_FOLDER_FAILURE",

  FOLDER_LIST_INITIATE: "FOLDER_LIST_INITIATE",
  FOLDER_LIST_SUCCESS: "FOLDER_LIST_SUCCESS",
  FOLDER_LIST_FAILURE: "FOLDER_LIST_FAILURE",

  FOLDER_HISTORY_LIST_INITIATE: "FOLDER_HISTORY_LIST_INITIATE",
  FOLDER_HISTORY_LIST_SUCCESS: "FOLDER_HISTORY_LIST_SUCCESS",
  FOLDER_HISTORY_LIST_FAILURE: "FOLDER_HISTORY_LIST_FAILURE",

  UPDATE_FOLDER_INITIATE: "UPDATE_FOLDER_INITIATE",
  UPDATE_FOLDER_SUCCESS: "UPDATE_FOLDER_SUCCESS",
  UPDATE_FOLDER_FAILURE: "UPDATE_FOLDER_FAILURE",

  DELETE_FOLDER_INITIATE: "DELETE_FOLDER_INITIATE",
  DELETE_FOLDER_SUCCESS: "DELETE_FOLDER_SUCCESS",
  DELETE_FOLDER_FAILURE: "DELETE_FOLDER_FAILURE",

  FOLDER_DETAILS_INITIATE: "FOLDER_DETAILS_INITIATE",
  FOLDER_DETAILS_SUCCESS: "FOLDER_DETAILS_SUCCESS",
  FOLDER_DETAILS_FAILURE: "FOLDER_DETAILS_FAILURE",

  SEARCH_FOLDER_LIST_INITIATE: "SEARCH_FOLDER_LIST_INITIATE",
  SEARCH_FOLDER_LIST_SUCCESS: "SEARCH_FOLDER_LIST_SUCCESS",
  SEARCH_FOLDER_LIST_FAILURE: "SEARCH_FOLDER_LIST_FAILURE",

  PENDING_FILE_LIST_INITIATE: "PENDING_FILE_LIST_INITIATE",
  PENDING_FILE_LIST_SUCCESS: "PENDING_FILE_LIST_SUCCESS",
  PENDING_FILE_LIST_FAILURE: "PENDING_FILE_LIST_FAILURE",

  SHARING_PENDING_FILE_INITIATE: "SHARING_PENDING_FILE_INITIATE",
  SHARING_PENDING_FILE_SUCCESS: "SHARING_PENDING_FILE_SUCCESS",
  SHARING_PENDING_FILE_FAILURE: "SHARING_PENDING_FILE_FAILURE",

  REMOVE_PENDING_FILE_INITIATE: "REMOVE_PENDING_FILE_INITIATE",
  REMOVE_PENDING_FILE_SUCCESS: "REMOVE_PENDING_FILE_SUCCESS",
  REMOVE_PENDING_FILE_FAILURE: "REMOVE_PENDING_FILE_FAILURE",

  ADD_FILES_INITIATE: "ADD_FILES_INITIATE",
  ADD_FILES_SUCCESS: "ADD_FILES_SUCCESS",
  ADD_FILES_FAILURE: "ADD_FILES_FAILURE",

  FILES_LIST_INITIATE: "FILES_LIST_INITIATE",
  FILES_LIST_SUCCESS: "FILES_LIST_SUCCESS",
  FILES_LIST_FAILURE: "FILES_LIST_FAILURE",

  CHANGE_STATUS_CLIENT_INITIATE: "CHANGE_STATUS_CLIENT_INITIATE",
  CHANGE_STATUS_CLIENT_SUCCESS: "CHANGE_STATUS_CLIENT_SUCCESS",
  CHANGE_STATUS_CLIENT_FAILURE: "CHANGE_STATUS_CLIENT_FAILURE",

  CLIENT_USERS_LIST_INITIATE: "CLIENT_USERS_LIST_INITIATE",
  CLIENT_USERS_LIST_SUCCESS: "CLIENT_USERS_LIST_SUCCESS",
  CLIENT_USERS_LIST_FAILURE: "CLIENT_USERS_LIST_FAILURE",

  SEND_CLIENT_INFORMATION_INITIATE: "SEND_CLIENT_INFORMATION_INITIATE",
  SEND_CLIENT_INFORMATION_SUCCESS: "SEND_CLIENT_INFORMATION_SUCCESS",
  SEND_CLIENT_INFORMATION_FAILURE: "SEND_CLIENT_INFORMATION_FAILURE",

  ADD_SIGNATURE_INITIATE: "ADD_SIGNATURE_INITIATE",
  ADD_SIGNATURE_SUCCESS: "ADD_SIGNATURE_SUCCESS",
  ADD_SIGNATURE_FAILURE: "ADD_SIGNATURE_FAILURE",

  SHARE_DOCUMENTS_INITIATE: "SHARE_DOCUMENTS_INITIATE",
  SHARE_DOCUMENTS_SUCCESS: "SHARE_DOCUMENTS_SUCCESS",
  SHARE_DOCUMENTS_FAILURE: "SHARE_DOCUMENTS_FAILURE",

  DOWNLOAD_DOCUMENT_INITIATE: "DOWNLOAD_DOCUMENT_INITIATE",
  DOWNLOAD_DOCUMENT_SUCCESS: "DOWNLOAD_DOCUMENT_SUCCESS",
  DOWNLOAD_DOCUMENT_FAILURE: "DOWNLOAD_DOCUMENT_FAILURE",

  UPDATE_SIGNED_DOCUMENTS_INITIATE: "UPDATE_SIGNED_DOCUMENTS_INITIATE",
  UPDATE_SIGNED_DOCUMENTS_SUCCESS: "UPDATE_SIGNED_DOCUMENTS_SUCCESS",
  UPDATE_SIGNED_DOCUMENTS_FAILURE: "UPDATE_SIGNED_DOCUMENTS_FAILURE",

  SEND_CLIENT_SIGNATURE_INITIATE: "SEND_CLIENT_SIGNATURE_INITIATE",
  SEND_CLIENT_SIGNATURE_SUCCESS: "SEND_CLIENT_SIGNATURE_SUCCESS",
  SEND_CLIENT_SIGNATURE_FAILURE: "SEND_CLIENT_SIGNATURE_FAILURE",

  UPDATE_DOCUMENT_INITIATE: "UPDATE_DOCUMENT_INITIATE",
  UPDATE_DOCUMENT_SUCCESS: "UPDATE_DOCUMENT_SUCCESS",
  UPDATE_DOCUMENT_FAILURE: "UPDATE_DOCUMENT_FAILURE",

  DOCUMENT_DETAILS_INITIATE: "DOCUMENT_DETAILS_INITIATE",
  DOCUMENT_DETAILS_SUCCESS: "DOCUMENT_DETAILS_SUCCESS",
  DOCUMENT_DETAILS_FAILURE: "DOCUMENT_DETAILS_FAILURE",

  RECIPENT_LIST_INITIATE: "RECIPENT_LIST_INITIATE",
  RECIPENT_LIST_SUCCESS: "RECIPENT_LIST_SUCCESS",
  RECIPENT_LIST_FAILURE: "RECIPENT_LIST_FAILURE",

  PRIMARY_CLIENT_SIGNEE_INITIATE: "PRIMARY_CLIENT_SIGNEE_INITIATE",
  PRIMARY_CLIENT_SIGNEE_SUCCESS: "PRIMARY_CLIENT_SIGNEE_SUCCESS",
  PRIMARY_CLIENT_SIGNEE_FAILURE: "PRIMARY_CLIENT_SIGNEE_FAILURE",

  BUILDER_SIGNEE_INITIATE: "BUILDER_SIGNEE_INITIATE",
  BUILDER_SIGNEE_SUCCESS: "BUILDER_SIGNEE_SUCCESS",
  BUILDER_SIGNEE_FAILURE: "BUILDER_SIGNEE_FAILURE",

  ADDITIONAL_CLIENT_SIGNEE_INITIATE: "ADDITIONAL_CLIENT_SIGNEE_INITIATE",
  ADDITIONAL_CLIENT_SIGNEE_SUCCESS: "ADDITIONAL_CLIENT_SIGNEE_SUCCESS",
  ADDITIONAL_CLIENT_SIGNEE_FAILURE: "ADDITIONAL_CLIENT_SIGNEE_FAILURE",
};

export const priceBookActionTypes = {
  ADD_CATEGORY_INITIATE: "ADD_CATEGORY_INITIATE",
  ADD_CATEGORY_SUCCESS: "ADD_CATEGORY_SUCCESS",
  ADD_CATEGORY_FAILURE: "ADD_CATEGORY_FAILURE",

  CATEGORY_LIST_INITIATE: "CATEGORY_LIST_INITIATE",
  CATEGORY_LIST_SUCCESS: "CATEGORY_LIST_SUCCESS",
  CATEGORY_LIST_FAILURE: "CATEGORY_LIST_FAILURE",

  CTC_CATEGORY_LIST_INITIATE: "CTC_CATEGORY_LIST_INITIATE",
  CTC_CATEGORY_LIST_SUCCESS:  "CTC_CATEGORY_LIST_SUCCESS",
  CTC_CATEGORY_LIST_FAILURE:  "CTC_CATEGORY_LIST_FAILURE",

  CTC_ASSEMBLY_LIST_INITIATE: "CTC_ASSEMBLY_LIST_INITIATE",
  CTC_ASSEMBLY_LIST_SUCCESS:  "CTC_ASSEMBLY_LIST_SUCCESS",
  CTC_ASSEMBLY_LIST_FAILURE:  "CTC_ASSEMBLY_LIST_FAILURE",

  UPDATE_CATEGORY_INITIATE: "UPDATE_CATEGORY_INITIATE",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",

  CATEGORY_DETAILS_INITIATE: "CATEGORY_DETAILS_INITIATE",
  CATEGORY_DETAILS_SUCCESS: "CATEGORY_DETAILS_SUCCESS",
  CATEGORY_DETAILS_FAILURE: "CATEGORY_DETAILS_FAILURE",

  DELETE_CATEGORY_INITIATE: "DELETE_CATEGORY_INITIATE",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",

  CATEGORY_HISTORY_INITIATE: "CATEGORY_HISTORY_INITIATE",
  CATEGORY_HISTORY_SUCCESS: "CATEGORY_HISTORY_SUCCESS",
  CATEGORY_HISTORY_FAILURE: "CATEGORY_HISTORY_FAILURE",

  ADD_ENTRIES_INITIATE: "ADD_ENTRIES_INITIATE",
  ADD_ENTRIES_SUCCESS: "ADD_ENTRIES_SUCCESS",
  ADD_ENTRIES_FAILURE: "ADD_ENTRIES_FAILURE",

  UPDATE_ENTRIES_INITIATE: "UPDATE_ENTRIES_INITIATE",
  UPDATE_ENTRIES_SUCCESS: "UPDATE_ENTRIES_SUCCESS",
  UPDATE_ENTRIES_FAILURE: "UPDATE_ENTRIES_FAILURE",

  ENTRIES_DETAILS_INITIATE: "ENTRIES_DETAILS_INITIATE",
  ENTRIES_DETAILS_SUCCESS: "ENTRIES_DETAILS_SUCCESS",
  ENTRIES_DETAILS_FAILURE: "ENTRIES_DETAILS_FAILURE",

  ENTRIES_LIST_INITIATE: "ENTRIES_LIST_INITIATE",
  ENTRIES_LIST_SUCCESS: "ENTRIES_LIST_SUCCESS",
  ENTRIES_LIST_FAILURE: "ENTRIES_LIST_FAILURE",

  DELETE_ENTRIES_INITIATE: "DELETE_ENTRIES_INITIATE",
  DELETE_ENTRIES_SUCCESS: "DELETE_ENTRIES_SUCCESS",
  DELETE_ENTRIES_FAILURE: "DELETE_ENTRIES_FAILURE",

  ENTRY_HISTORY_INITIATE: "ENTRY_HISTORY_INITIATE",
  ENTRY_HISTORY_SUCCESS: "ENTRY_HISTORY_SUCCESS",
  ENTRY_HISTORY_FAILURE: "ENTRY_HISTORY_FAILURE",

  UPDATE_ENTRIES_MARKUP_INITIATE: "UPDATE_ENTRIES_MARKUP_INITIATE",
  UPDATE_ENTRIES_MARKUP_SUCCESS: "UPDATE_ENTRIES_MARKUP_SUCCESS",
  UPDATE_ENTRIES_MARKUP_FAILURE: "UPDATE_ENTRIES_MARKUP_FAILURE",

  ADD_ASSEMBLY_INITIATE: "ADD_ASSEMBLY_INITIATE",
  ADD_ASSEMBLY_SUCCESS: "ADD_ASSEMBLY_SUCCESS",
  ADD_ASSEMBLY_FAILURE: "ADD_ASSEMBLY_FAILURE",

  ASSEMBLY_LIST_INITIATE: "ASSEMBLY_LIST_INITIATE",
  ASSEMBLY_LIST_SUCCESS: "ASSEMBLY_LIST_SUCCESS",
  ASSEMBLY_LIST_FAILURE: "ASSEMBLY_LIST_FAILURE",

  UPDATE_ASSEMBLY_INITIATE: "UPDATE_ASSEMBLY_INITIATE",
  UPDATE_ASSEMBLY_SUCCESS: "UPDATE_ASSEMBLY_SUCCESS",
  UPDATE_ASSEMBLY_FAILURE: "UPDATE_ASSEMBLY_FAILURE",

  ASSEMBLY_DETAILS_INITIATE: "ASSEMBLY_DETAILS_INITIATE",
  ASSEMBLY_DETAILS_SUCCESS: "ASSEMBLY_DETAILS_SUCCESS",
  ASSEMBLY_DETAILS_FAILURE: "ASSEMBLY_DETAILS_FAILURE",

  DELETE_ASSEMBLY_INITIATE: "DELETE_ASSEMBLY_INITIATE",
  DELETE_ASSEMBLY_SUCCESS: "DELETE_ASSEMBLY_SUCCESS",
  DELETE_ASSEMBLY_FAILURE: "DELETE_ASSEMBLY_FAILURE",

  ASSEMBLY_HISTORY_INITIATE: "ASSEMBLY_HISTORY_INITIATE",
  ASSEMBLY_HISTORY_SUCCESS: "ASSEMBLY_HISTORY_SUCCESS",
  ASSEMBLY_HISTORY_FAILURE: "ASSEMBLY_HISTORY_FAILURE",

  UPDATE_ASSEMBLY_MARKUP_INITIATE: "UPDATE_ASSEMBLY_MARKUP_INITIATE",
  UPDATE_ASSEMBLY_MARKUP_SUCCESS: "UPDATE_ASSEMBLY_MARKUP_SUCCESS",
  UPDATE_ASSEMBLY_MARKUP_FAILURE: "UPDATE_ASSEMBLY_MARKUP_FAILURE",

  ADD_MEASURE_INITIATE: "ADD_MEASURE_INITIATE",
  ADD_MEASURE_SUCCESS: "ADD_MEASURE_SUCCESS",
  ADD_MEASURE_FAILURE: "ADD_MEASURE_FAILURE",

  MEASURE_LIST_INITIATE: "MEASURE_LIST_INITIATE",
  MEASURE_LIST_SUCCESS: "MEASURE_LIST_SUCCESS",
  MEASURE_LIST_FAILURE: "MEASURE_LIST_FAILURE",

  UPDATE_MEASURE_INITIATE: "UPDATE_MEASURE_INITIATE",
  UPDATE_MEASURE_SUCCESS: "UPDATE_MEASURE_SUCCESS",
  UPDATE_MEASURE_FAILURE: "UPDATE_MEASURE_FAILURE",

  MEASURE_DETAILS_INITIATE: "MEASURE_DETAILS_INITIATE",
  MEASURE_DETAILS_SUCCESS: "MEASURE_DETAILS_SUCCESS",
  MEASURE_DETAILS_FAILURE: "MEASURE_DETAILS_FAILURE",

  DELETE_MEASURE_INITIATE: "DELETE_MEASURE_INITIATE",
  DELETE_MEASURE_SUCCESS: "DELETE_MEASURE_SUCCESS",
  DELETE_MEASURE_FAILURE: "DELETE_MEASURE_FAILURE",

  MEASURE_HISTORY_INITIATE: "MEASURE_HISTORY_INITIATE",
  MEASURE_HISTORY_SUCCESS: "MEASURE_HISTORY_SUCCESS",
  MEASURE_HISTORY_FAILURE: "MEASURE_HISTORY_FAILURE",

  DOWNLOAD_PRICEBOOK_INITIATE: "DOWNLOAD_PRICEBOOK_INITIATE",
  DOWNLOAD_PRICEBOOK_SUCCESS: "DOWNLOAD_PRICEBOOK_SUCCESS",
  DOWNLOAD_PRICEBOOK_FAILURE: "DOWNLOAD_PRICEBOOK_FAILURE",

  DOWNLOAD_CLIENTBOOK_INITIATE: "DOWNLOAD_CLIENTBOOK_INITIATE",
  DOWNLOAD_CLIENTBOOK_SUCCESS: "DOWNLOAD_CLIENTBOOK_SUCCESS",
  DOWNLOAD_CLIENTBOOK_FAILURE: "DOWNLOAD_CLIENTBOOK_FAILURE",

  CLIENTBOOK_PRICEBOOK_UPLOAD_INITIATE: "CLIENTBOOK_PRICEBOOK_UPLOAD_INITIATE",
  CLIENTBOOK_PRICEBOOK_UPLOAD_SUCCESS: "CLIENTBOOK_PRICEBOOK_UPLOAD_SUCCESS",
  CLIENTBOOK_PRICEBOOK_UPLOAD_FAILURE: "CLIENTBOOK_PRICEBOOK_UPLOAD_FAILURE",

  CREATE_CTC_INITIATE: "CREATE_CTC_INITIATE",
  CREATE_CTC_SUCCESS: "CREATE_CTC_SUCCESS",
  CREATE_CTC_FAILURE: "CREATE_CTC_FAILURE",

  CTC_LIST_INITIATE: "CTC_LIST_INITIATE",
  CTC_LIST_SUCCESS: "CTC_LIST_SUCCESS",
  CTC_LIST_FAILURE: "CTC_LIST_FAILURE",

  CTC_DETAILS_INITIATE: "CTC_DETAILS_INITIATE",
  CTC_DETAILS_SUCCESS: "CTC_DETAILS_SUCCESS",
  CTC_DETAILS_FAILURE: "CTC_DETAILS_FAILURE",

  DELETE_CTC_INITIATE: "DELETE_CTC_INITIATE",
  DELETE_CTC_SUCCESS: "DELETE_CTC_SUCCESS",
  DELETE_CTC_FAILURE: "DELETE_CTC_FAILURE",

  CTC_HISTORY_INITIATE: "CTC_HISTORY_INITIATE",
  CTC_HISTORY_SUCCESS: "CTC_HISTORY_SUCCESS",
  CTC_HISTORY_FAILURE: "CTC_HISTORY_FAILURE",

  UPDATE_CTC_INITIATE: " UPDATE_CTC_INITIATE",
  UPDATE_CTC_SUCCESS: " UPDATE_CTC_SUCCESS",
  UPDATE_CTC_FAILURE: " UPDATE_CTC_FAILURE",

  TAG_LIST_INITIATE: "TAG_LIST_INITIATE",
  TAG_LIST_SUCCESS: "TAG_LIST_SUCCESS",
  TAG_LIST_FAILURE: "TAG_LIST_FAILURE",

  CREATE_TEMPLATE_INITIATE: "CREATE_TEMPLATE_INITIATE",
  CREATE_TEMPLATE_SUCCESS: "CREATE_TEMPLATE_SUCCESS",
  CREATE_TEMPLATE_FAILURE: "CREATE_TEMPLATE_FAILURE",

  TEMPLATE_LIST_INITIATE: "TEMPLATE_LIST_INITIATE",
  TEMPLATE_LIST_SUCCESS: "TEMPLATE_LIST_SUCCESS",
  TEMPLATE_LIST_FAILURE: "TEMPLATE_LIST_FAILURE",

  TEMPLATE_DETAILS_INITIATE: "TEMPLATE_DETAILS_INITIATE",
  TEMPLATE_DETAILS_SUCCESS: "TEMPLATE_DETAILS_SUCCESS",
  TEMPLATE_DETAILS_FAILURE: "TEMPLATE_DETAILS_FAILURE",

  DELETE_TEMPLATE_INITIATE: "DELETE_TEMPLATE_INITIATE",
  DELETE_TEMPLATE_SUCCESS: "DELETE_TEMPLATE_SUCCESS",
  DELETE_TEMPLATE_FAILURE: "DELETE_TEMPLATE_FAILURE",

  TEMPLATE_HISTORY_INITIATE: "TEMPLATE_HISTORY_INITIATE",
  TEMPLATE_HISTORY_SUCCESS: "TEMPLATE_HISTORY_SUCCESS",
  TEMPLATE_HISTORY_FAILURE: "TEMPLATE_HISTORY_FAILURE",

  UPDATE_TEMPLATE_INITIATE: " UPDATE_TEMPLATE_INITIATE",
  UPDATE_TEMPLATE_SUCCESS: " UPDATE_TEMPLATE_SUCCESS",
  UPDATE_TEMPLATE_FAILURE: " UPDATE_TEMPLATE_FAILURE",

  ADD_TEMPLATE_PURCHASE_INITIATE: "ADD_TEMPLATE_PURCHASE_INITIATE",
  ADD_TEMPLATE_PURCHASE_SUCCESS: "ADD_TEMPLATE_PURCHASE_SUCCESS",
  ADD_TEMPLATE_PURCHASE_FAILURE: "ADD_TEMPLATE_PURCHASE_FAILURE",

  UPDATE_PRICEBOOK_PERMISSION_INITIATE: "UPDATE_PRICEBOOK_PERMISSION_INITIATE",
  UPDATE_PRICEBOOK_PERMISSION_SUCCESS: "UPDATE_PRICEBOOK_PERMISSION_SUCCESS",
  UPDATE_PRICEBOOK_PERMISSION_FAILURE: "UPDATE_PRICEBOOK_PERMISSION_FAILURE",

  PRICEBOOK_PERMISSION_LIST_INITIATE: "PRICEBOOK_PERMISSION_LIST_INITIATE",
  PRICEBOOK_PERMISSION_LIST_SUCCESS: "PRICEBOOK_PERMISSION_LIST_SUCCESS",
  PRICEBOOK_PERMISSION_LIST_FAILURE: "PRICEBOOK_PERMISSION_LIST_FAILURE",

  ENABLE_TEMPLATE_INITIATE: "ENABLE_TEMPLATE_INITIATE",
  ENABLE_TEMPLATE_SUCCESS: "ENABLE_TEMPLATE_SUCCESS",
  ENABLE_TEMPLATE_FAILURE: "ENABLE_TEMPLATE_FAILURE",

  DISABLE_TEMPLATE_INITIATE: "DISABLE_TEMPLATE_INITIATE",
  DISABLE_TEMPLATE_SUCCESS: "DISABLE_TEMPLATE_SUCCESS",
  DISABLE_TEMPLATE_FAILURE: "DISABLE_TEMPLATE_FAILURE",

  PURCHASE_DETAILS_LIST_INITIATE: "PURCHASE_DETAILS_LIST_INITIATE",
  PURCHASE_DETAILS_LIST_SUCCESS: "PURCHASE_DETAILS_LIST_SUCCESS",
  PURCHASE_DETAILS_LIST_FAILURE: "PURCHASE_DETAILS_LIST_FAILURE",

  UPDATE_PURCHASE_INITIATE: "UPDATE_PURCHASE_INITIATE",
  UPDATE_PURCHASE_SUCCESS: "UPDATE_PURCHASE_SUCCESS",
  UPDATE_PURCHASE_FAILURE: "UPDATE_PURCHASE_FAILURE",

  IMPORT_TEMPLATE_INITIATE: "IMPORT_TEMPLATE_INITIATE",
  IMPORT_TEMPLATE_SUCCESS: "IMPORT_TEMPLATE_SUCCESS",
  IMPORT_TEMPLATE_FAILURE: "IMPORT_TEMPLATE_FAILURE",
};

export const fileManagerAction = {
  CREATE_FOLDER_INITIATE: "CREATE_FOLDER_INITIATE",
  CREATE_FOLDER_SUCCESS: "CREATE_FOLDER_SUCCESS",
  CREATE_FOLDER_FAILURE: "CREATE_FOLDER_FAILURE",

  ALL_FOLDER_HISTORY_INITIATE: "ALL_FOLDER_HISTORY_INITIATE",
  ALL_FOLDER_HISTORY_SUCCESS: "ALL_FOLDER_HISTORY_SUCCESS",
  ALL_FOLDER_HISTORY_FAILURE: "ALL_FOLDER_HISTORY_FAILURE",

  ALL_FOLDER_LIST_INITIATE: "ALL_FOLDER_LIST_INITIATE",
  ALL_FOLDER_LIST_SUCCESS: "ALL_FOLDER_LIST_SUCCESS",
  ALL_FOLDER_LIST_FAILURE: "ALL_FOLDER_LIST_FAILURE",

  DELETE_FOLDER_INITIATE: "DELETE_FOLDER_INITIATE",
  DELETE_FOLDER_SUCCESS: "DELETE_FOLDER_SUCCESS",
  DELETE_FOLDER_FAILURE: "DELETE_FOLDER_FAILURE",

  SEARCH_FOLDER_BYNAME_INITIATE: "SEARCH_FOLDER_BYNAME_INITIATE",
  SEARCH_FOLDER_BYNAME_SUCCESS: "SEARCH_FOLDER_BYNAME_SUCCESS",
  SEARCH_FOLDER_BYNAME_FAILURE: "SEARCH_FOLDER_BYNAME_FAILURE",

  MANAGE_SIGNATURE_UPLOAD_INITIATE: "MANAGE_SIGNATURE_UPLOAD_INITIATE",
  MANAGE_SIGNATURE_UPLOAD_SUCCESS: "MANAGE_SIGNATURE_UPLOAD_SUCCESS",
  MANAGE_SIGNATURE_UPLOAD_FAILURE: "MANAGE_SIGNATURE_UPLOAD_FAILURE",

  CREATE_DOCUMENTATION_INITIATE: "CREATE_DOCUMENTATION_INITIATE",
  CREATE_DOCUMENTATION_SUCCESS: "CREATE_DOCUMENTATION_SUCCESS",
  CREATE_DOCUMENTATION_FAILURE: "CREATE_DOCUMENTATION_FAILURE",

  LIST_DOCUMENTATION_INITIATE: "LIST_DOCUMENTATION_INITIATE",
  LIST_DOCUMENTATION_SUCCESS: "LIST_DOCUMENTATION_SUCCESS",
  LIST_DOCUMENTATION_FAILURE: "LIST_DOCUMENTATION_FAILURE",

  SEARCH_LIST_DOCUMENTATION_INITIATE: "SEARCH_LIST_DOCUMENTATION_INITIATE",
  SEARCH_LIST_DOCUMENTATION_SUCCESS: "SEARCH_LIST_DOCUMENTATION_SUCCESS",
  SEARCH_LIST_DOCUMENTATION_FAILURE: "SEARCH_LIST_DOCUMENTATION_FAILURE",

  REMOVE_DOCUMENTATION_INITIATE: "REMOVE_DOCUMENTATION_INITIATE",
  REMOVE_DOCUMENTATION_SUCCESS: "REMOVE_DOCUMENTATION_SUCCESS",
  REMOVE_DOCUMENTATION_FAILURE: "REMOVE_DOCUMENTATION_FAILURE",

  SHARE_CLIENET_DOCUMENTATION_INITIATE: "SHARE_CLIENET_DOCUMENTATION_INITIATE",
  SHARE_CLIENET_DOCUMENTATION_SUCCESS: "SHARE_CLIENET_DOCUMENTATION_SUCCESS",
  SHARE_CLIENET_DOCUMENTATION_FAILURE: "SHARE_CLIENET_DOCUMENTATION_FAILURE",

  SEND_FOR_APPROVAL_INITIATE: "SEND_FOR_APPROVAL_INITIATE",
  SEND_FOR_APPROVAL_SUCCESS: "SEND_FOR_APPROVAL_SUCCESS",
  SEND_FOR_APPROVAL_FAILURE: "SEND_FOR_APPROVAL_FAILURE",

  UPLOAD_TEMPLATE_MAIN_PICTURE_INITIATE:
    "UPLOAD_TEMPLATE_MAIN_PICTURE_INITIATE",
  UPLOAD_TEMPLATE_MAIN_PICTURE_SUCCESS: "UPLOAD_TEMPLATE_MAIN_PICTURE_SUCCESS",
  UPLOAD_TEMPLATE_MAIN_PICTURE_FAILURE: "UPLOAD_TEMPLATE_MAIN_PICTURE_FAILURE",

  VIEW_DOCUMENTATION_INITIATE: "VIEW_DOCUMENTATION_INITIATE",
  VIEW_DOCUMENTATION_SUCCESS: "VIEW_DOCUMENTATION_SUCCESS",
  VIEW_DOCUMENTATION_FAILURE: "VIEW_DOCUMENTATION_FAILURE",

  DOCUMENTATION_APPROVAL_INITIATE: "DOCUMENTATION_APPROVAL_INITIATE",
  DOCUMENTATION_APPROVAL_SUCCESS: "DOCUMENTATION_APPROVAL_SUCCESS",
  DOCUMENTATION_APPROVAL_FAILURE: "DOCUMENTATION_APPROVAL_FAILURE",

  PENDING_FILE_EVENT_LIST_INITIATE: "PENDING_FILE_EVENT_LIST_INITIATE",
  PENDING_FILE_EVENT_LIST_SUCCESS: "PENDING_FILE_EVENT_LIST_SUCCESS",
  PENDING_FILE_EVENT_LIST_FAILURE: "PENDING_FILE_EVENT_LIST_FAILURE",

  GET_ALL_TAGS_LIST_INITIATE: "GET_ALL_TAGS_LIST_INITIATE",
  GET_ALL_TAGS_LIST_SUCCESS: "GET_ALL_TAGS_LIST_SUCCESS",
  GET_ALL_TAGS_LIST_FAILURE: "GET_ALL_TAGS_LIST_FAILURE",

  EDIT_DOCUMENTATION_INITIATE: "EDIT_DOCUMENTATION_INITIATE",
  EDIT_DOCUMENTATION_SUCCESS: "EDIT_DOCUMENTATION_SUCCESS",
  EDIT_DOCUMENTATION_FAILURE: "EDIT_DOCUMENTATION_FAILURE",

  FILE_HISTORY_LIST_INITIATE: "FILE_HISTORY_LIST_INITIATE",
  FILE_HISTORY_LIST_SUCCESS: "FILE_HISTORY_LIST_SUCCESS",
  FILE_HISTORY_LIST_FAILURE: "FILE_HISTORY_LIST_FAILURE",

  HTML_CONVERTER_INITIATE: "HTML_CONVERTER_INITIATE",
  HTML_CONVERTER_SUCCESS: "HTML_CONVERTER_SUCCESS",
  HTML_CONVERTER_FAILURE: "HTML_CONVERTER_FAILURE",

  GET_ALL_DOCUMENT_KEY_LIST_INITIATE: "GET_ALL_DOCUMENT_KEY_LIST_INITIATE",
  GET_ALL_DOCUMENT_KEY_LIST_SUCCESS: "GET_ALL_DOCUMENT_KEY_LIST_SUCCESS",
  GET_ALL_DOCUMENT_KEY_LIST_FAILURE: "GET_ALL_DOCUMENT_KEY_LIST_FAILURE",

  RELEASE_TEMPLATE_INITIATE: "RELEASE_TEMPLATE_INITIATE",
  RELEASE_TEMPLATE_SUCCESS: "RELEASE_TEMPLATE_SUCCESS",
  RELEASE_TEMPLATE_FAILURE: "RELEASE_TEMPLATE_FAILURE",

  LOCK_UNLOCK_FILES_INITIATE: "LOCK_UNLOCK_FILES_INITIATE",
  LOCK_UNLOCK_FILES_SUCCESS: "LOCK_UNLOCK_FILES_SUCCESS",
  LOCK_UNLOCK_FILES_FAILURE: "LOCK_UNLOCK_FILES_FAILURE",

  GET_MANAGE_SIGNATURE_INITIATE: "GET_MANAGE_SIGNATURE_INITIATE",
  GET_MANAGE_SIGNATURE_SUCCESS: "GET_MANAGE_SIGNATURE_SUCCESS",
  GET_MANAGE_SIGNATURE_FAILURE: "GET_MANAGE_SIGNATURE_FAILURE",

  REMOVE_MANAGE_SIGNATURE_INITIATE: "REMOVE_MANAGE_SIGNATURE_INITIATE",
  REMOVE_MANAGE_SIGNATURE_SUCCESS: "REMOVE_MANAGE_SIGNATURE_SUCCESS",
  REMOVE_MANAGE_SIGNATURE_FAILURE: "REMOVE_MANAGE_SIGNATURE_FAILURE",

  GET_ALL_VENDORS_INITIATE: "GET_ALL_VENDORS_INITIATE",
  GET_ALL_VENDORS_SUCCESS: "GET_ALL_VENDORS_SUCCESS",
  GET_ALL_VENDORS_FAILURE: "GET_ALL_VENDORS_FAILURE",

  GET_ALL_COMPANY_CLIENT_INITIATE: "GET_ALL_COMPANY_CLIENT_INITIATE",
  GET_ALL_COMPANY_CLIENT_SUCCESS: "GET_ALL_COMPANY_CLIENT_SUCCESS",
  GET_ALL_COMPANY_CLIENT_FAILURE: "GET_ALL_COMPANY_CLIENT_FAILURE",

  GET_FILE_MANGER_EVENT_HISTORY_INITIATE:
    "GET_FILE_MANGER_EVENT_HISTORY_INITIATE",
  GET_FILE_MANGER_EVENT_HISTORY_SUCCESS:
    "GET_FILE_MANGER_EVENT_HISTORY_SUCCESS",
  GET_FILE_MANGER_EVENT_HISTORY_FAILURE:
    "GET_FILE_MANGER_EVENT_HISTORY_FAILURE",

  FILE_MANGER_EVENT_HISTORY_READ_INITIATE:
    "FILE_MANGER_EVENT_HISTORY_READ_INITIATE",
  FILE_MANGER_EVENT_HISTORY_READ_SUCCESS:
    "FILE_MANGER_EVENT_HISTORY_READ_SUCCESS",
  FILE_MANGER_EVENT_HISTORY_READ_FAILURE:
    "FILE_MANGER_EVENT_HISTORY_READ_FAILURE",
};

export const taskAction = {
  ADD_TASK_INITIATE: "ADD_TASK_INITIATE",
  ADD_TASK_SUCCESS: "ADD_TASK_SUCCESS",
  ADD_TASK_FAILURE: "ADD_TASK_FAILURE",

  EDIT_TASK_INITIATE: "EDIT_TASK_INITIATE",
  EDIT_TASK_SUCCESS: "EDIT_TASK_SUCCESS",
  EDIT_TASK_FAILURE: "EDIT_TASK_FAILURE",

  DELETE_TASK_INITIATE: "DELETE_TASK_INITIATE",
  DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAILURE: "DELETE_TASK_FAILURE",

  TASK_JOB_LIST_INITIATE: "TASK_JOB_LIST_INITIATE",
  TASK_JOB_LIST_SUCCESS: "TASK_JOB_LIST_SUCCESS",
  TASK_JOB_LIST_FAILURE: "TASK_JOB_LIST_FAILURE",

  CREATED_TASK_LIST_INITIATE: "CREATED_TASK_LIST_INITIATE",
  CREATED_TASK_LIST_SUCCESS: "CREATED_TASK_LIST_SUCCESS",
  CREATED_TASK_LIST_FAILURE: "CREATED_TASK_LIST_FAILURE",

  INPROGRESS_TASK_LIST_INITIATE: "INPROGRESS_TASK_LIST_INITIATE",
  INPROGRESS_TASK_LIST_SUCCESS: "INPROGRESS_TASK_LIST_SUCCESS",
  INPROGRESS_TASK_LIST_FAILURE: "INPROGRESS_TASK_LIST_FAILURE",

  COMPLETED_TASK_LIST_INITIATE: "COMPLETED_TASK_LIST_INITIATE",
  COMPLETED_TASK_LIST_SUCCESS: "COMPLETED_TASK_LIST_SUCCESS",
  COMPLETED_TASK_LIST_FAILURE: "COMPLETED_TASK_LIST_FAILURE",

  TASK_MEMBER_LIST_INITIATE: "TASK_MEMBER_LIST_INITIATE",
  TASK_MEMBER_LIST_SUCCESS: "TASK_MEMBER_LIST_SUCCESS",
  TASK_MEMBER_LIST_FAILURE: "TASK_MEMBER_LIST_FAILURE",

  TASK_HISTORY_LIST_INITIATE: "TASK_HISTORY_LIST_INITIATE",
  TASK_HISTORY_LIST_SUCCESS: "TASK_HISTORY_LIST_SUCCESS",
  TASK_HISTORY_LIST_FAILURE: "TASK_HISTORY_LIST_FAILURE",

  ASSIGN_TASK_INITIATE: "ASSIGN_TASK_INITIATE",
  ASSIGN_TASK_SUCCESS: "ASSIGN_TASK_SUCCESS",
  ASSIGN_TASK_FAILURE: "ASSIGN_TASK_FAILURE",

  MANAGE_USER_TASK_LIST_INITIATE: "MANAGE_USER_TASK_LIST_INITIATE",
  MANAGE_USER_TASK_LIST_SUCCESS: "MANAGE_USER_TASK_LIST_SUCCESS",
  MANAGE_USER_TASK_LIST_FAILURE: "MANAGE_USER_TASK_LIST_FAILURE",

  UPDATE_REMINDER_INITIATE: "UPDATE_REMINDER_INITIATE",
  UPDATE_REMINDER_SUCCESS: "UPDATE_REMINDER_SUCCESS",
  UPDATE_REMINDER_FAILURE: "UPDATE_REMINDER_FAILURE",

  TASK_REQUEST_ACCESS_INITIATE: "TASK_REQUEST_ACCESS_INITIATE",
  TASK_REQUEST_ACCESS_SUCCESS: "TASK_REQUEST_ACCESS_SUCCESS",
  TASK_REQUEST_ACCESS_FAILURE: "TASK_REQUEST_ACCESS_FAILURE",

  GOOGLE_CALENDAR_DATA_INITIATE: "GOOGLE_CALENDAR_DATA_INITIATE",
  GOOGLE_CALENDAR_DATA_SUCCESS: "GOOGLE_CALENDAR_DATA_SUCCESS",
  GOOGLE_CALENDAR_DATA_FAILURE: "GOOGLE_CALENDAR_DATA_FAILURE",

  OUTLOOK_CALENDAR_DATA_INITIATE: "OUTLOOK_CALENDAR_DATA_INITIATE",
  OUTLOOK_CALENDAR_DATA_SUCCESS: "OUTLOOK_CALENDAR_DATA_SUCCESS",
  OUTLOOK_CALENDAR_DATA_FAILURE: "OUTLOOK_CALENDAR_DATA_FAILURE",

  EMAIL_CALENDAR_DATA_INITIATE: "EMAIL_CALENDAR_DATA_INITIATE",
  EMAIL_CALENDAR_DATA_SUCCESS: "EMAIL_CALENDAR_DATA_SUCCESS",
  EMAIL_CALENDAR_DATA_FAILURE: "EMAIL_CALENDAR_DATA_FAILURE",

  HIDE_TASK_INITIATE: "HIDE_TASK_INITIATE",
  HIDE_TASK_SUCCESS: "HIDE_TASK_SUCCESS",
  HIDE_TASK_FAILURE: "HIDE_TASK_FAILURE",

  UNHIDE_TASK_INITIATE: "UNHIDE_TASK_INITIATE",
  UNHIDE_TASK_SUCCESS: "UNHIDE_TASK_SUCCESS",
  UNHIDE_TASK_FAILURE: "UNHIDE_TASK_FAILURE",

  SHOW_ALL_TASK_INITIATE: "SHOW_ALL_TASK_INITIATE",
  SHOW_ALL_TASK_SUCCESS: "SHOW_ALL_TASK_SUCCESS",
  SHOW_ALL_TASK_FAILURE: "SHOW_ALL_TASK_FAILURE",

  CANCEL_TASK_INITIATE: "CANCEL_TASK_INITIATE",
  CANCEL_TASK_SUCCESS: "CANCEL_TASK_SUCCESS",
  CANCEL_TASK_FAILURE: "CANCEL_TASK_FAILURE",

  SHOW_HIDDEN_LIST_INITIATE: "SHOW_HIDDEN_LIST_INITIATE",
  SHOW_HIDDEN_LIST_SUCCESS: "SHOW_HIDDEN_LIST_SUCCESS",
  SHOW_HIDDEN_LIST_FAILURE: "SHOW_HIDDEN_LIST_FAILURE",

  SHOW_CANCEL_LIST_INITIATE: "SHOW_CANCEL_LIST_INITIATE",
  SHOW_CANCEL_LIST_SUCCESS: "SHOW_CANCEL_LIST_SUCCESS",
  SHOW_CANCEL_LIST_FAILURE: "SHOW_CANCEL_LIST_FAILURE",

  SHOW_MOVE_LIST_INITIATE: "SHOW_MOVE_LIST_INITIATE",
  SHOW_MOVE_LIST_SUCCESS: "SHOW_MOVE_LIST_SUCCESS",
  SHOW_MOVE_LIST_FAILURE: "SHOW_MOVE_LIST_FAILURE",

  ADD_ITINERTY_INITIATE: "ADD_ITINERTY_INITIATE",
  ADD_ITINERTY_SUCCESS: "ADD_ITINERTY_SUCCESS",
  ADD_ITINERTY_FAILURE: "ADD_ITINERTY_FAILURE",

  CALENDAR_TASK_LIST_INITIATE: "CALENDAR_TASK_LIST_INITIATE",
  CALENDAR_TASK_LIST_SUCCESS: "CALENDAR_TASK_LIST_SUCCESS",
  CALENDAR_TASK_LIST_FAILURE: "CALENDAR_TASK_LIST_FAILURE",

  VIEW_TASK_INITIATE: "VIEW_TASK_INITIATE",
  VIEW_TASK_SUCCESS: "VIEW_TASK_SUCCESS",
  VIEW_TASK_FAILURE: "VIEW_TASK_FAILURE",

  INCOMING_ACCESS_TASK_REQUEST_LIST_INITIATE:
    "INCOMING_ACCESS_TASK_REQUEST_LIST_INITIATE",
  INCOMING_ACCESS_TASK_REQUEST_LIST_SUCCESS:
    "INCOMING_ACCESS_TASK_REQUEST_LIST_SUCCESS",
  INCOMING_ACCESS_TASK_REQUEST_LIST_FAILURE:
    "INCOMING_ACCESS_TASK_REQUEST_LIST_FAILURE",

  MANAGE_ACCESS_TO_MY_TASK_LIST_INITIATE:
    "MANAGE_ACCESS_TO_MY_TASK_LIST_INITIATE",
  MANAGE_ACCESS_TO_MY_TASK_LIST_SUCCESS:
    "MANAGE_ACCESS_TO_MY_TASK_LIST_SUCCESS",
  MANAGE_ACCESS_TO_MY_TASK_LIST_FAILURE:
    "MANAGE_ACCESS_TO_MY_TASK_LIST_FAILURE",

  MANAGE_ACCESS_USER_TASK_LIST_INITIATE:
    "MANAGE_ACCESS_USER_TASK_LIST_INITIATE",
  MANAGE_ACCESS_USER_TASK_LIST_SUCCESS: "MANAGE_ACCESS_USER_TASK_LIST_SUCCESS",
  MANAGE_ACCESS_USER_TASK_LIST_FAILURE: "MANAGE_ACCESS_USER_TASK_LIST_FAILURE",

  TASK_REQUEST_ACCESS_USER_INITIATE: "TASK_REQUEST_ACCESS_USER_INITIATE",
  TASK_REQUEST_ACCESS_USER_SUCCESS: "TASK_REQUEST_ACCESS_USER_SUCCESS",
  TASK_REQUEST_ACCESS_USER_FAILURE: "TASK_REQUEST_ACCESS_USER_FAILURE",

  UPDATE_TARGET_DATE_INITIATE: "UPDATE_TARGET_DATE_INITIATE",
  UPDATE_TARGET_DATE_SUCCESS: "UPDATE_TARGET_DATE_SUCCESS",
  UPDATE_TARGET_DATE_FAILURE: "UPDATE_TARGET_DATE_FAILURE",
};

export const notificationsAction = {
  GET_ALL_NOTIFICATIONS_LIST_INITIATE: "GET_ALL_NOTIFICATIONS_LIST_INITIATE",
  GET_ALL_NOTIFICATIONS_LIST_SUCCESS: "GET_ALL_NOTIFICATIONS_LIST_SUCCESS",
  GET_ALL_NOTIFICATIONS_LIST_FAILURE: "GET_ALL_NOTIFICATIONS_LIST_FAILURE",

  DELETE_NOTIFICATIONS_INITIATE: "DELETE_NOTIFICATIONS_INITIATE",
  DELETE_NOTIFICATIONS_SUCCESS: "DELETE_NOTIFICATIONS_SUCCESS",
  DELETE_NOTIFICATIONS_FAILURE: "DELETE_NOTIFICATIONS_FAILURE",
};

export const itineraryAction = {
  ITINERTY_LIST_INITIATE: "ITINERTY_LIST_INITIATE",
  ITINERTY_LIST_SUCCESS: "ITINERTY_LIST_SUCCESS",
  ITINERTY_LIST_FAILURE: "ITINERTY_LIST_FAILURE",

  ITINERTY_ADD_INITIATE: "ITINERTY_ADD_INITIATE",
  ITINERTY_ADD_SUCCESS: "ITINERTY_ADD_SUCCESS",
  ITINERTY_ADD_FAILURE: "ITINERTY_ADD_FAILURE",

  ITINERTY_DELETE_INITIATE: "ITINERTY_DELETE_INITIATE",
  ITINERTY_DELETE_SUCCESS: "ITINERTY_DELETE_SUCCESS",
  ITINERTY_DELETE_FAILURE: "ITINERTY_DELETE_FAILURE",

  ASSIGN_STAFF_INITIATE: "ASSIGN_STAFF_INITIATE",
  ASSIGN_STAFF_SUCCESS: "ASSIGN_STAFF_SUCCESS",
  ASSIGN_STAFF_FAILURE: "ASSIGN_STAFF_FAILURE",

  UPDATE_REMINDER_INITIATE: "UPDATE_REMINDER_INITIATE",
  UPDATE_REMINDER_SUCCESS: "UPDATE_REMINDER_SUCCESS",
  UPDATE_REMINDER_FAILURE: "UPDATE_REMINDER_FAILURE",

  ITINERTY_UPDATE_INITIATE: "ITINERTY_UPDATE_INITIATE",
  ITINERTY_UPDATE_SUCCESS: "ITINERTY_UPDATE_SUCCESS",
  ITINERTY_UPDATE_FAILURE: "ITINERTY_UPDATE_FAILURE",

  ITINERTY_REQUEST_ACCESS_INITIATE: "ITINERTY_REQUEST_ACCESS_INITIATE",
  ITINERTY_REQUEST_ACCESS_SUCCESS: "ITINERTY_REQUEST_ACCESS_SUCCESS",
  ITINERTY_REQUEST_ACCESS_FAILURE: "ITINERTY_REQUEST_ACCESS_FAILURE",

  ITINERTY_PROVIDE_ACCESS_INITIATE: "ITINERTY_PROVIDE_ACCESS_INITIATE",
  ITINERTY_PROVIDE_ACCESS_SUCCESS: "ITINERTY_PROVIDE_ACCESS_SUCCESS",
  ITINERTY_PROVIDE_ACCESS_FAILURE: "ITINERTY_PROVIDE_ACCESS_FAILURE",

  LIST_REQUEST_ACCESS_INITIATE: "LIST_REQUEST_ACCESS_INITIATE",
  LIST_REQUEST_ACCESS_SUCCESS: "LIST_REQUEST_ACCESS_SUCCESS",
  LIST_REQUEST_ACCESS_FAILURE: "LIST_REQUEST_ACCESS_FAILURE",

  MANAGE_ACCESS_TO_MY_ITINERARY_LIST_INITIATE:
    "MANAGE_ACCESS_TO_MY_ITINERARY_LIST_INITIATE",
  MANAGE_ACCESS_TO_MY_ITINERARY_LIST_SUCCESS:
    "MANAGE_ACCESS_TO_MY_ITINERARY_LIST_SUCCESS",
  MANAGE_ACCESS_TO_MY_ITINERARY_LIST_FAILURE:
    "MANAGE_ACCESS_TO_MY_ITINERARY_LIST_FAILURE",

  SEARCH_GIVE_ACCESS_TO_ITINERARY_USER_INITIATE:
    "SEARCH_GIVE_ACCESS_TO_ITINERARY_USER_INITIATE",
  SEARCH_GIVE_ACCESS_TO_ITINERARY_USER_SUCCESS:
    "SEARCH_GIVE_ACCESS_TO_ITINERARY_USER_SUCCESS",
  SEARCH_GIVE_ACCESS_TO_ITINERARY_USER_FAILURE:
    "SEARCH_GIVE_ACCESS_TO_ITINERARY_USER_FAILURE",

  GIVE_ACCESS_TO_ITINERARY_USER_LIST_INITIATE:
    "GIVE_ACCESS_TO_ITINERARY_USER_LIST_INITIATE",
  GIVE_ACCESS_TO_ITINERARY_USER_LIST_SUCCESS:
    "GIVE_ACCESS_TO_ITINERARY_USER_LIST_SUCCESS",
  GIVE_ACCESS_TO_ITINERARY_USER_LIST_FAILURE:
    "GIVE_ACCESS_TO_ITINERARY_USER_LIST_FAILURE",

  ITINERARY_REQUEST_WITHDRAWN_INITIATE: "ITINERARY_REQUEST_WITHDRAWN_INITIATE",
  ITINERARY_REQUEST_WITHDRAWN_SUCCESS: "ITINERARY_REQUEST_WITHDRAWN_SUCCESS",
  ITINERARY_REQUEST_WITHDRAWN_FAILURE: "ITINERARY_REQUEST_WITHDRAWN_FAILURE",

  ITINERARY_REQUEST_REVOKE_INITIATE: "ITINERARY_REQUEST_REVOKE_INITIATE",
  ITINERARY_REQUEST_REVOKE_SUCCESS: "ITINERARY_REQUEST_REVOKE_SUCCESS",
  ITINERARY_REQUEST_REVOKE_FAILURE: "ITINERARY_REQUEST_REVOKE_FAILURE",

  ITINERARY_REQUEST_DENY_INITIATE: "ITINERARY_REQUEST_DENY_INITIATE",
  ITINERARY_REQUEST_DENY_SUCCESS: "ITINERARY_REQUEST_DENY_SUCCESS",
  ITINERARY_REQUEST_DENY_FAILURE: "ITINERARY_REQUEST_DENY_FAILURE",

  ITINERARY_REQUEST_APPROVE_INITIATE: "ITINERARY_REQUEST_APPROVE_INITIATE",
  ITINERARY_REQUEST_APPROVE_SUCCESS: "ITINERARY_REQUEST_APPROVE_SUCCESS",
  ITINERARY_REQUEST_APPROVE_FAILURE: "ITINERARY_REQUEST_APPROVE_FAILURE",

  MANAGE_ACCESS_TO_USER_ITINERARY_LIST_INITIATE:
    "MANAGE_ACCESS_TO_USER_ITINERARY_LIST_INITIATE",
  MANAGE_ACCESS_TO_USER_ITINERARY_LIST_SUCCESS:
    "MANAGE_ACCESS_TO_USER_ITINERARY_LIST_SUCCESS",
  MANAGE_ACCESS_TO_USER_ITINERARY_LIST_FAILURE:
    "MANAGE_ACCESS_TO_USER_ITINERARY_LIST_FAILURE",

  GET_SHARED_ITINERARY_INITIATE: "GET_SHARED_ITINERARY_INITIATE",
  GET_SHARED_ITINERARY_SUCCESS: "GET_SHARED_ITINERARY_SUCCESS",
  GET_SHARED_ITINERARY_FAILURE: "GET_SHARED_ITINERARY_FAILURE",
};
export const warrantyAction = {
  LIST_PROJECT_JOBS_INITIATE: "LIST_PROJECT_JOBS_INITIATE",
  LIST_PROJECT_JOBS_SUCCESS: "LIST_PROJECT_JOBS_SUCCESS",
  LIST_PROJECT_JOBS_FAILURE: "LIST_PROJECT_JOBS_FAILURE",

  CREATE_WARRANTY_FOLDER_INITIATE: "CREATE_WARRANTY_FOLDER_INITIATE",
  CREATE_WARRANTY_FOLDER_SUCCESS: "CREATE_WARRANTY_FOLDER_SUCCESS",
  CREATE_WARRANTY_FOLDER_FAILURE: "CREATE_WARRANTY_FOLDER_FAILURE",

  WARRANTY_FOLDER_LIST_INITIATE: "WARRANTY_FOLDER_LIST_INITIATE",
  WARRANTY_FOLDER_LIST_SUCCESS: "WARRANTY_FOLDER_LIST_SUCCESS",
  WARRANTY_FOLDER_LIST_FAILURE: "WARRANTY_FOLDER_LIST_FAILURE",

  WARRANTY_FOLDER_SEARCH_LIST_INITIATE: "WARRANTY_FOLDER_SEARCH_LIST_INITIATE",
  WARRANTY_FOLDER_SEARCH_LIST_SUCCESS: "WARRANTY_FOLDER_SEARCH_LIST_SUCCESS",
  WARRANTY_FOLDER_SEARCH_LIST_FAILURE: "WARRANTY_FOLDER_SEARCH_LIST_FAILURE",

  WARRANTY_FOLDER_DELETE_INITIATE: "WARRANTY_FOLDER_DELETE_INITIATE",
  WARRANTY_FOLDER_DELETE_SUCCESS: "WARRANTY_FOLDER_DELETE_SUCCESS",
  WARRANTY_FOLDER_DELETE_FAILURE: "WARRANTY_FOLDER_DELETE_FAILURE",

  WARRANTY_FOLDER_HISTORY_LIST_INITIATE:
    "WARRANTY_FOLDER_HISTORY_LIST_INITIATE",
  WARRANTY_FOLDER_HISTORY_LIST_SUCCESS: "WARRANTY_FOLDER_HISTORY_LIST_SUCCESS",
  WARRANTY_FOLDER_HISTORY_LIST_FAILURE: "WARRANTY_FOLDER_HISTORY_LIST_FAILURE",

  CREATE_NEW_FILE_INITIATE: "CREATE_NEW_FILE_INITIATE",
  CREATE_NEW_FILE_SUCCESS: "CREATE_NEW_FILE_SUCCESS",
  CREATE_NEW_FILE_FAILURE: "CREATE_NEW_FILE_FAILURE",

  NEW_FILE_LIST_INITIATE: "NEW_FILE_LIST_INITIATE",
  NEW_FILE_LIST_SUCCESS: "NEW_FILE_LIST_SUCCESS",
  NEW_FILE_LIST_FAILURE: "NEW_FILE_LIST_FAILURE",

  NEW_FILE_LIST_SEARCH_INITIATE: "NEW_FILE_LIST_SEARCH_INITIATE",
  NEW_FILE_LIST_SEARCH_SUCCESS: "NEW_FILE_LIST_SEARCH_SUCCESS",
  NEW_FILE_LIST_SEARCH_FAILURE: "NEW_FILE_LIST_SEARCH_FAILURE",

  WARRANTY_HISTORY_LIST_INITIATE: "WARRANTY_HISTORY_LIST_INITIATE",
  WARRANTY_HISTORY_LIST_SUCCESS: "WARRANTY_HISTORY_LIST_SUCCESS",
  WARRANTY_HISTORY_LIST_FAILURE: "WARRANTY_HISTORY_LIST_FAILURE",

  WARRANTY_PENDING_FILE_EVENTS_LIST_INITIATE:
    "WARRANTY_PENDING_FILE_EVENTS_LIST_INITIATE",
  WARRANTY_PENDING_FILE_EVENTS_LIST_SUCCESS:
    "WARRANTY_PENDING_FILE_EVENTS_LIST_SUCCESS",
  WARRANTY_PENDING_FILE_EVENTS_LIST_FAILURE:
    "WARRANTY_PENDING_FILE_EVENTS_LIST_FAILURE",

  WARRANTY_VIEW_DOCS_INITIATE: "WARRANTY_VIEW_DOCS_INITIATE",
  WARRANTY_VIEW_DOCS_SUCCESS: "WARRANTY_VIEW_DOCS_SUCCESS",
  WARRANTY_VIEW_DOCS_FAILURE: "WARRANTY_VIEW_DOCS_FAILURE",

  WARRANTY_UPDATE_DOCS_INITIATE: "WARRANTY_UPDATE_DOCS_INITIATE",
  WARRANTY_UPDATE_DOCS_SUCCESS: "WARRANTY_UPDATE_DOCS_SUCCESS",
  WARRANTY_UPDATE_DOCS_FAILURE: "WARRANTY_UPDATE_DOCS_FAILURE",

  CREATE_WARRANTY_SIGNATURE_INITIATE: "CREATE_WARRANTY_SIGNATURE_INITIATE",
  CREATE_WARRANTY_SIGNATURE_SUCCESS: "CREATE_WARRANTY_SIGNATURE_SUCCESS",
  CREATE_WARRANTY_SIGNATURE_FAILURE: "CREATE_WARRANTY_SIGNATURE_FAILURE",

  SEND_FOR_CLIENT_SIGNATURE_INITIATE: "SEND_FOR_CLIENT_SIGNATURE_INITIATE",
  SEND_FOR_CLIENT_SIGNATURE_SUCCESS: "SEND_FOR_CLIENT_SIGNATURE_SUCCESS",
  SEND_FOR_CLIENT_SIGNATURE_FAILURE: "SEND_FOR_CLIENT_SIGNATURE_FAILURE",

  SEND_FOR_UPLOAD_CLIENT_SIGNATURE_INITIATE:
    "SEND_FOR_UPLOAD_CLIENT_SIGNATURE_INITIATE",
  SEND_FOR_UPLOAD_CLIENT_SIGNATURE_SUCCESS:
    "SEND_FOR_UPLOAD_CLIENT_SIGNATURE_SUCCESS",
  SEND_FOR_UPLOAD_CLIENT_SIGNATURE_FAILURE:
    "SEND_FOR_UPLOAD_CLIENT_SIGNATURE_FAILURE",

  GET_DRAFT_WARRANTY_INITIATE: "GET_DRAFT_WARRANTY_INITIATE",
  GET_DRAFT_WARRANTY_SUCCESS: "GET_DRAFT_WARRANTY_SUCCESS",
  GET_DRAFT_WARRANTY_FAILURE: "GET_DRAFT_WARRANTY_FAILURE",

  ADD_NOTES_WARRANTY_INITIATE: "ADD_NOTES_WARRANTY_INITIATE",
  ADD_NOTES_WARRANTY_SUCCESS: "ADD_NOTES_WARRANTY_SUCCESS",
  ADD_NOTES_WARRANTY_FAILURE: "ADD_NOTES_WARRANTY_FAILURE",

  WARRANTY_SUPPORT_DOCS_UPDATE_INITIATE:
    "WARRANTY_SUPPORT_DOCS_UPDATE_INITIATE",
  WARRANTY_SUPPORT_DOCS_UPDATE_SUCCESS: "WARRANTY_SUPPORT_DOCS_UPDATE_SUCCESS",
  WARRANTY_SUPPORT_DOCS_UPDATE_FAILURE: "WARRANTY_SUPPORT_DOCS_UPDATE_FAILURE",

  ADD_USER_URGENCY_INITIATE: "ADD_USER_URGENCY_INITIATE",
  ADD_USER_URGENCY_SUCCESS: "ADD_USER_URGENCY_SUCCESS",
  ADD_USER_URGENCY_FAILURE: "ADD_USER_URGENCY_FAILURE",

  ALL_SYSTEM_URGENCY_LIST_INITIATE: "ALL_SYSTEM_URGENCY_LIST_INITIATE",
  ALL_SYSTEM_URGENCY_LIST_SUCCESS: "ALL_SYSTEM_URGENCY_LIST_SUCCESS",
  ALL_SYSTEM_URGENCY_LIST_FAILURE: "ALL_SYSTEM_URGENCY_LIST_FAILURE",

  ALL_URGENCY_LIST_INITIATE: "ALL_URGENCY_LIST_INITIATE",
  ALL_URGENCY_LIST_SUCCESS: "ALL_URGENCY_LIST_SUCCESS",
  ALL_URGENCY_LIST_FAILURE: "ALL_URGENCY_LIST_FAILURE",

  ALL_USER_URGENCY_LIST_INITIATE: "ALL_USER_URGENCY_LIST_INITIATE",
  ALL_USER_URGENCY_LIST_SUCCESS: "ALL_USER_URGENCY_LIST_SUCCESS",
  ALL_USER_URGENCY_LIST_FAILURE: "ALL_USER_URGENCY_LIST_FAILURE",

  CREATE_REQUEST_ITEM_INITIATE: "CREATE_REQUEST_ITEM_INITIATE",
  CREATE_REQUEST_ITEM_SUCCESS: "CREATE_REQUEST_ITEM_SUCCESS",
  CREATE_REQUEST_ITEM_FAILURE: "CREATE_REQUEST_ITEM_FAILURE",

  ALL_REQUEST_ITEM_LIST_INITIATE: "ALL_REQUEST_ITEM_LIST_INITIATE",
  ALL_REQUEST_ITEM_LIST_SUCCESS: "ALL_REQUEST_ITEM_LIST_SUCCESS",
  ALL_REQUEST_ITEM_LIST_FAILURE: "ALL_REQUEST_ITEM_LIST_FAILURE",

  ALL_SERVICE_TYPE_LIST_INITIATE: "ALL_SERVICE_TYPE_LIST_INITIATE",
  ALL_SERVICE_TYPE_LIST_SUCCESS: "ALL_SERVICE_TYPE_LIST_SUCCESS",
  ALL_SERVICE_TYPE_LIST_FAILURE: "ALL_SERVICE_TYPE_LIST_FAILURE",

  ALL_ITEM_TYPE_LIST_INITIATE: "ALL_ITEM_TYPE_LIST_INITIATE",
  ALL_ITEM_TYPE_LIST_SUCCESS: "ALL_ITEM_TYPE_LIST_SUCCESS",
  ALL_ITEM_TYPE_LIST_FAILURE: "ALL_ITEM_TYPE_LIST_FAILURE",

  CLEAR_TABLE_DATA_INITIATE: "CLEAR_TABLE_DATA_INITIATE",
  CLEAR_TABLE_DATA_SUCCESS: "CLEAR_TABLE_DATA_SUCCESS",
  CLEAR_TABLE_DATA_FAILURE: "CLEAR_TABLE_DATA_FAILURE",

  DELETE_REQUEST_ITEM_INITIATE: "DELETE_REQUEST_ITEM_INITIATE",
  DELETE_REQUEST_ITEM_SUCCESS: "DELETE_REQUEST_ITEM_SUCCESS",
  DELETE_REQUEST_ITEM_FAILURE: "DELETE_REQUEST_ITEM_FAILURE",

  WARRANTY_EDITABLE_BY_CLIENT_INITIATE: "WARRANTY_EDITABLE_BY_CLIENT_INITIATE",
  WARRANTY_EDITABLE_BY_CLIENT_SUCCESS: "WARRANTY_EDITABLE_BY_CLIENT_SUCCESS",
  WARRANTY_EDITABLE_BY_CLIENT_FAILURE: "WARRANTY_EDITABLE_BY_CLIENT_FAILURE",

  WARRANTY_UPDATE_INITIATE: "WARRANTY_UPDATE_INITIATE",
  WARRANTY_UPDATE_SUCCESS: "WARRANTY_UPDATE_SUCCESS",
  WARRANTY_UPDATE_FAILURE: "WARRANTY_UPDATE_FAILURE",

  ITEM_SUPPORT_DOC_UPDATE_INITIATE: "ITEM_SUPPORT_DOC_UPDATE_INITIATE",
  ITEM_SUPPORT_DOC_UPDATE_SUCCESS: "ITEM_SUPPORT_DOC_UPDATE_SUCCESS",
  ITEM_SUPPORT_DOC_UPDATE_FAILURE: "ITEM_SUPPORT_DOC_UPDATE_FAILURE",

  CREATE_NEW_ITEM_TYPE_INITIATE: "CREATE_NEW_ITEM_TYPE_INITIATE",
  CREATE_NEW_ITEM_TYPE_SUCCESS: "CREATE_NEW_ITEM_TYPE_SUCCESS",
  CREATE_NEW_ITEM_TYPE_FAILURE: "CREATE_NEW_ITEM_TYPE_FAILURE",

  CREATE_NEW_SERVICE_TYPE_INITIATE: "CREATE_NEW_SERVICE_TYPE_INITIATE",
  CREATE_NEW_SERVICE_TYPE_SUCCESS: "CREATE_NEW_SERVICE_TYPE_SUCCESS",
  CREATE_NEW_SERVICE_TYPE_FAILURE: "CREATE_NEW_SERVICE_TYPE_FAILURE",

  ALL_SYSTEM_SERVICE_TYPE_LIST_INITIATE:
    "ALL_SYSTEM_SERVICE_TYPE_LIST_INITIATE",
  ALL_SYSTEM_SERVICE_TYPE_LIST_SUCCESS: "ALL_SYSTEM_SERVICE_TYPE_LIST_SUCCESS",
  ALL_SYSTEM_SERVICE_TYPE_LIST_FAILURE: "ALL_SYSTEM_SERVICE_TYPE_LIST_FAILURE",

  ALL_USER_SERVICE_TYPE_LIST_INITIATE: "ALL_USER_SERVICE_TYPE_LIST_INITIATE",
  ALL_USER_SERVICE_TYPE_LIST_SUCCESS: "ALL_USER_SERVICE_TYPE_LIST_SUCCESS",
  ALL_USER_SERVICE_TYPE_LIST_FAILURE: "ALL_USER_SERVICE_TYPE_LIST_FAILURE",

  DELETE_SERVICE_TYPE_INITIATE: "DELETE_SERVICE_TYPE_INITIATE",
  DELETE_SERVICE_TYPE_SUCCESS: "DELETE_SERVICE_TYPE_SUCCESS",
  DELETE_SERVICE_TYPE_FAILURE: "DELETE_SERVICE_TYPE_FAILURE",

  ALL_SYSTEM_ITEM_TYPE_LIST_INITIATE: "ALL_SYSTEM_SERVICE_ITEM_LIST_INITIATE",
  ALL_SYSTEM_ITEM_TYPE_LIST_SUCCESS: "ALL_SYSTEM_SERVICE_ITEM_LIST_SUCCESS",
  ALL_SYSTEM_ITEM_TYPE_LIST_FAILURE: "ALL_SYSTEM_SERVICE_ITEM_LIST_FAILURE",

  ALL_USER_ITEM_TYPE_LIST_INITIATE: "ALL_USER_ITEM_TYPE_LIST_INITIATE",
  ALL_USER_ITEM_TYPE_LIST_SUCCESS: "ALL_USER_ITEM_TYPE_LIST_SUCCESS",
  ALL_USER_ITEM_TYPE_LIST_FAILURE: "ALL_USER_ITEM_TYPE_LIST_FAILURE",

  DELETE_URGENCY_INITIATE: "DELETE_URGENCY_INITIATE",
  DELETE_URGENCY_SUCCESS: "DELETE_URGENCY_SUCCESS",
  DELETE_URGENCY_FAILURE: "DELETE_URGENCY_FAILURE",

  WORK_ORDER_REQUEST_ITEM_LIST_INITIATE:
    "WORK_ORDER_REQUEST_ITEM_LIST_INITIATE",
  WORK_ORDER_REQUEST_ITEM_LIST_SUCCESS: "WORK_ORDER_REQUEST_ITEM_LIST_SUCCESS",
  WORK_ORDER_REQUEST_ITEM_LIST_FAILURE: "WORK_ORDER_REQUEST_ITEM_LIST_FAILURE",

  LIST_OF_VENDOR_TYPES_INITIATE: "LIST_OF_VENDOR_TYPES_INITIATE",
  LIST_OF_VENDOR_TYPES_SUCCESS: "LIST_OF_VENDOR_TYPES_SUCCESS",
  LIST_OF_VENDOR_TYPES_FAILURE: "LIST_OF_VENDOR_TYPES_FAILURE",

  VENDOR_LIST_INITIATE: "VENDOR_LIST_INITIATE",
  VENDOR_LIST_SUCCESS: "VENDOR_LIST_SUCCESS",
  VENDOR_LIST_FAILURE: "VENDOR_LIST_FAILURE",

  CREATE_WORK_ORDER_INITIATE: "CREATE_WORK_ORDER_INITIATE",
  CREATE_WORK_ORDER_SUCCESS: "CREATE_WORK_ORDER_SUCCESS",
  CREATE_WORK_ORDER_FAILURE: "CREATE_WORK_ORDER_FAILURE",

  GET_ALL_WARRANTY_LIST_INITIATE: "GET_ALL_WARRANTY_LIST_INITIATE",
  GET_ALL_WARRANTY_LIST_SUCCESS: "GET_ALL_WARRANTY_LIST_SUCCESS",
  GET_ALL_WARRANTY_LIST_FAILURE: "GET_ALL_WARRANTY_LIST_FAILURE",

  GET_ALL_WORK_ORDER_LIST_INITIATE: "GET_ALL_WORK_ORDER_LIST_INITIATE",
  GET_ALL_WORK_ORDER_LIST_SUCCESS: "GET_ALL_WORK_ORDER_LIST_SUCCESS",
  GET_ALL_WORK_ORDER_LIST_FAILURE: "GET_ALL_WORK_ORDER_LIST_FAILURE",

  WARRANTY_SUPPORT_DOC_UPDATE_INITIATE: "WARRANTY_SUPPORT_DOC_UPDATE_INITIATE",
  WARRANTY_SUPPORT_DOC_UPDATE_SUCCESS: "WARRANTY_SUPPORT_DOC_UPDATE_SUCCESS",
  WARRANTY_SUPPORT_DOC_UPDATE_FAILURE: "WARRANTY_SUPPORT_DOC_UPDATE_FAILURE",

  SUPPORTING_DOCS_LIST_INITIATE: "SUPPORTING_DOCS_LIST_INITIATE",
  SUPPORTING_DOCS_LIST_SUCCESS: "SUPPORTING_DOCS_LIST_SUCCESS",
  SUPPORTING_DOCS_LIST_FAILURE: "SUPPORTING_DOCS_LIST_FAILURE",

  DELETE_WARRANTY_INITIATE: "DELETE_WARRANTY_INITIATE",
  DELETE_WARRANTY_SUCCESS: "DELETE_WARRANTY_SUCCESS",
  DELETE_WARRANTY_FAILURE: "DELETE_WARRANTY_FAILURE",

  DELETE_WORK_ORDER_INITIATE: "DELETE_WORK_ORDER_INITIATE",
  DELETE_WORK_ORDER_SUCCESS: "DELETE_WORK_ORDER_SUCCESS",
  DELETE_WORK_ORDER_FAILURE: "DELETE_WORK_ORDER_FAILURE",

  INSPECTOR_LIST_INITIATE: "INSPECTOR_LIST_INITIATE",
  INSPECTOR_LIST_SUCCESS: "INSPECTOR_LIST_SUCCESS",
  INSPECTOR_LIST_FAILURE: "INSPECTOR_LIST_FAILURE",

  CREATE_INSPECTOR_INITIATE: "CREATE_INSPECTOR_INITIATE",
  CREATE_INSPECTOR_SUCCESS: "CREATE_INSPECTOR_SUCCESS",
  CREATE_INSPECTOR_FAILURE: "CREATE_INSPECTOR_FAILURE",

  GET_DRAFT_WORK_ORDER_INITIATE: "GET_DRAFT_WORK_ORDER_INITIATE",
  GET_DRAFT_WORK_ORDER_SUCCESS: "GET_DRAFT_WORK_ORDER_SUCCESS",
  GET_DRAFT_WORK_ORDER_FAILURE: "GET_DRAFT_WORK_ORDER_FAILURE",

  UPDATE_WORK_ORDER_INITIATE: "UPDATE_WORK_ORDER_INITIATE",
  UPDATE_WORK_ORDER_SUCCESS: "UPDATE_WORK_ORDER_SUCCESS",
  UPDATE_WORK_ORDER_FAILURE: "UPDATE_WORK_ORDER_FAILURE",

  GET_SELECTED_REQUEST_ITEMS_INITIATE: "GET_SELECTED_REQUEST_ITEMS_INITIATE",
  GET_SELECTED_REQUEST_ITEMS_SUCCESS: "GET_SELECTED_REQUEST_ITEMS_SUCCESS",
  GET_SELECTED_REQUEST_ITEMS_FAILURE: "GET_SELECTED_REQUEST_ITEMS_FAILURE",

  GET_SELECTED_WARRANTY_REQUEST_ITEMS_INITIATE:
    "GET_SELECTED_WARRANTY_REQUEST_ITEMS_INITIATE",
  GET_SELECTED_WARRANTY_REQUEST_ITEMS_SUCCESS:
    "GET_SELECTED_WARRANTY_REQUEST_ITEMS_SUCCESS",
  GET_SELECTED_WARRANTY_REQUEST_ITEMS_FAILURE:
    "GET_SELECTED_WARRANTY_REQUEST_ITEMS_FAILURE",

  GET_WARRANTY_SETTING_DISCUSSION_INITIATE:
    "GET_WARRANTY_SETTING_DISCUSSION_INITIATE",
  GET_WARRANTY_SETTING_DISCUSSION_SUCCESS:
    "GET_WARRANTY_SETTING_DISCUSSION_SUCCESS",
  GET_WARRANTY_SETTING_DISCUSSION_FAILURE:
    "GET_WARRANTY_SETTING_DISCUSSION_FAILURE",

  UPDATE_WARRANTY_SETTING_DISCUSSION_INITIATE:
    "UPDATE_WARRANTY_SETTING_DISCUSSION_INITIATE",
  UPDATE_WARRANTY_SETTING_DISCUSSION_SUCCESS:
    "UPDATE_WARRANTY_SETTING_DISCUSSION_SUCCESS",
  UPDATE_WARRANTY_SETTING_DISCUSSION_FAILURE:
    "UPDATE_WARRANTY_SETTING_DISCUSSION_FAILURE",

  GET_WORK_ORDER_SETTING_DISCUSSION_INITIATE:
    "GET_WORK_ORDER_SETTING_DISCUSSION_INITIATE",
  GET_WORK_ORDER_SETTING_DISCUSSION_SUCCESS:
    "GET_WORK_ORDER_SETTING_DISCUSSION_SUCCESS",
  GET_WORK_ORDER_SETTING_DISCUSSION_FAILURE:
    "GET_WORK_ORDER_SETTING_DISCUSSION_FAILURE",

  UPDATE_WORK_ORDER_SETTING_DISCUSSION_INITIATE:
    "UPDATE_WORK_ORDER_SETTING_DISCUSSION_INITIATE",
  UPDATE_WORK_ORDER_SETTING_DISCUSSION_SUCCESS:
    "UPDATE_WORK_ORDER_SETTING_DISCUSSION_SUCCESS",
  UPDATE_WORK_ORDER_SETTING_DISCUSSION_FAILURE:
    "UPDATE_WORK_ORDER_SETTING_DISCUSSION_FAILURE",

  GET_WARRANTY_JOBS_INITIATE: "GET_WARRANTY_JOBS_INITIATE",
  GET_WARRANTY_JOBS_SUCCESS: "GET_WARRANTY_JOBS_SUCCESS",
  GET_WARRANTY_JOBS_FAILURE: "GET_WARRANTY_JOBS_FAILURE",

  SHOW_IN_WARRANTY_JOBS_INITIATE: "SHOW_IN_WARRANTY_JOBS_INITIATE",
  SHOW_IN_WARRANTY_JOBS_SUCCESS: "SHOW_IN_WARRANTY_JOBS_SUCCESS",
  SHOW_IN_WARRANTY_JOBS_FAILURE: "SHOW_IN_WARRANTY_JOBS_FAILURE",

  UPDATE_SERVICE_TYPE_INITIATE: "UPDATE_SERVICE_TYPE_INITIATE",
  UPDATE_SERVICE_TYPE_SUCCESS: "UPDATE_SERVICE_TYPE_SUCCESS",
  UPDATE_SERVICE_TYPE_FAILURE: "UPDATE_SERVICE_TYPE_FAILURE",

  UPDATE_INSPECTOR_INITIATE: "UPDATE_INSPECTOR_INITIATE",
  UPDATE_INSPECTOR_SUCCESS: "UPDATE_INSPECTOR_SUCCESS",
  UPDATE_INSPECTOR_FAILURE: "UPDATE_INSPECTOR_FAILURE",

  WORK_ORDER_ACTION_INITIATE: "WORK_ORDER_ACTION_INITIATE",
  WORK_ORDER_ACTION_SUCCESS: "WORK_ORDER_ACTION_SUCCESS",
  WORK_ORDER_ACTION_FAILURE: "WORK_ORDER_ACTION_FAILURE",

  WORK_ORDER_ACTION_SELECTION_LIST_TABLE_INITIATE:
    "WORK_ORDER_ACTION_SELECTION_LIST_TABLE_INITIATE",
  WORK_ORDER_ACTION_SELECTION_LIST_TABLE_SUCCESS:
    "WORK_ORDER_ACTION_SELECTION_LIST_TABLE_SUCCESS",
  WORK_ORDER_ACTION_SELECTION_LIST_TABLE_FAILURE:
    "WORK_ORDER_ACTION_SELECTION_LIST_TABLE_FAILURE",

  CANCEL_SELECTION_WORK_FLOW_INITIATE: "CANCEL_SELECTION_WORK_FLOW_INITIATE",
  CANCEL_SELECTION_WORK_FLOW_SUCCESS: "CANCEL_SELECTION_WORK_FLOW_SUCCESS",
  CANCEL_SELECTION_WORK_FLOW_FAILURE: "CANCEL_SELECTION_WORK_FLOW_FAILURE",

  RESEND_SELECTION_WORK_FLOW_INITIATE: "RESEND_SELECTION_WORK_FLOW_INITIATE",
  RESEND_SELECTION_WORK_FLOW_SUCCESS: "RESEND_SELECTION_WORK_FLOW_SUCCESS",
  RESEND_SELECTION_WORK_FLOW_FAILURE: "RESEND_SELECTION_WORK_FLOW_FAILURE",

  UPDATE_WARRANTY_ITEM_INITIATE: "UPDATE_WARRANTY_ITEM_INITIATE",
  UPDATE_WARRANTY_ITEM_SUCCESS: "UPDATE_WARRANTY_ITEM_SUCCESS",
  UPDATE_WARRANTY_ITEM_FAILURE: "UPDATE_WARRANTY_ITEM_FAILURE",

  DELETE_ITEM_TYPE_INITIATE:"DELETE_ITEM_TYPE_INITIATE",
  DELETE_ITEM_TYPE_SUCCESS: "DELETE_ITEM_TYPE_SUCCESS",
  DELETE_ITEM_TYPE_FAILURE: "DELETE_ITEM_TYPE_FAILURE",
  
  UPDATE_SERVICE_TYPE_INITIATE:"UPDATE_SERVICE_TYPE_INITIATE",
  UPDATE_SERVICE_TYPE_SUCCESS: "UPDATE_SERVICE_TYPE_SUCCESS",
  UPDATE_SERVICE_TYPE_FAILURE: "UPDATE_SERVICE_TYPE_FAILURE",

  UPDATE_ITEM_TYPE_INITIATE:"UPDATE_ITEM_TYPE_INITIATE",
  UPDATE_ITEM_TYPE_SUCCESS: "UPDATE_ITEM_TYPE_SUCCESS",
  UPDATE_ITEM_TYPE_FAILURE: "UPDATE_ITEM_TYPE_FAILURE",

  UPDATE_URGENCY_INITIATE:"UPDATE_URGENCY_INITIATE",
  UPDATE_URGENCY_SUCCESS: "UPDATE_URGENCY_SUCCESS",
  UPDATE_URGENCY_FAILURE: "UPDATE_URGENCY_FAILURE",

  GET_PRIMARY_CLIENT_BY_JOB_INITIATE:"GET_PRIMARY_CLIENT_BY_JOB_INITIATE",
  GET_PRIMARY_CLIENT_BY_JOB_SUCCESS: "GET_PRIMARY_CLIENT_BY_JOB_SUCCESS",
  GET_PRIMARY_CLIENT_BY_JOB_FAILURE: "GET_PRIMARY_CLIENT_BY_JOB_FAILURE",
};

export const todoAction = {
  ADD_TODO_INITIATE: "ADD_TODO_INITIATE",
  ADD_TODO_SUCCESS: "ADD_TODO_SUCCESS",
  ADD_TODO_FAILURE: "ADD_TODO_FAILURE",

  DELETE_TODO_INITIATE: "DELETE_TODO_INITIATE",
  DELETE_TODO_SUCCESS: "DELETE_TODO_SUCCESS",
  DELETE_TODO_FAILURE: "DELETE_TODO_FAILURE",

  TODO_JOB_LIST_INITIATE: "TODO_JOB_LIST_INITIATE",
  TODO_JOB_LIST_SUCCESS: "TODO_JOB_LIST_SUCCESS",
  TODO_JOB_LIST_FAILURE: "TODO_JOB_LIST_FAILURE",

  CREATED_TODO_LIST_INITIATE: "CREATED_TODO_LIST_INITIATE",
  CREATED_TODO_LIST_SUCCESS: "CREATED_TODO_LIST_SUCCESS",
  CREATED_TODO_LIST_FAILURE: "CREATED_TODO_LIST_FAILURE",

  INPROGRESS_TODO_LIST_INITIATE: "INPROGRESS_TODO_LIST_INITIATE",
  INPROGRESS_TODO_LIST_SUCCESS: "INPROGRESS_TODO_LIST_SUCCESS",
  INPROGRESS_TODO_LIST_FAILURE: "INPROGRESS_TODO_LIST_FAILURE",

  COMPLETED_TODO_LIST_INITIATE: "COMPLETED_TODO_LIST_INITIATE",
  COMPLETED_TODO_LIST_SUCCESS: "COMPLETED_TODO_LIST_SUCCESS",
  COMPLETED_TODO_LIST_FAILURE: "COMPLETED_TODO_LIST_FAILURE",

  TODO_MEMBER_LIST_INITIATE: "TODO_MEMBER_LIST_INITIATE",
  TODO_MEMBER_LIST_SUCCESS: "TODO_MEMBER_LIST_SUCCESS",
  TODO_MEMBER_LIST_FAILURE: "TODO_MEMBER_LIST_FAILURE",

  TODO_HISTORY_LIST_INITIATE: "TODO_HISTORY_LIST_INITIATE",
  TODO_HISTORY_LIST_SUCCESS: "TODO_HISTORY_LIST_SUCCESS",
  TODO_HISTORY_LIST_FAILURE: "TODO_HISTORY_LIST_FAILURE",

  ASSIGN_TODO_INITIATE: "ASSIGN_TODO_INITIATE",
  ASSIGN_TODO_SUCCESS: "ASSIGN_TODO_SUCCESS",
  ASSIGN_TODO_FAILURE: "ASSIGN_TODO_FAILURE",

  ADD_GOOGLE_INITIATE: "ADD_GOOGLE_INITIATE",
  ADD_GOOGLE_SUCCESS: "ADD_GOOGLE_SUCCESS",
  ADD_GOOGLE_FAILURE: "ADD_GOOGLE_FAILURE",

  ADD_OUTLOOK_INITIATE: "ADD_OUTLOOK_INITIATE",
  ADD_OUTLOOK_SUCCESS: "ADD_OUTLOOK_SUCCESS",
  ADD_OUTLOOK_FAILURE: "ADD_OUTLOOK_FAILURE",

  ADD_YAHOO_INITIATE: "ADD_YAHOO_INITIATE",
  ADD_YAHOO_SUCCESS: "ADD_YAHOO_SUCCESS",
  ADD_YAHOO_FAILURE: "ADD_YAHOO_FAILURE",

  TODO_DETAILS_INITIATE: "TODO_DETAILS_INITIATE",
  TODO_DETAILS_SUCCESS: "TODO_DETAILS_SUCCESS",
  TODO_DETAILS_FAILURE: "TODO_DETAILS_FAILURE",

  UPDATE_TRADE_INITIATE: "UPDATE_TRADE_INITIATE",
  UPDATE_TRADE_SUCCESS: "UPDATE_TRADE_SUCCESS",
  UPDATE_TRADE_FAILURE: "UPDATE_TRADE_FAILURE",

  UPDATE_REMIANDER_INITIATE: "UPDATE_REMIANDER_INITIATE",
  UPDATE_REMIANDER_SUCCESS: "UPDATE_REMIANDER_SUCCESS",
  UPDATE_REMIANDER_FAILURE: "UPDATE_REMIANDER_FAILURE",

  UPDATE_STAFF_INITIATE: "UPDATE_STAFF_INITIATE",
  UPDATE_STAFF_SUCCESS: "UPDATE_STAFF_SUCCESS",
  UPDATE_STAFF_FAILURE: "UPDATE_STAFF_FAILURE",

  UPDATE_REMINDER_INITIATE: "UPDATE_REMINDER_INITIATE",
  UPDATE_REMINDER_SUCCESS: "UPDATE_REMINDER_SUCCESS",
  UPDATE_REMINDER_FAILURE: "UPDATE_REMINDER_FAILURE",

  UPDATE_TODO_INITIATE: "UPDATE_TODO_INITIATE",
  UPDATE_TODO_SUCCESS: "UPDATE_TODO_SUCCESS",
  UPDATE_TODO_FAILURE: "UPDATE_TODO_FAILURE",

  TODO_CALENDAR_INITIATE: "TODO_CALENDAR_INITIATE",
  TODO_CALENDAR_SUCCESS: "TODO_CALENDAR_SUCCESS",
  TODO_CALENDAR_FAILURE: "TODO_CALENDAR_FAILURE",

  UPDATE_ASSIGN_STAFF_INITIATE: "UPDATE_ASSIGN_STAFF_INITIATE",
  UPDATE_ASSIGN_STAFF_SUCCESS: "UPDATE_ASSIGN_STAFF_SUCCESS",
  UPDATE_ASSIGN_STAFF_FAILURE: "UPDATE_ASSIGN_STAFF_FAILURE",
};

export const costCodesActionTypes = {
  ADD_COST_PROFILE_INITIATE: "ADD_COST_PROFILE_INITIATE",
  ADD_COST_PROFILE_SUCCESS: "ADD_COST_PROFILE_SUCCESS",
  ADD_COST_PROFILE_FAILURE: "ADD_COST_PROFILE_FAILURE",

  GET_MASTER_COSTCATEGORY_INITIATE: "GET_MASTER_COSTCATEGORY_INITIATE",
  GET_MASTER_COSTCATEGORY_SUCCESS: "GET_MASTER_COSTCATEGORY_SUCCESS",
  GET_MASTER_COSTCATEGORY_FAILURE: "GET_MASTER_COSTCATEGORY_FAILURE",

  GET_MASTER_COSTCODE_INITIATE: "GET_MASTER_COSTCODE_INITIATE",
  GET_MASTER_COSTCODE_SUCCESS: "GET_MASTER_COSTCODE_SUCCESS",
  GET_MASTER_COSTCODE_FAILURE: "GET_MASTER_COSTCODE_FAILURE",

  GET_PROFORMA_SETTING_INITIATE: "GET_PROFORMA_SETTING_INITIATE",
  GET_PROFORMA_SETTING_SUCCESS: "GET_PROFORMA_SETTING_SUCCESS",
  GET_PROFORMA_SETTING_FAILURE: "GET_PROFORMA_SETTING_FAILURE",

  UPDATE_COST_PROFILE_INITIATE: "UPDATE_COST_PROFILE_INITIATE",
  UPDATE_COST_PROFILE_SUCCESS: "UPDATE_COST_PROFILE_SUCCESS",
  UPDATE_COST_PROFILE_FAILURE: "UPDATE_COST_PROFILE_FAILURE",

  ADD_SUB_COST_PROFILE_INITIATE: "ADD_SUB_COST_PROFILE_INITIATE",
  ADD_SUB_COST_PROFILE_SUCCESS: "ADD_SUB_COST_PROFILE_SUCCESS",
  ADD_SUB_COST_PROFILE_FAILURE: "ADD_SUB_COST_PROFILE_FAILURE",

  UPDATE_SUB_COST_PROFILE_INITIATE: "UPDATE_SUB_COST_PROFILE_INITIATE",
  UPDATE_SUB_COST_PROFILE_SUCCESS: "UPDATE_COST_PROFILE_SUCCESS",
  UPDATE_SUB_COST_PROFILE_FAILURE: "UPDATE_COST_PROFILE_FAILURE",

  DELETE_COST_PROFILE_INITIATE: "DELETE_COST_PROFILE_INITIATE",
  DELETE_COST_PROFILE_SUCCESS: "DELETE_COST_PROFILE_SUCCESS",
  DELETE_COST_PROFILE_FAILURE: "DELETE_COST_PROFILE_FAILURE",

  COST_PROFILE_JOB_LIST_INITIATE: "COST_PROFILE_JOB_LIST_INITIATE",
  COST_PROFILE_JOB_LIST_SUCCESS: "COST_PROFILE_JOB_LIST_SUCCESS",
  COST_PROFILE_JOB_LIST_FAILURE: "COST_PROFILE_JOB_LIST_FAILURE",

  COST_PROFILE_LIST_INITIATE: "COST_PROFILE_LIST_INITIATE",
  COST_PROFILE_LIST_SUCCESS: "COST_PROFILE_LIST_SUCCESS",
  COST_PROFILE_LIST_FAILURE: "COST_PROFILE_LIST_FAILURE",

  SUB_COST_PROFILE_JOB_LIST_INITIATE: "SUB_COST_PROFILE_JOB_LIST_INITIATE",
  SUB_COST_PROFILE_JOB_LIST_SUCCESS: "SUB_COST_PROFILE_JOB_LIST_SUCCESS",
  SUB_COST_PROFILE_JOB_LIST_FAILURE: "SUB_COST_PROFILE_JOB_LIST_FAILURE",

  DELETE_SUBCOST_PROFILE_INITIATE: "DELETE_SUBCOST_PROFILE_INITIATE",
  DELETE_SUBCOST_PROFILE_SUCCESS: "DELETE_SUBCOST_PROFILE_SUCCESS",
  DELETE_SUBCOST_PROFILE_FAILURE: "DELETE_SUBCOST_PROFILE_FAILURE",

  // CATEGORY
  ADD_CATEGORY_INITIATE: "ADD_CATEGORY_INITIATE",
  ADD_CATEGORY_SUCCESS: "ADD_CATEGORY_SUCCESS",
  ADD_CATEGORY_FAILURE: "ADD_CATEGORY_FAILURE",

  CATEGORY_LIST_INITIATE: "CATEGORY_LIST_INITIATE",
  CATEGORY_LIST_SUCCESS: "CATEGORY_LIST_SUCCESS",
  CATEGORY_LIST_FAILURE: "CATEGORY_LIST_FAILURE",

  UPDATE_CATEGORY_INITIATE: "UPDATE_CATEGORY_INITIATE",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",

  CATEGORY_DETAILS_INITIATE: "CATEGORY_DETAILS_INITIATE",
  CATEGORY_DETAILS_SUCCESS: "CATEGORY_DETAILS_SUCCESS",
  CATEGORY_DETAILS_FAILURE: "CATEGORY_DETAILS_FAILURE",

  DELETE_CATEGORY_INITIATE: "DELETE_CATEGORY_INITIATE",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",

  // COSTCODE

  ADD_COST_CODE_INITIATE: "ADD_COST_CODE_INITIATE",
  ADD_COST_CODE_SUCCESS: "ADD_COST_CODE_SUCCESS",
  ADD_COST_CODE_FAILURE: "ADD_COST_CODE_FAILURE",

  COST_CODE_LIST_INITIATE: "COST_CODE_LIST_INITIATE",
  COST_CODE_LIST_SUCCESS: "COST_CODE_LIST_SUCCESS",
  COST_CODE_LIST_FAILURE: "COST_CODE_LIST_FAILURE",

  UPDATE_COST_CODE_INITIATE: "UPDATE_COST_CODE_INITIATE",
  UPDATE_COST_CODE_SUCCESS: "UPDATE_COST_CODE_SUCCESS",
  UPDATE_COST_CODE_FAILURE: "UPDATE_COST_CODE_FAILURE",

  COST_CODE_DETAILS_INITIATE: "COST_CODE_DETAILS_INITIATE",
  COST_CODE_DETAILS_SUCCESS: "COST_CODE_DETAILS_SUCCESS",
  COST_CODE_DETAILS_FAILURE: "COST_CODE_DETAILS_FAILURE",

  DELETE_COST_CODE_INITIATE: "DELETE_COST_CODE_INITIATE",
  DELETE_COST_CODE_SUCCESS: "DELETE_COST_CODE_SUCCESS",
  DELETE_COST_CODE_FAILURE: "DELETE_COST_CODE_FAILURE",

  MASTER_SUBPROFILE_INITIATE: "MASTER_SUBPROFILE_INITIATE",
  MASTER_SUBPROFILE_SUCCESS: "MASTER_SUBPROFILE_SUCCESS",
  MASTER_SUBPROFILE_FAILURE: "MASTER_SUBPROFILE_FAILURE",

  MASTER_CATEGORY_INITIATE: "MASTER_CATEGORY_INITIATE",
  MASTER_CATEGORY_SUCCESS: "MASTER_CATEGORY_SUCCESS",
  MASTER_CATEGORY_FAILURE: "MASTER_CATEGORY_FAILURE",

  MASTER_COSTCODE_INITIATE: "MASTER_COSTCODE_INITIATE",
  MASTER_COSTCODE_SUCCESS: "MASTER_COSTCODE_SUCCESS",
  MASTER_COSTCODE_FAILURE: "MASTER_COSTCODE_FAILURE",

  GET_PROFORMA_PROFILE_INITIATE: "GET_PROFORMA_PROFILE_INITIATE",
  GET_PROFORMA_PROFILE_SUCCESS: "GET_PROFORMA_PROFILE_SUCCESS",
  GET_PROFORMA_PROFILE_FAILURE: "GET_PROFORMA_PROFILE_FAILURE",

  GET_PROFORMA_BASE_COST_INITIATE: "GET_PROFORMA_BASE_COST_INITIATE",
  GET_PROFORMA_BASE_COST_SUCCESS: "GET_PROFORMA_BASE_COST_SUCCESS",
  GET_PROFORMA_BASE_COST_FAILURE: "GET_PROFORMA_BASE_COST_FAILURE",

  VIEW_ADMIN_ESTIMATION_INITIATE: "VIEW_ADMIN_ESTIMATION_INITIATE",
  VIEW_ADMIN_ESTIMATION_SUCCESS: "VIEW_ADMIN_ESTIMATION_SUCCESS",
  VIEW_ADMIN_ESTIMATION_FAILURE: "VIEW_ADMIN_ESTIMATION_FAILURE",

  VIEW_USER_LIMIT_DETAILS_INITIATE: "VIEW_USER_LIMIT_DETAILS_INITIATE",
  VIEW_USER_LIMIT_DETAILS_SUCCESS: "VIEW_USER_LIMIT_DETAILS_SUCCESS",
  VIEW_USER_LIMIT_DETAILS_FAILURE: "VIEW_USER_LIMIT_DETAILS_FAILURE",

  //
};

export const JobActionTypes = {
  JOB_LIST_INITIATE: "JOB_LIST_INITIATE",
  JOB_LIST_SUCCESS: "JOB_LIST_SUCCESS",
  JOB_LIST_FAILURE: "JOB_LIST_FAILURE",

  PROJECT_LIST_INITIATE: "PROJECT_LIST_INITIATE",
  PROJECT_LIST_SUCCESS: "PROJECT_LIST_SUCCESS",
  PROJECT_LIST_FAILURE: "PROJECT_LIST_FAILURE",

  PROJECT_DETAILS_INITIATE: "PROJECT_DETAILS_INITIATE",
  PROJECT_DETAILS_SUCCESS: "PROJECT_DETAILS_SUCCESS",
  PROJECT_DETAILS_FAILURE: "PROJECT_DETAILS_FAILURE",

  ADD_RESIDENTIAL_JOB_INITIATE: "ADD_RESIDENTIAL_JOB_INITIATE",
  ADD_RESIDENTIAL_JOB_SUCCESS: "ADD_RESIDENTIAL_JOB_SUCCESS",
  ADD_RESIDENTIAL_JOB_FAILURE: "ADD_RESIDENTIAL_JOB_FAILURE",

  RESIDENTIAL_DETAILS_INITIATE: "RESIDENTIAL_DETAILS_INITIATE",
  RESIDENTIAL_DETAILS_SUCCESS: "RESIDENTIAL_DETAILS_SUCCESS",
  RESIDENTIAL_DETAILS_FAILURE: "RESIDENTIAL_DETAILS_FAILURE",

  UPDATE_RESIDENTIAL_JOB_INITIATE: "UPDATE_RESIDENTIAL_JOB_INITIATE",
  UPDATE_RESIDENTIAL_JOB_SUCCESS: "UPDATE_RESIDENTIAL_JOB_SUCCESS",
  UPDATE_RESIDENTIAL_JOB_FAILURE: "UPDATE_RESIDENTIAL_JOB_FAILURE",

  ADD_MULTIFAMILY_JOB_INITIATE: "ADD_MULTIFAMILY_JOB_INITIATE",
  ADD_MULTIFAMILY_JOB_SUCCESS: "ADD_MULTIFAMILY_JOB_SUCCESS",
  ADD_MULTIFAMILY_JOB_FAILURE: "ADD_MULTIFAMILY_JOB_FAILURE",

  ADD_LAND_DEVELOPMENT_JOB_INITIATE: "ADD_LAND_DEVELOPMENT_JOB_INITIATE",
  ADD_LAND_DEVELOPMENT_JOB_SUCCESS: "ADD_LAND_DEVELOPMENT_JOB_SUCCESS",
  ADD_LAND_DEVELOPMENT_JOB_FAILURE: "ADD_LAND_DEVELOPMENT_JOB_FAILURE",

  LAND_DEVELOPMENT_DETAILS_INITIATE: "LAND_DEVELOPMENT_DETAILS_INITIATE",
  LAND_DEVELOPMENT_DETAILS_SUCCESS: "LAND_DEVELOPMENT_DETAILS_SUCCESS",
  LAND_DEVELOPMENT_DETAILS_FAILURE: "LAND_DEVELOPMENT_DETAILS_FAILURE",

  UPDATE_LAND_DEVELOPMENT_JOB_INITIATE: "UPDATE_LAND_DEVELOPMENT_JOB_INITIATE",
  UPDATE_LAND_DEVELOPMENT_JOB_SUCCESS: "UPDATE_LAND_DEVELOPMENT_JOB_SUCCESS",
  UPDATE_LAND_DEVELOPMENT_JOB_FAILURE: "UPDATE_LAND_DEVELOPMENT_JOB_FAILURE",

  FOUNDATION_TYPE_INITIATE: "FOUNDATION_TYPE_INITIATE",
  FOUNDATION_TYPE_SUCCESS: "FOUNDATION_TYPE_SUCCESS",
  FOUNDATION_TYPE_FAILURE: "FOUNDATION_TYPE_FAILURE",

  HOME_TYPE_INITIATE: "HOME_TYPE_INITIATE",
  HOME_TYPE_SUCCESS: "HOME_TYPE_SUCCESS",
  HOME_TYPE_FAILURE: "HOME_TYPE_FAILURE",

  TAX_PROFILE_LIST_INITIATE: "TAX_PROFILE_LIST_INITIATE",
  TAX_PROFILE_LIST_SUCCESS: "TAX_PROFILE_LIST_SUCCESS",
  TAX_PROFILE_LIST_FAILURE: "TAX_PROFILE_LIST_FAILURE",

  CREATE_TAX_PROFILE_LIST_INITIATE: "CREATE_TAX_PROFILE_LIST_INITIATE",
  CREATE_TAX_PROFILE_LIST_SUCCESS: "CREATE_TAX_PROFILE_LIST_SUCCESS",
  CREATE_TAX_PROFILE_LIST_FAILURE: "CREATE_TAX_PROFILE_LIST_FAILURE",

  DELETE_TAX_PROFILE_INITIATE:"DELETE_TAX_PROFILE_INITIATE",
  DELETE_TAX_PROFILE_SUCCESS: "DELETE_TAX_PROFILE_SUCCESS",
  DELETE_TAX_PROFILE_FAILURE: "DELETE_TAX_PROFILE_FAILURE",

  UPDATE_TAX_PROFILE_INITIATE:"UPDATE_TAX_PROFILE_INITIATE",
  UPDATE_TAX_PROFILE_SUCCESS: "UPDATE_TAX_PROFILE_SUCCESS",
  UPDATE_TAX_PROFILE_FAILURE: "UPDATE_TAX_PROFILE_FAILURE",
};

export const estimationActionTypes = {
  REASON_LIST_INITIATE: "REASON_LIST_INITIATE",
  REASON_LIST_SUCCESS: "REASON_LIST_SUCCESS",
  REASON_LIST_FAILURE: "REASON_LIST_FAILURE",

  CREATE_NEW_QUOTE_INITIATE: "CREATE_NEW_QUOTE_INITIATE",
  CREATE_NEW_QUOTE_SUCCESS: "CREATE_NEW_QUOTE_SUCCESS",
  CREATE_NEW_QUOTE_FAILURE: "CREATE_NEW_QUOTE_FAILURE",

  UPDATE_NEW_QUOTE_INITIATE: "UPDATE_NEW_QUOTE_INITIATE",
  UPDATE_NEW_QUOTE_SUCCESS: "UPDATE_NEW_QUOTE_SUCCESS",
  UPDATE_NEW_QUOTE_FAILURE: "UPDATE_NEW_QUOTE_FAILURE",

  SELECT_JOB_INITIATE: "SELECT_JOB_INITIATE",
  SELECT_JOB_SUCCESS: "SELECT_JOB_SUCCESS",
  SELECT_JOB_FAILURE: "SELECT_JOB_FAILURE",

  SELECT_COST_CODE_INITIATE: "SELECT_COST_CODE_INITIATE",
  SELECT_COST_CODE_SUCCESS: "SELECT_COST_CODE_SUCCESS",
  SELECT_COST_CODE_FAILURE: "SELECT_COST_CODE_FAILURE",

  BLOCK_LIST_INITIATE: "BLOCK_LIST_INITIATE",
  BLOCK_LIST_SUCCESS: "BLOCK_LIST_SUCCESS",
  BLOCK_LIST_FAILURE: "BLOCK_LIST_FAILURE",

  UNIT_LIST_INITIATE: "UNIT_LIST_INITIATE",
  UNIT_LIST_SUCCESS: "UNIT_LIST_SUCCESS",
  UNIT_LIST_FAILURE: "UNIT_LIST_FAILURE",

  PROFILE_LIST_INITIATE: "PROFILE_LIST_INITIATE",
  PROFILE_LIST_SUCCESS: "PROFILE_LIST_SUCCESS",
  PROFILE_LIST_FAILURE: "PROFILE_LIST_FAILURE",

  CREATE_UNIT_INITIATE: "CREATE_UNIT_INITIATE",
  CREATE_UNIT_SUCCESS: "CREATE_UNIT_SUCCESS",
  CREATE_UNIT_FAILURE: "CREATE_UNIT_FAILURE",

  ESTIMATION_UNIT_LIST_INITIATE: "ESTIMATION_UNIT_LIST_INITIATE",
  ESTIMATION_UNIT_LIST_SUCCESS: "ESTIMATION_UNIT_LIST_SUCCESS",
  ESTIMATION_UNIT_LIST_FAILURE: "ESTIMATION_UNIT_LIST_FAILURE",

  CREATE_PURCHASE_INITIATE: "CREATE_PURCHASE_INITIATE",
  CREATE_PURCHASE_SUCCESS: "CREATE_PURCHASE_SUCCESS",
  CREATE_PURCHASE_FAILURE: "CREATE_PURCHASE_FAILURE",

  UPDATE_PURCHASE_INITIATE: "UPDATE_PURCHASE_INITIATE",
  UPDATE_PURCHASE_SUCCESS: "UPDATE_PURCHASE_SUCCESS",
  UPDATE_PURCHASE_FAILURE: "UPDATE_PURCHASE_FAILURE",

  ADD_BILL_INITIATE: "ADD_BILL_INITIATE",
  ADD_BILL_SUCCESS: "ADD_BILL_SUCCESS",
  ADD_BILL_FAILURE: "ADD_BILL_FAILURE",

  BILL_LIST_INITIATE: "BILL_LIST_INITIATE",
  BILL_LIST_SUCCESS: "BILL_LIST_SUCCESS",
  BILL_LIST_FAILURE: "BILL_LIST_FAILURE",

  BILL_DETAILS_INITIATE: "BILL_DETAILS_INITIATE",
  BILL_DETAILS_SUCCESS: "BILL_DETAILS_SUCCESS",
  BILL_DETAILS_FAILURE: "BILL_DETAILS_FAILURE",

  UPDATE_BILL_INITIATE: "UPDATE_BILL_INITIATE",
  UPDATE_BILL_SUCCESS: "UPDATE_BILL_SUCCESS",
  UPDATE_BILL_FAILURE: "UPDATE_BILL_FAILURE",

  ADD_FIELD_PURCHASE_ORDER_INITIATE: "ADD_FIELD_PURCHASE_ORDER_INITIATE",
  ADD_FIELD_PURCHASE_ORDER_SUCCESS: "ADD_FIELD_PURCHASE_ORDER_SUCCESS",
  ADD_FIELD_PURCHASE_ORDER_FAILURE: "ADD_FIELD_PURCHASE_ORDER_FAILURE",

  UPDATE_FIELD_PURCHASE_ORDER_INITIATE: "UPDATE_FIELD_PURCHASE_ORDER_INITIATE",
  UPDATE_FIELD_PURCHASE_ORDER_SUCCESS: "UPDATE_FIELD_PURCHASE_ORDER_SUCCESS",
  UPDATE_FIELD_PURCHASE_ORDER_FAILURE: "UPDATE_FIELD_PURCHASE_ORDER_FAILURE",

  ADD_SUPPLIER_CREDIT_INITIATE: "ADD_SUPPLIER_CREDIT_INITIATE",
  ADD_SUPPLIER_CREDIT_SUCCESS: "ADD_SUPPLIER_CREDIT_SUCCESS",
  ADD_SUPPLIER_CREDIT_FAILURE: "ADD_SUPPLIER_CREDIT_FAILURE",

  UPDATE_SUPPLIER_CREDIT_INITIATE: "UPDATE_SUPPLIER_CREDIT_INITIATE",
  UPDATE_SUPPLIER_CREDIT_SUCCESS: "UPDATE_SUPPLIER_CREDIT_SUCCESS",
  UPDATE_SUPPLIER_CREDIT_FAILURE: "UPDATE_SUPPLIER_CREDIT_FAILURE",

  ADD_BUDGET_CHANGE_ORDER_INITIATE: "ADD_BUDGET_CHANGE_ORDER_INITIATE",
  ADD_BUDGET_CHANGE_ORDER_SUCCESS: "ADD_BUDGET_CHANGE_ORDER_SUCCESS",
  ADD_BUDGET_CHANGE_ORDER_FAILURE: "ADD_BUDGET_CHANGE_ORDER_FAILURE",

  UPDATE_BUDGET_CHANGE_ORDER_INITIATE: "UPDATE_BUDGET_CHANGE_ORDER_INITIATE",
  UPDATE_BUDGET_CHANGE_ORDER_SUCCESS: "UPDATE_BUDGET_CHANGE_ORDER_SUCCESS",
  UPDATE_BUDGET_CHANGE_ORDER_FAILURE: "UPDATE_BUDGET_CHANGE_ORDER_FAILURE",

  ADD_ESTIMATE_SUPPORTING_DOCS_INITIATE:
    "ADD_ESTIMATE_SUPPORTING_DOCS_INITIATE",
  ADD_ESTIMATE_SUPPORTING_DOCS_SUCCESS: "ADD_ESTIMATE_SUPPORTING_DOCS_SUCCESS",
  ADD_ESTIMATE_SUPPORTING_DOCS_FAILURE: "ADD_ESTIMATE_SUPPORTING_DOCS_FAILURE",

  BUDGET_LISTING_INITIATE: "BUDGET_LISTING_INITIATE",
  BUDGET_LISTING_SUCCESS: "BUDGET_LISTING_SUCCESS",
  BUDGET_LISTING_FAILURE: "BUDGET_LISTING_FAILURE",

  BUILDER_SIGNEE_LIST_INITIATE: "BUILDER_SIGNEE_LIST_INITIATE",
  BUILDER_SIGNEE_LIST_SUCCESS: "BUILDER_SIGNEE_LIST_SUCCESS",
  BUILDER_SIGNEE_LIST_FAILURE: "BUILDER_SIGNEE_LIST_FAILURE",

  QUOTE_REQUEST_LIST_INITIATE: "QUOTE_REQUEST_LIST_INITIATE",
  QUOTE_REQUEST_LIST_SUCCESS: "QUOTE_REQUEST_LIST_SUCCESS",
  QUOTE_REQUEST_LIST_FAILURE: "QUOTE_REQUEST_LIST_FAILURE",

  FIELD_PURCHASE_ORDER_LIST_INITIATE: "FIELD_PURCHASE_ORDER_LIST_INITIATE",
  FIELD_PURCHASE_ORDER_LIST_SUCCESS: "FIELD_PURCHASE_ORDER_LIST_SUCCESS",
  FIELD_PURCHASE_ORDER_LIST_FAILURE: "FIELD_PURCHASE_ORDER_LIST_FAILURE",

  SUPPLIER_CREDIT_LIST_INITIATE: "SUPPLIER_CREDIT_LIST_INITIATE",
  SUPPLIER_CREDIT_LIST_SUCCESS: "SUPPLIER_CREDIT_LIST_SUCCESS",
  SUPPLIER_CREDIT_LIST_FAILURE: "SUPPLIER_CREDIT_LIST_FAILURE",

  PURCHASE_LIST_INITIATE: "PURCHASE_LIST_INITIATE",
  PURCHASE_LIST_SUCCESS: "PURCHASE_LIST_SUCCESS",
  PURCHASE_LIST_FAILURE: "PURCHASE_LIST_FAILURE",

  QUOTE_REQUEST_MODAL_OPEN: "QUOTE_REQUEST_MODAL_OPEN",
  QUOTE_REQUEST_MODAL_CLOSE: "QUOTE_REQUEST_MODAL_CLOSE",

  APPROVE_PO_INITIATE: "APPROVE_PO_INITIATE",
  APPROVE_PO_SUCCESS: "APPROVE_PO_SUCCESS",
  APPROVE_PO_FAILURE: "APPROVE_PO_FAILURE",

  MARK_QR_PREFERRED_INITIATE: "MARK_QR_PREFERRED_INITIATE",
  MARK_QR_PREFERRED_SUCCESS: "MARK_QR_PREFERRED_SUCCESS",
  MARK_QR_PREFERRED_FAILURE: "MARK_QR_PREFERRED_FAILURE",

  DELETE_QUOTE_INITIATE: "DELETE_QUOTE_INITIATE",
  DELETE_QUOTE_SUCCESS: "DELETE_QUOTE_SUCCESS",
  DELETE_QUOTE_FAILURE: "DELETE_QUOTE_FAILURE",

  EVENT_LIST_INITIATE: "EVENT_LIST_INITIATE",
  EVENT_LIST_SUCCESS: " EVENT_LIST_SUCCESS",
  EVENT_LIST_FAILURE: " EVENT_LIST_FAILURE",

  QUOTE_DETAILS_INITIATE: "QUOTE_DETAILS_INITIATE",
  QUOTE_DETAILS_SUCCESS: "QUOTE_DETAILS_SUCCESS",
  QUOTE_DETAILS_FAILURE: "QUOTE_DETAILS_FAILURE",

  BILL_DETAILS_INITIATE: "BILL_DETAILS_INITIATE",
  BILL_DETAILS_SUCCESS: "BILL_DETAILS_SUCCESS",
  BILL_DETAILS_FAILURE: "BILL_DETAILS_FAILURE",

  CANCEL_BILL_INITIATE: "CANCEL_BILL_INITIATE",
  CANCEL_BILL_SUCCESS: "CANCEL_BILL_SUCCESS",
  CANCEL_BILL_FAILURE: "CANCEL_BILL_FAILURE",

  CREATE_BACK_CHARGE_CREDIT_INITIATE: "CREATE_BACK_CHARGE_CREDIT_INITIATE",
  CREATE_BACK_CHARGE_CREDIT_SUCCESS: "CREATE_BACK_CHARGE_CREDIT_SUCCESS",
  CREATE_BACK_CHARGE_CREDIT_FAILURE: "CREATE_BACK_CHARGE_CREDIT_FAILURE",

  BACK_CHARGE_CREDIT_LIST_INITIATE: "BACK_CHARGE_CREDIT_LIST_INITIATE",
  BACK_CHARGE_CREDIT_LIST_SUCCESS: "BACK_CHARGE_CREDIT_LIST_SUCCESS",
  BACK_CHARGE_CREDIT_LIST_FAILURE: "BACK_CHARGE_CREDIT_LIST_FAILURE",

  SCOPE_WORK_ID_LIST_INITIATE: "SCOPE_WORK_ID_LIST_INITIATE",
  SCOPE_WORK_ID_LIST_SUCCESS: "SCOPE_WORK_ID_LIST_SUCCESS",
  SCOPE_WORK_ID_LIST_FAILURE: "SCOPE_WORK_ID_LIST_FAILURE",

  DELETE_BACK_CHARGE_CREDIT_INITIATE: "DELETE_BACK_CHARGE_CREDIT_INITIATE",
  DELETE_BACK_CHARGE_CREDIT_SUCCESS: "DELETE_BACK_CHARGE_CREDIT_SUCCESS",
  DELETE_BACK_CHARGE_CREDIT_FAILURE: "DELETE_BACK_CHARGE_CREDIT_FAILURE",

  QUOTE_REQUEST_COST_BREAKDOWN_DETAILS_INITIATE:
    "QUOTE_REQUEST_COST_BREAKDOWN_DETAILS_INITIATE",
  QUOTE_REQUEST_COST_BREAKDOWN_DETAILS_SUCCESS:
    "QUOTE_REQUEST_COST_BREAKDOWN_DETAILS_SUCCESS",
  QUOTE_REQUEST_COST_BREAKDOWN_DETAILS_FAILURE:
    "QUOTE_REQUEST_COST_BREAKDOWN_DETAILS_FAILURE",

  MODAL_BUDGET_VIEW_LIST_INITIATE: "MODAL_BUDGET_VIEW_LIST_INITIATE",
  MODAL_BUDGET_VIEW_LIST_SUCCESS: "MODAL_BUDGET_VIEW_LIST_SUCCESS",
  MODAL_BUDGET_VIEW_LIST_FAILURE: "MODAL_BUDGET_VIEW_LIST_FAILURE",

  ESTIMATION_DOCUMENT_LISTING_INITIATE: "ESTIMATION_DOCUMENT_LISTING_INITIATE",
  ESTIMATION_DOCUMENT_LISTING_SUCCESS: "ESTIMATION_DOCUMENT_LISTING_SUCCESS",
  ESTIMATION_DOCUMENT_LISTING_FAILURE: "ESTIMATION_DOCUMENT_LISTING_FAILURE",

  CREATE_BACK_CHARGE_NOTIFICATION_INITIATE:
    "CREATE_BACK_CHARGE_NOTIFICATION_INITIATE",
  CREATE_BACK_CHARGE_NOTIFICATION_SUCCESS:
    "CREATE_BACK_CHARGE_NOTIFICATION_SUCCESS",
  CREATE_BACK_CHARGE_NOTIFICATION_FAILURE:
    "CREATE_BACK_CHARGE_NOTIFICATION_FAILURE",

  APPROVER_LIST_INITIATE: "APPROVER_LIST_INITIATE",
  APPROVER_LIST_SUCCESS: "APPROVER_LIST_SUCCESS",
  APPROVER_LIST_FAILURE: "APPROVER_LIST_FAILURE",

  GET_ESTIMATION_SETTING_COST_CODE_INITIATE:
    "GET_ESTIMATION_SETTING_COST_CODE_INITIATE",
  GET_ESTIMATION_SETTING_COST_CODE_SUCCESS:
    "GET_ESTIMATION_SETTING_COST_CODE_SUCCESS",
  GET_ESTIMATION_SETTING_COST_CODE_FAILURE:
    "GET_ESTIMATION_SETTING_COST_CODE_FAILURE",

  UPDATE_ESTIMATION_SETTING_COST_CODE_INITIATE:
    "UPDATE_ESTIMATION_SETTING_COST_CODE_INITIATE",
  UPDATE_ESTIMATION_SETTING_COST_CODE_SUCCESS:
    "UPDATE_ESTIMATION_SETTING_COST_CODE_SUCCESS",
  UPDATE_ESTIMATION_SETTING_COST_CODE_FAILURE:
    "UPDATE_ESTIMATION_SETTING_COST_CODE_FAILURE",

  GET_ESTIMATION_SETTING_COST_CATEGORY_INITIATE:
    "GET_ESTIMATION_SETTING_COST_CATEGORY_INITIATE",
  GET_ESTIMATION_SETTING_COST_CATEGORY_SUCCESS:
    "GET_ESTIMATION_SETTING_COST_CATEGORY_SUCCESS",
  GET_ESTIMATION_SETTING_COST_CATEGORY_FAILURE:
    "GET_ESTIMATION_SETTING_COST_CATEGORY_FAILURE",

  UPDATE_ESTIMATION_SETTING_COST_CATEGORY_INITIATE:
    "UPDATE_ESTIMATION_SETTING_COST_CATEGORY_INITIATE",
  UPDATE_ESTIMATION_SETTING_COST_CATEGORY_SUCCESS:
    "UPDATE_ESTIMATION_SETTING_COST_CATEGORY_SUCCESS",
  UPDATE_ESTIMATION_SETTING_COST_CATEGORY_FAILURE:
    "UPDATE_ESTIMATION_SETTING_COST_CATEGORY_FAILURE",

  GET_RELATED_DOCUMNETS_INITIATE: "GET_RELATED_DOCUMNETS_INITIATE",
  GET_RELATED_DOCUMNETS_SUCCESS: "GET_RELATED_DOCUMNETS_SUCCESS",
  GET_RELATED_DOCUMNETS_FAILURE: "GET_RELATED_DOCUMNETS_FAILURE",

  SYNC_BACK_CHARGE_INITIATE: "SYNC_BACK_CHARGE_INITIATE",
  SYNC_BACK_CHARGE_SUCCESS: "SYNC_BACK_CHARGE_SUCCESS",
  SYNC_BACK_CHARGE_FAILURE: "SYNC_BACK_CHARGE_FAILURE",

  GET_BACK_CHARGE_RELATED_DOCUMNETS_INITIATE:
    "GET_BACK_CHARGE_RELATED_DOCUMNETS_INITIATE",
  GET_BACK_CHARGE_RELATED_DOCUMNETS_SUCCESS:
    "GET_BACK_CHARGE_RELATED_DOCUMNETS_SUCCESS",
  GET_BACK_CHARGE_RELATED_DOCUMNETS_FAILURE:
    "GET_BACK_CHARGE_RELATED_DOCUMNETS_FAILURE",

  CREATE_BACK_CHARGE_FIELD_PURCHASE_ORDER_INITIATE:
    "CREATE_BACK_CHARGE_FIELD_PURCHASE_ORDER_INITIATE",
  CREATE_BACK_CHARGE_FIELD_PURCHASE_ORDER_SUCCESS:
    "CREATE_BACK_CHARGE_FIELD_PURCHASE_ORDER_SUCCESS",
  CREATE_BACK_CHARGE_FIELD_PURCHASE_ORDER_FAILURE:
    "CREATE_BACK_CHARGE_FIELD_PURCHASE_ORDER_FAILURE",

  CREATE_BACK_CHARGE_SUPPLIER_CREDIT_INITIATE:
    "CREATE_BACK_CHARGE_SUPPLIER_CREDIT_INITIATE",
  CREATE_BACK_CHARGE_SUPPLIER_CREDIT_SUCCESS:
    "CREATE_BACK_CHARGE_SUPPLIER_CREDIT_SUCCESS",
  CREATE_BACK_CHARGE_SUPPLIER_CREDIT_FAILURE:
    "CREATE_BACK_CHARGE_SUPPLIER_CREDIT_FAILURE",

  MESSAGE_LIST_INITIATE: "MESSAGE_LIST_INITIATE",
  MESSAGE_LIST_SUCCESS: "MESSAGE_LIST_SUCCESS",
  MESSAGE_LIST_FAILURE: "MESSAGE_LIST_FAILURE",

  RESEND_MESSAGE_INITIATE: "RESEND_MESSAGE_INITIATE",
  RESEND_MESSAGE_SUCCESS: "RESEND_MESSAGE_SUCCESS",
  RESEND_MESSAGE_FAILURE: "RESEND_MESSAGE_FAILURE",

  CANCEL_MESSAGE_INITIATE: "CANCEL_MESSAGE_INITIATE",
  CANCEL_MESSAGE_SUCCESS: "CANCEL_MESSAGE_SUCCESS",
  CANCEL_MESSAGE_FAILURE: "CANCEL_MESSAGE_FAILURE",

  CANCEL_BACK_CHARGE_INITIATE:"CANCEL_BACK_CHARGE_INITIATE",
  CANCEL_BACK_CHARGE_SUCCESS: "CANCEL_BACK_CHARGE_SUCCESS",
  CANCEL_BACK_CHARGE_FAILURE: "CANCEL_BACK_CHARGE_FAILURE",

  RELEASED_CONSTRUCTION_DOCS_INITIATE:"RELEASED_CONSTRUCTION_DOCS_INITIATE",
  RELEASED_CONSTRUCTION_DOCS_SUCCESS: "RELEASED_CONSTRUCTION_DOCS_SUCCESS",
  RELEASED_CONSTRUCTION_DOCS_FAILURE: "RELEASED_CONSTRUCTION_DOCS_FAILURE",

  APPROVE_FPO_INITIATE:"APPROVE_FPO_INITIATE",
  APPROVE_FPO_SUCCESS: "APPROVE_FPO_SUCCESS",
  APPROVE_FPO_FAILURE: "APPROVE_FPO_FAILURE",

  APPROVE_SC_INITIATE:"APPROVE_SC_INITIATE",
  APPROVE_SC_SUCCESS: "APPROVE_SC_SUCCESS",
  APPROVE_SC_FAILURE: "APPROVE_SC_FAILURE",

  GET_BACK_CHARGE_NOTIFICATION_BY_ID_INITIATE:"GET_BACK_CHARGE_NOTIFICATION_BY_ID_INITIATE",
  GET_BACK_CHARGE_NOTIFICATION_BY_ID_SUCCESS: "GET_BACK_CHARGE_NOTIFICATION_BY_ID_SUCCESS",
  GET_BACK_CHARGE_NOTIFICATION_BY_ID_FAILURE: "GET_BACK_CHARGE_NOTIFICATION_BY_ID_FAILURE",

  CREATE_BACK_CHARGE_NOTIFICATIONS_INITIATE:"CREATE_BACK_CHARGE_NOTIFICATIONS_INITIATE",
  CREATE_BACK_CHARGE_NOTIFICATIONS_SUCCESS: "CREATE_BACK_CHARGE_NOTIFICATIONS_SUCCESS",
  CREATE_BACK_CHARGE_NOTIFICATIONS_FAILURE: "CREATE_BACK_CHARGE_NOTIFICATIONS_FAILURE",

  QUOTE_REQUEST_BY_VENDOR_INITIATE:"QUOTE_REQUEST_BY_VENDOR_INITIATE",
  QUOTE_REQUEST_BY_VENDOR_SUCCESS: "QUOTE_REQUEST_BY_VENDOR_SUCCESS",
  QUOTE_REQUEST_BY_VENDOR_FAILURE: "QUOTE_REQUEST_BY_VENDOR_FAILURE",

  GET_BILLS_BY_ID_INITIATE:"GET_BILLS_BY_ID_INITIATE",
  GET_BILLS_BY_ID_SUCCESS: "GET_BILLS_BY_ID_SUCCESS",
  GET_BILLS_BY_ID_FAILURE: "GET_BILLS_BY_ID_FAILURE",

  BILLS_ACCEPT_INITIATE:"BILLS_ACCEPT_INITIATE",
  BILLS_ACCEPT_SUCCESS: "BILLS_ACCEPT_SUCCESS",
  BILLS_ACCEPT_FAILURE: "BILLS_ACCEPT_FAILURE",

  ALL_BILLS_ACCEPT_INITIATE:"ALL_BILLS_ACCEPT_INITIATE",
  ALL_BILLS_ACCEPT_SUCCESS: "ALL_BILLS_ACCEPT_SUCCESS",
  ALL_BILLS_ACCEPT_FAILURE: "ALL_BILLS_ACCEPT_FAILURE",

  UPLOAD_AGRREMENT_DOCUMENT_INITIATE:"UPLOAD_AGRREMENT_DOCUMENT_INITIATE",
  UPLOAD_AGRREMENT_DOCUMENT_SUCCESS: "UPLOAD_AGRREMENT_DOCUMENT_SUCCESS",
  UPLOAD_AGRREMENT_DOCUMENT_FAILURE: "UPLOAD_AGRREMENT_DOCUMENT_FAILURE",
};

export const constructionConstant = {
  CREATE_SCHEDULE_INITIATE: "CREATE_SCHEDULE_INITIATE",
  CREATE_SCHEDULE_SUCCESS: "CREATE_SCHEDULE_SUCCESS",
  CREATE_SCHEDULE_FAILURE: "CREATE_SCHEDULE_INITIATE",

  UPDATE_DEFAULT_PHASE_INITIATE: "UPDATE_DEFAULT_PHASE_INITIATE",
  UPDATE_DEFAULT_PHASE_SUCCESS: "UPDATE_DEFAULT_PHASE_SUCCESS",
  UPDATE_DEFAULT_PHASE_FAILURE: "UPDATE_DEFAULT_PHASE_INITIATE",

  UPDATE_DEFAULT_TASK_INITIATE: "UPDATE_DEFAULT_TASK_INITIATE",
  UPDATE_DEFAULT_TASK_SUCCESS: "UPDATE_DEFAULT_TASK_SUCCESS",
  UPDATE_DEFAULT_TASK_FAILURE: "UPDATE_DEFAULT_TASK_INITIATE",

  ADD_PHASE_INITIATE: "ADD_PHASE_INITIATE",
  ADD_PHASE_SUCCESS: "ADD_PHASE_SUCCESS",
  ADD_PHASE_FAILURE: "ADD_PHASE_INITIATE",

  UPDATE_PHASE_INITIATE: "UPDATE_PHASE_INITIATE",
  UPDATE_PHASE_SUCCESS: "UPDATE_PHASE_SUCCESS",
  UPDATE_PHASE_FAILURE: "UPDATE_PHASE_INITIATE",

  DELETE_PHASE_INITIATE: "DELETE_PHASE_INITIATE",
  DELETE_PHASE_SUCCESS: "DELETE_PHASE_SUCCESS",
  DELETE_PHASE_FAILURE: "DELETE_PHASE_INITIATE",

  GET_ALL_PHASE_INITIATE: "GET_ALL_PHASE_INITIATE",
  GET_ALL_PHASE_SUCCESS: "GET_ALL_PHASE_SUCCESS",
  GET_ALL_PHASE_FAILURE: "GET_ALL_PHASE_INITIATE",

  GET_PROJECT_NAME_INITIATE: "GET_PROJECT_NAME_INITIATE",
  GET_PROJECT_NAME_SUCCESS: "GET_PROJECT_NAME_SUCCESS",
  GET_PROJECT_NAME_FAILURE: "GET_PROJECT_NAME_INITIATE",

  GET_ALL_JOBS_INITIATE: "GET_ALL_JOBS_INITIATE",
  GET_ALL_JOBS_SUCCESS: "GET_ALL_JOBS_SUCCESS",
  GET_ALL_JOBS_FAILURE: "GET_ALL_JOBS_INITIATE",

  GET_JOB_DETAILS_INITIATE: "GET_JOB_DETAILS_INITIATE",
  GET_JOB_DETAILS_SUCCESS: "GET_JOB_DETAILS_SUCCESS",
  GET_JOB_DETAILS_FAILURE: "GET_JOB_DETAILS_INITIATE",

  UPDATE_JOB_INITIATE: "UPDATE_JOB_INITIATE",
  UPDATE_JOB_SUCCESS: "UPDATE_JOB_SUCCESS",
  UPDATE_JOB_FAILURE: "UPDATE_JOB_INITIATE",

  CREATE_TASK_INITIATE: "CREATE_TASK_INITIATE",
  CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",
  CREATE_TASK_FAILURE: "CREATE_TASK_INITIATE",

  UPDATE_TASK_INITIATE: "UPDATE_TASK_INITIATE",
  UPDATE_TASK_SUCCESS: "UPDATE_TASK_SUCCESS",
  UPDATE_TASK_FAILURE: "UPDATE_TASK_INITIATE",

  FIND_ALL_TASK_INITIATE: "FIND_ALL_TASK_INITIATE",
  FIND_ALL_TASK_SUCCESS: "FIND_ALL_TASK_SUCCESS",
  FIND_ALL_TASK_FAILURE: "FIND_ALL_TASK_INITIATE",

  DELETE_TASK_INITIATE: "DELETE_TASK_INITIATE",
  DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAILURE: "DELETE_TASK_INITIATE",

  GET_ACCOUNT_LIST_INITIATE: "GET_ACCOUNT_LIST_INITIATE",
  GET_ACCOUNT_LIST_SUCCESS: "GET_ACCOUNT_LIST_SUCCESS",
  GET_ACCOUNT_LIST_FAILURE: "GET_ACCOUNT_LIST_INITIATE",

  GET_VENDOR_LIST_INITIATE: "GET_VENDOR_LIST_INITIATE",
  GET_VENDOR_LIST_SUCCESS: "GET_VENDOR_LIST_SUCCESS",
  GET_VENDOR_LIST_FAILURE: "GET_VENDOR_LIST_INITIATE",

  GET_ALL_TASK_FOR_PHASE_INITIATE: "GET_ALL_TASK_FOR_PHASE_INITIATE",
  GET_ALL_TASK_FOR_PHASE_SUCCESS: "GET_ALL_TASK_FOR_PHASE_SUCCESS",
  GET_ALL_TASK_FOR_PHASE_FAILURE: "GET_ALL_TASK_FOR_PHASE_INITIATE",

  ASSIGNED_TASK_INITIATE: "ASSIGNED_TASK_INITIATE",
  ASSIGNED_TASK_SUCCESS: "ASSIGNED_TASK_SUCCESS",
  ASSIGNED_TASK_FAILURE: "ASSIGNED_TASK_INITIATE",

  DEFAULT_SYSTEM_INITIATE: "DEFAULT_SYSTEM_INITIATE",
  DEFAULT_SYSTEM_SUCCESS: "DEFAULT_SYSTEM_SUCCESS",
  DEFAULT_SYSTEM_FAILURE: "DEFAULT_SYSTEM_INITIATE",

  SYSTEM_PHASE_INITIATE: "SYSTEM_PHASE_INITIATE",
  SYSTEM_PHASE_SUCCESS: "SYSTEM_PHASE_SUCCESS",
  SYSTEM_PHASE_FAILURE: "SYSTEM_PHASE_INITIATE",

  CALENDER_DATA_MONTHLY_INITIATE: "CALENDER_DATA_MONTHLY_INITIATE",
  CALENDER_DATA_MONTHLY_SUCCESS: "CALENDER_DATA_MONTHLY_SUCCESS",
  CALENDER_DATA_MONTHLY_FAILURE: "CALENDER_DATA_MONTHLY_INITIATE",

  CALENDER_DATA_WEEKLY_INITIATE: "CALENDER_DATA_WEEKLY_INITIATE",
  CALENDER_DATA_WEEKLY_SUCCESS: "CALENDER_DATA_WEEKLY_SUCCESS",
  CALENDER_DATA_WEEKLY_FAILURE: "CALENDER_DATA_WEEKLY_INITIATE",

  CALENDER_DATA_DAILY_INITIATE: "CALENDER_DATA_DAILY_INITIATE",
  CALENDER_DATA_DAILY_SUCCESS: "CALENDER_DATA_DAILY_SUCCESS",
  CALENDER_DATA_DAILY_FAILURE: "CALENDER_DATA_DAILY_INITIATE",

  CALENDER_DATA_TODAY_INITIATE: "CALENDER_DATA_TODAY_INITIATE",
  CALENDER_DATA_TODAY_SUCCESS: "CALENDER_DATA_TODAY_SUCCESS",
  CALENDER_DATA_TODAY_FAILURE: "CALENDER_DATA_TODAY_INITIATE",

  ADD_DISCUSSION_INITIATE: "ADD_DISCUSSION_INITIATE",
  ADD_DISCUSSION_SUCCESS: "ADD_DISCUSSION_SUCCESS",
  ADD_DISCUSSION_FAILURE: "ADD_DISCUSSION_INITIATE",

  UPDATE_DISCUSSION_INITIATE: "UPDATE_DISCUSSION_INITIATE",
  UPDATE_DISCUSSION_SUCCESS: "UPDATE_DISCUSSION_SUCCESS",
  UPDATE_DISCUSSION_FAILURE: "UPDATE_DISCUSSION_INITIATE",

  DELETE_DISCUSSION_INITIATE: "DELETE_DISCUSSION_INITIATE",
  DELETE_DISCUSSION_SUCCESS: "DELETE_DISCUSSION_SUCCESS",
  DELETE_DISCUSSION_FAILURE: "DELETE_DISCUSSION_INITIATE",

  DISCUSSION_LIST_INITIATE: "DISCUSSION_LIST_INITIATE",
  DISCUSSION_LIST_SUCCESS: "DISCUSSION_LIST_SUCCESS",
  DISCUSSION_LIST_FAILURE: "DISCUSSION_LIST_INITIATE",

  REPLY_LIST_INITIATE: "REPLY_LIST_INITIATE",
  REPLY_LIST_SUCCESS: "REPLY_LIST_SUCCESS",
  REPLY_LIST_FAILURE: "REPLY_LIST_INITIATE",

  ADD_REPLY_INITIATE: "ADD_REPLY_INITIATE",
  ADD_REPLY_SUCCESS: "ADD_REPLY_SUCCESS",
  ADD_REPLY_FAILURE: "ADD_REPLY_INITIATE",

  EDIT_REPLY_INITIATE: "EDIT_REPLY_INITIATE",
  EDIT_REPLY_SUCCESS: "EDIT_REPLY_SUCCESS",
  EDIT_REPLY_FAILURE: "EDIT_REPLY_INITIATE",

  DELETE_REPLY_INITIATE: "DELETE_REPLY_INITIATE",
  DELETE_REPLY_SUCCESS: "DELETE_REPLY_SUCCESS",
  DELETE_REPLY_FAILURE: "DELETE_REPLY_INITIATE",

  CHANGE_ORDER_CHECK_LIST_INITIATE: "CHANGE_ORDER_CHECK_LIST_INITIATE",
  CHANGE_ORDER_CHECK_LIST_SUCCESS: "CHANGE_ORDER_CHECK_LIST_SUCCESS",
  CHANGE_ORDER_CHECK_LIST_FAILURE: "CHANGE_ORDER_CHECK_LIST_FAILURE",

  BASE_LINE_INITIATE: "BASE_LINE_INITIATE",
  BASE_LINE_SUCCESS: "BASE_LINE_SUCCESS",
  BASE_LINE_FAILURE: "BASE_LINE_FAILURE",

  GANTT_DATA_INITIATE: "GANTT_DATA_INITIATE",
  GANTT_DATA_SUCCESS: "GANTT_DATA_SUCCESS",
  GANTT_DATA_FAILURE: "GANTT_DATA_FAILURE",

  GET_ALL_PHASE_INITIATE: "GET_ALL_PHASE_INITIATE",
  GET_ALL_PHASE_SUCCESS: "GET_ALL_PHASE_SUCCESS",
  GET_ALL_PHASE_FAILURE: "GET_ALL_PHASE_FAILURE",

  GANTT_DATA_INITIATE: "GANTT_DATA_INITIATE",
  GANTT_DATA_SUCCESS: "GANTT_DATA_SUCCESS",
  GANTT_DATA_FAILURE: "GANTT_DATA_FAILURE",

  CONSTRUCTION_DASHBOARD_INITIATE: "CONSTRUCTION_DASHBOARD_INITIATE",
  CONSTRUCTION_DASHBOARD_SUCCESS: "CONSTRUCTION_DASHBOARD_SUCCESS",
  CONSTRUCTION_DASHBOARD_FAILURE: "CONSTRUCTION_DASHBOARD_FAILURE",

  FIELD_PURCAHSE_ORDER_LIST_INITIATE: "FIELD_PURCAHSE_ORDER_LIST_INITIATE",
  FIELD_PURCAHSE_ORDER_LIST_SUCCESS: "FIELD_PURCAHSE_ORDER_LIST_SUCCESS",
  FIELD_PURCAHSE_ORDER_LIST_FAILURE: "FIELD_PURCAHSE_ORDER_LIST_FAILURE",
};

export const adminConstructionConstant = {
  ADD_PROJECT_PROFILE_INITIATE: "ADD_PROJECT_PROFILE_INITIATE",
  ADD_PROJECT_PROFILE_SUCCESS: "ADD_PROJECT_PROFILE_SUCCESS",
  ADD_PROJECT_PROFILE_FAILURE: "ADD_PROJECT_PROFILE_INITIATE",

  PROJECT_PROFILE_LIST_INITIATE: "CREATE_PROJECT_PROFILE_INITIATE",
  PROJECT_PROFILE_LIST_SUCCESS: "CREATE_PROJECT_PROFILE_SUCCESS",
  PROJECT_PROFILE_LIST_FAILURE: "CREATE_PROJECT_PROFILE_INITIATE",

  PROJECT_PROFILE_DETAILS_INITIATE: "PROJECT_PROFILE_DETAILS_INITIATE",
  PROJECT_PROFILE_DETAILS_SUCCESS: "PROJECT_PROFILE_DETAILS_SUCCESS",
  PROJECT_PROFILE_DETAILS_FAILURE: "PROJECT_PROFILE_DETAILS_INITIATE",

  SEARCH_PROJECT_PROFILE_INITIATE: "SEARCH_CREATE_PROJECT_INITIATE",
  SEARCH_PROJECT_PROFILE_SUCCESS: "SEARCH_CREATE_PROJECT_SUCCESS",
  SEARCH_PROJECT_PROFILE_FAILURE: "SEARCH_CREATE_PROJECT_INITIATE",

  UPDATE_PROJECT_PROFILE_INITIATE: "UPDATE_PROJECT_PROFILE_INITIATE",
  UPDATE_PROJECT_PROFILE_SUCCESS: "UPDATE_PROJECT_PROFILE_SUCCESS",
  UPDATE_PROJECT_PROFILE_FAILURE: "UPDATE_PROJECT_PROFILE_INITIATE",

  DELETE_PROJECT_PROFILE_INITIATE: "DELETE_PROJECT_PROFILE_INITIATE",
  DELETE_PROJECT_PROFILE_SUCCESS: "DELETE_PROJECT_PROFILE_SUCCESS",
  DELETE_PROJECT_PROFILE_FAILURE: "DELETE_PROJECT_PROFILE_INITIATE",

  ADD_CONSTRUCTION_CATEGORY_INITIATE: "ADD_CONSTRUCTION_CATEGORY_INITIATE",
  ADD_CONSTRUCTION_CATEGORY_SUCCESS: "ADD_CONSTRUCTION_CATEGORY_SUCCESS",
  ADD_CONSTRUCTION_CATEGORY_FAILURE: "ADD_CONSTRUCTION_CATEGORY_INITIATE",

  CONSTRUCTION_CATEGORY_LIST_INITIATE: "CREATE_CONSTRUCTION_CATEGORY_INITIATE",
  CONSTRUCTION_CATEGORY_LIST_SUCCESS: "CREATE_CONSTRUCTION_CATEGORY_SUCCESS",
  CONSTRUCTION_CATEGORY_LIST_FAILURE: "CREATE_CONSTRUCTION_CATEGORY_INITIATE",

  CONSTRUCTION_CATEGORY_DETAILS_INITIATE:
    "CONSTRUCTION_CATEGORY_DETAILS_INITIATE",
  CONSTRUCTION_CATEGORY_DETAILS_SUCCESS:
    "CONSTRUCTION_CATEGORY_DETAILS_SUCCESS",
  CONSTRUCTION_CATEGORY_DETAILS_FAILURE:
    "CONSTRUCTION_CATEGORY_DETAILS_INITIATE",

  SEARCH_CONSTRUCTION_CATEGORY_INITIATE:
    "SEARCH_CONSTRUCTION_CATEGORY_INITIATE",
  SEARCH_CONSTRUCTION_CATEGORY_SUCCESS: "SEARCH_CONSTRUCTION_CATEGORY_SUCCESS",
  SEARCH_CONSTRUCTION_CATEGORY_FAILURE: "SEARCH_CONSTRUCTION_CATEGORY_INITIATE",

  UPDATE_CONSTRUCTION_CATEGORY_INITIATE:
    "UPDATE_CONSTRUCTION_CATEGORY_INITIATE",
  UPDATE_CONSTRUCTION_CATEGORY_SUCCESS: "UPDATE_CONSTRUCTION_CATEGORY_SUCCESS",
  UPDATE_CONSTRUCTION_CATEGORY_FAILURE: "UPDATE_CONSTRUCTION_CATEGORY_INITIATE",

  DELETE_CONSTRUCTION_CATEGORY_INITIATE:
    "DELETE_CONSTRUCTION_CATEGORY_INITIATE",
  DELETE_CONSTRUCTION_CATEGORY_SUCCESS: "DELETE_CONSTRUCTION_CATEGORY_SUCCESS",
  DELETE_CONSTRUCTION_CATEGORY_FAILURE: "DELETE_CONSTRUCTION_CATEGORY_INITIATE",

  ADD_FROM_MASTER_TABLE_INITIATE: "ADD_FROM_MASTER_TABLE_INITIATE",
  ADD_FROM_MASTER_TABLE_SUCCESS: "ADD_FROM_MASTER_TABLE_SUCCESS",
  ADD_FROM_MASTER_TABLE_FAILURE: "ADD_FROM_MASTER_TABLE_INITIATE",

  ADD_FROM_USE_INITIATE: "ADD_FROM_USE_INITIATE",
  ADD_FROM_USE_SUCCESS: "ADD_FROM_USE_SUCCESS",
  ADD_FROM_USE_FAILURE: "ADD_FROM_USE_INITIATE",

  REMOVE_FROM_USE_INITIATE: "REMOVE_FROM_USE_INITIATE",
  REMOVE_FROM_USE_SUCCESS: "REMOVE_FROM_USE_SUCCESS",
  REMOVE_FROM_USE_FAILURE: "REMOVE_FROM_USE_INITIATE",

  ADD_MASTER_CATEGORY_INITIATE: "ADD_MASTER_CATEGORY_INITIATE",
  ADD_MASTER_CATEGORY_SUCCESS: "ADD_MASTER_CATEGORY_SUCCESS",
  ADD_MASTER_CATEGORY_FAILURE: "ADD_MASTER_CATEGORY_INITIATE",

  MASTER_CATEGORY_LIST_INITIATE: "CREATE_MASTER_CATEGORY_INITIATE",
  MASTER_CATEGORY_LIST_SUCCESS: "CREATE_MASTER_CATEGORY_SUCCESS",
  MASTER_CATEGORY_LIST_FAILURE: "CREATE_MASTER_CATEGORY_INITIATE",

  MASTER_CATEGORY_DETAILS_INITIATE: "MASTER_CATEGORY_DETAILS_INITIATE",
  MASTER_CATEGORY_DETAILS_SUCCESS: "MASTER_CATEGORY_DETAILS_SUCCESS",
  MASTER_CATEGORY_DETAILS_FAILURE: "MASTER_CATEGORY_DETAILS_INITIATE",

  SEARCH_MASTER_CATEGORY_INITIATE: "SEARCH_MASTER_CATEGORY_INITIATE",
  SEARCH_MASTER_CATEGORY_SUCCESS: "SEARCH_MASTER_CATEGORY_SUCCESS",
  SEARCH_MASTER_CATEGORY_FAILURE: "SEARCH_MASTER_CATEGORY_INITIATE",

  UPDATE_MASTER_CATEGORY_INITIATE: "UPDATE_MASTER_CATEGORY_INITIATE",
  UPDATE_MASTER_CATEGORY_SUCCESS: "UPDATE_MASTER_CATEGORY_SUCCESS",
  UPDATE_MASTER_CATEGORY_FAILURE: "UPDATE_MASTER_CATEGORY_INITIATE",

  DELETE_MASTER_CATEGORY_INITIATE: "DELETE_MASTER_CATEGORY_INITIATE",
  DELETE_MASTER_CATEGORY_SUCCESS: "DELETE_MASTER_CATEGORY_SUCCESS",
  DELETE_MASTER_CATEGORY_FAILURE: "DELETE_MASTER_CATEGORY_INITIATE",

  ADD_CONSTRUCTION_TASK_INITIATE: "ADD_CONSTRUCTION_TASK_INITIATE",
  ADD_CONSTRUCTION_TASK_SUCCESS: "ADD_CONSTRUCTION_TASK_SUCCESS",
  ADD_CONSTRUCTION_TASK_FAILURE: "ADD_CONSTRUCTION_TASK_INITIATE",

  UPDATE_CONSTRUCTION_TASK_INITIATE: "UPDATE_CONSTRUCTION_TASK_INITIATE",
  UPDATE_CONSTRUCTION_TASK_SUCCESS: "UPDATE_CONSTRUCTION_TASK_SUCCESS",
  UPDATE_CONSTRUCTION_TASK_FAILURE: "UPDATE_CONSTRUCTION_TASK_INITIATE",

  GET_CONSTRUCTION_TASK_INITIATE: "GET_CONSTRUCTION_TASK_INITIATE",
  GET_CONSTRUCTION_TASK_SUCCESS: "GET_CONSTRUCTION_TASK_SUCCESS",
  GET_CONSTRUCTION_TASK_FAILURE: "GET_CONSTRUCTION_TASK_INITIATE",

  DELETE_CONSTRUCTION_TASK_INITIATE: "DELETE_CONSTRUCTION_TASK_INITIATE",
  DELETE_CONSTRUCTION_TASK_SUCCESS: "DELETE_CONSTRUCTION_TASK_SUCCESS",
  DELETE_CONSTRUCTION_TASK_FAILURE: "DELETE_CONSTRUCTION_TASK_INITIATE",

  VIEW_CONSTRUCTION_TASK_INITIATE: "VIEW_CONSTRUCTION_TASK_INITIATE",
  VIEW_CONSTRUCTION_TASK_SUCCESS: "VIEW_CONSTRUCTION_TASK_SUCCESS",
  VIEW_CONSTRUCTION_TASK_FAILURE: "VIEW_CONSTRUCTION_TASK_INITIATE",

  SEARCH_CONSTRUCTION_TASK_INITIATE: "SEARCH_CONSTRUCTION_TASK_INITIATE",
  SEARCH_CONSTRUCTION_TASK_SUCCESS: "SEARCH_CONSTRUCTION_TASK_SUCCESS",
  SEARCH_CONSTRUCTION_TASK_FAILURE: "SEARCH_CONSTRUCTION_TASK_INITIATE",

  ADD_MASTER_CONSTRUCTION_TASK_INITIATE:
    "ADD_MASTER_CONSTRUCTION_TASK_INITIATE",
  ADD_MASTER_CONSTRUCTION_TASK_SUCCESS: "ADD_MASTER_CONSTRUCTION_TASK_SUCCESS",
  ADD_MASTER_CONSTRUCTION_TASK_FAILURE: "ADD_MASTER_CONSTRUCTION_TASK_INITIATE",

  UPDATE_MASTER_CONSTRUCTION_TASK_INITIATE:
    "UPDATE_MASTER_CONSTRUCTION_TASK_INITIATE",
  UPDATE_MASTER_CONSTRUCTION_TASK_SUCCESS:
    "UPDATE_MASTER_CONSTRUCTION_TASK_SUCCESS",
  UPDATE_MASTER_CONSTRUCTION_TASK_FAILURE:
    "UPDATE_MASTER_CONSTRUCTION_TASK_INITIATE",

  GET_MASTER_CONSTRUCTION_TASK_INITIATE:
    "GET_MASTER_CONSTRUCTION_TASK_INITIATE",
  GET_MASTER_CONSTRUCTION_TASK_SUCCESS: "GET_MASTER_CONSTRUCTION_TASK_SUCCESS",
  GET_MASTER_CONSTRUCTION_TASK_FAILURE: "GET_MASTER_CONSTRUCTION_TASK_INITIATE",

  DELETE_MASTER_CONSTRUCTION_TASK_INITIATE:
    "DELETE_MASTER_CONSTRUCTION_TASK_INITIATE",
  DELETE_MASTER_CONSTRUCTION_TASK_SUCCESS:
    "DELETE_MASTER_CONSTRUCTION_TASK_SUCCESS",
  DELETE_MASTER_CONSTRUCTION_TASK_FAILURE:
    "DELETE_MASTER_CONSTRUCTION_TASK_INITIATE",

  VIEW_MASTER_CONSTRUCTION_TASK_INITIATE:
    "VIEW_MASTER_CONSTRUCTION_TASK_INITIATE",
  VIEW_MASTER_CONSTRUCTION_TASK_SUCCESS:
    "VIEW_MASTER_CONSTRUCTION_TASK_SUCCESS",
  VIEW_MASTER_CONSTRUCTION_TASK_FAILURE:
    "VIEW_MASTER_CONSTRUCTION_TASK_INITIATE",

  SEARCH_MASTER_CONSTRUCTION_TASK_INITIATE:
    "SEARCH_MASTER_CONSTRUCTION_TASK_INITIATE",
  SEARCH_MASTER_CONSTRUCTION_TASK_SUCCESS:
    "SEARCH_MASTER_CONSTRUCTION_TASK_SUCCESS",
  SEARCH_MASTER_CONSTRUCTION_TASK_FAILURE:
    "SEARCH_MASTER_CONSTRUCTION_TASK_INITIATE",




    CREATE_MANUAL_TASK_INITIATE:"CREATE_MANUAL_TASK_INITIATE",
    CREATE_MANUAL_TASK_SUCCESS: "CREATE_MANUAL_TASK_SUCCESS",
    CREATE_MANUAL_TASK_FAILURE: "CREATE_MANUAL_TASK_FAILURE",

    CREATE_MANUAL_PHASE_INITIATE:"CREATE_MANUAL_PHASE_INITIATE",
    CREATE_MANUAL_PHASE_SUCCESS: "CREATE_MANUAL_PHASE_SUCCESS",
    CREATE_MANUAL_PHASE_FAILURE: "CREATE_MANUAL_PHASE_FAILURE",
  
};

export const companyActionTypes = {
  CREATE_COMPANY_INITIATE: "CREATE_COMPANY_INITIATE",
  CREATE_COMPANY_SUCCESS: "CREATE_COMPANY_SUCCESS",
  CREATE_COMPANY_FAILURE: "CREATE_COMPANY_FAILURE",

  COMPANY_DETAILS_INITIATE: "COMPANY_DETAILS_INITIATE",
  COMPANY_DETAILS_SUCCESS: "COMPANY_DETAILS_SUCCESS",
  COMPANY_DETAILS_FAILURE: "COMPANY_DETAILS_FAILURE",

  ADD_TAX_INITIATE: "ADD_TAX_INITIATE",
  ADD_TAX_SUCCESS: "ADD_TAX_SUCCESS",
  ADD_TAX_FAILURE: "ADD_TAX_FAILURE",

  UPDATE_PROJECTS_INITIATE: "UPDATE_PROJECTS_INITIATE",
  UPDATE_PROJECTS_SUCCESS: "UPDATE_PROJECTS_SUCCESS",
  UPDATE_PROJECTS_FAILURE: "UPDATE_PROJECTS_FAILURE",

  GET_TAX_PROFILE_INITIATE: "GET_TAX_PROFILE_INITIATE",
  GET_TAX_PROFILE_SUCCESS: "GET_TAX_PROFILE_SUCCESS",
  GET_TAX_PROFILE_FAILURE: "GET_TAX_PROFILE_FAILURE",

  GET_REGULAR_PROJECT_INITIATE:"GET_REGULAR_PROJECT_INITIATE",
  GET_REGULAR_PROJECT_SUCCESS: "GET_REGULAR_PROJECT_SUCCESS",
  GET_REGULAR_PROJECT_FAILURE: "GET_REGULAR_PROJECT_FAILURE",

  GET_ENTITY_PROJECT_INITIATE:"GET_ENTITY_PROJECT_INITIATE",
  GET_ENTITY_PROJECT_SUCCESS: "GET_ENTITY_PROJECT_SUCCESS",
  GET_ENTITY_PROJECT_FAILURE: "GET_ENTITY_PROJECT_FAILURE",
};

export const clientViewAuthAction = {
  CLIENT_VIEW_LOGIN_INITIATE: "CLIENT_VIEW_LOGIN_INITIATE",
  CLIENT_VIEW_LOGIN_SUCCESS: "CLIENT_VIEW_LOGIN_SUCCESS",
  CLIENT_VIEW_LOGIN_FAILURE: "CLIENT_VIEW_LOGIN_FAILURE",

  CLIENT_SEND_EMAIL_INITIATE: "CLIENT_SEND_EMAIL_INITIATE",
  CLIENT_SEND_EMAIL_SUCCESS: "CLIENT_SEND_EMAIL_SUCCESS",
  CLIENT_SEND_EMAIL_FAILURE: "CLIENT_SEND_EMAIL_FAILURE",

  CLIENT_UPLOAD_MAIN_IMAGE_INITIATE: "CLIENT_UPLOAD_MAIN_IMAGE_INITIATE",
  CLIENT_UPLOAD_MAIN_IMAGE_SUCCESS: "CLIENT_UPLOAD_MAIN_IMAGE_SUCCESS",
  CLIENT_UPLOAD_MAIN_IMAGE_FAILURE: "CLIENT_UPLOAD_MAIN_IMAGE_FAILURE",

  CLIENT_UPLOAD_ICON_IMAGE_INITIATE: "CLIENT_UPLOAD_ICON_IMAGE_INITIATE",
  CLIENT_UPLOAD_ICON_IMAGE_SUCCESS: "CLIENT_UPLOAD_ICON_IMAGE_SUCCESS",
  CLIENT_UPLOAD_ICON_IMAGE_FAILURE: "CLIENT_UPLOAD_ICON_IMAGE_FAILURE",

  //===================warranty==============
  WARRANTY_REQUEST_LIST_INITIATE: "WARRANTY_REQUEST_LIST_INITIATE",
  WARRANTY_REQUEST_LIST_SUCCESS: "WARRANTY_REQUEST_LIST_SUCCESS",
  WARRANTY_REQUEST_LIST_FAILURE: "WARRANTY_REQUEST_LIST_FAILURE",

  WARRANTY_WORK_ORDER_LIST_INITIATE: "WARRANTY_WORK_ORDER_INITIATE",
  WARRANTY_WORK_ORDER_LIST_SUCCESS: "WARRANTY_WORK_ORDER_SUCCESS",
  WARRANTY_WORK_ORDER_LIST_FAILURE: "WARRANTY_WORK_ORDER_FAILURE",

  WARRANTY_DRAFT_DATA_INITIATE: "WARRANTY_DRAFT_DATA_INITIATE",
  WARRANTY_DRAFT_DATA_SUCCESS: "WARRANTY_DRAFT_DATA_SUCCESS",
  WARRANTY_DRAFT_DATA_FAILURE: "WARRANTY_DRAFT_DATA_FAILURE",

  WARRANTY_ADD_NOTES_INITIATE: "WARRANTY_ADD_NOTES_INITIATE",
  WARRANTY_ADD_NOTES_SUCCESS: "WARRANTY_ADD_NOTES_SUCCESS",
  WARRANTY_ADD_NOTES_FAILURE: "WARRANTY_ADD_NOTES_FAILURE",

  CLIENT_WARRANTY_UPDATE_INITIATE: "CLIENT_WARRANTY_UPDATE_INITIATE",
  CLIENT_WARRANTY_UPDATE_SUCCESS: "CLIENT_WARRANTY_UPDATE_SUCCESS",
  CLIENT_WARRANTY_UPDATE_FAILURE: "CLIENT_WARRANTY_UPDATE_FAILURE",

  WARRANTY_ADD_NEW_SUPPORT_DOCS_INITIATE:
    "WARRANTY_ADD_NEW_SUPPORT_DOCS_INITIATE",
  WARRANTY_ADD_NEW_SUPPORT_DOCS_SUCCESS:
    "WARRANTY_ADD_NEW_SUPPORT_DOCS_SUCCESS",
  WARRANTY_ADD_NEW_SUPPORT_DOCS_FAILURE:
    "WARRANTY_ADD_NEW_SUPPORT_DOCS_FAILURE",

  WARRANTY_ADD_URGENCY_INITIATE: "WARRANTY_ADD_URGENCY_INITIATE",
  WARRANTY_ADD_URGENCY_SUCCESS: "WARRANTY_ADD_URGENCY_SUCCESS",
  WARRANTY_ADD_URGENCY_FAILURE: "WARRANTY_ADD_URGENCY_FAILURE",

  WARRANTY_URGENCY_LIST_INITIATE: "WARRANTY_URGENCY_LIST_INITIATE",
  WARRANTY_URGENCY_LIST_SUCCESS: "WARRANTY_URGENCY_LIST_SUCCESS",
  WARRANTY_URGENCY_LIST_FAILURE: "WARRANTY_URGENCY_LIST_FAILURE",

  WARRANTY_ALL_SYSTEM_URGENCY_LIST_INITIATE:
    "WARRANTY_ALL_SYSTEM_URGENCY_LIST_INITIATE",
  WARRANTY_ALL_SYSTEM_URGENCY_LIST_SUCCESS:
    "WARRANTY_ALL_SYSTEM_URGENCY_LIST_SUCCESS",
  WARRANTY_ALL_SYSTEM_URGENCY_LIST_FAILURE:
    "WARRANTY_ALL_SYSTEM_URGENCY_LIST_FAILURE",

  WARRANTY_ALL_REQUEST_ITEM_LIST_INITIATE:
    "WARRANTY_ALL_REQUEST_ITEM_LIST_INITIATE",
  WARRANTY_ALL_REQUEST_ITEM_LIST_SUCCESS:
    "WARRANTY_ALL_REQUEST_ITEM_LIST_SUCCESS",
  WARRANTY_ALL_REQUEST_ITEM_LIST_FAILURE:
    "WARRANTY_ALL_REQUEST_ITEM_LIST_FAILURE",

  //==================================Change--order======================

  CREATE_CLIENT_CHANGE_REQUEST_INITIATE:
    "CREATE_CLIENT_CHANGE_REQUEST_INITIATE",
  CREATE_CLIENT_CHANGE_REQUEST_SUCCESS: "CREATE_CLIENT_CHANGE_REQUEST_SUCCESS",
  CREATE_CLIENT_CHANGE_REQUEST_FAILURE: "CREATE_CLIENT_CHANGE_REQUEST_FAILURE",

  CLIENT_CHANGE_REQUEST_LIST_INITIATE: "CLIENT_CHANGE_REQUEST_LIST_INITIATE",
  CLIENT_CHANGE_REQUEST_LIST_SUCCESS: "CLIENT_CHANGE_REQUEST_LIST_SUCCESS",
  CLIENT_CHANGE_REQUEST_LIST_FAILURE: "CLIENT_CHANGE_REQUEST_LIST_FAILURE",

  CLIENT_CHANGE_ORDER_LIST_INITIATE: "CLIENT_CHANGE_ORDER_LIST_INITIATE",
  CLIENT_CHANGE_ORDER_LIST_SUCCESS: "CLIENT_CHANGE_ORDER_LIST_SUCCESS",
  CLIENT_CHANGE_ORDER_LIST_FAILURE: "CLIENT_CHANGE_ORDER_LIST_FAILURE",

  //========================appointments======================================
  CLIENT_APPOINTMENT_PENIDNG_LIST_INITIATE:
    "CLIENT_APPOINTMENT_PENIDNG_LIST_INITIATE",
  CLIENT_APPOINTMENT_PENIDNG_LIST_SUCCESS:
    "CLIENT_APPOINTMENT_PENIDNG_LIST_SUCCESS",
  CLIENT_APPOINTMENT_PENIDNG_LIST_FAILURE:
    "CLIENT_APPOINTMENT_PENIDNG_LIST_FAILURE",

  CLIENT_ALL_APPOINTMENT_LIST_INITIATE: "CLIENT_ALL_APPOINTMENT_LIST_INITIATE",
  CLIENT_ALL_APPOINTMENT_LIST_SUCCESS: "CLIENT_ALL_APPOINTMENT_LIST_SUCCESS",
  CLIENT_ALL_APPOINTMENT_LIST_FAILURE: "CLIENT_ALL_APPOINTMENT_LIST_FAILURE",

  GOOGLE_CALENDAR_CLIENT_VIEW_INITIATE: "GOOGLE_CALENDAR_CLIENT_VIEW_INITIATE",
  GOOGLE_CALENDAR_CLIENT_VIEW_SUCCESS: "GOOGLE_CALENDAR_CLIENT_VIEW_SUCCESS",
  GOOGLE_CALENDAR_CLIENT_VIEW_FAILURE: "GOOGLE_CALENDAR_CLIENT_VIEW_FAILURE",

  OUTLOOK_CALENDAR_CLIENT_VIEW_INITIATE:
    "OUTLOOK_CALENDAR_CLIENT_VIEW_INITIATE",
  OUTLOOK_CALENDAR_CLIENT_VIEW_SUCCESS: "OUTLOOK_CALENDAR_CLIENT_VIEW_SUCCESS",
  OUTLOOK_CALENDAR_CLIENT_VIEW_FAILURE: "OUTLOOK_CALENDAR_CLIENT_VIEW_FAILURE",

  YAHOO_CALENDAR_CLIENT_VIEW_INITIATE: "YAHOO_CALENDAR_CLIENT_VIEW_INITIATE",
  YAHOO_CALENDAR_CLIENT_VIEW_SUCCESS: "YAHOO_CALENDAR_CLIENT_VIEW_SUCCESS",
  YAHOO_CALENDAR_CLIENT_VIEW_FAILURE: "YAHOO_CALENDAR_CLIENT_VIEW_FAILURE",

  CLIENT_APPOINTMENT_UPDATE_REMINDER_INITIATE:
    "CLIENT_APPOINTMENT_UPDATE_REMINDER_INITIATE",
  CLIENT_APPOINTMENT_UPDATE_REMINDER_SUCCESS:
    "CLIENT_APPOINTMENT_UPDATE_REMINDER_SUCCESS",
  CLIENT_APPOINTMENT_UPDATE_REMINDER_FAILURE:
    "CLIENT_APPOINTMENT_UPDATE_REMINDER_FAILURE",

  SALES_FOLDER_N_LIST_INITIATE: "SALES_FOLDER_N_LIST_INITIATE",
  SALES_FOLDER_N_LIST_SUCCESS: "SALES_FOLDER_N_LIST_SUCCESS",
  SALES_FOLDER_N_LIST_FAILURE: "SALES_FOLDER_N_LIST_FAILURE",

  SALES_CREATE_MANAGE_SIGNATURES_INITIATE:
    "SALES_CREATE_MANAGE_SIGNATURES_INITIATE",
  SALES_CREATE_MANAGE_SIGNATURES_SUCCESS:
    "SALES_CREATE_MANAGE_SIGNATURES_SUCCESS",
  SALES_CREATE_MANAGE_SIGNATURES_FAILURE:
    "SALES_CREATE_MANAGE_SIGNATURES_FAILURE",

  SALES_DOCUMENT_HISTORY_LIST_INITIATE: "SALES_DOCUMENT_HISTORY_LIST_INITIATE",
  SALES_DOCUMENT_HISTORY_LIST_SUCCESS: "SALES_DOCUMENT_HISTORY_LIST_SUCCESS",
  SALES_DOCUMENT_HISTORY_LIST_FAILURE: "SALES_DOCUMENT_HISTORY_LIST_FAILURE",

  SALE_PENDING_TEMPLATES_INITIATE: "SALE_PENDING_TEMPLATES_INITIATE",
  SALE_PENDING_TEMPLATES_SUCCESS: "SALE_PENDING_TEMPLATES_SUCCESS",
  SALE_PENDING_TEMPLATES_FAILURE: "SALE_PENDING_TEMPLATES_FAILURE",

  SALE_APPROVED_CLIENT_DOC_TEMPLATE_INITIATE:
    "SALE_APPROVED_CLIENT_DOC_TEMPLATE_INITIATE",
  SALE_APPROVED_CLIENT_DOC_TEMPLATE_SUCCESS:
    "SALE_APPROVED_CLIENT_DOC_TEMPLATE_SUCCESS",
  SALE_APPROVED_CLIENT_DOC_TEMPLATE_FAILURE:
    "SALE_APPROVED_CLIENT_DOC_TEMPLATE_FAILURE",
  //==========================design----selectons======================

  GET_INTERIOR_DESIGN_SELECTONS_INITIATE:
    "GET_INTERIOR_DESIGN_SELECTONS_INITIATE",
  GET_INTERIOR_DESIGN_SELECTONS_SUCCESS:
    "GET_INTERIOR_DESIGN_SELECTONS_SUCCESS",
  GET_INTERIOR_DESIGN_SELECTONS_FAILURE:
    "GET_INTERIOR_DESIGN_SELECTONS_FAILURE",

  GET_EXTERIOR_DESIGN_SELECTONS_INITIATE:
    "GET_EXTERIOR_DESIGN_SELECTONS_INITIATE",
  GET_EXTERIOR_DESIGN_SELECTONS_SUCCESS:
    "GET_EXTERIOR_DESIGN_SELECTONS_SUCCESS",
  GET_EXTERIOR_DESIGN_SELECTONS_FAILURE:
    "GET_EXTERIOR_DESIGN_SELECTONS_FAILURE",

  ADD_CLIENT_VIEW_USER_INITIATE: "ADD_CLIENT_VIEW_USER_INITIATE",
  ADD_CLIENT_VIEW_USER_SUCCESS: "ADD_CLIENT_VIEW_USER_SUCCESS",
  ADD_CLIENT_VIEW_USER_FAILURE: "ADD_CLIENT_VIEW_USER_FAILURE",

  GET_CLIENT_VIEW_USER_INITIATE: "GET_CLIENT_VIEW_USER_INITIATE",
  GET_CLIENT_VIEW_USER_SUCCESS: "GET_CLIENT_VIEW_USER_SUCCESS",
  GET_CLIENT_VIEW_USER_FAILURE: "GET_CLIENT_VIEW_USER_FAILURE",

  UPDATE_CLIENT_VIEW_USER_INITIATE: "UPDATE_CLIENT_VIEW_USER_INITIATE",
  UPDATE_CLIENT_VIEW_USER_SUCCESS: "UPDATE_CLIENT_VIEW_USER_SUCCESS",
  UPDATE_CLIENT_VIEW_USER_FAILURE: "UPDATE_CLIENT_VIEW_USER_FAILURE",

  REMOVE_CLIENT_VIEW_USER_INITIATE: "REMOVE_CLIENT_VIEW_USER_INITIATE",
  REMOVE_CLIENT_VIEW_USER_SUCCESS: "REMOVE_CLIENT_VIEW_USER_SUCCESS",
  REMOVE_CLIENT_VIEW_USER_FAILURE: "REMOVE_CLIENT_VIEW_USER_FAILURE",

  CLIENT_VIEW_GENERAL_CONFIG_INITIATE: "CLIENT_VIEW_GENERAL_CONFIG_INITIATE",
  CLIENT_VIEW_GENERAL_CONFIG_SUCCESS: "CLIENT_VIEW_GENERAL_CONFIG_SUCCESS",
  CLIENT_VIEW_GENERAL_CONFIG_FAILURE: "CLIENT_VIEW_GENERAL_CONFIG_FAILURE",

  CLIENT_USER_PASSWORD_EMAIL_SEND_INITIATE:
    "CLIENT_USER_PASSWORD_EMAIL_SEND_INITIATE",
  CLIENT_USER_PASSWORD_EMAIL_SEND_SUCCESS:
    "CLIENT_USER_PASSWORD_EMAIL_SEND_SUCCESS",
  CLIENT_USER_PASSWORD_EMAIL_SEND_FAILURE:
    "CLIENT_USER_PASSWORD_EMAIL_SEND_FAILURE",

  CLIENT_UPDATE_DOCS_INITIATE: "CLIENT_UPDATE_DOCS_INITIATE",
  CLIENT_UPDATE_DOCS_SUCCESS: "CLIENT_UPDATE_DOCS_SUCCESS",
  CLIENT_UPDATE_DOCS_FAILURE: "CLIENT_UPDATE_DOCS_FAILURE",

  CLIENT_DELETE_SUPPORTING_DOCS_INITIATE:
    "CLIENT_DELETE_SUPPORTING_DOCS_INITIATE",
  CLIENT_DELETE_SUPPORTING_DOCS_SUCCESS:
    "CLIENT_DELETE_SUPPORTING_DOCS_SUCCESS",
  CLIENT_DELETE_SUPPORTING_DOCS_FAILURE:
    "CLIENT_DELETE_SUPPORTING_DOCS_FAILURE",

  CLIENT_VIEW_HTML_CONVERTOR_INITIATE: "CLIENT_VIEW_HTML_CONVERTOR_INITIATE",
  CLIENT_VIEW_HTML_CONVERTOR_SUCCESS: "CLIENT_VIEW_HTML_CONVERTOR_SUCCESS",
  CLIENT_VIEW_HTML_CONVERTOR_FAILURE: "CLIENT_VIEW_HTML_CONVERTOR_FAILURE",

  ADD_ADJUSTMENT_INITIATE: "ADD_ADJUSTMENT_INITIATE",
  ADD_ADJUSTMENT_SUCCESS: "ADD_ADJUSTMENT_SUCCESS",
  ADD_ADJUSTMENT_FAILURE: "ADD_ADJUSTMENT_FAILURE",

  REMOVE_ADJUSTMENT_INITIATE: "REMOVE_ADJUSTMENT_INITIATE",
  REMOVE_ADJUSTMENT_SUCCESS: "REMOVE_ADJUSTMENT_SUCCESS",
  REMOVE_ADJUSTMENT_FAILURE: "REMOVE_ADJUSTMENT_FAILURE",

  GET_ADJUSTMENT_INITIATE: "GET_ADJUSTMENT_INITIATE",
  GET_ADJUSTMENT_SUCCESS: "GET_ADJUSTMENT_SUCCESS",
  GET_ADJUSTMENT_FAILURE: "GET_ADJUSTMENT_FAILURE",

  GET_NOTIFICATIONS_INITIATE: "GET_NOTIFICATIONS_INITIATE",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",

  GET_TASK_FOR_CLIENT_INITIATE: "GET_TASK_FOR_CLIENT_INITIATE",
  GET_TASK_FOR_CLIENT_SUCCESS: "GET_TASK_FOR_CLIENT_SUCCESS",
  GET_TASK_FOR_CLIENT_FAILURE: "GET_TASK_FOR_CLIENT_FAILURE",

  CREATE_COMMENT_INITIATE: "CREATE_COMMENT_INITIATE",
  CREATE_COMMENT_SUCCESS: "CREATE_COMMENT_SUCCESS",
  CREATE_COMMENT_FAILURE: "CREATE_COMMENT_FAILURE",

  GET_COMMENT_INITIATE: "GET_COMMENT_INITIATE",
  GET_COMMENT_SUCCESS: "GET_COMMENT_SUCCESS",
  GET_COMMENT_FAILURE: "GET_COMMENT_FAILURE",

  CREATE_SUBCOMMENT_INITIATE: "CREATE_SUBCOMMENT_INITIATE",
  CREATE_SUBCOMMENT_SUCCESS: "CREATE_SUBCOMMENT_SUCCESS",
  CREATE_SUBCOMMENT_FAILURE: "CREATE_SUBCOMMENT_FAILURE",

  GET_SUBCOMMENT_INITIATE: "GET_SUBCOMMENT_INITIATE",
  GET_SUBCOMMENT_SUCCESS: "GET_SUBCOMMENT_SUCCESS",
  GET_SUBCOMMENT_FAILURE: "GET_SUBCOMMENT_FAILURE",
};

export const tradeViewAuthAction = {
  TRADE_VIEW_LOGIN_INITIATE: "TRADE_VIEW_LOGIN_INITIATE",
  TRADE_VIEW_LOGIN_SUCCESS: "TRADE_VIEW_LOGIN_SUCCESS",
  TRADE_VIEW_LOGIN_FAILURE: "TRADE_VIEW_LOGIN_FAILURE",

  TRADE_SEND_EMAIL_INITIATE: "TRADE_SEND_EMAIL_INITIATE",
  TRADE_SEND_EMAIL_SUCCESS: "TRADE_SEND_EMAIL_SUCCESS",
  TRADE_SEND_EMAIL_FAILURE: "TRADE_SEND_EMAIL_FAILURE",

  GET_TRADE_VENDOR_BY_USER_ID_INITIATE: "GET_TRADE_VENDOR_BY_USER_ID_INITIATE",
  GET_TRADE_VENDOR_BY_USER_ID_SUCCESS: "GET_TRADE_VENDOR_BY_USER_ID_SUCCESS",
  GET_TRADE_VENDOR_BY_USER_ID_FAILURE: "GET_TRADE_VENDOR_BY_USER_ID_FAILURE",

  UPLOAD_TRADE_ICON_IMAGE_INITIATE: "UPLOAD_TRADE_ICON_IMAGE_INITIATE",
  UPLOAD_TRADE_ICON_IMAGE_SUCCESS: "UPLOAD_TRADE_ICON_IMAGE_SUCCESS",
  UPLOAD_TRADE_ICON_IMAGE_FAILURE: "UPLOAD_TRADE_ICON_IMAGE_FAILURE",

  UPLOAD_MAIN_TRADE_IMAGE_INITIATE: "UPLOAD_MAIN_TRADE_IMAGE_INITIATE",
  UPLOAD_MAIN_TRADE_IMAGE_SUCCESS: "UPLOAD_MAIN_TRADE_IMAGE_SUCCESS",
  UPLOAD_MAIN_TRADE_IMAGE_FAILURE: "UPLOAD_MAIN_TRADE_IMAGE_FAILURE",

  CREATE_TRADE_COMMENT_INITIATE: "CREATE_TRADE_COMMENT_INITIATE",
  CREATE_TRADE_COMMENT_SUCCESS: "CREATE_TRADE_COMMENT_SUCCESS",
  CREATE_TRADE_COMMENT_FAILURE: "CREATE_TRADE_COMMENT_FAILURE",

  GET_TRADE_COMMENT_INITIATE: "GET_TRADE_COMMENT_INITIATE",
  GET_TRADE_COMMENT_SUCCESS: "GET_TRADE_COMMENT_SUCCESS",
  GET_TRADE_COMMENT_FAILURE: "GET_TRADE_COMMENT_FAILURE",

  CREATE_TRADE_SUB_COMMENT_INITIATE: "CREATE_TRADE_SUB_COMMENT_INITIATE",
  CREATE_TRADE_SUB_COMMENT_SUCCESS: "CREATE_TRADE_SUB_COMMENT_SUCCESS",
  CREATE_TRADE_SUB_COMMENT_FAILURE: "CREATE_TRADE_SUB_COMMENT_FAILURE",

  GET_SUB_TRADE_COMMENT_INITIATE: "GET_SUB_TRADE_COMMENT_INITIATE",
  GET_SUB_TRADE_COMMENT_SUCCESS: "GET_SUB_TRADE_COMMENT_SUCCESS",
  GET_SUB_TRADE_COMMENT_FAILURE: "GET_SUB_TRADE_COMMENT_FAILURE",

  TRADE_VIEW_ACCESS_INITIATE: "TRADE_VIEW_ACCESS_INITIATE",
  TRADE_VIEW_ACCESS_SUCCESS: "TRADE_VIEW_ACCESS_SUCCESS",
  TRADE_VIEW_ACCESS_FAILURE: "TRADE_VIEW_ACCESS_FAILURE",

  CONSTRUCTION_SCHEDULE_LIST_INITIATE: "CONSTRUCTION_SCHEDULE_LIST_INITIATE",
  CONSTRUCTION_SCHEDULE_LIST_SUCCESS: "CONSTRUCTION_SCHEDULE_LIST_SUCCESS",
  CONSTRUCTION_SCHEDULE_LIST_FAILURE: "CONSTRUCTION_SCHEDULE_LIST_FAILURE",

  CONSTRUCTION_SUPPORT_ADD_DOCS_INITIATE:
    "CONSTRUCTION_SUPPORT_ADD_DOCS_INITIATE",
  CONSTRUCTION_SUPPORT_ADD_DOCS_SUCCESS:
    "CONSTRUCTION_SUPPORT_ADD_DOCS_SUCCESS",
  CONSTRUCTION_SUPPORT_ADD_DOCS_FAILURE:
    "CONSTRUCTION_SUPPORT_ADD_DOCS_FAILURE",

  CONSTRUCTION_SUPPORT_EDIT_DOCS_INITIATE:
    "CONSTRUCTION_SUPPORT_EDIT_DOCS_INITIATE",
  CONSTRUCTION_SUPPORT_EDIT_DOCS_SUCCESS:
    "CONSTRUCTION_SUPPORT_EDIT_DOCS_SUCCESS",
  CONSTRUCTION_SUPPORT_EDIT_DOCS_FAILURE:
    "CONSTRUCTION_SUPPORT_EDIT_DOCS_FAILURE",

  CONSTRUCTION_SUPPORT_DOCS_LIST_INITIATE:
    "CONSTRUCTION_SUPPORT_DOCS_LIST_INITIATE",
  CONSTRUCTION_SUPPORT_DOCS_LIST_SUCCESS:
    "CONSTRUCTION_SUPPORT_DOCS_LIST_SUCCESS",
  CONSTRUCTION_SUPPORT_DOCS_LIST_FAILURE:
    "CONSTRUCTION_SUPPORT_DOCS_LIST_FAILURE",

  CONSTRUCTION_SUPPORT_DOCS_DELETE_INITIATE:
    "CONSTRUCTION_SUPPORT_DOCS_DELETE_INITIATE",
  CONSTRUCTION_SUPPORT_DOCS_DELETE_SUCCESS:
    "CONSTRUCTION_SUPPORT_DOCS_DELETE_SUCCESS",
  CONSTRUCTION_SUPPORT_DOCS_DELETE_FAILURE:
    "CONSTRUCTION_SUPPORT_DOCS_DELETE_FAILURE",

  CONSTRUCTION_CALENDAR_DATA_INITIATE:
    "CONSTRUCTION_CALENDAR_DATA_DELETE_INITIATE",
  CONSTRUCTION_CALENDAR_DATA_SUCCESS:
    "CONSTRUCTION_CALENDAR_DATA_DELETE_SUCCESS",
  CONSTRUCTION_CALENDAR_DATA_FAILURE:
    "CONSTRUCTION_CALENDAR_DATA_DELETE_FAILURE",

  CONSTRUCTION_SCHEDULE_VENDOR_PENDING_LIST_INITIATE:
    "CONSTRUCTION_SCHEDULE_VENDOR_PENDING_LIST_INITIATE",
  CONSTRUCTION_SCHEDULE_VENDOR_PENDING_LIST_SUCCESS:
    "CONSTRUCTION_SCHEDULE_VENDOR_PENDING_LIST_SUCCESS",
  CONSTRUCTION_SCHEDULE_VENDOR_PENDING_LIST_FAILURE:
    "CONSTRUCTION_SCHEDULE_VENDOR_PENDING_LIST_FAILURE",

  CONSTRUCTION_SCHEDULE_ACCEPT_REJECT_INITIATE:
    "CONSTRUCTION_SCHEDULE_ACCEPT_REJECT_INITIATE",
  CONSTRUCTION_SCHEDULE_ACCEPT_REJECT_SUCCESS:
    "CONSTRUCTION_SCHEDULE_ACCEPT_REJECT_SUCCESS",
  CONSTRUCTION_SCHEDULE_ACCEPT_REJECT_FAILURE:
    "CONSTRUCTION_SCHEDULE_ACCEPT_REJECT_FAILURE",

  TODO_JOB_LIST_INITIATE: "TODO_JOB_LIST_INITIATE",
  TODO_JOB_LIST_SUCCESS: "TODO_JOB_LIST_SUCCESS",
  TODO_JOB_LIST_FAILURE: "TODO_JOB_LIST_FAILURE",

  TODO_VENDOR_LIST_INITIATE: "TODO_VENDOR_LIST_INITIATE",
  TODO_VENDOR_LIST_SUCCESS: "TODO_VENDOR_LIST_SUCCESS",
  TODO_VENDOR_LIST_FAILURE: "TODO_VENDOR_LIST_FAILURE",

  TODO_MEMBER_LIST_INITIATE: "TODO_MEMBER_LIST_INITIATE",
  TODO_MEMBER_LIST_SUCCESS: "TODO_MEMBER_LIST_SUCCESS",
  TODO_MEMBER_LIST_FAILURE: "TODO_MEMBER_LIST_FAILURE",

  CREATE_TODO_INITIATE: "CREATE_TODO_INITIATE",
  CREATE_TODO_SUCCESS: "CREATE_TODO_SUCCESS",
  CREATE_TODO_FAILURE: "CREATE_TODO_FAILURE",

  TODO_TASK_LIST_INITIATE: "TODO_TASK_LIST_INITIATE",
  TODO_TASK_LIST_SUCCESS: "TODO_TASK_LIST_SUCCESS",
  TODO_TASK_LIST_FAILURE: "TODO_TASK_LIST_FAILURE",

  TODO_UPDATE_REMINDER_INITIATE: "TODO_UPDATE_REMINDER_INITIATE",
  TODO_UPDATE_REMINDER_SUCCESS: "TODO_UPDATE_REMINDER_SUCCESS",
  TODO_UPDATE_REMINDER_FAILURE: "TODO_UPDATE_REMINDER_FAILURE",

  TODO_GOOGLE_CALENDAR_DATA_INITIATE: "TODO_GOOGLE_CALENDAR_DATA_INITIATE",
  TODO_GOOGLE_CALENDAR_DATA_SUCCESS: "TODO_GOOGLE_CALENDAR_DATA_SUCCESS",
  TODO_GOOGLE_CALENDAR_DATA_FAILURE: "TODO_GOOGLE_CALENDAR_DATA_FAILURE",

  TODO_OUTLOOK_DATA_INITIATE: "TODO_OUTLOOK_DATA_INITIATE",
  TODO_OUTLOOK_DATA_SUCCESS: "TODO_OUTLOOK_DATA_SUCCESS",
  TODO_OUTLOOK_DATA_FAILURE: "TODO_OUTLOOK_DATA_FAILURE",

  TODO_EMAIL_DATA_INITIATE: "TODO_EMAIL_DATA_INITIATE",
  TODO_EMAIL_DATA_SUCCESS: "TODO_EMAIL_DATA_SUCCESS",
  TODO_EMAIL_DATA_FAILURE: "TODO_EMAIL_DATA_FAILURE",

  TODO_VIEW_TASK_DETAILS_INITIATE: "TODO_VIEW_TASK_DETAILS_INITIATE",
  TODO_VIEW_TASK_DETAILS_SUCCESS: "TODO_VIEW_TASK_DETAILS_SUCCESS",
  TODO_VIEW_TASK_DETAILS_FAILURE: "TODO_VIEW_TASK_DETAILS_FAILURE",

  TODO_VIEW_TASK_HISTORY_INITIATE: "TODO_VIEW_TASK_HISTORY_INITIATE",
  TODO_VIEW_TASK_HISTORY_SUCCESS: "TODO_VIEW_TASK_HISTORY_SUCCESS",
  TODO_VIEW_TASK_HISTORY_FAILURE: "TODO_VIEW_TASK_HISTORY_FAILURE",

  //======================warranty---todo--=================================

  TODO_BUILDER_LIST_INITIATE: "TODO_BUILDER_LIST_INITIATE",
  TODO_BUILDER_LIST_SUCCESS: "TODO_BUILDER_LIST_SUCCESS",
  TODO_BUILDER_LIST_FAILURE: "TODO_BUILDER_LIST_FAILURE",

  TODO_PORJECT_LIST_INITIATE: "TODO_PORJECT_LIST_INITIATE",
  TODO_PORJECT_LIST_SUCCESS: "TODO_PORJECT_LIST_SUCCESS",
  TODO_PORJECT_LIST_FAILURE: "TODO_PORJECT_LIST_FAILURE",

  TODO_ALL_JOB_LIST_INITIATE: "TODO_ALL_JOB_LIST_INITIATE",
  TODO_ALL_JOB_LIST_SUCCESS: "TODO_ALL_JOB_LIST_SUCCESS",
  TODO_ALL_JOB_LIST_FAILURE: "TODO_ALL_JOB_LIST_FAILURE",

  TODO_WARRANTY_WORK_ORDER_LIST_INITIATE:
    "TODO_WARRANTY_WORK_ORDER_LIST_INITIATE",
  TODO_WARRANTY_WORK_ORDER_LIST_SUCCESS:
    "TODO_WARRANTY_WORK_ORDER_LIST_SUCCESS",
  TODO_WARRANTY_WORK_ORDER_LIST_FAILURE:
    "TODO_WARRANTY_WORK_ORDER_LIST_FAILURE",

  TODO_WARRANTY_SUPPORT_DOCS_INITIATE: "TODO_WARRANTY_SUPPORT_DOCS_INITIATE",
  TODO_WARRANTY_SUPPORT_DOCS_SUCCESS: "TODO_WARRANTY_SUPPORT_DOCS_SUCCESS",
  TODO_WARRANTY_SUPPORT_DOCS_FAILURE: "TODO_WARRANTY_SUPPORT_DOCS_FAILURE",

  TODO_WARRANTY_SUPPORT_DOCS_LIST_INITIATE:
    "TODO_WARRANTY_SUPPORT_DOCS_LIST_INITIATE",
  TODO_WARRANTY_SUPPORT_DOCS_LIST_SUCCESS:
    "TODO_WARRANTY_SUPPORT_DOCS_LIST_SUCCESS",
  TODO_WARRANTY_SUPPORT_DOCS_LIST_FAILURE:
    "TODO_WARRANTY_SUPPORT_DOCS_LIST_FAILURE",

  TODO_WARRANTY_DELETE_INITIATE: "TODO_WARRANTY_DELETE_INITIATE",
  TODO_WARRANTY_DELETE_SUCCESS: "TODO_WARRANTY_DELETE_SUCCESS",
  TODO_WARRANTY_DELETE_FAILURE: "TODO_WARRANTY_DELETE_FAILURE",

  TODO_CALENDAR_TASK_LIST_INITIATE: "TODD_CALENDAR_TASK_LIST_INITIATE",
  TODO_CALENDAR_TASK_LIST_SUCCESS: "TODD_CALENDAR_TASK_LIST_SUCCESS",
  TODO_CALENDAR_TASK_LIST_FAILURE: "TODO_CALENDAR_TASK_LIST_FAILURE",

  TODO_WARRANTY_WORK_ORDER_DETAILS_INITIATE:
    "TODO_WARRANTY_WORK_ORDER_DETAILS_INITIATE",
  TODO_WARRANTY_WORK_ORDER_DETAILS_SUCCESS:
    "TODO_WARRANTY_WORK_ORDER_DETAILS_SUCCESS",
  TODO_WARRANTY_WORK_ORDER_DETAILS_FAILURE:
    "TODO_WARRANTY_WORK_ORDER_DETAILS_FAILURE",

  TODO_WARRANTY_WORK_ORDER_ADD_NOTES_INITIATE:
    "TODO_WARRANTY_WORK_ORDER_ADD_NOTES_INITIATE",
  TODO_WARRANTY_WORK_ORDER_ADD_NOTES_SUCCESS:
    "TODO_WARRANTY_WORK_ORDER_ADD_NOTES_SUCCESS",
  TODO_WARRANTY_WORK_ORDER_ADD_NOTES_FAILURE:
    "TODO_WARRANTY_WORK_ORDER_ADD_NOTES_FAILURE",

  //===========================PQ/Qr========================

  GET_QUOTE_REQUEST_INITIATE: "GET_QUOTE_REQUEST_INITIATE",
  GET_QUOTE_REQUEST_SUCCESS: "GET_QUOTE_REQUEST_SUCCESS",
  GET_QUOTE_REQUEST_FAILURE: "GET_QUOTE_REQUEST_FAILURE",

  UPDATE_QUOTE_REQUEST_INITIATE: "UPDATE_QUOTE_REQUEST_INITIATE",
  UPDATE_QUOTE_REQUEST_SUCCESS: "UPDATE_QUOTE_REQUEST_SUCCESS",
  UPDATE_QUOTE_REQUEST_FAILURE: "UPDATE_QUOTE_REQUEST_FAILURE",

  GET_PURCHASE_ORDER_INITIATE: "GET_PURCHASE_ORDER_INITIATE",
  GET_PURCHASE_ORDER_SUCCESS: "GET_PURCHASE_ORDER_SUCCESS",
  GET_PURCHASE_ORDER_FAILURE: "GET_PURCHASE_ORDER_FAILURE",

  UNIT_LIST_INITIATE: "UNIT_LIST_INITIATE",
  UNIT_LIST_SUCCESS: "UNIT_LIST_SUCCESS",
  UNIT_LIST_FAILURE: "UNIT_LIST_FAILURE",

  CREATE_UNIT_INITIATE: "CREATE_UNIT_INITIATE",
  CREATE_UNIT_SUCCESS: "CREATE_UNIT_SUCCESS",
  CREATE_UNIT_FAILURE: "CREATE_UNIT_FAILURE",

  EDIT_QR_REQUEST_INITIATE: "EDIT_QR_REQUEST_INITIATE",
  EDIT_QR_REQUEST_SUCCESS: "EDIT_QR_REQUEST_SUCCESS",
  EDIT_QR_REQUEST_FAILURE: "EDIT_QR_REQUEST_FAILURE",

  //====================change-order=================

  CHANGE_ORDER_CHECKLIST_LIST_INITIATE: "CHANGE_ORDER_CHECKLIST_LIST_INITIATE",
  CHANGE_ORDER_CHECKLIST_LIST_SUCCESS: "CHANGE_ORDER_CHECKLIST_LIST_SUCCESS",
  CHANGE_ORDER_CHECKLIST_LIST_FAILURE: "CHANGE_ORDER_CHECKLIST_LIST_FAILURE",

  CHANGE_ORDER_UPLOAD_FILES_INITIATE: "CHANGE_ORDER_UPLOAD_FILES_INITIATE",
  CHANGE_ORDER_UPLOAD_FILES_SUCCESS: "CHANGE_ORDER_UPLOAD_FILES_SUCCESS",
  CHANGE_ORDER_UPLOAD_FILES_FAILURE: "CHANGE_ORDER_UPLOAD_FILES_FAILURE",

  //===================file-manger==========================

  GET_FOLDER_N_FILE_LIST_INITIATE: "GET_FOLDER_N_FILE_LIST_INITIATE",
  GET_FOLDER_N_FILE_LIST_SUCCESS: "GET_FOLDER_N_FILE_LIST_SUCCESS",
  GET_FOLDER_N_FILE_LIST_FAILURE: "GET_FOLDER_N_FILE_LIST_FAILURE",

  GET_FOLDER_DOCUMENT_LIST_INITIATE: "GET_FOLDER_DOCUMENT_LIST_INITIATE",
  GET_FOLDER_DOCUMENT_LIST_SUCCESS: "GET_FOLDER_DOCUMENT_LIST_SUCCESS",
  GET_FOLDER_DOCUMENT_LIST_FAILURE: "GET_FOLDER_DOCUMENT_LIST_FAILURE",

  GET_FOLDER_HISTORY_INITIATE: "GET_FOLDER_HISTORY_INITIATE",
  GET_FOLDER_HISTORY_SUCCESS: "GET_FOLDER_HISTORY_SUCCESS",
  GET_FOLDER_HISTORY_FAILURE: "GET_FOLDER_HISTORY_FAILURE",

  //====================design-selections================

  GET_DESIGN_BY_CATEGORY_INTERIOR_INITIATE:
    "GET_DESIGN_BY_CATEGORY_INTERIOR_INITIATE",
  GET_DESIGN_BY_CATEGORY_INTERIOR_SUCCESS:
    "GET_DESIGN_BY_CATEGORY_INTERIOR_SUCCESS",
  GET_DESIGN_BY_CATEGORY_INTERIOR_FAILURE:
    "GET_DESIGN_BY_CATEGORY_INTERIOR_FAILURE",

  GET_DESIGN_BY_CATEGORY_EXTERIOR_INITIATE:
    "GET_DESIGN_BY_CATEGORY_EXTERIOR_INITIATE",
  GET_DESIGN_BY_CATEGORY_EXTERIOR_SUCCESS:
    "GET_DESIGN_BY_CATEGORY_EXTERIOR_SUCCESS",
  GET_DESIGN_BY_CATEGORY_EXTERIOR_FAILURE:
    "GET_DESIGN_BY_CATEGORY_EXTERIOR_FAILURE",

  //=====================appointment==================

  PENDING_DATE_SELECTION_LIST_INITIATE: "PENDING_DATE_SELECTION_LIST_INITIATE",
  PENDING_DATE_SELECTION_LIST_SUCCESS: "PENDING_DATE_SELECTION_LIST_SUCCESS",
  PENDING_DATE_SELECTION_LIST_FAILURE: "PENDING_DATE_SELECTION_LIST_FAILURE",

  BUILDER_LIST_INITIATE: "BUILDER_LIST_INITIATE",
  BUILDER_LIST_SUCCESS: "BUILDER_LIST_SUCCESS",
  BUILDER_LIST_FAILURE: "BUILDER_LIST_FAILURE",

  GET_PROJECT_BY_BUILDER_INITIATE: "GET_PROJECT_BY_BUILDER_INITIATE",
  GET_PROJECT_BY_BUILDER_SUCCESS: "GET_PROJECT_BY_BUILDER_SUCCESS",
  GET_PROJECT_BY_BUILDER_FAILURE: "GET_PROJECT_BY_BUILDER_FAILURE",

  GET_JOB_BY_PROJECT_INITIATE: "GET_JOB_BY_PROJECT_INITIATE",
  GET_JOB_BY_PROJECT_SUCCESS: "GET_JOB_BY_PROJECT_SUCCESS",
  GET_JOB_BY_PROJECT_FAILURE: "GET_JOB_BY_PROJECT_FAILURE",

  GET_APPOINTMENT_ALL_INITIATE: "GET_APPOINTMENT_ALL_INITIATE",
  GET_APPOINTMENT_ALL_SUCCESS: "GET_APPOINTMENT_ALL_SUCCESS",
  GET_APPOINTMENT_ALL_FAILURE: "GET_APPOINTMENT_ALL_FAILURE",

  SET_APPOINTMENT_REMINDER_INITIATE: "SET_APPOINTMENT_REMINDER_INITIATE",
  SET_APPOINTMENT_REMINDER_SUCCESS: "SET_APPOINTMENT_REMINDER_SUCCESS",
  SET_APPOINTMENT_REMINDER_FAILURE: "SET_APPOINTMENT_REMINDER_FAILURE",

  GOOGLE_CALENDAR_TRADE_VIEW_INITIATE: "GOOGLE_CALENDAR_TRADE_VIEW_INITIATE",
  GOOGLE_CALENDAR_TRADE_VIEW_SUCCESS: "GOOGLE_CALENDAR_TRADE_VIEW_SUCCESS",
  GOOGLE_CALENDAR_TRADE_VIEW_FAILURE: "GOOGLE_CALENDAR_TRADE_VIEW_FAILURE",

  OUTLOOK_CALENDAR_TRADE_VIEW_INITIATE: "OUTLOOK_CALENDAR_TRADE_VIEW_INITIATE",
  OUTLOOK_CALENDAR_TRADE_VIEW_SUCCESS: "OUTLOOK_CALENDAR_TRADE_VIEW_SUCCESS",
  OUTLOOK_CALENDAR_TRADE_VIEW_FAILURE: "OUTLOOK_CALENDAR_TRADE_VIEW_FAILURE",

  YAHOO_CALENDAR_TRADE_VIEW_INITIATE: "YAHOO_CALENDAR_TRADE_VIEW_INITIATE",
  YAHOO_CALENDAR_TRADE_VIEW_SUCCESS: "YAHOO_CALENDAR_TRADE_VIEW_SUCCESS",
  YAHOO_CALENDAR_TRADE_VIEW_FAILURE: "YAHOO_CALENDAR_TRADE_VIEW_FAILURE",

  ACCEPT_REJECT_APPOINMENTS_INITIATE: "ACCEPT_REJECT_APPOINMENTS_INITIATE",
  ACCEPT_REJECT_APPOINMENTS_SUCCESS: "ACCEPT_REJECT_APPOINMENTS_SUCCESS",
  ACCEPT_REJECT_APPOINMENTS_FAILURE: "ACCEPT_REJECT_APPOINMENTS_FAILURE",

  TRADE_VIEW_HTML_CONVERTOR_INITIATE: "TRADE_VIEW_HTML_CONVERTOR_INITIATE",
  TRADE_VIEW_HTML_CONVERTOR_SUCCESS: "TRADE_VIEW_HTML_CONVERTOR_SUCCESS",
  TRADE_VIEW_HTML_CONVERTOR_FAILURE: "TRADE_VIEW_HTML_CONVERTOR_FAILURE",

  GET_DESIGN_CATEGORY_BY_ID_INITIATE: "GET_DESIGN_CATEGORY_BY_ID_INITIATE",
  GET_DESIGN_CATEGORY_BY_ID_SUCCESS: "GET_DESIGN_CATEGORY_BY_ID_SUCCESS",
  GET_DESIGN_CATEGORY_BY_ID_FAILURE: "GET_DESIGN_CATEGORY_BY_ID_FAILURE",

  GET_OPTION_BY_CATEGORY_INITIATE: "GET_OPTION_BY_CATEGORY_INITIATE",
  GET_OPTION_BY_CATEGORY_SUCCESS: "GET_OPTION_BY_CATEGORY_SUCCESS",
  GET_OPTION_BY_CATEGORY_FAILURE: "GET_OPTION_BY_CATEGORY_FAILURE",

  WORK_ORDER_SELECTED_ITEM_INITIATE: "WORK_ORDER_SELECTED_ITEM_INITIATE",
  WORK_ORDER_SELECTED_ITEM_SUCCESS: "WORK_ORDER_SELECTED_ITEM_SUCCESS",
  WORK_ORDER_SELECTED_ITEM_FAILURE: "WORK_ORDER_SELECTED_ITEM_FAILURE",

  ACTION_REQURIED_SALES_DOCUMENT_LIST_INITIATE:
    "ACTION_REQURIED_SALES_DOCUMENT_LIST_INITIATE",
  ACTION_REQURIED_SALES_DOCUMENT_LIST_SUCCESS:
    "ACTION_REQURIED_SALES_DOCUMENT_LIST_SUCCESS",
  ACTION_REQURIED_SALES_DOCUMENT_LIST_FAILURE:
    "ACTION_REQURIED_SALES_DOCUMENT_LIST_FAILURE",

  ACCEPT_PURCHASE_ORDER_INITIATE: "ACCEPT_PURCHASE_ORDER_INITIATE",
  ACCEPT_PURCHASE_ORDER_SUCCESS: "ACCEPT_PURCHASE_ORDER_SUCCESS",
  ACCEPT_PURCHASE_ORDER_FAILURE: "ACCEPT_PURCHASE_ORDER_FAILURE",

  REJECT_PURCHASE_ORDER_INITIATE: "REJECT_PURCHASE_ORDER_INITIATE",
  REJECT_PURCHASE_ORDER_SUCCESS: "REJECT_PURCHASE_ORDER_SUCCESS",
  REJECT_PURCHASE_ORDER_FAILURE: "REJECT_PURCHASE_ORDER_FAILURE",

  GET_LIST_CHECKLIST_CHANGE_ORDER_INITIATE:
    "GET_LIST_CHECKLIST_CHANGE_ORDER_INITIATE",
  GET_LIST_CHECKLIST_CHANGE_ORDER_SUCCESS:
    "GET_LIST_CHECKLIST_CHANGE_ORDER_SUCCESS",
  GET_LIST_CHECKLIST_CHANGE_ORDER_FAILURE:
    "GET_LIST_CHECKLIST_CHANGE_ORDER_FAILURE",

  TRADE_VIEW_JOB_LIST_INITIATE: "TRADE_VIEW_JOB_LIST_INITIATE",
  TRADE_VIEW_JOB_LIST_SUCCESS: "TRADE_VIEW_JOB_LIST_SUCCESS",
  TRADE_VIEW_JOB_LIST_FAILURE: "TRADE_VIEW_JOB_LIST_FAILURE",

  TRADE_VIEW_MEMBER_LIST_INITIATE: "TRADE_VIEW_MEMBER_LIST_INITIATE",
  TRADE_VIEW_MEMBER_LIST_SUCCESS: "TRADE_VIEW_MEMBER_LIST_SUCCESS",
  TRADE_VIEW_MEMBER_LIST_FAILURE: "TRADE_VIEW_MEMBER_LIST_FAILURE",

  CREATE_TODO_TRADE_VIEW_INITIATE: "CREATE_TODO_TRADE_VIEW_INITIATE",
  CREATE_TODO_TRADE_VIEW_SUCCESS: "CREATE_TODO_TRADE_VIEW_SUCCESS",
  CREATE_TODO_TRADE_VIEW_FAILURE: "CREATE_TODO_TRADE_VIEW_FAILURE",

  CREATE_TRADE_VIEW_SIGNATURES_INITIATE:
    "CREATE_TRADE_VIEW_SIGNATURES_INITIATE",
  CREATE_TRADE_VIEW_SIGNATURES_SUCCESS: "CREATE_TRADE_VIEW_SIGNATURES_SUCCESS",
  CREATE_TRADE_VIEW_SIGNATURES_FAILURE: "CREATE_TRADE_VIEW_SIGNATURES_FAILURE",

  RETURN_QUOTE_REQUEST_INITIATE: "RETURN_QUOTE_REQUEST_INITIATE",
  RETURN_QUOTE_REQUEST_SUCCESS: "RETURN_QUOTE_REQUEST_SUCCESS",
  RETURN_QUOTE_REQUEST_FAILURE: "RETURN_QUOTE_REQUEST_FAILURE",

  ACCEPT_PENIDNG_PURCHASE_INITIATE: "ACCEPT_PENIDNG_PURCHASE_INITIATE",
  ACCEPT_PENIDNG_PURCHASE_SUCCESS: "ACCEPT_PENIDNG_PURCHASE_SUCCESS",
  ACCEPT_PENIDNG_PURCHASE_FAILURE: "ACCEPT_PENIDNG_PURCHASE_FAILURE",

  //===============================user-mangment=====================

  GET_ROLES_FOR_TRADE_VIEW_INITIATE: "GET_ROLES_FOR_TRADE_VIEW_INITIATE",
  GET_ROLES_FOR_TRADE_VIEW_SUCCESS: "GET_ROLES_FOR_TRADE_VIEW_SUCCESS",
  GET_ROLES_FOR_TRADE_VIEW_FAILURE: "GET_ROLES_FOR_TRADE_VIEW_FAILURE",

  GET_ALL_PROJECTS_TRADE_VIEW_INITIATE: "GET_ALL_PROJECTS_TRADE_VIEW_INITIATE",
  GET_ALL_PROJECTS_TRADE_VIEW_SUCCESS: "GET_ALL_PROJECTS_TRADE_VIEW_SUCCESS",
  GET_ALL_PROJECTS_TRADE_VIEW_FAILURE: "GET_ALL_PROJECTS_TRADE_VIEW_FAILURE",

  TRADE_VIEW_ADD_VENDOR_USER_INITIATE: "TRADE_VIEW_ADD_VENDOR_USER_INITIATE",
  TRADE_VIEW_ADD_VENDOR_USER_SUCCESS: "TRADE_VIEW_ADD_VENDOR_USER_SUCCESS",
  TRADE_VIEW_ADD_VENDOR_USER_FAILURE: "TRADE_VIEW_ADD_VENDOR_USER_FAILURE",

  GET_VENDOR_USER_INITIATE: "GET_VENDOR_USER_INITIATE",
  GET_VENDOR_USER_SUCCESS: "GET_VENDOR_USER_SUCCESS",
  GET_VENDOR_USER_FAILURE: "GET_VENDOR_USER_FAILURE",

  TRADE_USER_RESET_PASSWORD_EMAIL_SEND_INITIATE:
    "TRADE_USER_RESET_PASSWORD_EMAIL_SEND_INITIATE",
  TRADE_USER_RESET_PASSWORD_EMAIL_SEND_SUCCESS:
    "TRADE_USER_RESET_PASSWORD_EMAIL_SEND_SUCCESS",
  TRADE_USER_RESET_PASSWORD_EMAIL_SEND_FAILURE:
    "TRADE_USER_RESET_PASSWORD_EMAIL_SEND_FAILURE",

  UPDATE_VENDOR_USER_INITIATE: "UPDATE_VENDOR_USER_INITIATE",
  UPDATE_VENDOR_USER_SUCCESS: "UPDATE_VENDOR_USER_SUCCESS",
  UPDATE_VENDOR_USER_FAILURE: "UPDATE_VENDOR_USER_FAILURE",

  GET_TRADE_NOTIFICATIONS_INITIATE: "GET_TRADE_NOTIFICATIONS_INITIATE",
  GET_TRADE_NOTIFICATIONS_SUCCESS: "GET_TRADE_NOTIFICATIONS_SUCCESS",
  GET_TRADE_NOTIFICATIONS_FAILURE: "GET_TRADE_NOTIFICATIONS_FAILURE",

  ADD_REPLY_TRADE_VIEW_INITIATE: "ADD_REPLY_TRADE_VIEW_INITIATE",
  ADD_REPLY_TRADE_VIEW_SUCCESS: "ADD_REPLY_TRADE_VIEW_SUCCESS",
  ADD_REPLY_TRADE_VIEW_FAILURE: "ADD_REPLY_TRADE_VIEW_FAILURE",

  ADD_DISCUSSION_TRADE_VIEW_INITIATE: "ADD_DISCUSSION_TRADE_VIEW_INITIATE",
  ADD_DISCUSSION_TRADE_VIEW_SUCCESS: "ADD_DISCUSSION_TRADE_VIEW_SUCCESS",
  ADD_DISCUSSION_TRADE_VIEW_FAILURE: "ADD_DISCUSSION_TRADE_VIEW_FAILURE",

  LIST_DISCUSSION_TRADE_VIEW_INITIATE: "LIST_DISCUSSION_TRADE_VIEW_INITIATE",
  LIST_DISCUSSION_TRADE_VIEW_SUCCESS: "LIST_DISCUSSION_TRADE_VIEW_SUCCESS",
  LIST_DISCUSSION_TRADE_VIEW_FAILURE: "LIST_DISCUSSION_TRADE_VIEW_FAILURE",

  GET_EDIT_QUOTE_REQUEST_BY_ID_INITIATE:
    "GET_EDIT_QUOTE_REQUEST_BY_ID_INITIATE",
  GET_EDIT_QUOTE_REQUEST_BY_ID_SUCCESS: "GET_EDIT_QUOTE_REQUEST_BY_ID_SUCCESS",
  GET_EDIT_QUOTE_REQUEST_BY_ID_FAILURE: "GET_EDIT_QUOTE_REQUEST_BY_ID_FAILURE",

  UPDATE_EDIT_QUOTE_REQUEST_BY_ID_INITIATE:
    "UPDATE_EDIT_QUOTE_REQUEST_BY_ID_INITIATE",
  UPDATE_EDIT_QUOTE_REQUEST_BY_ID_SUCCESS:
    "UPDATE_EDIT_QUOTE_REQUEST_BY_ID_SUCCESS",
  UPDATE_EDIT_QUOTE_REQUEST_BY_ID_FAILURE:
    "UPDATE_EDIT_QUOTE_REQUEST_BY_ID_FAILURE",

  GET_EDIT_PURCHSE_ORDER_BY_ID_INITIATE:
    "GET_EDIT_PURCHSE_ORDER_BY_ID_INITIATE",
  GET_EDIT_PURCHSE_ORDER_BY_ID_SUCCESS: "GET_EDIT_PURCHSE_ORDER_BY_ID_SUCCESS",
  GET_EDIT_PURCHSE_ORDER_BY_ID_FAILURE: "GET_EDIT_PURCHSE_ORDER_BY_ID_FAILURE",

  ACCEPT_REJECT_EDIT_PURCHSE_ORDER_INITIATE:"ACCEPT_REJECT_EDIT_PURCHSE_ORDER_INITIATE",
  ACCEPT_REJECT_EDIT_PURCHSE_ORDER_SUCCESS: "ACCEPT_REJECT_EDIT_PURCHSE_ORDER_SUCCESS",
  ACCEPT_REJECT_EDIT_PURCHSE_ORDER_FAILURE: "ACCEPT_REJECT_EDIT_PURCHSE_ORDER_FAILURE",
  
  REJECT_PURCHSE_ORDER_INITIATE:"REJECT_PURCHSE_ORDER_INITIATE",
  REJECT_PURCHSE_ORDER_SUCCESS: "REJECT_PURCHSE_ORDER_SUCCESS",
  REJECT_PURCHSE_ORDER_FAILURE: "REJECT_PURCHSE_ORDER_FAILURE",

  GET_UNIT_QR_INITIATE: "GET_UNIT_QR_INITIATE",
  GET_UNIT_QR_SUCCESS: "GET_UNIT_QR_SUCCESS",
  GET_UNIT_QR_FAILURE: "GET_UNIT_QR_FAILURE",

  UPLOAD_ADDITIONAL_DOCUMENTS_QR_INITIATE:
    "UPLOAD_ADDITIONAL_DOCUMENTS_QR_INITIATE",
  UPLOAD_ADDITIONAL_DOCUMENTS_QR_SUCCESS:
    "UPLOAD_ADDITIONAL_DOCUMENTS_QR_SUCCESS",
  UPLOAD_ADDITIONAL_DOCUMENTS_QR_FAILURE:
    "UPLOAD_ADDITIONAL_DOCUMENTS_QR_FAILURE",

  GET_FIELD_PURCHASE_ORDER_BY_ID_INITIATE:
    "GET_FIELD_PURCHASE_ORDER_BY_ID_INITIATE",
  GET_FIELD_PURCHASE_ORDER_BY_ID_SUCCESS:
    "GET_FIELD_PURCHASE_ORDER_BY_ID_SUCCESS",
  GET_FIELD_PURCHASE_ORDER_BY_ID_FAILURE:
    "GET_FIELD_PURCHASE_ORDER_BY_ID_FAILURE",

  GET_SUPPLIER_CREDIT_BY_ID_INITIATE: "GET_SUPPLIER_CREDIT_BY_ID_INITIATE",
  GET_SUPPLIER_CREDIT_BY_ID_SUCCESS: "GET_SUPPLIER_CREDIT_BY_ID_SUCCESS",
  GET_SUPPLIER_CREDIT_BY_ID_FAILURE: "GET_SUPPLIER_CREDIT_BY_ID_FAILURE",

  GET_DISCUSSION_LISTS_INITIATE: "GET_DISCUSSION_LISTS_INITIATE",
  GET_DISCUSSION_LISTS_SUCCESS: "GET_DISCUSSION_LISTS_SUCCESS",
  GET_DISCUSSION_LISTS_FAILURE: "GET_DISCUSSION_LISTS_FAILURE",

  ADD_DISCUSSION_REPLYS_INITIATE: "ADD_DISCUSSION_REPLYS_INITIATE",
  ADD_DISCUSSION_REPLYS_SUCCESS: "ADD_DISCUSSION_REPLYS_SUCCESS",
  ADD_DISCUSSION_REPLYS_FAILURE: "ADD_DISCUSSION_REPLYS_FAILURE",

  ADD_DISCUSSIONS_INITIATE: "ADD_DISCUSSIONS_INITIATE",
  ADD_DISCUSSIONS_SUCCESS: "ADD_DISCUSSIONS_SUCCESS",
  ADD_DISCUSSIONS_FAILURE: "ADD_DISCUSSIONS_FAILURE",

  ACCEPT_FPO_INITIATE:"ACCEPT_FPO_INITIATE",
  ACCEPT_FPO_SUCCESS: "ACCEPT_FPO_SUCCESS",
  ACCEPT_FPO_FAILURE: "ACCEPT_FPO_FAILURE",

  REJECT_FPO_INITIATE:"REJECT_FPO_INITIATE",
  REJECT_FPO_SUCCESS: "REJECT_FPO_SUCCESS",
  REJECT_FPO_FAILURE: "REJECT_FPO_FAILURE",

  ACCEPT_SC_INITIATE:"ACCEPT_SC_INITIATE",
  ACCEPT_SC_SUCCESS: "ACCEPT_SC_SUCCESS",
  ACCEPT_SC_FAILURE: "ACCEPT_SC_FAILURE",

  REJECT_SC_INITIATE:"REJECT_SC_INITIATE",
  REJECT_SC_SUCCESS: "REJECT_SC_SUCCESS",
  REJECT_SC_FAILURE: "REJECT_SC_FAILURE",

};

export const clientViewConfigAction = {
  GET_CLIENT_VIEW_WARRANTY_LIST_INITIATE:
    "GET_CLIENT_VIEW_WARRANTY_LIST_INITIATE",
  GET_CLIENT_VIEW_WARRANTY_LIST_SUCCESS:
    "GET_CLIENT_VIEW_WARRANTY_LIST_SUCCESS",
  GET_CLIENT_VIEW_WARRANTY_LIST_FAILURE:
    "GET_CLIENT_VIEW_WARRANTY_LIST_FAILURE",

  CLIENT_VIEW_WARRANTY_UPDATE_INITIATE: "CLIENT_VIEW_WARRANTY_UPDATE_INITIATE",
  CLIENT_VIEW_WARRANTY_UPDATE_SUCCESS: "CLIENT_VIEW_WARRANTY_UPDATE_SUCCESS",
  CLIENT_VIEW_WARRANTY_UPDATE_FAILURE: "CLIENT_VIEW_WARRANTY_UPDATE_FAILURE",

  GET_CHANGE_ORDER_DATA_INITIATE: "GET_CHANGE_ORDER_DATA_INITIATE",
  GET_CHANGE_ORDER_DATA_SUCCESS: "GET_CHANGE_ORDER_DATA_SUCCESS",
  GET_CHANGE_ORDER_DATA_FAILURE: "GET_CHANGE_ORDER_DATA_FAILURE",

  CHANGE_ORDER_DATA_UPDATE_INITIATE: "CHANGE_ORDER_DATA_UPDATE_INITIATE",
  CHANGE_ORDER_DATA_UPDATE_SUCCESS: "CHANGE_ORDER_DATA_UPDATE_SUCCESS",
  CHANGE_ORDER_DATA_UPDATE_FAILURE: "CHANGE_ORDER_DATA_UPDATE_FAILURE",

  GET_APPOINTMENT_DATA_INITIATE: "GET_APPOINTMENT_DATA_INITIATE",
  GET_APPOINTMENT_DATA_SUCCESS: "GET_APPOINTMENT_DATA_SUCCESS",
  GET_APPOINTMENT_DATA_FAILURE: "GET_APPOINTMENT_DATA_FAILURE",

  CHANGE_APPOINTMENT_DATA_UPDATE_INITIATE:
    "CHANGE_APPOINTMENT_DATA_UPDATE_INITIATE",
  CHANGE_APPOINTMENT_DATA_UPDATE_SUCCESS:
    "CHANGE_APPOINTMENT_DATA_UPDATE_SUCCESS",
  CHANGE_APPOINTMENT_DATA_UPDATE_FAILURE:
    "CHANGE_APPOINTMENT_DATA_UPDATE_FAILURE",

  GET_CONSTRUCTION_DATA_INITIATE: "GET_CONSTRUCTION_DATA_INITIATE",
  GET_CONSTRUCTION_DATA_SUCCESS: "GET_CONSTRUCTION_DATA_SUCCESS",
  GET_CONSTRUCTION_DATA_FAILURE: "GET_CONSTRUCTION_DATA_FAILURE",

  CHANGE_CONSTRUCTION_DATA_UPDATE_INITIATE:
    "CHANGE_CONSTRUCTION_DATA_UPDATE_INITIATE",
  CHANGE_CONSTRUCTION_DATA_UPDATE_SUCCESS:
    "CHANGE_CONSTRUCTION_DATA_UPDATE_SUCCESS",
  CHANGE_CONSTRUCTION_DATA_UPDATE_FAILURE:
    "CHANGE_CONSTRUCTION_DATA_UPDATE_FAILURE",

  GET_SALE_DATA_INITIATE: "GET_SALE_DATA_INITIATE",
  GET_SALE_DATA_SUCCESS: "GET_SALE_DATA_SUCCESS",
  GET_SALE_DATA_FAILURE: "GET_SALE_DATA_FAILURE",

  CHANGE_SALE_DATA_UPDATE_INITIATE: "CHANGE_SALE_DATA_UPDATE_INITIATE",
  CHANGE_SALE_DATA_UPDATE_SUCCESS: "CHANGE_SALE_DATA_UPDATE_SUCCESS",
  CHANGE_SALE_DATA_UPDATE_FAILURE: "CHANGE_SALE_DATA_UPDATE_FAILURE",

  GENERAL_INFORMATION_DATA_INITIATE: "GENERAL_INFORMATION_DATA_INITIATE",
  GENERAL_INFORMATION_DATA_SUCCESS: "GENERAL_INFORMATION_DATA_SUCCESS",
  GENERAL_INFORMATION_DATA_FAILURE: "GENERAL_INFORMATION_DATA_FAILURE",

  CHANGE_GENERAL_INFORMATION_DATA_UPDATE_INITIATE:
    "CHANGE_GENERAL_INFORMATION_DATA_UPDATE_INITIATE",
  CHANGE_GENERAL_INFORMATION_DATA_UPDATE_SUCCESS:
    "CHANGE_GENERAL_INFORMATION_DATA_UPDATE_SUCCESS",
  CHANGE_GENERAL_INFORMATION_DATA_UPDATE_FAILURE:
    "CHANGE_GENERAL_INFORMATION_DATA_UPDATE_FAILURE",

  DESGIN_SELECTIONS_DATA_INITIATE: "DESGIN_SELECTIONS_DATA_INITIATE",
  DESGIN_SELECTIONS_DATA_SUCCESS: "DESGIN_SELECTIONS_DATA_SUCCESS",
  DESGIN_SELECTIONS_DATA_FAILURE: "DESGIN_SELECTIONS_DATA_FAILURE",

  CHANGE_DESGIN_SELECTIONS_DATA_UPDATE_INITIATE:
    "CHANGE_DESGIN_SELECTIONS_DATA_UPDATE_INITIATE",
  CHANGE_DESGIN_SELECTIONS_DATA_UPDATE_SUCCESS:
    "CHANGE_DESGIN_SELECTIONS_DATA_UPDATE_SUCCESS",
  CHANGE_DESGIN_SELECTIONS_DATA_UPDATE_FAILURE:
    "CHANGE_DESGIN_SELECTIONS_DATA_UPDATE_FAILURE",

  CREATE_CLIENT_USER_INITIATE: "CREATE_CLIENT_USER_INITIATE",
  CREATE_CLIENT_USER_SUCCESS: "CREATE_CLIENT_USER_SUCCESS",
  CREATE_CLIENT_USER_FAILURE: "CREATE_CLIENT_USER_FAILURE",

  ALL_CLIENT_USER_INITIATE: "ALL_CLIENT_USER_INITIATE",
  ALL_CLIENT_USER_SUCCESS: "ALL_CLIENT_USER_SUCCESS",
  ALL_CLIENT_USER_FAILURE: "ALL_CLIENT_USER_FAILURE",

  REMOVE_CLIENT_USER_INITIATE: "REMOVE_CLIENT_USER_INITIATE",
  REMOVE_CLIENT_USER_SUCCESS: "REMOVE_CLIENT_USER_SUCCESS",
  REMOVE_CLIENT_USER_FAILURE: "REMOVE_CLIENT_USER_FAILURE",
};

export const investorViewAuthAction = {
  INVESTOR_VIEW_LOGIN_INITIATE: "INVESTOR_VIEW_LOGIN_INITIATE",
  INVESTOR_VIEW_LOGIN_SUCCESS: "INVESTOR_VIEW_LOGIN_SUCCESS",
  INVESTOR_VIEW_LOGIN_FAILURE: "INVESTOR_VIEW_LOGIN_FAILURE",

  INVESTOR_VIEW_SEND_MAIL_INITIATE: "INVESTOR_VIEW_SEND_MAIL_INITIATE",
  INVESTOR_VIEW_SEND_MAIL_SUCCESS: "INVESTOR_VIEW_SEND_MAIL_SUCCESS",
  INVESTOR_VIEW_SEND_MAIL_FAILURE: "INVESTOR_VIEW_SEND_MAIL_FAILURE",

  INVESTOR_CHANGE_PASSWORD_INITIATE: "INVESTOR_VIEW_CHANGE_PASSWORD_INITIATE",
  INVESTOR_CHANGE_PASSWORD_SUCCESS: "INVESTOR_VIEW_CHANGE_PASSWORD_SUCCESS",
  INVESTOR_CHANGE_PASSWORD_FAILURE: "INVESTOR_VIEW_CHANGE_PASSWORD_FAILURE",

  GET_VENDOR_BY_USER_ID_INITIATE: "GET_VENDOR_BY_USER_ID_INITIATE",
  GET_VENDOR_BY_USER_ID_SUCCESS: "GET_VENDOR_BY_USER_ID_SUCCESS",
  GET_VENDOR_BY_USER_ID_FAILURE: "GET_VENDOR_BY_USER_ID_FAILURE",

  UPLOAD_ICON_IMAGE_INITIATE: "UPLOAD_ICON_IMAGE_INITIATE",
  UPLOAD_ICON_IMAGE_SUCCESS: "UPLOAD_ICON_IMAGE_SUCCESS",
  UPLOAD_ICON_IMAGE_FAILURE: "UPLOAD_ICON_IMAGE_FAILURE",

  UPLOAD_MAIN_IMAGE_INITIATE: "UPLOAD_MAIN_IMAGE_INITIATE",
  UPLOAD_MAIN_IMAGE_SUCCESS: "UPLOAD_MAIN_IMAGE_SUCCESS",
  UPLOAD_MAIN_IMAGE_FAILURE: "UPLOAD_MAIN_IMAGE_FAILURE",

  CREATE_COMMENT_INITIATE: "CREATE_COMMENT_INITIATE",
  CREATE_COMMENT_SUCCESS: "CREATE_COMMENT_SUCCESS",
  CREATE_COMMENT_FAILURE: "CREATE_COMMENT_FAILURE",

  GET_COMMENT_INITIATE: "GET_COMMENT_INITIATE",
  GET_COMMENT_SUCCESS: "GET_COMMENT_SUCCESS",
  GET_COMMENT_FAILURE: "GET_COMMENT_FAILURE",

  GET_VENDOR_COMPANIES_INITIATE: "GET_VENDOR_COMPANIES_INITIATE",
  GET_VENDOR_COMPANIES_SUCCESS: "GET_VENDOR_COMPANIES_SUCCESS",
  GET_VENDOR_COMPANIES_FAILURE: "GET_VENDOR_COMPANIES_FAILURE",

  GET_ALL_PROJECT_INITIATE: "GET_ALL_PROJECT_INITIATE",
  GET_ALL_PROJECT_SUCCESS: "GET_ALL_PROJECT_SUCCESS",
  GET_ALL_PROJECT_FAILURE: "GET_ALL_PROJECT_FAILURE",

  PROJECT_JOBS_LIST_INITIATE: "PROJECT_JOBS_LIST_INITIATE",
  PROJECT_JOBS_LIST_SUCCESS: "PROJECT_JOBS_LIST_SUCCESS",
  PROJECT_JOBS_LIST_FAILURE: "PROJECT_JOBS_LIST_FAILURE",

  INVESTOR_VIEW_INITIATE: "INVESTOR_VIEW_INITIATE",
  INVESTOR_VIEW_SUCCESS: "INVESTOR_VIEW_SUCCESS",
  INVESTOR_VIEW_FAILURE: "INVESTOR_VIEW_FAILURE",

  CREATE_SUB_COMMENT_INITIATE: "CREATE_SUB_COMMENT_INITIATE",
  CREATE_SUB_COMMENT_SUCCESS: "CREATE_SUB_COMMENT_SUCCESS",
  CREATE_SUB_COMMENT_FAILURE: "CREATE_SUB_COMMENT_FAILURE",

  GET_SUB_COMMENT_INITIATE: "GET_SUB_COMMENT_INITIATE",
  GET_SUB_COMMENT_SUCCESS: "GET_SUB_COMMENT_SUCCESS",
  GET_SUB_COMMENT_FAILURE: "GET_SUB_COMMENT_FAILURE",

  INVESTOR_BUILDER_LIST_INITIATE: "INVESTOR_BUILDER_LIST_INITIATE",
  INVESTOR_BUILDER_LIST_SUCCESS: "INVESTOR_BUILDER_LIST_SUCCESS",
  INVESTOR_BUILDER_LIST_FAILURE: "INVESTOR_BUILDER_LIST_FAILURE",

  INVESTOR_BUILDER_BY_PROJECT_INITIATE: "INVESTOR_BUILDER_BY_PROJECT_INITIATE",
  INVESTOR_BUILDER_BY_PROJECT_SUCCESS: "INVESTOR_BUILDER_BY_PROJECT_SUCCESS",
  INVESTOR_BUILDER_BY_PROJECT_FAILURE: "INVESTOR_BUILDER_BY_PROJECT_FAILURE",

  INVESTOR_JOB_BY_PROJECT_INITIATE: "INVESTOR_JOB_BY_PROJECT_INITIATE",
  INVESTOR_JOB_BY_PROJECT_SUCCESS: "INVESTOR_JOB_BY_PROJECT_SUCCESS",
  INVESTOR_JOB_BY_PROJECT_FAILURE: "INVESTOR_JOB_BY_PROJECT_FAILURE",

  INVESTOR_FOLDER_N_FILE_LIST_INITIATE: "INVESTOR_FOLDER_N_FILE_LIST_INITIATE",
  INVESTOR_FOLDER_N_FILE_LIST_SUCCESS: "INVESTOR_FOLDER_N_FILE_LIST_SUCCESS",
  INVESTOR_FOLDER_N_FILE_LIST_FAILURE: "INVESTOR_FOLDER_N_FILE_LIST_FAILURE",

  GET_INVESTOR_NOTIFICATIONS_INITIATE: "GET_INVESTOR_NOTIFICATIONS_INITIATE",
  GET_INVESTOR_NOTIFICATIONS_SUCCESS: "GET_INVESTOR_NOTIFICATIONS_SUCCESS",
  GET_INVESTOR_NOTIFICATIONS_FAILURE: "GET_INVESTOR_NOTIFICATIONS_FAILURE",
};

export const designSelectionsAction = {
  INTERIOR_CATEGORY_INITIATE: "INTERIOR_CATEGORY_INITIATE",
  INTERIOR_CATEGORY_SUCCESS: "INTERIOR_CATEGORY_SUCCESS",
  INTERIOR_CATEGORY_FAILURE: "INTERIOR_CATEGORY_FAILURE",

  INTERIOR_CREATE_CATEGORY_INITIATE: "INTERIOR_CREATE_CATEGORY_INITIATE",
  INTERIOR_CREATE_CATEGORY_SUCCESS: "INTERIOR_CREATE_CATEGORY_SUCCESS",
  INTERIOR_CREATE_CATEGORY_FAILURE: "INTERIOR_CREATE_CATEGORY_FAILURE",

  INTERIOR_CREATE_CATEGORY_LIST_INITIATE:
    "INTERIOR_CREATE_CATEGORY_LIST_INITIATE",
  INTERIOR_CREATE_CATEGORY_LIST_SUCCESS:
    "INTERIOR_CREATE_CATEGORY_LIST_SUCCESS",
  INTERIOR_CREATE_CATEGORY_LIST_FAILURE:
    "INTERIOR_CREATE_CATEGORY_LIST_FAILURE",

  INTERIOR_CREATE_CATEGORY_REOMVE_INITIATE:
    "INTERIOR_CREATE_CATEGORY_REOMVE_INITIATE",
  INTERIOR_CREATE_CATEGORY_REOMVE_SUCCESS:
    "INTERIOR_CREATE_CATEGORY_REOMVE_SUCCESS",
  INTERIOR_CREATE_CATEGORY_REOMVE_FAILURE:
    "INTERIOR_CREATE_CATEGORY_REOMVE_FAILURE",

  GET_PENDING_APPOINTMENT_INITIATE: "GET_PENDING_APPOINTMENT_INITIATE",
  GET_PENDING_APPOINTMENT_SUCCESS: "GET_PENDING_APPOINTMENT_SUCCESS",
  GET_PENDING_APPOINTMENT_FAILURE: "GET_PENDING_APPOINTMENT_FAILURE",

  DELETE_PENDING_APPOINTMENT_INITIATE: "DELETE_PENDING_APPOINTMENT",
  DELETE_PENDING_APPOINTMENT_SUCCESS: "DELETE_PENDING_APPOINTMENT",
  DELETE_PENDING_APPOINTMENT_FAILURE: "DELETE_PENDING_APPOINTMENT",

  GET_APPOINTMENT_CATEGORY_INITIATE: "GET_APPOINTMENT_CATEGORY_INITIATE",
  GET_APPOINTMENT_CATEGORY_SUCCESS: "GET_APPOINTMENT_CATEGORY_SUCCESS",
  GET_APPOINTMENT_CATEGORY_FAILURE: "GET_APPOINTMENT_CATEGORY_FAILURE",

  GET_DESIGNER_VENDOR_INITIATE: "GET_DESIGNER_VENDOR_INITIATE",
  GET_DESIGNER_VENDOR_SUCCESS: "GET_DESIGNER_VENDOR_SUCCESS",
  GET_DESIGNER_VENDOR_FAILURE: "GET_DESIGNER_VENDOR_FAILURE",

  APPOINTMENT_CREATE_INITIATE: "APPOINTMENT_CREATE_INITIATE",
  APPOINTMENT_CREATE_SUCCESS: "APPOINTMENT_CREATE_SUCCESS",
  APPOINTMENT_CREATE_FAILURE: "APPOINTMENT_CREATE_FAILURE",

  GET_APPROVED_APPOINTMENT_INITIATE: "GET_APPROVED_APPOINTMENT_INITIATE",
  GET_APPROVED_APPOINTMENT_SUCCESS: "GET_APPROVED_APPOINTMENT_SUCCESS",
  GET_APPROVED_APPOINTMENT_FAILURE: "GET_APPROVED_APPOINTMENT_FAILURE",

  GET_PRICE_BOOK_CATEGORY_INITIATE: "GET_PRICE_BOOK_CATEGORY_INITIATE",
  GET_PRICE_BOOK_CATEGORY_SUCCESS: "GET_PRICE_BOOK_CATEGORY_SUCCESS",
  GET_PRICE_BOOK_CATEGORY_FAILURE: "GET_PRICE_BOOK_CATEGORY_FAILURE",

  GET_SELECT_ITEM_INITIATE: "GET_SELECT_ITEM_INITIATE",
  GET_SELECT_ITEM_SUCCESS: "GET_SELECT_ITEM_SUCCESS",
  GET_SELECT_ITEM_FAILURE: "GET_SELECT_ITEM_FAILURE",

  GET_VENDOR_LIST_INITIATE: "GET_VENDOR_LIST_INITIATE",
  GET_VENDOR_LIST_SUCCESS: "GET_VENDOR_LIST_SUCCESS",
  GET_VENDOR_LIST_FAILURE: "GET_VENDOR_LIST_FAILURE",

  CREATE_FOLDER_INITIATE: "CREATE_FOLDER_INITIATE",
  CREATE_FOLDER_SUCCESS: "CREATE_FOLDER_SUCCESS",
  CREATE_FOLDER_FAILURE: "CREATE_FOLDER_FAILURE",

  FOLDER_LIST_INITIATE: "FOLDER_LIST_INITIATE",
  FOLDER_LIST_SUCCESS: "FOLDER_LIST_SUCCESS",
  FOLDER_LIST_FAILURE: "FOLDER_LIST_FAILURE",

  DELETE_DESIGN_FOLDER_INITIATE: "DELETE_DESIGN_FOLDER_INITIATE",
  DELETE_DESIGN_FOLDER_SUCCESS: "DELETE_DESIGN_FOLDER_SUCCESS",
  DELETE_DESIGN_FOLDER_FAILURE: "DELETE_DESIGN_FOLDER_FAILURE",

  PENDING_FILE_EVENT_LIST_INITIATE: "PENDING_FILE_EVENT_LIST_INITIATE",
  PENDING_FILE_EVENT_LIST_SUCCESS: "PENDING_FILE_EVENT_LIST_SUCCESS",
  PENDING_FILE_EVENT_LIST_FAILURE: "PENDING_FILE_EVENT_LIST_FAILURE",

  UPLOAD_FILES_INITIATE: "UPLOAD_FILES_INITIATE",
  UPLOAD_FILES_SUCCESS: "UPLOAD_FILES_SUCCESS",
  UPLOAD_FILES_FAILURE: "UPLOAD_FILES_FAILURE",

  CREATE_DESIGN_DOCUMENTATION_INITIATE: "CREATE_DESIGN_DOCUMENTATION_INITIATE",
  CREATE_DESIGN_DOCUMENTATION_SUCCESS: "CREATE_DESIGN_DOCUMENTATION_SUCCESS",
  CREATE_DESIGN_DOCUMENTATION_FAILURE: "CREATE_DESIGN_DOCUMENTATION_FAILURE",

  GET_LIST_DESIGN_DOCUMENT_INITIATE: "GET_LIST_DESIGN_DOCUMENT_INITIATE",
  GET_LIST_DESIGN_DOCUMENT_SUCCESS: "GET_LIST_DESIGN_DOCUMENT_SUCCESS",
  GET_LIST_DESIGN_DOCUMENT_FAILURE: "GET_LIST_DESIGN_DOCUMENT_FAILURE",

  GET_DESIGN_DOCUMENT_HISTORY_INITIATE: "GET_DESIGN_DOCUMENT_HISTORY_INITIATE",
  GET_DESIGN_DOCUMENT_HISTORY_SUCCESS: "GET_DESIGN_DOCUMENT_HISTORY_SUCCESS",
  GET_DESIGN_DOCUMENT_HISTORY_FAILURE: "GET_DESIGN_DOCUMENT_HISTORY_FAILURE",

  SHARE_DESIGN_DOCUMENT_INITIATE: "SHARE_DESIGN_DOCUMENT_INITIATE",
  SHARE_DESIGN_DOCUMENT_SUCCESS: "SHARE_DESIGN_DOCUMENT_SUCCESS",
  SHARE_DESIGN_DOCUMENT_FAILURE: "SHARE_DESIGN_DOCUMENT_FAILURE",

  RESEND_DATE_SELECTION_INITIATE: "RESEND_DATE_SELECTION_INITIATE",
  RESEND_DATE_SELECTION_SUCCESS: "RESEND_DATE_SELECTION_SUCCESS",
  RESEND_DATE_SELECTION_FAILURE: "RESEND_DATE_SELECTION_FAILURE",

  UPDATE_REMINDER_INITIATE: "UPDATE_REMINDER_INITIATE",
  UPDATE_REMINDER_SUCCESS: "UPDATE_REMINDER_SUCCESS",
  UPDATE_REMINDER_FAILURE: "UPDATE_REMINDER_FAILURE",

  EXTERIOR_CREATE_CATEGORY_LIST_INITIATE:
    "EXTERIOR_CREATE_CATEGORY_LIST_INITIATE",
  EXTERIOR_CREATE_CATEGORY_LIST_SUCCESS:
    "EXTERIOR_CREATE_CATEGORY_LIST_SUCCESS",
  EXTERIOR_CREATE_CATEGORY_LIST_FAILURE:
    "EXTERIOR_CREATE_CATEGORY_LIST_FAILURE",

  UPDATE_CATEGORY_INITIATE: "UPDATE_CATEGORY_INITIATE",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",

  RELEASE_FILES_INITIATE: "RELEASE_FILES_INITIATE",
  RELEASE_FILES_SUCCESS: "RELEASE_FILES_SUCCESS",
  RELEASE_FILES_FAILURE: "RELEASE_FILES_FAILURE",

  RELEASE_FOLDER_FILES_INITIATE: "RELEASE_FOLDER_FILES_INITIATE",
  RELEASE_FOLDER_FILES_SUCCESS: "RELEASE_FOLDER_FILES_SUCCESS",
  RELEASE_FOLDER_FILES_FAILURE: "RELEASE_FOLDER_FILES_FAILURE",

  GET_PRIMARY_CLIENT_INITIATE: "GET_PRIMARY_CLIENT_INITIATE",
  GET_PRIMARY_CLIENT_SUCCESS: "GET_PRIMARY_CLIENT_SUCCESS",
  GET_PRIMARY_CLIENT_FAILURE: "GET_PRIMARY_CLIENT_FAILURE",

  GET_SECONDARY_CLIENT_INITIATE: "GET_SECONDARY_CLIENT_INITIATE",
  GET_SECONDARY_CLIENT_SUCCESS: "GET_SECONDARY_CLIENT_SUCCESS",
  GET_SECONDARY_CLIENT_FAILURE: "GET_SECONDARY_CLIENT_FAILURE",

  SEND_FOR_CLIENT_SIGNATURE_INITIATE: "SEND_FOR_CLIENT_SIGNATURE_INITIATE",
  SEND_FOR_CLIENT_SIGNATURE_SUCCESS: "SEND_FOR_CLIENT_SIGNATURE_SUCCESS",
  SEND_FOR_CLIENT_SIGNATURE_FAILURE: "SEND_FOR_CLIENT_SIGNATURE_FAILURE",

  CREATE_OPTION_INITIATE: "CREATE_OPTION_INITIATE",
  CREATE_OPTION_SUCCESS: "CREATE_OPTION_SUCCESS",
  CREATE_OPTION_FAILURE: "CREATE_OPTION_FAILURE",

  GET_COST_CODE_DROPDOWN_INITIATE: "GET_COST_CODE_DROPDOWN_INITIATE",
  GET_COST_CODE_DROPDOWN_SUCCESS: "GET_COST_CODE_DROPDOWN_SUCCESS",
  GET_COST_CODE_DROPDOWN_FAILURE: "GET_COST_CODE_DROPDOWN_FAILURE",

  GET_MEASURE_DROPDOWN_INITIATE: "GET_MEASURE_DROPDOWN_INITIATE",
  GET_MEASURE_DROPDOWN_SUCCESS: "GET_MEASURE_DROPDOWN_SUCCESS",
  GET_MEASURE_DROPDOWN_FAILURE: "GET_MEASURE_DROPDOWN_FAILURE",

  DELETE_OPTION_BY_ID_INITIATE: "DELETE_OPTION_BY_ID_INITIATE",
  DELETE_OPTION_BY_ID_SUCCESS: "DELETE_OPTION_BY_ID_SUCCESS",
  DELETE_OPTION_BY_ID_FAILURE: "DELETE_OPTION_BY_ID_FAILURE",

  GET_OPTION_BY_CATEGORYID_INITIATE: "GET_OPTION_BY_CATEGORYID_INITIATE",
  GET_OPTION_BY_CATEGORYID_SUCCESS: "GET_OPTION_BY_CATEGORYID_SUCCESS",
  GET_OPTION_BY_CATEGORYID_FAILURE: "GET_OPTION_BY_CATEGORYID_FAILURE",

  SELECT_DESIGN_OPTION_INITIATE: "SELECT_DESIGN_OPTION_INITIATE",
  SELECT_DESIGN_OPTION_SUCCESS: "SELECT_DESIGN_OPTION_SUCCESS",
  SELECT_DESIGN_OPTION_FAILURE: "SELECT_DESIGN_OPTION_FAILURE",

  UPDATE_OPTION_INITIATE: "UPDATE_OPTION_INITIATE",
  UPDATE_OPTION_SUCCESS: "UPDATE_OPTION_SUCCESS",
  UPDATE_OPTION_FAILURE: "UPDATE_OPTION_FAILURE",

  SEND_FOR_CLIENT_APPROVAL_INITIATE: "SEND_FOR_CLIENT_APPROVAL_INITIATE",
  SEND_FOR_CLIENT_APPROVAL_SUCCESS: "SEND_FOR_CLIENT_APPROVAL_SUCCESS",
  SEND_FOR_CLIENT_APPROVAL_FAILURE: "SEND_FOR_CLIENT_APPROVAL_FAILURE",

  DELETE_SELECTION_INITIATE: "DELETE_SELECTION_INITIATE",
  DELETE_SELECTION_SUCCESS: "DELETE_SELECTION_SUCCESS",
  DELETE_SELECTION_FAILURE: "DELETE_SELECTION_FAILURE",

  DESIGN_SELECTION_AGGREMENT_SEND_INITIATE:
    "DESIGN_SELECTION_AGGREMENT_SEND_INITIATE",
  DESIGN_SELECTION_AGGREMENT_SEND_SUCCESS:
    "DESIGN_SELECTION_AGGREMENT_SEND_SUCCESS",
  DESIGN_SELECTION_AGGREMENT_SEND_FAILURE:
    "DESIGN_SELECTION_AGGREMENT_SEND_FAILURE",

  UPLOAD_AGGREMENT_FILES_INITIATE: "UPLOAD_AGGREMENT_FILES_INITIATE",
  UPLOAD_AGGREMENT_FILES_SUCCESS: "UPLOAD_AGGREMENT_FILES_SUCCESS",
  UPLOAD_AGGREMENT_FILES_FAILURE: "UPLOAD_AGGREMENT_FILES_FAILURE",

  SELECT_DESIGN_OPTION_BY_ID_INITIATE: "SELECT_DESIGN_OPTION_BY_ID_INITIATE",
  SELECT_DESIGN_OPTION_BY_ID_SUCCESS: "SELECT_DESIGN_OPTION_BY_ID_SUCCESS",
  SELECT_DESIGN_OPTION_BY_ID_FAILURE: "SELECT_DESIGN_OPTION_BY_ID_FAILURE",

  UPLOAD_MULTIPLE_FILES_INITIATE: "UPLOAD_MULTIPLE_FILES_INITIATE",
  UPLOAD_MULTIPLE_FILES_SUCCESS: "UPLOAD_MULTIPLE_FILES_SUCCESS",
  UPLOAD_MULTIPLE_FILES_FAILURE: "UPLOAD_MULTIPLE_FILES_FAILURE",

  GET_SELECTION_HISTORY_INITIATE: "GET_SELECTION_HISTORY_INITIATE",
  GET_SELECTION_HISTORY_SUCCESS: "GET_SELECTION_HISTORY_SUCCESS",
  GET_SELECTION_HISTORY_FAILURE: "GET_SELECTION_HISTORY_FAILURE",

  GET_DESIGN_CATEGORY_BY_ID_INITIATE: "GET_DESIGN_CATEGORY_BY_ID_INITIATE",
  GET_DESIGN_CATEGORY_BY_ID_SUCCESS: "GET_DESIGN_CATEGORY_BY_ID_SUCCESS",
  GET_DESIGN_CATEGORY_BY_ID_FAILURE: "GET_DESIGN_CATEGORY_BY_ID_FAILURE",

  ADD_SIGNATURE_IN_AGGREMENT_INITIATE: "ADD_SIGNATURE_IN_AGGREMENT_INITIATE",
  ADD_SIGNATURE_IN_AGGREMENT_SUCCESS: "ADD_SIGNATURE_IN_AGGREMENT_SUCCESS",
  ADD_SIGNATURE_IN_AGGREMENT_FAILURE: "ADD_SIGNATURE_IN_AGGREMENT_FAILURE",

  CLIENT_DETAILS_INITIATE: "CLIENT_DETAILS_INITIATE",
  CLIENT_DETAILS_SUCCESS: "CLIENT_DETAILS_SUCCESS",
  CLIENT_DETAILS_FAILURE: "CLIENT_DETAILS_FAILURE",

  COMPANY_DETAILS_INITIATE: "COMPANY_DETAILS_INITIATE",
  COMPANY_DETAILS_SUCCESS: "COMPANY_DETAILS_SUCCESS",
  COMPANY_DETAILS_FAILURE: "COMPANY_DETAILS_FAILURE",

  GET_GENERATE_AGREEMENT_INITIATE: "GET_GENERATE_AGREEMENT_INITIATE",
  GET_GENERATE_AGREEMENT_SUCCESS: "GET_GENERATE_AGREEMENT_SUCCESS",
  GET_GENERATE_AGREEMENT_FAILURE: "GET_GENERATE_AGREEMENT_FAILURE",

  UPDATE_DESIGN_DOCS_INITIATE:"UPDATE_DESIGN_DOCS_INITIATE",
  UPDATE_DESIGN_DOCS_SUCCESS: "UPDATE_DESIGN_DOCS_SUCCESS",
  UPDATE_DESIGN_DOCS_FAILURE: "UPDATE_DESIGN_DOCS_FAILURE",
};

export const changeOrderAction = {
  GET_ALL_CATEGORY_LIST_INITIATE: "GET_ALL_CATEGORY_LIST_INITIATE",
  GET_ALL_CATEGORY_LIST_SUCCESS: "GET_ALL_CATEGORY_LIST_SUCCESS",
  GET_ALL_CATEGORY_LIST_FAILURE: "GET_ALL_CATEGORY_LIST_FAILURE",

  GET_ASSEMBLY_LIST_INITIATE: "GET_ASSEMBLY_LIST_INITIATE",
  GET_ASSEMBLY_LIST_SUCCESS: "GET_ASSEMBLY_LIST_SUCCESS",
  GET_ASSEMBLY_LIST_FAILURE: "GET_ASSEMBLY_LIST_FAILURE",

  GET_MEASURE_LIST_INITIATE: "GET_MEASURE_LIST_INITIATE",
  GET_MEASURE_LIST_SUCCESS: "GET_MEASURE_LIST_SUCCESS",
  GET_MEASURE_LIST_FAILURE: "GET_MEASURE_LIST_FAILURE",

  COST_CATEGORY_LIST_INITIATE: "COST_CATEGORY_LIST_INITIATE",
  COST_CATEGORY_LIST_SUCCESS: "COST_CATEGORY_LIST_SUCCESS",
  COST_CATEGORY_LIST_FAILURE: "COST_CATEGORY_LIST_FAILURE",

  COST_CODE_LIST_INITIATE: "COST_CODE_LIST_INITIATE",
  COST_CODE_LIST_SUCCESS: "COST_CODE_LIST_SUCCESS",
  COST_CODE_LIST_FAILURE: "COST_CODE_LIST_FAILURE",

  ADD_CUSTOM_ITEM_INITIATE: "ADD_CUSTOM_ITEM_INITIATE",
  ADD_CUSTOM_ITEM_SUCCESS: "ADD_CUSTOM_ITEM_SUCCESS",
  ADD_CUSTOM_ITEM_FAILURE: "ADD_CUSTOM_ITEM_FAILURE",

  ADD_SUPPORTING_DOCS_FILES_INITIATE: "ADD_SUPPORTING_DOCS_FILES_INITIATE",
  ADD_SUPPORTING_DOCS_FILES_SUCCESS: "ADD_SUPPORTING_DOCS_FILES_SUCCESS",
  ADD_SUPPORTING_DOCS_FILES_FAILURE: "ADD_SUPPORTING_DOCS_FILES_FAILURE",

  CHANGE_ORDER_LIST_INITIATE: "CHANGE_ORDER_LIST_INITIATE",
  CHANGE_ORDER_LIST_SUCCESS: "CHANGE_ORDER_LIST_SUCCESS",
  CHANGE_ORDER_LIST_FAILURE: "CHANGE_ORDER_LIST_FAILURE",

  UPDATE_SUPPORTING_DOCS_FILES_INITIATE:
    "UPDATE_SUPPORTING_DOCS_FILES_INITIATE",
  UPDATE_SUPPORTING_DOCS_FILES_SUCCESS: "UPDATE_SUPPORTING_DOCS_FILES_SUCCESS",
  UPDATE_SUPPORTING_DOCS_FILES_FAILURE: "UPDATE_SUPPORTING_DOCS_FILES_FAILURE",

  DELETE_SUPPORTING_DOCS_FILES_INITIATE:
    "DELETE_SUPPORTING_DOCS_FILES_INITIATE",
  DELETE_SUPPORTING_DOCS_FILES_SUCCESS: "DELETE_SUPPORTING_DOCS_FILES_SUCCESS",
  DELETE_SUPPORTING_DOCS_FILES_FAILURE: "DELETE_SUPPORTING_DOCS_FILES_FAILURE",

  CLIENT_CHANGE_REQUEST_LIST_INITIATE: "CLIENT_CHANGE_REQUEST_LIST_INITIATE",
  CLIENT_CHANGE_REQUEST_LIST_SUCCESS: "CLIENT_CHANGE_REQUEST_LIST_SUCCESS",
  CLIENT_CHANGE_REQUEST_LIST_FAILURE: "CLIENT_CHANGE_REQUEST_LIST_FAILURE",

  CANCEL_CLIENT_CHANGE_REQUEST_INITIATE:
    "CANCEL_CLIENT_CHANGE_REQUEST_INITIATE",
  CANCEL_CLIENT_CHANGE_REQUEST_SUCCESS: "CANCEL_CLIENT_CHANGE_REQUEST_SUCCESS",
  CANCEL_CLIENT_CHANGE_REQUEST_FAILURE: "CANCEL_CLIENT_CHANGE_REQUEST_FAILURE",

  CREATE_CHANGE_ORDER_INITIATE: "CREATE_CHANGE_ORDER_INITIATE",
  CREATE_CHANGE_ORDER_SUCCESS: "CREATE_CHANGE_ORDER_SUCCESS",
  CREATE_CHANGE_ORDER_FAILURE: "CREATE_CHANGE_ORDER_FAILURE",

  ADD_CHANGE_ORDER_ITEM_INITIATE: "ADD_CHANGE_ORDER_ITEM_INITIATE",
  ADD_CHANGE_ORDER_ITEM_SUCCESS: "ADD_CHANGE_ORDER_ITEM_SUCCESS",
  ADD_CHANGE_ORDER_ITEM_FAILURE: "ADD_CHANGE_ORDER_ITEM_FAILURE",

  GET_ITEM_BY_CHANGE_ORDER_ID_INITIATE: "GET_ITEM_BY_CHANGE_ORDER_ID_INITIATE",
  GET_ITEM_BY_CHANGE_ORDER_ID_SUCCESS: "GET_ITEM_BY_CHANGE_ORDER_ID_SUCCESS",
  GET_ITEM_BY_CHANGE_ORDER_ID_FAILURE: "GET_ITEM_BY_CHANGE_ORDER_ID_FAILURE",

  DELETE_ITEM_INITIATE: "DELETE_ITEM_INITIATE",
  DELETE_ITEM_SUCCESS: "DELETE_ITEM_SUCCESS",
  DELETE_ITEM_FAILURE: "DELETE_ITEM_FAILURE",

  UPDATE_CHANGE_ORDER_ITEM_INITIATE: "UPDATE_CHANGE_ORDER_ITEM_INITIATE",
  UPDATE_CHANGE_ORDER_ITEM_SUCCESS: "UPDATE_CHANGE_ORDER_ITEM_SUCCESS",
  UPDATE_CHANGE_ORDER_ITEM_FAILURE: "UPDATE_CHANGE_ORDER_ITEM_FAILURE",

  DELETE_CHANGE_ORDER_INITIATE: "DELETE_CHANGE_ORDER_INITIATE",
  DELETE_CHANGE_ORDER_SUCCESS: "DELETE_CHANGE_ORDER_SUCCESS",
  DELETE_CHANGE_ORDER_FAILURE: "DELETE_CHANGE_ORDER_FAILURE",

  GENERATE_AGREEMENT_INITIATE: "GENERATE_AGREEMENT_INITIATE",
  GENERATE_AGREEMENT_SUCCESS: "GENERATE_AGREEMENT_SUCCESS",
  GENERATE_AGREEMENT_FAILURE: "GENERATE_AGREEMENT_FAILURE",

  SEND_EMAIL_AGREEMENT_INITIATE: "SEND_EMAIL_AGREEMENT_INITIATE",
  SEND_EMAIL_AGREEMENT_SUCCESS: "SEND_EMAIL_AGREEMENT_SUCCESS",
  SEND_EMAIL_AGREEMENT_FAILURE: "SEND_EMAIL_AGREEMENT_FAILURE",

  ADD_SIGNATURE_IN_AGREEMENT_INITIATE: "ADD_SIGNATURE_IN_AGREEMENT_INITIATE",
  ADD_SIGNATURE_IN_AGREEMENT_SUCCESS: "ADD_SIGNATURE_IN_AGREEMENT_SUCCESS",
  ADD_SIGNATURE_IN_AGREEMENT_FAILURE: "ADD_SIGNATURE_IN_AGREEMENT_FAILURE",

  HISTORY_CHANGE_ORDER_AGREEMENT_INITIATE:
    "HISTORY_CHANGE_ORDER_AGREEMENT_INITIATE",
  HISTORY_CHANGE_ORDER_AGREEMENT_SUCCESS:
    "HISTORY_CHANGE_ORDER_AGREEMENT_SUCCESS",
  HISTORY_CHANGE_ORDER_AGREEMENT_FAILURE:
    "HISTORY_CHANGE_ORDER_AGREEMENT_FAILURE",

  CHANGE_ORDER_DETAILS_INITIATE: "CHANGE_ORDER_DETAILS_INITIATE",
  CHANGE_ORDER_DETAILS_SUCCESS: "CHANGE_ORDER_DETAILS_SUCCESS",
  CHANGE_ORDER_DETAILS_FAILURE: "CHANGE_ORDER_DETAILS_FAILURE",
};

export const homeActionTypes = {
  RESIDENTIAL_HOME_LIST_INITIATE: "RESIDENTIAL_HOME_LIST_INITIATE",
  RESIDENTIAL_HOME_LIST_SUCCESS: "RESIDENTIAL_HOME_LIST_SUCCESS",
  RESIDENTIAL_HOME_LIST_FAILURE: "RESIDENTIAL_HOME_LIST_FAILURE",

  MULTIFAMILY_HOME_LIST_INITIATE: "MULTIFAMILY_HOME_LIST_INITIATE",
  MULTIFAMILY_HOME_LIST_SUCCESS: "MULTIFAMILY_HOME_LIST_SUCCESS",
  MULTIFAMILY_HOME_LIST_FAILURE: "MULTIFAMILY_HOME_LIST_FAILURE",

  LAND_DEVELOPMENT_HOME_LIST_INITIATE: "LAND_DEVELOPMENT_HOME_LIST_INITIATE",
  LAND_DEVELOPMENT_HOME_LIST_SUCCESS: "LAND_DEVELOPMENT_HOME_LIST_SUCCESS",
  LAND_DEVELOPMENT_HOME_LIST_FAILURE: "LAND_DEVELOPMENT_HOME_LIST_FAILURE",

  TODO_HOME_LIST_INITIATE: "TODO_HOME_LIST_INITIATE",
  TODO_HOME_LIST_SUCCESS: "TODO_HOME_LIST_SUCCESS",
  TODO_HOME_LIST_FAILURE: "TODO_HOME_LIST_FAILURE",

  TASK_HOME_LIST_INITIATE: "TASK_HOME_LIST_INITIATE",
  TASK_HOME_LIST_SUCCESS: "TASK_HOME_LIST_SUCCESS",
  TASK_HOME_LIST_FAILURE: "TASK_HOME_LIST_FAILURE",

  ITENARY_HOME_LIST_INITIATE: "ITENARY_HOME_LIST_INITIATE",
  ITENARY_HOME_LIST_SUCCESS: "ITENARY_HOME_LIST_SUCCESS",
  ITENARY_HOME_LIST_FAILURE: "ITENARY_HOME_LIST_FAILURE",

  GET_MULTI_BLOCKS_LIST_INITIATE: "GET_MULTI_BLOCKS_LIST_INITIATE",
  GET_MULTI_BLOCKS_LIST_SUCCESS: "GET_MULTI_BLOCKS_LIST_SUCCESS",
  GET_MULTI_BLOCKS_LIST_FAILURE: "GET_MULTI_BLOCKS_LIST_FAILURE",

  MULTI_FAMILY_PROJECT_LIST_INITIATE: "MULTI_FAMILY_PROJECT_LIST_INITIATE",
  MULTI_FAMILY_PROJECT_LIST_SUCCESS: "MULTI_FAMILY_PROJECT_LIST_SUCCESS",
  MULTI_FAMILY_PROJECT_LIST_FAILURE: "MULTI_FAMILY_PROJECT_LIST_FAILURE",

  LAND_DEVELOPMENT_PROJECT_LIST_INITIATE:
    "LAND_DEVELOPMENT_PROJECT_LIST_INITIATE",
  LAND_DEVELOPMENT_PROJECT_LIST_SUCCESS:
    "LAND_DEVELOPMENT_PROJECT_LIST_SUCCESS",
  LAND_DEVELOPMENT_PROJECT_LIST_FAILURE:
    "LAND_DEVELOPMENT_PROJECT_LIST_FAILURE",

  GET_LAND_PHASE_LIST_INITIATE: "GET_LAND_PHASE_LIST_INITIATE",
  GET_LAND_PHASE_LIST_SUCCESS: "GET_LAND_PHASE_LIST_SUCCESS",
  GET_LAND_PHASE_LIST_FAILURE: "GET_LAND_PHASE_LIST_FAILURE",
};

export const widCastTypes = {
  VIEW_WIDCAST_INITIATE: "VIEW_WIDCAST_INITIATE",
  VIEW_WIDCAST_SUCCESS: "VIEW_WIDCAST_SUCCESS",
  VIEW_WIDCAST_FAILURE: "VIEW_WIDCAST_FAILURE",

  UPDATE_WIDCAST_INITIATE: "UPDATE_WIDCAST_INITIATE",
  UPDATE_WIDCAST_SUCCESS: "UPDATE_WIDCAST_SUCCESS",
  UPDATE_WIDCAST_FAILURE: "UPDATE_WIDCAST_FAILURE",

  WIDCAST_LIST_INITIATE: "WIDCAST_LIST_INITIATE",
  WIDCAST_LIST_SUCCESS: "WIDCAST_LIST_SUCCESS",
  WIDCAST_LIST_FAILURE: "WIDCAST_LIST_FAILURE",

  ADD_WIDCAST_NOTIFICATION_INITIATE: "ADD_WIDCAST_NOTIFICATION_INITIATE",
  ADD_WIDCAST_NOTIFICATION_SUCCESS: "ADD_WIDCAST_NOTIFICATION_SUCCESS",
  ADD_WIDCAST_NOTIFICATION_FAILURE: "ADD_WIDCAST_NOTIFICATION_FAILURE",

  TURN_ON_WIDCAST_INITIATE: "TURN_ON_WIDCAST_INITIATE",
  TURN_ON_WIDCAST_SUCCESS: "TURN_ON_WIDCAST_SUCCESS",
  TURN_ON_WIDCAST_FAILURE: "TURN_ON_WIDCAST_FAILURE",

  TURN_OFF_WIDCAST_INITIATE: "TURN_OFF_WIDCAST_INITIATE",
  TURN_OFF_WIDCAST_SUCCESS: "TURN_OFF_WIDCAST_SUCCESS",
  TURN_OFF_WIDCAST_FAILURE: "TURN_OFF_WIDCAST_FAILURE",

  REMOVE_OFF_WIDCAST_INITIATE: "REMOVE_OFF_WIDCAST_INITIATE",
  REMOVE_OFF_WIDCAST_SUCCESS: "REMOVE_OFF_WIDCAST_SUCCESS",
  REMOVE_OFF_WIDCAST_FAILURE: "REMOVE_OFF_WIDCAST_FAILURE",
};

export const projectProformaAction = {
  PROFORMA_LIST_INITIATE: "PROFORMA_LIST_INITIATE",
  PROFORMA_LIST_SUCCESS: "PROFORMA_LIST_SUCCESS",
  PROFORMA_LIST_FAILURE: "PROFORMA_LIST_FAILURE",

  ACTIVE_INACTIVE_PROFORMA_INITIATE: "ACTIVE_INACTIVE_PROFORMA_INITIATE",
  ACTIVE_INACTIVE_PROFORMA_SUCCESS: "ACTIVE_INACTIVE_PROFORMA_SUCCESS",
  ACTIVE_INACTIVE_PROFORMA_FAILURE: "ACTIVE_INACTIVE_PROFORMA_FAILURE",

  CREATED_PROFORMA_PROJECT_AND_JOBS_INITIATE:
    "CREATED_PROFORMA_PROJECT_AND_JOBS_INITIATE",
  CREATED_PROFORMA_PROJECT_AND_JOBS_SUCCESS:
    "CREATED_PROFORMA_PROJECT_AND_JOBS_SUCCESS",
  CREATED_PROFORMA_PROJECT_AND_JOBS_FAILURE:
    "CREATED_PROFORMA_PROJECT_AND_JOBS_FAILURE",

  GET_PROFORMA_UNIT_PRICE_INITIATE: "GET_PROFORMA_UNIT_PRICE_INITIATE",
  GET_PROFORMA_UNIT_PRICE_SUCCESS: "GET_PROFORMA_UNIT_PRICE_SUCCESS",
  GET_PROFORMA_UNIT_PRICE_FAILURE: "GET_PROFORMA_UNIT_PRICE_FAILURE",

  GET_PROFORMA_HISTORY_INITIATE: "GET_PROFORMA_HISTORY_INITIATE",
  GET_PROFORMA_HISTORY_SUCCESS: "GET_PROFORMA_HISTORY_SUCCESS",
  GET_PROFORMA_HISTORY_FAILURE: "GET_PROFORMA_HISTORY_FAILURE",

  //=========================performa-financial--report=========================

  GET_PROFORMA_FINANCIAL_REPORT_INITIATE:
    "GET_PROFORMA_FINANCIAL_REPORT_INITIATE",
  GET_PROFORMA_FINANCIAL_REPORT_SUCCESS:
    "GET_PROFORMA_FINANCIAL_REPORT_SUCCESS",
  GET_PROFORMA_FINANCIAL_REPORT_FAILURE:
    "GET_PROFORMA_FINANCIAL_REPORT_FAILURE",
  //========================================performa-report=====================================

  PERFORMA_REPORT_INITIATE: "PERFORMA_REPORT_INITIATE",
  PERFORMA_REPORT_SUCCESS: "PERFORMA_REPORT_SUCCESS",
  PERFORMA_REPORT_FAILURE: "PERFORMA_REPORT_FAILURE",

  PERFORMA_REPORT_MARK_UP_INITIATE: "PERFORMA_REPORT_MARK_UP_INITIATE",
  PERFORMA_REPORT_MARK_UP_SUCCESS: "PERFORMA_REPORT_MARK_UP_SUCCESS",
  PERFORMA_REPORT_MARK_UP_FAILURE: "PERFORMA_REPORT_MARK_UP_FAILURE",
  //==========================

  GET_AFFORDABLE_RENTAL_REVENUE_INITIATE:
    "GET_AFFORDABLE_RENTAL_REVENUE_INITIATE",
  GET_AFFORDABLE_RENTAL_REVENUE_SUCCESS:
    "GET_AFFORDABLE_RENTAL_REVENUE_SUCCESS",
  GET_AFFORDABLE_RENTAL_REVENUE_FAILURE:
    "GET_AFFORDABLE_RENTAL_REVENUE_FAILURE",

  PROJECT_ANNUAL_AFFORDABLE_RENTAL_REVENUE_INITIATE:
    "PROJECT_ANNUAL_AFFORDABLE_RENTAL_REVENUE_INITIATE",
  PROJECT_ANNUAL_AFFORDABLE_RENTAL_REVENUE_SUCCESS:
    "PROJECT_ANNUAL_AFFORDABLE_RENTAL_REVENUE_SUCCESS",
  PROJECT_ANNUAL_AFFORDABLE_RENTAL_REVENUE_FAILURE:
    "PROJECT_ANNUAL_AFFORDABLE_RENTAL_REVENUE_FAILURE",

  GET_PROJECT_SALES_REVENUE_INITIATE: "GET_PROJECT_SALES_REVENUE_INITIATE",
  GET_PROJECT_SALES_REVENUE_SUCCESS: "GET_PROJECT_SALES_REVENUE_SUCCESS",
  GET_PROJECT_SALES_REVENUE_FAILURE: "GET_PROJECT_SALES_REVENUE_FAILURE",

  PROJECT_SALES_REVENUE_INITIATE: "PROJECT_SALES_REVENUE_INITIATE",
  PROJECT_SALES_REVENUE_SUCCESS: "PROJECT_SALES_REVENUE_SUCCESS",
  PROJECT_SALES_REVENUE_FAILURE: "PROJECT_SALES_REVENUE_FAILURE",
  //=======================

  GET_UNIT_COST_ANALYSIS_INITIATE: "GET_UNIT_COST_ANALYSIS_INITIATE",
  GET_UNIT_COST_ANALYSIS_SUCCESS: "GET_UNIT_COST_ANALYSIS_SUCCESS",
  GET_UNIT_COST_ANALYSIS_FAILURE: "GET_UNIT_COST_ANALYSIS_FAILURE",

  GET_GROSS_MARGIN_PER_UNIT_INITIATE: "GET_GROSS_MARGIN_PER_UNIT_INITIATE",
  GET_GROSS_MARGIN_PER_UNIT_SUCCESS: "GET_GROSS_MARGIN_PER_UNIT_SUCCESS",
  GET_GROSS_MARGIN_PER_UNIT_FAILURE: "GET_GROSS_MARGIN_PER_UNIT_FAILURE",

  CREATE_UNIT_TYPES_INITIATE: "CREATE_UNIT_TYPES_INITIATE",
  CREATE_UNIT_TYPES_SUCCESS: "CREATE_UNIT_TYPES_SUCCESS",
  CREATE_UNIT_TYPES_FAILURE: "CREATE_UNIT_TYPES_FAILURE",

  GET_UNIT_RENTAL_TABLE_INITIATE: "GET_UNIT_RENTAL_TABLE_INITIATE",
  GET_UNIT_RENTAL_TABLE_SUCCESS: "GET_UNIT_RENTAL_TABLE_SUCCESS",
  GET_UNIT_RENTAL_TABLE_FAILURE: "GET_UNIT_RENTAL_TABLE_FAILURE",

  UPDATE_UNIT_RENTAL_TABLE_INITIATE: "UPDATE_UNIT_RENTAL_TABLE_INITIATE",
  UPDATE_UNIT_RENTAL_TABLE_SUCCESS: "UPDATE_UNIT_RENTAL_TABLE_SUCCESS",
  UPDATE_UNIT_RENTAL_TABLE_FAILURE: "UPDATE_UNIT_RENTAL_TABLE_FAILURE",

  GET_UNIT_MONTHLY_RENTAL_TABLE_INITIATE:
    "GET_UNIT_MONTHLY_RENTAL_TABLE_INITIATE",
  GET_UNIT_MONTHLY_RENTAL_TABLE_SUCCESS:
    "GET_UNIT_MONTHLY_RENTAL_TABLE_SUCCESS",
  GET_UNIT_MONTHLY_RENTAL_TABLE_FAILURE:
    "GET_UNIT_MONTHLY_RENTAL_TABLE_FAILURE",

  UPDATE_UNIT_MONTHLY_RENTAL_TABLE_INITIATE:
    "UPDATE_UNIT_MONTHLY_RENTAL_TABLE_INITIATE",
  UPDATE_UNIT_MONTHLY_RENTAL_TABLE_SUCCESS:
    "UPDATE_UNIT_MONTHLY_RENTAL_TABLE_SUCCESS",
  UPDATE_UNIT_MONTHLY_RENTAL_TABLE_FAILURE:
    "UPDATE_UNIT_MONTHLY_RENTAL_TABLE_FAILURE",

  GET_UNIT_AFFORDABLE_REVENUE_TABLE_INITIATE:
    "GET_UNIT_AFFORDABLE_REVENUE_TABLE_INITIATE",
  GET_UNIT_AFFORDABLE_REVENUE_TABLE_SUCCESS:
    "GET_UNIT_AFFORDABLE_REVENUE_TABLE_SUCCESS",
  GET_UNIT_AFFORDABLE_REVENUE_TABLE_FAILURE:
    "GET_UNIT_AFFORDABLE_REVENUE_TABLE_FAILURE",

  UPDATE_UNIT_AFFORDABLE_REVENUE_TABLE_INITIATE:
    "UPDATE_UNIT_AFFORDABLE_REVENUE_TABLE_INITIATE",
  UPDATE_UNIT_AFFORDABLE_REVENUE_TABLE_SUCCESS:
    "UPDATE_UNIT_AFFORDABLE_REVENUE_TABLE_SUCCESS",
  UPDATE_UNIT_AFFORDABLE_REVENUE_TABLE_FAILURE:
    "UPDATE_UNIT_AFFORDABLE_REVENUE_TABLE_FAILURE",

  GET_REGULAR_RENTAL_REVENUE_INITIATE: "GET_REGULAR_RENTAL_REVENUE_INITIATE",
  GET_REGULAR_RENTAL_REVENUE_SUCCESS: "GET_REGULAR_RENTAL_REVENUE_SUCCESS",
  GET_REGULAR_RENTAL_REVENUE_FAILURE: "GET_REGULAR_RENTAL_REVENUE_FAILURE",

  GET_PROJECT_ANNUAL_RENTAL_REVENUE_INITIATE:
    "GET_PROJECT_ANNUAL_RENTAL_REVENUE_INITIATE",
  GET_PROJECT_ANNUAL_RENTAL_REVENUE_SUCCESS:
    "GET_PROJECT_ANNUAL_RENTAL_REVENUE_SUCCESS",
  GET_PROJECT_ANNUAL_RENTAL_REVENUE_FAILURE:
    "GET_PROJECT_ANNUAL_RENTAL_REVENUE_FAILURE",

  DELETE_PERFORMA_INITIATE: "DELETE_PERFORMA_INITIATE",
  DELETE_PERFORMA_SUCCESS: "DELETE_PERFORMA_SUCCESS",
  DELETE_PERFORMA_FAILURE: "DELETE_PERFORMA_FAILURE",
};

export const archiveAction = {
  ARCHIVE_LIST_INITIATE: "ARCHIVE_LIST_INITIATE",
  ARCHIVE_LIST_SUCCESS: "ARCHIVE_LIST_SUCCESS",
  ARCHIVE_LIST_FAILURE: "ARCHIVE_LIST_FAILURE",

  ARCHIVE_EVENT_LIST_INITIATE: "ARCHIVE_EVENT_LIST_INITIATE",
  ARCHIVE_EVENT_LIST_SUCCESS: "ARCHIVE_EVENT_LIST_SUCCESS",
  ARCHIVE_EVENT_LIST_FAILURE: "ARCHIVE_EVENT_LIST_FAILURE",

  ARCHIVE_RESTORE_JOB_INITIATE: "ARCHIVE_RESTORE_JOB_INITIATE",
  ARCHIVE_RESTORE_JOB_SUCCESS: "ARCHIVE_RESTORE_JOB_SUCCESS",
  ARCHIVE_RESTORE_JOB_FAILURE: "ARCHIVE_RESTORE_JOB_FAILURE",

  ARCHIVE_DELETE_JOB_INITIATE: "ARCHIVE_DELETE_JOB_INITIATE",
  ARCHIVE_DELETE_JOB_SUCCESS: "ARCHIVE_DELETE_JOB_SUCCESS",
  ARCHIVE_DELETE_JOB_FAILURE: "ARCHIVE_DELETE_JOB_FAILURE",
};

export const modelsAction = {
  JOB_LIST_PROJECT_BY_TYPE_INITIATE: "JOB_LIST_PROJECT_BY_TYPE_INITIATE",
  JOB_LIST_PROJECT_BY_TYPE_SUCCESS: "JOB_LIST_PROJECT_BY_TYPE_SUCCESS",
  JOB_LIST_PROJECT_BY_TYPE_FAILURE: "JOB_LIST_PROJECT_BY_TYPE_FAILURE",

  ALL_PROJECTS_LIST_INITIATE: "ALL_PROJECTS_LIST_INITIATE",
  ALL_PROJECTS_LIST_SUCCESS: "ALL_PROJECTS_LIST_SUCCESS",
  ALL_PROJECTS_LIST_FAILURE: "ALL_PROJECTS_LIST_FAILURE",

  CREATE_MODELS_INITIATE: "CREATE_MODELS_INITIATE",
  CREATE_MODELS_SUCCESS: "CREATE_MODELS_SUCCESS",
  CREATE_MODELS_FAILURE: "CREATE_MODELS_FAILURE",

  MODELS_LIST_INITIATE: "MODELS_LIST_INITIATE",
  MODELS_LIST_SUCCESS: "MODELS_LIST_SUCCESS",
  MODELS_LIST_FAILURE: "MODELS_LIST_FAILURE",

  UPDATE_MODELS_INITIATE: "UPDATE_MODELS_INITIATE",
  UPDATE_MODELS_SUCCESS: "UPDATE_MODELS_SUCCESS",
  UPDATE_MODELS_FAILURE: "UPDATE_MODELS_FAILURE",

  MODELS_DELETE_INITIATE: "MODELS_DELETE_INITIATE",
  MODELS_DELETE_SUCCESS: "MODELS_DELETE_SUCCESS",
  MODELS_DELETE_FAILURE: "MODELS_DELETE_FAILURE",

  LIST_PROJECT_JOBS_INITIATE: "LIST_PROJECT_JOBS_INITIATE",
  LIST_PROJECT_JOBS_SUCCESS: "LIST_PROJECT_JOBS_SUCCESS",
  LIST_PROJECT_JOBS_FAILURE: "LIST_PROJECT_JOBS_FAILURE",

  DELETE_ARCHIVE_JOBS_INITIATE: "DELETE_ARCHIVE_JOBS_INITIATE",
  DELETE_ARCHIVE_JOBS_SUCCESS: "DELETE_ARCHIVE_JOBS_SUCCESS",
  DELETE_ARCHIVE_JOBS_FAILURE: "DELETE_ARCHIVE_JOBS_FAILURE",
};

export const proformaActionTypes = {
  GET_UNITS_INITIATE: "GET_UNITS_INITIATE",
  GET_UNITS_SUCCESS: "GET_UNITS_SUCCESS",
  GET_UNITS_FAILURE: "GET_UNITS_INITIATE",

  ADD_PROFORMA_INITIATE: "ADD_PROFORMA_INITIATE",
  ADD_PROFORMA_SUCCESS: "ADD_PROFORMA_SUCCESS",
  ADD_PROFORMA_FAILURE: "ADD_PROFORMA_INITIATE",

  PROFORMA_TYPES_INITIATE: "PROFORMA_TYPES_INITIATE",
  PROFORMA_TYPES_SUCCESS: "PROFORMA_TYPES_SUCCESS",
  PROFORMA_TYPES_FAILURE: "PROFORMA_TYPES_INITIATE",

  FOUNDATION_LIST_INITIATE: "FOUNDATION_LIST_INITIATE",
  FOUNDATION_LIST_SUCCESS: "FOUNDATION_LIST_SUCCESS",
  FOUNDATION_LIST_FAILURE: "FOUNDATION_LIST_INITIATE",

  FIRST_STEP_1_BLOCK_INITIATE: "FIRST_STEP_1_BLOCK_INITIATE",
  FIRST_STEP_1_BLOCK_SUCCESS: "FIRST_STEP_1_BLOCK_SUCCESS",
  FIRST_STEP_1_BLOCK_FAILURE: "FIRST_STEP_1_BLOCK_FAILURE",

  FIRST_STEP_2_BLOCK_INITIATE: "FIRST_STEP_2_BLOCK_INITIATE",
  FIRST_STEP_2_BLOCK_SUCCESS: "FIRST_STEP_2_BLOCK_SUCCESS",
  FIRST_STEP_2_BLOCK_FAILURE: "FIRST_STEP_2_BLOCK_FAILURE",

  FIRST_STEP_3_BLOCK_INITIATE: "FIRST_STEP_3_BLOCK_INITIATE",
  FIRST_STEP_3_BLOCK_SUCCESS: "FIRST_STEP_3_BLOCK_SUCCESS",
  FIRST_STEP_3_BLOCK_FAILURE: "FIRST_STEP_3_BLOCK_FAILURE",

  GET_ALL_CONTRACTS_SUCCESS_STEP_1_BLOCK_INITIATE:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_1_BLOCK_INITIATE",
  GET_ALL_CONTRACTS_SUCCESS_STEP_1_BLOCK_SUCCESS:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_1_BLOCK_SUCCESS",
  GET_ALL_CONTRACTS_SUCCESS_STEP_1_BLOCK_FAILURE:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_1_BLOCK_FAILURE",

  GET_ALL_CONTRACTS_SUCCESS_STEP_2_BLOCK_INITIATE:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_2_BLOCK_INITIATE",
  GET_ALL_CONTRACTS_SUCCESS_STEP_2_BLOCK_SUCCESS:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_2_BLOCK_SUCCESS",
  GET_ALL_CONTRACTS_SUCCESS_STEP_2_BLOCK_FAILURE:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_2_BLOCK_FAILURE",

  GET_ALL_CONTRACTS_SUCCESS_STEP_3_BLOCK_INITIATE:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_3_BLOCK_INITIATE",
  GET_ALL_CONTRACTS_SUCCESS_STEP_3_BLOCK_SUCCESS:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_3_BLOCK_SUCCESS",
  GET_ALL_CONTRACTS_SUCCESS_STEP_3_BLOCK_FAILURE:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_3_BLOCK_FAILURE",

  GET_BLOCK_FOUNDATION_STEP1_INITIATE: "GET_BLOCK_FOUNDATION_STEP1_INITIATE",
  GET_BLOCK_FOUNDATION_STEP1_SUCCESS: "GET_BLOCK_FOUNDATION_STEP1_SUCCESS",
  GET_BLOCK_FOUNDATION_STEP1_FAILURE: "GET_BLOCK_FOUNDATION_STEP1_FAILURE",

  CREATE_BLOCK_FOUNDATION_STEP1_INITIATE:
    "CREATE_BLOCK_FOUNDATION_STEP1_INITIATE",
  CREATE_BLOCK_FOUNDATION_STEP1_SUCCESS:
    "CREATE_BLOCK_FOUNDATION_STEP1_SUCCESS",
  CREATE_BLOCK_FOUNDATION_STEP1_FAILURE:
    "CREATE_BLOCK_FOUNDATION_STEP1_FAILURE",

  GET_BLOCK_FOUNDATION_STEP2_INITIATE: "GET_BLOCK_FOUNDATION_STEP2_INITIATE",
  GET_BLOCK_FOUNDATION_STEP2_SUCCESS: "GET_BLOCK_FOUNDATION_STEP2_SUCCESS",
  GET_BLOCK_FOUNDATION_STEP2_FAILURE: "GET_BLOCK_FOUNDATION_STEP2_FAILURE",

  CREATE_BLOCK_FOUNDATION_STEP2_INITIATE:
    "CREATE_BLOCK_FOUNDATION_STEP2_INITIATE",
  CREATE_BLOCK_FOUNDATION_STEP2_SUCCESS:
    "CREATE_BLOCK_FOUNDATION_STEP2_SUCCESS",
  CREATE_BLOCK_FOUNDATION_STEP2_FAILURE:
    "CREATE_BLOCK_FOUNDATION_STEP2_FAILURE",

  GET_BLOCK_FOUNDATION_STEP3_INITIATE: "GET_BLOCK_FOUNDATION_STEP3_INITIATE",
  GET_BLOCK_FOUNDATION_STEP3_SUCCESS: "GET_BLOCK_FOUNDATION_STEP3_SUCCESS",
  GET_BLOCK_FOUNDATION_STEP3_FAILURE: "GET_BLOCK_FOUNDATION_STEP3_FAILURE",

  GET_UNIT_STEP1_INITIATE: "GET_UNIT_STEP1_INITIATE",
  GET_UNIT_STEP1_SUCCESS: "GET_UNIT_STEP1_SUCCESS",
  GET_UNIT_STEP1_FAILURE: "GET_UNIT_STEP1_FAILURE",

  GET_UNIT_STEP2_INITIATE: "GET_UNIT_STEP2_INITIATE",
  GET_UNIT_STEP2_SUCCESS: "GET_UNIT_STEP2_SUCCESS",
  GET_UNIT_STEP2_FAILURE: "GET_UNIT_STEP2_FAILURE",

  CREATE_UNIT_STEP1_INITIATE: "CREATE_UNIT_STEP1_INITIATE",
  CREATE_UNIT_STEP1_SUCCESS: "CREATE_UNIT_STEP1_SUCCESS",
  CREATE_UNIT_STEP1_FAILURE: "CREATE_UNIT_STEP1_FAILURE",

  CREATE_UNIT_STEP2_INITIATE: "CREATE_UNIT_STEP2_INITIATE",
  CREATE_UNIT_STEP2_SUCCESS: "CREATE_UNIT_STEP2_SUCCESS",
  CREATE_UNIT_STEP2_FAILURE: "CREATE_UNIT_STEP2_FAILURE",

  GET_UNIT_STEP3_INITIATE: "GET_UNIT_STEP3_INITIATE",
  GET_UNIT_STEP3_SUCCESS: "GET_UNIT_STEP3_SUCCESS",
  GET_UNIT_STEP3_FAILURE: "GET_UNIT_STEP3_FAILURE",

  CREATE_UNIT_STEP3_INITIATE: "CREATE_UNIT_STEP3_INITIATE",
  CREATE_UNIT_STEP3_SUCCESS: "CREATE_UNIT_STEP3_SUCCESS",
  CREATE_UNIT_STEP3_FAILURE: "CREATE_UNIT_STEP3_FAILURE",

  GET_UNIT_STEP4_INITIATE: "GET_UNIT_STEP4_INITIATE",
  GET_UNIT_STEP4_SUCCESS: "GET_UNIT_STEP4_SUCCESS",
  GET_UNIT_STEP4_FAILURE: "GET_UNIT_STEP4_FAILURE",

  GET_COMMON_AREA_STEP1_INITIATE: "GET_COMMON_AREA_STEP1_INITIATE",
  GET_COMMON_AREA_STEP1_SUCCESS: "GET_COMMON_AREA_STEP1_SUCCESS",
  GET_COMMON_AREA_STEP1_FAILURE: "GET_COMMON_AREA_STEP1_FAILURE",

  CREATE_COMMON_AREA_STEP1_INITIATE: "CREATE_COMMON_AREA_STEP1_INITIATE",
  CREATE_COMMON_AREA_STEP1_SUCCESS: "CREATE_COMMON_AREA_STEP1_SUCCESS",
  CREATE_COMMON_AREA_STEP1_FAILURE: "CREATE_COMMON_AREA_STEP1_FAILURE",

  GET_COMMON_AREA_STEP2_INITIATE: "GET_COMMON_AREA_STEP2_INITIATE",
  GET_COMMON_AREA_STEP2_SUCCESS: "GET_COMMON_AREA_STEP2_SUCCESS",
  GET_COMMON_AREA_STEP2_FAILURE: "GET_COMMON_AREA_STEP2_FAILURE",

  GET_COMMON_BUILDING_STEP1_INITIATE: "GET_COMMON_BUILDING_STEP1_INITIATE",
  GET_COMMON_BUILDING_STEP1_SUCCESS: "GET_COMMON_BUILDING_STEP1_SUCCESS",
  GET_COMMON_BUILDING_STEP1_FAILURE: "GET_COMMON_BUILDING_STEP1_FAILURE",

  GET_FOUNDATION_TYPE_INITIATE: "GET_FOUNDATION_TYPE_INITIATE",
  GET_FOUNDATION_TYPE_SUCCESS: "GET_FOUNDATION_TYPE_SUCCESS",
  GET_FOUNDATION_TYPE_FAILURE: "GET_FOUNDATION_TYPE_FAILURE",

  CREATE_COMMON_BUILDING_STEP1_INITIATE:
    "CREATE_COMMON_BUILDING_STEP1_INITIATE",
  CREATE_COMMON_BUILDING_STEP1_SUCCESS: "CREATE_COMMON_BUILDING_STEP1_SUCCESS",
  CREATE_COMMON_BUILDING_STEP1_FAILURE: "CREATE_COMMON_BUILDING_STEP1_FAILURE",

  CREATE_COMMON_BUILDING_STEP2_INITIATE:
    "CREATE_COMMON_BUILDING_STEP2_INITIATE",
  CREATE_COMMON_BUILDING_STEP2_SUCCESS: "CREATE_COMMON_BUILDING_STEP2_SUCCESS",
  CREATE_COMMON_BUILDING_STEP2_FAILURE: "CREATE_COMMON_BUILDING_STEP2_FAILURE",

  GET_COMMON_BUILDING_STEP2_INITIATE: "GET_COMMON_BUILDING_STEP2_INITIATE",
  GET_COMMON_BUILDING_STEP2_SUCCESS: "GET_COMMON_BUILDING_STEP2_SUCCESS",
  GET_COMMON_BUILDING_STEP2_FAILURE: "GET_COMMON_BUILDING_STEP2_FAILURE",

  GET_COMMON_BUILDING_STEP3_INITIATE: "GET_COMMON_BUILDING_STEP3_INITIATE",
  GET_COMMON_BUILDING_STEP3_SUCCESS: "GET_COMMON_BUILDING_STEP3_SUCCESS",
  GET_COMMON_BUILDING_STEP3_FAILURE: "GET_COMMON_BUILDING_STEP3_FAILURE",

  GET_LAND_DEVELOPMENT_STEP1_INITIATE: "GET_LAND_DEVELOPMENT_STEP1_INITIATE",
  GET_LAND_DEVELOPMENT_STEP1_SUCCESS: "GET_LAND_DEVELOPMENT_STEP1_SUCCESS",
  GET_LAND_DEVELOPMENT_STEP1_FAILURE: "GET_LAND_DEVELOPMENT_STEP1_FAILURE",

  GET_COMMON_BUILDING_FOUNDATION_STEP1_INITIATE:
    "GET_COMMON_BUILDING_FOUNDATION_STEP1_INITIATE",
  GET_COMMON_BUILDING_FOUNDATION_STEP1_SUCCESS:
    "GET_COMMON_BUILDING_FOUNDATION_STEP1_SUCCESS",
  GET_COMMON_BUILDING_FOUNDATION_STEP1_FAILURE:
    "GET_COMMON_BUILDING_FOUNDATION_STEP1_FAILURE",

  CREATE_COMMON_BUILDING_FOUNDATION_STEP1_INITIATE:
    "CREATE_COMMON_BUILDING_FOUNDATION_STEP1_INITIATE",
  CREATE_COMMON_BUILDING_FOUNDATION_STEP1_SUCCESS:
    "CREATE_COMMON_BUILDING_FOUNDATION_STEP1_SUCCESS",
  CREATE_COMMON_BUILDING_FOUNDATION_STEP1_FAILURE:
    "CREATE_COMMON_BUILDING_FOUNDATION_STEP1_FAILURE",

  GET_COMMON_BUILDING_FOUNDATION_STEP2_INITIATE:
    "GET_COMMON_BUILDING_FOUNDATION_STEP2_INITIATE",
  GET_COMMON_BUILDING_FOUNDATION_STEP2_SUCCESS:
    "GET_COMMON_BUILDING_FOUNDATION_STEP2_SUCCESS",
  GET_COMMON_BUILDING_FOUNDATION_STEP2_FAILURE:
    "GET_COMMON_BUILDING_FOUNDATION_STEP2_FAILURE",

  CREATE_COMMON_BUILDING_FOUNDATION_STEP2_INITIATE:
    "CREATE_COMMON_BUILDING_FOUNDATION_STEP2_INITIATE",
  CREATE_COMMON_BUILDING_FOUNDATION_STEP2_SUCCESS:
    "CREATE_COMMON_BUILDING_FOUNDATION_STEP2_SUCCESS",
  CREATE_COMMON_BUILDING_FOUNDATION_STEP2_FAILURE:
    "CREATE_COMMON_BUILDING_FOUNDATION_STEP2_FAILURE",

  GET_COMMON_BUILDING_FOUNDATION_STEP3_INITIATE:
    "GET_COMMON_BUILDING_FOUNDATION_STEP3_INITIATE",
  GET_COMMON_BUILDING_FOUNDATION_STEP3_SUCCESS:
    "GET_COMMON_BUILDING_FOUNDATION_STEP3_SUCCESS",
  GET_COMMON_BUILDING_FOUNDATION_STEP3_FAILURE:
    "GET_COMMON_BUILDING_FOUNDATION_STEP3_FAILURE",

  GET_COMMON_GARAGE_STEP1_INITIATE: "GET_COMMON_GARAGE_STEP1_INITIATE",
  GET_COMMON_GARAGE_STEP1_SUCCESS: "GET_COMMON_GARAGE_STEP1_SUCCESS",
  GET_COMMON_GARAGE_STEP1_FAILURE: "GET_COMMON_GARAGE_STEP1_FAILURE",

  CREATE_COMMON_GARAGE_STEP1_INITIATE: "CREATE_COMMON_GARAGE_STEP1_INITIATE",
  CREATE_COMMON_GARAGE_STEP1_SUCCESS: "CREATE_COMMON_GARAGE_STEP1_SUCCESS",
  CREATE_COMMON_GARAGE_STEP1_FAILURE: "CREATE_COMMON_GARAGE_STEP1_FAILURE",

  GET_COMMON_GARAGE_STEP2_INITIATE: "GET_COMMON_GARAGE_STEP2_INITIATE",
  GET_COMMON_GARAGE_STEP2_SUCCESS: "GET_COMMON_GARAGE_STEP2_SUCCESS",
  GET_COMMON_GARAGE_STEP2_FAILURE: "GET_COMMON_GARAGE_STEP2_FAILURE",

  CREATE_COMMON_GARAGE_STEP2_INITIATE: "CREATE_COMMON_GARAGE_STEP2_INITIATE",
  CREATE_COMMON_GARAGE_STEP2_SUCCESS: "CREATE_COMMON_GARAGE_STEP2_SUCCESS",
  CREATE_COMMON_GARAGE_STEP2_FAILURE: "CREATE_COMMON_GARAGE_STEP2_FAILURE",

  GET_COMMON_GARAGE_STEP3_INITIATE: "GET_COMMON_GARAGE_STEP3_INITIATE",
  GET_COMMON_GARAGE_STEP3_SUCCESS: "GET_COMMON_GARAGE_STEP3_SUCCESS",
  GET_COMMON_GARAGE_STEP3_FAILURE: "GET_COMMON_GARAGE_STEP3_FAILURE",

  GET_MULTI_LEVEL_STEP1_INITIATE: "GET_MULTI_LEVEL_STEP1_INITIATE",
  GET_MULTI_LEVEL_STEP1_SUCCESS: "GET_MULTI_LEVEL_STEP1_SUCCESS",
  GET_MULTI_LEVEL_STEP1_FAILURE: "GET_MULTI_LEVEL_STEP1_FAILURE",

  CREATE_MULTI_LEVEL_STEP1_INITIATE: "CREATE_MULTI_LEVEL_STEP1_INITIATE",
  CREATE_MULTI_LEVEL_STEP1_SUCCESS: "CREATE_MULTI_LEVEL_STEP1_SUCCESS",
  CREATE_MULTI_LEVEL_STEP1_FAILURE: "CREATE_MULTI_LEVEL_STEP1_FAILURE",

  GET_MULTI_LEVEL_STEP2_INITIATE: "GET_MULTI_LEVEL_STEP2_INITIATE",
  GET_MULTI_LEVEL_STEP2_SUCCESS: "GET_MULTI_LEVEL_STEP2_SUCCESS",
  GET_MULTI_LEVEL_STEP2_FAILURE: "GET_MULTI_LEVEL_STEP2_FAILURE",

  CREATE_MULTI_LEVEL_STEP2_INITIATE: "CREATE_MULTI_LEVEL_STEP2_INITIATE",
  CREATE_MULTI_LEVEL_STEP2_SUCCESS: "CREATE_MULTI_LEVEL_STEP2_SUCCESS",
  CREATE_MULTI_LEVEL_STEP2_FAILURE: "CREATE_MULTI_LEVEL_STEP2_FAILURE",

  GET_MULTI_LEVEL_STEP3_INITIATE: "GET_MULTI_LEVEL_STEP3_INITIATE",
  GET_MULTI_LEVEL_STEP3_SUCCESS: "GET_MULTI_LEVEL_STEP3_SUCCESS",
  GET_MULTI_LEVEL_STEP3_FAILURE: "GET_MULTI_LEVEL_STEP3_FAILURE",

  CREATE_LAND_DEVELOPMENT_STEP1_INITIATE:
    "CREATE_LAND_DEVELOPMENT_STEP1_INITIATE",
  CREATE_LAND_DEVELOPMENT_STEP1_SUCCESS:
    "CREATE_LAND_DEVELOPMENT_STEP1_SUCCESS",
  CREATE_LAND_DEVELOPMENT_STEP1_FAILURE:
    "CREATE_LAND_DEVELOPMENT_STEP1_FAILURE",

  GET_LAND_DEVELOPMENT_STEP2_INITIATE: "GET_LAND_DEVELOPMENT_STEP2_INITIATE",
  GET_LAND_DEVELOPMENT_STEP2_SUCCESS: "GET_LAND_DEVELOPMENT_STEP2_SUCCESS",
  GET_LAND_DEVELOPMENT_STEP2_FAILURE: "GET_LAND_DEVELOPMENT_STEP2_FAILURE",

  CREATE_LAND_DEVELOPMENT_STEP2_INITIATE:
    "CREATE_LAND_DEVELOPMENT_STEP2_INITIATE",
  CREATE_LAND_DEVELOPMENT_STEP2_SUCCESS:
    "CREATE_LAND_DEVELOPMENT_STEP2_SUCCESS",
  CREATE_LAND_DEVELOPMENT_STEP2_FAILURE:
    "CREATE_LAND_DEVELOPMENT_STEP2_FAILURE",

  GET_LAND_DEVELOPMENT_STEP3_INITIATE: "GET_LAND_DEVELOPMENT_STEP3_INITIATE",
  GET_LAND_DEVELOPMENT_STEP3_SUCCESS: "GET_LAND_DEVELOPMENT_STEP3_SUCCESS",
  GET_LAND_DEVELOPMENT_STEP3_FAILURE: "GET_LAND_DEVELOPMENT_STEP3_FAILURE",

  GET_PROFORMA_CONFIRMATION_SUMARRY_INITIATE:
    "GET_PROFORMA_CONFIRMATION_SUMARRY_INITIATE",
  GET_PROFORMA_CONFIRMATION_SUMARRY_SUCCESS:
    "GET_PROFORMA_CONFIRMATION_SUMARRY_SUCCESS",
  GET_PROFORMA_CONFIRMATION_SUMARRY_FAILURE:
    "GET_PROFORMA_CONFIRMATION_SUMARRY_FAILURE",

  ACTIVE_PROFORMA_INITIATE: "ACTIVE_PROFORMA_INITIATE",
  ACTIVE_PROFORMA_SUCCESS: "ACTIVE_PROFORMA_SUCCESS",
  ACTIVE_PROFORMA_FAILURE: "ACTIVE_PROFORMA_INITIATE",

  CREATE_PROJECTS_JOBS_PROFORMA_INITIATE: "CREATE_PROJECTS_JOBS_INITIATE",
  CREATE_PROJECTS_JOBS_PROFORMA_SUCCESS: "CREATE_PROJECTS_JOBS_SUCCESS",
  CREATE_PROJECTS_JOBS_PROFORMA_FAILURE: "CREATE_PROJECTS_JOBS_INITIATE",

  GET_PROFORMA_BLOCK_STEP2_INITIATE: "GET_PROFORMA_BLOCK_STEP2_INITIATE",
  GET_PROFORMA_BLOCK_STEP2_SUCCESS: "GET_PROFORMA_BLOCK_STEP2_SUCCESS",
  GET_PROFORMA_BLOCK_STEP2_FAILURE: "GET_PROFORMA_BLOCK_STEP2_FAILURE",

  CREATE_PROFORMA_BLOCK_STEP2_INITIATE: "CREATE_PROFORMA_BLOCK_STEP2_INITIATE",
  CREATE_PROFORMA_BLOCK_STEP2_SUCCESS: "CREATE_PROFORMA_BLOCK_STEP2_SUCCESS",
  CREATE_PROFORMA_BLOCK_STEP2_FAILURE: "CREATE_PROFORMA_BLOCK_STEP2_FAILURE",

  GET_PROFORMA_BLOCK_STEP3_INITIATE: "GET_PROFORMA_BLOCK_STEP3_INITIATE",
  GET_PROFORMA_BLOCK_STEP3_SUCCESS: "GET_PROFORMA_BLOCK_STEP3_SUCCESS",
  GET_PROFORMA_BLOCK_STEP3_FAILURE: "GET_PROFORMA_BLOCK_STEP3_FAILURE",

  GET_PROFORMA_STEP1_INITIATE: "GET_PROFORMA_STEP1_INITIATE",
  GET_PROFORMA_STEP2_SUCCESS: "GET_PROFORMA_STEP2_SUCCESS",
  GET_PROFORMA_STEP3_FAILURE: "GET_PROFORMA_STEP3_FAILURE",

  GET_PROFORMA_BLOCK_STEP1_INITIATE: "GET_PROFORMA_BLOCK_STEP1_INITIATE",
  GET_PROFORMA_BLOCK_STEP1_SUCCESS: "GET_PROFORMA_BLOCK_STEP2_SUCCESS",
  GET_PROFORMA_BLOCK_STEP1_FAILURE: "GET_PROFORMA_STEP3_FAILURE",

  GET_PROFORMA_BLOCK_STEP2_INITIATE: "GET_PROFORMA_BLOCK_STEP2_INITIATE",
  GET_PROFORMA_BLOCK_STEP2_SUCCESS: "GET_PROFORMA_BLOCK_STEP2_SUCCESS",
  GET_PROFORMA_BLOCK_STEP2_FAILURE: "GET_PROFORMA_STEP2_FAILURE",
};

export const iBuildAdministrationActionTypes = {
  ADD_SUPPORT_REQUEST_INITIATE: "ADD_SUPPORT_REQUEST_INITIATE",
  ADD_SUPPORT_REQUEST_SUCCESS: "ADD_SUPPORT_REQUEST_SUCCESS",
  ADD_SUPPORT_REQUEST_FAILURE: "ADD_SUPPORT_REQUEST_INITIATE",

  GET_HELP_REQUEST_INITIATE: "GET_HELP_REQUEST_INITIATE",
  GET_HELP_REQUEST_SUCCESS: "GET_HELP_REQUEST_SUCCESS",
  GET_HELP_REQUEST_FAILURE: "GET_HELP_REQUEST_INITIATE",

  GET_HEADER_HELP_REQUEST_INITIATE: "GET_HEADER_HELP_REQUEST_INITIATE",
  GET_HEADER_HELP_REQUEST_SUCCESS: "GET_HEADER_HELP_REQUEST_SUCCESS",
  GET_HEADER_HELP_REQUEST_FAILURE: "GET_HEADER_HELP_REQUEST_INITIATE",

  GET_CUSTOMER_DEMO_REQUEST_INITIATE: "GET_CUSTOMER_DEMO_REQUEST_INITIATE",
  GET_CUSTOMER_DEMO_REQUEST_SUCCESS: "GET_CUSTOMER_DEMO_REQUEST_SUCCESS",
  GET_CUSTOMER_DEMO_REQUEST_FAILURE: "GET_CUSTOMER_DEMO_REQUEST_INITIATE",

  GET_HELP_TAB_REQUEST_INITIATE: "GET_HELP_TAB_REQUEST_INITIATE",
  GET_HELP_TAB_REQUEST_SUCCESS: "GET_HELP_TAB_REQUEST_SUCCESS",
  GET_HELP_TAB_REQUEST_FAILURE: "GET_HELP_TAB_REQUEST_INITIATE",

  GET_HELP_REQUEST_DETAILS_INITIATE: "GET_HELP_REQUEST_DETAILS_INITIATE",
  GET_HELP_REQUEST_DETAILS_SUCCESS: "GET_HELP_REQUEST_DETAILS_SUCCESS",
  GET_HELP_REQUEST_DETAILS_FAILURE: "GET_HELP_REQUEST_DETAILS_INITIATE",

  RESOLVE_HELP_REQUEST_INITIATE: "RESOLVE_HELP_REQUEST_INITIATE",
  RESOLVE_HELP_REQUEST_SUCCESS: "RESOLVE_HELP_REQUEST_SUCCESS",
  RESOLVE_HELP_REQUEST_FAILURE: "RESOLVE_HELP_REQUEST_INITIATE",

  REMOVE_HELP_LIST_INITIATE: "REMOVE_HELP_LIST_INITIATE",
  REMOVE_HELP_LIST_SUCCESS: "REMOVE_HELP_LIST_SUCCESS",
  REMOVE_HELP_LIST_FAILURE: "REMOVE_HELP_LIST_INITIATE",

  UPDATE_HELP_LIST_INITIATE: "UPDATE_HELP_LIST_INITIATE",
  UPDATE_HELP_LIST_SUCCESS: "UPDATE_HELP_LIST_SUCCESS",
  UPDATE_HELP_LIST_FAILURE: "UPDATE_HELP_LIST_INITIATE",

  ADD_SUPPORTING_DOCS_INITIATE: "ADD_SUPPORTING_DOCS_INITIATE",
  ADD_SUPPORTING_DOCS_SUCCESS: "ADD_SUPPORTING_DOCS_SUCCESS",
  ADD_SUPPORTING_DOCS_FAILURE: "ADD_SUPPORTING_DOCS_INITIATE",

  REMOVE_SUPPORTING_DOCS_INITIATE: "REMOVE_SUPPORTING_DOCS_INITIATE",
  REMOVE_SUPPORTING_DOCS_SUCCESS: "REMOVE_SUPPORTING_DOCS_SUCCESS",
  REMOVE_SUPPORTING_DOCS_FAILURE: "REMOVE_SUPPORTING_DOCS_INITIATE",

  SUPPORTING_DOCS_LIST_INITIATE: "SUPPORTING_DOCS_LIST_INITIATE",
  SUPPORTING_DOCS_LIST_SUCCESS: "SUPPORTING_DOCS_LIST_SUCCESS",
  SUPPORTING_DOCS_LIST_FAILURE: "SUPPORTING_DOCS_LIST_INITIATE",

  FIRST_STEP_1_BLOCK_INITIATE: "FIRST_STEP_1_BLOCK_INITIATE",
  FIRST_STEP_1_BLOCK_SUCCESS: "FIRST_STEP_1_BLOCK_SUCCESS",
  FIRST_STEP_1_BLOCK_FAILURE: "FIRST_STEP_1_BLOCK_FAILURE",

  FIRST_STEP_2_BLOCK_INITIATE: "FIRST_STEP_2_BLOCK_INITIATE",
  FIRST_STEP_2_BLOCK_SUCCESS: "FIRST_STEP_2_BLOCK_SUCCESS",
  FIRST_STEP_2_BLOCK_FAILURE: "FIRST_STEP_2_BLOCK_FAILURE",

  FIRST_STEP_3_BLOCK_INITIATE: "FIRST_STEP_3_BLOCK_INITIATE",
  FIRST_STEP_3_BLOCK_SUCCESS: "FIRST_STEP_3_BLOCK_SUCCESS",
  FIRST_STEP_3_BLOCK_FAILURE: "FIRST_STEP_3_BLOCK_FAILURE",

  GET_ALL_CONTRACTS_SUCCESS_STEP_1_BLOCK_INITIATE:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_1_BLOCK_INITIATE",
  GET_ALL_CONTRACTS_SUCCESS_STEP_1_BLOCK_SUCCESS:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_1_BLOCK_SUCCESS",
  GET_ALL_CONTRACTS_SUCCESS_STEP_1_BLOCK_FAILURE:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_1_BLOCK_FAILURE",

  GET_ALL_CONTRACTS_SUCCESS_STEP_2_BLOCK_INITIATE:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_2_BLOCK_INITIATE",
  GET_ALL_CONTRACTS_SUCCESS_STEP_2_BLOCK_SUCCESS:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_2_BLOCK_SUCCESS",
  GET_ALL_CONTRACTS_SUCCESS_STEP_2_BLOCK_FAILURE:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_2_BLOCK_FAILURE",

  GET_ALL_CONTRACTS_SUCCESS_STEP_3_BLOCK_INITIATE:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_3_BLOCK_INITIATE",
  GET_ALL_CONTRACTS_SUCCESS_STEP_3_BLOCK_SUCCESS:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_3_BLOCK_SUCCESS",
  GET_ALL_CONTRACTS_SUCCESS_STEP_3_BLOCK_FAILURE:
    "GET_ALL_CONTRACTS_SUCCESS_STEP_3_BLOCK_FAILURE",

  GET_BLOCK_FOUNDATION_STEP1_INITIATE: "GET_BLOCK_FOUNDATION_STEP1_INITIATE",
  GET_BLOCK_FOUNDATION_STEP1_SUCCESS: "GET_BLOCK_FOUNDATION_STEP1_SUCCESS",
  GET_BLOCK_FOUNDATION_STEP1_FAILURE: "GET_BLOCK_FOUNDATION_STEP1_FAILURE",

  CREATE_BLOCK_FOUNDATION_STEP1_INITIATE:
    "CREATE_BLOCK_FOUNDATION_STEP1_INITIATE",
  CREATE_BLOCK_FOUNDATION_STEP1_SUCCESS:
    "CREATE_BLOCK_FOUNDATION_STEP1_SUCCESS",
  CREATE_BLOCK_FOUNDATION_STEP1_FAILURE:
    "CREATE_BLOCK_FOUNDATION_STEP1_FAILURE",

  GET_BLOCK_FOUNDATION_STEP2_INITIATE: "GET_BLOCK_FOUNDATION_STEP2_INITIATE",
  GET_BLOCK_FOUNDATION_STEP2_SUCCESS: "GET_BLOCK_FOUNDATION_STEP2_SUCCESS",
  GET_BLOCK_FOUNDATION_STEP2_FAILURE: "GET_BLOCK_FOUNDATION_STEP2_FAILURE",

  CREATE_BLOCK_FOUNDATION_STEP2_INITIATE:
    "CREATE_BLOCK_FOUNDATION_STEP2_INITIATE",
  CREATE_BLOCK_FOUNDATION_STEP2_SUCCESS:
    "CREATE_BLOCK_FOUNDATION_STEP2_SUCCESS",
  CREATE_BLOCK_FOUNDATION_STEP2_FAILURE:
    "CREATE_BLOCK_FOUNDATION_STEP2_FAILURE",

  GET_BLOCK_FOUNDATION_STEP3_INITIATE: "GET_BLOCK_FOUNDATION_STEP3_INITIATE",
  GET_BLOCK_FOUNDATION_STEP3_SUCCESS: "GET_BLOCK_FOUNDATION_STEP3_SUCCESS",
  GET_BLOCK_FOUNDATION_STEP3_FAILURE: "GET_BLOCK_FOUNDATION_STEP3_FAILURE",

  GET_UNIT_STEP1_INITIATE: "GET_UNIT_STEP1_INITIATE",
  GET_UNIT_STEP1_SUCCESS: "GET_UNIT_STEP1_SUCCESS",
  GET_UNIT_STEP1_FAILURE: "GET_UNIT_STEP1_FAILURE",

  GET_UNIT_STEP2_INITIATE: "GET_UNIT_STEP2_INITIATE",
  GET_UNIT_STEP2_SUCCESS: "GET_UNIT_STEP2_SUCCESS",
  GET_UNIT_STEP2_FAILURE: "GET_UNIT_STEP2_FAILURE",

  CREATE_UNIT_STEP1_INITIATE: "CREATE_UNIT_STEP1_INITIATE",
  CREATE_UNIT_STEP1_SUCCESS: "CREATE_UNIT_STEP1_SUCCESS",
  CREATE_UNIT_STEP1_FAILURE: "CREATE_UNIT_STEP1_FAILURE",

  CREATE_UNIT_STEP2_INITIATE: "CREATE_UNIT_STEP2_INITIATE",
  CREATE_UNIT_STEP2_SUCCESS: "CREATE_UNIT_STEP2_SUCCESS",
  CREATE_UNIT_STEP2_FAILURE: "CREATE_UNIT_STEP2_FAILURE",

  GET_UNIT_STEP3_INITIATE: "GET_UNIT_STEP3_INITIATE",
  GET_UNIT_STEP3_SUCCESS: "GET_UNIT_STEP3_SUCCESS",
  GET_UNIT_STEP3_FAILURE: "GET_UNIT_STEP3_FAILURE",

  CREATE_UNIT_STEP3_INITIATE: "CREATE_UNIT_STEP3_INITIATE",
  CREATE_UNIT_STEP3_SUCCESS: "CREATE_UNIT_STEP3_SUCCESS",
  CREATE_UNIT_STEP3_FAILURE: "CREATE_UNIT_STEP3_FAILURE",

  GET_UNIT_STEP4_INITIATE: "GET_UNIT_STEP4_INITIATE",
  GET_UNIT_STEP4_SUCCESS: "GET_UNIT_STEP4_SUCCESS",
  GET_UNIT_STEP4_FAILURE: "GET_UNIT_STEP4_FAILURE",

  GET_COMMON_AREA_STEP1_INITIATE: "GET_COMMON_AREA_STEP1_INITIATE",
  GET_COMMON_AREA_STEP1_SUCCESS: "GET_COMMON_AREA_STEP1_SUCCESS",
  GET_COMMON_AREA_STEP1_FAILURE: "GET_COMMON_AREA_STEP1_FAILURE",

  CREATE_COMMON_AREA_STEP1_INITIATE: "CREATE_COMMON_AREA_STEP1_INITIATE",
  CREATE_COMMON_AREA_STEP1_SUCCESS: "CREATE_COMMON_AREA_STEP1_SUCCESS",
  CREATE_COMMON_AREA_STEP1_FAILURE: "CREATE_COMMON_AREA_STEP1_FAILURE",

  GET_COMMON_AREA_STEP2_INITIATE: "GET_COMMON_AREA_STEP2_INITIATE",
  GET_COMMON_AREA_STEP2_SUCCESS: "GET_COMMON_AREA_STEP2_SUCCESS",
  GET_COMMON_AREA_STEP2_FAILURE: "GET_COMMON_AREA_STEP2_FAILURE",

  GET_COMMON_BUILDING_STEP1_INITIATE: "GET_COMMON_BUILDING_STEP1_INITIATE",
  GET_COMMON_BUILDING_STEP1_SUCCESS: "GET_COMMON_BUILDING_STEP1_SUCCESS",
  GET_COMMON_BUILDING_STEP1_FAILURE: "GET_COMMON_BUILDING_STEP1_FAILURE",

  GET_FOUNDATION_TYPE_INITIATE: "GET_FOUNDATION_TYPE_INITIATE",
  GET_FOUNDATION_TYPE_SUCCESS: "GET_FOUNDATION_TYPE_SUCCESS",
  GET_FOUNDATION_TYPE_FAILURE: "GET_FOUNDATION_TYPE_FAILURE",

  CREATE_COMMON_BUILDING_STEP1_INITIATE:
    "CREATE_COMMON_BUILDING_STEP1_INITIATE",
  CREATE_COMMON_BUILDING_STEP1_SUCCESS: "CREATE_COMMON_BUILDING_STEP1_SUCCESS",
  CREATE_COMMON_BUILDING_STEP1_FAILURE: "CREATE_COMMON_BUILDING_STEP1_FAILURE",

  CREATE_COMMON_BUILDING_STEP2_INITIATE:
    "CREATE_COMMON_BUILDING_STEP2_INITIATE",
  CREATE_COMMON_BUILDING_STEP2_SUCCESS: "CREATE_COMMON_BUILDING_STEP2_SUCCESS",
  CREATE_COMMON_BUILDING_STEP2_FAILURE: "CREATE_COMMON_BUILDING_STEP2_FAILURE",

  GET_COMMON_BUILDING_STEP2_INITIATE: "GET_COMMON_BUILDING_STEP2_INITIATE",
  GET_COMMON_BUILDING_STEP2_SUCCESS: "GET_COMMON_BUILDING_STEP2_SUCCESS",
  GET_COMMON_BUILDING_STEP2_FAILURE: "GET_COMMON_BUILDING_STEP2_FAILURE",

  GET_COMMON_BUILDING_STEP3_INITIATE: "GET_COMMON_BUILDING_STEP3_INITIATE",
  GET_COMMON_BUILDING_STEP3_SUCCESS: "GET_COMMON_BUILDING_STEP3_SUCCESS",
  GET_COMMON_BUILDING_STEP3_FAILURE: "GET_COMMON_BUILDING_STEP3_FAILURE",

  GET_LAND_DEVELOPMENT_STEP1_INITIATE: "GET_LAND_DEVELOPMENT_STEP1_INITIATE",
  GET_LAND_DEVELOPMENT_STEP1_SUCCESS: "GET_LAND_DEVELOPMENT_STEP1_SUCCESS",
  GET_LAND_DEVELOPMENT_STEP1_FAILURE: "GET_LAND_DEVELOPMENT_STEP1_FAILURE",

  CREATE_LAND_DEVELOPMENT_STEP1_INITIATE:
    "CREATE_LAND_DEVELOPMENT_STEP1_INITIATE",
  CREATE_LAND_DEVELOPMENT_STEP1_SUCCESS:
    "CREATE_LAND_DEVELOPMENT_STEP1_SUCCESS",
  CREATE_LAND_DEVELOPMENT_STEP1_FAILURE:
    "CREATE_LAND_DEVELOPMENT_STEP1_FAILURE",

  GET_LAND_DEVELOPMENT_STEP2_INITIATE: "GET_LAND_DEVELOPMENT_STEP2_INITIATE",
  GET_LAND_DEVELOPMENT_STEP2_SUCCESS: "GET_LAND_DEVELOPMENT_STEP2_SUCCESS",
  GET_LAND_DEVELOPMENT_STEP2_FAILURE: "GET_LAND_DEVELOPMENT_STEP2_FAILURE",

  CREATE_LAND_DEVELOPMENT_STEP2_INITIATE:
    "CREATE_LAND_DEVELOPMENT_STEP2_INITIATE",
  CREATE_LAND_DEVELOPMENT_STEP2_SUCCESS:
    "CREATE_LAND_DEVELOPMENT_STEP2_SUCCESS",
  CREATE_LAND_DEVELOPMENT_STEP2_FAILURE:
    "CREATE_LAND_DEVELOPMENT_STEP2_FAILURE",

  GET_LAND_DEVELOPMENT_STEP3_INITIATE: "GET_LAND_DEVELOPMENT_STEP3_INITIATE",
  GET_LAND_DEVELOPMENT_STEP3_SUCCESS: "GET_LAND_DEVELOPMENT_STEP3_SUCCESS",
  GET_LAND_DEVELOPMENT_STEP3_FAILURE: "GET_LAND_DEVELOPMENT_STEP3_FAILURE",

  GET_PROFORMA_CONFIRMATION_SUMARRY_INITIATE:
    "GET_PROFORMA_CONFIRMATION_SUMARRY_INITIATE",
  GET_PROFORMA_CONFIRMATION_SUMARRY_SUCCESS:
    "GET_PROFORMA_CONFIRMATION_SUMARRY_SUCCESS",
  GET_PROFORMA_CONFIRMATION_SUMARRY_FAILURE:
    "GET_PROFORMA_CONFIRMATION_SUMARRY_FAILURE",

  CUSTOMER_LIST_INITIATE: "CUSTOMER_LIST_INITIATE",
  CUSTOMER_LIST_SUCCESS: "CUSTOMER_LIST_SUCCESS",
  CUSTOMER_LIST_FAILURE: "CUSTOMER_LIST_FAILURE",

  CUSTOMER_HISTORY_LIST_INITIATE: "CUSTOMER_HISTORY_LIST_INITIATE",
  CUSTOMER_HISTORY_LIST_SUCCESS: "CUSTOMER_HISTORY_LIST_SUCCESS",
  CUSTOMER_HISTORY_LIST_FAILURE: "CUSTOMER_HISTORY_LIST_FAILURE",

  CUSTOMER_REQUEST_LIST_INITIATE: "CUSTOMER_REQUEST_LIST_INITIATE",
  CUSTOMER_REQUEST_LIST_SUCCESS: "CUSTOMER_REQUEST_LIST_SUCCESS",
  CUSTOMER_REQUEST_LIST_FAILURE: "CUSTOMER_REQUEST_LIST_FAILURE",

  CUSTOMER_CODE_LIST_INITIATE: "CUSTOMER_CODE_LIST_INITIATE",
  CUSTOMER_CODE_LIST_SUCCESS: "CUSTOMER_CODE_LIST_SUCCESS",
  CUSTOMER_CODE_LIST_FAILURE: "CUSTOMER_CODE_LIST_FAILURE",
};

export const vendorViewConfigAction = {
  GET_GENERAL_CONFIGURATION_INITIATE: "GET_GENERAL_CONFIGURATION_INITIATE",
  GET_GENERAL_CONFIGURATION_SUCCESS: "GET_GENERAL_CONFIGURATION_SUCCESS",
  GET_GENERAL_CONFIGURATION_FAILURE: "GET_GENERAL_CONFIGURATION_FAILURE",

  UPDATE_GENERAL_CONFIGURATION_INITIATE:
    "UPDATE_GENERAL_CONFIGURATION_INITIATE",
  UPDATE_GENERAL_CONFIGURATION_SUCCESS: "UPDATE_GENERAL_CONFIGURATION_SUCCESS",
  UPDATE_GENERAL_CONFIGURATION_FAILURE: "UPDATE_GENERAL_CONFIGURATION_FAILURE",

  GET_DESIGN_SELECTIONS_INITIATE: "GET_DESIGN_SELECTIONS_INITIATE",
  GET_DESIGN_SELECTIONS_SUCCESS: "GET_DESIGN_SELECTIONS_SUCCESS",
  GET_DESIGN_SELECTIONS_FAILURE: "GET_DESIGN_SELECTIONS_FAILURE",

  UPDATE_DESIGN_SELECTIONS_INITIATE: "UPDATE_DESIGN_SELECTIONS_INITIATE",
  UPDATE_DESIGN_SELECTIONS_SUCCESS: "UPDATE_DESIGN_SELECTIONS_SUCCESS",
  UPDATE_DESIGN_SELECTIONS_FAILURE: "UPDATE_DESIGN_SELECTIONS_FAILURE",

  GET_APPOINTMENTS_INITIATE: "GET_APPOINTMENTS_INITIATE",
  GET_APPOINTMENTS_SUCCESS: "GET_APPOINTMENTS_SUCCESS",
  GET_APPOINTMENTS_FAILURE: "GET_APPOINTMENTS_FAILURE",

  UPDATE_APPOINTMENTS_INITIATE: "UPDATE_APPOINTMENTS_INITIATE",
  UPDATE_APPOINTMENTS_SUCCESS: "UPDATE_APPOINTMENTS_SUCCESS",
  UPDATE_APPOINTMENTS_FAILURE: "UPDATE_APPOINTMENTS_FAILURE",

  GET_CONSTRUCTION_INITIATE: "GET_CONSTRUCTION_INITIATE",
  GET_CONSTRUCTION_SUCCESS: "GET_CONSTRUCTION_SUCCESS",
  GET_CONSTRUCTION_FAILURE: "GET_CONSTRUCTION_FAILURE",

  UPDATE_CONSTRUCTION_INITIATE: "UPDATE_CONSTRUCTION_INITIATE",
  UPDATE_CONSTRUCTION_SUCCESS: "UPDATE_CONSTRUCTION_SUCCESS",
  UPDATE_CONSTRUCTION_FAILURE: "UPDATE_CONSTRUCTION_FAILURE",

  GET_CHANGE_ORDER_INITIATE: "GET_CHANGE_ORDER_INITIATE",
  GET_CHANGE_ORDER_SUCCESS: "GET_CHANGE_ORDER_SUCCESS",
  GET_CHANGE_ORDER_FAILURE: "GET_CHANGE_ORDER_FAILURE",

  UPDATE_CHANGE_ORDER_INITIATE: "UPDATE_CHANGE_ORDER_INITIATE",
  UPDATE_CHANGE_ORDER_SUCCESS: "UPDATE_CHANGE_ORDER_SUCCESS",
  UPDATE_CHANGE_ORDER_FAILURE: "UPDATE_CHANGE_ORDER_FAILURE",

  GET_WARRANTY_INITIATE: "GET_WARRANTY_INITIATE",
  GET_WARRANTY_SUCCESS: "GET_WARRANTY_SUCCESS",
  GET_WARRANTY_FAILURE: "GET_WARRANTY_FAILURE",

  UPDATE_WARRANTY_INITIATE: "UPDATE_WARRANTY_INITIATE",
  UPDATE_WARRANTY_SUCCESS: "UPDATE_WARRANTY_SUCCESS",
  UPDATE_WARRANTY_FAILURE: "UPDATE_WARRANTY_FAILURE",

  GET_TODO_INITIATE: "GET_TODO_INITIATE",
  GET_TODO_SUCCESS: "GET_TODO_SUCCESS",
  GET_TODO_FAILURE: "GET_TODO_FAILURE",

  UPDATE_TODO_INITIATE: "UPDATE_TODO_INITIATE",
  UPDATE_TODO_SUCCESS: "UPDATE_TODO_SUCCESS",
  UPDATE_TODO_FAILURE: "UPDATE_TODO_FAILURE",

  GET_ESTIMATION_INITIATE: "GET_ESTIMATION_INITIATE",
  GET_ESTIMATION_SUCCESS: "GET_ESTIMATION_SUCCESS",
  GET_ESTIMATION_FAILURE: "GET_ESTIMATION_FAILURE",

  UPDATE_ESTIMATION_INITIATE: "UPDATE_ESTIMATION_INITIATE",
  UPDATE_ESTIMATION_SUCCESS: "UPDATE_ESTIMATION_SUCCESS",
  UPDATE_ESTIMATION_FAILURE: "UPDATE_ESTIMATION_FAILURE",

  GET_COUNTRY_LIST_INITIATE: "GET_COUNTRY_LIST_INITIATE",
  GET_COUNTRY_LIST_SUCCESS: "GET_COUNTRY_LIST_SUCCESS",
  GET_COUNTRY_LIST_FAILURE: "GET_COUNTRY_LIST_FAILURE",

  GET_STATE_LIST_INITIATE: "GET_STATE_LIST_INITIATE",
  GET_STATE_LIST_SUCCESS: "GET_STATE_LIST_SUCCESS",
  GET_STATE_LIST_FAILURE: "GET_STATE_LIST_FAILURE",

  ADD_VENDOR_AUTHORIZED_INITIATE: "ADD_VENDOR_AUTHORIZED_INITIATE",
  ADD_VENDOR_AUTHORIZED_SUCCESS: "ADD_VENDOR_AUTHORIZED_SUCCESS",
  ADD_VENDOR_AUTHORIZED_FAILURE: "ADD_VENDOR_AUTHORIZED_FAILURE",

  VENDOR_AUTHORIZED_LIST_INITIATE: "VENDOR_AUTHORIZED_LIST_INITIATE",
  VENDOR_AUTHORIZED_LIST_SUCCESS: "VENDOR_AUTHORIZED_LIST_SUCCESS",
  VENDOR_AUTHORIZED_LIST_FAILURE: "VENDOR_AUTHORIZED_LIST_FAILURE",

  CREATE_ASSGNED_VENDOR_JOBS_PROJECTS_INITIATE:
    "CREATE_ASSGNED_VENDOR_JOBS_PROJECTS_INITIATE",
  CREATE_ASSGNED_VENDOR_JOBS_PROJECTS_SUCCESS:
    "CREATE_ASSGNED_VENDOR_JOBS_PROJECTS_SUCCESS",
  CREATE_ASSGNED_VENDOR_JOBS_PROJECTS_FAILURE:
    "CREATE_ASSGNED_VENDOR_JOBS_PROJECTS_FAILURE",

  ASSGNED_VENDOR_JOBS_PROJECTS_LIST_INITIATE:
    "ASSGNED_VENDOR_JOBS_PROJECTS_LIST_INITIATE",
  ASSGNED_VENDOR_JOBS_PROJECTS_LIST_SUCCESS:
    "ASSGNED_VENDOR_JOBS_PROJECTS_LIST_SUCCESS",
  ASSGNED_VENDOR_JOBS_PROJECTS_LIST_FAILURE:
    "ASSGNED_VENDOR_JOBS_PROJECTS_LIST_FAILURE",

  DELETE_ASSGNED_VENDOR_JOBS_PROJECTS_INITIATE:
    "DELETE_ASSGNED_VENDOR_JOBS_PROJECTS_INITIATE",
  DELETE_ASSGNED_VENDOR_JOBS_PROJECTS_SUCCESS:
    "DELETE_ASSGNED_VENDOR_JOBS_PROJECTS_SUCCESS",
  DELETE_ASSGNED_VENDOR_JOBS_PROJECTS_FAILURE:
    "DELETE_ASSGNED_VENDOR_JOBS_PROJECTS_FAILURE",

  ALL_PROJECT_LIST_INITIATE: "ALL_PROJECT_LIST_INITIATE",
  ALL_PROJECT_LIST_SUCCESS: "ALL_PROJECT_LIST_SUCCESS",
  ALL_PROJECT_LIST_FAILURE: "ALL_PROJECT_LIST_FAILURE",

  ENABLE_TRADE_VIEW_ACCESS_INITIATE: "ENABLE_TRADE_VIEW_ACCESS_INITIATE",
  ENABLE_TRADE_VIEW_ACCESS_SUCCESS: "ENABLE_TRADE_VIEW_ACCESS_SUCCESS",
  ENABLE_TRADE_VIEW_ACCESS_FAILURE: "ENABLE_TRADE_VIEW_ACCESS_FAILURE",

  DISABLE_TRADE_VIEW_ACCESS_INITIATE: "DISABLE_TRADE_VIEW_ACCESS_INITIATE",
  DISABLE_TRADE_VIEW_ACCESS_SUCCESS: "DISABLE_TRADE_VIEW_ACCESS_SUCCESS",
  DISABLE_TRADE_VIEW_ACCESS_FAILURE: "DISABLE_TRADE_VIEW_ACCESS_FAILURE",

  GENERAL_CONFIGURATION_INVESTOR_VIEW_INITIATE:
    "GENERAL_CONFIGURATION_INVESTOR_VIEW_INITIATE",
  GENERAL_CONFIGURATION_INVESTOR_VIEW_SUCCESS:
    "GENERAL_CONFIGURATION_INVESTOR_VIEW_SUCCESS",
  GENERAL_CONFIGURATION_INVESTOR_VIEW_FAILURE:
    "GENERAL_CONFIGURATION_INVESTOR_VIEW_FAILURE",

  UPDATE_GENERAL_CONFIGURATION_INVESTOR_VIEW_INITIATE:
    "UPDATE_GENERAL_CONFIGURATION_INVESTOR_VIEW_INITIATE",
  UPDATE_GENERAL_CONFIGURATION_INVESTOR_VIEW_SUCCESS:
    "UPDATE_GENERAL_CONFIGURATION_INVESTOR_VIEW_SUCCESS",
  UPDATE_GENERAL_CONFIGURATION_INVESTOR_VIEW_FAILURE:
    "UPDATE_GENERAL_CONFIGURATION_INVESTOR_VIEW_FAILURE",

  ADD_INVESTOR_VENDOR_AUTHORIZED_INITIATE:
    "ADD_INVESTOR_VENDOR_AUTHORIZED_INITIATE",
  ADD_INVESTOR_VENDOR_AUTHORIZED_SUCCESS:
    "ADD_INVESTOR_VENDOR_AUTHORIZED_SUCCESS",
  ADD_INVESTOR_VENDOR_AUTHORIZED_FAILURE:
    "ADD_INVESTOR_VENDOR_AUTHORIZED_FAILURE",

  VENDOR_INVESTOR_AUTHORIZED_LIST_INITIATE:
    "VENDOR_INVESTOR_AUTHORIZED_LIST_INITIATE",
  VENDOR_INVESTOR_AUTHORIZED_LIST_SUCCESS:
    "VENDOR_INVESTOR_AUTHORIZED_LIST_SUCCESS",
  VENDOR_INVESTOR_AUTHORIZED_LIST_FAILURE:
    "VENDOR_INVESTOR_AUTHORIZED_LIST_FAILURE",

  REMOVE_INVESTOR_VENDOR_AUTHORIZED_INITIATE:
    "REMOVE_INVESTOR_VENDOR_AUTHORIZED_INITIATE",
  REMOVE_INVESTOR_VENDOR_AUTHORIZED_SUCCESS:
    "REMOVE_INVESTOR_VENDOR_AUTHORIZED_SUCCESS",
  REMOVE_INVESTOR_VENDOR_AUTHORIZED_FAILURE:
    "REMOVE_INVESTOR_VENDOR_AUTHORIZED_FAILURE",

  ENABLE_INVESTOR_VENDOR_AUTHORIZED_INITIATE:
    "ENABLE_INVESTOR_VENDOR_AUTHORIZED_INITIATE",
  ENABLE_INVESTOR_VENDOR_AUTHORIZED_SUCCESS:
    "ENABLE_INVESTOR_VENDOR_AUTHORIZED_SUCCESS",
  ENABLE_INVESTOR_VENDOR_AUTHORIZED_FAILURE:
    "ENABLE_INVESTOR_VENDOR_AUTHORIZED_FAILURE",

  DISABLE_INVESTOR_VENDOR_AUTHORIZED_INITIATE:
    "DISABLE_INVESTOR_VENDOR_AUTHORIZED_INITIATE",
  DISABLE_INVESTOR_VENDOR_AUTHORIZED_SUCCESS:
    "DISABLE_INVESTOR_VENDOR_AUTHORIZED_SUCCESS",
  DISABLE_INVESTOR_VENDOR_AUTHORIZED_FAILURE:
    "DISABLE_INVESTOR_VENDOR_AUTHORIZED_FAILURE",

  TRADE_VIEW_VENDOR_USER_LIST_INITIATE: "TRADE_VIEW_VENDOR_USER_LIST_INITIATE",
  TRADE_VIEW_VENDOR_USER_LIST_SUCCESS: "TRADE_VIEW_VENDOR_USER_LIST_SUCCESS",
  TRADE_VIEW_VENDOR_USER_LIST_FAILURE: "TRADE_VIEW_VENDOR_USER_LIST_FAILURE",

  TRADE_ACCESS_REQUEST_INITIATE:"TRADE_ACCESS_REQUEST_INITIATE",
  TRADE_ACCESS_REQUEST_SUCCESS: "TRADE_ACCESS_REQUEST_SUCCESS",
  TRADE_ACCESS_REQUEST_FAILURE: "TRADE_ACCESS_REQUEST_FAILURE",

  INVESTOR_VIEW_VENDOR_USER_LIST_INITIATE:
    "INVESTOR_VIEW_VENDOR_USER_LIST_INITIATE",
  INVESTOR_VIEW_VENDOR_USER_LIST_SUCCESS:
    "INVESTOR_VIEW_VENDOR_USER_LIST_SUCCESS",
  INVESTOR_VIEW_VENDOR_USER_LIST_FAILURE:
    "INVESTOR_VIEW_VENDOR_USER_LIST_FAILURE",

    INVESTOR_VIEW_ACCESS_INITIATE:"INVESTOR_VIEW_ACCESS_INITIATE",
    INVESTOR_VIEW_ACCESS_SUCCESS: "INVESTOR_VIEW_ACCESS_SUCCESS",
    INVESTOR_VIEW_ACCESS_FAILURE: "INVESTOR_VIEW_ACCESS_FAILURE",
};

export const notificationConstantDK = {
  GET_ALL_NOTIFICATION_INITIATE: "GET_ALL_NOTIFICATION_INITIATE",
  GET_ALL_NOTIFICATION_SUCCESS: "GET_ALL_NOTIFICATION_SUCCESS",
  GET_ALL_NOTIFICATION_FAILURE: "GET_ALL_NOTIFICATION_INITIATE",

  GET_SINGLE_NOTIFICATION_INITIATE: "GET_SINGLE_NOTIFICATION_INITIATE",
  GET_SINGLE_NOTIFICATION_SUCCESS: "GET_SINGLE_NOTIFICATION_SUCCESS",
  GET_SINGLE_NOTIFICATION_FAILURE: "GET_SINGLE_NOTIFICATION_INITIATE",

  DELETE_NOTIFICATION_INITIATE: "DELETE_NOTIFICATION_INITIATE",
  DELETE_NOTIFICATION_SUCCESS: "DELETE_NOTIFICATION_SUCCESS",
  DELETE_NOTIFICATION_FAILURE: "DELETE_NOTIFICATION_INITIATE",

  MARK_AS_READ_INITIATE: "MARK_AS_READ_INITIATE",
  MARK_AS_READ_SUCCESS: "MARK_AS_READ_SUCCESS",
  MARK_AS_READ_FAILURE: "MARK_AS_READ_INITIATE",
};

export const profileConstant = {
  ADD_CONTACT_INITIATE: "ADD_CONTACT_INITIATE",
  ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
  ADD_CONTACT_FAILURE: "ADD_CONTACT_FAILURE",

  UPDATE_CONTACT_INITIATE: "UPDATE_CONTACT_INITIATE",
  UPDATE_CONTACT_SUCCESS: "UPDATE_CONTACT_SUCCESS",
  UPDATE_CONTACT_FAILURE: "UPDATE_CONTACT_FAILURE",

  CONTACT_LIST_INITIATE: "CONTACT_LIST_INITIATE",
  CONTACT_LIST_SUCCESS: "CONTACT_LIST_SUCCESS",
  CONTACT_LIST_FAILURE: "CONTACT_LIST_FAILURE",

  DELETE_CONTACT_INITIATE: "DELELTE_CONTACT_INITIATE",
  DELETE_CONTACT_SUCCESS: "DELELTE_CONTACT_SUCCESS",
  DELETE_CONTACT_FAILURE: "DELELTE_CONTACT_FAILURE",

  CREATE_PROFILE_INITIATE: "CREATE_PROFILE_INITIATE",
  CREATE_PROFILE_SUCCESS: "CREATE_PROFILE_SUCCESS",
  CREATE_PROFILE_FAILURE: "CREATE_PROFILE_FAILURE",

  UPDATE_PROFILE_INITIATE: "UPDATE_PROFILE_INITIATE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",

  GET_PROFILE_INITIATE: "GET_PROFILE_INITIATE",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILURE: "GET_PROFILE_FAILURE",

  GET_MESSAGE_COUNT_INITIATE: "GET_MESSAGE_COUNT_INITIATE",
  GET_MESSAGE_COUNT_SUCCESS: "GET_MESSAGE_COUNT_SUCCESS",
  GET_MESSAGE_COUNT_FAILURE: "GET_MESSAGE_COUNT_FAILURE",

  UPDATE_MESSAGE_COUNT_INITIATE: "UPDATE_MESSAGE_COUNT_INITIATE",
  UPDATE_MESSAGE_COUNT_SUCCESS: "UPDATE_MESSAGE_COUNT_SUCCESS",
  UPDATE_MESSAGE_COUNT_FAILURE: "UPDATE_MESSAGE_COUNT_FAILURE",
};

export const projectConstantDK = {
  GET_NEW_PROJECT_INITIATE: "GET_NEW_PROJECT_INITIATE",
  GET_NEW_PROJECT_SUCCESS: "GET_NEW_PROJECT_SUCCESS",
  GET_NEW_PROJECT_FAILURE: "GET_NEW_PROJECT_INITIATE",

  CREATE_NEW_PROJECT_INITIATE: "CREATE_NEW_PROJECT_INITIATE",
  CREATE_NEW_PROJECT_SUCCESS: "CREATE_NEW_PROJECT_SUCCESS",
  CREATE_NEW_PROJECT_FAILURE: "CREATE_NEW_PROJECT_INITIATE",

  GET_ALL_PROJECT_LIST_INITIATE: "GET_ALL_PROJECT_LIST_INITIATE",
  GET_ALL_PROJECT_LIST_SUCCESS: "GET_ALL_PROJECT_LIST_SUCCESS",
  GET_ALL_PROJECT_LIST_FAILURE: "GET_ALL_PROJECT_LIST_INITIATE",

  UPDATE_PROJECT_INITIATE: "UPDATE_PROJECT_INITIATE",
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",
  UPDATE_PROJECT_FAILURE: "UPDATE_PROJECT_INITIATE",

  CREATE_PROJECT_ENTITY_INITIATE: "CREATE_PROJECT_ENTITY_INITIATE",
  CREATE_PROJECT_ENTITY_SUCCESS: "CREATE_PROJECT_ENTITY_SUCCESS",
  CREATE_PROJECT_ENTITY_FAILURE: "CREATE_PROJECT_ENTITY_INITIATE",

  UPDATE_PROJECT_ENTITY_INITIATE: "UPDATE_PROJECT_ENTITY_INITIATE",
  UPDATE_PROJECT_ENTITY_SUCCESS: "UPDATE_PROJECT_ENTITY_SUCCESS",
  UPDATE_PROJECT_ENTITY_FAILURE: "UPDATE_PROJECT_ENTITY_INITIATE",

  GET_PROJECT_ENTITY_INITIATE: "GET_PROJECT_ENTITY_INITIATE",
  GET_PROJECT_ENTITY_SUCCESS: "GET_PROJECT_ENTITY_SUCCESS",
  GET_PROJECT_ENTITY_FAILURE: "GET_PROJECT_ENTITY_INITIATE",
};

export const multifamilyJobConstantDK = {
  CREATE_MULTIFAMILY_INITIATE: "CREATE_MULTIFAMIL_INITIATE",
  CREATE_MULTIFAMILY_SUCCESS: "CREATE_MULTIFAMIL_SUCCESS",
  CREATE_MULTIFAMILY_FAILURE: "CREATE_MULTIFAMIL_INITIATE",

  UPDATE_MULTIFAMILY_INITIATE: "UPDATE_MULTIFAMILY_INITIATE",
  UPDATE_MULTIFAMILY_SUCCESS: "UPDATE_MULTIFAMILY_SUCCESS",
  UPDATE_MULTIFAMILY_FAILURE: "UPDATE_MULTIFAMILY_INITIATE",

  GET_MULTIFAMILY_INITIATE: "GET_MULTIFAMILY_INITIATE",
  GET_MULTIFAMILY_SUCCESS: "GET_MULTIFAMILY_SUCCESS",
  GET_MULTIFAMILY_FAILURE: "GET_MULTIFAMILY_INITIATE",

  MARK_AS_COMPLETE_MULTIFAMILY_INITIATE:
    "MARK_AS_COMPLETE_MULTIFAMILY_INITIATE",
  MARK_AS_COMPLETE_MULTIFAMILY_SUCCESS: "MARK_AS_COMPLETE_MULTIFAMILY_SUCCESS",
  MARK_AS_COMPLETE_MULTIFAMILY_FAILURE: "MARK_AS_COMPLETE_MULTIFAMILY_INITIATE",

  CREATE_BLOCK_UNIT_INITIATE: "CREATE_BLOCK_UNIT_INITIATE",
  CREATE_BLOCK_UNIT_SUCCESS: "CREATE_BLOCK_UNIT_SUCCESS",
  CREATE_BLOCK_UNIT_FAILURE: "CREATE_BLOCK_UNIT_INITIATE",

  CREATE_BUILDING_INITIATE: "CREATE_BUILDING_INITIATE",
  CREATE_BUILDING_SUCCESS: "CREATE_BUILDING_SUCCESS",
  CREATE_BUILDING_FAILURE: "CREATE_BUILDING_INITIATE",
};

export const DropdownConstant = {
  USER_LIST_INITIATE: "USER_LIST_INITIATE",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_FAILURE: "USER_LIST_FAILURE",

  DOCUMENT_KEY_LIST_INITIATE: "DOCUMENT_KEY_LIST_INITIATE",
  DOCUMENT_KEY_LIST_SUCCESS: "DOCUMENT_KEY_LIST_SUCCESS",
  DOCUMENT_KEY_LIST_FAILURE: "DOCUMENT_KEY_LIST_FAILURE",

  ROLE_LIST_INITIATE: "ROLE_LIST_INITIATE",
  ROLE_LIST_SUCCESS: "ROLE_LIST_SUCCESS",
  ROLE_LIST_FAILURE: "ROLE_LIST_FAILURE",

  COST_CODE_LIST_INITIATE: "COST_CODE_LIST_INITIATE",
  COST_CODE_LIST_SUCCESS: "COST_CODE_LIST_SUCCESS",
  COST_CODE_LIST_FAILURE: "COST_CODE_LIST_FAILURE",

  ADMIN_USER_LIST_INITIATE: "ADMIN_USER_LIST_INITIATE",
  ADMIN_USER_LIST_SUCCESS: "ADMIN_USER_LIST_SUCCESS",
  ADMIN_USER_LIST_FAILURE: "ADMIN_USER_LIST_FAILURE",

  GET_FILE_URL_INITIATE: "GET_FILE_URL_INITIATE",
  GET_FILE_URL_SUCCESS: "GET_FILE_URL_SUCCESS",
  GET_FILE_URL_FAILURE: "GET_FILE_URL_FAILURE",
};

export const AdminSalesConstant = {
  CREATE_GENERAL_SETTING_INITIATE: "CREATE_GENERAL_SETTING_INITIATE",
  CREATE_GENERAL_SETTING_SUCCESS: "CREATE_GENERAL_SETTING_SUCCESS",
  CREATE_GENERAL_SETTING_FAILURE: "CREATE_GENERAL_SETTING_FAILURE",

  UPDATE_GENERAL_SETTING_INITIATE: "UPDATE_GENERAL_SETTING_INITIATE",
  UPDATE_GENERAL_SETTING_SUCCESS: "UPDATE_GENERAL_SETTING_SUCCESS",
  UPDATE_GENERAL_SETTING_FAILURE: "UPDATE_GENERAL_SETTING_FAILURE",

  GET_GENERAL_SETTING_INITIATE: "GET_GENERAL_SETTING_INITIATE",
  GET_GENERAL_SETTING_SUCCESS: "GET_GENERAL_SETTING_SUCCESS",
  GET_GENERAL_SETTING_FAILURE: "GET_GENERAL_SETTING_FAILURE",
};

export const AdminMenuSetupConstant = {
  CREATE_SIDE_MENU_INITIATE: "CREATE_SIDE_MENU_INITIATE",
  CREATE_SIDE_MENU_SUCCESS: "CREATE_SIDE_MENU_SUCCESS",
  CREATE_SIDE_MENU_FAILURE: "CREATE_SIDE_MENU_FAILURE",

  UPDATE_SIDE_MENU_INITIATE: "UPDATE_SIDE_MENU_INITIATE",
  UPDATE_SIDE_MENU_SUCCESS: "UPDATE_SIDE_MENU_SUCCESS",
  UPDATE_SIDE_MENU_FAILURE: "UPDATE_SIDE_MENU_FAILURE",

  GET_SIDE_MENU_INITIATE: "GET_SIDE_MENU_INITIATE",
  GET_SIDE_MENU_SUCCESS: "GET_SIDE_MENU_SUCCESS",
  GET_SIDE_MENU_FAILURE: "GET_SIDE_MENU_FAILURE",

  CREATE_JOB_MENU_INITIATE: "CREATE_JOB_MENU_INITIATE",
  CREATE_JOB_MENU_SUCCESS: "CREATE_JOB_MENU_SUCCESS",
  CREATE_JOB_MENU_FAILURE: "CREATE_JOB_MENU_FAILURE",

  UPDATE_JOB_MENU_INITIATE: "UPDATE_JOB_MENU_INITIATE",
  UPDATE_JOB_MENU_SUCCESS: "UPDATE_JOB_MENU_SUCCESS",
  UPDATE_JOB_MENU_FAILURE: "UPDATE_JOB_MENU_FAILURE",

  GET_JOB_MENU_INITIATE: "GET_JOB_MENU_INITIATE",
  GET_JOB_MENU_SUCCESS: "GET_JOB_MENU_SUCCESS",
  GET_JOB_MENU_FAILURE: "GET_JOB_MENU_FAILURE",
};

export const AdminSigningAuthorityConstant = {
  CREATE_AUTHORITY_INITIATE: "CREATE_AUTHORITY_INITIATE",
  CREATE_AUTHORITY_SUCCESS: "CREATE_AUTHORITY_SUCCESS",
  CREATE_AUTHORITY_FAILURE: "CREATE_AUTHORITY_FAILURE",

  GET_AUTHORITY_INITIATE: "GET_AUTHORITY_INITIATE",
  GET_AUTHORITY_SUCCESS: "GET_AUTHORITY_SUCCESS",
  GET_AUTHORITY_FAILURE: "GET_AUTHORITY_FAILURE",

  DELETE_AUTHORITY_INITIATE: "DELETE_AUTHORITY_INITIATE",
  DELETE_AUTHORITY_SUCCESS: "DELETE_AUTHORITY_SUCCESS",
  DELETE_AUTHORITY_FAILURE: "DELETE_AUTHORITY_FAILURE",
};

export const AdminAccountingConstant = {
  GET_TRANSACTION_ACCOUNT_INITIATE: "GET_TRANSACTION_ACCOUNT_INITIATE",
  GET_TRANSACTION_ACCOUNT_SUCCESS: "GET_TRANSACTION_ACCOUNT_SUCCESS",
  GET_TRANSACTION_ACCOUNT_FAILURE: "GET_TRANSACTION_ACCOUNT_FAILURE",

  UPDATE_TRANSACTION_SETTING_INITIATE: "UPDATE_TRANSACTION_SETTING_INITIATE",
  UPDATE_TRANSACTION_SETTING_SUCCESS: "UPDATE_TRANSACTION_SETTING_SUCCESS",
  UPDATE_TRANSACTION_SETTING_FAILURE: "UPDATE_TRANSACTION_SETTING_FAILURE",

  UPDATE_ACCOUNT_SETTING_INITIATE: "UPDATE_ACCOUNT_SETTING_INITIATE",
  UPDATE_ACCOUNT_SETTING_SUCCESS: "UPDATE_ACCOUNT_SETTING_SUCCESS",
  UPDATE_ACCOUNT_SETTING_FAILURE: "UPDATE_ACCOUNT_SETTING_FAILURE",

  GET_ACCOUNT_SYSTEM_POSTING_INITIATE: "GET_ACCOUNT_SYSTEM_POSTING_INITIATE",
  GET_ACCOUNT_SYSTEM_POSTING_SUCCESS: "GET_ACCOUNT_SYSTEM_POSTING_SUCCESS",
  GET_ACCOUNT_SYSTEM_POSTING_FAILURE: "GET_ACCOUNT_SYSTEM_POSTING_FAILURE",

  UPDATE_SALES_POSTING_INITIATE: "UPDATE_SALES_POSTING_INITIATE",
  UPDATE_SALES_POSTING_SUCCESS: "UPDATE_SALES_POSTING_SUCCESS",
  UPDATE_SALES_POSTING_FAILURE: "UPDATE_SALES_POSTING_FAILURE",

  UPDATE_SYSTEM_POSTING_INITIATE: "UPDATE_SYSTEM_POSTING_INITIATE",
  UPDATE_SYSTEM_POSTING_SUCCESS: "UPDATE_SYSTEM_POSTING_SUCCESS",
  UPDATE_SYSTEM_POSTING_FAILURE: "UPDATE_SYSTEM_POSTING_FAILURE",

  UPDATE_ACCOUNT_POSTING_INITIATE: "UPDATE_ACCOUNT_POSTING_INITIATE",
  UPDATE_ACCOUNT_POSTING_SUCCESS: "UPDATE_ACCOUNT_POSTING_SUCCESS",
  UPDATE_ACCOUNT_POSTING_FAILURE: "UPDATE_ACCOUNT_POSTING_FAILURE",
};

export const constructionFileMangerConstant = {
  CREATE_FOLDER_INITIATE: "CREATE_FOLDER_INITIATE",
  CREATE_FOLDER_SUCCESS: "CREATE_FOLDER_SUCCESS",
  CREATE_FOLDER_FAILURE: "CREATE_FOLDER_FAILURE",

  ALL_FOLDER_HISTORY_INITIATE: "ALL_FOLDER_HISTORY_INITIATE",
  ALL_FOLDER_HISTORY_SUCCESS: "ALL_FOLDER_HISTORY_SUCCESS",
  ALL_FOLDER_HISTORY_FAILURE: "ALL_FOLDER_HISTORY_FAILURE",

  ALL_FOLDER_LIST_INITIATE: "ALL_FOLDER_LIST_INITIATE",
  ALL_FOLDER_LIST_SUCCESS: "ALL_FOLDER_LIST_SUCCESS",
  ALL_FOLDER_LIST_FAILURE: "ALL_FOLDER_LIST_FAILURE",

  DELETE_FOLDER_INITIATE: "DELETE_FOLDER_INITIATE",
  DELETE_FOLDER_SUCCESS: "DELETE_FOLDER_SUCCESS",
  DELETE_FOLDER_FAILURE: "DELETE_FOLDER_FAILURE",

  SEARCH_FOLDER_BYNAME_INITIATE: "SEARCH_FOLDER_BYNAME_INITIATE",
  SEARCH_FOLDER_BYNAME_SUCCESS: "SEARCH_FOLDER_BYNAME_SUCCESS",
  SEARCH_FOLDER_BYNAME_FAILURE: "SEARCH_FOLDER_BYNAME_FAILURE",

  MANAGE_SIGNATURE_UPLOAD_INITIATE: "MANAGE_SIGNATURE_UPLOAD_INITIATE",
  MANAGE_SIGNATURE_UPLOAD_SUCCESS: "MANAGE_SIGNATURE_UPLOAD_SUCCESS",
  MANAGE_SIGNATURE_UPLOAD_FAILURE: "MANAGE_SIGNATURE_UPLOAD_FAILURE",

  CREATE_DOCUMENTATION_INITIATE: "CREATE_DOCUMENTATION_INITIATE",
  CREATE_DOCUMENTATION_SUCCESS: "CREATE_DOCUMENTATION_SUCCESS",
  CREATE_DOCUMENTATION_FAILURE: "CREATE_DOCUMENTATION_FAILURE",

  LIST_DOCUMENTATION_INITIATE: "LIST_DOCUMENTATION_INITIATE",
  LIST_DOCUMENTATION_SUCCESS: "LIST_DOCUMENTATION_SUCCESS",
  LIST_DOCUMENTATION_FAILURE: "LIST_DOCUMENTATION_FAILURE",

  SEARCH_LIST_DOCUMENTATION_INITIATE: "SEARCH_LIST_DOCUMENTATION_INITIATE",
  SEARCH_LIST_DOCUMENTATION_SUCCESS: "SEARCH_LIST_DOCUMENTATION_SUCCESS",
  SEARCH_LIST_DOCUMENTATION_FAILURE: "SEARCH_LIST_DOCUMENTATION_FAILURE",

  REMOVE_DOCUMENTATION_INITIATE: "REMOVE_DOCUMENTATION_INITIATE",
  REMOVE_DOCUMENTATION_SUCCESS: "REMOVE_DOCUMENTATION_SUCCESS",
  REMOVE_DOCUMENTATION_FAILURE: "REMOVE_DOCUMENTATION_FAILURE",

  SHARE_CLIENET_DOCUMENTATION_INITIATE: "SHARE_CLIENET_DOCUMENTATION_INITIATE",
  SHARE_CLIENET_DOCUMENTATION_SUCCESS: "SHARE_CLIENET_DOCUMENTATION_SUCCESS",
  SHARE_CLIENET_DOCUMENTATION_FAILURE: "SHARE_CLIENET_DOCUMENTATION_FAILURE",

  SEND_FOR_APPROVAL_INITIATE: "SEND_FOR_APPROVAL_INITIATE",
  SEND_FOR_APPROVAL_SUCCESS: "SEND_FOR_APPROVAL_SUCCESS",
  SEND_FOR_APPROVAL_FAILURE: "SEND_FOR_APPROVAL_FAILURE",

  UPLOAD_TEMPLATE_MAIN_PICTURE_INITIATE:
    "UPLOAD_TEMPLATE_MAIN_PICTURE_INITIATE",
  UPLOAD_TEMPLATE_MAIN_PICTURE_SUCCESS: "UPLOAD_TEMPLATE_MAIN_PICTURE_SUCCESS",
  UPLOAD_TEMPLATE_MAIN_PICTURE_FAILURE: "UPLOAD_TEMPLATE_MAIN_PICTURE_FAILURE",

  VIEW_DOCUMENTATION_INITIATE: "VIEW_DOCUMENTATION_INITIATE",
  VIEW_DOCUMENTATION_SUCCESS: "VIEW_DOCUMENTATION_SUCCESS",
  VIEW_DOCUMENTATION_FAILURE: "VIEW_DOCUMENTATION_FAILURE",

  DOCUMENTATION_APPROVAL_INITIATE: "DOCUMENTATION_APPROVAL_INITIATE",
  DOCUMENTATION_APPROVAL_SUCCESS: "DOCUMENTATION_APPROVAL_SUCCESS",
  DOCUMENTATION_APPROVAL_FAILURE: "DOCUMENTATION_APPROVAL_FAILURE",

  PENDING_FILE_EVENT_LIST_INITIATE: "PENDING_FILE_EVENT_LIST_INITIATE",
  PENDING_FILE_EVENT_LIST_SUCCESS: "PENDING_FILE_EVENT_LIST_SUCCESS",
  PENDING_FILE_EVENT_LIST_FAILURE: "PENDING_FILE_EVENT_LIST_FAILURE",

  GET_ALL_TAGS_LIST_INITIATE: "GET_ALL_TAGS_LIST_INITIATE",
  GET_ALL_TAGS_LIST_SUCCESS: "GET_ALL_TAGS_LIST_SUCCESS",
  GET_ALL_TAGS_LIST_FAILURE: "GET_ALL_TAGS_LIST_FAILURE",

  EDIT_DOCUMENTATION_INITIATE: "EDIT_DOCUMENTATION_INITIATE",
  EDIT_DOCUMENTATION_SUCCESS: "EDIT_DOCUMENTATION_SUCCESS",
  EDIT_DOCUMENTATION_FAILURE: "EDIT_DOCUMENTATION_FAILURE",

  FILE_HISTORY_LIST_INITIATE: "FILE_HISTORY_LIST_INITIATE",
  FILE_HISTORY_LIST_SUCCESS: "FILE_HISTORY_LIST_SUCCESS",
  FILE_HISTORY_LIST_FAILURE: "FILE_HISTORY_LIST_FAILURE",

  HTML_CONVERTER_INITIATE:"HTML_CONVERTER_INITIATE",
  HTML_CONVERTER_SUCCESS: "HTML_CONVERTER_SUCCESS",
  HTML_CONVERTER_FAILURE: "HTML_CONVERTER_FAILURE",

  RELEASE_FOLDER_FILES_INITIATE:"RELEASE_FOLDER_FILES_INITIATE",
  RELEASE_FOLDER_FILES_SUCCESS: "RELEASE_FOLDER_FILES_SUCCESS",
  RELEASE_FOLDER_FILES_FAILURE: "RELEASE_FOLDER_FILES_FAILURE",

  CONSTRUCTION_FILES_DOCS_UPDATE_INITIATE:"CONSTRUCTION_FILES_DOCS_UPDATE_INITIATE",
  CONSTRUCTION_FILES_DOCS_UPDATE_SUCCESS: "CONSTRUCTION_FILES_DOCS_UPDATE_SUCCESS",
  CONSTRUCTION_FILES_DOCS_UPDATE_FAILURE: "CONSTRUCTION_FILES_DOCS_UPDATE_FAILURE",
};

export const AdminNotificationConstant = {
  GET_NOTIFICATION_HEADING_INITIATE: "GET_NOTIFICATION_HEADING_INITIATE",
  GET_NOTIFICATION_HEADING_SUCCESS: "GET_NOTIFICATION_HEADING_SUCCESS",
  GET_NOTIFICATION_HEADING_FAILURE: "GET_NOTIFICATION_HEADING_FAILURE",

  GET_NOTIFICATION_INITIATE: "GET_NOTIFICATION_INITIATE",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAILURE: "GET_NOTIFICATION_FAILURE",

  GET_NESTED_NOTIFICATION_INITIATE: "GET_NESTED_NOTIFICATION_INITIATE",
  GET_NESTED_NOTIFICATION_SUCCESS: "GET_NESTED_NOTIFICATION_SUCCESS",
  GET_NESTED_NOTIFICATION_FAILURE: "GET_NESTED_NOTIFICATION_FAILURE",

  GET_NOTIFICATION_TEMPLATE_INITIATE: "GET_NOTIFICATION_TEMPLATE_INITIATE",
  GET_NOTIFICATION_TEMPLATE_SUCCESS: "GET_NOTIFICATION_TEMPLATE_SUCCESS",
  GET_NOTIFICATION_TEMPLATE_FAILURE: "GET_NOTIFICATION_TEMPLATE_FAILURE",

  EDIT_NOTIFICATION_INITIATE: "EDIT_NOTIFICATION_INITIATE",
  EDIT_NOTIFICATION_SUCCESS: "EDIT_NOTIFICATION_SUCCESS",
  EDIT_NOTIFICATION_FAILURE: "EDIT_NOTIFICATION_FAILURE",

  EDIT_NOTIFICATION_STATUS_INITIATE: "EDIT_NOTIFICATION_STATUS_INITIATE",
  EDIT_NOTIFICATION_STATUS_SUCCESS: "EDIT_NOTIFICATION_STATUS_SUCCESS",
  EDIT_NOTIFICATION_STATUS_FAILURE: "EDIT_NOTIFICATION_STATUS_FAILURE",

  UPDATE_ORIGINATING_USER_INITIATE: "UPDATE_ORIGINATING_USER_INITIATE",
  UPDATE_ORIGINATING_USER_SUCCESS: "UPDATE_ORIGINATING_USER_SUCCESS",
  UPDATE_ORIGINATING_USER_FAILURE: "UPDATE_ORIGINATING_USER_FAILURE",

  ADD_ASSIGN_USER_INITIATE: "ADD_ASSIGN_USER_INITIATE",
  ADD_ASSIGN_USER_SUCCESS: "ADD_ASSIGN_USER_SUCCESS",
  ADD_ASSIGN_USER_FAILURE: "ADD_ASSIGN_USER_FAILURE",

  ADD_ASSIGN_ROLES_INITIATE: "ADD_ASSIGN_ROLES_INITIATE",
  ADD_ASSIGN_ROLES_SUCCESS: "ADD_ASSIGN_ROLES_SUCCESS",
  ADD_ASSIGN_ROLES_FAILURE: "ADD_ASSIGN_ROLES_FAILURE",

  CREATE_NOTIFICATION_INITIATE: "CREATE_NOTIFICATION_INITIATE",
  CREATE_NOTIFICATION_SUCCESS: "CREATE_NOTIFICATION_SUCCESS",
  CREATE_NOTIFICATION_FAILURE: "CREATE_NOTIFICATION_FAILURE",

  ADD_NOTIFICATION_EVENT_INITIATE: "ADD_NOTIFICATION_EVENT_INITIATE",
  ADD_NOTIFICATION_EVENT_SUCCESS: "ADD_NOTIFICATION_EVENT_SUCCESS",
  ADD_NOTIFICATION_EVENT_FAILURE: "ADD_NOTIFICATION_EVENT_FAILURE",

  CREATE_NESTED_NOTIFICATION__INITIATE: "CREATE_NESTED_NOTIFICATION_INITIATE",
  CREATE_NESTED_NOTIFICATION__SUCCESS: "CREATE_NESTED_NOTIFICATION_SUCCESS",
  CREATE_NESTED_NOTIFICATION__FAILURE: "CREATE_NESTED_NOTIFICATION_FAILURE",

  QUICK_BOOK_INITIATE: "QUICK_BOOK_INITIATE",
  QUICK_BOOK_SUCCESS: "QUICK_BOOK_SUCCESS",
  QUICK_BOOK_FAILURE: "QUICK_BOOK_FAILURE",
};

export const adminUserAction = {
  CREATE_USER_INITIATE: "CREATE_USER_INITIATE",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

  USER_LIST_INITIATE: "USER_LIST_INITIATE",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_FAILURE: "USER_LIST_FAILURE",

  USER_DETAILS_INITIATE: "USER_DETAILS_INITIATE",
  USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
  USER_DETAILS_FAILURE: "USER_DETAILS_FAILURE",

  USER_DELETE_INITIATE: "USER_DELETE_INITIATE",
  USER_DELETE_SUCCESS: "USER_DELETE_SUCCESS",
  USER_DELETE_FAILURE: "USER_DELETE_FAILURE",

  LOCK_USER_INITIATE: "LOCK_USER_INITIATE",
  LOCK_USER_SUCCESS: "LOCK_USER_SUCCESS",
  LOCK_USER_FAILURE: "LOCK_USER_FAILURE",

  UNLOCK_USER_INITIATE: "UNLOCK_USER_INITIATE",
  UNLOCK_USER_SUCCESS: "UNLOCK_USER_SUCCESS",
  UNLOCK_USER_FAILURE: "UNLOCK_USER_FAILURE",

  ROLE_LIST_INITIATE: "ROLE_LIST_INITIATE",
  ROLE_LIST_SUCCESS: "ROLE_LIST_SUCCESS",
  ROLE_LIST_FAILURE: "ROLE_LIST_FAILURE",

  USER_HISTORY_INITIATE: "USER_HISTORY_INITIATE",
  USER_HISTORY_SUCCESS: "USER_HISTORY_SUCCESS",
  USER_HISTORY_FAILURE: "USER_HISTORY_FAILURE",

  USER_UPDATE_INITIATE: "USER_UPDATE_INITIATE",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  USER_UPDATE_FAILURE: "USER_UPDATE_FAILURE",

  CREATE_ROLE_INITIATE: "CREATE_ROLE_INITIATE",
  CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
  CREATE_ROLE_FAILURE: "CREATE_ROLE_FAILURE",

  UPDATE_ROLE_INITIATE: "UPDATE_ROLE_INITIATE",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  UPDATE_ROLE_FAILURE: "UPDATE_ROLE_FAILURE",

  DELETE_ROLE_INITIATE: "DELETE_ROLE_INITIATE",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  DELETE_ROLE_FAILURE: "DELETE_ROLE_FAILURE",

  IBUILD_USER_RESET_PASSWORD_INITIATE: "IBUILD_USER_RESET_PASSWORD_INITIATE",
  IBUILD_USER_RESET_PASSWORD_SUCCESS: "IBUILD_USER_RESET_PASSWORD_SUCCESS",
  IBUILD_USER_RESET_PASSWORD_FAILURE: "IBUILD_USER_RESET_PASSWORD_INITIATE",

  ROLES_FORM_INITIATE: "ROLES_FORM_INITIATE",
  ROLES_FORM_SUCCESS: "ROLES_FORM_SUCCESS",
  ROLES_FORM_FAILURE: "ROLES_FORM_FAILURE",
};

export const adminPlanConstant = {
  PLAN_LIST_INITIATE: "PLAN_LIST_INITIATE",
  PLAN_LIST_SUCCESS: "PLAN_LIST_SUCCESS",
  PLAN_LIST_FAILURE: "PLAN_LIST_FAILURE",

  CREATE_PLAN_INITIATE: "CREATE_PLAN_INITIATE",
  CREATE_PLAN_SUCCESS: "CREATE_PLAN_SUCCESS",
  CREATE_PLAN_FAILURE: "CREATE_PLAN_FAILURE",

  UPDATE_PLAN_INITIATE: "UPDATE_PLAN_INITIATE",
  UPDATE_PLAN_SUCCESS: "UPDATE_PLAN_SUCCESS",
  UPDATE_PLAN_FAILURE: "UPDATE_PLAN_FAILURE",

  DELETE_PLAN_INITIATE: "DELETE_PLAN_INITIATE",
  DELETE_PLAN_SUCCESS: "DELETE_PLAN_SUCCESS",
  DELETE_PLAN_FAILURE: "DELETE_PLAN_FAILURE",

  GET_FEATURE_LIST_INITIATE: "GET_FEATURE_LIST_INITIATE",
  GET_FEATURE_LIST_SUCCESS: "GET_FEATURE_LIST_SUCCESS",
  GET_FEATURE_LIST_FAILURE: "GET_FEATURE_LIST_FAILURE",

  ACTIVATE_PLAN_INITIATE: "ACTIVATE_PLAN_INITIATE",
  ACTIVATE_PLAN_SUCCESS: "ACTIVATE_PLAN_SUCCESS",
  ACTIVATE_PLAN_FAILURE: "ACTIVATE_PLAN_FAILURE",

  DEACTIVATE_PLAN_INITIATE: "DEACTIVATE_PLAN_INITIATE",
  DEACTIVATE_PLAN_SUCCESS: "DEACTIVATE_PLAN_SUCCESS",
  DEACTIVATE_PLAN_FAILURE: "DEACTIVATE_PLAN_FAILURE",
};

export const accountingAction = {
  GET_ALL_CONTRACT_LIST_INITIATE: "GET_ALL_CONTRACT_LIST_INITIATE",
  GET_ALL_CONTRACT_LIST_SUCCESS: "GET_ALL_CONTRACT_LIST_SUCCESS",
  GET_ALL_CONTRACT_LIST_FAILURE: "GET_ALL_CONTRACT_LIST_FAILURE",

  UPDATE_CONTRACT_ASSIGN_UNIT_LIST_INITIATE:
    "UPDATE_CONTRACT_ASSIGN_UNIT_INITIATE",
  UPDATE_CONTRACT_ASSIGN_UNIT_LIST_SUCCESS:
    "UPDATE_CONTRACT_ASSIGN_UNIT_SUCCESS",
  UPDATE_CONTRACT_ASSIGN_UNIT_LIST_FAILURE:
    "UPDATE_CONTRACT_ASSIGN_UNIT_FAILURE",

  CREATE_TRANSACTON_INITIATE: "CREATE_TRANSACTON_INITIATE",
  CREATE_TRANSACTON_SUCCESS: "CREATE_TRANSACTON_SUCCESS",
  CREATE_TRANSACTON_FAILURE: "CREATE_TRANSACTON_FAILURE",

  TRANSACTON_LIST_INITIATE: "TRANSACTON_LIST_INITIATE",
  TRANSACTON_LIST_SUCCESS: "TRANSACTON_LIST_SUCCESS",
  TRANSACTON_LIST_FAILURE: "TRANSACTON_LIST_FAILURE",

  CHANGE_TRANSACTION_STATUS_TO_VOID_INITIATE:
    "CHANGE_TRANSACTION_STATUS_TO_VOID_INITIATE",
  CHANGE_TRANSACTION_STATUS_TO_VOID_SUCCESS:
    "CHANGE_TRANSACTION_STATUS_TO_VOID_SUCCESS",
  CHANGE_TRANSACTION_STATUS_TO_VOID_FAILURE:
    "CHANGE_TRANSACTION_STATUS_TO_VOID_FAILURE",

  ADD_SUPPORTING_DOCS_IN_TRANSACTION_INITIATE:
    "ADD_SUPPORTING_DOCS_IN_TRANSACTION_INITIATE",
  ADD_SUPPORTING_DOCS_IN_TRANSACTION_SUCCESS:
    "ADD_SUPPORTING_DOCS_IN_TRANSACTION_SUCCESS",
  ADD_SUPPORTING_DOCS_IN_TRANSACTION_FAILURE:
    "ADD_SUPPORTING_DOCS_IN_TRANSACTION_FAILURE",

  GET_ALL_PRIMARY_CLIENTS_INITIATE: "GET_ALL_PRIMARY_CLIENTS_INITIATE",
  GET_ALL_PRIMARY_CLIENTS_SUCCESS: "GET_ALL_PRIMARY_CLIENTS_SUCCESS",
  GET_ALL_PRIMARY_CLIENTS_FAILURE: "GET_ALL_PRIMARY_CLIENTS_FAILURE",

  CREATE_SCHEDULE_PAYMENT_INITIATE: "CREATE_SCHEDULE_PAYMENT_INITIATE",
  CREATE_SCHEDULE_PAYMENT_SUCCESS: "CREATE_SCHEDULE_PAYMENT_SUCCESS",
  CREATE_SCHEDULE_PAYMENT_FAILURE: "CREATE_SCHEDULE_PAYMENT_FAILURE",

  GET_SCHEDULE_PAYMENT_INITIATE: "GET_SCHEDULE_PAYMENT_INITIATE",
  GET_SCHEDULE_PAYMENT_SUCCESS: "GET_SCHEDULE_PAYMENT_SUCCESS",
  GET_SCHEDULE_PAYMENT_FAILURE: "GET_SCHEDULE_PAYMENT_FAILURE",

  REMOVE_SCHEDULE_PAYMENT_INITIATE: "REMOVE_SCHEDULE_PAYMENT_INITIATE",
  REMOVE_SCHEDULE_PAYMENT_SUCCESS: "REMOVE_SCHEDULE_PAYMENT_SUCCESS",
  REMOVE_SCHEDULE_PAYMENT_FAILURE: "REMOVE_SCHEDULE_PAYMENT_FAILURE",

  UPDATE_SCHEDULE_PAYMENT_INITIATE: "UPDATE_SCHEDULE_PAYMENT_INITIATE",
  UPDATE_SCHEDULE_PAYMENT_SUCCESS: "UPDATE_SCHEDULE_PAYMENT_SUCCESS",
  UPDATE_SCHEDULE_PAYMENT_FAILURE: "UPDATE_SCHEDULE_PAYMENT_FAILURE",

  SO_HISTORY_LIST_INITIATE: "SO_HISTORY_LIST_INITIATE",
  SO_HISTORY_LIST_SUCCESS: "SO_HISTORY_LIST_SUCCESS",
  SO_HISTORY_LIST_FAILURE: "SO_HISTORY_LIST_FAILURE",

  GET_FOLLOW_UPS_LIST_INITIATE: "GET_FOLLOW_UPS_LIST_INITIATE",
  GET_FOLLOW_UPS_LIST_SUCCESS: "GET_FOLLOW_UPS_LIST_SUCCESS",
  GET_FOLLOW_UPS_LIST_FAILURE: "GET_FOLLOW_UPS_LIST_FAILURE",

  DELETE_FOLLOW_UPS_INITIATE: "DELETE_FOLLOW_UPS_INITIATE",
  DELETE_FOLLOW_UPS_SUCCESS: "DELETE_FOLLOW_UPS_SUCCESS",
  DELETE_FOLLOW_UPS_FAILURE: "DELETE_FOLLOW_UPS_FAILURE",

  UPDATE_FOLLOW_UPS_REMINDER_INITIATE: "UPDATE_FOLLOW_UPS_REMINDER_INITIATE",
  UPDATE_FOLLOW_UPS_REMINDER_SUCCESS: "UPDATE_FOLLOW_UPS_REMINDER_SUCCESS",
  UPDATE_FOLLOW_UPS_REMINDER_FAILURE: "UPDATE_FOLLOW_UPS_REMINDER_FAILURE",

  SEND_FOLLOW_UP_INVITATION_INITIATE: "SEND_FOLLOW_UP_INVITATION_INITIATE",
  SEND_FOLLOW_UP_INVITATION_SUCCESS: "SEND_FOLLOW_UP_INVITATION_SUCCESS",
  SEND_FOLLOW_UP_INVITATION_FAILURE: "SEND_FOLLOW_UP_INVITATION_FAILURE",

  UPDATE_FOLLOW_MEMBER_INITIATE: "UPDATE_FOLLOW_MEMBER_INITIATE",
  UPDATE_FOLLOW_MEMBER_SUCCESS: "UPDATE_FOLLOW_MEMBER_SUCCESS",
  UPDATE_FOLLOW_MEMBER_FAILURE: "UPDATE_FOLLOW_MEMBER_FAILURE",

  GET_ESTIMATION_DOCUMENT_LIST_INITIATE:
    "GET_ESTIMATION_DOCUMENT_LIST_INITIATE",
  GET_ESTIMATION_DOCUMENT_LIST_SUCCESS: "GET_ESTIMATION_DOCUMENT_LIST_SUCCESS",
  GET_ESTIMATION_DOCUMENT_LIST_FAILURE: "GET_ESTIMATION_DOCUMENT_LIST_FAILURE",

  CANCEL_ESTIMATION_DOCUMENT_INITIATE: "CANCEL_ESTIMATION_DOCUMENT_INITIATE",
  CANCEL_ESTIMATION_DOCUMENT_SUCCESS: "CANCEL_ESTIMATION_DOCUMENT_SUCCESS",
  CANCEL_ESTIMATION_DOCUMENT_FAILURE: "CANCEL_ESTIMATION_DOCUMENT_FAILURE",

  GET_SOA_DETAILS_INITIATE: "GET_SOA_DETAILS_INITIATE",
  GET_SOA_DETAILS_SUCCESS: "GET_SOA_DETAILS_SUCCESS",
  GET_SOA_DETAILS_FAILURE: "GET_SOA_DETAILS_FAILURE",

  DECLINE_AND_REVERT_SOA_INITIATE: "DECLINE_AND_REVERT_SOA_INITIATE",
  DECLINE_AND_REVERT_SOA_SUCCESS: "DECLINE_AND_REVERT_SOA_SUCCESS",
  DECLINE_AND_REVERT_SOA_FAILURE: "DECLINE_AND_REVERT_SOA_FAILURE",

  ADD_FOLLOW_ITERNARAY_INITIATE: "ADD_FOLLOW_ITERNARAY_INITIATE",
  ADD_FOLLOW_ITERNARAY_SUCCESS: "ADD_FOLLOW_ITERNARAY_SUCCESS",
  ADD_FOLLOW_ITERNARAY_FAILURE: "ADD_FOLLOW_ITERNARAY_FAILURE",

  UPDATE_POSTAL_TRANSACTION_INITIATE: "UPDATE_POSTAL_TRANSACTION_INITIATE",
  UPDATE_POSTAL_TRANSACTION_SUCCESS: "UPDATE_POSTAL_TRANSACTION_SUCCESS",
  UPDATE_POSTAL_TRANSACTION_FAILURE: "UPDATE_POSTAL_TRANSACTION_FAILURE",

  GET_ALL_SUPPORTING_DOCS_TRANSACTION_INITIATE:
    "GET_ALL_SUPPORTING_DOCS_TRANSACTION_INITIATE",
  GET_ALL_SUPPORTING_DOCS_TRANSACTION_SUCCESS:
    "GET_ALL_SUPPORTING_DOCS_TRANSACTION_SUCCESS",
  GET_ALL_SUPPORTING_DOCS_TRANSACTION_FAILURE:
    "GET_ALL_SUPPORTING_DOCS_TRANSACTION_FAILURE",
};

export const reportsAction = {
  PROJECT_CASH_FLOW_INITIATE: "PROJECT_CASH_FLOW_INITIATE",
  PROJECT_CASH_FLOW_SUCCESS: "PROJECT_CASH_FLOW_SUCCESS",
  PROJECT_CASH_FLOW_FAILURE: "PROJECT_CASH_FLOW_FAILURE",

  ADD_COST_DISTRIBUTION_INITIATE: "ADD_COST_DISTRIBUTION_INITIATE",
  ADD_COST_DISTRIBUTION_SUCCESS: "ADD_COST_DISTRIBUTION_SUCCESS",
  ADD_COST_DISTRIBUTION_FAILURE: "ADD_COST_DISTRIBUTION_FAILURE",

  UPDATE_COST_DISTRIBUTION_INITIATE: "UPDATE_COST_DISTRIBUTION_INITIATE",
  UPDATE_COST_DISTRIBUTION_SUCCESS: "UPDATE_COST_DISTRIBUTION_SUCCESS",
  UPDATE_COST_DISTRIBUTION_FAILURE: "UPDATE_COST_DISTRIBUTION_FAILURE",

  DELETE_COST_DISTRIBUTION_INITIATE: "DELETE_COST_DISTRIBUTION_INITIATE",
  DELETE_COST_DISTRIBUTION_SUCCESS: "DELETE_COST_DISTRIBUTION_SUCCESS",
  DELETE_COST_DISTRIBUTION_FAILURE: "DELETE_COST_DISTRIBUTION_FAILURE",

  GET_COST_DISTRIBUTION_CATEGORIES_INITIATE:
    "GET_COST_DISTRIBUTION_CATEGORIES_INITIATE",
  GET_COST_DISTRIBUTION_CATEGORIES_SUCCESS:
    "GET_COST_DISTRIBUTION_CATEGORIES_SUCCESS",
  GET_COST_DISTRIBUTION_CATEGORIES_FAILURE:
    "GET_COST_DISTRIBUTION_CATEGORIES_FAILURE",

  GET_JOB_COST_CATEGORIES_INITIATE: "GET_JOB_COST_CATEGORIES_INITIATE",
  GET_JOB_COST_CATEGORIES_SUCCESS: "GET_JOB_COST_CATEGORIES_SUCCESS",
  GET_JOB_COST_CATEGORIES_FAILURE: "GET_JOB_COST_CATEGORIES_FAILURE",

  FINANCIAL_SALES_CASH_DEPOSITE_INITIATE:
    "FINANCIAL_SALES_CASH_DEPOSITE_INITIATE",
  FINANCIAL_SALES_CASH_DEPOSITE_SUCCESS:
    "FINANCIAL_SALES_CASH_DEPOSITE_SUCCESS",
  FINANCIAL_SALES_CASH_DEPOSITE_FAILURE:
    "FINANCIAL_SALES_CASH_DEPOSITE_FAILURE",

  FINANCIAL_SALES_REVENUES_GROSSS_INITIATE:
    "FINANCIAL_SALES_REVENUES_GROSSS_INITIATE",
  FINANCIAL_SALES_REVENUES_GROSSS_SUCCESS:
    "FINANCIAL_SALES_REVENUES_GROSSS_SUCCESS",
  FINANCIAL_SALES_REVENUES_GROSSS_FAILURE:
    "FINANCIAL_SALES_REVENUES_GROSSS_FAILURE",

  BLOCK_ACTUAL_COSTS_INITIATE: "BLOCK_ACTUAL_COSTS_INITIATE",
  BLOCK_ACTUAL_COSTS_SUCCESS: "BLOCK_ACTUAL_COSTS_SUCCESS",
  BLOCK_ACTUAL_COSTS_FAILURE: "BLOCK_ACTUAL_COSTS_FAILURE",

  BLOCK_UNIT_ACTUAL_COSTS_INITIATE: "BLOCK_UNIT_ACTUAL_COSTS_INITIATE",
  BLOCK_UNIT_ACTUAL_COSTS_SUCCESS: "BLOCK_UNIT_ACTUAL_COSTS_SUCCESS",
  BLOCK_UNIT_ACTUAL_COSTS_FAILURE: "BLOCK_UNIT_ACTUAL_COSTS_FAILURE",

  ESTIMATION_ACTUAL_INITIATE: "ESTIMATION_ACTUAL_COSTS_INITIATE",
  ESTIMATION_ACTUAL_SUCCESS: "ESTIMATION_ACTUAL_COSTS_SUCCESS",
  ESTIMATION_ACTUAL_FAILURE: "ESTIMATION_ACTUAL_COSTS_FAILURE",

  STATS_PURCHASING_FOR_UNIT_INITIATE: "STATS_PURCHASING_FOR_UNIT_INITIATE",
  STATS_PURCHASING_FOR_UNIT_SUCCESS: "STATS_PURCHASING_FOR_UNIT_SUCCESS",
  STATS_PURCHASING_FOR_UNIT_FAILURE: "STATS_PURCHASING_FOR_UNIT_FAILURE",

  STATS_BUDGET_PO_CHANGE_ORDER_INITIATE:
    "STATS_BUDGET_PO_CHANGE_ORDER_INITIATE",
  STATS_BUDGET_PO_CHANGE_ORDER_SUCCESS: "STATS_BUDGET_PO_CHANGE_ORDER_SUCCESS",
  STATS_BUDGET_PO_CHANGE_ORDER_FAILURE: "STATS_BUDGET_PO_CHANGE_ORDER_FAILURE",

  STATS_FPO_ANALYSIS_SUMMARY_INITIATE: "STATS_FPO_ANALYSIS_SUMMARY_INITIATE",
  STATS_FPO_ANALYSIS_SUMMARY_SUCCESS: "STATS_FPO_ANALYSIS_SUMMARY_SUCCESS",
  STATS_FPO_ANALYSIS_SUMMARY_FAILURE: "STATS_FPO_ANALYSIS_SUMMARY_FAILURE",

  STATS_FPO_ANALYSIS_REASON_INITIATE: "STATS_FPO_ANALYSIS_REASON_INITIATE",
  STATS_FPO_ANALYSIS_REASON_SUCCESS: "STATS_FPO_ANALYSIS_REASON_SUCCESS",
  STATS_FPO_ANALYSIS_REASON_FAILURE: "STATS_FPO_ANALYSIS_REASON_FAILURE",

  STATS_FPO_ANALYSIS_VENDOR_INITIATE: "STATS_FPO_ANALYSIS_VENDOR_INITIATE",
  STATS_FPO_ANALYSIS_VENDOR_SUCCESS: "STATS_FPO_ANALYSIS_VENDOR_SUCCESS",
  STATS_FPO_ANALYSIS_VENDOR_FAILURE: "STATS_FPO_ANALYSIS_VENDOR_FAILURE",

  STATS_SALE_INITIATE: "STATS_SALE_INITIATE",
  STATS_SALE_SUCCESS: "STATS_SALE_SUCCESS",
  STATS_SALE_FAILURE: "STATS_SALE_FAILURE",

  STATS_WARRANTY_INITIATE: "STATS_WARRANTY_INITIATE",
  STATS_WARRANTY_SUCCESS: "STATS_WARRANTY_SUCCESS",
  STATS_WARRANTY_FAILURE: "STATS_WARRANTY_FAILURE",

  STATS_WARRANTY_REPORT_FIRST_INITIATE: "STATS_WARRANTY_REPORT_FIRST_INITIATE",
  STATS_WARRANTY_REPORT_FIRST_SUCCESS: "STATS_WARRANTY_REPORT_FIRST_SUCCESS",
  STATS_WARRANTY_REPORT_FIRST_FAILURE: "STATS_WARRANTY_REPORT_FIRST_FAILURE",

  STATS_WARRANTY_REPORT_SECOND_INITIATE:
    "STATS_WARRANTY_REPORT_SECOND_INITIATE",
  STATS_WARRANTY_REPORT_SECOND_SUCCESS: "STATS_WARRANTY_REPORT_SECOND_SUCCESS",
  STATS_WARRANTY_REPORT_SECOND_FAILURE: "STATS_WARRANTY_REPORT_SECOND_FAILURE",

  STATS_WARRANTY_REPORT_THIRD_INITIATE: "STATS_WARRANTY_REPORT_THIRD_INITIATE",
  STATS_WARRANTY_REPORT_THIRD_SUCCESS: "STATS_WARRANTY_REPORT_THIRD_SUCCESS",
  STATS_WARRANTY_REPORT_THIRD_FAILURE: "STATS_WARRANTY_REPORT_THIRD_FAILURE",
};

export const subAdminConstant = {
  ROLE_LIST_INITIATE: "ROLE_LIST_INITIATE",
  ROLE_LIST_SUCCESS: "ROLE_LIST_SUCCESS",
  ROLE_LIST_FAILURE: "ROLE_LIST_INITIATE",

  ADD_ROLE_INITIATE: "ADD_ROLE_INITIATE",
  ADD_ROLE_SUCCESS: "ADD_ROLE_SUCCESS",
  ADD_ROLE_FAILURE: "ADD_ROLE_INITIATE",

  UPDATE_ROLE_INITIATE: "UPDATE_ROLE_INITIATE",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  UPDATE_ROLE_FAILURE: "UPDATE_ROLE_INITIATE",

  ROLE_STATUS_CHANGE_INITIATE: "ROLE_STATUS_CHANGE_INITIATE",
  ROLE_STATUS_CHANGE_SUCCESS: "ROLE_STATUS_CHANGE_SUCCESS",
  ROLE_STATUS_CHANGE_FAILURE: "ROLE_STATUS_CHANGE_INITIATE",

  DELETE_ROLE_INITIATE: "DELETE_ROLE_INITIATE",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  DELETE_ROLE_FAILURE: "DELETE_ROLE_INITIATE",

  SUBADMIN_LIST_INITIATE: "SUBADMIN_LIST_INITIATE",
  SUBADMIN_LIST_SUCCESS: "SUBADMIN_LIST_SUCCESS",
  SUBADMIN_LIST_FAILURE: "SUBADMIN_LIST_INITIATE",

  ADD_SUBADMIN_INITIATE: "ADD_SUBADMIN_INITIATE",
  ADD_SUBADMIN_SUCCESS: "ADD_SUBADMIN_SUCCESS",
  ADD_SUBADMIN_FAILURE: "ADD_SUBADMIN_INITIATE",

  UPDATE_SUBADMIN_INITIATE: "UPDATE_SUBADMIN_INITIATE",
  UPDATE_SUBADMIN_SUCCESS: "UPDATE_SUBADMIN_SUCCESS",
  UPDATE_SUBADMIN_FAILURE: "UPDATE_SUBADMIN_INITIATE",

  SUBADMIN_STATUS_CHANGE_INITIATE: "SUBADMIN_STATUS_CHANGE_INITIATE",
  SUBADMIN_STATUS_CHANGE_SUCCESS: "SUBADMIN_STATUS_CHANGE_SUCCESS",
  SUBADMIN_STATUS_CHANGE_FAILURE: "SUBADMIN_STATUS_CHANGE_INITIATE",

  DELETE_SUBADMIN_INITIATE: "DELETE_SUBADMIN_INITIATE",
  DELETE_SUBADMIN_SUCCESS: "DELETE_SUBADMIN_SUCCESS",
  DELETE_SUBADMIN_FAILURE: "DELETE_SUBADMIN_INITIATE",
};

export const ibuildAdminActionTypes = {
  IBUILDADMIN_USER_LIST_INITIATE: "IBUILDADMIN_USER_LIST_INITIATE",
  IBUILDADMIN_USER_LIST_SUCCESS: "IBUILDADMIN_USER_LIST_SUCCESS",
  IBUILDADMIN_USER_LIST_FAILURE: "IBUILDADMIN_USER_LIST_INITIATE",

  IBUILD_ADMIN_ROLE_LIST_INITIATE: "IBUILD_ADMIN_ROLE_LIST_INITIATE",
  IBUILD_ADMIN_ROLE_LIST_SUCCESS: "IBUILD_ADMIN_ROLE_LIST_SUCCESS",
  IBUILD_ADMIN_ROLE_LIST_FAILURE: "IBUILD_ADMIN_ROLE_LIST_INITIATE",

  ADD_ROLE_INITIATE: "ADD_ROLE_INITIATE",
  ADD_ROLE_SUCCESS: "ADD_ROLE_SUCCESS",
  ADD_ROLE_FAILURE: "ADD_ROLE_INITIATE",

  UPDATE_ROLE_INITIATE: "UPDATE_ROLE_INITIATE",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  UPDATE_ROLE_FAILURE: "UPDATE_ROLE_INITIATE",

  ROLE_STATUS_CHANGE_INITIATE: "ROLE_STATUS_CHANGE_INITIATE",
  ROLE_STATUS_CHANGE_SUCCESS: "ROLE_STATUS_CHANGE_SUCCESS",
  ROLE_STATUS_CHANGE_FAILURE: "ROLE_STATUS_CHANGE_INITIATE",

  DELETE_ROLE_INITIATE: "DELETE_ROLE_INITIATE",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  DELETE_ROLE_FAILURE: "DELETE_ROLE_INITIATE",

  SUBADMIN_LIST_INITIATE: "SUBADMIN_LIST_INITIATE",
  SUBADMIN_LIST_SUCCESS: "SUBADMIN_LIST_SUCCESS",
  SUBADMIN_LIST_FAILURE: "SUBADMIN_LIST_INITIATE",

  ADD_SUBADMIN_INITIATE: "ADD_SUBADMIN_INITIATE",
  ADD_SUBADMIN_SUCCESS: "ADD_SUBADMIN_SUCCESS",
  ADD_SUBADMIN_FAILURE: "ADD_SUBADMIN_INITIATE",

  UPDATE_SUBADMIN_INITIATE: "UPDATE_SUBADMIN_INITIATE",
  UPDATE_SUBADMIN_SUCCESS: "UPDATE_SUBADMIN_SUCCESS",
  UPDATE_SUBADMIN_FAILURE: "UPDATE_SUBADMIN_INITIATE",

  SUBADMIN_STATUS_CHANGE_INITIATE: "SUBADMIN_STATUS_CHANGE_INITIATE",
  SUBADMIN_STATUS_CHANGE_SUCCESS: "SUBADMIN_STATUS_CHANGE_SUCCESS",
  SUBADMIN_STATUS_CHANGE_FAILURE: "SUBADMIN_STATUS_CHANGE_INITIATE",

  DELETE_SUBADMIN_INITIATE: "DELETE_SUBADMIN_INITIATE",
  DELETE_SUBADMIN_SUCCESS: "DELETE_SUBADMIN_SUCCESS",
  DELETE_SUBADMIN_FAILURE: "DELETE_SUBADMIN_INITIATE",
};
