import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

import {
  AddDiscussionAction,
  AddReplyAction,
  discussionListAction,
} from "../../redux/action/constructionsAction";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import moment from "moment";
import GenerateChangeOrder from "./GenerateChangeOrder";
import ViewGenerateChangeOrder from "./ViewGenerateChangeOrder";
const units = [];

const SignClientCtcModal = ({
  signClientCtcModal,
  setSignClientCtcModal,
  setctcHistoryModal,
}) => {
  const dispatch = useDispatch();
  const { discussionList } = useSelector((state) => state.constructionReducer);
  const [generateChangeOrder, setGenerateChangeOrder] = useState(false);
  const [viewGenerateChangeOrder, setViewGenerateChangeOrder] = useState(false);

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  const [discussionState, updateDiscussionState] = useState({
    jobId: 2,
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });

  useEffect(() => {
    if (signClientCtcModal) {
      dispatch(discussionListAction({ jobId: 2 }));
    }
  }, [signClientCtcModal]);

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          jobId: replyState.jobId,
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: discussionState.jobId }));
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };
  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction({
          message: discussionState.message,
          jobId: discussionState.jobId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: discussionState.jobId }));

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <>
      <Modal show={signClientCtcModal} className="ModalBox ExtraLargeModal">
        <div class="modal-content BgCreate">
          <a
            class="CloseModal"
            onClick={() => {
              setctcHistoryModal(true);
              setSignClientCtcModal(false);
            }}
            data-dismiss="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Sign Client's CTC</h4>
            </div>
            <div class="FolderForm">
              <form>
                <div class="ContactInformationArea">
                  <h6>Contact Information</h6>
                  <div class="ContactInformationBox">
                    <span class="circle">JL</span>
                    <div class="ContactInformationName">
                      <h6>John Lead</h6>
                      <p>
                        312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                        <img
                          class="FolderPermissionId CursorPointer"
                          data-toggle="modal"
                          data-target="#TwoMapModal6"
                          data-dismiss="modal"
                          src="images/location.png"
                        />
                      </p>
                      <p>
                        Phone: <span>(403)555-555</span>
                      </p>
                      <p>
                        Email: <span>John.lead@email.com</span>
                      </p>
                    </div>

                    <div class="AssignUnit">
                      <h4>Assigned Unit</h4>
                      <p>U222-CHI-2B-243 (Unit #222)</p>
                    </div>
                  </div>

                  <div class="AgreementViewAreaGreen mt-3">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>Pictures</th>
                          <th>Unit Cost</th>
                          <th>Unit Discount</th>
                          <th>Quantity</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="DarkBlueBg">
                          <td>
                            <label class="CheckBox">
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td colspan="6">Appliances</td>
                        </tr>
                        <tr class="LightBlueBg">
                          <td>
                            <label class="CheckBox">
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td colspan="6">
                            Appliance Allowances: Includes GST.
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label class="CheckBox">
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            Platinum - Appliance Allowances: Includes GST.
                          </td>
                          <td>
                            <div>
                              <img src="images/picture-1.png" />
                            </div>
                          </td>
                          <td>$10,000.00</td>
                          <td>$300</td>
                          <td>1</td>
                          <td>$9,700.00</td>
                        </tr>
                        <tr>
                          <td>
                            <label class="CheckBox">
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td>Silver - Appliance Allowances: Includes GST</td>
                          <td>
                            <div>
                              <img src="images/picture-1.png" />
                            </div>
                          </td>
                          <td>$2,5000.00</td>
                          <td>1</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr class="DarkBlueBg">
                          <td>
                            <label class="CheckBox">
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td colspan="6">Cabinets and Vanities</td>
                        </tr>
                        <tr>
                          <td>
                            <label class="CheckBox">
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            Add Standard Spec Base Cabinets - <br />
                            Laminate Countertop Included
                          </td>
                          <td>
                            <div>
                              <img src="images/picture-1.png" />
                            </div>
                          </td>
                          <td>$4,50.00</td>
                          <td></td>
                          <td>2</td>
                          <td>$900.00</td>
                        </tr>
                        <tr class="LightBlueBg">
                          <td>
                            <label class="CheckBox">
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td colspan="6">Upgrade to granite</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>Cabinets</td>
                          <td>
                            <div>
                              <img src="images/picture-1.png" />
                            </div>
                          </td>
                          <td>$225.00</td>
                          <td></td>
                          <td>1</td>
                          <td>$13,500.00</td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="CostBreakDownArea">
                      <h5 class="TableHeading CursorPointer CostBreakDown">
                        Cost Breakdown <img src="images/down-arrow.png" />
                      </h5>
                      <div class="CostBreakDownShow">
                        <div class="Box">
                          <h6>Selections Details</h6>
                        </div>
                        <div class="Box DarkBlueBg">
                          <h6>Options Sub-Total</h6>
                          <h6>$15,075.00</h6>
                        </div>
                        <div class="Box LightBlueBg">
                          <h6>Tax 5%</h6>
                          <h6>$753.75</h6>
                        </div>
                        <div class="Box NavyBlueBg">
                          <h6>Total</h6>
                          <h6>$15,828.75</h6>
                        </div>
                      </div>
                    </div>

                    <div class="CostBreakDownArea">
                      <h5 class="TableHeading  my-4">
                        Cost Breakdown With Options
                      </h5>

                      <div class="Box DarkBlueBg">
                        <h6>Contract Price Before Tax</h6>
                        <h6>$400,000.00</h6>
                      </div>
                      <div class="Box LightBlueBg">
                        <h6>Change Order Total</h6>
                        <h6>$15,075.00</h6>
                      </div>
                      <div class="Box DarkBlueBg">
                        <h6>Sub Total</h6>
                        <h6>$415,075.00</h6>
                      </div>
                      <div class="Box LightBlueBg">
                        <h6>Tax 5%</h6>
                        <h6>$20,753.75</h6>
                      </div>
                      <div class="Box LightBlueBg">
                        <h6>Adjustment: Applied Tax Rebate</h6>
                        <h6>-$2,843.26</h6>
                      </div>
                      <div class="Box NavyBlueBg">
                        <h6>Total</h6>
                        <h6>$432,985.49</h6>
                      </div>
                    </div>

                    <div class="ChangeOrderCreatArea mt-5">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="InputBox">
                            <div class="form-group">
                              <div class="InputBoxSmall">
                                <h6>25/03/2023</h6>
                              </div>
                              <label>Date</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="InputBox">
                            <div class="form-group">
                              <a href="javascript:void(0);">
                                <img src="images/sign-img.png" />
                              </a>
                              <label>John Joe Client</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="InputBox border-0 text-center">
                            <h6>
                              Signed on 05/25/2023 02:42 PM <br />
                              by John Doe <br />
                              (johndoe@email.com)
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="InputBox">
                            <div class="form-group">
                              <div class="InputBoxSmall">
                                <h6>25/03/2023</h6>
                              </div>
                              <label>Date</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="InputBox">
                            <div class="form-group">
                              <a href="javascript:void(0);">
                                <img src="images/sign-img.png" />
                              </a>
                              <label>John Joe Client</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="InputBox border-0 text-center">
                            <h6>
                              Signed on 05/25/2023 02:42 PM <br />
                              by John Doe <br />
                              (johndoe@email.com)
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="DiscussionArea">
                    <div
                      class="DiscussionTitle"
                      style={{ backgroundColor: "#f7f7f7" }}
                    >
                      <h4>Discussion</h4>
                      <div class="DiscussionChat">
                        <img src="images/down.png" class="DiscussionIcon" />
                        <a href="#">
                          <img src="images/pdf2.png" />
                        </a>
                      </div>
                    </div>
                    <div
                      class="DiscussionHideArea"
                      style={{ display: "block" }}
                    >
                      <div class="DiscussionBox">
                        <span>
                          {iBuildLocalData?.user?.name.charAt(0) +
                            iBuildLocalData?.user?.name.charAt(1)}
                        </span>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            value={discussionState?.message}
                            onChange={(e) =>
                              updateDiscussionState({
                                ...discussionState,
                                message: e.target.value,
                              })
                            }
                            placeholder="Add a Comment ....."
                          />
                          {discussionState.discussionError &&
                            !discussionState.message && (
                              <p style={{ color: "red" }}>
                                {discussionState.discussionError}
                              </p>
                            )}

                          <div class="DiscussionOverlay">
                            <a
                              onClick={() =>
                                updateDiscussionState({
                                  ...discussionState,
                                  message: "",
                                })
                              }
                              class="CancelChat"
                            >
                              <img
                                src={require("../../assets/images/plus-cancel1.png")}
                              />
                            </a>
                            <a onClick={handleAddDiscussion} class="SendChat">
                              <img
                                src={require("../../assets/images/forwardbutton.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      {discussionList?.map((item, i) => {
                        return (
                          <>
                            <div class="DiscussionBox">
                              <span>{"NA"}</span>
                              <div class="DiscussionRight">
                                <h6>{item?.message}</h6>
                                <small>
                                  {moment(item.updatedAt).format("lll")}
                                </small>
                              </div>
                            </div>
                            <div class="ReplyArea">
                              <a
                                onClick={(e) => {
                                  updateReplyField({ flag: true, index: i });
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  });
                                }}
                              >
                                Reply
                              </a>
                              {item?.ReplyDiscussions?.map((ele) => (
                                <div class="DiscussionBox">
                                  <span>
                                    {iBuildLocalData?.user?.name.charAt(0) +
                                      iBuildLocalData?.user?.name.charAt(1)}
                                  </span>
                                  <div class="DiscussionRight">
                                    <h6>{ele?.message}</h6>
                                    <small>
                                      {moment(ele.updatedAt).format("lll")}
                                    </small>
                                  </div>
                                </div>
                              ))}

                              {replyField.index == i && (
                                <div class="DiscussionBox w-100">
                                  <span>
                                    {iBuildLocalData?.user?.name.charAt(0) +
                                      iBuildLocalData?.user?.name.charAt(1)}
                                  </span>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={replyState?.message}
                                      onChange={(e) =>
                                        updateReplyState({
                                          ...replyState,
                                          message: e.target.value,
                                          messageId: item?.id,
                                          jobId: item?.jobId,
                                        })
                                      }
                                      placeholder="Add reply ....."
                                    />
                                    {discussionState.discussionError &&
                                      !discussionState.message && (
                                        <p style={{ color: "red" }}>
                                          {discussionState.discussionError}
                                        </p>
                                      )}

                                    <div class="DiscussionOverlay">
                                      <a
                                        onClick={() =>
                                          updateReplyState({
                                            ...replyState,
                                            message: "",
                                          })
                                        }
                                        class="CancelChat"
                                      >
                                        <img
                                          src={require("../../assets/images/plus-cancel1.png")}
                                        />
                                      </a>
                                      <a
                                        onClick={handleAddReply}
                                        class="SendChat"
                                      >
                                        <img
                                          src={require("../../assets/images/forwardbutton.png")}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div class="Button d-flex justify-content-between mt-3">
                    <button
                      class="Create"
                      onClick={() => {
                        setSignClientCtcModal(false);

                        setViewGenerateChangeOrder(true);
                      }}
                    >
                      View Generated Change Order
                    </button>
                    <button
                      class="Create"
                      onClick={() => {
                        setSignClientCtcModal(false);
                        setGenerateChangeOrder(true);
                      }}
                    >
                      Generated Change Order
                    </button>
                    <button
                      class="Outline"
                      data-toggle="modal"
                      data-target="#CtcHistoryGenerate"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <GenerateChangeOrder
        generateChangeOrder={generateChangeOrder}
        setGenerateChangeOrder={setGenerateChangeOrder}
        setSignClientCtcModal={setSignClientCtcModal}
      />
      <ViewGenerateChangeOrder
        viewGenerateChangeOrder={viewGenerateChangeOrder}
        setViewGenerateChangeOrder={setViewGenerateChangeOrder}
        setSignClientCtcModal={setSignClientCtcModal}
      /> 
    </>
  );
};

export default SignClientCtcModal;
