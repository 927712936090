import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { getCitiesList, getStateList,getCountriesList } from "../../redux/action/authAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";


const MultifamilyJobStep5 = ({
  setShow,
  MultifamilyJobStep5Modal,
  SetMultifamilyJobStep3Modal,
  SetMultifamilyJobStep1Modal,
  SetMultifamilyJobStep2Modal,
  SetMultifamilyJobStep4Modal,
  SetMultifamilyJobStep5Modal,
  SetMultifamilyJobStep6Modal,
  iState, UpdateState
}) => {
  const handleMultifamilyJobStep5ModalClose = () => {SetMultifamilyJobStep5Modal(false)
    setShow(true)
  }
  const handleMultifamilyJobStep5ModalShow = () => {
    SetMultifamilyJobStep5Modal(true);
  };

  const dispatch = useDispatch()


  const { countryData, cityData, stateData, timeZoneData } = useSelector(
    (state) => state.authReducer
  );


  const {jobAddress,lot,block,phase, city,zipCode,country,province, errorMsg} = iState


  const handleChange = (e) => {
    const { name, value } = e.target;

    UpdateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = true;

    if (formIsValid) {
      SetMultifamilyJobStep5Modal(false);
      SetMultifamilyJobStep6Modal(true);
    }
  };


  useEffect(() => { 
    if(country)
    dispatch(
      getStateList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode
      )
    );
  }, [country,countryData?.countries,dispatch]);
  useEffect(() => {
    dispatch(
      getCitiesList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode,
        stateData?.states?.find((x) => x.name == province)?.isoCode
      )
    );
  }, [province]);


  return (
    <Modal show={MultifamilyJobStep5Modal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          href="javascript:void(0);"
          class="CloseModal"
          onClick={handleMultifamilyJobStep5ModalClose}
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
          <h4>Create Multi-Family Jobs</h4>
          </div>
          <div class="StepBox">
            <ul>
                <li class="active">
                    <span class="Icon">
                        <img src={require("../../assets/images/step_1.png")} onClick={()=> {SetMultifamilyJobStep1Modal(true) 
                      SetMultifamilyJobStep5Modal(false)}} />
                    </span>
                    <span class="Text">Details</span>
                </li>
                <li class="active" onClick={()=> {SetMultifamilyJobStep2Modal(true)
                   SetMultifamilyJobStep5Modal(false)}}>
                    <span class="Icon FolderPermissionId " >
                        <img src={require("../../assets/images/blocks.png")}/>
                    </span>
                    <span class="Text">Blocks</span>
                </li>
                <li class="active">
                  <span class="Icon FolderPermissionId " data-toggle="modal" onClick={()=> {SetMultifamilyJobStep3Modal(true)
                    SetMultifamilyJobStep5Modal(false)}} >
                      <img src={require("../../assets/images/units.png")}/>
                  </span>
                  <span class="Text">Units </span>
              </li>
                <li className="active">  
                    <span class="Icon FolderPermissionId " data-toggle="modal"  onClick={()=>{ SetMultifamilyJobStep4Modal(true)
                    SetMultifamilyJobStep5Modal(false)}}>
                        <img src={require("../../assets/images/step_8.png")} />
                    </span>
                    <span class="Text">Common <br /> Buildings</span>
                </li>
                <li className="active">
                    <span class="Icon FolderPermissionId " data-toggle="modal" >
                        <img src={require("../../assets/images/step_2.png")} />
                    </span>
                    <span class="Text">Job <br /> Address</span>
                </li>
                <li>
                    <span class="Icon FolderPermissionId " data-toggle="modal" onClick={()=> {SetMultifamilyJobStep6Modal(true)
                    SetMultifamilyJobStep5Modal(false)}}>
                        <img src={require("../../assets/images/step_7.png")} />
                    </span>
                    <span class="Text">Confirmation</span>
                </li> 
            </ul>
          </div>
          <div class="FolderForm">
          <form>
                  <div class="FolderFormCreateArea">
                    <div class="FieldsMandatory"><h6>Fields with <span class="required">*</span> are mandatory</h6></div>
                    <div class="row">
                      <div class="col-md-6">
                        
                        <div class="form-group">
                          <h6>Job Address</h6>
                          <input type="text" value={jobAddress} name="jobAddress" onChange={handleChange} class="form-control" placeholder="Insert full Address" />
                        </div>
                        <div class="form-group">
                          <h6>Lot</h6>
                          <input type="number" onChange={handleChange}  name="lot" value={lot} class="form-control" placeholder="Insert lot number" />
                        </div>
                        <div class="form-group">
                          <h6>Block</h6>
                          <input type="text" onChange={handleChange} value={block} name="block" class="form-control" placeholder="Insert block number" />
                        </div>
                        <div class="form-group">
                          <h6>Phase</h6>
                          <input type="text" onChange={handleChange}  value={phase} name="phase" class="form-control" placeholder="Insert Phase" />
                        </div>
                     
                        <div class="form-group">
                          <h6>Zip/ Postal Code</h6>
                          <input type="text"   onChange={handleChange}  value={zipCode} name="zipCode" class="form-control" placeholder="Insert zip code Zip/ Postal Cod" />
                        </div>
                        <div class="form-group">
                          <h6>Country</h6>
                          <div class="CommonSelectBox">
                          <select
                                name="country"
                                value={country}
                                onChange={handleChange}
                              >
                                <option>Select Country</option>
                                {countryData?.countries ? (
                                  <>
                                    {countryData?.countries?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <h6>State/Province</h6>
                          <div class="CommonSelectBox">
                          <select
                                name="province"
                                value={province}
                                onChange={handleChange}
                              >
                                <option>Select Province</option>
                                {stateData?.states ? (
                                  <>
                                    {stateData?.states?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <h6>City</h6>
                          <div class="CommonSelectBox">

                          <select
                                name="city"
                                value={city}
                                onChange={handleChange}
                              >
                                <option>Select City</option>
                                {cityData?.cities ? (
                                  <>
                                    {cityData?.cities?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>        
                              
                              </div>                </div>
                        
                      </div>
                    </div>
                </div>  
          
              
                  <div class="Button d-flex justify-content-between align-items-center px-5">
                    <button class="Outline" onClick={(e)=> {
                      e.preventDefault()
                    SetMultifamilyJobStep5Modal(false)
                    SetMultifamilyJobStep4Modal(true)
                  }}>Back</button>
                    <button class="Create" data-toggle="modal" onClick={handleSubmit}>Next</button>
                  </div>  
                  </form>
                
              </div>
        </div>
      </div>
    </Modal>
  );
};

export default MultifamilyJobStep5;
