import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Gantt from "./Gantt";
import Calendar from "react-awesome-calendar";

import GanttSchedule from "./GanttScheduler/GanttScheduler";
import Scheduler from "./Scheduler/Scheduler";
import SchedulerToolBar from "./SchedulerToolbar/SchedulerToolbar";
import ProgressBar from "@ramonak/react-progress-bar";

import { getListProjectJobs } from "../redux/action/warrantyAction";
import {
  getLandDevelopmentProjectList,
  getLandPhaseList,
  getMultiBlocksList,
  getMultiFamilyProjectList,
} from "../redux/action/homeAction";

import { getAllProjects } from "../redux/action/modelsAction";

import { getAllFolderList } from "../redux/action/constructionFileManger";

import Toolbar from "./Toolbar";

import {
  AddDiscussionAction,
  AddPhaseAction,
  AddReplyAction,
  AddTaskAction,
  BaselineAction,
  DeletePhaseAction,
  DeleteTaskAction,
  FindAllTaskAction,
  GetAccountAction,
  GetAllJobsAction,
  GetJobDetailsAction,
  GetPhaseAction,
  GetProjectNameAction,
  GetVendorAction,
  constructionDashboardAction,
  discussionListAction,
  getChangeOrderCheckListAction,
  getFieldPurcaseListAction,
  getGanttDataAction,
} from "../redux/action/constructionsAction";
import Modal from "react-bootstrap/Modal";

import { toast } from "react-toastify";
import Header from "./Header";
import SideNav from "./SideNav";
import FieldOrderList from "./Construction/Modal/FieldOrderList";
import ChangeOrderCheckList from "./Construction/Modal/ChangeOrderChecklist";
import FileManger from "./Construction/Modal/FileManager/FileManger";
import ConsolidatedChangeOrderCheckList from "./Construction/Modal/ConsolidatedChangeOrderChecklist";
import AddSchedule from "./Construction/Schedule/AddSchedule";
import TaskAssignModal from "./Construction/TaskAssignModal/TaskAssignModal";

import { Link, useLocation } from "react-router-dom";
import {
  getWarrantySettingDiscussion,
  updateWarrantySettingDiscussion,
} from "../redux/action/warrantyAction";
import { UserDetailsAction } from "../redux/action/adminUserAction";
import { getAllProjectList } from "../redux/action/vendorViewConfigAction";
import OkaypayModal from "./Construction/TaskAssignModal/OkaypayModal";
import AssignResouceModal from "./Construction/TaskAssignModal/AssignResourceModal";
import SelectAccountModal from "./Construction/TaskAssignModal/SelectAccountModal";

const constructionInit = {
  multiConstructionShow: false,

  landConstructionShow: false,
  residentalConstructionShow: false,
};
const taskInitialState = {
  jobId: "",
  phaseId: "",
  taskName: "",
  startDate: "",
  endDate: "",
  duration: "",
  taskNumber: "",
  progress: "",
  type: "",
};
const phaseInitialState = {
  jobId: "",
  taskName: "",
  startDate: "",
  endDate: "",
  duration: "",
  taskNumber: "",
  progress: "",
  sheduleType: "System",
  errorMsg: {},
};

const initialState = {
  DiscussionSettingsModal: false,
  discussionData: [],
  visibleInClientView: false,
  visibleInTradeView: false,
  visibleInInvestorView: false,
  NavigateModal: false,
  supportingDocFiles:null,
  message: "",


};

const initLeadState = {
  ProjectId: "",
};


const Construction = () => {
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();

  const { allProjectList } = useSelector(
    (state) => state.vendorViewConfigReducer
  );


  const [taskAssignModal, setTaskAssignModal] = useState(false);
  const [okaypayModal, setOkaypayModal] = useState(false);
  const [assignResouceModal, setAssignResouceModal] = useState(false);
  const [selectAccountModal, setSelectAccountModal] = useState(false);


  useEffect(() => {
    dispatch(getAllProjectList(iBuildLocalData?.user?.CompanyId));
  }, []);

  const [iLeadState, updateLeadState] = useState(initLeadState);
  const [ConstructionState, updateConstructionState] =
    useState(constructionInit);
  const {
    landConstructionShow,
    multiConstructionShow,
    residentalConstructionShow,
  } = ConstructionState;

  const [iState, updateState] = useState(initialState);

  const { ProjectId } = iLeadState;
  const {
    DiscussionSettingsModal,
    discussionData,
    visibleInClientView,
    visibleInTradeView,
    visibleInInvestorView,
    NavigateModal,
    supportingDocFiles,
    message
  } = iState;

  const {
    multiblocksList,
    multiFamilyProjectList,
    landDevelopmentProjectList,
    landPhaseList,
  } = useSelector((state) => state.homeReducer);

  const { projectsList } = useSelector((state) => state.modelsReducer);

  const events = [
    {
      id: 1,
      color: "#fd3153",
      from: "2024-07-02T18:00:00+00:00",
      to: "2024-07-03T19:00:00+00:00",
      title: "This is an event",
    },
    {
      id: 2,
      color: "#1ccb9e",
      from: "2024-06-01T13:00:00+00:00",
      to: "2024-06-05T14:00:00+00:00",
      title: "This is another event",
    },
    {
      id: 3,
      color: "#3694DF",
      from: "2024-06-05T13:00:00+00:00",
      to: "2024-06-05T20:00:00+00:00",
      title: "This is also another event",
    },
    {
      id: 4,
      color: "#00FF00",
      from: "2024-06-10T13:00:00+00:00",
      to: "2024-06-12T20:00:00+00:00",
      title: "This is new event",
    },
    {
      id: 5,
      color: "#b20000",
      from: "2024-06-20T13:00:00+00:00",
      to: "2024-06-22T20:00:00+00:00",
      title: "This new find",
    },
  ];


  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      supportingDocFiles: null,
      supportingDocFiless: null,
      itemDocs: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };
  const [rerender, setRerender] = useState(false);
  const [allTask, setAllTask] = useState({ data: [] });
  const [schedulerData, setSchedulerData] = useState([]);

  const [lockStatus, SetLockStatus] = useState(false);
  const [sideJobId, setSideJobId] = useState("");
  const [sideUnitId, setSideUnitId] = useState("");

  const [FieldOrderModal, SetFieldOrderModal] = useState(false);
  const [
    ConsolidatedChangeOrderCheckListModal,
    SetConsolidatedChangeOrderCheckListModal,
  ] = useState(false);

  const [FileMangerModal, SetFileMangerModal] = useState(false);
  const [PhaseTaskManagementModal, SetPhaseTaskManagementModal] =
    useState(false);

  const handlePhaseTaskManagementModalClose = () =>
    SetPhaseTaskManagementModal(false);
  const handlePhaseTaskManagementModalShow = () => {
    SetPhaseTaskManagementModal(true);
  };
  const [currentTimeFormatState, setcurrentTimeFormatState] = useState();
  const [calendarViewShow, setCalendarViewShow] = useState(false);
  const [ScheduleViewShow, setScheduleViewShow] = useState(false);
  const [blockShow, setBlockShow] = useState(false);
  const [unitShow, setUnitShow] = useState(false);
  const [AddNotesModal, setAddNotesModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState("Text");

  const [textInput, setTextInput] = useState(true);
  const [fileData, setFileData] = useState(false);

  const handleAddNotesShow = () => {
 
    setAddNotesModal(true)
  };
  const handleAddNotesClose = () => {
    setAddNotesModal(false)


  };

  const onValueChange = (event) => {
    updateState({ ...iState, message: "", supportingDocFiles: "" });
    const value = event.target.value;
    setSelectedOption(value);
    setTextInput(value === "Text");
    setFileData(value === "File");
  };

  
  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "support") {
      updateState({ ...iState, supportingDocFiles: file });
    }
    if (type === "itemDoc") {
      updateState({ ...iState, itemDocs: file });
    } else {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const modifiedValue = value.slice(0, 1000);
    updateState({
      ...iState,
      [name]: modifiedValue,
    });
  };
  const [currentZoom, setCurrentZoom] = useState("Days");
  const data = {
    data: [
      // {
      //   id: 1,
      //   text: "Task #1",
      //   start_date: "2024-03-02",
      //   end_date: "2024-03-03",

      //   duration: 3,
      //   predecessors: 0,
      //   progress: 54,

      //   compeltion_date: "2024-03-04",
      //   Ok_to_pay: "",
      // },
      // {
      //   id: 2,
      //   text: "Task #2",
      //   start_date: "2024-03-03",
      //   end_date: "2024-03-05",

      //   duration: 3,
      //   predecessors: 0,
      //   progress: 54,

      //   compeltion_date: "2024-03-05",
      //   Ok_to_pay: "",
      // },

      {
        id: 1,
        text: "Task #1",
        start_date: "02-04-2023 00:00",
        duration: 3,
        priority: 3,
        stage: 1,
        user: 3,
        parent: 0,
      },
      {
        id: 5,
        text: "Task #1.1",
        start_date: "05-04-2023 00:00",
        duration: 4,
        parent: 1,
        priority: 1,
        stage: 1,
        user: 1,
      },
      {
        id: 6,
        text: "Task #1.2",
        start_date: "11-04-2023 00:00",
        duration: 6,
        parent: 1,
        priority: 2,
        stage: 2,
        user: 3,
      },
      {
        id: 2,
        text: "Task #2",
        start_date: "11-04-2023 00:00",
        duration: 2,
        priority: 1,
        stage: 3,
        user: 0,
        parent: 0,
      },
      {
        id: 7,
        text: "Task #2.1",
        start_date: "13-04-2023 00:00",
        duration: 2,
        parent: 2,
        priority: 3,
        stage: 2,
        user: 2,
      },
      {
        id: 3,
        text: "Task #3",
        start_date: "11-04-2023 00:00",
        duration: 6,
        priority: 2,
        stage: 2,
        user: 1,
        open: true,
        parent: 0,
      },
      {
        id: 8,
        text: "Task #3.1",
        start_date: "09-04-2023 00:00",
        duration: 3,
        parent: 3,
        priority: 1,
        stage: 1,
        user: 3,
      },
      {
        id: 9,
        text: "Task #3.2",
        start_date: "12-04-2023 00:00",
        duration: 2,
        parent: 3,
        priority: 3,
        stage: 3,
        user: 1,
      },
      {
        id: 10,
        text: "Task #3.3",
        start_date: "17-04-2023 00:00",
        duration: 2,
        parent: 3,
        priority: 2,
        stage: 2,
        user: 0,
      },
    ],
    links: [{ id: 1, source: 1, target: 2, type: "0" }],
  };
  const adminData = JSON.parse(window.localStorage.getItem("admin"));
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  const [projectState, updateProjectState] = useState({
    projectName: "",
    projectId: "",
    projectType: "",
    isUpdate: false,
  });

  const [jobState, updateJobState] = useState({});
  const [jobListState, updateJobListState] = useState([]);

  const [phaseLevel, updatePhaseLevel] = useState({ flag: false, index: 0 });
  const [taskLevel, updateTaskLevel] = useState({ flag: false, index: 0 });

  const {
    phaseList,
    taskList,
    discussionList,
    ganttData,
    constructionDashboard,
    changeOrderCheckList,
    fieldPurchaseOrderList,
  } = useSelector((state) => state.constructionReducer);

  const [jobList, setJobList] = useState([]);

  const { allFolderList } = useSelector((state) => state.fileManagerData);

  const jobStaticClass = [
    { 0: "First" },
    { 1: "Second" },
    { 2: "THird" },
    { 3: "Fourth" },
    { 4: "Fifth" },
  ];
  const [jobId, setJobId] = useState(1);
  const [taskState, updateTaskState] = useState(taskInitialState);

  const [phaseState, updatePhaseState] = useState(phaseInitialState);

  const [discussionState, updateDiscussionState] = useState({
    jobId: "",
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });

  const [AddTaskModal, setAddTask] = useState(false);
  const handleAddTaskModalClose = () => setAddTask(false);
  const handleAddTaskModalShow = (phase) => {
    updateTaskState((prev) => ({
      ...prev,
      jobId: phase?.jobId,
      phaseId: phase?.id,
    }));
    setAddTask(true);
  };

  const [AddScheduleModal, setAddSchedule] = useState(false);
  const handleAddScheduleModalShow = () => {
    setAddSchedule(true);
  };

  const [TaskDetailsModal, setTaskDetails] = useState(false);
  const handleTaskDetailsModalClose = () => setTaskDetails(false);
  const handleTaskDetailsModalShow = () => {
    setTaskDetails(true);
  };

  const [AddPhaseModal, setAddPhase] = useState(false);
  const handleAddPhaseModalClose = (e) => {
    e.preventDefault();
    setAddPhase(false);
  };
  const handleAddPhaseModalShow = (job) => {
    setAddPhase(true);
    updatePhaseState((prev) => ({
      ...prev,
      jobId: job.id,
    }));
  };

  useEffect(() => {
    let data = {
      JobId: state?.id,
      JobType: state?.jobType,
    };
    dispatch(getAllFolderList(data));

    dispatch(
      getChangeOrderCheckListAction({
        projectId: state?.ProjectId,
        jobId: state?.id,
      })
    );
    dispatch(getFieldPurcaseListAction(state?.ProjectId));

    dispatch(constructionDashboardAction({ jobId: state?.id }));
    dispatch(GetProjectNameAction({ id: adminData?.id }))
      .then((res) => {})
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    // dispatch(GetJobDetailsAction({ id: 1, jobType: "multiFamily" }));
    dispatch(GetAllJobsAction())
      .then((res) => {})
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    dispatch(GetAccountAction())
      .then((res) => {})
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    dispatch(GetVendorAction())
      .then((res) => {})
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    dispatch(discussionListAction({ jobId: jobId }));

    dispatch(
      getChangeOrderCheckListAction({
        id: 6,

        projectId: 3,

        jobId: 4,
      })
    );

    updateJobListState([state]);

    if (state?.jobType == "Multi Family") {
      updateConstructionState((prev) => ({
        ...prev,
        multiConstructionShow: true,
      }));
    }

    if (state?.jobType == "Single Family") {
      updateConstructionState((prev) => ({
        ...prev,
        residentalConstructionShow: true,
      }));
    }

    if (state?.jobType == "Land Development") {
      updateConstructionState((prev) => ({
        ...prev,
        landConstructionShow: true,
      }));
    }
  }, []);

  let handleValidationPhase = () => {
    let errorMsg = {};
    let formIsValid = true;

    if (phaseState.taskName == "") {
      errorMsg.taskName = "* Please enter phase name";
      formIsValid = false;
    }

    if (!phaseState?.startDate) {
      errorMsg.startDate = "* Please enter start date";
      formIsValid = false;
    }

    if (!phaseState?.duration) {
      errorMsg.duration = "* Please enter duration";
      formIsValid = false;
    }

    if (!phaseState?.taskNumber) {
      errorMsg.taskNumber = "* Please enter task number";
      formIsValid = false;
    }

    updatePhaseState({ ...phaseState, errorMsg: errorMsg });
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = true;

    if (formIsValid) {
      let data = {
        // riderType,
        // companyName,
        // address,
        // phoneNumber: `+${phoneNumber}`,
        // name,
        // city,
        // email,
        // id,
        // idImage,
        // bankName,
        // ibanNumber,
        // accountHolderName,
        // vehicleImage,
        // vehicleNumber,
        // vehicleType
      };

      dispatch(AddTaskAction(taskState))
        .then((res) => {
          if (res.status == 201) {
            setAddTask(false);
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleSubmitPhase = (e) => {
    e.preventDefault();

    let formIsValid = handleValidationPhase();

    if (formIsValid) {
      let data = {
        jobId: phaseState?.jobId,
        taskName: phaseState?.taskName,
        startDate: phaseState?.startDate,
        endDate: moment(phaseEndDay).format("YYYY-MM-DD"),
        duration: phaseState?.duration,
        taskNumber: phaseState?.taskNumber,
        progress: phaseState?.progress,
        sheduleType: phaseState?.sheduleType,
      };

      dispatch(AddPhaseAction(data))
        .then((res) => {
          if (res.status == 201) {
            setAddPhase(false);
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction({ message: discussionState.message, jobId: jobId })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: jobId }));

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          jobId: replyState.jobId,
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: jobId }));
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleZoomChange = (zoom) => {
    setCurrentZoom(zoom);
  };

  useEffect(() => {
    if (jobListState) {
      let tempListSchedule = jobListState?.map((item, i) => {
        let temp = {
          id: i + 1,
          start_date: moment(item?.startDate).format("YYYY-MM-DD"),
          endDate_date: moment(item?.endDate).format("YYYY-MM-DD"),
        };

        return temp;
      });
      setSchedulerData(tempListSchedule);
    }
  }, [jobListState]);

  const handleBaseLine = () => {
    dispatch(BaselineAction(1));
  };

  const handleGetPhaseList = (item, index) => {
    updatePhaseLevel({
      flag: phaseLevel.flag ? false : true,
      index: index,
    });

    dispatch(GetPhaseAction({ jobId: item?.id }));
  };

  const handleGetTaskList = (item, index) => {
    updateTaskLevel({
      flag: taskLevel.flag ? false : true,
      index: index,
    });

    dispatch(FindAllTaskAction({ phaseId: item?.id }));
  };

  const handleSubmitTask = (e) => {
    e.preventDefault();

    let formIsValid = true;

    if (formIsValid) {
      let data = {
        jobId: taskState?.jobId,
        phaseId: taskState?.phaseId,
        taskName: taskState?.taskName,
        startDate: taskState?.startDate,
        endDate: taskState?.endDate,
        duration: taskState?.duration,
        taskNumber: taskState?.taskNumber,
        progress: taskState?.progress,
        sheduleType: taskState?.sheduleType,
      };

      dispatch(AddTaskAction(data))
        .then((res) => {
          if (res.status == 201) {
            setAddTask(false);
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleDeleteTask = (item) => {
    dispatch(DeleteTaskAction({ id: item?.id }))
      .then((res) => {
        if (res.status == 201) {
          dispatch(FindAllTaskAction({ phaseId: item?.phaseId }));

          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleDeletePhase = (item) => {
    dispatch(DeletePhaseAction({ id: item?.id }))
      .then((res) => {
        if (res.status == 201) {
          dispatch(GetPhaseAction({ jobId: item?.jobId }));

          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleGantView = () => {
    setScheduleViewShow(false);

    let temp = jobListState?.map((item) => {
      return item?.id;
    });

    if (temp?.length > 0)
      dispatch(
        getGanttDataAction({
          resultArr: temp,
        })
      );
  };

  useEffect(() => {
    if (ganttData?.length > 0) {
      let tempList = ganttData?.map((item, i) => {
        let temp = {
          ...item,
          start_date: moment(item?.startDate).format("YYYY-MM-DD"),
          endDate_date: moment(item?.endDate).format("YYYY-MM-DD"),
        };

        return temp;
      });

      setAllTask({ data: tempList });

      let tempListSchedule = ganttData?.map((item, i) => {
        let temp = {
          id: item?.id,
          start_date: moment(item?.startDate).format("YYYY-MM-DD"),
          end_date: moment(item?.endDate).format("YYYY-MM-DD"),
        };

        return temp;
      });
      setSchedulerData(tempListSchedule);
      setRerender(true);
    }
  }, [ganttData]);

  const handleCalenderView = () => {
    setCalendarViewShow(true);
  };

  const handleScheduleView = () => {
    setScheduleViewShow(true);
    setRerender(false);
  };

  useEffect(() => {
    console.log(state, "STATE================>");
    updateJobState(state);

    dispatch(GetProjectNameAction(state?.CompanyId, state?.ProjectId)).then(
      (res) => {
        updateProjectState({
          projectName: res?.data?.projects?.projectName,
          projectId: res?.data?.projects?.id,
          projectType: res?.data?.projects?.projectType,
          isUpdate: false,
        });
      }
    );

    if (state?.projectType == "Multi Family") {
      updateConstructionState((prev) => ({
        ...prev,
        multiConstructionShow: true,
      }));
    }

    if (state?.projectType == "Land Development") {
      updateConstructionState((prev) => ({
        ...prev,
        landConstructionShow: true,
      }));
    }

    if (state?.projectType == "Single Family") {
      updateConstructionState((prev) => ({
        ...prev,
        residentalConstructionShow: true,
      }));
    }
  }, [state]);

  useEffect(() => {
    if (DiscussionSettingsModal) {
      dispatch(getWarrantySettingDiscussion("Project", state?.ProjectId)).then(
        (res) => {
          if (res.status === 200) {
            updateState({
              ...iState,
              discussionData: res.data,
              visibleInTradeView: res.data.settings?.visibleInTradeView,
              visibleInInvestorView: res.data.settings?.visibleInInvestorView,
              visibleInClientView: res.data.settings?.visibleInClientView,
            });
          }
        }
      );
    }
  }, [DiscussionSettingsModal]);

  const handleDiscussionSettingsModalShow = () => {
    updateState({ ...iState, DiscussionSettingsModal: true });
  };
  const handleDiscussionSettingsModalClose = () => {
    updateState({ ...iState, DiscussionSettingsModal: false });
  };

  const handleDiscussionCheckBox = (type) => {
    if (type === "client") {
      updateState({
        ...iState,
        visibleInClientView: !visibleInClientView,
      });
    } else if (type === "trade") {
      updateState({
        ...iState,
        visibleInTradeView: !visibleInTradeView,
      });
    } else {
      updateState({
        ...iState,
        visibleInInvestorView: !visibleInInvestorView,
      });
    }
  };

  const handleDiscussionSettingUpdate = () => {
    const dataa = {
      visibleInClientView,
      visibleInTradeView,
      visibleInInvestorView,
      discussableId: discussionData?.settings?.id,
      discussableType: "Project",
    };
    dispatch(updateWarrantySettingDiscussion(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getWarrantySettingDiscussion("Project", state?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          DiscussionSettingsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          DiscussionSettingsModal: true,
        });
      }
    });
  };

  const date1 = moment(phaseState.startDate);
  const phaseEndDay = date1.add(phaseState.duration, "days");

  useEffect(() => {
    if (landConstructionShow) {
      dispatch(getLandDevelopmentProjectList(ProjectId)).then((res) => {
        setJobList(res?.data?.projects);
      });
    }
  }, [landConstructionShow, ProjectId]);

  useEffect(() => {
    if (multiConstructionShow) {
      dispatch(getMultiFamilyProjectList(ProjectId)).then((res) => {
        setJobList(res?.data?.projects);
      });
    }
  }, [multiConstructionShow, ProjectId]);

  useEffect(() => {
    if (residentalConstructionShow) {
      dispatch(getAllProjects(ProjectId)).then((res) => {
        setJobList(res?.data?.projects);
      });
    }
  }, [residentalConstructionShow, ProjectId]);

  const handleChildData = (data) => {
    dispatch(getListProjectJobs(iBuildLocalData?.user?.CompanyId));
    updateLeadState({ ...iLeadState, ProjectId: data });
  };

  const handleMultiBlockListByProject = (Id) => {
    dispatch(getMultiBlocksList(Id));
    setSideJobId(Id);

    setBlockShow(blockShow ? false : true);
  };
  const handleMultiUnitListByProject = (Id) => {
    setUnitShow(unitShow ? false : true);
    setSideUnitId(Id);
  };

  const handleLandPhaseListByProject = (Id) => {
    dispatch(getLandPhaseList(Id));
    setUnitShow(unitShow ? true : false);
  };

  const [JobMenuModal, setJobMenuModal] = useState(false);
  const handleJobMenuModalClose = () => setJobMenuModal(false);
  const handleJobMenuModalShow = () => setJobMenuModal(true);

  const handleNavigateModalShow = () => {
    updateState({ ...iState, NavigateModal: true });
  };
  const handleNavigateModalClose = () => {
    updateState({ ...iState, NavigateModal: false });
  };

  console.log(taskAssignModal, "TASK====================>");
  return (
    <>
      <Header sendDataToParent={handleChildData} />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div>
            <div className="row">
              <div class="col-lg-3 px-lg-0">
                <div class="ChinookLeft">
                  <div class="ChinookTitle">
                    <h4>{projectState?.projectName}</h4>
                    <h6>({projectState?.projectType})</h6>
                  </div>

                  <div class="Search">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                    />
                    <span>
                      <img src={require("../assets/images/search.png")} />
                    </span>
                  </div>
                  <div class="ChinookStarHomes">
                    <div class="ChinookStarHomesLeft">
                      <input
                        type="text"
                        class={
                          projectState?.isUpdate
                            ? "EditHeadingChange"
                            : "EditHeading"
                        }
                        value={projectState?.projectName}
                        onChange={(e) =>
                          updateProjectState((prev) => ({
                            ...prev,
                            projectName: e.target.value,
                          }))
                        }
                      />
                      <p>{jobState?.jobNumber}</p>
                      <p>
                        {jobState?.jobAddress}

                        {/* <br />
                        Calgary, Alberta, T2J 1J1 */}
                      </p>
                    </div>
                    <div class="ChinookStarIcons">
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        onClick={handleAddPhaseModalShow}
                      >
                        <img src={require("../assets/images/icon-13.png")} />
                      </a>
                      <a
                        onClick={handleAddScheduleModalShow}
                        data-toggle="modal"
                        data-target="#ConstructionScheduleGeneration"
                      >
                        <img src={require("../assets/images/icon-14.png")} />
                      </a>
                      <a
                        onClick={(e) =>
                          updateProjectState((prev) => ({
                            ...prev,
                            isUpdate: projectState.isUpdate ? false : true,
                          }))
                        }
                        class="ChangeHeading"
                      >
                        <img src={require("../assets/images/icon-15.png")} />
                      </a>
                    </div>
                  </div>
                  <div class="ChinookListedJobs">
                    <h6 class="listedJobs">
                      All {jobList?.length} Listed jobs
                    </h6>
                    <div class="ListedJobsArea">
                      <ul>
                        {jobList?.map((item, jobIndex) => (
                          <li class={`FirstListing`}>
                            <div class="ListingLeft">
                              <div class="ListingBorder">
                                <span class="Red"></span>
                              </div>
                              <div class="ListingRight">
                                <label class="CheckBox">
                                  {item?.jobNumber}
                                  <input
                                    type="checkbox"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        updateJobListState([
                                          ...jobListState,
                                          item,
                                        ]);
                                      } else {
                                        let temp = [...jobListState];
                                        let index = temp.findIndex(
                                          (x) => x.id === item?.id
                                        );

                                        temp.splice(index, 1);

                                        updateJobListState(temp);
                                      }
                                    }}
                                  />
                                  <span class="checkmark"></span>
                                </label>
                                <a
                                  onClick={() => {
                                    if (state?.projectType == "Multi Family") {
                                      handleMultiBlockListByProject(item?.id);
                                    }
                                  }}
                                >
                                  <img
                                    src={require("../assets/images/icon-34.png")}
                                  />
                                </a>
                              </div>
                            </div>

                            <hr />
                            <ul>
                              {sideJobId == item?.id &&
                                blockShow &&
                                multiblocksList?.length > 0 &&
                                multiblocksList?.map((data, index) => {
                                  let tempMultiStoredata =
                                    data?.jobType == "MultiBlock" &&
                                    [
                                      ...data?.MultiUnits,
                                      ...data?.MultiStories,
                                    ]?.flat();

                                  return (
                                    <>
                                      {" "}
                                      <li>
                                        <div class="ListingLeft">
                                          <div class="ListingBorder">
                                            <span class="Red"></span>
                                            <span class="Yellow"></span>
                                          </div>
                                          <div class="ListingRight">
                                            <label class="CheckBox">
                                              {data?.jobNumber ?? "N.A"}{" "}
                                              <input type="checkbox" />
                                              <span class="checkmark"></span>
                                            </label>
                                            <a
                                              onClick={() =>
                                                handleMultiUnitListByProject(
                                                  data?.id
                                                )
                                              }
                                            >
                                              <img src="images/icon-34.png" />
                                            </a>
                                          </div>
                                        </div>
                                      </li>
                                      <hr />
                                      <ul>
                                        {sideUnitId == data?.id &&
                                          unitShow &&
                                          tempMultiStoredata?.length > 0 &&
                                          tempMultiStoredata?.map((unit) => (
                                            <li>
                                              <div class="ListingLeft">
                                                <div class="ListingBorder">
                                                  <span class="Red"></span>
                                                  <span class="DarkOrange"></span>
                                                  <span class="DarkBrawn"></span>
                                                </div>
                                                <div class="ListingRight">
                                                  <label class="CheckBox">
                                                    {unit?.name ?? "N/A"}{" "}
                                                    <input type="checkbox" />
                                                    <span class="checkmark"></span>
                                                  </label>
                                                </div>
                                              </div>
                                            </li>
                                          ))}
                                      </ul>
                                    </>
                                  );
                                })}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-9">
                <div class="ChinookRight">
                  <div class="row">
                    <div class="col-lg-12 pl-lg-0">
                      <div class="ChinookTabsArea">
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            width: "100%",
                          }}
                        >
                          <div class="ChinookTabs">
                            <ul class="nav nav-tabs">
                              <li class="nav-item">
                                <a
                                  onClick={handleScheduleView}
                                  class="nav-link active"
                                  data-toggle="tab"
                                  href="#scheduleView"
                                >
                                  <span>
                                    <img
                                      src={require("../assets/images/icon-21.png")}
                                    />
                                  </span>
                                  Schedule view
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  onClick={handleGantView}
                                  class="nav-link"
                                  data-toggle="tab"
                                  href="#GrantView"
                                >
                                  <span>
                                    <img
                                      src={require("../assets/images/icon-22.png")}
                                    />
                                  </span>
                                  Gant view
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  onClick={handleCalenderView}
                                  class="nav-link"
                                  data-toggle="tab"
                                  href="#Calendar"
                                >
                                  <span>
                                    <img
                                      src={require("../assets/images/icon-23.png")}
                                    />
                                  </span>
                                  Calendar
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  data-toggle="tab"
                                  href="#ListView"
                                >
                                  <span>
                                    <img
                                      src={require("../assets/images/icon-24.png")}
                                    />
                                  </span>
                                  List View
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  onClick={handleBaseLine}
                                  class="nav-link"
                                  data-toggle="tab"
                                  href="#Baseline"
                                >
                                  <span>
                                    <img
                                      src={require("../assets/images/icon-25.png")}
                                    />
                                  </span>
                                  Baseline
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="TitleBoxNavigate">
                            <div className="NavigateButton gap-2">
                              <button
                                onClick={handleJobMenuModalShow}
                                className="newbtn"
                              >
                                Job Menu
                              </button>
                              <button
                                className="ml-2 newbtn"
                                onClick={handleNavigateModalShow}
                              >
                                Navigate
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="ChinookTabsRight">
                          <div class="ChinookRightIcons">
                            <a
                              onClick={() =>
                                SetConsolidatedChangeOrderCheckListModal(true)
                              }
                            >
                              <img
                                src={require("../assets/images/icon-26.png")}
                              />
                              <span>{changeOrderCheckList?.length}</span>
                            </a>
                            <a onClick={() => SetFieldOrderModal(true)}>
                              <img
                                src={require("../assets/images/icon-27.png")}
                              />
                              <span>{fieldPurchaseOrderList?.length}</span>
                            </a>
                            <a onClick={() => SetFileMangerModal(true)}>
                              <img
                                src={require("../assets/images/icon-28.png")}
                              />
                              <span>{allFolderList?.length}</span>
                            </a>
                          </div>
                          <div class="ChinookTabsRightBtn">
                            <button
                              onClick={() =>
                                SetLockStatus(lockStatus ? false : true)
                              }
                            >
                              {lockStatus ? "Lock" : "Unlock"}
                            </button>
                            <button class="HideDashboard">
                              <img
                                src={require("../assets/images/icon-29.png")}
                              />
                              Hide Dashboard
                            </button>
                            <button class="ShowDashboard">
                              <img
                                src={require("../assets/images/icon-29.png")}
                              />
                              Show Dashboard
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="DurationArea">
                        <div class="row">
                          <div class="col-lg-2">
                            <div class="DurationLeft">
                              <div class="DurationBox">
                                <h6>Total Duration</h6>
                                <p> Workdays</p>
                              </div>
                              <div class="DurationBox">
                                <h6>Days Elapsed</h6>
                                <p> Workdays</p>
                              </div>
                              <div class="DurationBox">
                                <h6>Days Remaining</h6>
                                <p> Workdays</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-10">
                            <div class="DurationRight">
                              <div class="form-group">
                                <label class="mb-1">
                                  Schedule Duration : WorkDays
                                </label>
                                {/* {constructionDashboard &&  */}
                                {/* <ProgressBar bgColor="#28a745" completed={constructionDashboard?.duration} />} */}
                                <div class="d-flex justify-content-between mt-1">
                                  <label>Days Elapsed : WorkDays</label>
                                  <label>Days Remaining: WorkDays</label>
                                </div>
                              </div>

                              <div class="MileStoneArea">
                                <div class="MileStoneLeft">
                                  <div class="MileStoneBox">
                                    <h6>Start</h6>
                                    <img
                                      src={require("../assets/images/icon-44.png")}
                                    />
                                    <h6>01/03/2024</h6>
                                  </div>
                                  <div class="MileStoneBox">
                                    <h6>Start</h6>
                                    <img
                                      src={require("../assets/images/icon-45.png")}
                                    />
                                    <h6>01/03/2024</h6>
                                  </div>
                                  <div class="MileStoneBox">
                                    <h6>Start</h6>
                                    <img
                                      src={require("../assets/images/icon-45.png")}
                                    />
                                    <h6>01/03/2024</h6>
                                  </div>
                                </div>
                                <div class="MileStoneRight">
                                  <div class="MileStoneBox">
                                    <h6>Start</h6>
                                    <img
                                      src={require("../assets/images/icon-45.png")}
                                    />
                                    <h6>04/03/2024</h6>
                                  </div>
                                  <div class="MileStoneBox">
                                    <h6>Start</h6>
                                    <img
                                      src={require("../assets/images/icon-46.png")}
                                    />
                                    <h6>01/03/2024</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="tab-content">
                        <div class="tab-pane active show" id="scheduleView">
                          <div class="TabPaneTop">
                            <div class="TabPaneTopBtn">
                              <button class="Active">Critical Path</button>
                              <button
                                onClick={() => {
                                  updatePhaseLevel({
                                    flag: true,
                                    index: 0,
                                  });

                                  updateTaskLevel({
                                    flag: true,
                                    index: 0,
                                  });
                                }}
                              >
                                Expand
                              </button>
                              <button         onClick={() => {
                                  updatePhaseLevel({
                                    flag: false,
                                    index: 0,
                                  });

                                  updateTaskLevel({
                                    flag: false,
                                    index: 0,
                                  });
                                }}>Collapse</button>
                            </div>
                            <div class="TabPaneTopBtnIcon">
                              <a href="#">
                                <img
                                  src={require("../assets/images/pdf.png")}
                                />
                              </a>
                              <a href="#">
                                <img
                                  src={require("../assets/images/download.png")}
                                />
                              </a>
                              {/* <a href="#">
                                <img
                                  src={require("../assets/images/setting.png")}
                                />
                              </a> */}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12 pr-0">
                              <div class="NestedTable">
                                <table class="table" style={{ width: "150%" }}>
                                  <thead>
                                    <tr class="TheadColor">
                                      <th>Actions</th>
                                      <th>WBS</th>
                                      <th>Task Name</th>
                                      <th>Start</th>
                                      <th>End</th>
                                      <th>Duration</th>
                                      <th>Predecessors</th>
                                      <th>Assigned</th>
                                      <th>Progress</th>
                                      <th>Completion Date</th>

                                      <th>Ok to Pay</th>
                                    </tr>
                                  </thead>
                                  {jobListState?.map((item, i) => (
                                    <tbody>
                                      <tr class="MainTable">
                                        <td>
                                          <div class="Actions">
                                            <a>
                                              <img
                                                src={require("../assets/images/icon-30.png")}
                                              />
                                            </a>
                                            <a
                                              data-toggle="modal"
                                              data-target="#TaskDetails"
                                            >
                                              <img
                                                src={require("../assets/images/edit-32.png")}
                                              />
                                            </a>
                                            <a
                                              href="javascript:void(0);"
                                              data-toggle="modal"
                                              onClick={() => {
                                                handleAddPhaseModalShow(item);
                                              }}
                                            >
                                              <img
                                                src={require("../assets/images/icon-13.png")}
                                              />
                                            </a>
                                          </div>
                                        </td>
                                        <td
                                          onClick={() =>
                                            handleGetPhaseList(item, i)
                                          }
                                        >
                                          <div class="SmallBox">
                                            <strong>{i + 1}.3</strong>
                                          </div>
                                        </td>
                                        <td
                                          onClick={() =>
                                            handleGetPhaseList(item, i)
                                          }
                                        >
                                          <div class="BigBox">
                                            <strong>
                                              <a>
                                                <img
                                                  src={require("../assets/images/plus-2.png")}
                                                />
                                              </a>
                                              &nbsp;{item?.jobNumber}
                                            </strong>
                                          </div>
                                        </td>
                                        <td
                                          onClick={() =>
                                            handleGetPhaseList(item, i)
                                          }
                                        >
                                          <div class="SmallBox">
                                            <strong>2023-04-01</strong>
                                          </div>
                                        </td>
                                        <td
                                          onClick={() =>
                                            handleGetPhaseList(item, i)
                                          }
                                        >
                                          <div class="SmallBox">
                                            <strong>2023-04-01</strong>
                                          </div>
                                        </td>
                                        <td
                                          onClick={() =>
                                            handleGetPhaseList(item, i)
                                          }
                                        >
                                          <div class="SmallBox">
                                            <strong>48</strong>
                                          </div>
                                        </td>
                                        <td
                                          onClick={() =>
                                            handleGetPhaseList(item, i)
                                          }
                                        >
                                          <div class="SmallBox">
                                            <strong></strong>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="SmallBox">
                                            <div class="Actions">
                                              <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#PhaseTaskManagement2"
                                              >
                                                <img
                                                  src={require("../assets/images/icon-31.png")}
                                                />
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="SmallBox">
                                            <strong>54 %</strong>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="SmallBox"></div>
                                        </td>
                                        <td></td>
                                      </tr>

                                      {phaseList?.map((phase, index) => (
                                        <tr>
                                          <td colspan="11" class="padding_none">
                                            <table
                                              class={
                                                phaseLevel.flag &&
                                                phaseLevel.index == i
                                                  ? "sublevel open"
                                                  : "sublevel"
                                              }
                                            >
                                              <thead>
                                                <tr className="FirstNested">
                                                  <th>
                                                    <div class="Actions">
                                                      <a
                                                        onClick={() =>
                                                          handleDeletePhase(
                                                            phase
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={require("../assets/images/icon-30.png")}
                                                        />
                                                      </a>
                                                      <a>
                                                        <img
                                                          src={require("../assets/images/edit-32.png")}
                                                        />
                                                      </a>
                                                      <a
                                                        onClick={() =>
                                                          handleAddTaskModalShow(
                                                            phase,
                                                            "add"
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={require("../assets/images/icon-13.png")}
                                                        />
                                                      </a>
                                                    </div>
                                                  </th>
                                                  <th
                                                    onClick={() =>
                                                      handleGetTaskList(
                                                        phase,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <div class="SmallBox">
                                                      <strong>
                                                        {i + 1}.3.{index + 1}
                                                      </strong>
                                                    </div>
                                                  </th>
                                                  <th
                                                    onClick={() =>
                                                      handleGetTaskList(
                                                        phase,
                                                        i
                                                      )
                                                    }
                                                  >
                                                    <div class="BigBox">
                                                      <strong>
                                                        {phase?.taskName}
                                                      </strong>
                                                    </div>
                                                  </th>
                                                  <th
                                                    onClick={() =>
                                                      handleGetTaskList(
                                                        phase,
                                                        i
                                                      )
                                                    }
                                                  >
                                                    <div class="SmallBox">
                                                      <strong>
                                                        {moment(
                                                          phase?.startDate
                                                        )?.format("YYYY-MM-DD")}
                                                      </strong>
                                                    </div>
                                                  </th>
                                                  <th
                                                    onClick={() =>
                                                      handleGetTaskList(
                                                        phase,
                                                        i
                                                      )
                                                    }
                                                  >
                                                    <div class="SmallBox">
                                                      <strong>
                                                        {moment(
                                                          phase?.endDate
                                                        )?.format(
                                                          "YYYY-MM-DD"
                                                        )}{" "}
                                                      </strong>
                                                    </div>
                                                  </th>
                                                  <th
                                                    onClick={() =>
                                                      handleGetTaskList(
                                                        phase,
                                                        i
                                                      )
                                                    }
                                                  >
                                                    <div class="SmallBox">
                                                      <strong>
                                                        {phase?.duration}
                                                      </strong>
                                                    </div>
                                                  </th>
                                                  <th
                                                    onClick={() =>
                                                      handleGetTaskList(
                                                        phase,
                                                        i
                                                      )
                                                    }
                                                  >
                                                    <div class="SmallBox">
                                                      <strong>{phase?.taskNumber}</strong>
                                                    </div>
                                                  </th>
                                                  <th>
                                                    <div class="SmallBox">
                                                      <div class="Actions">
                                                        <a href="javascript:void(0);">
                                                          <img
                                                            src={require("../assets/images/icon-31.png")}
                                                          />
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </th>
                                                  <th>
                                                    <div class="SmallBox">
                                                      <strong>
                                                        {phase?.progress} %
                                                      </strong>
                                                    </div>
                                                  </th>
                                                  <th>
                                                    <div class="SmallBox"></div>
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody
                                                id={
                                                  taskLevel?.flag &&
                                                  taskLevel.index == index
                                                    ? "subsubLevel open"
                                                    : "subsubLevel"
                                                }
                                              >
                                                {taskList?.map(
                                                  (task, taskIndex) => (
                                                    <tr>
                                                      <td>
                                                        <div class="Actions">
                                                          <a
                                                            onClick={() =>
                                                              handleDeleteTask(
                                                                task
                                                              )
                                                            }
                                                          >
                                                            <img
                                                              src={require("../assets/images/icon-30.png")}
                                                            />
                                                          </a>
                                                          <a
                                                            onClick={
                                                              handleTaskDetailsModalShow
                                                            }
                                                          >
                                                            <img
                                                              src={require("../assets/images/edit-32.png")}
                                                            />
                                                          </a>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="SmallBox ModalHover">
                                                          <strong>
                                                            {i + 1}.3 .
                                                            {index + 1} .
                                                            {taskIndex + 1}
                                                          </strong>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="BigBox pl-3">
                                                          {task?.taskName}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="SmallBox">
                                                          {moment(
                                                            task?.startDate
                                                          )?.format(
                                                            "YYYY-MM-DD"
                                                          )}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="SmallBox">
                                                          {moment(
                                                            task?.endDate
                                                          )?.format(
                                                            "YYYY-MM-DD"
                                                          )}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="SmallBox">
                                                          {task?.duration}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="SmallBox">{task?.taskNumber}</div>
                                                      </td>
                                                      <td>
                                                        <div class="SmallBox">
                                                          <div class="Actions2">
                                                            <a
                                                              onClick={() =>
                                                                setAssignResouceModal(
                                                                  true
                                                                )
                                                              }
                                                              class="AssignResourcesHover"
                                                            >
                                                              <img src="images/icon-32.png" />
                                                              <span class="Count">
                                                                +
                                                              </span>
                                                            </a>
                                                            <a
                                                              onClick={() =>
                                                                setTaskAssignModal(
                                                                  true
                                                                )
                                                              }
                                                              data-toggle="modal"
                                                              data-target="#ContactModal"
                                                            >
                                                              <img src="images/icon-green.png" />
                                                              <span class="Count">
                                                                3
                                                              </span>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="SmallBox">
                                                          {task?.progress} %
                                                        </div>
                                                      </td>

                                                      <td>
                                                        <div class="SmallBox">
                                                          <div class="Actions">
                                                            <a
                                                              onClick={() =>
                                                                setOkaypayModal(
                                                                  true
                                                                )
                                                              }
                                                              data-toggle="modal"
                                                              data-target="#OkModal"
                                                            >
                                                              <img src="images/dollar.png" />
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </td>{" "}
                                        </tr>
                                      ))}
                                    </tbody>
                                  ))}
                                </table>
                              </div>
                            </div>

                            {/* <div className="col-lg-6 pl-0">
                              <div class="GrantTable">
                                {ScheduleViewShow && (
                                  <div className="gantt-container">
                                    <GanttSchedule
                                      tasks={allTask}
                                      zoom={currentZoom}
                                      SetPhaseTaskManagementModal={
                                        SetPhaseTaskManagementModal
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div class="tab-pane fade" id="GrantView">
                          <div class="TabPaneTop">
                            <div class="TabPaneTopBtn">
                              <button class="Active">Critical Path</button>
                              <button>Expand</button>
                              <button>Collapse</button>
                            </div>
                            <div class="TabPaneTopBtnIcon">
                              <a href="#">
                                <img
                                  src={require("../assets/images/pdf.png")}
                                />
                              </a>
                              <a href="#">
                                <img
                                  src={require("../assets/images/download.png")}
                                />
                              </a>
                              {/* <a href="#">
                                <img
                                  src={require("../assets/images/setting.png")}
                                />
                              </a> */}
                            </div>
                          </div>
                          <div class="GrantTable">
                            <Toolbar
                              zoom={currentZoom}
                              onZoomChange={handleZoomChange}
                            />
                            <div className="gantt-container">
                              {rerender && (
                                <Gantt
                                  jobId={jobListState?.[0]?.id}
                                  tasks={allTask}
                                  // tasks={data}
                                  zoom={currentZoom}
                                  SetPhaseTaskManagementModal={
                                    SetPhaseTaskManagementModal
                                  }
                                  // onDataUpdated={this.logDataUpdate}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="Calendar">
                          <div class="TabPaneTop">
                            <div class="TabPaneTopBtn">
                              <button class="Active">Critical Path</button>
                            </div>
                            <div class="TabPaneTopBtnIcon">
                              <a href="#">
                                <img src="images/pdf.png" />
                              </a>
                              <a href="#">
                                <img src="images/download.png" />
                              </a>
                              {/* <a href="#">
                                <img src="images/setting.png" />
                              </a> */}
                            </div>
                          </div>
                          <Calendar events={events} />

                          <div>
                            {/* <div className="tool-bar">
                              <Toolbar
                                timeFormatState={currentTimeFormatState}
                                // onTimeFormatStateChange={this.handleTimeFormatStateChange}
                              />
                            </div>
                            <div className="scheduler-container">
                              <SchedulerToolBar />

                              {calendarViewShow && (
                                <Scheduler
                                  events={schedulerData}
                                  timeFormatState={true}
                                />
                              )}
                            </div> */}
                          </div>
                        </div>

                        <div class="tab-pane fade" id="ListView">
                          <div class="TabPaneTop">
                            <div class="TabPaneTopBtn">
                              <button class="Active">Critical Path</button>
                              <button>Expand</button>
                              <button>Collapse</button>
                            </div>
                            <div class="TabPaneTopBtnIcon">
                              <a href="#">
                                <img src="images/pdf.png" />
                              </a>
                              <a href="#">
                                <img src="images/download.png" />
                              </a>
                              {/* <a href="#">
                                <img src="images/setting.png" />
                              </a> */}
                            </div>
                          </div>
                          <div className="NestedTable">
                            <table class="table" style={{ width: "150%" }}>
                              <thead>
                                <tr class="TheadColor">
                                  <th>Actions</th>
                                  <th>WBS</th>
                                  <th>Tadk Name</th>
                                  <th>Start</th>
                                  <th>End</th>
                                  <th>Duration</th>
                                  <th>Predecessors</th>
                                  <th>Assigned</th>
                                  <th>Progress</th>
                                  <th>Ok to Pay</th>
                                </tr>
                              </thead>
                              {jobListState?.map((item, i) => (
                                <tbody>
                                  <tr class="MainTable">
                                    <td>
                                      <div class="Actions">
                                        <a>
                                          <img
                                            src={require("../assets/images/icon-30.png")}
                                          />
                                        </a>
                                        <a
                                          data-toggle="modal"
                                          data-target="#TaskDetails"
                                        >
                                          <img
                                            src={require("../assets/images/edit-32.png")}
                                          />
                                        </a>
                                        <a
                                          href="javascript:void(0);"
                                          data-toggle="modal"
                                          onClick={() => {
                                            handleAddPhaseModalShow(item);
                                          }}
                                        >
                                          <img
                                            src={require("../assets/images/icon-13.png")}
                                          />
                                        </a>
                                      </div>
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleGetPhaseList(item, i)
                                      }
                                    >
                                      <div class="SmallBox">
                                        <strong>{i + 1}.3</strong>
                                      </div>
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleGetPhaseList(item, i)
                                      }
                                    >
                                      <div class="BigBox">
                                        <strong>
                                          <a>
                                            <img
                                              src={require("../assets/images/plus-2.png")}
                                            />
                                          </a>
                                          &nbsp;{item?.jobNumber}
                                        </strong>
                                      </div>
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleGetPhaseList(item, i)
                                      }
                                    >
                                      <div class="SmallBox">
                                        <strong>2023-04-01</strong>
                                      </div>
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleGetPhaseList(item, i)
                                      }
                                    >
                                      <div class="SmallBox">
                                        <strong>2023-04-01</strong>
                                      </div>
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleGetPhaseList(item, i)
                                      }
                                    >
                                      <div class="SmallBox">
                                        <strong>48</strong>
                                      </div>
                                    </td>
                                    <td
                                      onClick={() =>
                                        handleGetPhaseList(item, i)
                                      }
                                    >
                                      <div class="SmallBox">
                                        <strong></strong>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="SmallBox">
                                        <div class="Actions">
                                          <a
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#PhaseTaskManagement2"
                                          >
                                            <img
                                              src={require("../assets/images/icon-31.png")}
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="SmallBox">
                                        <strong>54 %</strong>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="SmallBox"></div>
                                    </td>
                                  </tr>

                                  {phaseList?.map((phase, index) => (
                                    <tr>
                                      <td colspan="11" class="padding_none">
                                        <table
                                          class={
                                            phaseLevel.flag &&
                                            phaseLevel.index == i
                                              ? "sublevel open"
                                              : "sublevel"
                                          }
                                        >
                                          <thead>
                                            <tr>
                                              <th>
                                                <div class="Actions">
                                                  <a
                                                    onClick={() =>
                                                      handleDeletePhase(phase)
                                                    }
                                                  >
                                                    <img
                                                      src={require("../assets/images/icon-30.png")}
                                                    />
                                                  </a>
                                                  <a>
                                                    <img
                                                      src={require("../assets/images/edit-32.png")}
                                                    />
                                                  </a>
                                                  <a
                                                    onClick={() =>
                                                      handleAddTaskModalShow(
                                                        phase,
                                                        "add"
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={require("../assets/images/icon-13.png")}
                                                    />
                                                  </a>
                                                </div>
                                              </th>
                                              <th
                                                onClick={() =>
                                                  handleGetTaskList(phase, i)
                                                }
                                              >
                                                <div class="SmallBox">
                                                  <strong>
                                                    {i + 1}.3.{index + 1}
                                                  </strong>
                                                </div>
                                              </th>
                                              <th
                                                onClick={() =>
                                                  handleGetTaskList(phase, i)
                                                }
                                              >
                                                <div class="BigBox">
                                                  <strong>
                                                    {phase?.taskName}
                                                  </strong>
                                                </div>
                                              </th>
                                              <th
                                                onClick={() =>
                                                  handleGetTaskList(phase, i)
                                                }
                                              >
                                                <div class="SmallBox">
                                                  <strong>
                                                    {moment(
                                                      phase?.startDate
                                                    )?.format("YYYY-MM-DD")}
                                                  </strong>
                                                </div>
                                              </th>
                                              <th
                                                onClick={() =>
                                                  handleGetTaskList(phase, i)
                                                }
                                              >
                                                <div class="SmallBox">
                                                  <strong>
                                                    {moment(
                                                      phase?.endDate
                                                    )?.format(
                                                      "YYYY-MM-DD"
                                                    )}{" "}
                                                  </strong>
                                                </div>
                                              </th>
                                              <th
                                                onClick={() =>
                                                  handleGetTaskList(phase, i)
                                                }
                                              >
                                                <div class="SmallBox">
                                                  <strong>
                                                    {phase?.duration}
                                                  </strong>
                                                </div>
                                              </th>
                                              <th
                                                onClick={() =>
                                                  handleGetTaskList(phase, i)
                                                }
                                              >
                                                <div class="SmallBox">
                                                  <strong></strong>
                                                </div>
                                              </th>
                                              <th>
                                                <div class="SmallBox">
                                                  <div class="Actions">
                                                    <a href="javascript:void(0);">
                                                      <img
                                                        src={require("../assets/images/icon-31.png")}
                                                      />
                                                    </a>
                                                  </div>
                                                </div>
                                              </th>
                                              <th>
                                                <div class="SmallBox">
                                                  <strong>
                                                    {phase?.progress} %
                                                  </strong>
                                                </div>
                                              </th>
                                              <th>
                                                <div class="SmallBox"></div>
                                              </th>
                                              <th>
                                                <div class="SmallBox"></div>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody
                                            id={
                                              taskLevel?.flag &&
                                              taskLevel.index == index
                                                ? "subsubLevel open"
                                                : "subsubLevel"
                                            }
                                          >
                                            {taskList?.map(
                                              (task, taskIndex) => (
                                                <tr>
                                                  <td>
                                                    <div class="Actions">
                                                      <a
                                                        onClick={() =>
                                                          handleDeleteTask(task)
                                                        }
                                                      >
                                                        <img
                                                          src={require("../assets/images/icon-30.png")}
                                                        />
                                                      </a>
                                                      <a
                                                        onClick={
                                                          handleTaskDetailsModalShow
                                                        }
                                                      >
                                                        <img
                                                          src={require("../assets/images/edit-32.png")}
                                                        />
                                                      </a>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="SmallBox ModalHover">
                                                      <strong>
                                                        {i + 1}.3 .{index + 1} .
                                                        {taskIndex + 1}
                                                      </strong>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="BigBox pl-3">
                                                      {task?.taskName}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="SmallBox">
                                                      {moment(
                                                        task?.startDate
                                                      )?.format("YYYY-MM-DD")}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="SmallBox">
                                                      {moment(
                                                        task?.endDate
                                                      )?.format("YYYY-MM-DD")}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="SmallBox">
                                                      {task?.duration}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="SmallBox">{task?.taskNumber}</div>
                                                  </td>
                                                  <td>
                                                    <div class="SmallBox">
                                                      <div class="Actions2">
                                                        <a
                                                          onClick={() =>
                                                            setTaskAssignModal(
                                                              true
                                                            )
                                                          }
                                                          class="AssignResourcesHover"
                                                        >
                                                          <img src="images/icon-32.png" />
                                                          <span class="Count">
                                                            +
                                                          </span>
                                                        </a>
                                                        <a
                                                          onClick={() =>
                                                            setTaskAssignModal(
                                                              true
                                                            )
                                                          }
                                                          data-toggle="modal"
                                                        >
                                                          <img src="images/icon-green.png" />
                                                          <span class="Count">
                                                            3
                                                          </span>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="SmallBox">
                                                      {task?.progress} %
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="SmallBox"></div>
                                                  </td>
                                                  <td>
                                                    <div class="SmallBox">
                                                      <div class="Actions">
                                                        <a
                                                          onClick={() =>
                                                            setTaskAssignModal(
                                                              true
                                                            )
                                                          }
                                                          data-toggle="modal"
                                                          data-target="#OkModal"
                                                        >
                                                          <img src="images/dollar.png" />
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </td>{" "}
                                    </tr>
                                  ))}
                                </tbody>
                              ))}
                            </table>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="Baseline">
                          <div class="TabPaneTop">
                            <div class="TabPaneTopBtn">
                              <button>Expand</button>
                              <button>Collapse</button>
                            </div>
                            <div class="TabPaneTopBtnIcon">
                              <a href="#">
                                <img src="images/pdf.png" />
                              </a>
                              <a href="#">
                                <img src="images/download.png" />
                              </a>
                              {/* <a href="#">
                                <img src="images/setting.png" />
                              </a> */}
                            </div>
                          </div>

                          <div class="BaselineTable">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th rowspan="2">Complete Title</th>
                                  <th colspan="2">Duration</th>
                                  <th rowspan="2">Duration Slip</th>
                                  <th colspan="2">Start date</th>
                                  <th colspan="2">End date</th>
                                  <th rowspan="2">End Date Slip</th>
                                  <th rowspan="2">Direct Shifts</th>
                                  <th rowspan="2">Latest Shift Reason</th>
                                  <th rowspan="2">Latest Shift Notes</th>
                                </tr>
                                <tr>
                                  <th>Expected</th>
                                  <th>Actual</th>
                                  <th>Expected</th>
                                  <th>Actual</th>
                                  <th>Expected</th>
                                  <th>Actual</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* <tr class="BaselineShow">
                                  <td>
                                    <div class="BigBox">
                                      <i
                                        class="fa fa-caret-down"
                                        aria-hidden="true"
                                      ></i>
                                      <label class="CheckBox">
                                        {" "}
                                        Chinook Moon Homes B-3 Unit 1
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="SmallBox">+2 Workdays</div>
                                  </td>
                                  <td>
                                    <div class="SmallBox">+2 Workdays</div>
                                  </td>
                                  <td>
                                    <div class="SmallBox">0 Workdays</div>
                                  </td>
                                  <td>
                                    <div class="SmallBox">02/05/2023</div>
                                  </td>
                                  <td>
                                    <div class="SmallBox">02/07/2023</div>
                                  </td>
                                  <td>
                                    <div class="SmallBox">02/05/2023</div>
                                  </td>
                                  <td>
                                    <div class="SmallBox">02/07/2023</div>
                                  </td>
                                  <td>
                                    <div class="SmallBox">-2 Workdays</div>
                                  </td>
                                  <td>
                                    <div class="SmallBox">1</div>
                                  </td>
                                  <td>
                                    <div class="SmallBox"></div>
                                  </td>
                                  <td>
                                    <div class="SmallBox">In-Line Edit</div>
                                  </td>
                                </tr>

                                <tr class="BaselineHide">
                                  <td colspan="12" class="padding_none">
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div class="BigBox">
                                              <i
                                                class="fa fa-caret-right"
                                                aria-hidden="true"
                                              ></i>
                                              <label class="CheckBox">
                                                {" "}
                                                Chinook Moon Homes B-3 Unit 1
                                                <input type="checkbox" />
                                                <span class="checkmark"></span>
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              +2 Workdays
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              +2 Workdays
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              0 Workdays
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              02/05/2023
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              02/07/2023
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              02/05/2023
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              02/07/2023
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              -2 Workdays
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">1</div>
                                          </td>
                                          <td>
                                            <div class="SmallBox"></div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              In-Line Edit
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div class="BigBox">
                                              <i
                                                class="fa fa-caret-right"
                                                aria-hidden="true"
                                              ></i>
                                              <label class="CheckBox">
                                                {" "}
                                                Chinook Moon Homes B-3 Unit 1
                                                <input type="checkbox" />
                                                <span class="checkmark"></span>
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              +2 Workdays
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              +2 Workdays
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              0 Workdays
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              02/05/2023
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              02/07/2023
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              02/05/2023
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              02/07/2023
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              -2 Workdays
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">1</div>
                                          </td>
                                          <td>
                                            <div class="SmallBox"></div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              In-Line Edit
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div class="BigBox">
                                              <i
                                                class="fa fa-caret-right"
                                                aria-hidden="true"
                                              ></i>
                                              <label class="CheckBox">
                                                {" "}
                                                Chinook Moon Homes B-3 Unit 1
                                                <input type="checkbox" />
                                                <span class="checkmark"></span>
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              +2 Workdays
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              +2 Workdays
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              0 Workdays
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              02/05/2023
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              02/07/2023
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              02/05/2023
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              02/07/2023
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              -2 Workdays
                                            </div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">1</div>
                                          </td>
                                          <td>
                                            <div class="SmallBox"></div>
                                          </td>
                                          <td>
                                            <div class="SmallBox">
                                              In-Line Edit
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr> */}
                                {jobListState?.map((item) => (
                                  <tr>
                                    <td>
                                      <div class="BigBox">
                                        <i
                                          class="fa fa-caret-down"
                                          aria-hidden="true"
                                        ></i>
                                        <label class="CheckBox">
                                          {" "}
                                          {item?.jobNumber}{" "}
                                          <input type="checkbox" />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="SmallBox">+2 Workdays</div>
                                    </td>
                                    <td>
                                      <div class="SmallBox">+2 Workdays</div>
                                    </td>
                                    <td>
                                      <div class="SmallBox">0 Workdays</div>
                                    </td>
                                    <td>
                                      <div class="SmallBox">02/05/2023</div>
                                    </td>
                                    <td>
                                      <div class="SmallBox">02/07/2023</div>
                                    </td>
                                    <td>
                                      <div class="SmallBox">02/05/2023</div>
                                    </td>
                                    <td>
                                      <div class="SmallBox">02/07/2023</div>
                                    </td>
                                    <td>
                                      <div class="SmallBox">-2 Workdays</div>
                                    </td>
                                    <td>
                                      <div class="SmallBox">1</div>
                                    </td>
                                    <td>
                                      <div class="SmallBox"></div>
                                    </td>
                                    <td>
                                      <div class="SmallBox">In-Line Edit</div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="DiscussionArea">
            <div class="DiscussionTitle">
              <div>
                <span
                  style={{
                    backgroundColor: "rgb(252, 52, 0)",
                    borderRadius: "50%",
                    color: "white",
                    marginLeft: "100px",
                    padding: "1px 5px",
                  }}
                >
                  {discussionList?.length}
                </span>

                <h4>Discussion</h4>
              </div>

              <div class="DiscussionChat">
                <img
                  src={require("../assets/images/down.png")}
                  class="DiscussionIcon"
                />
                <a>
                  <img src={require("../assets/images/pdf2.png")} />
                </a>
                <a onClick={() => handleDiscussionSettingsModalShow()}>
                  <img src="images/setting.png" />
                </a>
              </div>
            </div>
            <div class="DiscussionHideArea" style={{ display: "block" }}>
              <div class="DiscussionBox">
                <span onClick={() => setTaskAssignModal(true)}>
                  {iBuildLocalData?.user?.name.charAt(0) +
                    iBuildLocalData?.user?.name.charAt(1)}
                </span>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    value={discussionState?.message}
                    onChange={(e) =>
                      updateDiscussionState({
                        ...discussionState,
                        message: e.target.value,
                      })
                    }
                    placeholder="Add a Comment ....."
                  />
                  {discussionState.discussionError &&
                    !discussionState.message && (
                      <p style={{ color: "red" }}>
                        {discussionState.discussionError}
                      </p>
                    )}

                  <div class="DiscussionOverlay">
                    <a
                      onClick={() =>
                        updateDiscussionState({
                          ...discussionState,
                          message: "",
                        })
                      }
                      class="CancelChat"
                    >
                      <img src={require("../assets/images/plus-cancel1.png")} />
                    </a>
                    <a onClick={handleAddDiscussion} class="SendChat">
                      <img
                        src={require("../assets/images/forwardbutton.png")}
                      />
                    </a>
                  </div>
                </div>
              </div>
              {discussionList?.map((item, i) => {
                return (
                  <>
                    <div class="DiscussionBox">
                      <span>{"NA"}</span>
                      <div class="DiscussionRight">
                        <h6>{item?.message}</h6>
                        <small>{moment(item.updatedAt).format("lll")}</small>
                      </div>
                    </div>
                    <div class="ReplyArea">
                      <a
                        onClick={(e) => {
                          updateReplyField({ flag: true, index: i });
                          updateReplyState({ ...replyState, message: "" });
                        }}
                      >
                        Reply
                      </a>
                      {item?.ReplyDiscussions?.map((ele) => (
                        <div class="DiscussionBox">
                          <span>
                            {iBuildLocalData?.user?.name.charAt(0) +
                              iBuildLocalData?.user?.name.charAt(1)}
                          </span>
                          <div class="DiscussionRight">
                            <h6>{ele?.message}</h6>
                            <small>{moment(ele.updatedAt).format("lll")}</small>
                          </div>
                        </div>
                      ))}

                      {replyField.index == i && (
                        <div class="DiscussionBox w-100">
                          <span>
                            {iBuildLocalData?.user?.name.charAt(0) +
                              iBuildLocalData?.user?.name.charAt(1)}
                          </span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={replyState?.message}
                              onChange={(e) =>
                                updateReplyState({
                                  ...replyState,
                                  message: e.target.value,
                                  messageId: item?.id,
                                  jobId: item?.jobId,
                                })
                              }
                              placeholder="Add reply ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddReply} class="SendChat">
                                <img
                                  src={require("../assets/images/forwardbutton.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={AddTaskModal}
        onHide={handleAddTaskModalClose}
        className="ModalBox SmallModal"
      >
        <div class="modal-content">
          <a
            onClick={handleAddTaskModalClose}
            className="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Add New Task</h4>
            </div>
            <div class="FolderForm">
              <form>
                <div class="form-group">
                  <h6>
                    Task Name :<span class="required"></span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    value={taskState?.taskName}
                    onChange={(e) =>
                      updateTaskState((prev) => ({
                        ...prev,
                        taskName: e.target.value,
                      }))
                    }
                    placeholder="Insert Phsase Name"
                  />
                  {phaseState.errorMsg.taskName && !phaseState.taskName && (
                    <p style={{ color: "red" }}>
                      {phaseState.errorMsg.taskName}
                    </p>
                  )}
                </div>

                <div class="Filter row">
                  <div class="form-group col-lg-6">
                    <label>Start</label>
                    <input
                      type="date"
                      value={taskState?.startDate}
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={(e) =>
                        updateTaskState((prev) => ({
                          ...prev,
                          startDate: e.target.value,
                        }))
                      }
                      class="form-control"
                    />

                    {phaseState.errorMsg.startDate && !phaseState.startDate && (
                      <p style={{ color: "red" }}>
                        {phaseState.errorMsg.startDate}
                      </p>
                    )}
                  </div>
                  <div class="form-group col-lg-6">
                    <label>End</label>
                    <input
                      type="date"
                      value={taskState?.endDate}
                      min={taskState?.startDate}
                      onChange={(e) =>
                        updateTaskState((prev) => ({
                          ...prev,
                          endDate: e.target.value,
                        }))
                      }
                      class="form-control"
                    />
                    {phaseState.errorMsg.endDate && !phaseState.endDate && (
                      <p style={{ color: "red" }}>
                        {phaseState.errorMsg.endDate}
                      </p>
                    )}
                  </div>
                </div>

                <div class="Filter row">
                  <div class="form-group col-lg-6">
                    <label>Duration</label>
                    <input
                      value={taskState?.duration}
                      onChange={(e) => {
                        if (!/^[0-9]{0,10}$/.test(e.target.value)) return;
                        updateTaskState((prev) => ({
                          ...prev,
                          duration: e.target.value,
                        }));
                      }}
                      type="text"
                      class="form-control"
                    />
                    {phaseState.errorMsg.duration && !phaseState.duration && (
                      <p style={{ color: "red" }}>
                        {phaseState.errorMsg.duration}
                      </p>
                    )}
                  </div>
                </div>
                <div class="form-group">
                  <h6>Predecessor</h6>
                  <div class="Categories Categories1">
                    <input
                      value={taskState?.taskNumber}
                      onChange={(e) => {
                        if (!/^[0-9]{0,10}$/.test(e.target.value)) return;
                        updateTaskState((prev) => ({
                          ...prev,
                          taskNumber: e.target.value,
                        }));
                      }}
                      type="text"
                      class="form-control"
                    />{" "}
                    {phaseState.errorMsg.taskNumber &&
                      !phaseState.taskNumber && (
                        <p style={{ color: "red" }}>
                          {phaseState.errorMsg.taskNumber}
                        </p>
                      )}
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-8">
                    <div class="RushRoomEarnArea">
                      <div class="RushRoomEarnLeft">
                        <h6 class="mb-0">Progress</h6>
                        <Slider
                          getAriaLabel={() => "Temperature range"}
                          onChange={(value) =>
                            updateTaskState({
                              ...taskState,
                              progress: value,
                            })
                          }
                          valueLabelDisplay="auto"
                          getAriaValueText="10"
                          tipFormatter={(value) => (
                            <span className="tooltip">{value}€</span>
                          )}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="Button d-flex justify-content-between mt-5">
                  <button class="Create" onClick={handleSubmitTask}>
                    Save
                  </button>
                  <button onClick={handleAddTaskModalClose}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={AddPhaseModal}
        onHide={handleAddPhaseModalClose}
        className="ModalBox SmallModal"
      >
        <a
          onClick={handleAddPhaseModalClose}
          className="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>

        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Add New Phase</h4>
          </div>
          <div class="FolderForm">
            <form>
              <div class="form-group">
                <h6>
                  Phase Name :<span class="required"></span>
                </h6>
                <input
                  type="text"
                  class="form-control"
                  value={phaseState?.taskName}
                  onChange={(e) =>
                    updatePhaseState((prev) => ({
                      ...prev,
                      taskName: e.target.value,
                    }))
                  }
                  placeholder="Insert Phsase Name"
                />
                {phaseState.errorMsg.taskName && !phaseState.taskName && (
                  <p style={{ color: "red" }}>{phaseState.errorMsg.taskName}</p>
                )}
              </div>

              <div class="Filter row">
                <div class="form-group col-lg-6">
                  <label>Start</label>
                  <input
                    type="date"
                    value={phaseState?.startDate}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      updatePhaseState((prev) => ({
                        ...prev,
                        startDate: e.target.value,
                      }))
                    }
                    class="form-control"
                  />

                  {phaseState.errorMsg.startDate && !phaseState.startDate && (
                    <p style={{ color: "red" }}>
                      {phaseState.errorMsg.startDate}
                    </p>
                  )}
                </div>
                <div class="Filter row">
                  <div class="form-group col-lg-6">
                    <label>Duration</label>
                    <input
                      value={phaseState?.duration}
                      onChange={(e) => {
                        if (!/^[0-9]{0,10}$/.test(e.target.value)) return;
                        updatePhaseState((prev) => ({
                          ...prev,
                          duration: e.target.value,
                        }));
                      }}
                      type="text"
                      class="form-control"
                    />
                    {phaseState.errorMsg.duration && !phaseState.duration && (
                      <p style={{ color: "red" }}>
                        {phaseState.errorMsg.duration}
                      </p>
                    )}
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label>End</label>
                  <input
                    type="date"
                    value={moment(phaseEndDay).format("YYYY-MM-DD")}
                    disabled={true}
                    class="form-control"
                  />
                  {phaseState.errorMsg.endDate && !phaseState.endDate && (
                    <p style={{ color: "red" }}>
                      {phaseState.errorMsg.endDate}
                    </p>
                  )}
                </div>
              </div>

              <div class="form-group">
                <h6>Predecessor</h6>
                <div class="Categories Categories1">
                  <input
                    value={phaseState?.taskNumber}
                    onChange={(e) => {
                      updatePhaseState((prev) => ({
                        ...prev,
                        taskNumber: e.target.value,
                      }));
                    }}
                    type="text"
                    class="form-control"
                  />{" "}
                  {phaseState.errorMsg.taskNumber && !phaseState.taskNumber && (
                    <p style={{ color: "red" }}>
                      {phaseState.errorMsg.taskNumber}
                    </p>
                  )}
                </div>
              </div>

              <div class="row">
                <div class="col-lg-8">
                  <div class="RushRoomEarnArea">
                    <div class="RushRoomEarnLeft">
                      <h6 class="mb-0">Progress</h6>
                      <Slider
                        getAriaLabel={() => "Temperature range"}
                        onChange={(value) =>
                          updatePhaseState({
                            ...phaseState,
                            progress: value,
                          })
                        }
                        valueLabelDisplay="auto"
                        getAriaValueText="10"
                        tipFormatter={(value) => (
                          <span className="tooltip">{value}€</span>
                        )}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div class="Button d-flex justify-content-between mt-5">
                <button class="Create" onClick={handleSubmitPhase}>
                  Save
                </button>
                <button class="Outline" onClick={handleAddPhaseModalClose}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal show={TaskDetailsModal} className="ModalBox LargeModal">
        <a
          onClick={handleTaskDetailsModalClose}
          class="CloseModal ClosePermissionId"
          data-dismiss="modal"
        >
          ×
        </a>
        <div class="FormArea">
          <div class="TitleBox">
            <h4 class="Title">Task Details</h4>
            <a href="#">
              {" "}
              <img src="images/Icon-4.png" />
            </a>
          </div>
          <div class="FolderForm">
            <form>
              <div class="JobTitleArea mb-2">
                <div class="JobBox">
                  <h6>
                    <span>Job:</span>&nbsp;Chinook Moon Homes B-3 Unit 1
                  </h6>
                </div>
                <div class="Filter FilterTaskDetails ">
                  <div class="form-group">
                    <label>Duration</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
              </div>

              <div class="Filter FilterTaskDetails">
                <div class="form-group">
                  <label>Start</label>
                  <input type="date" class="form-control" />
                </div>
                <div class="form-group">
                  <label>End</label>
                  <input type="date" class="form-control" />
                </div>
                <div class="form-group">
                  <label>Duration</label>
                  <input type="text" class="form-control" />
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <h6>Predecessor</h6>
                    <div class="Categories Categories1">
                      <div class="Categories_all Categories_all1">
                        <span>Select or Type Task number</span>
                        <img src="images/down.png" />
                      </div>
                      <ul style={{ display: "none" }}>
                        <li>
                          <input type="text" placeholder="-Search" />
                          <span class="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        <li>- List of task loaded by the system</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <div class="RushRoomEarnArea">
                      <div class="RushRoomEarnLeft">
                        <h6 class="mb-0">Progress</h6>
                        <input type="range" name="" class="mb-0" />
                        <h6>50%</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="JobTitleArea mb-1">
                <div class="JobBox">
                  <h6>
                    <span>Account:</span>&nbsp;3-0102 Survey &amp; Stake
                  </h6>
                </div>
              </div>
              <div class="form-group">
                <div class="PermissionsName">
                  <h5
                    class="FolderPermissionId"
                    data-toggle="modal"
                    data-target="#SelectAccountTaskDetails"
                    data-dismiss="modal"
                  >
                    <span>
                      <img src="images/icon-43.png" />
                    </span>{" "}
                    Select Account
                  </h5>
                </div>
              </div>
              <div>
                <h6>Purchase Details</h6>
              </div>
              <div class="TableList DarkHead my-4">
                <table class="table">
                  <thead class="thead-dark">
                    <tr>
                      <th>Ok to Pay</th>
                      <th>Primary Doc #</th>
                      <th>Q.R.</th>
                      <th>R.O. Total</th>
                      <th>Field Orders Total</th>
                      <th>Grand Total</th>
                      <th>Workflow Status</th>
                      <th>Vendor/Supplier</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="TableList DarkHead my-4">
                <table class="table">
                  <thead class="thead-dark">
                    <tr>
                      <th>Ok to Pay</th>
                      <th>Primary Doc #</th>
                      <th>Q.R.</th>
                      <th>R.O. Total</th>
                      <th>Field Orders Total</th>
                      <th>Grand Total</th>
                      <th>Workflow Status</th>
                      <th>Vendor/Supplier</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                    <tr>
                      <td>
                        <label class="CheckBox">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>3C-12-3-286</td>
                      <td>$1,360.00</td>
                      <td>$1,360.00</td>
                      <td>$1,842.00</td>
                      <td>$3,202.00</td>
                      <td>P.O. Accepted</td>
                      <td>A &amp; G Construction</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="StaffMemberArea StaffMemberAreaConst">
                <aside>
                  <h6>Field Purchase Order</h6>
                  <div class="d-flex">
                    <div class="StaffName">
                      <img src="images/send.png" />
                      <h6>Send</h6>
                    </div>
                    <div class="StaffName ml-5">
                      <img src="images/view.png" />
                      <h6>View</h6>
                    </div>
                  </div>
                </aside>
              </div>

              <div class="StaffMemberArea StaffMemberAreaConst">
                <aside>
                  <h6>Add Notes</h6>
                  <div
                    class="StaffName FolderPermissionId"
                    data-toggle="modal"
                    data-target="#NoteAddTaskDetails"
                    data-dismiss="modal"
                  >
                    <img src="images/Action-4.png" />
                    <h6>Add Notes</h6>
                  </div>
                </aside>
              </div>
              <h6>Assigned Resources</h6>
              <div class="TableList my-4">
                <table>
                  <thead>
                    <tr>
                      <th>Resources</th>
                      <th>Type</th>
                      <th>Reminder</th>
                      <th>Confirmation Sent with date</th>
                      <th>Confirmation Accepted with date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>John Doe</td>
                      <td>Staff Member</td>
                      <td>3 Days</td>
                      <td>02/05/2023</td>
                      <td>02/02/2023</td>
                      <td>
                        <a>
                          <img src="images/icon-41.png" />
                        </a>
                        <a>
                          <img src="images/icon-42.png" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Plumber LTD</td>
                      <td>Vendor</td>
                      <td>1 week</td>
                      <td>02/02/2023</td>
                      <td>02/02/2023</td>
                      <td>
                        <a>
                          <img src="images/icon-41.png" />
                        </a>
                        <a>
                          <img src="images/icon-42.png" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Plumber LTD</td>
                      <td>Vendor</td>
                      <td>1 week</td>
                      <td>02/02/2023</td>
                      <td>02/02/2023</td>
                      <td>
                        <a>
                          <img src="images/icon-41.png" />
                        </a>
                        <a>
                          <img src="images/icon-42.png" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Plumber LTD</td>
                      <td>Vendor</td>
                      <td>1 week</td>
                      <td>02/02/2023</td>
                      <td>02/02/2023</td>
                      <td>
                        <a>
                          <img src="images/icon-41.png" />
                        </a>
                        <a>
                          <img src="images/icon-42.png" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Plumber LTD</td>
                      <td>Vendor</td>
                      <td>1 week</td>
                      <td>02/02/2023</td>
                      <td>02/02/2023</td>
                      <td>
                        <a>
                          <img src="images/icon-41.png" />
                        </a>
                        <a>
                          <img src="images/icon-42.png" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Plumber LTD</td>
                      <td>Vendor</td>
                      <td>1 week</td>
                      <td>02/02/2023</td>
                      <td>02/02/2023</td>
                      <td>
                        <a>
                          <img src="images/icon-41.png" />
                        </a>
                        <a>
                          <img src="images/icon-42.png" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Plumber LTD</td>
                      <td>Vendor</td>
                      <td>1 week</td>
                      <td>02/02/2023</td>
                      <td>02/02/2023</td>
                      <td>
                        <a>
                          <img src="images/icon-41.png" />
                        </a>
                        <a>
                          <img src="images/icon-42.png" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="StaffMemberArea StaffMemberAreaConst">
                <aside>
                  <h6>Assign New Resources</h6>
                  <div
                    class="StaffName FolderPermissionId"
                   onClick={()=> {
                    setTaskDetails(false)
                    setAssignResouceModal(true)}}
                  >
                    <img src="images/icon-32.png" />
                    <h6>Assign New Resources</h6>
                  </div>
                </aside>
              </div>
              <div class="Button d-flex">
                <button
                  class="Create FolderPermissionId"
                  data-toggle="modal"
                  data-target="#PhaseTaskManagement"
                  data-dismiss="modal"
                >
                  Update
                </button>
                <button class="Outline ml-5">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal show={PhaseTaskManagementModal} className="ModalBox LargeModal">
        <a
          onClick={handlePhaseTaskManagementModalClose}
          class="CloseModal ClosePermissionId"
          data-dismiss="modal"
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Phase Task Management</h4>
          </div>
          <div class="PhoneTaskManagement">
            <h4>Phase Task Management</h4>
            <div class="TableList">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Action</th>
                    <th>Task</th>
                    <th>Account</th>
                    <th>Start</th>
                    <th>Start members</th>
                    <th>Vendor</th>
                    <th>
                      Confirmation Sent <br />
                      with Date
                    </th>
                    <th>
                      Confirmation <br />
                      Accepted with Date
                    </th>
                    <th>End date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <a
                        href="#"
                        class="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#TaskDetails3"
                        data-dismiss="modal"
                      >
                        <img src="images/edit-32.png" />
                      </a>
                      <a href="#">
                        <img src="images/Action-4.png" />
                      </a>
                    </td>
                    <td>Insulated and fire rate ceiling</td>
                    <td>06/22/2023</td>
                    <td>06/22/2023</td>
                    <td>
                      <a href="#">
                        <img src="images/add-friend 5.png" />
                      </a>
                    </td>
                    <td>
                      <a href="#">
                        <img src="images/icon-37.png" />
                      </a>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <a
                        href="#"
                        class="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#TaskDetails3"
                        data-dismiss="modal"
                      >
                        <img src="images/edit-32.png" />
                      </a>
                      <a href="#">
                        <img src="images/Action-4.png" />
                      </a>
                    </td>
                    <td>Insulated and fire rate ceiling</td>
                    <td>06/22/2023</td>
                    <td>06/22/2023</td>
                    <td>
                      <a href="#">
                        <img src="images/add-friend 5.png" />
                      </a>
                    </td>
                    <td>
                      <a href="#">
                        <img src="images/icon-37.png" />
                      </a>
                    </td>
                    <td>
                      Jack Dane - <span class="Count">3</span> 04/30/20 23 Ling
                      Lane - 04/30/20 23
                    </td>
                    <td>Jack Dane - 04/30/20 23 Ling Lane - 04/30/20 23</td>
                    <td>06/22/2023</td>
                  </tr>

                  <tr>
                    <td>3</td>
                    <td>
                      <a
                        href="#"
                        class="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#TaskDetails3"
                        data-dismiss="modal"
                      >
                        <img src="images/edit-32.png" />
                      </a>
                      <a href="#">
                        <img src="images/Action-4.png" />
                      </a>
                    </td>
                    <td>Insulated and fire rate ceiling</td>
                    <td>06/22/2023</td>
                    <td>06/22/2023</td>
                    <td>
                      Jack Dane - <span class="Count">×</span> <br />
                      Jack Dane - <span class="Count">×</span>
                      <a>
                        <img src="images/add-friend 5.png" />
                      </a>
                    </td>
                    <td>
                      <a href="#">
                        <img src="images/icon-37.png" />
                      </a>
                    </td>
                    <td>
                      Jack Dane - <span class="Count">3</span> 04/30/20 23 Ling
                      Lane - 04/30/20 23
                    </td>
                    <td>Jack Dane - 04/30/20 23 Ling Lane - 04/30/20 23</td>
                    <td>06/22/2023</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="TodosButtons">
            <a href="javascript:void(0);" class="Create">
              <img src="images/icon-39.png" />
              Send all<span>3</span>
            </a>
            <a href="javascript:void(0);" data-dismiss="modal">
              <img src="images/icon-38.png" />
              Re-send mail <span>3</span>
            </a>
          </div>
        </div>
      </Modal>

      <Modal
        show={AddTaskModal}
        onHide={handleAddTaskModalClose}
        className="ModalBox SmallModal"
      >
        <div class="modal-content">
          <a
            onClick={handleAddTaskModalClose}
            className="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Add New Task</h4>
            </div>
            <div class="FolderForm">
              <form>
                <div class="form-group">
                  <h6>
                    Task Name :<span class="required"></span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    value={taskState?.taskName}
                    onChange={(e) =>
                      updateTaskState((prev) => ({
                        ...prev,
                        taskName: e.target.value,
                      }))
                    }
                    placeholder="Insert Phsase Name"
                  />
                  {phaseState.errorMsg.taskName && !phaseState.taskName && (
                    <p style={{ color: "red" }}>
                      {phaseState.errorMsg.taskName}
                    </p>
                  )}
                </div>

                <div class="Filter row">
                  <div class="form-group col-lg-6">
                    <label>Start</label>
                    <input
                      type="date"
                      value={taskState?.startDate}
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={(e) =>
                        updateTaskState((prev) => ({
                          ...prev,
                          startDate: e.target.value,
                        }))
                      }
                      class="form-control"
                    />

                    {phaseState.errorMsg.startDate && !phaseState.startDate && (
                      <p style={{ color: "red" }}>
                        {phaseState.errorMsg.startDate}
                      </p>
                    )}
                  </div>
                  <div class="form-group col-lg-6">
                    <label>End</label>
                    <input
                      type="date"
                      value={taskState?.endDate}
                      min={taskState?.startDate}
                      onChange={(e) =>
                        updateTaskState((prev) => ({
                          ...prev,
                          endDate: e.target.value,
                        }))
                      }
                      class="form-control"
                    />
                    {phaseState.errorMsg.endDate && !phaseState.endDate && (
                      <p style={{ color: "red" }}>
                        {phaseState.errorMsg.endDate}
                      </p>
                    )}
                  </div>
                </div>

                <div class="Filter row">
                  <div class="form-group col-lg-6">
                    <label>Duration</label>
                    <input
                      value={taskState?.duration}
                      onChange={(e) => {
                        if (!/^[0-9]{0,10}$/.test(e.target.value)) return;
                        updateTaskState((prev) => ({
                          ...prev,
                          duration: e.target.value,
                        }));
                      }}
                      type="text"
                      class="form-control"
                    />
                    {phaseState.errorMsg.duration && !phaseState.duration && (
                      <p style={{ color: "red" }}>
                        {phaseState.errorMsg.duration}
                      </p>
                    )}
                  </div>
                </div>
                <div class="form-group">
                  <h6>Predecessor</h6>
                  <div class="Categories Categories1">
                    <input
                      value={taskState?.taskNumber}
                      onChange={(e) => {
                        updateTaskState((prev) => ({
                          ...prev,
                          taskNumber: e.target.value,
                        }));
                      }}
                      type="text"
                      class="form-control"
                    />{" "}
                    {phaseState.errorMsg.taskNumber &&
                      !phaseState.taskNumber && (
                        <p style={{ color: "red" }}>
                          {phaseState.errorMsg.taskNumber}
                        </p>
                      )}
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-8">
                    <div class="RushRoomEarnArea">
                      <div class="RushRoomEarnLeft">
                        <h6 class="mb-0">Progress</h6>
                        <Slider
                          getAriaLabel={() => "Temperature range"}
                          onChange={(value) =>
                            updateTaskState({
                              ...taskState,
                              progress: value,
                            })
                          }
                          valueLabelDisplay="auto"
                          getAriaValueText="10"
                          tipFormatter={(value) => (
                            <span className="tooltip">{value}€</span>
                          )}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="Button d-flex justify-content-between mt-5">
                  <button class="Create" onClick={handleSubmitTask}>
                    Save
                  </button>
                  <button onClick={handleAddTaskModalClose}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="ModalBox fade SmallModal"
        show={AddNotesModal}
        onHide={handleAddNotesClose}
      >
        <Modal.Body>
          <div className="Category">
            <a className="CloseModal" onClick={handleAddNotesClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Note</h4>
              </div>
              <h6>Note </h6>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> {state?.jobType ?? "N/A"}
                  </h6>
                </div>
                {/* <div className="JobBox mb-3">
                  <h6>
                    <span>Origin:</span> #185
                  </h6>
                </div> */}
                <h6>Type</h6>

                <div className="FolderForm">
                  <div className="form-group mb-4">
                    <label className="Radio">
                      Text
                      <input
                        type="radio"
                        value="Text"
                        checked={selectedOption === "Text"}
                        onChange={onValueChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="Radio">
                      File
                      <input
                        type="radio"
                        value="File"
                        checked={selectedOption === "File"}
                        onChange={onValueChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>

                  {textInput == true && (
                    <div className="form-group">
                      <h6>Message (Max 1000 chars limit)</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write here ....."
                        name="message"
                        value={message}
                        onChange={handleInputChange}
                      />
                      <small className="form-text text-right">
                        {1000 - Number(message.length)} characters remaining
                      </small>
                    </div>
                  )}

                  {fileData == true && (
                    <>
                      <div className="form-group">
                        <h6>Select Files</h6>
                        <div className="Upload">
                          <span>-Drag and Drop Files or click to select</span>
                          <input
                            type="file"
                            id="fileInput"
                            onChange={(e) => handleFileChange(e, "support")}
                          />
                        </div>
                      </div>
                      {supportingDocFiles && (
                        <div className="FilesAdded">
                          <label>{supportingDocFiles?.name}</label>
                          <span>
                            <img src="images/fileview.png" alt="File view" />
                          </span>
                          <span onClick={handleRemoveClientFile}>
                            <img
                              src="images/Cross.png"
                              className="cross"
                              alt="Cross"
                            />
                          </span>
                        </div>
                      )}
                    </>
                  )}

                  <div className="Button d-flex justify-content-between mt-4">
                    <button className="Create" onClick={handleAddNotesClose}>
                      Update Note
                    </button>
                    <button className="Outline" onClick={handleAddNotesClose}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <FieldOrderList
        FieldOrderModal={FieldOrderModal}
        SetFieldOrderModal={SetFieldOrderModal}
      />
      <ConsolidatedChangeOrderCheckList
        ConsolidatedChangeOrderCheckListModal={
          ConsolidatedChangeOrderCheckListModal
        }
        SetConsolidatedChangeOrderCheckListModal={
          SetConsolidatedChangeOrderCheckListModal
        }
      />
      <FileManger
        FileMangerModal={FileMangerModal}
        SetFileMangerModal={SetFileMangerModal}
        state={state}
      />

      <AddSchedule
        jobId={jobState?.id}
        jobNumber={jobState?.jobNumber}
        projectName={projectState?.projectName}
        AddScheduleModal={AddScheduleModal}
        setAddSchedule={setAddSchedule}
      />
      <Modal
        show={DiscussionSettingsModal}
        onHide={handleDiscussionSettingsModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleDiscussionSettingsModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Discussion Settings</h4>
            </div>
            <div className="FolderForm">
              <div
                className="FolderFormCreateArea p-0"
                style={{ overflow: "initial" }}
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ModalMediumheading">
                      <h3>
                        <span>Linked to: </span>Warranty Request #
                        {state?.item?.id}
                      </h3>
                    </div>
                    <h6>Visible in :</h6>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mt-2 mb-4 ">
                      <label className="switch">
                        Client View
                        <input
                          type="checkbox"
                          checked={visibleInClientView === true ? true : false}
                          onClick={() => handleDiscussionCheckBox("client")}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                    <div className="form-group mb-4 ">
                      <label className="switch">
                        Trade View
                        <input
                          type="checkbox"
                          checked={visibleInTradeView === true ? true : false}
                          onClick={() => handleDiscussionCheckBox("trade")}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                    <div className="form-group mb-4 ">
                      <label className="switch">
                        Investor View
                        <input
                          type="checkbox"
                          checked={
                            visibleInInvestorView === true ? true : false
                          }
                          onChange={() => handleDiscussionCheckBox("invest")}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center">
                <button
                  className="Create"
                  onClick={handleDiscussionSettingUpdate}
                >
                  Save Discussion Settings
                </button>
                <button
                  className="Outline"
                  onClick={handleDiscussionSettingsModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={JobMenuModal}
        onHide={handleJobMenuModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleJobMenuModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Job Menu</h4>
            </div>
            <div className="FolderForm">
              <div className="JobMenuListing">
                <ul>
                  <li>
                    <a href="javascript:void(0);">
                      <figure>
                        <img src="images/add_build.png" />
                      </figure>
                      Add to/Edit Build
                      <img
                        src="images/rightarrow2.png"
                        className="ListingOverflow"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <figure>
                        <img src="images/details.png" />
                      </figure>
                      Details
                    </a>
                  </li>
                  <li className="JobMenuListing_All">
                    <Link to="/change-order-list" state={state}>
                      <figure>
                        <img src="images/change_order.png" />
                      </figure>
                      Change Order
                      <img
                        src="images/rightarrow2.png"
                        className="ListingOverflow"
                      />
                    </Link>
                    <ul className="JobMenuListingUnder">
                      <li>
                        <Link to="/change-order-list" state={state}>
                          Change orders List
                        </Link>
                      </li>
                      <li>
                        <Link to="/change-order-list" state={state}>
                          Change to Contract (CTC) master
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/warranty-dashboard" state={state}>
                      <figure>
                        <img src="images/design.png" />
                      </figure>
                      Warranty
                    </Link>
                  </li>
                  <li>
                    <Link to="/sales" state={state}>
                      <figure>
                        <img src="images/sales.png" />
                      </figure>
                      Sales
                    </Link>
                  </li>
                  <li>
                    <Link to="/estimation" state={state}>
                      <figure>
                        <img src="images/estimation.png" />
                      </figure>
                      Estimation
                      <img
                        src="images/rightarrow2.png"
                        className="ListingOverflow"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/design-selections" state={state}>
                      <figure>
                        <img src="images/construction_icon.png" />
                      </figure>
                      Design-Selections
                      <img
                        src="images/rightarrow2.png"
                        className="ListingOverflow"
                      />
                    </Link>
                  </li>
                  {pathname !== "/construction" && (
                    <li>
                      <Link to="/construction" state={state}>
                        <figure>
                          <img src="images/warranty.png" />
                        </figure>
                        Construction
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link to="/reports" state={state}>
                      <figure>
                        <img src="images/reports.png" />
                      </figure>
                      Reports
                      <img
                        src="images/rightarrow2.png"
                        className="ListingOverflow"
                      />
                    </Link>
                  </li>

                  <li>
                    <Link to="/client-view-config" state={state}>
                      <figure>
                        <img src="images/view_config.png" />
                      </figure>
                      Views Config
                      <img
                        src="images/rightarrow2.png"
                        className="ListingOverflow"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={NavigateModal}
        onHide={handleNavigateModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleNavigateModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Navigate Project</h4>
            </div>
            <div className="FolderForm">
              <div className="NaviagateProjectArea">
                <ul>
                  {allProjectList?.projects?.length > 0 &&
                    allProjectList?.projects.map((item, index) => {
                      return (
                        <>
                          <li className="DarkBlueList AvalonTownsTab">
                            <a href="javascript:void(0);">{item.projectName}</a>
                          </li>
                          <li className="LightBlueList AdministrationTab">
                            <a href="javascript:void(0);">{item.jobPattern}</a>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu dropdown-menuwidth DropDownMenuZindex">
                                    <li>
                                      <a>
                                        <img src="images/add_build.png" />
                                        Add to/Edit Build
                                      </a>
                                    </li>
                                    <li>
                                      <a>
                                        <img src="images/details.png" />
                                        Details
                                      </a>
                                    </li>
                                    <li className="border-0">
                                      <Link
                                        to="/change-order-list"
                                        state={state}
                                        className="dropdown-item"
                                        style={{
                                          border:
                                            "1px solid transparent!important",
                                        }}
                                      >
                                        <img src="images/change_order.png" />
                                        Change Order &nbsp;&nbsp;
                                        <img src="images/rightarrow2.png" />
                                      </Link>
                                      <ul className="dropdown-menu submenu">
                                        <li>
                                          <Link
                                            to="/change-order-list"
                                            state={state}
                                            className="dropdown-item"
                                          >
                                            Change orders List
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/change-order-list"
                                            state={state}
                                            className="dropdown-item"
                                          >
                                            Change to Contract (CTC) master
                                          </Link>
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <Link
                                        to="/design-selections"
                                        state={state}
                                      >
                                        <img src="images/design.png" />
                                        Design
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/sales" state={state}>
                                        <img src="images/sales.png" />
                                        Sales
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/estimation" state={state}>
                                        <img src="images/estimation.png" />
                                        Estimation
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/construction" state={state}>
                                        <img src="images/construction_icon.png" />
                                        Construction
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/warranty-dashboard"
                                        state={state}
                                      >
                                        <img src="images/warranty.png" />
                                        Warranty
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/reports" state={state}>
                                        <img src="images/reports.png" />
                                        Reports
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/client-view-config"
                                        state={state}
                                      >
                                        <img src="images/view_config.png" />
                                        Views Config
                                      </Link>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <TaskAssignModal
        taskAssignModal={taskAssignModal}
        setTaskAssignModal={setTaskAssignModal}
        state={state}
      />

      <OkaypayModal
        okaypayModal={okaypayModal}
        setOkaypayModal={setOkaypayModal}
      />

      <AssignResouceModal
        assignResouceModal={assignResouceModal}
        setAssignResouceModal={setAssignResouceModal}
        setTaskDetails={setTaskDetails}
      />

      <SelectAccountModal

      setSelectAccountModal={setSelectAccountModal}
      
      />








    </>
  );
};

export default Construction;
