import React, { useEffect, useState } from "react";
import { UserDropdownAction } from "../../redux/action/dropdownAction";
import { useDispatch, useSelector } from "react-redux";
import {
  createAuthorityAction,
  getAuthorityAction,
} from "../../redux/action/adminSigningAuthorityAction";
import { toast } from "react-toastify";

const AdminSigningAuthority = () => {
  const [selectedUser, setSelectedUser] = useState("");

  const [removeUserIndex, setRemoveUserIndex]= useState("")


  const [signDocument, setSignDocument] = useState([
    {
      userIds: [],
      documentType: "Contract",
    },
    {
      userIds: [],
      documentType: "Sales Letter",
    },

    {
      userIds: [],
      documentType: "Construction Letter",
    },
    {
      userIds: [],
      documentType: "Warranty Letter",
    },
    {
      userIds: [],
      documentType: "Change Order",
    },

    {
      userIds: [],
      documentType: "Change Order CTC",
    },
    {
      userIds: [],
      documentType: "CTC Document",
    },
  ]);

  const dispatch = useDispatch();
  const { userList } = useSelector((state) => state.dropdownReducer);

  const {authoriyList} = useSelector((state)=> state.adminSigningAuthorityReducer)

  const [editSigning, setEditSigning] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // updateState({ ...iState, stopper: true });
    let formIsValid = true;

    if (formIsValid) {
      dispatch(createAuthorityAction({ signDocument }))
        .then((res) => {
            toast.success("Users Assigned Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });

            setEditSigning(false);

            dispatch(getAuthorityAction());


          
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    dispatch(UserDropdownAction());
  }, [editSigning]);

  useEffect(() => {
    dispatch(getAuthorityAction());
  }, []);

  const handleAddUser = (e, type) => {
    e.preventDefault();

    // Find the index of the document with the matching documentType
    const objIndex = signDocument.findIndex((doc) => doc.documentType === type);

    if (objIndex !== -1) {
      // Create a copy of the existing array
      const updatedDocuments = [...signDocument];

      // Add the selected user to the correct document's userIds array
      updatedDocuments[objIndex] = {
        ...updatedDocuments[objIndex],
        userIds: [...updatedDocuments[objIndex].userIds, selectedUser],
      };

      // Update the state with the modified array
      setSignDocument(updatedDocuments);

      // Clear the selected user
      setSelectedUser("");
    }
  };

  const handleRemoveUser = (e, type, i) => {
    e.preventDefault();

    // Find the index of the document with the matching documentType
    const objIndex = signDocument.findIndex((doc) => doc.documentType === type);

    if (objIndex !== -1) {
      // Create a copy of the existing array
      const updatedDocuments = [...signDocument];

      const removeUser = [...updatedDocuments[objIndex].userIds];

      removeUser.splice(i, 1);

      // Add the selected user to the correct document's userIds array
      updatedDocuments[objIndex] = {
        ...updatedDocuments[objIndex],
        userIds: removeUser,
      };

      // Update the state with the modified array
      setSignDocument(updatedDocuments);

      // Clear the selected user
      setRemoveUserIndex("");
    }
  };



  return (
    <div className="tab-pane fade" id="SingingAuthority">
      <div className="ResidentialJobArea ViewConfigArea">
        <div className="ModalAccordian">
          <div id="accordion">
            <div className="card">
              <div className="card-header">
                <h4
                  data-toggle="collapse"
                  href="#SigningAuthorityUsersSetupTabs"
                >
                  <span>
                    <img src="images/side_menu.png" />
                  </span>{" "}
                  Signing Authority Users Setup
                </h4>
                <a
                  className="Edit EditViewConfigBodyBtn"
                  onClick={() => setEditSigning(editSigning ? false : true)}
                >
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="SigningAuthorityUsersSetupTabs"
                className="collapse show"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <div
                    className="ViewConfigBodyMain"
                    style={{ display: editSigning ? "none" : "block" }}
                  >
                    <div className="ViewConfigBody flex-column">
                      <div className="AuthorityBox">
                        <h3>Sales</h3>
                        <h6>Document Contract</h6>
                        <h6>Authorized Users:</h6>

                       { authoriyList?.find((doc) => doc.documentType == "Contract")?.userIds?.map((user)=>
                         <p> {
                          userList?.find(
                            (doc) => doc?.id == user
                          )?.name
                        }</p>
                      )}
                      
                      </div>
                      <div className="AuthorityBox">
                        <h6>Document: Sales Letter</h6>
                        <h6>Authorized Users:</h6>
                        { authoriyList?.find((doc) => doc.documentType == "Sales Letter")?.userIds?.map((user)=>
                         <p> {
                          userList?.find(
                            (doc) => doc?.id == user
                          )?.name
                        }</p>
                      )}
                      </div>
                      <div className="AuthorityBox">
                        <h3>Construction</h3>
                        <h6>Document: Construction Letter</h6>
                        <h6>Authorized Users:</h6>
                        { authoriyList?.find((doc) => doc.documentType == "Construction Letter")?.userIds?.map((user)=>
                         <p> {
                          userList?.find(
                            (doc) => doc?.id == user
                          )?.name
                        }</p>
                      )}
                      </div>
                      <div className="AuthorityBox">
                        <h3>Warranty</h3>
                        <h6>Document: Warranty Letter</h6>
                        <h6>Authorized Users:</h6>
                        { authoriyList?.find((doc) => doc.documentType == "Warranty Letter")?.userIds?.map((user)=>
                         <p> {
                          userList?.find(
                            (doc) => doc?.id == user
                          )?.name
                        }</p>
                      )}
                      </div>
                      <div className="AuthorityBox">
                        <h3>Change Order/CTC</h3>
                        <h6>Document: Change Order</h6>
                        <h6>Authorized Users:</h6>
                        { authoriyList?.find((doc) => doc.documentType == "Change Order")?.userIds?.map((user)=>
                         <p> {
                          userList?.find(
                            (doc) => doc?.id == user
                          )?.name
                        }</p>
                      )}
                      </div>
                      <div className="AuthorityBox">
                        <h6>Document: CTC Document</h6>
                        <h6>Authorized Users:</h6>
                        { authoriyList?.find((doc) => doc.documentType == "Change Order CTC")?.userIds?.map((user)=>
                         <p> {
                          userList?.find(
                            (doc) => doc?.id == user
                          )?.name
                        }</p>
                      )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="ViewConfigBodyHideMain"
                    style={{ display: editSigning ? "block" : "none" }}
                  >
                    <div className="ViewConfigBodyHide flex-column">
                      <div className="row">
                        <div className="col-md-10">
                          <div className="AuthoritySetupBox">
                            <h3>Sales</h3>
                            <div className="AuthoritySetupBoxMain">
                              <div className="AuthoritySetupBoxLeft">
                                <h6>Document: Contract</h6>
                                <div className="CommonSelectBox">
                                  <select
                                    value={selectedUser}
                                    onChange={(e) =>
                                      setSelectedUser(e.target.value)
                                    }
                                  >
                                    <option>Select User</option>
                                    {userList?.map((user) => (
                                      <option value={user?.id}>
                                        {user?.name}
                                      </option>
                                    ))}
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                                <button
                                  className="SmallRedBtn"
                                  onClick={(e) => handleAddUser(e, "Contract")}
                                >
                                  Add Signing Authority
                                </button>
                              </div>
                              <div className="AuthoritySetupBoxRight">
                                <h6>Authorized Users:</h6>

                                {signDocument
                                  ?.find(
                                    (doc) => doc.documentType == "Contract"
                                  )
                                  ?.userIds?.map((user, i) => (
                                    <div className="AuthorizeName">
                                      <p>
                                        {
                                          userList?.find(
                                            (doc) => doc?.id == user
                                          )?.name
                                        }
                                      </p>
                                      <span
                                        onClick={(e) =>
                                          handleRemoveUser(e, "Contract", i)
                                        }
                                      >
                                        <img src="images/dropdown-delete.png" />
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="AuthoritySetupBox">
                            <div className="AuthoritySetupBoxMain">
                              <div className="AuthoritySetupBoxLeft">
                                <h6>Document: Sales Letter</h6>
                                <div className="CommonSelectBox">
                                  <select
                                    value={selectedUser}
                                    onChange={(e) =>
                                      setSelectedUser(e.target.value)
                                    }
                                  >
                                    <option selected="">Select User</option>
                                    {userList?.map((user) => (
                                      <option value={user?.id}>
                                        {user?.name}
                                      </option>
                                    ))}
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                                <button
                                  className="SmallRedBtn"
                                  onClick={(e) => handleAddUser(e, "Sales Letter")}
                                >                                  Add Signing Authority
                                </button>
                              </div>
                              <div className="AuthoritySetupBoxRight">
                                <h6>Authorized Users:</h6>

                                {signDocument
                                  ?.find(
                                    (doc) => doc.documentType == "Sales Letter"
                                  )
                                  ?.userIds?.map((user, i) => (
                                    <div className="AuthorizeName">
                                      <p>
                                        {
                                          userList?.find(
                                            (doc) => doc?.id == user
                                          )?.name
                                        }
                                      </p>
                                      <span
                                        onClick={(e) =>
                                          handleRemoveUser(e, "Sales Letter", i)
                                        }
                                      >
                                        <img src="images/dropdown-delete.png" />
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="AuthoritySetupBox">
                            <h3>Construction</h3>
                            <div className="AuthoritySetupBoxMain">
                              <div className="AuthoritySetupBoxLeft">
                                <h6>Document: Construction Letter</h6>
                                <div className="CommonSelectBox">
                                  <select
                                    value={selectedUser}
                                    onChange={(e) =>
                                      setSelectedUser(e.target.value)
                                    }
                                  >
                                    <option selected="">Select User</option>
                                    {userList?.map((user) => (
                                      <option value={user?.id}>
                                        {user?.name}
                                      </option>
                                    ))}
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                                <button
                                  className="SmallRedBtn"
                                  onClick={(e) => handleAddUser(e, "Construction Letter")}
                                >                                  Add Signing Authority
                                </button>
                              </div>
                              <div className="AuthoritySetupBoxRight">
                                <h6>Authorized Users:</h6>

                                {signDocument
                                  ?.find(
                                    (doc) => doc.documentType == "Construction Letter"
                                  )
                                  ?.userIds?.map((user, i) => (
                                    <div className="AuthorizeName">
                                      <p>
                                        {
                                          userList?.find(
                                            (doc) => doc?.id == user
                                          )?.name
                                        }
                                      </p>
                                      <span
                                        onClick={(e) =>
                                          handleRemoveUser(e, "Construction Letter", i)
                                        }
                                      >
                                        <img src="images/dropdown-delete.png" />
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="AuthoritySetupBox">
                            <h3>Warranty</h3>
                            <div className="AuthoritySetupBoxMain">
                              <div className="AuthoritySetupBoxLeft">
                                <h6>Document: Warranty Letter</h6>
                                <div className="CommonSelectBox">
                                  <select
                                    value={selectedUser}
                                    onChange={(e) =>
                                      setSelectedUser(e.target.value)
                                    }
                                  >
                                    <option selected="">Select User</option>
                                    {userList?.map((user) => (
                                      <option value={user?.id}>
                                        {user?.name}
                                      </option>
                                    ))}
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                                <button
                                  className="SmallRedBtn"
                                  onClick={(e) => handleAddUser(e, "Warranty Letter")}
                                >                                  Add Signing Authority
                                </button>
                              </div>
                              <div className="AuthoritySetupBoxRight">
                                <h6>Authorized Users:</h6>

                                {signDocument
                                  ?.find(
                                    (doc) => doc.documentType == "Warranty Letter"
                                  )
                                  ?.userIds?.map((user, i) => (
                                    <div className="AuthorizeName">
                                      <p>
                                        {
                                          userList?.find(
                                            (doc) => doc?.id == user
                                          )?.name
                                        }
                                      </p>
                                      <span
                                        onClick={(e) =>
                                          handleRemoveUser(e, "Warranty Letter", i)
                                        }
                                      >
                                        <img src="images/dropdown-delete.png" />
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="AuthoritySetupBox">
                            <h3>Change Order/CTC</h3>
                            <div className="AuthoritySetupBoxMain">
                              <div className="AuthoritySetupBoxLeft">
                                <h6>Document: Change Order</h6>
                                <div className="CommonSelectBox">
                                  <select
                                    value={selectedUser}
                                    onChange={(e) =>
                                      setSelectedUser(e.target.value)
                                    }
                                  >
                                    <option selected="">Select User</option>
                                    {userList?.map((user) => (
                                      <option value={user?.id}>
                                        {user?.name}
                                      </option>
                                    ))}
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                                <button
                                  className="SmallRedBtn"
                                  onClick={(e) => handleAddUser(e, "Change Order")}
                                >                                  Add Signing Authority
                                </button>
                              </div>
                              <div className="AuthoritySetupBoxRight">
                                <h6>Authorized Users:</h6>

                                {signDocument
                                  ?.find(
                                    (doc) => doc.documentType == "Change Order"
                                  )
                                  ?.userIds?.map((user, i) => (
                                    <div className="AuthorizeName">
                                      <p>
                                        {
                                          userList?.find(
                                            (doc) => doc?.id == user
                                          )?.name
                                        }
                                      </p>
                                      <span
                                        onClick={(e) =>
                                          handleRemoveUser(e, "Change Order", i)
                                        }
                                      >
                                        <img src="images/dropdown-delete.png" />
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="AuthoritySetupBox">
                            <h3>Document: CTC Document</h3>
                            <div className="AuthoritySetupBoxMain">
                              <div className="AuthoritySetupBoxLeft">
                                <h6>Document: Change Order</h6>
                                <div className="CommonSelectBox">
                                  <select
                                    value={selectedUser}
                                    onChange={(e) =>
                                      setSelectedUser(e.target.value)
                                    }
                                  >
                                    <option selected="">Select User</option>
                                    {userList?.map((user) => (
                                      <option value={user?.id}>
                                        {user?.name}
                                      </option>
                                    ))}
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                                <button
                                  className="SmallRedBtn"
                                  onClick={(e) => handleAddUser(e, "Change Order CTC")}
                                >                                  Add Signing Authority
                                </button>
                              </div>
                              <div className="AuthoritySetupBoxRight">
                                <h6>Authorized Users:</h6>

                                {signDocument
                                  ?.find(
                                    (doc) => doc.documentType == "Change Order CTC"
                                  )
                                  ?.userIds?.map((user, i) => (
                                    <div className="AuthorizeName">
                                      <p>
                                        {
                                          userList?.find(
                                            (doc) => doc?.id == user
                                          )?.name
                                        }
                                      </p>
                                      <span
                                        onClick={(e) =>
                                          handleRemoveUser(e, "Change Order CTC", i)
                                        }
                                      >
                                        <img src="images/dropdown-delete.png" />
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Button d-flex justify-content-center mt-4">
                      <button
                        className="OutlineBlack mr-5 SaveViewConfigBodyBtn"
                        onClick={() => setEditSigning(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="Respond ml-5 SaveViewConfigBodyBtn"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSigningAuthority;
