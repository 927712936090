import axios from "axios";
import { saleActionTypes } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
const contactId = window.localStorage.getItem("contractId")
export const getAllLeadsListInitate = () => ({
  type: saleActionTypes.GET_ALL_LEADS_INITIATE,
});

export const getAllLeadsListSuccess = (data) => ({
  type: saleActionTypes.GET_ALL_LEADS_SUCCESS,
  payload: data,
});

export const getAllLeadsListFailure = (data) => ({
  type: saleActionTypes.GET_ALL_LEADS_FAILURE,
  payload: data,
});

export function getAllLeadsListAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllLeadsListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllLeadsListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllLeadsListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getAllLeadsListFailure(err));
          reject(err);
        });
    });
  };
}

export const addLeadsInitate = () => ({
  type: saleActionTypes.CREATE_LEAD_INITIATE,
});

export const addLeadsSuccess = (data) => ({
  type: saleActionTypes.CREATE_LEAD_SUCCESS,
  payload: data,
});

export const addLeadsFailure = (data) => ({
  type: saleActionTypes.CREATE_LEAD_FAILURE,
  payload: data,
});

export function addLeadsAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addLeadsInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addLeadsSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addLeadsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addLeadsFailure(err));
          reject(err);
        });
    });
  };
}

export const importContactFileInitate = () => ({
  type: saleActionTypes.IMPORT_CONTACT_INITIATE,
});

export const importContactFileSuccess = (data) => ({
  type: saleActionTypes.IMPORT_CONTACT_SUCCESS,
  payload: data,
});

export const importContactFileFailure = (data) => ({
  type: saleActionTypes.IMPORT_CONTACT_FAILURE,
  payload: data,
});

export function importContactFileAction(payload) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ file: payload.file, ...payload }); // Modify this line

  return (dispatch) => {
    dispatch(importContactFileInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${
            iBuildLocalData?.user?.CompanyId ? iBuildLocalData?.user?.CompanyId : ""
          }/import-lead-contact`,
          updatedData,
          {
            headers: { Authorization: ` Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(importContactFileSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(importContactFileFailure(data.data));
            reject(data.data);
          }
        })
        .catch((err) => {
          dispatch(importContactFileFailure(err));
          reject(err);
        });
    });
  };
}

export const removeLeadInitate = () => ({
  type: saleActionTypes.DELETE_LEAD_INITIATE,
});

export const removeLeadSuccess = (data) => ({
  type: saleActionTypes.DELETE_LEAD_SUCCESS,
  payload: data,
});

export const removeLeadFailure = (data) => ({
  type: saleActionTypes.DELETE_LEAD_FAILURE,
  payload: data,
});

export function removeLeadAction(payload, id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removeLeadInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${
            payload ? payload : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removeLeadSuccess(data));
            resolve(data);
          } else {
            dispatch(removeLeadFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeLeadFailure(err));
          reject(err);
        });
    });
  };
}

export const updateLeadInitate = () => ({
  type: saleActionTypes.UPDATE_LEAD_INITIATE,
});

export const updateLeadSuccess = (data) => ({
  type: saleActionTypes.UPDATE_LEAD_SUCCESS,
  payload: data,
});

export const updateLeadFailure = (data) => ({
  type: saleActionTypes.UPDATE_LEAD_SUCCESS,
  payload: data,
});

export function updateLeadAction(payload, params) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(updateLeadInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateLeadSuccess(data));
            resolve(data);
          } else {
            dispatch(updateLeadFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateLeadFailure(err));
          reject(err);
        });
    });
  };
}

export const updateContactInitate = () => ({
  type: saleActionTypes.UPDATE_CONTACT_INITIATE,
});

export const updateContactSuccess = (data) => ({
  type: saleActionTypes.UPDATE_CONTACT_SUCCESS,
  payload: data,
});

export const updateContactFailure = (data) => ({
  type: saleActionTypes.UPDATE_CONTACT_SUCCESS,
  payload: data,
});


export function updateContactAction(params, payload) {
  const token = isLoggedIn("adminData1");
  
  return (dispatch) => {
    dispatch(updateContactInitate());
    
    return axios.put(
      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${params}/contact`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        dispatch(updateContactSuccess(response.data));
        return Promise.resolve(response.data);
      } else {
        dispatch(updateContactFailure(response.data));
        return Promise.reject(response.data);
      }
    })
    .catch((err) => {
      dispatch(updateContactFailure(err));
      return Promise.reject(err);
    });
  };
}


export const getLeadDetailsInitate = () => ({
  type: saleActionTypes.LEAD_DETAILS_INITIATE,
});

export const getLeadDetailsSuccess = (data) => ({
  type: saleActionTypes.LEAD_DETAILS_SUCCESS,
  payload: data,
});

export const getLeadDetailsFailure = (data) => ({
  type: saleActionTypes.LEAD_DETAILS_FAILURE,
  payload: data,
});

export function getLeadDetailsAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getLeadDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${payload}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getLeadDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getLeadDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getLeadDetailsFailure(err));
          reject(err);
        });
    });
  };
}

export const convertToContractInitate = () => ({
  type: saleActionTypes.CONVERT_TO_CONTACT_INITIATE,
});

export const convertToContractSuccess = (data) => ({
  type: saleActionTypes.CONVERT_TO_CONTACT_SUCCESS,
  payload: data,
});

export const convertToContractFailure = (data) => ({
  type: saleActionTypes.CONVERT_TO_CONTACT_FAILURE,
  payload: data,
});

export function convertToContractAction(id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(convertToContractInitate());
    return new Promise((resolve, reject) => {
      axios
      .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId ? iBuildLocalData?.user?.CompanyId :""}/leads/${id ? id : ""}/convert-to-contract`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      // axios
      //   .get(
      //     `${Url}companies/${iBuildLocalData?.user?.CompanyId ? iBuildLocalData?.user?.CompanyId :""}/leads/${id ? id : ""}/convert-to-contract`,
      //     {
      //       headers: { Authorization: `${token}` },
      //     }
      //   )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 201) {
            dispatch(convertToContractSuccess(data.data));
            resolve(data);
          } else {
            dispatch(convertToContractFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(convertToContractFailure(err));
          reject(err);
        });
    });
  };
}

export const getAllCommentsListInitate = () => ({
  type: saleActionTypes.COMMENT_LIST_INITIATE,
});

export const getAllCommentsListSuccess = (data) => ({
  type: saleActionTypes.COMMENT_LIST_SUCCESS,
  payload: data,
});

export const getAllCommentsListFailure = (data) => ({
  type: saleActionTypes.COMMENT_LIST_FAILURE,
  payload: data,
});

export function getAllCommentsListAction(payload, id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllCommentsListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.id}/leads/${
            id ? id : ""
          }/comments`,
          {
            headers: { Authorization: `${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllCommentsListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getAllCommentsListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getAllCommentsListFailure(err));
          reject(err);
        });
    });
  };
}

export const addCommentInitate = () => ({
  type: saleActionTypes.ADD_COMMENT_INITIATE,
});

export const addCommentSuccess = (data) => ({
  type: saleActionTypes.ADD_COMMENT_SUCCESS,
  payload: data,
});

export const addCommentFailure = (data) => ({
  type: saleActionTypes.ADD_COMMENT_FAILURE,
  payload: data,
});

export function addCommentAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addCommentInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.id}/leads/${
            payload ? payload : ""
          }/comments`,
          payload,
          {
            headers: { Authorization: `${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addCommentSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addCommentFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addCommentFailure(err));
          reject(err);
        });
    });
  };
}

export const getAllSubCommentsListInitate = () => ({
  type: saleActionTypes.COMMENT_LIST_INITIATE,
});

export const getAllSubCommentsListSuccess = (data) => ({
  type: saleActionTypes.COMMENT_LIST_SUCCESS,
  payload: data,
});

export const getAllSubCommentsListFailure = (data) => ({
  type: saleActionTypes.COMMENT_LIST_FAILURE,
  payload: data,
});

export function getAllSubCommentsListAction(payload, id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllSubCommentsListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.id}/leads/${
            id ? id : ""
          }/comments`,
          {
            headers: { Authorization: `${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllSubCommentsListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getAllSubCommentsListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getAllSubCommentsListFailure(err));
          reject(err);
        });
    });
  };
}

export const addSubCommentInitate = () => ({
  type: saleActionTypes.ADD_SUB_COMMENT_INITIATE,
});

export const addSubCommentSuccess = (data) => ({
  type: saleActionTypes.ADD_SUB_COMMENT_SUCCESS,
  payload: data,
});

export const addSubCommentFailure = (data) => ({
  type: saleActionTypes.ADD_SUB_COMMENT_FAILURE,
  payload: data,
});

export function addSubCommentAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addSubCommentInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.id}/leads/${
            payload ? payload : ""
          }/comments`,
          payload,
          {
            headers: { Authorization: `${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addSubCommentSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addSubCommentFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addSubCommentFailure(err));
          reject(err);
        });
    });
  };
}

//   CONTRACTS

export const getAllContractsListInitate = () => ({
  type: saleActionTypes.GET_ALL_CONTRACTS_INITIATE,
});

export const getAllContractsListSuccess = (data) => ({
  type: saleActionTypes.GET_ALL_CONTRACTS_SUCCESS,
  payload: data,
});

export const getAllContractsListFailure = (data) => ({
  type: saleActionTypes.GET_ALL_CONTRACTS_FAILURE,
  payload: data,
});

export function getAllContractsListAction(payload, id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllContractsListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllContractsListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getAllContractsListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getAllContractsListFailure(err));
          reject(err);
        });
    });
  };
}



export const addContractsInitate = () => ({
  type: saleActionTypes.ADD_CONTRACT_INITIATE,
});

export const addContractsSuccess = (data) => ({
  type: saleActionTypes.ADD_CONTRACT_SUCCESS,
  payload: data,
});

export const addContractsFailure = (data) => ({
  type: saleActionTypes.ADD_CONTRACT_FAILURE,
  payload: data,
});

export function addContractsAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addContractsInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.id}/leads/${
            payload ? payload : ""
          }/contracts`,
          payload,
          {
            headers: { Authorization: `${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            dispatch(addContractsSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addContractsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addContractsFailure(err));
          reject(err);
        });
    });
  };
}





export const ContractDetailsInitate = () => ({
  type: saleActionTypes.GET_CONTRACTS_DETAILS_INITIATE,
});

export const ContractDetailsSuccess = (data) => ({
  type: saleActionTypes.GET_CONTRACTS_DETAILS_SUCCESS,
  payload: data,
});

export const ContractDetailsFailure = (data) => ({
  type: saleActionTypes.GET_CONTRACTS_DETAILS_FAILURE,
  payload: data,
});

export function ContractDetailsAction(payload, id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(ContractDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${
            payload ? payload : ""
          }`,
          
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201 || 200) {
            dispatch(ContractDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(ContractDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(ContractDetailsFailure(err));
          reject(err);
        });
    });
  };
}






export const updateContractGeneralInformationInitate = () => ({
    type: saleActionTypes.UPDATE_CONTRACT_GENERAL_INITIATE,
  });
  
  export const updateContractGeneralInformationSuccess = (data) => ({
    type: saleActionTypes.UPDATE_CONTRACT_GENERAL_SUCCESS,
    payload: data,
  });
  
  export const updateContractGeneralInformationFailure = (data) => ({
    type: saleActionTypes.UPDATE_CONTRACT_GENERAL_FAILURE,
    payload: data,
  });
  
  export function updateContractGeneralInformationAction(payload, id) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(updateContractGeneralInformationInitate());
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${Url}companies/${iBuildLocalData?.id}/contracts/${
              id ? id : ""
            }/general-info`,
            {
              headers: { Authorization: `${token}` },
            }
          )
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if ((data && data.status == 200) || 201) {
              dispatch(updateContractGeneralInformationSuccess(data.data));
              resolve(data);
            } else {
              dispatch(updateContractGeneralInformationFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(updateContractGeneralInformationFailure(err));
            reject(err);
          });
      });
    };
  }
  
  
export const updateContractAssignInitate = () => ({
    type: saleActionTypes.UPDATE_CONTRACT_ASSIGN_INITIATE,
  });
  
  export const updateContractAssignSuccess = (data) => ({
    type: saleActionTypes.UPDATE_CONTRACT_ASSIGN_SUCCESS,
    payload: data,
  });
  
  export const updateContractAssignFailure = (data) => ({
    type: saleActionTypes.UPDATE_CONTRACT_ASSIGN_FAILURE,
    payload: data,
  });
  
  export function updateContractAssignAction(payload, id) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(updateContractAssignInitate());
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${Url}companies/${iBuildLocalData?.id}/contracts/${
              id ? id : ""
            }/assign-unit`,
            {
              headers: { Authorization: `${token}` },
            }
          )
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if ((data && data.status == 200) || 201) {
              dispatch(updateContractAssignSuccess(data.data));
              resolve(data);
            } else {
              dispatch(updateContractAssignFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(updateContractAssignFailure(err));
            reject(err);
          });
      });
    };
  }
  

   
export const updateContractContactInitate = () => ({
    type: saleActionTypes.UPDATE_CONTRACT_CONTACT_INITIATE,
  });
  
  export const updateContractContactSuccess = (data) => ({
    type: saleActionTypes.UPDATE_CONTRACT_CONTACT_SUCCESS,
    payload: data,
  });
  
  export const updateContractContactFailure = (data) => ({
    type: saleActionTypes.UPDATE_CONTRACT_CONTACT_FAILURE,
    payload: data,
  });
  
  export function updateContractContactAction(payload, id) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(updateContractContactInitate());
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${Url}companies/${iBuildLocalData?.id}/contracts/${
              id ? id : ""
            }/contact`,
            {
              headers: { Authorization: `${token}` },
            }
          )
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if ((data && data.status == 200) || 201) {
              dispatch(updateContractContactSuccess(data.data));
              resolve(data);
            } else {
              dispatch(updateContractContactFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(updateContractContactFailure(err));
            reject(err);
          });
      });
    };
  }
  

  
export const updateRemoveAssignedInitate = () => ({
    type: saleActionTypes.REMVOE_ASSIGNED_INITIATE,
  });
  
  export const updateRemoveAssignedSuccess = (data) => ({
    type: saleActionTypes.REMVOE_ASSIGNED_SUCCESS,
    payload: data,
  });
  
  export const updateRemoveAssignedFailure = (data) => ({
    type: saleActionTypes.REMVOE_ASSIGNED_FAILURE,
    payload: data,
  });
  
  export function updateRemoveAssignedAction(payload, id) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(updateRemoveAssignedInitate());
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${
              id ? id : ""
            }/remove-unit`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if ((data && data.status == 200) || 201) {
              dispatch(updateRemoveAssignedSuccess(data.data));
              resolve(data);
            } else {
              dispatch(updateRemoveAssignedFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(updateRemoveAssignedFailure(err));
            reject(err);
          });
      });
    };
  }
  

  
  
export const updateContractConditionalsInitate = () => ({
    type: saleActionTypes.UPDATE_CONTRACT_CONDITIONALS_INITIATE,
  });
  
  export const updateContractConditionalsSuccess = (data) => ({
    type: saleActionTypes.UPDATE_CONTRACT_CONDITIONALS_SUCCESS,
    payload: data,
  });
  
  export const updateContractConditionalsFailure = (data) => ({
    type: saleActionTypes.UPDATE_CONTRACT_CONDITIONALS_FAILURE,
    payload: data,
  });
  
  export function updateContractConditionalsAction(payload, id) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(updateContractConditionalsInitate());
      return new Promise((resolve, reject) => {
        axios
          .put(
            `${Url}companies/${iBuildLocalData?.id}/contracts/${
              id ? id : ""
            }/conditional`,
            {
              headers: { Authorization: `${token}` },
            }
          )
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if ((data && data.status == 200) || 201) {
              dispatch(updateContractConditionalsSuccess(data.data));
              resolve(data);
            } else {
              dispatch(updateContractConditionalsFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(updateContractConditionalsFailure(err));
            reject(err);
          });
      });
    };
  }
  




  
export const getAllStaffListInitate = () => ({
  type: saleActionTypes.GET_STAFF_LIST_INITIATE,
});

export const getAllStaffListSuccess = (data) => ({
  type: saleActionTypes.GET_STAFF_LIST_SUCCESS,
  payload: data,
});

export const getAllStaffListFailure = (data) => ({
  type: saleActionTypes.GET_STAFF_LIST_FAILURE,
  payload: data,
});

export function getAllStaffListAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllStaffListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/staff`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllStaffListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getAllStaffListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getAllStaffListFailure(err));
          reject(err);
        });
    });
  };
}




export const getAllfollowUpListInitate = () => ({
  type: saleActionTypes.GET_FOLLOWUP_LIST_INITIATE,
});

export const getAllfollowUpListSuccess = (data) => ({
  type: saleActionTypes.GET_FOLLOWUP_LIST_SUCCESS,
  payload: data,
});

export const getAllfollowUpListFailure = (data) => ({
  type: saleActionTypes.GET_FOLLOWUP_LIST_FAILURE,
  payload: data,
});

export function getAllfollowUpListAction( ID ) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllfollowUpListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${ID}/follow-ups`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllfollowUpListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllfollowUpListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getAllfollowUpListFailure(err));
          reject(err);
        });
    });
  };
}

export const addFollowUpInitate = () => ({
  type: saleActionTypes.ADD_FOLLOWUP_INITIATE,
});

export const addFollowUpSuccess = (data) => ({
  type: saleActionTypes.ADD_FOLLOWUP_SUCCESS,
  payload: data,
});

export const addFollowUpFailure = (data) => ({
  type: saleActionTypes.ADD_FOLLOWUP_FAILURE,
  payload: data,
});

export function addFollowUpAction(payload, id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addFollowUpInitate());
    return new Promise((resolve, reject) => {
      axios
      // companies/2/FollowUp
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${id}/follow-ups`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addFollowUpSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(addFollowUpFailure(data.data));
            reject(data.data);
          }
        })
        .catch((err) => {
          dispatch(addFollowUpFailure(err));
          reject(err);
        });
    });
  };
}



  
  
export const updateFollowUpInitate = () => ({
  type: saleActionTypes.UPDATE_FOLLOWUP_INITIATE,
});

export const updateFollowUpSuccess = (data) => ({
  type: saleActionTypes.UPDATE_FOLLOWUP_SUCCESS,
  payload: data,
});

export const updateFollowUpFailure = (data) => ({
  type: saleActionTypes.UPDATE_FOLLOWUP_FAILURE,
  payload: data,
});

export function updateFollowUpAction(payload, id, editId) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(updateFollowUpInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${
            id ? id : ""
          }/follow-ups/${editId}`, payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if ((data && data.status == 200 || 201) ) {
            dispatch(updateFollowUpSuccess(data.data));
            resolve(data);
          } else {
            dispatch(updateFollowUpFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateFollowUpFailure(err));
          reject(err);
        });
    });
  };
}




  
export const getAllFollowUpDetailsInitate = () => ({
  type: saleActionTypes.GET_FOLLOWUP_DETAILS_INITIATE,
});

export const getAllFollowUpDetailsSuccess = (data) => ({
  type: saleActionTypes.GET_FOLLOWUP_DETAILS_SUCCESS,
  payload: data,
});

export const getAllFollowUpDetailsFailure = (data) => ({
  type: saleActionTypes.GET_FOLLOWUP_DETAILS_FAILURE,
  payload: data,
});

export function getAllFollowUpDetailsAction( id, lastId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getAllFollowUpDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${id}/follow-ups/${lastId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("data", data);
          if (data && data.status == 201 || 200) {
            dispatch(getAllFollowUpDetailsSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getAllFollowUpDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getAllFollowUpDetailsFailure(err));
          reject(err);
        });
    });
  };
}



  
export const cancelFollowUpDetailsInitate = () => ({
  type: saleActionTypes.CANCEL_FOLLOWUP_LIST_INITIATE,
});

export const cancelFollowUpDetailsSuccess = (data) => ({
  type: saleActionTypes.CANCEL_FOLLOWUP_LIST_SUCCESS,
  payload: data,
});

export const cancelFollowUpDetailsFailure = (data) => ({
  type: saleActionTypes.CANCEL_FOLLOWUP_LIST_FAILURE,
  payload: data,
});

export function cancelFollowUpDetailsAction( id, lastId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(cancelFollowUpDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${id}/follow-ups/${lastId}/cancel`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 201 || 200) {
            dispatch(cancelFollowUpDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(cancelFollowUpDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(cancelFollowUpDetailsFailure(err));
          reject(err);
        });
    });
  };
}



  
export const sendFollowUpInvitationInitate = () => ({
  type: saleActionTypes.SEND_FOLLOWUP_INVITATION_INITIATE,
});

export const sendFollowUpInvitationSuccess = (data) => ({
  type: saleActionTypes.SEND_FOLLOWUP_INVITATION_SUCCESS,
  payload: data,
});

export const sendFollowUpInvitationFailure = (data) => ({
  type: saleActionTypes.SEND_FOLLOWUP_INVITATION_FAILURE,
  payload: data,
});

export function sendFollowUpInvitationAction( id, lastId , payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(cancelFollowUpDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${id}/follow-ups/${lastId}/send-invitation`, payload, {
          headers: { Authorization: `Bearer ${token}` },
         
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 201 || 200) {
            dispatch(cancelFollowUpDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(cancelFollowUpDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(cancelFollowUpDetailsFailure(err));
          reject(err);
        });
    });
  };
}





 
export const setReminderInitate = () => ({
  type: saleActionTypes.SET_REMINDER_INITIATE,
});

export const setReminderSuccess = (data) => ({
  type: saleActionTypes.SET_REMINDER_SUCCESS,
  payload: data,
});

export const setReminderFailure = (data) => ({
  type: saleActionTypes.SET_REMINDER_FAILURE,
  payload: data,
});

export function setReminderAction( id, lastId , payload) {

  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(setReminderInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${id}/follow-ups/${lastId}/set-reminder`, payload, {
          headers: { Authorization: `Bearer ${token}` },
         
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 201 || 200) {
            dispatch(setReminderSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(setReminderFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(setReminderFailure(err));
          reject(err);
        });
    });
  };
}

export const updateSurveyInitate = () => ({
  type: saleActionTypes.UPDATE_SURVEY_INITIATE,
});

export const updateSurveySuccess = (data) => ({
  type: saleActionTypes.UPDATE_SURVEY_SUCCESS,
  payload: data,
});

export const updateSurveyFailure = (data) => ({
  type: saleActionTypes.UPDATE_SURVEY_SUCCESS,
  payload: data,
});

export function updateSurveyAction(payload, params, id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(updateSurveyInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${params}/surveys/${id}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201 || 200) ) {
            dispatch(updateSurveySuccess(data));
            resolve(data);
          } else {
            dispatch(updateSurveyFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateSurveyFailure(err));
          reject(err);
        });
    });
  };
}


export const SurveyDetailsInitate = () => ({
  type: saleActionTypes.SURVEY_DETAILS_INITIATE,
});

export const SurveyDetailsSuccess = (data) => ({
  type: saleActionTypes.SURVEY_DETAILS_SUCCESS,
  payload: data,
});

export const SurveyDetailsFailure = (data) => ({
  type: saleActionTypes.SURVEY_DETAILS_FAILURE,
  payload: data,
});

export function SurveyDetailsAction(params, id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(SurveyDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${params}/surveys/${id}`,
          
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201 || 200)  {
            dispatch(SurveyDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(SurveyDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(SurveyDetailsFailure(err));
          reject(err);
        });
    });
  };
}







  
export const getAllsupportingDocsListInitate = () => ({
  type: saleActionTypes.SUPPORTING_DOCS_LIST_INITIATE,
});

export const getAllsupportingDocsListSuccess = (data) => ({
  type: saleActionTypes.SUPPORTING_DOCS_LIST_SUCCESS,
  payload: data,
});

export const getAllsupportingDocsListFailure = (data) => ({
  type: saleActionTypes.SUPPORTING_DOCS_LIST_FAILURE,
  payload: data,
});

export function getAllsupportingDocsListAction( id, lastId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getAllsupportingDocsListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/support-doc`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("data", data);
          if (data && data.status == 201 || 200) {
            dispatch(getAllsupportingDocsListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllsupportingDocsListFailure(data.data));
            reject(data.data);
          }
        })
        .catch((err) => {
          // console.log("=========errr", err)
          dispatch(getAllsupportingDocsListFailure(err));
          reject(err);
        });
    });
  };
}


export const SupportingDocsDetailsInitate = () => ({
  type: saleActionTypes.SUPPORTING_DOCS_DETAILS_INITIATE,
});

export const SupportingDocsDetailsSuccess = (data) => ({
  type: saleActionTypes.SUPPORTING_DOCS_DETAILS_SUCCESS,
  payload: data,
});

export const SupportingDocsDetailsFailure = (data) => ({
  type: saleActionTypes.SUPPORTING_DOCS_DETAILS_FAILURE,
  payload: data,
});

export function SupportingDocsDetailsAction( id, lastId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(SupportingDocsDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/support-doc/${lastId}`,       
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201 ||200) {
            dispatch(SupportingDocsDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(SupportingDocsDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(SupportingDocsDetailsFailure(err));
          reject(err);
        });
    });
  };
}


export const addSupportingDocsInitate = () => ({
  type: saleActionTypes.ADD_SUPPORTING_DOCS_INITIATE,
});

export const addSupportingDocsSuccess = (data) => ({
  type: saleActionTypes.ADD_SUPPORTING_DOCS_SUCCESS,
  payload: data,
});

export const addSupportingDocsFailure = (data) => ({
  type: saleActionTypes.ADD_SUPPORTING_DOCS_FAILURE,
  payload: data,
});

export function addSupportingDocsAction(payload, id) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ files: payload.files, ...payload })
  return (dispatch) => {
    dispatch(addSupportingDocsInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/support-doc`,
          updatedData,
          {
            headers: { Authorization: ` Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addSupportingDocsSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addSupportingDocsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addSupportingDocsFailure(err));
          reject(err);
        });
    });
  };
}






export const updateSupportingDocsInitate = () => ({
  type: saleActionTypes.UPDATE_SUPPORTING_DOCS_INITIATE,
});

export const updateSupportingDocsSuccess = (data) => ({
  type: saleActionTypes.UPDATE_SUPPORTING_DOCS_SUCCESS,
  payload: data,
});

export const updateSupportingDocsFailure = (data) => ({
  type: saleActionTypes.UPDATE_SUPPORTING_DOCS_SUCCESS,
  payload: data,
});

export function updateSupportingDocsAction(payload, params, id) {
  const updatedData = multiPartData({ file: payload.files, notes:"dl" });
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(updateSupportingDocsInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${params}/support-doc/${id}`,
          updatedData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201 || 200) ) {
            dispatch(updateSupportingDocsSuccess(data));
            resolve(data);
          } else {
            dispatch(updateSupportingDocsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateSupportingDocsFailure(err));
          reject(err);
        });
    });
  };
}



export const removeSupportingDocsInitate = () => ({
  type: saleActionTypes.DELETE_SUPPORTING_DOCS_INITIATE,
});

export const removeSupportingDocsSuccess = (data) => ({
  type: saleActionTypes.DELETE_SUPPORTING_DOCS_SUCCESS,
  payload: data,
});

export const removeSupportingDocsFailure = (data) => ({
  type: saleActionTypes.DELETE_SUPPORTING_DOCS_FAILURE,
  payload: data,
});

export function removeSupportingDocsAction( id, lastid) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removeSupportingDocsInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/support-doc/${lastid}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removeSupportingDocsSuccess(data));
            resolve(data);
          } else {
            dispatch(removeSupportingDocsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeSupportingDocsFailure(err));
          reject(err);
        });
    });
  };
}






export const getAllSchedulePaymentListInitate = () => ({
  type: saleActionTypes.GET_ALL_SCHEDULE_INITIATE,
});

export const getAllSchedulePaymentListSuccess = (data) => ({
  type: saleActionTypes.GET_ALL_SCHEDULE_SUCCESS,
  payload: data,
});

export const getAllSchedulePaymentListFailure = (data) => ({
  type: saleActionTypes.GET_ALL_SCHEDULE_FAILURE,
  payload: data,
});

export function getAllSchedulePaymentListAction( id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getAllSchedulePaymentListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${
            id ? id : ""
          }/schedule-payments`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if ((data && data.status == 200) || 201) {
            dispatch(getAllSchedulePaymentListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getAllSchedulePaymentListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllSchedulePaymentListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllSchedulePaymentListFailure(err));
            reject(err);
          }     
      
        });
    });
  };
}




export const getAllScheduleTransactionPaymentListInitate = () => ({
  type: saleActionTypes.GET_ALL_SCHEDULE_PAYMENTS_TRANSACATION_INITIATE,
});

export const getAllScheduleTransactionPaymentListSuccess = (data) => ({
  type: saleActionTypes.GET_ALL_SCHEDULE_PAYMENTS_TRANSACATION_SUCCESS,
  payload: data,
});

export const getAllScheduleTransactionPaymentListFailure = (data) => ({
  type: saleActionTypes.GET_ALL_SCHEDULE_PAYMENTS_TRANSACATION_FAILURE,
  payload: data,
});

export function getAllScheduleTransactionPaymentListAction( id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllScheduleTransactionPaymentListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${
            id ? id : ""
          }/contract-transactions`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if ((data && data.status == 200 || 201) ) {
            dispatch(getAllScheduleTransactionPaymentListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getAllScheduleTransactionPaymentListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllScheduleTransactionPaymentListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllScheduleTransactionPaymentListFailure(err));
            reject(err);
          }       
      
        });
    });
  };
}



export const addAllSchedulePaymentInitate = () => ({
  type: saleActionTypes.ADD_SCHEDULE_PAYMENTS_INITIATE,
});

export const addAllSchedulePaymentSuccess = (data) => ({
  type: saleActionTypes.ADD_SCHEDULE_PAYMENTS_SUCCESS,
  payload: data,
});

export const addAllSchedulePaymentFailure = (data) => ({
  type: saleActionTypes.ADD_SCHEDULE_PAYMENTS_FAILURE,
  payload: data,
});

export function addAllSchedulePaymentAction(payload,ID) {
  console.log('===>Id', ID)
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addAllSchedulePaymentInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${ID}/schedule-payments`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201  || 200) {
            dispatch(addAllSchedulePaymentSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addAllSchedulePaymentFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addAllSchedulePaymentFailure(err));
          reject(err);
        });
    });
  };
}

export const addAllSchedulePaymentTransactionInitate = () => ({
  type: saleActionTypes.ADD_SCHEDULE_PAYMENTS_TRANSACATION_INITIATE,
});

export const addAllSchedulePaymentTransactionSuccess = (data) => ({
  type: saleActionTypes.ADD_SCHEDULE_PAYMENTS_TRANSACATION_SUCCESS,
  payload: data,
});

export const addAllSchedulePaymentTransactionFailure = (data) => ({
  type: saleActionTypes.ADD_SCHEDULE_PAYMENTS_TRANSACATION_FAILURE,
  payload: data,
});

export function addAllSchedulePaymentTransactionAction(
  payload,
  contactId,
  secondaryId
) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ suppDoc: payload.suppDoc, ...payload })
  return (dispatch) => {
    dispatch(addAllSchedulePaymentTransactionInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contactId}/contract-transactions`,
          updatedData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if ((data && data.status == 201) || 200) {
            dispatch(
              addAllSchedulePaymentTransactionSuccess(data.data)
            );
            resolve(data);
          } else {
            dispatch(
              addAllSchedulePaymentTransactionFailure(data.data)
            );
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addAllSchedulePaymentTransactionFailure(err));
          reject(err);
        });
    });
  };
}

export const updateAllSchedulePaymentListInitate = () => ({
  type: saleActionTypes.UPDATE_SCHEDULE_PAYMENT_INITIATE,
});

export const updateAllSchedulePaymentListSuccess = (data) => ({
  type: saleActionTypes.UPDATE_SCHEDULE_PAYMENT_SUCCESS,
  payload: data,
});

export const updateAllSchedulePaymentListFailure = (data) => ({
  type: saleActionTypes.UPDATE_SCHEDULE_PAYMENT_FAILURE,
  payload: data,
});

export function updateAllSchedulePaymentListAction(id,secondaryId,payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(updateAllSchedulePaymentListInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/schedule-payments/${secondaryId}`,payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if ((data && data.status == 200) || 201) {
            dispatch(updateAllSchedulePaymentListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(updateAllSchedulePaymentListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateAllSchedulePaymentListFailure(err));
          reject(err);
        });
    });
  };
}

export const ScheduleDetailsPaymentInitate = () => ({
  type: saleActionTypes.GET_SCHEDULE_DETAILS_INITIATE,
});

export const ScheduleDetailsPaymentSuccess = (data) => ({
  type: saleActionTypes.GET_SCHEDULE_DETAILS_SUCCESS,
  payload: data,
});

export const ScheduleDetailsPaymentFailure = (data) => ({
  type: saleActionTypes.GET_SCHEDULE_DETAILS_FAILURE,
  payload: data,
});

export function ScheduleDetailsPaymentAction(payload, id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(ScheduleDetailsPaymentInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.id}/leads/${
            payload ? payload : ""
          }/schedule-payments/${id}`,
          payload,
          {
            headers: { Authorization: `${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(ScheduleDetailsPaymentSuccess(data.data));
            resolve(data);
          } else {
            dispatch(ScheduleDetailsPaymentFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(ScheduleDetailsPaymentFailure(err));
          reject(err);
        });
    });
  };
}



export const removeScheduledInitate = () => ({
  type: saleActionTypes.DELETE_SUPPORTING_DOCS_INITIATE,
});

export const removeScheduledSuccess = (data) => ({
  type: saleActionTypes.DELETE_SUPPORTING_DOCS_SUCCESS,
  payload: data,
});

export const removeScheduledFailure = (data) => ({
  type: saleActionTypes.DELETE_SUPPORTING_DOCS_FAILURE,
  payload: data,
});

export function removeScheduledAction( id, lastid) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removeScheduledInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/schedule-payments/${lastid}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removeScheduledSuccess(data));
            resolve(data);
          } else {
            dispatch(removeScheduledFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeScheduledFailure(err));
          reject(err);
        });
    });
  };
}



export const getSalesTypeListInitate = () => ({
  type: saleActionTypes.SALE_TYPE_INITIATE,
});

export const getSalesTypeListSuccess = (data) => ({
  type: saleActionTypes.SALE_TYPE_SUCCESS,
  payload: data,
});

export const getSalesTypeListFailure = (data) => ({
  type: saleActionTypes.SALE_TYPE_FAILURE,
  payload: data,
});

export function getSalesTypeListAction( id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getSalesTypeListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/sale-types`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if ((data && data.status == 200 || 201) ) {
            dispatch(getSalesTypeListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getSalesTypeListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getSalesTypeListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getSalesTypeListFailure(err));
            reject(err);
          }       
      
        });
    });
  };
}




export const getClientAssingedInitate = () => ({
  type: saleActionTypes.CLIENT_ASSIGNED_INITIATE,
});

export const getClientAssingedSuccess = (data) => ({
  type: saleActionTypes.CLIENT_ASSIGNED_SUCCESS,
  payload: data,
});

export const getClientAssingedFailure = (data) => ({
  type: saleActionTypes.CLIENT_ASSIGNED_FAILURE,
  payload: data,
});

export function getClientAssingedAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getClientAssingedInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/search-assiged-unit`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params:payload
          }
        )
        .then((response) => {
          const data = response;
          if ((data && data.status == 200) ) {
            dispatch(getClientAssingedSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getClientAssingedFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
            dispatch(getClientAssingedFailure(err));
            reject(err);    
        });
    });
  };
}



export const getAllClientInitate = () => ({
  type: saleActionTypes.GET_ALL_CLIENTS_INITIATE,
});

export const getAllClientSuccess = (data) => ({
  type: saleActionTypes.GET_ALL_CLIENTS_SUCCESS,
  payload: data,
});

export const getAllClientFailure = (data) => ({
  type: saleActionTypes.GET_ALL_CLIENTS_FAILURE,
  payload: data,
});

export function getAllClientAction(id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getAllClientInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/clients`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllClientSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllClientFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getAllClientFailure(err));
          reject(err);
        });
    });
  };
}





export const updateAssignUnitInitate = () => ({
  type: saleActionTypes.UPDATE_SCHEDULE_PAYMENT_INITIATE,
});

export const updateAssignUnitSuccess = (data) => ({
  type: saleActionTypes.UPDATE_SCHEDULE_PAYMENT_SUCCESS,
  payload: data,
});

export const updateAssignUnitFailure = (data) => ({
  type: saleActionTypes.UPDATE_SCHEDULE_PAYMENT_FAILURE,
  payload: data,
});

export function updateAssignUnitAction(payload, id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(updateAssignUnitInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${
            id ? id : ""
          }/assign-unit`, payload,
          {
            headers: { Authorization: ` Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if ((data && data.status == 200) || 201) {
            dispatch(updateAssignUnitSuccess(data.data));
            resolve(data);
          } else {
            dispatch(updateAssignUnitFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateAssignUnitFailure(err));
          reject(err);
        });
    });
  };
}










export const addParkingInitate = () => ({
  type: saleActionTypes.ADD_PARKING_INITIATE,
});

export const addParkingSuccess = (data) => ({
  type: saleActionTypes.ADD_PARKING_SUCCESS,
  payload: data,
});

export const addParkingFailure = (data) => ({
  type: saleActionTypes.ADD_PARKING_FAILURE,
  payload: data,
});

export function addParkingAction(
  id,
  payload,
 
) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ suppDoc: payload.suppDoc, ...payload })
  return (dispatch) => {
    dispatch(addParkingInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/parkings`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if ((data && data.status == 201) || 200) {
            dispatch(
              addParkingSuccess(data.data)
            );
            resolve(data);
          } else {
            dispatch(
              addParkingFailure(data.data)
            );
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addParkingFailure(err));
          reject(err);
        });
    });
  };
}




export const getParkingListInitate = () => ({
  type: saleActionTypes.GET_PARKING_LIST_INITIATE,
});

export const getParkingListSuccess = (data) => ({
  type: saleActionTypes.GET_PARKING_LIST_SUCCESS,
  payload: data,
});

export const getParkingListFailure = (data) => ({
  type: saleActionTypes.GET_PARKING_LIST_FAILURE,
  payload: data,
});

export function getParkingListAction(id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getParkingListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/parkings`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getParkingListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getParkingListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getParkingListFailure(err));
          reject(err);
        });
    });
  };
}





export const removeParkingInitate = () => ({
  type: saleActionTypes.CANCEL_PARKING_LIST_INITIATE,
});

export const removeParkingSuccess = (data) => ({
  type: saleActionTypes.CANCEL_PARKING_LIST_SUCCESS,
  payload: data,
});

export const removeParkingFailure = (data) => ({
  type: saleActionTypes.CANCEL_PARKING_LIST_FAILURE,
  payload: data,
});

export function removeParkingAction( id ,cid) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removeParkingInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/parkings/${cid}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removeParkingSuccess(data));
            resolve(data);
          } else {
            dispatch(removeParkingFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeParkingFailure(err));
          reject(err);
        });
    });
  };
}




export const updateConditionalDetailsInitate = () => ({
  type: saleActionTypes.UPDATE_CONDITIONAL_INITIATE,
});

export const updateConditionalDetailsSuccess = (data) => ({
  type: saleActionTypes.UPDATE_CONDITIONAL_SUCCESS,
  payload: data,
});

export const updateConditionalDetailsFailure = (data) => ({
  type: saleActionTypes.UPDATE_CONDITIONAL_SUCCESS,
  payload: data,
});

export function updateConditionalDetailsAction(payload, id) {
  console.log(("-a----d-f-dfd", payload, id))
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(updateConditionalDetailsInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/conditional`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateConditionalDetailsSuccess(data));
            resolve(data);
          } else {
            dispatch(updateConditionalDetailsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateConditionalDetailsFailure(err));
          reject(err);
        });
    });
  };
}



export const addSOAInitate = () => ({
  type: saleActionTypes.ADD_SOA_INITIATE,
});

export const addSOASuccess = (data) => ({
  type: saleActionTypes.ADD_SOA_SUCCESS,
  payload: data,
});

export const addSOAFailure = (data) => ({
  type: saleActionTypes.ADD_SOA_FAILURE,
  payload: data,
});

export function addSOAAction(id,payload = {}) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addSOAInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/create-soa`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 200) {
            dispatch(addSOASuccess(data.data));
            resolve(data);
          } else {
            dispatch(addSOAFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addSOAFailure(err));
          reject(err);
        });
    });
  };
}


export const getSOADetailsInitate = () => ({
  type: saleActionTypes.GET_SOA_DETAILS_INITIATE,
});

export const getSOADetailsSuccess = (data) => ({
  type: saleActionTypes.GET_SOA_DETAILS_SUCCESS,
  payload: data,
});

export const getSOADetailsFailure = (data) => ({
  type: saleActionTypes.GET_SOA_DETAILS_FAILURE,
  payload: data,
});

export function getSOADetailsAction(id,payload = {}) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getSOADetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/soa`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("data", response);
          if (data && data.status == 201 || data.status == 200 ) {
            dispatch(getSOADetailsSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getSOADetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getSOADetailsFailure(err));
          reject(err);
        });
    });
  };
}




export const addUnlockCondtionInitate = () => ({
  type: saleActionTypes.ADD_UNLOCK_CONDITION_INITIATE,
});

export const addUnlockCondtionSuccess = (data) => ({
  type: saleActionTypes.ADD_UNLOCK_CONDITION_SUCCESS,
  payload: data,
});

export const addUnlockCondtionFailure = (data) => ({
  type: saleActionTypes.ADD_UNLOCK_CONDITION_FAILURE,
  payload: data,
});

export function addUnlockCondtionAction(id,payload = {}) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addUnlockCondtionInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/satisfied-condition`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201 ||  data.status == 200) {
            dispatch(addUnlockCondtionSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addUnlockCondtionFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addUnlockCondtionFailure(err));
          reject(err);
        });
    });
  };
}


export const getUnlockConditonListInitate = () => ({
  type: saleActionTypes.UNLOCK_CONDITION_LIST_INITIATE,
});

export const getUnlockConditonListSuccess = (data) => ({
  type: saleActionTypes.UNLOCK_CONDITION_LIST_SUCCESS,
  payload: data,
});

export const getUnlockConditonListFailure = (data) => ({
  type: saleActionTypes.UNLOCK_CONDITION_LIST_FAILURE,
  payload: data,
});

export function getUnlockConditonListAction(id,) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getUnlockConditonListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/get-satisfied-condition-approver`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 200) {
            dispatch(getUnlockConditonListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getUnlockConditonListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getUnlockConditonListFailure(err));
          reject(err);
        });
    });
  };
}





export const getUnlockConditionalDetailsInitate = () => ({
  type: saleActionTypes.UNLOCK_CONDITION_DETAILS_INITIATE,
});

export const getUnlockConditionalDetailsSuccess = (data) => ({
  type: saleActionTypes.UNLOCK_CONDITION_DETAILS_SUCCESS,
  payload: data,
});

export const getUnlockConditionalDetailsFailure = (data) => ({
  type: saleActionTypes.UNLOCK_CONDITION_DETAILS_FAILURE,
  payload: data,
});

export function getUnlockConditionalDetailsAction(id,payload = {}) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getUnlockConditionalDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/soa`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(getUnlockConditionalDetailsSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getUnlockConditionalDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getUnlockConditionalDetailsFailure(err));
          reject(err);
        });
    });
  };
}





export const changeUnlockConditionalStatusInitate = () => ({
  type: saleActionTypes.CHANGE_UNLOCK_STATUS_LIST_INITIATE,
});

export const changeUnlockConditionalStatusSuccess = (data) => ({
  type: saleActionTypes.CHANGE_UNLOCK_STATUS_LIST_SUCCESS,
  payload: data,
});

export const changeUnlockConditionalStatusFailure = (data) => ({
  type: saleActionTypes.CHANGE_UNLOCK_STATUS_LIST_FAILURE,
  payload: data,
});

export function changeUnlockConditionalStatusAction(modalID, secID, status) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(changeUnlockConditionalStatusInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${modalID}/approve-status/${secID}`, {approvalStatus:status}, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(changeUnlockConditionalStatusSuccess(data.data));
            resolve(data);
          } else {
            dispatch(changeUnlockConditionalStatusFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("errrr",err);
          dispatch(changeUnlockConditionalStatusFailure(err));
          reject(err?.response.data.message);
        });
    });
  };
}



export const SOAHistoryInitate = () => ({
  type: saleActionTypes.SOA_HISTORY_INITIATE,
});

export const SOAHistorySuccess = (data) => ({
  type: saleActionTypes.SOA_HISTORY_SUCCESS,
  payload: data,
});

export const SOAHistoryFailure = (data) => ({
  type: saleActionTypes.SOA_HISTORY_FAILURE,
  payload: data,
});

export function SOAHistoryAction(mid,id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(SOAHistoryInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${mid}/soa/${id}/history`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 200) {
            dispatch(SOAHistorySuccess(data.data));
            resolve(data);
          } else {
            dispatch(SOAHistoryFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(SOAHistoryFailure(err));
          reject(err);
        });
    });
  };
}



//=================================reslease=template===list===================

export const templateReleaseListInitiate = () => ({
  type: saleActionTypes.TEMPLATE_RELEASE_LIST_INITIATE,
});

export const templateReleaseListSuccess = (data) => ({
  type: saleActionTypes.TEMPLATE_RELEASE_LIST_SUCCESS,
  payload: data,
});

export const  templateReleaseListFailure = (data) => ({
  type: saleActionTypes.TEMPLATE_RELEASE_LIST_FAILURE,
  payload: data,
});

export const getTemplateReleaseList = () => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(templateReleaseListInitiate())
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/released-template`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(templateReleaseListSuccess(response.data));
    } else {
      dispatch(templateReleaseListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(templateReleaseListFailure(err));
    return err.response;
  }
};

//===========================delete==draft===================


export const deleteDraftTemplateInitiate = () => ({
  type: saleActionTypes.DELETE_DRAFT_TEMPLATE_INITIATE,
});

export const deleteDraftTemplateSuccess = (data) => ({
  type: saleActionTypes.DELETE_DRAFT_TEMPLATE_SUCCESS,
  payload: data,
});

export const  deleteDraftTemplateFailure = (data) => ({
  type: saleActionTypes.DELETE_DRAFT_TEMPLATE_FAILURE,
  payload: data,
});

export const deleteDraftTemplate = (templId) => async (dispatch) => {

  try {
    const token = isLoggedIn("adminData1");
    dispatch(deleteDraftTemplateInitiate());
    const response = await axios.delete(
      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contactId}/released-templates/${templId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(deleteDraftTemplateSuccess(response.data));
    } else {
      dispatch(deleteDraftTemplateFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deleteDraftTemplateFailure(err));
    return err.response;
  }
};

//===========================get-client-ctc-history----------------===


export const getClientCtcGenerateHistoryInitiate = () => ({
  type: saleActionTypes.GET_CLIENT_CTC_GENERATE_HISTORY_INITIATE,
});

export const getClientCtcGenerateHistorySuccess = (data) => ({
  type: saleActionTypes.GET_CLIENT_CTC_GENERATE_HISTORY_SUCCESS,
  payload: data,
});

export const  getClientCtcGenerateHistoryFailure = (data) => ({
  type: saleActionTypes.GET_CLIENT_CTC_GENERATE_HISTORY_FAILURE,
  payload: data,
});

export const getClientCtcGenerateHistory = (ctcId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getClientCtcGenerateHistoryInitiate())
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contactId}/client-ctc-generate/${ctcId}/history`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getClientCtcGenerateHistorySuccess(response.data));
    } else {
      dispatch(getClientCtcGenerateHistoryFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getClientCtcGenerateHistoryFailure(err));
    return err.response;
  }
};

//===============================GET-client-ctc---by--------template====================


export const getClientCtcByTemplateInitiate = () => ({
  type: saleActionTypes.GET_CLIENT_CTC_BY_TEMPLATE_INITIATE,
});

export const getClientCtcByTemplateSuccess = (data) => ({
  type: saleActionTypes.GET_CLIENT_CTC_BY_TEMPLATE_SUCCESS,
  payload: data,
});

export const  getClientCtcByTemplateFailure = (data) => ({
  type: saleActionTypes.GET_CLIENT_CTC_BY_TEMPLATE_FAILURE,
  payload: data,
});

export const getClientCtcByTemplate = (contactId,ctcId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getClientCtcByTemplateInitiate())
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contactId}/ctc-templates/${ctcId}/ctc-client`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getClientCtcByTemplateSuccess(response.data));
    } else {
      dispatch(getClientCtcByTemplateFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getClientCtcByTemplateFailure(err));
    return err.response;
  }
};

//=============================================GET-cost-reakdown-list===================

export const getCostBreakDownInitiate = () => ({
  type: saleActionTypes.GET_COST_BREAKDOWN_INITIATE,
});

export const getCostBreakDownSuccess = (data) => ({
  type: saleActionTypes.GET_COST_BREAKDOWN_SUCCESS,
  payload: data,
});

export const  getCostBreakDownFailure = (data) => ({
  type: saleActionTypes.GET_COST_BREAKDOWN_FAILURE,
  payload: data,
});

export const getCostBreakDown = (contractId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getCostBreakDownInitiate())
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contractId}/cost-breakdown`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getCostBreakDownSuccess(response.data));
    } else {
      dispatch(getCostBreakDownFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getCostBreakDownFailure(err));
    return err.response;
  }
};

//=========================================get--margin==breakdown==========================

export const getMarginBreakDownInitiate = () => ({
  type: saleActionTypes.GET_MARGIN_BREAKDOWN_INITIATE,
});

export const getMarginBreakDownSuccess = (data) => ({
  type: saleActionTypes.GET_MARGIN_BREAKDOWN_SUCCESS,
  payload: data,
});

export const  getMarginBreakDownFailure = (data) => ({
  type: saleActionTypes.GET_MARGIN_BREAKDOWN_FAILURE,
  payload: data,
});

export const getMarginBreakDown = (contractId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getMarginBreakDownInitiate())
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contractId}/margin-breakdown`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getMarginBreakDownSuccess(response.data));
    } else {
      dispatch(getMarginBreakDownFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getMarginBreakDownFailure(err));
    return err.response;
  }
};

//==================================create-client--=============================


export const createClientDraftInitiate = () => ({
  type: saleActionTypes.CREATE_CLIENT_DRAFT_INITIATE,
});

export const createClientDraftSuccess = (data) => ({
  type: saleActionTypes.CREATE_CLIENT_DRAFT_SUCCESS,
  payload: data,
});

export const  createClientDraftFailure = (data) => ({
  type: saleActionTypes.CREATE_CLIENT_DRAFT_FAILURE,
  payload: data,
});

export const createClientDraft = (id,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    const updatedData = multiPartData({ ...payload });
    dispatch(createClientDraftInitiate())
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/client-ctc-draft`,updatedData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(createClientDraftSuccess(response.data));
    } else {
      dispatch(createClientDraftFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(createClientDraftFailure(err));
    return err.response;
  }
};




export const generateClientCTC = (id,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/client-ctc-generate/${payload}`,{},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
    } else {
    }
    return response;
  } catch (err) {
   
    return err.response;
  }
};

//=====================================send-for-client-signature==================


export const sendForClientSignatureInitiate = () => ({
  type: saleActionTypes.SEND_FOR_CLIENT_SIGNATURES_INITIATE,
});

export const sendForClientSignatureSuccess = (data) => ({
  type: saleActionTypes.SEND_FOR_CLIENT_SIGNATURES_SUCCESS,
  payload: data,
});

export const  sendForClientSignatureFailure = (data) => ({
  type: saleActionTypes.SEND_FOR_CLIENT_SIGNATURES_FAILURE,
  payload: data,
});

export const sendForClientSignatures = (contactId,ctcId,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(sendForClientSignatureInitiate())
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contactId}/client-ctc/${ctcId}/client-email-send`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(sendForClientSignatureSuccess(response.data));
    } else {
      dispatch(sendForClientSignatureFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(sendForClientSignatureFailure(err));
    return err.response;
  }
};




// export const checkUncheckContractListInitiate = () => ({
//   type: saleActionTypes.GET_CLIENT_CTC_BY_TEMPLATE_INITIATE,
// });

// export const checkUncheckContractListSuccess = (data) => ({
//   type: saleActionTypes.GET_CLIENT_CTC_BY_TEMPLATE_SUCCESS,
//   payload: data,
// });

// export const  checkUncheckContractListFailure = (data) => ({
//   type: saleActionTypes.GET_CLIENT_CTC_BY_TEMPLATE_FAILURE,
//   payload: data,
// });

export const checkUncheckContractList = (id, payload) => async (dispatch) => {
  console.log(payload)
  try {
    const token = isLoggedIn("adminData1");
    // dispatch(checkUncheckContractListInitiate())
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/lock-unlock`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
     
      // dispatch(checkUncheckContractListSuccess(response.data));
    } else {
      // dispatch(checkUncheckContractListFailure(response.data));
    }
    return response;
  } catch (err) {
    // dispatch(checkUncheckContractListFailure(err));
    return err.response;
  }
};

//============================send--for--client--------signature-----------------

export const sendForClientSignaturesInitiate = () => ({
  type: saleActionTypes.SEND_DOCUMENT_FOR_CLIENT_SIGNATURE_INITIATE,
});

export const sendForClientSignaturesSuccess = (data) => ({
  type: saleActionTypes.SEND_DOCUMENT_FOR_CLIENT_SIGNATURE_SUCCESS,
  payload: data,
});

export const sendForClientSignaturesFailure = (data) => ({
  type: saleActionTypes.SEND_DOCUMENT_FOR_CLIENT_SIGNATURE_FAILURE,
  payload: data,
});

export const sendForClientsSignature = (folderId,docsId,contracttableId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(sendForClientSignaturesInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/design-docs/${docsId}/send-for-signature?contracttableId=${contracttableId}&contracttableType=Contract`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(sendForClientSignaturesSuccess(response.data));
      } else {
        dispatch(sendForClientSignaturesFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(sendForClientSignaturesFailure(err));
      return err.response
    }
  };
//=============================get-gross-margin===============

export const getGrossMarginInitiate = () => ({
  type: saleActionTypes.GET_GROSS_MARGIN_INITIATE,
});

export const getGrossMarginSuccess = (data) => ({
  type: saleActionTypes.GET_GROSS_MARGIN_SUCCESS,
  payload: data,
});

export const getGrossMarginFailure = (data) => ({
  type: saleActionTypes.GET_GROSS_MARGIN_FAILURE,
  payload: data,
});

export const getGrossMargin = (contactId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getGrossMarginInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contactId}/margin-breakdown`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getGrossMarginSuccess(response.data));
      } else {
        dispatch(getGrossMarginFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getGrossMarginFailure(err));
      return err.response
    }
  };

//========================get-client-docs--by--id================

export const getClientDocsByIdInitiate = () => ({
  type: saleActionTypes.GET_CLIENT_DOCS_BY_ID_INITIATE,
});

export const getClientDocsByIdSuccess = (data) => ({
  type: saleActionTypes.GET_CLIENT_DOCS_BY_ID_SUCCESS,
  payload: data,
});

export const getClientDocsByIdFailure = (data) => ({
  type: saleActionTypes.GET_CLIENT_DOCS_BY_ID_FAILURE,
  payload: data,
});

export const getClientDocsById = (folderId,clientDocsId,contarctId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getClientDocsByIdInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${clientDocsId}?contracttableId=${contarctId}&contracttableType=Contract`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getClientDocsByIdSuccess(response.data));
      } else {
        dispatch(getClientDocsByIdFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getClientDocsByIdFailure(err));
      return err.response
    }
  };



  
export function addMainContractAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addContractsInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            dispatch(addContractsSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addContractsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addContractsFailure(err));
          reject(err);
        });
    });
  };
}

//==================BUILDER-SIGNEE-LIST================

export const builderSigneeListInitiate = () => ({
  type: saleActionTypes.BUILDER_SIGNEE_LIST_INITIATE,
});

export const builderSigneeListSuccess = (data) => ({
  type: saleActionTypes.BUILDER_SIGNEE_LIST_SUCCESS,
  payload: data,
});

export const builderSigneeListFailure = (data) => ({
  type: saleActionTypes.BUILDER_SIGNEE_LIST_FAILURE,
  payload: data,
});

export const getBuilderSigneeList = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(builderSigneeListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/staff`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getClientDocsByIdSuccess(response.data));
      } else {
        dispatch(getClientDocsByIdFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getClientDocsByIdFailure(err));
      return err.response
    }
  };
//===========================generated--document-for---client----signature=====================


export const generatedDocumentForClientSignatureInitiate = () => ({
  type: saleActionTypes.GENERATED_DOCUMENT_FOR_CLIENT_SIGNATURE_INITIATE,
});

export const generatedDocumentForClientSignatureSuccess = (data) => ({
  type: saleActionTypes.GENERATED_DOCUMENT_FOR_CLIENT_SIGNATURE_SUCCESS,
  payload: data,
});

export const generatedDocumentForClientSignatureFailure = (data) => ({
  type: saleActionTypes.GENERATED_DOCUMENT_FOR_CLIENT_SIGNATURE_FAILURE,
  payload: data,
});

export const generatedDocumentForClientSignature = (folderId,clientDocsId,contactId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(generatedDocumentForClientSignatureInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${clientDocsId}/generate-sales-sign-flow?contracttableId=${contactId}&contracttableType=Contract`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(generatedDocumentForClientSignatureSuccess(response.data));
      } else {
        dispatch(generatedDocumentForClientSignatureFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(generatedDocumentForClientSignatureFailure(err));
      return err.response
    }
  };

//=========================GET-ALL-SIGNATURE================


export const getAllSignatureInitiate = () => ({
  type: saleActionTypes.GET_ALL_SIGNATURE_INITIATE,
});

export const getAllSignatureSuccess = (data) => ({
  type: saleActionTypes.GET_ALL_SIGNATURE_SUCCESS,
  payload: data,
});

export const getAllSignatureFailure = (data) => ({
  type: saleActionTypes.GET_ALL_SIGNATURE_FAILURE,
  payload: data,
});

export const getAllSignature = (contractId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getAllSignatureInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contractId}/image-uploads`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getAllSignatureSuccess(response.data));
      } else {
        dispatch(getAllSignatureFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAllSignatureFailure(err));
      return err.response
    }
  };

//==========================DELETE-SIGNATURE=======================


export const deleteSignatureInitiate = () => ({
  type: saleActionTypes.DELETE_SIGNATURE_INITIATE,
});

export const deleteSignatureSuccess = (data) => ({
  type: saleActionTypes.DELETE_SIGNATURE_SUCCESS,
  payload: data,
});

export const deleteSignatureFailure = (data) => ({
  type: saleActionTypes.DELETE_SIGNATURE_FAILURE,
  payload: data,
});

export const deleteSignature = (contractId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(deleteSignatureInitiate());
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contractId}/image-uploads`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(deleteSignatureSuccess(response.data));
      } else {
        dispatch(deleteSignatureFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteSignatureFailure(err));
      return err.response
    }
  };
//========================GET-SALES ==TEMPLATE=BY=ID===========================


export const getSalesTemplateByIdInitiate = () => ({
  type: saleActionTypes.GET_SALES_TEMPLATE_BY_ID_INITIATE,
});

export const getSalesTemplateByIdSuccess = (data) => ({
  type: saleActionTypes.GET_SALES_TEMPLATE_BY_ID_SUCCESS,
  payload: data,
});

export const getSalesTemplateByIdFailure = (data) => ({
  type: saleActionTypes.GET_SALES_TEMPLATE_BY_ID_FAILURE,
  payload: data,
});

export const getSalesTemplateByIds = (documentId,companyId,token) => async (dispatch) => {
    try {
      dispatch(getSalesTemplateByIdInitiate());
      const response = await axios.get(`${Url}companies/${companyId}/sales-template/${documentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getSalesTemplateByIdSuccess(response.data));
      } else {
        dispatch(getSalesTemplateByIdFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getSalesTemplateByIdFailure(err));
      return err.response
    }
  };

//==================convert--to==html============================
export const getConverToStringInitiate = () => ({
  type: saleActionTypes.GET_CONVERT_TO_STRING_INITIATE,
});

export const getConverToStringSuccess = (data) => ({
  type: saleActionTypes.GET_CONVERT_TO_STRING_SUCCESS,
  payload: data,
});

export const getConverToStringFailure = (data) => ({
  type: saleActionTypes.GET_CONVERT_TO_STRING_FAILURE,
  payload: data,
});

export const getConverToString = (payload,token) => async (dispatch) => {
  
    try {
      dispatch(getConverToStringInitiate());
      const response = await axios.get(encodeURI(`${Url}convertToString?url=${payload}`), {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getConverToStringSuccess(response.data));
      } else {
        dispatch(getConverToStringFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getConverToStringFailure(err));
      return err.response
    }
  };

//========================add-sgin--in--sales---templates==============

export const addSignInTemplatesInitiate = () => ({
  type: saleActionTypes.ADD_SIGN_IN_SALES_TEMPLATES_INITIATE,
});

export const addSignInTemplatesSuccess = (data) => ({
  type: saleActionTypes.ADD_SIGN_IN_SALES_TEMPLATES_SUCCESS,
  payload: data,
});

export const addSignInTemplatesFailure = (data) => ({
  type: saleActionTypes.ADD_SIGN_IN_SALES_TEMPLATES_FAILURE,
  payload: data,
});

export const addSignInTemplated = (companyId,documentId,payload,token) => async (dispatch) => {
  
    try {
      dispatch(addSignInTemplatesInitiate());
      const response = await axios.post(`${Url}companies/${companyId}/sales-template/${documentId}/add-sign`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(addSignInTemplatesSuccess(response.data));
      } else {
        dispatch(addSignInTemplatesFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addSignInTemplatesFailure(err));
      return err.response
    }
  };



  
export const addClientAction = (id, payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id}/clients`,payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 200) {
    } else {
    }
    return response;
  } catch (err) {
    return err.response
  }
};




//==============================get-client-doc-template=================


export const getClientDocTemplatesInitiate = () => ({
  type: saleActionTypes.GET_CLIENT_DOC_TEMPLATE_INITIATE,
});

export const getClientDocTemplatesSuccess = (data) => ({
  type: saleActionTypes.GET_CLIENT_DOC_TEMPLATE_SUCCESS,
  payload: data,
});

export const getClientDocTemplatesFailure = (data) => ({
  type: saleActionTypes.GET_CLIENT_DOC_TEMPLATE_FAILURE,
  payload: data,
});

export const getClientDocTemplates = (docId,contractId) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getClientDocTemplatesInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/client-docs/${docId}?contractId=${contractId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getClientDocTemplatesSuccess(response.data));
      } else {
        dispatch(getClientDocTemplatesFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getClientDocTemplatesFailure(err));
      return err.response
    }
  };
//===========================SEND-SMS--TO--LEAD===========

export const sendSmsToLeadInitiate = () => ({
  type: saleActionTypes.SEND_SMS_TO_LEAD_INITIATE,
});

export const sendSmsToLeadSuccess = (data) => ({
  type: saleActionTypes.SEND_SMS_TO_LEAD_SUCCESS,
  payload: data,
});

export const sendSmsToLeadFailure = (data) => ({
  type: saleActionTypes.SEND_SMS_TO_LEAD_FAILURE,
  payload: data,
});

export const sendSmsToLead = (leadId,payload) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(sendSmsToLeadInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${leadId}/send-sms`, payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(sendSmsToLeadSuccess(response.data));
      } else {
        dispatch(sendSmsToLeadFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(sendSmsToLeadFailure(err));
      return err.response
    }
  };

//===================REMOVED--ASSSIGN--UNIT=================

export const removedAssignUnitInitiate = () => ({
  type: saleActionTypes.REMOVED_ASSIGN_UNIT_INITIATE,
});

export const removedAssignUnitSuccess = (data) => ({
  type: saleActionTypes.REMOVED_ASSIGN_UNIT_SUCCESS,
  payload: data,
});

export const removedAssignUnitFailure = (data) => ({
  type: saleActionTypes.REMOVED_ASSIGN_UNIT_FAILURE,
  payload: data,
});

export const removedAssignUnits = (contractId) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(removedAssignUnitInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contractId}/remove-unit`,{},{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(removedAssignUnitSuccess(response.data));
      } else {
        dispatch(removedAssignUnitFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(removedAssignUnitFailure(err));
      return err.response
    }
  };

//==============UPDATE--CONATCT-DETAILS===============

export const updateContactDetailsInitiate = () => ({
  type: saleActionTypes.UPDATE_CONTACT_DETAILS_INITIATE,
});

export const updateContactDetailsSuccess = (data) => ({
  type: saleActionTypes.UPDATE_CONTACT_DETAILS_SUCCESS,
  payload: data,
});

export const updateContactDetailsFailure = (data) => ({
  type: saleActionTypes.UPDATE_CONTACT_DETAILS_FAILURE,
  payload: data,
});

export const updateContactDetails = (leadId,payload) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateContactDetailsInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${leadId}/contact`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateContactDetailsSuccess(response.data));
      } else {
        dispatch(updateContactDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateContactDetailsFailure(err));
      return err.response
    }
  };

//=============SEND--NEW--EMAIL=============================

export const sendNewEmailInitiate = () => ({
  type: saleActionTypes.SEND_NEW_EMAIL_INITIATE,
});

export const sendNewEmailSuccess = (data) => ({
  type: saleActionTypes.SEND_NEW_EMAIL_SUCCESS,
  payload: data,
});

export const sendNewEmailFailure = (data) => ({
  type: saleActionTypes.SEND_NEW_EMAIL_FAILURE,
  payload: data,
});

export const sendNewEmails = (leadId,payload) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(sendNewEmailInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${leadId}/send-email`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(sendNewEmailSuccess(response.data));
      } else {
        dispatch(sendNewEmailFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(sendNewEmailFailure(err));
      return err.response
    }
  };

//===============


export const clientDocTemplateInitiate = () => ({
  type: saleActionTypes.CLIENT_DOC_TEMPLATE_INITIATE,
});

export const clientDocTemplateSuccess = (data) => ({
  type: saleActionTypes.CLIENT_DOC_TEMPLATE_SUCCESS,
  payload: data,
});

export const clientDocTemplateFailure = (data) => ({
  type: saleActionTypes.CLIENT_DOC_TEMPLATE_FAILURE,
  payload: data,
});

export const clientDocTemplates = (payload) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(clientDocTemplateInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/generate-client-docs-document`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(clientDocTemplateSuccess(response.data));
      } else {
        dispatch(clientDocTemplateFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(clientDocTemplateFailure(err));
      return err.response
    }
  };

//========================SET=PAYEMNET==SECHELUE========================

export const setPaymentScheduleInitiate = () => ({
  type: saleActionTypes.SET_PAYMENT_SCHEDULE_INITIATE,
});

export const setPaymentScheduleSuccess = (data) => ({
  type: saleActionTypes.SET_PAYMENT_SCHEDULE_SUCCESS,
  payload: data,
});

export const setPaymentScheduleFailure = (data) => ({
  type: saleActionTypes.SET_PAYMENT_SCHEDULE_FAILURE,
  payload: data,
});

export const addPaymentSchedules = (contractId,schedulePayId,payload) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(setPaymentScheduleInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contractId}/schedule-payments/${schedulePayId}/reminder`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(setPaymentScheduleSuccess(response.data));
      } else {
        dispatch(setPaymentScheduleFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(setPaymentScheduleFailure(err));
      return err.response
    }
  };




  
export const removeReleaseCTCInitate = () => ({
  type: saleActionTypes.DELETE_SUPPORTING_DOCS_INITIATE,
});

export const removeReleaseCTCSuccess = (data) => ({
  type: saleActionTypes.DELETE_SUPPORTING_DOCS_SUCCESS,
  payload: data,
});

export const removeReleaseCTCFailure = (data) => ({
  type: saleActionTypes.DELETE_SUPPORTING_DOCS_FAILURE,
  payload: data,
});

export function removeReleaseCTCAction( id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removeReleaseCTCInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/released-template/${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removeReleaseCTCSuccess(data));
            resolve(data);
          } else {
            dispatch(removeReleaseCTCFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeReleaseCTCFailure(err));
          reject(err);
        });
    });
  };
}



