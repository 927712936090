import logo from './logo.svg';
import './App.css';
import { Route, HashRouter, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom'
import { ToastContainer, toast, Slide } from "react-toastify";
import Home from './components/Home';
import AppointementsTradeView from './components/appointements-trade-view';
import ArchiveLandDevelopment from './components/archive-land-development';
import ArchiveMultiFamily from './components/archive_multi_family';
import Change_Order_Checklist_Tradeview from './components/change-order-checklist-tradeview';
import ChangeOrderDiscussion from './components/change-order-discussion';
import ArchiveResidential from './components/archive_residential';
import Archive from './components/archive';
import ChangeOrder from './components/change_order';
import ClientViewHome from './components/client-view-home';
import ConstructionScheduleTradeview from './components/construction-schedule-tradeview';
import Construction from './components/construction';
import CreateNewPassword from './components/create-new-password';
import FileManagerConstruction from './components/file-manager-construction';
import FileManagerTradeView from './components/file-manager-trade-view';
import FileManagerWarranty from './components/file-manager-warranty';
import FileManager from './components/file-manager';
import HouseWarrantyWorkOrderDetails from './components/house-warranty-work-order-details';
import HouseWarranty from './components/house-warranty';
import HouseRoughWarranty from './components/house-rough-warranty';
import IndividualComponentsUserWorkflowTasks from './components/individual_components_part2_User WorkflowTasks';
import IndivisualComponentsPartVendorList from './components/individual_components_part2_Vendor_List';
import Itinerary from './components/Itinerary';
import LandDevelopment from './components/land_development';
import LeadContractManagment from './components/lead_contract_managment';
import LoginForgot from './components/login-forgot';
import Login from './components/login';
import MultiFamily from './components/multi_family';
import NewPasswordCreated from './components/new_password_created';
import PoqrTradeview from './components/po_qr_tradeview';
import PriceBook from './components/price-book';
import Residential from './components/residential';
import SalesDocument from './components/sales_document';
import TodoTradeview from './components/todo-tradeview';
import Todos from './components/todos';
import TradeViewHome from './components/trade-view-home';
import Warranty_Dashboard from './components/Warranty_Dashboard';
import WarrantyRequestClientDetails from './components/warranty_request_client_details';
import Warranty_request_client from './components/warranty_request_client';
import Warranty_Request_work_order_table from './components/Warranty_Request_work_order_table';
import Warranty_work_order_tradeview_workorder from './components/warranty_work_order_tradeview_workorder';
import Warranty_Work_Order_Tradeview from './components/warranty_work_order_tradeview';
import Warranty_Work_Order from './components/Warranty_Work_Order';
import WarrantyRequest from './components/Warranty-request';
import WarrantyWorkOrder1 from './components/Warranty-Work_Order1';
import BuilderMultiProjectLogin from './components/builder-multi-project-login';
import BuilderSingleProject from './components/builder-single-project-login';
import ClientViewLogin from './components/client-view-login';
import Ibuildsystemlogin from './components/ibuild_system_login';
import LoginBuilder from './components/login-builder';
import MainCreateNewPassword from './components/main-create-new-password';
import MainLoginForgot from './components/main-login-forgot';
import MainLogin from './components/main-login';
import MainNewPasswordCreated from './components/main-new-password-created';
import CheckoutScreen from './components/checkout-screen';
import PlanScreen from './components/plan-screen';
import SubscriptionStart from './components/subscription-start';
import IbuildLogin from './components/iBuild-login';
import TradeViewLogin from './components/trade-view-login';
import SalesModule from './components/sales-module';
import TransactionManager from './components/transaction-manager';
import Admin from './components/admin';
import EstimationModule from './components/estimation-module';

import InvestorViewLogin from './components/investor-view-login';
// import InvestorForgot from './components/investor-forgot';
// import InvestorCreatePassword from './components/investor-create-password';
import InvestorViewHome from './components/investor-view-home';
import DesignSelections from './components/design-selections';
import ConstructionScheduleTradeView from './components/construction_schedule_tradeview';
import WarrantyWorkOrderTradeViewDetails from './components/warranty_work_order_tradeview_workorder';
import ChangeOrderlist from './components/change-order-list';
import ResetPassword from './components/reset-password';

import ClientViewConfig from './components/client-view-config';
import VendorViewConfig from './components/vendor-view-config';
import DesignSelectionTradeView from './components/design-selection-trade-view';
import Models from './components/models';
import ProjectProforma from './components/project-proforma';
import Systemadministration from './components/system-administration';
import WarrantyWorkOrderDetails from './components/warranty-work-order-details';
import WarrantyRequestDetails from './components/warranty-request-details';
import Notifications from "./components/Notifications"
import IbuildAdminDashboard from './components/IbuildAdmin/IbuildAdminDashboard'
import IbuildAdminPriceBook from './components/IbuildAdmin/IbuildAdminPriceBook'
import IbuildAdminTab from './components/IbuildAdmin/IbuildAdminTab'
import IbuildAdminLogin from './components/IbuildAdmin/IbuildAdminLogin'
import IbuildAdminItinerary from './components/IbuildAdmin/IbuildAdminItinerary'
import IbuildAdminFileManager from './components/IbuildAdmin/IbuildAdminFileManager/IbuildAdminFileManager';
import ReportsMain from './components/Reports/ReportsMain';





import DesignSelectionTradeViewDetails from './components/design_selection_trade_view_details';
import AppointmentsClientView from './components/appointments-client-view';
import ChangeOrderListClient from './components/change-order-list-client';
import DesignSelectionsClientView from './components/design-selections-client-view';
import EditChangeOrderTradeView from './components/edit-change-order-trade-view';
import FileManagerInvestorView from './components/file_manager_investor_view';
import ChangeOrderClientViewDetails from './components/change-order-client-view-details';
import IbuildUserManagement from './components/IbuildAdmin/Ibuild-User-Management';
import TransactionsManager from './components/transactions_manager';
import SubscriptionAgreement from './components/subscription-agreement';
import TradeViewQuoteRequest from './components/TradeViewQuoteRequest';
import TradeViewPurchaseOrder from './components/TradeViewPurchaseOrder';
import TradeViewFpoSc from './components/TradeViewFpoSc';
import DesignSelectionAgreement from './components/DesignSelectionAgreement';
import ClientDocsSign from './components/ClientDocSign';
import ChangeOrderAgreement from './components/change-order-agreement';
import ClientCTCSales from './components/client-ctc-sales';
import ApproveFileManager from './components/ApproveFileManager';
import TradeViewFieldPurchaseOrder from './components/TradeViewFieldPurchaseOrder';
import TradeViewSupplierCredit from './components/TradeViewSupplierCredit';
import EditBackChargeNotification from './components/EditBackChargeNotification';
import EditMassMailOut from './components/EditMassMailOut';
import BudgetChangeOrderAgreement from './components/BudgetChangeOrderAgreement';





function App() {

 
  return (
    <div className="App">
      <ToastContainer />
      <HashRouter>     
      <Routes>
    <Route path="/dashboard" element={<Home/>} />
    <Route path="/appointements-trade-view" element={<AppointementsTradeView/>} />
    <Route path="/archive-land-development" element={<ArchiveLandDevelopment/>} />
    <Route path="/archive-multi-family" element={<ArchiveMultiFamily/>} />
    <Route path="/archive-land-development" element={<ArchiveLandDevelopment/>} />
    <Route path="/change-order-checklist-tradeview" element={<Change_Order_Checklist_Tradeview/>} />
    <Route path="/change-order-discussion" element={<ChangeOrderDiscussion/>} />
    <Route path="/archive-residential" element={<ArchiveResidential/>} />
    <Route path="/archive" element={<Archive/>} />
    <Route path="/change-order" element={<ChangeOrder/>} />
    <Route path="/change-order-list" element={<ChangeOrderlist/>} />
    <Route path="/client-view-home" element={<ClientViewHome/>} />
    <Route path="/construction-schedule-tradeview" element={<ConstructionScheduleTradeview/>} />
    <Route path="/construction" element={<Construction/>} />
    <Route path="/create-new-password" element={<CreateNewPassword/>} />
    <Route path="/file-manager-construction" element={<FileManagerConstruction/>} />
    <Route path="/file-manager-trade-view" element={<FileManagerTradeView/>} />
    <Route path="/file-manager-warranty" element={<FileManagerWarranty/>} />
    <Route path="/file-manager" element={<FileManager/>} />
    <Route path="/house-rough-warranty" element={<HouseRoughWarranty/>} />
    <Route path="/house-warranty" element={<HouseWarranty/>} />
    <Route path="/house-warranty-work-order-details" element={<HouseWarrantyWorkOrderDetails/>} />
    <Route path="/individual-components-part-User-WorkflowTasks" element={<IndividualComponentsUserWorkflowTasks/>} />
    <Route path="/individual-components-part2-Vendor-List" element={<IndivisualComponentsPartVendorList/>} />
    <Route path="/Itinerary" element={<Itinerary/>} />
    <Route path="/land-development" element={<LandDevelopment/>} />
    <Route path="/lead-contract-management" element={<LeadContractManagment/>} />
    <Route path="/login-forgot" element={<LoginForgot/>} />
    <Route path="/login" element={<Login/>} />
    <Route path="/multi-family" element={<MultiFamily/>} />
       
    <Route path="/new-password-created" element={<NewPasswordCreated/>} />
    <Route path="/po-qr-tradeview" element={<PoqrTradeview/>} />
    <Route path="/price-book" element={<PriceBook/>} />
    <Route path="/residential" element={<Residential/>} />
    <Route path="/sales-document" element={<SalesDocument/>} />
    <Route path="/sales" element={<SalesModule/>} />
    <Route path="/todo-tradeview" element={<TodoTradeview/>} />
    <Route path="/todos" element={<Todos/>} />
    <Route path="/trade-view-home" element={<TradeViewHome/>} />
    <Route path="/warranty-dashboard" element={<Warranty_Dashboard/>} />
    <Route path="/warranty-request-client-details" element={<WarrantyRequestClientDetails/>} />
    <Route path="/warranty-request-client" element={<Warranty_request_client/>} />
    <Route path="/warranty-request-work-order-table" element={<Warranty_Request_work_order_table/>} />
    <Route path="/warranty-work-order-tradeview-workorder" element={<Warranty_work_order_tradeview_workorder/>} />
    <Route path="/warranty-work-order-tradeview" element={<Warranty_Work_Order_Tradeview/>} />
    <Route path="/warranty-work-order" element={<Warranty_Work_Order/>} />
    <Route path="/warranty-request" element={<WarrantyRequest/>} />
    <Route path="/warranty-work-order1" element={<WarrantyWorkOrder1/>} />
    <Route path="/warranty-work-order-details" element={<WarrantyWorkOrderDetails/>} />
    <Route path="/warranty-request-details" element={<WarrantyRequestDetails/>} />
    <Route path="/reset-password" element={<ResetPassword/>} />
    <Route path="/design-selection-agreement" element={<DesignSelectionAgreement/>} />
    <Route path="/approve-file-manager" element={<ApproveFileManager/>} />
    <Route path="/edit-mass-mailOut" element={<EditMassMailOut/>} />


             {/* {/ AUTHH-------------------------------------AUTHHH /} */}
    <Route path="/builder-multi-project-login" element={<BuilderMultiProjectLogin/>} />
    <Route path="/builder-single-project-login" element={<BuilderSingleProject/>} />
    <Route path="/client-view-login" element={<ClientViewLogin/>} />
    <Route path="/ibuild-system-login" element={<Ibuildsystemlogin/>} />
    <Route path="/trade-view-login" element={<TradeViewLogin/>} />
    <Route path="/login-builder" element={<LoginBuilder/>} />
    <Route path="/main-create-new-password" element={<MainCreateNewPassword/>} />
    <Route path="/main-login-forgot" element={<MainLoginForgot/>} />
    <Route path="/" element={<MainLogin/>} />
    <Route path="/ibuild-login" element={<IbuildLogin/>} />
    <Route path="/main-new-password-create" element={<MainNewPasswordCreated/>} />

    <Route path="/checkout-screen/:planId/:price" element={<CheckoutScreen/>} />
    <Route path="/plan-screen/:id/:price" element={<PlanScreen/>} />
    <Route path="/subscription-start" element={<SubscriptionStart/>} />
    <Route path="/transaction-manager" element={<TransactionManager/>} />
    <Route path="/admin" element={<Admin/>} />
    <Route path="/estimation" element={<EstimationModule/>} />
    <Route path="/transactions_manager" element={<TransactionsManager/>} />
    <Route path="/edit-backCharge-notification" element={<EditBackChargeNotification/>} />
    <Route path="/budget-changeOrder-agreement" element={<BudgetChangeOrderAgreement/>} />

    <Route path="/client-view-config" element={<ClientViewConfig/>} />
    <Route path="/project-proforma" element={<ProjectProforma/>} />
    <Route path="/models" element={<Models/>} />
    <Route path="/vendor-view-config" element={<VendorViewConfig/>} />

    //==============================investorViewLogin==============================
<Route path="/investor-view-login" element={<InvestorViewLogin/>} />
{/* {/ <Route path="/investor-forgot" element={<InvestorForgot/>} /> /} */}
{/* {/ <Route path="/investor-create-password" element={<InvestorCreatePassword/>} /> /} */}
<Route path="/investor-view-home" element={<InvestorViewHome/>} />
<Route path="/fileManager-investor-view" element={<FileManagerInvestorView/>} />
<Route path="/change-order-list-client" element={<ChangeOrderListClient/>} />
<Route path="/warranty-work-order-tradeview-details" element={<WarrantyWorkOrderTradeViewDetails/>} />
<Route path="/edit-change-order-tradeview" element={<EditChangeOrderTradeView/>} />
<Route path="/change-order-clientview-details" element={<ChangeOrderClientViewDetails/>} />



<Route path="/design-selections" element={<DesignSelections/>} />
<Route path="/design-selections-trade-view-details" element={<DesignSelectionTradeViewDetails/>} />
<Route path="/appointments-client-view" element={<AppointmentsClientView/>} />
<Route path="/design-selections-client-view" element={<DesignSelectionsClientView/>} />
<Route path="/client-doc-sign" element={<ClientDocsSign/>} />
<Route path="/change-order-agreement" element={<ChangeOrderAgreement/>} />
<Route path="/trade-edit-field-purchase-order" element={<TradeViewFieldPurchaseOrder/>} />
<Route path="/trade-edit-supplier-credit" element={<TradeViewSupplierCredit/>} />


<Route path="/construction-schedul-tradeview" element={<ConstructionScheduleTradeView/>} />
<Route path="/todo-tradeview" element={<TodoTradeview/>} />
<Route path="/design-selection-tradeview" element={<DesignSelectionTradeView/>} />

<Route path="/system-adminsitration" element={<Systemadministration/>} />
<Route path="/notification" element={<Notifications/>} />
<Route path="/trade-edit-quote-request" element={<TradeViewQuoteRequest/>} />
<Route path="/trade-edit-purchase-order" element={<TradeViewPurchaseOrder/>} />
<Route path="/trade-view-document" element={<TradeViewFpoSc/>} />



//==============================Ibuild Admin==============================

<Route path="/ibuild-admin-login" element={<IbuildAdminLogin/>} />
<Route path="/ibuild-admin-dashboard" element={<IbuildAdminDashboard/>} />
<Route path="/ibuild-admin-pricebook" element={<IbuildAdminPriceBook/>} />
<Route path="/ibuild-admin-tab" element={<IbuildAdminTab/>} />
<Route path="/ibuild-admin-itinerary" element={<IbuildAdminItinerary />} />
<Route path="/ibuild-admin-file-manager" element={<IbuildAdminFileManager />} />


//==============================Ibuild Admin==============================


<Route path="/reports" element={<ReportsMain />} />
<Route path="/unit-management" element={<IbuildUserManagement />} />

<Route path="/subscription-agreement" element={<SubscriptionAgreement />} />
<Route path="/client-ctc-sales" element={<ClientCTCSales />} />

  </Routes>
  </HashRouter>
    </div>
  );
}

export default App;
