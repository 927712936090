import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getConverToString } from "../redux/action/saleAction";
import { useDispatch } from "react-redux";
import moment from "moment";

const ViewTemplate = ({ ViewTemplateData, show, close }) => {
  console.log(ViewTemplateData, "ViewTemplateData");
  const dispatch = useDispatch();
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const [headerData, setHeaderData] = useState("");

  useEffect(() => {
   if(ViewTemplateData){
    const filename = ViewTemplateData?.htmlBlobName?.split("?")[0];
    dispatch(getConverToString(filename, iBuildLocalData?.user?.token)).then(
      (response) => {
        if (response.status === 200) {
          setHeaderData(response?.data?.data);
        }
      }
    )
}
  }, []);

  const craeteContent = () => {
    return { __html: headerData };
  };
  return (
    <>
      <Modal
        show={show}
        onHide={close}
        className="ModalBox modal fade LargeModal ExtraLargeModalGreen show"
      >
        <Modal.Body>
          <a href="javascript:void(0);" className="CloseModal" onClick={close}>
            ×
          </a>
          <div class="ModalTitleBox">
            <h4>View Template</h4>
          </div>
          <div className="FolderForm">
            <div className="AgreementViewArea mt-5">
              <div className="AgreementViewTop">
                <div className="AgreementViewTopRight">
                  <p
                    dangerouslySetInnerHTML={craeteContent()}
                    className="editor"
                  ></p>
                </div>
              </div>
            </div>
            <div className="ChangeOrderCreatArea mt-5">
              {ViewTemplateData?.ClientDocAssignees?.length > 0 &&
                ViewTemplateData?.ClientDocAssignees?.map((item, i) => {
                  return (
                    <div className="row">
                      <div className="col-md-4">
                        <div className="InputBox">
                          <div className="form-group">
                            <input
                              type="date"
                              disabled
                              value={moment(new Date(item?.updatedAt)).format(
                                "YYYY-MM-DD"
                              )}
                            />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ml-auto">
                        <div className="InputBox">
                          <div className="form-group">
                            <div className="InsertSignature">
                                <a>
                                  <img src={item.signUrl} alt="Signature" />
                                  Signature
                                </a>
                            </div>

                            <label>{item.signeeType}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="Button d-flex justify-content-between">
              <button className="Outline" onClick={close}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewTemplate;
