import React from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import {toast} from 'react-toastify'
import { createProjectAction } from "../../redux/action/projectActionDk";


const NewProjectStep6 = ({
  iState,
  updateState,
  setShow,
  NewProjectStep6Modal,
  SetNewProjectStep3Modal,
  SetNewProjectStep1Modal,
  SetNewProjectStep2Modal,
  SetNewProjectStep4Modal,
  SetNewProjectStep5Modal,
  SetNewProjectStep6Modal,
}) => {

  const dispatch = useDispatch()
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  const handleNewProjectStep6ModalClose = () => {SetNewProjectStep6Modal(false)
    setShow(true)
  }
  const handleNewProjectStep6ModalShow = () => {
    SetNewProjectStep6Modal(true);
  };

  const {quickBooks ,sage,proformaId,integrateAccountingSoftware,useCompanyPattern,subdivisions,projectName, projectLogo, projectEmail, projectAddress, city, country,zipCode,province,projectType,creationType,jobPattern , developer,taxProfile,profileCategories} =iState


  const handleSubmit=(e)=>{
    e.preventDefault()
    let data = {
      projectName ,
      projectLogo ,
      projectEmail ,
    
      projectAddress ,
    
      city ,
    
      country ,
    
      zipCode ,
    
      province ,
    
      projectType ,
    
      creationType ,
    
      subdivisions,
    
      useCompanyPattern,
    
      jobPattern ,
      profileCategories,
      integrateAccountingSoftware,
    
      developer ,
    
      taxProfile ,
    
      proformaId,
    
      quickBooks,
    
      sage,
    }

    dispatch(createProjectAction(data))
    .then((res) => {

      if(res.status==201){

      console.log(res,'RRRRRRRRRR')
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
  
        setShow(true)
        SetNewProjectStep6Modal(false)
  

      }
      else{
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
   
    
    })
    .catch((error) => {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });

  }
  return (
    <Modal show={NewProjectStep6Modal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          href="javascript:void(0);"
          class="CloseModal"
          onClick={handleNewProjectStep6ModalClose}
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Create New Project</h4>
          </div>
          <div class="StepBox">
            <ul>
              <li class="active">
                <span class="Icon">
                  <img
                    src={require("../../assets/images/step_1.png")}
                    onClick={() => {
                      SetNewProjectStep1Modal(true);
                      SetNewProjectStep6Modal(false);
                    }}
                  />
                </span>
                <span class="Text">Details</span>
              </li>
              <li
                class="active"
                onClick={() => {
                  SetNewProjectStep2Modal(true);
                  SetNewProjectStep6Modal(false);
                }}
              >
                <span class="Icon FolderPermissionId ">
                  <img src={require("../../assets/images/step_2.png")} />
                </span>
                <span class="Text">Address</span>
              </li>
              <li class="active">
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetNewProjectStep3Modal(true);
                    SetNewProjectStep6Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_8.png")} />
                </span>
                <span class="Text">
                  Type&amp; <br /> Accounting{" "}
                </span>
              </li>
              <li className="active">
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetNewProjectStep4Modal(true);
                    SetNewProjectStep6Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_5.png")} />
                </span>
                <span class="Text">
                  Job <br /> Numbering
                </span>
              </li>
              <li className="active">
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetNewProjectStep5Modal(true);
                    SetNewProjectStep6Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_6.png")} />
                </span>
                <span class="Text">
                  Cost <br /> Profiles
                </span>
              </li>
              <li className="active">
                <span class="Icon FolderPermissionId " data-toggle="modal">
                  <img src={require("../../assets/images/step_7.png")} />
                </span>
                <span class="Text">Confirmation</span>
              </li>
            </ul>
          </div>
          <div class="FolderForm">
            <form>
              <div class="FolderFormCreateArea">
                <div class="FieldsMandatory">
                  <h6>
                    Fields with <span class="required">*</span> are mandatory
                  </h6>
                </div>

                <div class="row">
                  <div class="form-group col-md-3">
                    <h6>Company</h6>
                    <p>{iBuildLocalData?.companies?.[0]?.name}</p>
                  </div>
                  <div class="form-group col-md-3">
                    <h6>Project Name</h6>
                    <p>{projectName}</p>
                  </div>
                  <div class="form-group col-md-3">
                    <h6>Project Email</h6>
                    <p>{projectEmail}</p>
                  </div>
                  <div class="form-group col-md-3">
                    <h6>Project Logo</h6>
                    <img src={projectLogo} />
                  </div>
                  <div class="form-group col-md-3">
                    <h6>Project Type</h6>
                    <p>{projectType}</p>
                  </div>
                  <div class="form-group col-md-3">
                    <h6>Creation Type</h6>
                    <p>{creationType}</p>
                  </div>
                  <div class="form-group col-md-6">
                    <h6>Accounting Status</h6>
                    <p>Integrated with Company's Accounting Software</p>
                  </div>
                  <div class="SubdivisionsArea col-md-3">
                    <h6>Subdividisions</h6>
                    <div class="SubdivisionsBox">
                      <div class="form-group">
                        <h6>Subdivision</h6>
                        {subdivisions?.map((item)=>
                          <p>{item}</p>
                          
                          )}                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-3">
                    <h6>Developer</h6>
                    <p>{developer}</p>
                  </div>
                  <div class="form-group col-md-3">
                    <h6>Tax Profile</h6>
                    <p>{taxProfile}</p>
                  </div>
                  <div class="form-group col-md-3">
                    <h6>Job Naming Format</h6>
                    <p>{jobPattern}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <h6>Project Address</h6>
                    <p>{projectAddress}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <h6>City</h6>
                    <p>{city}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <h6>State/Province</h6>
                    <p>{province}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <h6>Zip/ Postal Code</h6>
                    <p>{zipCode}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <h6>Country</h6>
                    <p>{country}</p>
                  </div>
                  <div class="form-group col-md-12">
                    <h6>Cost Category Profiles</h6>
                    <p>
                 
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <h6>Include Land Development Shared Costs</h6>
                    <p>Yes - Job: CHI-2B-236</p>
                  </div>
                </div>
              </div>

              <div class="Button d-flex justify-content-between align-items-center px-5">
                <button
                  class="Outline"
                  onClick={() => {
                    SetNewProjectStep6Modal(false);
                    SetNewProjectStep5Modal(true);
                  }}
                >
                  Back
                </button>
                <button class="Create" onClick={handleSubmit} data-toggle="modal">
                  Create New Project
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewProjectStep6;
