import { update } from "lodash";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { checkArray } from "../../utils/CheckType";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import { getAllTaxProfileAction } from "../../redux/action/jobAction";
import { getProformaList } from "../../redux/action/projectProformaAction";

const ProjectAsEntityStep3 = ({
  ProjectAsEntityStep3Modal,
  SetProjectAsEntityStep1Modal,
  SetProjectAsEntityStep2Modal,
  SetProjectAsEntityStep3Modal,
  SetProjectAsEntityStep4Modal,
  SetProjectAsEntityStep5Modal,
  SetProjectAsEntityStep6Modal,
  SetProjectAsEntityStep7Modal,
  SetProjectAsEntityStep8Modal,
  setShow,
  iState,
  UpdateState,
}) => {
  const handleProjectAsEntityStep3ModalClose = () => {
    setShow(true);
    SetProjectAsEntityStep3Modal(false);
  };
  const handleProjectAsEntityStep3ModalShow = () => {
    SetProjectAsEntityStep3Modal(true);
  };

  const { projectType, creationType, developer, subdivisions, errorMsg } =
    iState;

  const dispatch = useDispatch();

  const { projectListData, taxProfileListData } = useSelector(
    (state) => state.jobReducer


   
  );

  const {
    projectProformaList,
   
  } = useSelector((state) => state.projectProformaReducer);

  const [subDisvisionState, setsubDivisonState] = useState("");
  const [subDivisonFlag, setsubDivisonFlag] = useState(false);
  const [tempIndex, setTempIndex] = useState("")
  const [editFlag, setEditFlag] =useState(false)

  const handleValidation = () => {
    let formIsValid = true;
    let errorMsg = {};

    if (!projectType) {
      formIsValid = false;
      errorMsg.projectType = "* Please select project type.";
    }

    if (!creationType) {
      formIsValid = false;
      errorMsg.creationType = "* Please select creation type.";
    }

    UpdateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    UpdateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      SetProjectAsEntityStep3Modal(false);
      SetProjectAsEntityStep4Modal(true);
    }
  };

  const handleProjectType = (value) => {
    UpdateState({ ...iState, projectType: value });
  };

  const handleCreationType = (value) => {
    UpdateState({ ...iState, creationType: value });
  };

  useEffect(() => {
    if (ProjectAsEntityStep3Modal) {
      dispatch(getAllTaxProfileAction());
      dispatch(getProformaList())
    }
  }, [ProjectAsEntityStep3Modal]);

  const handleaddSubDivision = (e) => {
    e.preventDefault();

    UpdateState({
      ...iState,
      subdivisions: [...subdivisions, subDisvisionState],
    });

    setsubDivisonState("");
  };

  const handleDeleteSubDivison = (e, i) => {
    e.preventDefault();

    let temArray = [...subdivisions];

    temArray.splice(i, 1);

    UpdateState({
      ...iState,
      subdivisions: temArray,
    });
  };


  const handleUpdateSubDivison = (e) => {
    e.preventDefault();

    let temArray = [...subdivisions];
    temArray[tempIndex] = subDisvisionState


    UpdateState({
      ...iState,
      subdivisions: temArray,
    });

    setEditFlag(false)
    setsubDivisonState('')

  };


  return (
    <>
      <Modal
        show={ProjectAsEntityStep3Modal}
        className="ModalBox LargeModal BgClass"
      >
        <div>
          <a
            onClick={handleProjectAsEntityStep3ModalClose}
            class="CloseModal"
            data-toggle="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create New Project as an Entity</h4>
            </div>
            <div class="StepBox">
              <ul>
                <li class="active">
                  <span
                    class="Icon"
                    onClick={() => {
                      SetProjectAsEntityStep1Modal(true);
                      SetProjectAsEntityStep3Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_1.png")} />
                  </span>
                  <span class="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    onClick={() => {
                      SetProjectAsEntityStep2Modal(true);
                      SetProjectAsEntityStep3Modal(false);
                    }}
                    class="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityAddress"
                    data-dismiss="modal"
                  >
                    <img src={require("../../assets/images/step_2.png")} />
                  </span>
                  <span class="Text">Address</span>
                </li>
                <li className="active">
                  <span
                    class="Icon FolderPermissioId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityType"
                    data-dismiss="modal"
                  >
                    <img src={require("../../assets/images/step_8.png")} />
                  </span>
                  <span class="Text">Type</span>
                </li>
                <li
                  onClick={() => {
                    SetProjectAsEntityStep4Modal(true);
                    SetProjectAsEntityStep3Modal(false);
                  }}
                >
                  <span
                    class="Icon FolderPermissioId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntitySubscription"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../../assets/images/subscription_step.png")}
                    />
                  </span>
                  <span class="Text">Subscription</span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      SetProjectAsEntityStep5Modal(true);
                      SetProjectAsEntityStep3Modal(false);
                    }}
                    class="Icon FolderPermissioId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityTax"
                    data-dismiss="modal"
                  >
                    <img ssrc={require("../../assets/images/step_3.png")} />
                  </span>
                  <span class="Text">
                    Accounting <br /> &amp; Tax
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      SetProjectAsEntityStep6Modal(true);
                      SetProjectAsEntityStep3Modal(false);
                    }}
                    class="Icon FolderPermissioId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityNumber"
                    data-dismiss="modal"
                  >
                    <img src={require("../../assets/images/step_5.png")} />
                  </span>
                  <span class="Text">Job Number</span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      SetProjectAsEntityStep7Modal(true);
                      SetProjectAsEntityStep3Modal(false);
                    }}
                    class="Icon FolderPermissioId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityInitial"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../../assets/images/initial_settings.png")}
                    />
                  </span>
                  <span class="Text">
                    Initial <br />
                    Settings
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      SetProjectAsEntityStep8Modal(true);
                      SetProjectAsEntityStep3Modal(false);
                    }}
                    class="Icon FolderPermissioId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityConfirmation"
                    data-dismiss="modal"
                  >
                    <img src={require("../../assets/images/step_7.png")} />
                  </span>
                  <span class="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div class="FolderForm">
              <form>
                <div class="FolderFormCreateArea">
                  <div class="FieldsMandatory">
                    <h6>
                      Fields with <span class="required">*</span> are mandatory
                    </h6>
                  </div>
                  <div class="">
                    <h6>
                      Project Type<span class="required">*</span>
                    </h6>
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label class="Radio">
                          {" "}
                          Residential
                          <input
                            type="radio"
                            name="Cuisine"
                            checked={
                              projectType == "ResidentialJob" ? true : false
                            }
                            onChange={() => handleProjectType("ResidentialJob")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-group col-md-4">
                        <label class="Radio">
                          {" "}
                          Multi Family
                          <input
                            type="radio"
                            name="Cuisine"
                            checked={
                              projectType == "MultiFamilyJob" ? true : false
                            }
                            onChange={() => handleProjectType("MultiFamilyJob")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    {errorMsg.projectType && !projectType && (
                      <p style={{ color: "red" }}>{errorMsg.projectType}</p>
                    )}
                  </div>
                  <div class="">
                    <h6>
                      Creation Type<span class="required">*</span>
                    </h6>
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label class="Radio">
                          Brand New
                          <input
                            type="radio"
                            name="Cuisine2"
                            value="text"
                            onChange={() => handleCreationType("Brand New")}
                            checked={creationType == "Brand New" ? true : false}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>

                      <div class="form-group col-md-4">
                        <label class="Radio">
                          From Proforma
                          <input
                            type="radio"
                            name="Cuisine2"
                            value="file"
                            onChange={() => handleCreationType("From Proforma")}
                            checked={
                              creationType == "From Proforma" ? true : false
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-group col-md-4">
                        <label class="Radio">
                          From Old Project
                          <input
                            type="radio"
                            name="Cuisine2"
                            value="OldProject"
                            onChange={() =>
                              handleCreationType("From Old Project")
                            }
                            checked={
                              creationType == "From Old Project" ? true : false
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    {errorMsg.creationType && !creationType && (
                      <p style={{ color: "red" }}>{errorMsg.creationType}</p>
                    )}
                  </div>

                  {creationType == "From Proforma" && (
                    <div class="file RadioText" style={{ display: "block" }}>
                      <div class="row">
                        {/* <div class="col-md-6">
                        <div class="SubdivisionsArea">
                          <h6>Subdivisions</h6>
                          <div class="SubdivisionsBox">
                            <div class="form-group">
                              <h6>Subdivision</h6>
                              <input type="text" class="form-control" value={subdivisions}  onChange={(e)=> UpdateState({...iState, subdivisions:[e.target.value]})} />
                            </div>
                            <div class="form-group">
                              <h6>Action</h6>
                              <div class="Action">
                                <a href="javascript:void(0);"><img src="images/Action-1.png" /></a>
                                <a onClick={()=> UpdateState({...iState, subdivisions:[]})}><img src="images/dropdown-delete.png" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                        <div class="form-group col-md-6">
                          <h6>
                            From Proforma <span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox">
                            <select>
                              <option selected="">Select Proforma</option>
                              {checkArray(projectProformaList?.data) &&
                                projectProformaList?.data?.map((ele, i) => {
                                  return (
                                    <>
                                      <option value={ele?.id}>
                                        {ele?.name}
                                      </option>
                                    </>
                                  );
                                })}{" "}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {creationType == "From Old Project" && (
                    <div class="OldProject RadioText">
                      <div class="row">
                        <div class="col-md-6">
                          {/* <div class="SubdivisionsArea">
                          <h6>Subdivisions</h6>
                          <div class="SubdivisionsBox">
                            <div class="form-group">
                              <h6>Subdivision</h6>
                              <input type="text" class="form-control" />
                            </div>
                            <div class="form-group">
                              <h6>Action</h6>
                              <div class="Action">
                                <a href="javascript:void(0);"><img src="images/Action-1.png" /></a>
                                <a href="javascript:void(0);"><img src="images/dropdown-delete.png" /></a>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        </div>
                        <div class="form-group col-md-6">
                          <h6>
                            Old Project <span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox">
                            <select
                              value={subdivisions}
                              onChange={(e) =>
                                UpdateState({
                                  ...iState,
                                  subdivisions: [e.target.value],
                                })
                              }
                            >
                              <option selected="">Select Project</option>

                              {checkArray(projectListData) &&
                                projectListData?.map((ele, i) => {
                                  return (
                                    <option value={ele?.projectName}>
                                      {ele?.projectName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div class="col-md-5">
                    <div class="TodosButtons mt-0">
                      <a
                        style={{ color: "white", marginBottom: "10px" }}
                        onClick={() => setsubDivisonFlag(true)}
                      >
                        Create Subdividision
                      </a>
                    </div>
                  </div>

             
                    {subDivisonFlag &&

                  <div class="AuthoritySetupBox">
                    <div class="AuthoritySetupBoxMain">
                      <div class="AuthoritySetupBoxLeft">
                        <h6>Subdividision</h6>
                        <div class="CommonSelectBox">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Subdivision"
                            value={subDisvisionState}
                            onChange={(e) => setsubDivisonState(e.target.value)}
                          />
                        </div>
                        {editFlag?
                        <button
                          onClick={handleUpdateSubDivison}
                          class="SmallRedBtn"
                        >
                          Update Subdividision
                        </button>:
                         <button
                          onClick={handleaddSubDivision}
                          class="SmallRedBtn"
                        >
                          Add Subdividision
                        </button>}
                      </div>
                      <div class="AuthoritySetupBoxRight">
                        <h6>Subdividision List:</h6>

                        {subdivisions?.map((item, i) => (
                          <div class="AuthorizeName">
                            <p>{item}</p>

                            <span onClick={(e) => {setEditFlag(true)
                            setsubDivisonState(item)
                              setTempIndex(i)
                            }}>
                              <img src="images/Action-1.png" />
                            </span>

                            <span onClick={(e) => handleDeleteSubDivison(e, i)}>
                              <img src="images/dropdown-delete.png" />
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>}

                  <div class="row"></div>

                  <div class="row">
                    <div class="form-group col-md-6">
                      <h6>Developer</h6>
                      <div class="CommonSelectBox">
                        <select
                          onChange={handleChange}
                          value={developer}
                          name="developer"
                        >
                          <option value={""}>Select Developer</option>
                          <option value={"Developer 1"}>Developer 1</option>
                          <option value={"Developer 2"}>Developer 2</option>
                        </select>
                      </div>
                      {errorMsg.developer && !developer && (
                        <p style={{ color: "red" }}>{errorMsg.developer}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div class="Button d-flex justify-content-between align-items-center px-5">
                  <button
                    class="Outline"
                    data-dismiss="modal"
                    onClick={() => {
                      SetProjectAsEntityStep3Modal(false);
                      SetProjectAsEntityStep2Modal(true);
                    }}
                  >
                    Back
                  </button>
                  <button
                    class="Create FolderPermissionId"
                    data-toggle="modal"
                    onClick={handleSubmit}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProjectAsEntityStep3;
