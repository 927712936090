import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  contactListAction,
  deleteContactAction,
} from "../../redux/action/profileAction";
import Loader from "../Loader";
import { removeContactAction } from "../../redux/action/vendorAction";
import { toast } from "react-toastify";
import moment from "moment";
import {
  AddDiscussionAction,
  AddReplyAction,
  discussionListAction,
} from "../../redux/action/constructionsAction";
import { sendTextAction } from "../../redux/action/adminUserAction";
import ContactDetails from "./contactDetails";

const ContactList = ({
  ContactListModal,
  setContactList,
  SetAddContact,
  SetContactDetails,
}) => {
  const dispatch = useDispatch();
  const { contactList, loader } = useSelector((state) => state.profileReducer);
  const [ContactDetailsModal, setContactDetailsModal] = useState(false);
  const [messageBody, setMessageBody] = useState("");

  const [contactDetailsData, setContactDetailsData] = useState({});

  const { discussionList } = useSelector((state) => state.constructionReducer);
  const handleContactListModalClose = () => setContactList(false);
  const handleContactListModalShow = () => {
    setContactList(true);
  };

  const showContactDetailsModal = (ele) => {
    setContactList(false);
    setContactDetailsModal(true);
    setContactDetailsData(ele);
  };

  const hideContactDetailsModal = () => {
    setContactList(true);
    setContactDetailsModal(false);
  };

  useEffect(() => {
    dispatch(contactListAction());
  }, [ContactListModal]);
  // console.log("ContactList", contactList)

  const removeContactFunc = (id) => {
    dispatch(deleteContactAction(id)).then((res) => {
      dispatch(contactListAction());
    });
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction({
          message: discussionState.message,
          helpId: contactDetailsData?.id,
          type: "Help",
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: contactDetailsData?.id,
                type: "Help",
              })
            );
            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          helpId: contactDetailsData?.id,
          type: "Help",
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: contactDetailsData?.id,
                type: "Help",
              })
            );
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddText = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        sendTextAction({
          messageBody,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const [discussionState, updateDiscussionState] = useState({
    jobId: "",
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };
  return (
    <>
      <Modal show={ContactListModal} className="ModalBox LargeModal">
        <div class="modal-content">
          <a
            onClick={handleContactListModalClose}
            class="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>{" "}
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Contacts</h4>
            </div>
            <div class="ModalMediumheding">
              <h3></h3>
            </div>
            <div class="Tableheader2 mb-1">
              <div class="VendorCoastCode">
                <h6
                  class="mb-0 CursorPointer FolderPermissionId"
                  onClick={() => {
                    SetAddContact(true);
                    setContactList(false);
                  }}
                >
                  <img src="images/add-contact.png" />
                  &nbsp;Add Contact
                </h6>
              </div>
              <div class="NewActions">
                <a href="javascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/setting.png" />
                </a>
              </div>
            </div>
            <div class="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    {/* <th>Type</th> */}
                    <th>Company Name</th>
                    <th>Role/Title</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <Loader />
                    </tr>
                  ) : (
                    contactList?.details &&
                    contactList?.details?.length > 0 &&
                    contactList?.details?.map((item) => (
                      <tr>
                        <td>
                          <div
                            class="TextRed UserNameHoverModal2 FolderPermissionId"
                            onClick={() => {
                              setContactDetailsData(item);
                              setContactDetailsModal(true);
                            }}
                          >
                            {item?.name}
                          </div>
                        </td>
                        {/* <td>Admin</td> */}
                        <td>{item?.company}</td>
                        <td>{item?.role}</td>
                        <td>{item?.email}</td>
                        <td>{item?.phoneNumber}</td>
                        <td>
                          <div class="DetailsDots">
                            <div class="TableThreeDots">
                              <ul class="">
                                <li class="dropdown">
                                  <a
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol class="dropdown-menu dropdown-menuwidth">
                                    <li>
                                      <a
                                        onClick={() =>
                                          removeContactFunc(item?.id)
                                        }
                                        href="javascript:void(0);"
                                      >
                                        <img src="images/dropdown-delete.png" />
                                        Remove Contact
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div class="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> 01{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
            <div class="Button text-center">
              <button class="Outline">Close</button>
            </div>
          </div>
        </div>
      </Modal>

      {/* 
    <Modal show={ContactDetailsModal} className="ModalBox SmallModal">
      <div class="modal-content">
      <a
          onClick={hideContactDetailsModal}
          class="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>
            <div class="FormArea">
              <div class="ContactTopBox">
                <div class="SalesPersonarea">
                  <span>
                    Md
                  </span>
                  <h6>{contactDetailsData?.name}</h6>
                </div>
              </div>

              <div class="FolderForm">
                <form>
                  <div class="SendEmailText">
                    <a href="mailto: dkmehta@gmail.com"><img src="images/send-email.png" />Send E-mail</a>
                    <p class="SendText SendText1">
                      <img src="images/send-text.png" />Send Text
                    </p>
                    <div class="SendTextSearch SendTextSearch1">
                      <input type="text" onChange={(e)=>setMessageBody(e.target.value)} /><button onClick={handleAddText}>Apply</button>

                    </div>
                  </div>
                  <div class="MolalContactForm mb-4">
                    <h6>Contact</h6>
                    <p><img src="images/send-email.png" />{contactDetailsData?.email}</p>
                    <p class="mb-4"><img src="images/send-text.png" />{contactDetailsData?.phoneNumber}</p>
 
                  </div>
        
                  <div class="DiscussionArea">
            <div class="DiscussionTitle">
              <h4>Discussion</h4>
              <div class="DiscussionChat">
                <img
                  src="images/down.png"
                  class="DiscussionIcon"
                />
                <a href="#">
                  <img src="images/pdf2.png" />
                </a>
              </div>
            </div>
            <div class="DiscussionHideArea" style={{ display: "block" }}>
              <div class="DiscussionBox">
                <span>FM</span>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    value={discussionState?.message}
                    onChange={(e) =>
                      updateDiscussionState({
                        ...discussionState,
                        message: e.target.value,
                      })
                    }
                    placeholder="Add a Comment ....."
                  />
                  {discussionState.discussionError &&
                    !discussionState.message && (
                      <p style={{ color: "red" }}>
                        {discussionState.discussionError}
                      </p>
                    )}

                  <div class="DiscussionOverlay">
                    <a
                      onClick={() =>
                        updateDiscussionState({
                          ...discussionState,
                          message: "",
                        })
                      }
                      class="CancelChat"
                    >
                      <img src="images/plus-cancel1.png" />
                    </a>
                    <a onClick={handleAddDiscussion} class="SendChat">
                      <img
                        src="images/forwardbutton.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
              {discussionList?.map((item, i) => (
                <>
                  <div class="DiscussionBox">
                    <span>CO</span>
                    <div class="DiscussionRight">
                      <h6>{item?.message}</h6>
                      <small>{moment(item.updatedAt).format("lll")}</small>
                    </div>
                  </div>
                  <div class="ReplyArea">
                    <a
                      onClick={(e) => {
                        updateReplyField({ flag: true, index: i });
                        updateReplyState({ ...replyState, message: "" });
                      }}
                    >
                      Reply
                    </a>
                    {item?.ReplyDiscussions?.map((ele) => (
                      <div class="DiscussionBox">
                        <span>RE</span>
                        <div class="DiscussionRight">
                          <h6>{ele?.message}</h6>
                          <small>{moment(ele.updatedAt).format("lll")}</small>
                        </div>
                      </div>
                    ))}

                    {replyField.index == i && (
                      <div class="DiscussionBox w-100">
                        <span>FM{item?.id}</span>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            value={replyState?.message}
                            onChange={(e) =>
                              updateReplyState({
                                ...replyState,
                                message: e.target.value,
                                messageId: item?.id,
                                jobId: item?.jobId,
                              })
                            }
                            placeholder="Add reply ....."
                          />
                          {discussionState.discussionError &&
                            !discussionState.message && (
                              <p style={{ color: "red" }}>
                                {discussionState.discussionError}
                              </p>
                            )}

                          <div class="DiscussionOverlay">
                            <a
                              onClick={() =>
                                updateReplyState({ ...replyState, message: "" })
                              }
                              class="CancelChat"
                            >
                              <img
                                src="images/plus-cancel1.png"
                              />
                            </a>
                            <a onClick={handleAddReply} class="SendChat">
                              <img
                                src="images/forwardbutton.png"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
                </form>
              </div>
            </div>
          </div>
    </Modal> */}

      <ContactDetails
        ContactDetailsModal={ContactDetailsModal}
        setContactDetailsModal={setContactDetailsModal}
        contactDetailsData={contactDetailsData}
      />
    </>
  );
};

export default ContactList;
