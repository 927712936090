import { join } from "lodash";
import React from "react";
import Modal from "react-bootstrap/Modal";

const NewProjectStep4 = ({
  iState,
  UpdateState,
  setShow,
  NewProjectStep4Modal,
  SetNewProjectStep3Modal,
  SetNewProjectStep1Modal,
  SetNewProjectStep2Modal,
  SetNewProjectStep4Modal,
  SetNewProjectStep5Modal,
  SetNewProjectStep6Modal,
}) => {
  const handleNewProjectStep4ModalClose = () => {SetNewProjectStep4Modal(false)
    setShow(true)

  };
  const handleNewProjectStep4ModalShow = () => {
    SetNewProjectStep4Modal(true);
  };


const {jobPattern ,useCompanyPattern,errorMsg} = iState

const handleValidation = () => {
  let formIsValid = true;
  let errorMsg = {};

  if (!jobPattern) {
    formIsValid = false;
    errorMsg.jobPattern = "* Please enter job number.";
  }

  UpdateState({ ...iState, errorMsg: errorMsg });

  return formIsValid;
};


const handleChange = (e) => {
  const { name, value } = e.target;

  UpdateState({
    ...iState,
    [name]: value,
  });
};


const handleSubmit = (e) => {
  e.preventDefault();

  let formIsValid = handleValidation();

  if (formIsValid) {
    SetNewProjectStep4Modal(false);
    SetNewProjectStep5Modal(true);
  }
};


const handleCheck =(e)=>{

  UpdateState({...iState, useCompanyPattern:e.target.checked})
 
   }


   const handleKeySelection=(e,value)=>{
    e.preventDefault()
    if(!jobPattern){

    UpdateState({...iState, jobPattern:value})
    }
    else{

      let prev = jobPattern + `-${value}`
      UpdateState({...iState, jobPattern:prev})


    }





   }


  return (
    <Modal show={NewProjectStep4Modal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          class="CloseModal"
          onClick={handleNewProjectStep4ModalClose}
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Create New Project</h4>
          </div>
          <div class="StepBox">
            <ul>
                <li class="active">
                    <span class="Icon">
                        <img src={require("../../assets/images/step_1.png")} onClick={()=> {SetNewProjectStep1Modal(true) 
                      SetNewProjectStep4Modal(false)}} />
                    </span>
                    <span class="Text">Details</span>
                </li>
                <li class="active" onClick={()=> {SetNewProjectStep2Modal(true)
                   SetNewProjectStep4Modal(false)}}>
                    <span class="Icon FolderPermissionId " >
                        <img src={require("../../assets/images/step_2.png")}/>
                    </span>
                    <span class="Text">Address</span>
                </li>
                <li class="active">
                  <span class="Icon FolderPermissionId " data-toggle="modal" onClick={()=> {SetNewProjectStep3Modal(true)
                    SetNewProjectStep4Modal(false)}} >
                      <img src={require("../../assets/images/step_8.png")}/>
                  </span>
                  <span class="Text">Type&amp; <br /> Accounting </span>
              </li>
                <li className="active">  
                    <span class="Icon FolderPermissionId " data-toggle="modal" >
                        <img src={require("../../assets/images/step_5.png")} />
                    </span>
                    <span class="Text">Job <br /> Numbering</span>
                </li>
                <li>
                    <span class="Icon FolderPermissionId " data-toggle="modal" onClick={()=>{ SetNewProjectStep5Modal(true)
                    SetNewProjectStep4Modal(false)}}>
                        <img src={require("../../assets/images/step_6.png")} />
                    </span>
                    <span class="Text">Cost <br /> Profiles</span>
                </li>
                <li>
                    <span class="Icon FolderPermissionId " data-toggle="modal" onClick={()=> {SetNewProjectStep6Modal(true)
                    SetNewProjectStep4Modal(false)}}>
                        <img src={require("../../assets/images/step_7.png")} />
                    </span>
                    <span class="Text">Confirmation</span>
                </li> 
            </ul>
          </div>
          <div class="FolderForm">
                
                  <form>
                    <div class="FolderFormCreateArea">
                      <div class="FieldsMandatory"><h6>Fields with <span class="required">*</span> are mandatory</h6></div>
                     <div class="row">
                         
                      
                      <div class="col-12">
                        <div class="form-group">
                          <h6>Job Pattern Definition</h6>
                          <label class="switch"> Use Company's Pattern
                            <input onChange={handleCheck} checked={useCompanyPattern} type="checkbox" />
                            <span class="slider"></span> 
                        </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <h6>Set Job Naming Format<span class="required">*</span></h6>
                          <input type="text" class="form-control" value={jobPattern} onChange={handleChange} name="jobPattern" placeholder="[UNUM]-[SD3]-[CID]-[JN]" />
                          {errorMsg.jobPattern && !jobPattern && (
                        <p style={{ color: "red" }}>{errorMsg.jobPattern}</p>
                      )}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <h6>Job Naming Format</h6>
                          <input type="text" class="form-control InputCyanBg" placeholder="MAH-2B-377" />
                        </div>
                      </div>
                    </div>
                    <div class="RestrictionArea">
                      <h6>Restrictions</h6>
                      <ul>
                        <li>-Max length for generated job number is 40 characters.</li>
                        <li>-[CID] and [JN] must both occur at least once.</li>
                        <li>-Only alpha numeric characters and single dashes are allowed.</li>
                        <li>-It cannot end/start in a dash.</li>
                      </ul>
                    </div>
                    <div class="KeySelectionArea">
                      <h6>Key Selection</h6>
                      <div class="KeySelectionBox">
                        <aside>
                          <p><button onClick={(e)=> handleKeySelection(e,'[ACD]')} class="BlueBtn">[ACD]</button><span>Agreement or Creation Date</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[ACDM2]')} class="BlueBtn">[ACDM2]</button><span>Agreement or Creation <br />Date: Two Digit Month</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[SD3]')} class="BlueBtn">[SD3]</button><span>Three Characteres of Subdivision</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[FY2]')} class="BlueBtn">[FY2]</button><span>Two Digit Fiscal Year</span></p>
                        </aside>
                        <aside>
                          <p><button onClick={(e)=> handleKeySelection(e,'[ACDY2]')} class="BlueBtn">[ACDY2]</button><span>Agreement or Creation <br />
                            Date: Two Digit Year</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[CID]')} class="GreenBtn">[CID]</button><span>Company ID</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[SD4]')} class="BlueBtn">[SD4]</button><span>Four Characteres of 
                            Subdivision</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[FY4]')} class="BlueBtn">[FY4]</button><span>Four Digit Fiscal Year</span></p>
                        </aside>
                        <aside>
                          <p><button onClick={(e)=> handleKeySelection(e,'[ACDY4]')} class="BlueBtn">[ACDY4]</button><span>Agreement or Creation <br />
                            Date: Four Digit Year</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[JN]')} class="GreenBtn">[JN]</button><span>Job Number</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[UNUM]')} class="BlueBtn">[UNUM]</button><span>Unit Number</span></p>
                        </aside>
                      </div>
                    </div>
                  </div>  
                  <div class="Button d-flex justify-content-between align-items-center px-5">
                    <button class="Outline" onClick={()=> {
                    SetNewProjectStep4Modal(false)
                    SetNewProjectStep3Modal(true)
                  }}>Back</button>
                    <button class="Create" data-toggle="modal" onClick={handleSubmit}>Next</button>
                  </div>  
                  </form>
                
              </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewProjectStep4;
