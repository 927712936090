import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  companyDetailsAction,
  createCompanyAction,
  getEntityProjectList,
  getRegularProjectList,
} from "../../redux/action/companyAction";
import { quicksBookAction } from "../../redux/action/adminNotificationAction";
import { checkArray } from "../../utils/CheckType";
import { Modal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  getCitiesList,
  getCountriesList,
  getStateList,
} from "../../redux/action/authAction";
import moment from "moment";
import ProjectAsEntityStep1 from "../ProjectAsEntity/ProjectAsEntityStep1";
import NewProjectStep1 from "../NewProject/NewProjectStep1";
import {
  createAllTaxProfileAction,
  deleteTaxProfile,
  updateTaxProfile,
} from "../../redux/action/jobAction";
import { init } from "../../utils/uploadFile";
import { uploadAggrementFiles } from "../../redux/action/designSelectionsAction";
const inititalState = {
  name: "",
  email: "",
  primaryNumber: "",
  website: "",
  logo: "",
  motto: "",
  displayLogo: false,
  displayMotto: false,
  displayName: false,
  streetAddressLine1: "",
  streetAddressLine2: "",
  zipCode: "",
  country: "",
  province: "",
  city: "",
  taxRegistration: "",
  jobNumberPattern: "",
  fiscalYearEnd: "",
  quickBooks: false,
  letterHeader: "",
  sage: false,
  curStep: "",
  costCodeProfileType: false,
  residentialProfile: "",
  multiFamilyProfile: "",
  landDevelopmentProfile: "",
  imagePreview: "",
  companyErrors: {},
  CreateProfileModal: false,
  activeCheck: false,
  defaultCheck: false,
  isRebate: false,
  taxRebates: [
    {
      minRange: 0,
      maxRange: 0,
      appliedAss: "",
      amount: 0,
      applicableRow: "",   
    },
  ],
  taxRows: [
    {
      taxName: "",
      taxPercent: 0,
    },
  ],
  taxProfile: [],
};
const AdminCompanyDetails = ({ adminCompanyDetailsStatus }) => {
  const [phonecode, setPhoneCode] = useState("");
  const { companyDetailData } = useSelector((state) => state.companyReducer);
  const [iState, updateState] = useState(_.cloneDeep(inititalState));

  const {
    name,
    email,
    primaryNumber,
    website,
    logo,
    motto,
    displayLogo,
    displayMotto,
    displayName,
    streetAddressLine1,
    streetAddressLine2,
    zipCode,
    country,
    province,
    city,
    taxRegistration,
    jobNumberPattern,
    fiscalYearEnd,
    quickBooks,
    letterHeader,
    curStep,
    costCodeProfileType,
    residentialProfile,
    multiFamilyProfile,
    landDevelopmentProfile,
    companyErrors,
    imagePreview,
    sage,
    CreateProfileModal,
    activeCheck,
    defaultCheck,
    isRebate,
    taxRebates,
    taxRows,
    taxProfile,
  } = iState;
  const { countryData, cityData, stateData } = useSelector(
    (state) => state.authReducer
  );

  const { regularProjectList, regularEntityList } = useSelector(
    (state) => state.companyReducer
  );


  useEffect(() => {
    updateState((prev) => ({
      ...prev,
      name: companyDetailData?.name,
      email: companyDetailData?.email,
      primaryNumber: companyDetailData?.primaryNumber,
      website: companyDetailData?.website,
      displayLogo: companyDetailData?.displayLogo,
      displayMotto: companyDetailData?.displayMotto,
      displayName: companyDetailData?.displayName,
      streetAddressLine1: companyDetailData?.streetAddressLine1,
      motto: companyDetailData?.motto,
      streetAddressLine2: companyDetailData?.streetAddressLine2,
      city: companyDetailData?.city,
      zipCode: companyDetailData?.zip,
      // country: companyDetailData?.country,
      // province: companyDetailData?.province,
      logo: companyDetailData?.logo,
      taxRegistration: companyDetailData?.taxRegistration,
      jobNumberPattern: companyDetailData?.jobNumberPattern,
      fiscalYearEnd: companyDetailData?.fiscalYearEnd,
      quickBooks: companyDetailData?.quickBooks,
      letterHeader: companyDetailData?.letterHeader,
      curStep,
      costCodeProfileType:
        companyDetailData?.costCodeProfileType === null
          ? false
          : companyDetailData?.costCodeProfileType,
      residentialProfile: companyDetailData?.residentialProfile,
      multiFamilyProfile: companyDetailData?.multiFamilyProfile,
      landDevelopmentProfile: companyDetailData?.landDevelopmentProfile,
      sage: companyDetailData?.sage,
      taxProfile: companyDetailData?.TaxProfiles,
    }));
  }, [companyDetailData]);

  console.log("TaxProfiles", taxProfile);
  // useEffect(() => {
  //   if (countryData?.countries) {
  //     const selectedCountry = countryData.countries.find((x) => x.name == country);
  //     if (selectedCountry?.isoCode) {
  //       dispatch(getStateList(selectedCountry.isoCode));
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (countryData?.countries && stateData?.states) {
  //     const selectedCountryIsoCode = countryData.countries.find((x) => x.name === country)?.isoCode;
  //     const selectedStateIsoCode = stateData.states.find((x) => x.name === province)?.isoCode;

  //     if (selectedCountryIsoCode && selectedStateIsoCode) {
  //       dispatch(getCitiesList(selectedCountryIsoCode, selectedStateIsoCode));
  //     }
  //   }
  // }, [province]);

  const [editGeneralInfo, setEditGeneralInfo] = useState(false);
  const [quickbook, setQuickBook] = useState("");

  const dispatch = useDispatch();

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  useEffect(() => {
    if (adminCompanyDetailsStatus) {
      dispatch(quicksBookAction()).then((response) => {
        setQuickBook(response);
      });
      dispatch(getCountriesList());
    }
  }, [adminCompanyDetailsStatus]);

  useEffect(() => {
    dispatch(getRegularProjectList());
    dispatch(getEntityProjectList());
  }, []);

  const handleUpload = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        updateState({
          ...iState,
          logo: e.target.files[0],
          imagePreview: URL.createObjectURL(file),
        });
      }
    }
  };

  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateState({ ...iState, [name]: result });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      const selectedCountry = countryData?.countries?.find(
        (x) => x.name == value
      );
      if (selectedCountry?.isoCode) {
        dispatch(getStateList(selectedCountry.isoCode));
      }
      updateState({ ...iState, [name]: value });
    } else {
      updateState({ ...iState, [name]: value });
    }
  };

  const addCompanyFunc = (e) => {
    e.preventDefault();
    dispatch(
      createCompanyAction(iBuildLocalData?.user?.CompanyId, {
        costCodeProfileType: "System Default Cost Codes",
        name,
        email,
        primaryNumber,
        website,
        logo,
        motto,
        displayLogo,
        displayMotto,
        displayName,
        streetAddressLine1,
        streetAddressLine2,
        zipCode,
        country,
        province,
        city,
        taxRegistration,
        jobNumberPattern,
        fiscalYearEnd,
        quickBooks,
        sage,
        letterHeader,
        curStep: 6,
        residentialProfile,
        multiFamilyProfile,
        landDevelopmentProfile,
        phonecode,
      })
    ).then((res) => {
      if (res) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleKeySelection = (e, value) => {
    e.preventDefault();
    if (!jobNumberPattern) {
      updateState({ ...iState, jobNumberPattern: value });
    } else {
      let prev = jobNumberPattern + `-${value}`;
      updateState({ ...iState, jobNumberPattern: prev });
    }
  };

  const editorsRef = useRef(null);
  const [HeaderPreviewModal, setHeaderPreviewModal] = useState(false);
  const handlHeaderPreviewModalShow = () => {
    setHeaderPreviewModal(true);
  };
  const handlHeaderPreviewModalClose = () => {
    setHeaderPreviewModal(false);
  };

  const handleEditorChange = (newContent) => {
    updateState({...iState,letterHeader: newContent});
  };

  console.log("letterHeader",letterHeader);

  const [file, setFile] = useState(null); 
  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  };

  const handleUploadFiles = async () => {
    if (!file) {
      toast.error("No file selected", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const formData = new FormData();
    formData.append("files", file); 

    try {
      const uploadResponse = await dispatch(uploadAggrementFiles(formData))
      const imgTag = `<img src="${uploadResponse?.data?.data}" height=10% width=20% alt="Signature Image" />`;
updateState({...iState,letterHeader:imgTag})
await dispatch(createCompanyAction(iBuildLocalData?.user?.CompanyId, {letterHeader:imgTag}))
await dispatch(companyDetailsAction(iBuildLocalData?.user?.CompanyId))
      toast.success("File uploaded successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  const [NewProjectStep1Modal, SetNewProjectStep1Modal] = useState(false);

  const [ProjectAsEntityStep1Modal, SetProjectAsEntityStep1Modal] =
    useState(false);

  const [show, setShow] = useState(false);

  const handleCraeteProfileModalShow = () => {
    updateState({ ...iState, 
      CreateProfileModal: true,
      activeCheck: false,
      defaultCheck: false,
      isRebate: false,
      taxRebates: [
        {
          minRange: 0,
          maxRange: 0,
          appliedAss: "",
          amount: 0,
          applicableRow: "",
          
        },
      ],
      taxRows: [
        {
          taxName: "",
          taxPercent: 0,
        },
      ],
      country:"",
    });
  };
  const handleCraeteProfileModalClose = () => {
    updateState({ ...iState, CreateProfileModal: false });
  };

  const handleTaxInput = (e, i) => {
    const { name, value } = e.target;
    let user = [...taxRows];
    user[i] = { ...user[i], [name]: value };
    updateState({ ...iState, taxRows: user });
  };

  const handleRebelChange = (e, i) => {
    const { name, value } = e.target;
    let user = [...taxRebates];
    user[i] = { ...user[i], [name]: value };
    updateState({ ...iState, taxRebates: user });
  };
  const handleTaxRemove = (id) => {
    let user = [...taxRows];
    user.splice(id, 1);
    updateState({
      ...iState,
      taxRows: user,
    });
  };
  const handleRebelRemove = (id) => {
    let user = [...taxRebates];
    user.splice(id, 1);
    updateState({
      ...iState,
      taxRebates: user,
    });
  };
  const handleTaxCheck = (e) => {
    const { name, checked } = e.target;
    updateState({
      ...iState,
      [name]: checked,
      taxRebates: !checked? [
          {
            minRange: 0,
            maxRange: 0,
            appliedAss: "",
            amount: 0,
            applicableRow: "",
            
          },
        ]:taxRebates,
        
    });
  };
  
  //=================ADD NEW LINE=================
  const Addmore = (type) => {
    if (type === "taxRow") {
      updateState({
        ...iState,
        taxRows: Array.isArray(iState.taxRows)
          ? [...iState.taxRows, { taxName: "", taxPercent: 0 }]
          : [{ taxName: "", taxPercent: 0 }],
      });
    } else if (type === "taxRebel") {
      updateState({
        ...iState,
        taxRebates: Array.isArray(iState.taxRebates)
          ? [
              ...iState.taxRebates,
              {
                minRange: 0,
                maxRange: 0,
                appliedAss: "",
                amount: 0,
              },
            ]
          : [
              {
                minRange: 0,
                maxRange: 0,
                appliedAss: "",
                amount: 0,
              },
            ],
      });
    }
  };

  const handleCreateTaxProfile = () => {
    const datta = {
      activeCheck,
      defaultCheck,
      isRebate,
      taxRebates:isRebate?taxRebates:[],
      taxRows,
      CompanyId: iBuildLocalData?.user?.CompanyId,
      country,
      province,
    };
    dispatch(createAllTaxProfileAction(datta)).then((res) => {
      if (res?.message == "Success") {
        toast.success("Tax Create Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        dispatch(companyDetailsAction(iBuildLocalData?.user?.CompanyId)).then(
          (resp) => {
            if (resp) {
              updateState((prevState) => ({
                ...prevState,
                CreateProfileModal: false,
                activeCheck: false,
                defaultCheck: false,
                isRebate: false,
                country:"",
                province:"",
              }));
            }
          }
        );
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState((prevState) => ({
          ...prevState,
          CreateProfileModal: true,
        }));
      }
    });
  };

  const handleTaxRowsChange = (e, i, index) => {
    const { name, value } = e.target;
    const newList = [...taxProfile];
    newList[i].taxRows[index] = {
      ...newList[i].taxRows[index],
      [name]: value,
    };

    updateState((prevState) => ({
      ...prevState,
      taxProfile: newList,
    }));
  };

  const handleTaxDebatesChange = (e, i, index) => {
    const { name, value } = e.target;
    const newList = [...taxProfile];
    newList[i].taxRebates[index] = {
      ...newList[i].taxRebates[index],
      [name]: value,
    };

    updateState((prevState) => ({
      ...prevState,
      taxProfile: newList,
    }));
  };
  const handleDeleteTaxProfile = (i) => {
    dispatch(deleteTaxProfile(i)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        dispatch(companyDetailsAction(iBuildLocalData?.user?.CompanyId));
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };



  const handleCountryStateChange = (e, i) => {
    const { name, value } = e.target;
    let user = [...taxProfile];
    user[i] = { ...user[i], [name]: value };
    updateState({ ...iState, taxProfile: user });
  };

  const handleUpdateTaxProfile = (data) => {
    const datta = {
      activeCheck:data.activeCheck,
      defaultCheck:data.defaultCheck,
      isRebate:data.isRebate,
      taxRebates:data?.isRebate?data.taxRebates?.map((item)=>({
        minRange:item.minRange,
      maxRange:item.maxRange,
      appliedAss:item.appliedAss,
      amount:item.amount,
      applicableRow:item.applicableRow,
      })):[],
      taxRows:data?.taxRows?.map((item)=>({
        taxName:item.taxName,
      taxPercent:item.taxPercent,
      })),
      CompanyId: data?.CompanyId,
      country:data.country,
      province:data.province,
    };
    dispatch(updateTaxProfile(data?.id,datta)).then((res) => {
      if (res.status ===200) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        dispatch(companyDetailsAction(iBuildLocalData?.user?.CompanyId)).then(
          (resp) => {
            if (resp) {
              updateState((prevState) => ({
                ...prevState,
              
                country:"",
                province:"",
              }));
            }
          }
        );
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };


  const [HeaderPreviewsModal, setHeaderPreviewsModal] = useState(false);
  const handlHeaderPreviewsModalShow = () => {
    setHeaderPreviewsModal(true);
  };
  const handlHeaderPreviewsModalClose = () => {
    setHeaderPreviewsModal(false);
  };

  const createAboutHeader = () => {
    return { __html: letterHeader };
  };
 
   

    const handleTaxUpdate=()=>{
       dispatch(createCompanyAction(iBuildLocalData?.user?.CompanyId, {taxRegistration})).then((res)=>{
        if(res.details){
           dispatch(companyDetailsAction(iBuildLocalData?.user?.CompanyId))
          toast.success("File uploaded successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else{
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
       })
    }
 
  
  return (
    <>
      <div class="tab-pane fade" id="CompanyDetails">
        <div class="ResidentialJobArea ViewConfigArea">
          <div class="ModalAccordian">
            <div id="accordion">
              <div class="card">
                <div class="card-header">
                  <h4 data-toggle="collapse" href="#GeneralInformationTabs">
                    <span>
                      <img src="images/generation_information.png" />
                    </span>{" "}
                    General Information
                  </h4>
                  <a
                    onClick={() =>
                      setEditGeneralInfo(editGeneralInfo ? false : true)
                    }
                    class="Edit EditViewConfigBodyBtn"
                  >
                    <img src="images/Action-1.png" />
                  </a>
                </div>
                <div
                  id="GeneralInformationTabs"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div
                      class="ViewConfigBodyMain"
                      style={{ display: editGeneralInfo ? "none" : "block" }}
                    >
                      <div class="ViewConfigBody">
                        <aside>
                          <div class="form-group">
                            <h6>Name</h6>
                            <p>{companyDetailData?.name}</p>
                          </div>
                          <div class="form-group">
                            <h6>Email</h6>
                            <p>{companyDetailData?.email}</p>
                          </div>
                          <div class="form-group">
                            <h6>Primary Phone Number</h6>
                            <p>{companyDetailData?.primaryNumber}</p>
                          </div>
                          <div class="form-group">
                            <h6>Website</h6>
                            <p>{companyDetailData?.website}</p>
                          </div>
                          <div class="form-group">
                            <h6>Login URL Shorthand</h6>
                            <p>{companyDetailData?.shorthand}</p>
                          </div>
                        </aside>
                        <aside>
                          <div class="form-group">
                            <h6>Logo</h6>
                            <span class="FmBuilderLogo">
                              <img
                                src={
                                  companyDetailData?.logo
                                    ? companyDetailData?.logo
                                    : "images/fm_builder_logo.png"
                                }
                              />
                            </span>
                          </div>
                          <div class="form-group">
                            <h6>Motto</h6>
                            <p>{companyDetailData?.motto}</p>
                          </div>
                          <div class="form-group">
                            <h6>Letter Header</h6>
                            <button
                              class="PreviewBtn"
                              onClick={handlHeaderPreviewsModalShow}
                            >
                              Preview
                            </button>
                          </div>
                          <div class="form-group">
                            <h6>Display on Login Page</h6>
                            {companyDetailData?.displayName && (
                              <p class="mb-2">Name</p>
                            )}
                            {companyDetailData?.displayLogo && (
                              <p class="mb-2">Logo</p>
                            )}
                            {companyDetailData?.displayMotto && (
                              <p class="mb-2">Motto</p>
                            )}
                          </div>
                        </aside>
                      </div>
                    </div>
                    <div
                      class="ViewConfigBodyHideMain"
                      style={{ display: editGeneralInfo ? "block" : "none" }}
                    >
                      <div class="ViewConfigBodyHide">
                        <aside>
                          <div class="form-group">
                            <h6>Name</h6>
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              value={name}
                              onChange={handleInput}
                              placeholder="Company 1"
                            />
                          </div>
                          <div class="form-group">
                            <h6>Email</h6>
                            <input
                              type="text"
                              class="form-control"
                              name="email"
                              onChange={handleInput}
                              value={email}
                              placeholder="contact@company1.com"
                            />
                          </div>
                          <div class="form-group">
                            <h6>Primary Phone Number</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Insert Company's primary phone number"
                              name="primaryNumber"
                              value={primaryNumber}
                              onChange={handleInputNumber}
                            />
                          </div>
                          <div class="form-group">
                            <h6>Website</h6>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="www.company1.com"
                              name="website"
                              defaultValue={website}
                              onChange={handleInput}
                            />
                          </div>
                          <div class="form-group">
                            <h6>Login URL Shorthand</h6>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="company1"
                            />
                          </div>
                        </aside>
                        <aside>
                          <div class="form-group">
                            <h6>Logo</h6>
                            <span class="FmBuilderLogo">
                              {logo ? (
                                <>
                                  <img
                                    src={imagePreview ? imagePreview : logo}
                                  />
                                  <input
                                    type="file"
                                    name="logo"
                                    onChange={handleUpload}
                                  />
                                </>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    name="logo"
                                    onChange={handleUpload}
                                  />
                                </>
                              )}
                            </span>
                            <div>
                              <button class="AlterBtn mt-3">Alter</button>
                            </div>
                          </div>
                          <div class="form-group">
                            <h6>Motto</h6>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Building futures"
                              name="motto"
                              defaultValue={motto}
                              onChange={handleInput}
                            />
                          </div>
                          <div class="form-group">
                            <h6>Letter Header</h6>
                            <button
                              class="PreviewBtn mr-2"
                              onClick={handlHeaderPreviewsModalShow}
                            >
                              Preview
                            </button>
                            <button
                              class="AlterBtn ml-2"
                              onClick={handlHeaderPreviewModalShow}
                            >
                              Alter
                            </button>
                          </div>
                          <div class="DisplayLoginOnPage">
                            <div class="form-group">
                              <h6>Display on Login Page</h6>
                              <div class="mb-2">
                                <label class="CheckBox">
                                  {" "}
                                  Name
                                  <input type="checkbox" />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                              <div class="mb-2">
                                <label class="CheckBox">
                                  {" "}
                                  Logo
                                  <input type="checkbox" />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                              <div>
                                <label class="CheckBox">
                                  {" "}
                                  Motto
                                  <input type="checkbox" />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                      <div class="Button d-flex justify-content-center">
                        <button
                          onClick={() => setEditGeneralInfo(false)}
                          class="OutlineBlack mr-5 SaveViewConfigBodyBtn"
                        >
                          Cancel
                        </button>
                        <button
                          class="Respond ml-5 SaveViewConfigBodyBtn"
                          onClick={addCompanyFunc}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header">
                  <h4 data-toggle="collapse" href="#BusinessAddressTabs">
                    <span>
                      <img src="images/business_address.png" />
                    </span>{" "}
                    Business Address
                  </h4>
                  <a
                    class="Edit EditViewConfigBodyBtn"
                    href="javascript:void(0);"
                  >
                    <img src="images/business_address.png" />
                  </a>
                </div>
                <div
                  id="BusinessAddressTabs"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div
                      class="ViewConfigBodyMain"
                      style={{ display: editGeneralInfo ? "none" : "block" }}
                    >
                      <div class="ViewConfigBody">
                        <aside>
                          <div class="form-group">
                            <h6>Street Address</h6>
                            <p>{companyDetailData?.streetAddressLine1}</p>
                          </div>
                          <div class="form-group">
                            <h6>Street Address Line Two</h6>
                            <p>{companyDetailData?.streetAddressLine2}</p>
                          </div>
                          <div class="form-group">
                            <h6>City</h6>
                            <p>{companyDetailData?.province}</p>
                          </div>
                        </aside>
                        <aside>
                          <div class="form-group">
                            <h6>Zip/ Postal Code</h6>
                            <p>{companyDetailData?.zipCode}</p>
                          </div>
                          <div class="form-group">
                            <h6>Country</h6>
                            <p>{companyDetailData?.country}</p>
                          </div>
                          <div class="form-group">
                            <h6>State/Province</h6>
                            <p>{companyDetailData?.province}</p>
                          </div>
                        </aside>
                      </div>
                    </div>

                    <div
                      class="ViewConfigBodyHideMain"
                      style={{ display: editGeneralInfo ? "block" : "none" }}
                    >
                      <div class="ViewConfigBodyHide">
                        <aside>
                          <div class="form-group">
                            <h6>Street Address</h6>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Street Address"
                              name="streetAddressLine1"
                              value={streetAddressLine1}
                              onChange={handleInput}
                            />
                          </div>
                          <div class="form-group">
                            <h6>Street Address Line Two</h6>
                            <input
                              type="text"
                              class="form-control"
                              name="streetAddressLine2"
                              value={streetAddressLine2}
                              onChange={handleInput}
                              placeholder="Insert full Address"
                            />
                          </div>
                          <div class="form-group">
                            <h6>City</h6>
                            <input
                              type="text"
                              name="zipCode"
                              value={zipCode}
                              onChange={handleInput}
                              class="form-control"
                              placeholder="Calgary"
                            />
                          </div>
                        </aside>
                        <aside>
                          <div class="form-group">
                            <h6>Zip/ Postal Code</h6>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="T3A T3A"
                            />
                          </div>
                          <div class="form-group">
                        <h6>
                          Country<span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox w-100">
                          <select
                            name="country"
                            value={country}
                            onChange={handleInput}
                          >
                            <option value="">Select Country</option>
                            {countryData?.countries?.length > 0 &&
                              countryData?.countries?.map((item, i) => {
                                return (
                                  <option value={item.name}>{item.name}</option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div class="form-group">
                        <h6>
                          Province/State<span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox w-100">
                          <select
                            name="province"
                            value={province}
                            onChange={handleInput}
                          >
                            <option value="">Select Province/State</option>
                            {stateData?.states?.length > 0 ? (
                              stateData?.states?.map((item, i) => {
                                return (
                                  <option value={item.name}>{item.name}</option>
                                );
                              })
                            ) : (
                              <option disabled>
                                No Province/State Available
                              </option>
                            )}
                          </select>
                        </div>
                      </div>
                        </aside>
                      </div>
                      <div class="Button d-flex justify-content-center">
                        <button
                          onClick={() => setEditGeneralInfo(false)}
                          class="OutlineBlack mr-5 SaveViewConfigBodyBtn"
                        >
                          Cancel
                        </button>
                        <button
                          class="Respond ml-5 SaveViewConfigBodyBtn"
                          onClick={addCompanyFunc}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header">
                  <h4 data-toggle="collapse" href="#AccountingTabs">
                    <span>
                      <img src="images/accounting.png" />
                    </span>{" "}
                    Accounting
                  </h4>
                  <a class="Edit EditViewConfigBodyBtn">
                    <img src="images/tax.png" />
                  </a>
                </div>
                <div
                  id="AccountingTabs"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div
                      class="ViewConfigBodyMain"
                      style={{ display: editGeneralInfo ? "none" : "block" }}
                    >
                      <div class="ViewConfigBody">
                        <aside style={{ width: "100%" }}>
                          <div class="AccounitingCollapseArea AccounitingCollapseArea2">
                            <h6 class="mb-3">
                              Recommended Accounting Configuration Procedures
                              Before Integration
                            </h6>
                            <p>
                              - You must first purchase the{" "}
                              <span>QuickBooks Online Plus</span> version as the{" "}
                              <span>Plus</span> version allows class usage
                            </p>
                            <p>
                              - Second, ensure the{" "}
                              <span>Chart of Accounts (COA)</span> has an{" "}
                              <span>Accounts Payables (A/P)</span> account
                              already set-up, if not, set one up.
                            </p>
                            <p>
                              - In your QuickBooks Online Plus version you must
                              first Turn-On the <span>Purchase Order</span>{" "}
                              Option, the <span>Enable account numbers</span>
                              option, then the the <span>Class</span> Option as
                              follows:
                            </p>
                            <ul>
                              <li>
                                --Click on the{" "}
                                <span>
                                  Gear Icon in the Upper Right-Hand Corner of on
                                  your Home Dashboard screen.
                                </span>
                              </li>
                              <li>
                                --Click on <span>Account and Settings</span>
                              </li>
                              <li>
                                --Click on the <span>Account and Settings</span>{" "}
                                option.{" "}
                              </li>

                              <li>
                                --Click on the <span>Expenses</span> option.{" "}
                              </li>
                              <li>
                                --Click on the box in front of the{" "}
                                <span>Use Purchase Orders</span> option.
                              </li>
                              <li>
                                --Click the green <span>Save</span> button.
                              </li>
                              <li>
                                --Click on the <span>Advanced</span> option.
                              </li>
                              <li>
                                --Click on the <span>Chart of Accounts</span>{" "}
                                option.
                              </li>
                              <li>
                                --Click on the box in front of{" "}
                                <span>Enable Account Numbers</span>{" "}
                              </li>
                              <li>
                                --Click the green <span>Save</span> button.
                              </li>
                              <li>
                                --Click the box in front of the{" "}
                                <span>Track Classes</span> option.
                              </li>
                              <li>
                                --Click the green <span>Save</span> button
                              </li>
                            </ul>
                            <p>
                              -Return to this page and click on the Green{" "}
                              <span>Connect to QuickBooks </span>button.
                            </p>
                          </div>
                          <div class="row mt-5">
                            <div class="col-lg-4">
                              <div class="form-group">
                                <h6>Software</h6>
                                <a>
                                  {" "}
                                  <img src="images/quickbooks.png" />
                                </a>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <h6>Software</h6>
                              <h6 class="fw-500">Not Syncd</h6>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group">
                                <h6>Fiscal Year End</h6>
                                <p>March</p>
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                    </div>
                    <div
                      class="ViewConfigBodyHideMain"
                      style={{ display: editGeneralInfo ? "block" : "none" }}
                    >
                      <div class="ViewConfigBodyHide">
                        <aside style={{ width: "100%" }}>
                          <div class="AccounitingCollapseArea">
                            <h6>
                              Recommended Accounting Configuration Procedures
                              Before Integration
                            </h6>
                            <p>
                              - You must first purchase the{" "}
                              <span>QuickBooks Online Plus</span> version as the{" "}
                              <span>Plus</span> version allows class usage
                            </p>
                            <p>
                              - Second, ensure the{" "}
                              <span>Chart of Accounts (COA)</span> has an{" "}
                              <span>Accounts Payables (A/P)</span> account
                              already set-up, if not, set one up.
                            </p>
                            <p>
                              - In your QuickBooks Online Plus version you must
                              first Turn-On the <span>Purchase Order</span>{" "}
                              Option, the <span>Enable account numbers</span>
                              option, then the the <span>Class</span> Option as
                              follows:
                            </p>
                            <ul>
                              <li>
                                --Click on the{" "}
                                <span>
                                  Gear Icon in the Upper Right-Hand Corner of on
                                  your Home Dashboard screen.
                                </span>
                              </li>
                              <li>
                                --Click on <span>Account and Settings</span>
                              </li>
                              <li>
                                --Click on the <span>Account and Settings</span>{" "}
                                option.{" "}
                              </li>

                              <li>
                                --Click on the <span>Expenses</span> option.{" "}
                              </li>
                              <li>
                                --Click on the box in front of the{" "}
                                <span>Use Purchase Orders</span> option.
                              </li>
                              <li>
                                --Click the green <span>Save</span> button.
                              </li>
                              <li>
                                --Click on the <span>Advanced</span> option.
                              </li>
                              <li>
                                --Click on the <span>Chart of Accounts</span>{" "}
                                option.
                              </li>
                              <li>
                                --Click on the box in front of{" "}
                                <span>Enable Account Numbers</span>{" "}
                              </li>
                              <li>
                                --Click the green <span>Save</span> button.
                              </li>
                              <li>
                                --Click the box in front of the{" "}
                                <span>Track Classes</span> option.
                              </li>
                              <li>
                                --Click the green <span>Save</span> button
                              </li>
                            </ul>
                            <p>
                              -Return to this page and click on the Green{" "}
                              <span>Connect to QuickBooks </span>button.
                            </p>
                          </div>
                          <div class="row mt-5">
                            <div class="col-lg-4">
                              <div class="form-group">
                                <h6>Software</h6>
                                <img src="images/quickbooks.png" />
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="d-flex">
                                <div>
                                  <h6>Software</h6>
                                  <h6 class="fw-500">Not Syncd</h6>
                                </div>
                                <div class="Button ml-2">
                                  <button class="Respond p-1">
                                    Connect to <br /> Quickbooks
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group">
                                <h6>Fiscal Year End</h6>
                                <p>March</p>
                              </div>
                            </div>
                            <div>
                              <button class="AlterBtn">Alter</button>
                            </div>
                          </div>
                        </aside>
                      </div>
                      <div class="Button d-flex justify-content-center">
                        <button
                          onClick={() => setEditGeneralInfo(false)}
                          class="OutlineBlack mr-5 SaveViewConfigBodyBtn"
                        >
                          Cancel
                        </button>
                        <button
                          class="Respond ml-5 SaveViewConfigBodyBtn"
                          onClick={addCompanyFunc}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header">
                  <h4 data-toggle="collapse" href="#TaxTabs">
                    <span>
                      <img src="images/qoqr_1.png" />
                    </span>{" "}
                    Tax
                  </h4>
                  <a
                    onClick={() =>
                      setEditGeneralInfo(editGeneralInfo ? false : true)
                    }
                    class="Edit EditViewConfigBodyBtn"
                  >
                    <img src="images/Action-1.png" />
                  </a>
                </div>
                <div
                  id="TaxTabs"
                  className="collapse show"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <div
                      className="ViewConfigBodyMain"
                      style={{ display: editGeneralInfo ? "none" : "block" }}
                    >
                      <div className="ViewConfigBody">
                        <aside className="w-100">
                          <div className="form-group">
                            <h6>Tax Registration</h6>
                            <p>{taxRegistration}</p>
                          </div>
                          <div className="AlbertaTaxMain">
                            <div className="AlbertaTax AlbertaTaxBtn">
                              <h5>Alberta Taxs</h5>
                              <img src="images/down_arrow_fill.png" />
                            </div>
                            <div className="AlbertaTaxHide">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <h6>Tax Location</h6>
                                    <div className="d-flex justify-content-between">
                                      <p>Canada</p>
                                      <p>Alberta (AB)</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 ml-auto">
                                  <div className="form-group">
                                    <h6>Tax Condition</h6>
                                    <div className="d-flex">
                                      <div>
                                        <label className="CheckBox">
                                          {" "}
                                          Default
                                          <input type="checkbox" />
                                          <span className="checkmark" />
                                        </label>
                                      </div>
                                      <div className="ml-4">
                                        <label className="CheckBox">
                                          {" "}
                                          Active
                                          <input type="checkbox" />
                                          <span className="checkmark" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="AlbertaTaxMain">
                            <div className="TaxRow">
                              <h5>Tax Rows</h5>
                            </div>
                            <div className="TaxRowHide">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Tax Name</th>
                                    <th>Tax Percent</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <p>GST</p>
                                    </td>
                                    <td>
                                      <p>5%</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="AlbertaTaxMain">
                            <div className="Rebate">
                              <h5>Rebates</h5>
                            </div>
                            <div className="TaxRowHide">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Applicable Row</th>
                                    <th>Sale Price Range</th>
                                    <th>Applied as</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <p>GST(AB 5%)</p>
                                    </td>
                                    <td>
                                      <p>$0.00</p>
                                    </td>
                                    <td>
                                      <p>Sliding Scale</p>
                                    </td>
                                    <td>
                                      <p>$6,300.00</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </aside>
                      </div>
                    </div>
                    <div
                      className="ViewConfigBodyHideMain"
                      style={{ display: editGeneralInfo ? "block" : "none" }}
                    >
                      <div className="ViewConfigBodyHide">
                        <aside className="w-100">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Tax Registration</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="713158681RT0001"
                                  name="taxRegistration"
                                  value={taxRegistration}
                                  onChange={handleInput}
                                />
                              </div>
                              <div className="form-group">
                                <h6>Tax Profiles</h6>
                                <div className="SaveDeleteProfile justify-content-start">
                                  <button
                                    className="mx-0"
                                    onClick={handleCraeteProfileModalShow}
                                  >
                                    Add New Tax Profile
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {taxProfile?.length > 0 &&
                            taxProfile?.map((data, i) => {
                              return (
                                <div className="row">
                                  <div className="col-12">
                                    <div className="AlbertaTaxMain">
                                      <div className="AlbertaTax AlbertaTaxBtn">
                                        <h5>{data.name}</h5>
                                        <img src="images/down_arrow_fill.png" />
                                      </div>
                                      <div className="AlbertaTaxHide">
                                        <div className="row">
                                          <div className="col-md-7">
                                            <div className="row">
                                              <div className="col-md-6">
                                                <div className="form-group">
                                                  <h6>Tax Location</h6>
                                                  <div className="CommonSelectBox">
                                                    <select
                                                      name="country"
                                                      value={country}
                                                      onChange={(e)=>handleCountryStateChange(e,i)}
                                                    >
                                                      <option value="">
                                                        Select Country
                                                      </option>
                                                      {countryData?.countries
                                                        ?.length > 0 ? (
                                                        countryData?.countries?.map(
                                                          (item) => {
                                                            return (
                                                              <option
                                                                value={
                                                                  item.name
                                                                }
                                                                key={item.id}
                                                                selected={
                                                                  item.name ===
                                                                  data?.country
                                                                }
                                                              >
                                                                {item.name}
                                                              </option>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <option disabled>
                                                          No Countries are
                                                          available
                                                        </option>
                                                      )}
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-5">
                                                <div className="form-group">
                                                  <h6>&nbsp;</h6>
                                                  <div className="CommonSelectBox">
                                                    <select
                                                      name="province"
                                                      value={province}
                                                      onChange={(e)=>handleCountryStateChange(e,i)}
                                                    >
                                                      <option value="">
                                                        Select Province/State
                                                      </option>
                                                      {stateData?.states
                                                        ?.length > 0 ? (
                                                        stateData?.states?.map(
                                                          (item) => {
                                                            return (
                                                              <option
                                                                value={
                                                                  item.name
                                                                }
                                                                key={item.id}
                                                                selected={
                                                                  item.name ===
                                                                  data?.province
                                                                }
                                                              >
                                                                {item.name}
                                                              </option>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <option disabled>
                                                          No Province/State are
                                                          available
                                                        </option>
                                                      )}
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="form-group">
                                              <h6>Tax Condition</h6>
                                              <div className="d-flex">
                                                <div>
                                                  <label className="CheckBox">
                                                    {" "}
                                                    Default
                                                    <input
                                                      type="checkbox"
                                                      checked={data.activeCheck}
                                                    />
                                                    <span className="checkmark" />
                                                  </label>
                                                </div>
                                                <div className="ml-4">
                                                  <label className="CheckBox">
                                                    {" "}
                                                    Active
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        data.defaultCheck
                                                      }
                                                    />
                                                    <span className="checkmark" />
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                 
                                      <div className="AlbertaTaxMain">
                                        <div className="TaxRow">
                                          <h5>Tax Rows</h5>
                                        </div>
                                        <div className="TaxRowHide">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Tax Name</th>
                                                <th>Tax Percent</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {data?.taxRows?.length > 0 &&
                                                data?.taxRows?.map(
                                                  (datas, index) => {
                                                    return (
                                                      <tr>
                                                        <td>
                                                          <div className="form-group">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Insert Tax Name"
                                                              name="taxName"
                                                              value={
                                                                datas.taxName
                                                              }
                                                              onChange={(e) =>
                                                                handleTaxRowsChange(
                                                                  e,
                                                                  i,
                                                                  index
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className="form-group">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Insert Tax Percent"
                                                              name="taxPercent"
                                                              value={
                                                                datas.taxPercent
                                                              }
                                                              onChange={(e) =>
                                                                handleTaxRowsChange(
                                                                  e,
                                                                  i,
                                                                  index
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className="TableThreeDots text-center">
                                                            <ul className="">
                                                              <li className="dropdown">
                                                                <a
                                                                  className=""
                                                                  role="button"
                                                                  data-toggle="dropdown"
                                                                  aria-haspopup="true"
                                                                  aria-expanded="false"
                                                                >
                                                                  <img src="images/dots.png" />
                                                                </a>
                                                                <ol
                                                                  className="dropdown-menu dropdown-menuwidth"
                                                                  style={{}}
                                                                >
                                                                  <li>
                                                                    <a href="javascript:void(0);">
                                                                      <img src="images/remove_tax_line.png" />
                                                                      Remove
                                                                      line
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a href="javascript:void(0);">
                                                                      <img src="images/add_new_tax_line.png" />
                                                                      Add New
                                                                      Line
                                                                    </a>
                                                                  </li>
                                                                </ol>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                  
                                    {data?.isRebate && (
                                    <div className="AlbertaTaxMain">
                                      <div className="Rebate">
                                        <h5>Rebates</h5>
                                      </div>
                                      <div
                                        className="TaxRowHide"
                                        style={{ overflowX: "auto" }}
                                      >
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>Applicable Row</th>
                                              <th>Sale Price Range</th>
                                              <th>Applied as</th>
                                              <th>Amount</th>
                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {data?.taxRebates?.length > 0 &&
                                              data?.taxRebates?.map(
                                                (item, ind) => {
                                                  return (
                                                    <tr key={ind}>
                                                      <td>
                                                        <div
                                                          className="CommonSelectBox"
                                                          style={{
                                                            width:
                                                              "200px!important",
                                                          }}
                                                        >
                                                          <select
                                          name="applicableRow"
                                          onChange={(e) =>
                                            handleTaxDebatesChange(
                                              e,
                                              i,
                                              ind
                                            )
                                          }
                                        >
                                          <option value="">
                                            Select Option
                                          </option>
                                          {data?.taxRows?.length > 0 &&
                                            data?.taxRows?.map((x) => {
                                              return (
                                                <option value={x.taxName} selected={x.taxName===item.applicableRow}>
                                                  {x.taxName}
                                                </option>
                                              );
                                            })}
                                        </select>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="d-flex">
                                                          <div className="form-group mr-1">
                                                            <input
                                                              type="number"
                                                              className="form-control width-50"
                                                              placeholder="$0.00"
                                                              name="minRange"
                                                              value={
                                                                item.minRange
                                                              }
                                                              onChange={(e) =>
                                                                handleTaxDebatesChange(
                                                                  e,
                                                                  i,
                                                                  ind
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className="form-group ml-1">
                                                            <input
                                                              type="text"
                                                              className="form-control width-50"
                                                              placeholder="$0.00"
                                                              name="maxRange"
                                                              value={
                                                                item.maxRange
                                                              }
                                                              onChange={(e) =>
                                                                handleTaxDebatesChange(
                                                                  e,
                                                                  i,
                                                                  ind
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div
                                                            className="CommonSelectBox"
                                                            style={{
                                                              width:
                                                                "200px!important",
                                                            }}
                                                          >
                                                            <select
                                                              name="appliedAss"
                                                              onChange={(e) =>
                                                                handleTaxDebatesChange(
                                                                  e,
                                                                  i,
                                                                  ind
                                                                )
                                                              }
                                                            >
                                                              <option value="">
                                                                Select Option
                                                              </option>
                                                              <option
                                                                value="Sliding"
                                                                selected={
                                                                  item?.appliedAss ==
                                                                  "Sliding"
                                                                }
                                                              >
                                                                Sliding
                                                              </option>
                                                              <option
                                                                value="Fixed"
                                                                selected={
                                                                  item?.appliedAss ==
                                                                  "Fixed"
                                                                }
                                                              >
                                                                Fixed
                                                              </option>
                                                            </select>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="$6,300.00"
                                                            name="amount"
                                                            value={item.amount}
                                                            onChange={(e) =>
                                                              handleTaxDebatesChange(
                                                                e,
                                                                i,
                                                                ind
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="TableThreeDots text-center">
                                                          <ul className="">
                                                            <li className="dropdown">
                                                              <a
                                                                className=""
                                                                role="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                              >
                                                                <img src="images/dots.png" />
                                                              </a>
                                                              <ol className="dropdown-menu dropdown-menuwidth">
                                                                <li>
                                                                  <a href="javascript:void(0);">
                                                                    <img src="images/remove_tax_line.png" />
                                                                    Remove line
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a href="javascript:void(0);">
                                                                    <img src="images/add_new_tax_line.png" />
                                                                    Add New Line
                                                                  </a>
                                                                </li>
                                                              </ol>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="SaveDeleteProfile mt-4">
                                        <button onClick={()=>handleUpdateTaxProfile(data)}>Save Profile</button>
                                        <button
                                          onClick={() =>
                                            handleDeleteTaxProfile(data.id)
                                          }
                                        >
                                          Delete Profile
                                        </button>
                                      </div>
                                    </div>
                                      )}
                                  </div>
                                </div>
                              );
                            })}
                        </aside>
                      </div>
                      <div className="Button d-flex justify-content-center mt-4">
                        <button className="OutlineBlack mr-3 SaveViewConfigBodyBtn">
                          Cancel
                        </button>
                        <button className="Respond ml-3 SaveViewConfigBodyBtn" onClick={handleTaxUpdate}>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header">
                  <h4 data-toggle="collapse" href="#JobNumberingTabs">
                    <span>
                      <img src="images/job_numbering.png" />
                    </span>{" "}
                    Job Numbering
                  </h4>
                  <a class="Edit EditViewConfigBodyBtn">
                    <img src="images/Action-1.png" />
                  </a>
                </div>
                <div
                  id="JobNumberingTabs"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div class="ViewConfigBodyHideMain">
                      <div class="ViewConfigBodyHide">
                        <aside class="w-100">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group">
                                <h6>Job Number Pattern</h6>
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    updateState({
                                      ...iState,
                                      jobNumberPattern: e.target.value,
                                    });
                                  }}
                                  value={jobNumberPattern}
                                  class="form-control InputCyanBg"
                                  placeholder="[UNUM]-[ACD]"
                                />
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <h6>Sample Job Number</h6>
                                <input
                                  type="text"
                                  class="form-control InputCyanBg"
                                  placeholder="MAH-2B-377"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="RestrictionArea">
                            <h6>Restrictions</h6>
                            <ul>
                              <li>
                                -Max length for generated job number is 40
                                characters.
                              </li>
                              <li>
                                -[CID] and [JN] must both occur at least once.
                              </li>
                              <li>
                                -Only alpha numeric characters and single dashes
                                are allowed.
                              </li>
                              <li>-It cannot end/start in a dash.</li>
                            </ul>
                          </div>
                          <div class="KeySelectionArea">
                            <h6>Key Selection</h6>
                            <div class="KeySelectionBox">
                              <aside>
                                <p>
                                  <button
                                    onClick={(e) =>
                                      handleKeySelection(e, "[ACD]")
                                    }
                                    class="BlueBtn"
                                  >
                                    [ACD]
                                  </button>
                                  <span>Agreement or Creation Date</span>
                                </p>
                                <p>
                                  <button
                                    onClick={(e) =>
                                      handleKeySelection(e, "[ACDM2]")
                                    }
                                    class="BlueBtn"
                                  >
                                    [ACDM2]
                                  </button>
                                  <span>
                                    Agreement or Creation <br />
                                    Date: Two Digit Month
                                  </span>
                                </p>
                                <p>
                                  <button
                                    onClick={(e) =>
                                      handleKeySelection(e, "[SD3]")
                                    }
                                    class="BlueBtn"
                                  >
                                    [SD3]
                                  </button>
                                  <span>Three Characteres of Subdivision</span>
                                </p>
                                <p>
                                  <button
                                    onClick={(e) =>
                                      handleKeySelection(e, "[FY2]")
                                    }
                                    class="BlueBtn"
                                  >
                                    [FY2]
                                  </button>
                                  <span>Two Digit Fiscal Year</span>
                                </p>
                              </aside>
                              <aside>
                                <p>
                                  <button
                                    onClick={(e) =>
                                      handleKeySelection(e, "[ACDY2]")
                                    }
                                    class="BlueBtn"
                                  >
                                    [ACDY2]
                                  </button>
                                  <span>
                                    Agreement or Creation <br />
                                    Date: Two Digit Year
                                  </span>
                                </p>
                                <p>
                                  <button
                                    onClick={(e) =>
                                      handleKeySelection(e, "[CID]")
                                    }
                                    class="GreenBtn"
                                  >
                                    [CID]
                                  </button>
                                  <span>Company ID</span>
                                </p>
                                <p>
                                  <button
                                    onClick={(e) =>
                                      handleKeySelection(e, "[SD4]")
                                    }
                                    class="BlueBtn"
                                  >
                                    [SD4]
                                  </button>
                                  <span>Four Characteres of Subdivision</span>
                                </p>
                                <p>
                                  <button
                                    onClick={(e) =>
                                      handleKeySelection(e, "[FY4]")
                                    }
                                    class="BlueBtn"
                                  >
                                    [FY4]
                                  </button>
                                  <span>Four Digit Fiscal Year</span>
                                </p>
                              </aside>
                              <aside>
                                <p>
                                  <button
                                    onClick={(e) =>
                                      handleKeySelection(e, "[ACDY4]")
                                    }
                                    class="BlueBtn"
                                  >
                                    [ACDY4]
                                  </button>
                                  <span>
                                    Agreement or Creation <br />
                                    Date: Four Digit Year
                                  </span>
                                </p>
                                <p>
                                  <button
                                    onClick={(e) =>
                                      handleKeySelection(e, "[JN]")
                                    }
                                    class="GreenBtn"
                                  >
                                    [JN]
                                  </button>
                                  <span>Job Number</span>
                                </p>
                                <p>
                                  <button
                                    onClick={(e) =>
                                      handleKeySelection(e, "[UNUM]")
                                    }
                                    class="BlueBtn"
                                  >
                                    [UNUM]
                                  </button>
                                  <span>Three Characteres of Subdivision</span>
                                </p>
                              </aside>
                            </div>
                          </div>
                        </aside>
                      </div>
                      <div class="Button d-flex justify-content-center">
                        <button
                          onClick={() => setEditGeneralInfo(false)}
                          class="OutlineBlack mr-5 SaveViewConfigBodyBtn"
                        >
                          Cancel
                        </button>
                        <button
                          class="Respond ml-5 SaveViewConfigBodyBtn"
                          onClick={addCompanyFunc}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header">
                  <h4 data-toggle="collapse" href="#SubscriptionTabs">
                    <span>
                      <img src="images/subscription.png" />
                    </span>{" "}
                    Subscription
                  </h4>
                  <a class="Edit EditViewConfigBodyBtn">
                    <img src="images/Action-1.png" />
                  </a>
                </div>
                <div
                  id="SubscriptionTabs"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div class="ViewConfigBodyMain">
                      <div class="ViewConfigBody">
                        <aside class="w-100">
                          <div class="row">
                            <div class="col-lg-3">
                              <div class="form-group">
                                <h6>Company Id</h6>
                                <p>Company Id</p>
                              </div>
                            </div>
                            <div class="col-lg-3">
                              <div class="form-group">
                                <h6>Subscription</h6>
                                <p>Plan 1</p>
                              </div>
                            </div>
                            <div class="col-lg-3">
                              <div class="form-group">
                                <h6>Billing Option</h6>
                                <p>Monthly</p>
                              </div>
                            </div>
                            <div class="col-lg-3">
                              <div class="form-group">
                                <h6>Contract Code</h6>
                                <p>CO001FULL</p>
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                    </div>
                    <div class="ViewConfigBodyHideMain">
                      <div class="ViewConfigBodyHide">
                        <aside class="w-100">
                          <div class="row">
                            <div class="col-lg-3">
                              <div class="form-group">
                                <h6>Company Id</h6>
                                <p>Company Id</p>
                              </div>
                            </div>
                            <div class="col-lg-3">
                              <div class="form-group">
                                <h6>Subscription</h6>
                                <p>Plan 1</p>
                                <button class="AlterBtn mt-3">Alter</button>
                              </div>
                            </div>
                            <div class="col-lg-3">
                              <div class="form-group">
                                <h6>Billing Option</h6>
                                <p>Monthly</p>
                                <button class="AlterBtn mt-3">Alter</button>
                              </div>
                            </div>
                            <div class="col-lg-3">
                              <div class="form-group">
                                <h6>Contract Code</h6>
                                <p>CO001FULL</p>
                              </div>
                            </div>
                          </div>

                          <div class="Button d-flex justify-content-center">
                            <button class="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                              Cancel
                            </button>
                            <button class="Respond ml-5 SaveViewConfigBodyBtn">
                              Save
                            </button>
                          </div>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header">
                  <h4 data-toggle="collapse" href="#ProjectsTabs">
                    <span>
                      <img src="images/project_collapse.png" />
                    </span>{" "}
                    Projects
                  </h4>
                  <a class="Edit EditViewConfigBodyBtn">
                    <img src="images/Action-1.png" />
                  </a>
                </div>
                <div
                  id="ProjectsTabs"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div class="ViewConfigBodyMain">
                      <div class="ViewConfigBody">
                        <aside class="w-100">
                          <div class="Tableheader">
                            <h6 class="mb-0">Regular Project</h6>
                            <div class="d-flex align-items-center">
                              <div class="TodosButtons mt-0 d-flex">
                                <a
                                  onClick={() => SetNewProjectStep1Modal(true)}
                                  class=""
                                >
                                  <img src="images/create_new_project.png" />
                                  Create New Project
                                </a>
                              </div>
                              <div class="NewActions">
                                <a href="jacascript:void(0);">
                                  <img src="images/icon-53.png" />
                                </a>
                                <a href="jacascript:void(0);">
                                  <img src="images/download.png" />
                                </a>
                                <a href="jacascript:void(0);">
                                  <img src="images/setting.png" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="TableOverflowScroll">
                            <div class="TableList TableListHeader NewTableList TableHeightScroll">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Address</th>
                                    <th>Creation Date</th>
                                    <th>Created By</th>
                                    <th>Accounting Status</th>
                                    <th># Active Jobs</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {regularProjectList?.projects?.length ? (
                                    regularProjectList?.projects?.map(
                                      (item) => {
                                        return (
                                          <tr key={item.id}>
                                            <td>
                                              <a
                                                href="javascript:void(0);"
                                                class="TextBlue"
                                              >
                                                {item.id}
                                              </a>
                                            </td>
                                            <td>{item.projectName}</td>
                                            <td>{item.projectType}</td>
                                            <td>{item.projectAddress}</td>
                                            <td>
                                              {moment(item.craetedAt).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </td>
                                            <td>John Doe</td>
                                            <td>{item.status}</td>
                                            <td>0</td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan="10">No data found</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="PaginationArea">
                            <h6>Showing 3 of 3 entries</h6>
                            <h6>
                              <img src="images/leftarrow.png" /> 01{" "}
                              <img src="images/rightarrow.png" /> Out 10
                            </h6>
                          </div>

                          <div class="Tableheader mt-5">
                            <h6 class="mb-0">Regular Entity Project</h6>
                            <div class="d-flex align-items-center">
                              <div class="TodosButtons mt-0 d-flex">
                                <a
                                  onClick={() =>
                                    SetProjectAsEntityStep1Modal(true)
                                  }
                                  class=""
                                >
                                  <img src="images/create_new_project.png" />
                                  Create New Project
                                </a>
                              </div>
                              <div class="NewActions">
                                <a href="jacascript:void(0);">
                                  <img src="images/icon-53.png" />
                                </a>
                                <a href="jacascript:void(0);">
                                  <img src="images/download.png" />
                                </a>
                                <a href="jacascript:void(0);">
                                  <img src="images/setting.png" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="TableOverflowScroll">
                            <div class="TableList TableListHeader NewTableList TableHeightScroll">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Address</th>
                                    <th>Creation Date</th>
                                    <th>Created By</th>
                                    <th>Accounting Status</th>
                                    <th>Subscription</th>
                                    <th># Active Jobs</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {regularEntityList?.projects?.length ? (
                                    regularEntityList?.projects?.map((item) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>
                                            <a
                                              href="javascript:void(0);"
                                              class="TextBlue"
                                            >
                                              {item.id}
                                            </a>
                                          </td>
                                          <td>{item.projectName}</td>
                                          <td>{item.projectType}</td>
                                          <td>{item.projectAddress}</td>
                                          <td>
                                            {moment(item.craetedAt).format(
                                              "YYYY-MM-DD"
                                            )}
                                          </td>
                                          <td>John Doe</td>
                                          <td>{item.status}</td>
                                          <td>Yes</td>
                                          <td>0</td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="10">No data found</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="PaginationArea">
                            <h6>Showing 3 of 3 entries</h6>
                            <h6>
                              <img src="images/leftarrow.png" /> 01{" "}
                              <img src="images/rightarrow.png" /> Out 10
                            </h6>
                          </div>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="CloseButton">
                <button class="Close" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        HeaderPreviewModal&& <Modal
        show={HeaderPreviewModal}
        onHide={handlHeaderPreviewModalClose}
        className="ModalBox fade ModalBox LargeModal modal show"
      >
        <Modal.Body>
          <button className="CloseModal" onClick={handlHeaderPreviewModalClose}>
            ×
          </button>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Configure Company Letter Header</h4>
            </div>
            <div className="FolderForm">

            <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload" style={{
                    width:"300px"
                  }}>
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              <div className="AgreementViewArea">
                <div className="AgreementViewTop">
                  <div className="AgreementViewTopRight">
                    <div className="mce-pagebreak">
                      <Editor
                        apiKey="s5lpq1i7ftirx73vaw448qm0lk3phqsvnx3dpqvp542y1fbd"
                        onInit={(evt, editor) => (editorsRef.current = editor)}
                        value={letterHeader}
                        init={init}
                        onEditorChange={handleEditorChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button text-center mt-5">
                <button className="Outline" onClick={handleUploadFiles}>
                  Apply
                </button>

                <button className="Outline ml-2" onClick={handlHeaderPreviewModalClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      }

      


      <Modal
        show={HeaderPreviewsModal}
        onHide={handlHeaderPreviewsModalClose}
        className="ModalBox fade ModalBox LargeModal modal show"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handlHeaderPreviewsModalClose}>
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Header Preview</h4>
            </div>
            <div className="FolderForm">
              <div className="AgreementViewArea">
              <div className="AgreementViewTop">
                <div className="AgreementViewTopRight">
                  <p
                    dangerouslySetInnerHTML={createAboutHeader()}
                    className="editor"
                  ></p>
                </div>
              </div>
              </div>
              <div className="Button text-center mt-5">
                <button className="Outline" onClick={handlHeaderPreviewsModalClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={CreateProfileModal}
        onHide={handleCraeteProfileModalClose}
        className="ModalBox modal fade LargeModal GreyModalContent show"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handleCraeteProfileModalClose}>
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create New Tax Profile</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <h6>Tax Location</h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        name="country"
                        value={country}
                        onChange={handleInput}
                      >
                        <option value="">Select Country</option>
                        {countryData?.countries?.length > 0 ? (
                          countryData?.countries?.map((item) => {
                            return (
                              <option value={item.name} key={item.id}>
                                {item.name}
                              </option>
                            );
                          })
                        ) : (
                          <option disabled>No Countries are available</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <h6>&nbsp;</h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        name="province"
                        value={province}
                        onChange={handleInput}
                      >
                        <option value="">Select Province/State</option>
                        {stateData?.states?.length > 0 ? (
                          stateData?.states?.map((item) => {
                            return (
                              <option value={item.name} key={item.id}>
                                {item.name}
                              </option>
                            );
                          })
                        ) : (
                          <option disabled>
                            No Province/State are available
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="AlbertaTaxMain">
                    <div className="TaxRowHide">
                      <table>
                        <thead>
                          <tr>
                            <th>Tax Name</th>
                            <th>Tax Percent</th>
                            <th>Action</th>
                            <th>
                              <button
                                className="BrandBtn mr-2"
                                onClick={() => Addmore("taxRow")}
                              >
                                Add
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {taxRows?.map((taxD, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Insert Tax Name"
                                      name="taxName"
                                      value={taxD.taxName}
                                      onChange={(e) => handleTaxInput(e, i)}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Insert Tax Percent"
                                      name="taxPercent"
                                      value={taxD.taxPercent}
                                      onChange={(e) => handleTaxInput(e, i)}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="TableThreeDots text-center">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu dropdown-menuwidth">
                                          {taxRows?.length > 1 && (
                                            <li
                                              onClick={() => handleTaxRemove(i)}
                                            >
                                              <a href="javascript:void(0);">
                                                <img src="images/remove_tax_line.png" />
                                                Remove line
                                              </a>
                                            </li>
                                          )}
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td colSpan={3}>
                              <div className="form-group">
                                <h6>Rebate</h6>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name="isRebate"
                                    checked={isRebate}
                                    onClick={handleTaxCheck}
                                  />
                                  <span className="slider" />
                                </label>
                              </div>
                            </td>
                          </tr>
                          {isRebate &&
                            taxRebates?.length > 0 &&
                            taxRebates?.map((taxRe, i) => {
                              return (
                                <tr>
                                  <td>
                                    <div className="form-group">
                                      <h6>Applied Row</h6>
                                      <div className="CommonSelectBox">
                                        <select
                                          name="applicableRow"
                                          onChange={(e) =>
                                            handleRebelChange(e, i)
                                          }
                                        >
                                          <option value="">
                                            Select Option
                                          </option>
                                          {taxRows?.length > 0 &&
                                            taxRows?.map((x) => {
                                              return (
                                                <option value={x.taxName}>
                                                  {x.taxName}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <div className="form-group mr-1">
                                        <h6>Sale Price Range</h6>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="$ greater than"
                                          name="minRange"
                                          value={taxRe?.minRange}
                                          onChange={(e) =>
                                            handleRebelChange(e, i)
                                          }
                                        />
                                      </div>
                                      <div className="form-group ml-1">
                                        <h6>&nbsp;</h6>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="$ to"
                                          name="maxRange"
                                          value={taxRe?.maxRange}
                                          onChange={(e) =>
                                            handleRebelChange(e, i)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <h6>Applied as</h6>
                                      <div className="CommonSelectBox">
                                        <select
                                          name="appliedAss"
                                          onChange={(e) =>
                                            handleRebelChange(e, i)
                                          }
                                        >
                                          <option value="">
                                            Select Option
                                          </option>
                                          <option value="Sliding">
                                            Sliding
                                          </option>
                                          <option value="Fixed">Fixed</option>
                                        </select>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <h6>Amount</h6>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Insert Tax Percent"
                                        name="amount"
                                        value={taxRe?.amount}
                                        onChange={(e) =>
                                          handleRebelChange(e, i)
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="pt-0">
                                    <div className="form-group">
                                      <h6 style={{ marginTop: "-10px" }}>
                                        Action
                                      </h6>
                                      <ul className="TableThreeDots text-center">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            {taxRebates.length > 0 && (
                                              <li
                                                onClick={() =>
                                                  handleRebelRemove(i)
                                                }
                                              >
                                                <a href="javascript:void(0);">
                                                  <img src="images/remove_tax_line.png" />
                                                  Remove line
                                                </a>
                                              </li>
                                            )}
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          <button
                            className="BrandBtn mr-2 mt-2"
                            onClick={() => Addmore("taxRebel")}
                          >
                            Add New Line
                          </button>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="form-gro">
                    <h6>Tax Condition</h6>
                    <div className="d-flex flex-column">
                      <label className="CheckBox">
                        {" "}
                        Default
                        <input
                          type="checkbox"
                          name="defaultCheck"
                          checked={defaultCheck}
                          onClick={handleTaxCheck}
                        />
                        <span className="checkmark" />
                      </label>
                      <label className="CheckBox">
                        {" "}
                        Active
                        <input
                          type="checkbox"
                          name="activeCheck"
                          checked={activeCheck}
                          onClick={handleTaxCheck}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center mt-3s">
                <button
                  className="Create"
                  button="type"
                  onClick={handleCreateTaxProfile}
                >
                  Create Tax Profile
                </button>
                <button
                  className="Outline"
                  onClick={handleCraeteProfileModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <NewProjectStep1
        SetNewProjectStep1Modal={SetNewProjectStep1Modal}
        NewProjectStep1Modal={NewProjectStep1Modal}
        setShow={setShow}
      />

      {/* PROJECT AS ENTITY */}

      <ProjectAsEntityStep1
        ProjectAsEntityStep1Modal={ProjectAsEntityStep1Modal}
        SetProjectAsEntityStep1Modal={SetProjectAsEntityStep1Modal}
        setShow={setShow}
      />
    </>
  );
};

export default AdminCompanyDetails;
