
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

import {
  AddDiscussionAction,
  AddReplyAction,
  discussionListAction,
} from "../../redux/action/constructionsAction"

import { toast } from "react-toastify";
import SignClientCtcModal from "./SignClientCtcModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const units = []


const ViewGenerateChangeOrder = ({viewGenerateChangeOrder, setViewGenerateChangeOrder , setSignClientCtcModal
}) => {


  const dispatch = useDispatch()



  const { discussionList } = useSelector((state) => state.constructionReducer);

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  const [discussionState, updateDiscussionState] = useState({
    jobId: 3,
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });


  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          jobId: replyState.jobId,
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: discussionState.jobId }));
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };
  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction({
          message: discussionState.message,
          jobId: discussionState.jobId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: discussionState.jobId }));

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };



  useEffect(() => {
    if (viewGenerateChangeOrder) {
      dispatch(discussionListAction({ jobId: 3 }));
    }
  }, [viewGenerateChangeOrder]);

  return (
    <>

    <Modal show={viewGenerateChangeOrder} className="ModalBox ExtraLargeModal">
    <div class="modal-content BgCreate">
      <a
        class="CloseModal"
        onClick={()=> {          setSignClientCtcModal(true)

          setViewGenerateChangeOrder(false)}}
        data-dismiss="modal"
      >
        ×
      </a>
      <div class="FormArea">
          
              <div class="FolderForm">
                <form>
                    
                  <div class="WrapperArea m-0 w-100">
                    <div class="WrapperBox  pt-0 px-0">
            
                      <div class="TitleBox">
                        <h4 class="Title">Change Order #1</h4>
                        <div class="TitleBoxProjectHeading">
                          <h6><span>Status:</span>&nbsp;Purchase Orders Created</h6>
                          <h6><span>Authorization Status:</span>&nbsp;In progess</h6>
                       </div>
                        <div class="TitleBox2 TitleBoxRight">
                          <div>
                            <h6>UUnit3 -CHI -2B -315</h6>
                            <span>Multi-Family - Residential Unit</span>
                          </div>
                        </div>
                      </div>
              
                      <div class="WarrantyDashboardForm mb-4">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <label>Description <span class="Required">*</span></label>
                                  <p class="ml-0">Originated Change Order from CTC Master Client Name</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-5 offset-1">
                            <div class="Profile_ontario mb-5">
                              <div class="Profile_ontario_box">
                                <h6>Profile:</h6>
                                <p>Ontario Tax</p>
                              </div>
                              <div class="Profile_ontario_box">
                                <h6>HST:</h6>
                                <p>13%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
              
                      <div class="WarrantyDashboardForm">
                        <h4>Client Details</h4>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label>Possession Date</label>
                                  <input type="text" class="form-control" placeholder="Auto-filled by System" />
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label>Request Date</label>
                                  <input type="text" class="form-control" placeholder="Auto-filled by System" />
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label>Client Full Legal Name</label>
                                  <input type="text" class="form-control" placeholder="Auto-filled by System" />
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label>Phone Number</label>
                                  <input type="text" class="form-control" placeholder="Auto-filled by System" />
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label>Primary Email</label>
                                  <input type="text" class="form-control" placeholder="Auto-filled by System" />
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label>Job Address <img src="images/location.png" class="ml-2" /></label>
                                  <input type="text" class="form-control" placeholder="Auto-filled by System" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
              
                      <div class="Tableheader mt-5">
                        <h6>Change Items</h6>
                      </div>
                        <div class="TableList NewTableList TableListHeader TableHeightScroll">
                          <table>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Measure</th>
                                <th>Per Unit</th>
                                <th>Client Total</th>
                                <th>Po Line Total</th>
                                <th>Markup</th>
                                <th>Accounts</th>
                                <th>Pictures</th>
                                <th>Supporting Doc</th>
                                <th>Action</th>
                              </tr>
                              
                            </thead>
                            <tbody>
              
              
                              <tr>
                                <td>1</td>
                                <td>Sandstone Color Pallete</td>
                                <td>2</td>
                                <td>
                                  <div class="SelectAnOption">
                                    <div class="dropdown">
                                      <button class="" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Select an option
                                        <img src="images/black-down-arrow.png" />
                                      </button>
                                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <div class="DropdownItemSearch">
                                            <input type="text" placeholder="-Search" />
                                            
                                              <img src="images/search.png" />
                                            
                                          </div>
                                          <a class="dropdown-item" href="javascript:void(0);"><img src="images/add-plus.png" />Add New</a>
                                          <a class="dropdown-item" href="javascript:void(0);">-Warranty</a>
                                          <a class="dropdown-item" href="javascript:void(0);">-   Full list of units 
                                            loaded from 
                                            the system</a>
                                        </div>
                                    </div>
                                  </div>
                                </td>
                                <td>$1,000.00</td>
                                <td>$1,000.00</td>
                                <td>$1,000.00</td>
                                <td>20 %</td>
                                <td>
                                  <div class="SelectAnOption">
                                    <div class="dropdown">
                                      <button class="" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Select an option
                                        <img src="images/black-down-arrow.png" />
                                      </button>
                                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <div class="DropdownItemSearch">
                                            <input type="text" placeholder="-Search" />
                                            
                                              <img src="images/search.png" />
                                            
                                          </div>
                                          <div class="TableCheckBoxArea">
                                            <label class="CheckBox"> List of Cost Codes loaded by the system
                                              <input type="checkbox" />
                                              <span class="checkmark"></span>
                                          </label>
                                          </div>
                                          
                                          
                                        </div>
                                    </div>
                                  </div>
                                </td>
                                <td><figure><img src="images/picture-11.png" /></figure></td>
                                <td>
                                  <div class="Actions">
                                        <a href="javascript:void(0);"><img src="images/icon-63.png" /></a>
                                        <a href="javascript:void(0)"><span class="Count">3</span></a>
                                    </div>
                                </td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a class="" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li><a href="javascript:void(0);"><img src="images/update_price_book.png" />Update Price Book's Entry</a></li>
                                          <li><a href="javascript:void(0);"><img src="images/dropdown-delete.png" />Remove item</a></li>
                                        </ol>
                                      </li>
                                    </ul>
                                   </div>
                                </td>
                                
                              </tr>
              
              
              
              
              
              
                           
              
                              
                              
                
                                
                            </tbody>
                          </table>
                        </div>
                        <div class="TodosButtons mt-5">
                          <a href="javascript:void(0)" class="mr-5 Create"><h6 class="mb-0"  style={{color:"#FC3400"}}><img src="images/generate_purchase_order.png" />Generate Purchase Orders</h6>
                            <span>3</span>
                          </a>
                          <a href="javascript:void(0)" class="mx-5"><h6 class="mb-0 text-white"><img src="images/show-create-purchase.png" />Purchase Order List</h6>
                            <span>3</span>
                          </a>
                        </div>
                       
                        <div class="ClientContactArea mt-5">
                          <div class="ClientContactTitle ClientContactTitle1">
                            <h4>Client Contact</h4>
                            <div class="DiscussionChat">
                              <img src="images/down.png" class="DiscussionIcon" />
                            </div>
                          </div>
                          <div class="ClientContactHideArea ClientContactHideArea1" style={{display:"block"}}>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-5">
                                    <div class="UrgencyArea mb-5">
                                      <div class="form-group">
                                        <label>Builder Signee</label>
                                        <p class="ml-0">Joe Manager</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5 ml-auto">
                                    <div class="FolderForm">
                                      <div class="form-group">
                                        <h6>Client Signees</h6>
                                        <h6>Number of Signees</h6>
                                        <input type="text" class="form-control" placeholder="Insert Number of Signees" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">
                                    <div class="UrgencyArea mt-5">
                                      <div class="form-group">
                                        <label>Primary Client Signee</label>
                                       <p class="ml-0">Client 1</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-5 ml-auto">
                              <div class="FolderForm">
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <div class="ClinetSignatureStatus">
                                        <h6>Signature Status</h6>
                                        <p class="ml-0 SigneeColor">Signed</p>
                                      </div>
                                    </div>
                                    <div class="form-group mt-5">
                                      <div class="ClinetSignatureStatus">
                                        <h6>Signature Status</h6>
                                        <p class="ml-0 SigneeColor">Signed</p>
                                      </div>
                                    </div>
                                  </div>
                                
                                </div>
                              </div>
                              </div>
                            </div>
                          </div>
              
              
                          
                        </div>
              
              
                        <div class="ClientContactArea">
                          <div class="ClientContactTitle ClientContactTitle2">
                            <h4>Agreement Document</h4>
                            <div class="DiscussionChat">
                              <img src="images/down.png" class="DiscussionIcon" />
                            </div>
                          </div>
                          <div class="ClientContactHideArea ClientContactHideArea2" style={{display:"block"}}>
                            <div class="FolderForm">
                              
                                <div class="row">
                                  <div class="form-group col-md-6 mb-4">
                                    <div class="CommonModalArea">
                                     <h5>Agreement Document</h5>
                                    <div class="CommonModalBox CursorPointer FolderPermissionId">
                                      <figure class="mb-0"><img src="images/agreement_document.png" /></figure>
                                        <span class="Count CursorPointer">3</span>
                                        <h5 class="ml-2"> Agreement Document</h5>
                                    </div>
                                    </div>
                                  </div>
                    
                                  
                                </div>
      
              
                                
                              
                            </div>
              
              
                            <div class="SaveDraftBtn text-center mt-5 pb-0">
                              <button class="BrandBtn">Cencel</button>
                           
                            </div>
              
                            
              
              
                          </div>            
                        </div>
              
              
              
                        <div class="DiscussionArea">
                    <div
                      class="DiscussionTitle"
                      style={{ backgroundColor: "#f7f7f7" }}
                    >
                      <h4>Discussion</h4>
                      <div class="DiscussionChat">
                        <img src="images/down.png" class="DiscussionIcon" />
                        <a href="#">
                          <img src="images/pdf2.png" />
                        </a>
                      </div>
                    </div>
                    <div
                      class="DiscussionHideArea"
                      style={{ display: "block" }}
                    >
                      <div class="DiscussionBox">
                        <span>
                          {iBuildLocalData?.user?.name.charAt(0) +
                            iBuildLocalData?.user?.name.charAt(1)}
                        </span>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            value={discussionState?.message}
                            onChange={(e) =>
                              updateDiscussionState({
                                ...discussionState,
                                message: e.target.value,
                              })
                            }
                            placeholder="Add a Comment ....."
                          />
                          {discussionState.discussionError &&
                            !discussionState.message && (
                              <p style={{ color: "red" }}>
                                {discussionState.discussionError}
                              </p>
                            )}

                          <div class="DiscussionOverlay">
                            <a
                              onClick={() =>
                                updateDiscussionState({
                                  ...discussionState,
                                  message: "",
                                })
                              }
                              class="CancelChat"
                            >
                              <img
                                src={require("../../assets/images/plus-cancel1.png")}
                              />
                            </a>
                            <a onClick={handleAddDiscussion} class="SendChat">
                              <img
                                src={require("../../assets/images/forwardbutton.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      {discussionList?.map((item, i) => {
                        return (
                          <>
                            <div class="DiscussionBox">
                              <span>{"NA"}</span>
                              <div class="DiscussionRight">
                                <h6>{item?.message}</h6>
                                <small>
                                  {moment(item.updatedAt).format("lll")}
                                </small>
                              </div>
                            </div>
                            <div class="ReplyArea">
                              <a
                                onClick={(e) => {
                                  updateReplyField({ flag: true, index: i });
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  });
                                }}
                              >
                                Reply
                              </a>
                              {item?.ReplyDiscussions?.map((ele) => (
                                <div class="DiscussionBox">
                                  <span>
                                    {iBuildLocalData?.user?.name.charAt(0) +
                                      iBuildLocalData?.user?.name.charAt(1)}
                                  </span>
                                  <div class="DiscussionRight">
                                    <h6>{ele?.message}</h6>
                                    <small>
                                      {moment(ele.updatedAt).format("lll")}
                                    </small>
                                  </div>
                                </div>
                              ))}

                              {replyField.index == i && (
                                <div class="DiscussionBox w-100">
                                  <span>
                                    {iBuildLocalData?.user?.name.charAt(0) +
                                      iBuildLocalData?.user?.name.charAt(1)}
                                  </span>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={replyState?.message}
                                      onChange={(e) =>
                                        updateReplyState({
                                          ...replyState,
                                          message: e.target.value,
                                          messageId: item?.id,
                                          jobId: item?.jobId,
                                        })
                                      }
                                      placeholder="Add reply ....."
                                    />
                                    {discussionState.discussionError &&
                                      !discussionState.message && (
                                        <p style={{ color: "red" }}>
                                          {discussionState.discussionError}
                                        </p>
                                      )}

                                    <div class="DiscussionOverlay">
                                      <a
                                        onClick={() =>
                                          updateReplyState({
                                            ...replyState,
                                            message: "",
                                          })
                                        }
                                        class="CancelChat"
                                      >
                                        <img
                                          src={require("../../assets/images/plus-cancel1.png")}
                                        />
                                      </a>
                                      <a
                                        onClick={handleAddReply}
                                        class="SendChat"
                                      >
                                        <img
                                          src={require("../../assets/images/forwardbutton.png")}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
            
              
                    </div>
                  </div></form>
      
      
                
              </div>
            </div>
    </div>
  </Modal>




 

  </>

  


)
}

export default ViewGenerateChangeOrder