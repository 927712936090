import React from 'react'

const BudgetChangeOrderAgreement = () => {
  return (
    <>
     <div className="FormArea mx-5">
            <div className="ModalTitleBox">
              <h4>View Budget Change Order Agreement</h4>
            </div>
            <div className="FolderForm">
{/*             
                <div className="AgreementViewArea"  ref={componentRef}>
                  <div className="AgreementViewTop">
                 
                    <div className="AgreementViewTopRight">
                    <p
                        dangerouslySetInnerHTML={createAboutMarkup()}
                        className="editor"
                      ></p>
                    </div>
                  </div>
                  <button className="RedArea">
                    Residential Real Estate Purchase Contract
                  </button>
                  <div className="ResidentialEstateArea">
                    <div className="ResidentialEstateLeft">
                      <h3>Document Number: #{agreeDeatils?.id}</h3>
                      <p>
                        <label>Date:</label> <span>{moment(agreeDeatils?.createdAt).format("YYYY-MM-DD")}</span>
                      </p>
                      <p>
                        <label>Current Working Budget:</label>{" "}
                        <span>${agreeDeatils?.workingBudget??0}</span>
                      </p>
                      <p>
                        <label>Budget Increase Amount:</label>{" "}
                        <span>${increaseAmount??0}</span>
                      </p>
                      <p>
                        <label>Cost Code:</label>{" "}
                        <span>{costCodeStatic} {costCodeStaticName}</span>
                      </p>
                      <p>
                        <label>Description:</label>{" "}
                        <span>
                        {documentDescription}
                        </span>
                      </p>
                    </div>
                    <div className="ResidentialEstateRight">
                      <p>
                        <label>Project:</label> <span>{state?.ProjectId}</span>
                      </p>
                      <p>
                        <label>Job:</label>{" "}
                        <span>{state?.jobType}</span>
                      </p>
                      <p>
  <label>Remaining Budget:</label> 
  <span>
    ${agreeDeatils?.workingBudget - agreeDeatils?.purchaseOrder - agreeDeatils?.fieldPurchaseOrder}
  </span>
</p>

                      <p>
                        <label>Reason:</label> <span>{resaonData?.description??"N/A"}</span>
                      </p>
                    </div>
                  </div>
                  <div className="AgreementViewTable Border BorderRadius">
                    <table>
                      <thead>
                        <tr className="border-bottom">
                          <th>#</th>
                          <th>
                            Material Description <br />
                            (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                          </th>
                          <th>Color</th>
                          <th>Quantity</th>
                          <th>Units</th>
                          <th>Price/Units</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          reltDoc?.VendorItems?.length>0?
                          reltDoc?.VendorItems?.map((item,i)=>{
                            return(
                              <tr key={i}>
                               <td>{item.id}</td>
                                      <td>{item.name}</td>
                                      <td>{item.color}</td>
                                      <td>{item.quantity}</td>
                                      <td>{item.Unit?.name}</td>
                                      <td>$ {item.price}</td>
                                      <td>$ {item.total}</td>
                            </tr>
                            )
                          }):"No data available"
                        }
                       
                      </tbody>
                    </table>
                  </div>
                  <div className="TableBottom2">
                    <h6>Prex-Tax Total</h6>
                    <h6>${Number(
                                      reltDoc?.VendorItems
                                        ?.reduce(
                                          (total, data) =>
                                            total + data.quantity * data.price,
                                          0
                                        )
                                        .toFixed(2) || 0
                                    )}</h6>
                  </div>
                  <div className="ChangeOrderCreatArea">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="InputBox">
                              <div className="form-group">
                              <input type="text" value={moment(new Date()).format("YYYY-MM-DD")} />
                                <label>Date</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="InputBox">
                              <div className="form-group">
                              {signature && (
                            <img
                              src={signature}
                              style={{
                                border: "1px solid gray",
                                padding: "5px",
                                marginBottom: "10px",
                                height: "190px",
                                width: "200px",
                                objectFit: "contain",
                                objectPosition: "center",
                              }}
                            />
                          )}

                          <Button
                            className="mb-4"
                            onClick={handleManageSignatueShow}
                          >
                            signature
                          </Button>
                                <label>{vendorName??""}</label>
                                <label>Builder</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="InputBox">
                              <div className="form-group">
                              <input type="text" value={moment(new Date()).format("YYYY-MM-DD")} />
                                <label>Date</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="InputBox">
                              <div className="form-group">
                              
                                <label>{builditemData?.name??""}</label>
                                <label>Investor </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button text-center">
                  <button
                    className="Outline"
                    onClick={handleGenerateAgreement}
                  >
                    Save
                  </button>
                </div> */}
        
            </div>
          </div>
    </>
  )
}

export default BudgetChangeOrderAgreement