
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import { toast } from "react-toastify";
import SignClientCtcModal from "./SignClientCtcModal";

const units = []


const CTCHistoryModal = ({ctcHistoryModal, setctcHistoryModal,updateModalState}) => {


  const [signClientCtcModal ,setSignClientCtcModal] = useState(false)


  const handleClose=()=>{

    setctcHistoryModal(false)

    updateModalState((prev)=> ({...prev,ClientDocumentationModal:true }))
    
  }


  return (
    <>

    <Modal show={ctcHistoryModal} className="ModalBox LargeModal">
    <div class="modal-content BgCreate">
      <a
        class="CloseModal"
        onClick={handleClose}
        data-dismiss="modal"
      >
        ×
      </a>
      <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>CTC History</h4>
              </div>
              <div class="JobTitleArea mb-2">
                <div class="JobBox">
                  <h6><span>Document:</span>&nbsp;Sales Contract.pdf</h6>
                </div>
              </div>
             
              <div class="TableList CommonHeading TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Modified By</th>
                      <th>Created By</th>
                      <th>Date</th>
                      <th>View</th>
                      <th>Activity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>John Doe</td>
                      <td>Jane Williams</td>
                      <td>04/25/2023</td>
                      <td>
                        <a onClick={()=>{

setctcHistoryModal(false)
                          
                          setSignClientCtcModal(true)}} class="FolderPermissionId" data-toggle="modal" data-target="#TwoSignClientCtcThird" data-dismiss="modal"><i class="fa fa-eye"></i></a>
                      </td>
                      <td>File Shared by the trade</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
    </div>
  </Modal>


  <SignClientCtcModal  signClientCtcModal={signClientCtcModal} setSignClientCtcModal={setSignClientCtcModal} setctcHistoryModal={setctcHistoryModal} />


 

  </>

  


)
}

export default CTCHistoryModal