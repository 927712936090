import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CostCodeDropdownAction,
  DocumentKeyListAction,
} from "../../redux/action/dropdownAction";
import {
  getGeneralSettingAction,
  updateGeneralSettingAction,
} from "../../redux/action/adminSalesAction";

import { toast } from "react-toastify";
import { getAllCostCodeAction } from "../../redux/action/authAction";
import { getMasterCostCategoryAction } from "../../redux/action/costProfileAction";

const initialState = {
  key: "",
  stopper: false,

  realtorCostCode: "",
  landpurchaseCostCode: "",
  hideMarginCalculation: false,
  leadCreation: false,
  costCode:"",
landCategory:"",
};

const AdminSalesIndex = () => {
  const [edit, setEdit] = useState(false);
  const [iState, updateState] = useState(initialState);

  const [landCostCodes, setLandCostCodes]= useState([])
  const [costCodes, setCostCodes] = useState([])

  const { generalSettingList } = useSelector(
    (state) => state.adminSalesReducer
  );



  const {
    key,
    realtorCostCode,
    landpurchaseCostCode,
    hideMarginCalculation,
    leadCreation,
    costCode,
    landCategory,
  } = iState;
  const dispatch = useDispatch();
  const { documentkeyList, costCodeList } = useSelector(
    (state) => state.dropdownReducer
  );

  
  const { getAllCostCodeListData } = useSelector((state) => state.authReducer);

  const { masterCostCategoryListData } = useSelector(
    (state) => state.costReducer
  );

  useEffect(() => {
    if (edit) {
      dispatch(DocumentKeyListAction());
      dispatch(CostCodeDropdownAction());
      dispatch(getMasterCostCategoryAction())
    }
  }, [edit]);

  useEffect(() => {
    if (costCode) dispatch(getAllCostCodeAction(costCode)).then((res) => {
      setCostCodes(res?.data?.data)


    });
  }, [costCode]);


  useEffect(() => {
    if (landCategory) dispatch(getAllCostCodeAction(landCategory)).then((res) => {

      setLandCostCodes(res?.data?.data)
    });
  }, [landCategory]);



  const handleSubmit = (e) => {
    e.preventDefault();
    updateState({ ...iState, stopper: true });
    let formIsValid = true;

    if (formIsValid) {
      dispatch(
        updateGeneralSettingAction({
          realtorCostCode,
          landpurchaseCostCode,
          hideMarginCalculation,
          leadCreation,
        })
      ).then((res) => {
          if (res.status === 202) {
            toast.success("Users Assigned Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            dispatch(getGeneralSettingAction());

            setEdit(false);

            // dispatch(getNestedNotificationAction(element?.id)).then((res) => {
            //   setC(res.data);
            // });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    dispatch(getGeneralSettingAction());
  }, []);

  useEffect(() => {
    updateState({
      ...iState,
      realtorCostCode: generalSettingList?.realtor?.id,
      landpurchaseCostCode: generalSettingList?.landpurchase?.id,
      leadCreation: generalSettingList?.leadCreation,
      hideMarginCalculation: generalSettingList?.hideMarginCalculation,
    });
  }, [generalSettingList]);



  console.log(costCodeList,'COST CODE')

  return (
    <div class="tab-pane fade" id="Sales">
      <div
        class="ResidentialJobArea ViewConfigArea"
        style={{ minHeight: "850px" }}
      >
        <div class="ModalAccordian">
          <div id="accordion6">
            <div class="card">
              <div class="card-header">
                <h4 data-toggle="collapse" href="#GeneralSettingsTabs2">
                  <span>
                    <img src="images/generation_information.png" />
                  </span>{" "}
                  General Settings
                </h4>
                <a
                  onClick={() => setEdit(edit ? false : true)}
                  class="Edit EditViewConfigBodyBtn"
                >
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="GeneralSettingsTabs2"
                class="collapse show"
                data-parent="#accordion6"
              >
                <div class="card-body">
                  <div
                    class="ViewConfigBodyMain"
                    style={{ display: edit ? "none" : "block" }}
                  >
                    <div class="ViewConfigBody">
                      <aside>
                        <div class="form-group">
                          <h6>Realtor Cost Code for Comissions</h6>
                          <p>
                            {generalSettingList?.realtor?.number}{" "}
                            {generalSettingList?.realtor?.name}
                          </p>
                        </div>
                        <div class="form-group">
                          <h6>Land Purchase Price Cost Code</h6>
                          <p>
                            {generalSettingList?.landpurchase?.number}{" "}
                            {generalSettingList?.landpurchase?.name}
                          </p>
                        </div>
                        <div class="form-group">
                          <h6>Hide margin calculations from Sales users</h6>
                          <p>
                            {generalSettingList?.hideMarginCalculation
                              ? "On"
                              : "Off"}
                          </p>
                        </div>
                        <div class="form-group">
                          <h6>Lead Creation from Survey</h6>
                          <p>
                            {generalSettingList?.leadCreation ? "On" : "Off"}
                          </p>
                        </div>
                        <div class="mb-3">
                          <button class="SmallRedBtn">
                            Show API to connect your Website with Lead
                          </button>
                        </div>
                        <div class="CommonModalArea">
                          <div class="form-group">
                            {/* <h5>
                              {generalSettingList?.endpoint}
                            </h5> */}
                            <div class="CommonModalBox">
                              <a href="https://ibuildstagingstorage.blob.core.windows.net/stagingblobs/1728308483071_create%20lead%20api%20documentation.pdf?sv=2023-11-03&se=2124-10-07T13%3A41%3A23Z&sr=b&sp=rade&sig=nCooVO3KWr0i0rmLuklF6f5HaCp5IxReUcciKpe0O%2FY%3D" target="_blank">
                              <h5 class="CursorPointer text-black fw-16" >
                                <figure>
                                  <img
                                    src="images/user_guide.png"
                                    class="mr-2"
                                  />
                                </figure>{" "}
                                Full Documentation
                              </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </aside>
                    </div>
                  </div>
                  <div
                    class="ViewConfigBodyHideMain"
                    style={{ display: edit ? "block" : "none" }}
                  >
                    <div class="ViewConfigBodyHide">
                      <aside>

                      <div class="form-group">
                          <h6>Cost Code Categories</h6>
                          <div class="CommonSelectBox">
                            <select
                              value={costCode}
                              onChange={(e) =>
                                updateState({
                                  ...iState,
                                  costCode: e.target.value,
                                })
                              }
                            >
                              <option value="">Select</option>
                              {masterCostCategoryListData?.data?.map((item) => (
                                <option value={item?.id}>{item?.name}</option>
                              ))}
                            </select>
                            <span>
                              <img src="images/down.png" />
                            </span>
                          </div>
                        </div>
                        <div class="form-group">
                          <h6>Realtor Cost Code for Comissions</h6>
                          <div class="CommonSelectBox">
                            <select
                              value={realtorCostCode}
                              onChange={(e) =>
                                updateState({
                                  ...iState,
                                  realtorCostCode: e.target.value,
                                })
                              }
                            >
                              <option value="">Select</option>
                              {costCodes?.map((item) => (
                                <option value={item?.id}>{item?.name}</option>
                              ))}
                            </select>
                         
                          </div>
                        </div>
                        <div class="form-group">
                          <h6>Cost Code Categories</h6>
                          <div class="CommonSelectBox">
                            <select
                              value={landCategory}
                              onChange={(e) =>
                                updateState({
                                  ...iState,
                                  landCategory: e.target.value,
                                })
                              }
                            >
                              <option value="">Select</option>
                              {masterCostCategoryListData?.data?.map((item) => (
                                <option value={item?.id}>{item?.name}</option>
                              ))}
                            </select>
                         
                          </div>
                        </div>
                        <div class="form-group">
                          <h6>Land Purchase Price Cost Code</h6>
                          <div class="CommonSelectBox">
                            <select
                              value={landpurchaseCostCode}
                              onChange={(e) =>
                                updateState({
                                  ...iState,
                                  landpurchaseCostCode: e.target.value,
                                })
                              }
                            >
                              <option value="">Select</option>

                              {landCostCodes?.map((item) => (
                                <option value={item?.id}>{item?.name}</option>
                              ))}
                            </select>
                         
                          </div>
                        </div>
                        <div class="form-group">
                          <h6>Hide margin calculations from Sales users</h6>
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked={hideMarginCalculation}
                              onChange={(e) =>
                                updateState({
                                  ...iState,
                                  hideMarginCalculation: e.target.checked,
                                })
                              }
                            />
                            <span class="slider" style={{ left: 0 }}></span>
                          </label>
                        </div>
                        <div class="form-group">
                          <h6>Lead Creation from Survey</h6>
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked={leadCreation}
                              onChange={(e) =>
                                updateState({
                                  ...iState,
                                  leadCreation: e.target.checked,
                                })
                              }
                            />
                            <span class="slider" style={{ left: 0 }}></span>
                          </label>
                        </div>
                        <div class="mb-3">
                          <button class="SmallRedBtn">
                            Show API to connect your Website with Lead
                          </button>
                        </div>
                        <div class="CommonModalArea">
                          <div class="form-group">
                            <h5>
                              {generalSettingList?.endpoint}
                            </h5>
                            <div class="CommonModalBox">
                              <a href="https://ibuildstagingstorage.blob.core.windows.net/stagingblobs/1728308483071_create%20lead%20api%20documentation.pdf?sv=2023-11-03&se=2124-10-07T13%3A41%3A23Z&sr=b&sp=rade&sig=nCooVO3KWr0i0rmLuklF6f5HaCp5IxReUcciKpe0O%2FY%3D" target="_blank">
                              <h5 class="CursorPointer text-black fw-16" >
                                <figure>
                                  <img
                                    src="images/user_guide.png"
                                    class="mr-2"
                                  />
                                </figure>{" "}
                                Full Documentation
                              </h5>
                              </a>
                            </div>
                          </div>
                        </div>
                      </aside>
                    </div>
                    <div class="Button d-flex justify-content-center">
                      <button
                        class="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                        onClick={() => setEdit(false)}
                      >
                        Cancel
                      </button>
                      <button
                        class="Respond ml-3 SaveViewConfigBodyBtn"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h4 data-toggle="collapse" href="#DocumentKeysSettings">
                  <span>
                    <img src="images/generation_information.png" />
                  </span>{" "}
                  Document Keys Settings
                </h4>
              </div>
              <div
                id="DocumentKeysSettings"
                class="collapse show"
                data-parent="#accordion6"
              >
                <div class="card-body">
                  <div class="">
                    <div class="ViewConfigBody">
                      <aside>
                        <div class="form-group">
                          <h6>Keys List</h6>
                          <div class="Categories Categories1">
                            {/* <span>Block Job</span>
                              <img src="images/down.png" /> */}

                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateState({ ...iState, key: e.target.value })
                              }
                            >
                              <option value="">Select document key</option>
                              {documentkeyList?.map((key) => (
                                <option value={key?.label}>{key?.label}</option>
                              ))}
                            </select>
                            <ul>
                              <li>
                                <input type="text" placeholder="Search" />
                                <span class="Search">
                                  <img src="images/search.png" />
                                </span>
                              </li>
                              <li>- Full list of available Document Keys</li>
                            </ul>
                          </div>
                        </div>
                      </aside>
                      <aside>
                        <div class="form-group ml-5">
                          <h6>Keys</h6>
                          <p class="GreenSignedColor">
                            <img src="images/insert_keys.png" />
                            &nbsp;&nbsp; {key}
                          </p>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSalesIndex;
