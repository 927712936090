import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { CreateUnitBlockAction, markCompleteMultiFamilyJobAction } from "../../redux/action/MultifamilyJobActionDk";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const CreateOnlyUnits = ({
    createOnlyUnitsModal,
    setCreateOnlyUnitsModal,
  jobId,
  selectedId,
  createdFrom,
}) => {
  const dispatch = useDispatch();

  const handleJobDetialsModalClose = () => {
    setCreateOnlyUnitsModal(false);
   
  };
  const handleJobDetialsModalShow = () => {
    setCreateOnlyUnitsModal(true);
  };

  const ProjectId ="";


  const [blocks, setBlocks] = useState([]);

  const [units, setUnits] =useState([])
  const [count, setCount] = useState(1);



  const handleCount = (e) => {
    setCount(e.target.value);

    let newArray;
    let localValue = count;
    let tempTotalPerson = [...units];
    let currentValue = Math.abs(localValue - e.target.value);

    if (count > e.target.value) {
      newArray = blocks.slice(0, e.target.value);
    } else {
      let tempArray = new Array(currentValue).fill({
        unitNumber: "0",
                    unitsSqFt: "0",
                    numOfBedrooms: "0",
                    numOfBathrooms: "0",
                    basementDev: false,
      });

      newArray = [...tempTotalPerson, ...tempArray];
    }
    setUnits(newArray);
  };

//   useEffect(() => {
//     let temp;

//     if (units) {
//       temp = units?.map((item) => {
//         return {
//           "blockDescription": item?.blockDescription,
//           "foundationType": item?.foundationType,
//           "numOfUnits": item?.numOfUnits,
//           "numOfStories": item?.numOfStories,
          
//           units:createdFrom=="home"?item?.MultiUnits?.map((ele)=> {return { unitNumber:ele.unitNumber ,
//             unitsSqFt: ele.unitsSqFt,
//             numOfBedrooms: ele.numOfBedrooms,
//             numOfBathrooms: ele.numOfBathrooms,
//             basementDev: false,
//           }} ): Array(+item?.numOfUnits).fill({
//             unitNumber: "0",
//             unitsSqFt: "0",
//             numOfBedrooms: "0",
//             numOfBathrooms: "0",
//             basementDev: false,
//           }),
//         };
//       });

//       setBlocks(temp);
//     }
//   }, [units]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = true;

    let tempArray = blocks
    let obj = tempArray[0]
    obj['units'] = units

    let data = {
      "numOfBlocks": blocks?.length,

      blocks: tempArray
   
      
    };

    if (formIsValid) {
      dispatch(CreateUnitBlockAction(selectedId ,jobId,data))
      .then((res) => {
          if (res.data.message == "Multi Family Job updated successfully") {
            toast.success("Multi Family Job created successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });

            setCreateOnlyUnitsModal(false);
          } else {
            setCreateOnlyUnitsModal(false);

            toast.error(res.data.message, {
              
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
            setCreateOnlyUnitsModal(false);

          toast.success(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };


  return (
    <Modal show={createOnlyUnitsModal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          class="CloseModal"
          onClick={handleJobDetialsModalClose}
          data-dismiss="modal"
        >
          ×
        </a>
        <form>

        <div class="ModalTitleBox">
              <h4>Create Multi-Family Units</h4>
            </div>
          <div class="FolderFormCreateArea">
            <div class="ModalMediumheading">
              <h3>
                <span>Project:</span> Proj-4E-SIN-1
              </h3>
              <h3>
                <span>Job id:</span> HIL-2B-376
              </h3>
            </div>
            <div class="ModalMediumheading">
              <h3>
                <span>Units Setup</span>
              </h3>
            </div>
            <div class="ModalBigheading2 d-flex align-items-center mb-3">
                    <h3 class="mb-0">Enter Number of units to be added</h3>
                    <input
                    class="form-control ModalBigheadingBox"
                    onChange={handleCount}
                  />                  </div>
            <div class="EnterProjectTable UnitSetupTable">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Block's Units #</th>
                    <th>Unit's Sq/Ft</th>
                    <th># of Bedrooms</th>
                    <th># of Bathrooms</th>
                    <th>Basement Development</th>
                  </tr>
                </thead>
                <tbody>
                    <>
                      <tr>
                        <td colspan="6">
                          <div class="Action EnterProjectTableAction">
                            <h6 class="mb-0"></h6>
                            <img src="images/block_icon.png" class="BlockImg" />
                            <div class="CommonSelectBox">
                              <select>
                                <option selected="">Select Lot Type</option>
                                <option>Lot type 1</option>
                              </select>
                              <span>
                                <img src="images/down.png" />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {units?.map((item, i) => (
                        <tr>
                          <td>Unit {i + 1}</td>
                          <td>
                            <div class="form-group">
                              <input

                              value={item?.unitNumber}
                                onChange={(e) => {
                                  let value= [...units]
                                  let temp = units[i];
                                  value[i] = {...temp,
                                    unitNumber: e.target.value,
                                  };



                                   

                                  setUnits(value);
                                }}
                                type="number"

                                class="form-control"
                                placeholder={""}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input
                                  onChange={(e) => {
                                    let value= [...units]
                                    let temp = units[i];
                                    value[i] = {...temp,
                                      unitsSqFt: e.target.value,
                                    };
  
  
  
                                     
  
                                    setUnits(value);
                                  }}
                                type="number"
                                                                value={item?.unitsSqFt}

                                class="form-control"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input
                                type="number"
                                class="form-control"
                                value={item?.numOfBedrooms}
                                onChange={(e) => {
                                  let value= [...units]
                                  let temp = units[i];
                                  value[i] = {...temp,
                                    numOfBedrooms: e.target.value,
                                  };



                                   

                                  setUnits(value);
                                }}
                                placeholder=""
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input
                                type="number"
                                                                value={item?.numOfBathrooms}

                                                                onChange={(e) => {
                                                                  let value= [...units]
                                                                  let temp = units[i];
                                                                  value[i] = {...temp,
                                                                    numOfBathrooms: e.target.value,
                                                                  };
                                
                                
                                
                                                                   
                                
                                                                  setUnits(value);
                                                                }}
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </td>
                          <td>
                            <div class="text-center">
                              <label class="CheckBox">
                                <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                </tbody>
              </table>
              <div class="PaginationArea mt-5 px-3">
                <h6>Showing 4 of 4 block entries</h6>
                <h6>
                  <img src="images/leftarrow.png" /> 01{" "}
                  <img src="images/rightarrow.png" /> Out 10
                </h6>
              </div>
            </div>
          </div>

          <div class="Button d-flex justify-content-between align-items-center px-5">
            <button
              onClick={() => {
                setCreateOnlyUnitsModal(false);
              }}
              class="Outline"
              data-toggle="modal"
              data-target="#CreateMultiFamilyJobsBlocks2"
              data-dismiss="modal"
            >
              Back
            </button>
            <button onClick={handleSubmit} class="Create">
              Create  Units
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateOnlyUnits;
