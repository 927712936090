import React from "react";
import Modal from "react-bootstrap/Modal";
import { markCompleteMultiFamilyJobAction } from "../../redux/action/MultifamilyJobActionDk";
import {toast} from "react-toastify"
import { useDispatch } from "react-redux";

const MultifamilyJobStep6 = ({
  setShow,
  MultifamilyJobStep6Modal,
  SetMultifamilyJobStep3Modal,
  SetMultifamilyJobStep1Modal,
  SetMultifamilyJobStep2Modal,
  SetMultifamilyJobStep4Modal,
  SetMultifamilyJobStep5Modal,
  SetMultifamilyJobStep6Modal,
  iState,
  UpdateState,
}) => {
  const handleMultifamilyJobStep6ModalClose = () => {
    SetMultifamilyJobStep6Modal(false);
    setShow(true);
  };
  const handleMultifamilyJobStep6ModalShow = () => {
    SetMultifamilyJobStep6Modal(true);
  };


  const dispatch = useDispatch()
  const {
    ProjectId,
    jobAddress,
    lot,
    blocks,
    phase,
    city,
    zipCode,
    country,
    subdividision,
    taxProfile,
    buildingPermit,
    homeType,
    accountSoftware,
    defaultCurrency,
    timeZone,
    costScheme,
    province,
    specificationDoc,
    baseModel,
    underParkade,
    numOfBlocks,
    numOfBuildings,
    buildings,
    itemId,
    proformaId,


  } = iState;



  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = true

    let data ={
      subdividision,
      taxProfile,
      buildingPermit,
      homeType,
      costScheme,
      province,
      specificationDoc,
      baseModel,
      defaultCurrency,
      timeZone :new Date(),


      accountSoftware,
      underParkade,
      jobAddress,
      lot,
      phase,
      city,
      zipCode,
      country,
      numOfBlocks,
      blocks,
      numOfBuildings,
      buildings,
      proformaId,
    
    }

    if (formIsValid) {
      dispatch(markCompleteMultiFamilyJobAction(ProjectId,itemId, data))
                  SetMultifamilyJobStep6Modal(false);

  
          toast.success("Multi Family Job created successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setShow(true)

          UpdateState({  ProjectId:"",
          buildingPermit: "",
          subdividision: "",
          specificationDoc: "",
          taxProfile: "",
          baseModel: "",
          homeType: "",
          defaultCurrency: "",
          costScheme: "",
          timeZone: "",
          accountSoftware: false,
          underParkade: false,
          jobAddress: "",
          lot: "",
          block: "",
          phase: "",
          city: "",
          zipCode: "",
          country: "",
          province: "",
          numOfBlocks: 0,
          blocks: [],
          numOfBuildings: 0,
        
        
          
          buildings: [],
          itemId:"",
          errorMsg: {},
        })



       
                  
       }
  };



  return (
    <Modal show={MultifamilyJobStep6Modal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          href="javascript:void(0);"
          class="CloseModal"
          onClick={handleMultifamilyJobStep6ModalClose}
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Create Multi-Family Jobs</h4>
          </div>
          <div class="StepBox">
            <ul>
              <li class="active">
                <span class="Icon">
                  <img
                    src={require("../../assets/images/step_1.png")}
                    onClick={() => {
                      SetMultifamilyJobStep1Modal(true);
                      SetMultifamilyJobStep6Modal(false);
                    }}
                  />
                </span>
                <span class="Text">Details</span>
              </li>
              <li
                class="active"
                onClick={() => {
                  SetMultifamilyJobStep2Modal(true);
                  SetMultifamilyJobStep6Modal(false);
                }}
              >
                <span class="Icon FolderPermissionId ">
                  <img src={require("../../assets/images/blocks.png")} />
                </span>
                <span class="Text">Blocks</span>
              </li>
              <li class="active">
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep3Modal(true);
                    SetMultifamilyJobStep6Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/units.png")} />
                </span>
                <span class="Text">Units </span>
              </li>
              <li className="active">
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep4Modal(true);
                    SetMultifamilyJobStep6Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_8.png")} />
                </span>
                <span class="Text">
                  Common <br /> Buildings
                </span>
              </li>
              <li className="active">
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep5Modal(true);
                    SetMultifamilyJobStep6Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_2.png")} />
                </span>
                <span class="Text">
                  Job <br /> Address
                </span>
              </li>
              <li className="active">
                <span class="Icon FolderPermissionId " data-toggle="modal">
                  <img src={require("../../assets/images/step_7.png")} />
                </span>
                <span class="Text">Confirmation</span>
              </li>
            </ul>
          </div>
          <div class="FolderForm">
            <form>
              <div class="FolderFormCreateArea">
                <div class="FieldsMandatory">
                  <h6>
                    Fields with <span class="required">*</span> are mandatory
                  </h6>
                </div>
                <div class="row">
                  <div class="form-group col-md-3">
                    <h6>Project</h6>
                    <p>Project 2</p>
                  </div>
                  <div class="form-group col-md-3">
                    <h6>Home Type</h6>
                    <p>{homeType}</p>
                  </div>
                  <div class="form-group col-md-3">
                    <h6>Shared Unit Cost Scheme</h6>
                    <p>Square Foot Percentage</p>
                  </div>
                  <div class="form-group col-md-3">
                    <h6>Subdivision</h6>
                    <p>{subdividision}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <h6>Tax Profile</h6>
                    <p>{taxProfile}</p>
                  </div>
                  <div class="form-group col-md-4">
                    <h6>Default Currency</h6>
                    <p>{defaultCurrency}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-12">
                    <h6>Job Site GMT (UTC) Timezone</h6>
                    <p>{timeZone}</p>
                  </div>
                  <div class="form-group col-md-12">
                    <h6>Integrate Job With Accounting Software</h6>
                    <p>{accountSoftware?"Yes":"No"}</p>
                  </div>
               
                  <div class="form-group col-md-12">
                    <h6>Blocks Details were skipped</h6>
                    <h6>Units Details were skipped</h6>
                    <h6>Common Buildings Details were skipped</h6>
                    <h6>Job Address Details were skipped</h6>
                  </div>
                </div>
              </div>
              <div class="Button d-flex justify-content-between align-items-center px-5">
                <button
                  class="Outline"
                  onClick={() => {
                    SetMultifamilyJobStep6Modal(false);
                    SetMultifamilyJobStep5Modal(true);
                  }}
                >
                  Back
                </button>
                <button onClick={handleSubmit} class="Create" data-toggle="modal">
                  Create 
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MultifamilyJobStep6;
