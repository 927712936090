import { vendorViewConfigAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//==========================================all-project-list================================

export const allProjectListInitiate = () => ({
  type: vendorViewConfigAction.ALL_PROJECT_LIST_INITIATE,
});

export const allProjectListSuccess = (data) => ({
  type: vendorViewConfigAction.ALL_PROJECT_LIST_SUCCESS,
  payload: data,
});

export const allProjectListFailure = (data) => ({
  type: vendorViewConfigAction.ALL_PROJECT_LIST_FAILURE,
  payload: data,
});

export const getAllProjectList = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(allProjectListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/all-project`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(allProjectListSuccess(response.data));
      } else {
        dispatch(allProjectListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(allProjectListFailure(err));
      return err.response
    }
  };

//---------------------------------GET-general---------------------------------------------------
export const getGeneralConfigurationInitiate = () => ({
    type: vendorViewConfigAction.GET_GENERAL_CONFIGURATION_INITIATE,
  });
  
  export const getGeneralConfigurationSuccess = (data) => ({
    type: vendorViewConfigAction.GET_GENERAL_CONFIGURATION_SUCCESS,
    payload: data,
  });
  
  export const getGeneralConfigurationFailure = (data) => ({
    type: vendorViewConfigAction.GET_GENERAL_CONFIGURATION_FAILURE,
    payload: data,
  });
  
  export const getGeneralConfiguration = () => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        dispatch(getGeneralConfigurationInitiate());
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view`,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(getGeneralConfigurationSuccess(response.data));
        } else {
          dispatch(getGeneralConfigurationFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getGeneralConfigurationFailure(err));
        return err.response
      }
    };

//============================update-general==================


export const updateGeneralConfigInitiate = () => ({
  type: vendorViewConfigAction.UPDATE_GENERAL_CONFIGURATION_INITIATE,
});

export const updateGeneralConfigSuccess = (data) => ({
  type: vendorViewConfigAction.UPDATE_GENERAL_CONFIGURATION_SUCCESS,
  payload: data,
});

export const updateGeneralConfigFailure = (data) => ({
  type: vendorViewConfigAction.UPDATE_GENERAL_CONFIGURATION_FAILURE,
  payload: data,
});

export const updateGeneralConfig = (viewId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(updateGeneralConfigInitiate(viewId,payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateGeneralConfigSuccess(response.data));
      } else {
        dispatch(updateGeneralConfigFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateGeneralConfigFailure(err));
      return err.response
    }
  };


//===============================GET-DESIGN-SELECTION==================

export const getDesignSelectionsInitiate = () => ({
    type: vendorViewConfigAction.GET_DESIGN_SELECTIONS_INITIATE,
  });
  
  export const getDesignSelectionsSuccess = (data) => ({
    type: vendorViewConfigAction.GET_DESIGN_SELECTIONS_SUCCESS,
    payload: data,
  });
  
  export const getDesignSelectionsFailure = (data) => ({
    type: vendorViewConfigAction.GET_DESIGN_SELECTIONS_FAILURE,
    payload: data,
  });
  
  export const getDesignSelections = (viewId) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        dispatch(getDesignSelectionsInitiate(viewId));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/design-selections`,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(getDesignSelectionsSuccess(response.data));
        } else {
          dispatch(getDesignSelectionsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getDesignSelectionsFailure(err));
        return err.response
      }
    };

//=============================design-selections---update===========

export const updateDesignSelectionsInitiate = () => ({
    type: vendorViewConfigAction.UPDATE_DESIGN_SELECTIONS_INITIATE,
  });
  
  export const updateDesignSelectionsSuccess = (data) => ({
    type: vendorViewConfigAction.UPDATE_DESIGN_SELECTIONS_SUCCESS,
    payload: data,
  });
  
  export const updateDesignSelectionsFailure = (data) => ({
    type: vendorViewConfigAction.UPDATE_DESIGN_SELECTIONS_FAILURE,
    payload: data,
  });
  
  export const updateDesignSelections = (viewId,id,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        dispatch(updateDesignSelectionsInitiate(viewId,id,payload));
        const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/design-selections/${id}`,payload,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(updateDesignSelectionsSuccess(response.data));
        } else {
          dispatch(updateDesignSelectionsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(updateDesignSelectionsFailure(err));
        return err.response
      }
    };

//====================================get-Appointments===============

export const getAppointmentsInitiate = () => ({
  type: vendorViewConfigAction.GET_APPOINTMENTS_INITIATE,
});

export const getAppointmentsSuccess = (data) => ({
  type: vendorViewConfigAction.GET_APPOINTMENTS_SUCCESS,
  payload: data,
});

export const getAppointmentsFailure = (data) => ({
  type: vendorViewConfigAction.GET_APPOINTMENTS_FAILURE,
  payload: data,
});

export const getAppointments = (viewId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(getAppointmentsInitiate(viewId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/appointments`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getAppointmentsSuccess(response.data));
      } else {
        dispatch(getAppointmentsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAppointmentsFailure(err));
      return err.response
    }
  };

//================================update-appointsment=============

export const updateAppointmentsInitiate = () => ({
  type: vendorViewConfigAction.UPDATE_APPOINTMENTS_INITIATE,
});

export const updateAppointmentsSuccess = (data) => ({
  type: vendorViewConfigAction.UPDATE_APPOINTMENTS_SUCCESS,
  payload: data,
});

export const updateAppointmentsFailure = (data) => ({
  type: vendorViewConfigAction.UPDATE_APPOINTMENTS_FAILURE,
  payload: data,
});

export const updateAppointments = (viewId,id,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(updateAppointmentsInitiate(viewId,id,payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/appointments/${id}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateAppointmentsSuccess(response.data));
      } else {
        dispatch(updateAppointmentsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateAppointmentsFailure(err));
      return err.response
    }
  };

//===============================Get-constuction=================================


export const getConstructionInitiate = () => ({
  type: vendorViewConfigAction.GET_CONSTRUCTION_INITIATE,
});

export const getConstructionSuccess = (data) => ({
  type: vendorViewConfigAction.GET_CONSTRUCTION_SUCCESS,
  payload: data,
});

export const getConstructionFailure = (data) => ({
  type: vendorViewConfigAction.GET_CONSTRUCTION_FAILURE,
  payload: data,
});

export const getConstruction = (viewId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(getConstructionInitiate(viewId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/constructions`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getConstructionSuccess(response.data));
      } else {
        dispatch(getConstructionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getConstructionFailure(err));
      return err.response
    }
  };

//====================================update-construction=======================

export const updateConstructionInitiate = () => ({
  type: vendorViewConfigAction.UPDATE_CONSTRUCTION_INITIATE,
});

export const updateConstructionSuccess = (data) => ({
  type: vendorViewConfigAction.UPDATE_CONSTRUCTION_SUCCESS,
  payload: data,
});

export const updateConstructionFailure = (data) => ({
  type: vendorViewConfigAction.UPDATE_APPOINTMENTS_FAILURE,
  payload: data,
});

export const updateConstruction = (viewId,id,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(updateAppointmentsInitiate(viewId,id,payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/constructions/${id}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateConstructionInitiate(response.data));
      } else {
        dispatch(updateConstructionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateConstructionFailure(err));
      return err.response
    }
  };
//==========================get-change-order===========================


export const getChangeOrderInitiate = () => ({
  type: vendorViewConfigAction.GET_CHANGE_ORDER_INITIATE,
});

export const getChangeOrderSuccess = (data) => ({
  type: vendorViewConfigAction.GET_CHANGE_ORDER_SUCCESS,
  payload: data,
});

export const getChangeOrderFailure = (data) => ({
  type: vendorViewConfigAction.GET_CHANGE_ORDER_FAILURE,
  payload: data,
});

export const getChangeOrder = (viewId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(getChangeOrderInitiate(viewId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/change-orders`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getChangeOrderSuccess(response.data));
      } else {
        dispatch(getChangeOrderFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getChangeOrderFailure(err));
      return err.response
    }
  };

//==========================chage-order==update===============================

export const updateChangeOrderInitiate = () => ({
  type: vendorViewConfigAction.UPDATE_CHANGE_ORDER_INITIATE,
});

export const updateChangeOrderSuccess = (data) => ({
  type: vendorViewConfigAction.UPDATE_CHANGE_ORDER_SUCCESS,
  payload: data,
});

export const updateChangeOrderFailure = (data) => ({
  type: vendorViewConfigAction.UPDATE_CHANGE_ORDER_FAILURE,
  payload: data,
});

export const updateChangeOrder = (viewId,id,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(updateChangeOrderInitiate(viewId,id,payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/change-orders/${id}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateChangeOrderSuccess(response.data));
      } else {
        dispatch(updateChangeOrderFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateChangeOrderFailure(err));
      return err.response
    }
  };
//======================GET-warranty==================

export const getWarrantyInitiate = () => ({
  type: vendorViewConfigAction.GET_WARRANTY_INITIATE,
});

export const getWarrantySuccess = (data) => ({
  type: vendorViewConfigAction.GET_WARRANTY_SUCCESS,
  payload: data,
});

export const getWarrantyFailure = (data) => ({
  type: vendorViewConfigAction.GET_WARRANTY_FAILURE,
  payload: data,
});

export const getWarranty = (viewId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(getWarrantyInitiate(viewId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/warranties`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getWarrantySuccess(response.data));
      } else {
        dispatch(getWarrantyFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getWarrantyFailure(err));
      return err.response
    }
  };
//=================================update-warranties============
export const updateWarrantyInitiate = () => ({
  type: vendorViewConfigAction.UPDATE_WARRANTY_INITIATE,
});

export const updateWarrantySuccess = (data) => ({
  type: vendorViewConfigAction.UPDATE_WARRANTY_SUCCESS,
  payload: data,
});

export const updateWarrantyFailure = (data) => ({
  type: vendorViewConfigAction.UPDATE_WARRANTY_FAILURE,
  payload: data,
});

export const updateWarranty = (viewId,id,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(updateWarrantyInitiate(viewId,id,payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/warranties/${id}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateWarrantySuccess(response.data));
      } else {
        dispatch(updateWarrantyFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateWarrantyFailure(err));
      return err.response
    }
  };

//==============================get-todo==============================

export const getTodoInitiate = () => ({
  type: vendorViewConfigAction.GET_TODO_INITIATE,
});

export const getTodoSuccess = (data) => ({
  type: vendorViewConfigAction.GET_TODO_SUCCESS,
  payload: data,
});

export const getTodoFailure = (data) => ({
  type: vendorViewConfigAction.GET_TODO_FAILURE,
  payload: data,
});

export const getTodo = (viewId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(getTodoInitiate(viewId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/todos`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getTodoSuccess(response.data));
      } else {
        dispatch(getTodoFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getTodoFailure(err));
      return err.response
    }
  }

//==================================update-todo==============================

export const updateTodoInitiate = () => ({
  type: vendorViewConfigAction.UPDATE_TODO_INITIATE,
});

export const updateTodoSuccess = (data) => ({
  type: vendorViewConfigAction.UPDATE_TODO_SUCCESS,
  payload: data,
});

export const updateTodoFailure = (data) => ({
  type: vendorViewConfigAction.UPDATE_TODO_FAILURE,
  payload: data,
});

export const updateTodo = (viewId,id,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(updateTodoInitiate(viewId,id,payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/todos/${id}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateTodoSuccess(response.data));
      } else {
        dispatch(updateTodoFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateTodoFailure(err));
      return err.response
    }
  };

//=====================get-estimation============


export const getEstimationInitiate = () => ({
  type: vendorViewConfigAction.GET_ESTIMATION_INITIATE,
});

export const getEstimationSuccess = (data) => ({
  type: vendorViewConfigAction.GET_ESTIMATION_SUCCESS,
  payload: data,
});

export const getEstimationFailure = (data) => ({
  type: vendorViewConfigAction.GET_ESTIMATION_FAILURE,
  payload: data,
});

export const getEstimation = (viewId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(getEstimationInitiate(viewId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/estimations`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getEstimationSuccess(response.data));
      } else {
        dispatch(getEstimationFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getEstimationFailure(err));
      return err.response
    }
  }

//==========================update---estimation=====================

export const updateEstimationInitiate = () => ({
  type: vendorViewConfigAction.UPDATE_ESTIMATION_INITIATE,
});

export const updateEstimationSuccess = (data) => ({
  type: vendorViewConfigAction.UPDATE_ESTIMATION_SUCCESS,
  payload: data,
});

export const updateEstimationFailure = (data) => ({
  type: vendorViewConfigAction.UPDATE_ESTIMATION_FAILURE,
  payload: data,
});

export const updateEstimation = (viewId,id,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(updateEstimationInitiate(viewId,id,payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/estimations/${id}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateEstimationSuccess(response.data));
      } else {
        dispatch(updateEstimationFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateEstimationFailure(err));
      return err.response
    }
  };

//==================================add-authorused============

export const addvendorAuthorisedInitiate = () => ({
  type: vendorViewConfigAction.ADD_VENDOR_AUTHORIZED_INITIATE,
});

export const addvendorAuthorisedSuccess = (data) => ({
  type: vendorViewConfigAction.ADD_VENDOR_AUTHORIZED_SUCCESS,
  payload: data,
});

export const addvendorAuthorisedFailure = (data) => ({
  type: vendorViewConfigAction.ADD_VENDOR_AUTHORIZED_FAILURE,
  payload: data,
});

export const addVendorAuthorised = (viewId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(addvendorAuthorisedInitiate(viewId,payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/authorized-vendor`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(addvendorAuthorisedSuccess(response.data));
      } else {
        dispatch(addvendorAuthorisedFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addvendorAuthorisedFailure(err));
      return err.response
    }
  };

//=========================================vendor-list=======================================


export const vendorAuthorisedListInitiate = () => ({
  type: vendorViewConfigAction.VENDOR_AUTHORIZED_LIST_INITIATE,
});

export const vendorAuthorisedListSuccess = (data) => ({
  type: vendorViewConfigAction.VENDOR_AUTHORIZED_LIST_SUCCESS,
  payload: data,
});

export const vendorAuthorisedListFailure = (data) => ({
  type: vendorViewConfigAction.VENDOR_AUTHORIZED_LIST_FAILURE,
  payload: data,
});

export const getVendorAuthorisedList = (viewId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(vendorAuthorisedListInitiate(viewId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/authorized-vendor-list`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(vendorAuthorisedListSuccess(response.data));
      } else {
        dispatch(vendorAuthorisedListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(vendorAuthorisedListFailure(err));
      return err.response
    }
  };

//-===============================create-vendor-assigned-jobs& projects=====================


export const createAssgnedVendorJobsProjectsInitiate = () => ({
  type: vendorViewConfigAction.CREATE_ASSGNED_VENDOR_JOBS_PROJECTS_INITIATE,
});

export const createAssgnedVendorJobsProjectsSuccess = (data) => ({
  type: vendorViewConfigAction.CREATE_ASSGNED_VENDOR_JOBS_PROJECTS_SUCCESS,
  payload: data,
});

export const createAssgnedVendorJobsProjectsFailure = (data) => ({
  type: vendorViewConfigAction.CREATE_ASSGNED_VENDOR_JOBS_PROJECTS_FAILURE,
  payload: data,
});

export const createAssgnedVendorJobsProjects = (tradeId,vendorId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(createAssgnedVendorJobsProjectsInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${tradeId}/vendors/${vendorId}/assigned-jobs`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(createAssgnedVendorJobsProjectsSuccess(response.data));
      } else {
        dispatch(createAssgnedVendorJobsProjectsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createAssgnedVendorJobsProjectsFailure(err));
      return err.response
    }
  };

//=======================get-assigned-vendor--job-project=========================


export const assgnedVendorJobsProjectsListInitiate = () => ({
  type: vendorViewConfigAction.ASSGNED_VENDOR_JOBS_PROJECTS_LIST_INITIATE,
});

export const assgnedVendorJobsProjectsListSuccess = (data) => ({
  type: vendorViewConfigAction.ASSGNED_VENDOR_JOBS_PROJECTS_LIST_SUCCESS,
  payload: data,
});

export const assgnedVendorJobsProjectsListFailure = (data) => ({
  type: vendorViewConfigAction.ASSGNED_VENDOR_JOBS_PROJECTS_LIST_FAILURE,
  payload: data,
});

export const getAssgnedVendorJobsProjectsList = (tradeId,vendorId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(assgnedVendorJobsProjectsListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${tradeId}/vendors/${vendorId}/assigned-jobs`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(assgnedVendorJobsProjectsListSuccess(response.data));
      } else {
        dispatch(assgnedVendorJobsProjectsListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(assgnedVendorJobsProjectsListFailure(err));
      return err.response
    }
  };
//====================================delete=================


export const deleteVendorJobsProjectsInitiate = () => ({
  type: vendorViewConfigAction.DELETE_ASSGNED_VENDOR_JOBS_PROJECTS_INITIATE,
});

export const deleteVendorJobsProjectsSuccess = (data) => ({
  type: vendorViewConfigAction.DELETE_ASSGNED_VENDOR_JOBS_PROJECTS_SUCCESS,
  payload: data,
});

export const deleteVendorJobsProjectsFailure = (data) => ({
  type: vendorViewConfigAction.DELETE_ASSGNED_VENDOR_JOBS_PROJECTS_FAILURE,
  payload: data,
});

export const deleteVendorJobsProjects = (tradeId,vendorId,assignId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(deleteVendorJobsProjectsInitiate());
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${tradeId}/vendors/${vendorId}/assigned-jobs/${assignId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(deleteVendorJobsProjectsSuccess(response.data));
      } else {
        dispatch(deleteVendorJobsProjectsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteVendorJobsProjectsFailure(err));
      return err.response
    }
  };

//========================enable=========================


export const enableTradeViewAccessInitiate = () => ({
  type: vendorViewConfigAction.ENABLE_TRADE_VIEW_ACCESS_INITIATE,
});

export const enableTradeViewAccessSuccess = (data) => ({
  type: vendorViewConfigAction.ENABLE_TRADE_VIEW_ACCESS_SUCCESS,
  payload: data,
});

export const enableTradeViewAccessFailure = (data) => ({
  type: vendorViewConfigAction.ENABLE_TRADE_VIEW_ACCESS_FAILURE,
  payload: data,
});

export const enableTradeViewAccess = (tradeId,vendorId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(enableTradeViewAccessInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${tradeId}/vendors/${vendorId}/enable`,{},{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(enableTradeViewAccessSuccess(response.data));
      } else {
        dispatch(enableTradeViewAccessFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(enableTradeViewAccessFailure(err));
      return err.response
    }
  };

//========================================dsable========================


export const disableTradeViewAccessInitiate = () => ({
  type: vendorViewConfigAction.ENABLE_TRADE_VIEW_ACCESS_INITIATE,
});

export const disableTradeViewAccessSuccess = (data) => ({
  type: vendorViewConfigAction.ENABLE_TRADE_VIEW_ACCESS_SUCCESS,
  payload: data,
});

export const disableTradeViewAccessFailure = (data) => ({
  type: vendorViewConfigAction.ENABLE_TRADE_VIEW_ACCESS_FAILURE,
  payload: data,
});

export const disableTradeViewAccess = (tradeId,vendorId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(disableTradeViewAccessInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${tradeId}/vendors/${vendorId}/disable`,{},{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(disableTradeViewAccessSuccess(response.data));
      } else {
        dispatch(disableTradeViewAccessFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(disableTradeViewAccessFailure(err));
      return err.response
    }
  };

//===================investor-view---genral--config==========

export const genralConfigInvestorViewInitiate = () => ({
  type: vendorViewConfigAction.GENERAL_CONFIGURATION_INVESTOR_VIEW_INITIATE,
});

export const genralConfigInvestorViewSuccess = (data) => ({
  type: vendorViewConfigAction.GENERAL_CONFIGURATION_INVESTOR_VIEW_SUCCESS,
  payload: data,
});

export const genralConfigInvestorViewFailure = (data) => ({
  type: vendorViewConfigAction.GENERAL_CONFIGURATION_INVESTOR_VIEW_FAILURE,
  payload: data,
});

export const getGenralConfigInvestorView = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(genralConfigInvestorViewInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/investor-view`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(genralConfigInvestorViewSuccess(response.data));
      } else {
        dispatch(genralConfigInvestorViewFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(genralConfigInvestorViewFailure(err));
      return err.response
    }
  };

//=================UPDate---investor==============

export const updateGenralConfigInvestorViewInitiate = () => ({
  type: vendorViewConfigAction.UPDATE_GENERAL_CONFIGURATION_INVESTOR_VIEW_INITIATE,
});

export const updateGenralConfigInvestorViewSuccess = (data) => ({
  type: vendorViewConfigAction.UPDATE_GENERAL_CONFIGURATION_INVESTOR_VIEW_SUCCESS,
  payload: data,
});

export const updateGenralConfigInvestorViewFailure = (data) => ({
  type: vendorViewConfigAction.UPDATE_GENERAL_CONFIGURATION_INVESTOR_VIEW_FAILURE,
  payload: data,
});

export const updateGeneralConfigInvestorView = (investorId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(updateGenralConfigInvestorViewInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/investor-view/${investorId}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateGenralConfigInvestorViewSuccess(response.data));
      } else {
        dispatch(updateGenralConfigInvestorViewFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateGenralConfigInvestorViewFailure(err));
      return err.response
    }
  };
//=================================ADD-investor--authorised===============


export const addInvestorVendorAuthorisedInitiate = () => ({
  type: vendorViewConfigAction.ADD_INVESTOR_VENDOR_AUTHORIZED_INITIATE,
});

export const addInvestorVendorAuthorisedSuccess = (data) => ({
  type: vendorViewConfigAction.ADD_INVESTOR_VENDOR_AUTHORIZED_SUCCESS,
  payload: data,
});

export const addInvestorVendorAuthorisedFailure = (data) => ({
  type: vendorViewConfigAction.ADD_INVESTOR_VENDOR_AUTHORIZED_FAILURE,
  payload: data,
});

export const addInvestorVendorAuthorised = (viewId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(addInvestorVendorAuthorisedInitiate(viewId,payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/investor-view/${viewId}/authorized-vendor`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(addInvestorVendorAuthorisedSuccess(response.data));
      } else {
        dispatch(addInvestorVendorAuthorisedFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addInvestorVendorAuthorisedFailure(err));
      return err.response
    }
  };

//=============================authorised--investor-list========================

export const vendorInvestorAuthorisedListInitiate = () => ({
  type: vendorViewConfigAction.VENDOR_INVESTOR_AUTHORIZED_LIST_INITIATE,
});

export const vendorInvestorAuthorisedListSuccess = (data) => ({
  type: vendorViewConfigAction.VENDOR_INVESTOR_AUTHORIZED_LIST_SUCCESS,
  payload: data,
});

export const vendorInvestorAuthorisedListFailure = (data) => ({
  type: vendorViewConfigAction.VENDOR_INVESTOR_AUTHORIZED_LIST_FAILURE,
  payload: data,
});

export const getVendorInvestorAuthorisedList = (viewId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(vendorInvestorAuthorisedListInitiate(viewId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/investor-view/${viewId}/authorized-vendor-list`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(vendorInvestorAuthorisedListSuccess(response.data));
      } else {
        dispatch(vendorInvestorAuthorisedListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(vendorInvestorAuthorisedListFailure(err));
      return err.response
    }
  };

//==============================REmove--investor============

export const removeInvestorInitiate = () => ({
  type: vendorViewConfigAction.REMOVE_INVESTOR_VENDOR_AUTHORIZED_INITIATE,
});

export const removeInvestorSuccess = (data) => ({
  type: vendorViewConfigAction.REMOVE_INVESTOR_VENDOR_AUTHORIZED_SUCCESS,
  payload: data,
});

export const removeInvestorFailure = (data) => ({
  type: vendorViewConfigAction.REMOVE_INVESTOR_VENDOR_AUTHORIZED_FAILURE,
  payload: data,
});

export const removeInvestor = (tradeId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(removeInvestorInitiate());
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${tradeId}/deleteTradeVendors`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(removeInvestorSuccess(response.data));
      } else {
        dispatch(removeInvestorFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(removeInvestorFailure(err));
      return err.response
    }
  };

//=========================enable--investor====================

export const enableInvestorVendorInitiate = () => ({
  type: vendorViewConfigAction.ENABLE_INVESTOR_VENDOR_AUTHORIZED_INITIATE,
});

export const enableInvestorVendorSuccess = (data) => ({
  type: vendorViewConfigAction.ENABLE_INVESTOR_VENDOR_AUTHORIZED_SUCCESS,
  payload: data,
});

export const enableInvestorVendorFailure = (data) => ({
  type: vendorViewConfigAction.ENABLE_INVESTOR_VENDOR_AUTHORIZED_FAILURE,
  payload: data,
});

export const enableInvestorVendor = (viewId,vendorId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(enableInvestorVendorInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/investor-view/${viewId}/vendors/${vendorId}/enable`,{},{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(enableInvestorVendorSuccess(response.data));
      } else {
        dispatch(enableInvestorVendorFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(enableInvestorVendorFailure(err));
      return err.response
    }
  };

//===============================disable----investor================


export const disableInvestorVendorInitiate = () => ({
  type: vendorViewConfigAction.DISABLE_INVESTOR_VENDOR_AUTHORIZED_INITIATE,
});

export const disableInvestorVendorSuccess = (data) => ({
  type: vendorViewConfigAction.DISABLE_INVESTOR_VENDOR_AUTHORIZED_SUCCESS,
  payload: data,
});

export const disableInvestorVendorFailure = (data) => ({
  type: vendorViewConfigAction.DISABLE_INVESTOR_VENDOR_AUTHORIZED_FAILURE,
  payload: data,
});

export const disableInvestorVendor = (viewId,vendorId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(disableInvestorVendorInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/investor-view/${viewId}/vendors/${vendorId}/disable`,{},{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(disableInvestorVendorSuccess(response.data));
      } else {
        dispatch(disableInvestorVendorFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(disableInvestorVendorFailure(err));
      return err.response
    }
  };

//====================TRADE-VIEW--VENDOR--USER-LIST====================


export const tradeViewVendorUserListInitiate = () => ({
  type: vendorViewConfigAction.TRADE_VIEW_VENDOR_USER_LIST_INITIATE,
});

export const tradeViewVendorUserListSuccess = (data) => ({
  type: vendorViewConfigAction.TRADE_VIEW_VENDOR_USER_LIST_SUCCESS,
  payload: data,
});

export const tradeViewVendorUserListFailure = (data) => ({
  type: vendorViewConfigAction.TRADE_VIEW_VENDOR_USER_LIST_FAILURE,
  payload: data,
});

export const getTradeViewVendorUserList = (viewId,vendorId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(tradeViewVendorUserListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/vendors/${vendorId}/users-list`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(tradeViewVendorUserListSuccess(response.data));
      } else {
        dispatch(tradeViewVendorUserListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(tradeViewVendorUserListFailure(err));
      return err.response
    }
  };

//=========TRADE-AACESS-REQUEST========================


export const tradeAccessRequestInitiate = () => ({
  type: vendorViewConfigAction.TRADE_ACCESS_REQUEST_INITIATE,
});

export const tradeAccessRequestSuccess = (data) => ({
  type: vendorViewConfigAction.TRADE_ACCESS_REQUEST_SUCCESS,
  payload: data,
});

export const tradeAccessRequestFailure = (data) => ({
  type: vendorViewConfigAction.TRADE_ACCESS_REQUEST_FAILURE,
  payload: data,
});

export const getTradeAccessRequest = (viewId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(tradeAccessRequestInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/trade-view/${viewId}/trade-access-request`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(tradeAccessRequestSuccess(response.data));
      } else {
        dispatch(tradeAccessRequestFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(tradeAccessRequestFailure(err));
      return err.response
    }
  };


//====================INVESTOR-VIEW--VENDOR--USER-LIST====================


export const investorViewVendorUserListInitiate = () => ({
  type: vendorViewConfigAction.INVESTOR_VIEW_VENDOR_USER_LIST_INITIATE,
});

export const investorViewVendorUserListSuccess = (data) => ({
  type: vendorViewConfigAction.INVESTOR_VIEW_VENDOR_USER_LIST_SUCCESS,
  payload: data,
});

export const investorViewVendorUserListFailure = (data) => ({
  type: vendorViewConfigAction.INVESTOR_VIEW_VENDOR_USER_LIST_FAILURE,
  payload: data,
});

export const getInvestorViewVendorUserList = (viewId,vendorId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(investorViewVendorUserListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/investor-view/${viewId}/vendors/${vendorId}/users-list`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(investorViewVendorUserListSuccess(response.data));
      } else {
        dispatch(investorViewVendorUserListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(investorViewVendorUserListFailure(err));
      return err.response
    }
  };

//===================vendor--view-access================

export const investorViewAccessInitiate = () => ({
  type: vendorViewConfigAction.INVESTOR_VIEW_ACCESS_INITIATE,
});

export const investorViewAccessSuccess = (data) => ({
  type: vendorViewConfigAction.INVESTOR_VIEW_ACCESS_SUCCESS,
  payload: data,
});

export const investorViewAccessFailure = (data) => ({
  type: vendorViewConfigAction.INVESTOR_VIEW_ACCESS_FAILURE,
  payload: data,
});

export const investorViewAccess = (viewId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(investorViewAccessInitiate());
      const response = await axios.patch(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/invester-view-access/${viewId}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(investorViewAccessSuccess(response.data));
      } else {
        dispatch(investorViewAccessFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(investorViewAccessFailure(err));
      return err.response
    }
  };