import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {
  AllbillsAccepts,
  billsAccepts,
  getBillsByIds,
  qouteRequestByVendors,
} from "../redux/action/estimationAction";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { getUnitQr } from "../redux/action/tradeViewAuthAction";
import { useSelector, useDispatch } from "react-redux";
import { sucessToast } from "../utils/toast";

const initialState = {
  itemDetails: [],
};
const EditMassMailOut = () => {
  const { unitListQr } = useSelector((state) => state.tradeViewLogin);
  const location = useLocation();
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const { itemDetails } = iState;
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1]?.split("#")[0]
  );
  const companyId = urlParams?.get("companyId");
  const documentId = urlParams?.get("documentId");
  const token = urlParams?.get("token");
  const [viewData, setViewData] = useState([]);
  useEffect(() => {
    dispatch(getBillsByIds(companyId, documentId, token)).then((res) => {
      if (res.status === 200) {
        const dummyData = res?.data?.data?.[0];
        setViewData(dummyData);
      }
    });
  }, []);

  const handleAcceptRejectPO = (type, id, statuss) => {
    const datta = { documentType: type, documentId: id, status: statuss };
    dispatch(billsAccepts(companyId, documentId, token, datta)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        dispatch(getBillsByIds(companyId, documentId, token)).then(
          (response) => {
            if (response.status === 200) {
              const dummyData = response?.data?.data?.[0];
              setViewData(dummyData);
            }
          }
        );
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleAlllBillsAccepts = () => {
    dispatch(AllbillsAccepts(companyId, documentId, token)).then((res) => {
      if (res.status === 200) {
        dispatch(getBillsByIds(companyId, documentId, token)).then(
          (response) => {
            if (response.status === 200) {
              const dummyData = response?.data?.data?.[0];
              setViewData(dummyData);
            }
          }
        );
      }
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  };

  const handlePrint = () => {
    window.print();
  };
  const [items, setItems] = useState([]);
  const [itemsModal, setItemsModal] = useState(false);
  const handleItemsModalShow = (data) => {
 
    setItemsModal(true);
    dispatch(getUnitQr(token));
    setItems(data);
    const dummyData=data?.VendorItems
    updateState({ ...iState, itemDetails:dummyData  });
  };
  const handleItemsModalClose = () => {
    setItemsModal(false);
  };


  const builderTotal =
    items?.BuilderItems?.reduce(
      (accumulator, item) => accumulator + item.total,
      0
    ) || 0;

  const copyBuilderItems = () => {
    const newMappedData =
      items?.BuilderItems?.map((item) => ({
        parentTableName: "QUOTEREQUEST",
        color: item.color || "",
        quantity: Number(item.quantity) || null,
        unitId: item.unitId || null,
        price: item.price || "",
        total: item.total || "",
        name: item.name || "",
      })) || [];

    updateState({ ...iState, itemDetails: newMappedData });
  };

  const handleClear = () => {
    updateState({ ...iState, itemDetails: [] });
  };

  const handleDetailsChange = (e, i) => {
    const { name, value } = e.target;
    let user = [...itemDetails];
    user[i] = { ...user[i], [name]: value };
    updateState({
      ...iState,
      itemDetails: user,
    });
  };

  const removeDetailsClick = (i) => {
    let user = [...itemDetails];
    user.splice(i, 1);
    updateState({
      ...iState,
      itemDetails: user,
    });
  };
  const addDetailsClick = () => {
    updateState({
      ...iState,
      itemDetails: [
        ...iState.itemDetails,
        {
          parentTableName: "QUOTEREQUEST",
          color: "",
          quantity: null,
          unitId: null,
          price: "",
          total: "",
          name: "",
        },
      ],
    });
  };

  const updateItems = async () => {
    try {
      const subtotal =
        itemDetails?.reduce(
          (total, data) => total + data.quantity * data.price,
          0
        ) || 0;
      const gst = viewData?.TaxProfile?.taxRows?.[0]?.taxPercent || 0;
      const gstTotal = (subtotal * gst) / 100;

      const additionalGST = viewData?.gst || 0;
      const additionalGSTTotal = (subtotal * additionalGST) / 100;

      const grandTotal = String(subtotal + gstTotal + additionalGSTTotal);

      const dataa = {
        itemDetails: itemDetails?.map((item) => ({
          parentTableName: "QUOTEREQUEST",
          color: item.color,
          quantity: item.quantity,
          unitId: item.unitId,
          price: item.price,
          total: String(item.quantity * item.price),
          name: item.name,
        })),
        subtotal,
        gst,
        grandTotal,
      };

      const res = await dispatch(qouteRequestByVendors(companyId,items.id,token, dataa));
      if (res.status === 200) {
        dispatch(getBillsByIds(companyId, documentId, token)).then((res) => {
          if (res.status === 200) {
            const dummyData = res?.data?.data?.[0];
            setViewData(dummyData);
          }
        });
        sucessToast(res.data.message);
        setItemsModal(false)
        updateState({ ...iState,itemDetails: [
          {
            parentTableName: "QUOTEREQUEST",
            color: "",
            quantity: null,
            unitId: null,
            price: "",
            total: "",
            name: "",
          },
        ],});
      
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <>
      <div className="FormArea  p-5">
        <div className="ModalTitleBox">
          <h4>Vendor QR/PO Mass Mailout</h4>
        </div>
        <div className="FolderForm">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between mb-5">
                <div className="Heading mb-5">
                  <h3> QR/PO Mass Mailout # {viewData?.id}</h3>
                </div>
                <div className="text-right">
                  <div className="ModalBigheading">
                    <h3 style={{ marginBottom: "10px!important" }}>
                      <span className="fw-700">Status:</span>
                      {viewData?.status}
                    </h3>
                    <h3 style={{ marginBottom: "10px!important" }}>
                      <span className="fw-700">Total Documents:</span>
                      {viewData?.totalDocuments || 0}{" "}
                    </h3>
                  </div>
                  <div className="ModalMediumheading">
                    <h3 style={{ marginBottom: "10px!important" }}>
                      <span className="Green">Completed:</span>
                      {viewData?.pendingDocuments || 0}{" "}
                    </h3>
                    <h3 style={{ marginBottom: "0px!important" }}>
                      <span className="Red">Pending:</span>
                      {viewData?.completedDocuments || 0}{" "}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-2">
          <div className="form-group">
            <div className="CommonModalArea">
              <h5>Reason</h5>
              <div className="CommonModalBox">
                <h5 className="text-black">Budget</h5>
              </div>
            </div>
          </div>
        </div> */}
            <div className="col-md-2">
              <div className="form-group">
                <h6>Sent By</h6>
                <p className="ml-0">
                  {viewData?.sendByUserDetails?.name ||
                    viewData?.sendByUserDetails?.email}
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <h6>Date Received</h6>
                <p className="ml-0">
                  {moment(viewData?.createdAt).format("YYYY-MM-DD")}
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="CommonModalArea">
                <div className="form-group">
                  <h5>Date Sent</h5>
                  <div className="CommonModalBox">
                    <p className="ml-0">
                      {moment(viewData?.sendAt).format("YYYY-MM-DD")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="form-group">
                <h6>Messsage</h6>
                <p className="ml-0">{viewData?.message}</p>
              </div>
            </div>
            <div className="col-12">
              <div className="ItemDetails">
                <h6>QR/PO Mass Mailout Items</h6>
              </div>
            </div>
            <div className="col-12">
              <div className="Tableheader mb-3">
                <h6 className="mb-0">Mass Mailout Items</h6>
                <div className="d-flex align-items-center">
                  <div className="Button" onClick={handleAlllBillsAccepts}>
                    <button className="Outline mr-3">Accept All</button>
                  </div>
                  <div className="Actions">
                    <a onClick={handlePrint}>
                      <img src="images/icon-53.png" />
                    </a>
                    <a onClick={handlePrint}>
                      <img src="images/download.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="TableList SchedulePaymentsTable TransactionTableShow TableListMaxHeight mb-4">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Job Id</th>
                      <th>Cost Code</th>
                      <th>Cost Code Description</th>
                      <th>Item Description</th>
                      <th>Document Type</th>
                      <th>Document Reason</th>
                      <th>Document #</th>
                      <th>Builder's Subtototal</th>
                      <th>Vendor's Subtototal</th>
                      <th>Document Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewData?.Categories?.length > 0
                      ? viewData?.Categories?.map((item, i) => {
                          return (
                            <>
                              <tr
                                className={`MaterialTableBgColor 
  ${
    item?.documentType === "QUOTEREQUEST"
      ? "ModalTitleBoxYellow"
      : item?.documentType === "PURCHASEORDER"
      ? "ModalTitleBoxBlue"
      : item?.documentType === "FIELDPURCHASEORDER"
      ? "ModalTitleBoxGreen"
      : "ModalTitleBoxRed"
  }`}
                              >
                                <td>{i + 1}</td>
                                <td>{item.job?.jobNumber??""}</td>
                                <td>{item.CostCode?.number}</td>
                                <td>{item.CostCode?.name}</td>
                                <td>{item.itemDescription}</td>
                                <td>{item.documentType}</td>
                                <td>{item.Reason?.description ?? "N/A"}</td>
                                <td>{item.documentCode}</td>
                                <td>${item.subtotal || 0}</td>
                                <td>${item.vendorPreTaxTotal || 0}</td>
                                <td>
                                  <div className="TextGreen">{item.status}</div>
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol
                                          className="dropdown-menu dropdown-menuwidth2"
                                          style={{}}
                                        >
                                          {item.documentType ===
                                            "QUOTEREQUEST" &&
                                            (item.status === "SEND" ||
                                              item.status === "RESEND") && (
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  className="FolderPermissionId"
                                                  onClick={() =>
                                                    handleItemsModalShow(item)
                                                  }
                                                >
                                                  <img src="images/view-po.png" />
                                                  View/Edit Document
                                                </a>
                                              </li>
                                            )}
                                          {item.documentType ===
                                            "QUOTEREQUEST" &&
                                          (item.status === "SEND" ||
                                            item.status === "RESEND") ? (
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleAcceptRejectPO(
                                                    item.documentType,
                                                    item.id,
                                                    "RETURNED"
                                                  )
                                                }
                                                className="FolderPermissionId"
                                              >
                                                <img src="images/approve_create.png" />
                                                Approve
                                              </a>
                                            </li>
                                          ) : (
                                            (item.status === "SEND" ||
                                              item.status === "RESEND") && (
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleAcceptRejectPO(
                                                      item.documentType,
                                                      item.id,
                                                      "ACCEPTED"
                                                    )
                                                  }
                                                  className="FolderPermissionId"
                                                >
                                                  <img src="images/approve_create.png" />
                                                  Approve
                                                </a>
                                              </li>
                                            )
                                          )}

                                          {item.documentType ===
                                            "QUOTEREQUEST" &&
                                          (item.status === "SEND" ||
                                            item.status === "RESEND") ? (
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleAcceptRejectPO(
                                                    item.documentType,
                                                    item.id,
                                                    "CANCELLED"
                                                  )
                                                }
                                                className="FolderPermissionId"
                                              >
                                                <img src="images/approve_create.png" />
                                                Reject
                                              </a>
                                            </li>
                                          ) : (
                                            (item.status === "SEND" ||
                                              item.status === "RESEND") && (
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleAcceptRejectPO(
                                                      item.documentType,
                                                      item.id,
                                                      "REJECTED"
                                                    )
                                                  }
                                                  className="FolderPermissionId"
                                                >
                                                  <img src="images/approve_create.png" />
                                                  Reject
                                                </a>
                                              </li>
                                            )
                                          )}
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr className="MaterialTableBgColor">
                                <td colSpan={5}>
                                  <div className="TableList CommonHeadingBlack CommonHeadingBlack2">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>
                                            <div className="text-center">
                                              Material Description
                                              <br />
                                              (i.e: Modal/Type/Style/manuf
                                              part#/Dimensions, etc.)
                                            </div>
                                          </th>
                                          <th>Color</th>
                                          <th>Quantity</th>
                                          <th>Units</th>
                                          <th>Price/Unit</th>
                                          <th>Total</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item?.VendorItems?.length > 0 ? (
                                          item?.VendorItems?.map(
                                            (data, index) => {
                                              return (
                                                <tr className="border-0" key={index}>
                                                 <td>{data.id}</td>
                                                  <td>
                                                    <div className="text-center">
                                                      {data.name}
                                                    </div>
                                                  </td>
                                                  <td>{data.color}</td>
                                                  <td>{data.quantity}</td>
                                                  <td>
                                                    {data.Unit?.name ?? "N/A"}
                                                  </td>
                                                  <td>$ {data.price}</td>
                                                  <td>$ {data.total}</td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="10"
                                              style={{ textAlign: "center" }}
                                            >
                                              No data found
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                                <td colSpan={7} />
                              </tr>
                            </>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-md-4 ml-auto">
                  <div className="GstTable">
                    <table>
                      <tbody>
                        <tr>
                          <td>Profile</td>
                          <td>{viewData?.TaxProfile?.name}</td>
                        </tr>
                        <tr>
                          <td>Subtotal</td>
                          <td>${viewData?.subTotal || 0}</td>
                        </tr>

                        <tr>
                          <td>
                            {viewData?.TaxProfile?.taxRows?.[0]?.taxName}(
                            {viewData?.TaxProfile?.taxRows?.[0]?.taxPercent}%)
                          </td>
                          <td>
                            $
                            {(viewData?.subTotal *
                              viewData?.TaxProfile?.taxRows?.[0]?.taxPercent) /
                              100}
                          </td>
                        </tr>

                        <tr>
                          <td>Grand Total</td>
                          <td>${viewData?.total || 0}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="mb-4">
                <div className="Heading">
                  <h6 className="text-black">Attachments</h6>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="CommonModalArea">
                <div className="form-group">
                  <h5>Scope of Work</h5>
                  <div className="CommonModalBox">
                    <h5
                      className="CursorPointer FolderPermissionId"
                      data-toggle="modal"
                      data-target="#SelectScopesOfWork12"
                      data-dismiss="modal"
                    >
                      <figure>
                        <img src="images/scopeofwork.png" />
                      </figure>{" "}
                      Scope of Work
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="CommonModalArea">
                <div className="form-group">
                  <h5>Construction Documents</h5>
                  <div className="CommonModalBox">
                    <h5
                      className="CursorPointer FolderPermissionId"
                      data-toggle="modal"
                      data-target="#SelectConstructionDocuments12"
                      data-dismiss="modal"
                    >
                      <figure>
                        <img src="images/construction_documents.png" />
                      </figure>{" "}
                      Construction Documents
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="CommonModalArea">
                <div className="form-group">
                  <h5>Additional Documents</h5>
                  <div className="CommonModalBox">
                    <h5
                      className="CursorPointer FolderPermissionId"
                      data-toggle="modal"
                      data-target="#UploadAdditionalDocumentsUnder12"
                      data-dismiss="modal"
                    >
                      <figure>
                        <img src="images/upload_additional.png" />
                      </figure>{" "}
                      Additional Documents
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Button d-flex justify-content-between mt-4 ">
            <button className="Create" onClick={handlePrint}>
              Print
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={itemsModal}
        onHide={handleItemsModalClose}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleItemsModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View/Edit Items</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalMediumheading">
                <h3>QR/PO Mass Mailout # {viewData?.id}</h3>
                {/* <h3>QR/PO Mass Mailout Category # 1</h3> */}
              </div>
              <div className="ItemDetails">
                <h6>Item Details</h6>
              </div>
              <div className="Tableheader mb-3">
                <div>
                  <h6 className="mb-0">Builder</h6>
                  <h6 className="mb-0">Item</h6>
                </div>
                <div className="Actions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                 
                </div>
              </div>
              <div className="TableList CommonHeadingBlack mb-0">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        <div className="text-center">
                          Material Description
                          <br />
                          (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                        </div>
                      </th>
                      <th>Color</th>
                      <th>Quantity</th>
                      <th>Units</th>
                      <th>Price/Unit</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items?.BuilderItems?.length > 0 ? (
                      items?.BuilderItems?.map((data, i) => {
                        return (
                          <tr>
                            <td>{data.id}</td>
                            <td>{data.name}</td>
                            <td>{data.color}</td>
                            <td>{data.quantity}</td>
                            <td>{data.Unit?.name ?? "N/A"}</td>
                            <td>{data.price}</td>
                            <td>{data.total}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="TableBottom mb-2">
                <h6>
                  Builder's Prex-Tax Total: <span> ${builderTotal}</span>
                </h6>
              </div>
              <div className="Tableheader mb-3">
                <div>
                  <h6 className="mb-3">Vendor</h6>
                  <h6 className="mb-0">Item</h6>
                </div>
                <div className="Actions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                 
                </div>
              </div>
              <div className="TableList CommonHeadingBlack ">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        <div className="text-center">
                          Material Description
                          <br />
                          (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                        </div>
                      </th>
                      <th>Color</th>
                      <th>Quantity</th>
                      <th>Units</th>
                      <th>Price/Unit</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemDetails?.length > 0
                      ? itemDetails?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{data.id}</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  value={data.name}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="color"
                                  value={data.color}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="quantity"
                                  value={data.quantity}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                />
                              </td>
                              <td>
                              <select
                                  className="form-control"
                                  name="unitId"
                                  value={data.unitId}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                >
                                  <option value="">Select</option>
                                  {unitListQr?.map((item, i) => (
                                    <option value={item.id} key={i}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <input type="number" className="form-control"  name="price"
                                  value={data.price}
                             
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }/>
                              </td>
                              <td>
                                <input type="text" className="form-control"  value={
                                    (data.quantity * data.price).toFixed(2) ?? 0
                                  }
                                  readOnly/>
                              </td>
                              <td>
                                <div className="TableThreeDots">
                                  <ul className="">
                                    <li className="dropdown">
                                      <a
                                        className=""
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" />
                                      </a>
                                      <ol className="dropdown-menu dropdown-menuwidth">
                                       
                                        <li>
                                          <a  onClick={() =>
                                            removeDetailsClick(index)
                                          }>
                                            <img src="images/dropdown-delete.png" />
                                            Remove Line
                                          </a>
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : ""}

                    <tr>
                      <td colSpan={8}>
                        <div className="TableButtons">
                          <button className="BrandBtn" onClick={addDetailsClick}>Add Line</button>
                          <button
                            className="BrandBtn ml-2"
                            onClick={handleClear}
                          >
                            Clear all lines
                          </button>
                          <button
                            className="BrandBtn ml-2"
                            onClick={copyBuilderItems}
                          >
                            {" "}
                            <span>
                              <img src="images/copy_builder_item.png" />
                            </span>{" "}
                            Copy Builder's Items
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="Button d-flex justify-content-center mt-5">
                <button
                  className="Create mr-5"
                 onClick={updateItems}
                >
                  Update Items
                </button>
                <button
                  className="Outline ml-5"
                  onClick={handleItemsModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditMassMailOut;
