import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { createProjectAsEntityAction } from "../../redux/action/projectActionDk";
import { useDispatch } from "react-redux";
import { zip } from "lodash";
import { useSelector } from "react-redux";

const ProjectAsEntityStep8 = ({
  iState,
  UpateState,
  ProjectAsEntityStep8Modal,
  SetProjectAsEntityStep1Modal,
  SetProjectAsEntityStep2Modal,
  SetProjectAsEntityStep3Modal,
  SetProjectAsEntityStep4Modal,
  SetProjectAsEntityStep5Modal,
  SetProjectAsEntityStep6Modal,
  SetProjectAsEntityStep7Modal,
  SetProjectAsEntityStep8Modal,
  setShow,
}) => {
  const handleProjectAsEntityStep8ModalClose = () => {
    setShow(true);
    SetProjectAsEntityStep8Modal(false);
  };
  const handleProjectAsEntityStep1ModalShow = () => {
    SetProjectAsEntityStep8Modal(true);
  };

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));



  
  const { taxProfileListData } = useSelector(
    (state) => state.jobReducer
  );


  const dispatch = useDispatch();

  const {
    CompanyId,
    projectEmail,
    projectName,
    projectAddress,
    city,
    country,
    zipCode,
    province,
    projectType,
    creationType,
    subdivisions,

    taxProfile,
    useCompanyPattern,
    jobPattern,
    profileCategories,

    loginUrl,
    projectLogo,
    products,
    Subscription,

    contractCode,
    billingOption,
    PlanId,
    accoTransSett,
    useCompFiscalYearEnd,
    fiscalYearEnd,
    useCompTaxRegistration,

    taxRegistration,

    useTaxProfiles,
    costCodeStructure,
    commWorkflows,
    designSettings,
    jobModels,
    quickBooks,
    sage,
    proformaId,
    ctcMaster,
    fileManager,
    priceBook,
    vendorList,
    developer,

    selAccoSoft,
  } = iState;

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {

      // CompanyId :iBuildLocalData?.user?.CompanyId,
      projectEmail,
      projectName,
      projectAddress,
      city,
      country,
      zipCode,
      province,
      projectType,
      creationType,
      subdivisions,

      taxProfile:1,
      useCompanyPattern,
      jobPattern,

      projectLogo,
      products,

      contractCode,
      billingOption,
      PlanId:1,
      accoTransSett,
      useCompFiscalYearEnd,
      fiscalYearEnd,
      useCompTaxRegistration,

      taxRegistration,

      useTaxProfiles,
      costCodeStructure,
      commWorkflows,
      designSettings,
      jobModels,
      quickBooks,
      sage,
      proformaId,

    };

    dispatch(createProjectAsEntityAction(data))
      .then((res) => {
        if (res.status == 201) {
          console.log(res, "RRRRRRRRRR");
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setShow(true);
          SetProjectAsEntityStep8Modal(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <Modal
        show={ProjectAsEntityStep8Modal}
        className="ModalBox LargeModal BgClass"
      >
        <div>
          <a
            onClick={handleProjectAsEntityStep8ModalClose}
            class="CloseModal"
            data-toggle="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create New Project as an Entity</h4>
            </div>
            <div class="StepBox">
              <ul>
                <li class="active">
                  <span
                    class="Icon"
                    onClick={() => {
                      SetProjectAsEntityStep1Modal(true);
                      SetProjectAsEntityStep8Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_1.png")} />
                  </span>
                  <span class="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    onClick={() => {
                      SetProjectAsEntityStep2Modal(true);
                      SetProjectAsEntityStep8Modal(false);
                    }}
                    class="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityAddress"
                    data-dismiss="modal"
                  >
                    <img src={require("../../assets/images/step_2.png")} />
                  </span>
                  <span class="Text">Address</span>
                </li>
                <li className="active">
                  <span
                    onClick={() => {
                      SetProjectAsEntityStep3Modal(true);
                      SetProjectAsEntityStep8Modal(false);
                    }}
                    class="Icon FolderPermissioId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityType"
                    data-dismiss="modal"
                  >
                    <img src={require("../../assets/images/step_8.png")} />
                  </span>
                  <span class="Text">Type</span>
                </li>
                <li className="active">
                  <span
                    onClick={() => {
                      SetProjectAsEntityStep4Modal(true);
                      SetProjectAsEntityStep8Modal(false);
                    }}
                    class="Icon FolderPermissioId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntitySubscription"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../../assets/images/subscription_step.png")}
                    />
                  </span>
                  <span class="Text">Subscription</span>
                </li>
                <li className="active">
                  <span
                    onClick={() => {
                      SetProjectAsEntityStep5Modal(true);
                      SetProjectAsEntityStep8Modal(false);
                    }}
                    class="Icon FolderPermissioId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityTax"
                    data-dismiss="modal"
                  >
                    <img src={require("../../assets/images/step_3.png")} />
                  </span>
                  <span class="Text">
                    Accounting <br /> &amp; Tax
                  </span>
                </li>
                <li className="active">
                  <span
                    onClick={() => {
                      SetProjectAsEntityStep6Modal(true);
                      SetProjectAsEntityStep8Modal(false);
                    }}
                    class="Icon FolderPermissioId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityNumber"
                    data-dismiss="modal"
                  >
                    <img src={require("../../assets/images/step_5.png")} />
                  </span>
                  <span class="Text">Job Number</span>
                </li>
                <li className="active">
                  <span
                    onClick={() => {
                      SetProjectAsEntityStep7Modal(true);
                      SetProjectAsEntityStep8Modal(false);
                    }}
                    class="Icon FolderPermissioId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityInitial"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../../assets/images/initial_settings.png")}
                    />
                  </span>
                  <span class="Text">
                    Initial <br />
                    Settings
                  </span>
                </li>
                <li className="active">
                  <span
                    class="Icon FolderPermissioId"
                    data-toggle="modal"
                    data-target="#CreateNewProjectAsAnEntityConfirmation"
                    data-dismiss="modal"
                  >
                    <img src={require("../../assets/images/step_7.png")} />
                  </span>
                  <span class="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div class="FolderForm">
              <form>
                <div class="FolderFormCreateArea">
                  <div class="FieldsMandatory">
                    <h6>
                      Fields with <span class="required">*</span> are mandatory
                    </h6>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-2">
                      <h6>Company</h6>
                      <p>{iBuildLocalData?.companies?.[0]?.name}</p>
                    </div>
                    <div class="form-group col-md-2">
                      <h6>Project Name</h6>
                      <p>{projectName}</p>
                    </div>
                    <div class="form-group col-md-3">
                      <h6>Project Email</h6>
                      <p>{projectEmail}</p>
                    </div>
                    <div class="form-group col-md-3">
                      <h6>Login URL Shorthand</h6>
                      <p>{loginUrl}</p>
                    </div>
                    <div class="form-group col-md-2">
                      <h6>Project Logo</h6>
                      <img src={projectLogo} />
                    </div>
                    <div class="form-group col-md-3">
                      <h6>Project Type</h6>
                      <p>{projectType}</p>
                    </div>
                    <div class="form-group col-md-3">
                      <h6>Creation Type</h6>
                      <p>{creationType}</p>
                    </div>
                    <div class="form-group col-md-3">
                      <h6>Job Pattern</h6>
                      <p>{jobPattern}</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="SubdivisionsArea col-md-3">
                      <h6>Subdividisions</h6>
                      <div class="SubdivisionsBox">
                        <div class="form-group mb-0">
                          <h6>Subdivision</h6>
                          {subdivisions?.map((item)=>
                          <p>{item}</p>
                          
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <h6>Developer</h6>
                      <p>{developer}</p>
                    </div>
                    <div class="form-group col-md-3">
                      <h6>Tax Registration #</h6>
                      <p>{taxProfileListData?.[0]?.taxRows?.[0]?.taxName}</p>
                    </div>
                    <div class="form-group col-md-3">
                      <h6>Tax Profiles</h6>
                      <p>{taxProfileListData?.find((tax)=> tax?.id ==taxProfile)?.name}</p>
                    </div>
                    <div class="form-group col-md-4">
                      <h6>Project Address</h6>
                      <p>{projectAddress}</p>
                    </div>
                    <div class="form-group col-md-4">
                      <h6>City</h6>
                      <p>{city}</p>
                    </div>
                    <div class="form-group col-md-4">
                      <h6>State/Province</h6>
                      <p>{province}</p>
                    </div>
                    <div class="form-group col-md-4">
                      <h6>Zip/ Postal Code</h6>
                      <p>{zipCode}</p>
                    </div>
                    <div class="form-group col-md-4">
                      <h6>Country</h6>
                      <p>{country}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-3">
                      <h6>Subscription</h6>
                      <p>{Subscription}</p>
                    </div>
                    <div class="form-group col-md-3">
                      <h6>Billing Option</h6>
                      <p>{billingOption}</p>
                    </div>
                    <div class="form-group col-md-3">
                      <h6>Contract Code</h6>
                      <p>{contractCode}</p>
                    </div>
                    <div class="form-group col-md-3">
                      <h6>Accounting Software</h6>
                      <p>Quickbooks</p>
                    </div>
                    <div class="form-group col-md-12">
                      <h6>Initial Settings</h6>
                      <p>
                       {accoTransSett&& "Accounting Transaction Settings ,"}
                       {costCodeStructure&&"Cost Code Structure ,"} 
                       {designSettings &&"Design Settings Templates ,"}
                       {jobModels && "Job Models ,"}
                       {commWorkflows && "Communication Workflows ,"}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="Button d-flex justify-content-between align-items-center px-5">
                  <button
                    class="Outline"
                    data-dismiss="modal"
                    onClick={() => {
                      SetProjectAsEntityStep8Modal(false);
                      SetProjectAsEntityStep7Modal(true);
                    }}
                  >
                    Back
                  </button>
                  <button class="Create FolderPermissionId" data-toggle="modal" onClick={handleSubmit}>
                    Create Project
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProjectAsEntityStep8;
