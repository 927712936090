import React, { useEffect ,useState} from 'react'
import Modal from "react-bootstrap/Modal";
import { DocumentKeyListAction } from '../../../redux/action/dropdownAction';
import { useSelector, useDispatch } from 'react-redux';

const BellEdit = ({BellEditModal,SetBellEditModal, element}) => {



  const initialState = {
    keys: [],
    keysName: [],
    message: "",
    stopper: false,
    userId: "",
    reminder: "2023-01-02",
    assignVendor: true,
    itineraryType: "any",
    includeVendor: false,
    includeClient: false,
    errors: {},
  };
  const { documentkeyList } = useSelector((state) => state.dropdownReducer);
  const dispatch = useDispatch()



  const [iState, updateState] = useState(initialState);
  const {
    includeClient,
    includeVendor,
    assignVendor,
    itineraryType,
    keys,
    keysName,
    userId,
    message,
    errors,
  } = iState;

  
    const handleBellEditModalClose = () => {
        SetBellEditModal(false);
    
    
      };



      useEffect(() => {
        if (BellEditModal) {
          dispatch(DocumentKeyListAction());
        }
      }, [BellEditModal]);
  return (
<Modal show={BellEditModal} className="ModalBox SmallModal BgClass">
    <a
      onClick={handleBellEditModalClose}
      class="CloseModal ClosePermissionId"
    >
      ×
    </a>
    <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Edit Notification</h4>
              </div>
              <div class="FolderForm">
                <form>
                   <div class="form-group">
                    <h6>Event</h6>
                    <h6 class="fw-400 fw-14">{element?.eventName}</h6>
                    </div>
                   <div class="form-group">
                    <h6>Notification Type</h6>
                    <p class="ml-0">Bell Icon</p>
                   </div>
                   <div class="form-group">
                    <h6>Assign Users</h6>
                   </div>
                   <div class="form-group">
                    <h6>Assign User Roles</h6>
                    <p class="ml-0">Estimator</p>
                   </div>
                   <div class="form-group">
                    <h6>Originating User Only</h6>
                    <p class="ml-0">On</p>
                   </div>
                   <div class="row">
                    <div class="col-md-7">
                      <div>
                        <h6 class="fw-20 fw-600">Notification Message</h6>
                      </div>
                      <div class="form-group">
                        <h6>Text</h6>
                        <h6>Insert keys <span class="ml-3"><img src="images/insert_key_24.png" /></span> </h6>
                        <div class="CommonSelectBox w-100">
                         <select>
                           <option selected="">Select document key</option>
                           {documentkeyList?.map((key) => (
                        <option value={key?.id}>{key?.label}</option>
                      ))}
                         </select>
                       </div>
                       <div class="Button my-3">
                        <button class="Create px-3">Add Key</button>
                       </div>
                       <input type="text" class="form-control" placeholder="Quoted" />
                       </div>
                     </div>
                   </div>
                    <div class="Button d-flex justify-content-betweens mt-5">
                      <button class="Outline">Save</button>
                      <button class="Outline">Cancel</button>
                    </div>
                </form>
              </div>
            </div>
  </Modal>   )
}

export default BellEdit