import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import SignaturePad from "react-signature-canvas";
import { convertToPNG, dataURLtoBlob } from "../utils/blog";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addSignInTemplated,
  getConverToString,
  getSalesTemplateByIds,
} from "../redux/action/saleAction";
import moment from "moment";

const initialState = {
  primarySignature: null,
  primarySignatureBlob: "",
  ManageSignature: false,
  signature: "",
};

const ClienTDocsSign = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const signCanvas = useRef({});
  const [clientData, setClientData] = useState([]);
  const [headerData, setHeaderData] = useState("");
  const [iState, updateState] = useState(initialState);
  const [isMatch,setIsMatch] = useState(null)
  const { primarySignature, primarySignatureBlob, ManageSignature, signature } =
    iState;
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1].split("#")[0]
  );
  const companyId = urlParams?.get("companyId");
  const documentId = urlParams?.get("documentId");
  const token = urlParams?.get("token");
  const userType = urlParams?.get("userType");
  const userId = urlParams?.get("userId");
  

  useEffect(() => {
    if (token) {
      dispatch(getSalesTemplateByIds(documentId, companyId, token)).then(
        (res) => {
          if (res.status === 200) {
            const foundSignee = res?.data?.data?.ClientDocAssignees?.find(signee => signee.signeeType === userType);
            setIsMatch(foundSignee)
            setClientData(res?.data); 
            const filename = res?.data?.data?.htmlBlobName?.split("?")[0];
            dispatch(getConverToString(filename, token)).then((response) => {
              if (response.status === 200) {
                setHeaderData(response?.data?.data);
              }
            });
          }
        }
      );
    }
  }, [documentId, token]);

  const handleManageSignatueShow = () => {
    updateState({
      ...iState,
      ManageSignature: true,
      primarySignature: null,
    });
  };
  const handleManageSignatueClose = () => {
    updateState({
      ...iState,
      ManageSignature: false,
      signature: "",
      signTypes: "",
    });
  };

  const addSignature = () => {
    const signatureBlob = dataURLtoBlob(signature);
    const convertToValidFormat = (blob, fileName) => {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (
        ![
          "jpg",
          "jpeg",
          "png",
          "gif",
          "pdf",
          "bmp",
          "webp",
          "svg+xml",
        ].includes(fileExtension)
      ) {
        const convertedBlob = convertToPNG(blob);
        return convertedBlob;
      }
      return blob;
    };
    const validSignatureBlob = convertToValidFormat(
      signatureBlob,
      "signature.png"
    );
    const validSignatureBlobURL = URL.createObjectURL(validSignatureBlob);
    updateState({
      ...iState,
      primarySignature: validSignatureBlobURL,
      primarySignatureBlob: validSignatureBlob,
      ManageSignature: false,
    });
  };
  const clearSignature = () => {
    signCanvas.current.clear();
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append("signeeId", userId);
    formData.append("isSigned", true);
    formData.append("signeeType", isMatch?.signeeType);
    formData.append("file", primarySignatureBlob);
    dispatch(addSignInTemplated(companyId, documentId, formData, token)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getSalesTemplateByIds(documentId, companyId, token)).then(
            (res) => {
              if (res.status === 200) {
                const foundSignee = res?.data?.data?.ClientDocAssignees?.find(signee => signee.signeeType === userType);
            setIsMatch(foundSignee)
            setClientData(res?.data); 
            const filename = res?.data?.data?.htmlBlobName?.split("?")[0];
            dispatch(getConverToString(filename, token)).then((response) => {
              if (response.status === 200) {
                setHeaderData(response?.data?.data);
              }
            });
              }
            }
          );
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState });
        }
      }
    );
  };

  return (
    <>
      <div className="FormArea p-5">
        <div className="ModalTitleBox">
          <h4>View Document</h4>
        </div>
        <div className="FolderForm">
          <div className="AgreementViewArea">
            <div className="AgreementViewTop">
              <div className="AgreementViewTopRight">
                {clientData && (
                  <p
                    dangerouslySetInnerHTML={{ __html: headerData }}
                    className="editor"
                  ></p>
                )}
              </div>
            </div>
            <div className="ChangeOrderCreatArea mt-5">
              {clientData?.data?.ClientDocAssignees?.length > 0 &&
                clientData?.data?.ClientDocAssignees?.map((item, i) => {
                  return (
                    <div className="row">
                      <div className="col-md-4">
                        <div className="InputBox">
                          <div className="form-group">
                            <input
                              type="date"
                              disabled
                              value={moment(new Date(item?.updatedAt)).format(
                                "YYYY-MM-DD"
                              )}
                            />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ml-auto">
                        <div className="InputBox">
                          <div className="form-group">
                            <div className="InsertSignature">
                              {!item.isSigned &&
                              userType === item.signeeType
                               ? (
                                <a onClick={() => handleManageSignatueShow()}>
                                  <img
                                    src={signature?signature:"images/insert_signature.png"}
                                    alt="Insert Signature"
                                  />
                                  Insert Signature
                                </a>
                              ) : item.isSigned&& (
                                <a>
                                  <img src={item.signUrl} alt="Signature" />
                                  Signature Inserted
                                </a>
                              ) }
                            </div>

                            <label>{item.signeeType}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="Button d-flex justify-content-center mt-3 mb-3">
             {
              !isMatch?.isSigned ?
              <button
              className="Create FolderPermissionId mb-3"
              onClick={handleSave}
            >
              Save
            </button>:
            <h4>An Action has performed</h4>
            } 
           
          </div>
        </div>
      </div>
      <Modal
        show={ManageSignature}
        onHide={handleManageSignatueClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handleManageSignatueClose}>
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new signature</h5>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea border p-2">
                  <h5>Signatures</h5>
                  <div className="CommonModalBox">
                    <SignaturePad
                      ref={signCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        updateState({
                          ...iState,
                          signature: signCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearSignature}>
                      <img src="images/circle_minus.png" />
                    </a>
                    {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                    {errors?.signatureError}
                  </span> */}
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addSignature}>
                  Save
                </button>
                <button className="Outline" onClick={handleManageSignatueClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClienTDocsSign;
