import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  addInvestorVendorAuthorised,
  addVendorAuthorised,
  createAssgnedVendorJobsProjects,
  deleteVendorJobsProjects,
  disableInvestorVendor,
  disableTradeViewAccess,
  enableInvestorVendor,
  enableTradeViewAccess,
  getAllProjectList,
  getAppointments,
  getAssgnedVendorJobsProjectsList,
  getChangeOrder,
  getConstruction,
  getCountryList,
  getDesignSelections,
  getEstimation,
  getGeneralConfiguration,
  getGenralConfigInvestorView,
  getInvestorViewVendorUserList,
  getTodo,
  getTradeAccessRequest,
  getTradeViewVendorUserList,
  getVendorAuthorisedList,
  getVendorInvestorAuthorisedList,
  getWarranty,
  investorViewAccess,
  removeInvestor,
  updateAppointments,
  updateChangeOrder,
  updateConstruction,
  updateDesignSelections,
  updateEstimation,
  updateGeneralConfig,
  updateGeneralConfigInvestorView,
  updateTodo,
  updateWarranty,
} from "../redux/action/vendorViewConfigAction";
import {
  getListOfVendorTypes,
  getListProjectJobs,
  getVendorList,
} from "../redux/action/warrantyAction";
import { getCountriesList, getStateList } from "../redux/action/authAction";
import moment from "moment";
import SmsInfo from "./SmsInfo";

const initialState = {
  viewConfigId: "",
  designPriceShow: true,
  designNotificationShow: true,
  builderPriceUpdate: true,
  discountUpdate: true,
  newSelectionSentBuilder: true,
  selectionCanceled: true,
  selectionDeadlineDateApro: true,
  clientMissedSelectionDeadline: true,

  appointmentCalenderShow: true,
  appointmentNotificationsShow: true,
  displayCalendarHomepage: true,
  displayPastAppointments: true,
  newAppointment: true,
  appointmentCancelled: true,
  appointmentUpdate: true,
  newDateSelectionEvent: true,

  constructionAppShow: true,
  constructionAppsShow: true,
  constructionNotificationsShow: true,
  disConstScheTasksHomepage: true,
  vendorDocumentDownload: true,
  vendorDocumentShare: true,
  constructionDocuments: true,
  newDocumentAvailable: true,
  vendorSignaturePending: true,
  builderSignatureCompleted: true,
  newScheduleTaskAssigned: true,
  assignedScheTaskPenConfi: true,
  scheduleTaskCompleted: true,
  scheduleTaskCancelled: true,

  changeOrderChecklistShow: true,
  changeOrderNotificationShow: true,
  displayChangeOrderHomepage: true,
  newChangeOrder: true,
  clientApprovedChangeOrder: true,
  clientRejectedChangeOrder: true,

  warrantyWorkOrderShow: true,
  warrantyNotificationShow: true,
  displayWarrWorkOrdHomepage: true,
  newWorkOrder: true,
  newTradesDaySelection: true,
  tradesDayConfirmation: true,
  tradesDayupdate: true,
  workOrderupdate: true,
  workOrderapprovedbyClient: true,
  workOrderrejectedbyClient: true,

  todoSettingShow: true,
  todoNotificationShow: true,
  displayTODOHomepage: true,
  newTODOAssignedBuilder: true,
  newTODOAssignedInternalStaff: true,
  TODOStatusUpdate: true,
  TODOCompleted: true,
  TODOCancelled: true,

  estimationPoShow: true,
  estimationNotificationShow: true,
  displayPOQRHomepage: true,
  displayNotSelectedQR: true,
  displayCompletedPOs: true,
  newQRSentbyBuilder: true,
  QRResponsePending: true,
  QRApprovedbyBuilder: true,
  QRNotSelectedbyBuilder: true,
  QRAbouttoExpire: true,
  QRExpired: true,
  newPOSentbyBuilder: true,
  POAcceptPending: true,
  POCancelled: true,
  POCompleted: true,
  newSupplierCreditSentBuilder: true,
  supplierCreditAcceptPending: true,
  supplierCreditAcceptcancelled: true,
  supplierCreditCompleted: true,

  generalMenuShow: true,
  generalAcessShow: true,
  generalNotificationShow: true,
  emailNotifications: true,
  notifications: true,
  pushNotifications: true,

  vendorName: "",
  phone: "",
  email: "",
  city: "",
  country: "",
  province: "",
  vendorType: "",
  addressLine1: "",
  addressLine2: "",
  taxRegistration: "",
  postalCode: "",
  TradeViewAccessRequestModal: false,
  VendorAssignedJobsProjectsModal: false,
  designSelections: true,
  fileManager: true,
  appointments: true,
  changeOrdersChecklist: true,
  warrantyWorkOrder: true,
  POQR: true,
  constructionSchedule: true,
  TODO: true,

  AssignJobsProjectModal: false,
  search: "",
  vendorId: "",
  projectSearch: "",
  ProjectId: "",
  jobSearch: "",
  DeleteModal: false,
  assignId: "",
  TradeViewUsersModal: false,

  investorMenuAppsShow: true,
  investorNotificationsShow: true,
  fileManagers: true,
  reports: true,
  emailNotificationss: true,
  notificationss: true,
  pushNotificationss: true,
  investorAccessShow: true,
  viewConfigInvestorId: "",
  phonecode: "",
  NavigateModal: false,
  phonecode: "",
  errors: {},
  vendorUserId: "",
  InvestorViewUsersModal: false,
 
};

const VendorViewConfig = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [iState, updateState] = useState(initialState);
  const {
    viewConfigId,
    designPriceShow,
    designNotificationShow,
    builderPriceUpdate,
    discountUpdate,
    newSelectionSentBuilder,
    selectionCanceled,
    selectionDeadlineDateApro,
    clientMissedSelectionDeadline,

    appointmentCalenderShow,
    appointmentNotificationsShow,
    displayCalendarHomepage,
    displayPastAppointments,
    newAppointment,
    appointmentCancelled,
    appointmentUpdate,
    newDateSelectionEvent,

    constructionAppShow,
    constructionAppsShow,
    constructionNotificationsShow,
    disConstScheTasksHomepage,
    vendorDocumentDownload,
    vendorDocumentShare,
    constructionDocuments,
    newDocumentAvailable,
    vendorSignaturePending,
    builderSignatureCompleted,
    newScheduleTaskAssigned,
    assignedScheTaskPenConfi,
    scheduleTaskCompleted,
    scheduleTaskCancelled,

    changeOrderChecklistShow,
    changeOrderNotificationShow,
    displayChangeOrderHomepage,
    newChangeOrder,
    clientApprovedChangeOrder,
    clientRejectedChangeOrder,

    warrantyWorkOrderShow,
    warrantyNotificationShow,
    displayWarrWorkOrdHomepage,
    newWorkOrder,
    newTradesDaySelection,
    tradesDayConfirmation,
    tradesDayupdate,
    workOrderupdate,
    workOrderapprovedbyClient,
    workOrderrejectedbyClient,

    todoSettingShow,
    todoNotificationShow,
    displayTODOHomepage,
    newTODOAssignedBuilder,
    newTODOAssignedInternalStaff,
    TODOStatusUpdate,
    TODOCompleted,
    TODOCancelled,

    estimationPoShow,
    estimationNotificationShow,
    displayPOQRHomepage,
    displayNotSelectedQR,
    displayCompletedPOs,

    newQRSentbyBuilder,
    QRResponsePending,
    QRApprovedbyBuilder,
    QRNotSelectedbyBuilder,
    QRAbouttoExpire,
    QRExpired,
    newPOSentbyBuilder,
    POAcceptPending,
    POCancelled,
    POCompleted,
    newSupplierCreditSentBuilder,
    supplierCreditAcceptPending,
    supplierCreditAcceptcancelled,
    supplierCreditCompleted,

    generalMenuShow,
    generalAcessShow,
    generalNotificationShow,
    emailNotifications,
    notifications,
    pushNotifications,

    vendorName,
    phone,
    email,
    city,
    country,
    province,
    vendorType,
    addressLine1,
    addressLine2,
    taxRegistration,
    postalCode,
    TradeViewAccessRequestModal,
    VendorAssignedJobsProjectsModal,
    designSelections,
    fileManagers,
    appointments,
    changeOrdersChecklist,
    warrantyWorkOrder,
    POQR,
    constructionSchedule,
    TODO,
    AssignJobsProjectModal,
    search,
    vendorId,
    projectSearch,
    ProjectId,
    jobSearch,
    DeleteModal,
    assignId,
    TradeViewUsersModal,

    investorMenuAppsShow,
    investorNotificationsShow,
    fileManager,
    reports,
    emailNotificationss,
    notificationss,
    pushNotificationss,
    investorAccessShow,
    viewConfigInvestorId,
    phonecode,
    NavigateModal,
    errors,
    vendorUserId,
    InvestorViewUsersModal,

 
  } = iState;

  const [SmsInfoModal, SetSmsInfoModal] = useState(false);
  const [userData, setUserData] = useState({});

  const {
    generalconfigData,
    designSelectionData,
    appointmentsData,
    constructionData,
    changeOrderData,
    warrantyData,
    todoData,
    estimationData,
    vendorAuthorizedList,
    allProjectList,
    assignVendorJobProjectList,
    generalConfigInvestorView,
    vendorInvestorAuthorizedList,
    vendorUserList,
    tradeAccesRequestList,
    innvestorVendorUserList,
  } = useSelector((state) => state.vendorViewConfigReducer);

  const [selectedOption, setSelectedOption] = useState("New Vendor");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const { vendorTypesList, vendorList } = useSelector(
    (state) => state.warrantyReducer
  );

  const { projectJobList } = useSelector((state) => state.warrantyReducer);
  const { countryData, stateData } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (iBuildLocalData?.user?.CompanyId && iBuildLocalData?.user?.token) {
      dispatch(getCountriesList());
      dispatch(getListOfVendorTypes());
      dispatch(getListProjectJobs(iBuildLocalData?.user?.CompanyId));
      dispatch(getAllProjectList(iBuildLocalData?.user?.CompanyId));
      dispatch(getGenralConfigInvestorView()).then((res) => {
        updateState({
          ...iState,
          viewConfigInvestorId: res?.data?.ViewSetting?.id,
        });
        dispatch(getVendorInvestorAuthorisedList(res?.data?.ViewSetting?.id));
      });
    }
  }, []);

  useEffect(() => {
    if (iBuildLocalData?.user?.CompanyId && iBuildLocalData?.user?.token) {
      dispatch(getGeneralConfiguration()).then((res) => {
        dispatch(getDesignSelections(res?.data?.ViewSetting?.id));
        dispatch(getAppointments(res?.data?.ViewSetting?.id));
        dispatch(getConstruction(res?.data?.ViewSetting?.id));
        dispatch(getChangeOrder(res?.data?.ViewSetting?.id));
        dispatch(getWarranty(res?.data?.ViewSetting?.id));
        dispatch(getTodo(res?.data?.ViewSetting?.id));
        dispatch(getEstimation(res?.data?.ViewSetting?.id));
        dispatch(getVendorAuthorisedList(res?.data?.ViewSetting?.id));
        updateState({ ...iState, viewConfigId: res?.data?.ViewSetting?.id });
      });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      dispatch(
        getStateList(
          countryData?.countries?.find((x) => x.name == value)?.isoCode
        )
      );
      updateState({
        ...iState,
        [name]: value,
      });
    } else if (name === "vendorType") {
      let vendorData = vendorTypesList?.vendorType?.find(
        (item) => item === value
      );
      dispatch(getVendorList(vendorData));
      updateState({
        ...iState,
        [name]: value,
      });
    } else if (name === "vendorName") {
      let vendorListData = vendorList?.vendors?.find(
        (item) => item.vendorName === value
      );
      updateState({
        ...iState,
        [name]: value,
        phone: vendorListData?.phone,
        email: vendorListData?.email,
        taxRegistration: vendorListData?.taxRegistration,
        addressLine1: vendorListData?.addressLine1,
        addressLine2: vendorListData?.addressLine2,
        city: vendorListData?.city,
        postalCode: vendorListData?.postalCode,
        country: vendorListData?.country,
        province: vendorListData?.province,
      });
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };


  const handleDesignPriceToggle = () => {
    updateState({ ...iState, designPriceShow: !designPriceShow });
  };
  const handleDesignSelectionsCheck = (type) => {
    if (type === "price") {
      updateState({ ...iState, builderPriceUpdate: !builderPriceUpdate });
    }
    if (type === "discount") {
      updateState({ ...iState, discountUpdate: !discountUpdate });
    }
    if (type === "newSelectionSent") {
      updateState({
        ...iState,
        newSelectionSentBuilder: !newSelectionSentBuilder,
      });
    }
    if (type === "selectionCancel") {
      updateState({ ...iState, selectionCanceled: !selectionCanceled });
    }
    if (type === "selectionDead") {
      updateState({
        ...iState,
        selectionDeadlineDateApro: !selectionDeadlineDateApro,
      });
    }
    if (type === "clientMissedSelection") {
      updateState({
        ...iState,
        clientMissedSelectionDeadline: !clientMissedSelectionDeadline,
      });
    }
  };

  const handleDesignPriceDiscountUpdate = () => {
    const dataa = { builderPriceUpdate, discountUpdate };
    dispatch(
      updateDesignSelections(
        generalconfigData?.ViewSetting?.id,
        designSelectionData?.designSelections?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getDesignSelections(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          designPriceShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleDesignNotificationsToggle = () => {
    updateState({ ...iState, designNotificationShow: !designNotificationShow });
  };
  const handleDesignNotificationsUpdate = () => {
    const dataa = {
      newSelectionSentBuilder,
      selectionCanceled,
      selectionDeadlineDateApro,
      clientMissedSelectionDeadline,
    };
    dispatch(
      updateDesignSelections(
        generalconfigData?.ViewSetting?.id,
        designSelectionData?.designSelections?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getDesignSelections(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          designNotificationShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleAppointmentsCalendarToggle = () => {
    updateState({
      ...iState,
      appointmentCalenderShow: !appointmentCalenderShow,
    });
  };

  const handleAppointmentsCheck = (type) => {
    if (type === "calend") {
      updateState({
        ...iState,
        displayCalendarHomepage: !displayCalendarHomepage,
      });
    }
    if (type === "displayPast") {
      updateState({
        ...iState,
        displayPastAppointments: !displayPastAppointments,
      });
    }
    if (type === "newApponit") {
      updateState({
        ...iState,
        newAppointment: !newAppointment,
      });
    }
    if (type === "appointmentCancel") {
      updateState({ ...iState, appointmentCancelled: !appointmentCancelled });
    }
    if (type === "appointmentUpdat") {
      updateState({
        ...iState,
        appointmentUpdate: !appointmentUpdate,
      });
    }
    if (type === "newDateSelection") {
      updateState({
        ...iState,
        newDateSelectionEvent: !newDateSelectionEvent,
      });
    }
  };

  const handleAppointmentCalendarUpdate = () => {
    const dataa = {
      displayCalendarHomepage,
      displayPastAppointments,
    };
    dispatch(
      updateAppointments(
        generalconfigData?.ViewSetting?.id,
        appointmentsData?.appointments?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getAppointments(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          appointmentCalenderShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleAppointmentsNotificationToggle = () => {
    updateState({
      ...iState,
      appointmentNotificationsShow: !appointmentNotificationsShow,
    });
  };
  const handleAppointmentNotificationUpdate = () => {
    const dataa = {
      newAppointment,
      appointmentCancelled,
      appointmentUpdate,
      newDateSelectionEvent,
    };
    dispatch(
      updateAppointments(
        generalconfigData?.ViewSetting?.id,
        appointmentsData?.appointments?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getAppointments(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          appointmentNotificationsShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleConstructionAppToggle = () => {
    updateState({
      ...iState,
      constructionAppShow: !constructionAppShow,
    });
  };

  const handleConstructionCheck = (type) => {
    if (type === "home") {
      updateState({
        ...iState,
        disConstScheTasksHomepage: !disConstScheTasksHomepage,
      });
    }
    if (type === "vendorDown") {
      updateState({
        ...iState,
        vendorDocumentDownload: !vendorDocumentDownload,
      });
    }
    if (type === "vendoShare") {
      updateState({
        ...iState,
        vendorDocumentShare: !vendorDocumentShare,
      });
    }
    if (type === "constructDocus") {
      updateState({ ...iState, constructionDocuments: !constructionDocuments });
    }
    if (type === "newDoc") {
      updateState({
        ...iState,
        newDocumentAvailable: !newDocumentAvailable,
      });
    }
    if (type === "vendoSignPend") {
      updateState({
        ...iState,
        vendorSignaturePending: !vendorSignaturePending,
      });
    }
    if (type === "buildSign") {
      updateState({
        ...iState,
        builderSignatureCompleted: !builderSignatureCompleted,
      });
    }
    if (type === "newAssignTask") {
      updateState({
        ...iState,
        newScheduleTaskAssigned: !newScheduleTaskAssigned,
      });
    }
    if (type === "assignSchTask") {
      updateState({
        ...iState,
        assignedScheTaskPenConfi: !assignedScheTaskPenConfi,
      });
    }
    if (type === "schComplet") {
      updateState({
        ...iState,
        scheduleTaskCompleted: !scheduleTaskCompleted,
      });
    }
    if (type === "schCancel") {
      updateState({
        ...iState,
        scheduleTaskCancelled: !scheduleTaskCancelled,
      });
    }
  };

  const handleConstructionAppUpdate = () => {
    const dataa = {
      disConstScheTasksHomepage,
    };
    dispatch(
      updateConstruction(
        generalconfigData?.ViewSetting?.id,
        constructionData?.constructions?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getConstruction(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          constructionAppShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleConstructionAppsToggle = () => {
    updateState({
      ...iState,
      constructionAppsShow: !constructionAppsShow,
    });
  };

  const handleConstructionAppsUpdate = () => {
    const dataa = {
      vendorDocumentDownload,
      vendorDocumentShare,
      constructionDocuments,
    };
    dispatch(
      updateConstruction(
        generalconfigData?.ViewSetting?.id,
        constructionData?.constructions?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getConstruction(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          constructionAppsShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleConstructionNotificationToggle = () => {
    updateState({
      ...iState,
      constructionNotificationsShow: !constructionNotificationsShow,
    });
  };
  const handleConstructionNotificationUpdate = () => {
    const dataa = {
      newDocumentAvailable,
      vendorSignaturePending,
      builderSignatureCompleted,
      newScheduleTaskAssigned,
      assignedScheTaskPenConfi,
      scheduleTaskCompleted,
      scheduleTaskCancelled,
    };
    dispatch(
      updateConstruction(
        generalconfigData?.ViewSetting?.id,
        constructionData?.constructions?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getConstruction(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          constructionNotificationsShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleChangeOrderChecklistToggle = () => {
    updateState({
      ...iState,
      changeOrderChecklistShow: !changeOrderChecklistShow,
    });
  };

  const handleChangeOrderCheck = (type) => {
    if (type === "home") {
      updateState({
        ...iState,
        displayChangeOrderHomepage: !displayChangeOrderHomepage,
      });
    }
    if (type === "newChange") {
      updateState({
        ...iState,
        newChangeOrder: !newChangeOrder,
      });
    }
    if (type === "clientApp") {
      updateState({
        ...iState,
        clientApprovedChangeOrder: !clientApprovedChangeOrder,
      });
    }
    if (type === "clientRej") {
      updateState({
        ...iState,
        clientRejectedChangeOrder: !clientRejectedChangeOrder,
      });
    }
  };

  const handleChangeOrderChecklistUpdate = () => {
    const dataa = {
      displayChangeOrderHomepage,
    };
    dispatch(
      updateChangeOrder(generalconfigData?.ViewSetting?.id, changeOrderData?.changeOrders?.id, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getChangeOrder(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          changeOrderChecklistShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleChangeOrderNotificationToggle = () => {
    updateState({
      ...iState,
      changeOrderNotificationShow: !changeOrderNotificationShow,
    });
  };
  const handleChangeOrderNotificationUpdate = () => {
    const dataa = {
      displayChangeOrderHomepage,
    };
    dispatch(
      updateChangeOrder(generalconfigData?.ViewSetting?.id, changeOrderData?.changeOrders?.id, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getChangeOrder(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          changeOrderNotificationShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleWarrantyOrderOrderToggle = () => {
    updateState({
      ...iState,
      warrantyWorkOrderShow: !warrantyWorkOrderShow,
    });
  };

  const handleWarrantyCheck = (type) => {
    if (type === "home") {
      updateState({
        ...iState,
        displayWarrWorkOrdHomepage: !displayWarrWorkOrdHomepage,
      });
    }
    if (type === "newOrder") {
      updateState({
        ...iState,
        newWorkOrder: !newWorkOrder,
      });
    }
    if (type === "newTradesDay") {
      updateState({
        ...iState,
        newTradesDaySelection: !newTradesDaySelection,
      });
    }
    if (type === "tradesDayConfirm") {
      updateState({
        ...iState,
        tradesDayConfirmation: !tradesDayConfirmation,
      });
    }
    if (type === "tradesDayupda") {
      updateState({
        ...iState,
        tradesDayupdate: !tradesDayupdate,
      });
    }
    if (type === "workOrderupdat") {
      updateState({
        ...iState,
        workOrderupdate: !workOrderupdate,
      });
    }
    if (type === "workOrderapproved") {
      updateState({
        ...iState,
        workOrderapprovedbyClient: !workOrderapprovedbyClient,
      });
    }
    if (type === "workOrderrejected") {
      updateState({
        ...iState,
        workOrderrejectedbyClient: !workOrderrejectedbyClient,
      });
    }
  };

  const handleWarrantyWorkOrderUpdate = () => {
    const dataa = {
      displayWarrWorkOrdHomepage,
    };
    dispatch(
      updateWarranty(generalconfigData?.ViewSetting?.id, warrantyData?.warranties?.id, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getWarranty(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          warrantyWorkOrderShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleWarrantyNotificationToggle = () => {
    updateState({
      ...iState,
      warrantyNotificationShow: !warrantyNotificationShow,
    });
  };

  const handleWarrantyNotioficationUpdate = () => {
    const dataa = {
      newWorkOrder,
      newTradesDaySelection,
      tradesDayConfirmation,
      tradesDayupdate,
      workOrderupdate,
      workOrderapprovedbyClient,
      workOrderrejectedbyClient,
    };
    dispatch(
      updateWarranty(generalconfigData?.ViewSetting?.id, warrantyData?.warranties?.id, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getWarranty(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          warrantyNotificationShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleTodoSettingToggle = () => {
    updateState({
      ...iState,
      todoSettingShow: !todoSettingShow,
    });
  };

  const handleTodoCheck = (type) => {
    if (type === "home") {
      updateState({
        ...iState,
        displayTODOHomepage: !displayTODOHomepage,
      });
    }
    if (type === "newTodoAssig") {
      updateState({
        ...iState,
        newTODOAssignedBuilder: !newTODOAssignedBuilder,
      });
    }
    if (type === "newTodoInt") {
      updateState({
        ...iState,
        newTODOAssignedInternalStaff: !newTODOAssignedInternalStaff,
      });
    }
    if (type === "todoStatus") {
      updateState({
        ...iState,
        TODOStatusUpdate: !TODOStatusUpdate,
      });
    }
    if (type === "todoCompl") {
      updateState({
        ...iState,
        TODOCompleted: !TODOCompleted,
      });
    }
    if (type === "todoCanel") {
      updateState({
        ...iState,
        TODOCancelled: !TODOCancelled,
      });
    }
  };

  const handleTodoSettingUpdate = () => {
    const dataa = { displayTODOHomepage };
    dispatch(updateTodo(generalconfigData?.ViewSetting?.id, todoData?.toDos?.id, dataa)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getTodo(generalconfigData?.ViewSetting?.id));
          toast.success("Update Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            todoSettingShow: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleTodoNotificationToggle = () => {
    updateState({
      ...iState,
      todoNotificationShow: !todoNotificationShow,
    });
  };

  const handleTodoNotificationUpdate = () => {
    const dataa = {
      newTODOAssignedBuilder,
      newTODOAssignedInternalStaff,
      TODOStatusUpdate,
      TODOCompleted,
      TODOCancelled,
    };
    dispatch(updateTodo(generalconfigData?.ViewSetting?.id, todoData?.toDos?.id, dataa)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getTodo(generalconfigData?.ViewSetting?.id));
          toast.success("Update Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            todoNotificationShow: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleEstimationPoToggleShow = () => {
    updateState({ ...iState, estimationPoShow: !estimationPoShow });
  };

  const handleEstimationCheck = (type) => {
    if (type === "home") {
      updateState({ ...iState, displayPOQRHomepage: !displayPOQRHomepage });
    }
    if (type === "dispNotSel") {
      updateState({ ...iState, displayNotSelectedQR: !displayNotSelectedQR });
    }
    if (type === "displayCompl") {
      updateState({
        ...iState,
        displayCompletedPOs: !displayCompletedPOs,
      });
    }
    if (type === "newQRSentbyBuilder") {
      updateState({ ...iState, newQRSentbyBuilder: !newQRSentbyBuilder });
    }
    if (type === "QRResponsePending") {
      updateState({
        ...iState,
        QRResponsePending: !QRResponsePending,
      });
    }
    if (type === "QRApprovedbyBuilder") {
      updateState({
        ...iState,
        QRApprovedbyBuilder: !QRApprovedbyBuilder,
      });
    }
    if (type === "QRNotSelectedbyBuilder") {
      updateState({
        ...iState,
        QRNotSelectedbyBuilder: !QRNotSelectedbyBuilder,
      });
    }
    if (type === "QRAbouttoExpire") {
      updateState({
        ...iState,
        QRAbouttoExpire: !QRAbouttoExpire,
      });
    }
    if (type === "QRExpired") {
      updateState({
        ...iState,
        QRExpired: !QRExpired,
      });
    }
    if (type === "newPOSentbyBuilder") {
      updateState({
        ...iState,
        newPOSentbyBuilder: !newPOSentbyBuilder,
      });
    }
    if (type === "POAcceptPending") {
      updateState({
        ...iState,
        POAcceptPending: !POAcceptPending,
      });
    }
    if (type === "POCancelled") {
      updateState({
        ...iState,
        POCancelled: !POCancelled,
      });
    }
    if (type === "POCompleted") {
      updateState({
        ...iState,
        POCompleted: !POCompleted,
      });
    }
    if (type === "newSupplierCreditSentBuilder") {
      updateState({
        ...iState,
        newSupplierCreditSentBuilder: !newSupplierCreditSentBuilder,
      });
    }
    if (type === "supplierCreditAcceptPending") {
      updateState({
        ...iState,
        supplierCreditAcceptPending: !supplierCreditAcceptPending,
      });
    }
    if (type === "supplierCreditAcceptcancelled") {
      updateState({
        ...iState,
        supplierCreditAcceptcancelled: !supplierCreditAcceptcancelled,
      });
    }
    if (type === "supplierCreditCompleted") {
      updateState({
        ...iState,
        supplierCreditCompleted: !supplierCreditCompleted,
      });
    }
  };

  const handleEstimationPoUpdate = () => {
    const dataa = {
      displayPOQRHomepage,
      displayNotSelectedQR,
      displayCompletedPOs,
    };
    dispatch(
      updateEstimation(generalconfigData?.ViewSetting?.id, estimationData?.estimations?.id, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getEstimation(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          estimationPoShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleEstimationNotificationToggle = () => {
    updateState({
      ...iState,
      estimationNotificationShow: !estimationNotificationShow,
    });
  };

  const handleEstimationNotificationUpdate = () => {
    const dataa = {
      newQRSentbyBuilder,
      QRResponsePending,
      QRApprovedbyBuilder,
      QRNotSelectedbyBuilder,
      QRAbouttoExpire,
      QRExpired,
      newPOSentbyBuilder,
      POAcceptPending,
      POCancelled,
      POCompleted,
      newSupplierCreditSentBuilder,
      supplierCreditAcceptPending,
      supplierCreditAcceptcancelled,
      supplierCreditCompleted,
    };
    dispatch(
      updateEstimation(generalconfigData?.ViewSetting?.id, estimationData?.estimations?.id, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getEstimation(generalconfigData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          estimationNotificationShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleGeneralNotificationToggle = () => {
    updateState({
      ...iState,
      generalNotificationShow: !generalNotificationShow,
    });
  };

  const handleGeneralCheck = (type) => {
    if (type === "designSelections") {
      updateState({ ...iState, designSelections: !designSelections });
    }
    if (type === "fileManager") {
      updateState({ ...iState, fileManager: !fileManager });
    }
    if (type === "appointments") {
      updateState({ ...iState, appointments: !appointments });
    }
    if (type === "changeOrdersChecklist") {
      updateState({ ...iState, changeOrdersChecklist: !changeOrdersChecklist });
    }
    if (type === "warrantyWorkOrder") {
      updateState({ ...iState, warrantyWorkOrder: !warrantyWorkOrder });
    }
    if (type === "POQR") {
      updateState({ ...iState, POQR: !POQR });
    }
    if (type === "constructionSchedule") {
      updateState({ ...iState, constructionSchedule: !constructionSchedule });
    }
    if (type === "TODO") {
      updateState({ ...iState, TODO: !TODO });
    }
    if (type === "emailNotifications") {
      updateState({ ...iState, emailNotifications: !emailNotifications });
    }

    if (type === "notifications") {
      updateState({
        ...iState,
        notifications: !notifications,
      });
    }
    if (type === "pushNotifications") {
      updateState({ ...iState, pushNotifications: !pushNotifications });
    }
  };

  const handleGeneralNotificationUpdate = () => {
    const dataa = {
      emailNotifications,
      notifications,
      pushNotifications,
    };
    dispatch(updateGeneralConfig(generalconfigData?.ViewSetting?.id, dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getGeneralConfiguration());
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          generalNotificationShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleAddAuthorisedVendor = () => {
    let formIsValid = handleValidation();
    if (formIsValid) {
      const dataa = {
        vendorName,
        phone,
        email,
        city,
        country,
        province,
        vendorType,
        addressLine1,
        addressLine2:addressLine2?addressLine2:"",
        taxRegistration:taxRegistration?taxRegistration:"",
        postalCode,
        phonecode,
      };
      dispatch(addVendorAuthorised(generalconfigData?.ViewSetting?.id, dataa)).then((res) => {
        if (res.status === 201) {
          dispatch(getVendorAuthorisedList(generalconfigData?.ViewSetting?.id));
          toast.success("Update Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            vendorName: "",
            phone: "",
            email: "",
            city: "",
            country: "",
            province: "",
            vendorType: "",
            addressLine1: "",
            addressLine2: "",
            taxRegistration: "",
            postalCode: "",
            phonecode: "",
            errors:{},
            investorAccessShow: false
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleGeneralMenuToggle = () => {
    updateState({ ...iState, generalMenuShow: !generalMenuShow });
  };

  const handleGeneralMenuUpdate = () => {
    const dataa = {
      designSelections,
      fileManager,
      appointments,
      changeOrdersChecklist,
      warrantyWorkOrder,
      POQR,
      constructionSchedule,
      TODO,
    };
    dispatch(updateGeneralConfig(generalconfigData?.ViewSetting?.id, dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getGeneralConfiguration());
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          generalMenuShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleVendorAssignedJobsProjectsModalShow = (id) => {
    updateState({
      ...iState,
      VendorAssignedJobsProjectsModal: true,
      vendorId: id,
    });
  };

  useEffect(() => {
    if (VendorAssignedJobsProjectsModal) {
      dispatch(getAssgnedVendorJobsProjectsList(generalconfigData?.ViewSetting?.id, vendorId));
    }
  }, [VendorAssignedJobsProjectsModal]);

  const handleVendorAssignedJobsProjectsModalClose = () => {
    updateState({
      ...iState,
      VendorAssignedJobsProjectsModal: false,
      vendorId: "",
    });
  };

  const handleAssignJobsProjectModalShow = () => {
    updateState({
      ...iState,
      AssignJobsProjectModal: true,
      VendorAssignedJobsProjectsModal: false,
      selectedItems: [],
      ProjectId: "",
    });
  };

  const handleAssignJobsProjectModalClose = () => {
    updateState({
      ...iState,
      AssignJobsProjectModal: false,
      VendorAssignedJobsProjectsModal: true,
      selectedItems: [],
      ProjectId: "",
    });
  };

  const handleItemClick = (id) => {
    updateState({ ...iState, ProjectId: id });
  };

  const [selectedItems, setSelectedItems] = useState([]);

  // Function to handle checkbox change
  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the selected item to the array
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    } else {
      // Remove the item from the array
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item)
      );
    }
  };

  // console.log("selectedItems",selectedItems);

  const handleAssignJobsProjects = () => {
    const dataa = {
      ProjectId,
      jobList: selectedItems?.map((data) => ({
        JobId: data.id,
        JobType: data.jobType,
      })),
    };
    dispatch(
      createAssgnedVendorJobsProjects(generalconfigData?.ViewSetting?.id, vendorId, dataa)
    ).then((res) => {
      if (res.status === 201) {
        dispatch(getAssgnedVendorJobsProjectsList(generalconfigData?.ViewSetting?.id, vendorId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          VendorAssignedJobsProjectsModal: true,
          AssignJobsProjectModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          VendorAssignedJobsProjectsModal: false,
        });
      }
    });
  };

  const handleDeleteShow = (id) => {
    updateState({
      ...iState,
      DeleteModal: true,
      assignId: id,
      VendorAssignedJobsProjectsModal: false,
    });
  };

  const handleDeleteClose = () => {
    updateState({
      ...iState,
      DeleteModal: false,
      VendorAssignedJobsProjectsModal: true,
    });
  };

  const handleDeleteAssign = () => {
    dispatch(deleteVendorJobsProjects(generalconfigData?.ViewSetting?.id, vendorId, assignId)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getAssgnedVendorJobsProjectsList(generalconfigData?.ViewSetting?.id, vendorId));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            DeleteModal: false,
            VendorAssignedJobsProjectsModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            DeleteModal: true,
          });
        }
      }
    );
  };

  const handleAccessToggle = () => {
    updateState({ ...iState, generalAcessShow: !generalAcessShow });
  };

  const handleEnable = (ID) => {
    dispatch(enableTradeViewAccess(generalconfigData?.ViewSetting?.id, ID)).then((res) => {
      if (res.status === 200) {
        dispatch(getVendorAuthorisedList(generalconfigData?.ViewSetting?.id));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const handleDisable = (ID) => {
    dispatch(disableTradeViewAccess(generalconfigData?.ViewSetting?.id, ID)).then((res) => {
      if (res.status === 200) {
        dispatch(getVendorAuthorisedList(generalconfigData?.ViewSetting?.id));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleInvestorMenuAppToggle = () => {
    updateState({
      ...iState,
      investorMenuAppsShow: !investorMenuAppsShow,
    });
  };

  const handleInvestorNotificationToggle = () => {
    updateState({
      ...iState,
      investorNotificationsShow: !investorNotificationsShow,
    });
  };

  const handleInvestorViewCheck = (type) => {
    if (type === "fileManager") {
      updateState({ ...iState, fileManagers: !fileManagers });
    }
    if (type === "reports") {
      updateState({ ...iState, reports: !reports });
    }
    if (type === "emailNotificationss") {
      updateState({
        ...iState,
        emailNotificationss: !emailNotificationss,
      });
    }
    if (type === "notificationss") {
      updateState({ ...iState, notificationss: !notificationss });
    }
    if (type === "pushNotificationss") {
      updateState({
        ...iState,
        pushNotificationss: !pushNotificationss,
      });
    }
  };

  const handleGeneralInvestorMenuUpdate = () => {
    const dataa = {
      fileManager: fileManagers,
      reports,
    };
    dispatch(
      updateGeneralConfigInvestorView(
        generalConfigInvestorView?.ViewSetting?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getGenralConfigInvestorView());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          investorMenuAppsShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const handleGeneralInvestorNotificationUpdate = () => {
    const dataa = {
      emailNotifications: emailNotificationss,
      notifications: notificationss,
      pushNotifications: pushNotificationss,
    };
    dispatch(
      updateGeneralConfigInvestorView(
        generalConfigInvestorView?.ViewSetting?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getGenralConfigInvestorView());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          investorNotificationsShow: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleInvestorAccessToggle = () => {
    updateState({
      ...iState,
      investorAccessShow: !investorAccessShow,
    });
  };

  const handlePhoneInputChange = (value, country) => {
    updateState({
      ...iState,
      phonecode: `+${country.dialCode}`,
    });
  };

  let handleValidation = () => {
    let error = {};
    let formIsValid = true;

    if (!vendorName || !vendorName.trim()) {
      error.vendorNameError = " * Vendor Name can't be empty";
      formIsValid = false;
    }

    if (!phone || !phone.trim()) {
      error.phoneError = " * Phone No. can't be empty";
      formIsValid = false;
    } else {
      if (phone) {
        if (!/^[1-9][0-9]{7,12}$/.test(phone)) {
          error.phoneError = " * Phone number should be 8 to 13 digits";
          formIsValid = false;
        }
        if (isNaN(phone)) {
          error.phoneError = " * Only Numeric Value are allowed";
        }
      }
    }
    if (!email || !email.trim()) {
      error.emailError = " * E-mail can't be empty";
      formIsValid = false;
    }
    if (!city || !city.trim()) {
      error.cityError = " * City can't be empty";
      formIsValid = false;
    }
    if (!country) {
      error.countryError = " * Please Select country";
      formIsValid = false;
    }
    if (!province) {
      error.provinceError = " * Please Select province";
      formIsValid = false;
    }
    if (!vendorType) {
      error.vendorTypeError = " * Please Select Vendor Type";
      formIsValid = false;
    }
    if (!addressLine1) {
      error.addressError = " * Address Line1 can't be empty";
      formIsValid = false;
    }
    // if (!taxRegistration) {
    //   error.taxRegistrationError = " * Tax-Registration  can't be empty";
    //   formIsValid = false;
    // }
    if (!postalCode) {
      error.postalCodeError = " * Postal-Code  can't be empty";
      formIsValid = false;
    }
    if (!phonecode) {
      error.phonecodeError = " * Phone-Code  can't be empty";
      formIsValid = false;
    }
    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleAddInvestorAuthorisedVendor = () => {
    let formIsValid = handleValidation();
    if (formIsValid) {
      const dataa = {
        vendorName,
        phone,
        email,
        city,
        country,
        province,
        vendorType,
        addressLine1,
        addressLine2:addressLine2?addressLine2:"",
        taxRegistration:taxRegistration?taxRegistration:"",
        postalCode,
        phonecode,
      };
      dispatch(
        addInvestorVendorAuthorised(
          generalConfigInvestorView?.ViewSetting?.id,
          dataa
        )
      ).then((res) => {
        if (res.status === 201) {
          dispatch(getVendorInvestorAuthorisedList(generalConfigInvestorView?.ViewSetting?.id));
          toast.success("Update Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            vendorName: "",
            phone: "",
            email: "",
            city: "",
            country: "",
            province: "",
            vendorType: "",
            addressLine1: "",
            addressLine2: "",
            taxRegistration: "",
            postalCode: "",
            phonecode: "",
            errors:{},
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleEnableInvestor = (ID) => {
    dispatch(
      enableInvestorVendor(generalConfigInvestorView?.ViewSetting?.id, ID)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getVendorInvestorAuthorisedList(
            generalConfigInvestorView?.ViewSetting?.id
          )
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const handleDisableInvestor = (ID) => {
    dispatch(
      disableInvestorVendor(generalConfigInvestorView?.ViewSetting?.id, ID)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getVendorInvestorAuthorisedList(
            generalConfigInvestorView?.ViewSetting?.id
          )
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const [JobMenuModal, setJobMenuModal] = useState(false);
  const handleJobMenuModalClose = () => setJobMenuModal(false);
  const handleJobMenuModalShow = () => setJobMenuModal(true);
  const handleNavigateModalShow = () => {
    updateState({ ...iState, NavigateModal: true });
  };
  const handleNavigateModalClose = () => {
    updateState({ ...iState, NavigateModal: false });
  };

  const handleTradeViewUsersModalShow = (id) => {
    updateState({ ...iState, TradeViewUsersModal: true, vendorUserId: id });
  };

  const handleTradeViewUsersModalClose = () => {
    updateState({ ...iState, TradeViewUsersModal: false, vendorUserId: "" });
  };

  useEffect(() => {
    if (TradeViewUsersModal)
      dispatch(getTradeViewVendorUserList(generalconfigData?.ViewSetting?.id, vendorUserId));
  }, [TradeViewUsersModal]);

  const handleTradeViewAccessRequestModalShow = () => {
    updateState({ ...iState, TradeViewAccessRequestModal: true });
  };

  const handleTradeViewAccessRequestModalClose = () => {
    updateState({ ...iState, TradeViewAccessRequestModal: false });
  };

  useEffect(() => {
    if (TradeViewAccessRequestModal)
      dispatch(getTradeAccessRequest(generalconfigData?.ViewSetting?.id));
  }, [TradeViewAccessRequestModal]);

  const handleInvestorViewUsersModalShow = (id) => {
    updateState({ ...iState, InvestorViewUsersModal: true, vendorUserId: id });
  };

  const handleInvestorViewUsersModalClose = () => {
    updateState({ ...iState, InvestorViewUsersModal: false, vendorUserId: "" });
  };

  useEffect(() => {
    if (InvestorViewUsersModal)
      dispatch(
        getInvestorViewVendorUserList(generalConfigInvestorView?.ViewSetting?.id, vendorUserId)
      );
  }, [InvestorViewUsersModal]);

  const handleRemoveInvestorUser = (vendorId, type) => {
    dispatch(removeInvestor(vendorId)).then((res) => {
      if (res.status === 200) {
        if (type === "invest") {
          dispatch(getVendorInvestorAuthorisedList(generalConfigInvestorView?.ViewSetting?.id));
        } else {
          dispatch(getVendorAuthorisedList(generalconfigData?.ViewSetting?.id));
        }

        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const [ investerViewStatus, setInvesterViewStatus]=useState(true)
  const [ fileManagerApp, setFileManagerApp]=useState(true)
  const [ reportsApp, setReportsApp]=useState(true)


  const handleInvestorCheckboxChange = (e, id, type) => {
    const checked = e.target.checked; // Common variable for checked state
    
    if (type === "investerViewStatus") {
      setInvesterViewStatus(checked); 
      dispatch(
        investorViewAccess(
          id,
          { investerViewStatus: checked } // Correct syntax for object property
        )
      ).then((res)=>{
        if(res.status===200){
          dispatch(getVendorInvestorAuthorisedList(generalConfigInvestorView?.ViewSetting?.id));
        }
      })
    } else if (type === "fileManagerApp") {
      setFileManagerApp(checked);
      dispatch(
        investorViewAccess(
          id,
          { fileManagerApp: checked } // Correct syntax for object property
        )
      );
    } else if (type === "reportsApp") {
      setReportsApp(checked); // Correct state updater
      dispatch(
        investorViewAccess(
          id,
          { reportsApp: checked } // Correct syntax for object property
        )
      );
    }
  };
  


  return (
    <>
      <div>
        <Header />
        <SideNav />
        <div className="WrapperArea">
          <div className="WrapperBox">
            <div className="TitleBox">
              <div>
                <div className="TitleBox bg-none border-0 p-0">
                  <h4 className="Title">Views Config</h4>
                </div>
                <div className="CommonTabs mt-3">
                  <ul className="nav nav-tabs">
                    {/* <li class="nav-item">
            <a
              class="nav-link active"
              data-toggle="tab"
              href="#ClientView"
              ><span><img src="images/client_view_red.png" /></span>Client
              View</a
            >
          </li> */}
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#TradeView"
                      >
                        <span>
                          <img src="images/trade_view_red.png" />
                        </span>
                        Trade View
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#InvestorView"
                      >
                        <span>
                          <img src="images/trade_view_red.png" />
                        </span>{" "}
                        Investor View
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* // */}
              <div class="TitleBoxNavigate">
                {/* <h5>UUnit2 -CHI -2B -314</h5> */}
                {/* <p>Multi-Family - Residential Unit</p> */}
                <div class="NavigateButton">
                  <button onClick={handleJobMenuModalShow}>Job Menu</button>
                  <button  className="ml-2" onClick={handleNavigateModalShow}>
                    Navigate
                  </button>
                </div>
              </div>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="TradeView">
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-12">
                    <div className="ViewsConfigLeft">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            href="#GeneralConfigurationLeft2"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            General Configuration
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#DesignSelectionsLeft2"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Design Selections
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#EstimationLeft2"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Estimation
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#ConstructionLeft2"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Construction
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#AppointmentsLeft2"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Appointments
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#ChangeOrdersLeft2"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Change Orders
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#WarrantyLeftTrade2"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Warranty
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#TodoLeft2"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            TODO
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-12">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="GeneralConfigurationLeft2"
                      >
                        <div className="ResidentialJobArea ViewConfigArea">
                          <div className="ModalAccordian">
                            <div id="accordion">
                              <div className="card">
                                <div className="card-header">
                                  <h4 data-toggle="collapse" href="#MenuApps">
                                    <span>
                                      <img src="images/general_inform.png" />
                                    </span>{" "}
                                    Menu Apps
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleGeneralMenuToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="MenuApps"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {generalMenuShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>Design Selections</label>
                                              <span>
                                                {generalconfigData?.ViewSetting
                                                  ?.designSelections === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>File Manager</label>
                                              <span>
                                                {generalconfigData?.ViewSetting
                                                  ?.fileManager === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>Appointments</label>
                                              <span>
                                                {generalconfigData?.ViewSetting
                                                  ?.appointments === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Change Orders Checklist
                                              </label>
                                              <span>
                                                {generalconfigData?.ViewSetting
                                                  ?.changeOrdersChecklist ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>Warranty Work Order</label>
                                              <span>
                                                {generalconfigData?.ViewSetting
                                                  ?.warrantyWorkOrder === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>PO/QR</label>
                                              <span>
                                                {generalconfigData?.ViewSetting
                                                  ?.POQR === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Construction Schedule
                                              </label>
                                              <span>
                                                {generalconfigData?.ViewSetting
                                                  ?.constructionSchedule ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>TODO</label>
                                              <span>
                                                {generalconfigData?.ViewSetting
                                                  ?.TODO === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              Design Selections
                                              <input
                                                type="checkbox"
                                                checked={designSelections}
                                                onClick={() =>
                                                  handleGeneralCheck(
                                                    "designSelections"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              File Manager
                                              <input
                                                type="checkbox"
                                                checked={fileManager}
                                                onClick={() =>
                                                  handleGeneralCheck(
                                                    "fileManager"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Appointments
                                              <input
                                                type="checkbox"
                                                checked={appointments}
                                                onClick={() =>
                                                  handleGeneralCheck(
                                                    "appointments"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Change Order Checklist
                                              <input
                                                type="checkbox"
                                                checked={changeOrdersChecklist}
                                                onClick={() =>
                                                  handleGeneralCheck(
                                                    "changeOrdersChecklist"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Warranty Work Order
                                              <input
                                                type="checkbox"
                                                checked={warrantyWorkOrder}
                                                onClick={() =>
                                                  handleGeneralCheck(
                                                    "warrantyWorkOrder"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              PO/QR
                                              <input
                                                type="checkbox"
                                                checked={POQR}
                                                onClick={() =>
                                                  handleGeneralCheck("POQR")
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Construction Schedule
                                              <input
                                                type="checkbox"
                                                checked={constructionSchedule}
                                                onClick={() =>
                                                  handleGeneralCheck(
                                                    "constructionSchedule"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              TODO
                                              <input
                                                type="checkbox"
                                                checked={TODO}
                                                onClick={() =>
                                                  handleGeneralCheck("TODO")
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={handleGeneralMenuToggle}
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={handleGeneralMenuUpdate}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#AccessGeneralConfig"
                                  >
                                    <span>
                                      <img src="images/access.png" />
                                    </span>
                                    Access
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleAccessToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="AccessGeneralConfig"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                  
                                    <div className="ViewConfigBodyHideMain">
                                      <div className="ViewConfigBodyHide">
                                        <aside>
                                          <div className="form-group">
                                            <h6>Add Authorized Vendor</h6>
                                          </div>
                                        </aside>
                                        <aside>
                                          <div className="ViewConfigHeading">
                                            <h6
                                              className="CursorPointer"
                                              onClick={
                                                handleTradeViewAccessRequestModalShow
                                              }
                                            >
                                              <span>
                                                <img src="images/client_view_preview.png" />
                                              </span>
                                              Access Request<sup>3</sup>
                                            </h6>
                                          </div>
                                        </aside>
                                      </div>

                                      <div className="d-flex mt-4">
                                        <div className="form-group">
                                          <label className="Radio text-black">
                                            New Vendor
                                            <input
                                              type="radio"
                                              name="Cuisine"
                                              value="New Vendor"
                                              checked={
                                                selectedOption === "New Vendor"
                                              }
                                              onChange={handleOptionChange}
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </div>
                                        <div className="form-group ml-5">
                                          <label className="Radio text-black">
                                            Select from Vendor List
                                            <input
                                              type="radio"
                                              name="Cuisine"
                                              value="Select from Vendor List"
                                              checked={
                                                selectedOption ===
                                                "Select from Vendor List"
                                              }
                                              onChange={handleOptionChange}
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </div>
                                      </div>

                                      {selectedOption === "New Vendor" ? (
                                        <div
                                          className="text RadioText"
                                          style={{ display: "block" }}
                                        >
                                          <div className="row">
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <h6>
                                                  Vendor Name{" "}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert Vendor Name"
                                                  name="vendorName"
                                                  value={vendorName}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.vendorNameError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <label>Country Code</label>
                                                <PhoneInput
                                                  inputClass="CountryCodeInput"
                                                  countryCodeEditable={false}
                                                  enableSearch
                                                  value={phonecode}
                                                  onChange={
                                                    handlePhoneInputChange
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.phonecodeError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <h6>Phone</h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert company's Phone Number"
                                                  name="phone"
                                                  value={phone}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.phoneError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <h6>Email</h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert company's main email address"
                                                  name="email"
                                                  value={email}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.emailError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <h6>
                                                  Vendor Type{" "}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <div className="CommonSelectBox">
                                                  <select
                                                    name="vendorType"
                                                    value={vendorType}
                                                    onChange={(e) =>
                                                      handleInputChange(e)
                                                    }
                                                  >
                                                    <option selected="">
                                                      Select Service Type
                                                    </option>
                                                    {vendorTypesList?.vendorType
                                                      ?.length > 0 &&
                                                      vendorTypesList?.vendorType?.map(
                                                        (data, ind) => {
                                                          return (
                                                            <option
                                                              key={ind}
                                                              value={data.id}
                                                            >
                                                              {data}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>

                                                  {/* <span>
                                                  <img src="images/down.png" />
                                                </span> */}
                                                </div>
                                                <span style={{ color: "red" }}>
                                                  {errors?.vendorTypeError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <h6>Tax Registration #</h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert Vendor Name"
                                                  name="taxRegistration"
                                                  value={taxRegistration}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.taxRegistrationError}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <h6>
                                                  Street Address
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert company's street address"
                                                  name="addressLine1"
                                                  value={addressLine1}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.addressError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <h6>Street Address Line 2</h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert company's street address"
                                                  name="addressLine2"
                                                  value={addressLine2}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <h6>
                                                  City{" "}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert city information"
                                                  name="city"
                                                  value={city}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.cityError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <h6>
                                                  Zip / Postal Code
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert zip code"
                                                  name="postalCode"
                                                  value={postalCode}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.postalCodeError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <h6>
                                                  Country{" "}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <div className="CommonSelectBox">
                                                  <select
                                                    name="country"
                                                    value={country}
                                                    onChange={(e) =>
                                                      handleInputChange(e)
                                                    }
                                                  >
                                                    <option value="">
                                                      Select a Country
                                                    </option>
                                                    {countryData?.countries
                                                      ?.length > 0 &&
                                                      countryData?.countries?.map(
                                                        (data, i) => {
                                                          return (
                                                            <option
                                                              value={data.name}
                                                              key={i}
                                                            >
                                                              {data.name}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                  {/* <span>
                                                  <img src="images/down.png" />
                                                </span> */}
                                                </div>
                                                <span style={{ color: "red" }}>
                                                  {errors?.countryError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-6">
                                              <div className="form-group">
                                                <h6>
                                                  State/Province{" "}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <div className="CommonSelectBox">
                                                  <select
                                                    name="province"
                                                    value={province}
                                                    onChange={(e) =>
                                                      handleInputChange(e)
                                                    }
                                                  >
                                                    <option value="">
                                                      Select a State / Province
                                                    </option>
                                                    {stateData?.states?.length >
                                                      0 &&
                                                      stateData?.states?.map(
                                                        (item, i) => {
                                                          return (
                                                            <option
                                                              value={item.name}
                                                              key={i}
                                                              selected={province==item.name}
                                                            >
                                                              {item.name}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                  {/* <span>
                                                  <img src="images/down.png" />
                                                </span> */}
                                                </div>
                                                <span style={{ color: "red" }}>
                                                  {errors?.provinceError}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="Button text-center">
                                            <button
                                              className="Create"
                                              onClick={
                                                handleAddAuthorisedVendor
                                              }
                                            >
                                              Add Authorized Vendor and Save to
                                              the Vendor List
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="file RadioText">
                                          <div className="row">
                                            <div className="col-md-5">
                                              <div className="form-group">
                                                <h6>Vendor Type</h6>
                                                <div className="CommonSelectBox">
                                                  <select
                                                    name="vendorType"
                                                    value={vendorType}
                                                    onChange={(e) =>
                                                      handleInputChange(e)
                                                    }
                                                  >
                                                    <option selected="">
                                                      Select Vendor Type
                                                    </option>
                                                    {vendorTypesList?.vendorType
                                                      ?.length > 0 &&
                                                      vendorTypesList?.vendorType?.map(
                                                        (data, ind) => {
                                                          return (
                                                            <option
                                                              key={ind}
                                                              value={data.id}
                                                            >
                                                              {data}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-5">
                                              <div className="form-group">
                                                <h6>Vendor Name</h6>
                                                <div className="CommonSelectBox">
                                                  <select
                                                    name="vendorName"
                                                    value={vendorName}
                                                    onChange={(e) =>
                                                      handleInputChange(e)
                                                    }
                                                  >
                                                    <option selected="">
                                                      Select Vendor Name
                                                    </option>
                                                    {vendorList?.vendors
                                                      ?.length > 0 &&
                                                      vendorList?.vendors?.map(
                                                        (data, ind) => {
                                                          return (
                                                            <option
                                                              key={ind}
                                                              value={
                                                                data.vendorName
                                                              }
                                                            >
                                                              {data.vendorName}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="Button text-center">
                                            <button className="Create" onClick={()=>setSelectedOption("New Vendor")}>
                                              Confirm Selection
                                            </button>
                                          </div>
                                        </div>
                                      )}

                                      <div className="TableHeadingArea mt-5">
                                        <div className="FilesHead">
                                          <div className="FilesLeft">
                                            <h4>Authorized Vendors</h4>
                                          </div>
                                          <div className="FilesRight d-flex align-items-center">
                                            <div className="Search">
                                              <span>
                                                <img src="images/search.png" />
                                              </span>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search"
                                                name="search"
                                                value={search}
                                                onChange={handleInputChange}
                                              />
                                            </div>
                                            <div>
                                              <a href="javascript:void(0);">
                                                <img src="images/pdf.png" />
                                              </a>
                                              <a href="javascript:void(0);">
                                                <img src="images/download.png" />
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="TableList TableListAlign TableListHeader NewTableList TableHeightScroll TableHeight500">
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>Name</th>
                                              <th>Type</th>
                                              <th>Primary Contact's Name</th>
                                              <th>Primary Email</th>
                                              <th>Primary Phone Number</th>
                                              <th>
                                                Permission Granted to Receive
                                                SMS
                                              </th>

                                              <th>Trade View Status</th>
                                              <th>Assigned Jobs/Projects</th>
                                              <th># of Trade View Users</th>
                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {vendorAuthorizedList?.pagination
                                              ?.data?.length > 0 ? (
                                              vendorAuthorizedList?.pagination?.data
                                                ?.filter((data) =>
                                                  search.toLowerCase() === ""
                                                    ? true
                                                    : data.vendorName &&
                                                      data.vendorName
                                                        .toLowerCase()
                                                        .includes(
                                                          search.toLowerCase()
                                                        )
                                                )
                                                .map((data, i) => {
                                                  return (
                                                    <tr key={i}>
                                                      <td>{data.vendorName}</td>
                                                      <td>{data.vendorType}</td>
                                                      <td>{data.vendorName}</td>
                                                      <td>{data.email}</td>
                                                      <td>{data.phone}</td>
                                                      <td>
                                                        Yes{" "}
                                                        <img
                                                          onClick={() => {
                                                            SetSmsInfoModal(
                                                              true
                                                            );
                                                            setUserData(data);
                                                          }}
                                                          src="images/icon_62.png"
                                                        />
                                                      </td>
                                                      <td>
                                                        {data
                                                          .VendorAccessRequest
                                                          ?.tradeViewStatus
                                                          ? "Active"
                                                          : "Inactive"}
                                                      </td>
                                                      <td>
                                                        <a
                                                          onClick={() =>
                                                            handleVendorAssignedJobsProjectsModalShow(
                                                              data.id
                                                            )
                                                          }
                                                        >
                                                          <img src="images/create_building.png" />
                                                          <span className="Count">
                                                            3
                                                          </span>
                                                        </a>
                                                      </td>
                                                      <td>{data?.VendorAccessRequest?.noTradeViewUsers||0}</td>
                                                      <td>
                                                        <div className="TableThreeDots TableSlider">
                                                          <ul className="">
                                                            <li className="dropdown">
                                                              <a
                                                                className=""
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                              >
                                                                <img src="images/dots.png" />
                                                              </a>
                                                              <ol className="dropdown-menu dropdown-menuwidth2">
                                                                <li>
                                                                  <Link to="/trade-view-login">
                                                                    <img src="images/Action-1.png" />
                                                                    Trade View
                                                                    Preview
                                                                  </Link>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    onClick={() =>
                                                                      handleTradeViewUsersModalShow(
                                                                        data.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <img src="images/view_trade_view.png" />
                                                                    View Trade
                                                                    View users
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    onClick={() =>
                                                                      handleEnable(
                                                                        data.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <label className="switch">
                                                                      {" "}
                                                                      Enable
                                                                      Trade View
                                                                      Access
                                                                      {/* <input type="checkbox" /> */}
                                                                      {/* <span className="slider" /> */}
                                                                    </label>
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    onClick={() =>
                                                                      handleDisable(
                                                                        data.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <label className="switch">
                                                                      {" "}
                                                                      Disable
                                                                      Trade View
                                                                      Access
                                                                      {/* <input type="checkbox" /> */}
                                                                      {/* <span className="slider" /> */}
                                                                    </label>
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    onClick={() =>
                                                                      handleRemoveInvestorUser(
                                                                        data.id,
                                                                        "trade"
                                                                      )
                                                                    }
                                                                  >
                                                                    <img src="images/dropdown-delete.png" />
                                                                    Remove
                                                                    Vendor
                                                                  </a>
                                                                </li>
                                                              </ol>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                })
                                            ) : (
                                              <tr>
                                                <td colSpan="4">
                                                  No data found
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="PaginationArea mb-4">
                                        <h6>Showing 3 of 27 entries</h6>
                                        <h6>
                                          <img src="images/leftarrow.png" /> 01{" "}
                                          <img src="images/rightarrow.png" />{" "}
                                          Out 10
                                        </h6>
                                      </div>
                                      {/* <div className="Button d-flex justify-content-center mt-3">
                                      <button className="OutlineBlack mr-3 SaveViewConfigBodyBtn">
                                        Cancel
                                      </button>
                                      <button className="Respond ml-3 SaveViewConfigBodyBtn">
                                        Save
                                      </button>
                                    </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#NotificationsSetup"
                                  >
                                    <span>
                                      <img src="images/notification.png" />
                                    </span>{" "}
                                    Notifications Setup
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleGeneralNotificationToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="NotificationsSetup"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {generalNotificationShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>Email </label>
                                              <span>
                                                {generalconfigData?.ViewSetting
                                                  ?.emailNotifications === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>Notifications </label>
                                              <span>
                                                {generalconfigData?.ViewSetting
                                                  ?.notifications === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>Push </label>
                                              <span>
                                                {generalconfigData?.ViewSetting
                                                  ?.pushNotifications === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              Email
                                              <input
                                                type="checkbox"
                                                checked={emailNotifications}
                                                onClick={() =>
                                                  handleGeneralCheck(
                                                    "emailNotifications"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Notifications
                                              <input
                                                type="checkbox"
                                                checked={notifications}
                                                onClick={() =>
                                                  handleGeneralCheck(
                                                    "notifications"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Push
                                              <input
                                                type="checkbox"
                                                checked={pushNotifications}
                                                onClick={() =>
                                                  handleGeneralCheck(
                                                    "pushNotifications"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleGeneralNotificationToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleGeneralNotificationUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="DesignSelectionsLeft2">
                        <div className="ResidentialJobArea ViewConfigArea">
                          <div className="ModalAccordian">
                            <div id="accordion">
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#DesignSelectionPricingSettingsApps"
                                  >
                                    <span>
                                      <img src="images/design_selector.png" />
                                    </span>{" "}
                                    Design Selection Pricing Settings
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleDesignPriceToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="DesignSelectionPricingSettingsApps"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {designPriceShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>
                                                Builder Price Update
                                              </label>
                                              <span>
                                                {designSelectionData
                                                  ?.designSelections
                                                  ?.builderPriceUpdate === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>Discount Update</label>
                                              <span>
                                                {designSelectionData
                                                  ?.designSelections
                                                  ?.discountUpdate === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              Builder Price Update
                                              <input
                                                type="checkbox"
                                                checked={builderPriceUpdate}
                                                onClick={() =>
                                                  handleDesignSelectionsCheck(
                                                    "price"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Discount Update
                                              <input
                                                type="checkbox"
                                                checked={discountUpdate}
                                                onClick={() =>
                                                  handleDesignSelectionsCheck(
                                                    "discount"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center mt-5">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={handleDesignPriceToggle}
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleDesignPriceDiscountUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#NotificationsSetup3"
                                  >
                                    <span>
                                      <img src="images/notification.png" />
                                    </span>{" "}
                                    Notifications Setup
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleDesignNotificationsToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="NotificationsSetup3"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {designNotificationShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>
                                                New Selection Sent by Builder{" "}
                                              </label>
                                              <span>
                                                {designSelectionData
                                                  ?.designSelections
                                                  ?.newSelectionSentBuilder ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>Selection Canceled </label>
                                              <span>
                                                {designSelectionData
                                                  ?.designSelections
                                                  ?.selectionCanceled === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Selection Deadline Date
                                                Aproaching{" "}
                                              </label>
                                              <span>
                                                {designSelectionData
                                                  ?.designSelections
                                                  ?.selectionDeadlineDateApro ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Client Missed Selection Deadline{" "}
                                              </label>
                                              <span>
                                                {designSelectionData
                                                  ?.designSelections
                                                  ?.clientMissedSelectionDeadline ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              New Selection Sent by Builder
                                              <input
                                                type="checkbox"
                                                checked={
                                                  newSelectionSentBuilder
                                                }
                                                onClick={() =>
                                                  handleDesignSelectionsCheck(
                                                    "newSelectionSent"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Selection Canceled
                                              <input
                                                type="checkbox"
                                                checked={selectionCanceled}
                                                onClick={() =>
                                                  handleDesignSelectionsCheck(
                                                    "selectionCancel"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Selection Deadline Date Aproaching
                                              <input
                                                type="checkbox"
                                                checked={
                                                  selectionDeadlineDateApro
                                                }
                                                onClick={() =>
                                                  handleDesignSelectionsCheck(
                                                    "selectionDead"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Client Missed Selection Deadline
                                              <input
                                                type="checkbox"
                                                checked={
                                                  clientMissedSelectionDeadline
                                                }
                                                onClick={() =>
                                                  handleDesignSelectionsCheck(
                                                    "clientMissedSelection"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center mt-5">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleDesignNotificationsToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleDesignNotificationsUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="EstimationLeft2">
                        <div className="ResidentialJobArea ViewConfigArea">
                          <div className="ModalAccordian">
                            <div id="accordion">
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#ConstructionAppsSettings"
                                  >
                                    <span>
                                      <img src="images/po_qr_settings.png" />
                                    </span>{" "}
                                    PO/QR Settings
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleEstimationPoToggleShow}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="ConstructionAppsSettings"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {estimationPoShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>
                                                Display PO/QR in the Homepage
                                              </label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.displayPOQRHomepage === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Display Not Selected QR
                                              </label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.displayNotSelectedQR ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Display Completed POs
                                              </label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.displayCompletedPOs === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              Display PO/QR in the Homepage
                                              <input
                                                type="checkbox"
                                                checked={displayPOQRHomepage}
                                                onClick={() =>
                                                  handleEstimationCheck("home")
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Display Not Selected QR
                                              <input
                                                type="checkbox"
                                                checked={displayNotSelectedQR}
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "dispNotSel"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Display Completed POs
                                              <input
                                                type="checkbox"
                                                checked={displayCompletedPOs}
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "displayCompl"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleEstimationPoToggleShow
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={handleEstimationPoUpdate}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#Notifications"
                                  >
                                    <span>
                                      <img src="images/notification.png" />
                                    </span>{" "}
                                    Notifications
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleEstimationNotificationToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="Notifications"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {estimationNotificationShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>
                                                New QR Sent by Builder
                                              </label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.newQRSentbyBuilder === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>QR Response Pending</label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.QRResponsePending === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                QR Approved by Builder
                                              </label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.QRApprovedbyBuilder === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                QR Not Selected by Builder
                                              </label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.QRNotSelectedbyBuilder ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            {/* <p>
                                          <label>Warranty Work Order</label>
                                          <span>{estimationData?.estimations?.newPOSentbyBuilder===true?"On":"Off"}</span>
                                        </p> */}
                                            <p>
                                              <label>QR About to Expire</label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.QRAbouttoExpire === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>QR Expired</label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.QRExpired === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                New PO Sent by Builder
                                              </label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.newPOSentbyBuilder === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>PO Accept Pending</label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.POAcceptPending === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>PO Cancelled</label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.POCancelled === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>PO Completed</label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.POCompleted === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                New Supplier Credit Sent by
                                                Builder
                                              </label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.newSupplierCreditSentBuilder ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Supplier Credit Accept Pending
                                              </label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.supplierCreditAcceptPending ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Supplier Credit Accept cancelled
                                              </label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.supplierCreditAcceptcancelled ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Supplier Credit Completed
                                              </label>
                                              <span>
                                                {estimationData?.estimations
                                                  ?.supplierCreditCompleted ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              New QR Sent by Builder
                                              <input
                                                type="checkbox"
                                                checked={newQRSentbyBuilder}
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "newQRSentbyBuilder"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              QR Response Pending
                                              <input
                                                type="checkbox"
                                                checked={QRResponsePending}
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "QRResponsePending"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              QR Approved by Builder
                                              <input
                                                type="checkbox"
                                                checked={QRApprovedbyBuilder}
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "QRApprovedbyBuilder"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              QR Not Selected by Builder
                                              <input
                                                type="checkbox"
                                                checked={QRNotSelectedbyBuilder}
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "QRNotSelectedbyBuilder"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            {/* <label className="switch">
                                            {" "}
                                            Warranty Work Order
                                            <input type="checkbox" 
                                             checked={displayCompletedPOs}
                                             onClick={() =>
                                               handleEstimationCheck(
                                                 "displayCompl"
                                               )
                                             }/>
                                            <span className="slider" />
                                          </label> */}
                                            <label className="switch">
                                              {" "}
                                              QR About to Expire
                                              <input
                                                type="checkbox"
                                                checked={QRAbouttoExpire}
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "QRAbouttoExpire"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              QR Expired
                                              <input
                                                type="checkbox"
                                                checked={QRExpired}
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "QRExpired"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              New PO Sent by Builder
                                              <input
                                                type="checkbox"
                                                checked={newPOSentbyBuilder}
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "newPOSentbyBuilder"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              PO Accept Pending
                                              <input
                                                type="checkbox"
                                                checked={POAcceptPending}
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "POAcceptPending"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              PO Cancelled
                                              <input
                                                type="checkbox"
                                                checked={POCancelled}
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "POCancelled"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              PO Completed
                                              <input
                                                type="checkbox"
                                                checked={POCompleted}
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "POCompleted"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              New Supplier Credit Sent by
                                              Builder
                                              <input
                                                type="checkbox"
                                                checked={
                                                  newSupplierCreditSentBuilder
                                                }
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "newSupplierCreditSentBuilder"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Supplier Credit Accept Pending
                                              <input
                                                type="checkbox"
                                                checked={
                                                  supplierCreditAcceptPending
                                                }
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "supplierCreditAcceptPending"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Supplier Credit Accept cancelled
                                              <input
                                                type="checkbox"
                                                checked={
                                                  supplierCreditAcceptcancelled
                                                }
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "supplierCreditAcceptcancelled"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Supplier Credit Completed
                                              <input
                                                type="checkbox"
                                                checked={
                                                  supplierCreditCompleted
                                                }
                                                onClick={() =>
                                                  handleEstimationCheck(
                                                    "supplierCreditCompleted"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleEstimationNotificationToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleEstimationNotificationUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="ConstructionLeft2">
                        <div className="ResidentialJobArea ViewConfigArea">
                          <div className="ModalAccordian">
                            <div id="accordion">
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#ConstructionAppsSettings"
                                  >
                                    <span>
                                      <img src="images/construction_apps.png" />
                                    </span>{" "}
                                    Construction Apps Settings
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleConstructionAppToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="ConstructionAppsSettings"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {constructionAppShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>
                                                Display Construction Schedule
                                                Tasks in the Homepage
                                              </label>
                                              <span>
                                                {constructionData?.constructions
                                                  ?.disConstScheTasksHomepage ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              Display Construction Schedule
                                              Tasks in the Homepage
                                              <input
                                                type="checkbox"
                                                checked={
                                                  disConstScheTasksHomepage
                                                }
                                                onClick={() =>
                                                  handleConstructionCheck(
                                                    "home"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleConstructionAppToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleConstructionAppUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#ConstructionAppsSettings2"
                                  >
                                    <span>
                                      <img src="images/sales_documents.png" />
                                    </span>{" "}
                                    Construction Apps Settings
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleConstructionAppsToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="ConstructionAppsSettings2"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {constructionAppsShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>
                                                Vendor Document Download
                                              </label>
                                              <span>
                                                {constructionData?.constructions
                                                  ?.vendorDocumentDownload ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Vendor Document Share
                                              </label>
                                              <span>
                                                {constructionData?.constructions
                                                  ?.vendorDocumentShare === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Construction Documents
                                              </label>
                                              <span>
                                                {constructionData?.constructions
                                                  ?.constructionDocuments ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              Vendor Document Download
                                              <input
                                                type="checkbox"
                                                checked={vendorDocumentDownload}
                                                onClick={() =>
                                                  handleConstructionCheck(
                                                    "vendorDown"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Vendor Document Share
                                              <input
                                                type="checkbox"
                                                checked={vendorDocumentShare}
                                                onClick={() =>
                                                  handleConstructionCheck(
                                                    "vendoShare"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Construction Documents
                                              <input
                                                type="checkbox"
                                                checked={constructionDocuments}
                                                onClick={() =>
                                                  handleConstructionCheck(
                                                    "constructDocus"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center mt-3">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleConstructionAppsToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleConstructionAppsUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#ConstructionPhotos"
                                  >
                                    <span>
                                      <img src="images/notification.png" />
                                    </span>{" "}
                                    Notifications
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={
                                      handleConstructionNotificationToggle
                                    }
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="ConstructionPhotos"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {constructionNotificationsShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>
                                                New Document Available
                                              </label>
                                              <span>
                                                {constructionData?.constructions
                                                  ?.newDocumentAvailable ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Vendor Signature Pending
                                              </label>
                                              <span>
                                                {constructionData?.constructions
                                                  ?.vendorSignaturePending ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Builder Signature Completed
                                              </label>
                                              <span>
                                                {constructionData?.constructions
                                                  ?.builderSignatureCompleted ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                New Schedule Task Assigned
                                              </label>
                                              <span>
                                                {constructionData?.constructions
                                                  ?.newScheduleTaskAssigned ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Assigned Schedule Task Pending
                                                Confirmation
                                              </label>
                                              <span>
                                                {constructionData?.constructions
                                                  ?.assignedScheTaskPenConfi ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Schedule Task Completed
                                              </label>
                                              <span>
                                                {constructionData?.constructions
                                                  ?.scheduleTaskCompleted ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Schedule Task Cancelled
                                              </label>
                                              <span>
                                                {constructionData?.constructions
                                                  ?.scheduleTaskCancelled ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              New Document Available
                                              <input
                                                type="checkbox"
                                                checked={newDocumentAvailable}
                                                onClick={() =>
                                                  handleConstructionCheck(
                                                    "newDoc"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Vendor Signature Pending
                                              <input
                                                type="checkbox"
                                                checked={vendorSignaturePending}
                                                onClick={() =>
                                                  handleConstructionCheck(
                                                    "vendoSignPend"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Builder Signature Completed
                                              <input
                                                type="checkbox"
                                                checked={
                                                  builderSignatureCompleted
                                                }
                                                onClick={() =>
                                                  handleConstructionCheck(
                                                    "buildSign"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              New Schedule Task Assigned
                                              <input
                                                type="checkbox"
                                                checked={
                                                  newScheduleTaskAssigned
                                                }
                                                onClick={() =>
                                                  handleConstructionCheck(
                                                    "newAssignTask"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Assigned Schedule Task Pending{" "}
                                              <br /> Confirmation
                                              <input
                                                type="checkbox"
                                                checked={
                                                  assignedScheTaskPenConfi
                                                }
                                                onClick={() =>
                                                  handleConstructionCheck(
                                                    "assignSchTask"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Schedule Task Completed
                                              <input
                                                type="checkbox"
                                                checked={scheduleTaskCompleted}
                                                onClick={() =>
                                                  handleConstructionCheck(
                                                    "schComplet"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Schedule Task Cancelled
                                              <input
                                                type="checkbox"
                                                checked={scheduleTaskCancelled}
                                                onClick={() =>
                                                  handleConstructionCheck(
                                                    "schCancel"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleConstructionNotificationToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleConstructionNotificationUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="AppointmentsLeft2">
                        <div className="ResidentialJobArea ViewConfigArea">
                          <div className="ModalAccordian">
                            <div id="accordion">
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#CalendarSettings"
                                  >
                                    <span>
                                      <img src="images/calendar_settings.png" />
                                    </span>{" "}
                                    Calendar Settings
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleAppointmentsCalendarToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="CalendarSettings"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {appointmentCalenderShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>
                                                Display Calendar in the Homepage
                                              </label>
                                              <span>
                                                {appointmentsData?.appointments
                                                  ?.displayCalendarHomepage ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Display Past Appointments
                                              </label>
                                              <span>
                                                {appointmentsData?.appointments
                                                  ?.displayPastAppointments ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Default Appointment Reminder
                                              </label>
                                              <span>1 day</span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              Display Calendar in the Homepage
                                              <input
                                                type="checkbox"
                                                checked={
                                                  displayCalendarHomepage
                                                }
                                                onClick={() =>
                                                  handleAppointmentsCheck(
                                                    "calend"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Display Past Appointments
                                              <input
                                                type="checkbox"
                                                checked={
                                                  displayPastAppointments
                                                }
                                                onClick={() =>
                                                  handleAppointmentsCheck(
                                                    "displayPast"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <p>
                                              <label>
                                                Default Appointment Reminder
                                              </label>
                                              <span className="CommonSelectBox">
                                                <select>
                                                  <option>1 day</option>
                                                  <option>2 day</option>
                                                </select>
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleAppointmentsCalendarToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleAppointmentCalendarUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#Notifications"
                                  >
                                    <span>
                                      <img src="images/notification.png" />
                                    </span>{" "}
                                    Notifications
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={
                                      handleAppointmentsNotificationToggle
                                    }
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="Notifications"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {appointmentNotificationsShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>New Appointment</label>
                                              <span>
                                                {appointmentsData?.appointments
                                                  ?.newAppointment === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Appointment Cancelled
                                              </label>
                                              <span>
                                                {appointmentsData?.appointments
                                                  ?.appointmentCancelled ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>Appointment Update</label>
                                              <span>
                                                {appointmentsData?.appointments
                                                  ?.appointmentUpdate === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                New Date Selection Event
                                              </label>
                                              <span>
                                                {appointmentsData?.appointments
                                                  ?.newDateSelectionEvent ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              New Appointment
                                              <input
                                                type="checkbox"
                                                checked={newAppointment}
                                                onClick={() =>
                                                  handleAppointmentsCheck(
                                                    "newApponit"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Appointment Cancelled
                                              <input
                                                type="checkbox"
                                                checked={appointmentCancelled}
                                                onClick={() =>
                                                  handleAppointmentsCheck(
                                                    "appointmentCancel"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Appointment Update
                                              <input
                                                type="checkbox"
                                                checked={appointmentUpdate}
                                                onClick={() =>
                                                  handleAppointmentsCheck(
                                                    "appointmentUpdat"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              New Date Selection Event
                                              <input
                                                type="checkbox"
                                                checked={newDateSelectionEvent}
                                                onClick={() =>
                                                  handleAppointmentsCheck(
                                                    "newDateSelection"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleAppointmentsNotificationToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleAppointmentNotificationUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="ChangeOrdersLeft2">
                        <div className="ResidentialJobArea ViewConfigArea">
                          <div className="ModalAccordian">
                            <div id="accordion">
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#ChangeOrderChecklistSettings"
                                  >
                                    <span>
                                      <img src="images/change_order_settings.png" />
                                    </span>{" "}
                                    Change Order Checklist Settings
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleChangeOrderChecklistToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="ChangeOrderChecklistSettings"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {changeOrderChecklistShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>
                                                Display Change Order Checklist
                                                in the Homepage
                                              </label>
                                              <span>
                                                {changeOrderData?.changeOrders
                                                  ?.displayChangeOrderHomepage ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              Display Change Order Checklist in
                                              the Homepage
                                              <input
                                                type="checkbox"
                                                checked={
                                                  displayChangeOrderHomepage
                                                }
                                                onClick={() =>
                                                  handleChangeOrderCheck("home")
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleChangeOrderChecklistToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleChangeOrderChecklistUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#Notifications"
                                  >
                                    <span>
                                      <img src="images/notification.png" />
                                    </span>{" "}
                                    Notifications
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={
                                      handleChangeOrderNotificationToggle
                                    }
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="Notifications"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {changeOrderNotificationShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>New Change Order</label>
                                              <span>
                                                {" "}
                                                {changeOrderData?.changeOrders
                                                  ?.newChangeOrder === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Client Approved Change Order
                                              </label>
                                              <span>
                                                {" "}
                                                {changeOrderData?.changeOrders
                                                  ?.clientApprovedChangeOrder ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Client Rejected Change Order
                                              </label>
                                              <span>
                                                {" "}
                                                {changeOrderData?.changeOrders
                                                  ?.clientRejectedChangeOrder ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              New Change Order
                                              <input
                                                type="checkbox"
                                                checked={newChangeOrder}
                                                onClick={() =>
                                                  handleChangeOrderCheck(
                                                    "newChange"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Client Approved Change Order
                                              <input
                                                type="checkbox"
                                                checked={
                                                  clientApprovedChangeOrder
                                                }
                                                onClick={() =>
                                                  handleChangeOrderCheck(
                                                    "clientApp"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Client Rejected Change Order
                                              <input
                                                type="checkbox"
                                                checked={
                                                  clientRejectedChangeOrder
                                                }
                                                onClick={() =>
                                                  handleChangeOrderCheck(
                                                    "clientRej"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleChangeOrderNotificationToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleChangeOrderNotificationUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="WarrantyLeftTrade2">
                        <div className="ResidentialJobArea ViewConfigArea">
                          <div className="ModalAccordian">
                            <div id="accordion">
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#WarrantyWorkOrdersSettings"
                                  >
                                    <span>
                                      <img src="images/home_warranty.png" />
                                    </span>{" "}
                                    Warranty Work Orders Settings
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleWarrantyOrderOrderToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="WarrantyWorkOrdersSettings"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {warrantyWorkOrderShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>
                                                Display Warranty Work Orders in
                                                the Homepage
                                              </label>
                                              <span>
                                                {warrantyData?.warranties
                                                  ?.displayWarrWorkOrdHomepage ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              Display Warranty Work Orders in
                                              the <br /> Homepage
                                              <input
                                                type="checkbox"
                                                checked={
                                                  displayWarrWorkOrdHomepage
                                                }
                                                onClick={() =>
                                                  handleWarrantyCheck("home")
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleWarrantyOrderOrderToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleWarrantyWorkOrderUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#Notifications"
                                  >
                                    <span>
                                      <img src="images/notification.png" />
                                    </span>{" "}
                                    Notifications
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleWarrantyNotificationToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="Notifications"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {warrantyNotificationShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>New Work Order </label>
                                              <span>
                                                {warrantyData?.warranties
                                                  ?.newWorkOrder === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                New Trades Day Selection{" "}
                                              </label>
                                              <span>
                                                {warrantyData?.warranties
                                                  ?.newTradesDaySelection ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Trades Day Confirmation{" "}
                                              </label>
                                              <span>
                                                {warrantyData?.warranties
                                                  ?.tradesDayConfirmation ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>Trades Day update</label>
                                              <span>
                                                {warrantyData?.warranties
                                                  ?.tradesDayupdate === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>Work Order update</label>
                                              <span>
                                                {warrantyData?.warranties
                                                  ?.workOrderupdate === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Work Order approved by Client
                                              </label>
                                              <span>
                                                {warrantyData?.warranties
                                                  ?.workOrderapprovedbyClient ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                Work Order rejected by Client
                                              </label>
                                              <span>
                                                {warrantyData?.warranties
                                                  ?.workOrderrejectedbyClient ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              New Work Order
                                              <input
                                                type="checkbox"
                                                checked={newWorkOrder}
                                                onClick={() =>
                                                  handleWarrantyCheck(
                                                    "newOrder"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              New Trades Day Selection
                                              <input
                                                type="checkbox"
                                                checked={newTradesDaySelection}
                                                onClick={() =>
                                                  handleWarrantyCheck(
                                                    "newTradesDay"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Trades Day Confirmation
                                              <input
                                                type="checkbox"
                                                checked={tradesDayConfirmation}
                                                onClick={() =>
                                                  handleWarrantyCheck(
                                                    "tradesDayConfirm"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Trades Day update
                                              <input
                                                type="checkbox"
                                                checked={tradesDayupdate}
                                                onClick={() =>
                                                  handleWarrantyCheck(
                                                    "tradesDayupda"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Work Order update
                                              <input
                                                type="checkbox"
                                                checked={workOrderupdate}
                                                onClick={() =>
                                                  handleWarrantyCheck(
                                                    "workOrderupdat"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Work Order approved by Client
                                              <input
                                                type="checkbox"
                                                checked={
                                                  workOrderapprovedbyClient
                                                }
                                                onClick={() =>
                                                  handleWarrantyCheck(
                                                    "workOrderapproved"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Work Order rejected by Client
                                              <input
                                                type="checkbox"
                                                checked={
                                                  workOrderrejectedbyClient
                                                }
                                                onClick={() =>
                                                  handleWarrantyCheck(
                                                    "workOrderrejected"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleWarrantyNotificationToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleWarrantyNotioficationUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="TodoLeft2">
                        <div className="ResidentialJobArea ViewConfigArea">
                          <div className="ModalAccordian">
                            <div id="accordion">
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#TodoSettings"
                                  >
                                    <span>
                                      <img src="images/home_warranty.png" />
                                    </span>{" "}
                                    TODO Settings
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleTodoSettingToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="TodoSettings"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {todoSettingShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>
                                                Display TODO in the Homepage
                                              </label>
                                              <span>
                                                {todoData?.toDos
                                                  ?.displayTODOHomepage === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              Display TODO in the Homepage
                                              <input
                                                type="checkbox"
                                                checked={displayTODOHomepage}
                                                onClick={() =>
                                                  handleTodoCheck("home")
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={handleTodoSettingToggle}
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={handleTodoSettingUpdate}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#Notifications"
                                  >
                                    <span>
                                      <img src="images/notification.png" />
                                    </span>{" "}
                                    Notifications
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleTodoNotificationToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="Notifications"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {todoNotificationShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>
                                                New TODO Assigned by Builder
                                              </label>
                                              <span>
                                                {todoData?.toDos
                                                  ?.newTODOAssignedBuilder ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>
                                                New TODO Assigned by Internal
                                                Staff
                                              </label>
                                              <span>
                                                {todoData?.toDos
                                                  ?.newTODOAssignedInternalStaff ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>TODO Status Update</label>
                                              <span>
                                                {todoData?.toDos
                                                  ?.TODOStatusUpdate === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>TODO Completed</label>
                                              <span>
                                                {todoData?.toDos
                                                  ?.TODOCompleted === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>TODO Cancelled</label>
                                              <span>
                                                {todoData?.toDos
                                                  ?.TODOCancelled === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              New TODO Assigned by Builder
                                              <input
                                                type="checkbox"
                                                checked={newTODOAssignedBuilder}
                                                onClick={() =>
                                                  handleTodoCheck(
                                                    "newTodoAssig"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              New TODO Assigned by Internal
                                              Staff
                                              <input
                                                type="checkbox"
                                                checked={
                                                  newTODOAssignedInternalStaff
                                                }
                                                onClick={() =>
                                                  handleTodoCheck("newTodoInt")
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              TODO Status Update
                                              <input
                                                type="checkbox"
                                                checked={TODOStatusUpdate}
                                                onClick={() =>
                                                  handleTodoCheck("todoStatus")
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              TODO Completed
                                              <input
                                                type="checkbox"
                                                checked={TODOCompleted}
                                                onClick={() =>
                                                  handleTodoCheck("todoCompl")
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              TODO Cancelled
                                              <input
                                                type="checkbox"
                                                checked={TODOCancelled}
                                                onClick={() =>
                                                  handleTodoCheck("todoCanel")
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleTodoNotificationToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleTodoNotificationUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="InvestorView">
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-12">
                    <div className="ViewsConfigLeft">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            href="#GeneralConfigurationLeft3"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            General Configuration
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#Reports"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Reports
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-12">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="GeneralConfigurationLeft3"
                      >
                        <div className="ResidentialJobArea ViewConfigArea">
                          <div className="ModalAccordian">
                            <div id="accordion">
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#MenuAppsGeneralConfig"
                                  >
                                    <span>
                                      <img src="images/general_inform.png" />
                                    </span>{" "}
                                    Menu Apps
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleInvestorMenuAppToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="MenuAppsGeneralConfig"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {investorMenuAppsShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>File Manager</label>
                                              <span>
                                                {generalConfigInvestorView
                                                  ?.ViewSetting?.fileManager ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>Reports</label>
                                              <span>
                                                {generalConfigInvestorView
                                                  ?.ViewSetting?.reports ===
                                                true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              File Manager
                                              <input
                                                type="checkbox"
                                                checked={fileManagers}
                                                onClick={() =>
                                                  handleInvestorViewCheck(
                                                    "fileManager"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Reports
                                              <input
                                                type="checkbox"
                                                checked={reports}
                                                onClick={() =>
                                                  handleInvestorViewCheck(
                                                    "reports"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center mt-4">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleInvestorMenuAppToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleGeneralInvestorMenuUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#AccessGeneralConfig"
                                  >
                                    <span>
                                      <img src="images/access.png" />
                                    </span>
                                    Access
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleInvestorAccessToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="AccessGeneralConfig"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    <div className="ViewConfigBodyHideMain">
                                  
                          
                                      <div className="ViewConfigBodyHide">
                                        <aside>
                                          <div className="form-group">
                                            <h6>Add Authorized Vendor</h6>
                                          </div>
                                        </aside>
                                        <aside>
                                          <div className="ViewConfigHeading">
                                            <h6
                                              className="CursorPointer"
                                              onClick={
                                                handleTradeViewAccessRequestModalShow
                                              }
                                            >
                                              <span>
                                                <img src="images/client_view_preview.png" />
                                              </span>
                                              Access Request<sup>3</sup>
                                            </h6>
                                          </div>
                                        </aside>
                                      </div>
                                      <div className="d-flex mt-4">
                                        <div className="form-group">
                                          <label className="Radio text-black">
                                            {" "}
                                            New Vendor
                                            <input
                                              type="radio"
                                              name="investor"
                                              value="New Vendor"
                                              checked={
                                                selectedOption === "New Vendor"
                                              }
                                              onChange={handleOptionChange}
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </div>
                                        <div className="form-group ml-5">
                                          <label className="Radio text-black">
                                            {" "}
                                            Select from Vendor List
                                            <input
                                              type="radio"
                                              name="investor"
                                              value="Select from Vendor List"
                                              checked={
                                                selectedOption ===
                                                "Select from Vendor List"
                                              }
                                              onChange={handleOptionChange}
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </div>
                                      </div>
                                      {selectedOption === "New Vendor"  ? (
                                        <div
                                          className="text RadioText"
                                          style={{ display: "block" }}
                                        >
                                          <div className="row">
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <h6>
                                                  Vendor Name{" "}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert Vendor Name"
                                                  name="vendorName"
                                                  value={vendorName}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.vendorNameError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <label>Country Code</label>
                                                <PhoneInput
                                                  inputClass="CountryCodeInput"
                                                  countryCodeEditable={false}
                                                  enableSearch
                                                  value={phonecode}
                                                  onChange={
                                                    handlePhoneInputChange
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.phonecodeError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <h6>Phone</h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert company's Phone Number"
                                                  name="phone"
                                                  value={phone}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.phoneError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <h6>Email</h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert company's main email address"
                                                  name="email"
                                                  value={email}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.emailError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <h6>
                                                  Vendor Type{" "}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <div className="CommonSelectBox">
                                                  <select
                                                    name="vendorType"
                                                    value={vendorType}
                                                    onChange={(e) =>
                                                      handleInputChange(e)
                                                    }
                                                  >
                                                    <option selected="">
                                                      Select Service Type
                                                    </option>
                                                    {vendorTypesList?.vendorType
                                                      ?.length > 0 &&
                                                      vendorTypesList?.vendorType?.map(
                                                        (data, ind) => {
                                                          return (
                                                            <option
                                                              key={ind}
                                                              value={data.id}
                                                            >
                                                              {data}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                  {/* <span>
                                              <img src="images/down.png" />
                                            </span> */}
                                                </div>
                                                <span style={{ color: "red" }}>
                                                  {errors?.vendorType}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <h6>Tax Registration #</h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert Vendor Name"
                                                  name="taxRegistration"
                                                  value={taxRegistration}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <h6>
                                                  Street Address
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert company's street address"
                                                  name="addressLine1"
                                                  value={addressLine1}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.addressError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <h6>Street Address Line 2</h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert company's street address"
                                                  name="addressLine2"
                                                  value={addressLine2}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <h6>
                                                  City{" "}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert city information"
                                                  name="city"
                                                  value={city}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.cityError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <h6>
                                                  Zip / Postal Code
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert zip code"
                                                  name="postalCode"
                                                  value={postalCode}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                />
                                                <span style={{ color: "red" }}>
                                                  {errors?.postalCodeError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <h6>
                                                  Country{" "}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <div className="CommonSelectBox">
                                                  <select
                                                    name="country"
                                                    value={country}
                                                    onChange={(e) =>
                                                      handleInputChange(e)
                                                    }
                                                  >
                                                    <option value="">
                                                      Select a Country
                                                    </option>
                                                    {countryData?.countries
                                                      ?.length > 0 &&
                                                      countryData?.countries?.map(
                                                        (data, i) => {
                                                          return (
                                                            <option
                                                              value={data.name}
                                                              key={i}
                                                            >
                                                              {data.name}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                  {/* <span>
                                              <img src="images/down.png" />
                                            </span> */}
                                                </div>
                                                <span style={{ color: "red" }}>
                                                  {errors?.countryError}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12">
                                              <div className="form-group">
                                                <h6>
                                                  State/Province{" "}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </h6>
                                                <div className="CommonSelectBox">
                                                  <select
                                                    name="province"
                                                    value={province}
                                                    onChange={(e) =>
                                                      handleInputChange(e)
                                                    }
                                                  >
                                                    <option value="">
                                                      Select a State / Province
                                                    </option>
                                                    {stateData?.states?.length >
                                                      0 &&
                                                      stateData?.states?.map(
                                                        (item, i) => {
                                                          return (
                                                            <option
                                                              value={item.name}
                                                              key={i}
                                                            >
                                                              {item.name}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                  {/* <span>
                                              <img src="images/down.png" />
                                            </span> */}
                                                </div>
                                                <span style={{ color: "red" }}>
                                                  {errors?.provinceError}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="Button text-center">
                                            <button
                                              className="Create"
                                              onClick={
                                                handleAddInvestorAuthorisedVendor
                                              }
                                            >
                                              Add Authorized Vendor and Save to
                                              the Vendor List
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="file RadioText">
                                          <div className="row">
                                            <div className="col-md-5">
                                              <div className="form-group">
                                                <h6>Vendor Type</h6>
                                                <div className="CommonSelectBox">
                                                  <select
                                                    name="vendorType"
                                                    value={vendorType}
                                                    onChange={(e) =>
                                                      handleInputChange(e)
                                                    }
                                                  >
                                                    <option selected="">
                                                      Select Vendor Type
                                                    </option>
                                                    {vendorTypesList?.vendorType
                                                      ?.length > 0 &&
                                                      vendorTypesList?.vendorType?.map(
                                                        (data, ind) => {
                                                          return (
                                                            <option
                                                              key={ind}
                                                              value={data.id}
                                                            >
                                                              {data}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-5">
                                              <div className="form-group">
                                                <h6>Vendor Name</h6>
                                                <div className="CommonSelectBox">
                                                  <select
                                                    name="vendorName"
                                                    value={vendorName}
                                                    onChange={(e) =>
                                                      handleInputChange(e)
                                                    }
                                                  >
                                                    <option selected="">
                                                      Select Vendor Name
                                                    </option>
                                                    {vendorList?.vendors
                                                      ?.length > 0 &&
                                                      [
                                                        ...new Set(
                                                          vendorList.vendors.map(
                                                            (data) =>
                                                              data.vendorName
                                                          )
                                                        ),
                                                      ].map(
                                                        (vendorName, ind) => (
                                                          <option
                                                            key={ind}
                                                            value={vendorName}
                                                          >
                                                            {vendorName}
                                                          </option>
                                                        )
                                                      )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="Button text-center">
                                            <button className="Create" onClick={()=>setSelectedOption("New Vendor")}>
                                              Confirm Selection
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                      <div className="TableHeadingArea mt-5">
                                        <div className="FilesHead">
                                          <div className="FilesLeft">
                                            <h4>Authorized Vendors</h4>
                                          </div>
                                          <div className="FilesRight d-flex align-items-center">
                                            <div className="Search">
                                              <span>
                                                <img src="images/search.png" />
                                              </span>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search"
                                                name="search"
                                                value={search}
                                                onChange={handleInputChange}
                                              />
                                            </div>
                                            <div>
                                              <a href="javascript:void(0);">
                                                <img src="images/pdf.png" />
                                              </a>
                                              <a href="javascript:void(0);">
                                                <img src="images/download.png" />
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="TableList TableListAlign TableListHeader NewTableList TableHeightScroll VendorTable TableHeight500">
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>Name</th>
                                              <th>Type</th>
                                              <th>Primary Contact's Name</th>
                                              <th>Primary Email</th>
                                              <th>Primary Phone Number</th>
                                              <th>
                                                Permission Granted to Receive
                                                SMS
                                              </th>

                                              <th>Investor View Status</th>
                                              <th>Assigned Jobs/Projects</th>
                                              <th># of Trade View Users</th>

                                              <th>File Manager App</th>
                                              <th>Reports App</th>
                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {vendorInvestorAuthorizedList
                                              ?.pagination?.data?.length > 0 ? (
                                              vendorInvestorAuthorizedList?.pagination?.data
                                                ?.filter((data) =>
                                                  search.toLowerCase() === ""
                                                    ? true
                                                    : data.vendorName &&
                                                      data.vendorName
                                                        .toLowerCase()
                                                        .includes(
                                                          search.toLowerCase()
                                                        )
                                                )
                                                .map((data, i) => {
                                                  return (
                                                    <tr key={i}>
                                                      <td>{data.vendorName}</td>
                                                      <td>{data.vendorType}</td>
                                                      <td>{data.phone}</td>
                                                      <td>{data.email}</td>
                                                      <td>{data.phone}</td>
                                                      <td>
                                                        Yes{" "}
                                                        <img
                                                          onClick={() => {
                                                            SetSmsInfoModal(
                                                              true
                                                            );
                                                            setUserData(data);
                                                          }}
                                                          src="images/icon_62.png"
                                                        />
                                                      </td>
                                                      {
                                                        investorAccessShow?
                                                        <td>{data.VendorAccessRequest?.investerViewStatus?"On":"Off"}</td>
                                                        : <td>
                                                        <label className="switch">
                                                          <input
                                                            type="checkbox"
                                                            checked={investerViewStatus}
                                                            onChange={(e)=>handleInvestorCheckboxChange(e,data?.VendorAccessRequest?.id,"investerViewStatus")}
                                                          />
                                                          <span className="slider" />
                                                        </label>
                                                      </td>
                                                      }
                                                     
                                                      <td>
                                                        <a
                                                          href="javascript:void(0);"
                                                          data-toggle="modal"
                                                          data-target="#VendorAssignedJobsProjects"
                                                        >
                                                          <img src="images/create_building.png" />
                                                          <span className="Count">
                                                            3
                                                          </span>
                                                        </a>
                                                      </td>
                                                      <td>{data?.VendorAccessRequest?.noInvestorViewUsers||0}</td>
                                                      {
                                                        
                                                          investorAccessShow?
                                                          <td>{data.VendorAccessRequest?.fileManagerApp?"On":"Off"}</td>
                                                          : <td>
                                                          <label className="switch">
                                                            <input
                                                              type="checkbox"
                                                             
                                                              checked={fileManagerApp}
                                                              onChange={(e)=>handleInvestorCheckboxChange(e,data?.VendorAccessRequest?.id,"fileManagerApp")}
                                                            />
                                                            <span className="slider" />
                                                          </label>
                                                        </td>
                                                        }
                                                      
                                                      {
                                                        investorAccessShow?
                                                        <td>{data.VendorAccessRequest?.reportsApp?"On":"Off"}</td>
                                                        : <td>
                                                        <label className="switch">
                                                          <input
                                                            type="checkbox"
                                                           
                                                            checked={reportsApp}

                                                            onChange={(e)=>handleInvestorCheckboxChange(e,data?.VendorAccessRequest?.id,"reportsApp")}
                                                          />
                                                          <span className="slider" />
                                                        </label>
                                                      </td>
                                                      }

                                                   
                                                      <td>
                                                        <div className="TableThreeDots TableSlider">
                                                          <ul className="">
                                                            <li className="dropdown">
                                                              <a
                                                                className=""
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                              >
                                                                <img src="images/dots.png" />
                                                              </a>
                                                              <ol className="dropdown-menu dropdown-menuwidth2">
                                                                <li>
                                                                  <Link to="/investor-view-login">
                                                                    <img src="images/Action-1.png" />{" "}
                                                                    Investor
                                                                    View Preview
                                                                  </Link>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    onClick={() =>
                                                                      handleInvestorViewUsersModalShow(
                                                                        data.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <img src="images/view_trade_view.png" />
                                                                    Manage
                                                                    Investor
                                                                    View users
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    onClick={() =>
                                                                      handleEnableInvestor(
                                                                        data.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <label className="switch">
                                                                      {" "}
                                                                      Enable
                                                                      Investor
                                                                      View
                                                                      Access
                                                                      {/* <input type="checkbox" />
                                                           <span className="slider" /> */}
                                                                    </label>
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    onClick={() =>
                                                                      handleDisableInvestor(
                                                                        data.id
                                                                      )
                                                                    }
                                                                  >
                                                                    <label className="switch">
                                                                      {" "}
                                                                      Disable
                                                                      Investor
                                                                      View
                                                                      Access
                                                                      {/* <input type="checkbox" />
                                                           <span className="slider" /> */}
                                                                    </label>
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    onClick={() =>
                                                                      handleRemoveInvestorUser(
                                                                        data.id,
                                                                        "invest"
                                                                      )
                                                                    }
                                                                  >
                                                                    <img src="images/dropdown-delete.png" />
                                                                    Remove
                                                                    Investor
                                                                  </a>
                                                                </li>
                                                              </ol>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                })
                                            ) : (
                                              <tr>
                                                <td colSpan="4">
                                                  No data found
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="PaginationArea mb-4">
                                        <h6>Showing 3 of 27 entries</h6>
                                        <h6>
                                          <img src="images/leftarrow.png" /> 01{" "}
                                          <img src="images/rightarrow.png" />{" "}
                                          Out 10
                                        </h6>
                                      </div>

                                    </div>
                                    
                                     
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#NotificationsSetupGeneralConfig"
                                  >
                                    <span>
                                      <img src="images/notification.png" />
                                    </span>{" "}
                                    Notifications Setup
                                  </h4>
                                  <a
                                    className="Edit EditViewConfigBodyBtn"
                                    onClick={handleInvestorNotificationToggle}
                                  >
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="NotificationsSetupGeneralConfig"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    {investorNotificationsShow ? (
                                      <div className="ViewConfigBodyMain">
                                        <div className="ViewConfigBody">
                                          <aside>
                                            <p>
                                              <label>Email </label>
                                              <span>
                                                {generalConfigInvestorView
                                                  ?.ViewSetting
                                                  ?.emailNotifications === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>Notifications </label>
                                              <span>
                                                {generalConfigInvestorView
                                                  ?.ViewSetting
                                                  ?.notifications === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                            <p>
                                              <label>Push </label>
                                              <span>
                                                {generalConfigInvestorView
                                                  ?.ViewSetting
                                                  ?.pushNotifications === true
                                                  ? "On"
                                                  : "Off"}
                                              </span>
                                            </p>
                                          </aside>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="ViewConfigBodyHideMain">
                                        <div className="ViewConfigBodyHide">
                                          <aside>
                                            <label className="switch">
                                              {" "}
                                              Email
                                              <input
                                                type="checkbox"
                                                checked={emailNotificationss}
                                                onClick={() =>
                                                  handleInvestorViewCheck(
                                                    "emailNotificationss"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Notifications
                                              <input
                                                type="checkbox"
                                                checked={notificationss}
                                                onClick={() =>
                                                  handleInvestorViewCheck(
                                                    "notificationss"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                            <label className="switch">
                                              {" "}
                                              Push
                                              <input
                                                type="checkbox"
                                                checked={pushNotificationss}
                                                onClick={() =>
                                                  handleInvestorViewCheck(
                                                    "pushNotificationss"
                                                  )
                                                }
                                              />
                                              <span className="slider" />
                                            </label>
                                          </aside>
                                        </div>
                                        <div className="Button d-flex justify-content-center">
                                          <button
                                            className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleInvestorNotificationToggle
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="Respond ml-3 SaveViewConfigBodyBtn"
                                            onClick={
                                              handleGeneralInvestorNotificationUpdate
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="Reports">
                        <div className="ResidentialJobArea ViewConfigArea">
                          <div className="ModalAccordian">
                            <div id="accordion">
                              <div className="card">
                                <div className="card-header">
                                  <h4
                                    data-toggle="collapse"
                                    href="#TodoSettings1"
                                  >
                                    <span>
                                      <img src="images/reports-access.png" />
                                    </span>{" "}
                                    Menu Apps
                                  </h4>
                                  <a className="Edit EditViewConfigBodyBtn">
                                    <img src="images/Action-1.png" />
                                  </a>
                                </div>
                                <div
                                  id="TodoSettings1"
                                  className="collapse show"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body">
                                    <div className="ViewConfigBodyMain">
                                      <div className="ViewConfigBody">
                                        <aside className="aside60">
                                          <div className="form-group">
                                            <h6>Investor Name</h6>
                                            <div className="Categories Categories1">
                                              <div className="Categories_all Categories_all1">
                                                <span>
                                                  Select a Vendor Name
                                                </span>
                                                <img src="images/down.png" />
                                              </div>
                                              <ul>
                                                <li>
                                                  <input
                                                    type="text"
                                                    placeholder="-Search"
                                                  />
                                                  <span className="Search">
                                                    <img src="images/search.png" />
                                                  </span>
                                                </li>
                                                <li className="CursorPointer">
                                                  - Full list of investors
                                                  authorized to Investor View
                                                  for this Job
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="TableHeadingArea mt-5">
                                            <div className="FilesHead">
                                              <div className="FilesLeft">
                                                <h4>Reports Access List</h4>
                                              </div>
                                              <div className="FilesRight d-flex align-items-center">
                                                <div className="Search">
                                                  <span>
                                                    <img src="images/search.png" />
                                                  </span>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search"
                                                  />
                                                </div>
                                                <div>
                                                  <a href="javascript:void(0);">
                                                    <img src="images/pdf.png" />
                                                  </a>
                                                  <a href="javascript:void(0);">
                                                    <img src="images/download.png" />
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="TableList TableListAlign TableListHeader NewTableList TableHeightScroll">
                                            <table>
                                              <thead>
                                                <tr>
                                                  <th>Report Name</th>
                                                  <th>Report Type</th>
                                                  <th>Report Module</th>
                                                  <th>Access</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Sales 1</td>
                                                  <td>Financial</td>
                                                  <td>Sales</td>
                                                  <td>On</td>
                                                </tr>
                                                <tr>
                                                  <td>Sales 1</td>
                                                  <td>Financial</td>
                                                  <td>Sales</td>
                                                  <td>On</td>
                                                </tr>
                                                <tr>
                                                  <td>Sales 1</td>
                                                  <td>Financial</td>
                                                  <td>Sales</td>
                                                  <td>On</td>
                                                </tr>
                                                <tr>
                                                  <td>Sales 1</td>
                                                  <td>Financial</td>
                                                  <td>Sales</td>
                                                  <td>On</td>
                                                </tr>
                                                <tr>
                                                  <td>Sales 1</td>
                                                  <td>Financial</td>
                                                  <td>Sales</td>
                                                  <td>On</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="PaginationArea mb-4">
                                            <h6>Showing 3 of 27 entries</h6>
                                            <h6>
                                              <img src="images/leftarrow.png" />{" "}
                                              01{" "}
                                              <img src="images/rightarrow.png" />{" "}
                                              Out 10
                                            </h6>
                                          </div>
                                        </aside>
                                      </div>
                                    </div>
                                    <div className="ViewConfigBodyHideMain">
                                      <div className="ViewConfigBodyHide">
                                        <aside className="aside60">
                                          <div className="form-group">
                                            <h6>Investor Name</h6>
                                            <div className="Categories Categories1">
                                              <div className="Categories_all Categories_all1">
                                                <span>
                                                  Select a Vendor Name
                                                </span>
                                                <img src="images/down.png" />
                                              </div>
                                              <ul>
                                                <li>
                                                  <input
                                                    type="text"
                                                    placeholder="-Search"
                                                  />
                                                  <span className="Search">
                                                    <img src="images/search.png" />
                                                  </span>
                                                </li>
                                                <li className="CursorPointer">
                                                  - Full list of investors
                                                  authorized to Investor View
                                                  for this Job
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="TableHeadingArea mt-5">
                                            <div className="FilesHead">
                                              <div className="FilesLeft">
                                                <h4>Reports Access List</h4>
                                              </div>
                                              <div className="FilesRight d-flex align-items-center">
                                                <div className="Search">
                                                  <span>
                                                    <img src="images/search.png" />
                                                  </span>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search"
                                                  />
                                                </div>
                                                <div>
                                                  <a href="javascript:void(0);">
                                                    <img src="images/pdf.png" />
                                                  </a>
                                                  <a href="javascript:void(0);">
                                                    <img src="images/download.png" />
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="TableList TableListAlign TableListHeader NewTableList TableHeightScroll">
                                            <table>
                                              <thead>
                                                <tr>
                                                  <th>Report Name</th>
                                                  <th>Report Type</th>
                                                  <th>Report Module</th>
                                                  <th>Access</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Sales 1</td>
                                                  <td>Financial</td>
                                                  <td>Sales</td>
                                                  <td>
                                                    <label className="switch w-auto">
                                                      <input type="checkbox" />
                                                      <span className="slider" />
                                                    </label>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Sales 1</td>
                                                  <td>Financial</td>
                                                  <td>Sales</td>
                                                  <td>
                                                    <label className="switch w-auto">
                                                      <input type="checkbox" />
                                                      <span className="slider" />
                                                    </label>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Sales 1</td>
                                                  <td>Financial</td>
                                                  <td>Sales</td>
                                                  <td>
                                                    <label className="switch w-auto">
                                                      <input type="checkbox" />
                                                      <span className="slider" />
                                                    </label>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Sales 1</td>
                                                  <td>Financial</td>
                                                  <td>Sales</td>
                                                  <td>
                                                    <label className="switch w-auto">
                                                      <input type="checkbox" />
                                                      <span className="slider" />
                                                    </label>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Sales 1</td>
                                                  <td>Financial</td>
                                                  <td>Sales</td>
                                                  <td>
                                                    <label className="switch w-auto">
                                                      <input type="checkbox" />
                                                      <span className="slider" />
                                                    </label>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="PaginationArea mb-4">
                                            <h6>Showing 3 of 27 entries</h6>
                                            <h6>
                                              <img src="images/leftarrow.png" />{" "}
                                              01{" "}
                                              <img src="images/rightarrow.png" />{" "}
                                              Out 10
                                            </h6>
                                          </div>
                                        </aside>
                                      </div>
                                      <div className="Button d-flex justify-content-center">
                                        <button className="OutlineBlack mr-3 SaveViewConfigBodyBtn">
                                          Cancel
                                        </button>
                                        <button className="Respond ml-3 SaveViewConfigBodyBtn">
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={TradeViewAccessRequestModal}
          onHide={handleTradeViewAccessRequestModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleTradeViewAccessRequestModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Trade View Access Request</h4>
              </div>
              <div className="ModalBigheading">
                {/* <h3>
                  <span>Job:</span> UUnit2-CHI-2B-314
                </h3> */}
              </div>
              <div className="TableList TableListAlign CommonHeading TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Vendor Name</th>
                      <th>Request Date</th>
                      <th colSpan={2}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                      <td>Vendor 1</td>
                      <td>07/28/2023</td>
                      <td>Accept</td>
                      <td>Reject</td>
                    </tr> */}
                    <tr>No data found</tr>
                  </tbody>
                </table>
              </div>
              <div className="PaginationArea">
                <h6>Showing 3 of 27 entries</h6>
                <h6>
                  <img src="images/leftarrow.png" /> 01{" "}
                  <img src="images/rightarrow.png" /> Out 10
                </h6>
              </div>
              <div
                className="Button text-center mt-4"
                onClick={handleTradeViewAccessRequestModalClose}
              >
                <button className="Outline">Close</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={VendorAssignedJobsProjectsModal}
          onHide={handleVendorAssignedJobsProjectsModalClose}
          className="ModalBox modal fade MediumModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleVendorAssignedJobsProjectsModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Vendor Assigned Jobs &amp; Projects</h4>
              </div>
              <div className="ModalMediumheading d-lg-flex justify-content-lg-between flex-column">
                <div>
                  {/* <h3 className="mb-0">
                    <span>Vendor:</span> Vendor 1
                  </h3> */}
                  <h3>
                    <span>View Type:</span> Trade View
                  </h3>
                </div>
                <div className="TodosButtons">
                  <a
                    className="FolderPermissionId"
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#AssignJobsProject"
                    data-dismiss="modal"
                  >
                    <h6
                      className="mb-0 text-white"
                      onClick={handleAssignJobsProjectModalShow}
                    >
                      <img src="images/file-manager.png" />
                      Assign New Project/Job
                    </h6>
                  </a>
                </div>
              </div>
              <div className="NotesArea mb-2">
                <h6>Assigned Jobs &amp; Projects</h6>
                <div>
                  <a href="javascript:void(0);" title="pdf">
                    <img src="images/pdf.png" />
                  </a>
                  <a href="javascript:void(0);" title="download">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);" title="settings">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="TableList CommonHeading TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Project</th>
                      <th>Job</th>
                      <th>Assigned By</th>
                      <th>Assigned Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignVendorJobProjectList?.data?.data?.length > 0 ? (
                      assignVendorJobProjectList?.data?.data?.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.Project?.jobNumber}</td>
                              <td>{item.job?.jobNumber}</td>
                              <td>{item.assignedByUserDetails?.name||item.assignedByUserDetails?.email}</td>
                              <td>
                                {moment(item.createdAt).format("YYYY-MM-DD")}
                              </td>
                              <td>
                                <div className="TableThreeDots">
                                  <ul className="">
                                    <li className="dropdown">
                                      <a
                                        className=""
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" />
                                      </a>
                                      <ol className="dropdown-menu dropdown-menuwidth">
                                        <li>
                                          <a
                                            onClick={() =>
                                              handleDeleteShow(item.id)
                                            }
                                          >
                                            <img src="images/cancel_qr.png" />
                                            Remove Assignment
                                          </a>
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td colSpan="4">No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="Button text-center mt-4">
                <button
                  className="Outline ClosePermissionId"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={AssignJobsProjectModal}
          onHide={handleAssignJobsProjectModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleAssignJobsProjectModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Assign Jobs/Project</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>Project</h6>
                  <div className="Categories Categories1">
                    <div className="Categories_all Categories_all1">
                      <span>Select projects</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input
                          type="text"
                          placeholder="-Search"
                          name="projectSearch"
                          value={projectSearch}
                          onChange={handleInputChange}
                        />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>

                      {allProjectList?.projects?.length > 0 &&
                        allProjectList?.projects
                          ?.filter((data) =>
                            projectSearch.toLowerCase() === ""
                              ? true
                              : data.projectName
                                  .toLowerCase()
                                  .includes(projectSearch.toLowerCase())
                          )
                          .map((item, i) => {
                            return (
                              <li
                                // value={item.id}
                                key={i}
                                onClick={() => handleItemClick(item.id)}
                                style={{
                                  backgroundColor:
                                    ProjectId === item.id
                                      ? "#e71e09"
                                      : "transparent",
                                }}
                              >
                                {item.projectName}
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                </div>
                <div className="form-group">
                  <h6>Job</h6>
                  <div className="Categories Categories1">
                    <div className="Categories_all Categories_all1">
                      <span>Select Job</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input
                          type="text"
                          placeholder="-Search"
                          name="jobSearch"
                          value={jobSearch}
                          onChange={handleInputChange}
                        />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      {projectJobList?.jobs?.length > 0 &&
                        projectJobList?.jobs
                          ?.filter((data) =>
                            jobSearch.toLowerCase() === ""
                              ? true
                              : data.jobType
                                  .toLowerCase()
                                  .includes(jobSearch.toLowerCase())
                          )
                          .map((item, ind) => (
                            <li key={ind}>
                              <label className="CheckBox">
                                {" "}
                                {item.jobNumber}
                                <input
                                  type="checkbox"
                                  checked={selectedItems.includes(item)}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, item)
                                  }
                                />
                                <span className="checkmark" />
                              </label>
                            </li>
                          ))}
                    </ul>
                  </div>
                </div>
                <div className="form-group mb-2">
                  <h4>Selected Jobs</h4>
                  {selectedItems?.length > 0 &&
                    selectedItems?.map((data) => {
                      return (
                        <div className="FilesAdded">
                          <label>{data.jobNumber}</label>
                          {/* <span className="cross">
                        <img src="images/Cross.png" />
                      </span> */}
                        </div>
                      );
                    })}
                </div>

                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={handleAssignJobsProjects}>
                    Assign Jobs/Project
                  </button>
                  <button
                    className="Outline"
                    onClick={handleAssignJobsProjectModalClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={DeleteModal}
          onHide={handleDeleteClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleDeleteClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Delete</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="my-5">
                      <h6>Are you sure you want to delete this ?</h6>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create" onClick={handleDeleteAssign}>
                        Delete
                      </button>
                      <button className="Outline" onClick={handleDeleteClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={TradeViewUsersModal}
          onHide={handleTradeViewUsersModalClose}
          className="ModalBox ExtraLargeModal"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleTradeViewUsersModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Trade View Users</h4>
              </div>
              <div className="ModalBigheading">
                {/* <h3>
                  <span>Job:</span> UUnit2-CHI-2B-314
                </h3>
                <h3>
                  <span>Vendor:</span> Vendor 1
                </h3> */}
              </div>
              <div className="Tableheader mb-2">
                <h6>Registered Users</h6>
              </div>
              <div className="TableList CommonHeading TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Role</th>
                      <th>Position</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vendorUserList?.userList?.length > 0 ? (
                      vendorUserList?.userList?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phoneNumber}</td>
                            <td>N/A</td>
                            <td>{item.position ?? "N/A"}</td>
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol
                                      className="dropdown-menu dropdown-menuwidth"
                                      style={{}}
                                    >
                                      <li>
                                        <a href="javascript:void(0);">
                                          <img src="images/reset_password.png" />
                                          Reset Password
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4">No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="Button text-center mt-4"
                onClick={handleTradeViewUsersModalClose}
              >
                <button className="Outline">Close</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={InvestorViewUsersModal}
          onHide={handleInvestorViewUsersModalClose}
          className="ModalBox ExtraLargeModal"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleInvestorViewUsersModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Investor View Users</h4>
              </div>
              <div className="ModalBigheading">
                {/* <h3>
                  <span>Job:</span> UUnit2-CHI-2B-314
                </h3>
                <h3>
                  <span>Vendor:</span> Vendor 1
                </h3> */}
              </div>
              <div className="Tableheader mb-2">
                <h6>Registered Users</h6>
              </div>
              <div className="TableList CommonHeading TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Role</th>
                      <th>Position</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {innvestorVendorUserList?.userList?.length > 0 ? (
                      innvestorVendorUserList?.userList?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phoneNumber}</td>
                            <td>N/A</td>
                            <td>{item.position ?? "N/A"}</td>
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol
                                      className="dropdown-menu dropdown-menuwidth"
                                      style={{}}
                                    >
                                      <li>
                                        <a href="javascript:void(0);">
                                          <img src="images/reset_password.png" />
                                          Reset Password
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4">No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="Button text-center mt-4"
                onClick={handleInvestorViewUsersModalClose}
              >
                <button className="Outline">Close</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={JobMenuModal}
          onHide={handleJobMenuModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleJobMenuModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Job Menu</h4>
              </div>
              <div className="FolderForm">
                <div className="JobMenuListing">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <figure>
                          <img src="images/add_build.png" />
                        </figure>
                        Add to/Edit Build
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <figure>
                          <img src="images/details.png" />
                        </figure>
                        Details
                      </a>
                    </li>
                    <li className="JobMenuListing_All">
                      <Link to="/change-order-list" state={state}>
                        <figure>
                          <img src="images/change_order.png" />
                        </figure>
                        Change Order
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                      <ul className="JobMenuListingUnder">
                        <li>
                          <Link to="/change-order-list" state={state}>
                            Change orders List
                          </Link>
                        </li>
                        <li>
                          <Link to="/change-order-list" state={state}>
                            Change to Contract (CTC) master
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/design-selections" state={state}>
                        <figure>
                          <img src="images/design.png" />
                        </figure>
                        Design
                      </Link>
                    </li>
                    <li>
                      <Link to="/sales" state={state}>
                        <figure>
                          <img src="images/sales.png" />
                        </figure>
                        Sales
                      </Link>
                    </li>
                    <li>
                      <Link to="/estimation" state={state}>
                        <figure>
                          <img src="images/estimation.png" />
                        </figure>
                        Estimation
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/construction" state={state}>
                        <figure>
                          <img src="images/construction_icon.png" />
                        </figure>
                        Construction
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/warranty-dashboard" state={state}>
                        <figure>
                          <img src="images/warranty.png" />
                        </figure>
                        Warranty
                      </Link>
                    </li>
                    <li>
                      <Link to="/reports" state={state}>
                        <figure>
                          <img src="images/reports.png" />
                        </figure>
                        Reports
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>

                    <li>
                      <Link to="/client-view-config" state={state}>
                        <figure>
                          <img src="images/view_config.png" />
                        </figure>
                        Views Config
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={NavigateModal}
          onHide={handleNavigateModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleNavigateModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Navigate Project</h4>
              </div>
              <div className="FolderForm">
                <div className="NaviagateProjectArea">
                  <ul>
                    {allProjectList?.projects?.length > 0 &&
                      allProjectList?.projects.map((item, index) => {
                        return (
                          <>
                            <li className="DarkBlueList AvalonTownsTab">
                              <a href="javascript:void(0);">
                                {item.projectName}
                              </a>
                            </li>
                            <li className="LightBlueList AdministrationTab">
                              <a href="javascript:void(0);">
                                {item.jobPattern}
                              </a>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth DropDownMenuZindex">
                                      <li>
                                        <a>
                                          <img src="images/add_build.png" />
                                          Add to/Edit Build
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <img src="images/details.png" />
                                          Details
                                        </a>
                                      </li>
                                      <li className="border-0">
                                        <Link
                                          to="/change-order-list"
                                          state={state}
                                          className="dropdown-item"
                                          style={{
                                            border:
                                              "1px solid transparent!important",
                                          }}
                                        >
                                          <img src="images/change_order.png" />
                                          Change Order &nbsp;&nbsp;
                                          <img src="images/rightarrow2.png" />
                                        </Link>
                                        <ul className="dropdown-menu submenu">
                                          <li>
                                            <Link
                                              to="/change-order-list"
                                              state={state}
                                              className="dropdown-item"
                                            >
                                              Change orders List
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to="/change-order-list"
                                              state={state}
                                              className="dropdown-item"
                                            >
                                              Change to Contract (CTC) master
                                            </Link>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <Link
                                          to="/design-selections"
                                          state={state}
                                        >
                                          <img src="images/design.png" />
                                          Design
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/sales" state={state}>
                                          <img src="images/sales.png" />
                                          Sales
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/estimation" state={state}>
                                          <img src="images/estimation.png" />
                                          Estimation
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/construction" state={state}>
                                          <img src="images/construction_icon.png" />
                                          Construction
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/warranty-dashboard"
                                          state={state}
                                        >
                                          <img src="images/warranty.png" />
                                          Warranty
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/reports" state={state}>
                                          <img src="images/reports.png" />
                                          Reports
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/client-view-config"
                                          state={state}
                                        >
                                          <img src="images/view_config.png" />
                                          Views Config
                                        </Link>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {SmsInfoModal && (
        <SmsInfo
          SmsInfoModal={SmsInfoModal}
          SetSmsInfoModal={SetSmsInfoModal}
          userData={userData}
        />
      )}
    </>
  );
};

export default VendorViewConfig;
