import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { saveAs } from "file-saver";
import { useReactToPrint } from "react-to-print";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import {
  AddDiscussionAction,
  AddReplyAction,
  discussionListAction,
} from "../redux/action/constructionsAction";
import { checkArray } from "../utils/CheckType";
import _, { assign, initial, truncate, update } from "lodash";
import {
  buildingMultiAdminLoginInitiate,
  createMessageAction,
  createRoomAction,
  getCitiesList,
  getCountriesList,
  getMessagesAction,
  getStateList,
  subDivisionListAction,
} from "../redux/action/authAction";
import {
  ContractDetailsAction,
  SOAHistoryAction,
  SupportingDocsDetailsAction,
  SurveyDetailsAction,
  addAllSchedulePaymentAction,
  addAllSchedulePaymentTransactionAction,
  addFollowUpAction,
  addLeadsAction,
  addParkingAction,
  addSOAAction,
  addSupportingDocsAction,
  addUnlockCondtionAction,
  cancelFollowUpDetailsAction,
  changeUnlockConditionalStatusAction,
  checkUncheckContractList,
  convertToContractAction,
  createClientDraft,
  deleteDraftTemplate,
  getAllClientAction,
  getAllContractsListAction,
  getAllFollowUpDetailsAction,
  getAllLeadsListAction,
  getAllSchedulePaymentListAction,
  getAllScheduleTransactionPaymentListAction,
  getAllStaffListAction,
  getAllfollowUpListAction,
  getAllsupportingDocsListAction,
  getClientAssingedAction,
  getClientCtcByTemplate,
  getClientCtcGenerateHistory,
  getGrossMargin,
  getLeadDetailsAction,
  getParkingListAction,
  getSOADetailsAction,
  getSalesTypeListAction,
  getTemplateReleaseList,
  getUnlockConditonListAction,
  importContactFileAction,
  removeParkingAction,
  removeScheduledAction,
  removeSupportingDocsAction,
  sendFollowUpInvitationAction,
  sendForClientSignatures,
  sendForClientsSignature,
  setReminderAction,
  updateAllSchedulePaymentListAction,
  updateAssignUnitAction,
  updateConditionalDetailsAction,
  updateContactAction,
  updateFollowUpAction,
  updateLeadAction,
  updateSupportingDocsAction,
  updateSurveyAction,
  addMainContractAction,
  getBuilderSigneeList,
  generatedDocumentForClientSignature,
  generateClientCTC,
  getAllSignature,
  addClientAction,
  getClientDocTemplates,
  getConverToString,
  sendSmsToLead,
  removedAssignUnits,
  updateContactDetails,
  sendNewEmails,
  clientDocTemplates,
  addPaymentSchedules,
  removeReleaseCTCAction,
} from "../redux/action/saleAction";
import { errorToast, sucessToast } from "../utils/toast";
import Loader from "./Loader";
import moment from "moment/moment";
import NoDataFound from "./Nodatafound";
import Multiselect from "multiselect-react-dropdown";
import {
  addFilesAction,
  addFolderAction,
  addSignatureAction,
  changeStatusAction,
  folderHistoryAction,
  getAllFileListAction,
  getAllFolderListAction,
  getAllPendingFileListAction,
  getClientUserListAction,
  removeFolderAction,
  searchFolderListAction,
  sendClientInformationAction,
  shareDocumentAction,
} from "../redux/action/clientDocumentationAction";
import { formToJSON } from "axios";
import {
  ContactListAction,
  VendorListAction,
  VendorSearchAction,
  VendorTypesAction,
} from "../redux/action/vendorAction";
import { logDOM } from "@testing-library/react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import SignaturePad from "react-signature-canvas";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  addGoogleCalendayAction,
  addOutlookCalendayAction,
  addYahooAction,
  updateTradeSuccess,
} from "../redux/action/todoAction";
import {
  getAllDocumentKeyList,
  getHtmlFile,
} from "../redux/action/fileManagerAction";
import { toast } from "react-toastify";
import {
  deleteFollowUps,
  getFollowUpsList,
  sendInvitation,
} from "../redux/action/accountingAction";
import {
  getPrimaryClient,
  getSecondaryClient,
  sendForClientSignature,
  uploadAggrementFiles,
} from "../redux/action/designSelectionsAction";
import { convertToPNG, dataURLtoBlob } from "../utils/blog";
import { updateLandDevelopmentAction } from "../redux/action/jobAction";
import ContactDetails from "./headerModals/contactDetails";
import { getMaxDate } from "../utils/constant";
import { getTaxProfileList } from "../redux/action/companyAction";
import CTCHistoryModal from "./SalesCtc/CTCHistoryModal";
import RejectCtcDocModal from "./SalesCtc/RejectCtcDoc";
import UpdateLeadModal from "./UpdateLeadModal";
import ViewTemplate from "./ViewTemplate";
const initModal = {
  AddLeadModal: false,
  contactInformationModal: false,
  assignSaleModal: false,
  AddContractModal: false,
  ClientDocumentationModal: false,
  sendClientInformationModal: false,
  createClientCTCModal: false,
  manageSignatureModal: false,
  folderHistoryModal: false,
  documentHistoryModal: false,
  discussionModal: false,
  editFileModal: false,
  shareDocumentsModal: false,
  editLeadDetailsModal: false,
  editContactInformationModal: false,
  editSurveyModal: false,
  supportingDocsModal: false,
  SystemVendorModal: false,
  viewDocumentModal: false,
  locationModal: false,
  createNewFileModal: false,
  uploadFilesModal: false,
  leadClientDetailsShow: false,
  editActionLeadDetailsModal: false,
  scheduleNewFollowModal: false,
  convertLeadToContractModal: false,
  editFollowUpActivityModal: false,
  addItineraryModal: false,
  setReminderModal: false,
  modalState: "",
  newEmailModal: false,
  newTextModal: false,
  adjustmentStatementModal: false,
  supportingDocumentListShow: false,
  mainContractEditModal: false,
  contractAssignUnitModal: false,
  contractTransactionsModal: false,
  contractSchedulePaymentsModal: false,
  contractAddSupportingModal: false,
  ContractSupportingDocsModal: false,
  editSupportingModal: false,
  scheduledPaymentModal: false,
  modalId: "",
  secondaryId: "",
  thirdId: "",
  leadClientDetailsDataTemp: [],
  setReminderState: "",
  addFolderModal: false,
  addSurveyModal: false,
  showHideCheck: true,
  SOAModal: false,
  addUnlockConditionalModal: false,
  unlockConditonListModal: false,
  clientAssignUnitModal: false,
  SOAHistoryModal: false,
  heading: "",
  avjobId: "",
  DocumentKeyModal: false,
  CTCTemplateReleasedModal: false,
  ViewCtcModal: false,
  FollowUpActivityModal: false,
  SendForClientModal: false,
  assignsaleDetailsModal: false,
  clientSignatureModal: false,
  clientHistoryModal: false,
  assignsaleDetailsModal: false,
  primarySignature: null,
  secondarySignature: null,
  additionalSignature: null,
  builderSignature: null,
  ManageSignature: false,
  signTypes: "",
  primarySignatureBlob: "",
  secondarySignatureBlob: "",
  builderSignatureBlob: "",
  additionalSignatureBlob: "",
  fileNames: "",
  SignModal: false,
  afterDraftViewCTCData: [],
  addClientModal: false,
  ctcSignature: "",
  ctcSignatureBlob: "",
  ctcSignatureBuilder: "",
  ctcSignatureBuilderBlob: "",
};
const leadInitState = {
  name: "",
  clientView: "",
  legalName: "",
  streetAddress: "",
  primaryPhoneNum: "",
  addressLineTwo: "",
  email: "",
  city: "",
  businessPhoneNum: "",
  zipCode: "",
  faxPhoneNum: "",
  country: "",
  preferredCommu: "",
  province: "",
  subDivision: "",
  leadStatus: "",
  confidenceLevel: "",
  referrerName: "",
  referrerAgency: "",
  referrerEmail: "",
  assignStaffId: [],
  surveys: [],
  leadSource: "",
  purchasePlaning: "",
  currentHomeStyle: "",
  preferredPrice: "",
  currentHomeSize: "",
  reasonToMove: "",
  seekingHomeSize: "",
  seekingHomeStyle: "",
  seekingHomeSite: "",
  beenSearching: "",
  moreInfo: false,
  mortgageAssistance: false,
  realtorAssistance: false,
  survey: "",
  file: "",
  UserId: "",
  sendType: "",
  location: "",
  activityDate: "",
  fromTime: "",
  toTime: "",
  setReminder: "",
  sendTo: "",
  emailData: [],
  discussionError: {},
  leadErrors: {},
  leadDetailsData: [],
  followUpDetailsData: [],
  surveyErrors: {},
  folderName: "",
  folderId: "",
  folderError: {},
  clientDocumentationFile: "",
  clientDocumnetationError: {},
  files: "",
  folderIdLocal: "",
  emailBody: "",
  clientInfomationError: {},
  signedDocs: [],
  sendInformationName: "",
  sendInformationEmail: "",
  vendorSearchData: [],
  vendorType: "",
  signature: "",
  initial: "",
  tempAssignList: [],
  signatureErrors: {},
  supportingDocsError: "",
  saleType: "",
  clientAssiUnit: "",
  scheOfPayDate: "",
  transactionType: "",
  tranId: "",
  tranDate: "",
  payMethod: "",
  tranType: "",
  total: "",
  notes: "",
  suppDoc: "",
  tranStatus: "",
  scheduledPaymentError: "",
  scheduledTransactionPaymentError: "",
  shareDocumentationError: {},
  ProjectId: "",
  jobId: "",
  contractDetailsData: [],
  price: "",
  description: "",
  clientPurchasePrice: "",
  isRebate:false,
  depositAmount: "",
  downPayPercent: "",
  documentSearch: "",
  satisCondition: {
    depositPaid: false,
    depositPaidStatus: false,

    downPayPaid: false,
    downPayPaidStatus: false,

    contractSigned: false,
    contractSignedStatus: false,

    finanConfirmed: false,
    finanConfirmedStatus: false,

    waiverOfConSigned: false,
    waiverOfConSignedStatus: false,
  },
  reason: "",
  requestApproverId: "",
  requestApprover: "",
  SOADetailsData: [],
  templateId: "",
  // setReminderState:""
  // secondaryId:"",
  leadId: "",
  primarySignee: "",
  secondarySignee: "",
  numberOfSignee: "1",
  docsId: "",
  message: "",
  roomId: "",
  senderId: "",
  receiverId: "",
  message: "",
  fileNameStatic: "",
  purchaseDetailsId: "",
  unitDiscount: "",
  isChecked: "",
  quantity: "",
  clientSignature: "",

  receiverId: "",
  message: "",
  fileNameStatic: "",
  builderSignee: "",
  builderSignStatus: "",
  primarySignStatus: "",
  secondarySignee: "",
  secondarySignStatus: "",
  additionalSignee: "",
  additionalSignStatus: "",
  purchaseDetailId: "",
  slectedClient: "",
  clientDocId: "",
  messageBody: "",
  updateDate: "",
  jobNumbers: "",
  trnsId: "",
  jobType: "",
  JobId: "",
  contID: "",
  leaId:"",
  userInfo:"",
};
const SalesModule = () => {
  const signCanvas = useRef({});
  const intialCanvas = useRef({});

  const [signedDate, setSignedDate] = useState("");
  const [signedDateBuilder, setSignedDateBuilder] = useState("");

  const [clientCtcByTemplateData, setClientCtcByTemplateData] = useState({});
  const [ContactDetailsModal, setContactDetailsModal] = useState(false);
  const [contactDetailsData, setContactDetailsData] = useState({});

  const { discussionList } = useSelector((state) => state.constructionReducer);
  const [iModalState, updateModalState] = useState(initModal);
  const [leadDetailsShowMore, SetLeadDetailsShowMore] = useState(false);
  const [iLeadState, updateLeadState] = useState(leadInitState);
  const [ctcHistoryModal, setctcHistoryModal] = useState(false);
  const [rejectCtcDocModal, setrejectCtcDocModal] = useState(false);
  const [sendClientInfoModal, setsendClientInfoModal] = useState(false);
  const [sendClientDropdown, setsendClientDropdown] = useState(false);

  const { getTaxProfile } = useSelector((state) => state.companyReducer);
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const {
    staffListData,
    allLeadsListData,
    loader,
    followUpListData,
    contractListData,
    supprtingDocsListData,
    scheduledPaymentListData,
    transactionListData,
    clientAssignedListData,
    salesTypeListData,
    allClientListData,
    parkingListData,
    unlockListData,
    SOAHistoryListData,
    releaseList,
    loaderRelease,
    generateHistoryList,
    loaderHistory,
    clientCtcByTemplate,
    costBreakDownData,
    marginBreakDownData,
    allSignatureList,
    clientDocTemplateList,
  } = useSelector((state) => state.salesReducer);

  const {
    countryData,
    cityData,
    stateData,
    subDivisionListData,
    messageListData,
  } = useSelector((state) => state.authReducer);

  const { followUpList } = useSelector((state) => state.accountingReducer);
  const { primaryList, secondaryList } = useSelector(
    (state) => state.designSelectionsReducers
  );
  const { allDocumentKey } = useSelector((state) => state.fileManagerData);
  const [phonecode, setPhoneCode] = useState("");
  const [bussinessPhoneCode, setBussinessPhoneCode] = useState("");
  const {
    folderListData,
    folderHistoryListData,
    folderSearchListData,
    pendingFileListData,
    fileListData,
  } = useSelector((state) => state.clientReducer);

  const {
    AddLeadModal,
    contactInformationModal,
    assignSaleModal,
    AddContractModal,
    ClientDocumentationModal,
    sendClientInformationModal,
    createClientCTCModal,
    manageSignatureModal,
    folderHistoryModal,
    documentHistoryModal,
    discussionModal,
    shareDocumentsModal,
    editFileModal,
    editLeadDetailsModal,
    editContactInformationModal,
    editSurveyModal,
    supportingDocsModal,
    SystemVendorModal,
    viewDocumentModal,
    locationModal,
    createNewFileModal,
    uploadFilesModal,
    leadClientDetailsShow,
    editActionLeadDetailsModal,
    scheduleNewFollowModal,
    convertLeadToContractModal,
    editFollowUpActivityModal,
    addItineraryModal,
    setReminderModal,
    newEmailModal,
    newTextModal,
    modalState,
    adjustmentStatementModal,
    supportingDocumentListShow,
    mainContractEditModal,
    updateSatisfiedConditionModal,
    contractAssignUnitModal,
    contractTransactionsModal,
    contractSchedulePaymentsModal,
    contractAddSupportingModal,
    ContractSupportingDocsModal,
    editSupportingModal,
    scheduledPaymentModal,
    modalId,
    setReminderState,
    secondaryId,
    leadClientDetailsDataTemp,
    addSurveyModal,
    addFolderModal,
    thirdId,
    SOAModal,
    addUnlockConditionalModal,
    unlockConditonListModal,
    clientAssignUnitModal,
    SOAHistoryModal,
    DocumentKeyModal,
    avjobId,
    ViewCtcModal,
    CTCTemplateReleasedModal,
    FollowUpActivityModal,
    SendForClientModal,
    assignsaleDetailsModal,
    clientSignatureModal,
    clientHistoryModal,
    primarySignature,
    secondarySignature,
    additionalSignature,
    builderSignature,
    ManageSignature,
    signTypes,
    primarySignatureBlob,
    secondarySignatureBlob,
    builderSignatureBlob,
    additionalSignatureBlob,
    fileNames,
    SignModal,
    afterDraftViewCTCData,
    addClientModal,
    ctcSignature,
    ctcSignatureBlob,
    ctcSignatureBuilder,
    ctcSignatureBuilderBlob,
  } = iModalState;

  const leadListRef = useRef();
  const contractListRef = useRef();
  const supportingDocsRef = useRef();
  const scheduledListRef = useRef();
  const transactionListRef = useRef();

  const handlePhoneInputChange = (value, country) => {
    // console.log('-------->', value, country)
    setPhoneCode(`+${country.dialCode}`);
  };
  const handleBussinessPhoneInputChange = (value, country) => {
    // console.log('-------->', value, country)
    setBussinessPhoneCode(`+${country.dialCode}`);
  };
  const {
    legalName,
    name,
    templateId,
    clientView,
    streetAddress,
    primaryPhoneNum,
    UserId,
    addressLineTwo,
    email,
    city,
    businessPhoneNum,
    zipCode,
    faxPhoneNum,
    country,
    preferredCommu,
    province,
    subDivision,
    leadStatus,
    confidenceLevel,
    referrerName,
    referrerAgency,
    referrerEmail,
    survey,
    assignStaffId,
    surveys,

    leadErrors,
    file,
    sendType,
    location,
    activityDate,
    fromTime,
    toTime,
    setReminder,
    sendTo,
    leadDetailsData,
    emailData,
    discussionError,
    // secondaryId,
    followUpDetailsData,
    leadSource,
    purchasePlaning,
    currentHomeStyle,
    preferredPrice,
    currentHomeSize,
    reasonToMove,
    seekingHomeSize,
    seekingHomeStyle,
    seekingHomeSite,
    beenSearching,
    moreInfo,
    mortgageAssistance,
    realtorAssistance,
    surveyErrors,
    folderName,
    folderError,
    folderId,
    clientDocumentationFile,
    clientDocumnetationError,
    folderIdLocal,
    files,
    emailBody,
    clientInfomationError,
    signedDocs,
    sendInformationEmail,
    sendInformationName,
    showHideCheck,
    vendorSearchData,
    vendorType,
    systemErrors,
    signature,
    saleType,
    clientAssiUnit,
    initial,
    signatureErrors,
    tempAssignList,
    supportingDocsError,
    scheOfPayDate,
    transactionType,
    tranId,
    tranDate,
    payMethod,
    tranType,
    total,
    notes,
    suppDoc,
    tranStatus,
    scheduledPaymentError,
    scheduledTransactionPaymentError,
    shareDocumentationError,
    ProjectId,
    jobId,
    contractDetailsData,
    price,
    description,
    clientPurchasePrice,
    isRebate,
    depositAmount,
    downPayPercent,
    satisCondition,
    reason,
    requestApproverId,
    requestApprover,
    SOADetailsData,
    heading,
    documentSearch,
    leadId,
    primarySignee,
    secondarySignee,
    numberOfSignee,
    docsId,
    message,
    roomId,
    senderId,
    receiverId,
    fileNameStatic,
    purchaseDetailsId,
    unitDiscount,
    isChecked,
    quantity,
    builderSignee,
    builderSignStatus,
    primarySignStatus,
    secondarySignStatus,
    additionalSignee,
    additionalSignStatus,
    purchaseDetailId,
    slectedClient,
    clientDocId,
    messageBody,
    updateDate,
    jobNumbers,
    trnsId,
    jobType,
    JobId,
    contID,
    leaId,
    userInfo,
  } = iLeadState;
  const [preSelectedData, setPreSelectedData] = useState([]);


  const handleTaxCheck = (e) => {
    const { name, checked } = e.target;
    updateLeadState({
      ...iLeadState,
      [name]: checked,
    });
  };
  const showAddClientModal = (job) => {
    updateModalState({
      ...iModalState,
      addClientModal: true,
      mainContractEditModal: false,
    });
    updateLeadState({ ...iLeadState, jobNumbers: job ?? "N/A" });
  };

  const hideAddClientModal = () => {
    updateModalState({
      ...iModalState,
      addClientModal: false,
      mainContractEditModal: true,
    });
  };
  // const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const handleCheckboxChange = (condition) => {
    updateLeadState({
      ...iLeadState,
      satisCondition: {
        ...iLeadState.satisCondition,
        [condition]: !iLeadState.satisCondition[condition],
      },
    });
  };

  const handleCHeckBoxForContractList = (e, id, checkedState) => {
    const { name, value, checked } = e.target;
    let satisCondition = {};
    satisCondition[checkedState] = checked;
    dispatch(checkUncheckContractList(id, satisCondition)).then((res) => {
      // console.log("Ressss", res)
      dispatch(getAllContractsListAction());
    });
  };

  const handleInputPurchaseCheckbox = () => {};

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vendorTypesData, vendorListData } = useSelector(
    (state) => state.constructionVendor
  );

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const showAddLeadModal = (id) => {
    updateModalState({ ...iModalState, AddLeadModal: true });
  };
  const hideAddLeadModal = () => {
    updateModalState({ ...iModalState, AddLeadModal: false });
    updateLeadState(leadInitState);
  };

  const showCreateNewFileModal = (id, secid, thirdId) => {
    updateModalState({
      ...iModalState,
      createNewFileModal: true,
      ClientDocumentationModal: false,
    });
  };
  const hideCreateNewFileModal = () => {
    updateModalState({
      ...iModalState,
      createNewFileModal: false,
      ClientDocumentationModal: true,
    });
    updateLeadState({ ...iLeadState, name: "", folderId: "", file: "" });
  };
  const showUploadFilesModal = (id) => {
    updateModalState({
      ...iModalState,
      uploadFilesModal: true,
      ClientDocumentationModal: false,
    });
  };
  const hideUploadFilesModal = () => {
    updateModalState({
      ...iModalState,
      uploadFilesModal: false,
      ClientDocumentationModal: true,
    });
  };

  const showcontactInformationModal = (id) => {
    // le("===>", id);
    if (id == "addContract") {
      updateModalState({
        ...iModalState,
        contactInformationModal: true,
        AddContractModal: false,
        modalState: id,
      });
    } else if (id === "editContract") {
      updateModalState({
        ...iModalState,
        contactInformationModal: true,
        editContactInformationModal: false,
        modalState: id,
      });
    } else {
      updateModalState({
        ...iModalState,
        contactInformationModal: true,
        AddLeadModal: false,
        modalState: id,
      });
    }
  };
  const hideContactInformationModal = () => {
    if (modalState === "contract") {
      updateModalState({
        ...iModalState,
        contactInformationModal: true,
        AddContractModal: false,
      });
    } else if (modalState === "editContract") {
      updateModalState({
        ...iModalState,
        contactInformationModal: true,
        editContactInformationModal: false,
        modalState: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        contactInformationModal: false,
        AddLeadModal: true,
      });
    }
  };

  const [leadsId, setLeadIdd] = useState("");

  const showAssignSaleModal = (state, id) => {
    if (state) {
      dispatch(getAllStaffListAction());
      updateModalState({
        ...iModalState,
        // AddLeadModal: false,
        assignSaleModal: true,
        modalState: state,
      });
      setLeadIdd(id);
      // updateLeadState({ ...iLeadState, assignStaffId:[{UserId:id}], emailData:[email] });
    } else if (!state) {
      updateModalState({
        ...iModalState,
        AddLeadModal: false,
        assignSaleModal: true,
        modalState: state,
      });
    }

    if (state == "editFollowUpActivity") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        editFollowUpActivityModal: false,
        modalState: state,
      });
    } else if (state == "discussion") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        discussionModal: false,
        modalState: state,
      });
      updateLeadState({ ...iLeadState, UserId: id });
    } else if (state == "Itinerary") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        addItineraryModal: false,
        modalState: state,
      });
      updateLeadState({ ...iLeadState, UserId: id });
    } else if (state == "editLeadDetails") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        editLeadDetailsModal: false,
        modalState: state,
      });
      updateLeadState({ ...iLeadState, UserId: id });
    } else if (state == "scheduleNewFollow") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        scheduleNewFollowModal: false,
        modalState: state,
      });
    } else if (state === "contract") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        AddContractModal: false,
        modalState: state,
      });
    } else if (state === "editContract") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        editContactInformationModal: false,
        modalState: state,
      });
    }
  };

  const hideAssignSaleModal = () => {
    if (modalState) {
      updateModalState({
        ...iModalState,
        // AddLeadModal: true,
        assignSaleModal: false,
      });
    } else {
      updateModalState({
        ...iModalState,
        AddLeadModal: true,
        assignSaleModal: false,
      });
    }
    if (modalState == "editFollowUpActivity") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        editFollowUpActivityModal: true,
      });
    } else if (modalState == "discussion") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        discussionModal: true,
      });
    } else if (modalState == "Itinerary") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        addItineraryModal: true,
        modalState: "",
      });
    } else if (modalState == "editLeadDetails") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        editLeadDetailsModal: true,
        modalState: "",
      });
    } else if (modalState == "scheduleNewFollow") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        scheduleNewFollowModal: true,
        modalState: "",
      });
    } else if (modalState === "contract") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        AddContractModal: true,
        modalState: "",
      });
    } else if (modalState === "editContract") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        editContactInformationModal: true,
        modalState: "",
      });
    }
  };

  const showAssignDetailsModal = (state) => {
    updateModalState({
      ...iModalState,
      assignsaleDetailsModal: true,
      modalState: state,
    });
  };

  const hideAssignDetailsModal = () => {
    updateModalState({
      ...iModalState,
      modalState: "",
      // AddLeadModal: true,
      assignsaleDetailsModal: false,
    });
  };

  const handleAssignStaffs = () => {
    const dataa = {
      assignStaffId: [{ UserId: Number(UserId) }],
    };

    dispatch(updateContactDetails(leadsId, dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getAllLeadsListAction());
        dispatch(getAllContractsListAction());
        toast.success("Assign Staff Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateModalState({
          ...iModalState,
          modalState: "",
          assignSaleModal: false,
        });
        setLeadIdd("");
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({
          ...iModalState,
          modalState: "",
          assignSaleModal: true,
        });
      }
    });
  };

  const showAddFolderModal = () => {
    updateModalState({
      ...iModalState,
      addFolderModal: true,
      ClientDocumentationModal: false,
    });
  };

  const hideAddFolderModal = () => {
    updateModalState({
      ...iModalState,
      addFolderModal: false,
      ClientDocumentationModal: true,
    });
    updateLeadState({ ...iLeadState, folderName: "" });
  };

  const showAddContractModal = () => {
    updateModalState({ ...iModalState, AddContractModal: true });
  };
  const hideAddContractModal = () => {
    updateModalState({ ...iModalState, AddContractModal: false });
    updateLeadState(leadInitState);
  };
  const [contactDetails, setContactDetails] = useState([]);
  const showClientDocumentatioModal = (id, state, leadId) => {
    window.localStorage.setItem("leadId", JSON.stringify(leadId));
    window.localStorage.setItem("contId", JSON.stringify(id));
    dispatch(searchFolderListAction(id, "Contract"));
    dispatch(ContractDetailsAction(id)).then((res) => {
      if (res) {
        setContactDetails(res?.setContactDetails);
        setContactDetails(res?.contract?.[0]);
      }
    });
    if (id == "editLeadDetails") {
      updateModalState({
        ...iModalState,
        ClientDocumentationModal: true,
        editLeadDetailsModal: false,
        modalId: id,
        modalState: state,
        leadId: leadId,
      });
    } else {
      updateModalState({
        ...iModalState,
        ClientDocumentationModal: true,
        modalId: id,
        modalState: state,
        leadId: leadId,
      });
    }
  };

  const hideClientDocumentationModal = () => {
    if (modalState == "editLeadDetails") {
      updateModalState({
        ...iModalState,
        ClientDocumentationModal: false,
        editLeadDetailsModal: true,
        modalId: "",
      });
    } else {
      updateModalState({ ...iModalState, ClientDocumentationModal: false });
    }
  };

  const showClientInformationModal = (id) => {
    dispatch(getAllFileListAction(folderIdLocal, 2, "Contract"));
    updateModalState({
      ...iModalState,
      sendClientInformationModal: true,
      ClientDocumentationModal: false,
    });
  };

  useEffect(() => {
    dispatch(VendorSearchAction(vendorType)).then((res) => {
      updateLeadState({ ...iLeadState, vendorSearchData: res.vendors });
    });
  }, [vendorType]);
  const hideClientInformationModal = () => {
    updateModalState({
      ...iModalState,
      sendClientInformationModal: false,
      ClientDocumentationModal: true,
    });
    updateLeadState({
      ...iLeadState,
      sendInformationEmail: "",
      sendInformationName: "",
      signedDocs: [],
      emailBody: "",
    });
  };

  const showLocationModal = (id) => {
    updateModalState({
      ...iModalState,
      locationModal: true,
      ClientDocumentationModal: false,
    });
  };
  const hideLocationModal = () => {
    updateModalState({
      ...iModalState,
      locationModal: false,
      ClientDocumentationModal: true,
    });
  };

  const showCreateClientCTCModal = (id) => {
    dispatch(getAllClientAction(modalId));
    dispatch(getClientCtcByTemplate(modalId, id));
    updateModalState({
      ...iModalState,
      createClientCTCModal: true,
      ClientDocumentationModal: false,
    });
  };
  const hideCreateClientCTCModal = () => {
    updateModalState({
      ...iModalState,
      createClientCTCModal: false,
      ClientDocumentationModal: true,
    });
  };

  const showManageSignatureModal = (id) => {
    updateModalState({
      ...iModalState,
      manageSignatureModal: true,
      ClientDocumentationModal: false,
    });
  };
  const hideManageSignatureModal = () => {
    updateModalState({
      ...iModalState,
      manageSignatureModal: false,
      ClientDocumentationModal: true,
    });
    updateLeadState({ ...iLeadState, signature: "", initial: "" });
  };

  const showFolderHistoryModal = (contractId, id, type) => {
    dispatch(folderHistoryAction(contractId, id, type));
    updateModalState({
      ...iModalState,
      folderHistoryModal: true,
      ClientDocumentationModal: false,
    });
  };
  const hideFolderHistoryModal = () => {
    updateModalState({
      ...iModalState,
      folderHistoryModal: false,
      ClientDocumentationModal: true,
    });
  };

  const showDocumentHistoryModal = (id, folderId, type) => {
    dispatch(folderHistoryAction(2, id, folderId, type));
    updateModalState({
      ...iModalState,
      documentHistoryModal: true,
      ClientDocumentationModal: false,
    });
  };
  const hideDocumentHistoryModal = () => {
    updateModalState({
      ...iModalState,
      documentHistoryModal: false,
      ClientDocumentationModal: true,
    });
  };

  const hideDiscussionModal = () => {
    updateModalState({ ...iModalState, discussionModal: false });
  };

  const showDiscussionModal = (id) => {
    dispatch(getAllfollowUpListAction(id));
    updateModalState({ ...iModalState, discussionModal: true, modalId: id });
  };
  const [selectedOptions, setSelectedOptions] = useState("");
  const showShareDocumentModal = (id, fid, name) => {
    updateModalState({
      ...iModalState,
      shareDocumentsModal: true,
      ClientDocumentationModal: false,
      secondaryId: fid,
      thirdId: id,
      fileNames: name,
    });
  };
  const [allClients, setAllClients] = useState([]);
  useEffect(() => {
    if (shareDocumentsModal) {
      let contId = JSON.parse(window.localStorage.getItem("contId"));
      dispatch(getAllClientAction(contId)).then((res) => {
        if (res) {
          const dummyClients = res?.clientDetails;
          setAllClients(dummyClients);
        }
      });
    }
  }, [shareDocumentsModal]);

  useEffect(() => {
    setClientCtcByTemplateData(clientCtcByTemplate?.data);
    dispatch(getTaxProfileList());
  }, [clientCtcByTemplate]);

  const hideShareDocumentModal = () => {
    updateModalState({
      ...iModalState,
      shareDocumentsModal: false,
      ClientDocumentationModal: true,
    });
    updateLeadState(leadInitState);
  };

  const hideEditLeadDetailsModal = () => {
    updateModalState({ ...iModalState, editLeadDetailsModal: false });
  };
  const showLeadDetailsModal = (id) => {
    window.localStorage.setItem("leadId", id);
    dispatch(getLeadDetailsAction(id)).then((res) => {
      updateLeadState({ ...iLeadState, leadDetailsData: res?.lead });
    });
    dispatch(getAllfollowUpListAction(id));
    updateModalState({
      ...iModalState,
      editLeadDetailsModal: true,
      modalId: id,
    });
  };

  const hideEditSurveyModal = () => {
    updateModalState({ ...iModalState, editSurveyModal: false });
  };

  const showEditSurveyModal = (leadId, id) => {
    dispatch(SurveyDetailsAction(leadId, id)).then((res) => {
      updateLeadState({
        ...iLeadState,
        leadSource: res?.survey?.leadSource,
        purchasePlaning: res?.survey?.purchasePlaning,
        currentHomeStyle: res?.survey?.currentHomeStyle,
        preferredPrice: res?.survey?.preferredPrice,
        currentHomeSize: res?.survey?.currentHomeSize,
        reasonToMove: res?.survey?.reasonToMove,
        seekingHome: res?.survey?.seekingHome,
        seekingHomeStyle: res?.survey?.seekingHomeStyle,
        seekingHomeSite: res?.survey?.seekingHome,
        beenSearching: res?.survey?.beenSearching,
        moreInfo: res?.survey?.moreInfo,
        mortgageAssistance: res?.survey?.mortgageAssistance,
        realtorAssistance: res?.survey?.realtorAssistance,
        updateDate: res?.survey?.updatedAt,
      });
    });
    updateModalState({
      ...iModalState,
      editSurveyModal: true,
      editLeadDetailsModal: false,
      modalId: leadId,
      secondaryId: id,
    });
  };

  const convertLeadToContractFunc = (id) => {
    dispatch(convertToContractAction(id))
      .then((res) => {
        dispatch(getAllLeadsListAction());
        dispatch(getAllContractsListAction());
        sucessToast("Lead converted to contract successfully");
      })
      .catch((err) => {
        errorToast("Lead already converted to contract");
      });
  };

  const hideEditContactInformationModal = () => {
    updateModalState({ ...iModalState, editContactInformationModal: false });
  };

  const showEditContactInformationModal = (state, id) => {
    updateModalState({ ...iModalState, editContactInformationModal: true });
    dispatch(ContractDetailsAction(id))
    .then((res) => {
      if (res && res.contract) {
        updateLeadState({
          ...iLeadState,
          name: res.contract?.[0]?.Clients?.[0]?.email?.name || "",
          clientView: res.contract?.[0]?.Clients?.[0]?.clientView || "",
          legalName: res.contract?.[0]?.Clients?.[0]?.legalName || "",
          streetAddress: res.contract?.[0]?.Clients?.[0]?.streetAddress || "",
          primaryPhoneNum: res.contract?.[0]?.Clients?.[0]?.primaryPhoneNum || "",
          addressLineTwo: res.contract?.[0]?.Clients?.[0]?.addressLineTwo || "",
          email: res.contract?.[0]?.Clients?.[0]?.email || "",
          city: res.contract?.[0]?.Clients?.[0]?.city || "",
          businessPhoneNum: res.contract?.[0]?.Clients?.[0]?.businessPhoneNum || "",
          zipCode: res.contract?.[0]?.Clients?.[0]?.zipCode || "",
          faxPhoneNum: res.contract?.[0]?.Clients?.[0]?.faxPhoneNum || "",
          country: res.contract?.[0]?.Clients?.[0]?.country || "",
          preferredCommu: res.contract?.[0]?.Clients?.[0]?.preferredCommu || "",
          province: res.contract?.[0]?.Clients?.[0]?.province || "",
          subDivision: res.contract?.[0]?.subDivision || "",
          leadStatus: res.contract?.[0]?.Clients?.[0]?.leadStatus || "",
          confidenceLevel: res.contract?.[0]?.Clients?.[0]?.confidenceLevel || "",
          referrerName: res.contract?.[0]?.referrerName || "",
          referrerAgency: res.contract?.[0]?.referrerAgency || "",
          referrerEmail: res.contract?.[0]?.referrerEmail || "",
          contID: res.contract?.[0]?.id || "",
          saleType: res.contract?.[0]?.jobType || "",
          jobId: res.contract?.[0]?.jobId || "",
          leaId: res.contract?.[0]?.LeadId || "",
          userInfo:
            res.contract?.[0]?.Users?.[0]?.name ||
            res.contract?.[0]?.Users?.[0]?.email,
        });

        setBussinessPhoneCode(
          res.contract?.[0]?.bussinessPhoneCode?.startsWith("+")
            ? res.contract[0].bussinessPhoneCode.substring(1)
            : res.contract[0].bussinessPhoneCode
        );

        setPhoneCode(
          res.contract?.[0]?.phonecode?.startsWith("+")
            ? res.contract[0].phonecode.substring(1)
            : res.contract[0].phonecode
        )
      }
    })
      .catch((error) => {
        console.error("Error fetching contract details:", error);
      });
  };

  const hideSupportingDocsModal = () => {
    if (modalState === "contractList") {
      updateModalState({
        ...iModalState,
        supportingDocsModal: false,
        modalId: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        supportingDocsModal: false,
        modalId: "",
        ContractSupportingDocsModal: true,
      });
    }
  };

  const showSupportingDocsModal = (data, id) => {
    if (data === "contractList") {
      updateModalState({
        ...iModalState,
        supportingDocsModal: true,
        modalId: id,
        modalState: data,
      });
    } else {
      updateModalState({
        ...iModalState,
        supportingDocsModal: true,
        modalId: id,
        ContractSupportingDocsModal: false,
        modalState: data,
      });
    }
  };
  //  SYSTEMVENDORMODAL

  const handleSystemVendorModalClose = () => {
    updateModalState({
      ...iModalState,
      SystemVendorModal: false,
      sendClientInformationModal: true,
    });
  };

  const handleSystemVendorModalShow = (data) => {
    setData1(vendorTypesData?.vendorType);
    setData2(vendorTypesData?.vendorType);
    dispatch(VendorSearchAction()).then((res) => {
      updateLeadState({ ...iLeadState, vendorSearchData: res.vendors });
    });
    updateModalState({
      ...iModalState,
      SystemVendorModal: true,
      sendClientInformationModal: false,
      vendorName: "",
      vendorType: "",
    });
  };

  const hideLeadClientDetailsModal = () => {
    updateModalState({ ...iModalState, leadClientDetailsShow: false });
  };

  const showLeadClientDetailModal = (data) => {
    updateModalState({
      ...iModalState,
      leadClientDetailsShow: true,
      leadClientDetailsDataTemp: data,
    });
  };

  const hideActionEditDetailsModal = () => {
    updateModalState({ ...iModalState, editActionLeadDetailsModal: false });
  };

  const showActionEditDetailsModal = () => {
    updateModalState({ ...iModalState, editActionLeadDetailsModal: true });
    updateLeadState(leadInitState);
  };

  const hideScheduleFollowActivityModal = () => {
    updateModalState({ ...iModalState, scheduleNewFollowModal: false });
    // updateLeadState(leadInitState);
  };

  const showScheduleFollowActivityModal = (id) => {
    updateModalState({
      ...iModalState,
      scheduleNewFollowModal: true,
      // modalId: id,
    });
  };

  const hideConvertLeadToContractModal = () => {
    updateModalState({ ...iModalState, convertLeadToContractModal: false });
  };

  const showConvertLeadToContractModal = () => {
    updateModalState({ ...iModalState, convertLeadToContractModal: true });
  };

  const hideSetReminderModal = () => {
    if (modalState == "editFollowUpActivity") {
      updateModalState({
        ...iModalState,
        setReminderModal: false,
        editFollowUpActivityModal: true,
        modalState: "",
      });
    } else if (modalState === "itinerary") {
      updateModalState({
        ...iModalState,
        setReminderModal: false,
        addItineraryModal: true,
        modalState: modalState,
      });
    } else if (modalState == "discussion") {
      updateModalState({
        ...iModalState,
        setReminderModal: false,
        discussionModal: true,
        modalState: "",
      });
    } else if (modalState == "editLeadDetails") {
      updateModalState({
        ...iModalState,
        setReminderModal: false,
        editLeadDetailsModal: true,
        modalState: modalState,
      });
    } else if (modalState == "scheduleNewFollow") {
      updateModalState({
        ...iModalState,
        setReminderModal: false,
        scheduleNewFollowModal: true,
        modalState: modalState,
      });
    } else {
      updateModalState({
        ...iModalState,
        setReminderModal: false,
        editActionLeadDetailsModal: true,
      });
    }
  };

  const showSetReminderModal = (modalState, LeadId, id, time) => {
    if (modalState == "editFollowUpActivity") {
      updateModalState({
        ...iModalState,
        setReminderModal: true,
        editFollowUpActivityModal: false,
        modalState: modalState,
        modalId: LeadId,
        secondaryId: id,
        setReminderState: true,
      });
    } else if (modalState === "itinerary") {
      updateModalState({
        ...iModalState,
        setReminderModal: true,
        addItineraryModal: false,
        modalState: modalState,
        modalId: LeadId,
        secondaryId: id,
      });
    } else if (modalState == "discussion") {
      updateModalState({
        ...iModalState,
        setReminderModal: true,
        discussionModal: false,
        modalState: modalState,
        modalId: LeadId,
        secondaryId: id,
        setReminderState: false,
      });
    } else if (modalState == "editLeadDetails") {
      updateModalState({
        ...iModalState,
        setReminderModal: true,
        editLeadDetailsModal: false,
        modalState: modalState,
      });
    } else if (modalState == "scheduleNewFollow") {
      updateModalState({
        ...iModalState,
        setReminderModal: true,
        scheduleNewFollowModal: false,
        modalState: modalState,
        setReminderState: true,
      });
    } else {
      updateModalState({
        ...iModalState,
        setReminderModal: true,
        editActionLeadDetailsModal: false,
      });
    }
  };

  const hideAddItineraryModal = () => {
    if (modalState == "discussion") {
      updateModalState({
        ...iModalState,
        addItineraryModal: false,
        discussionModal: true,
      });
    } else if (modalState == "editLeadDetails") {
      updateModalState({
        ...iModalState,
        addItineraryModal: false,
        editLeadDetailsModal: true,
        modalState: modalState,
      });
    } else {
      updateModalState({
        ...iModalState,
        addItineraryModal: false,
        editActionLeadDetailsModal: true,
      });
    }
  };

  const showAddItineraryModal = (modalState) => {
    if (modalState == "discussion") {
      updateModalState({
        ...iModalState,
        addItineraryModal: true,
        discussionModal: false,
        modalState: modalState,
      });
    } else if (modalState == "editLeadDetails") {
      updateModalState({
        ...iModalState,
        addItineraryModal: true,
        editLeadDetailsModal: false,
        modalState: modalState,
      });
    }
    // else if (modalState === "discussion") {
    //   updateModalState({
    //     ...iModalState,
    //     editFollowUpActivityModal: false,
    //     modalState: modalState,
    //     discussionModal: true,
    //   });
    // }
    else {
      updateModalState({
        ...iModalState,
        addItineraryModal: true,
        editActionLeadDetailsModal: false,
        modalState: modalState,
      });
    }
  };
  const showEditFollowUpActivityModal = (modalState, LeadId, id) => {
    dispatch(getAllFollowUpDetailsAction(LeadId, id)).then((res) => {
      updateLeadState({
        ...iLeadState,
        followUpDetailsData: res.data.followUp,
        sendTo: res?.data.followUp?.sendTo,
        sendType: res?.data?.followUp?.sendType,
        sendBy: res?.data?.followUp?.sendBy,
        location: res?.data?.followUp?.location,
        setReminder: res?.data?.followUp?.setReminder,
        assignStaffId: res?.data?.followUp?.FollowUpStaffs?.map((ele, i) => {
          return { UserId: ele?.UserId };
        }),
        emailData: res?.data?.followUp?.FollowUpStaffs?.map((ele, i) => {
          return ele?.email;
        }),
        activityDate: res?.data?.followUp?.activityDate?.split("T")[0],
        fromTime: res?.data?.followUp?.fromTime?.substr(11, 5),
        toTime: res?.data?.followUp?.toTime?.substr(11, 5),
      });
    });

    if (modalState === "editLeadDetails") {
      updateModalState({
        ...iModalState,
        editFollowUpActivityModal: true,
        modalState: modalState,
        editLeadDetailsModal: false,
        modalId: LeadId,
        secondaryId: id,
      });
    } else if (modalState === "discussion") {
      updateModalState({
        ...iModalState,
        editFollowUpActivityModal: true,
        modalState: modalState,
        discussionModal: false,
        secondaryId: id,
        modalId: LeadId,
      });
    } else {
      updateModalState({
        ...iModalState,
        editFollowUpActivityModal: true,
        modalState: modalState,
        discussionModal: false,
        secondaryId: id,
        modalId: LeadId,
      });
    }
  };

  const hideEditFollowUpActivityModal = () => {
    if (modalState === "editLeadDetails") {
      updateModalState({
        ...iModalState,
        editFollowUpActivityModal: false,
        modalState: modalState,
        editLeadDetailsModal: true,
      });
    } else if (modalState === "discussion") {
      updateModalState({
        ...iModalState,
        editFollowUpActivityModal: false,
        modalState: modalState,
        discussionModal: true,
      });
    } else {
      updateModalState({
        ...iModalState,
        editFollowUpActivityModal: false,
        modalState: modalState,
        discussionModal: true,
      });
    }
  };

  const showNewEmailModal = (modalState, data) => {
    if (modalState === "details") {
      updateModalState({
        ...iModalState,
        newEmailModal: true,
        modalState: modalState,
        editLeadDetailsModal: false,
      });
    } else {
      updateModalState({
        ...iModalState,
        newEmailModal: true,
        modalState: modalState,
      });
      setContactDetailsModal(false);
    }
  };

  const hideNewEmailModal = (modalState) => {
    if (modalState === "details") {
      updateModalState({
        ...iModalState,
        newEmailModal: false,
        modalState: "",
        editLeadDetailsModal: true,
      });
    } else {
      updateModalState({
        ...iModalState,
        newEmailModal: false,
        modalState: "",
      });
      setContactDetailsModal(true);
    }
  };

  const showNewTextModal = (modalState) => {
    if (modalState === "edit") {
      updateModalState({
        ...iModalState,
        newTextModal: true,
        modalState: modalState,
        editLeadDetailsModal: false,
      });
    } else if (modalState === "detils") {
      updateModalState({
        ...iModalState,
        newTextModal: true,
        modalState: modalState,
      });
      setContactDetailsModal(false);
    }
  };

  const hideNewTextModal = () => {
    if (modalState === "edit") {
      updateModalState({
        ...iModalState,
        newTextModal: false,
        modalState: "",
        editLeadDetailsModal: true,
      });
    } else if (modalState === "detils") {
      updateModalState({
        ...iModalState,
        newTextModal: false,
        modalState: "",
      });
      setContactDetailsModal(true);
    }
  };
  const handleSendSmsToLead = (e) => {
    e.preventDefault();
    const leadIdd = window.localStorage.getItem("leadId");
    const dataa = { messageBody };
    dispatch(sendSmsToLead(contactDetailsData?.id || leadIdd, dataa)).then(
      (res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateLeadState({ ...iLeadState, messageBody: "" });
          if (modalState === "edit")
            updateModalState({
              ...iLeadState,
              newTextModal: false,
              editLeadDetailsModal: true,
            });
          else {
            updateModalState({
              ...iLeadState,
              newTextModal: false,
            });
            setContactDetailsModal(true);
          }
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleHandleAssignUnit = (id) => {
    dispatch(removedAssignUnits(id)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const hideAdjustStatementModal = (modalState) => {
    updateModalState({ ...iModalState, adjustmentStatementModal: false });
  };
  const showAdjustStatementModal = (modalState) => {
    updateModalState({ ...iModalState, adjustmentStatementModal: true });
  };

  const hideMainContractModal = (modalState) => {
    updateModalState({ ...iModalState, mainContractEditModal: false });
  };

  const showMainContractModal = (modalState, id, heading) => {
    dispatch(ContractDetailsAction(id)).then((res) => {
      updateLeadState({
        ...iLeadState,
        heading: heading,
        contractDetailsData: res?.contract,
        referrerAgency: res?.contract?.[0]?.referrerAgency,
        referrerEmail: res?.contract?.[0]?.referrerEmail,
        referrerName: res?.contract?.[0]?.referrerName,
        subDivision: res?.contract?.[0]?.subDivision,
        saleType: res?.contract?.[0]?.jobType,
        // jobId: res?.contract?.[0]?.jobNumber?.split(" ")[0],
        jobId: res?.contract?.[0]?.jobId,
        contID: res?.contract?.[0]?.id,
        jobType: res?.contract?.[0]?.jobType,
        ProjectId: res?.contract?.[0]?.ProjectId,
        satisCondition: {
          depositPaid: res?.contract?.[0]?.SatisCondition?.depositPaid,
          downPayPaid: res?.contract?.[0]?.SatisCondition?.downPayPaid,
          contractSigned: res?.contract?.[0]?.SatisCondition?.contractSigned,
          finanConfirmed: res?.contract?.[0]?.SatisCondition?.finanConfirmed,
          waiverOfConSigned:
            res?.contract?.[0]?.SatisCondition?.waiverOfConSigned,
          depositPaidStatus:
            res?.contract?.[0]?.SatisCondition?.depositPaidStatus,
          downPayPaidStatus:
            res?.contract?.[0]?.SatisCondition?.downPayPaidStatus,
          contractSignedStatus:
            res?.contract?.[0]?.SatisCondition?.contractSignedStatus,
          finanConfirmedStatus:
            res?.contract?.[0]?.SatisCondition?.finanConfirmedStatus,
          waiverOfConSignedStatus:
            res?.contract?.[0]?.SatisCondition?.waiverOfConSignedStatus,
        },
      });
    });
    dispatch(getAllClientAction(id));
    dispatch(getParkingListAction(id));
    dispatch(getAllSchedulePaymentListAction(id));
    // dispatch()
    updateModalState({
      ...iModalState,
      mainContractEditModal: true,
      modalId: id,
    });
  };

  const hideContractAssignUnitModal = (modalState) => {
    updateModalState({ ...iModalState, leadAssignUnitModal: false });
  };

  const showContractAssignUnitModal = (modalState) => {
    updateModalState({ ...iModalState, contractAssignUnitModal: true });
  };

  const hideContractTransactionsModal = (modalState) => {
    updateModalState({
      ...iModalState,
      contractTransactionsModal: false,
      contractSchedulePaymentsModal: true,
    });
    updateLeadState(iLeadState);
  };

  const showContractTransactionsModal = (modalState, id) => {
    // le("------>", modalState, id);
    dispatch(getAllScheduleTransactionPaymentListAction(id));
    updateModalState({
      ...iModalState,
      contractTransactionsModal: true,
      secondaryId: modalState,
      contractSchedulePaymentsModal: false,
    });
  };

  const showContractSchedulePaymentModal = (modalState) => {
    updateModalState({ ...iModalState, contractSchedulePaymentsModal: true });
  };

  const hideContractSchedulePaymentModal = (modalState) => {
    updateModalState({ ...iModalState, contractSchedulePaymentsModal: false });
  };

  const showUpdateSatisfiedConditionPaymentModal = (modalState) => {
    updateModalState({ ...iModalState, updateSatisfiedConditionModal: true });
  };

  const hideUpdateSatisfiedConditionModal = (modalState) => {
    updateModalState({ ...iModalState, updateSatisfiedConditionModal: false });
  };

  const showAddContractSupportingModal = (modalState) => {
    updateModalState({ ...iModalState, contractAddSupportingModal: true });
  };

  const hideAddContractSupportingModal = (modalState) => {
    updateModalState({ ...iModalState, contractAddSupportingModal: false });
  };

  const showContractSupportingListModal = (modalState, id) => {
    dispatch(getAllsupportingDocsListAction(id));
    updateModalState({
      ...iModalState,
      ContractSupportingDocsModal: true,
      modalId: id,
    });
  };

  const hideContractSupportingListModal = (modalState) => {
    updateModalState({ ...iModalState, ContractSupportingDocsModal: false });
  };

  const showEditSupportingModal = (modalState, id) => {
    dispatch(SupportingDocsDetailsAction(modalId, id)).then((res) => {
      // le("=======>", res);
      updateLeadState({ ...iLeadState, files: res?.docUrl });
    });
    updateModalState({
      ...iModalState,
      editSupportingModal: true,
      ContractSupportingDocsModal: false,
      secondaryId: id,
    });
  };

  const hideEditSupportingModal = (modalState) => {
    updateModalState({
      ...iModalState,
      editSupportingModal: false,
      ContractSupportingDocsModal: true,
    });
    updateLeadState({ ...iLeadState, files: "" });
  };
  const showScheduledPaymentModal = (modalState, id) => {
    // le("=---------->", id);
    dispatch(getAllSchedulePaymentListAction(id));
    updateModalState({
      ...iModalState,
      scheduledPaymentModal: true,
      modalId: id,
    });
    updateLeadState(leadInitState);
  };

  const hideScheduledPaymentModal = (modalState) => {
    updateModalState({
      ...iModalState,
      scheduledPaymentModal: false,
      // modalId: "",
    });
    updateLeadState(leadInitState);
  };



  const showAddSurveyModal = () => {
    updateModalState({
      ...iModalState,
      addSurveyModal: true,
      AddLeadModal: false,
    });
  };

  const hideAddSurveyModal = () => {
    updateModalState({
      ...iModalState,
      addSurveyModal: false,
      AddLeadModal: true,
    });
  };

  useEffect(() => {
    if (leadDetailsData) {
      updateLeadState({
        ...iLeadState,
        addressLineTwo: leadDetailsData?.addressLineTwo,
        businessPhoneNum: leadDetailsData?.businessPhoneNum,
        city: leadDetailsData?.city,
        country: leadDetailsData?.country,
        email: leadDetailsData?.email,
        faxPhoneNum: leadDetailsData?.faxPhoneNum,
        legalName: leadDetailsData?.legalName,
        preferredCommu: leadDetailsData?.preferredCommu,
        primaryPhoneNum: leadDetailsData?.primaryPhoneNum,
        province: leadDetailsData?.province,
        streetAddress: leadDetailsData?.streetAddress,
        zipCode: leadDetailsData?.zipCode,
        confidenceLevel: leadDetailsData?.confidenceLevel,
        leadStatus: leadDetailsData?.leadStatus,
        referrerAgency: leadDetailsData?.referrerAgency,
        referrerEmail: leadDetailsData?.referrerEmail,
        referrerName: leadDetailsData?.referrerName,
        UserId: leadDetailsData?.id,
        subDivision: leadDetailsData?.subDivision,
        fileNameStatic: leadDetailsData?.Users?.[0]?.name,
      });
    }
  }, [leadDetailsData]);

  useEffect(() => {
    dispatch(getCountriesList());
    dispatch(getAllStaffListAction());
    dispatch(getAllLeadsListAction());
    dispatch(VendorTypesAction());
    dispatch(VendorListAction());
    dispatch(getAllContractsListAction());
    dispatch(getSalesTypeListAction());
    // dispatch(getAllClientAction(modalId ? modalId :""));
    dispatch(subDivisionListAction());
    // dispatch(getPrimaryClient());
    // dispatch(getSecondaryClient());
  }, []);

  useEffect(() => {
    if (saleType) {
      dispatch(
        getClientAssingedAction({
          saleType,
          projectId: ProjectId,
          jobId,
          jobType,
        })
      );
    }
  }, [saleType]);

  useEffect(() => {
    const fetchCountryStateCity = async () => {
        await dispatch(getCountriesList());
        const countryIsoCode = countryData?.countries?.find(
          (x) => x.name === country
        )?.isoCode;
        if (countryIsoCode) {
          await dispatch(getStateList(countryIsoCode));
          const stateIsoCode = stateData?.states?.find(
            (x) => x.name === province
          )?.isoCode;
          if (stateIsoCode) {
            await dispatch(getCitiesList(countryIsoCode, stateIsoCode));
          }
        }
      }

    fetchCountryStateCity();
  }, [country, province]);

  const handleLeadValidation = () => {
    let error = {};
    let formIsValid = true;
    if (!legalName) {
      error.legalNameError = "Legal name can't be empty";
      formIsValid = false;
    }
    // if (!streetAddress) {
    //   error.streetAddressError = "Street address name can't be empty";
    //   formIsValid = false;
    // }
    // if (assignStaffId?.length === 0) {
    //   error.UserIdError = "Assigned can't be empty";
    //   formIsValid = false;
    // }
    if (!primaryPhoneNum) {
      error.primaryPhoneNumError = "Phone number can't be empty";
      formIsValid = false;
    }
    // if (!addressLineTwo) {
    //   error.addressLineTwoError = "Address Line 2 can't be empty";
    //   formIsValid = false;
    // }

    if (!primaryPhoneNum) {
      error.primaryPhoneNumError = "*  Phone number can't be empty";
      formIsValid = false;
    }
    if (primaryPhoneNum) {
      if (!/^[1-9][0-9]{9,12}$/.test(primaryPhoneNum)) {
        error.primaryPhoneNumError = "Please enter a valid phone number";
        formIsValid = false;
      }
    }

    if (!email) {
      error.emailError = "* Email address can't be empty";
      formIsValid = false;
    }

    if (email) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        error.emailError = "Please enter a valid email";
        formIsValid = false;
      }
    }

    if (!country) {
      error.countryError = "Country can't be empty";
      formIsValid = false;
    }

    // if (!preferredCommu) {
    //   error.preferredCommuError = "Preferred commu can't be empty";
    //   formIsValid = false;
    // }

    if (!province) {
      error.provinceError = "Province can't be empty";
      formIsValid = false;
    }
    if (!city) {
      error.cityError = "City can't be empty";
      formIsValid = false;
    }

    if (!subDivision) {
      error.subDivisionError = "Sub divison can't be empty";
      formIsValid = false;
    }

    if (!leadStatus) {
      error.leadStatusError = "Lead status can't be empty";
      formIsValid = false;
    }

    // if (!confidenceLevel) {
    //   error.confidenceLevelError = "Confidence level can't be empty";
    //   formIsValid = false;
    // }

    // if (!referrerName) {
    //   error.referrerNameError = "Referrer name can't be empty";
    //   formIsValid = false;
    // }

    // if (!referrerAgency) {
    //   error.referrerAgencyError = "Referrer agency can't be empty";
    //   formIsValid = false;
    // }

    // if (!referrerEmail) {
    //   error.referrerEmailError = "* Referrer email can't be empty";
    //   formIsValid = false;
    // }

    if (referrerEmail) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          referrerEmail
        )
      ) {
        error.referrerEmailError = "Please enter a valid email";
        formIsValid = false;
      }
    }

    // if (!businessPhoneNum) {
    //   error.businessPhoneNumError = "* Phone number can't be empty";
    //   formIsValid = false;
    // }
    if (businessPhoneNum) {
      if (!/^[1-9][0-9]{9,12}$/.test(businessPhoneNum)) {
        error.businessPhoneNumError = "Please enter a valid phone number";
        formIsValid = false;
      }
    }
    if (!zipCode) {
      error.zipCodeError = "*Zip code can't be empty";
      formIsValid = false;
    }
    // if (zipCode) {
    //   if (!/^[1-9][0-9]{5,6}$/.test(zipCode)) {
    //     error.zipCodeError = "Please enter a valid zip code";
    //     formIsValid = false;
    //   }
    // }

    // if (!faxPhoneNum) {
    //   error.faxPhoneNumError = "* Phone number can't be empty";
    //   formIsValid = false;
    // }
    if (faxPhoneNum) {
      if (!/^[1-9][0-9]{5,10}$/.test(faxPhoneNum)) {
        error.faxPhoneNumError = "Please enter a valid fax number";
        formIsValid = false;
      }
    }

    updateLeadState({ ...iLeadState, leadErrors: error });
    return formIsValid;
  };

  const handleEditLeadValidation = () => {
    let error = {};
    let formIsValid = true;
    if (!confidenceLevel) {
      error.confidenceLevelError = "Confidence level can't be empty";
      formIsValid = false;
    }
    if (!subDivision) {
      error.subDivisionError = "SubDivison  can't be empty";
      formIsValid = false;
    }

    if (!referrerName) {
      error.referrerNameError = "Referrer name can't be empty";
      formIsValid = false;
    }

    if (!leadStatus) {
      error.leadStatusError = "Lead status can't be empty";
      formIsValid = false;
    }

    if (!referrerAgency) {
      error.referrerAgencyError = "Referrer agency can't be empty";
      formIsValid = false;
    }

    if (!referrerEmail) {
      error.referrerEmailError = "* Referrer email can't be empty";
      formIsValid = false;
    }

    if (referrerEmail) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          referrerEmail
        )
      ) {
        error.referrerEmailError = "Please enter a valid email";
        formIsValid = false;
      }
    }
    updateLeadState({ ...iLeadState, leadErrors: error });
    return formIsValid;
  };

  const handleDiscussionValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!sendType) {
      error.sendTypeError = "* Send type can't be empty";
      formIsValid = false;
    }

    if (!location) {
      error.locationError = "* Location can't be empty";
      formIsValid = false;
    }

    if (!activityDate) {
      error.activityDateError = "* Activity date can't be empty";
      formIsValid = false;
    }

    if (!fromTime) {
      error.fromTimeError = "* From time can't be empty";
      formIsValid = false;
    }

    if (!toTime) {
      error.toTimeError = "* To time can't be empty";
      formIsValid = false;
    }

    // if (!toTime) {
    //   error.toTimeError = "* Send type can't be empty";
    //   formIsValid = false;
    // }

    if (!setReminder) {
      error.setReminderError = "* Reminder can't be empty";
      formIsValid = false;
    }

    // if (!sendTo) {
    //   error.sendToError = "* Send to can't be empty";
    //   formIsValid = false;
    // }
    updateLeadState({ ...iLeadState, discussionError: error });
    return formIsValid;
  };

  const handleSurveyValidation = () => {
    let error = {};
    let formIsValid = true;
    if (!leadSource) {
      error.leadSourceError = "* Lead source can't be empty";
      formIsValid = false;
    }

    if (!purchasePlaning) {
      error.purchasePlaningError = "* Purchase planing can't be empty";
      formIsValid = false;
    }

    if (!currentHomeStyle) {
      error.currentHomeStyleError = "* Current home style can't be empty";
      formIsValid = false;
    }

    if (!preferredPrice) {
      error.preferredPriceError = "* Preferred price can't be empty";
      formIsValid = false;
    }

    if (!currentHomeSize) {
      error.currentHomeSizeError = "* Current home size can't be empty";
      formIsValid = false;
    }

    if (!reasonToMove) {
      error.reasonToMoveError = "* Reason to move can't be empty";
      formIsValid = false;
    }

    if (!seekingHomeSize) {
      error.seekingHomeSizeError = "* Seeking home size can't be empty";
      formIsValid = false;
    }

    if (!seekingHomeStyle) {
      error.seekingHomeStyleError = "* Seeking home style can't be empty";
      formIsValid = false;
    }

    if (!seekingHomeSite) {
      error.seekingHomeSiteError = "* Seeking home site can't be empty";
      formIsValid = false;
    }

    if (!beenSearching) {
      error.beenSearchingError = "* Been searching size can't be empty";
      formIsValid = false;
    }

    // if (!moreInfo) {
    //   error.moreInfoError = "* More info style can't be empty";
    //   formIsValid = false;
    // }

    updateLeadState({ ...iLeadState, surveyErrors: error });
    return formIsValid;
  };

  const handleAddSurvey = () => {
    let formIsValid = handleSurveyValidation();
    if (formIsValid) {
      updateLeadState({
        ...iLeadState,
        survey: [
          {
            leadSource,
            purchasePlaning,
            currentHomeStyle,
            preferredPrice,
            currentHomeSize,
            reasonToMove,
            seekingHomeSize,
            seekingHomeStyle,
            seekingHomeSite,
            beenSearching,
            moreInfo,
            mortgageAssistance,
            realtorAssistance,
          },
        ],
      });
      // // le("=========<<<>>>>>>", surveys);
      hideAddSurveyModal();
    }
  };

  const handleUpdateSurvey = () => {
    let formIsValid = handleSurveyValidation();
    if (formIsValid) {
      let data = {
        leadSource,
        purchasePlaning,
        currentHomeStyle,
        preferredPrice,
        currentHomeSize,
        reasonToMove,
        seekingHomeSize,
        seekingHomeStyle,
        seekingHomeSite,
        beenSearching,
        moreInfo,
        mortgageAssistance,
        realtorAssistance,
      };
      dispatch(updateSurveyAction(data, modalId, secondaryId)).then((res) => {
        dispatch(getAllLeadsListAction());
        hideEditSurveyModal();
        sucessToast("Survey updated successfully");
      });
    }
  };

  const handleFolderValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!folderName) {
      errors.folderNameError = "* Folder name can't be empty";
      formIsValid = false;
    }
    updateLeadState({ ...iLeadState, folderError: errors });
    return formIsValid;
  };

  const handleFolder = (e) => {
    e.preventDefault();
    let formIsValid = handleFolderValidation();
    if (formIsValid) {
      // le("======>", modalId);
      dispatch(addFolderAction({ folderName }, modalId, "Contract")).then(
        (res) => {
          sucessToast("Folder created successfully");
          hideAddFolderModal();
          dispatch(searchFolderListAction(modalId, "Contract"));
        }
      );
    }
  };

  const deleteFolder = (id, folid, type) => {
    dispatch(removeFolderAction(id, folid, type)).then((res) => {
      sucessToast("Folder removed successfully");
      dispatch(searchFolderListAction(folid, type));
    });
  };
  const handleInput = (e) => {
    const { name, value } = e.target;

    updateLeadState({ ...iLeadState, [name]: value });
  };

  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateLeadState({ ...iLeadState, [name]: result });
  };

  const handleCheckbox = (e) => {
    const { name, value, checked } = e.target;
    if (checked == true) {
      updateLeadState({ ...iLeadState, [name]: checked });
    } else {
      updateLeadState({ ...iLeadState, [name]: false });
    }
  };
  const createLeadFunc = (e) => {
    e.preventDefault();
    let formIsValid = handleLeadValidation();
    if (formIsValid) {
      const data = {
        legalName,
        streetAddress,
        primaryPhoneNum,
        addressLineTwo,
        phonecode,
        email,
        city,
        businessPhoneNum,
        zipCode,
        faxPhoneNum,
        country,
        preferredCommu,
        province,
        subDivision,
        leadStatus,
        confidenceLevel,
        referrerName,
        referrerAgency,
        referrerEmail,
        survey,
        // assignStaffId: [{ UserId }],
        bussinessPhoneCode,
      };
      dispatch(addLeadsAction(data))
        .then((res) => {
          sucessToast("Lead added successfully");
          dispatch(getAllLeadsListAction());
          //  updateLeadState(leadInitState)
          hideAddLeadModal();
        })
        .catch((err) => {
          errorToast(err.message);
        });
    }
  };

  const handleInputFile = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];

    if (file) {
      updateLeadState({
        ...iLeadState,
        [name]: file,
        fileNameStatic: file.name,
      });
    }
    e.target.files = null;
  };

  // le("===>", file);

  const importContactInformationFun = (e) => {
    e.preventDefault();
    dispatch(importContactFileAction({ file }))
      .then((res) => {
        updateLeadState({
          ...iLeadState,
          addressLineTwo: res?.addressLineTwo,
          businessPhoneNum: res?.businessPhoneNum,
          city: res?.city,
          country: res?.country,
          email: res?.email,
          faxPhoneNum: res?.faxPhoneNum,
          legalName: res?.legalName,
          preferredCommu: res?.preferredCommu,
          primaryPhoneNum: res?.primaryPhoneNum,
          province: res?.province,
          streetAddress: res?.streetAddress,
          zipCode: res?.zipCode,
        });
        hideContactInformationModal();
        if (modalState === "editContract") {
          showEditContactInformationModal();
        } else if (modalState === "addContract") {
          showAddContractModal();
        } else {
          showAddLeadModal();
        }
      })
      .catch((err) => {
        // le("errerrerrerr===>>>", err);
        errorToast(err.response.data.message);
      });
  };

  // le("modalsate====>", modalState);
  const editLeadDetailsFun = (e) => {
    e.preventDefault();
    let formIsValid = handleEditLeadValidation();
    let data = {
      subDivision,
      leadStatus,
      confidenceLevel,
      referrerName,
      referrerAgency,
      referrerEmail,
      assignStaffId: [{ UserId }],
    };

    // if (formIsValid) {
    dispatch(updateLeadAction(data, modalId))
      .then((res) => {
        sucessToast("Lead updated successfully");
        hideEditLeadDetailsModal();
      })
      .catch((err) => {
        // le("=====>>, err", err);
      });
    // }
  };

  const staffList = {
    options: staffListData?.staff,
  };

  const addDiscussionFun = (e) => {
    e.preventDefault();
    let formIsValid = handleDiscussionValidation();
    if (formIsValid) {
      let data = {
        sendType,
        location,
        activityDate,
        fromTime: fromTime.toString(),
        toTime: toTime.toString(),
        setReminder: +setReminder,
        sendTo: "Itinerary",
        assignStaffId: [{ UserId }],
      };
      dispatch(addFollowUpAction(data, leadDetailsData?.id))
        .then((res) => {
          dispatch(getAllfollowUpListAction(modalId));
          sucessToast("Follow up created successfully");
          hideScheduleFollowActivityModal();
        })
        .catch((err) => {
          // le("======>", err);
        });
    }
  };

  function onSelectStream(selectedList, selectedItem) {
    for (let i = 0; i < selectedList?.length; i++) {
      let assignStaffIdLocal = [...assignStaffId];
      let emailDataLocal = [...emailData];
      let streamData = {
        UserId: selectedItem?.id,
      };
      assignStaffIdLocal = [...assignStaffIdLocal, streamData];
      emailDataLocal = [...emailDataLocal, selectedItem?.email];
      updateLeadState({
        ...iLeadState,
        assignStaffId: assignStaffIdLocal,
        emailData: emailDataLocal,
      });
    }
  }

  const editFollowUpActivity = (e) => {
    e.preventDefault();
    let formIsValid = handleDiscussionValidation();
    if (formIsValid) {
      let data = {
        sendType,
        location,
        activityDate,
        fromTime: fromTime.toString(),
        toTime: toTime.toString(),
        setReminder: setReminder,
        sendTo: "Itinerary",
        // assignStaffId:[{
        assignStaffId,
        // }]
      };
      dispatch(updateFollowUpAction(data, modalId, secondaryId))
        .then((res) => {
          sucessToast("Follow up updated successfully");
          dispatch(getAllfollowUpListAction(modalId));
          // hideScheduleFollowActivityModal()
          hideEditFollowUpActivityModal();
        })
        .catch((err) => {
          // le("======>", err);
        });
    }
  };

  const cancelFollowUpActivity = (leadId, id) => {
    dispatch(cancelFollowUpDetailsAction(leadId, id)).then((res) => {
      //  sucessToast("Cancel follow up activity successfully")
      dispatch(getAllfollowUpListAction(modalId));
      sucessToast(res.message);
    });
  };

  const sendInvitationFun = (leadId, id) => {
    dispatch(sendFollowUpInvitationAction(leadId, id)).then((res) => {
      sucessToast("Invitation send successfully");
      dispatch(getAllfollowUpListAction(modalId));
    });
  };

  const updateSetReminderFun = () => {
    dispatch(
      setReminderAction(modalId, secondaryId, { setReminder: +setReminder })
    ).then((res) => {
      hideSetReminderModal();
      sucessToast("Reminder updated successfully");
      dispatch(getAllfollowUpListAction(modalId));
    });
  };

  const handleLockClick = (condition) => {
    updateLeadState((prevState) => ({
      ...prevState,
      satisCondition: {
        ...prevState.satisCondition,
        [`${condition}Status`]: true,
      },
    }));
  };

  const handleUnlockClick = (condition) => {
    updateLeadState((prevState) => ({
      ...prevState,
      satisCondition: {
        ...prevState.satisCondition,
        [`${condition}Status`]: false,
      },
    }));
  };

  const sendApproveUnlockFunc = (id, approvalStatus) => {
    dispatch(changeUnlockConditionalStatusAction(modalId, id, approvalStatus))
      .then((res) => {
        if (res.status == 200) {
          sucessToast(res.message);
          dispatch(getUnlockConditonListAction(modalId));
          dispatch(
            ContractDetailsAction(res.data.updatedCondition.ContractId)
          ).then((res) => {
            updateLeadState({
              ...iLeadState,
              heading: heading,
              contractDetailsData: res?.contract,
              referrerAgency: res?.contract?.[0]?.referrerAgency,
              referrerEmail: res?.contract?.[0]?.referrerEmail,
              referrerName: res?.contract?.[0]?.referrerName,
              subDivision: res?.contract?.[0]?.subDivision,
              satisCondition: {
                depositPaid: res?.contract?.[0]?.SatisCondition?.depositPaid,
                downPayPaid: res?.contract?.[0]?.SatisCondition?.downPayPaid,
                contractSigned:
                  res?.contract?.[0]?.SatisCondition?.contractSigned,
                finanConfirmed:
                  res?.contract?.[0]?.SatisCondition?.finanConfirmed,
                waiverOfConSigned:
                  res?.contract?.[0]?.SatisCondition?.waiverOfConSigned,
                depositPaidStatus:
                  res?.contract?.[0]?.SatisCondition?.depositPaidStatus,
                downPayPaidStatus:
                  res?.contract?.[0]?.SatisCondition?.downPayPaidStatus,
                contractSignedStatus:
                  res?.contract?.[0]?.SatisCondition?.contractSignedStatus,
                finanConfirmedStatus:
                  res?.contract?.[0]?.SatisCondition?.finanConfirmedStatus,
                waiverOfConSignedStatus:
                  res?.contract?.[0]?.SatisCondition?.waiverOfConSignedStatus,
              },
            });
          });
        }
      })
      .catch((err) => {
        errorToast(err);
      });
  };

  const handleClinetDocumentationValidation = () => {
    let error = {};
    let formIsValid = true;

    if (!name) {
      error.nameError = "* Name can't be empty";
      formIsValid = false;
    }

    if (!folderId) {
      error.folderIdError = "* Folder id can't be empty";
      formIsValid = false;
    }
    if (!files) {
      error.clientDocumentationFileError = "* File can't be empty";
      formIsValid = false;
    }
    updateLeadState({ ...iLeadState, clientDocumnetationError: error });
    return formIsValid;
  };

  const handleAddClientDocumentation = (e) => {
    e.preventDefault();
    let formIsValid = handleClinetDocumentationValidation();
    if (formIsValid) {
      let data = {
        name,
        files,
        clientView: false,
      };
      dispatch(addFilesAction(data, folderId, modalId, "Contract")).then(
        (res) => {
          hideCreateNewFileModal();
          dispatch(getAllFileListAction(folderId, modalId, "Contract"));
        }
      );
    }
  };
  const [folderIDD, setFolderIDD] = useState("");

  const showClientDocumentationData = (folderId) => {
    setFolderIDD(folderId);
    updateLeadState({ ...iLeadState, folderIdLocal: folderId });
    let contId = JSON.parse(window.localStorage.getItem("contId"));
    dispatch(getAllFileListAction(folderId, contId, "Contract"));
    dispatch(getAllPendingFileListAction(contId));
  };

  const changeStatusFun = (id, status, folderId) => {
    dispatch(changeStatusAction(folderId, id, { clientView: status }))
      .then((res) => {
        dispatch(getAllFileListAction(folderId, 2, "Contract"));
      })
      .catch((err) => {
        // le("rerere-<", err);
      });
  };

  const handleSendClientInformationValidation = () => {
    let error = {};
    let formIsValid = true;

    if (!emailBody) {
      error.emailBodyError = "* Email body can't be empty";
      formIsValid = false;
    }

    if (!sendInformationEmail) {
      error.emailrror = "* Email can't be empty";
      formIsValid = false;
    }

    if (!sendInformationName) {
      error.nameError = "* Name can't be empty";
      formIsValid = false;
    }
    if (signedDocs.length == 0) {
      error.signedDocsError = "Signed docs can't be empty";
      formIsValid = false;
    }

    updateLeadState({ ...iLeadState, clientInfomationError: error });
    return formIsValid;
  };

  const handleCheckboxSpe = (e) => {
    const { name, value, checked } = e.target;
    let existingSignedDocs = signedDocs;
    let data = {
      blobName: fileListData?.clientDocDetails?.find((x) => x.name == value)
        .blobName,
      name: value,
    };
    const updatedSignedDocs = [...existingSignedDocs, data];
    updateLeadState({ ...iLeadState, signedDocs: updatedSignedDocs });
  };

  const sendInformationFun = (e, contractId = 2) => {
    e.preventDefault();
    let formIsValid = handleSendClientInformationValidation();
    if (formIsValid) {
      let data = {
        emailBody,
        signedDocs,
        emails: [{ name: sendInformationName, email: sendInformationEmail }],
      };
      dispatch(sendClientInformationAction(data, contractId)).then((res) => {
        hideClientInformationModal();
      });
    }
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    updateLeadState({
      ...iLeadState,
      showHideCheck: value == "registeredVendor",
    });
    // setShowHideCheck(e.target.value === 'registeredVendor'); // Set the state based on the selected radio button
  };

  const handleInputSearchCity = (e) => {
    const { name, value } = e.target;
    dispatch(VendorSearchAction(value, vendorType)).then((res) => {
      updateLeadState({ ...iLeadState, vendorSearchData: res.vendors });
    });
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    try {
      if (value == "") {
        setData1(data2);
      } else {
        let temp = data1.filter((item) => {
          return item.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
        setData1(temp);
      }
    } catch (error) {
      // le(error);
    }
  };

  const handleSignatureValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!signature) {
      error.signatureError = "* Signature can't be empty";
      formIsValid = false;
    }

    // if (!initial) {
    //   error.initialError = "* Initial can't be empty";
    //   formIsValid = false;
    // }

    updateLeadState({ ...iLeadState, signatureErrors: error });
    return formIsValid;
  };
  const addSignatureFun = (e) => {
    e.preventDefault();
    let formIsValid = handleSignatureValidation();
    if (formIsValid) {
      const signatureBlob = dataURLtoBlob(signature);
      // const initialBlob = dataURLtoBlob(initial);

      const convertToValidFormat = (blob, fileName) => {
        const fileExtension = fileName.split(".").pop().toLowerCase();
        if (
          ![
            "jpg",
            "jpeg",
            "png",
            "gif",
            "pdf",
            "bmp",
            "webp",
            "svg+xml",
          ].includes(fileExtension)
        ) {
          const convertedBlob = convertToPNG(blob);
          return convertedBlob;
        }
        return blob;
      };
      const validSignatureBlob = convertToValidFormat(
        signatureBlob,
        "signature.png"
      );
      // const validInitialBlob = convertToValidFormat(initialBlob, "initial.png");
      const formData = new FormData();
      formData.append("signature", validSignatureBlob, "signature.png");
      // formData.append("initial", validInitialBlob, "initial.png");
      formData.append("imageOwner", "test");
      dispatch(addSignatureAction(formData, modalId))
        .then((res) => {
          sucessToast("Signaute updated successfully");
          updateModalState({
            ...iModalState,
            manageSignatureModal: false,
            ClientDocumentationModal: true,
          });
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateModalState({ ...iModalState, manageSignatureModal: true });
        });
    }
  };

  // const addSignatureFun = (e,) => {
  //   e.preventDefault();
  //   let formIsValid = handleSignatureValidation();
  //   if (formIsValid) {
  //     dispatch(addSignatureAction({ signature, initial }, modalId)).then(
  //       (res) => {
  //         hideManageSignatureModal();
  //         // le("=======res", res);
  //       }
  //     );
  //   }
  // };

  const handleSystemVendor = (e) => {
    e.preventDefault();
    updateLeadState({
      ...iLeadState,
      sendInformationEmail: vendorSearchData?.find(
        (x) => x?.vendorName == sendInformationName
      )?.email,
    });
    handleSystemVendorModalClose();
  };

  const addUnlockConditionFunc = (e) => {
    e.preventDefault();
    let data = {
      unlockReason: reason,
      requestApproverId,
      requestApprover,
    };
    dispatch(addUnlockCondtionAction(modalId, data)).then((res) => {
      // console.log("resssssssssss",res)
      updateLeadState({
        ...iLeadState,
        reason: "",
        requestApproverId: "",
        requestApprover: "",
      });
      sucessToast("Unlock condition added successfully");
      hideUnlockConditionModal();
    });
  };

  const handleRemoveEmail = (i) => {
    let temp = emailData;
    let assignTemp = assignStaffId;
    temp.splice(i, 1);
    assignTemp.splice(i, 1);
    updateLeadState({
      ...iLeadState,
      emailData: temp,
      assignStaffId: assignTemp,
    });
  };

  useEffect(() => {
    let tempSubStreamList;

    if (
      assignStaffId &&
      assignStaffId.length > 0 &&
      assignStaffId?.some((x) => x?.UserId?.toString().trim() == "")
    ) {
      tempSubStreamList = assignStaffId?.map((item, i) => {
        return {
          id: item?.UserId,
        };
      });

      tempSubStreamList = emailData?.map((item, i) => {
        return {
          email: item,
        };
      });
      updateLeadState({ ...iLeadState, tempAssignList: tempSubStreamList });
    }
  }, [assignStaffId]);

  const addContractFunc = () => {
    // let formIsValid = handleLeadValidation();
    // if(formIsValid ){
    // && salesType && clientAssiUnit
    const data = {
      clients: [
        {
          legalName,
          streetAddress,
          primaryPhoneNum,
          addressLineTwo,
          email,
          city,
          businessPhoneNum,
          zipCode,
          faxPhoneNum,
          country,
          preferredCommu,
          province,
          phonecode,
          bussinessPhoneCode,
        },
      ],
      subDivision,
      referrerName,
      referrerAgency,
      referrerEmail,
      jobType: saleType,
      jobId: +jobId,
      // assignStaffId: [{ UserId }],
    };
    dispatch(addMainContractAction(data)).then((res) => {
      // le("===========res", res);
      if (res) {
        sucessToast("Add Contact successfully");
        dispatch(getAllContractsListAction(modalId));
        updateModalState({ ...iModalState, AddContractModal: false });
      }
    });
    // }
  };

  const addSupportingDocsFunc = () => {
    if (!files) {
      updateLeadState({
        ...iLeadState,
        supportingDocsError: "* Files can't be empty",
      });
    } else {
      dispatch(addSupportingDocsAction({ files }, modalId))
        .then((res) => {
          hideSupportingDocsModal();
          sucessToast("Supporting document added successfully");
          dispatch(getAllsupportingDocsListAction(modalId));
          dispatch(getAllLeadsListAction());
          dispatch(getAllContractsListAction());
        })
        .catch((err) => {
          // le("=>", err);
        });
    }
  };

  const deleteSupportingDocs = (id) => {
    dispatch(removeSupportingDocsAction(modalId, id)).then((res) => {
      sucessToast("Supporting docs removed successfully");
      dispatch(getAllsupportingDocsListAction(modalId));
    });
  };

  const handleUpdateSupportingFun = () => {
    if (typeof files == "object") {
      dispatch(
        updateSupportingDocsAction({ files }, modalId, secondaryId)
      ).then((res) => {
        sucessToast("Supporting docs updated successfully");
        // le("=====>", res);
        dispatch(getAllsupportingDocsListAction(modalId));
        hideEditSupportingModal();
      });
    } else {
      sucessToast("Supporting docs updated successfully");
      hideEditSupportingModal();
    }
  };

  const updateContractFunc = () => {
    // let formIsValid = handleLeadValidation();
    // if(formIsValid){

    const data = {
      legalName,
      streetAddress,
      primaryPhoneNum,
      addressLineTwo,
      email,
      city,
      businessPhoneNum,
      zipCode,
      faxPhoneNum,
      country,
      preferredCommu,
      province,
      subDivision,
      referrerName,
      referrerAgency,
      referrerEmail,
      saleType,
      jobId,
      // assignStaffId,
      phonecode: phonecode?.includes("+") ? phonecode : `+${phonecode}`,
      bussinessPhoneCode: bussinessPhoneCode?.includes("+")
        ? bussinessPhoneCode
        : `+${bussinessPhoneCode}`,
    };

    dispatch(updateContactAction(contID, data)).then((res) => {
      if (res) {
        sucessToast("Updated Contact successfully");
        dispatch(getAllContractsListAction());
        updateModalState({
          ...iModalState,
          editContactInformationModal: false,
        });
      }
    });
    // }
  };

  const handleAddScheduleValidation = () => {
    let formIsValid = true;
    let error = {};
    if (!scheOfPayDate) {
      error.scheOfPayDateError = "* Scheduled of payment can't be empty";
      formIsValid = false;
    }
    if (!transactionType) {
      error.transactionTypeError = "* Transaction type can't be empty";
      formIsValid = false;
    }

    if (total == 0) {
      error.totalError = "* Total can't be empty";
      formIsValid = false;
    }

    updateLeadState({ ...iLeadState, scheduledPaymentError: error });
    return formIsValid;
  };

  const handleAddTransactionScheduleValidation = () => {
    let formIsValid = true;
    let error = {};
    if (!tranDate) {
      error.tranDateError = "* Trans date can't be empty";
      formIsValid = false;
    }
    if (!payMethod) {
      error.payMethodError = "* Pay methoed can't be empty";
      formIsValid = false;
    }

    if (!tranType) {
      error.tranTypeError = "* Trans type can't be empty";
      formIsValid = false;
    }

    if (total == 0) {
      error.totalError = "* Total can't be empty";
      formIsValid = false;
    }

    if (!notes) {
      error.notesError = "* Notes can't be empty";
      formIsValid = false;
    }

    if (!suppDoc) {
      error.suppDocError = "* Support docs can't be empty";
      formIsValid = false;
    }

    updateLeadState({ ...iLeadState, scheduledTransactionPaymentError: error });
    return formIsValid;
  };

  const addSchedulePaymentFun = (e) => {
    e.preventDefault();
    let formIsValid = handleAddScheduleValidation();
    if (formIsValid) {
      const data = {
        scheOfPayDate,
        transactionType,
        total,
      };
      dispatch(addAllSchedulePaymentAction(data, modalId)).then((res) => {
        if (res.status === 200) {
          sucessToast("Scheduled added successfully");
          dispatch(getAllSchedulePaymentListAction(modalId));
          updateLeadState({
            ...iLeadState,
            scheOfPayDate: "",
            transactionType: "",
            total: "",
          });
        }
      });
    }
  };

  const addTransactionScheduledFun = (e) => {
    e.preventDefault();
    let formIsValid = handleAddTransactionScheduleValidation();
    if (formIsValid) {
      const data = {
        // tranId: secondaryId,
        tranDate,
        payMethod,
        tranType,
        total,
        notes,
        suppDoc,
        // tranStatus,
      };
      dispatch(
        addAllSchedulePaymentTransactionAction(data, modalId, secondaryId)
      )
        .then((res) => {
          sucessToast("Transaction added successfully");
          dispatch(getAllScheduleTransactionPaymentListAction(modalId));
          // hideContractTransactionsModal();
        })
        .catch((err) => {
          errorToast(err?.response?.data?.message);
        });
    }
  };

  const updateSchedulePaymentData = (data) => {
    updateLeadState({
      ...iLeadState,
      transactionType: data.transactionType,
      total: data.total,
      scheOfPayDate: moment(data.scheOfPayDate).format("YYYY-MM-DD"),
      trnsId: data.contractId,
      tranId: data.id,
    });
  };

  const editScheduledPaymentFun = () => {
    const data = {
      transactionType,
      scheOfPayDate,
      total,
    };
    dispatch(updateAllSchedulePaymentListAction(trnsId, tranId, data)).then(
      (res) => {
        if (res.status === 200) {
          sucessToast("Scheduled payment updated successfully");
          dispatch(getAllSchedulePaymentListAction(modalId));
          updateLeadState({
            ...iLeadState,
            transactionType: "",
            total: "",
            scheOfPayDate: "",
            trnsId: "",
          });
        }
      }
    );
  };

  const totalClientTotal =
    clientCtcByTemplateData?.formatedData?.[0]?.assemblies?.[0]?.purchaseDetails?.reduce(
      (total, item) => {
        return total + item?.clientTotal * +item?.quantity - item?.unitDiscount;
      },
      0
    );

  const removeScheduledPaymentFun = (id) => {
    dispatch(removeScheduledAction(modalId, id)).then((res) => {
      // le("----ress", res);
      sucessToast("Scheduled payment removed successfully");
      dispatch(getAllSchedulePaymentListAction(modalId));
    });
  };

  const handleShareValidation = () => {
    let formIsValid = true;
    let error = {};

    // if (!emailBody) {
    //   error.emailBodyError = "* Email body can't be empty";
    //   formIsValid = false;
    // }

    if (!name) {
      error.nameError = "* Name can't be empty";
      formIsValid = false;
    }
    if (!email) {
      error.emailError = "* Email can't be empty";
      formIsValid = false;
    }
    if (email) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        error.emailError = "Please enter a valid email";
        formIsValid = false;
      }
    }
    updateLeadState({ ...iLeadState, shareDocumentationError: error });
    return formIsValid;
  };

  const addShareDocsFun = (e) => {
    e.preventDefault();
    let error = {};

    if (emailData?.length === 0) {
      error.nameError = "* Name can't be empty";
      error.emailError = "* Email can't be empty";
      updateLeadState({ ...iLeadState, shareDocumentationError: error });
    } else {
      dispatch(
        shareDocumentAction(
          { emailBody, emails: emailData },
          modalId,
          secondaryId,
          thirdId,
          "Contract"
        )
      ).then((res) => {
        if (res) {
          sucessToast("Share Document successfully");
          hideShareDocumentModal();
        }
      });
    }
  };

  const showUnlockConditionModal = (modalState) => {
    updateModalState({
      ...iModalState,
      addUnlockConditionalModal: true,
      modalState: modalState,
      mainContractEditModal: false,
    });
  };
  const hideUnlockConditionModal = (modalState) => {
    updateModalState({
      ...iModalState,
      addUnlockConditionalModal: false,
      modalState: "",
      mainContractEditModal: true,
    });
  };

  const showUnlockConditionListModal = (modalState) => {
    dispatch(getUnlockConditonListAction(modalId));
    updateModalState({
      ...iModalState,
      unlockConditonListModal: true,
      modalState: modalState,
      mainContractEditModal: false,
    });
  };
  const hideUnlockConditionListModal = (modalState) => {
    updateModalState({
      ...iModalState,
      unlockConditonListModal: false,
      modalState: "",
      mainContractEditModal: true,
    });
  };
  const addRecipentList = () => {
    let formIsValid = handleShareValidation();
    if (formIsValid) {
      let emailDataLocal = [...emailData];

      let recipentData = {
        email,
        name,
      };
      emailDataLocal = [...emailDataLocal, recipentData];
      updateLeadState({
        ...iLeadState,
        emailData: emailDataLocal,
        email: "",
        name: "",
      });
    }
  };

  const removeEmailData = (i) => {
    let data = emailData?.splice(i, 1);
    updateLeadState({ ...iLeadState, data });
  };

  const updateContractGenralApi = (e) => {
    e.preventDefault();
    let data = {
      subDivision,
      referrerAgency,
      referrerEmail,
      referrerName,
      clientPurchasePrice,
      isRebate
    };
    dispatch(updateContactAction(modalId, data)).then((res) => {
      // console.log("ressssss", res);
      hideMainContractModal();
      sucessToast("Contract general information updated successfully");
    });
  };

  const updateAssignUnitFunc = (e) => {
    e.preventDefault();
    dispatch(updateAssignUnitAction({ jobId, jobType: saleType }, modalId))
      .then((res) => {
        // console.log("ressssss", res?.response);
        // if(res?.response?.status == 400){
        //   hideMainContractModal();
        //   hideClientAssignUnitMOdal()
        //   errorToast(res?.response?.data?.message);
        // } else{
        dispatch(getAllContractsListAction());
        hideMainContractModal();
        hideClientAssignUnitMOdal();
        sucessToast("Assign unit updated successfully");
        // }
      })
      .catch((err) => {
        if (err?.response?.status == 400) {
          hideMainContractModal();
          hideClientAssignUnitMOdal();
          errorToast(err?.response?.data?.message);
        } else {
          hideMainContractModal();
          hideClientAssignUnitMOdal();
          errorToast("Internal server error");
        }
      });
  };

  const clearSignature = () => {
    signCanvas.current.clear();
  };
  const clearInitials = () => {
    intialCanvas.current.clear();
  };
  // const saveSignature = () => {
  //   updateLeadState({...iLeadState, signature : signCanvas.current.getTrimmedCanvas()?.toDataUrl()})

  // };

  // // le("dlfdjflfdjgdflgdf", signature)
  const handleChildData = (data) => {
    console.log("cness", data);
    updateLeadState({ ...iLeadState, ProjectId: data });
  };

  const handleGoogleData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: new Date(),
      reminderDate: item.reminderDate,
      location: "Sample Location",
    };
    dispatch(addGoogleCalendayAction(datta))
      .then((res) => {
        window.open(res?.google, "_blank");
        dispatch(getAllfollowUpListAction(modalId));
        sucessToast("Calendar links generated successfully");
      })
      .catch((error) => {
        errorToast("Something went wrong", error?.response?.message);
      });
  };

  const handleYahooData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: new Date(),
      reminderDate: item.reminderDate,
      location: "Sample Location",
    };
    dispatch(addYahooAction(datta))
      .then((res) => {
        window.open(res?.yahoo, "_blank");
        dispatch(getAllfollowUpListAction(modalId));
        sucessToast("Yahoo links generated successfully");
      })
      .catch((error) => {
        errorToast("Something went wrong", error?.response?.message);
      });
  };

  const handleOutlookData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: new Date(),
      reminderDate: item.reminderDate,
      location: "Sample Location",
    };
    dispatch(addOutlookCalendayAction(datta))
      .then((res) => {
        window.open(res?.outlook, "_blank");
        dispatch(getAllfollowUpListAction(modalId));
        sucessToast("Outlook links generated successfully");
      })
      .catch((error) => {
        errorToast("Something went wrong", error?.response?.message);
      });
  };

  const addParkingFunc = (e) => {
    e.preventDefault();
    let data = {
      description,
      price,
    };
    dispatch(addParkingAction(modalId, data)).then((res) => {
      // console.log("ressssss", res);
      // hideParkingModal();
      dispatch(getParkingListAction(modalId));
      updateLeadState({ ...iLeadState, description: "", price: "" });
      sucessToast("Parking information added successfully");
    });
  };

  const removeParkingFunc = (id) => {
    // console.log("idddddd", id);
    dispatch(removeParkingAction(modalId, id)).then((res) => {
      // console.log("ressssss", res);
      // hideParkingModal();
      dispatch(getParkingListAction(modalId));
      sucessToast("Parking information removed successfully");
    });
  };

  const updateConditionalDetailsFunc = () => {
    // console.log("satisCondtion", satisCondition)
    dispatch(updateConditionalDetailsAction({ satisCondition }, modalId)).then(
      (res) => {
        // console.log("ressssss", res);
        // hideParkingModal();
        // dispatch(getParkingListAction(modalId));
        dispatch(getAllContractsListAction());
        sucessToast("Conditional information updated successfully");
      }
    );
  };

  const showSOAModal = (modalState, id) => {
    dispatch(getSOADetailsAction(id)).then((res) => {
      // console.log("ressssss", res?.data);
      updateLeadState({ ...iLeadState, SOADetailsData: res?.data });
    });
    updateModalState({ ...iModalState, SOAModal: true, modalId: id });
  };

  console.table("SOADetailsData",SOADetailsData);
  const hideSOAModal = () => {
    updateModalState({ ...iModalState, SOAModal: false });
  };

  const showSOAHistoryModal = (state, id) => {
    dispatch(SOAHistoryAction(modalId, id));
    updateModalState({
      ...iModalState,
      SOAHistoryModal: true,
      SOAModal: false,
    });
  };
  const hideSOAHistoryModal = (id) => {
    updateModalState({
      ...iModalState,
      SOAHistoryModal: false,
      SOAModal: true,
    });
  };

  const addSOAFunc = (state, id) => {
    dispatch(addSOAAction(modalId))
      .then((res) => {
        // console.log("ressssss", res);
        hideSOAModal();
        // dispatch(getSOAListAction(modalId));
        sucessToast("SOA information added successfully");
      })
      .catch((err) => {
        // console.log("error", err);
        errorToast("No transactions found to create SOA");
      });
  };

  const showClientAssignUnitMOdal = (state, id) => {
    updateModalState({
      ...iModalState,
      clientAssignUnitModal: true,
      modalId: id,
    });
  };

  const hideClientAssignUnitMOdal = () => {
    updateModalState({ ...iModalState, clientAssignUnitModal: false });
  };

  const handleCTCTemplateReleasedModalShow = () => {
    // dispatch(getClientCtcByTemplate());
    updateModalState({
      ...iModalState,
      CTCTemplateReleasedModal: true,
      ClientDocumentationModal: false,
    });
  };

  useEffect(() => {
    if (CTCTemplateReleasedModal) {
      dispatch(getTemplateReleaseList());
    }
  }, [CTCTemplateReleasedModal]);

  const handleCTCTemplateReleasedModalClose = () => {
    updateModalState({
      ...iModalState,
      CTCTemplateReleasedModal: false,
      ClientDocumentationModal: true,
    });
  };

  const handleDeleteShow = (id) => {
    updateModalState({
      ...iModalState,
      DeleteModal: true,
      CTCTemplateReleasedModal: false,
      templateId: id,
    });
  };

  const handleDeleteTemplate = () => {
    dispatch(deleteDraftTemplate(templateId))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getTemplateReleaseList());
          toast.success(" Delete Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateModalState({
            ...iModalState,
            DeleteModal: false,
            docId: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateModalState({
            ...iModalState,
            FolderDeleteModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({
          ...iModalState,
          DeleteModal: true,
        });
      });
  };

  const handleDeleteClose = () => {
    updateModalState({
      ...iModalState,
      DeleteModal: false,
      CTCTemplateReleasedModal: true,
    });
  };

  const handleHistoryModalShow = () => {
    updateModalState({ ...iModalState, HistoryModal: true });
    dispatch(
      getClientCtcGenerateHistory(
        clientCtcByTemplateData?.ctc?.ContractId,
        clientCtcByTemplateData?.ctc?.id
      )
    );
    updateModalState({
      ...iModalState,
      createClientCTCModal: false,
    });
  };

  const handleHistoryModalClose = () => {
    updateModalState({ ...iModalState, HistoryModal: false });
    updateModalState({
      ...iModalState,
      createClientCTCModal: true,
    });
  };

  const handleViewCtcModalShow = (id) => {
    // dispatch(getClientCtcByTemplate(id));
    updateModalState({
      ...iModalState,
      createClientCTCModal: false,
      ViewCtcModal: true,
    });
  };

  const handleViewCtcModalClose = () => {
    updateModalState({
      ...iModalState,
      createClientCTCModal: true,
      ViewCtcModal: false,
    });
  };

  const handleCreateDraft = () => {
    const dataa = {
      CTCTemplateId: clientCtcByTemplateData?.ctc?.CTCTemplateId,
      purchaseDetails: [
        {
          unitDiscount: +unitDiscount,
          quantity: +quantity,
          isChecked: true,
          purchaseDetailId: +purchaseDetailsId,
        },
      ],
      clientVisible: true,
    };

    dispatch(createClientDraft(modalId, dataa))
      .then((res) => {
        dispatch(getAllClientAction(modalId));
        dispatch(generateClientCTC(modalId, res?.data?.data?.clientCTC?.id));
        if (res.status === 201) {
          toast.success("Add Draft Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateModalState({
            ...iModalState,
            createClientCTCModal: false,
            ViewCtcModal: true,
            afterDraftViewCTCData: res?.data?.data?.formatedData,
          });
        } else {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateModalState({
            ...iModalState,
            createClientCTCModal: false,
            ViewCtcModal: true,
          });
        }
      })
      .catch((error) => {
        errorToast(error.message);
        updateModalState({
          ...iModalState,
          createClientCTCModal: false,
          ViewCtcModal: true,
        });
      });
  };

  const handleSendClientSignatures = () => {
    dispatch(
      sendForClientSignatures(
        clientCtcByTemplateData?.ctc?.ContractId,
        clientCtcByTemplateData?.ctc?.id
        //{state:"SEND"}
      )
    )
      .then((res) => {
        console.log("SEND EMAIL =============>");
        if (res) {
          sucessToast("Send Signture Successfully");
          updateModalState({ ...iModalState, ViewCtcModal: false });
          updateModalState({
            ...iModalState,
            ClientDocumentationModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateModalState({ ...iModalState, ViewCtcModal: true });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({ ...iModalState, ViewCtcModal: false });
      });
  };

  useEffect(() => {
    let temp = assignStaffId
      ?.map((item) => {
        return item?.anchorData?.flat();
      })
      ?.flat();
    setPreSelectedData(temp);
  }, [assignStaffId]);

  const handleFollowUpActivityModalShow = (id) => {
    updateModalState({
      ...iModalState,
      FollowUpActivityModal: true,
      ClientDocumentationModal: false,
    });
    updateLeadState({ ...iLeadState, clientDocId: id });
  };

  useEffect(() => {
    if (FollowUpActivityModal) {
      // let LeadId = JSON.parse(window.localStorage.getItem("leadId"));
      // dispatch(getFollowUpsList(LeadId));
      let contId = JSON.parse(window.localStorage.getItem("contId"));
      dispatch(getClientDocTemplates(clientDocId, contId));
    }
  }, [FollowUpActivityModal]);

  const handleFollowUpActivityModalClose = () => {
    updateModalState({
      ...iModalState,
      FollowUpActivityModal: false,
      ClientDocumentationModal: true,
    });
    updateLeadState({ ...iLeadState, clientDocId: "" });
  };

  const handleActivityDelete = (leadId, followId) => {
    dispatch(deleteFollowUps(leadId, followId)).then((res) => {
      if (res.status === 200) {
        dispatch(getFollowUpsList(leadId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateModalState({
          ...iModalState,
          FollowUpActivityModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleSendInvitation = (leadId, followId) => {
    dispatch(sendInvitation(leadId, followId)).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateModalState({
          ...iModalState,
          FollowUpActivityModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleSendForClientModalShow = (data) => {
    updateModalState({
      ...iModalState,
      SendForClientModal: true,
      ClientDocumentationModal: false,
    });
    updateLeadState({
      ...iLeadState,
      folderId: data?.folderId,
      docsId: data?.id,
    });

    setDocsUrlData(data?.docUrl);
  };

  const [primayClient, setPrimaryClient] = useState([]);
  const [secondaryClient, setSecondaryClient] = useState([]);
  const [builderData, setBuilder] = useState([]);

  useEffect(() => {
    if (SendForClientModal) {
      let contId = JSON.parse(window.localStorage.getItem("contId"));
      dispatch(getBuilderSigneeList()).then((response) => {
        if (response.status === 200) {
          const buillldata = response?.data?.staff;
          setBuilder(buillldata);
        }
      });
      dispatch(getAllClientAction(contId)).then((res) => {
        if (res) {
          const primeData = res?.clientDetails?.filter(
            (data) => data.isPrimary === true
          );
          const secondData = res?.clientDetails?.filter(
            (data) => data.isPrimary === false
          );
          setPrimaryClient(primeData);
          setSecondaryClient(secondData);
        }
      });
    }
  }, [SendForClientModal]);

  const handleSendForClientModalClose = () => {
    updateModalState({
      ...iModalState,
      SendForClientModal: false,
      ClientDocumentationModal: true,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "numberOfSignee") {
      updateLeadState({
        ...iLeadState,
        [name]: value,
        secondarySignee: "",
        additionalSignee: "",
        secondarySignStatus: "",
        additionalSignStatus: "",
      });
    } else if (name === "primarySignee") {
      const dummmyPrimaryData = primayClient?.find((item) => item.id == value);
      updateLeadState({
        ...iLeadState,
        [name]: value,
        primarySignStatus: dummmyPrimaryData?.legalName,
      });
    } else if (name === "secondarySignee") {
      const dummmySecondaryData = secondaryClient?.find(
        (item) => item.id == value
      );
      updateLeadState({
        ...iLeadState,
        [name]: value,
        secondarySignStatus: dummmySecondaryData?.legalName,
      });
    } else if (name === "additionalSignee") {
      const dummmyAdditionalData = secondaryClient?.find(
        (item) => item.id == value
      );
      updateLeadState({
        ...iLeadState,
        [name]: value,
        additionalSignStatus: dummmyAdditionalData?.legalName,
      });
    } else if (name === "builderSignee") {
      const dummmyBuilderData = builderData?.find((item) => item.id == value);
      updateLeadState({
        ...iLeadState,
        [name]: value,
        builderSignStatus: dummmyBuilderData?.name,
      });
    } else if (name === "slectedClient") {
      const dummmyallClientsData = allClients?.find((item) => item.id == value);
      updateLeadState({
        ...iLeadState,
        [name]: value,
        name: dummmyallClientsData?.legalName,
        email: dummmyallClientsData?.email,
      });
    } else {
      updateLeadState({ ...iLeadState, [name]: value });
    }
  };

  const handleSendForClient = () => {
    // let contId = JSON.parse(window.localStorage.getItem("contId"));
    // const formData = new FormData();
    // formData.append("primarySignee[0]", primarySignee);
    // formData.append("secondarySignee[0]", secondarySignee);
    // formData.append("numberOfSignee", numberOfSignee);
    // formData.append("builderSignee[0]", iBuildLocalData?.user?.id);
    // formData.append("draft", "false");
    // dispatch(sendForClientsSignature(folderId, docsId, contId, formData)).then(
    //   (res) => {
    //     if (res.status === 200) {
    //       toast.success(res.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 1000,
    //       });
    //       updateModalState({
    //         ...iModalState,
    //         SendForClientModal: false,
    //         ClientDocumentationModal: true,
    //       });
    //       updateLeadState({
    //         ...iLeadState,
    //         primarySignee: "",
    //         secondarySignee: "",
    //         numberOfSignee: "",
    //       });
    //     } else {
    //       toast.error(res.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //       updateModalState({ ...iModalState, SendForClientModal: true });
    //     }
    //   }
    // );
    handleSendforClientSignatureModalShow();
  };

  const [includedConsultantt, setIncludedConsultantt] = useState([]);
  const [FilterModal, setFilterModal] = useState(false);

  const handleFilterModalShow = () => {
    setFilterModal(true);
    setIncludedConsultantt([]);
  };

  const handleFilterModalClose = () => {
    setFilterModal(false);
    setIncludedConsultantt([]);
  };

  const consultModule = [
    { value: "legalName", label: "Client Contact" },
    { value: "createdAt", label: "Date Created" },
    { value: "subDivision", label: "Subdivision" },
    { value: "leadStatus", label: "Status" },
    { value: "Budget", label: "Reason" },
    { value: "No", label: "Latest Follow up Activity" },
    { value: "day", label: "Age" },
    { value: "confidenceLevel", label: "Confidence leve" },
  ];

  const handleConsChange = (selectedOption) => {
    setIncludedConsultantt(selectedOption);
  };

  const handleApply = () => {
    setFilterModal(false);
  };

  const handleAllLockFUnc = () => {
    updateLeadState((prevState) => ({
      ...prevState,
      satisCondition: {
        ...prevState.satisCondition,
        depositPaidStatus: true,
        downPayPaidStatus: true,
        contractSignedStatus: true,
        finanConfirmedStatus: true,
        waiverOfConSignedStatus: true,
      },
    }));
    dispatch(updateConditionalDetailsAction({ satisCondition }, modalId)).then(
      (res) => {
        // console.log("ressssss", res);
        // hideParkingModal();
        // dispatch(getParkingListAction(modalId));
        dispatch(getAllContractsListAction());
        sucessToast("Conditional information updated successfully");
      }
    );
  };

  const [GrossMarginModal, setGrossMarginModal] = useState(false);

  const handleGrossMarginModalClose = () => {
    updateModalState({
      ...iModalState,
      FollowUpActivityModal: true,
    });
    setGrossMarginModal(false);
  };
  const [grossMarginData, setGrossMarginData] = useState([]);
  const handleGrossMarginModalShow = (id) => {
    setGrossMarginModal(true);
    updateModalState({
      ...iModalState,
      FollowUpActivityModal: false,
    });
    let LeadId = JSON.parse(window.localStorage.getItem("leadId"));
    dispatch(getGrossMargin(id)).then((res) => {
      //  console.log("resssss", res?.data?.data)
      setGrossMarginData(res?.data?.data);
    });
  };
  // console.log("groessMarginData,,, ", grossMarginData)
  const handleDownloadTemplate = () => {};

  const [discussionState, updateDiscussionState] = useState({
    jobId: "",
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction({
          message: discussionState.message,
          helpId: contractDetailsData[0]?.id ?? 1,
          type: "Help",
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: contractDetailsData[0]?.id ?? 1,
                type: "Help",
              })
            );

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          helpId: contractDetailsData[0]?.id,
          type: "Help",
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: contractDetailsData[0]?.id,
                type: "Help",
              })
            );
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const createRoomfun = (id) => {
    let data = {
      userOneId: iBuildLocalData?.user?.CompanyId,
      userTwoId: id,
    };
    dispatch(createRoomAction(data)).then((res) => {
      // console.log("resss", res)
      dispatch(getMessagesAction(res?.data?.data?.id));
      updateLeadState({
        ...iLeadState,
        roomId: res?.data?.data?.id,
        senderId: id,
      });
      SetLeadDetailsShowMore(true);
    });
  };
  const sendMessageFunc = (e) => {
    // e.preventDefault()
    if (e.keyCode == 13) {
      let data = {
        senderId,
        receiverId: iBuildLocalData?.user?.CompanyId,
        message,
        roomId,
      };
      dispatch(createMessageAction(data)).then((res) => {
        // console.log("ressss", res)
        sucessToast("Message sent successfully");
        dispatch(getMessagesAction(roomId));
      });
    }
  };

  const [docsUrlData, setDocsUrlData] = useState("");
  const [SendforClientSignatureModal, setSendforClientSignatureModal] =
    useState(false);
  const handleSendforClientSignatureModalShow = async () => {
    setSendforClientSignatureModal(true);
    updateModalState((prevState) => ({
      ...prevState,
      SendForClientModal: false,
    }));

    try {
      const contId = JSON.parse(window.localStorage.getItem("contId"));
      const payload = {
        primaryClientId: +primarySignee,
        secondaryClientId: +secondarySignee,
        additionalClientId: +additionalSignee,
        clientDocId: +docsId,
        contractId: +contId,
        folderId: +folderId,
        builderId: +builderSignee,
      };

      const response = await dispatch(clientDocTemplates(payload));

      if (response?.data) {
        setDocsUrlData(response.data.data);
      } else {
        console.warn("Response data not found.");
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message.
      console.error("Error fetching documentation:", error);
    }
  };

  const handleSendforClientSignatureModalClose = () => {
    setSendforClientSignatureModal(false);
    updateModalState((prevState) => ({
      ...prevState,
      SendForClientModal: true,
    }));
  };

  const createAboutMarkup = () => {
    return { __html: docsUrlData ?? "Documents uploading..." };
  };
  const handleManageSignatueShow = (type) => {
    if (type == "primarySignature") {
      updateModalState({
        ...iModalState,
        ManageSignature: true,
        primarySignature:null,
        signTypes: type,
      });
    }
    if (type == "secondarySignature") {
      updateModalState({
        ...iModalState,
        ManageSignature: true,
        secondarySignature:null,
        signTypes: type,
      });
    }
    if (type == "builderSignature") {
      updateModalState({
        ...iModalState,
        ManageSignature: true,
        builderSignature:null,
        signTypes: type,
      });
    }
    if (type == "additionalSignature") {
      updateModalState({
        ...iModalState,
        ManageSignature: true,
        additionalSignature:null,
        signTypes: type,
      });
    }

    if (type == "ctcSignature") {
      updateModalState({
        ...iModalState,
        ManageSignature: true,
        ctcSignature: "",
        signTypes: type,
      });
    }

    if (type == "ctcSignatureBuilder") {
      updateModalState({
        ...iModalState,
        ManageSignature: true,
        ctcSignatureBuilder: "",
        signTypes: type,
      });
    }
  };
  const handleManageSignatueClose = () => {
    updateModalState({
      ...iModalState,
      ManageSignature: false,
      signature: "",
      signTypes: "",
    });
  };

  const addSignature = () => {
    const signatureBlob = dataURLtoBlob(signature);
    const convertToValidFormat = (blob, fileName) => {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (
        ![
          "jpg",
          "jpeg",
          "png",
          "gif",
          "pdf",
          "bmp",
          "webp",
          "svg+xml",
        ].includes(fileExtension)
      ) {
        const convertedBlob = convertToPNG(blob);
        return convertedBlob;
      }
      return blob;
    };
    const validSignatureBlob = convertToValidFormat(
      signatureBlob,
      "signature.png"
    );
    const validSignatureBlobURL = URL.createObjectURL(validSignatureBlob);

    if (signTypes === "primarySignature") {
      updateModalState({
        ...iModalState,
        primarySignature: validSignatureBlobURL,
        primarySignatureBlob: validSignatureBlob,
        ManageSignature: false,
      });
    } else if (signTypes === "secondarySignature") {
      updateModalState({
        ...iModalState,
        secondarySignature: validSignatureBlobURL,
        secondarySignatureBlob: validSignatureBlob,
        ManageSignature: false,
      });
    } else if (signTypes === "builderSignature") {
      updateModalState({
        ...iModalState,
        builderSignature: validSignatureBlobURL,
        builderSignatureBlob: validSignatureBlob,
        ManageSignature: false,
      });
    } else if (signTypes === "additionalSignature") {
      updateModalState({
        ...iModalState,
        additionalSignature: validSignatureBlobURL,
        additionalSignatureBlob: validSignatureBlob,
        ManageSignature: false,
      });
    } else if (signTypes === "ctcSignature") {
      updateModalState({
        ...iModalState,
        ctcSignature: validSignatureBlobURL,
        ctcSignatureBlob: validSignatureBlob,
        ManageSignature: false,
      });
    } else if (signTypes === "ctcSignatureBuilder") {
      updateModalState({
        ...iModalState,
        ctcSignatureBuilder: validSignatureBlobURL,
        ctcSignatureBuilderBlob: validSignatureBlob,
        ManageSignature: false,
      });
    }
  };

  const clearSignatureFunc = () => {
    signCanvas.current.clear();
  };

  const handleSendToClient = async (type) => {
    let contId = JSON.parse(window.localStorage.getItem("contId"));
    const htmlContent =
      iBuildLocalData?.companies?.[0]?.letterHeader + docsUrlData;
    const blob = new Blob([htmlContent], { type: "text/html" });
    const file = new File([blob], "content.html");

    const initialFormData = new FormData();
    initialFormData.append("files", file);

    try {
      const uploadResponse = await dispatch(
        uploadAggrementFiles(initialFormData)
      );
      if (uploadResponse.status === 200) {
        const formData = new FormData();
        formData.append("htmlBlobName", uploadResponse?.data?.data);
        formData.append("primarySignee", Number(primarySignee));
        formData.append("primarySign", primarySignatureBlob);
        formData.append("secondarySign", secondarySignatureBlob);
        formData.append("additionalSign", additionalSignatureBlob);
        formData.append("builderSign", builderSignatureBlob);
        formData.append("primarySignStatus", !!primarySignStatus);
        formData.append("secondarySignee", Number(secondarySignee));
        formData.append("secondarySignStatus", !!secondarySignStatus);
        formData.append("builderSignee", Number(builderSignee));
        formData.append("builderSignStatus", !!builderSignStatus);
        formData.append("additionalSignee", Number(additionalSignee));
        formData.append("additionalSignStatus", !!additionalSignStatus);
        formData.append("numberOfSignee", Number(numberOfSignee));
        formData.append("status", type === "save" ? "DRAFT" : "SEND");

        const signatureResponse = await dispatch(
          generatedDocumentForClientSignature(
            folderId,
            docsId,
            contId,
            formData
          )
        );

        if (signatureResponse.status === 200) {
          toast.success(signatureResponse.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setSendforClientSignatureModal(false);
          updateModalState((prev) => ({
            ...prev,
            primarySignature:null,
            secondarySignature:null,
            additionalSignature:null,
            builderSignature:null,
            signTypes: "",
            primarySignee: "",
            primarySignStatus: "",
            secondarySignee: "",
            secondarySignStatus: "",
            builderSignee: "",
            builderSignStatus: "",
            additionalSignee: "",
            additionalSignStatus: "",
          }));
        } else {
          throw new Error(signatureResponse.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSendforClientSignatureModal(true);
    }
  };

  const createAboutHeader = () => {
    return { __html: iBuildLocalData?.companies?.[0]?.letterHeader };
  };

  const showClientSignatureModal = () => {
    updateModalState({
      ...iModalState,
      clientSignatureModal: true,
      ViewCtcModal: false,
    });
  };

  const hideClientSignatureModal = () => {
    updateModalState({
      ...iModalState,
      clientSignatureModal: false,
      ViewCtcModal: true,
    });
  };

  const showClientHistoryModal = () => {
    updateModalState({
      ...iModalState,
      clientHistoryModal: true,
      ViewCtcModal: false,
    });
  };

  const hideClientHistoryModal = () => {
    updateModalState({
      ...iModalState,
      clientHistoryModal: false,
      ViewCtcModal: true,
    });
  };

  const [viewDocumentData, setViewDocumentData] = useState([]);

  const showViewFileModal = async (url) => {
    updateModalState({
      ...iModalState,
      viewDocumentModal: true,
      ClientDocumentationModal: false,
    });
    try {
      const urlWithoutQueryString = url?.split("?")[0];
      const htmlData = await dispatch(
        getHtmlFile({ url: urlWithoutQueryString })
      );
      if (htmlData) {
        setViewDocumentData(htmlData?.data);
      }
    } catch (error) {
      console.error("Error fetching documentation:", error);
    }
  };
  const hideViewFileModal = () => {
    updateModalState({
      ...iModalState,
      viewDocumentModal: false,
      ClientDocumentationModal: true,
    });
  };

  const [content, setContent] = useState([]);
  const [contentData, setContentData] = useState([]);
  const showEditFileModal = async (item) => {
    updateModalState({
      ...iModalState,
      editFileModal: true,
      ClientDocumentationModal: false,
    });
    setContentData(item);
    try {
      const urlWithoutQueryString = item?.docUrl?.split("?")[0];
      const htmlData = await dispatch(
        getHtmlFile({ url: urlWithoutQueryString })
      );
      if (htmlData) {
        setContent(htmlData?.data);
      }
    } catch (error) {
      console.error("Error fetching documentation:", error);
    }
  };
  const hideEditFileModal = () => {
    updateModalState({
      ...iModalState,
      editFileModal: false,
      ClientDocumentationModal: true,
    });
  };

  const editorsRef = useRef(null);

  const handleEditorChange = (newContent) => {
    setContent(newContent);
  };

  const [HeaderPreviewModal, setHeaderPreviewModal] = useState(false);
  const handlHeaderPreviewModalShow = () => {
    updateModalState({
      ...iModalState,
      editFileModal: false,
    });
    setHeaderPreviewModal(true);
  };
  const handlHeaderPreviewModalClose = () => {
    updateModalState({
      ...iModalState,
      editFileModal: true,
    });
    setHeaderPreviewModal(false);
  };

  const handleHeaderApply = () => {
    const dataa = iBuildLocalData?.companies?.[0]?.letterHeader;
    const updatedContent = dataa ? dataa + content : content;
    updateModalState({
      ...iModalState,
      editFileModal: true,
    });
    setContent(updatedContent);
    setHeaderPreviewModal(false);
  };

  const handleDocumentKeyModalShow = () => {
    dispatch(getAllDocumentKeyList());
    updateModalState({
      ...iModalState,
      editFileModal: false,
      DocumentKeyModal: true,
    });
  };

  const handleDocumentKeyModalClose = () => {
    updateModalState({
      ...iModalState,
      editFileModal: true,
      DocumentKeyModal: false,
    });
  };

  const handleItemClick = (e) => {
    const { value } = e.target;
    const updatedContent = value ? value + content : content;
    updateModalState({
      ...iModalState,
      editFileModal: true,
      DocumentKeyModal: false,
    });
    setContent(updatedContent);
  };

  const [InsertSignatureModal, setInsertSignatureModal] = useState(false);
  const handlInsertSignatureModalClose = () => {
    setInsertSignatureModal(false);
    updateModalState({
      ...iModalState,
      editFileModal: true,
    });
  };
  useEffect(() => {
    if (InsertSignatureModal) {
      let contId = JSON.parse(window.localStorage.getItem("contId"));
      dispatch(getAllSignature(contId));
    }
  }, [InsertSignatureModal]);

  const handlInsertSignatureModalShow = () => {
    setInsertSignatureModal(true);
    updateModalState({
      ...iModalState,
      editFileModal: false,
    });
  };

  const handleAddSignature = () => {
    if (allSignatureList?.images?.length > 0) {
      let updatedContent = content; // Initialize with current content

      allSignatureList?.images?.forEach((image) => {
        const imgTag = `<img src="${image.docUrl}" alt="Signature Image" />`;
        updatedContent += imgTag; // Append each image HTML to existing content
      });
      setContent(updatedContent);
      setInsertSignatureModal(false);
      updateModalState({ ...iModalState, editFileModal: true }); // Update editor content
    }
  };
  const addClientFunc = (e) => {
    e.preventDefault();
    let data = {
      legalName,
      streetAddress,
      primaryPhoneNum,
      addressLineTwo,
      email,
      city,
      businessPhoneNum,
      zipCode,
      faxPhoneNum,
      country,
      preferredCommu,
      province,
      phonecode,
    };

    dispatch(addClientAction(modalId, data))
      .then((res) => {
        sucessToast("Client added successfully");
        dispatch(getAllClientAction(modalId));
        hideAddClientModal();
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  };

  const [clientDocData, setClientDocData] = useState([]);
  const [headerData, setHeaderData] = useState("");
  const handlSignModalShow = (item) => {
    setClientDocData(item);
    const filename = item?.htmlBlobName?.split("?")[0];
    dispatch(getConverToString(filename, iBuildLocalData?.user?.token)).then(
      (response) => {
        if (response.status === 200) {
          setHeaderData(response?.data?.data);
        }
      }
    );
    updateModalState({
      ...iModalState,
      SignModal: true,
      FollowUpActivityModal: false,
    });
  };

  const primaryData = clientDocData?.ClientDocAssignees?.find(
    (item) => item.signeeType === "Primary Signee"
  );

  const secondaryData = clientDocData?.ClientDocAssignees?.find(
    (item) => item.signeeType === "Secondary Signee"
  );
  const additionalData = clientDocData?.ClientDocAssignees?.find(
    (item) => item.signeeType === "Additional Signee"
  );
  const builderDatas = clientDocData?.ClientDocAssignees?.find(
    (item) => item.signeeType === "Builder Signee"
  );

  const handlSignModalClose = () => {
    updateModalState({
      ...iModalState,
      SignModal: false,
      FollowUpActivityModal: true,
    });
  };

  const [titles, setTitles] = useState("");
  const [messages, setMessages] = useState("");
  const [attachments, setAttachments] = useState([]);
  const handleFileChange = (event) => {
    const files = event?.target?.files;
    if (files) {
      setAttachments([...files]);
    }
  };

  const handleRemoveClientFile = (indexToRemove) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleNewEmailSubmit = () => {
    const formData = new FormData();

    formData.append("title", titles);
    formData.append("message", messages);
    Array.from(attachments).forEach((image) => {
      formData.append("attachments", image);
    });

    formData.append(
      "email",
      leadDetailsData?.email || contactDetailsData?.email
    );
    formData.append(
      "name",
      leadDetailsData?.legalName || contactDetailsData?.legalName
    );
    dispatch(sendNewEmails(leadDetailsData?.id, formData)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        if (modalState === "details") {
          updateModalState({
            ...iModalState,
            newEmailModal: false,
            modalState: "",
            editLeadDetailsModal: true,
          });
        } else {
          updateModalState({
            ...iModalState,
            newEmailModal: false,
            ContactDetailsModal: true,
          });
        }

        setTitles("");
        setMessages("");
        setAttachments([]);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({
          ...iModalState,
          newEmailModal: true,
        });
      }
    });
  };

  const tableRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const [paymentReminder, setPaymentReminder] = useState("");
  const [AddPaymentReminder, setAddPaymentReminder] = useState(false);
  const handleShowAddPaymentReminder = (data) => {
    setAddPaymentReminder(true);
    setPaymentReminder(moment(data.setPaymentReminder).format("YYYY-MM-DD"));
    updateLeadState({
      ...iLeadState,
      trnsId: data.contractId,
      tranId: data.id,
    });
    updateModalState({ ...iModalState, mainContractEditModal: false });
  };

  const handleCloseAddPaymentReminder = () => {
    setAddPaymentReminder(false);
    updateModalState({ ...iModalState, mainContractEditModal: true });
  };
  const handlePaymentReminderSubmit = (e) => {
    e.preventDefault();
    const dataa = { paymentReminder };
    dispatch(addPaymentSchedules(trnsId, tranId, dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getAllSchedulePaymentListAction(trnsId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setPaymentReminder("");
        setAddPaymentReminder(false);
        updateModalState({ ...iModalState, mainContractEditModal: true });
        updateLeadState({ ...iLeadState, heading: "Adjustments And Deposits" });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setAddPaymentReminder(true);
      }
    });
  };

  const handleRemoveRelease = (id) => {
    dispatch(removeReleaseCTCAction(id)).then((res) => {
      dispatch(getTemplateReleaseList());
    });
  };

  const handleLeadUpdateShow=()=>{

  }
  const [UpdateLeadsModal,SetUpdateLeadsModal]=useState(false)
  const [LeadDatas,SetLeadDatas]=useState([])
  const handUpdateLeadsModalShow = (item) => {
 SetUpdateLeadsModal(true)
 SetLeadDatas(item)
  }

  const handleUpdateLeadsModalClose = () => {
    SetUpdateLeadsModal(false)
    SetLeadDatas([])
  }

  const [ViewTemplateData,SetViewTemplateData]=useState([])
  const [ViewTemplateModal,SetViewTemplateModal]=useState(false)
  const handleViewTemplateDataShow=(data)=>{
    SetViewTemplateData(data)
    SetViewTemplateModal(true)
    updateModalState({
      ...iModalState,
      FollowUpActivityModal: false,
    });
  }

  const handleViewTemplateDataClose=()=>{
    
    SetViewTemplateModal(false)
    updateModalState({
      ...iModalState,
      FollowUpActivityModal:true,
    });
  }
  return (
    <>
      <Header sendDataToParent={handleChildData} />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TodosArea">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <div className="TitleBox bg-none border-0 p-0">
                  <h4 className="Title">Lead &amp; Contract Managment</h4>
                </div>
                <div className="CommonTabs mt-3">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="LeadsTabId"
                        data-toggle="tab"
                        href="#Leads"
                      >
                        <span>
                          <img src="images/leads.png" />
                        </span>
                        Leads
                      </a>
                      {/* <span className="Count"></span> */}
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="ContractsTabId"
                        data-toggle="tab"
                        href="#Contracts"
                      >
                        <span>
                          <img src="images/contract_red.png" />
                        </span>
                        Contracts
                      </a>
                      {/* <span className="Count"></span> */}
                    </li>
                    {/* <li className="nav-item">
                      <a
                        className="nav-link"
                        onClick={showClientDocumentatioModal}
                      >
                        <span>
                          <img src="images/icon-63.png" />
                        </span>
                        Sales Client Documentation
                      </a>
                      <span className="Count">3</span>
                    </li> */}
                    {/* <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/transaction-manager"
                        // onClick={showClientDocumentatioModal}
                      >
                        <span>
                          <img src="images/icon-63.png" />
                        </span>
                        Transaction Manager
                      </Link>
                      <span className="Count">3</span>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="Leads">
              <div
                className="TodosButtons mt-0"
                style={{ position: "absolute", top: "70px", right: "50px" }}
              >
                <a onClick={() => showAddLeadModal()}>
                  <img src="images/create_new_lead.png" />
                  Create New Lead
                </a>
              </div>
              <div className="TableHeadingArea mt-5">
                <div className="FilesHead">
                  <div className="FilesLeft">
                    <h4>Leads</h4>
                  </div>
                  <div className="NewActions">
                    <a onClick={handleFilterModalShow} title="filter">
                      <img src="images/icon-64.png" />
                    </a>
                    <a href="Javascript:void(0);" title="pdf">
                      <img src="images/icon-53.png" />
                    </a>
                    <DownloadTableExcel
                      filename="Leads List"
                      sheet="Vendors"
                      currentTableRef={leadListRef.current}
                    >
                      <button href="jacascript:void(0);">
                        <img src="images/download.png" />
                      </button>
                    </DownloadTableExcel>
                    {/* <a href="Javascript:void(0);" title="download">
                      <img src="images/download.png" />
                    </a> */}
                    {/* <a href="Javascript:void(0);" title="settings">
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="TableList TableListDecoration CommonHeading TableMinimumHeight">
                <table ref={leadListRef}>
                  <thead>
                    {includedConsultantt?.length > 0 ? (
                      <>
                        <th />
                        {includedConsultantt.map((data, index) => (
                          <th key={index}>{data.label}</th>
                        ))}
                        <th>Assigned to</th>
                        <th>Discussion</th>
                        <th>Action</th>
                      </>
                    ) : (
                      <tr>
                        <th />
                        <th>Client Contact</th>
                        <th>Date Created</th>
                        <th>Subdivision</th>
                        <th>Status</th>
                        {/* <th>Reason</th> */}
                        <th>Latest Follow up Activity</th>
                        <th>Age</th>
                        <th>Confidence level</th>
                        <th>Assigned to</th>
                        <th>Discussion</th>
                        <th>Action</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {loader ? (
                      <>
                        <td>
                          {" "}
                          <Loader />
                        </td>
                      </>
                    ) : (
                      <>
                        {checkArray(allLeadsListData?.leads) ? (
                          allLeadsListData?.leads?.map((ele, ind) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    {ele?.leadStatus === "New" ||
                                    ele?.createdAt == ele?.updatedAt ? (
                                      <div className="NewButton">
                                        <button
                                          type="button"
                                          className="NewBtn"
                                        >
                                          New
                                        </button>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                  {includedConsultantt.length > 0 &&
                                  includedConsultantt.some(
                                    (module) => module.value === "legalName"
                                  ) ? (
                                    <td>
                                      <div
                                        className="SalesPersonHoverName TextBlue"
                                        title="Client Details"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setContactDetailsData(ele);
                                          setContactDetailsModal(true);
                                        }}
                                      >
                                        {ele?.legalName}
                                      </div>
                                    </td>
                                  ) : (
                                    includedConsultantt.length == 0 && (
                                      <td>
                                        <div
                                          className="SalesPersonHoverName TextBlue"
                                          title="Client Details"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setContactDetailsData(ele);
                                            setContactDetailsModal(true);
                                          }}
                                        >
                                          {ele?.legalName}
                                        </div>
                                      </td>
                                    )
                                  )}

                                  {includedConsultantt.length > 0 &&
                                  includedConsultantt.some(
                                    (module) => module.value === "createdAt"
                                  ) ? (
                                    <td>{ele?.createdAt?.split("T")[0]}</td>
                                  ) : (
                                    includedConsultantt.length == 0 && (
                                      <td>{ele?.createdAt?.split("T")[0]}</td>
                                    )
                                  )}

                                  {includedConsultantt.length > 0 &&
                                  includedConsultantt.some(
                                    (module) => module.value === "subDivision"
                                  ) ? (
                                    <td>{ele?.subDivision}</td>
                                  ) : (
                                    includedConsultantt.length == 0 && (
                                      <td>{ele?.subDivision}</td>
                                    )
                                  )}

                                  {includedConsultantt.length > 0 &&
                                  includedConsultantt.some(
                                    (module) => module.value === "leadStatus"
                                  ) ? (
                                    <td>{ele?.leadStatus}</td>
                                  ) : (
                                    includedConsultantt.length == 0 && (
                                      <td>{ele?.leadStatus}</td>
                                    )
                                  )}

                                  {/* {includedConsultantt.length > 0 &&
                                  includedConsultantt.some(
                                    (module) => module.value === "Budget"
                                  ) ? (
                                    <td>Budget PO</td>
                                  ) : (
                                    includedConsultantt.length == 0 && (
                                      <td>Budget PO</td>
                                    )
                                  )} */}

                                  {includedConsultantt.length > 0 &&
                                  includedConsultantt.some(
                                    (module) => module.value === "No"
                                  ) ? (
                                    <td>{ele?.FollowUps[0]?.scheduleStatus}</td>
                                  ) : (
                                    includedConsultantt.length == 0 && (
                                      <td>
                                        {ele?.FollowUps[0]?.scheduleStatus}
                                      </td>
                                    )
                                  )}
                                  {includedConsultantt.length > 0 &&
                                  includedConsultantt.some(
                                    (module) => module.value === "day"
                                  ) ? (
                                    <td>
                                      ;{" "}
                                      {(
                                        (new Date() -
                                          new Date(ele?.createdAt)) /
                                        (1000 * 60 * 60 * 24)
                                      ).toFixed()}{" "}
                                      days
                                    </td>
                                  ) : (
                                    includedConsultantt.length == 0 && (
                                      <td>
                                        <div>
                                          &lt;{" "}
                                          {(
                                            (new Date() -
                                              new Date(ele?.createdAt)) /
                                            (1000 * 60 * 60 * 24)
                                          ).toFixed()}{" "}
                                          days
                                        </div>
                                      </td>
                                    )
                                  )}
                                  {includedConsultantt.length > 0 &&
                                  includedConsultantt.some(
                                    (module) =>
                                      module.value === "confidenceLevel"
                                  ) ? (
                                    <td>{ele?.confidenceLevel}</td>
                                  ) : (
                                    includedConsultantt.length == 0 && (
                                      <td>{ele?.confidenceLevel}</td>
                                    )
                                  )}

                                  <td>
                                    <div className="Action text-center">
                                      <a
                                        href="javascript:void(0);"
                                        title="assign sales representative"
                                        onClick={() =>
                                          showAssignSaleModal(true, ele?.id)
                                        }
                                      >
                                        <img src="images/assign_icon.png" />
                                      </a>

                                      {ele?.Users?.[0]?.name ? (
                                        <a
                                          // href="javascript:void(0);"
                                          onClick={() =>
                                            showAssignDetailsModal(
                                              ele?.Users[0]?.name
                                            )
                                          }
                                          title={ele?.Users?.[0]?.name ?? "N/A"}
                                          className="SalesPersonHover CursorPointer"
                                        >
                                          <img src="images/icon-32.png" />
                                        </a>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="text-center">
                                      <a
                                        onClick={() =>
                                          showDiscussionModal(ele?.id)
                                        }
                                        href="javascript:void(0);"
                                      >
                                        <img src="images/resend_all_documents.png" />
                                      </a>
                                      <span className="Count">
                                        {ele?.followUpCount}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu ChangeOrderDropdown2">
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showLeadDetailsModal(ele?.id)
                                                }
                                              >
                                                <img src="images/icon-15.png" />
                                                Edit Lead Details
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  handUpdateLeadsModalShow(
                                                    ele
                                                  )
                                                }
                                              >
                                                <img src="images/edit_contact.png" />
                                                Edit Leads Information
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showScheduleFollowActivityModal(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/calendar.png" />
                                                Schedule New Follow up Activity
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  convertLeadToContractFunc(
                                                    ele?.id
                                                  )
                                                }
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/icon-26.png" />
                                                Convert lead to Contract
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showEditSurveyModal(
                                                    ele?.Survey?.LeadId,
                                                    ele?.Survey?.id
                                                  )
                                                }
                                              >
                                                <img src="images/survey.png" />
                                                View/Edit Survey
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setContactDetailsData(ele);
                                                  setContactDetailsModal(true);
                                                }}
                                              >
                                                <img src="images/send-text.png" />
                                                Sent Text
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setContactDetailsData(ele);
                                                  setContactDetailsModal(true);
                                                }}
                                              >
                                                <img src="images/send-email.png" />
                                                Send Email
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <td>
                            {" "}
                            <NoDataFound />
                          </td>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tab-pane fade" id="Contracts">
              <div
                className="TodosButtons mt-0"
                style={{ position: "absolute", top: "70px", right: "50px" }}
              >
                <a href="javascript:void(0)" onClick={showAddContractModal}>
                  <img src="images/create_new_lead.png" />
                  Create New Contract Client
                </a>
              </div>
              <div className="TableHeadingArea mt-5">
                <div className="FilesHead">
                  <div className="FilesLeft">
                    <h4>Contracts</h4>
                  </div>
                  <div className="NewActions">
                    <a href="Javascript:void(0);" title="filter">
                      <img src="images/icon-64.png" />
                    </a>
                    <a href="Javascript:void(0);" title="pdf">
                      <img src="images/icon-53.png" />
                    </a>
                    <DownloadTableExcel
                      filename="Contracts List"
                      sheet="Vendors"
                      currentTableRef={contractListRef.current}
                    >
                      <button href="jacascript:void(0);">
                        <img src="images/download.png" />
                      </button>
                    </DownloadTableExcel>
                    {/* <a href="Javascript:void(0);" title="settings">
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="TableList CommonHeading TableMinimumHeight">
                <table ref={contractListRef}>
                  <thead>
                    <tr>
                      <th />
                      <th>Client Contact</th>
                      <th>Date Created</th>
                      <th>Sale Type</th>
                      <th>Client Assigned Unit</th>
                      <th>Client Purchase Price</th>
                      <th>Status</th>
                      <th>Deposit Paid</th>
                      <th>Contract Signed</th>
                      <th>Down Payment Paid</th>
                      <th>Financing Confirmed</th>
                      <th>Waiver of Conditions Signed</th>
                      <th>View SOA</th>
                      <th>Next Scheduled Transaction</th>
                      <th>Assigned to</th>
                      <th>Documents</th>
                      <th>Discussion</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <>
                        <td>
                          {" "}
                          <Loader />
                        </td>
                      </>
                    ) : (
                      <>
                        {checkArray(contractListData?.contracts) ? (
                          contractListData?.contracts?.map((ele, ind) => {
                            return (
                              <>
                                <tr>
                                  {ele?.saleType == "new" ? (
                                    <td>
                                      <div className="NewButton">
                                        <button
                                          type="button"
                                          className="NewBtn"
                                        >
                                          New
                                        </button>
                                      </div>
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}

                                  <td>
                                    <div
                                      onClick={() => {
                                        setContactDetailsData(ele?.clients[0]);
                                        setContactDetailsModal(true);
                                      }}
                                      className="TextBlue"
                                    >
                                      {ele?.clients[0]?.legalName}
                                    </div>
                                  </td>
                                  <td>{ele?.createdAt?.split("T")[0]}</td>
                                  <td>
                                    {ele?.jobType === "MultiUnit"
                                      ? "MultifamilyJob"
                                      : ele?.jobType}
                                  </td>
                                  {/* <td> */}
                                  {/* <div className="TextBlue">
                                      {ele?.clientAssiUnit}
                                    </div> */}
                                  {ele?.jobNumber ? (
                                    <>
                                      <td>
                                        <div class="TextBlue">
                                          {`${ele?.jobNumber?.split(" ")[0]} (${
                                            ele?.jobType === "MultiUnit"
                                              ? "MultifamilyJob"
                                              : ele?.jobType
                                          })`}
                                          <a
                                            onClick={() =>
                                              handleHandleAssignUnit(ele?.id)
                                            }
                                          >
                                            <img src="images/edit.png" />
                                          </a>
                                        </div>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td>
                                        <div
                                          class="CursorPointer"
                                          onClick={() =>
                                            showClientAssignUnitMOdal(
                                              "clientAssignUnit",
                                              ele?.id
                                            )
                                          }
                                        >
                                          <img src="images/client_assigned_unit.png" />
                                        </div>
                                      </td>
                                    </>
                                  )}

                                  {/* </td> */}

                                  <td> {ele?.clientPurchasePrice}</td>
                                  <td> {ele?.clients[0]?.status}</td>
                                  <td>
                                    <div className="text-center">
                                      <label className="CheckBox">
                                        <input
                                          type="checkbox"
                                          checked={
                                            ele?.SatisCondition?.depositPaid
                                          }
                                          onChange={(e) =>
                                            handleCHeckBoxForContractList(
                                              e,
                                              ele?.id,
                                              "depositPaid"
                                            )
                                          }
                                          disabled={
                                            ele?.SatisCondition
                                              ?.depositPaidStatus
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-center">
                                      <label className="CheckBox">
                                        <input
                                          type="checkbox"
                                          checked={
                                            ele?.SatisCondition?.contractSigned
                                          }
                                          onChange={(e) =>
                                            handleCHeckBoxForContractList(
                                              e,
                                              ele?.id,
                                              "contractSigned"
                                            )
                                          }
                                          disabled={
                                            ele?.SatisCondition
                                              ?.contractSignedStatus
                                          }
                                        />

                                        {/* <input type="checkbox" 
                                         checked={ele?.SatisCondition?.contractSigned}
                                         onChange={(e) =>
                                           handleCHeckBoxForContractList(e, ele?.id,"contractSigned")
                                         }  /> */}
                                        <span className="checkmark" />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-center">
                                      <label className="CheckBox">
                                        <input
                                          type="checkbox"
                                          checked={
                                            ele?.SatisCondition?.downPayPaid
                                          }
                                          onChange={(e) =>
                                            handleCHeckBoxForContractList(
                                              e,
                                              ele?.id,
                                              "downPayPaid"
                                            )
                                          }
                                          disabled={
                                            ele?.SatisCondition
                                              ?.downPayPaidStatus
                                          }
                                        />

                                        <span className="checkmark" />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-center">
                                      <label className="CheckBox">
                                        <input
                                          type="checkbox"
                                          checked={
                                            ele?.SatisCondition?.finanConfirmed
                                          }
                                          onChange={(e) =>
                                            handleCHeckBoxForContractList(
                                              e,
                                              ele?.id,
                                              "finanConfirmed"
                                            )
                                          }
                                          disabled={
                                            ele?.SatisCondition
                                              ?.finanConfirmedStatus
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-center">
                                      <label className="CheckBox">
                                        <input
                                          type="checkbox"
                                          checked={
                                            ele?.SatisCondition
                                              ?.waiverOfConSigned
                                          }
                                          onChange={(e) =>
                                            handleCHeckBoxForContractList(
                                              e,
                                              ele?.id,
                                              "waiverOfConSigned"
                                            )
                                          }
                                          disabled={
                                            ele?.SatisCondition
                                              ?.waiverOfConSignedStatus
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </div>
                                  </td>
                                  {ele?.jobId ? (
                                    <td>
                                      <div className="Actions">
                                        <a
                                          href="javascript:void(0);"
                                          title="Statement Of Adjustment"
                                          onClick={() =>
                                            showSOAModal(
                                              "contractList",
                                              ele?.id
                                            )
                                          }
                                        >
                                          <img src="images/visa_icon.png" />
                                        </a>
                                      </div>
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}

                                  <td>Friday, June 16, 2023</td>
                                  <td>
                                    <div className="Action text-center">
                                      <a
                                        href="javascript:void(0);"
                                        title="assign sales representative"
                                        onClick={() =>
                                          showAssignSaleModal(
                                            "contractList",
                                            ele?.id
                                          )
                                        }
                                      >
                                        <img src="images/assign_icon.png" />
                                      </a>
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() =>
                                          showAssignDetailsModal(
                                            ele?.Users[0]?.name
                                          )
                                        }
                                        title="staff member profile"
                                        className="SalesPersonHover CursorPointer"
                                      >
                                        <img src="images/icon-32.png" />
                                      </a>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="Actions">
                                      <a
                                        href="javascript:void(0);"
                                        title="Upload Document"
                                        onClick={() =>
                                          showSupportingDocsModal(
                                            "contractList",
                                            ele?.id
                                          )
                                        }
                                      >
                                        <img src="images/icon-63.png" />
                                      </a>
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          showContractSupportingListModal(
                                            "contractList",
                                            ele?.id
                                          )
                                        }
                                      >
                                        <span className="Count">
                                          {ele?.supportDocCount}
                                        </span>
                                      </a>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-center">
                                      <a href="javascript:void(0);">
                                        <img
                                          src="images/resend_all_documents.png"
                                          onClick={() =>
                                            showMainContractModal(
                                              "contractList",
                                              ele?.id,
                                              "Conditional Details"
                                            )
                                          }
                                        />
                                      </a>
                                      <span
                                        className="Count"
                                        onClick={() =>
                                          showDiscussionModal(ele?.id)
                                        }
                                      >
                                        {ele?.followUpCount}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu ChangeOrderDropdown2">
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showMainContractModal(
                                                    "contractList",
                                                    ele?.id,
                                                    "General Information"
                                                  )
                                                }
                                              >
                                                <img src="images/Action-1.png" />
                                                Edit General Information
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showMainContractModal(
                                                    "contractList",
                                                    ele?.id,
                                                    "Conditional Details"
                                                  )
                                                }
                                              >
                                                <img src="images/Action-1.png" />
                                                Edit Conditional Details
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showMainContractModal(
                                                    "contractList",
                                                    ele?.id,
                                                    "Adjustments And Deposits"
                                                  )
                                                }
                                              >
                                                <img src="images/Action-1.png" />
                                                Edit Adjustments and Deposit
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showEditContactInformationModal(
                                                    "contractList",
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/contact-list.png" />
                                                Edit Contact Information
                                              </a>
                                            </li>

                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showClientAssignUnitMOdal(
                                                    "clientAssignUnit",
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/client_assigned_unit.png" />
                                                Assign Unit
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                onClick={() =>
                                                  showUpdateSatisfiedConditionPaymentModal(
                                                    "contractList"
                                                  )
                                                }
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/update_satisfied.png" />
                                                Update Satisfied Conditions
                                              </a>
                                            </li> */}
                                            {/* <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/view_update.png" />
                                                View/Update Job Details
                                              </a>
                                            </li> */}
                                            {/* <li>
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() =>
                                      showContractTransactionsModal(
                                        "contractList", 
                                        ele?.id
                                      )
                                    }
                                  >
                                    <img src="images/add_transactions.png" />
                                    Add Transactions
                                  </a>
                                </li> */}
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showScheduledPaymentModal(
                                                    "contractList",
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/schedule_payment.png" />
                                                Schedule Payments
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showClientDocumentatioModal(
                                                    ele?.id,
                                                    "contractList",
                                                    ele?.LeadId
                                                  )
                                                }
                                              >
                                                <img src="images/icon-63.png" />
                                                Sales Documents
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/configure_client_view.png" />
                                                Configure Client View
                                              </a>
                                            </li> */}
                                            <li>
                                              <a onClick={showSOAModal}>
                                                <img src="images/visa_icon.png" />
                                                View SOA
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleGrossMarginModalShow(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/view-po.png" />
                                                View Gross Margin
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <td>
                            {" "}
                            <NoDataFound />
                          </td>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ADDLEADMODALSHOW */}
      <Modal
        show={AddLeadModal}
        onHide={hideAddLeadModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal ClosePermissionId"
                onClick={hideAddLeadModal}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Create New Lead</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <div className="CommonModalArea">
                            <h5>Contact Information</h5>
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                title="import contact information"
                                onClick={showcontactInformationModal}
                              >
                                <figure>
                                  <img src="images/upload_icon.png" />
                                </figure>{" "}
                                Import contact information
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-12">
                        <div className="form-group">
                          <div className="ModalBigheading">
                            <h3>
                              <span>Personal Information</span>
                            </h3>
                          </div>
                        </div>
                        <div className="form-group">
                          <h6>
                            Client Full Legal name
                            <span className="required">*</span>
                          </h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input client's full legal name"
                            name="legalName"
                            value={legalName}
                            onChange={handleInput}
                          />
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {leadErrors?.legalNameError}
                          </span>
                        </div>
                        <div className="form-group">
                          <h6>Country Code</h6>
                          <PhoneInput
                            inputClass="CountryCodeInput"
                            countryCodeEditable={false}
                            enableSearch
                            value={phonecode}
                            onChange={handlePhoneInputChange}
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <h6>
                            Primary Phone Number
                            <span className="required">*</span>
                          </h6>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input client's full legal name"
                            name="primaryPhoneNum"
                            value={primaryPhoneNum}
                            onChange={handleInput}
                          />
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {leadErrors?.primaryPhoneNumError}
                          </span>
                        </div>
                        <div className="form-group">
                          <h6>
                            Email<span className="required">*</span>
                          </h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input email "
                            name="email"
                            value={email}
                            onChange={handleInput}
                          />
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {leadErrors?.emailError}
                          </span>
                        </div>
                        <div className="form-group">
                          <h6> Bssiness Country Code</h6>
                          <PhoneInput
                            inputClass="CountryCodeInput "
                            countryCodeEditable={false}
                            enableSearch
                            value={bussinessPhoneCode}
                            onChange={handleBussinessPhoneInputChange}
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <h6>Business Phone Number</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input client's full legal name"
                            name="businessPhoneNum"
                            value={businessPhoneNum}
                            onChange={handleInputNumber}
                          />
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {leadErrors?.businessPhoneNumError}
                          </span>
                        </div>
                        <div className="form-group">
                          <h6>Fax Phone Number</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input fax number"
                            name="faxPhoneNum"
                            value={faxPhoneNum}
                            onChange={handleInputNumber}
                          />
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {leadErrors?.faxPhoneNumError}
                          </span>
                        </div>
                        <div class="form-group">
                          <h6>Preferred Communication</h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="preferredCommu"
                              value={preferredCommu}
                              onChange={handleInput}
                            >
                              <option value="">Select Preference</option>
                              <option value="No Preference">No Preference
                                </option>
                                <option value="Phone">Phone</option>
                                <option value="Email">Email</option>
                            </select>
                          </div>
                        </div>
                       
                      </div>
                      <div className="col-lg-5 col-md-12 ml-auto">
                        <div className="form-group">
                          <div className="ModalBigheading">
                            <h3>
                              <span>Address</span>
                            </h3>
                          </div>
                        </div>
                        <div className="form-group">
                          <h6>Street Address</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input street name"
                            name="streetAddress"
                            value={streetAddress}
                            onChange={handleInput}
                          />
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {leadErrors?.streetAddressError}
                          </span>
                        </div>
                        <div className="form-group">
                          <h6>Address Line two</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input address line two"
                            name="addressLineTwo"
                            value={addressLineTwo}
                            onChange={handleInput}
                          />
                          {/* <span style={{ color: "red", fontSize: "14px" }}>{leadErrors?.legalNameError}</span> */}
                        </div>
                        <div className="form-group">
                          <h6>
                            Zip/Postal Code<span className="required">*</span>
                          </h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input postal name"
                            name="zipCode"
                            value={zipCode}
                            onChange={handleInput}
                          />
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {leadErrors?.zipCodeError}
                          </span>
                        </div>

                        <div class="form-group">
                          <h6>
                            Country<span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="country"
                              value={country}
                              onChange={handleInput}
                            >
                              <option value="">Select Country</option>
                              {countryData?.countries?.length > 0 &&
                                countryData?.countries?.map((item, i) => {
                                  return (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <h6>
                            Province/State<span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="province"
                              value={province}
                              onChange={handleInput}
                            >
                              <option value="">Select Province/State</option>
                              {stateData?.states?.length > 0 ? (
                                stateData?.states?.map((item, i) => {
                                  return (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option disabled>
                                  No Province/State Available
                                </option>
                              )}
                            </select>
                          </div>
                        </div>

                        <div class="form-group">
                          <h6>
                            City<span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="city"
                              value={city}
                              onChange={handleInput}
                            >
                              <option value="">Select City</option>
                              {cityData?.cities?.length > 0 ? (
                                cityData?.cities?.map((item, i) => {
                                  return (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option disabled>No Cities Available</option>
                              )}
                            </select>
                          </div>
                        </div>

                       
                      </div>
                      <div className="col-md-12">
                        <div className="TodosButtons mt-0 text-center">
                          <a href="javascript:void(0)">
                            <img src="images/general_information.png" />
                            General Information
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="GeneralInformation mt-4">
                      <div className="row">
                        {/* <div className="form-group col-lg-4 col-md-12">
                          <div className="CommonModalArea">
                            <h5>Assigned to</h5>
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                title="assign sales representative"
                                onClick={() => showAssignSaleModal(false)}
                              >
                                <figure>
                                  <img src="images/icon-32.png" />
                                </figure>
                                <figure>
                                  <img src="images/refresh.png" />
                                </figure>
                                {UserId
                                  ? staffListData?.staff?.find(
                                      (ele) => ele?.id == UserId
                                    )?.name
                                  : "Select"}
                              </h5>
                            </div>
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {leadErrors?.UserIdError}
                            </span>
                          </div>
                        </div> */}

<div class="form-group col-lg-4 col-md-12">
                          <h6>
                          SubDivision/Projects<span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="subDivision"
                              value={subDivision}
                              onChange={handleInput}
                            >
                              <option value="">Select SubDivision/Projects</option>
                              {subDivisionListData?.details?.length > 0 ? (
                                subDivisionListData?.details?.map((item, i) => {
                                  return (
                                    <option value={item}>
                                      {item}
                                    </option>
                                  );
                                })
                              ) : (
                                <option disabled>No SubDivision/Projetcs Available</option>
                              )}
                            </select>
                          </div>
                        </div>
                       
                        <div className="form-group col-lg-4 col-md-12">
                          <div className="CommonModalArea">
                            <h5>Survey</h5>
                            <div className="CommonModalBox">
                              <figure>
                                <img src="images/survey.png" title="survey" />
                              </figure>
                              {/* <span className="Count">3</span> */}
                              <h5 onClick={showAddSurveyModal} className="">
                                Survey
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-lg-4 col-md-12">
                          <div className="CommonModalArea">
                            <h5>Lead Age</h5>
                            <div className="CommonModalBox">
                              <h5 className="">&lt; 1day</h5>
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <div className="form-group">
                            <h6>
                              Lead Status<span className="required">*</span>
                            </h6>

                              <div class="CommonSelectBox w-100">
                                <select
                                  name="leadStatus"
                                  value={leadStatus}
                                  onChange={handleInput}
                                >
                                  <option>Select Status </option>
                                  <option value="New">New</option>
                                  <option value="InProgress">
                                    In progress
                                  </option>
                                  <option value="Intersted">Interested</option>
                                  <option value="Not Interested">
                                    Not Interested
                                  </option>
                                  <option value="Cancelled">Cancelled</option>
                                </select>

                              </div>
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {leadErrors?.leadStatusError}
                              </span>
                            
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                        <div className="form-group">
                            <h6>
                              Confidence Level
                              {/* <span className="required">*</span> */}
                            </h6>
                          <div className="CommonSelectBox w-100">
                              <select
                                name="confidenceLevel"
                                value={confidenceLevel}
                                onChange={handleInput}
                              >
                                <option>Confidence Level </option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                              </select>
                              </div>
                            
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {leadErrors?.confidenceLevelError}
                            </span>
                            </div>
                            </div>
                      

                        <div className="col-12">
                          <h6 className="mb-4">Realtor/Referrer</h6>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="row">
                            <div className="form-group col-md-10">
                              <h6>Name</h6>
                              <input
                                type="text"
                                className="form-control"
                                name="referrerName"
                                value={referrerName}
                                onChange={handleInput}
                              />
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {leadErrors?.referrerNameError}
                              </span>
                            </div>
                            <div className="form-group col-md-10">
                              <h6>Email</h6>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Input Email"
                                name="referrerEmail"
                                value={referrerEmail}
                                onChange={handleInput}
                              />
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {leadErrors?.referrerEmailError}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="form-group col-md-10 ml-auto">
                            <h6>Agency</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Input Agency"
                              name="referrerAgency"
                              value={referrerAgency}
                              onChange={handleInput}
                            />
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {leadErrors?.referrerAgencyError}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="Button d-flex justify-content-between mt-3">
                        <button onClick={createLeadFunc} className="Create">
                          Create Lead
                        </button>
                        <button className="Outline" onClick={hideAddLeadModal}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* LEADCLIENTDETAILSSHOW */}
      <Modal
        show={leadClientDetailsShow}
        onHide={hideLeadClientDetailsModal}
        className="ModalBox ExtraSmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal closebtn ClosePermissionId"
            onClick={hideLeadClientDetailsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ContactTopBox">
              <div className="SalesPersonarea">
                <span>
                  {leadClientDetailsDataTemp?.legalName?.charAt(0) +
                    leadClientDetailsDataTemp?.legalName?.charAt(1)}
                </span>

                <h6>{leadClientDetailsDataTemp?.legalName}</h6>
              </div>
            </div>
            <div className="FolderForm">
              <div className="SendEmailText">
                <p>
                  <img src="images/send-email.png" />
                  Send E-mail
                </p>
                <p className="SendText SendText1">
                  <img src="images/send-text.png" />
                  Send Text
                </p>
                <div className="SendTextSearch SendTextSearch1">
                  <input type="text" />
                  <button>Apply</button>
                </div>
              </div>
              <div className="MolalContactForm mb-4">
                <h6>Contact</h6>
                <p>
                  <img src="images/send-email.png" />
                  {leadClientDetailsDataTemp?.email}
                </p>
                <p className="mb-4">
                  <img src="images/send-text.png" />
                  {leadClientDetailsDataTemp?.primaryPhoneNum}
                </p>
                {leadDetailsShowMore == false ? (
                  <>
                    <a
                      onClick={() =>
                        createRoomfun(leadClientDetailsDataTemp?.id)
                      }
                      href="javascript:void(0);"
                      className="ShowMoreBtn"
                    >
                      Show more
                    </a>
                  </>
                ) : (
                  <a
                    onClick={() => SetLeadDetailsShowMore(false)}
                    href="javascript:void(0);"
                    className="ShowMoreBtn"
                  >
                    Show less
                  </a>
                )}
              </div>
              <div className="form-group mt-3">
                <div className="ContactModalAddressArea">
                  <h6>Address</h6>
                  <p>
                    {leadClientDetailsDataTemp?.city} <br />
                    {leadClientDetailsDataTemp?.country}
                    {/* <img src="images/location.png" /> */}
                  </p>
                </div>
              </div>
              {leadDetailsShowMore == true ? (
                <div className="">
                  <div className="form-group mb-0">
                    <h6>Discussion</h6>
                    <input
                      type="text"
                      placeholder="Write here ....."
                      className="form-control"
                      name="message"
                      value={message}
                      onChange={handleInput}
                      onKeyDown={sendMessageFunc}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {checkArray(messageListData?.data?.data) &&
                messageListData?.data?.data?.map((ele, i) => {
                  return (
                    <>
                      <p>{ele?.message}</p>
                    </>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CONTACTINFORMATIONMODALSHOW */}
      <Modal
        show={contactInformationModal}
        onHide={hideContactInformationModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            onClick={hideContactInformationModal}
            href="javascript:void(0);"
            className="CloseModal"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Import Contact Information</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Select File (Ibuild or xlsx)</h6>
                  <div className="Upload">
                    <span>Drag and Drop Files </span>
                    <input type="file" name="file" onChange={handleInputFile} />
                  </div>
                </div>
                <div className="form-group">
                  <div className="FilesAdded">
                    <label>{fileNameStatic}</label>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between ">
                  <button
                    className="Create"
                    onClick={importContactInformationFun}
                  >
                    Import Contact
                  </button>
                  <button
                    className="Outline"
                    onClick={hideContactInformationModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ASSIGNSALEMODALSHOW */}
      <Modal
        show={assignSaleModal}
        onHide={hideAssignSaleModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAssignSaleModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Assign Sales Representative</h4>
            </div>
            <div className="ModalTitleHeading">
              <h2>Staff Member Information</h2>
            </div>
            <div className="FolderForm">
              <div className="form-group col-md-12">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Name<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    {/* <Multiselect
                      options={staffList.options}
                      // selectedValues={assignStaffId
                      //   ?.map((item) => {
                      //     return item?.flat();
                      //   })
                      //   ?.flat()}
                      displayValue="email"
                      onSelect={onSelectStream}
                      // onRemove={onRemoveStream}
                      // disable={!stream}
                      // ref={dropDownRefsubStream}
                      // resetSelectedValues={handleRefresh}
                    /> */}
                    <select
                      name="UserId"
                      value={UserId}
                      onChange={handleInput}
                      // disabled={modalState === true}
                    >
                      <option>Select Staff </option>
                      {checkArray(staffListData?.staff) ? (
                        <>
                          {staffListData?.staff?.map((ele, i) => {
                            return <option value={ele?.id}>{ele?.name}</option>;
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {leadErrors?.UserIdError}
                  </span>
                </div>
              </div>
              <div className="Button text-center mt-3">
                <button onClick={handleAssignStaffs} className="Create">
                  Assign
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ASSIGN DETAILS MODAL  */}

      <Modal
        show={assignsaleDetailsModal}
        onHide={hideAssignDetailsModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAssignDetailsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Assign Sales Representative Details</h4>
            </div>
            <div className="ModalTitleHeading">
              <h2>Staff Member Information</h2>
            </div>
            <div className="FolderForm">
              <div className="form-group col-md-12">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Name<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    {/* <Multiselect
                      options={staffList.options}
                      // selectedValues={assignStaffId
                      //   ?.map((item) => {
                      //     return item?.flat();
                      //   })
                      //   ?.flat()}
                      displayValue="email"
                      onSelect={onSelectStream}
                      // onRemove={onRemoveStream}
                      // disable={!stream}
                      // ref={dropDownRefsubStream}
                      // resetSelectedValues={handleRefresh}
                    /> */}
                    <select disabled>
                      <option>{modalState} </option>
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {leadErrors?.UserIdError}
                  </span>
                </div>
              </div>
              <div className="Button text-center mt-3">
                <button onClick={hideAssignDetailsModal} className="Create">
                  Assign
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADDCONTRACTMODALSHOW */}
      <Modal
        show={AddContractModal}
        onHide={hideAddContractModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddContractModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox GrayModalTitle">
              <h4>Create New Contract</h4>
            </div>
            <div className="FolderForm">
              <div className="ResidentialJobArea ResidentialJobAreaPara">
                <div className="ModalAccordian">
                  <div id="accordion">
                    <div className="card">
                      <div
                        id="GenerationInformation"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <div className="CommonModalArea">
                                  <h5>Contact Information</h5>
                                  <div className="CommonModalBox">
                                    <h5
                                      className="CursorPointer FolderPermissionId"
                                      title="import contact information"
                                      onClick={() =>
                                        showcontactInformationModal(
                                          "addContract"
                                        )
                                      }
                                    >
                                      <figure>
                                        <img src="images/upload_icon.png" />
                                      </figure>{" "}
                                      Import contact information
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-5 col-md-12">
                              <div className="form-group">
                                <div className="ModalBigheading">
                                  <h3>
                                    <span>Personal Information</span>
                                  </h3>
                                </div>
                              </div>
                              <div className="form-group">
                                <h6>
                                  Client Full Legal name
                                  <span className="required">*</span>
                                </h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input client's full legal name"
                                  name="legalName"
                                  value={legalName}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.legalNameError}
                                </span>
                              </div>
                              <div className="form-group">
                                <h6>Country Code</h6>
                                <PhoneInput
                                  inputClass="CountryCodeInput "
                                  countryCodeEditable={false}
                                  enableSearch
                                  value={phonecode}
                                  onChange={handlePhoneInputChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <h6>
                                  Primary Phone Number
                                  <span className="required">*</span>
                                </h6>

                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input client's full legal name"
                                  name="primaryPhoneNum"
                                  value={primaryPhoneNum}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.primaryPhoneNumError}
                                </span>
                              </div>
                              <div className="form-group">
                                <h6>
                                  Email<span className="required">*</span>
                                </h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input email "
                                  name="email"
                                  value={email}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.emailError}
                                </span>
                              </div>
                              <div className="form-group">
                                <h6> Bssiness Country Code</h6>
                                <PhoneInput
                                  inputClass="CountryCodeInput "
                                  countryCodeEditable={false}
                                  enableSearch
                                  value={bussinessPhoneCode}
                                  onChange={handleBussinessPhoneInputChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <h6>Business Phone Number</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input client's full legal name"
                                  name="businessPhoneNum"
                                  value={businessPhoneNum}
                                  onChange={handleInputNumber}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.businessPhoneNumError}
                                </span>
                              </div>
                              <div className="form-group">
                                <h6>Fax Phone Number</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input fax number"
                                  name="faxPhoneNum"
                                  value={faxPhoneNum}
                                  onChange={handleInputNumber}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.faxPhoneNumError}
                                </span>
                              </div>
                              <div class="form-group">
                          <h6>Preferred Communication</h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="preferredCommu"
                              value={preferredCommu}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Preference</option>
                              <option value="No Preference">No Preference
                                </option>
                                <option value="Phone">Phone</option>
                                <option value="Email">Email</option>
                            </select>
                          </div>
                        </div>
                            </div>

                            <div className="col-lg-5 col-md-12 ml-auto">
                              <div className="form-group">
                                <div className="ModalBigheading">
                                  <h3>
                                    <span>Address</span>
                                  </h3>
                                </div>
                              </div>
                              <div className="form-group">
                                <h6>Street Address</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input street name"
                                  name="streetAddress"
                                  value={streetAddress}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.streetAddressError}
                                </span>
                              </div>
                              <div className="form-group">
                                <h6>Address Line two</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input address line two"
                                  name="addressLineTwo"
                                  value={addressLineTwo}
                                  onChange={handleInput}
                                />
                                {/* <span style={{ color: "red", fontSize: "14px" }}>{leadErrors?.legalNameError}</span> */}
                              </div>
                              <div className="form-group">
                                <h6>
                                  Zip/Postal Code
                                  <span className="required">*</span>
                                </h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input postal name"
                                  name="zipCode"
                                  value={zipCode}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.zipCodeError}
                                </span>
                              </div>
                             
                        <div class="form-group">
                          <h6>
                            Country<span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="country"
                              value={country}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Country</option>
                              {countryData?.countries?.length > 0 &&
                                countryData?.countries?.map((item, i) => {
                                  return (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <h6>
                            Province/State<span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="province"
                              value={province}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Province/State</option>
                              {stateData?.states?.length > 0 ? (
                                stateData?.states?.map((item, i) => {
                                  return (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option disabled>
                                  No Province/State Available
                                </option>
                              )}
                            </select>
                          </div>
                        </div>

                        <div class="form-group">
                          <h6>
                            City<span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="city"
                              value={city}
                              onChange={handleInputChange}
                            >
                              <option value="">Select City</option>
                              {cityData?.cities?.length > 0 ? (
                                cityData?.cities?.map((item, i) => {
                                  return (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option disabled>No Cities Available</option>
                              )}
                            </select>
                          </div>
                        </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#GenerationInformation"
                      >
                        <h4>
                          <span>
                            <img src="images/generation_new.png" />
                          </span>
                          Generation Information
                        </h4>
                      </div>
                      <div
                        id="GenerationInformation"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                    
                          <div className="card-body">
                            <div className="row">
                              {/* <div className="form-group col-lg-4 col-md-12">
                                <div className="CommonModalArea">
                                  <h5>Assigned to</h5>
                                  <div className="CommonModalBox">
                                    <h5
                                      className="CursorPointer FolderPermissionId"
                                      title="assign sales representative"
                                      onClick={() =>
                                        showAssignSaleModal("contract")
                                      }
                                    >
                                      <figure>
                                        <img src="images/icon-32.png" />
                                      </figure>
                                      <figure>
                                        <img src="images/refresh.png" />
                                      </figure>
                                      {UserId
                                        ? staffListData?.staff?.find(
                                            (ele) => ele?.id == UserId
                                          )?.name
                                        : "Select"}
                                    </h5>
                                  </div>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {leadErrors?.UserIdError}
                                  </span>
                                </div>
                              </div> */}
                             <div class="form-group col-lg-4 col-md-12">
                          <h6>
                          SubDivision/Projects<span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="subDivision"
                              value={subDivision}
                              onChange={handleInputChange}
                            >
                              <option value="">Select SubDivision/Projects</option>
                              {subDivisionListData?.details?.length > 0 ? (
                                subDivisionListData?.details?.map((item, i) => {
                                  return (
                                    <option value={item}>
                                      {item}
                                    </option>
                                  );
                                })
                              ) : (
                                <option disabled>No SubDivision/Projetcs Available</option>
                              )}
                            </select>
                          </div>
                        </div>
                            </div>

                            <div className="row">
                              <div className="col-12">
                                <h6 className="mb-4">Realtor/Referrer</h6>
                              </div>
                              <div className="form-group col-lg-4 col-md-12">
                                <h6>Name</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input Name"
                                  name="referrerName"
                                  value={referrerName}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.referrerNameError}
                                </span>
                              </div>
                              <div className="form-group col-lg-4 col-md-12">
                                <h6>Agency</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input Agency"
                                  name="referrerAgency"
                                  value={referrerAgency}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.referrerAgencyError}
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-lg-4 col-md-12 mr-auto">
                                <h6>Email</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input Email"
                                  name="referrerEmail"
                                  value={referrerEmail}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.referrerEmailError}
                                </span>
                              </div>
                            </div>
                          </div>
                      
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#businessAddress"
                      >
                        <h4>
                          <span>
                            <img src="images/conditonal_details.png" />
                          </span>
                          Conditional Details
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ResidentialEdit"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="businessAddress"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-4 col-md-12">
                              <div className="form-group">
                                <h6>Sale Type</h6>
                                <div className="CommonSelectBox w-100">
                                    <select
                                      name="saleType"
                                      value={saleType}
                                      onChange={handleInput}
                                    >
                                      <option>Select Sale Type</option>
                                      {checkArray(
                                        salesTypeListData?.saleTypes
                                      ) ? (
                                        <>
                                          {salesTypeListData?.saleTypes?.map(
                                            (ele, i) => {
                                              return (
                                                <option
                                                  value={ele[0]}
                                                  selected={ele[0] === saleType}
                                                >
                                                  {ele[1]}
                                                </option>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </select>
                                   
                                 
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                              <div className="form-group">
                                <h6>Client Assigned Unit</h6>
                                <div className="CommonSelectBox w-100">
                                    <select
                                      name="jobId"
                                      value={jobId}
                                      onChange={handleInput}
                                    >
                                      <option>Select Assigned Unit</option>
                                      {saleType === "Lot" ? (
                                        <>
                                          {checkArray(
                                            clientAssignedListData?.lotUnit
                                          ) ? (
                                            <>
                                              {clientAssignedListData?.lotUnit?.map(
                                                (ele, i) => {
                                                  // le('elelelele',ele[0])
                                                  return (
                                                    <option value={ele?.id}>
                                                      {ele?.jobNumber}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {saleType === "ResidentialJob" ? (
                                            checkArray(
                                              clientAssignedListData?.residentialUnit
                                            ) ? (
                                              <>
                                                {clientAssignedListData?.residentialUnit?.map(
                                                  (ele, i) => {
                                                    return (
                                                      <option value={ele?.id}>
                                                        {ele?.jobNumber}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )
                                          ) : (
                                            <>
                                              {checkArray(
                                                clientAssignedListData?.multiUnit
                                              ) ? (
                                                <>
                                                  {clientAssignedListData?.multiUnit?.map(
                                                    (ele, i) => {
                                                      if (ele?.jobNumber)
                                                        return (
                                                          <option
                                                            value={ele?.id}
                                                          >
                                                            {ele?.jobNumber}
                                                          </option>
                                                        );
                                                    }
                                                  )}
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </select>
                                  
                                
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="Button d-flex justify-content-between">
                      <button className="Create" onClick={addContractFunc}>
                        Create Contract
                      </button>
                      <button
                        className="Outline"
                        onClick={hideAddContractModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CLIENTDOCUMENTATIONMODALSHOW */}
      <Modal
        show={ClientDocumentationModal}
        onHide={hideClientDocumentationModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideClientDocumentationModal}
            title="Close"
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Client Documentation</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-lg-4 col-md-12">
                    <div className="DashboardLeft">
                      <div className="ShowList">
                        <div className="ContactInformationArea">
                          <h6>Contact Information</h6>
                          <div className="ContactInformationBox border-0">
                            <span className="circle">JL</span>
                            <div className="ContactInformationName">
                              <h6>{contactDetails?.Clients?.[0]?.legalName}</h6>
                              <p>
                                {contactDetails?.Clients?.[0]?.streetAddress ??
                                  ""}{" "}
                                <br />{" "}
                                {contactDetails?.Clients?.[0]?.province ?? ""}
                                <img
                                  src="images/location.png"
                                  className="CursorPointer FolderPermissionId ml-2"
                                  onClick={showLocationModal}
                                />
                              </p>
                              <p>
                                Phone:{" "}
                                <span>
                                  {contactDetails?.Clients?.[0]
                                    ?.primaryPhoneNum ?? ""}
                                </span>
                              </p>
                              <p>
                                Email:{" "}
                                <span>
                                  {contactDetails?.Clients?.[0]?.email ?? ""}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="AssignUnit">
                            <h4>Assigned Unit</h4>
                            <p>{contactDetails?.jobNumber}</p>
                          </div>
                        </div>
                        <aside>
                          <h5>Folder</h5>
                          <div>
                            <a
                              href="javascript:void(0);"
                              data-toggle="tooltip"
                              title="Add Folder"
                              onClick={() => showAddFolderModal()}
                            >
                              <img src="images/Plus.png" />
                            </a>
                          </div>
                        </aside>
                        <div className="Search">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) =>
                              dispatch(
                                searchFolderListAction(
                                  modalId,
                                  "Contract",
                                  e.target.value
                                )
                              )
                            }
                            // name="name"
                          />
                          <span>
                            <img src="images/search.png" />
                          </span>
                        </div>
                      </div>
                      <div className="FolderList border-0">
                        <ul>
                          {checkArray(folderSearchListData?.FolderDetails) ? (
                            folderSearchListData?.FolderDetails?.map(
                              (ele, i) => {
                                return (
                                  <>
                                    <li
                                      style={{
                                        backgroundColor:
                                          folderIDD === ele.id
                                            ? "green"
                                            : "transparent",
                                      }}
                                      onClick={() =>
                                        showClientDocumentationData(ele?.id)
                                      }
                                    >
                                      <span className="Icon">
                                        <img src="images/Folder.png" />
                                      </span>
                                      <label>{ele?.folderName}</label>
                                      <a
                                        onClick={() =>
                                          deleteFolder(
                                            ele?.id,
                                            ele?.foldertableId,
                                            ele?.foldertableType
                                          )
                                        }
                                        href="javascript:void(0);"
                                        title="Cancel"
                                      >
                                        <img src="images/Cross.png" />
                                      </a>
                                      <a
                                        href="javascript:void(0);"
                                        title="Folder History"
                                        className="FolderPermissionId"
                                        onClick={() =>
                                          showFolderHistoryModal(
                                            ele?.id,
                                            ele?.foldertableId,
                                            ele?.foldertableType
                                          )
                                        }
                                      >
                                        <img src="images/timer.png" />
                                      </a>
                                      {/* <a
                                        href="javascript:void(0);"
                                        title="Upload Files"
                                        className="FolderPermissionId"
                                        onClick={() =>
                                          showUploadFilesModal(
                                            ele?.id,
                                            ele?.foldertableId,
                                            ele?.foldertableType
                                          )
                                        }
                                      >
                                        <img src="images/Icon-6.png" />
                                       </a> */}
                                      <a
                                        href="javascript:void(0);"
                                        title="Create New File"
                                        className="FolderPermissionId"
                                        onClick={() =>
                                          showCreateNewFileModal(
                                            ele?.id,
                                            ele?.foldertableId,
                                            ele?.foldertableType
                                          )
                                        }
                                      >
                                        <img src="images/document-support.png" />
                                      </a>
                                    </li>
                                  </>
                                );
                              }
                            )
                          ) : (
                            <NoDataFound />
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12">
                    <div className="EventArea">
                      <h4>Pending File Events</h4>
                      <div className="TableList">
                        <table>
                          <thead>
                            <tr>
                              <th>File Name</th>
                              <th>Modified by</th>
                              <th>Status</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loader ? (
                              <>
                                <td>
                                  {" "}
                                  <Loader />
                                </td>
                              </>
                            ) : (
                              <>
                                {checkArray(pendingFileListData?.data) ? (
                                  pendingFileListData?.data?.map((ele, ind) => {
                                    return (
                                      <>
                                        <tr key={ind}>
                                          <td>
                                            {ele?.ClientDoc?.fileName ?? "N/A"}
                                          </td>
                                          <td>
                                            {ele.UpdatedBy?.name ||
                                              ele.UpdatedBy?.firstName ||
                                              ele.UpdatedBy?.lastName ||
                                              "N/A"}
                                          </td>
                                          <td>{ele.status}</td>
                                          <td>
                                            {moment(ele.createdAt).format(
                                              "YYYY-MM-DD"
                                            )}
                                          </td>
                                          <td>
                                            <div className="Actions">
                                              <a
                                                className="FolderPermissionId"
                                                onClick={() =>
                                                  showViewFileModal(ele?.docUrl)
                                                }
                                              >
                                                <img src="images/view_jobs_details.png" />
                                              </a>
                                              <a
                                                href="javascript:void(0);"
                                                className="FolderPermissionId"
                                                title="Edit"
                                                onClick={() =>
                                                  showEditFileModal(ele)
                                                }
                                              >
                                                <img src="images/Action-1.png" />
                                              </a>
                                              <a
                                                onClick={() =>
                                                  showShareDocumentModal(
                                                    ele?.ClientDoc?.id,
                                                    ele?.ClientDoc?.folderId,
                                                    ele?.ClientDoc?.fileName
                                                  )
                                                }
                                              >
                                                <img src="images/Action-2.png" />
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })
                                ) : (
                                  <td>
                                    {" "}
                                    <NoDataFound />
                                  </td>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="TodosButtons ClientDocumentationBtn">
                      <a
                        href="javascript:void(0)"
                        className="Create mb-2 FolderPermissionId"
                        onClick={showClientInformationModal}
                      >
                        <img src="images/send_clinet.png" />
                        Send Client Information<span>3</span>
                      </a>
                      <a
                        onClick={handleCTCTemplateReleasedModalShow}
                        className="ml-0 FolderPermissionId"
                      >
                        <h6>
                          <img src="images/change_contract.png" />
                          Change to Contract (CTC) Template
                        </h6>
                      </a>
                      <a
                        onClick={() => {
                          setctcHistoryModal(true);
                          updateModalState((prev) => ({
                            ...prev,
                            ClientDocumentationModal: false,
                          }));
                        }}
                        className="ml-0 FolderPermissionId"
                      >
                        <h6>
                          <img src="images/change_contract.png" />
                          CTC History
                        </h6>
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="ml-0 FolderPermissionId"
                        onClick={showManageSignatureModal}
                      >
                        <img src="images/white_manage_signatures.png" /> Manage
                        Signatures
                      </a>
                      {/* <a
                        className="ml-0 FolderPermissionId"
                        onClick={handleDocumentKeyModalShow}
                      >
                        <img src="images/insert_keys_white.png" /> Document Keys
                      </a> */}
                      <div className="FilesArea d-inline-block">
                        <div className="FilesHead">
                          <div className="FilesLeft FilesLeftSalesdoocument">
                            <aside>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                              />
                              <span>
                                <img src="images/search.png" />
                              </span>
                            </aside>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="FilesArea">
                      <div className="FilesHead">
                        <div className="FilesLeft">
                          <h4>Files</h4>
                          <aside>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              onChange={(e) =>
                                dispatch(
                                  getAllFileListAction(
                                    folderIdLocal,
                                    2,
                                    "Contract",
                                    e.target.value
                                  )
                                )
                              }
                            />
                            <span>
                              <img src="images/search.png" />
                            </span>
                          </aside>
                        </div>

                        <div className="FilesRight">
                          <a
                            title="Upload Files"
                            className="FolderPermissionId"
                            onClick={showUploadFilesModal}
                          >
                            <img src="images/Icon-6.png" />
                          </a>
                          <a
                            title="Create New File"
                            className="FolderPermissionId"
                            onClick={showCreateNewFileModal}
                          >
                            <img src="images/document-support.png" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {loader ? (
                        <>
                          <td>
                            {" "}
                            <Loader />
                          </td>
                        </>
                      ) : (
                        <>
                          {checkArray(fileListData?.clientDocDetails) ? (
                            fileListData?.clientDocDetails?.map((ele, ind) => {
                              return (
                                <>
                                  <div className="col-lg-4 col-md-12">
                                    <div className="FilesBox2 Safety">
                                      <figcaption>
                                        <div>
                                          <h4>{ele?.name}</h4>
                                        </div>
                                        <ul>
                                          <li className="dropdown">
                                            <a
                                              className="dropdown-toggle"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </a>
                                            <ol className="dropdown-menu DropdownMenuSafetyModal">
                                              <li>
                                                <a
                                                  className="FolderPermissionId"
                                                  onClick={() =>
                                                    showEditFileModal(ele)
                                                  }
                                                >
                                                  <img src="images/Action-1.png" />
                                                  Edit Document
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="FolderPermissionId"
                                                  onClick={() =>
                                                    showViewFileModal(
                                                      ele?.docUrl
                                                    )
                                                  }
                                                >
                                                  <img src="images/view_jobs_details.png" />
                                                  View Document
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleSendForClientModalShow(
                                                      ele
                                                    )
                                                  }
                                                  className="FolderPermissionId"
                                                >
                                                  <img src="images/sign_document.png" />
                                                  Generate/Send for Client
                                                  Signature
                                                </a>
                                              </li>
                                              {/* <li><a href="javascript:void(0);" class="FolderPermissionId" data-toggle="modal" data-target="#TwoEditFile2" data-dismiss="modal" ><img src="images/sign_document2.png">Sign documents</a></li>
                                               */}
                                              <li>
                                                <a
                                                  className="FolderPermissionId"
                                                  href={ele.docUrl}
                                                  target="_blank"
                                                >
                                                  <img src="images/download.png" />
                                                  Download documents
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  className="FolderPermissionId"
                                                  // onClick={() =>
                                                  //   showUploadFilesModal(
                                                  //     ele?.id
                                                  //   )
                                                  // }
                                                  onClick={() =>
                                                    handleSendForClientModalShow(
                                                      ele
                                                    )
                                                  }
                                                >
                                                  <img src="images/upload_new.png" />
                                                  Upload Signed documents
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  className="FolderPermissionId"
                                                  onClick={() =>
                                                    showShareDocumentModal(
                                                      ele?.id,
                                                      ele?.FolderId,
                                                      ele?.fileName
                                                    )
                                                  }
                                                >
                                                  <img src="images/Action-2.png" />
                                                  Share documents
                                                </a>
                                              </li>
                                            </ol>
                                          </li>
                                        </ul>
                                      </figcaption>
                                      <div className="FilesBoxCenterSafety FilesBoxCenterSafetyModal">
                                        <div
                                          className="FilesBoxCenterSafetyLeft FolderPermissionId CursorPointer"
                                          onClick={() =>
                                            handleFollowUpActivityModalShow(
                                              ele.id
                                            )
                                          }
                                        >
                                          <img
                                            src="images/file.png"
                                            title="File"
                                          />
                                          <h6 className="underline-grey">
                                            Template
                                          </h6>
                                          <span className="Count">
                                            {ele?.templateCount ?? 0}
                                          </span>
                                        </div>
                                        <div className="FilesBoxCenterSafetyRight">
                                          <div className="form-group">
                                            <h6>Client View</h6>
                                            <label className="switch">
                                              <input
                                                type="checkbox"
                                                onChange={() =>
                                                  changeStatusFun(
                                                    ele?.id,
                                                    !ele?.clientView,
                                                    ele?.FolderId
                                                  )
                                                }
                                                checked={ele?.clientView}
                                              />
                                              <span className="slider" />
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <figcaption>
                                        <div>
                                          <p className="CursorPointer">
                                            Last update{" "}
                                            {ele?.updatedAt?.split("T")[0]}
                                          </p>
                                        </div>
                                        <ul>
                                          <li>
                                            <a
                                              href="javascript:void(0);"
                                              title="Document History"
                                              className="FolderPermissionId"
                                              onClick={() =>
                                                showDocumentHistoryModal(
                                                  ele?.id,
                                                  ele?.FolderId,
                                                  "Contract"
                                                )
                                              }
                                            >
                                              <img src="images/timer.png" />
                                            </a>
                                          </li>
                                        </ul>
                                      </figcaption>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <td>
                              {" "}
                              <NoDataFound />
                            </td>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADDNEWFILEMODALSHOW */}
      <Modal
        show={createNewFileModal}
        onHide={hideCreateNewFileModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideCreateNewFileModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create New File</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert new entry name"
                    name="name"
                    value={name}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {" "}
                    {clientDocumnetationError?.nameError}
                  </span>
                </div>
                <div className="form-group">
                  <div className="Categories Categories2 mb-4">
                    <h6>Folder Name</h6>
                    <div className="Categories_all Categories_all2">
                      <select
                        name="folderId"
                        value={folderId}
                        onChange={handleInput}
                      >
                        <option>Select a Folder</option>
                        {checkArray(folderSearchListData?.FolderDetails) ? (
                          <>
                            {folderSearchListData?.FolderDetails?.map(
                              (ele, i) => {
                                return (
                                  <option value={ele?.id}>
                                    {ele.folderName}
                                  </option>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) =>
                            dispatch(
                              searchFolderListAction(
                                modalId,
                                "Contract",
                                e.target.value
                              )
                            )
                          }
                        />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                    </ul>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {" "}
                    {clientDocumnetationError?.folderIdError}
                  </span>
                </div>
                {/* <div className="ModalFilterAreaBox mb-3">
                  <h4 className="mb-4">Editor Option</h4>
                  <div className="RadioArea d-flex  flex-column">
                    <div className="form-group">
                      <label className="Radio">
                        Create file with base template upload
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="text"
                          defaultChecked=""
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label className="Radio">
                        Create new file with editor
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="file"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="file RadioText">
                  <div className="form-group"></div>
                </div> */}
                <div className="text RadioText" style={{ display: "block" }}>
                  <div className="form-group">
                    <h6>Select Template File (Docx, pdf, HTML or rtf)</h6>
                    <div className="Upload">
                      <span>-Drag and Drop Files or click to select</span>
                      <input
                        type="file"
                        name="files"
                        onChange={handleInputFile}
                      />
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {" "}
                      {clientDocumnetationError?.clientDocumentationFileError}
                    </span>
                  </div>
                  {/* <div className="FilesAdded">
                    <label>File_name.docs</label>
                    <span>
                      <img src="images/fileview.png" />
                    </span>
                    <span className="cross">
                      <img src="images/Cross.png" />
                    </span>
                  </div> */}
                </div>
                <div className="Button d-flex justify-content-between mt-5">
                  <button
                    className="Create"
                    onClick={handleAddClientDocumentation}
                  >
                    Create File
                  </button>
                  <button className="Outline" onClick={hideCreateNewFileModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* UPLOADNEWFILESMODALSHOW */}

      <Modal
        show={uploadFilesModal}
        onHide={hideUploadFilesModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUploadFilesModal}
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Upload Files</h4>
            </div>
            <div class="FolderForm">
              <form>
                <div class="form-group">
                  <h6>Folder Name</h6>
                  <div class="Categories Categories1">
                    <div class="Categories_all Categories_all1">
                      <span>Select a folder to insert the file</span>
                      <img src="images/down.png" />
                    </div>
                    <ul style="">
                      <li>
                        <input type="text" placeholder="-Search" />
                        <span class="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      <li class="CursorPointer" data-dismiss="modal">
                        - New Folder
                      </li>
                      <li>- Full list of folders loaded from the system</li>
                    </ul>
                  </div>
                </div>
                <div class="form-group">
                  <h6>Select Files</h6>
                  <div class="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input type="file" />
                  </div>
                </div>
                <div class="Button mb-4">
                  <button class="Create">Add</button>
                </div>
                <div class="form-group mb-2">
                  <h4>Files</h4>
                </div>
                <div class="FilesAdded">
                  <label>File_name.docs</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span class="cross">
                    <img src="images/Cross.png" />
                  </span>
                </div>
                <div class="FilesAdded">
                  <label>File_name.docs</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span class="cross">
                    <img src="images/Cross.png" />
                  </span>
                </div>

                <div class="Button d-flex justify-content-between">
                  <button
                    class="Create"
                    data-toggle="modal"
                    data-target="#TwoClientDocumentation2"
                    data-dismiss="modal"
                  >
                    Save
                  </button>
                  <button
                    class="Outline"
                    data-toggle="modal"
                    data-target="#TwoClientDocumentation2"
                    data-dismiss="modal"
                  >
                    Save as Draft
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SYSTEMVENDORMO
      {/* CLIENTINFORMATIONMODALSHOW */}
      <Modal
        show={sendClientInformationModal}
        onHide={hideClientInformationModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideClientInformationModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Send Client Information</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading2">
                  <h3>Send Client Information</h3>
                </div>
                <div className="form-group">
                  <h6>Message to Receiver</h6>
                  <textarea
                    id="editornew3"
                    className="form-control"
                    rows={5}
                    // defaultValue={""}
                    name="emailBody"
                    value={emailBody}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {clientInfomationError?.emailBodyError}
                  </span>
                </div>
                <div className="SelectAvailableArea">
                  <h5>Select Available Documents</h5>
                  <div className="SelectAvailableAreaBox">
                    <h6>Sales Documents + Contracts</h6>
                    {/* {clie} */}
                    {loader ? (
                      <>
                        <td>
                          {" "}
                          <Loader />
                        </td>
                      </>
                    ) : (
                      <>
                        {checkArray(fileListData?.clientDocDetails) ? (
                          fileListData?.clientDocDetails?.map((ele, ind) => {
                            return (
                              <>
                                <div className="form-group">
                                  <label className="CheckBox">
                                    {" "}
                                    {ele?.name}
                                    <input
                                      type="checkbox"
                                      name={ele.name}
                                      value={ele.name}
                                      onChange={handleCheckboxSpe}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <td>
                            {" "}
                            <NoDataFound />
                          </td>
                        )}
                      </>
                    )}

                    <span style={{ color: "red", fontSize: "14px" }}>
                      {clientInfomationError?.signedDocsError}
                    </span>
                  </div>
                </div>
                <div className="ModalFilterAreaBox mb-3">
                  <h4 className="mb-4">Select Recipient</h4>
                  <div className="RadioArea d-flex  flex-column">
                    <div className="form-group">
                      <label className="Radio">
                        Registered Vendor
                        <input
                          type="radio"
                          name="showHideCheck"
                          value="registeredVendor"
                          checked={showHideCheck === true}
                          onChange={handleRadioChange}
                          defaultChecked
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label className="Radio">
                        Other Contact
                        <input
                          type="radio"
                          name="showHideCheck"
                          value="otherContact"
                          checked={showHideCheck === false} // Check the radio button if showHideCheck is false
                          onChange={handleRadioChange} // Handle the change event
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
                {showHideCheck ? (
                  <div className="file RadioText">
                    <div className="form-group">
                      <h6>Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert name"
                        name="sendInformationName"
                        value={sendInformationName}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {clientInfomationError?.nameError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>Email</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Email"
                        name="sendInformationEmail"
                        value={sendInformationEmail}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {clientInfomationError?.emailError}
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className="text RadioText mb-5"
                      style={{ display: "block" }}
                    >
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer"
                              onClick={handleSystemVendorModalShow}
                            >
                              <figure>
                                <img src="images/register_vendor.png" />
                              </figure>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={sendInformationFun}>
                    Send Information
                  </button>
                  <button
                    className="Outline"
                    onClick={hideClientInformationModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* SYSTEMVENDORMODALSHOW */}
      <Modal
        show={SystemVendorModal}
        onHide={handleSystemVendorModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={handleSystemVendorModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>System Vendor List</h4>
                </div>
                <div className="FolderForm">
                  {/* <form> */}
                  <div className="form-group">
                    <div className="Categories Categories1 mb-4">
                      <h6>Vendor Type</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="vendorType"
                          value={vendorType}
                          onChange={handleInput}
                        >
                          <option value="">Select a Vendor Type</option>
                          {checkArray(data1) ? (
                            <>
                              {data1?.map((ele, i) => {
                                return <option value={ele}>{ele}</option>;
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => onChangeHandler(e)}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of vendor types loaded from the system
                      </li> */}
                      </ul>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {systemErrors?.vendorTypeError}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Vendor Name</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="sendInformationName"
                          value={sendInformationName}
                          onChange={handleInput}
                        >
                          <option value="">Select a Vendor Name</option>
                          {checkArray(vendorSearchData) ? (
                            <>
                              {vendorSearchData?.map((ele, i) => {
                                return (
                                  <option value={ele?.vendorName}>
                                    {ele.vendorName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={handleInputSearchCity}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                      </ul>
                      {/* <span style={{ color: "red", fontSize: "14px" }}>
                        {systemErrors?.vendorNameError}
                      </span> */}
                    </div>
                  </div>
                  <div className="Button mt-5 d-flex justify-content-between">
                    <button
                      className="Outline"
                      onClick={handleSystemVendorModalClose}
                    >
                      Cancel
                    </button>
                    <button className="Create" onClick={handleSystemVendor}>
                      Confirm Selection
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CREATECLIENTCTCMODALSHOW */}
      <Modal
        show={createClientCTCModal}
        onHide={hideCreateClientCTCModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideCreateClientCTCModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Client's CTC</h4>
            </div>
            <div className="FolderForm">
              <div className="ContactInformationArea">
                <h6>Contact Information</h6>
                <div className="ContactInformationBox">
                  <span className="circle">JL</span>
                  <div className="ContactInformationName">
                    <h6>{allClientListData?.clientDetails?.[0]?.legalName}</h6>
                    <p>
                      {allClientListData?.clientDetails?.[0]?.streetAddress},{" "}
                      {allClientListData?.clientDetails?.[0]?.city}{" "}
                      <img src="images/location.png" />
                    </p>
                    <p>
                      Phone:{" "}
                      <span>
                        {
                          allClientListData?.clientDetails?.[0]
                            ?.businessPhoneNum
                        }
                      </span>
                    </p>
                    <p>
                      Email:{" "}
                      <span>
                        {allClientListData?.clientDetails?.[0]?.email}
                      </span>
                    </p>
                  </div>
                  <div className="AssignUnit">
                    <h4>Assigned Unit</h4>
                    <p>{contactDetails?.jobNumber}</p>
                    <a
                      onClick={() =>
                        handleCreateDraft(clientCtcByTemplateData?.ctc?.id)
                      }
                      className="CommonBtn mt-4"
                    >
                      <img src="images/generate_ctc.png" />
                      Generate Client's CTC
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="AgreementViewAreaGreen mt-3"
                style={{ overflow: "scroll", width: "100%", height: "500px" }}
              >
                <table>
                  <thead>
                    <tr>
                      <th>
                        <label className="CheckBox">
                          {" "}
                          Client Visible
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </th>
                      <th>Name</th>
                      <th>Pictures</th>
                      <th>Unit Cost</th>
                      <th>Unit Discount</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkArray(clientCtcByTemplateData?.formatedData) &&
                      clientCtcByTemplateData?.formatedData?.map((ele, i) => {
                        return (
                          <>
                            <tr className="DarkBlueBg" key={`ele-${i}`}>
                              <td>
                                {/* <label className="CheckBox">
              <input type="checkbox" />
              <span className="checkmark" />
            </label> */}
                              </td>
                              <td colSpan={6}>{ele?.name}</td>
                            </tr>
                            {checkArray(ele?.assemblies) &&
                              ele?.assemblies?.map((item, j) => {
                                return (
                                  <>
                                    <tr
                                      className="LightBlueBg"
                                      key={`item-${j}`}
                                    >
                                      <td>
                                        {/* <label className="CheckBox">
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label> */}
                                      </td>
                                      <td colSpan={6}>{item?.name}</td>
                                    </tr>
                                    {checkArray(item?.purchaseDetails) &&
                                      item?.purchaseDetails?.map(
                                        (result, k) => {
                                          return (
                                            <tr key={`result-${k}`}>
                                              <td>
                                                <label className="CheckBox">
                                                  <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                      updateLeadState({
                                                        ...iLeadState,
                                                        purchaseDetailsId:
                                                          result?.id,
                                                      })
                                                    }
                                                  />
                                                  <span className="checkmark" />
                                                </label>
                                              </td>
                                              <td>{result?.name}</td>
                                              <td>
                                                <div>
                                                  <img
                                                    src="images/picture-1.png"
                                                    alt="Thumbnail"
                                                  />
                                                </div>
                                              </td>
                                              <td>{result?.clientTotal}</td>
                                              <td>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  name="unitDiscount"
                                                  value={result?.unitDiscount}
                                                  onChange={(e) => {
                                                    let tempArray =
                                                      clientCtcByTemplateData
                                                        ?.formatedData?.[i]
                                                        ?.assemblies?.[j]
                                                        ?.purchaseDetails;

                                                    let obj = tempArray[k];
                                                    obj.unitDiscount =
                                                      e.target.value;

                                                    tempArray[k] = obj;

                                                    console.log(
                                                      tempArray,
                                                      "ARRAY"
                                                    );

                                                    let wholeArray = [
                                                      ...clientCtcByTemplateData?.formatedData,
                                                    ];

                                                    wholeArray[i].assemblies[
                                                      j
                                                    ].purchaseDetails =
                                                      tempArray;

                                                    setClientCtcByTemplateData({
                                                      ...clientCtcByTemplateData,
                                                      formatedData: wholeArray,
                                                    });
                                                  }}
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  name="quantity"
                                                  value={result?.quantity}
                                                  onChange={(e) => {
                                                    let tempArray =
                                                      clientCtcByTemplateData
                                                        ?.formatedData?.[i]
                                                        ?.assemblies?.[j]
                                                        ?.purchaseDetails;

                                                    let obj = tempArray[k];
                                                    obj.quantity =
                                                      e.target.value;

                                                    tempArray[k] = obj;

                                                    console.log(
                                                      tempArray,
                                                      "ARRAY"
                                                    );

                                                    let wholeArray = [
                                                      ...clientCtcByTemplateData?.formatedData,
                                                    ];

                                                    wholeArray[i].assemblies[
                                                      j
                                                    ].purchaseDetails =
                                                      tempArray;

                                                    setClientCtcByTemplateData({
                                                      ...clientCtcByTemplateData,
                                                      formatedData: wholeArray,
                                                    });
                                                  }}
                                                />
                                              </td>
                                              <td>
                                                {+result?.clientTotal *
                                                  +result?.quantity -
                                                  result?.unitDiscount}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </>
                                );
                              })}
                          </>
                        );
                      })}
                  </tbody>
                </table>
                <div className="CostBreakDownArea">
                  <h5 className="TableHeading CursorPointer" id="CostBreakDown">
                    Cost Breakdown <img src="images/down-arrow.png" />
                  </h5>
                  <div className="CostBreakDownShow">
                    <div className="Box">
                      <h6>Selections Details</h6>
                    </div>
                    <div className="Box DarkBlueBg">
                      <h6>Options Sub-Total</h6>
                      <h6>${totalClientTotal}</h6>
                    </div>
                    <div className="Box LightBlueBg">
                      <h6>
                        Tax {getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent}%
                      </h6>
                      <h6>
                        ${" "}
                        {(
                          (totalClientTotal *
                            getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) /
                          100
                        ).toFixed(2)}
                      </h6>
                    </div>
                    <div className="Box NavyBlueBg">
                      <h6>Total</h6>
                      <h6>
                        ${" "}
                        {(
                          totalClientTotal +
                          (totalClientTotal *
                            getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) /
                            100
                        ).toFixed(2)}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="CostBreakDownArea">
                  <h5 className="TableHeading  my-4">
                    Cost Breakdown With Options
                  </h5>
                  <div className="Box DarkBlueBg">
                    <h6>Contract Price Before Tax</h6>
                    <h6>${contactDetails?.clientPurchasePrice}</h6>
                  </div>
                  <div className="Box LightBlueBg">
                    <h6>Change Order Total</h6>
                    <h6>
                      $
                      {(
                        totalClientTotal +
                        (totalClientTotal *
                          getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) /
                          100
                      ).toFixed(2)}
                    </h6>
                  </div>
                  <div className="Box DarkBlueBg">
                    <h6>Sub Total</h6>
                    <h6>{totalClientTotal}</h6>
                  </div>
                  <div className="Box LightBlueBg">
                    <h6>Tax {getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent}%</h6>
                    <h6>
                      ${" "}
                      {(
                        (totalClientTotal *
                          getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) /
                        100
                      ).toFixed(2)}
                    </h6>
                  </div>
                  <div className="Box LightBlueBg">
                    <h6>Adjustment: Applied Tax Rebate</h6>
                    <h6>- ${getTaxProfile?.[0]?.taxRebates?.[0]?.amount}</h6>
                  </div>
                  <div className="Box NavyBlueBg">
                    <h6>Total</h6>
                    <h6>
                      {parseFloat(
                        (
                          (totalClientTotal *
                            getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) /
                          100
                        ).toFixed(2) -
                          getTaxProfile?.[0]?.taxRebates?.[0]?.amount +
                          totalClientTotal +
                          contactDetails?.clientPurchasePrice +
                          totalClientTotal +
                          (totalClientTotal *
                            getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) /
                            100
                      ).toFixed(2)}{" "}
                    </h6>
                  </div>
                </div>
                {/* <div className="ChangeOrderCreatArea mt-5">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="InputBox">
                            <div className="form-group"
                              <input type="text" />
                              <label>Date</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="InputBox">
                            <div className="form-group">
                              <input type="text" />
                              <label>John Joe Client</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="InputBox">
                            <div className="form-group">
                              <input type="text" />
                              <label>Date</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="InputBox">
                            <div className="form-group">
                              <input type="text" />
                              <label>John Joe Builder Company</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="Button text-center">
                <button
                  className="Create mr-5 FolderPermissionId"
                  onClick={handleSendClientSignatures}
                >
                  Send for client signature
                </button>
                <button
                  className="Outline ml-5"
                  onClick={hideCreateClientCTCModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* MANAGESIGNATUREMODALSHOW */}
      <Modal
        show={manageSignatureModal}
        onHide={hideManageSignatureModal}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideManageSignatureModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new signature</h5>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea border p-2">
                  <h5>Signatures</h5>
                  <div className="CommonModalBox">
                    <SignaturePad
                      ref={signCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        updateLeadState({
                          ...iLeadState,
                          signature: signCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearSignature} href="javascript:void(0);">
                      <img src="images/circle_minus.png" />
                    </a>
                    {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {signatureErrors?.signatureError}
                  </span>
                </div>
              </div>
              {/* <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new Initials</h5>
                  <div className="CommonModalBox">
                    <h5>
                      <figure>
                        <img src="images/add_new_signature.png" />
                      </figure>{" "}
                      Add new Initials
                    </h5>
                   
                    <Popup modal trigger={<div>buttuon clikck</div>}> <div>HELLO I'M A POPIIU </div></Popup>
                  </div>
                </div>
              </div> */}
              {/* <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Initials</h5>
                  <div className="CommonModalBox">
                    <SignaturePad
                      ref={intialCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "intialCanvas",
                      }}
                      onEnd={() =>
                        updateLeadState({
                          ...iLeadState,
                          initial: intialCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearInitials} href="javascript:void(0);">
                      <img src="images/circle_minus.png" />
                    </a>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {signatureErrors?.initialError}
                  </span>
                </div>
              </div> */}
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addSignatureFun}>
                  Save
                </button>
                <button className="Outline" onClick={hideManageSignatureModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* FOLDERHISTORYSHOW */}

      <Modal
        show={folderHistoryModal}
        onHide={hideFolderHistoryModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideFolderHistoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Folder History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              {/* <div className="JobBox">
                <h6>
                  <span>Folder:</span>&nbsp;Custom Layouts
                </h6>
              </div> */}
            </div>
            <div className="TableList TableListAlign CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(folderHistoryListData?.folderHistories) ? (
                    folderHistoryListData?.folderHistories?.map((ele, i) => {
                      return (
                        <>
                          <tr>
                            <td>{ele?.modifiedBy}</td>
                            <td>{ele?.createdBy}</td>
                            <td>{ele?.createdAt?.split("T")[0]}</td>
                            <td>{ele?.activity}</td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <NoDataFound />
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* DISCUSSIONMODALSHOW */}
      <Modal
        show={discussionModal}
        onHide={hideDiscussionModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideDiscussionModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Follow Up Activity</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="TableList CommonHeading MinHeightTable mt-4">
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th>Type</th>
                        <th>Sent By</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {followUpListData?.listFollowUps?.map((ele, i) => {
                        return (
                          <>
                            <tr>
                              <td>
                                <div className="ActionsWidth">
                                  <img src="images/meeting_icon.png" />
                                </div>
                              </td>
                              <td>{ele?.sendType}</td>
                              <td>{ele?.sendBy}</td>
                              <td>{ele?.createdAt?.split("T")[0]}</td>
                              <td>{ele?.scheduleStatus}</td>
                              <td>
                                <div className="dropdown CursorPointer">
                                  <p data-toggle="dropdown">
                                    <span>
                                      <img src="images/three-dots.png" />
                                    </span>
                                  </p>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuOffset"
                                  >
                                    <li>
                                      <a
                                        className="dropdown-item FolderPermissionId"
                                        href="javascript:void(0);"
                                        onClick={() =>
                                          showEditFollowUpActivityModal(
                                            "discussion",
                                            ele?.LeadId,
                                            ele?.id
                                          )
                                        }
                                      >
                                        <img src="images/icon-15.png" />
                                        Edit Activity Details
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          cancelFollowUpActivity(
                                            ele?.LeadId,
                                            ele?.id
                                          )
                                        }
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <img src="images/dropdown-delete.png" />
                                        Cancel Activity
                                      </a>
                                    </li>
                                    {/* <li>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                        onClick={() =>
                                          showAddItineraryModal("discussion")
                                        }
                                      >
                                        <img src="images/send_to_itinary.png" />
                                        Send to itinery
                                      </a>
                                    </li> */}
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <img src="images/export-to-calendar.png" />
                                        Export to Calender
                                        <img src="images/rightarrow2.png" />
                                      </a>
                                      <ul className="dropdown-menu submenu submenuLead">
                                        <li>
                                          <a
                                            onClick={() =>
                                              handleGoogleData(ele)
                                            }
                                            href="javascript:void(0);"
                                            className="dropdown-item"
                                          >
                                            <img src="images/google-calendar.png" />
                                            Google Calendar
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleOutlookData(ele)
                                            }
                                          >
                                            <img src="images/outlook-calendar.png" />
                                            Outlook Calender
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            className="dropdown-item"
                                            onClick={() => handleYahooData(ele)}
                                          >
                                            <img src="images/email-calendar.png" />
                                            Yahoo Calender
                                          </a>
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                        onClick={() =>
                                          showSetReminderModal(
                                            "discussion",
                                            ele?.LeadId,
                                            ele?.id,
                                            ele?.setReminder
                                          )
                                        }
                                      >
                                        <img src="images/set-reminder.png" />
                                        Set Reminder
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          sendInvitationFun(
                                            ele?.LeadId,
                                            ele?.id
                                          )
                                        }
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <img src="images/send_invitaiton.png" />
                                        Send Invitation
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                        onClick={() =>
                                          showAssignSaleModal("discussion")
                                        }
                                      >
                                        <img src="images/assign_icon.png" />
                                        Assign Staff member
                                      </a>
                                    </li>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="Button text-center">
                  <button onClick={hideDiscussionModal} className="Outline">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* LOCATIONMODALSHOW */}

      <Modal
        show={locationModal}
        onHide={hideLocationModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideLocationModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Location</h4>
            </div>
            <div className="FolderForm">
              <form>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2512.956156815679!2d-114.06795422412809!3d50.961514850436004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53717131e33f4a8f%3A0xa5e9f67b76d85576!2s312%20Weddenburn%20Rd%20SE%2C%20Calgary%2C%20AB%20T2J%201J1%2C%20Canada!5e0!3m2!1sen!2sin!4v1692684451831!5m2!1sen!2sin"
                  width="100%"
                  height={400}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* DOCUMENTHISTORYSHOW */}
      <Modal
        show={documentHistoryModal}
        onHide={hideDocumentHistoryModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideDocumentHistoryModal}
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Document History</h4>
            </div>
            <div class="JobTitleArea mb-2">
              <div class="JobBox">
                <h6>
                  <span>Document:</span>&nbsp;Sales Contract.pdf
                </h6>
              </div>
            </div>

            <div class="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(folderHistoryListData?.folderHistories) ? (
                        folderHistoryListData?.folderHistories?.map(
                          (ele, ind) => {
                            return (
                              <>
                                <tr>
                                  <td>{ele?.modifiedBy}</td>
                                  <td>{ele?.createdBy}</td>
                                  <td>{ele?.createdAt?.split("T")[0]}</td>
                                  <td>{ele?.activity}</td>
                                </tr>
                              </>
                            );
                          }
                        )
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDITFILEMODALSHOW */}

      <Modal
        show={editFileModal}
        onHide={hideEditFileModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideEditFileModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Edit File</h4>
            </div>
            <div className="FolderForm">
              <div className="EditOption">
                <div className="EditOptionBox">
                  <h6>Name</h6>
                  <a href="javascript:void(0);">
                    {contentData?.name ?? "N/A"}
                    &nbsp;&nbsp;
                    <span>
                      <img src="images/fileview.png" />
                    </span>
                  </a>
                </div>
                <div className="EditOptionBox">
                  <h6>Folder</h6>
                  <a href="javascript:void(0);">
                    {contentData?.fileName ?? "N/A"}
                    &nbsp;&nbsp;
                  </a>
                </div>
                <div className="EditOptionBox">
                  <h6>Draft</h6>
                  <a href="javascript:void(0);">
                    <label className="CheckBox">
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </a>
                </div>
                <div className="EditOptionBox">
                  <h6>Use Company Header</h6>
                  <a
                    className="FolderPermissionId"
                    onClick={() => handlHeaderPreviewModalShow()}
                  >
                    <span>
                      <img src="images/company_header.png" />
                    </span>
                  </a>
                </div>
                <div className="EditOptionBox">
                  <h6>Download</h6>
                  <a href={contentData?.docUrl} target="_blank">
                    <span>
                      <img src="images/download.png" />
                    </span>
                  </a>
                </div>
                <div className="EditOptionBox">
                  <h6>Insert Signature</h6>
                  <a onClick={() => handlInsertSignatureModalShow()}>
                    <span>
                      <img src="images/signature.png" />
                    </span>
                  </a>
                </div>
                <div className="EditOptionBox">
                  <h6>Insert Keys</h6>
                  <a
                    className="FolderpermissionId"
                    onClick={() => handleDocumentKeyModalShow()}
                  >
                    <span>
                      <img src="images/insert_keys.png" />
                    </span>
                  </a>
                </div>
              </div>

              {content ? (
                <div className="mce-pagebreak">
                  <Editor
                    apiKey="rye4mz748ekd8ke0loyu8j7gq7iu1tkxs3nticj32j848g6r" // Use your premium API key
                    onInit={(evt, editor) => (editorsRef.current = editor)}
                    value={content}
                    init={{
                      height: 500,
                      menubar: "file edit view insert format tools table help",
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "print",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "paste",
                        "help",
                        "wordcount",
                        "lists",
                        "table",
                        "pagebreak",
                        "spellchecker",
                        "powerpaste",
                        "mediaembed",
                        "advcode",
                        "emoticons",
                        "codesample",
                        "advtable",
                        "visualchars",
                        "visualblocks",
                        "linkchecker",
                        "checklist",
                        "media",
                        "tableofcontents",
                        "a11ychecker",
                        "permanentpen",
                        "casechange",
                        "pageembed",
                        "nonbreaking",
                        "quickbars",
                        "advlist",
                        "autosave",
                        "autolink",
                        "autoresize",
                      ],
                      toolbar:
                        "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | " +
                        "alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent | " +
                        "forecolor backcolor casechange permanentpen formatpainter removeformat | " +
                        "pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media pageembed link anchor codesample | " +
                        "a11ycheck ltr rtl | showcomments addcomment",

                      toolbar_sticky: true,

                      autosave_ask_before_unload: true,
                      autosave_interval: "30s",
                      autosave_prefix: "{path}{query}-{id}-",
                      autosave_restore_when_empty: true,
                      autosave_retention: "2m",

                      image_advtab: true,

                      content_css: "default",
                      importcss_append: true,

                      image_title: true,
                      automatic_uploads: true,

                      // Handle drag and drop events
                      setup: (editor) => {
                        editor.on("drop", (e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          // Handle dropped files
                          if (e.dataTransfer && e.dataTransfer.files) {
                            const files = e.dataTransfer.files;
                            for (const file of files) {
                              const reader = new FileReader();
                              reader.onload = (event) => {
                                // Insert image at the cursor position
                                editor.insertContent(
                                  `<img src="${event.target.result}" alt="${file.name}"/>`
                                );
                              };
                              reader.readAsDataURL(file);
                            }
                          }
                        });
                      },

                      link_list: [
                        {
                          title: "My page 1",
                          value: "https://www.tinymce.com",
                        },
                        {
                          title: "My page 2",
                          value: "http://www.moxiecode.com",
                        },
                      ],
                      image_list: [
                        {
                          title: "My image 1",
                          value:
                            "https://www.tiny.cloud/images/glyph-tinymce.svg",
                        },
                        {
                          title: "My image 2",
                          value:
                            "https://www.tiny.cloud/docs/images/glyph-tinymce.svg",
                        },
                      ],
                      image_class_list: [
                        { title: "None", value: "" },
                        { title: "Some class", value: "some-class" },
                      ],
                      importcss_file_filter: "main.css",
                      templates: [
                        {
                          title: "New Table",
                          description: "creates a new table",
                          content:
                            "<table><tr><td>Sample Text</td></tr></table>",
                        },
                        {
                          title: "Starting my story",
                          description: "A sample document",
                          content: "Once upon a time...",
                        },
                        {
                          title: "New list with dates",
                          description: "New List with Dates",
                          content:
                            "<ul><li>12 December</li><li>13 December</li></ul>",
                        },
                      ],
                      template_cdate_format: "[CDATE: %m/%d/%Y : %H:%M:%S]",
                      template_mdate_format: "[MDATE: %m/%d/%Y : %H:%M:%S]",
                      height: 600,
                      image_caption: true,
                      quickbars_selection_toolbar:
                        "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                      noneditable_class: "mceNonEditable",
                      toolbar_mode: "sliding",
                      spellchecker_dialog: true,
                      spellchecker_whitelist: ["Ephox", "Moxiecode"],

                      tinycomments_mode: "embedded",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      a11y_advanced_options: true,

                      branding: false, // Remove TinyMCE branding
                      powerpaste_word_import: "clean", // Configure PowerPaste
                      powerpaste_html_import: "merge",
                      powerpaste_allow_local_images: true,

                      // Enable live media embeds
                      media_live_embeds: true,

                      // Enable line breaks
                      forced_root_block: "",
                      force_br_newlines: true,
                      force_p_newlines: false,
                    }}
                    onEditorChange={handleEditorChange}
                  />
                </div>
              ) : (
                <p>Loading document...</p>
              )}

              <div className="Button d-flex justify-content-between">
                <button
                  className="Create mt-5"
                  // onClick={handleEditDocumentation}
                  // disabled={loading}
                >
                  {/* {loading ? (
                          <>
                            {" "}
                            <span
                              className="spinner-border spinner-border-md"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : ( */}
                  Save File
                </button>
                <button className="Create mt-5" onClick={hideEditFileModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* VIEWFILEMODALSHOW */}

      <Modal
        show={viewDocumentModal}
        onHide={hideViewFileModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideViewFileModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View Document</h4>
            </div>
            <div className="FolderForm">
              {viewDocumentData && (
                <Editor
                  apiKey="rye4mz748ekd8ke0loyu8j7gq7iu1tkxs3nticj32j848g6r"
                  // onInit={(evt, editor) => (editorsRef.current = editor)}
                  value={viewDocumentData}
                  disabled
                  init={{
                    height: 500,
                    menubar: false,

                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                      "lists",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | " +
                      "alignleft aligncenter alignright alignjustify | " +
                      "bullist numlist outdent indent | removeformat | help",
                    readOnly: true,
                  }}
                />
              )}
              <div className="Button mt-2 d-flex justify-content-center">
                <button className="Outline ">Close</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* SHAREDOCUMENTMODALSHOW */}

      <Modal
        show={shareDocumentsModal}
        onHide={hideShareDocumentModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideShareDocumentModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Share Document</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading">
                <h3>
                  <span>Documents:</span>
                  {fileNames}
                </h3>
              </div>
              <div className="ModalFilterAreaBox mb-3">
                <h4 className="mb-4">Select Recipient</h4>
                <div className="RadioArea d-flex flex-column">
                  <div className="form-group">
                    <label className="Radio">
                      Select Recipient
                      <input
                        type="radio"
                        name="recipient"
                        value="Select Recipient"
                        checked={selectedOptions === "Select Recipient"}
                        onChange={() => setSelectedOptions("Select Recipient")}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group mb-4">
                    <label className="Radio">
                      Other Contact
                      <input
                        type="radio"
                        name="recipient"
                        value="Other Contact"
                        checked={selectedOptions === "Other Contact"}
                        onChange={() => setSelectedOptions("Other Contact")}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              </div>
              {selectedOptions === "Select Recipient" && (
                <div className="form-group">
                  <h6>Select Recipient</h6>
                  <div class="CommonSelectBox w-100">
                    <select
                      name="slectedClient"
                      value={slectedClient}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      {allClients?.length > 0 &&
                        allClients?.map((item, i) => {
                          return (
                            <option value={item.id} key={i}>
                              {item.legalName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              )}
              <div className="text RadioText">
                <div className="form-group"></div>
              </div>
              <div className="file RadioText mb-5" style={{ display: "block" }}>
                <div className="form-group">
                  <h6>Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert name"
                    name="name"
                    value={name}
                    onChange={handleInput}
                  />
                  <span style={{ fontSize: "14px", color: "red" }}>
                    {shareDocumentationError?.nameError}
                  </span>
                </div>
                <div className="form-group">
                  <h6>Email</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert Email"
                    name="email"
                    value={email}
                    onChange={handleInput}
                  />
                  <span style={{ fontSize: "14px", color: "red" }}>
                    {shareDocumentationError?.emailError}
                  </span>
                </div>
                <div className="Button">
                  <button onClick={addRecipentList} className="Create">
                    Add Recipient
                  </button>
                </div>
              </div>
              <h6>Recipients</h6>
              <div className="FilesAddedTable">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkArray(emailData) ? (
                      emailData?.map((ele, i) => {
                        return (
                          <>
                            <tr>
                              <td>
                                <div className="FilesAddedName">
                                  <p>{ele?.name}</p>
                                  <span>
                                    <img
                                      src="images/Cross.png"
                                      title="canceling"
                                      onClick={() => removeEmailData(i)}
                                    />
                                  </span>
                                </div>
                              </td>
                              <td>{ele?.email}</td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <NoDataFound />
                    )}
                  </tbody>
                </table>
              </div>
              <div className="form-group">
                <h6>Message</h6>
                <textarea
                  type="text"
                  className="form-control"
                  rows={4}
                  placeholder="Include message (optional)"
                  name="emailBody"
                  value={emailBody}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {shareDocumentationError?.emailBodyError}
                </span>
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addShareDocsFun}>
                  Share Document
                </button>
                <button className="Outline" onClick={hideShareDocumentModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* STATEMENTOFADJUSTMENTMODALSHOW */}

      <Modal
        show={adjustmentStatementModal}
        onHide={hideAdjustStatementModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAdjustStatementModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Statement of Adjustments</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading">
                  <h3>
                    <span>Status: Pending Accountant Release</span>
                  </h3>
                </div>
                <div className="AgreementViewArea">
                  <div className="AgreementViewTop">
                    <a href="javscript:void(0)">
                      <img src="images/modal-big-logo.png" />
                    </a>
                    <div className="AgreementViewTopRight">
                      <h3>iBuild</h3>
                      <p>312 Weddenburn Rd SE</p>
                      <p>Calgary, AB CA, T2J 1J1</p>
                      <p>
                        4034005933 www.ibuildapplications.com
                        linden@ibuildapplications.com
                      </p>
                      <p>
                        “The bitterness of poor quality remains long after the
                        sweetness of meeting
                        <br />
                        the schedule has been gorgotten”
                      </p>
                    </div>
                  </div>
                  <div className="StatementAdjustmentArea px-4">
                    <h6>Statement of Adjustments</h6>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Client Name</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Address</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>State/Provice</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Possession Date</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Date Prepared</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Job Number</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Sales Consultant</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Model</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Contract Date</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="Title">JOB DETAILS</h6>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Address</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>State/Provice</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Subdivision</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Lot</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Block</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Plan</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Sale Type</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="Title">Sale Revenues</h6>
                        <div className="StatementAdjustmentBottom">
                          <label>
                            Contract Purchase Agreement Price (Pre-Tax)
                          </label>
                          <p className="LightGreen">$500,000.00</p>
                        </div>
                        <h6 className="Title">ADD: Tax</h6>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>ADD: Tax</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="StatementAdjustmentBottom">
                          <label>Applied Tax</label>
                          <p className="LightGrey">5.00%</p>
                          <p className="LightRed">$25,000.00</p>
                        </div>
                        <h6 className="Title">Less: Deposit</h6>
                        <div className="StatementAdjustmentTable">
                          <table>
                            <thead>
                              <tr>
                                <th>Notes</th>
                                <th>Transaction ID#</th>
                                <th>Date</th>
                                <th>Tx</th>
                                <th>Tx Type</th>
                                <th>
                                  Supporting <br /> Documents
                                </th>
                                <th>Status</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Condition Removal Deposit</td>
                                <td>OP-CHI-2B-243-1</td>
                                <td>03/30/20 21</td>
                                <td>CH</td>
                                <td>OP</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocsStatement2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentListStatement"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>Commited</td>
                                <td>
                                  <div className="BgBlue">$35,000.00</div>
                                </td>
                              </tr>
                              <tr>
                                <td>Construction Drawings</td>
                                <td>OP-CHI-2B-243-2</td>
                                <td>06/30/20 21</td>
                                <td>D</td>
                                <td>DP</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocsStatement2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentListStatement"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>Commited</td>
                                <td>
                                  <div className="BgBlue">$35,000.00</div>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={6}>
                                  Down Payment Amount Required
                                </td>
                                <td colSpan={2}>
                                  <div className="BgBlue">$0.00</div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={6}>Total Deposit</td>
                                <td colSpan={2}>
                                  <div className="BgBlue">$55,000.00</div>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <h6 className="Title">Summary</h6>
                        <div className="StatementAdjustmentBottom">
                          <label>
                            Contract Purchase Agreement Price (Pre-Tax)
                          </label>
                          <p className="LightGreen">$500,000.00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ChangeOrderCreatArea mt-5">
                    <div className="row">
                      <div className="col-lg-12"></div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 ml-auto">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            {/* <div class="InsertSignature">
                            </div> */}
                            <label>John Doe</label>
                            <label>Company Accountant</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 ml-auto">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>John Doe</label>
                            <label>Company Accountant</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" data-dismiss="modal">
                    Print
                  </button>
                  <button
                    className="Create FolderPermissionId"
                    data-toggle="modal"
                    data-target="#StatementOfAdjustmentsSaveSignature"
                    data-dismiss="modal"
                  >
                    Save
                  </button>
                  <button
                    className="Create FolderPermissionId"
                    data-toggle="modal"
                    data-target="#SoaHistory1"
                    data-dismiss="modal"
                  >
                    <img src="images/history_icon.png" />
                    History
                  </button>
                  <button
                    className="Outline"
                    onClick={hideAdjustStatementModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* scheduledPaymentsShow */}
      <Modal
        show={scheduledPaymentModal}
        onHide={hideScheduledPaymentModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideScheduledPaymentModal}
          >
            ×
          </a>
          <>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Scheduled Payments</h4>
              </div>
              <div className="ModalBigheading mb-5">
                {/* <h3>
                  <span>Client:</span>Marna Contract
                </h3>
                <h3>
                  <span>Job Assigned:</span>U37-ARB-1B-243
                </h3> */}
              </div>
              <div className="Tableheader">
                <h6 className="mb-0">Schedule of Payments</h6>
                <div className="Actions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <DownloadTableExcel
                    filename="Leads List"
                    sheet="Vendors"
                    currentTableRef={scheduledListRef.current}
                  >
                    <button href="jacascript:void(0);">
                      <img src="images/download.png" />
                    </button>
                  </DownloadTableExcel>
                  {/* <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a> */}
                </div>
              </div>
              <div className="TableList CommonHeading TableHeightScroll">
                <table ref={scheduledListRef}>
                  <thead>
                    <tr>
                      <th>Scheduled Payment Date</th>
                      <th>Transaction Type</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkArray(scheduledPaymentListData) ? (
                      scheduledPaymentListData?.map((ele, i) => {
                        return (
                          <tr>
                            <td>{ele?.scheOfPayDate?.split("T")[0]}</td>
                            <td>{ele?.transactionType}</td>
                            <td>{ele?.total}</td>

                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className="javascript:void(0);"
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth2">
                                      <li>
                                        <a
                                          onClick={editScheduledPaymentFun}
                                          href="javascript:void(0);"
                                        >
                                          <img src="images/Action-1.png" />
                                          Edit Scheduled Payment
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            removeScheduledPaymentFun(ele?.id)
                                          }
                                          href="javascript:void(0);"
                                        >
                                          <img src="images/dropdown-delete.png" />
                                          Remove Scheduled Payment
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="FolderPermissionId"
                                          onClick={() =>
                                            showContractTransactionsModal(
                                              ele?.id,
                                              ele?.ContractId
                                            )
                                          }
                                        >
                                          <img src="imagewwwes/add_transactions.png" />
                                          Create New Transaction Entry
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleCloseAddPaymentReminder(
                                              ele?.id,
                                              ele?.ContractId
                                            )
                                          }
                                        >
                                          <img src="images/timer.png" />
                                          Set Payment Reminder
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoDataFound />
                    )}

                    <tr>
                      <td>
                        <div className="TableInput">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Insert Amount"
                            name="scheOfPayDate"
                            value={scheOfPayDate}
                            onChange={handleInput}
                          />
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {scheduledPaymentError?.scheOfPayDateError}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="CommonSelectBox">
                          <select
                            name="transactionType"
                            value={transactionType}
                            onChange={handleInput}
                          >
                            <option>Select Transaction Type</option>

                            <option value="ADJ: Other Adjustments">
                              ADJ: Other Adjustments
                            </option>
                            <option value="ADJR: Revenue Adjustments">
                              ADJR: Revenue Adjustments
                            </option>
                            <option value="CO: Change Order Deposit">
                              CO: Change Order Deposit
                            </option>
                            <option value="DP: Down Payment Deposit">
                              DP: Down Payment Deposit
                            </option>
                            <option value="OP: Offer to Purchase">
                              OP: Offer to Purchase
                            </option>

                            <option value="Balance Due on Closing">
                              Balance Due on Closing
                            </option>
                            <option value="Closing Transactions">
                              Closing Transactions
                            </option>
                            <option value="Revenue - Change Order">
                              Change Order
                            </option>
                            <option value="Revenue - Contract Price (Pre-Tax)">
                              Contract Price
                            </option>
                            <option value="Revenue - Parking">
                              Revenue Parking
                            </option>
                            <option value="Tax - Base">Tax - Base</option>
                            <option value="Tax - Rebate">Tax - Rebate</option>
                          </select>
                          <span>
                            <img src="images/down.png" />
                          </span>
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {scheduledPaymentError?.transactionTypeError}
                        </span>
                      </td>
                      <td>
                        <div className="TableInput">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Insert Amount"
                            name="total"
                            value={total}
                            onChange={handleInputNumber}
                          />
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {scheduledPaymentError?.totalError}
                          </span>
                        </div>
                      </td>
                      {/* <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className="javascript:void(0);"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/Action-1.png" />
                                    Edit Scheduled Payment
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/dropdown-delete.png" />
                                    Remove Scheduled Payment
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#TransactionsScheduleSmall"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/add_transactions.png" />
                                    Create New Transaction Entry
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/timer.png" />
                                    Set Payment Reminder
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td> */}
                    </tr>
                    {/* <tr>
                      <td colSpan={4}>
                        <div className="TableButtons">
                          <button className="BrandBtn">
                            Schedule New Payment
                          </button>
                        </div>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              <div className="Button mt-5 d-flex justify-content-between">
                <button
                  className="Create"
                  onClick={(e) => addSchedulePaymentFun(e)}
                >
                  Save
                </button>
                <button className="Outline" onClick={hideScheduledPaymentModal}>
                  Cancel
                </button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>

      {/* EDIT LEAD DETAILS MODAL */}
      <Modal
        show={editLeadDetailsModal}
        onHide={hideEditLeadDetailsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <>
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              onClick={hideEditLeadDetailsModal}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Lead Details</h4>
              </div>
              <div className="FolderForm">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="ContactInformationArea">
                      <h6>Contact Information</h6>
                      <div className="ContactInformationBox">
                        <span className="circle">JL</span>
                        <div className="ContactInformationName">
                          <h6>{leadDetailsData?.legalName}</h6>
                          <p>
                            {leadDetailsData?.streetAddress} <br />{" "}
                            {leadDetailsData?.city}, {leadDetailsData?.state}{" "}
                            {/* <img src="images/location.png" title="location" /> */}
                          </p>
                          <p>
                            Phone:{" "}
                            <span>{leadDetailsData?.primaryPhoneNum}</span>
                          </p>
                          <p>
                            Email: <span>{leadDetailsData?.email}</span>
                          </p>
                        </div>
                        <div className="ContactInformationIcon">
                          <a
                            href="javascript:void(0);"
                            title="pdf"
                            onClick={handlePrint}
                          >
                            <img src="images/pdf.png" />
                          </a>
                          <a
                            href="javascript:void(0);"
                            title="download"
                            onClick={handlePrint}
                          >
                            <img src="images/download.png" />
                          </a>
                          {/* <a
                            href="javascript:void(0);"
                            title="client documentationnnnnn"
                            onClick={() =>
                              showClientDocumentatioModal(
                                leadDetailsData?.id,
                                "editLeadDetails"
                              )
                            }
                          >
                            <img src="images/icon-75.png" />
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="TodosButtons mt-5">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#GeneralInformationLead"
                      >
                        <img src="images/general_information_red.png" />
                        General Information
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#FollowUpActivity"
                      >
                        <img src="images/timer-red.png" />
                        Follow up Activity<span>3</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="GeneralInformationLead"
                    ref={tableRef}
                  >
                    <div className="LeadDetailsInput mt-4">
                      <div className="row">
                        <div className="col-lg-4 col-md-12">
                          <div className="CommonModalArea">
                            <div className="form-group">
                              <h5>Assigned to</h5>
                              <div className="CommonModalBox">
                                <h5
                                  className="CursorPointer FolderPermissionId"
                                  onClick={() =>
                                    showAssignSaleModal(
                                      "editLeadDetails",
                                      UserId
                                    )
                                  }
                                >
                                  <figure>
                                    <img
                                      src="images/icon-32.png"
                                      title="assign sales representative"
                                    />
                                  </figure>
                                  <figure>
                                    <img src="images/refresh.png" />
                                  </figure>
                                  {/* {fileNameStatic} */}
                                  {leadDetailsData?.Users?.[0]?.name ||
                                    leadDetailsData?.Users?.[0]?.email ||
                                    "No User Found"}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="Categories Categories2 mb-4">
                            <h6>
                              SubDivision<span className="required">*</span>
                            </h6>
                            <div className="Categories_all Categories_all2">
                              <select
                                name="subDivision"
                                value={subDivision}
                                onChange={handleInput}
                              >
                                <option>Select SubDivision </option>
                                {checkArray(subDivisionListData?.details) ? (
                                  <>
                                    {subDivisionListData?.details?.map(
                                      (ele, i) => {
                                        // le('elelelele',ele[0])
                                        return (
                                          <option value={ele}>{ele}</option>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                              <img src="images/down.png" />
                            </div>
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {leadErrors?.subDivisionError}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="CommonModalArea">
                            <div className="form-group">
                              <h5>Survey</h5>
                              <div className="CommonModalBox">
                                <h5
                                  className=""
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    showEditSurveyModal(
                                      leadDetailsData?.Survey?.LeadId,
                                      leadDetailsData?.Survey?.id
                                    )
                                  }
                                >
                                  <figure>
                                    <img src="images/survey.png" />
                                  </figure>
                                  <span className="Count">3</span>John Doe
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="CommonModalArea">
                            <h5>Lead Age</h5>
                            <div className="CommonModalBox">
                              <h5 className="">
                                &lt;{" "}
                                {(
                                  (new Date() -
                                    new Date(leadDetailsData?.createdAt)) /
                                  (1000 * 60 * 60 * 24)
                                ).toFixed()}{" "}
                                days
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="Categories Categories2 mb-4">
                            <h6>
                              Status<span className="required">*</span>
                            </h6>
                            <div className="Categories_all Categories_all2">
                              <select
                                name="leadStatus"
                                value={leadStatus}
                                onChange={handleInput}
                              >
                                <option>Select lead status </option>
                                <option value="New">New</option>
                                <option value="InProgress">In progress</option>
                                <option value="Intersted">Interested</option>
                                <option value="Not Interested">
                                  Not Interested
                                </option>
                                <option value="Cancelled">Cancelled</option>
                              </select>
                              <img src="images/down.png" />
                            </div>
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {leadErrors?.leadStatusError}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="Categories Categories2 mb-4">
                            <h6>
                              Confidence Level
                              {/* / <span className="required">*</span> */}
                            </h6>
                            <div className="Categories_all Categories_all2">
                              <select
                                name="confidenceLevel"
                                value={confidenceLevel}
                                onChange={handleInput}
                              >
                                <option>Confidence Level </option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                              </select>
                              <img src="images/down.png" />
                            </div>
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {leadErrors?.confidenceLevelError}
                            </span>
                          </div>
                        </div>
                        <div className="col-12">
                          <h6 className="mb-4">Realtor/Referrer</h6>
                        </div>
                        <div className="form-group  col-lg-5 col-md-12">
                          <h6>Name</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Name"
                            name="referrerName"
                            value={referrerName}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="form-group col-lg-5 col-md-12 ml-auto">
                          <h6>Agency</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Agency"
                            name="referrerAgency"
                            value={referrerAgency}
                            onChange={handleInput}
                          />
                        </div>
                        <div className="form-group col-lg-5 col-md-12">
                          <h6>Email</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Email"
                            name="referrerEmail"
                            value={referrerEmail}
                            onChange={handleInput}
                          />
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {leadErrors?.referrerEmail}
                          </span>
                        </div>
                      </div>
                      <div className="Button d-flex justify-content-between flex-lg-row flex-column">
                        <button onClick={editLeadDetailsFun} className="Create">
                          Save
                        </button>
                        <button
                          onClick={editLeadDetailsFun}
                          className="Create my-lg-0 my-2"
                        >
                          Convert lead to Contract
                        </button>
                        <button
                          onClick={hideEditLeadDetailsModal}
                          className="Outline"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="FollowUpActivity">
                    <div className="TableList CommonHeading mt-4">
                      <table>
                        <thead>
                          <tr>
                            <th colSpan={6}>
                              <div className="TodosButtons mt-0 d-flex align-items-center justify-content-center MinHeightTable">
                                <a
                                  href="javascript:void(0)"
                                  className="m-0"
                                  onClick={() =>
                                    showScheduleFollowActivityModal(
                                      "editLeadDetails"
                                    )
                                  }
                                >
                                  <img src="images/schedule_white.png" />
                                  Schedule New Activity
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  className="Create mx-3 mb-0 FolderPermissionId"
                                  onClick={() => showNewEmailModal("details")}
                                >
                                  <img src="images/new_mail.png" />
                                  New Email
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  className="m-0 FolderPermissionId"
                                  onClick={() => showNewTextModal("edit")}
                                >
                                  <img src="images/new_text.png" />
                                  New Text
                                </a>
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <th />
                            <th>Type</th>
                            <th>Sent By</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {followUpListData?.listFollowUps?.map((ele, i) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div className="ActionsWidth">
                                      <img src="images/meeting_icon.png" />
                                    </div>
                                  </td>
                                  <td>{ele?.sendType}</td>
                                  <td>{ele?.sendBy}</td>
                                  <td>{ele?.createdAt?.split("T")[0]}</td>
                                  <td>{ele?.scheduleStatus}</td>
                                  <td>
                                    <div className="dropdown CursorPointer">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item FolderPermissionId"
                                            href="javascript:void(0);"
                                            onClick={() =>
                                              showEditFollowUpActivityModal(
                                                "discussion",
                                                ele?.LeadId,
                                                ele?.id
                                              )
                                            }
                                          >
                                            <img src="images/icon-15.png" />
                                            Edit Activity Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              cancelFollowUpActivity(
                                                ele?.LeadId,
                                                ele?.id
                                              )
                                            }
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/dropdown-delete.png" />
                                            Cancel Activity
                                          </a>
                                        </li>
                                        {/* <li>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                        onClick={() =>
                                          showAddItineraryModal("discussion")
                                        }
                                      >
                                        <img src="images/send_to_itinary.png" />
                                        Send to itinery
                                      </a>
                                    </li> */}
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/export-to-calendar.png" />
                                            Export to Calender
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuLead">
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleGoogleData(ele)
                                                }
                                                href="javascript:void(0);"
                                                className="dropdown-item"
                                              >
                                                <img src="images/google-calendar.png" />
                                                Google Calendar
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                className="dropdown-item"
                                                onClick={() =>
                                                  handleOutlookData(ele)
                                                }
                                              >
                                                <img src="images/outlook-calendar.png" />
                                                Outlook Calender
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                className="dropdown-item"
                                                onClick={() =>
                                                  handleYahooData(ele)
                                                }
                                              >
                                                <img src="images/email-calendar.png" />
                                                Yahoo Calender
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                            onClick={() =>
                                              showSetReminderModal(
                                                "discussion",
                                                ele?.LeadId,
                                                ele?.id,
                                                ele?.setReminder
                                              )
                                            }
                                          >
                                            <img src="images/set-reminder.png" />
                                            Set Reminder
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              sendInvitationFun(
                                                ele?.LeadId,
                                                ele?.id
                                              )
                                            }
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/send_invitaiton.png" />
                                            Send Invitation
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                            onClick={() =>
                                              showAssignSaleModal("discussion")
                                            }
                                          >
                                            <img src="images/assign_icon.png" />
                                            Assign Staff member
                                          </a>
                                        </li>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="PaginationArea">
                      <h6>Showing 1 to 10 of 27 entries</h6>
                      <h6>
                        <img src="images/leftarrow.png" /> 01{" "}
                        <img src="images/rightarrow.png" /> Out 10
                      </h6>
                    </div>
                    <div className="Button text-center">
                      <button className="Outline">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="DiscussionArea">
                <div class="DiscussionTitle">
                  <h4>Discussion</h4>
                  <span>{discussionList?.length}</span>
                  <div class="DiscussionChat">
                    <img
                      src={require("../assets/images/down.png")}
                      class="DiscussionIcon"
                    />
                    <a href="#">
                      <img src={require("../assets/images/pdf2.png")} />
                    </a>
                  </div>
                </div>
                <div class="DiscussionHideArea" style={{ display: "block" }}>
                  <div class="DiscussionBox">
                    <span>FM</span>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        value={discussionState?.message}
                        onChange={(e) =>
                          updateDiscussionState({
                            ...discussionState,
                            message: e.target.value,
                          })
                        }
                        placeholder="Add a Comment ....."
                      />
                      {discussionState.discussionError &&
                        !discussionState.message && (
                          <p style={{ color: "red" }}>
                            {discussionState.discussionError}
                          </p>
                        )}

                      <div class="DiscussionOverlay">
                        <a
                          onClick={() =>
                            updateDiscussionState({
                              ...discussionState,
                              message: "",
                            })
                          }
                          class="CancelChat"
                        >
                          <img
                            src={require("../assets/images/plus-cancel1.png")}
                          />
                        </a>
                        <a onClick={handleAddDiscussion} class="SendChat">
                          <img
                            src={require("../assets/images/forwardbutton.png")}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  {discussionList?.map((item, i) => (
                    <>
                      <div class="DiscussionBox">
                        <span>CO</span>
                        <div class="DiscussionRight">
                          <h6>{item?.message}</h6>
                          <small>{moment(item.updatedAt).format("lll")}</small>
                        </div>
                      </div>
                      <div class="ReplyArea">
                        <a
                          onClick={(e) => {
                            updateReplyField({ flag: true, index: i });
                            updateReplyState({ ...replyState, message: "" });
                          }}
                        >
                          Reply
                        </a>
                        {item?.ReplyDiscussions?.map((ele) => (
                          <div class="DiscussionBox">
                            <span>RE</span>
                            <div class="DiscussionRight">
                              <h6>{ele?.message}</h6>
                              <small>
                                {moment(ele.updatedAt).format("lll")}
                              </small>
                            </div>
                          </div>
                        ))}

                        {replyField.index == i && (
                          <div class="DiscussionBox w-100">
                            <span>FM{item?.id}</span>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                value={replyState?.message}
                                onChange={(e) =>
                                  updateReplyState({
                                    ...replyState,
                                    message: e.target.value,
                                    messageId: item?.id,
                                    jobId: item?.jobId,
                                  })
                                }
                                placeholder="Add reply ....."
                              />
                              {discussionState.discussionError &&
                                !discussionState.message && (
                                  <p style={{ color: "red" }}>
                                    {discussionState.discussionError}
                                  </p>
                                )}

                              <div class="DiscussionOverlay">
                                <a
                                  onClick={() =>
                                    updateReplyState({
                                      ...replyState,
                                      message: "",
                                    })
                                  }
                                  class="CancelChat"
                                >
                                  <img
                                    src={require("../assets/images/plus-cancel1.png")}
                                  />
                                </a>
                                <a onClick={handleAddReply} class="SendChat">
                                  <img
                                    src={require("../assets/images/forwardbutton.png")}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>

      {/*EDITCONTRACTSMODALSHOW  */}

      <Modal
        show={editContactInformationModal}
        onHide={hideEditContactInformationModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideEditContactInformationModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox GrayModalTitle">
              <h4>Update Contact</h4>
            </div>
            <div className="FolderForm">
              <div className="ResidentialJobArea ResidentialJobAreaPara">
                <div className="ModalAccordian">
                  <div id="accordion">
                    <div className="card">
                      <div
                        id="GenerationInformation"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <div className="CommonModalArea">
                                  <h5>Contact Information</h5>
                                  {/* <div className="CommonModalBox">
                                    <h5
                                      className="CursorPointer FolderPermissionId"
                                      title="import contact information"
                                      onClick={() =>
                                        showcontactInformationModal(
                                          "editContract"
                                        )
                                      }
                                    >
                                      <figure>
                                        <img src="images/upload_icon.png" />
                                      </figure>{" "}
                                      Import contact information
                                    </h5>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-5 col-md-12">
                              <div className="form-group">
                                <div className="ModalBigheading">
                                  <h3>
                                    <span>Personal Information</span>
                                  </h3>
                                </div>
                              </div>
                              <div className="form-group">
                                <h6>
                                  Client Full Legal name
                                  <span className="required">*</span>
                                </h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input client's full legal name"
                                  name="legalName"
                                  value={legalName}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.legalNameError}
                                </span>
                              </div>
                              <div className="form-group">
                                <h6>Country Code</h6>
                                <PhoneInput
                                  inputClass="CountryCodeInput "
                                  countryCodeEditable={false}
                                  enableSearch
                                  value={phonecode}
                                  onChange={handlePhoneInputChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <h6>
                                  Primary Phone Number
                                  <span className="required">*</span>
                                </h6>

                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input client's full legal name"
                                  name="primaryPhoneNum"
                                  value={primaryPhoneNum}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.primaryPhoneNumError}
                                </span>
                              </div>
                              <div className="form-group">
                                <h6>
                                  Email<span className="required">*</span>
                                </h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input email "
                                  name="email"
                                  value={email}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.emailError}
                                </span>
                              </div>
                              <div className="form-group">
                                <h6> Bssiness Country Code</h6>
                                <PhoneInput
                                  inputClass="CountryCodeInput "
                                  countryCodeEditable={false}
                                  enableSearch
                                  value={bussinessPhoneCode}
                                  onChange={handleBussinessPhoneInputChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <h6>Business Phone Number</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input client's full legal name"
                                  name="businessPhoneNum"
                                  value={businessPhoneNum}
                                  onChange={handleInputNumber}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.businessPhoneNumError}
                                </span>
                              </div>
                              <div className="form-group">
                                <h6>Fax Phone Number</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input fax number"
                                  name="faxPhoneNum"
                                  value={faxPhoneNum}
                                  onChange={handleInputNumber}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.faxPhoneNumError}
                                </span>
                              </div>
                              <div className="form-group">
                              <h6>Preferred Communication</h6>
                              <div class="CommonSelectBox w-100">
                            <select
                              name="preferredCommu"
                              value={preferredCommu}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Preference</option>
                              <option value="No Preference">No Preference
                                </option>
                                <option value="Phone">Phone</option>
                                <option value="Email">Email</option>
                            </select>
                          </div>
                          </div>
                            </div>

                            <div className="col-lg-5 col-md-12 ml-auto">
                              <div className="form-group">
                                <div className="ModalBigheading">
                                  <h3>
                                    <span>Address</span>
                                  </h3>
                                </div>
                              </div>
                              <div className="form-group">
                                <h6>Street Address</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input street name"
                                  name="streetAddress"
                                  value={streetAddress}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.streetAddressError}
                                </span>
                              </div>
                              <div className="form-group">
                                <h6>Address Line two</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input address line two"
                                  name="addressLineTwo"
                                  value={addressLineTwo}
                                  onChange={handleInput}
                                />
                                {/* <span style={{ color: "red", fontSize: "14px" }}>{leadErrors?.legalNameError}</span> */}
                              </div>
                              <div className="form-group">
                                <h6>
                                  Zip/Postal Code
                                  <span className="required">*</span>
                                </h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input postal name"
                                  name="zipCode"
                                  value={zipCode}
                                  onChange={handleInput}
                                />
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.zipCodeError}
                                </span>
                              </div>
                              <div class="form-group">
                          <h6>
                            Country<span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="country"
                              value={country}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Country</option>
                              {countryData?.countries?.length > 0 &&
                                countryData?.countries?.map((item, i) => {
                                  return (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <h6>
                            Province/State<span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="province"
                              value={province}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Province/State</option>
                              {stateData?.states?.length > 0 ? (
                                stateData?.states?.map((item, i) => {
                                  return (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option disabled>
                                  No Province/State Available
                                </option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <h6>
                            City<span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="city"
                              value={city}
                              onChange={handleInputChange}
                            >
                              <option value="">Select City</option>
                              {cityData?.cities?.length > 0 ? (
                                cityData?.cities?.map((item, i) => {
                                  return (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option disabled>No Cities Available</option>
                              )}
                            </select>
                          </div>
                        </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#GenerationInformation"
                      >
                        <h4>
                          <span>
                            <img src="images/generation_new.png" />
                          </span>
                          Generation Information
                        </h4>
                      </div>
                      <div
                        id="GenerationInformation"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-4 col-md-12">
                              <div className="CommonModalArea mb-lg-0 mb-4">
                                <h5>Assigned to</h5>
                                <div className="CommonModalBox">
                                  <h5
                                    className="CursorPointer FolderPermissionId"
                                    title="assign sales representative"
                                    onClick={() =>
                                      showAssignSaleModal("editContract",leaId)
                                    }
                                  >
                                    <figure>
                                      <img src="images/icon-32.png" />
                                    </figure>
                                    <figure>
                                      <img src="images/refresh.png" />
                                    </figure>
                                    {userInfo??"Select"}
                                  </h5>
                                </div>
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {leadErrors?.UserIdError}
                                </span>
                              </div>
                            </div>
                            <div class="form-group col-lg-4 col-md-12">
                          <h6>
                          SubDivision/Projects<span class="required">*</span>
                          </h6>
                          <div class="CommonSelectBox w-100">
                            <select
                              name="subDivision"
                              value={subDivision}
                              onChange={handleInputChange}
                            >
                              <option value="">Select SubDivision/Projects</option>
                              {subDivisionListData?.details?.length > 0 ? (
                                subDivisionListData?.details?.map((item, i) => {
                                  return (
                                    <option value={item}>
                                      {item}
                                    </option>
                                  );
                                })
                              ) : (
                                <option disabled>No SubDivision/Projetcs Available</option>
                              )}
                            </select>
                          </div>
                        </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <h6 className="mb-4">Realtor/Referrer</h6>
                            </div>
                            <div className="form-group col-lg-4 col-md-12">
                              <h6>Name</h6>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Input Name"
                                name="referrerName"
                                value={referrerName}
                                onChange={handleInput}
                              />
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {leadErrors?.referrerNameError}
                              </span>
                            </div>
                            <div className="form-group col-lg-4 col-md-12">
                              <h6>Agency</h6>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Input Agency"
                                name="referrerAgency"
                                value={referrerAgency}
                                onChange={handleInput}
                              />
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {leadErrors?.referrerAgencyError}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-lg-4 col-md-12 mr-auto">
                              <h6>Email</h6>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Input Email"
                                name="referrerEmail"
                                value={referrerEmail}
                                onChange={handleInput}
                              />
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {leadErrors?.referrerEmailError}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
        
                    <div className="Button d-flex justify-content-between">
                      <button className="Create" onClick={updateContractFunc}>
                        Update Contact
                      </button>
                      <button
                        className="Outline"
                        onClick={hideEditContactInformationModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADDSURVEYSHOW */}
      <Modal
        show={addSurveyModal}
        onHide={hideAddSurveyModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddSurveyModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Survey</h4>
            </div>
            <div className="JobTitleArea">
              <div className="JobBox">
                <h6>
                  <span>Status:</span> New
                </h6>
                <h6>
                  <span>Last Update:</span> 06/062023
                </h6>
              </div>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="form-group col-lg-6">
                  <div className="Categories Categories2 mb-4">
                    <h6>Lead Source</h6>
                    <div className="Categories_all Categories_all2">
                      <select
                        name="leadSource"
                        value={leadSource}
                        onChange={handleInput}
                      >
                        <option>Choose</option>
                        <option value="Not Applicable/No Answer">
                          {" "}
                          Not Applicable/No Answer
                        </option>
                        <option value="Newspaper AD">Newspaper AD</option>
                        <option value={"TV AD"}>TV AD</option>
                        <option value={"Radio AD"}>Radio AD</option>
                        <option value={"Signage"}>Signage</option>
                        <option value="Internet">Internet</option>
                        <option value={"Passing By"}>Passing By</option>
                        <option value={"Other"}>Other</option>
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {surveyErrors?.leadSourceError}
                    </span>
                  </div>
                </div>

                <div className="form-group col-lg-6">
                  <div className="Categories Categories2 mb-4">
                    <h6>When Planning to Purchase</h6>

                    <div className="Categories_all Categories_all2">
                      <select
                        name="purchasePlaning"
                        value={purchasePlaning}
                        onChange={handleInput}
                      >
                        <option>Choose</option>
                        <option value="Not Applicable">Not Applicable</option>
                        <option value="Now">Now</option>
                        <option value={"1-3"}>1-3 months</option>
                        <option value={"3-6"}>3-5 months</option>
                        <option value={"6-12"}>6-12 months</option>
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {surveyErrors?.purchasePlaningError}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <div className="Categories Categories2 mb-4">
                    <h6>Current Home Style</h6>
                    <div className="Categories_all Categories_all2">
                      <select
                        name="currentHomeStyle"
                        value={currentHomeStyle}
                        onChange={handleInput}
                      >
                        <option>Choose</option>
                        <option value="Not Applicable/No Answer">
                          {" "}
                          Not Applicable/No Answer
                        </option>
                        <option value="2 Story">2 Story</option>
                        <option value={"By-Level"}>By-Level</option>
                        <option value={"Bunglow"}>Bunglow</option>
                        <option value={"Duplex"}>Duplex</option>
                        <option value="Townhouse">Townhouse</option>
                        <option value={"Apartment"}>Apartment</option>
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {surveyErrors?.currentHomeStyleError}
                    </span>
                  </div>
                </div>

                <div className="form-group col-lg-6">
                  <h6>Preferred Price</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="preferredPrice"
                          value={preferredPrice}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            {" "}
                            Not Applicable/No Answer
                          </option>
                          <option value="Up to $200,000">Up to $200,000</option>
                          <option value={"$200,000 - $400,000"}>
                            $200,000 - $400,000
                          </option>
                          <option value={"$400,000 - $1,000,000"}>
                            $400,000 – $1,000,000
                          </option>
                          <option value="Over $1,000,000">
                            Over $1,000,000
                          </option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.preferredPriceError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <h6>Current Home Size</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="currentHomeSize"
                          value={currentHomeSize}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            {" "}
                            Not Applicable/No Answer
                          </option>
                          <option value="500 - 1000 Sq/Ft">
                            500 - 1000 Sq/Ft
                          </option>
                          <option value={"1001 - 1500 Sq/Ft"}>
                            1001 - 1500 Sq/Ft
                          </option>
                          <option value={"1501 - 2000 Sq/Ft"}>
                            1501 - 2000 Sq/Ft
                          </option>
                          <option value="2001 - 2500 Sq/Ft">
                            2001 - 2500 Sq/Ft
                          </option>
                          <option value="Larger than 2500 Sq/Ft">
                            Larger than 2500 Sq/Ft
                          </option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.currentHomeSizeError}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group col-lg-6">
                  <h6>Reason to Move</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="reasonToMove"
                          value={reasonToMove}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            Not Applicable/No Answer
                          </option>
                          <option value="Larger Home">Larger Home</option>
                          <option value={"Smaller Home"}>Smaller Home</option>
                          <option value={"Change of Communit"}>
                            Change of Communit
                          </option>
                          <option value="Features">Features</option>
                          <option value="Affordability">Affordability</option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.reasonToMoveError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-lg-6">
                  <h6>Seeking Home Size</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="seekingHomeSize"
                          value={seekingHomeSize}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            Not Applicable/No Answer
                          </option>
                          <option value="500 - 1000 Sq/F">
                            500 - 1000 Sq/F
                          </option>
                          <option value={"1001 - 1500 Sq/Ft"}>
                            1001 - 1500 Sq/Ft
                          </option>
                          <option value={"1501 - 2000 Sq/Ft"}>
                            1501 - 2000 Sq/Ft
                          </option>
                          <option value="2001 - 2500 Sq/Ft">
                            2001 - 2500 Sq/Ft
                          </option>
                          <option value="Larger than 2500 Sq/Ft">
                            Larger than 2500 Sq/Ft
                          </option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.seekingHomeSizeError}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group col-lg-6">
                  <h6>Require Realtor Assistance</h6>
                  <label className="CheckBox">
                    <input
                      type="checkbox"
                      name="realtorAssistance"
                      value={realtorAssistance}
                      onChange={handleCheckbox}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <h6>Seeking Home Style</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="seekingHomeStyle"
                          value={seekingHomeStyle}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            Not Applicable/No Answer
                          </option>
                          <option value="2 Story">2 Story</option>
                          <option value="By-Level">By-Level</option>
                          <option value="Bunglow">Bunglow</option>
                          <option value="Duplex">Duplex</option>
                          <option value="Townhouse">Townhouse</option>
                          <option value="Apartment">Apartment</option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.seekingHomeStyleError}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group col-lg-6">
                  <h6>Require Mortgage Assistance</h6>
                  <label className="CheckBox">
                    <input
                      type="checkbox"
                      name="mortgageAssistance"
                      value={mortgageAssistance}
                      onChange={handleCheckbox}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <h6>Seeking Home Site</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="seekingHomeSite"
                          value={seekingHomeSite}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            Not Applicable/No Answer
                          </option>
                          <option value="Level">Level</option>
                          <option value={"Walk-Out"}>Walk-Out</option>
                          <option value={"Pie"}>Pie</option>
                          <option value="Corner">Corner</option>
                          <option value="Green Space">Green Space</option>
                          <option value="Acreage">Acreage</option>
                          <option value="Other">Other</option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.seekingHomeSiteError}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group col-lg-6">
                  <h6>Requested more info</h6>
                  <label className="CheckBox">
                    <input
                      type="checkbox"
                      name="moreInfo"
                      value={moreInfo}
                      onChange={handleCheckbox}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <h6>How Long Been Searching</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="beenSearching"
                          value={beenSearching}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            Not Applicable/No Answer
                          </option>
                          <option value="Just Started">Just Started</option>
                          <option value={"1 Month"}>1 Month</option>
                          <option value={"6-12 Months"}>6-12 Months</option>
                          <option value="Longer">Longer</option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.beenSearchingError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-3">
                <button onClick={handleAddSurvey} className="Create">
                  Save
                </button>
                <button className="Create">Reopen Survey</button>
                <button className="Outline">Cancel</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDITSURVEYSHOW */}
      <Modal
        show={editSurveyModal}
        onHide={hideEditSurveyModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideEditSurveyModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Survey</h4>
            </div>
            <div className="JobTitleArea">
              <div className="JobBox">
                <h6>
                  <span>Status:</span> New
                </h6>
                <h6>
                  <span>Last Update:</span>
                  {moment(updateDate).format("YYYY-MM-DD")}
                </h6>
              </div>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="form-group col-lg-6">
                  <div className="Categories Categories2 mb-4">
                    <h6>Lead Source</h6>
                    <div className="Categories_all Categories_all2">
                      <select
                        name="leadSource"
                        value={leadSource}
                        onChange={handleInput}
                      >
                        <option>Choose</option>
                        <option value="Not Applicable/No Answer">
                          {" "}
                          Not Applicable/No Answer
                        </option>
                        <option value="Newspaper AD">Newspaper AD</option>
                        <option value={"TV AD"}>TV AD</option>
                        <option value={"Radio AD"}>Radio AD</option>
                        <option value={"Signage"}>Signage</option>
                        <option value="Internet">Internet</option>
                        <option value={"Passing By"}>Passing By</option>
                        <option value={"Other"}>Other</option>
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {surveyErrors?.leadSourceError}
                    </span>
                  </div>
                </div>

                <div className="form-group col-lg-6">
                  <div className="Categories Categories2 mb-4">
                    <h6>When Planning to Purchase</h6>

                    <div className="Categories_all Categories_all2">
                      <select
                        name="purchasePlaning"
                        value={purchasePlaning}
                        onChange={handleInput}
                      >
                        <option>Choose</option>
                        <option value="Not Applicable">Not Applicable</option>
                        <option value="Now">Now</option>
                        <option value={"1-3"}>1-3 months</option>
                        <option value={"3-6"}>3-5 months</option>
                        <option value={"6-12"}>6-12 months</option>
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {surveyErrors?.purchasePlaningError}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <div className="Categories Categories2 mb-4">
                    <h6>Current Home Style</h6>
                    <div className="Categories_all Categories_all2">
                      <select
                        name="currentH"
                        value={currentHomeStyle}
                        onChange={handleInput}
                      >
                        <option>Choose</option>
                        <option value="Not Applicable/No Answer">
                          {" "}
                          Not Applicable/No Answer
                        </option>
                        <option value="2 Story">2 Story</option>
                        <option value={"By-Level"}>By-Level</option>
                        <option value={"Bunglow"}>Bunglow</option>
                        <option value={"Duplex"}>Duplex</option>
                        <option value="Townhouse">Townhouse</option>
                        <option value={"Apartment"}>Apartment</option>
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {surveyErrors?.currentHomeStyle}
                    </span>
                  </div>
                </div>

                <div className="form-group col-lg-6">
                  <h6>Preferred Price</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="preferredPrice"
                          value={preferredPrice}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            {" "}
                            Not Applicable/No Answer
                          </option>
                          <option value="Up to $200,000">Up to $200,000</option>
                          <option value={"$200,000 - $400,000"}>
                            $200,000 - $400,000
                          </option>
                          <option value={"400,000 – $1,000,000"}>
                            $400,000 – $1,000,000
                          </option>
                          <option value="Over $1,000,000">
                            Over $1,000,000
                          </option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.preferredPriceError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <h6>Current Home Size</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="currentHomeSize"
                          value={currentHomeSize}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            {" "}
                            Not Applicable/No Answer
                          </option>
                          <option value="500 - 1000 Sq/Ft">
                            500 - 1000 Sq/Ft
                          </option>
                          <option value={"1001 - 1500 Sq/Ft"}>
                            1001 - 1500 Sq/Ft
                          </option>
                          <option value={"1501 - 2000 Sq/Ft"}>
                            1501 - 2000 Sq/Ft
                          </option>
                          <option value="2001 - 2500 Sq/Ft">
                            2001 - 2500 Sq/Ft
                          </option>
                          <option value="Larger than 2500 Sq/Ft">
                            Larger than 2500 Sq/Ft
                          </option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.currentHomeSizeError}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group col-lg-6">
                  <h6>Reason to Move</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="reasonToMove"
                          value={reasonToMove}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            Not Applicable/No Answer
                          </option>
                          <option value="Larger Home">Larger Home</option>
                          <option value={"Smaller Home"}>Smaller Home</option>
                          <option value={"Change of Communit"}>
                            Change of Communit
                          </option>
                          <option value="Features">Features</option>
                          <option value="Affordability">Affordability</option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.reasonToMoveError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-lg-6">
                  <h6>Seeking Home Size</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="seekingHomeSize"
                          value={seekingHomeSize}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            Not Applicable/No Answer
                          </option>
                          <option value="500 - 1000 Sq/F">
                            500 - 1000 Sq/F
                          </option>
                          <option value={"1001 - 1500 Sq/Ft"}>
                            1001 - 1500 Sq/Ft
                          </option>
                          <option value={"1501 - 2000 Sq/Ft"}>
                            1501 - 2000 Sq/Ft
                          </option>
                          <option value="2001 - 2500 Sq/Ft">
                            2001 - 2500 Sq/Ft
                          </option>
                          <option value="Larger than 2500 Sq/Ft">
                            Larger than 2500 Sq/Ft
                          </option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.seekingHomeSizeError}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group col-lg-6">
                  <h6>Require Realtor Assistance</h6>
                  <label className="CheckBox">
                    <input
                      type="checkbox"
                      name="realtorAssistance"
                      value={realtorAssistance}
                      onChange={handleCheckbox}
                      checked={realtorAssistance}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <h6>Seeking Home Style</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="seekingHomeStyle"
                          value={seekingHomeStyle}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            Not Applicable/No Answer
                          </option>
                          <option value="2 Story">2 Story</option>
                          <option value="By-Level">By-Level</option>
                          <option value="Bunglow">Bunglow</option>
                          <option value="Duplex">Duplex</option>
                          <option value="Townhouse">Townhouse</option>
                          <option value="Apartment">Apartment</option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.seekingHomeStyleError}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group col-lg-6">
                  <h6>Require Mortgage Assistance</h6>
                  <label className="CheckBox">
                    <input
                      type="checkbox"
                      name="mortgageAssistance"
                      value={mortgageAssistance}
                      onChange={handleCheckbox}
                      checked={mortgageAssistance}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <h6>Seeking Home Site</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="seekingHomeSite"
                          value={seekingHomeSite}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            Not Applicable/No Answer
                          </option>
                          <option value="Level">Level</option>
                          <option value={"Walk-Out"}>Walk-Out</option>
                          <option value={"Pie"}>Pie</option>
                          <option value="Corner">Corner</option>
                          <option value="Green Space">Green Space</option>
                          <option value="Acreage">Acreage</option>
                          <option value="Other">Other</option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.seekingHomeSiteError}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group col-lg-6">
                  <h6>Requested more info</h6>
                  <label className="CheckBox">
                    <input
                      type="checkbox"
                      name="moreInfo"
                      value={moreInfo}
                      onChange={handleCheckbox}
                      checked={moreInfo}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <h6>How Long Been Searching</h6>
                  <div className="form-group col-lg-6">
                    <div className="Categories Categories2 mb-4">
                      <div className="Categories_all Categories_all2">
                        <select
                          name="beenSearching"
                          value={beenSearching}
                          onChange={handleInput}
                        >
                          <option>Choose</option>
                          <option value="Not Applicable/No Answer">
                            Not Applicable/No Answer
                          </option>
                          <option value="Just Started">Just Started</option>
                          <option value={"1 Month"}>1 Month</option>
                          <option value={"6-12 Months"}>6-12 Months</option>
                          <option value="Longer">Longer</option>
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {surveyErrors?.beenSearchingError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-3">
                <button onClick={handleUpdateSurvey} className="Create">
                  Save
                </button>
                <button className="Create">Reopen Survey</button>
                <button onClick={handleUpdateSurvey} className="Outline">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* SUPPORTINGSHOW */}
      <Modal
        show={supportingDocsModal}
        onHide={hideSupportingDocsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportingDocsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Supporting Docs</h4>
            </div>
            <div className="JobTitleArea">
              <h3>Supporting Documents #2</h3>
              <div className="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Origin</span> #185
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Item:</span> #143
                </h6>
              </div>
            </div>
            <div className="form-group">
              <h6>Select Files</h6>
              <div className="Upload">
                <span>-Drag and Drop Files or click to select</span>
                <input type="file" name="files" onChange={handleInputFile} />
              </div>
              <span style={{ color: "red", fontSize: "14px" }}>
                {supportingDocsError}
              </span>
            </div>
            {/* <div className="FilesAddedTitle">
              <h4>Files</h4>
            </div> */}
            {/* <div className="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" className="cross" />
              </span>
            </div>
            <div className="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" className="cross" />
              </span>
            </div> */}
            <div className="Button mt-3">
              <button className="Create" onClick={addSupportingDocsFunc}>
                Add Documents
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDITACTIONLEADDETAILSSHOW */}

      <Modal
        show={editActionLeadDetailsModal}
        onHide={hideActionEditDetailsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideActionEditDetailsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Lead Details</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="ContactInformationArea">
                      <h6>Contact Information</h6>
                      <div className="ContactInformationBox">
                        <span className="circle">JL</span>
                        <div className="ContactInformationName">
                          <h6>John Lead</h6>
                          <p>
                            312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                            <img src="images/location.png" title="location" />
                          </p>
                          <p>
                            Phone: <span>(403)555-555</span>
                          </p>
                          <p>
                            Email: <span>John.lead@email.com</span>
                          </p>
                        </div>
                        <div className="ContactInformationIcon">
                          <a href="javascript:void(0);" title="pdf">
                            <img src="images/pdf.png" />
                          </a>
                          <a href="javascript:void(0);" title="download">
                            <img src="images/download.png" />
                          </a>
                          <a
                            href="javascript:void(0);"
                            title="client documentation"
                          >
                            <img src="images/icon-75.png" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="TodosButtons mt-5">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#GeneralInformationLead"
                      >
                        <img src="images/general_information_red.png" />
                        General Information
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#FollowUpActivity"
                      >
                        <img src="images/timer-red.png" />
                        Follow up Activity<span>3</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="GeneralInformationLead"
                  >
                    <div className="LeadDetailsInput mt-4">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="CommonModalArea">
                            <div className="form-group">
                              <h5>Assigned to</h5>
                              <div className="CommonModalBox">
                                <h5
                                  className="CursorPointer FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#AssignSalesRepresentative"
                                  data-dismiss="modal"
                                >
                                  <figure>
                                    <img
                                      src="images/icon-32.png"
                                      title="assign sales representative"
                                    />
                                  </figure>
                                  <figure>
                                    <img src="images/refresh.png" />
                                  </figure>
                                  John Doe
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <h6>Sub Division</h6>
                            <div className="Categories Categories3">
                              <div className="Categories_all Categories_all3">
                                <span>Select lead status</span>
                                <img src="images/down.png" />
                              </div>
                              <ul>
                                <li>-New</li>
                                <li>-In progress</li>
                                <li>-Interested</li>
                                <li>-Not Interested</li>
                                <li>-Cancelled</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="CommonModalArea">
                            <div className="form-group">
                              <h5>Survey</h5>
                              <div className="CommonModalBox">
                                <h5 className="">
                                  <figure>
                                    <img src="images/survey.png" />
                                  </figure>
                                  <span className="Count">3</span>John Doe
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="CommonModalArea">
                            <h5>Lead Age</h5>
                            <div className="CommonModalBox">
                              <h5 className="">&lt; 1day</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <h6>Status</h6>
                            <div className="Categories Categories2">
                              <div className="Categories_all Categories_all2">
                                <span>Select lead status</span>
                                <img src="images/down.png" />
                              </div>
                              <ul>
                                <li>-New</li>
                                <li>-In progress</li>
                                <li>-Interested</li>
                                <li>-Not Interested</li>
                                <li>-Cancelled</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <h6>Confidence Level</h6>
                            <div className="Categories Categories1">
                              <div className="Categories_all Categories_all1">
                                <span>Select confidence level</span>
                                <img src="images/down.png" />
                              </div>
                              <ul>
                                <li>-Low</li>
                                <li>-Medium</li>
                                <li>-High</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <h6 className="mb-4">Realtor/Referrer</h6>
                        </div>
                        <div className="form-group col-lg-5">
                          <h6>Name</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Name"
                          />
                        </div>
                        <div className="form-group col-lg-5 ml-auto">
                          <h6>Agency</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Agency"
                          />
                        </div>
                        <div className="form-group col-lg-5">
                          <h6>Email</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Email"
                          />
                        </div>
                      </div>
                      <div className="Button d-flex justify-content-between">
                        <button className="Create">Save</button>
                        <button className="Create">
                          Convert lead to Contract
                        </button>
                        <button className="Outline">Cancel</button>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="FollowUpActivity">
                    <div className="TableList CommonHeading mt-4">
                      <table>
                        <thead>
                          <tr>
                            <th colSpan={6}>
                              <div className="TodosButtons mt-0 d-flex align-items-center justify-content-center">
                                <a
                                  href="javascript:void(0)"
                                  className="m-0"
                                  data-toggle="modal"
                                  data-target="#ScheduleNewFollowUpActivity"
                                  data-dismiss="modal"
                                >
                                  <img src="images/schedule_white.png" />
                                  Schedule New Activity
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  className="Create mx-3 FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#NewEmail"
                                  data-dismiss="modal"
                                >
                                  <img src="images/new_mail.png" />
                                  New Email
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  className="m-0 FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#NewText"
                                  data-dismiss="modal"
                                >
                                  <img src="images/new_text.png" />
                                  New Text
                                </a>
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <th />
                            <th>Type</th>
                            <th>Sent By</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="ActionsWidth">
                                <img src="images/meeting_icon.png" />
                              </div>
                            </td>
                            <td>Meeting</td>
                            <td>John Doe</td>
                            <td>06/12/2023 10:30 AM</td>
                            <td>Scheduled</td>
                            <td>
                              <div className="dropdown CursorPointer">
                                <p data-toggle="dropdown">
                                  <span>
                                    <img src="images/three-dots.png" />
                                  </span>
                                </p>
                                <div
                                  className="dropdown-menu dropdown-menuwidth2"
                                  aria-labelledby="dropdownMenuOffset"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#EditFollowUpActivity"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/icon-15.png" />
                                      Edit Activity Details
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/dropdown-delete.png" />
                                      Cancel Activity
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewItineraryItemFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/send_to_itinary.png" />
                                      Send to itinery
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/export-to-calendar.png" />
                                      Export to Calender
                                      <img src="images/rightarrow2.png" />
                                    </a>
                                    <ul className="dropdown-menu submenu submenuLead">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/google-calendar.png" />
                                          Google Calendar
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/outlook-calendar.png" />
                                          Outlook Calender
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/email-calendar.png" />
                                          Email Calender
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#ReminderFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/set-reminder.png" />
                                      Set Reminder
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/send_invitaiton.png" />
                                      Send Invitation
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AssignStaffMemberFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/assign_icon.png" />
                                      Assign Staff member
                                    </a>
                                  </li>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ActionsWidth">
                                <img src="images/call.png" />
                              </div>
                            </td>
                            <td>Call</td>
                            <td>John Doe</td>
                            <td>06/12/2023 10:30 AM</td>
                            <td>Scheduled</td>
                            <td>
                              <div className="dropdown CursorPointer">
                                <p data-toggle="dropdown">
                                  <span>
                                    <img src="images/three-dots.png" />
                                  </span>
                                </p>
                                <div
                                  className="dropdown-menu dropdown-menuwidth2"
                                  aria-labelledby="dropdownMenuOffset"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#EditFollowUpActivity"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/icon-15.png" />
                                      Edit Activity Details
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/dropdown-delete.png" />
                                      Cancel Activity
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewItineraryItemFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/send_to_itinary.png" />
                                      Send to itinery
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/export-to-calendar.png" />
                                      Export to Calender
                                      <img src="images/rightarrow2.png" />
                                    </a>
                                    <ul className="dropdown-menu submenu submenuLead">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/google-calendar.png" />
                                          Google Calendar
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/outlook-calendar.png" />
                                          Outlook Calender
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/email-calendar.png" />
                                          Email Calender
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#ReminderFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/set-reminder.png" />
                                      Set Reminder
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/send_invitaiton.png" />
                                      Send Invitation
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AssignStaffMemberFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/assign_icon.png" />
                                      Assign Staff member
                                    </a>
                                  </li>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ActionsWidth">
                                <img src="images/message.png" />
                              </div>
                            </td>
                            <td>Text</td>
                            <td>John Doe</td>
                            <td>06/12/2023 10:30 AM</td>
                            <td>Start</td>
                            <td>
                              <div className="dropdown CursorPointer">
                                <p data-toggle="dropdown">
                                  <span>
                                    <img src="images/three-dots.png" />
                                  </span>
                                </p>
                                <div
                                  className="dropdown-menu dropdown-menuwidth2"
                                  aria-labelledby="dropdownMenuOffset"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#EditFollowUpActivity"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/icon-15.png" />
                                      Edit Activity Details
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/dropdown-delete.png" />
                                      Cancel Activity
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewItineraryItemFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/send_to_itinary.png" />
                                      Send to itinery
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/export-to-calendar.png" />
                                      Export to Calender
                                      <img src="images/rightarrow2.png" />
                                    </a>
                                    <ul className="dropdown-menu submenu submenuLead">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/google-calendar.png" />
                                          Google Calendar
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/outlook-calendar.png" />
                                          Outlook Calender
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/email-calendar.png" />
                                          Email Calender
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#ReminderFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/set-reminder.png" />
                                      Set Reminder
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/send_invitaiton.png" />
                                      Send Invitation
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AssignStaffMemberFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/assign_icon.png" />
                                      Assign Staff member
                                    </a>
                                  </li>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ActionsWidth">
                                <img src="images/send-email.png" />
                              </div>
                            </td>
                            <td>Email</td>
                            <td>John Doe</td>
                            <td>06/12/2023 10:30 AM</td>
                            <td>Received</td>
                            <td>
                              <div className="dropdown CursorPointer">
                                <p data-toggle="dropdown">
                                  <span>
                                    <img src="images/three-dots.png" />
                                  </span>
                                </p>
                                <div
                                  className="dropdown-menu dropdown-menuwidth2"
                                  aria-labelledby="dropdownMenuOffset"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#EditFollowUpActivity"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/icon-15.png" />
                                      Edit Activity Details
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/dropdown-delete.png" />
                                      Cancel Activity
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewItineraryItemFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/send_to_itinary.png" />
                                      Send to itinery
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/export-to-calendar.png" />
                                      Export to Calender
                                      <img src="images/rightarrow2.png" />
                                    </a>
                                    <ul className="dropdown-menu submenu submenuLead">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/google-calendar.png" />
                                          Google Calendar
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/outlook-calendar.png" />
                                          Outlook Calender
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/email-calendar.png" />
                                          Email Calender
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/set-reminder.png" />
                                      Set Reminder
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/send_invitaiton.png" />
                                      Send Invitation
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AssignStaffMemberFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/assign_icon.png" />
                                      Assign Staff member
                                    </a>
                                  </li>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ActionsWidth">
                                <img src="images/discussion_icon.png" />
                              </div>
                            </td>
                            <td>Discussion message</td>
                            <td>John Doe</td>
                            <td>06/12/2023 10:30 AM</td>
                            <td>Received</td>
                            <td>
                              <div className="dropdown CursorPointer">
                                <p data-toggle="dropdown">
                                  <span>
                                    <img src="images/three-dots.png" />
                                  </span>
                                </p>
                                <div
                                  className="dropdown-menu dropdown-menuwidth2"
                                  aria-labelledby="dropdownMenuOffset"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#EditFollowUpActivity"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/icon-15.png" />
                                      Edit Activity Details
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/dropdown-delete.png" />
                                      Cancel Activity
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewItineraryItemFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/send_to_itinary.png" />
                                      Send to itinery
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/export-to-calendar.png" />
                                      Export to Calender
                                      <img src="images/rightarrow2.png" />
                                    </a>
                                    <ul className="dropdown-menu submenu submenuLead">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/google-calendar.png" />
                                          Google Calendar
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/outlook-calendar.png" />
                                          Outlook Calender
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/email-calendar.png" />
                                          Email Calender
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#ReminderFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/set-reminder.png" />
                                      Set Reminder
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/send_invitaiton.png" />
                                      Send Invitation
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AssignStaffMemberFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/assign_icon.png" />
                                      Assign Staff member
                                    </a>
                                  </li>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ActionsWidth">
                                <img src="images/discussion_icon.png" />
                              </div>
                            </td>
                            <td>Discussion message</td>
                            <td>John Doe</td>
                            <td>06/12/2023 10:30 AM</td>
                            <td>Sent</td>
                            <td>
                              <div className="dropdown CursorPointer">
                                <p data-toggle="dropdown">
                                  <span>
                                    <img src="images/three-dots.png" />
                                  </span>
                                </p>
                                <div
                                  className="dropdown-menu dropdown-menuwidth2"
                                  aria-labelledby="dropdownMenuOffset"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#EditFollowUpActivity"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/icon-15.png" />
                                      Edit Activity Details
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/dropdown-delete.png" />
                                      Cancel Activity
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewItineraryItemFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/send_to_itinary.png" />
                                      Send to itinery
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/export-to-calendar.png" />
                                      Export to Calender
                                      <img src="images/rightarrow2.png" />
                                    </a>
                                    <ul className="dropdown-menu submenu submenuLead">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/google-calendar.png" />
                                          Google Calendar
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/outlook-calendar.png" />
                                          Outlook Calender
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/email-calendar.png" />
                                          Email Calender
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#ReminderFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/set-reminder.png" />
                                      Set Reminder
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/send_invitaiton.png" />
                                      Send Invitation
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AssignStaffMemberFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/assign_icon.png" />
                                      Assign Staff member
                                    </a>
                                  </li>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="ActionsWidth">
                                <img src="images/send-email.png" />
                              </div>
                            </td>
                            <td>Email</td>
                            <td>John Doe</td>
                            <td>06/12/2023 10:30 AM</td>
                            <td>Sent</td>
                            <td>
                              <div className="dropdown CursorPointer">
                                <p data-toggle="dropdown">
                                  <span>
                                    <img src="images/three-dots.png" />
                                  </span>
                                </p>
                                <div
                                  className="dropdown-menu dropdown-menuwidth2"
                                  aria-labelledby="dropdownMenuOffset"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#EditFollowUpActivity"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/icon-15.png" />
                                      Edit Activity Details
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/dropdown-delete.png" />
                                      Cancel Activity
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewItineraryItemFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/send_to_itinary.png" />
                                      Send to itinery
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/export-to-calendar.png" />
                                      Export to Calender
                                      <img src="images/rightarrow2.png" />
                                    </a>
                                    <ul className="dropdown-menu submenu submenuLead">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/google-calendar.png" />
                                          Google Calendar
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/outlook-calendar.png" />
                                          Outlook Calender
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          <img src="images/email-calendar.png" />
                                          Email Calender
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#ReminderFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/set-reminder.png" />
                                      Set Reminder
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/send_invitaiton.png" />
                                      Send Invitation
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AssignStaffMemberFollowUp"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/assign_icon.png" />
                                      Assign Staff member
                                    </a>
                                  </li>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="PaginationArea">
                      <h6>Showing 1 to 10 of 27 entries</h6>
                      <h6>
                        <img src="images/leftarrow.png" /> 01{" "}
                        <img src="images/rightarrow.png" /> Out 10
                      </h6>
                    </div>
                    <div className="Button text-center">
                      <button
                        onClick={hideActionEditDetailsModal}
                        className="Outline"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <hr />
            <div class="DiscussionArea">
              <div class="DiscussionTitle">
                <h4>Discussion</h4>
                <span>{discussionList?.length}</span>
                <div class="DiscussionChat">
                  <img
                    src={require("../assets/images/down.png")}
                    class="DiscussionIcon"
                  />
                  <a href="#">
                    <img src={require("../assets/images/pdf2.png")} />
                  </a>
                </div>
              </div>
              <div class="DiscussionHideArea" style={{ display: "block" }}>
                <div class="DiscussionBox">
                  <span>FM</span>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      value={discussionState?.message}
                      onChange={(e) =>
                        updateDiscussionState({
                          ...discussionState,
                          message: e.target.value,
                        })
                      }
                      placeholder="Add a Comment ....."
                    />
                    {discussionState.discussionError &&
                      !discussionState.message && (
                        <p style={{ color: "red" }}>
                          {discussionState.discussionError}
                        </p>
                      )}

                    <div class="DiscussionOverlay">
                      <a
                        onClick={() =>
                          updateDiscussionState({
                            ...discussionState,
                            message: "",
                          })
                        }
                        class="CancelChat"
                      >
                        <img
                          src={require("../assets/images/plus-cancel1.png")}
                        />
                      </a>
                      <a onClick={handleAddDiscussion} class="SendChat">
                        <img
                          src={require("../assets/images/forwardbutton.png")}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                {discussionList?.map((item, i) => (
                  <>
                    <div class="DiscussionBox">
                      <span>CO</span>
                      <div class="DiscussionRight">
                        <h6>{item?.message}</h6>
                        <small>{moment(item.updatedAt).format("lll")}</small>
                      </div>
                    </div>
                    <div class="ReplyArea">
                      <a
                        onClick={(e) => {
                          updateReplyField({ flag: true, index: i });
                          updateReplyState({ ...replyState, message: "" });
                        }}
                      >
                        Reply
                      </a>
                      {item?.ReplyDiscussions?.map((ele) => (
                        <div class="DiscussionBox">
                          <span>RE</span>
                          <div class="DiscussionRight">
                            <h6>{ele?.message}</h6>
                            <small>{moment(ele.updatedAt).format("lll")}</small>
                          </div>
                        </div>
                      ))}

                      {replyField.index == i && (
                        <div class="DiscussionBox w-100">
                          <span>FM{item?.id}</span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={replyState?.message}
                              onChange={(e) =>
                                updateReplyState({
                                  ...replyState,
                                  message: e.target.value,
                                  messageId: item?.id,
                                  jobId: item?.jobId,
                                })
                              }
                              placeholder="Add reply ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddReply} class="SendChat">
                                <img
                                  src={require("../assets/images/forwardbutton.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* FOLLOWSCHEDULESHOW */}
      <Modal
        show={scheduleNewFollowModal}
        onHide={hideScheduleFollowActivityModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideScheduleFollowActivityModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Schedule New Follow up activity</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="d-flex justify-content-end">
                  <h6>Itinerary Item: #</h6>
                </div>
                <div className="row">
                  <div className="form-group col-lg-5">
                    <h6>Type</h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        name="sendType"
                        value={sendType}
                        onChange={handleInput}
                      >
                        <option selected="">Select Type</option>
                        <option value="Meeting">-Meeting</option>
                        <option value="Phone call">-Phone call</option>
                        <option value="Email">-Email</option>
                      </select>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {" "}
                        {discussionError?.sendTypeError}
                      </span>
                    </div>
                  </div>
                  <div className="form-group col-lg-5 ml-auto">
                    <h6>Location</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Location"
                      name="location"
                      value={location}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <h6>Date</h6>
                    {/* <input type="date" className="form-control" /> */}
                    <input
                      type="date"
                      class="form-control"
                      name="activityDate"
                      value={activityDate}
                      min={moment().format("YYYY-MM-DD")}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {discussionError?.activityDateError}
                    </span>
                  </div>
                  <div className="form-group col-lg-4">
                    <h6>From</h6>
                    <input
                      type="time"
                      className="form-control"
                      name="fromTime"
                      value={fromTime}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {discussionError?.fromTimeError}
                    </span>
                  </div>
                  <div className="form-group col-lg-4">
                    <h6>To</h6>
                    <input
                      type="time"
                      className="form-control"
                      name="toTime"
                      value={toTime}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {discussionError?.toTimeError}
                    </span>
                  </div>
                </div>
                <div className="CommonModalAreaMain align-items-start mt-2 flex-lg-row flex-column">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Set Reminder</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={() =>
                            showSetReminderModal("scheduleNewFollow")
                          }
                        >
                          <figure>
                            <img src="images/timer.png" title="set reminder" />
                          </figure>{" "}
                          {setReminder ? `${setReminder} min` : "Set Reminder"}
                        </h5>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {discussionError?.setReminderError}
                      </span>
                    </div>
                  </div>
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Assign Staff Member</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={() =>
                            showAssignSaleModal("scheduleNewFollow")
                          }
                        >
                          <figure>
                            <img
                              src="images/assign-resources.png"
                              title="assign staff member"
                            />
                          </figure>{" "}
                          <span className="Count">3</span> Assign
                        </h5>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {discussionError?.UnitId}
                      </span>
                    </div>
                  </div>
                  <div className="StaffMemberArea mt-0">
                    <div className="form-group">
                      <h6>Assign Staff member</h6>
                      <div className="StaffBox">
                        {emailData?.map((ele, i) => {
                          return (
                            <>
                              <div className="StaffName">
                                <p>{ele}</p>
                                <img src="images/Cross.png" title="canceling" />
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-center">
                  <button className="Create mr-2" onClick={addDiscussionFun}>
                    Schedule <img src="images/down-arrow-white.png" />
                  </button>
                  <button
                    className="Outline"
                    onClick={hideScheduleFollowActivityModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CONVERTLEADTOCONTRACTSHOW */}

      <Modal
        show={convertLeadToContractModal}
        onHide={hideConvertLeadToContractModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideConvertLeadToContractModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4 className="abc abc11 " style={{ display: "block" }}>
                General Information
              </h4>
              <h4 className="abc abc12">Conditional Details</h4>
              <h4 className="abc abc13">Adjustments and Deposits</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-lg-5">
                    <div className="ContactInformationArea">
                      <h6>Contact Information</h6>
                      <div className="ContactInformationBox">
                        <span className="circle">JL</span>
                        <div className="ContactInformationName">
                          <h6>John Lead</h6>
                          <p>
                            312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                            <img src="images/location.png" />
                          </p>
                          <p>
                            Phone: <span>(403)555-555</span>
                          </p>
                          <p>
                            Email: <span>John.lead@email.com</span>
                            <img src="images/fill_star.png" className="ml-2" />
                          </p>
                        </div>
                        <div className="ContactInformationIcon">
                          <a href="javascript:void(0);" title="pdf">
                            <img src="images/pdf.png" />
                          </a>
                          <a href="javascript:void(0);" title="download">
                            <img src="images/download.png" />
                          </a>
                          <a
                            href="javascript:void(0);"
                            title="client documentation"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ClientDocumentationModal"
                            data-dismiss="modal"
                          >
                            <img src="images/icon-75.png" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="TodosButtons mt-5">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active GeneralInformationBtnShow"
                        data-toggle="tab"
                        href="#GeneralInformation"
                      >
                        <img src="images/general_information_red.png" />
                        General Information
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link ConditionalDetailsBtnShow"
                        data-toggle="tab"
                        href="#ConditionalDetails"
                      >
                        <img src="images/conditional_details.png" />
                        Conditional Details<span>3</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link AdjustmentsAndDepositBtnShow"
                        data-toggle="tab"
                        href="#AdjustmentsAndDeposit"
                      >
                        <img src="images/adjustment_deposit.png" />
                        Adjustments and Deposits<span>3</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="LeadDetailsInput mt-4">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="GeneralInformation"
                    >
                      <div className="row">
                        <div className="col-md-3">
                          <div className="CommonModalArea">
                            <div className="form-group">
                              <h5>Assigned to</h5>
                              <div className="CommonModalBox">
                                <h5
                                  className="CursorPointer FolderPermissionId"
                                  title="assign respresentative"
                                  data-toggle="modal"
                                  data-target="#AssignSalesRepresentative2"
                                  data-dismiss="modal"
                                >
                                  <figure>
                                    <img src="images/icon-32.png" />
                                  </figure>
                                  <figure>
                                    <img src="images/refresh.png" />
                                  </figure>
                                  John Doe
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mx-auto">
                          <div className="form-group">
                            <h6>Sub Division</h6>
                            <div className="Categories Categories3">
                              <div className="Categories_all Categories_all3">
                                <span>Select SubDivision</span>
                                <img src="images/down.png" />
                              </div>
                              <ul>
                                <li>
                                  -List of Subdivisions offered by the builder
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 ml-auto">
                          <div className="CommonModalArea">
                            <div className="form-group">
                              <h5>Status</h5>
                              <div className="CommonModalBox">
                                <h5 className="">Interested</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <h6 className="mb-4">Realtor/Referrer</h6>
                        </div>
                        <div className="form-group col-lg-5">
                          <h6>Name</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Name"
                          />
                        </div>
                        <div className="form-group col-lg-5 ml-auto">
                          <h6>Agency</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Agency"
                          />
                        </div>
                        <div className="form-group col-lg-5">
                          <h6>Email</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Email"
                          />
                        </div>
                        <div className="col-12">
                          <div className="form-group ">
                            <div className="CommonModalArea">
                              <h5>Configure Client View</h5>
                              <div className="CommonModalBox">
                                <h5 className="">
                                  <figure>
                                    <img src="images/configure_client_view.png" />
                                  </figure>
                                  Configure Client View
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Button d-flex justify-content-between mt-4">
                        <button className="Create">Save</button>
                        <button className="Outline">Cancel</button>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="ConditionalDetails">
                      <div className="row">
                        <div className="col-lg-5 mx-auto">
                          <div className="form-group">
                            <h6>Sale Type</h6>
                            <div className="Categories Categories3">
                              <div className="Categories_all Categories_all3">
                                <span>Sale Type</span>
                                <img src="images/down.png" />
                              </div>
                              <ul>
                                <li>
                                  -List of sales type for that company loaded
                                  from the systems (for example Multi-Family,
                                  Residential)
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5 mx-auto">
                          <div className="form-group">
                            <h6>Client Assigned Unit</h6>
                            <div className="Categories Categories3">
                              <div className="Categories_all Categories_all3">
                                <span>Client Assigned Unit</span>
                                <img src="images/down.png" />
                              </div>
                              <ul>
                                <li>
                                  -List of available units (no client assined)
                                  loaded from the system based on the sale type
                                  selection
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-11 mx-auto">
                          <div className="Button d-flex justify-content-between mt-4">
                            <button
                              className="Create FolderPermissionId"
                              data-toggle="modal"
                              data-target="#ClientContractDetailsGreen"
                              data-dismiss="modal"
                            >
                              Assign Unit
                            </button>
                            <button className="Outline" data-dismiss="modal">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="AdjustmentsAndDeposit">
                      <div className="SchedulePaymentsTableMain">
                        <div className="TableHeader">
                          <h6 id="SchedulePaymentsBtn">
                            Schedule Payments{" "}
                            <img src="images/down-arrow.png" />
                          </h6>
                          <div className="Actions">
                            <a href="javascript:void(0);" title="pdf">
                              <img src="images/icon-53.png" />
                            </a>
                            <a href="javascript:void(0);" title="download">
                              <img src="images/download.png" />
                            </a>
                            {/* <a href="javascript:void(0);" title="setting">
                              <img src="images/setting.png" />
                            </a> */}
                          </div>
                        </div>
                        <div className="SchedulePaymentsTable SchedulePaymentsTableShow">
                          <table>
                            <thead>
                              <tr>
                                <th>Scheduled Payment Date</th>
                                <th>Transaction Type</th>
                                <th>Total</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>06/30/2023</td>
                                <td>DP:Down Payment Deposit</td>
                                <td>$ 30,000.00</td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/work-order-black.png" />
                                              Edit Scheduled Payment
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/dropdown-delete.png" />
                                              Remove Scheduled Payment
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/dropdown-delete.png" />
                                              Create New Transaction Entry
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/dropdown-delete.png" />
                                              Set Payment Reminder
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div>
                                    <input
                                      type="date"
                                      className="form-control"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="SelectAnOption">
                                    <div className="dropdown">
                                      <button
                                        className=""
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        Select an option
                                        <img src="images/black-down-arrow.png" />
                                      </button>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          className="dropdown-item fw-16"
                                          href="javascript:void(0);"
                                        >
                                          <b>Sales</b>
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -ADJ: Other Ajustments
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -ADRJ: Revenue Ajustments
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -CO: Change Order Deposit
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -DP: Down Payment Deposit
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -OP: Offer to Purchase
                                        </a>
                                        <a
                                          className="dropdown-item fw-16"
                                          href="javascript:void(0);"
                                        >
                                          <b>Accounting</b>
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Balance Due on Closing
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Closing Transactions
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Revenue - Change Order
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Revenue - Contract Price (Pre-Tax)
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Revenue - Parking
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Tax - Base
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Tax - Rebate
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <input
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/work-order-black.png" />
                                              Edit Scheduled Payment
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/dropdown-delete.png" />
                                              Remove Scheduled Payment
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/dropdown-delete.png" />
                                              Create New Transaction Entry
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/dropdown-delete.png" />
                                              Set Payment Reminder
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="SchedulePaymentsTableMain mt-5">
                        <div className="TableHeader">
                          <h6 id="TransactionBtn">
                            Transactions
                            <img src="images/down-arrow.png" />
                          </h6>
                          <div className="Actions">
                            <a
                              href="transaction_manager.html"
                              title="transaction manager"
                            >
                              <img src="images/add_transactions.png" />
                            </a>
                            <a href="javascript:void(0);" title="pdf">
                              <img src="images/icon-53.png" />
                            </a>
                            <a href="javascript:void(0);" title="download">
                              <img src="images/download.png" />
                            </a>
                            {/* <a href="javascript:void(0);" title="settings">
                              <img src="images/setting.png" />
                            </a> */}
                          </div>
                        </div>
                        <div className="SchedulePaymentsTable TransactionTableShow">
                          <table>
                            <thead>
                              <tr>
                                <th>Transaction ID#</th>
                                <th>Transaction Date</th>
                                <th>Payment Method</th>
                                <th>Transaction Type</th>
                                <th>Total</th>
                                <th>Notes</th>
                                <th>Supporting Documents</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>ADJR-ARB-1B-243-1</td>
                                <td>06/12/2023</td>
                                <td>American Express</td>
                                <td>ADRJ: Revenue Adjustments</td>
                                <td>$ 500.00</td>
                                <td>Price Updates</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocs2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentList"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <button className="Commited">Commited</button>
                                </td>
                              </tr>
                              <tr>
                                <td>DP-ARB-1B-243-2</td>
                                <td>06/12/2023</td>
                                <td>Cheque</td>
                                <td>DP : Down Payment Depsoit</td>
                                <td>$ 45,000.00</td>
                                <td>Cheque number #123</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocs2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentList"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <button className="Commited">Commited</button>
                                </td>
                              </tr>
                              <tr>
                                <td>DP-ARB-1B-243-2</td>
                                <td>06/12/2023</td>
                                <td>
                                  <div className="SelectAnOption">
                                    <div className="dropdown">
                                      <button
                                        className=""
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        Select an option
                                        <img src="images/black-down-arrow.png" />
                                      </button>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Ajustments
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -American Express
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Bank Draft
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Cash
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Cheque
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Direct Deposit
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Joural Entry
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Mastercard
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Visa
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="SelectAnOption">
                                    <div className="dropdown">
                                      <button
                                        className=""
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        Select an option
                                        <img src="images/black-down-arrow.png" />
                                      </button>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          className="dropdown-item fw-16"
                                          href="javascript:void(0);"
                                        >
                                          <b>Sales</b>
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -ADJ: Other Ajustments
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -ADRJ: Revenue Ajustments
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -CO: Change Order Deposit
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -DP: Down Payment Deposit
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -OP: Offer to Purchase
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert Amount"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert notes"
                                  />
                                </td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocs2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentList"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="AdjustCostBreakdown mt-5">
                        <div className="TableHeader">
                          <h6 id="CostBreandownBtn" className="CursorPointer">
                            Cost Breakdown{" "}
                            <img src="images/black-down-arrow.png" />
                          </h6>
                        </div>
                        <div className="CostBreandownTable CostBreandownTableShow">
                          <table>
                            <tbody>
                              <tr className="MiddleBlue">
                                <td>Contract Price Before Tax</td>
                                <td>$500,000.00</td>
                              </tr>
                              <tr className="LightBlue">
                                <td>Change Order Total</td>
                                <td>$10,825.00</td>
                              </tr>
                              <tr className="LightBlue">
                                <td>Parking (Stall 229)</td>
                                <td>$1,200.00</td>
                              </tr>
                              <tr className="LightBlue">
                                <td>Adjustment: Price Updates</td>
                                <td>$500.00</td>
                              </tr>
                              <tr className="MiddleBlue">
                                <td>Sub Total</td>
                                <td>$512,525.00</td>
                              </tr>
                              <tr className="LightBlue">
                                <td>Tax 5%</td>
                                <td>$25,626.25</td>
                              </tr>
                              <tr className="DarkBlue">
                                <td>Total</td>
                                <td>$538,151.25</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="Button mt-4 d-flex justify-content-between">
                        <button className="Create">Save</button>
                        <button className="Outline">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <hr className="HrLine" />
            <div class="DiscussionArea">
              <div class="DiscussionTitle">
                <h4>Discussion</h4>
                <span>{discussionList?.length}</span>
                <div class="DiscussionChat">
                  <img
                    src={require("../assets/images/down.png")}
                    class="DiscussionIcon"
                  />
                  <a href="#">
                    <img src={require("../assets/images/pdf2.png")} />
                  </a>
                </div>
              </div>
              <div class="DiscussionHideArea" style={{ display: "block" }}>
                <div class="DiscussionBox">
                  <span>FM</span>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      value={discussionState?.message}
                      onChange={(e) =>
                        updateDiscussionState({
                          ...discussionState,
                          message: e.target.value,
                        })
                      }
                      placeholder="Add a Comment ....."
                    />
                    {discussionState.discussionError &&
                      !discussionState.message && (
                        <p style={{ color: "red" }}>
                          {discussionState.discussionError}
                        </p>
                      )}

                    <div class="DiscussionOverlay">
                      <a
                        onClick={() =>
                          updateDiscussionState({
                            ...discussionState,
                            message: "",
                          })
                        }
                        class="CancelChat"
                      >
                        <img
                          src={require("../assets/images/plus-cancel1.png")}
                        />
                      </a>
                      <a onClick={handleAddDiscussion} class="SendChat">
                        <img
                          src={require("../assets/images/forwardbutton.png")}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                {discussionList?.map((item, i) => (
                  <>
                    <div class="DiscussionBox">
                      <span>CO</span>
                      <div class="DiscussionRight">
                        <h6>{item?.message}</h6>
                        <small>{moment(item.updatedAt).format("lll")}</small>
                      </div>
                    </div>
                    <div class="ReplyArea">
                      <a
                        onClick={(e) => {
                          updateReplyField({ flag: true, index: i });
                          updateReplyState({ ...replyState, message: "" });
                        }}
                      >
                        Reply
                      </a>
                      {item?.ReplyDiscussions?.map((ele) => (
                        <div class="DiscussionBox">
                          <span>RE</span>
                          <div class="DiscussionRight">
                            <h6>{ele?.message}</h6>
                            <small>{moment(ele.updatedAt).format("lll")}</small>
                          </div>
                        </div>
                      ))}

                      {replyField.index == i && (
                        <div class="DiscussionBox w-100">
                          <span>FM{item?.id}</span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={replyState?.message}
                              onChange={(e) =>
                                updateReplyState({
                                  ...replyState,
                                  message: e.target.value,
                                  messageId: item?.id,
                                  jobId: item?.jobId,
                                })
                              }
                              placeholder="Add reply ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddReply} class="SendChat">
                                <img
                                  src={require("../assets/images/forwardbutton.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SETREMIANDERMODALSHOW */}

      <Modal
        show={setReminderModal}
        onHide={hideSetReminderModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSetReminderModal}
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Reminder</h4>
            </div>
            <div class="FolderForm">
              <div className="form-group col-md-12">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Reminder<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    <select
                      name="setReminder"
                      value={setReminder}
                      onChange={handleInput}
                    >
                      0, 5, 15, 30, 60, 720, 1440, 10080
                      <option>Select Reminder</option>
                      <option value="5">5 min</option>
                      <option value="15">15 min</option>
                      <option value="30">30 min</option>
                      <option value="60">1 hour</option>
                      <option value="720">12 hour</option>
                      <option value="1440">1 Day</option>
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <div class="Button text-center mt-5">
                    <button
                      class="Create mr-4"
                      onClick={
                        setReminderState
                          ? hideSetReminderModal
                          : () => updateSetReminderFun(setReminder)
                      }
                    >
                      Set Reminder
                    </button>
                  </div>
                </div>
              </div>

              {/* <div class="Categories Categories1">
                  <div class="Categories_all Categories_all1">
                    <span>Select aumont of time for reminder</span>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>-0 minutes</li>
                    <li>-5 minutes</li>
                    <li>-15 minutes</li>
                  </ul>
                </div> */}
              {/* <div class="Button text-center mt-5">
                  <button
                    class="Create mr-4"
                    data-toggle="modal"
                    data-target="#LeadDetails"
                    data-dismiss="modal"
                  >
                    Set Reminder
                  </button>
                </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADDITINERARYMODALSHOW */}

      <Modal
        show={addItineraryModal}
        onHide={hideAddItineraryModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddItineraryModal}
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Add New Itinerary Item</h4>
            </div>
            <div class="FolderForm">
              <h6>Item #12</h6>

              <div class="form-group">
                <h6>Subject</h6>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Executive Meeting"
                />
              </div>
              <div class="form-group">
                <h6>Description</h6>
                <textarea rows="5"></textarea>
              </div>
              <div class="form-group">
                <h6>Location</h6>
                <input type="text" class="form-control" placeholder="Room 1" />
              </div>
              <h6>Select New Date</h6>
              <div class="Filter row">
                <div class="form-group col-lg-4 col-md-12">
                  <label>Date Option 1</label>
                  <input type="date" class="form-control" />
                </div>
                <div class="form-group col-lg-4 col-md-12">
                  <label>From</label>
                  <input type="time" class="form-control" />
                </div>
                <div class="form-group col-lg-4 col-md-12">
                  <label>To</label>
                  <input type="time" class="form-control" />
                </div>
              </div>

              <div class="Filter row">
                <div class="form-group col-lg-4 col-md-12">
                  <label>Type</label>
                  <select class="form-control">
                    <option value="Days">Meeting</option>
                    <option>Phone Call</option>
                    <option>Text</option>
                    <option>Task</option>
                    <option>Other</option>
                  </select>
                </div>
                <div class="form-group ml-auto col-lg-4 col-md-12">
                  <label class="mb-3">Set Reminder</label>
                  <div
                    class="FolderPermissionId CursorPointer"
                    onClick={() => showSetReminderModal("itinerary")}
                  >
                    <img src="images/timer-red.png" />
                    <a href="javascript:void(0);" class="text-red ml-3">
                      Reminder
                    </a>
                  </div>
                </div>
              </div>
              <h6>Itinerary is related to Job or Schedule's Task?</h6>
              <div class="row">
                <div class="form-group col-lg-6 col-md-12">
                  <div class="d-flex justify-content-between">
                    <label class="CheckBox">
                      Job
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                    <label class="CheckBox">
                      Schedule
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="Categories Categories1 mb-3">
                <div class="Categories_all Categories_all1">
                  <span>Select a folder to insert the file</span>
                  <img src="images/down.png" />
                </div>
                <ul>
                  <li>
                    <input type="text" placeholder="-Search" />
                    <span class="Search">
                      <img src="images/search.png" />
                    </span>
                  </li>
                  <li>- List of Jobs already filtered</li>
                </ul>
              </div>

              <div class="Categories Categories2">
                <div class="Categories_all Categories_all2">
                  <span>Select a Schedule Task</span>
                  <img src="images/down.png" />
                </div>
                <ul>
                  <li>
                    <input type="text" placeholder="-Search" />
                    <span class="Search">
                      <img src="images/search.png" />
                    </span>
                  </li>
                  <li>- List of Schedule tasks for job</li>
                </ul>
              </div>

              <div class="StaffMemberArea">
                <div class="row">
                  <div class="form-group col-lg-4 col-md-12">
                    <h6>Assign Staff Member</h6>
                    <div
                      class="StaffName FolderPermissionId"
                      onClick={() => showAssignSaleModal("Itinerary")}
                    >
                      <img src="images/member.png" />
                      <div class="Count">3</div>
                      <h6>Assign</h6>
                    </div>
                  </div>
                  <div class="form-group col-lg-4 col-md-12 ml-auto">
                    <h6>Assign Staff member</h6>
                    <div class="StaffBox">
                      <div class="StaffName">
                        <p>John Doe</p>
                        <img src="images/Cross.png" />
                      </div>
                      <div class="StaffName">
                        <p>John Williams</p>
                        <img src="images/Cross.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Button text-center mt-5">
                <button
                  class="Create mr-4"
                  data-toggle="modal"
                  data-target="#LeadDetails"
                  data-dismiss="modal"
                >
                  Create new Itinerary Item
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDITFOLLOWUPACTIVITYMODALSHOW */}

      <Modal
        show={editFollowUpActivityModal}
        onHide={hideEditFollowUpActivityModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideEditFollowUpActivityModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Edit Follow up activity</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="d-flex justify-content-end">
                  {/* <h6>Itinerary Item: #256</h6> */}
                </div>
                <div className="row">
                  <div className="form-group col-lg-6 col-md-12">
                    <h6>Type</h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        name="sendType"
                        value={sendType}
                        onChange={handleInput}
                      >
                        <option selected="">Select Type</option>
                        <option value="Meeting">-Meeting</option>
                        <option value="Phone call">-Phone call</option>
                        <option value="Email">-Email</option>
                      </select>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {" "}
                        {discussionError?.sendTypeError}
                      </span>
                    </div>
                  </div>
                  <div className="form-group col-lg-6 col-md-12 ml-auto">
                    <h6>Location</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Location"
                      name="location"
                      value={location}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="form-group col-lg-4 col-md-12">
                    <h6>Date</h6>
                    {/* <input type="date" className="form-control" /> */}
                    <input
                      type="date"
                      class="form-control"
                      name="activityDate"
                      value={activityDate}
                      max={moment().format("YYYY-MM-DD")}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {discussionError?.activityDateError}
                    </span>
                  </div>
                  <div className="form-group col-lg-4 col-md-12">
                    <h6>From</h6>
                    <input
                      type="time"
                      className="form-control"
                      name="fromTime"
                      value={fromTime}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {discussionError?.fromTimeError}
                    </span>
                  </div>
                  <div className="form-group col-lg-4 col-md-12">
                    <h6>To</h6>
                    <input
                      type="time"
                      className="form-control"
                      name="toTime"
                      value={toTime}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {discussionError?.toTimeError}
                    </span>
                  </div>
                </div>
                <div className="CommonModalAreaMain align-items-start mt-2 flex-lg-row flex-column">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Set Reminder</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={() =>
                            showSetReminderModal("editFollowUpActivity")
                          }
                        >
                          <figure>
                            <img src="images/timer.png" title="set reminder" />
                          </figure>{" "}
                          {setReminder ? `${setReminder} min` : "Set Reminder"}
                        </h5>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {discussionError?.setReminderError}
                      </span>
                    </div>
                  </div>
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Assign Staff Member</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={() =>
                            showAssignSaleModal("editFollowUpActivity")
                          }
                        >
                          <figure>
                            <img
                              src="images/assign-resources.png"
                              title="assign staff member"
                            />
                          </figure>{" "}
                          <span className="Count">3</span> Assign
                        </h5>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {discussionError?.UnitIdError}
                      </span>
                    </div>
                  </div>
                  <div className="StaffMemberArea mt-0">
                    <div className="form-group">
                      <h6>Assign Staff member</h6>
                      <div className="StaffBox">
                        {emailData?.map((ele, i) => {
                          return (
                            <>
                              <div className="StaffName">
                                <p>{ele}</p>
                                <img
                                  onClick={() => handleRemoveEmail(i)}
                                  src="images/Cross.png"
                                  title="canceling"
                                />
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={editFollowUpActivity}>
                    Update <img src="images/down-arrow-white.png" />
                  </button>
                  <button
                    className="Outline"
                    onClick={hideScheduleFollowActivityModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* NEWEMAILMODALSHOW */}
      <Modal
        show={newEmailModal}
        onHide={hideNewEmailModal}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideNewEmailModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>New Email</h4>
            </div>
            <div className="FolderForm">
              <div className="text RadioText">
                <div className="form-group">
                  <h6>Title</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Input email title"
                    value={titles}
                    onChange={(e) => setTitles(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <h6>Message</h6>
                  <textarea
                    className="form-control"
                    rows={4}
                    placeholder="Input Message"
                    value={messages}
                    onChange={(e) => setMessages(e.target.value)}
                  />
                </div>
              </div>
              <div className="file RadioText">
                <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input type="file" multiple onChange={handleFileChange} />
                  </div>
                </div>
                {attachments?.length > 0 && (
                  <div className="FilesAddedContainer">
                    <h3>Files Added:</h3>
                    {attachments?.map((file, index) => (
                      <div key={index} className="FilesAdded">
                        <label>{file.name}</label>
                        <span>
                          <img src="images/fileview.png" alt="File Preview" />
                        </span>
                        <span onClick={() => handleRemoveClientFile(index)}>
                          <img
                            src="images/Cross.png"
                            className="cross"
                            alt="Remove File"
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="NewEmailArea">
                <div className="NewEmailBox">
                  <h4>Title</h4>
                  <p>{titles}</p>
                </div>
                <div className="NewEmailBox">
                  <h4>Message</h4>
                  {messages}
                </div>
                <div className="NewEmailBox">
                  <h4>Attachments</h4>
                  <p>
                    {attachments?.length > 0 &&
                      attachments?.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.name}
                          <img src="images/file_name.png" alt="File icon" />
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-4">
                <button className="Create" onClick={handleNewEmailSubmit}>
                  Create Email
                </button>
                <button className="Outline" onClick={hideNewEmailModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* NEWTEXTMODALSHOW */}

      <Modal
        show={newTextModal}
        onHide={hideNewTextModal}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideNewTextModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>New Text</h4>
            </div>
            <div className="FolderForm">
              <form onSubmit={handleSendSmsToLead}>
                <div className="form-group">
                  <h6>Message</h6>
                  <textarea
                    className="form-control"
                    rows={4}
                    placeholder="Input Message"
                    name="messageBody"
                    value={messageBody}
                    onChange={handleInput}
                  />
                </div>
                <div className="Button d-flex justify-content-between ">
                  <button className="Create">Send Text</button>
                  <button className="Outline" onClick={hideNewTextModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SUPPORTINGDOCSMODALSHOW */}
      <Modal
        show={ContractSupportingDocsModal}
        onHide={hideContractSupportingListModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideContractSupportingListModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Supporting Documents List</h4>
            </div>
            <div className="JobTitleArea">
              <div className="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
                <div>
                  <a
                    href="javascript:void(0);"
                    className="FolderPermissionId"
                    onClick={() =>
                      showSupportingDocsModal("supportingDocsContract", modalId)
                    }
                  >
                    <img src="images/notebook.png" />
                  </a>
                </div>
              </div>
              {/* <div className="JobBox">
                <h6>
                  <span className="text-red">Origin</span> #185
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span className="text-red">Item:</span> #143
                </h6>
              </div> */}
            </div>
            <div className="NotesAreaMain">
              <div className="NotesArea mt-4">
                <h6>Supporting Documents List</h6>
                <div>
                  <a href="javascript:void(0);">
                    <img src="images/pdf.png" />
                  </a>
                  <DownloadTableExcel
                    filename="Supporting document List"
                    sheet="Vendors"
                    currentTableRef={supportingDocsRef.current}
                  >
                    <button href="jacascript:void(0);">
                      <img src="images/download.png" />
                    </button>
                  </DownloadTableExcel>
                  {/* <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a> */}
                </div>
              </div>
              <div className="TableList">
                <table ref={supportingDocsRef}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Sent by</th>
                      <th>Date</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <>
                        <td>
                          {" "}
                          <Loader />
                        </td>
                      </>
                    ) : (
                      <>
                        {checkArray(supprtingDocsListData?.supportDocs) ? (
                          supprtingDocsListData?.supportDocs?.map(
                            (ele, ind) => {
                              return (
                                <>
                                  <tr>
                                    <td>{ind + 1}</td>
                                    <td>{ele?.sendBy}</td>
                                    <td>{ele?.createdAt?.split("T")[0]}</td>
                                    <td className="Inspection">
                                      <a href={ele?.docUrl}>Docs</a>
                                    </td>
                                    <td>
                                      <div className="Actions">
                                        <a
                                          href="javascript:void(0);"
                                          className="FolderPermissionId"
                                          onClick={() =>
                                            showEditSupportingModal(
                                              "supportingList",
                                              ele?.id
                                            )
                                          }
                                        >
                                          <img src="images/Action-1.png" />
                                        </a>
                                        <a
                                          onClick={() =>
                                            deleteSupportingDocs(ele?.id)
                                          }
                                          href="javascript:void(0);"
                                        >
                                          <img src="images/delete.png" />
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          )
                        ) : (
                          <td>
                            {" "}
                            <NoDataFound />
                          </td>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="Button mt-5">
              {/* <button
                className="Create FolderPermissionId"
                onClick={() => showSupportingDocsModal("supportingList")}
              >
                Add New Supporting Docs
              </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDITSUPPORTINGSHOW */}

      <Modal
        show={editSupportingModal}
        onHide={hideEditSupportingModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideEditSupportingModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Edit New Supporting Docs</h4>
            </div>
            {/* <div className="JobTitleArea">
              <h3>Supporting Documents #2</h3>
              <div className="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Origin</span> #185
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Item:</span> #143
                </h6>
              </div>
            </div> */}
            <div className="form-group">
              <h6>Select Files</h6>
              <div className="Upload">
                <span>-Drag and Drop Files or click to select</span>
                <input type="file" name="files" onChange={handleInputFile} />
              </div>
            </div>
            <div className="FilesAddedTitle">
              <h4>Files</h4>
            </div>
            <div className="FilesAdded">
              <a href={files ? files : ""}>
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
              </a>
              {/* <span>
                <img src="images/Cross.png" className="cross" />
              </span> */}
            </div>
            {/* <div className="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" className="cross" />
              </span>
            </div> */}
            <div className="Button mt-3">
              <button onClick={handleUpdateSupportingFun} className="Create">
                Update Documents
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CONTRACTALLEDITSHOW */}

      <Modal
        show={mainContractEditModal}
        onHide={hideMainContractModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideMainContractModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <div
                className="abc abc11"
                style={{
                  width: "100%",
                  display: "block",
                  margin: "-20px 0 -20px 0",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <h4>General Information</h4>
                  <div className="form-group mb-0">
                    <h6>Job Type</h6>
                    <p>{saleType}</p>
                    <p className="ml-0">{contractDetailsData[0]?.jobType}</p>
                    <p className="ml-0">Client purchase price</p>
                    <p className="ml-0">
                      $ {contractDetailsData[0]?.clientPurchasePrice}
                    </p>
                  </div>
                </div>
              </div>
              <div className="abc abc12" style={{ width: "100%" }}>
                <div className="d-flex justify-content-between">
                  <h4>Conditional Details</h4>
                  <div className="form-group mb-0">
                    <h6>Sale Type</h6>
                    <p className="ml-0">Multi-Family</p>
                  </div>
                  <div className="form-group d-flex align-items-end mb-0">
                    <div>
                      <h6>Client Assigned Unit</h6>
                      <p className="ml-0 GreenAnchor">
                        U222-CHI-2B-243 (Unit #222)
                      </p>
                    </div>
                    <div className="Button ml-1">
                      <button className="Create px-1">
                        Remove Assigned Unit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="abc abc13">Adjustments and Deposits</h4>
              <h4 className="abc abc14">Assign Unit/Home to Client</h4>
              <h4 className="abc abc15">Pay For Parkade</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-12 mb-3 ContactInformationArea d-flex align-items-center ">
                  <h6 className="mb-0">Contact Information</h6>
                  <div className="TodosButtons">
                    <a
                      href="javascript:void(0);"
                      className="FolderPermissionId"
                      onClick={() =>
                        showAddClientModal(contractDetailsData?.[0]?.jobNumber)
                      }
                    >
                      <h6 className="mb-0 text-white">
                        <img src="images/add_new_client.png" />
                        Add New Client
                      </h6>
                    </a>
                  </div>
                </div>
                {checkArray(allClientListData?.clientDetails) &&
                  allClientListData?.clientDetails?.map((ele, i) => {
                    // console.log("eleleleleleele,", ele);0

                    return (
                      <>
                        <div className="col-md-5">
                          <div className="ContactInformationArea">
                            <div className="ContactInformationBox">
                              <span className="circle"></span>
                              <div className="ContactInformationName">
                                <div className="CrossOverlay">X</div>

                                <h6>{ele?.legalName}</h6>
                                <p>
                                  {contractDetailsData?.name}
                                  <br /> {ele?.streetAddress}, {ele.city}{" "}
                                  <img
                                    src="images/location.png"
                                    title="location"
                                  />
                                </p>
                                <p>
                                  Phone: <span>{ele?.businessPhoneNum}</span>
                                </p>
                                <p>
                                  Email: <span>{ele?.email}</span>
                                  <img
                                    src="images/fill_star.png"
                                    className="ml-2"
                                  />
                                </p>
                              </div>
                              <div className="ContactInformationIcon">
                                <a
                                  href="javascript:void(0);"
                                  title="pdf"
                                  onClick={handlePrint}
                                >
                                  <img src="images/pdf.png" />
                                </a>
                                {/* <a href="javascript:void(0);" title="download" onClick={handlePrint}>
                                  <img src="images/download.png" />
                                </a> */}
                                <a
                                  href="javascript:void(0);"
                                  title="client documentation"
                                  className="FolderPermissionId"
                                  onClick={() =>
                                    showClientDocumentatioModal(
                                      ele?.id,
                                      "jfgf",
                                      ele?.LeadId
                                    )
                                  }
                                >
                                  <img src="images/icon-75.png" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className="TodosButtons mt-5">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      className={
                        heading == "General Information"
                          ? "nav-link active GeneralInformationBtnShow"
                          : "nav-link GeneralInformationBtnShow"
                      }
                      data-toggle="tab"
                      href="#GeneralInformationNoUnit"
                    >
                      <img src="images/general_information_red.png" />
                      General Information
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link AssisnUnitHomeClientBtnShow"
                      data-toggle="tab"
                      href="#AssisnUnitHomeClient"
                    >
                      <img
                        src={require("../assets/images/assign-unit-red.png")}
                      />
                      Assign Unit/Home to Client
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        heading == "Conditional Details"
                          ? "nav-link active ConditionalDetailsBtnShow"
                          : "nav-link  ConditionalDetailsBtnShow"
                      }
                      data-toggle="tab"
                      href="#ConditionalDetailsNoUnit"
                    >
                      <img src="images/conditional_details.png" />
                      Conditional Details
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        heading == "Adjustments And Deposits"
                          ? "nav-link active AdjustmentsAndDepositBtnShow"
                          : "nav-link  AdjustmentsAndDepositBtnShow"
                      }
                      data-toggle="tab"
                      href="#AdjustmentsAndDepositNoUnit"
                    >
                      <img src="images/adjustment_deposit.png" />
                      Adjustments and Deposits
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link PayForParkadeBtnShow"
                      data-toggle="tab"
                      href="#PayForParkade"
                    >
                      <img src="images/adjustment_deposit.png" />
                      Pay For Parkade
                    </a>
                  </li>
                </ul>
              </div>
              <div className="LeadDetailsInput mt-4">
                <div className="tab-content">
                  <div
                    className={
                      heading == "General Information"
                        ? "tab-pane fade show active"
                        : "tab-pane fade show"
                    }
                    id="GeneralInformationNoUnit"
                    ref={tableRef}
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <h5>Assigned to</h5>
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                onClick={() =>
                                  showAssignSaleModal("contractEdit")
                                }
                              >
                                <figure>
                                  <img src="images/icon-32.png" />
                                </figure>
                                <figure>
                                  <img src="images/refresh.png" />
                                </figure>
                                {UserId
                                  ? staffListData?.staff?.find(
                                      (ele) => ele?.id == UserId
                                    )?.name
                                  : "Select"}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mx-auto">
                        <div className="form-group">
                          <h6>Sub Division</h6>
                          <div className="Categories Categories3">
                            <div className="Categories_all Categories_all3">
                              <select
                                name="subDivision"
                                value={subDivision}
                                onChange={handleInput}
                              >
                                <option>Select SubDivision </option>
                                {checkArray(subDivisionListData?.details) ? (
                                  <>
                                    {subDivisionListData?.details?.map(
                                      (ele, i) => {
                                        // le('elelelele',ele[0])
                                        return (
                                          <option value={ele}>{ele}</option>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 ml-auto">
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <h5>Status</h5>
                            <div className="CommonModalBox">
                              <h5 className="">Interested</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="mb-4">Realtor/Referrer</h6>
                      </div>
                      <div className="form-group col-md-5">
                        <h6>Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input Name"
                          name="referrerName"
                          value={referrerName}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group col-md-5 ml-auto">
                        <h6>Agency</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input Agency"
                          name="referrerAgency"
                          value={referrerAgency}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group col-md-5">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input Email"
                          name="referrerEmail"
                          value={referrerEmail}
                          onChange={handleInput}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {leadErrors?.referrerEmail}
                        </span>
                      </div>

                      <div className="form-group col-md-5 ml-auto">
                        <h6>Client Purchase Price</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input purchase price"
                          name="clientPurchasePrice"
                          value={clientPurchasePrice}
                          onChange={handleInput}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {leadErrors?.referrerEmail}
                        </span>
                      </div>
                      <div className="col-6">
                        <div className="form-group " >
                          <div className="CommonModalArea">
                            <h5>Configure Client Views</h5>
                            <div className="CommonModalBox">
                              <Link
                                to="/client-view-config"
                                state={{
                                  data: {
                                    id: contractDetailsData[0]?.JobId,
                                    jobNumber:
                                      contractDetailsData[0]?.jobNumber,
                                    jobType: contractDetailsData[0]?.jobType,
                                    ProjectId:
                                      contractDetailsData[0]?.ProjectId,
                                  },
                                }}
                              >
                                <h5>
                                  <figure>
                                    <img src="images/configure_client_view.png" />
                                  </figure>
                                  Configure Client View
                                </h5>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group" style={{
                              width: "55vw !important" ,
                              textAlign: "end !important",
                        }}>
                                  <h6>Rebate</h6>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="isRebate"
                                      checked={isRebate}
                                      onClick={handleTaxCheck}
                                    />
                                    <span className="slider" />
                                  </label>
                                </div>
                                </div>
                      </div>
                    </div>
                    <div className="Button d-flex justify-content-between mt-4">
                      <button
                        onClick={updateContractGenralApi}
                        className="Create"
                      >
                        Save
                      </button>
                      <button
                        onClick={hideMainContractModal}
                        className="Outline"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="AssisnUnitHomeClient">
                    <div className="row">
                      <div className="col-md-4 mx-auto">
                        <div className="form-group">
                          <h6>Sales Types</h6>
                          <div className="Categories Categories3">
                            <div className="Categories_all Categories_all3">
                              <select
                                name="saleType"
                                value={saleType}
                                onChange={handleInput}
                              >
                                <option>Select Sale Type</option>
                                {checkArray(salesTypeListData?.saleTypes) ? (
                                  <>
                                    {salesTypeListData?.saleTypes?.map(
                                      (ele, i) => {
                                        return (
                                          <option
                                            value={ele[0]}
                                            selected={ele[0] === saleType}
                                          >
                                            {ele[1]}
                                          </option>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mx-auto">
                        <div className="form-group">
                          <h6>Assign Unit/Home to Client</h6>
                          <div className="Categories Categories2">
                            <div className="Categories_all Categories_all2">
                              <select
                                name="jobId"
                                value={jobId}
                                onChange={handleInput}
                              >
                                <option>Select Assigned Unit</option>
                                {saleType === "Lot" ? (
                                  <>
                                    {checkArray(
                                      clientAssignedListData?.lotUnit
                                    ) ? (
                                      <>
                                        {clientAssignedListData?.lotUnit?.map(
                                          (ele, i) => {
                                            // le('elelelele',ele[0])
                                            return (
                                              <option
                                                value={ele?.id}
                                                selected={
                                                  ele.jobNumber == jobId
                                                }
                                              >
                                                {ele?.jobNumber}
                                              </option>
                                            );
                                          }
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {saleType === "ResidentialJob" ? (
                                      checkArray(
                                        clientAssignedListData?.residentialUnit
                                      ) ? (
                                        <>
                                          {clientAssignedListData?.residentialUnit?.map(
                                            (ele, i) => {
                                              return (
                                                <option
                                                  value={ele?.id}
                                                  selected={ele.id == jobId}
                                                >
                                                  {ele?.jobNumber}
                                                </option>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )
                                    ) : (
                                      <>
                                        {checkArray(
                                          clientAssignedListData?.multiUnit
                                        ) ? (
                                          <>
                                            {clientAssignedListData?.multiUnit?.map(
                                              (ele, i) => {
                                                if (ele?.jobNumber)
                                                  return (
                                                    <option
                                                      value={ele?.id}
                                                      selected={
                                                        ele?.jobNumber == jobId
                                                      }
                                                    >
                                                      {ele?.jobNumber}
                                                    </option>
                                                  );
                                              }
                                            )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 mx-auto">
                        <div className="Button d-flex justify-content-between">
                          <button
                            className="Create"
                            onClick={updateAssignUnitFunc}
                          >
                            Assign Unit
                          </button>
                          <button
                            className="Outline"
                            onClick={hideMainContractModal}
                          >
                            {" "}
                            Cancel
                          </button>
                        </div>
                        <div className="Button d-flex justify-content-between mt-4">
                          <button className="Outline">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      heading == "Conditional Details"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="ConditionalDetailsNoUnit"
                  >
                    <div className="row">
                      {/* <div class="col-md-4">
                      
                      <div class="form-group">
                        <h6>Client Purchase Price</h6>
                        <input type="text" class="form-control" placeholder="Insert purchase price">
                      </div>
                
                    </div> */}
                      <div className="col-md-9">
                        <div className="SatisfiedTab">
                          <table>
                            <thead>
                              <tr>
                                <th>Satisfied Conditions</th>
                                <th>Check Condition</th>
                                {/* <th>Date</th> */}
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Depositd Paid</td>
                                <td>
                                  <label className="CheckBox">
                                    <input
                                      type="checkbox"
                                      checked={satisCondition.depositPaid}
                                      onChange={() =>
                                        handleCheckboxChange("depositPaid")
                                      }
                                      disabled={
                                        satisCondition.depositPaidStatus
                                      }
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </td>
                                {/* <td>06/12/2023 - 02:34 pm</td> */}
                                <td>
                                  <div
                                    onClick={() =>
                                      handleLockClick("depositPaid")
                                    }
                                    className="Action"
                                  >
                                    <img src="images/Lock.png" />
                                  </div>
                                </td>
                                <td
                                  className={`${
                                    satisCondition.depositPaidStatus
                                      ? "UnlockRed"
                                      : satisCondition.depositPaid
                                      ? "UnlockGreen"
                                      : ""
                                  }`}
                                >
                                  <div
                                    // onClick={() =>
                                    //   handleUnlockClick("depositPaid")
                                    // }
                                    className="Action OpacaityAction"
                                  >
                                    <img
                                      src="images/unlock.png"
                                      className="mr-2"
                                    />
                                    Unlock condition
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Contract Signed</td>
                                <td>
                                  <label className="CheckBox">
                                    <input
                                      type="checkbox"
                                      checked={satisCondition.contractSigned}
                                      onChange={() =>
                                        handleCheckboxChange("contractSigned")
                                      }
                                      disabled={
                                        satisCondition.contractSignedStatus
                                      }
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </td>

                                <td>
                                  <div
                                    onClick={() =>
                                      handleLockClick("contractSigned")
                                    }
                                    className="Action"
                                  >
                                    <img src="images/Lock.png" />
                                  </div>
                                </td>
                                <td
                                  className={`${
                                    satisCondition.contractSignedStatus
                                      ? "UnlockRed"
                                      : satisCondition.contractSigned
                                      ? "UnlockGreen"
                                      : ""
                                  }`}
                                >
                                  <div
                                    // onClick={() =>
                                    //   handleUnlockClick("contractSigned")
                                    // }
                                    className="Action OpacaityAction"
                                  >
                                    <img
                                      src="images/unlock.png"
                                      className="mr-2"
                                    />
                                    Unlock condition
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Down Payment Paid</td>
                                <td>
                                  <label className="CheckBox">
                                    <input
                                      type="checkbox"
                                      checked={satisCondition.downPayPaid}
                                      onChange={() =>
                                        handleCheckboxChange("downPayPaid")
                                      }
                                      disabled={
                                        satisCondition.downPayPaidStatus
                                      }
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </td>

                                <td>
                                  <div
                                    onClick={() =>
                                      handleLockClick("downPayPaid")
                                    }
                                    className="Action"
                                  >
                                    <img src="images/Lock.png" />
                                  </div>
                                </td>

                                <td
                                  className={`${
                                    satisCondition.downPayPaidStatus
                                      ? "UnlockRed"
                                      : satisCondition.downPayPaid
                                      ? "UnlockGreen"
                                      : ""
                                  }`}
                                >
                                  <div
                                    // onClick={() =>
                                    //   handleUnlockClick("downPayPaid")
                                    // }
                                    className="Action OpacaityAction"
                                  >
                                    <img
                                      src="images/unlock.png"
                                      className="mr-2"
                                    />
                                    Unlock condition
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Financing Confirmed</td>
                                <td>
                                  <label className="CheckBox">
                                    <input
                                      type="checkbox"
                                      checked={satisCondition.finanConfirmed}
                                      onChange={() =>
                                        handleCheckboxChange("finanConfirmed")
                                      }
                                      disabled={
                                        satisCondition.finanConfirmedStatus
                                      }
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </td>

                                <td>
                                  <div
                                    onClick={() =>
                                      handleLockClick("finanConfirmed")
                                    }
                                    className="Action"
                                  >
                                    <img src="images/Lock.png" />
                                  </div>
                                </td>
                                <td
                                  className={`${
                                    satisCondition.finanConfirmedStatus
                                      ? "UnlockRed"
                                      : satisCondition.finanConfirmed
                                      ? "UnlockGreen"
                                      : ""
                                  }`}
                                >
                                  <div
                                    // onClick={() =>
                                    //   handleUnlockClick("finanConfirmed")
                                    // }

                                    className="Action OpacaityAction"
                                  >
                                    <img
                                      src="images/unlock.png"
                                      className="mr-2"
                                    />
                                    Unlock condition
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Waiver of conditions Signed</td>
                                <td>
                                  <label className="CheckBox">
                                    <input
                                      type="checkbox"
                                      checked={satisCondition.waiverOfConSigned}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          "waiverOfConSigned"
                                        )
                                      }
                                      disabled={
                                        satisCondition.waiverOfConSignedStatus
                                      }
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </td>

                                <td>
                                  <div
                                    onClick={() =>
                                      handleLockClick("waiverOfConSigned")
                                    }
                                    className="Action"
                                  >
                                    <img src="images/Lock.png" />
                                  </div>
                                </td>
                                <td
                                  className={`${
                                    satisCondition.waiverOfConSignedStatus
                                      ? "UnlockRed"
                                      : satisCondition.waiverOfConSigned
                                      ? "UnlockGreen"
                                      : ""
                                  }`}
                                >
                                  <div
                                    // onClick={() =>
                                    //   handleUnlockClick("waiverOfConSigned")
                                    // }
                                    className="Action OpacaityAction"
                                  >
                                    <img
                                      src="images/unlock.png"
                                      className="mr-2"
                                    />
                                    Unlock condition
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={5}>
                                  <div className="Button text-right">
                                    <button
                                      className="Create px-2 py-2 fw-14"
                                      onClick={() =>
                                        showUnlockConditionModal("editContract")
                                      }
                                    >
                                      Request to Unlock
                                      <br /> Condition
                                    </button>
                                  </div>
                                </td>
                                <td />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <button
                            onClick={handleAllLockFUnc}
                            className="LockBtn"
                          >
                            <img src="images/lock_white.png" />
                            Lock all Conditions
                          </button>
                          {/* <button className="UnlockBtn">
                              <img src="images/unlock_white.png" />
                              Unlock all Conditions
                            </button> */}
                        </div>
                        {/* <div class="row mt-5">
                        <div class="col-md-4 mx-auto">
                          <div class="form-group">
                            <div class="CommonModalArea">
                             <h5>Authorization Status</h5>
                             <div class="CommonModalBox">
                               <h5> Pending with John Manager
                               </h5>
                             </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mx-auto">
                          <div class="form-group">
                            <div class="CommonModalArea">
                             <h5>Client Documentation</h5>
                             <div class="CommonModalBox">
                               <h5>
                                 <figure><img src="images/icon-63.png"></figure> Client Documentation
                               </h5>
                             </div>
                            </div>
                            </div>
                        </div>
                      </div> */}
                      </div>
                      <div class="col-md-3">
                        <button
                          class="UnlockBtn mb-3 FolderPermissionId"
                          style={{ padding: "15px 5px", width: "auto" }}
                          onClick={() =>
                            showUnlockConditionListModal("EditContract")
                          }
                        >
                          <img src="images/unlock_white.png" /> Unlock
                          Conditions Approval <span class="White">4</span>
                        </button>
                        {/* <!-- <div class="form-group">
                              <h6>Authorization Status</h6>
                              <p class="ml-0">Pending with John Manager <br> <img src="images/view-po.png" data-toggle="modal" data-target="#ApproveUnlockCondition" data-dismiss="modal"></p>
                            </div>
                            <div class="ApproveRejectBtnArea d-flex justify-content-between"><button class="ApproveBtn">Approve</button><button class="RejectBtn">Reject</button></div> --> */}
                      </div>
                    </div>

                    <div className="Button mt-4 d-flex justify-content-between">
                      <button
                        className="Create"
                        onClick={updateConditionalDetailsFunc}
                      >
                        Save
                      </button>
                      <button
                        className="Outline"
                        data-toggle="modal"
                        data-target="#ClientContractDetailsNoUnit"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                    <hr className="HrLine" />
                    <div class="DiscussionArea">
                      <div class="DiscussionTitle">
                        <h4>Discussion</h4>
                        <span>{discussionList?.length}</span>
                        <div class="DiscussionChat">
                          <img
                            src={require("../assets/images/down.png")}
                            class="DiscussionIcon"
                          />
                          <a href="#">
                            <img src={require("../assets/images/pdf2.png")} />
                          </a>
                        </div>
                      </div>
                      <div
                        class="DiscussionHideArea"
                        style={{ display: "block" }}
                      >
                        <div class="DiscussionBox">
                          <span>
                            {iBuildLocalData?.user?.name?.slice(0, 3)}
                          </span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={discussionState?.message}
                              onChange={(e) =>
                                updateDiscussionState({
                                  ...discussionState,
                                  message: e.target.value,
                                })
                              }
                              placeholder="Add a Comment ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateDiscussionState({
                                    ...discussionState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddDiscussion} class="SendChat">
                                <img
                                  src={require("../assets/images/forwardbutton.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        {discussionList?.map((item, i) => (
                          <>
                            <div class="DiscussionBox">
                              <span>
                                {iBuildLocalData?.user?.name?.slice(0, 3)}
                              </span>
                              <div class="DiscussionRight">
                                <h6>{item?.message}</h6>
                                <small>
                                  {moment(item.updatedAt).format("lll")}
                                </small>
                              </div>
                            </div>
                            <div class="ReplyArea">
                              <a
                                onClick={(e) => {
                                  updateReplyField({ flag: true, index: i });
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  });
                                }}
                              >
                                Reply
                              </a>
                              {item?.ReplyDiscussions?.map((ele) => (
                                <div class="DiscussionBox">
                                  <span>{ele?.User?.name?.slice(0, 2)}</span>
                                  <div class="DiscussionRight">
                                    <h6>{ele?.message}</h6>
                                    <small>
                                      {moment(ele.updatedAt).format("lll")}
                                    </small>
                                  </div>
                                </div>
                              ))}

                              {replyField.index == i && (
                                <div class="DiscussionBox w-100">
                                  <span>FM{item?.id}</span>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={replyState?.message}
                                      onChange={(e) =>
                                        updateReplyState({
                                          ...replyState,
                                          message: e.target.value,
                                          messageId: item?.id,
                                          jobId: item?.jobId,
                                        })
                                      }
                                      placeholder="Add reply ....."
                                    />
                                    {discussionState.discussionError &&
                                      !discussionState.message && (
                                        <p style={{ color: "red" }}>
                                          {discussionState.discussionError}
                                        </p>
                                      )}

                                    <div class="DiscussionOverlay">
                                      <a
                                        onClick={() =>
                                          updateReplyState({
                                            ...replyState,
                                            message: "",
                                          })
                                        }
                                        class="CancelChat"
                                      >
                                        <img
                                          src={require("../assets/images/plus-cancel1.png")}
                                        />
                                      </a>
                                      <a
                                        onClick={handleAddReply}
                                        class="SendChat"
                                      >
                                        <img
                                          src={require("../assets/images/forwardbutton.png")}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      heading == "Adjustments And Deposits"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="AdjustmentsAndDepositNoUnit"
                  >
                    <div className="SchedulePaymentsTableMain">
                      {/* <div className="row">
                          <div className="col-md-5">
                            <div className="form-group">
                              <h6>Deposit Amount</h6>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Insert deposit amount"
                              />
                            </div>
                            <div className="form-group">
                              <h6>Down Payment Percentage</h6>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Down Payment Percentage"
                              />
                            </div>
                          </div>
                        </div> */}
                      <div className="TableHeader">
                        <h6 className="ScheduleNewPaymentBtn">
                          Schedule New Payment{" "}
                          <img src="images/down-arrow.png" />
                        </h6>
                      </div>
                      <div className="ScheduleNewPaymentShow SchedulePaymentsTable">
                        <table>
                          <thead>
                            <tr>
                              <th>Schedule Date</th>
                              <th>Transaction Type</th>
                              <th>Amount</th>
                              {/* <th>Notes</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="TableInput">
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Insert Amount"
                                    name="scheOfPayDate"
                                    value={scheOfPayDate}
                                    min={getMaxDate()}
                                    onChange={handleInput}
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {scheduledPaymentError?.scheOfPayDateError}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="CommonSelectBox">
                                  <select
                                    name="transactionType"
                                    value={transactionType}
                                    onChange={handleInput}
                                  >
                                    <option>Select Transaction Type</option>

                                    <option value="ADJ: Other Adjustments">
                                      ADJ: Other Adjustments
                                    </option>
                                    <option value="ADJR: Revenue Adjustments">
                                      ADJR: Revenue Adjustments
                                    </option>
                                    <option value="CO: Change Order Deposit">
                                      CO: Change Order Deposit
                                    </option>
                                    <option value="DP: Down Payment Deposit">
                                      DP: Down Payment Deposit
                                    </option>
                                    <option value="OP: Offer to Purchase">
                                      OP: Offer to Purchase
                                    </option>

                                    <option value="Balance Due on Closing">
                                      Balance Due on Closing
                                    </option>
                                    <option value="Closing Transactions">
                                      Closing Transactions
                                    </option>
                                    <option value="Revenue - Change Order">
                                      Change Order
                                    </option>
                                    <option value="Revenue - Contract Price (Pre-Tax)">
                                      Contract Price
                                    </option>
                                    <option value="Revenue - Parking">
                                      Revenue Parking
                                    </option>
                                    <option value="Tax - Base">
                                      Tax - Base
                                    </option>
                                    <option value="Tax - Rebate">
                                      Tax - Rebate
                                    </option>
                                  </select>
                                </div>
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {scheduledPaymentError?.transactionTypeError}
                                </span>
                              </td>
                              <td>
                                <div className="TableInput">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Insert Amount"
                                    name="total"
                                    value={total}
                                    onChange={handleInputNumber}
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {scheduledPaymentError?.totalError}
                                  </span>
                                </div>
                              </td>
                              {/* <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className="javascript:void(0);"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/Action-1.png" />
                                    Edit Scheduled Payment
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/dropdown-delete.png" />
                                    Remove Scheduled Payment
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#TransactionsScheduleSmall"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/add_transactions.png" />
                                    Create New Transaction Entry
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/timer.png" />
                                    Set Payment Reminder
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td> */}
                            </tr>
                            <tr>
                              <td colSpan={4}>
                                <div className="TableButtons">
                                  <button
                                    onClick={addSchedulePaymentFun}
                                    className="BrandBtn mr-2"
                                  >
                                    Schedule New Payment
                                  </button>

                                  <button
                                    onClick={editScheduledPaymentFun}
                                    className="BrandBtn"
                                  >
                                    Update Schedule Payment
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTableMain mt-5">
                      <div className="TableHeader">
                        <h6 className="SchedulePaymentsBtn">
                          Schedule Payment Table{" "}
                          <img src="images/down-arrow.png" />
                        </h6>
                        <div className="Actions">
                          <a href="javascript:void(0);" title="pdf">
                            <img src="images/icon-53.png" />
                          </a>
                          <a href="javascript:void(0);" title="download">
                            <img src="images/download.png" />
                          </a>
                        </div>
                      </div>
                      <div className="SchedulePaymentsTable SchedulePaymentsTableShow TableHeight500">
                        <table>
                          <thead>
                            <tr>
                              <th>Scheduled Payment Date</th>
                              <th style={{ color: "red" }}>
                                Scheduled Payment Reminder
                              </th>
                              <th>Transaction Type</th>
                              <th>Total</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {checkArray(scheduledPaymentListData) ? (
                              scheduledPaymentListData?.map((ele, i) => {
                                return (
                                  <tr>
                                    <td>{ele?.scheOfPayDate?.split("T")[0]}</td>
                                    <td style={{ color: "red" }}>
                                      {ele?.paymentReminder?.split("T")[0]}
                                    </td>
                                    <td>{ele?.transactionType}</td>
                                    <td>{ele?.total}</td>

                                    <td>
                                      <div className="TableThreeDots">
                                        <ul className="">
                                          <li className="dropdown">
                                            <a
                                              className="javascript:void(0);"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </a>
                                            <ol className="dropdown-menu dropdown-menuwidth2">
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    updateSchedulePaymentData(
                                                      ele
                                                    )
                                                  }
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/Action-1.png" />
                                                  Edit Scheduled Payment
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    removeScheduledPaymentFun(
                                                      ele?.id
                                                    )
                                                  }
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/dropdown-delete.png" />
                                                  Remove Scheduled Payment
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  className="FolderPermissionId"
                                                  onClick={() =>
                                                    showContractTransactionsModal(
                                                      ele?.id,
                                                      ele?.ContractId
                                                    )
                                                  }
                                                >
                                                  <img src="images/add_transactions.png" />
                                                  Create New Transaction Entry
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleShowAddPaymentReminder(
                                                      ele
                                                    )
                                                  }
                                                >
                                                  <img src="images/timer.png" />
                                                  Set Payment Reminder
                                                </a>
                                              </li>
                                            </ol>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <NoDataFound />
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <div className="SchedulePaymentsTableMain  mt-5">
                      <div className="TableHeader">
                        <h6 className="AddNewTransactionsBtn">
                          Add Payment/Deposit{" "}
                          <img src="images/down-arrow.png" />
                        </h6>
                        <div className="Actions">
                          <a href="javascript:void(0);">
                            <img src="images/icon-53.png" />
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/download.png" />
                          </a>
                         
                        </div>
                      </div>
                      <div className="AddNewTransactionsShow SchedulePaymentsTable TransactionTableShow">
                        <table>
                          <thead>
                            <tr>
                              <th>Transaction Date</th>
                              <th>Payment Method</th>
                              <th>Transaction Type</th>
                              <th>Total</th>
                              <th>Notes</th>
                              <th>Supporting Documents</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="TableInput">
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Insert Amount"
                                    name="tranDate"
                                    value={tranDate}
                                    onChange={handleInput}
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {
                                      scheduledTransactionPaymentError?.tranDateError
                                    }
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="CommonSelectBox">
                                  <select
                                    name="payMethod"
                                    value={payMethod}
                                    onChange={handleInput}
                                  >
                                    <option value="">
                                      Select Payment Method
                                    </option>
                                    <option value="Adjustment">
                                      Adjustment
                                    </option>
                                    <option value="American Express">
                                      American Express
                                    </option>
                                    <option value="Bank Draft">
                                      Bank Draft
                                    </option>
                                    <option value="Cash">Cash</option>
                                    <option value="Cheque">Cheque</option>
                                    <option value="Direct Deposit">
                                      Direct Deposit{" "}
                                    </option>
                                    <option value="Journal Entry">
                                      Journal Entry
                                    </option>
                                    <option value="MasterCard">
                                      MasterCard
                                    </option>
                                    <option value="Visa">Visa</option>
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {
                                    scheduledTransactionPaymentError?.payMethodError
                                  }
                                </span>
                              </td>
                              <td>
                                <div className="CommonSelectBox">
                                  <select
                                    name="tranType"
                                    value={tranType}
                                    onChange={handleInput}
                                  >
                                    <option selected="">
                                      Select Transaction Type
                                    </option>
                                    <option value="DP">DP: </option>
                                    <option value="Other Ajustments">
                                      Other Ajustments
                                    </option>
                                    <option value="Revenue Ajustments">
                                      Revenue Ajustments
                                    </option>
                                    <option value="CO">
                                      Change Order Deposit
                                    </option>
                                    <option value="Down Payment Deposit">
                                      Down Payment Deposit
                                    </option>
                                    <option value="Offer to Purchase">
                                      Offer to Purchase
                                    </option>
                                    <option value="Deposit">Deposit</option>

                                    <b>Accounting</b>
                                    <option value="Accounting">
                                      Accounting{" "}
                                    </option>
                                    <option value="Balance Due on Closing">
                                      Balance Due on Closing
                                    </option>
                                    <option value="Closing Transactions">
                                      Closing Transactions
                                    </option>
                                    <option value="Change Order">
                                      Change Order
                                    </option>
                                    <option value="Revenue-Contract Price(Pre-tax)">
                                      Contract Price
                                    </option>
                                    <option value="Revenue- Parking">
                                      Revenue Parking
                                    </option>
                                    <option value="Tax-Base">Tax-Base</option>
                                    <option value="Tax-Rebate">
                                      Tax-Rebate
                                    </option>
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {
                                    scheduledTransactionPaymentError?.tranTypeError
                                  }
                                </span>
                              </td>
                              <td>
                                <div className="TableInput">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert Amount"
                                    name="total"
                                    value={total}
                                    onChange={handleInputNumber}
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {
                                      scheduledTransactionPaymentError?.totalError
                                    }
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="TableInput">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert Notes"
                                    name="notes"
                                    value={notes}
                                    onChange={handleInput}
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {
                                      scheduledTransactionPaymentError?.notesError
                                    }
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="TableInput">
                                  <input
                                    type="file"
                                    className="form-control"
                                    // placeholder="Insert Amount"
                                    name="suppDoc"
                                    // value={suppDoc}
                                    onChange={handleInputFile}
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {
                                      scheduledTransactionPaymentError?.suppDocError
                                    }
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={6}>
                                <div className="TableButtons">
                                  <button
                                    className="BrandBtn"
                                    onClick={addTransactionScheduledFun}
                                  >
                                    Create New Transaction
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> */}
                    {/* <div className="SchedulePaymentsTableMain mt-5">
                      <div className="TableHeader">
                        <h6 className="TransactionNewBtn">
                          Payment/Deposite Table
                          <img src="images/down-arrow.png" />
                        </h6>
                        <div className="Actions">
                          <a
                            href="transaction_manager.html"
                            title="Transaction Module"
                          >
                            <img src="images/add_transactions.png" />
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/icon-53.png" />
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/download.png" />
                          </a>
                        
                        </div>
                      </div>
                      <div className="TransactionNewTable SchedulePaymentsTable TransactionTableShow ">
                        <table>
                          <thead>
                            <tr>
                              <th>Transaction ID#</th>
                              <th>Transaction Date</th>
                              <th>Payment Method</th>
                              <th>Transaction Type</th>
                              <th>Total</th>
                              <th>Notes</th>
                              <th>Supporting Documents</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>ADJR-ARB-1B-243-1</td>
                              <td>06/12/2023</td>
                              <td>American Express</td>
                              <td>ADRJ: Revenue Adjustments</td>
                              <td>$ 500.00</td>
                              <td>Price Updates</td>
                              <td>
                                <div className="Actions">
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#AddNewSupportingDocs2"
                                  >
                                    <img src="images/icon-63.png" />
                                  </a>
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="modal"
                                    data-target="#SupportingDocumentList"
                                  >
                                    <span className="Count">3</span>
                                  </a>
                                </div>
                              </td>
                              <td>
                                <button className="Commited">
                                  Send to Accounting
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>DP-ARB-1B-243-2</td>
                              <td>06/12/2023</td>
                              <td>Cheque</td>
                              <td>DP : Down Payment Depsoit</td>
                              <td>$ 45,000.00</td>
                              <td>Cheque number #123</td>
                              <td>
                                <div className="Actions">
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#AddNewSupportingDocs2"
                                  >
                                    <img src="images/icon-63.png" />
                                  </a>
                                  <a
                                    href="javascript:void(0)"
                                    data-toggle="modal"
                                    data-target="#SupportingDocumentList"
                                  >
                                    <span className="Count">3</span>
                                  </a>
                                </div>
                              </td>
                              <td>
                                <button className="Commited">
                                  Send to Accounting
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> */}
                    {/* <div className="AdjustCostBreakdown mt-5">
                      <div className="TableHeader">
                        <h6 className="CostBreandownBtn CursorPointer">
                          Revenue Breakdown{" "}
                          <img src="images/black-down-arrow.png" />
                        </h6>
                      </div>
                      <div className="CostBreandownTable CostBreandownTableShow">
                        <table>
                          <tbody>
                            <tr className="MiddleBlue">
                              <td>Contract Price Before Tax</td>
                              <td>$500,000.00</td>
                            </tr>
                            <tr className="LightBlue">
                              <td>Change Order Total</td>
                              <td>$10,825.00</td>
                            </tr>
                            <tr className="LightBlue">
                              <td>Parking (Stall 229)</td>
                              <td>$1,200.00</td>
                            </tr>
                            <tr className="LightBlue">
                              <td>Adjustment: Price Updates</td>
                              <td>$500.00</td>
                            </tr>
                            <tr className="MiddleBlue">
                              <td>Sub Total</td>
                              <td>$512,525.00</td>
                            </tr>
                            <tr className="LightBlue">
                              <td>Tax 5%</td>
                              <td>$25,626.25</td>
                            </tr>
                            <tr className="DarkBlue">
                              <td>Total</td>
                              <td>$538,151.25</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="Button mt-4 d-flex justify-content-between">
                      <button className="Create">Save</button>
                      <button className="Outline">Cancel</button>
                    </div> */}
                  </div>
                  <div className="tab-pane fade" id="PayForParkade">
                    <div className="row">
                      {/* <a
                        href="javascript:void(0);"
                        className="OutlineBrandBtn mb-4"
                      >
                        <img src="images/show-parkade.png" /> Show Parkade
                        Options <span>1</span>
                      </a> */}
                      <div className="ParkingSpacesUnitsArea position-relative">
                        <h5>Parking Spaces/Units</h5>
                        <div className="ParkingSpacesUnitsTable">
                          <table>
                            <thead>
                              <tr>
                                <th>Description or Stall/Unit #</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* <tr>
                                  <td>
                                    <div className="Action">
                                      Stall 228
                                      <img
                                        src="images/Cross.png"
                                        className="ml-4"
                                      />
                                    </div>
                                  </td>
                                  <td>$1,200.00</td>
                                </tr> */}
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert description or Stall/Unit #"
                                    name="description"
                                    value={description}
                                    onChange={handleInput}
                                  />
                                </td>
                                <td>
                                  <input
                                    type=""
                                    className="form-control"
                                    placeholder="Insert quantity"
                                    name="price"
                                    value={price}
                                    onChange={handleInputNumber}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="Button mt-3">
                          <button onClick={addParkingFunc} className="Create">
                            Add Parking
                          </button>
                        </div>

                        <div className="AdjustCostBreakdown AdjustCostBreakdownGreen mt-5">
                          <div className="TableHeader">
                            <h6 className="GrossMarginBtn CursorPointer">
                              Revenue From Parkade{" "}
                              <img src="images/black-down-arrow.png" />
                            </h6>
                          </div>
                          <div className="CostBreandownTable CostBreandownTableShow">
                            <table>
                              <tbody>
                                <tr className="MiddleGreen">
                                  <td>Revenue From Parkade</td>
                                  <td>Price</td>
                                  <td>Date Purchased</td>
                                  <td>Action</td>
                                </tr>
                                {checkArray(parkingListData?.data) &&
                                  parkingListData?.data?.map((ele, i) => {
                                    // console.log("elelelelele", ele);
                                    return (
                                      <>
                                        <tr className="pl-3">
                                          <td>{ele?.description}</td>
                                          <td>{ele?.price}</td>
                                          <td>
                                            {ele?.createdAt?.split("T")[0]}
                                          </td>
                                          <td
                                            className="TextBlue"
                                            onClick={() =>
                                              removeParkingFunc(ele?.id)
                                            }
                                          >
                                            Remove
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <div>
                          <a
                            href="javascript:void(0);"
                            className="ParKadeOverlay"
                          >
                            ×
                          </a>
                        </div> */}
                      </div>
                    </div>
                    <div className="Button d-flex justify-content-between mt-4">
                      <button className="Create">Save</button>
                      <button className="Outline">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CONTRACTASSIGNSHOW */}

      <Modal
        show={contractAssignUnitModal}
        onHide={hideContractAssignUnitModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideContractAssignUnitModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Assign Unit</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="ContactInformationArea">
                      <h6>Contact Information</h6>
                      <div className="ContactInformationBox">
                        <span className="circle">JL</span>
                        <div className="ContactInformationName">
                          <h6>John Lead</h6>
                          <p>
                            312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                            <img src="images/location.png" />
                          </p>
                          <p>
                            Phone: <span>(403)555-555</span>
                          </p>
                          <p>
                            Email: <span>John.lead@email.com</span>
                          </p>
                        </div>
                        <div className="ContactInformationIcon">
                          <a href="javascript:void(0);" title="pdf">
                            <img src="images/pdf.png" />
                          </a>
                          <a href="javascript:void(0);" title="download">
                            <img src="images/download.png" />
                          </a>
                          <a
                            href="javascript:void(0);"
                            title="client documentation"
                          >
                            <img src="images/icon-75.png" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="LeadDetailsInput mt-4">
                  <div className="row">
                    <div className="col-lg-5 mx-auto">
                      <div className="form-group">
                        <h6>Sales Type</h6>
                        <div className="Categories Categories3">
                          <div className="Categories_all Categories_all3">
                            <span>Select Sale Type</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              -List of sales type for that company loaded from
                              the systems (for example Multi-Family,
                              Residential)
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 mx-auto">
                      <div className="form-group">
                        <h6>Client Assigned Unit</h6>
                        <div className="Categories Categories2">
                          <div className="Categories_all Categories_all2">
                            <span>Select available unit</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              -List of available units (no client assined)
                              loaded from the system based on the sale type
                              selection
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-11 mx-auto">
                      <div className="Button d-flex justify-content-between">
                        <button className="Create" data-dismiss="modal">
                          Assign Unit
                        </button>
                        <button
                          className="Outline"
                          onClick={hideContractAssignUnitModal}
                        >
                          {" "}
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* TRANSACTIONSHOW */}

      <Modal
        show={contractTransactionsModal}
        onHide={hideContractTransactionsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideContractTransactionsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Transactions</h4>
            </div>
            <div className="ModalBigheading mb-5">
              <h3>
                <span>Client:</span>Marna Contract
              </h3>
              <h3>
                <span>Job Assigned:</span>U37-ARB-1B-243
              </h3>
            </div>
            <div className="Tableheader">
              <h6 className="mb-0">Create new Transaction</h6>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Transaction Date</th>
                    <th>Payment Method</th>
                    <th>Transaction Type</th>
                    <th>Total</th>
                    <th>Notes</th>
                    <th>Supporting Documents</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="TableInput">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Insert Amount"
                          name="tranDate"
                          value={tranDate}
                          onChange={handleInput}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {scheduledTransactionPaymentError?.tranDateError}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select
                          name="payMethod"
                          value={payMethod}
                          onChange={handleInput}
                        >
                          <option value="">Select Payment Method</option>
                          <option value="Adjustment">Adjustment</option>
                          <option value="American Express">
                            American Express
                          </option>
                          <option value="Bank Draft">Bank Draft</option>
                          <option value="Cash">Cash</option>
                          <option value="Cheque">Cheque</option>
                          <option value="Direct Deposit">
                            Direct Deposit{" "}
                          </option>
                          <option value="Journal Entry">Journal Entry</option>
                          <option value="MasterCard">MasterCard</option>
                          <option value="Visa">Visa</option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {scheduledTransactionPaymentError?.payMethodError}
                      </span>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select
                          name="tranType"
                          value={tranType}
                          onChange={handleInput}
                        >
                          <option>Select Transaction Type</option>

                          <option value="ADJ">ADJ: Other Adjustments</option>
                          <option value="ADJR">
                            ADJR: Revenue Adjustments
                          </option>
                          <option value="CO">CO: Change Order Deposit</option>
                          <option value="DP">DP: Down Payment DeposiT</option>
                          <option value="OP">OP: Offer to Purchase</option>

                          {/* <option value="Balance Due on Closing">
                            Balance Due on Closing
                          </option>
                          <option value="Closing Transactions">
                            Closing Transactions
                          </option>
                          <option value="Revenue - Change Order">
                            Change Order
                          </option>
                          <option value="Revenue - Contract Price (Pre-Tax)">
                            Contract Price
                          </option>
                          <option value="Revenue - Parking">
                            Revenue Parking
                          </option>
                          <option value="Tax - Base">Tax - Base</option>
                          <option value="Tax - Rebate">Tax - Rebate</option> */}
                        </select>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {scheduledTransactionPaymentError?.tranTypeError}
                      </span>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Amount"
                          name="total"
                          value={total}
                          onChange={handleInputNumber}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {scheduledTransactionPaymentError?.totalError}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Notes"
                          name="notes"
                          value={notes}
                          onChange={handleInput}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {scheduledTransactionPaymentError?.notesError}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="file"
                          className="form-control"
                          // placeholder="Insert Amount"
                          name="suppDoc"
                          // value={suppDoc}
                          onChange={handleInputFile}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {scheduledTransactionPaymentError?.suppDocError}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <div className="TableButtons">
                        <button
                          className="BrandBtn"
                          onClick={addTransactionScheduledFun}
                        >
                          Create New Transaction
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="TableHeadingArea mt-5">
              <div className="FilesHead">
                <div className="FilesLeft">
                  <h4>Transaction</h4>
                </div>
                <div className="d-flex align-items-center">
                  {/* <div className="Search">
                    <span>
                      <img src="images/search.png" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div> */}
                  <div className="FilesRight">
                    <a href="#">
                      <img src="images/pdf.png" />
                    </a>
                    <DownloadTableExcel
                      filename=" Transacton List"
                      sheet="Vendors"
                      currentTableRef={transactionListRef.current}
                    >
                      <button href="jacascript:void(0);">
                        <img src="images/download.png" />
                      </button>
                    </DownloadTableExcel>
                    {/* <a href="#">
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="TableList CommonHeading TableListLarge">
              <table>
                <thead>
                  <tr>
                    <th>Job Assigned</th>
                    <th>Transaction ID#</th>
                    <th>Transaction/Adjustment Date</th>
                    <th>Payment Method</th>
                    <th>Transaction Type</th>
                    <th>Total</th>
                    <th>Notes</th>
                    <th>Debit</th>
                    <th>Credit</th>

                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={12}>
                      <h6 className="mb-0">ADRJ: Revenue Adjustments</h6>
                    </td>
                  </tr>
                  {checkArray(transactionListData) ? (
                    transactionListData?.map((ele, i) => {
                      // console.log("erlrllr",ele)
                      return (
                        <tr>
                          <td>
                            <div className="TextBlue">{ele?.jobType}</div>
                          </td>
                          <td> {ele?.tranId}</td>
                          <td> {ele?.tranDate}</td>
                          <td> {ele?.payMethod}</td>
                          <td> {ele?.tranType}</td>
                          <td> {ele?.total}</td>
                          <td> {ele?.notes}</td>
                          <td> {ele?.debit}</td>
                          <td> {ele?.credit}</td>

                          <td>
                            <button className="Posted">
                              {" "}
                              {ele?.tranStatus}
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoDataFound />
                  )}

                  <tr>
                    <td colSpan={12}>
                      <h6 className="mb-0">ADRJ: Revenue Adjustments</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="TextBlue">ADRJ: Revenue Adjustments</div>
                    </td>
                    <td>DP-ARB-1B-243-1</td>
                    <td>06/14/20 23</td>
                    <td>Adjustment</td>
                    <td>ADJ: Other Adjustments</td>
                    <td>$5,00.00</td>
                    <td>Price updates</td>
                    <td />
                    <td />
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <button className="Commited">Committed</button>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className="javascript:void(0);"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu">
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#PostTransaction"
                                  data-dismiss="modal"
                                >
                                  <img src="images/post_transaction.png" />
                                  Post Transaction
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/void_transaction.png" />
                                  Void Transaction
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="TextBlue">ADRJ: Revenue Adjustments</div>
                    </td>
                    <td>DP-ARB-1B-243-1</td>
                    <td>06/14/20 23</td>
                    <td>Direct Deposit</td>
                    <td>CO: Change Order Deposit</td>
                    <td>$15,000.00</td>
                    <td>Change Order full deposit</td>
                    <td />
                    <td />
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <button className="Void">Void</button>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="Button mt-5 d-flex justify-content-between">
              <button
                className="Create"
                onClick={hideContractTransactionsModal}
              >
                Save
              </button>
              <button
                className="Outline"
                onClick={hideContractTransactionsModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADDFOLDERSHOW */}

      <Modal
        show={addFolderModal}
        onHide={hideAddFolderModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddFolderModal}
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create Folder</h4>
            </div>
            <div class="FolderForm">
              <form>
                <div class="form-group">
                  <h6>Folder Name *</h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Insert new folder name"
                    name="folderName"
                    value={folderName}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {folderError?.folderNameError}
                  </span>
                </div>
                <div class="form-group">
                  <h6>Folder Permissions</h6>
                  <div class="PermissionsName">
                    <h5
                      class="FolderPermissionId"
                      data-toggle="modal"
                      data-target="#permissionModal2"
                      data-dismiss="modal"
                    >
                      <span>
                        <img src="images/Folder.png" />
                      </span>{" "}
                      Folder Permissions
                    </h5>
                  </div>
                </div>
                <div class="form-group">
                  <h6>Folder Actions</h6>
                  <div class="PermissionsName">
                    <h5
                      class="FolderPermissionId"
                      data-toggle="modal"
                      data-target="#folderActionModal2"
                      data-dismiss="modal"
                    >
                      <span>
                        <img src="images/Icon-4.png" />
                      </span>{" "}
                      Folder Actions
                    </h5>
                  </div>
                </div>
                <div class="Button text-center">
                  <button
                    class="Create"
                    // data-toggle="modal"
                    onClick={handleFolder}
                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* UPDATESATISFIEDCONDITIONMODAL */}

      <Modal
        show={updateSatisfiedConditionModal}
        onHide={hideUpdateSatisfiedConditionModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUpdateSatisfiedConditionModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4 className="abcgreen abcgreen11">General Information</h4>
              <h4 className="abcgreen abcgreen12" style={{ display: "block" }}>
                Conditional Details
              </h4>
              <h4 className="abcgreen abcgreen13">Adjustments and Deposit</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="ContactInformationArea">
                      <h6>Contact Information</h6>
                      <div className="ContactInformationBox">
                        <span className="circle">JL</span>
                        <div className="ContactInformationName">
                          <h6>John Lead</h6>
                          <p>
                            312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                            <img src="images/location.png" />
                          </p>
                          <p>
                            Phone: <span>(403)555-555</span>
                          </p>
                          <p>
                            Email: <span>John.lead@email.com</span>
                            <img src="images/fill_star.png" className="ml-2" />
                          </p>
                        </div>
                        <div className="ContactInformationIcon">
                          <a href="javascript:void(0);">
                            <img src="images/pdf.png" />
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/download.png" />
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/icon-75.png" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="TodosButtons mt-5">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link GeneralInformationBtnShowGreen"
                        data-toggle="tab"
                        href="#GeneralInformationGreen"
                      >
                        <img src="images/general_information_red.png" />
                        General Information
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active ConditionalDetailsBtnShowGreen"
                        data-toggle="tab"
                        href="#ConditionalDetailsGreen"
                      >
                        <img src="images/conditional_details.png" />
                        Conditional Details<span>3</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link AdjustmentsAndDepositBtnShowGreen"
                        data-toggle="tab"
                        href="#AdjustmentsAndDepositGreen"
                      >
                        <img src="images/adjustment_deposit.png" />
                        Adjustments and Deposits<span>3</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="LeadDetailsInput mt-4">
                  <div className="tab-content">
                    <div className="tab-pane fade" id="GeneralInformationGreen">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="CommonModalArea">
                            <div className="form-group">
                              <h5>Assigned to</h5>
                              <div className="CommonModalBox">
                                <h5
                                  className="CursorPointer FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#AssignSalesRepresentative2"
                                  data-dismiss="modal"
                                >
                                  <figure>
                                    <img src="images/icon-32.png" />
                                  </figure>
                                  <figure>
                                    <img src="images/refresh.png" />
                                  </figure>
                                  John Doe
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mx-auto">
                          <div className="form-group">
                            <h6>Sub Division</h6>
                            <div className="Categories Categories3">
                              <div className="Categories_all Categories_all3">
                                <span>Select SubDivision</span>
                                <img src="images/down.png" />
                              </div>
                              <ul>
                                <li>
                                  -List of Subdivisions offered by the builder
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 ml-auto">
                          <div className="CommonModalArea">
                            <div className="form-group">
                              <h5>Status</h5>
                              <div className="CommonModalBox">
                                <h5 className="">Interested</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <h6 className="mb-4">Realtor/Referrer</h6>
                        </div>
                        <div className="form-group col-lg-5">
                          <h6>Name</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Name"
                          />
                        </div>
                        <div className="form-group col-lg-5 ml-auto">
                          <h6>Agency</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Agency"
                          />
                        </div>
                        <div className="form-group col-lg-5">
                          <h6>Email</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Email"
                          />
                        </div>
                        <div className="col-12">
                          <div className="form-group ">
                            <div className="CommonModalArea">
                              <h5>Configure Client View</h5>
                              <div className="CommonModalBox">
                                <h5 className="">
                                  <figure>
                                    <img src="images/configure_client_view.png" />
                                  </figure>
                                  Configure Client View
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Button d-flex justify-content-between mt-4">
                        <button className="Create">Save</button>
                        <button className="Outline">Cancel</button>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade show active"
                      id="ConditionalDetailsGreen"
                    >
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <h6>Sale Type</h6>
                            <p className="ml-0">Multi-Family</p>
                          </div>
                          <div className="form-group d-flex align-items-end">
                            <div>
                              <h6>Client Assigned Unit</h6>
                              <p className="ml-0">
                                U222-CHI-2B-243 (Unit #222)
                              </p>
                            </div>
                            <div className="Button ml-1">
                              <button className="Create px-1">
                                Remove Assigned Unit
                              </button>
                            </div>
                          </div>
                          <div className="form-group">
                            <h6>Client Purchase Price</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Insert purchase price"
                            />
                          </div>
                          <div className="form-group">
                            <h6>Down Payment Percentage</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Insert deposit amount"
                            />
                          </div>
                          <div className="form-group">
                            <h6>Down Payment Percentage</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Down Payment Percentage"
                            />
                          </div>
                          <div className="ParkingSpacesUnitsArea">
                            <h5>Parking Spaces/Units</h5>
                            <div className="ParkingSpacesUnitsTable">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Description or Stall/Unit #</th>
                                    <th>Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="Action">
                                        Stall 228
                                        <img
                                          src="images/Cross.png"
                                          className="ml-4"
                                        />
                                      </div>
                                    </td>
                                    <td>$1,200.00</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <input
                                        type=""
                                        className="form-control"
                                        placeholder="Insert description or Stall/Unit #"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type=""
                                        className="form-control"
                                        placeholder="Insert quantity"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="Button mt-3">
                            <button className="Create">Add Parking</button>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="SatisfiedTab">
                            <table>
                              <thead>
                                <tr>
                                  <th>Satisfied Conditions</th>
                                  <th />
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                  <th>Authorization Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Deposit Paid</td>
                                  <td>
                                    <label className="CheckBox">
                                      <input type="checkbox" />
                                      <span className="checkmark" />
                                    </label>
                                  </td>
                                  <td>06/12/2023 - 02:34 pm</td>
                                  <td>
                                    <div className="Action">
                                      <img src="images/Lock.png" />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="Action OpacaityAction">
                                      <img
                                        src="images/unlock.png"
                                        className="mr-2"
                                      />
                                      Unlock condition
                                    </div>
                                  </td>
                                  <td>
                                    <div className="Action TextRed">
                                      Pending with John Manager{" "}
                                      <img
                                        src="images/view-po.png"
                                        data-toggle="modal"
                                        data-target="#ApproveUnlockCondition"
                                        data-dismiss="modal"
                                      />
                                    </div>
                                    <div className="ApproveRejectBtnArea">
                                      <button className="ApproveBtn">
                                        Approve
                                      </button>
                                      <button className="RejectBtn">
                                        Reject
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Deposit Paid</td>
                                  <td>
                                    <label className="CheckBox">
                                      <input type="checkbox" />
                                      <span className="checkmark" />
                                    </label>
                                  </td>
                                  <td>06/12/2023 - 02:34 pm</td>
                                  <td>
                                    <div className="Action">
                                      <img src="images/Lock.png" />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="Action OpacaityAction">
                                      <img
                                        src="images/unlock.png"
                                        className="mr-2"
                                      />
                                      Unlock condition
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Deposit Paid</td>
                                  <td>
                                    <label className="CheckBox">
                                      <input type="checkbox" />
                                      <span className="checkmark" />
                                    </label>
                                  </td>
                                  <td>06/12/2023 - 02:34 pm</td>
                                  <td>
                                    <div className="Action">
                                      <img src="images/Lock.png" />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="Action OpacaityAction">
                                      <img
                                        src="images/unlock.png"
                                        className="mr-2"
                                      />
                                      Unlock condition
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Deposit Paid</td>
                                  <td>
                                    <label className="CheckBox">
                                      <input type="checkbox" />
                                      <span className="checkmark" />
                                    </label>
                                  </td>
                                  <td>06/12/2023 - 02:34 pm</td>
                                  <td>
                                    <div className="Action">
                                      <img src="images/Lock.png" />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="Action OpacaityAction">
                                      <img
                                        src="images/unlock.png"
                                        className="mr-2"
                                      />
                                      Unlock condition
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Deposit Paid</td>
                                  <td>
                                    <label className="CheckBox">
                                      <input type="checkbox" />
                                      <span className="checkmark" />
                                    </label>
                                  </td>
                                  <td>06/12/2023 - 02:34 pm</td>
                                  <td>
                                    <div className="Action">
                                      <img src="images/Lock.png" />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="Action OpacaityAction">
                                      <img
                                        src="images/unlock.png"
                                        className="mr-2"
                                      />
                                      Unlock condition
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={5}>
                                    <div className="Button text-right">
                                      <button
                                        className="Create px-2 py-2 fw-14"
                                        data-toggle="modal"
                                        data-target="#UnlockCondition"
                                        data-dismiss="modal"
                                      >
                                        Request to Unlock
                                        <br /> Condition
                                      </button>
                                    </div>
                                  </td>
                                  <td />
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="text-right mt-5">
                            <button className="LockBtn">
                              <img src="images/lock_white.png" />
                              Lock all Conditions
                            </button>
                            <button className="UnlockBtn">
                              <img src="images/unlock_white.png" />
                              Unlock all Conditions
                            </button>
                          </div>
                          <div className="row mt-5">
                            <div className="col-lg-4 mx-auto">
                              <div className="form-group">
                                <div className="CommonModalArea">
                                  <h5>Authorization Status</h5>
                                  <div className="CommonModalBox">
                                    <h5> Pending with John Manager</h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 mx-auto">
                              <div className="form-group">
                                <div className="CommonModalArea">
                                  <h5>Client Documentation</h5>
                                  <div className="CommonModalBox">
                                    <h5>
                                      <figure>
                                        <img src="images/icon-63.png" />
                                      </figure>{" "}
                                      Client Documentation
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="AdjustCostBreakdown AdjustCostBreakdownGreen mt-5">
                        <div className="TableHeader">
                          <h6 id="GrossMarginBtn" className="CursorPointer">
                            Gross margin{" "}
                            <img src="images/black-down-arrow.png" />
                          </h6>
                        </div>
                        <div className="CostBreandownTable CostBreandownTableShow">
                          <table>
                            <tbody>
                              <tr className="MiddleGreen">
                                <td>Revenue and Gross Margin Analysis</td>
                                <td>Budget</td>
                                <td>Accounting Actuals</td>
                                <td />
                                <td />
                              </tr>
                              <tr className="LightGreen">
                                <td>
                                  Gross Parking Stalls/Pads Margin (% of Gross
                                  Margin)
                                </td>
                                <td />
                                <td>$1,200.00</td>
                                <td>0.24%</td>
                                <td />
                              </tr>
                              <tr>
                                <td>Contract Revenue (% Contract Margin)</td>
                                <td>$500,000.00</td>
                                <td>$500,000.00</td>
                                <td>100.00%</td>
                                <td />
                              </tr>
                              <tr>
                                <td className="pl-3">
                                  Less: Design, Development, Admin Costs
                                </td>
                                <td>$500,000.00</td>
                                <td>$500,000.00</td>
                                <td />
                                <td />
                              </tr>
                              <tr>
                                <td className="pl-3">
                                  Less: Construction Costs
                                </td>
                                <td>$500,000.00</td>
                                <td>$500,000.00</td>
                                <td />
                                <td />
                              </tr>
                              <tr className="LightGreen">
                                <td>
                                  Gross Contract Margin (% of Gross Margin)
                                </td>
                                <td>$40,000.00</td>
                                <td>$500,000.00</td>
                                <td>92.00%</td>
                                <td />
                              </tr>
                              <tr className="MiddleGreenBlank">
                                <td colSpan={5}>blank</td>
                              </tr>
                              <tr>
                                <td>Contract Total Income (Projected)</td>
                                <td>$501,700.00</td>
                                <td>$501,700.00</td>
                                <td />
                                <td />
                              </tr>
                              <tr>
                                <td>
                                  Less: Total Design, Develop, Admin
                                  Construction Costs
                                </td>
                                <td>$460,000.00</td>
                                <td>$0.00</td>
                                <td>($460,000.00)</td>
                                <td>-100.00</td>
                              </tr>
                              <tr className="DarkGreen">
                                <td>
                                  Gross Budgeted Margin / Gross Actual Margin
                                  (Projected)
                                </td>
                                <td>$41,700.00(8.31%)</td>
                                <td>$501,700.00(-)</td>
                                <td />
                                <td />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="Button mt-4 d-flex justify-content-between">
                        <button
                          className="Create"
                          data-toggle="modal"
                          data-target="#ClientContractDetailsNoUnit"
                          data-dismiss="modal"
                        >
                          Save
                        </button>
                        <button
                          className="Outline"
                          data-toggle="modal"
                          data-target="#ClientContractDetailsNoUnit"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="AdjustmentsAndDepositGreen"
                    >
                      <div className="SchedulePaymentsTableMain">
                        <div className="TableHeader">
                          <h6>Schedule New Payment</h6>
                        </div>
                        <div className="SchedulePaymentsTable SchedulePaymentsTableShow TableMinimumHeight2">
                          <table>
                            <thead>
                              <tr>
                                <th>Schedule Date</th>
                                <th>Transaction Type</th>
                                <th>Notes</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div>
                                    <input
                                      type="date"
                                      className="form-control"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="SelectAnOption"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="dropdown">
                                      <button
                                        className=""
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        Select Transaction Type
                                        <img src="images/black-down-arrow.png" />
                                      </button>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          <b>Sales</b>
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -ADJ: Other Ajustments
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -ADRJ: Revenue Ajustments
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -CO: Change Order Deposit
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -DP: Down Payment Deposit
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -OP: Offer to Purchase
                                        </a>
                                        <a
                                          className="dropdown-item fw-16"
                                          href="javascript:void(0);"
                                        >
                                          <b>Accounting</b>
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Balance Due on Closing
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Closing Transactions
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Revenue - Change Order
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Revenue - Contract Price (Pre-Tax)
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Revenue - Parking
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Tax - Base
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Tax - Rebate
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <input
                                      type="text"
                                      className="form-control width-50 mx-auto"
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  <div className="TableButtons">
                                    <button className="BrandBtn">
                                      Schedule New Payment
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="SchedulePaymentsTableMain mt-5">
                        <div className="TableHeader">
                          <h6 className="SchedulePaymentsBtn">
                            Schedule Payments{" "}
                            <img src="images/down-arrow.png" />
                          </h6>
                          <div className="Actions">
                            <a href="javascript:void(0);" title="pdf">
                              <img src="images/icon-53.png" />
                            </a>
                            <a href="javascript:void(0);" title="download">
                              <img src="images/download.png" />
                            </a>
                            {/* <a href="javascript:void(0);" title="settings">
                              <img src="images/setting.png" />
                            </a> */}
                          </div>
                        </div>
                        <div className="SchedulePaymentsTable SchedulePaymentsTableShow">
                          <table>
                            <thead>
                              <tr>
                                <th>Scheduled Payment Date</th>
                                <th>Transaction Type</th>
                                <th>Total</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>06/30/2023</td>
                                <td>DP:Down Payment Deposit</td>
                                <td>$ 30,000.00</td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/work-order-black.png" />
                                              Edit Scheduled Payment
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/dropdown-delete.png" />
                                              Remove Scheduled Payment
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/dropdown-delete.png" />
                                              Create New Transaction Entry
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/dropdown-delete.png" />
                                              Set Payment Reminder
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="SchedulePaymentsTableMain  mt-5">
                        <div className="TableHeader">
                          <h6>Add New Transactions</h6>
                          <div className="Actions">
                            <a href="javascript:void(0);">
                              <img src="images/icon-53.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/download.png" />
                            </a>
                            {/* <a href="javascript:void(0);">
                              <img src="images/setting.png" />
                            </a> */}
                          </div>
                        </div>
                        <div className="SchedulePaymentsTable TransactionTableShow TableMinimumHeight2">
                          <table>
                            <thead>
                              <tr>
                                <th>Transaction Date</th>
                                <th>Payment Method</th>
                                <th>Transaction Type</th>
                                <th>Total</th>
                                <th>Notes</th>
                                <th>Supporting Documents</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>06/12/2023</td>
                                <td>
                                  <div className="SelectAnOption">
                                    <div className="dropdown">
                                      <button
                                        className=""
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        Select an option
                                        <img src="images/black-down-arrow.png" />
                                      </button>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Ajustments
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -American Express
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Bank Draft
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Cash
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Cheque
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Direct Deposit
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Joural Entry
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Mastercard
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -Visa
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="SelectAnOption">
                                    <div className="dropdown">
                                      <button
                                        className=""
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        Select Transaction Type
                                        <img src="images/black-down-arrow.png" />
                                      </button>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          className="dropdown-item fw-16"
                                          href="javascript:void(0);"
                                        >
                                          <b>Sales</b>
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -ADJ: Other Ajustments
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -ADRJ: Revenue Ajustments
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -CO: Change Order Deposit
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -DP: Down Payment Deposit
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                        >
                                          -OP: Offer to Purchase
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert Amount"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert notes"
                                  />
                                </td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocs2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentList"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td />
                              </tr>
                              <tr>
                                <td colSpan={6}>
                                  <div className="TableButtons">
                                    <button className="BrandBtn">
                                      Create New Transaction
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="SchedulePaymentsTableMain mt-5">
                        <div className="TableHeader">
                          <h6 className="TransactionBtn">
                            Transactions
                            <img src="images/down-arrow.png" />
                          </h6>
                          <div className="Actions">
                            <a
                              href="transaction_manager.html"
                              title="Transaction Module"
                            >
                              <img src="images/add_transactions.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/icon-53.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/download.png" />
                            </a>
                            {/* <a href="javascript:void(0);">
                              <img src="images/setting.png" />
                            </a> */}
                          </div>
                        </div>
                        <div className="SchedulePaymentsTable TransactionTableShow">
                          <table>
                            <thead>
                              <tr>
                                <th>Transaction ID#</th>
                                <th>Transaction Date</th>
                                <th>Payment Method</th>
                                <th>Transaction Type</th>
                                <th>Total</th>
                                <th>Notes</th>
                                <th>Supporting Documents</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>ADJR-ARB-1B-243-1</td>
                                <td>06/12/2023</td>
                                <td>American Express</td>
                                <td>ADRJ: Revenue Adjustments</td>
                                <td>$ 500.00</td>
                                <td>Price Updates</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocs2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentList"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <button className="Commited">Commited</button>
                                </td>
                              </tr>
                              <tr>
                                <td>DP-ARB-1B-243-2</td>
                                <td>06/12/2023</td>
                                <td>Cheque</td>
                                <td>DP : Down Payment Depsoit</td>
                                <td>$ 45,000.00</td>
                                <td>Cheque number #123</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocs2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentList"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <button className="Commited">Commited</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="AdjustCostBreakdown mt-5">
                        <div className="TableHeader">
                          <h6 id="CostBreandownBtn" className="CursorPointer">
                            Cost Breakdown{" "}
                            <img src="images/black-down-arrow.png" />
                          </h6>
                        </div>
                        <div className="CostBreandownTable CostBreandownTableShow">
                          <table>
                            <tbody>
                              <tr className="MiddleBlue">
                                <td>Contract Price Before Tax</td>
                                <td>$500,000.00</td>
                              </tr>
                              <tr className="LightBlue">
                                <td>Change Order Total</td>
                                <td>$10,825.00</td>
                              </tr>
                              <tr className="LightBlue">
                                <td>Parking (Stall 229)</td>
                                <td>$1,200.00</td>
                              </tr>
                              <tr className="LightBlue">
                                <td>Adjustment: Price Updates</td>
                                <td>$500.00</td>
                              </tr>
                              <tr className="MiddleBlue">
                                <td>Sub Total</td>
                                <td>$512,525.00</td>
                              </tr>
                              <tr className="LightBlue">
                                <td>Tax 5%</td>
                                <td>$25,626.25</td>
                              </tr>
                              <tr className="DarkBlue">
                                <td>Total</td>
                                <td>$538,151.25</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="Button mt-4 d-flex justify-content-between">
                        <button
                          className="Create"
                          data-toggle="modal"
                          data-target="#ClientContractDetailsNoUnit"
                          data-dismiss="modal"
                        >
                          Save
                        </button>
                        <button
                          className="Outline"
                          onClick={hideUpdateSatisfiedConditionModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <hr className="HrLine" />
            <div class="DiscussionArea">
              <div class="DiscussionTitle">
                <h4>Discussion</h4>
                <span>{discussionList?.length}</span>
                <div class="DiscussionChat">
                  <img
                    src={require("../assets/images/down.png")}
                    class="DiscussionIcon"
                  />
                  <a href="#">
                    <img src={require("../assets/images/pdf2.png")} />
                  </a>
                </div>
              </div>
              <div class="DiscussionHideArea" style={{ display: "block" }}>
                <div class="DiscussionBox">
                  <span>FM</span>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      value={discussionState?.message}
                      onChange={(e) =>
                        updateDiscussionState({
                          ...discussionState,
                          message: e.target.value,
                        })
                      }
                      placeholder="Add a Comment ....."
                    />
                    {discussionState.discussionError &&
                      !discussionState.message && (
                        <p style={{ color: "red" }}>
                          {discussionState.discussionError}
                        </p>
                      )}

                    <div class="DiscussionOverlay">
                      <a
                        onClick={() =>
                          updateDiscussionState({
                            ...discussionState,
                            message: "",
                          })
                        }
                        class="CancelChat"
                      >
                        <img
                          src={require("../assets/images/plus-cancel1.png")}
                        />
                      </a>
                      <a onClick={handleAddDiscussion} class="SendChat">
                        <img
                          src={require("../assets/images/forwardbutton.png")}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                {discussionList?.map((item, i) => (
                  <>
                    <div class="DiscussionBox">
                      <span>CO</span>
                      <div class="DiscussionRight">
                        <h6>{item?.message}</h6>
                        <small>{moment(item.updatedAt).format("lll")}</small>
                      </div>
                    </div>
                    <div class="ReplyArea">
                      <a
                        onClick={(e) => {
                          updateReplyField({ flag: true, index: i });
                          updateReplyState({ ...replyState, message: "" });
                        }}
                      >
                        Reply
                      </a>
                      {item?.ReplyDiscussions?.map((ele) => (
                        <div class="DiscussionBox">
                          <span>RE</span>
                          <div class="DiscussionRight">
                            <h6>{ele?.message}</h6>
                            <small>{moment(ele.updatedAt).format("lll")}</small>
                          </div>
                        </div>
                      ))}

                      {replyField.index == i && (
                        <div class="DiscussionBox w-100">
                          <span>FM{item?.id}</span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={replyState?.message}
                              onChange={(e) =>
                                updateReplyState({
                                  ...replyState,
                                  message: e.target.value,
                                  messageId: item?.id,
                                  jobId: item?.jobId,
                                })
                              }
                              placeholder="Add reply ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddReply} class="SendChat">
                                <img
                                  src={require("../assets/images/forwardbutton.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateSatisfiedConditionModal}
        onHide={hideUpdateSatisfiedConditionModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUpdateSatisfiedConditionModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Statement of Adjustments</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading">
                  <h3>
                    <span>Status: Pending Accountant Release</span>
                  </h3>
                </div>
                <div className="AgreementViewArea">
                  <div className="AgreementViewTop">
                    <a href="javscript:void(0)">
                      <img src="images/modal-big-logo.png" />
                    </a>
                    <div className="AgreementViewTopRight">
                      <h3>iBuild</h3>
                      <p>312 Weddenburn Rd SE</p>
                      <p>Calgary, AB CA, T2J 1J1</p>
                      <p>
                        4034005933 www.ibuildapplications.com
                        linden@ibuildapplications.com
                      </p>
                      <p>
                        “The bitterness of poor quality remains long after the
                        sweetness of meeting
                        <br />
                        the schedule has been gorgotten”
                      </p>
                    </div>
                  </div>
                  <div className="StatementAdjustmentArea px-4">
                    <h6>Statement of Adjustments</h6>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Client Name</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Address</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>State/Provice</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Possession Date</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Date Prepared</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Job Number</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Sales Consultant</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Model</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Contract Date</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="Title">JOB DETAILS</h6>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Address</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>State/Provice</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Subdivision</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Lot</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Block</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Plan</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Sale Type</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="Title">Sale Revenues</h6>
                        <div className="StatementAdjustmentBottom">
                          <label>
                            Contract Purchase Agreement Price (Pre-Tax)
                          </label>
                          <p className="LightGreen">$500,000.00</p>
                        </div>
                        <h6 className="Title">ADD: Tax</h6>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>ADD: Tax</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="StatementAdjustmentBottom">
                          <label>Applied Tax</label>
                          <p className="LightGrey">5.00%</p>
                          <p className="LightRed">$25,000.00</p>
                        </div>
                        <h6 className="Title">Less: Deposit</h6>
                        <div className="StatementAdjustmentTable">
                          <table>
                            <thead>
                              <tr>
                                <th>Notes</th>
                                <th>Transaction ID#</th>
                                <th>Date</th>
                                <th>Tx</th>
                                <th>Tx Type</th>
                                <th>
                                  Supporting <br /> Documents
                                </th>
                                <th>Status</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Condition Removal Deposit</td>
                                <td>OP-CHI-2B-243-1</td>
                                <td>03/30/20 21</td>
                                <td>CH</td>
                                <td>OP</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocsStatement2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentListStatement"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>Commited</td>
                                <td>
                                  <div className="BgBlue">$35,000.00</div>
                                </td>
                              </tr>
                              <tr>
                                <td>Construction Drawings</td>
                                <td>OP-CHI-2B-243-2</td>
                                <td>06/30/20 21</td>
                                <td>D</td>
                                <td>DP</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocsStatement2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentListStatement"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>Commited</td>
                                <td>
                                  <div className="BgBlue">$35,000.00</div>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={6}>
                                  Down Payment Amount Required
                                </td>
                                <td colSpan={2}>
                                  <div className="BgBlue">$0.00</div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={6}>Total Deposit</td>
                                <td colSpan={2}>
                                  <div className="BgBlue">$55,000.00</div>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <h6 className="Title">Summary</h6>
                        <div className="StatementAdjustmentBottom">
                          <label>
                            Contract Purchase Agreement Price (Pre-Tax)
                          </label>
                          <p className="LightGreen">$500,000.00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ChangeOrderCreatArea mt-5">
                    <div className="row">
                      <div className="col-lg-12"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ml-auto">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            {/* <div class="InsertSignature">
                            </div> */}
                            <label>John Doe</label>
                            <label>Company Accountant</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ml-auto">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>John Doe</label>
                            <label>Company Accountant</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" data-dismiss="modal">
                    Print
                  </button>
                  <button
                    className="Create FolderPermissionId"
                    data-toggle="modal"
                    data-target="#StatementOfAdjustmentsSaveSignature"
                    data-dismiss="modal"
                  >
                    Save
                  </button>
                  <button
                    className="Create FolderPermissionId"
                    data-toggle="modal"
                    data-target="#SoaHistory1"
                    data-dismiss="modal"
                  >
                    <img src="images/history_icon.png" />
                    History
                  </button>
                  <button className="Outline" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addUnlockConditionalModal}
        onHide={hideUnlockConditionModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUnlockConditionModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Unlock Condition</h4>
            </div>
            <div className="FolderForm">
              <div className="LeadDetailsInput mt-4">
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <h6>Client Assigned Unit</h6>
                      <p className="ml-0">U222-CHI-2B-243 (Unit #222)</p>
                    </div>
                    <div className="form-group">
                      <h6>
                        Unlock Reason <span className="required">*</span>
                      </h6>
                      <textarea
                        className="form-control"
                        rows={6}
                        placeholder="Insert reason of the unlock request"
                        name="reason"
                        value={reason}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group">
                      <h6>Condition(s) to Unlock:</h6>
                      <p className="ml-0">Deposit Paid</p>
                    </div>
                    <div className="form-group">
                      <h6>
                        Request Approver <span>*</span>
                      </h6>
                      <div className="Categories Categories2">
                        <div className="Categories_all Categories_all2">
                          <select
                            name="requestApproverId"
                            value={requestApproverId}
                            onChange={handleInput}
                            // disabled={modalState === true}
                          >
                            <option>Select Staff </option>
                            {checkArray(staffListData?.staff) ? (
                              <>
                                {staffListData?.staff?.map((ele, i) => {
                                  return (
                                    <option value={ele?.id}>
                                      {ele?.email}
                                    </option>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                          <img src="images/down.png" />
                        </div>
                        {/* <ul>
                  <li>
                    - List of Staff Members authorized to perform this action
                    loaded from the system
                  </li>
                </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button onClick={addUnlockConditionFunc} className="Create">
                    Send Unlock Request
                  </button>
                  <button
                    className="Outline"
                    onClick={hideUnlockConditionModal}
                  >
                    {" "}
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={unlockConditonListModal}
        onHide={hideUnlockConditionListModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUnlockConditionListModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Unlock Conditions Approval Table</h4>
            </div>
            <div className="TableList TableListAlign CommonHeading TableMinimumHeight">
              <table>
                <thead>
                  <tr>
                    <th>Date Requested</th>
                    <th>Date Approved/Rejected</th>
                    <th>Reason</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(unlockListData?.satisfiedUnlockConditions) ? (
                        unlockListData?.satisfiedUnlockConditions?.map(
                          (ele, ind) => {
                            return (
                              <>
                                <tr>
                                  <td>{ele?.requestedDate?.split("T")[0]}</td>
                                  <td>{ele?.createdAt?.split("T")[0]}</td>
                                  <td>{ele?.unlockReason}</td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            {/* <li>
                      <a
                        href="javascript:void(0);"
                        className="FolderPermissionId"
                        // onClick={() =>  }
                      >
                        <img src="images/view-p o.png" />
                        View
                      </a>
                    </li> */}
                                            <li>
                                              <a
                                                onClick={() =>
                                                  sendApproveUnlockFunc(
                                                    ele?.id,
                                                    "APPROVE"
                                                  )
                                                }
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/post_transaction.png" />
                                                Approval
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  sendApproveUnlockFunc(
                                                    ele?.id,
                                                    "REJECT"
                                                  )
                                                }
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/Action-2.png" />
                                                Reject
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        )
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {/* <div className="PaginationArea mt-3">
    <h6>Showing 1 to 10 of 27 entries</h6>
    <h6>
      <img src="images/leftarrow.png" /> 01 <img src="images/rightarrow.png" />{" "}
      Out 10
    </h6>
  </div> */}
            <div className="Button  text-center mt-4">
              <button
                className="Outline"
                data-toggle="modal"
                data-target="#ClientContractDetailsNoUnit"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={clientAssignUnitModal}
        onHide={hideClientAssignUnitMOdal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideClientAssignUnitMOdal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Assign Unit</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="ContactInformationArea">
                      <h6>Contact Information</h6>
                      <div className="ContactInformationBox">
                        <span className="circle">JL</span>
                        <div className="ContactInformationName">
                          <h6>John Lead</h6>
                          <p>
                            312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                            <img src="images/location.png" />
                          </p>
                          <p>
                            Phone: <span>(403)555-555</span>
                          </p>
                          <p>
                            Email: <span>John.lead@email.com</span>
                          </p>
                        </div>
                        <div className="ContactInformationIcon">
                          <a href="javascript:void(0);" title="pdf">
                            <img src="images/pdf.png" />
                          </a>
                          <a href="javascript:void(0);" title="download">
                            <img src="images/download.png" />
                          </a>
                          <a
                            href="javascript:void(0);"
                            title="client documentation"
                          >
                            <img src="images/icon-75.png" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="LeadDetailsInput mt-4">
                  <div className="row">
                    <div className="col-md-5 mx-auto">
                      <div className="form-group">
                        <h6>Sales Type</h6>
                        <div className="Categories Categories3">
                          <div className="Categories_all Categories_all3">
                            <select
                              name="saleType"
                              value={saleType}
                              onChange={handleInput}
                            >
                              <option>Select Sale Type</option>
                              {checkArray(salesTypeListData?.saleTypes) ? (
                                <>
                                  {salesTypeListData?.saleTypes?.map(
                                    (ele, i) => {
                                      // le('elelelele',ele[0])
                                      return (
                                        <option value={ele[0]}>{ele[1]}</option>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                            <img src="images/down.png" />
                          </div>
                          {/* <ul>
                  <li>
                    -List of sales type for that company loaded from the systems
                    (for example Multi-Family, Residential)
                  </li>
                </ul> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 mx-auto">
                      <div className="form-group">
                        <h6>Assign Unit/Home to Client</h6>
                        <div className="Categories Categories2">
                          <div className="Categories_all Categories_all2">
                            <select
                              name="jobId"
                              value={jobId}
                              onChange={handleInput}
                            >
                              <option>Select Assigned Unit</option>
                              {saleType === "Lot" ? (
                                <>
                                  {checkArray(
                                    clientAssignedListData?.lotUnit
                                  ) ? (
                                    <>
                                      {clientAssignedListData?.lotUnit?.map(
                                        (ele, i) => {
                                          // le('elelelele',ele[0])
                                          return (
                                            <option value={ele?.id}>
                                              {ele?.jobNumber}
                                            </option>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {saleType === "ResidentialJob" ? (
                                    checkArray(
                                      clientAssignedListData?.residentialUnit
                                    ) ? (
                                      <>
                                        {clientAssignedListData?.residentialUnit?.map(
                                          (ele, i) => {
                                            // le('elelelele',ele[0])
                                            return (
                                              <option value={ele?.id}>
                                                {ele?.jobNumber}
                                              </option>
                                            );
                                          }
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <>
                                      {checkArray(
                                        clientAssignedListData?.multiUnit
                                      ) ? (
                                        <>
                                          {clientAssignedListData?.multiUnit?.map(
                                            (ele, i) => {
                                              // le('elelelele',ele[0])
                                              return (
                                                <option value={ele?.id}>
                                                  {ele?.jobNumber}
                                                </option>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </select>
                            <img src="images/down.png" />
                          </div>
                          {/* <ul>
                  <li>
                    -List of available units (no client assined) loaded from the
                    system based on the sale type selection
                  </li>
                </ul>*/}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-11 mx-auto">
                      <div className="Button d-flex justify-content-between">
                        <button
                          className="Create"
                          onClick={updateAssignUnitFunc}
                        >
                          Assign Unit
                        </button>
                        <button className="Outline" data-dismiss="modal">
                          {" "}
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={SOAModal}
        onHide={hideSOAModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSOAModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Statement of Adjustments</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading">
                <h3>
                  <span>Status:{SOADetailsData?.soaData?.status}</span>
                </h3>
              </div>
              <div className="AgreementViewArea">
                <div className="AgreementViewTop">
                  <a href="javscript:void(0)">
                    <img src="images/modal-big-logo.png" />
                  </a>
                  <div className="AgreementViewTopRight">
                    <h3>iBuild</h3>
                    <p>312 Weddenburn Rd SE</p>
                    <p>Calgary, AB CA, T2J 1J1</p>
                    <p>
                      4034005933 www.ibuildapplications.com
                      linden@ibuildapplications.com
                    </p>
                    <p>
                      “The bitterness of poor quality remains long after the
                      sweetness of meeting
                      <br />
                      the schedule has been gorgotten”
                    </p>
                  </div>
                </div>
                <div className="StatementAdjustmentArea px-4">
                  <h6>Statement of Adjustments</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Client Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            SOADetailsData?.contract?.clients?.[0]?.legalName
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            SOADetailsData?.contract?.clients?.[0]
                              ?.streetAddress
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>City</label>
                        <input
                          type="text"
                          className="form-control"
                          value={SOADetailsData?.contract?.clients?.[0]?.city}
                        />
                      </div>
                      <div className="form-group">
                        <label>State/Provice</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            SOADetailsData?.contract?.clients?.[0]?.province
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Possession Date</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            SOADetailsData?.contract?.clients?.[0]?.createdAt?.split(
                              "T"
                            )[0]
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* <div className="form-group">
                <label>Date Prepared</label>
                <input type="text" className="form-control" />
              </div> */}
                      <div className="form-group">
                        <label>Job Type</label>
                        <input
                          type="text"
                          className="form-control"
                          value={SOADetailsData?.contract?.jobType}
                        />
                      </div>
                      <div className="form-group">
                        <label>Sales Consultant</label>
                        <input type="text" className="form-control"
                         value={
                            SOADetailsData?.contract?.ContractAssignees?.[0]?.User?.name??"N/A"
                          } />
                      </div>
                      <div className="form-group">
                        <label>Model</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label>Contract Date</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            SOADetailsData?.contract?.createdAt?.split("T")[0]
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="col-12">
              <h6 className="Title">JOB DETAILS</h6>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>Address</label>
                <input type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label>City</label>
                <input type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label>State/Provice</label>
                <input type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label>Subdivision</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>Lot</label>
                <input type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label>Block</label>
                <input type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label>Plan</label>
                <input type="text" className="form-control" />
              </div>
              <div className="form-group">
                <label>Sale Type</label>
                <input type="text" className="form-control" />
              </div>
            </div> */}
                    <div className="col-12">
                      <h6 className="Title">Sale Revenues</h6>
                      <div className="StatementAdjustmentBottom">
                        <label>
                          Contract Purchase Agreement Price (Pre-Tax)
                        </label>
                        <p className="LightGreen">
                          {SOADetailsData?.contract?.clientPurchasePrice}
                        </p>
                      </div>
                      <h6 className="Title">ADD: Tax</h6>
                    </div>
                    {/* <div className="col-md-6">
              <div className="form-group">
                <label>ADD: Tax</label>
                <input type="text" className="form-control" />
              </div>
            </div> */}
                    <div className="col-12">
                      {SOADetailsData?.Ajson &&
                        SOADetailsData?.Ajson?.length > 0 &&
                        SOADetailsData?.Ajson?.map((ele, i) => {
                       
                          return (
                            <div className="StatementAdjustmentBottom">
                              <label>{ele?.label}</label>
                              {/* <label></label> */}
                              <p className="LightGrey">{(ele?.amount)}</p>
                            </div>
                          );
                        })}

                      {/* <h6 className="Title">Less: Deposit</h6> */}
                      <div className="StatementAdjustmentTable">
                        <table>
                          <thead>
                            <tr>
                              <th>Notes</th>
                              <th>Transaction ID#</th>
                              <th>Date</th>
                              <th>Tx</th>
                              {/* <th>Tx Type</th> */}
                              {/* <th>
                        Supporting <br /> Documents
                      </th> */}
                              <th>Status</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {checkArray(SOADetailsData?.transactions) ? (
                              SOADetailsData?.transactions?.map((ele, i) => {
                                return (
                                  <tr>
                                    <td>{ele?.notes}</td>
                                    <td>{ele?.tranId}</td>
                                    <td>{ele?.createdAt?.split("T")[0]}</td>
                                    <td>{ele?.tranType}</td>
                                    {/* <td>{ele?.tranStatus}</td> */}
                                    {/* <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#AddNewSupportingDocsStatement2"
                          >
                            <img src="images/icon-63.png" />
                          </a>
                          <a
                            href="javascript:void(0)"
                            data-toggle="modal"
                            data-target="#SupportingDocumentListStatement"
                          >
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td> */}
                                    <td>{ele?.tranStatus}</td>
                                    <td>
                                      <div className="BgBlue">{ele?.total}</div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <>
                                {" "}
                                <NoDataFound />
                              </>
                            )}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={6}>Down Payment Amount Required</td>
                              <td colSpan={2}>
                                <div className="BgBlue">
                                  {SOADetailsData?.contract?.downPayPercent}
                                </div>
                              </td>
                            </tr>
                            {/* <tr>
                              <td colSpan={6}>Total Deposit</td>
                              <td colSpan={2}>
                                <div className="BgBlue">
                                  {SOADetailsData?.contract?.depositAmount}
                                </div>
                              </td>
                            </tr> */}
                          </tfoot>
                        </table>
                      </div>
                      {/* <h6 className="Title">Summary</h6> */}
                      {/* <div className="StatementAdjustmentBottom">
                <label>Contract Purchase Agreement Price (Pre-Tax)</label>
                <p className="LightGreen">$500,000.00</p>
              </div> */}
                    </div>
                  </div>
                </div>
                <div className="ChangeOrderCreatArea mt-5">
                  <div className="row">
                    <div className="col-lg-12"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="text" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="text" />
                          {/* <div class="InsertSignature">
                            </div> */}
                          <label>John Doe</label>
                          <label>Company Accountant</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="text" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="text" />
                          <label>John Doe</label>
                          <label>Company Accountant</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Create" data-dismiss="modal">
                  Print
                </button>
                <button
                  className="Create FolderPermissionId"
                  onClick={() => addSOAFunc("SOA")}
                >
                  Save
                </button>
                {SOADetailsData?.soaData ? (
                  <button
                    className="Create FolderPermissionId"
                    onClick={() =>
                      showSOAHistoryModal("SOA", SOADetailsData?.soaData?.id)
                    }
                  >
                    <img src="images/history_icon.png" />
                    History
                  </button>
                ) : (
                  <></>
                )}

                <button className="Outline" onClick={hideSOAModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={SOAHistoryModal}
        onHide={hideSOAHistoryModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSOAHistoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>SOA History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                {/* <h6>
        <span>Job Id:</span>&nbsp;U231-CHI-2B-243
      </h6> */}
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll TableLeft">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(SOAHistoryListData?.soaHistories) &&
                    SOAHistoryListData?.soaHistories?.map((ele, i) => {
                      return (
                        <>
                          <tr>
                            <td>{ele?.modifiedBy}</td>
                            <td>{ele?.createdBy}</td>
                            <td>{ele?.createdAt?.split("T")[0]}</td>
                            <td>{ele?.activity}</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CTCTemplateReleasedModal}
        onHide={handleCTCTemplateReleasedModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleCTCTemplateReleasedModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>CTC Template Released</h4>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>CTC Template</th>
                    <th>Released By</th>
                    <th>Data Released</th>
                    <th>Status</th>
                    <th>Select CTC</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!loaderRelease ? (
                    releaseList?.ctcTemplates?.length > 0 ? (
                      releaseList?.ctcTemplates?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.name}</td>
                            <td>{item.updateBy ?? "N/A"}</td>
                            <td>
                              {moment(item.releasedDate).format("YYYY-MM-DD")}
                            </td>
                            <td>{item.ctcTemplatesStatus}</td>
                            <td>
                              ---
                              <a
                                href="javascript:void(0);"
                                className="FolderPermissionId"
                                onClick={() =>
                                  showCreateClientCTCModal(item.id)
                                }
                              >
                                <img src="images/Action-1.png" />
                              </a>
                            </td>
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth">
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleRemoveRelease(item?.id)
                                          }
                                        >
                                          <img src="images/dropdown-delete.png" />
                                          Delete
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      "No Data found"
                    )
                  ) : (
                    <Loader />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ViewCtcModal}
        onHide={handleViewCtcModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewCtcModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View Client's CTC</h4>
            </div>
            <div className="FolderForm">
              <div className="ContactInformationArea">
                <h6>Contact Information</h6>
                <div className="ContactInformationBox">
                  <span className="circle">JL</span>
                  <div className="ContactInformationName">
                    <h6>{allClientListData?.clientDetails?.[0]?.legalName}</h6>
                    <p>
                      {allClientListData?.clientDetails?.[0]?.streetAddress},{" "}
                      {allClientListData?.clientDetails?.[0]?.city}{" "}
                      <img src="images/location.png" />
                    </p>
                    <p>
                      Phone:{" "}
                      <span>
                        {
                          allClientListData?.clientDetails?.[0]
                            ?.businessPhoneNum
                        }
                      </span>
                    </p>
                    <p>
                      Email:{" "}
                      <span>
                        {allClientListData?.clientDetails?.[0]?.email}
                      </span>
                    </p>
                  </div>
                  <div className="AssignUnit">
                    <h4>Assigned Unit</h4>
                    <p>{contactDetails?.jobNumber}</p>
                  </div>
                </div>
              </div>
              <div className="AgreementViewAreaGreen mt-3">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Pictures</th>
                      <th>Unit Cost</th>
                      <th>Unit Discount</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkArray(clientCtcByTemplateData?.formatedData) &&
                      clientCtcByTemplateData?.formatedData?.map((ele, i) => {
                        return (
                          <>
                            <tr className="DarkBlueBg" key={`ele-${i}`}>
                              <td colSpan={6}>{ele?.name}</td>
                            </tr>
                            {checkArray(ele?.assemblies) &&
                              ele?.assemblies?.map((item, j) => {
                                return (
                                  <>
                                    <tr
                                      className="LightBlueBg"
                                      key={`item-${j}`}
                                    >
                                      <td>
                                        {/* <label className="CheckBox">
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label> */}
                                      </td>
                                      <td colSpan={6}>{item?.name}</td>
                                    </tr>
                                    {checkArray(item?.purchaseDetails) &&
                                      item?.purchaseDetails?.map(
                                        (result, k) => {
                                          return (
                                            <tr key={`result-${k}`}>
                                              <td>{result?.name}</td>
                                              <td>
                                                <div>
                                                  <img
                                                    src="images/picture-1.png"
                                                    alt="Thumbnail"
                                                  />
                                                </div>
                                              </td>
                                              <td>{result?.clientTotal}</td>
                                              <td>{result?.unitDiscount}</td>

                                              <td>{result?.quantity}</td>

                                              <td>
                                                {result?.quantity *
                                                  result?.clientTotal -
                                                  result?.unitDiscount}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </>
                                );
                              })}
                          </>
                        );
                      })}
                  </tbody>
                </table>
                <div className="CostBreakDownArea">
                  <h5 className="TableHeading CursorPointer" id="CostBreakDown">
                    Cost Breakdown <img src="images/down-arrow.png" />
                  </h5>
                  <div className="CostBreakDownShow">
                    <div className="Box">
                      <h6>Selections Details</h6>
                    </div>
                    <div className="Box DarkBlueBg">
                      <h6>Options Sub-Total</h6>
                      <h6>${totalClientTotal}</h6>
                    </div>
                    <div className="Box LightBlueBg">
                      <h6>
                        Tax {getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent}%
                      </h6>
                      <h6>
                        ${" "}
                        {(
                          (totalClientTotal *
                            getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) /
                          100
                        ).toFixed(2)}
                      </h6>
                    </div>
                    <div className="Box NavyBlueBg">
                      <h6>Total</h6>
                      <h6>
                        ${" "}
                        {(
                          totalClientTotal +
                          (totalClientTotal *
                            getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) /
                            100
                        ).toFixed(2)}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="CostBreakDownArea">
                  <h5 className="TableHeading  my-4">
                    Cost Breakdown With Options
                  </h5>
                  <div className="Box DarkBlueBg">
                    <h6>Contract Price Before Tax</h6>
                    <h6>${contactDetails?.clientPurchasePrice}</h6>
                  </div>
                  <div className="Box LightBlueBg">
                    <h6>Change Order Total</h6>
                    <h6>
                      $
                      {(
                        totalClientTotal +
                        (totalClientTotal *
                          getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) /
                          100
                      ).toFixed(2)}
                    </h6>
                  </div>
                  <div className="Box DarkBlueBg">
                    <h6>Sub Total</h6>
                    <h6>{totalClientTotal}</h6>
                  </div>
                  <div className="Box LightBlueBg">
                    <h6>Tax {getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent}%</h6>
                    <h6>
                      ${" "}
                      {(
                        (totalClientTotal *
                          getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) /
                        100
                      ).toFixed(2)}
                    </h6>
                  </div>
                  <div className="Box LightBlueBg">
                    <h6>Adjustment: Applied Tax Rebate</h6>
                    <h6>-${getTaxProfile?.[0]?.taxRebates?.[0]?.amount}</h6>
                  </div>
                  <div className="Box NavyBlueBg">
                    <h6>Total</h6>
                    <h6>
                      {parseFloat(
                        (
                          (totalClientTotal *
                            getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) /
                          100
                        ).toFixed(2) -
                          getTaxProfile?.[0]?.taxRebates?.[0]?.amount +
                          totalClientTotal +
                          contactDetails?.clientPurchasePrice +
                          totalClientTotal +
                          (totalClientTotal *
                            getTaxProfile?.[0]?.taxRows?.[0]?.taxPercent) /
                            100
                      ).toFixed(2)}{" "}
                    </h6>
                  </div>
                </div>

                <div className="ChangeOrderCreatArea mt-5">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <div className="InputBoxSmall">
                            <h6>
                              {" "}
                              <input
                                type="date"
                                value={moment(signedDate).format("YYYY-MM-DD")}
                                onChange={(e) => setSignedDate(e.target.value)}
                              />
                            </h6>
                          </div>
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          {ctcSignature ? (
                            <a
                              onClick={() =>
                                handleManageSignatueShow("ctcSignature")
                              }
                            >
                              <img
                                style={{ height: "80px", width: "150px" }}
                                src={ctcSignature}
                              />
                            </a>
                          ) : (
                            <a
                              onClick={() =>
                                handleManageSignatueShow("ctcSignature")
                              }
                              className="FolderPermissionId"
                            >
                              <img src="images/insert_signature.png" />
                              Insert Signature
                            </a>
                          )}
                          <label>
                            {allClientListData?.clientDetails?.[0]?.legalName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="InputBox border-0 text-center">
                        <h6>
                          Signed on{" "}
                          {signedDate && moment(signedDate).format("LLL")}{" "}
                          <br />
                          by {
                            allClientListData?.clientDetails?.[0]?.legalName
                          }{" "}
                          <br />
                          (mailto:{allClientListData?.clientDetails?.[0]?.email}
                          )
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <div className="InputBoxSmall">
                            <h6>
                              {" "}
                              <input
                                type="date"
                                value={moment(signedDateBuilder).format(
                                  "YYYY-MM-DD"
                                )}
                                onChange={(e) =>
                                  setSignedDateBuilder(e.target.value)
                                }
                              />
                            </h6>
                          </div>
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          {ctcSignatureBuilder ? (
                            <a
                              onClick={() =>
                                handleManageSignatueShow("ctcSignatureBuilder")
                              }
                            >
                              <img
                                style={{ height: "80px", width: "150px" }}
                                src={ctcSignatureBuilder}
                              />
                            </a>
                          ) : (
                            <a
                              onClick={() =>
                                handleManageSignatueShow("ctcSignatureBuilder")
                              }
                              className="FolderPermissionId"
                            >
                              <img src="images/insert_signature.png" />
                              Insert Signature
                            </a>
                          )}
                          <label>
                            {allClientListData?.clientDetails?.[0]?.legalName}{" "}
                            Builder Company
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <div className="Button dropdown show">
                  <button
                    className="Create px-4"
                    type="button"
                    onClick={() =>
                      setsendClientDropdown(sendClientDropdown ? false : true)
                    }
                  >
                    Send for client signature{" "}
                    <img src="images/down-arrow-white.png" />
                  </button>

                  <div
                    className={
                      sendClientDropdown
                        ? "dropdown-menu show"
                        : "dropdown-menu"
                    }
                    aria-labelledby="dropdownMenuButton"
                    style={{
                      position: "absolute",
                      transform: "translate3d(0px, -120px, 0px)",
                      top: "0px",
                      left: "0px",
                      willChange: "transform",
                    }}
                  >
                    <a
                      className="dropdown-item FolderPermissionId"
                      onClick={handleSendClientSignatures}
                    >
                      Send for Client Signature
                    </a>
                    <a class="dropdown-item" href="javascript:void(0);">
                      Confirm Signature
                    </a>
                    {/* <a
                      onClick={showClientHistoryModal}
                      class="dropdown-item"
                    >
                      view History
                    </a> */}

                    <a
                      className="dropdown-item FolderPermissionId"
                      onClick={() => setrejectCtcDocModal(true)}
                      data-toggle="modal"
                      data-target="#TwoRejectReturnCtcMaster"
                      data-dismiss="modal"
                    >
                      Reject/Return
                    </a>
                    {/* <a
              className="dropdown-item FolderPermissionId"
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#TwoRejectReturnCtcMaster"
              data-dismiss="modal"
            >
              Reject/Return
            </a>
            <a
            
                    {/* <a class="dropdown-item FolderPermissionId" href="javascript:void(0);" data-toggle="modal" data-target="#TwoGenerateChangeOrderCtc" data-dismiss="modal">Generate Change Order List</a>
                  <a class="dropdown-item FolderPermissionId" href="javascript:void(0);" data-toggle="modal" data-target="#TwoChangeOrderCtc" data-dismiss="modal">View Generated Change Order</a> */}
                  </div>
                </div>

                <button className="Outline" onClick={handleViewCtcModalClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={FollowUpActivityModal}
        onHide={handleFollowUpActivityModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleFollowUpActivityModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Template Activity</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Template:</span> Sales Contract
                </h6>
              </div>
            </div>
            <div className="Tableheader">
              <h6>Files</h6>
              <div className="NewActions">
                <a href="javascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
                {/* <a href="javascript:void(0);">
                  <img src="images/setting.png" />
                </a> */}
              </div>
            </div>
            <div className="TableList TableListAlign CommonHeading TableMinimumHeight TableHeight500">
              <table>
                <thead>
                  <tr>
                    <th>File Id</th>
                    <th>File Creation Process</th>
                    <th>Client Signature Status</th>
                    <th>Builder Signature Status</th>
                    <th>Executed by</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {clientDocTemplateList?.data?.length > 0
                    ? clientDocTemplateList?.data?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.id}</td>
                            <td>{item.status}</td>
                            <td>
                              {item.clientSignatureStatus}
                              <img src="images/Action-2.png" />
                            </td>
                            <td>
                              {item.builderSignatureStatus}
                              <img src="images/Action-2.png" />
                            </td>
                            <td>
                              {" "}
                              {item?.UpdatedBy?.name ||
                                item.UpdatedBy?.firstName ||
                                item.UpdatedBy?.lastName ||
                                "N/A"}
                            </td>
                            <td>
                              {moment(item.createdAt).format("YYYY-MM-DD")}
                            </td>
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="true"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth">
                                    <li>
                      <a
                        href="javascript:void(0);"
                        onClick={()=>handleViewTemplateDataShow(item)}
                      >
                        <img src="images/view-po.png" />
                        View File
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#SendForClientSignatureTemplate"
                        data-dismiss="modal"
                      >
                        <img src="images/post_transaction.png" />
                        Send as new
                      </a>
                    </li>  */}
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleSendInvitation(
                                              item.LeadId,
                                              item.id
                                            )
                                          }
                                        >
                                          <img src="images/Action-2.png" />
                                          Resend
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleActivityDelete(
                                              item.LeadId,
                                              item.id
                                            )
                                          }
                                        >
                                          <img src="images/dropdown-delete.png" />
                                          Delete
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handlSignModalShow(item)
                                          }
                                        >
                                          <img src="images/send_signature.png" />
                                          Sign
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleDownloadTemplate()
                                          }
                                        >
                                          <img src="images/download.png" />
                                          Download
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          data-toggle="modal"
                                          data-target="#Amendment"
                                          data-dismiss="modal"
                                        >
                                          <img src="images/icon-26.png" />
                                          Amendement
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleGrossMarginModalShow(item.id)
                                          }
                                        >
                                          <img src="images/view-po.png" />
                                          View Gross Margin
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
            <div className="PaginationArea mt-3">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> 01{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
            <div className="Button  text-center mt-4">
              <button
                className="Outline"
                onClick={handleFollowUpActivityModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={SendForClientModal}
        onHide={handleSendForClientModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleSendForClientModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Send Document for Client Signature</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>Template</h6>
                    <p className="ml-0">Sales Contract</p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>File Id</h6>
                    <p className="ml-0">F1</p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="ModalMediumheading">
                    <h3>
                      <span>Client Signees</span>
                    </h3>
                  </div>
                  <div className="form-group">
                    <h6>Number of Signee</h6>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Insert Number of Signees"
                      name="numberOfSignee"
                      value={numberOfSignee}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>Select Primary Client Signee</h6>
                    <div class="CommonSelectBox w-100">
                      <select
                        name="primarySignee"
                        value={primarySignee}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        {primayClient?.length > 0 &&
                          primayClient?.map((item, i) => {
                            return (
                              <option value={item.id} key={i}>
                                {item.legalName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {numberOfSignee >= 2 && (
                    <div className="form-group">
                      <h6>Select Secondary Client Signee</h6>
                      <div class="CommonSelectBox w-100">
                        <select
                          name="secondarySignee"
                          value={secondarySignee}
                          onChange={handleInputChange}
                        >
                          <option value="">Select</option>
                          {secondaryClient?.length > 0 &&
                            secondaryClient?.map((item, i) => {
                              return (
                                <option value={item.id}>
                                  {item.legalName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  )}
                  {numberOfSignee == 3 && (
                    <div className="form-group">
                      <h6>Select Additional Client Signee</h6>
                      <div class="CommonSelectBox w-100">
                        <select
                          name="additionalSignee"
                          value={additionalSignee}
                          onChange={handleInputChange}
                        >
                          <option value="">Select</option>
                          {secondaryClient?.length > 0 &&
                            secondaryClient?.map((item, i) => {
                              return (
                                <option value={item.id}>
                                  {item.legalName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <h6>Select Builder Signee</h6>
                    {/* <p className="ml-0">{iBuildLocalData?.user?.id}</p> */}
                    <div class="CommonSelectBox w-100">
                      <select
                        name="builderSignee"
                        value={builderSignee}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        {builderData?.length > 0 &&
                          builderData?.map((item, i) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  {/* <div class="form-group">
                <h6>Builder Signee</h6>
                <div class="CommonSelectBox w-100">
                  <select>
                    <option selected>Select Builder Signee</option>
                    <option>Signee 1</option>
                  </select>
                  <span><img src="images/down.png"></span>
                </div>
              </div> */}
                </div>
              </div>

              <div className="Button d-flex justify-content-between mt-3">
                <button
                  className="Create FolderPermissionId"
                  onClick={handleSendForClient}
                >
                  Generate Document
                </button>
                <button
                  className="Outline"
                  onClick={handleSendForClientModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={FilterModal}
        onHide={handleFilterModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleFilterModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Filter Table</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalMediumheading">
                <h3>
                  <span>Table : </span>Table 1
                </h3>
              </div>
              <div className="form-group">
                <h6>Column</h6>
                <div className="CommonSelectBox w-100">
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    value={includedConsultantt}
                    onChange={handleConsChange}
                    options={consultModule}
                  />
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={handleApply}>
                  Apply Filter
                </button>
                <button className="Outline" onClick={handleFilterModalClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={GrossMarginModal}
        onHide={handleGrossMarginModalClose}
        className="ModalBox modal fade LargeModal ExtraLargeModalGreen show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleGrossMarginModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Gross Margin</h4>
            </div>
            <div className="FolderForm">
              <div className="CommonGreenTable">
                <table>
                  <thead>
                    <tr className="BorderBottom">
                      <th colSpan={5} className="pt-4">
                        Unit Total Gross Revenues, Total Construction Costs
                        &amp; Gross Margin Analysis <br /> Report
                      </th>
                    </tr>
                    <tr>
                      <th className="text-left">Description</th>

                      <th>$'s</th>
                      <th>%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkArray(grossMarginData) &&
                      grossMarginData?.map((ele, i) => {
                        return (
                          <>
                            <tr>
                              <td className="text-left">{ele?.label} </td>
                              <td>{ele?.amount}</td>
                              <td>{ele?.persent}</td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={SendforClientSignatureModal}
        onHide={handleSendforClientSignatureModalClose}
        className="ModalBox modal fade LargeModal ExtraLargeModalGreen show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleSendforClientSignatureModalClose}
          >
            ×
          </a>
          <div class="ModalTitleBox">
            <h4>Send for Client Signature</h4>
          </div>
          <div className="FolderForm">
            <div className="AgreementViewArea mt-5">
              <div className="AgreementViewTop">
                <div className="AgreementViewTopRight">
                  <p
                    dangerouslySetInnerHTML={createAboutHeader()}
                    className="editor"
                  ></p>
                </div>
              </div>
              <div className="StatementAdjustmentArea px-4 pt-4">
                <p
                  dangerouslySetInnerHTML={createAboutMarkup()}
                  className="editor"
                ></p>
              </div>
              <div className="ChangeOrderCreatArea mt-5">
                {primarySignStatus && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date"  value={moment(new Date()).format("YYYY-MM-DD")} disabled/>
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatueShow("primarySignature")
                            }
                          >
                            {primarySignature&& <img src={primarySignature} />}
                            {primarySignature==null&& (
                              <a>
                                <img src="images/insert_signature.png" />
                                Insert Signature
                              </a>
                            )}
                          </div>
                          <label>{primarySignStatus}</label>
                          <label>Primary Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {secondarySignStatus && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" value={moment(new Date()).format("YYYY-MM-DD")} disabled/>
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatueShow("secondarySignature")
                            }
                          >
                            {secondarySignature && (
                              <>
                                <img src={secondarySignature} />
                              </>
                            )}
                            {secondarySignature==null  && (
                              <a>
                                <img src="images/insert_signature.png" />
                                Insert Signature
                              </a>
                            )}
                          </div>
                          <label>{secondarySignStatus}</label>
                          <label>Secondary Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {additionalSignStatus && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" value={moment(new Date()).format("YYYY-MM-DD")} disabled/>
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatueShow("additionalSignature")
                            }
                          >
                            {additionalSignature && (
                              <>
                                <img src={additionalSignature} />
                              </>
                            )}
                            {additionalSignature==null&& (
                              <a>
                                <img src="images/insert_signature.png" />
                                Insert Signature
                              </a>
                            )}
                          </div>
                          <label>{additionalSignStatus}</label>
                          <label>Additional Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {builderSignStatus && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" value={moment(new Date()).format("YYYY-MM-DD")} disabled/>
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatueShow("builderSignature")
                            }
                          >
                            {builderSignature&& (
                              <>
                                <img src={builderSignature} />
                              </>
                            )}
                            {builderSignature==null && (
                              <a>
                                <img src="images/insert_signature.png" />
                                Insert Signature
                              </a>
                            )}
                          </div>
                          <label>{builderSignStatus}</label>
                          <label>Builder Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="Button d-flex justify-content-between">
              <button
                className="Create"
                onClick={() => handleSendToClient("save")}
              >
                Save Draft
              </button>
              <button
                className="Create"
                onClick={() => handleSendToClient("send")}
              >
                Save &amp; Send to Client
              </button>
              <button
                className="Outline"
                onClick={handleSendforClientSignatureModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ManageSignature}
        onHide={handleManageSignatueClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handleManageSignatueClose}>
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new signature</h5>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea border p-2">
                  <h5>Signatures</h5>
                  <div className="CommonModalBox">
                    <SignaturePad
                      ref={signCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        updateLeadState({
                          ...iLeadState,
                          signature: signCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearSignature}>
                      <img src="images/circle_minus.png" />
                    </a>
                    {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                    {errors?.signatureError}
                  </span> */}
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addSignature}>
                  Save
                </button>
                <button className="Outline" onClick={handleManageSignatueClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={leadClientDetailsShow}
        onHide={hideLeadClientDetailsModal}
        className="ModalBox ExtraSmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal closebtn ClosePermissionId"
            onClick={hideLeadClientDetailsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ContactTopBox">
              <div className="SalesPersonarea">
                <span>
                  {leadClientDetailsDataTemp?.legalName?.charAt(0) +
                    leadClientDetailsDataTemp?.legalName?.charAt(1)}
                </span>

                <h6>{leadClientDetailsDataTemp?.legalName}</h6>
              </div>
            </div>
            <div className="FolderForm">
              <div className="SendEmailText">
                <p>
                  <img src="images/send-email.png" />
                  Send E-mail
                </p>
                <p className="SendText SendText1">
                  <img src="images/send-text.png" />
                  Send Text
                </p>
                <div className="SendTextSearch SendTextSearch1">
                  <input type="text" />
                  <button>Apply</button>
                </div>
              </div>
              <div className="MolalContactForm mb-4">
                <h6>Contact</h6>
                <p>
                  <img src="images/send-email.png" />
                  {leadClientDetailsDataTemp?.email}
                </p>
                <p className="mb-4">
                  <img src="images/send-text.png" />
                  {leadClientDetailsDataTemp?.primaryPhoneNum}
                </p>
                {leadDetailsShowMore == false ? (
                  <>
                    <a
                      onClick={() =>
                        createRoomfun(leadClientDetailsDataTemp?.id)
                      }
                      href="javascript:void(0);"
                      className="ShowMoreBtn"
                    >
                      Show more
                    </a>
                  </>
                ) : (
                  <a
                    onClick={() => SetLeadDetailsShowMore(false)}
                    href="javascript:void(0);"
                    className="ShowMoreBtn"
                  >
                    Show less
                  </a>
                )}
              </div>
              <div className="form-group mt-3">
                <div className="ContactModalAddressArea">
                  <h6>Address</h6>
                  <p>
                    {leadClientDetailsDataTemp?.city} <br />
                    {leadClientDetailsDataTemp?.country}
                    {/* <img src="images/location.png" /> */}
                  </p>
                </div>
              </div>
              {leadDetailsShowMore == true ? (
                <div className="">
                  <div className="form-group mb-0">
                    <h6>Discussion</h6>
                    <input
                      type="text"
                      placeholder="Write here ....."
                      className="form-control"
                      name="message"
                      value={message}
                      onChange={handleInput}
                      onKeyDown={sendMessageFunc}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {checkArray(messageListData?.data?.data) &&
                messageListData?.data?.data?.map((ele, i) => {
                  return (
                    <>
                      <p>{ele?.message}</p>
                    </>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={HeaderPreviewModal}
        onHide={handlHeaderPreviewModalClose}
        className="ModalBox modal fade LargeModal GreyModalContent show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handlHeaderPreviewModalClose}
          >
            ×
          </a>
          <div className="FolderForm">
            <div className="AgreementViewArea">
              <div className="AgreementViewTop">
                <div className="AgreementViewTopRight">
                  <p
                    dangerouslySetInnerHTML={createAboutHeader()}
                    className="editor"
                  ></p>
                </div>
              </div>
            </div>

            <div className="Button d-flex justify-content-between mt-5">
              <button className="Create" onClick={() => handleHeaderApply()}>
                Apply
              </button>
              <button
                className="Outline"
                onClick={handlHeaderPreviewModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={clientSignatureModal}
        onHide={hideClientSignatureModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideClientSignatureModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new signature</h5>
                  <div className="CommonModalBox">
                    <h5>
                      <figure>
                        <img src="images/add_new_signature.png" />
                      </figure>{" "}
                      Add new signature
                    </h5>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Signatures</h5>
                  <div className="CommonModalBox">
                    {/* <input
                        // type="file"
                        // name="signature"
                        // onChange={handleInputFile}
                      /> */}
                    <SignaturePad
                      ref={signCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        updateLeadState({
                          ...iLeadState,
                          clientSignature: signCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearSignature} href="javascript:void(0);">
                      <img src="images/circle_minus.png" />
                    </a>
                    {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {signatureErrors?.signatureError}
                  </span>
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addSignatureFun}>
                  Save
                </button>
                <button className="Outline" onClick={hideManageSignatureModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={clientHistoryModal}
        onHide={hideClientHistoryModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideClientHistoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>CTC History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Document:</span>&nbsp;Sales Contract.pdf
                </h6>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    {/* /<th>View</th> */}
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John Doe</td>
                    <td>Jane Williams</td>
                    <td>04/25/2023</td>
                    {/* <td>
                  <a href="javascript:void(0);" className="FolderPermissionId" data-toggle="modal" data-target="#TwoSignClientCtcThird" data-dismiss="modal"><i className="fa fa-eye" /></a>
                </td> */}
                    <td>File Shared by the trade</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DocumentKeyModal}
        onHide={handleDocumentKeyModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleDocumentKeyModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Document Key</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-md-7">
                  <div className="form-group">
                    <h6>Keys List</h6>
                    <div className="Categories Categories1">
                      <select
                        className="Categories_all Categories_all1"
                        onChange={handleItemClick}
                      >
                        <option value="">Select</option>
                        {allDocumentKey?.length > 0 &&
                          allDocumentKey?.map((data, i) => {
                            return (
                              <option value={data.html} key={i}>
                                {data.label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={InsertSignatureModal}
        onHide={handlInsertSignatureModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handlInsertSignatureModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Insert Signature</h4>
            </div>
            <div className="FolderForm">
              {allSignatureList?.images?.length > 0 &&
                allSignatureList?.images?.map((item, i) => {
                  return (
                    <div className="SignatureArea border-bottom py-2" key={i}>
                      <a>
                        <img src={item.docUrl} />
                      </a>

                      <div className="Button">
                        {/* <button className="Create px-2" onClick={()=>handleRemoveManageSignature(item.id)}>Remove</button> */}
                      </div>
                    </div>
                  );
                })}

              <div className="Button d-flex justify-content-between mt-5">
                <button
                  className="Create FolderPermissionId"
                  onClick={handleAddSignature}
                >
                  Add Signature
                </button>
                <button
                  className="Outline"
                  onClick={handlInsertSignatureModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={SignModal}
        onHide={handlSignModalClose}
        className="ModalBox modal fade LargeModal ExtraLargeModalGreen show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handlSignModalClose}
          >
            ×
          </a>
          <div class="ModalTitleBox">
            <h4>Send for Client Signature</h4>
          </div>
          <div className="FolderForm">
            <div className="AgreementViewArea mt-5">
              <div className="AgreementViewTop">
                <div className="AgreementViewTopRight">
                  {/* <p
                    dangerouslySetInnerHTML={createAboutHeader()}
                    className="editor"
                  ></p> */}
                </div>
              </div>
              <div className="StatementAdjustmentArea px-4 pt-4">
                {clientDocData && (
                  <p
                    dangerouslySetInnerHTML={{ __html: headerData }}
                    className="editor"
                  ></p>
                )}
              </div>
              <div className="ChangeOrderCreatArea mt-5">
                {primaryData && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatueShow("primarySignature")
                            }
                          >
                            {primarySignature ? (
                              <img src={primarySignature} />
                            ) : (
                              <img src={primaryData?.signUrl} />
                            )}
                            <a>
                              <img src="images/insert_signature.png" />
                              Insert Signature
                            </a>
                          </div>
                          {/* <label>{primarySignStatus}</label> */}
                          <label>Primary Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {secondaryData && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatueShow("secondarySignature")
                            }
                          >
                            {secondarySignature ? (
                              <img src={secondarySignature} />
                            ) : (
                              <img src={secondaryData?.signUrl} />
                            )}
                            <a>
                              <img src="images/insert_signature.png" />
                              Insert Signature
                            </a>
                          </div>
                          {/* <label>{secondarySignStatus}</label> */}
                          <label>Secondary Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {additionalData && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatueShow("additionalSignature")
                            }
                          >
                            {additionalSignature ? (
                              <img src={additionalSignature} />
                            ) : (
                              <img src={additionalData?.signUrl} />
                            )}
                            <a>
                              <img src="images/insert_signature.png" />
                              Insert Signature
                            </a>
                          </div>
                          {/* <label>{additionalSignStatus}</label> */}
                          <label>Additional Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {builderDatas && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatueShow("builderSignature")
                            }
                          >
                            {builderSignature ? (
                              <img src={builderSignature} />
                            ) : (
                              <img src={builderDatas?.signUrl} />
                            )}
                            <a>
                              <img src="images/insert_signature.png" />
                              Insert Signature
                            </a>
                          </div>
                          {/* <label>{builderSignStatus}</label> */}
                          <label>Builder Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="Button d-flex justify-content-between">
              <button
                className="Create"
                // onClick={() => handleSendToClient("send")}
              >
                Save &amp; Send to Client
              </button>
              <button className="Outline" onClick={handlSignModalClose}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addClientModal}
        onHide={hideAddClientModal}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddClientModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Client</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group ml-3">
                      <div className="ModalMediumheading">
                        <h3>
                          <span>Job id:</span>
                          {jobNumbers}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group col-md-12">
                      <div className="ModalBigheading">
                        <h3>
                          <span>Personal Information</span>
                        </h3>
                      </div>
                    </div>
                    <div className="form-group col-md-12">
                      <h6>
                        Client Full Legal name
                        <span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input client's full legal name"
                        name="legalName"
                        value={legalName}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Country Code</h6>
                      <PhoneInput
                        inputClass="CountryCodeInput"
                        countryCodeEditable={false}
                        enableSearch
                        value={phonecode}
                        onChange={handlePhoneInputChange}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <h6>
                        Primary Phone Number<span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input primary phone number"
                        name="primaryPhoneNum"
                        value={primaryPhoneNum}
                        onChange={handleInputNumber}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <h6>
                        Email<span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input email"
                        name="email"
                        value={email}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <h6>Business Phone Number</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input business phone number"
                        name="businessPhoneNum"
                        value={businessPhoneNum}
                        onChange={handleInputNumber}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <h6>Fax Phone Number</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input fax phone number"
                        name="faxPhoneNum"
                        value={faxPhoneNum}
                        onChange={handleInputNumber}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <h6>Preferred Communication</h6>
                      <select
                        name="preferredCommu"
                        value={preferredCommu}
                        onChange={handleInput}
                      >
                        <option>Select Preference</option>
                        <option value="No Preference">No Preference</option>
                        <option value="Phone">Phone</option>
                        <option value={"Email"}>Email</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group col-md-12">
                      <div className="ModalBigheading">
                        <h3>
                          <span>Address</span>
                        </h3>
                      </div>
                    </div>
                    <div className="form-group col-md-12">
                      <h6>Street Address</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input street name"
                        name="streetAddress"
                        value={streetAddress}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <h6>Address Line two</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input address line two"
                        name="addressLineTwo"
                        value={addressLineTwo}
                        onChange={handleInput}
                      />
                    </div>

                    <div className="form-group col-md-12">
                      <h6>
                        Zip/Postal Code<span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input postal name"
                        name="zipCode"
                        value={zipCode}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <h6>
                        Country<span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox w-100">
                        <select
                          name="country"
                          value={country}
                          onChange={handleInput}
                        >
                          <option>Select Country</option>
                          {checkArray(countryData?.countries) ? (
                            <>
                              {countryData?.countries?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group col-md-12">
                      <h6>
                        State/Province<span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox w-100">
                        <select
                          name="province"
                          value={province}
                          onChange={handleInput}
                        >
                          <option>Select Province</option>
                          {checkArray(stateData?.states) ? (
                            <>
                              {stateData?.states?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group col-md-12">
                      <h6>
                        City<span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox w-100">
                        <select name="city" value={city} onChange={handleInput}>
                          <option>Select City</option>
                          {checkArray(cityData?.cities) ? (
                            <>
                              {cityData?.cities?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create mr-3" onClick={addClientFunc}>
                    Add New Client
                  </button>
                  <button className="Outline ml-3" onClick={hideAddClientModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={AddPaymentReminder}
        onHide={handleCloseAddPaymentReminder}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleCloseAddPaymentReminder}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Set Payment Reminder</h4>
            </div>

            <div className="FolderForm">
              <form onSubmit={handlePaymentReminderSubmit}>
                <div className="form-group">
                  <input
                    type="date"
                    className="form-control"
                    value={paymentReminder}
                    min={getMaxDate()}
                    onChange={(e) => setPaymentReminder(e.target.value)}
                  />
                </div>
                <div className="Button text-center mt-3">
                  <button className="Create" type="submit">
                    Set Payment Reminder
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {ContactDetailsModal && (
        <ContactDetails
          ContactDetailsModal={ContactDetailsModal}
          setContactDetailsModal={setContactDetailsModal}
          contactDetailsData={contactDetailsData}
          showNewEmailModal={showNewEmailModal}
          showNewTextModal={showNewTextModal}
        />
      )}

      <RejectCtcDocModal
        rejectCtcDocModal={rejectCtcDocModal}
        setrejectCtcDocModal={setrejectCtcDocModal}
      />
      <CTCHistoryModal
        ctcHistoryModal={ctcHistoryModal}
        setctcHistoryModal={setctcHistoryModal}
        updateModalState={updateModalState}
      />

<UpdateLeadModal
        show={UpdateLeadsModal}
        onClose={()=>handleUpdateLeadsModalClose()}
        LeadDatas={LeadDatas}
      />

<ViewTemplate
        ViewTemplateData={ViewTemplateData}
        show={ViewTemplateModal}
        close={handleViewTemplateDataClose}
      />
      {/* <SendForClientModal sendClientInfoModal={sendClientInfoModal} setsendClientInfoModal={setsendClientInfoModal} />
      // <CTCHistoryModal ctcHistoryModal={ctcHistoryModal} setctcHistoryModal={setctcHistoryModal} />
      <RejectCtcDocModal rejectCtcDocModal={rejectCtcDocModal} setrejectCtcDocModal={setrejectCtcDocModal} />
 */}
    </>
  );
};

export default SalesModule;
