import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../App.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  acceptFPOs,
  getFPOById,
  rejectFPOs,
} from "../redux/action/tradeViewAuthAction";
import { companyDetails } from "../redux/action/designSelectionsAction";

const TradeViewFieldPurchaseOrder = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1].split("#")[0]
  );
  const companyId = urlParams?.get("companyId");
  const documentId = urlParams?.get("documentId");
  const token = urlParams?.get("token");
  const [viewFP0Data, setViewFpoData] = useState([]);
  const [companyData,setCompanyData] = useState([])
  useEffect(() => {
    dispatch(getFPOById(companyId, documentId, token)).then((res) => {
      if (res.status === 200) {
        const dummyData = res?.data?.data;
        setViewFpoData(dummyData);
      }
    });
    dispatch(companyDetails(companyId,token)).then((res)=>{
      if(res.status===200){
        let dummData=res?.data?.company
        setCompanyData(dummData)

      }
    })
  }, [companyId, documentId, token]);

  

  const handleAcceptRejectPO = (type) => {
    if (type === "accept") {
      dispatch(acceptFPOs(companyId, documentId, token)).then((res) => {
        if (res.status === 200) {
          dispatch(getFPOById(companyId, documentId,token)).then((res) => {
            if (res.status === 200) {
              const dummyData = res?.data?.data;
        setViewFpoData(dummyData);
            }
          });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } else {
      dispatch(rejectFPOs(companyId, documentId, token)).then((res) => {
        if (res.status === 200) {
          dispatch(getFPOById(companyId, documentId,token)).then((res) => {
            if (res.status === 200) {
              const dummyData = res?.data?.data;
              setViewFpoData(dummyData);
            }
          });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handlePrint=()=>{
    window.print();
  }
  const createAboutMarkup = () => {
    return { __html: companyData?.letterHeader };
  };
  return (
    <>
      <div className="FormArea p-5">
      <div className="AgreementViewTop">
                
                <div className="AgreementViewTopRight">
                <p
                    dangerouslySetInnerHTML={createAboutMarkup()}
                    className="editor"
                  ></p>
                </div>
              </div>
        <div className="ModalTitleBox ModalTitleBoxGreen">
          <h4>View Field Purchase Order</h4>
        </div>
        <div className="FolderForm">
          <div className="BlueTextArea GreenTextArea">
            <div className="row">
              <div className="col-md-6">
                <div className="Heading">
                  <h3>Field Quote Request # {viewFP0Data?.documentCode}</h3>
                </div>

                <div className="row">
                  <div className="form-group col-md-9">
                    <h6>Reason</h6>
                    <p className="ml-0 text-black">
                      {" "}
                      {viewFP0Data?.Reason?.description ?? "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="Heading">
                  {/* <h3>Rivers Edge Unit 366(RIV-2B-366)</h3> */}
                  <h3>{viewFP0Data?.job?.jobNumber ?? "N/A"}</h3>
                  <div className="d-flex align-items-center">
                    <h3>
                      Cost Code:{" "}
                      <span>{`${viewFP0Data?.CostCode?.number ?? ""}-${
                        viewFP0Data?.CostCode?.name ?? ""
                      }`}</span>
                    </h3>{" "}<br></br>
                    <h6>
                      Status:<span> {viewFP0Data?.status}</span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ModalFilterAreaBox">
                  <h4 className="mb-4">Vendor</h4>
                  <div className="CommonModalBox">
                    <h5>
                      <p className="ml-0">
                        {viewFP0Data?.Vendor?.vendorName ?? "N/A"}
                      </p>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <h6>Date Sent</h6>
                  <p className="ml-0">
                    {" "}
                    {viewFP0Data?.sendAt !== null
                      ? moment(viewFP0Data?.sendAt).format("YYYY-MM-DD")
                      : "N/A"}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5 className="GreenText">Reminder</h5>
                    <div className="CommonModalBox">
                      <p>
                        {viewFP0Data?.reminder !== null
                          ? moment(viewFP0Data?.reminder).format("YYYY-MM-DD")
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-12">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <div className="CommonModalBox">
                      <figure
                        className="CursorPointer FolderPermissionId"
                        data-toggle="modal"
                        data-target="#LeftBudget1SelectVendorGreen"
                        data-dismiss="modal"
                      >
                        <img src="images/vendor.png" />
                      </figure>
                      <h5 className="text-black">Vendor 3</h5>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-12">
                <div className="ShippingAddressArea">
                  <div className="ShippingAddressAreaBox">
                    <h6>Mailing Address</h6>
                    <p>{`${viewFP0Data?.Vendor?.addressLine1 ?? ""}
                                ${viewFP0Data?.Vendor?.addressLine2 ?? ""}`}</p>
                  </div>
                  <div className="ShippingAddressAreaBox">
                    <h6>Email</h6>
                    <p>{viewFP0Data?.Vendor?.email}</p>
                  </div>
                  <div className="ShippingAddressAreaBox">
                    <h6>Phone</h6>
                    <p>{viewFP0Data?.Vendor?.phone}</p>
                  </div>
                  <div className="ShippingAddressAreaBox">
                    <h6>Shipping Address</h6>
                    <p>{`${viewFP0Data?.Vendor?.addressLine1 ?? ""}
                                ${viewFP0Data?.Vendor?.addressLine2 ?? ""}`}</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-12">
            <div className="form-group">
              <div className="CommonModalArea">
                <h5 className="GreenText">Back Charge Vendor</h5>
                <div className="CommonModalBox">
                  <figure
                    className="CursorPointer FolderPermissionId"
                    data-toggle="modal"
                    data-target="#LeftBudget1SelectVendorGreen"
                    data-dismiss="modal"
                  >
                    <img src="images/vendor.png" />
                  </figure>
                  <h5 className="text-black">Vendor 3</h5>
                </div>
              </div>
            </div>
          </div> */}
              <div className="col-md-7">
                <div className="form-group">
                  <h6>Messsage</h6>
                  <p>{viewFP0Data?.message ?? "N/A"}</p>
                </div>
              </div>
              <div className="col-12">
                <div className="ItemDetails">
                  <h6>Item Details</h6>
                </div>
              </div>
              <div className="col-12">
                <div className="Tableheader mb-3">
                  <h6 className="mb-0">Item</h6>
                  <div className="Actions">
                    <a onClick={handlePrint}>
                      <img src="images/icon-53.png" />
                    </a>
                    <a onClick={handlePrint}>
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
                <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <div className="text-center">
                            Material Description
                            <br />
                            (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                          </div>
                        </th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th>Units</th>
                        <th>Price/Unit</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewFP0Data?.Items?.length > 0 ? (
                        viewFP0Data?.Items?.map((item, ind) => {
                          return (
                            <>
                              <tr key={ind}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.color}</td>
                                <td>{item.quantity}</td>
                                <td>{item.unitId}</td>
                                <td>$ {item.price}</td>
                                <td>$ {item.total}</td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="12">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-md-4 ml-auto">
                    <div className="GstTable">
                      <table>
                        <tbody>
                          <tr>
                            <td>Profile</td>
                            <td>{viewFP0Data?.TaxProfile?.name ?? "N/A"}</td>
                          </tr>
                          <tr>
                            <td>Subtotal</td>
                            <td>${viewFP0Data?.subtotal ?? 0}</td>
                          </tr>
                          {
                            viewFP0Data?.otherTaxes?.length>0&&
                          <tr>
                            <td>
                              {" "}
                              {viewFP0Data?.otherTaxes?.[0]?.taxName}(
                              {viewFP0Data?.otherTaxes?.[0]?.taxPercentage||0}
                              %)
                            </td>
                            <td>
                              $
                              {viewFP0Data?.otherTaxes?.[0]?.taxAmount||0}
                            </td>
                          </tr>
                          }
                          <tr>
                            <td>
                              {" "}
                              GST(
                              {Number(
                                viewFP0Data?.TaxProfile?.taxRows?.[0]
                                  ?.taxPercent
                              ) || 0}
                              %)
                            </td>
                            <td>
                              $
                              {(Number(viewFP0Data?.subtotal) *
                                (Number(
                                  viewFP0Data?.TaxProfile?.taxRows?.[0]
                                    ?.taxPercent
                                ) || 0)) /
                                100}
                            </td>
                          </tr>
                          
                          <tr>
                            <td>Grand Total</td>
                            <td>
                              $
                              {Number(viewFP0Data?.grandTotal||0)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-12">
                <div className="mb-4">
                  <div className="Heading">
                    <h6 className="GreenText">Attachments</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5 className="GreenText">Scope of Work</h5>
                    <div className="CommonModalBox">
                      <h5 className="CursorPointer FolderPermissionId GreenText">
                        <figure>
                          <img src="images/scopeofwork.png" />
                        </figure>{" "}
                        Scope of Work
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5 className="GreenText">Construction Documents</h5>
                    <div className="CommonModalBox">
                      <h5 className="CursorPointer FolderPermissionId GreenText">
                        <figure>
                          <img src="images/construction_documents.png" />
                        </figure>{" "}
                        Construction Documents
                      </h5>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-3">
            <div className="CommonModalArea">
              <div className="form-group">
                <h5 className="GreenText">External Quote</h5>
                <div className="CommonModalBox">
                  <h5
                    className="CursorPointer FolderPermissionId GreenText"
                   
                  >
                    <figure>
                      <img src="images/extermal_quote.png" />
                    </figure>{" "}
                    External Quote
                  </h5>
                </div>
              </div>
            </div>
          </div> */}
            </div>

            {viewFP0Data?.status === "SEND" ? (
              <div className="Button d-flex justify-content-between mt-4 ">
                <button
                  className="Create"
                  onClick={() => handleAcceptRejectPO("accept")}
                >
                  Accept
                </button>
                <button
                  className="Create"
                  onClick={() => handleAcceptRejectPO("reject")}
                >
                  Reject
                </button>
              </div>
            ) : (
              <p>An Action has been already performed</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeViewFieldPurchaseOrder;
