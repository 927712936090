import { DropdownConstant } from "../actionTypes";
const initialState={
    userList:[],
    documentkeyList:[],
    roleList:[],
    costCodeList:[],
    adminUserList:{},

    loader:false,
}


export default function DropdownReducer(state = initialState, {type, payload}) {
    
    switch(type) {
      case DropdownConstant.USER_LIST_INITIATE: {
        return {
          ...state, loader:true, userList:[]
        }
      }
      case DropdownConstant.USER_LIST_SUCCESS: {
        return {
          ...state, loader:false, userList:payload.users
        }
      }
      case DropdownConstant.USER_LIST_FAILURE: {
        return {
          ...state, loader:false,userList:[]
        }
      }



      case DropdownConstant.ADMIN_USER_LIST_INITIATE: {
        return {
          ...state, loader:true, adminUserList:{}
        }
      }
      case DropdownConstant.ADMIN_USER_LIST_SUCCESS: {
        return {
          ...state, loader:false, adminUserList:payload
        }
      }
      case DropdownConstant.ADMIN_USER_LIST_FAILURE: {
        return {
          ...state, loader:false,adminUserList:{}
        }
      }



      case DropdownConstant.DOCUMENT_KEY_LIST_INITIATE: {
        return {
          ...state, loader:true, documentkeyList:[]
        }
      }
      case DropdownConstant.DOCUMENT_KEY_LIST_SUCCESS: {
        return {
          ...state, loader:false, documentkeyList:payload
        }
      }
      case DropdownConstant.DOCUMENT_KEY_LIST_FAILURE: {
        return {
          ...state, loader:false,documentkeyList:[]
        }
      }
  

      case DropdownConstant.ROLE_LIST_INITIATE: {
        return {
          ...state, loader:true, roleList:[]
        }
      }
      case DropdownConstant.ROLE_LIST_SUCCESS: {
        return {
          ...state, loader:false, roleList:payload?.companyRoles
        }
      }
      case DropdownConstant.ROLE_LIST_FAILURE: {
        return {
          ...state, loader:false,roleList:[]
        }
      }



      case DropdownConstant.COST_CODE_LIST_INITIATE: {
        return {
          ...state, loader:true, costCodeList:[]
        }
      }
      case DropdownConstant.COST_CODE_LIST_SUCCESS: {
        return {
          ...state, loader:false, costCodeList:payload
        }
      }
      case DropdownConstant.COST_CODE_LIST_INITIATE: {
        return {
          ...state, loader:false,roleList:[]
        }
      }

  
      
      default: 
      return state
  }
}