import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {toast} from 'react-toastify';

import MultifamilyJobStep2 from "./MultifamilyJobStep2";
import MultifamilyJobStep3 from "./MultifamilyJobStep3";
import MultifamilyJobStep4 from "./MultifamilyJobStep4";
import MultifamilyJobStep5 from "./MultifamilyJobStep5";
import MultifamilyJobStep6 from "./MultifamilyJobStep6";


import { checkArray } from "../../utils/CheckType";

import { useDispatch, useSelector } from "react-redux";
import { createMultiFamilyJobAction } from "../../redux/action/MultifamilyJobActionDk";
import { getAllHomeListAction, getAllProjectListAction, getAllTaxProfileAction } from "../../redux/action/jobAction";
import { getCountriesList, getTimeZoneList ,getStateList} from "../../redux/action/authAction";


import { getMultiFamilyProjectList } from "../../redux/action/homeAction";

const initialState = {
  ProjectId:"",
  buildingPermit: "",
  subdividision: "",
  specificationDoc: "",
  taxProfile: "",
  baseModel: "",
  homeType: "",
  defaultCurrency: "",
  costScheme: "",
  timeZone: "",
  accountSoftware: false,
  underParkade: false,
  jobAddress: "",
  lot: "",
  block: "",
  phase: "",
  city: "",
  zipCode: "",
  country: "",
  province: "",
  numOfBlocks: 0,
  blocks: [],
  numOfBuildings: 0,


  
  buildings: [],
  itemId:"",
  errorMsg: {},
};

const MultifamilyJobStep1 = ({
  MultifamilyJobStep1Modal,
  SetMultifamilyJobStep1Modal,
  setShow,
  perFormaId
}) => {
  const handleMultifamilyJobStep1ModalClose = () => {
    setShow(true);
    SetMultifamilyJobStep1Modal(false);
  };
  const handleMultifamilyJobStep1ModalShow = () => {
    SetMultifamilyJobStep1Modal(true);
  };


  

  const { countryData, cityData, stateData, timeZoneData } = useSelector(
    (state) => state.authReducer
  );

  const {
    projectListData,
    homeListData,
    taxProfileListData,
  } = useSelector((state) => state.jobReducer);

const dispatch = useDispatch()

  const [MultifamilyJobStep2Modal, SetMultifamilyJobStep2Modal] =
    useState(false);
  const [MultifamilyJobStep3Modal, SetMultifamilyJobStep3Modal] =
    useState(false);
  const [MultifamilyJobStep4Modal, SetMultifamilyJobStep4Modal] =
    useState(false);
  const [MultifamilyJobStep5Modal, SetMultifamilyJobStep5Modal] =
    useState(false);
  const [MultifamilyJobStep6Modal, SetMultifamilyJobStep6Modal] =
    useState(false);




  const [iState, UpdateState] = useState(initialState);

  const {
    ProjectId,
    subdividision,
    taxProfile,
    buildingPermit,
    homeType,
    costScheme,
    province,
    specificationDoc,
    baseModel,
    defaultCurrency,
    timeZone,
    proformaId,


    
    accountSoftware,
    underParkade,
    jobAddress,
    lot,
    block,
    phase,
    city,
    zipCode,
    country,
    numOfBlocks,
    blocks,
    numOfBuildings,
    buildings,
    itemId,
  
    errorMsg,
  } = iState;



  const handleValidation = () => {
    let formIsValid = true;
    let errorMsg = {};

    if (!subdividision) {
      formIsValid = false;
      errorMsg.subdividision = "* Please select subdividision.";
    }
    if (!taxProfile) {
      formIsValid = false;
      errorMsg.taxProfile = "* Please select tax profile.";
    }
    if (!subdividision) {
      formIsValid = false;
      errorMsg.subdividision = "* Please select subdividision.";
    }
    if (!homeType) {
      formIsValid = false;
      errorMsg.homeType = "* Please select home type.";
    }
    if (!costScheme) {
      formIsValid = false;
      errorMsg.costScheme = "* Please select cost schema.";
    }

    if (!province) {
      formIsValid = false;
      errorMsg.province = "* Please select province.";
    }

    if (!defaultCurrency) {
      formIsValid = false;
      errorMsg.defaultCurrency = "* Please select currency.";
    }

    if (!timeZone) {
      formIsValid = false;
      errorMsg.timeZone = "* Please select timezone.";
    }

    UpdateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    UpdateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    let data ={
      subdividision,
      taxProfile: parseInt(taxProfile),
      buildingPermit,
      homeType,
      costScheme,
      province,
      specificationDoc,
      baseModel,
      defaultCurrency,
      timeZone :new Date(),


      accountSoftware,
      underParkade,
      jobAddress,
      lot,
      block,
      phase,
      city,
      zipCode,
      country,
      numOfBlocks,
      blocks,
      numOfBuildings,
      buildings,
    
    }

    if (formIsValid) {
      dispatch(createMultiFamilyJobAction(ProjectId, data))
      .then((res) => {
        if (res.status == 201) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          UpdateState({...iState,itemId :res.data.details.id})

          // console.log(res,"RRRRRRRRRRRRRR")

          SetMultifamilyJobStep2Modal(true);
          SetMultifamilyJobStep1Modal(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });    }
  };

  const handleCheck = (e) => {
    UpdateState((prev)=> ({...prev, accountSoftware:e.target.checked}));
  };



  useEffect(() => {
    // if(MultifamilyJobStep1Modal){
      dispatch(getMultiFamilyProjectList());
      dispatch(getAllHomeListAction());
      dispatch(getAllTaxProfileAction());
      dispatch(getTimeZoneList());
      UpdateState({...iState,proformaId:perFormaId})
    // }
   
  }, [MultifamilyJobStep1Modal]);



  useEffect(() => {
    dispatch(getCountriesList());
  }, [MultifamilyJobStep1Modal]);


  useEffect(() => { 
    if(country)
    dispatch(
      getStateList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode
      )
    );
  }, [country,countryData?.countries,dispatch]);


  return (
    <>
      <Modal  show={MultifamilyJobStep1Modal} className="ModalBox LargeModal">
        <div class="modal-content BgCreate">
          <a
            onClick={handleMultifamilyJobStep1ModalClose}
            class="CloseModal"
            data-toggle="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create Multi-Family Jobs</h4>
            </div>

            <div class="StepBox">
              <ul>
                <li class="active">
                  <span class="Icon">
                    <img src={require("../../assets/images/step_1.png")} />
                  </span>
                  <span class="Text">Details</span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissionId "
                    onClick={() => {
                      SetMultifamilyJobStep2Modal(true);
                      SetMultifamilyJobStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/blocks.png")} />
                  </span>
                  <span class="Text">Blocks</span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissionId "
                    data-toggle="modal"
                    onClick={() => {
                      SetMultifamilyJobStep3Modal(true);
                      SetMultifamilyJobStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/units.png")} />
                  </span>
                  <span class="Text">Units </span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissionId "
                    data-toggle="modal"
                    onClick={() => {
                      SetMultifamilyJobStep4Modal(true);
                      SetMultifamilyJobStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_8.png")} />
                  </span>
                  <span class="Text">
                    Common <br /> Buildings
                  </span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissionId "
                    data-toggle="modal"
                    onClick={() => {
                      SetMultifamilyJobStep5Modal(true);
                      SetMultifamilyJobStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_2.png")} />
                  </span>
                  <span class="Text">
                    Job <br /> Address
                  </span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissionId "
                    data-toggle="modal"
                    onClick={() => {
                      SetMultifamilyJobStep6Modal(true);
                      SetMultifamilyJobStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_7.png")} />
                  </span>
                  <span class="Text">Confirmation</span>
                </li>
              </ul>
            </div>

            <div class="FolderForm">
              <form>
                <div class="FolderFormCreateArea">
                  <div class="FieldsMandatory">
                    <h6>
                      Fields with <span class="required">*</span> are mandatory
                    </h6>
                  </div>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group">
                        <h6>
                          Project <span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox">
                          
                            <select
                          name="ProjectId"
                          value={ProjectId}
                          onChange={handleChange}
                        >
                          <option selected="">Select Project</option>
                          {checkArray(projectListData) &&
                            projectListData?.filter((item)=>item?.projectType=="Multi Family")?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele?.id}>
                                    {ele?.projectName}
                                  </option>
                                </>
                              );
                            })}
                        </select>                         
                      
                        </div>
                      </div>
                      <div class="form-group">
                        <h6>
                          Subdividision <span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox">
                        <select
                          name="subdividision"
                          value={subdividision}
                          onChange={handleChange}
                        >
                          <option value="">Select Subdivision</option>
                          {checkArray(projectListData?.find((x) => x?.id == ProjectId)?.subdivisions) &&
                            projectListData?.find((x) => x?.id == ProjectId)?.subdivisions?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele}> {ele}</option>
                                </>
                              );
                            })}
                        </select>
                        
                        </div>
                        {errorMsg.subdividision && !subdividision && (
                          <p style={{ color: "red" }}>
                            {errorMsg.subdividision}
                          </p>
                        )}
                      </div>
                      <div class="form-group">
                        <h6>
                          Tax Profile <span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox">
                        <select
                          name="taxProfile"
                          value={taxProfile}
                          onChange={handleChange}
                        >
                          <option value="">Select Tax profile</option>
                          {checkArray(taxProfileListData) &&
                            taxProfileListData?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele?.id}>{ele?.name}</option>
                                </>
                              );
                            })}
                        </select>

                     
                        </div>
                        {errorMsg.taxProfile && !taxProfile && (
                          <p style={{ color: "red" }}>{errorMsg.taxProfile}</p>
                        )}
                      </div>
                      <div class="form-group">
                        <h6>
                          Home Type <span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox">
                        <select
                          name="homeType"
                          value={homeType}
                          onChange={handleChange}
                        >
                          <option value="">Select Home Type</option>
                          {checkArray(homeListData) &&
                            homeListData?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele?.id}>{ele?.name}</option>
                                </>
                              );
                            })}
                        </select>

                  
                        </div>
                        {errorMsg.homeType && !homeType && (
                          <p style={{ color: "red" }}>{errorMsg.homeType}</p>
                        )}
                      </div>
                      <div class="form-group">
                        <h6>
                          Shared Unit Cost Scheme{" "}
                          <span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox">
                          <select
                            onChange={handleChange}
                            value={costScheme}
                            name="costScheme"
                          >
                            <option value={""}>Select Cost Scheme</option>
                            <option value={1}>
                              Square Foot Percentage
                            </option>
                            <option value={2}>
                              Divide Equally Per Unit
                            </option>
                          </select>
                         
                        </div>

                        {errorMsg.costScheme && !costScheme && (
                          <p style={{ color: "red" }}>{errorMsg.costScheme}</p>
                        )}
                      </div>
                      <div class="form-group">
                          <h6>Country</h6>
                          <div class="CommonSelectBox">
                          <select
                                name="country"
                                value={country}
                                onChange={handleChange}
                              >
                                <option>Select Country</option>
                                {countryData?.countries ? (
                                  <>
                                    {countryData?.countries?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <h6>State/Province</h6>
                          <div class="CommonSelectBox">
                          <select
                                name="province"
                                value={province}
                                onChange={handleChange}
                              >
                                <option>Select Province</option>
                                {stateData?.states ? (
                                  <>
                                    {stateData?.states?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                          </div>
                        </div>
                      <div class="form-group">
                        {/* <label class="switch">
                        Integrate Job With Accounting Software{" "}

                          <input onChange={handleCheck} type="checkbox"  />{" "}
                          <span class="required">*</span>
                          <span class="slider"></span>
                        </label> */}

                        <label class="switch">  Integrate Job With Accounting Software
                          <input type="checkbox" onChange={handleCheck} checked={accountSoftware} />
                          <span class="slider"></span> 
                      </label>
                      </div>
                    </div>
                    <div class="col-md-5 ml-auto">
                      <div class="form-group">
                        <h6>Building Permit #</h6>
                        <input
                          type="text"
                          class="form-control"
                          onChange={handleChange}
                          value={buildingPermit}
                          name="buildingPermit"
                          placeholder="Insert Building Permit Number"
                        />
                      </div>
                      <div class="form-group">
                        <h6>Specification Document</h6>
                        <div class="CommonSelectBox">
                          <select
                            onChange={handleChange}
                            value={specificationDoc}
                            name="specificationDoc"
                          >
                            <option value={""}>
                              Select Specification Document
                            </option>
                            <option value={1}>Document 1</option>
                          </select>
                         
                        </div>
                      </div>
                      <div class="form-group">
                        <h6>
                          Base Model 
                        </h6>
                        <div class="CommonSelectBox">
                          <select
                            onChange={handleChange}
                            value={baseModel}
                            name="baseModel"
                          >
                            <option value={""}>Select Base Model Jobs</option>
                            <option value={1}>Model Jobs 1</option>
                          </select>
                      
                        </div>
                        {errorMsg.baseModel && !baseModel && (
                          <p style={{ color: "red" }}>{errorMsg.baseModel}</p>
                        )}
                      </div>
                      <div class="form-group">
                        <h6>
                          Default Currency <span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox">
                          <select
                            onChange={handleChange}
                            value={defaultCurrency}
                            name="defaultCurrency"
                          >
                            <option value={""}>Select Currency</option>
                            <option value={"USD"}>USD</option>
                            <option value={"CAD"}>CAD</option>

                          </select>
                     
                        </div>
                        {errorMsg.defaultCurrency && !defaultCurrency && (
                          <p style={{ color: "red" }}>
                            {errorMsg.defaultCurrency}
                          </p>
                        )}
                      </div>
                      <div class="form-group">
                        <h6>
                          Job Site GMT (UTC) Timezone{" "}
                          <span class="required">*</span>
                        </h6>
                        <div class="CommonSelectBox">
                        <select
                          name="timeZone"
                          value={timeZone}
                          onChange={handleChange}
                        >
                          <option>Select Timezone</option>
                          {checkArray(timeZoneData?.data) ? (
                            <>
                              {timeZoneData?.data?.map((ele, i) => {
                                return (
                                  <option value={ele?.zoneName}>
                                    {ele?.zoneName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                     
                        </div>
                        {errorMsg.timeZone && !timeZone && (
                          <p style={{ color: "red" }}>{errorMsg.timeZone}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Button d-flex justify-content-between align-items-center px-5">
                  <button
                    class="Outline"
                    onClick={() => {
                      SetMultifamilyJobStep1Modal(false);
                      setShow(true);
                    }}
                  >
                    Back
                  </button>
                  <button
                    class="Create FolderPermissionId"
                    onClick={handleSubmit}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <MultifamilyJobStep2
  

     iState={iState}
     UpdateState={UpdateState}

        MultifamilyJobStep2Modal={MultifamilyJobStep2Modal}
        SetMultifamilyJobStep2Modal={SetMultifamilyJobStep2Modal}
        MultifamilyJobStep1Modal={MultifamilyJobStep1Modal}
        SetMultifamilyJobStep1Modal={SetMultifamilyJobStep1Modal}
        MultifamilyJobStep3Modal={MultifamilyJobStep3Modal}
        SetMultifamilyJobStep3Modal={SetMultifamilyJobStep3Modal}
        MultifamilyJobStep4Modal={MultifamilyJobStep4Modal}
        SetMultifamilyJobStep4Modal={SetMultifamilyJobStep4Modal}
        MultifamilyJobStep5Modal={MultifamilyJobStep5Modal}
        SetMultifamilyJobStep5Modal={SetMultifamilyJobStep5Modal}
        MultifamilyJobStep6Modal={MultifamilyJobStep6Modal}
        SetMultifamilyJobStep6Modal={SetMultifamilyJobStep6Modal}
        setShow={setShow}
      />
      <MultifamilyJobStep3

iState={iState}
UpdateState={UpdateState}
        MultifamilyJobStep3Modal={MultifamilyJobStep3Modal}
        SetMultifamilyJobStep3Modal={SetMultifamilyJobStep3Modal}
        MultifamilyJobStep1Modal={MultifamilyJobStep1Modal}
        SetMultifamilyJobStep1Modal={SetMultifamilyJobStep1Modal}
        MultifamilyJobStep2Modal={MultifamilyJobStep2Modal}
        SetMultifamilyJobStep2Modal={SetMultifamilyJobStep2Modal}
        MultifamilyJobStep4Modal={MultifamilyJobStep4Modal}
        SetMultifamilyJobStep4Modal={SetMultifamilyJobStep4Modal}
        MultifamilyJobStep5Modal={MultifamilyJobStep5Modal}
        SetMultifamilyJobStep5Modal={SetMultifamilyJobStep5Modal}
        MultifamilyJobStep6Modal={MultifamilyJobStep6Modal}
        SetMultifamilyJobStep6Modal={SetMultifamilyJobStep6Modal}
        setShow={setShow}
      />
      <MultifamilyJobStep4

iState={iState}
UpdateState={UpdateState}
        MultifamilyJobStep4Modal={MultifamilyJobStep4Modal}
        SetMultifamilyJobStep4Modal={SetMultifamilyJobStep4Modal}
        MultifamilyJobStep1Modal={MultifamilyJobStep1Modal}
        SetMultifamilyJobStep1Modal={SetMultifamilyJobStep1Modal}
        MultifamilyJobStep2Modal={MultifamilyJobStep2Modal}
        SetMultifamilyJobStep2Modal={SetMultifamilyJobStep2Modal}
        MultifamilyJobStep3Modal={MultifamilyJobStep3Modal}
        SetMultifamilyJobStep3Modal={SetMultifamilyJobStep3Modal}
        MultifamilyJobStep5Modal={MultifamilyJobStep5Modal}
        SetMultifamilyJobStep5Modal={SetMultifamilyJobStep5Modal}
        MultifamilyJobStep6Modal={MultifamilyJobStep6Modal}
        SetMultifamilyJobStep6Modal={SetMultifamilyJobStep6Modal}
        setShow={setShow}
      />
      <MultifamilyJobStep5

      
       
       iState={iState} UpdateState={UpdateState}
        MultifamilyJobStep5Modal={MultifamilyJobStep5Modal}
        SetMultifamilyJobStep5Modal={SetMultifamilyJobStep5Modal}
        MultifamilyJobStep1Modal={MultifamilyJobStep1Modal}
        SetMultifamilyJobStep1Modal={SetMultifamilyJobStep1Modal}
        MultifamilyJobStep2Modal={MultifamilyJobStep2Modal}
        SetMultifamilyJobStep2Modal={SetMultifamilyJobStep2Modal}
        MultifamilyJobStep3Modal={MultifamilyJobStep3Modal}
        SetMultifamilyJobStep3Modal={SetMultifamilyJobStep3Modal}
        MultifamilyJobStep4Modal={MultifamilyJobStep4Modal}
        SetMultifamilyJobStep4Modal={SetMultifamilyJobStep4Modal}
        MultifamilyJobStep6Modal={MultifamilyJobStep6Modal}
        SetMultifamilyJobStep6Modal={SetMultifamilyJobStep6Modal}
        setShow={setShow}
      />
      <MultifamilyJobStep6
             iState={iState} UpdateState={UpdateState}

        MultifamilyJobStep6Modal={MultifamilyJobStep6Modal}
        SetMultifamilyJobStep6Modal={SetMultifamilyJobStep6Modal}
        MultifamilyJobStep1Modal={MultifamilyJobStep1Modal}
        SetMultifamilyJobStep1Modal={SetMultifamilyJobStep1Modal}
        MultifamilyJobStep2Modal={MultifamilyJobStep2Modal}
        SetMultifamilyJobStep2Modal={SetMultifamilyJobStep2Modal}
        MultifamilyJobStep3Modal={MultifamilyJobStep3Modal}
        SetMultifamilyJobStep3Modal={SetMultifamilyJobStep3Modal}
        MultifamilyJobStep4Modal={MultifamilyJobStep4Modal}
        SetMultifamilyJobStep4Modal={SetMultifamilyJobStep4Modal}
        MultifamilyJobStep5Modal={MultifamilyJobStep5Modal}
        SetMultifamilyJobStep5Modal={SetMultifamilyJobStep5Modal}
        setShow={setShow}
      />
    </>
  );
};

export default MultifamilyJobStep1;
