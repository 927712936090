// let Url = "http://3.109.73.139:3000/api/v1/";
// import Url from "../../config/config";
import axios from "axios";
import { authActionTypes } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
export const SubscriptionPlanListInitate = () => ({
  type: authActionTypes.SUBSCRIPTION_PLAN_LIST_INITIATE,
});

export const SubscriptionPlanListSuccess = (data) => ({
  type: authActionTypes.SUBSCRIPTION_PLAN_LIST_SUCCESS,
  payload: data,
});

export const SubscriptionPlanListFailure = (data) => ({
  type: authActionTypes.SUBSCRIPTION_PLAN_LIST_FAILURE,
  payload: data,
});

export function SubscriptionPlanListAction(payload) {
  const token = isLoggedIn("adminData1");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(SubscriptionPlanListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}plans/${payload ? payload : ""}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(SubscriptionPlanListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(SubscriptionPlanListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(SubscriptionPlanListFailure(err));
          reject(err);
        });
    });
  };
}

export const SubscriptionPlanDetailsInitate = () => ({
  type: authActionTypes.SUBSCRIPTION_PLAN_DETAILS_INITIATE,
});

export const SubscriptionPlanDetailsSuccess = (data) => ({
  type: authActionTypes.SUBSCRIPTION_PLAN_DETAILS_SUCCESS,
  payload: data,
});

export const SubscriptionPlanDetailsFailure = (data) => ({
  type: authActionTypes.SUBSCRIPTION_PLAN_DETAILS_FAILURE,
  payload: data,
});

export function SubscriptionPlanDetailsAction(payload) {
  const token = isLoggedIn("adminData1");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(SubscriptionPlanDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}plans?planId=${payload}`, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(SubscriptionPlanDetailsSuccess(data.data));
            resolve(data);
          } else {
            dispatch(SubscriptionPlanDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(SubscriptionPlanDetailsFailure(err));
          reject(err);
        });
    });
  };
}

export const addSubscriptionInitate = () => ({
  type: authActionTypes.ADD_SUBSCRIPTION_INITIATE,
});

export const addSubscriptionSuccess = (data) => ({
  type: authActionTypes.ADD_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const addSubscriptionFailure = (data) => ({
  type: authActionTypes.ADD_SUBSCRIPTION_FAILURE,
  payload: data,
});

export function addSubscriptionAction(payload) {
  const token = isLoggedIn("adminData1");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(addSubscriptionInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}subscriptions`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            dispatch(addSubscriptionSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addSubscriptionFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addSubscriptionFailure(err));
          reject(err);
        });
    });
  };
}

export const mainAdminLoginInitiate = () => ({
  type: authActionTypes.MAIN_ADMIN_LOGIN_INITIATE,
});

export const mainAdminLoginSuccess = (data) => ({
  type: authActionTypes.MAIN_ADMIN_LOGIN_SUCCESS,
  payload: data,
});

export const mainAdminLoginFailure = (data) => ({
  type: authActionTypes.MAIN_ADMIN_LOGIN_FAILURE,
  payload: data,
});

export function mainAdminLoginAction(payload) {
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(mainAdminLoginInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}builder-user/login`, payload)
        .then((response) => {
          const data = response.data;
          if (response.status === 200) {
            window.localStorage.setItem(
              "iBuildAdminData",
              JSON.stringify(data)
            );
            resolve(response.data);
            dispatch(mainAdminLoginSuccess(data));
          } else {
            dispatch(mainAdminLoginFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(mainAdminLoginFailure(err));
          reject(err);
        });
    });
  };
}

export const buildingAdminLoginInitiate = () => ({
  type: authActionTypes.BUILDING_ADMIN_LOGIN_INITIATE,
});

export const buildingAdminLoginSuccess = (data) => ({
  type: authActionTypes.BUILDING_ADMIN_LOGIN_SUCCESS,
  payload: data,
});

export const buildingAdminLoginFailure = (data) => ({
  type: authActionTypes.BUILDING_ADMIN_LOGIN_FAILURE,
  payload: data,
});

export function buildingAdminLoginAction(payload) {
  const token = isLoggedIn("institueData");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(buildingAdminLoginInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}instititeLoginOtp`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // // console.log("response", response);
          const data = response.data;
          if (data.status && data.status === 200) {
            window.localStorage.setItem(
              "institueData",
              JSON.stringify(data.data)
            );
            resolve(response);
            dispatch(buildingAdminLoginSuccess(data));
          } else {
            dispatch(buildingAdminLoginFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(buildingAdminLoginFailure(err));
          reject(err);
        });
    });
  };
}

export const buildingMultiAdminLoginInitiate = () => ({
  type: authActionTypes.BUILDING_MULTI_PROJECT_LOGIN_INITIATE,
});

export const buildingMultiAdminLoginSuccess = (data) => ({
  type: authActionTypes.BUILDING_MULTI_PROJECT_LOGIN_SUCCESS,
  payload: data,
});

export const buildingMultiAdminLoginFailure = (data) => ({
  type: authActionTypes.BUILDING_MULTI_PROJECT_LOGIN_FAILURE,
  payload: data,
});

export function buildingMultiAdminLoginAction(payload) {
  const token = isLoggedIn("institueData");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(buildingMultiAdminLoginInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}instititeLoginOtp`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // // // // // // console.log("response", response);
          const data = response.data;
          if (data.status && data.status === 200) {
            window.localStorage.setItem(
              "institueData",
              JSON.stringify(data.data)
            );
            resolve(response);
            dispatch(buildingMultiAdminLoginSuccess(data));
          } else {
            dispatch(buildingMultiAdminLoginFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(buildingMultiAdminLoginFailure(err));
          reject(err);
        });
    });
  };
}

export const buildingSingleAdminLoginInitiate = () => ({
  type: authActionTypes.BUILDING_SINGLE_PROJECT_LOGIN_INITIATE,
});

export const buildingSingleAdminLoginSuccess = (data) => ({
  type: authActionTypes.BUILDING_SINGLE_PROJECT_LOGIN_SUCCESS,
  payload: data,
});

export const buildingSingleAdminLoginFailure = (data) => ({
  type: authActionTypes.BUILDING_SINGLE_PROJECT_LOGIN_FAILURE,
  payload: data,
});

export function buildingSingleAdminLoginAction(payload) {
  const token = isLoggedIn("institueData");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(buildingSingleAdminLoginInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}instititeLoginOtp`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // // // // // // console.log("response", response);
          const data = response.data;
          if (data.status && data.status === 200) {
            window.localStorage.setItem(
              "institueData",
              JSON.stringify(data.data)
            );
            resolve(response);
            dispatch(buildingSingleAdminLoginSuccess(data));
          } else {
            dispatch(buildingSingleAdminLoginFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(buildingSingleAdminLoginFailure(err));
          reject(err);
        });
    });
  };
}

export const superAdminDetailsInitiate = () => ({
  type: authActionTypes.SUPER_ADMIN_DETAILS_INITIATE,
});

export const superAdminDetailsSuccess = (data) => ({
  type: authActionTypes.SUPER_ADMIN_DETAILS_SUCCESS,
  payload: data,
});

export const superAdminDetailsFailure = (data) => ({
  type: authActionTypes.SUPER_ADMIN_DETAILS_FAILURE,
  payload: data,
});

export function superAdminDetailsAction(payload) {
  const token = isLoggedIn("institueData");
  return (dispatch) => {
    dispatch(superAdminDetailsInitiate(payload));
    return new Promise((resolve, reject) =>
      axios
        .post(`${Url}adminDetails`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response.data;

          if (data && data.status === 200) {
            dispatch(superAdminDetailsSuccess(data));
            resolve(data);
          } else {
            dispatch(superAdminDetailsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(superAdminDetailsFailure(err));
          reject(err);
        })
    );
  };
}

export const mainLoginForgetPasswordInitate = () => ({
  type: authActionTypes.MAIN_LOGIN_FORGET_PASSWORD_INITIATE,
});

export const mainLoginForgetPasswordSuccess = (data) => ({
  type: authActionTypes.MAIN_LOGIN_FORGET_PASSWORD_SUCCESS,
  payload: data,
});

export const mainLoginForgetPasswordFailure = (data) => ({
  type: authActionTypes.MAIN_LOGIN_FORGET_PASSWORD_FAILURE,
  payload: data,
});

export function mainLoginForgetPassword(payload) {
  return (dispatch) => {
    dispatch(mainLoginForgetPasswordInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}mainLoginForgetPassword`, payload)
        .then((response) => {
          const data = response.data;
          if (data && data.status == 200) {
            // // // // // // console.log("mainLoginForgetPassword", data);
            dispatch(mainLoginForgetPasswordSuccess(data));
            resolve(data);
          } else {
            dispatch(mainLoginForgetPasswordFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(mainLoginForgetPasswordFailure(err));
          reject(err);
        });
    });
  };
}

export const changePasswordIbuildInitate = () => ({
  type: authActionTypes.CHANGE_MAIN_PASSWORD_INITIATE,
});

export const changePasswordIbuildSuccess = (data) => ({
  type: authActionTypes.CHANGE_MAIN_PASSWORD_SUCCESS,
  payload: data,
});

export const changePasswordIbuildFailure = (data) => ({
  type: authActionTypes.CHANGE_MAIN_PASSWORD_FAILURE,
  payload: data,
});

export function changePasswordIbuild(payload) {
  const token = isLoggedIn("institueData");
  // // // // // // console.log("token-----" , token)
  return (dispatch) => {
    dispatch(changePasswordIbuildInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}changePassword`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response.data;
          if (data && data.status === 200) {
            dispatch(changePasswordIbuildSuccess(data));
            // // // // // // // console.log("change Password ", data);
            resolve(data);
          } else {
            dispatch(changePasswordIbuildFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(changePasswordIbuildFailure(err));
          reject(err);
        });
    });
  };
}

export const iBuildAdminLogoutInitate = () => ({
  type: authActionTypes.IBUILD_ADMIN_LOGOUT_INITIATE,
});

export const iBuildAdminLogoutSuccess = (data) => ({
  type: authActionTypes.IBUILD_ADMIN_LOGOUT_SUCCESS,
  payload: data,
});

export const iBuildAdminLogoutFailure = (data) => ({
  type: authActionTypes.IBUILD_ADMIN_LOGOUT_FAILURE,
  payload: data,
});

export function iBuildAdminLogout(payload) {
  const token = isLoggedIn("institueData");
  return (dispatch) => {
    dispatch(iBuildAdminLogoutInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}adminiBuildAdminLogout`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          const data = response.data;
          if (data && data.status == 200) {
            dispatch(iBuildAdminLogoutSuccess(data));
            resolve(data);
            // // // // // // console.log("adminiBuildAdminLogout", data);
          } else {
            dispatch(iBuildAdminLogoutFailure(data));
            reject(response);
          }
        })
        .catch((err) => {
          dispatch(iBuildAdminLogoutFailure(err));
          reject(err);
        });
    });
  };
}

// --------------DASHBOARD ----------//
export const iBuildAdminDashboardInitiate = () => ({
  type: authActionTypes.IBUILD_ADMIN_DASHBOARD_INITITATE,
});

export const iBuildAdminDashobardSuccess = (data) => ({
  type: authActionTypes.IBUILD_ADMIN_DASHBOARD_SUCCESS,
  payload: data,
});

export const iBuildAdminDashobardFailure = (data) => ({
  type: authActionTypes.IBUILD_ADMIN_DASHBOARD_FAILURE,
  payload: data,
});

export function iBuildAdminDashboardAction(payload) {
  const token = isLoggedIn("institueData");
  // // // // console.log("token--->", token)
  return (dispatch) => {
    dispatch(iBuildAdminDashboardInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}iBuildAdminDashobard`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // // // // // console.log("response", response);
          const data = response.data;
          if (data && data.status === 200) {
            dispatch(iBuildAdminDashobardSuccess(data));
            // // // // console.log("adminLogin", response.data);

            resolve(data);
          } else {
            dispatch(iBuildAdminDashobardFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(iBuildAdminDashobardFailure(err));
          reject(err);
        });
    });
  };
}

export const iBuildAdminSginUpInitiate = () => ({
  type: authActionTypes.IBUILD_SIGNUP_INITIATE,
});

export const iBuildAdminSignUpSuccess = (data) => ({
  type: authActionTypes.IBUILD_SIGNUP_SUCCESS,
  payload: data,
});

export const iBuildAdminSignUpFailure = (data) => ({
  type: authActionTypes.IBUILD_SIGNUP_FAILURE,
  payload: data,
});

export function iBuildAdminSginUpAction(payload) {
  const token = isLoggedIn("institueData");
  // // // // console.log("token--->", token)
  return (dispatch) => {
    dispatch(iBuildAdminDashboardInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}iBuildAdminSignUp`, payload, {
          headers: { Authorization: `${token}` },
        })
        .then((response) => {
          // // // // // console.log("response", response);
          const data = response.data;
          if (data && data.status === 200) {
            dispatch(iBuildAdminSignUpSuccess(data));
            // // // // console.log("adminLogin", response.data);

            resolve(data);
          } else {
            dispatch(iBuildAdminSignUpFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(iBuildAdminSignUpFailure(err));
          reject(err);
        });
    });
  };
}

export const getCountryListInitate = () => ({
  type: authActionTypes.GET_COUNTRIES_LIST_INITIATE,
});

export const getCountryListSuccess = (data) => ({
  type: authActionTypes.GET_COUNTRIES_LIST_SUCCESS,
  payload: data,
});

export const getCountryListFailure = (data) => ({
  type: authActionTypes.GET_COUNTRIES_LIST_FAILURE,
  payload: data,
});

export function getCountriesList(payload) {
  const token = isLoggedIn("institueData");
  return (dispatch) => {
    dispatch(getCountryListInitate(payload));
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}countries`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          // // console.log("ress",response.data);
          const data = response.data;
          if (response.status === 200) {
            dispatch(getCountryListSuccess(data));
            // // console.log(data)
            resolve(data);
          } else {
            dispatch(getCountryListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getCountryListFailure(err));
          reject(err);
        });
    });
  };
}

export const getTimeZoneInitate = () => ({
  type: authActionTypes.TIMEZONE_LIST_INITIATE,
});

export const getTimeZoneSuccess = (data) => ({
  type: authActionTypes.TIMEZONE_LIST_SUCCESS,
  payload: data,
});

export const getTimeZoneFailure = (data) => ({
  type: authActionTypes.TIMEZONE_LIST_FAILURE,
  payload: data,
});

export function getTimeZoneList(payload) {
  const token = isLoggedIn("institueData");
  return (dispatch) => {
    dispatch(getTimeZoneInitate(payload));
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}list-time-zone`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          // // console.log("ress",response.data);
          const data = response.data;
          if (response.status === 200) {
            dispatch(getTimeZoneSuccess(data));
            // // console.log(data)
            resolve(data);
          } else {
            dispatch(getTimeZoneFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getTimeZoneFailure(err));
          reject(err);
        });
    });
  };
}

export const getStateListInitate = () => ({
  type: authActionTypes.GET_STATE_LIST_INITIATE,
});

export const getStateListSuccess = (data) => ({
  type: authActionTypes.GET_STATE_LIST_SUCCESS,
  payload: data,
});

export const getStateListFailure = (data) => ({
  type: authActionTypes.GET_STATE_LIST_FAILURE,
  payload: data,
});

export function getStateList(payload) {
  const token = isLoggedIn("institueData");
  // // // // // // console.log("token-----" , token)
  return (dispatch) => {
    dispatch(getStateListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}states?countryCode=${payload ? payload : ""}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response.data;
          if (response && response.status === 200) {
            dispatch(getStateListSuccess(data));
            resolve(data);
          } else {
            dispatch(getStateListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getStateListFailure(err));
          reject(err);
        });
    });
  };
}

export const getCityListInitate = () => ({
  type: authActionTypes.GET_CITIES_LIST_INITIATE,
});

export const getCityListSuccess = (data) => ({
  type: authActionTypes.GET_CITIES_LIST_SUCCESS,
  payload: data,
});

export const getCityListFailure = (data) => ({
  type: authActionTypes.GET_CITIES_LIST_FAILURE,
  payload: data,
});

export function getCitiesList(payload, state) {
  const token = isLoggedIn("institueData");
  // // // // // // console.log("token-----" , token)
  return (dispatch) => {
    dispatch(getCityListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}cities?countryCode=${payload ? payload : ""}&stateCode=${
            state ? state : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          // // console.log("resposoe------->", response)
          const data = response.data;
          if (response && response.status === 200) {
            dispatch(getCityListSuccess(data));

            resolve(data);
          } else {
            dispatch(getCityListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getCityListFailure(err));
          reject(err);
        });
    });
  };
}

export const bulkUploadCorporateInitiate = () => ({
  type: authActionTypes.UPLOAD_BULK_VENDOR_INITIATE,
});

export const bulkUploadCorporateSuccess = (data) => ({
  type: authActionTypes.UPLOAD_BULK_VENDOR_SUCCESS,
  payload: data,
});

export const bulkUploadCorporateFailure = (data) => ({
  type: authActionTypes.UPLOAD_BULK_VENDOR_FAILURE,
  payload: data,
});

export const bulkUploadCorporate = (payload) => async (dispatch) => {
  try {
    const updatedData = multiPartData(payload);
    const token = isLoggedIn("adminLogin");
    dispatch(bulkUploadCorporateInitiate(payload));
    const response = await axios.post(`${Url}admin/add-csv`, updatedData, {
      headers: { Authorization: `${token}` },
    });
    if (response.status === 200) {
      dispatch(bulkUploadCorporateSuccess(response.data));
    } else {
      dispatch(bulkUploadCorporateFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(bulkUploadCorporateFailure(err));
    return err.response;
  }
};

export const updateChangePasswordLinkInitate = () => ({
  type: authActionTypes.UPDATE_PASSWORD_INITIATE,
});

export const updateChangePasswordLinkSuccess = (data) => ({
  type: authActionTypes.UPDATE_PASSWORD_SUCCESS,
  payload: data,
});

export const updateChangePasswordLinkFailure = (data) => ({
  type: authActionTypes.UPDATE_PASSWORD_FAILURE,
  payload: data,
});

export function updateChangePasswordLinkAction(payload) {
  const token = isLoggedIn("institueData");
  return (dispatch) => {
    dispatch(updateChangePasswordLinkInitate(payload));
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}builder-user/send-reset`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          // // console.log("ress",response.data);
          const data = response.data;
          if (response.status === 200) {
            dispatch(updateChangePasswordLinkSuccess(data));
            // // console.log(data)
            resolve(data);
          } else {
            dispatch(updateChangePasswordLinkFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateChangePasswordLinkFailure(err));
          reject(err);
        });
    });
  };
}

export const resetPasswordInitate = () => ({
  type: authActionTypes.PASSWORD_RESET_INITIATE,
});

export const resetPasswordSuccess = (data) => ({
  type: authActionTypes.PASSWORD_RESET_SUCCESS,
  payload: data,
});

export const resetPasswordFailure = (data) => ({
  type: authActionTypes.PASSWORD_RESET_FAILURE,
  payload: data,
});

export function resetPasswordAction(password, userId, companyId, token) {
  // const token = isLoggedIn("institueData")

  return (dispatch) => {
    dispatch(resetPasswordInitate(password));
    return new Promise((resolve, reject) => {
      axios
      .put(`${Url}companies/${companyId ? companyId : ""}/users/${userId ? userId : ""}/reset-password/${token ? token : ""}`, password, {
        headers: { Authorization: `Bearer ${token}` },
      })
       
        .then((response) => {
          // // console.log("ress",response.data);
          const data = response.data;
          if (response.status === 200) {
            dispatch(resetPasswordSuccess(data));
            // // console.log(data)
            resolve(data);
          } else {
            dispatch(resetPasswordFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(resetPasswordFailure(err));
          reject(err);
        });
    });
  };
}

export const headerNotifcaitionListInitate = () => ({
  type: authActionTypes.GET_CITIES_LIST_INITIATE,
});

export const headerNotifcaitionListSuccess = (data) => ({
  type: authActionTypes.GET_CITIES_LIST_SUCCESS,
  payload: data,
});

export const headerNotifcaitionListFailure = (data) => ({
  type: authActionTypes.GET_CITIES_LIST_FAILURE,
  payload: data,
});

export function headerNotifcaitionList(payload, state) {
  const token = isLoggedIn("institueData");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(headerNotifcaitionListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/notifications`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if (response && response.status === 200) {
            dispatch(headerNotifcaitionListSuccess(data));

            resolve(data);
          } else {
            dispatch(headerNotifcaitionListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(headerNotifcaitionListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(headerNotifcaitionListFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const removeHeaderNotifcaitionListInitate = () => ({
  type: authActionTypes.GET_CITIES_LIST_INITIATE,
});

export const removeHeaderNotifcaitionListSuccess = (data) => ({
  type: authActionTypes.GET_CITIES_LIST_SUCCESS,
  payload: data,
});

export const removeHeaderNotifcaitionListFailure = (data) => ({
  type: authActionTypes.GET_CITIES_LIST_FAILURE,
  payload: data,
});

export function removeHeaderNotifcaitionList(payload, state) {
  const token = isLoggedIn("institueData");
  // // // // // // console.log("token-----" , token)
  return (dispatch) => {
    dispatch(removeHeaderNotifcaitionListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/notifications`,
          {
            removeHeaders: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          // // console.log("resposoe------->", response)
          const data = response.data;
          if (response && response.status === 200) {
            dispatch(removeHeaderNotifcaitionListSuccess(data));

            resolve(data);
          } else {
            dispatch(removeHeaderNotifcaitionListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeHeaderNotifcaitionListFailure(err));
          reject(err);
        });
    });
  };
}




export const subDivisionListInitate = () => ({
  type: authActionTypes.SUBDIVISION_LIST_INITIATE,
});

export const subDivisionListSuccess = (data) => ({
  type: authActionTypes.SUBDIVISION_LIST_SUCCESS,
  payload: data,
});

export const subDivisionListFailure = (data) => ({
  type: authActionTypes.SUBDIVISION_LIST_FAILURE,
  payload: data,
});

export function subDivisionListAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(subDivisionListInitate());
    return new Promise((resolve, reject) => {
      axios
      .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/subdivisions`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(subDivisionListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(subDivisionListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(subDivisionListFailure(err));
          reject(err);
        });
    });
  };
}







export const commonCostCodeListInitate = () => ({
  type: authActionTypes.COMMON_COST_CODE_LIST_INITIATE,
});

export const commonCostCodeListSuccess = (data) => ({
  type: authActionTypes.COMMON_COST_CODE_LIST_SUCCESS,
  payload: data,
});

export const commonCostCodeListFailure = (data) => ({
  type: authActionTypes.COMMON_COST_CODE_LIST_FAILURE,
  payload: data,
});

export function commonCostCodeListAction(jobId, jobType,CostCategoryId) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(commonCostCodeListInitate());
    return new Promise((resolve, reject) => {
      axios
      .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode/job?jobId=${jobId}&jobType=${jobType}&CostCategoryId=${CostCategoryId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(commonCostCodeListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(commonCostCodeListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(commonCostCodeListFailure(err));
          reject(err);
        });
    });
  };
}





export const approverListInitate = () => ({
  type: authActionTypes.APPROVER_LIST_INITIATE,
});

export const approverListSuccess = (data) => ({
  type: authActionTypes.APPROVER_LIST_SUCCESS,
  payload: data,
});

export const approverListFailure = (data) => ({
  type: authActionTypes.APPROVER_LIST_FAILURE,
  payload: data,
});

export function approverListAction(jobId, jobType,cid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(approverListInitate());
    return new Promise((resolve, reject) => {
      axios
      .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode/job?jobId=${jobId}&jobType=${jobType}&CostCategoryId=${cid}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(approverListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(approverListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(approverListFailure(err));
          reject(err);
        });
    });
  };
}




export const commonCostCategoryListInitate = () => ({
  type: authActionTypes.COMMON_COST_CATEGORY_LIST_INITIATE,
});

export const commonCostCategoryListSuccess = (data) => ({
  type: authActionTypes.COMMON_COST_CATEGORY_LIST_SUCCESS,
  payload: data,
});

export const commonCostCategoryListFailure = (data) => ({
  type: authActionTypes.COMMON_COST_CATEGORY_LIST_FAILURE,
  payload: data,
});

export function commonCostCategoryListAction(jobId, jobType) {
  const token = isLoggedIn("adminData1");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(commonCostCategoryListInitate());
    return new Promise((resolve, reject) => {
      axios
      .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory/job?jobId=${jobId}&jobType=${jobType}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(commonCostCategoryListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(commonCostCategoryListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(commonCostCategoryListFailure(err));
          reject(err);
        });
    });
  };
}




export const ibuildAdminLoginInitiate = () => ({
  type: authActionTypes.MAIN_ADMIN_LOGIN_INITIATE,
});

export const ibuildAdminLoginSuccess = (data) => ({
  type: authActionTypes.MAIN_ADMIN_LOGIN_SUCCESS,
  payload: data,
});

export const ibuildAdminLoginFailure = (data) => ({
  type: authActionTypes.MAIN_ADMIN_LOGIN_FAILURE,
  payload: data,
});

export function ibuildAdminLoginAction(payload) {
  return (dispatch) => {
    dispatch(ibuildAdminLoginInitiate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}ibuild-user/login`, payload)
        .then((response) => {
          const data = response.data;
          if (response.status && response.status === 200) {
            window.localStorage.setItem(
              "iBuildAdminData",
              JSON.stringify(data)
            );
            resolve(response.data);
            dispatch(ibuildAdminLoginSuccess(data));
          } else {
            dispatch(ibuildAdminLoginFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(mainAdminLoginFailure(err));
          reject(err);
        });
    });
  };
}




export function createRoomAction(jobId, jobType,cid) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
      .post(`${Url}discussions/room`, jobId, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}



export function getRoomAction(jobId,cid) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
  
    return new Promise((resolve, reject) => {
      axios
      .get(`${Url}discussions/room?userOneId=${jobId}&userTwoId=${cid}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
          
            resolve(data);
          } else {
           
            reject(data);
          }
        })
        .catch((err) => {
        
          reject(err);
        });
    });
  };
}



export function createMessageAction(jobId,cid) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
      .post(`${Url}discussions/message`, jobId, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}




export const getMessageReceivedInitate = () => ({
  type: authActionTypes.MESSAGE_LIST_INITIATE,
});

export const getMessageReceivedSuccess = (data) => ({
  type: authActionTypes.MESSAGE_LIST_SUCCESS,
  payload: data,
});

export const getMessageReceivedFailure = (data) => ({
  type: authActionTypes.MESSAGE_LIST_FAILURE,
  payload: data,
});

export function getMessagesAction(jobId) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getMessageReceivedInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}discussions/message/${jobId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("dataatattt", data)
          if (data && data.status == 200) {
            dispatch(getMessageReceivedSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getMessageReceivedFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getMessageReceivedFailure(err));
          reject(err);
        });
    });
  };
}




export const getAllCostcategroyInitate = () => ({
  type: authActionTypes.GET_ALL_COSTCATEGORY_INITIATE,
});

export const getAllCostcategroySuccess = (data) => ({
  type: authActionTypes.GET_ALL_COSTCATEGORY_SUCCESS,
  payload: data,
});

export const getAllCostcategroyFailure = (data) => ({
  type: authActionTypes.GET_ALL_COSTCATEGORY_FAILURE,
  payload: data,
});

export function getAllCostcategroyAction(jobId, jobType,cid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllCostcategroyInitate());
    return new Promise((resolve, reject) => {
      axios
      .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory/all`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getAllCostcategroySuccess(data.data));
            resolve(data);
          } else {
            dispatch(getAllCostcategroyFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getAllCostcategroyFailure(err));
          reject(err);
        });
    });
  };
}




export const getAllCostCodeInitate = () => ({
  type: authActionTypes.GET_ALL_COSTCODE_INITIATE,
});

export const getAllCostCodeSuccess = (data) => ({
  type: authActionTypes.GET_ALL_COSTCODE_SUCCESS,
  payload: data,
});

export const getAllCostCodeFailure = (data) => ({
  type: authActionTypes.GET_ALL_COSTCODE_FAILURE,
  payload: data,
});

export function getAllCostCodeAction(jobId) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllCostCodeInitate());
    return new Promise((resolve, reject) => {
      axios
      .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode/all?CostCategoryId=${jobId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getAllCostCodeSuccess(data.data));
            resolve(data);
          } else {
            dispatch(getAllCostCodeFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getAllCostCodeFailure(err));
          reject(err);
        });
    });
  };
}



export const getBlockListInitate = () => ({
  type: authActionTypes.GET_BLOCK_LIST_INITIATE,
});

export const getBlockListSuccess = (data) => ({
  type: authActionTypes.GET_BLOCK_LIST_SUCCESS,
  payload: data,
});

export const getBlockListFailure = (data) => ({
  type: authActionTypes.GET_BLOCK_LIST_FAILURE,
  payload: data,
});

export function getBlockList(payload, state) {
  const token = isLoggedIn("institueData");
  // // // // // // console.log("token-----" , token)
  return (dispatch) => {
    dispatch(getBlockListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/model`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          // // console.log("resposoe------->", response)
          const data = response.data;
          if (response && response.status === 200) {
            dispatch(getBlockListSuccess(data));

            resolve(data);
          } else {
            dispatch(getBlockListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getBlockListFailure(err));
          reject(err);
        });
    });
  };
}



export const getSpecificationDocsInitate = () => ({
  type: authActionTypes.GET_SPECIFICATION_DOCS_LIST_INITIATE,
});

export const getSpecificationDocsSuccess = (data) => ({
  type: authActionTypes.GET_SPECIFICATION_DOCS_LIST_SUCCESS,
  payload: data,
});

export const getSpecificationDocsFailure = (data) => ({
  type: authActionTypes.GET_SPECIFICATION_DOCS_LIST_FAILURE,
  payload: data,
});

export function getSpecificationDocsList(payload) {
  const token = isLoggedIn("institueData");
  // // // // // // console.log("token-----" , token)
  return (dispatch) => {
    dispatch(getSpecificationDocsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/specific-released-document?ProjectId=${payload ? payload :""}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          // // console.log("resposoe------->", response)
          const data = response.data;
          if (response && response.status === 200) {
            dispatch(getSpecificationDocsSuccess(data));

            resolve(data);
          } else {
            dispatch(getSpecificationDocsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getSpecificationDocsFailure(err));
          reject(err);
        });
    });
  };
}


//================GET-COMPANY-BY--ID======================


export const getProjectByIdInitate = () => ({
  type: authActionTypes.GET_PROJECT_BY_ID_INITIATE,
});

export const getProjectByIdSuccess = (data) => ({
  type: authActionTypes.GET_PROJECT_BY_ID_SUCCESS,
  payload: data,
});

export const getProjectByIdFailure = (data) => ({
  type: authActionTypes.GET_PROJECT_BY_ID_FAILURE,
  payload: data,
});



export const getProjectByIds = (comapnyId,projectId,token) => async (dispatch) => {
  try {
    dispatch(getProjectByIdInitate())
    const response = await axios.get(
      `${Url}companies/${comapnyId}/projects/${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getProjectByIdSuccess(response.data));
    } else {
      dispatch(getProjectByIdFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getProjectByIdFailure(err));
    return err.response;
  }
};