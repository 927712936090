import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import EditProjectModal from "./DashboardModal/EditProjectModal";

import {
  getTaxProfileList,
  updateProjectss,
} from "../redux/action/companyAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { useSelector } from "react-redux";
const initialState = {
  projectAddress: "",
  city: "",
  zipCode: "",
  province: "",
  country: "",
  projectName: "",
  projectEmail: "",
  taxProfile: "",
  jobPattern: "",
  imagePreview:"",
  logo:"",

};

const JobDetailsModal = ({
  jobDetailModalShow,
  setJobDetailModalShow,
  updateState,
  projectDetailsData,
}) => {
  


  const [editProjectModal, setEditProjectModal] =useState(false)
  const [iState, updateStates] = useState(initialState);
  const {
    projectAddress,
    city,
    zipCode,
    province,
    country,
    projectName,
    projectEmail,
    taxProfile,
    jobPattern,
    logo,
    imagePreview
  } = iState;
  const dispatch = useDispatch();

  const { getTaxProfile } = useSelector((state) => state.companyReducer);
  const {jobListData } =
  useSelector((state) => state.jobReducer);

  useEffect(() => {
    if (jobDetailModalShow) dispatch(getTaxProfileList());
  }, [jobDetailModalShow]);

  const handleJobDetailModalClose = () => {
    setJobDetailModalShow(false);
    updateState((prev) => ({
      ...prev,
      projectDetailsModal: true,
    }));
  };

  useEffect(() => {
    if (!_.isEmpty(projectDetailsData)) {
      const updateData = _.cloneDeep(iState);
      updateData.projectAddress = projectDetailsData?.projectAddress;
      updateData.city = projectDetailsData?.city;
      updateData.zipCode = projectDetailsData?.zipCode;
      updateData.province = projectDetailsData?.province;
      updateData.country = projectDetailsData?.country;
      updateData.projectName = projectDetailsData?.projectName;
      updateData.projectEmail = projectDetailsData?.projectEmail;
      updateData.taxProfile = projectDetailsData?.TaxProfile?.id;
      updateData.jobPattern = projectDetailsData?.jobPattern;
      updateData.logo = projectDetailsData?.displayLogo;

      updateStates(updateData);
    }
  }, [projectDetailsData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateStates({ ...iState, [name]: value });
  };

  const handleJobDetailsUpdate = (types) => {

    console.log(projectDetailsData,'DATA==============>')
    if (types === "Business") {
      const formData = new FormData();
      formData.append("projectAddress", projectAddress);
      formData.append("city", city);
      formData.append("zipCode", zipCode);
      formData.append("country", country);
      formData.append("province", province);
      dispatch(updateProjectss(projectDetailsData?.id, formData)).then(
        (res) => {
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    } else if (types === "general") {
      const formData = new FormData();
      formData.append("projectName", projectName);
      formData.append("projectEmail", projectEmail);
      formData.append("projectLogo", logo)
      dispatch(updateProjectss(projectDetailsData?.id, formData)).then(
        (res) => {
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    } else if (types === "tax") {
      const formData = new FormData();
      formData.append("taxProfile", taxProfile);
      dispatch(updateProjectss(projectDetailsData.id, formData)).then(
        (res) => {
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    } else if (types === "pattern") {
      const formData = new FormData();
      formData.append("jobPattern", jobPattern);
      dispatch(updateProjectss(projectDetailsData?.id, formData)).then(
        (res) => {
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  
  const handleUpload = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        

        updateStates({
          ...iState,
          logo: e.target.files[0],
          imagePreview:  URL.createObjectURL(file),
        });
      } 
    
  };  };


  console.log(projectDetailsData,"PROJECT DETAILS DATA ==================>")


  return (
    <>
      <Modal
        show={jobDetailModalShow}
        onHide={handleJobDetailModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleJobDetailModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox GrayModalTitle">
              <h4>Project Details</h4>
            </div>
            <div className="FolderForm">
              <div className="ResidentialJobArea ResidentialJobAreaPara">
                <div className="ResidentialJobHead">
                  <h3>
                    Proj-4E-SIN-1
                    <span className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src="images/dots.png" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          <img src="images/cancecl_message.png" />
                          Cancel Project
                        </a>
                      </div>
                    </span>
                  </h3>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Created By</h6>
                        <p>{projectDetailsData?.createdBy}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Creation Date</h6>
                        <p>
                          {moment(projectDetailsData?.createdAt).format(
                            "YYYY-MM-DD"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Last Update</h6>
                        <p>
                          {moment(projectDetailsData?.updatedAt).format(
                            "YYYY-MM-DD"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Type</h6>
                        <p>{projectDetailsData?.projectType}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ModalAccordian">
                  <div id="accordion">
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#GenerationInformation"
                        aria-expanded="true"
                      >
                        <h4>
                          <span>
                            <img src="images/generation_information.png" />
                          </span>
                          Generation Information
                        </h4>
                      </div>
                      <div
                        id="GenerationInformation"
                        className="collapse show"
                        data-parent="#accordion"
                        style={{}}
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <h6>Name</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Company 1"
                                  name="projectName"
                                  value={projectName}
                                  onChange={handleInputChange}
                                />
                              </div>

                              <div className="form-group">
                                <h6>Email</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="contact@company1.com"
                                  name="projectEmail"
                                  value={projectEmail}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <h6>Logo</h6>
                                
                            
                          {logo ? 
                        <>
                         <img src={ imagePreview?imagePreview :logo } />
                         <input
                        type="file"
                        name="logo"
                        onChange={handleUpload}
                      />
                        </>
                       
                        :
                        <>
                         <input
                        type="file"
                        name="logo"
                        onChange={handleUpload}
                      />
                      </ >}
                                {/* <span className="FmBuilderLogo">
                                  <img src="images/fm_builder_logo.png" />
                                </span> */}
                                <div>
                                  {/* <button className="AlterBtn mt-3">
                                      Alter
                                    </button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="Button d-flex justify-content-center">
                            <button className="OutlineBlack mr-3">
                              Cancel
                            </button>
                            <button
                              className="Respond ml-3"
                              onClick={() => handleJobDetailsUpdate("general")}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#businessAddress"
                      >
                        <h4>
                          <span>
                            <img src="images/business_address.png" />
                          </span>{" "}
                          Business Address
                        </h4>
                      </div>
                      <div
                        id="businessAddress"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Address</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Street Address"
                                  value={projectAddress}
                                  name="projectAddress"
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="form-group">
                                <h6>City</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Calgary"
                                  value={city}
                                  name="city"
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Zip/ Postal Code</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="T3A T3A"
                                  value={zipCode}
                                  name="zipCode"
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="form-group">
                                <h6>Country</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Canada"
                                  value={country}
                                  name="country"
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="form-group">
                                <h6>State/Province</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="AB"
                                  value={province}
                                  name="province"
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="Button d-flex justify-content-center">
                            <button className="OutlineBlack mr-3">
                              Cancel
                            </button>
                            <button
                              className="Respond ml-3"
                              onClick={() => handleJobDetailsUpdate("Business")}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#Accounting"
                      >
                        <h4>
                          <span>
                            <img src="images/accounting.png" />
                          </span>
                          Accounting and Tax
                        </h4>
                      </div>
                      <div
                        id="Accounting"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-5 ml-auto">
                              <div className="form-group">
                                <h6>Developer</h6>
                                <div className="CommonSelectBox">
                                  <select>
                                    <option selected="">Developer 1</option>
                                    <option>Developer 2</option>
                                  </select>
                                </div>
                              </div>
                              <div className="form-group">
                                <h6>Tax Profile</h6>
                                <div className="CommonSelectBox">
                                  <select
                                    name="taxProfile"
                                    value={taxProfile}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">Select Tax Profile</option>
                                    {getTaxProfile?.length > 0 &&
                                      getTaxProfile?.map((item, i) => {
                                        return (
                                          <option
                                            key={i}
                                            value={item.id}
                                            selected={item.id == taxProfile}
                                          >
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="Button d-flex justify-content-center">
                            <button className="OutlineBlack mr-3">
                              Cancel
                            </button>
                            <button
                              className="Respond ml-3"
                              onClick={() => handleJobDetailsUpdate("tax")}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header collapsed"
                        data-toggle="collapse"
                        href="#JobNumbering"
                        aria-expanded="false"
                      >
                        <h4>
                          <span>
                            <img src="images/job_numbering.png" />
                          </span>{" "}
                          Job Numbering
                        </h4>
                      </div>
                      <div
                        id="JobNumbering"
                        className="collapse"
                        data-parent="#accordion"
                        style={{}}
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Job Number Pattern</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="[UNUM]-[SD3]-[CID]-[JN]"
                                  name="jobPattern"
                                  value={jobPattern}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <h6>Sample Job Number</h6>
                                <input
                                  type="text"
                                  className="form-control InputCyanBg"
                                  placeholder="[UNUM]-[SD3]-[CID]-[JN]"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>

                          <div className="Button mt-4 d-flex justify-content-center">
                            <button className="OutlineBlack mr-3">
                              Cancel
                            </button>
                            <button
                              className="Respond ml-3"
                              onClick={() => handleJobDetailsUpdate("pattern")}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div
                        className="card-header collapsed"
                        data-toggle="collapse"
                        href="#Jobs"
                        aria-expanded="false"
                      >
                        <h4>
                          <span>
                            <img src="images/jobs_collpase.png" />
                          </span>{" "}
                          Jobs
                        </h4>
                      </div>
                      <div
                        id="Jobs"
                        className="collapse"
                        data-parent="#accordion"
                        style={{}}
                      >
                        <div className="card-body">
                          <div className="Tableheader">
                            <h6 className="mb-0">Residential Jobs</h6>
                            <div className="d-flex align-items-center">
                              <div className="TodosButtons mt-0 d-flex">
                                {/* <a href="javascript:void(0)" className="">
                                  <img src="images/create_new_job.png" />
                                  Create New Job
                                </a> */}
                              </div>
                              <div className="NewActions">
                                <a href="jacascript:void(0);">
                                  <img src="images/icon-53.png" />
                                </a>
                                <a href="jacascript:void(0);">
                                  <img src="images/download.png" />
                                </a>
                                <a href="jacascript:void(0);">
                                  <img src="images/setting.png" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="TableList TableListHeader NewTableList TableHeightScroll">
                            <table>
                              <thead>
                                <tr>
                                  <th>Id</th>
                                  <th>Home Type</th>
                                  <th>Tax Profile</th>
                                  <th>Foundation</th>
                                  <th>Address</th>
                                  <th>Creation Date</th>
                                  <th>Created By</th>
                                  <th>Timezone</th>
                                  <th>Accounting Status</th>
                                  <th>Add-Ons</th>
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                             

                                {/* <tr>
                                  <td>
                                    <div className="TextBlue">HIL-2B-376</div>
                                  </td>
                                  <td>2-Storey</td>
                                  <th>Alberta Tax</th>
                                  <td>Basement</td>
                                  <td>
                                    312 Weddenburn Rd SE Calgary, Alberta, T2J
                                    1J1, Canada
                                  </td>
                                  <td>05/24/2023 </td>
                                  <td>John Doe</td>
                                  <td>Moutain Time GMT - 06</td>
                                  <td>Integrated with Accouting Software</td>
                                  <td>20</td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth2">
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/Action-1.png" />
                                                Edit Job Details
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/archive_job.png" />
                                                Archive Job
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/enable_account.png" />
                                                Enable Accouting Integration
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/add_build.png" />
                                                Create Add-Ons
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr> */}
                              </tbody>
                            </table>
                          </div>
                          <div className="PaginationArea">
                            <h6>Showing 3 of 3 entries</h6>
                            <h6>
                              <img src="images/leftarrow.png" /> 01{" "}
                              <img src="images/rightarrow.png" /> Out 10
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="CloseButton">
                    <button
                      className="Close"
                      onClick={handleJobDetailModalClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {editProjectModal && <EditProjectModal />}

    </>
  );
};

export default JobDetailsModal;
